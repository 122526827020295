import React, { useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiDrawer from "@mui/material/Drawer";
import Box from "@mui/material/Box";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuIcon from "@mui/icons-material/Menu";
import Images from "../Config/Images";
import {
  Avatar,
  BottomNavigation,
  BottomNavigationAction,
  Collapse,
  Grid,
  ListItemButton,
  ListItemText,
  Popover,
  Tooltip,
} from "@mui/material";
import { Menubar_Arr } from "../Config/Static_Data";
import NavigationStack from "./navigationStack";
import { useNavigate, useLocation } from "react-router-dom";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../Redux/reducers/auth/actions";
import {
  ContentPasteSearch,
  ExpandLess,
  ExpandMore,
  Home,
  LocalShipping,
  SentimentVeryDissatisfied,
} from "@mui/icons-material";
import { color } from "../Config/theme";
import ConfirmDialog from "../Components/ConfirmDialog";
import { Setting } from "../Utils/Setting";
import { getApiData } from "../Utils/APIHelper";
import { toast } from "react-toastify";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import NotificationPopup from "../Components/NotificationPopUp";
import { isIOS, isMobile, isTablet } from "react-device-detect";
import { isEmpty, isObject } from "lodash";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  // shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  backgroundColor: "#2B3643",
  boxShadow: "none",
  zIndex:
    theme.breakpoints.down("md") && open
      ? theme.zIndex.drawer - 1
      : theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  ...(open && {
    // marginLeft: drawerWidth,
    width: theme.breakpoints.down("md")
      ? null
      : `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  // shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  "& .MuiDrawer-paper": {
    backgroundColor: color.secondary,
    position: "relative",
    whiteSpace: "nowrap",
    width: open ? drawerWidth : 60,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    boxSizing: "border-box",
    ...(!open && {
      overflowX: "hidden",
      transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
      [theme.breakpoints.up("sm")]: {
        width: theme.spacing(9),
      },
    }),
  },
}));

function DrawerMenu() {
  // const { isTablet, isMobile } = Setting;
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [drawerOpen, setDrawerOpen] = useState(false);
  const toggleDrawer = () => {
    setOpen(!open);
    if (open) {
      setDrawerOpen(false);
    }
  };
  const { setUserData } = authActions;
  const { userdata, useruuid, token, permissionData, isOnline, countDown } =
    useSelector((state) => state.auth);

  const [value, setValue] = useState("dashboard");

  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorEl2, setAnchorEl2] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const pop = Boolean(anchorEl);
  const pop2 = Boolean(anchorEl2);
  const id = pop ? "simple-popover" : undefined;
  const id2 = pop2 ? "simple-popover" : undefined;

  const [dashboardView, setDashboardView] = useState(true);
  const [fleetView, setFleetView] = useState(true);
  const [inspectionView, setInspectionView] = useState(true);
  const [scrapView, setScrapView] = useState(true);
  const [tyreView, setTyreView] = useState(true);
  const [ServiceView, setServiceView] = useState(true);
  const [subActive, setSubActive] = useState("");

  const Ref = useRef(null);

  // The state for our timer
  const [timer, setTimer] = useState("30:00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    return {
      total,
      minutes,
      seconds,
    };
  };

  const startTimer = (e) => {
    let { total, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (minutes > 9 ? minutes : "0" + minutes) +
          ":" +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
      isObject(userdata) && !isEmpty(userdata) && handleSubmit();
      clearInterval(Ref.current);
    }
  };

  const clearTimer = (e) => {
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };

  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 1800);
    return deadline;
  };

  useEffect(() => {
    isObject(userdata) && !isEmpty(userdata) && clearTimer(getDeadTime());
  }, [countDown]);

  useEffect(() => {
    isEmpty(userdata) && clearInterval(Ref.current);
  }, [userdata]);

  const isTabFleet =
    userdata?.role !== "superadmin" &&
    userdata?.role !== "admin" &&
    userdata?.role !== "call_centre" &&
    !fleetView;

  const isTabService =
    userdata?.role !== "superadmin" &&
    userdata?.role !== "admin" &&
    userdata?.role !== "call_centre" &&
    !ServiceView;

  const isTabDashboard =
    userdata?.role !== "superadmin" &&
    userdata?.role !== "admin" &&
    userdata?.role !== "call_centre" &&
    !dashboardView;

  const isTabTyre =
    userdata?.role !== "superadmin" &&
    userdata?.role !== "admin" &&
    userdata?.role !== "call_centre" &&
    !tyreView;

  const isTabScrap =
    userdata?.role !== "superadmin" &&
    userdata?.role !== "admin" &&
    userdata?.role !== "call_centre" &&
    !scrapView;

  const isTabInspection =
    userdata?.role !== "superadmin" &&
    userdata?.role !== "admin" &&
    userdata?.role !== "call_centre" &&
    !inspectionView;

  const isCallCenter = userdata?.role === "call_centre";

  const isAudit = userdata?.role === "superadmin" && userdata?.role !== "admin";
  const isVehicle =
    userdata?.role !== "superadmin" && userdata?.role !== "admin";
  const isTrailer =
    userdata?.role !== "superadmin" && userdata?.role !== "admin";

  // const [supportsPWA, setSupportsPWA] = useState(false);
  // const [promptInstall, setPromptInstall] = useState(null);

  // useEffect(() => {
  //   const handler = (e) => {
  //     e.preventDefault();
  //     console.log("we are being triggered :D");
  //     setSupportsPWA(true);
  //     setPromptInstall(e);
  //   };

  //   window.addEventListener("beforeinstallprompt", handler);
  //   return () => window.removeEventListener("transitionend", handler);
  // }, []);

  useEffect(() => {
    if (location?.pathname === "/dashboard") {
      setValue("dashboard");
    } else if (location?.pathname === "/dashboard/Inspection") {
      setValue("Inspection");
    } else if (location?.pathname === "/dashboard/ScrapAnalysis") {
      setValue("Scrap");
    } else if (location?.pathname === "/dashboard/FleetManagement") {
      setValue("Fleet");
    } else if (location?.pathname === "/dashboard/notification") {
      setValue("Notification");
    } else if (location?.pathname === "/dashboard/ServiceCentre") {
      setValue("Service");
    } else if (location?.pathname === "/dashboard/TyreHistory") {
      setValue("Tyre");
    } else if (location?.pathname === "/dashboard/change-password") {
      setValue("ChangePassword");
    } else if (location?.pathname === "/dashboard/TyreInventory") {
      setValue("TyreInventory");
    } else if (location?.pathname === "/dashboard/VehicleMaster") {
      setValue("VehicleMaster");
    } else if (location?.pathname === "/dashboard/AuditLog") {
      setValue("AuditLog");
    } else if (location?.pathname === "/dashboard/VehicleMaster") {
      setValue("VehicleMaster");
    }
  }, [location]);

  useEffect(() => {
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Dashboard") {
            setDashboardView(v?.isActive);
          } else if (v?.label === "Fleet Management") {
            setFleetView(v?.isActive);
          } else if (v?.label === "Inspection Management") {
            setInspectionView(v?.isActive);
          } else if (v?.label === "Scrap Analysis") {
            setScrapView(v?.isActive);
          } else if (v?.label === "Service Centre") {
            setServiceView(v?.isActive);
          } else if (v?.label === "Tyre History") {
            setTyreView(v?.isActive);
          }
        });
      });
    }
  }, [permissionData]);

  async function handleSubmit() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.logout}?uuid=${useruuid}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      dispatch(setUserData(""));
      setTimeout(() => setConfirmDialog(false), 500);
      toast.success(response?.message);
      setRemoveBtnLoad(true);
      if (Ref.current) clearInterval(Ref.current);
    } catch (error) {
      setRemoveBtnLoad(true);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  // function onClick(evt) {
  //   evt.preventDefault();
  //   if (!promptInstall) {
  //     return;
  //   }
  //   promptInstall.prompt();
  // }
  // if (!supportsPWA) {
  //   return null;
  // }

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flex: 1,
          height: "100vh",
          backgroundColor: "#F3F0F0",
          flexDirection: isTablet ? "column" : "row",
        }}
      >
        {isTablet || isMobile ? (
          <>
            {!isKeyboardOpen && (
              <div
                style={{
                  backgroundColor: color.secondary,
                  left: 0,
                  right: 0,
                  top: 0,
                  zIndex: 10,
                  height: 60,
                  display: "flex",
                  position: "fixed",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                {isTablet ||
                value === "Notification" ||
                value === "dashboard" ||
                value === "ChangePassword" ? (
                  <Box
                    style={{
                      display: "flex",
                      flex: 1,
                      flexDirection: "row",
                      alignItems: "center",
                      height: isMobile ? 30 : 40,
                      marginLeft: 20,
                      marginBottom: 3,
                    }}
                  >
                    <img
                      src={Images.frame}
                      alt="frame"
                      style={{ height: "160%" }}
                    />
                  </Box>
                ) : (
                  ""
                )}
                <Box
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <text style={{ marginRight: 10, color: color.white }}>
                    {timer}
                  </text>
                  {isMobile && (
                    <>
                      <Tooltip title="Notifications" placement="bottom" arrow>
                        <IconButton
                          color="inherit"
                          aria-describedby={id}
                          onClick={(event) => {
                            // setAnchorEl(event.currentTarget);
                            navigate("/dashboard/notification");
                          }}
                        >
                          <Badge
                            color="primary"
                            badgeContent={permissionData?.unread_count}
                            invisible={permissionData?.unread_count == 0}
                          >
                            <img
                              src={Images.notification}
                              style={{ width: 16 }}
                              alt="notification"
                            />
                          </Badge>
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                  <Tooltip title="Profile" placement="bottom" arrow>
                    <IconButton
                      color="inherit"
                      style={{
                        marginLeft: 10,
                        marginRight: 10,
                      }}
                      aria-describedby={id2}
                      onClick={(event) => {
                        setAnchorEl2(event.currentTarget);
                      }}
                    >
                      <Badge color="secondary">
                        {!isEmpty(userdata?.user_photo) ? (
                          <Avatar
                            src={userdata?.user_photo}
                            style={{ width: 24, height: 24 }}
                          />
                        ) : (
                          <img
                            src={userdata?.user_photo || Images.profile}
                            style={{ width: 22 }}
                            alt="profile"
                          />
                        )}
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Popover
                    onClose={() => setAnchorEl2(null)}
                    anchorEl={anchorEl2}
                    id={id2}
                    open={pop2}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: isTablet ? "center" : "left",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ListItemButton>{`Hi, ${
                      userdata?.user_name || "-"
                    }`}</ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        setAnchorEl2(null);
                        navigate("/dashboard/change-password");
                      }}
                    >
                      Change Password
                    </ListItemButton>
                    <ListItemButton
                      onClick={() => {
                        setConfirmDialog(true);
                      }}
                    >
                      Log Out
                    </ListItemButton>
                  </Popover>
                </Box>
              </div>
            )}
            <Box
              sx={{
                flexGrow: 1,
                overflow: "auto",
                paddingTop: !isKeyboardOpen ? "60px" : "auto",
                paddingBottom: isIOS ? "100px" : "60px",
              }}
            >
              {!isOnline && (
                <div
                  style={{
                    backgroundColor: color.primary,
                    display: "flex",
                    flex: 1,
                    alignItems: "center",
                    justifyContent: "center",
                    paddingTop: 4,
                    paddingBottom: 4,
                  }}
                >
                  <Typography style={{ color: "#fff" }}>
                    No Internet Connection
                  </Typography>
                  <SentimentVeryDissatisfied
                    style={{ color: "#fff", marginLeft: 10 }}
                  />
                </div>
              )}
              <NavigationStack />
              <Grid
                item
                container
                justifyContent={"center"}
                alignItems="center"
                minHeight="60px"
                style={{ marginBottom: isIOS ? 70 : 0 }}
              >
                <Typography variant="caption">
                  Fleet Kaptan™ 2022. All Rights Reserved.
                </Typography>
              </Grid>
            </Box>
            {!isKeyboardOpen && (
              <BottomNavigation
                showLabels
                value={value}
                onChange={(event, newValue) => {
                  setValue(newValue);
                }}
                sx={{
                  position: "fixed",
                  bottom: 0,
                  justifyContent: "unset",
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                  overflowX: "scroll",
                  overflowY: "hidden",
                  height: "64px",
                  width: 1.0,
                  boxShadow: "0px 0px 24px 0px rgba(0,0,0,0.3)",
                  gap: 2,
                }}
              >
                {!isTabDashboard && !isCallCenter ? (
                  <BottomNavigationAction
                    // label={isTablet && "Dashboard"}
                    style={{ marginLeft: 8, padding: 0, minWidth: 40 }}
                    icon={<Home />}
                    value={"dashboard"}
                    onClick={() => {
                      if (location?.pathname === "/dashboard") {
                        window.location.reload(false);
                        window.history.replaceState({}, document.title);
                      } else {
                        navigate("/dashboard");
                      }
                    }}
                  />
                ) : null}
                {!isTabFleet && !isCallCenter ? (
                  <BottomNavigationAction
                    // label={isTablet && "My Fleet"}
                    style={{
                      padding: 0,
                      minWidth: 40,
                    }}
                    icon={<LocalShipping />}
                    value={"Fleet"}
                    onClick={() => {
                      if (location?.pathname === "/dashboard/FleetManagement") {
                        window.location.reload(false);
                        window.history.replaceState({}, document.title);
                      } else {
                        navigate("/dashboard/FleetManagement");
                      }
                    }}
                  />
                ) : null}
                {!isTabInspection && !isCallCenter ? (
                  <BottomNavigationAction
                    // label={isTablet && "Inspection"}
                    style={{
                      padding: 0,
                      minWidth: 40,
                    }}
                    icon={<ContentPasteSearch />}
                    value={"Inspection"}
                    onClick={() => {
                      if (location?.pathname === "/dashboard/Inspection") {
                        window.location.reload(false);
                        window.history.replaceState({}, document.title);
                      } else {
                        navigate("/dashboard/Inspection");
                      }
                    }}
                  />
                ) : null}
                {!isTabScrap && !isCallCenter ? (
                  <BottomNavigationAction
                    // label={isTablet && "Scrap Analysis"}
                    style={{ minWidth: 40, padding: 0 }}
                    icon={
                      location?.pathname === "/dashboard/ScrapAnalysis" ? (
                        <img
                          src={Images.scrapb}
                          style={{ width: isMobile && 26 }}
                        />
                      ) : (
                        <img
                          src={Images.scrapgray}
                          style={{ width: isMobile && 26 }}
                        />
                      )
                    }
                    value={"Scrap"}
                    onClick={() => {
                      if (location?.pathname === "/dashboard/ScrapAnalysis") {
                        window.location.reload(false);
                        window.history.replaceState({}, document.title);
                      } else {
                        navigate("/dashboard/ScrapAnalysis");
                      }
                    }}
                  />
                ) : null}
                {!isTabTyre && !isCallCenter ? (
                  <BottomNavigationAction
                    // label={isTablet && "Tyre History"}
                    icon={
                      location?.pathname === "/dashboard/TyreHistory" ? (
                        <img src={Images.thistoryb} style={{ width: 26 }} />
                      ) : (
                        <img src={Images.thistoryg} style={{ width: 26 }} />
                      )
                    }
                    style={{ minWidth: 40, padding: 0 }}
                    value={"Tyre"}
                    onClick={() => {
                      if (location?.pathname === "/dashboard/TyreHistory") {
                        window.location.reload(false);
                        window.history.replaceState({}, document.title);
                      } else {
                        navigate("/dashboard/TyreHistory");
                      }
                    }}
                  />
                ) : null}

                {/* vehicle master Tab and Mobile Navigation */}
                {!isVehicle && (
                  <BottomNavigationAction
                    style={{
                      marginRight: 10,
                      padding: 0,
                      minWidth: 25,
                    }}
                    icon={
                      location?.pathname === "/dashboard/VehicleMaster" ? (
                        <img src={Images.vmasterB} style={{ width: 30 }} />
                      ) : (
                        <img src={Images.vmasterGrey} style={{ width: 30 }} />
                      )
                    }
                    value={"TyreInventory"}
                    onClick={() => {
                      if (location?.pathname === "/dashboard/VehicleMaster") {
                        window.location.reload(false);
                        window.history.replaceState({}, document.title);
                      } else {
                        navigate("/dashboard/VehicleMaster");
                      }
                    }}
                  />
                )}

                {/* {!isTrailer && ( */}
                <BottomNavigationAction
                  style={{
                    marginRight: 10,
                    padding: 0,
                    minWidth: 25,
                  }}
                  icon={
                    location?.pathname === "/dashboard/TrailerMaster" ? (
                      <img src={Images.tmasterB} style={{ width: 30 }} />
                    ) : (
                      <img src={Images.tmasterGrey} style={{ width: 30 }} />
                    )
                  }
                  value={"TyreInventory"}
                  onClick={() => {
                    if (location?.pathname === "/dashboard/TrailerMaster") {
                      window.location.reload(false);
                      window.history.replaceState({}, document.title);
                    } else {
                      navigate("/dashboard/TrailerMaster");
                    }
                  }}
                />
                {/* )} */}

                <BottomNavigationAction
                  // label={isTablet && "My Fleet"}
                  style={{
                    marginRight: 10,
                    padding: 0,
                    minWidth: 40,
                  }}
                  icon={
                    location?.pathname === "/dashboard/TyreInventory" ? (
                      <img src={Images.inventoryb} style={{ width: 30 }} />
                    ) : (
                      <img src={Images.inventoryGrey} style={{ width: 30 }} />
                    )
                  }
                  value={"TyreInventory"}
                  onClick={() => {
                    if (location?.pathname === "/dashboard/TyreInventory") {
                      window.location.reload(false);
                      window.history.replaceState({}, document.title);
                    } else {
                      navigate("/dashboard/TyreInventory");
                    }
                  }}
                />
                {isAudit ? (
                  <BottomNavigationAction
                    // label={isTablet && "My Fleet"}
                    style={{
                      marginRight: 20,
                      padding: 0,
                      minWidth: 25,
                    }}
                    icon={
                      location?.pathname === "/dashboard/AuditLog" ? (
                        <img src={Images.auditB} style={{ width: 30 }} />
                      ) : (
                        <img src={Images.auditGrey} style={{ width: 30 }} />
                      )
                    }
                    value={"AuditLog"}
                    onClick={() => {
                      if (location?.pathname === "/dashboard/AuditLog") {
                        window.location.reload(false);
                        window.history.replaceState({}, document.title);
                      } else {
                        navigate("/dashboard/AuditLog");
                      }
                    }}
                  />
                ) : null}

                {!isTabService || isCallCenter ? (
                  <BottomNavigationAction
                    // label={isTablet && "Service Center"}
                    style={{
                      marginRight: 10,
                      minWidth: 40,
                      padding: 0,
                    }}
                    icon={
                      location?.pathname === "/dashboard/ServiceCentre" ? (
                        <img src={Images.serviceb} style={{ width: 30 }} />
                      ) : (
                        <img src={Images.serviceg} style={{ width: 30 }} />
                      )
                    }
                    value={"Service"}
                    onClick={() => {
                      if (location?.pathname === "/dashboard/ServiceCentre") {
                        window.location.reload(false);
                        window.history.replaceState({}, document.title);
                      } else {
                        navigate("/dashboard/ServiceCentre");
                      }
                    }}
                  />
                ) : null}
              </BottomNavigation>
            )}
          </>
        ) : (
          <>
            <AppBar position="absolute" open={open}>
              <Toolbar>
                <IconButton
                  edge="start"
                  color="inherit"
                  aria-label="open drawer"
                  onClick={toggleDrawer}
                  style={{ marginRight: 0, marginLeft: -8 }}
                  sx={{
                    marginRight: "36px",
                    ...(open && { display: "none" }),
                  }}
                >
                  <MenuIcon />
                </IconButton>
                <Box
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 16,
                    position: "relative",
                  }}
                >
                  <img src={Images.frame} alt="frame" width={85} />
                </Box>
                {!isOnline && (
                  <div
                    style={{
                      display: "flex",
                      flex: 1,
                      justifyContent: "center",
                    }}
                  >
                    <Typography style={{ color: "#fff" }}>
                      No Internet Connection
                    </Typography>
                    <SentimentVeryDissatisfied
                      style={{ color: "#fff", marginLeft: 10 }}
                    />
                  </div>
                )}
                <Box
                  style={{
                    display: "flex",
                    flex: 1,
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  <div style={{ marginRight: 20 }}>{timer}</div>
                  <Tooltip title="Notifications" placement="bottom" arrow>
                    <IconButton
                      color="inherit"
                      aria-describedby={id}
                      onClick={(event) => {
                        // setAnchorEl(event.currentTarget);
                        navigate("/dashboard/notification");
                      }}
                    >
                      <Badge
                        color="primary"
                        badgeContent={permissionData?.unread_count}
                        invisible={permissionData?.unread_count == 0}
                      >
                        <img
                          src={Images.notification}
                          style={{ width: 20 }}
                          alt="notification"
                        />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <div>
                    <Tooltip title="Profile" placement="bottom" arrow>
                      <IconButton
                        color="inherit"
                        style={{ marginLeft: 10 }}
                        aria-describedby={id2}
                        onClick={(event) => {
                          setAnchorEl2(event.currentTarget);
                        }}
                      >
                        <Badge color="secondary">
                          {!isEmpty(userdata?.user_photo) ? (
                            <Avatar
                              src={userdata?.user_photo}
                              style={{ width: 32, height: 32 }}
                            />
                          ) : (
                            <img
                              src={userdata?.user_photo || Images.profile}
                              style={{ width: 22 }}
                              alt="profile"
                            />
                          )}
                        </Badge>
                      </IconButton>
                    </Tooltip>
                    <Popover
                      onClose={() => setAnchorEl2(null)}
                      anchorEl={anchorEl2}
                      id={id2}
                      open={pop2}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: isTablet ? "center" : "left",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <ListItemButton>{`Hi, ${
                        userdata?.user_name || "-"
                      }`}</ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setAnchorEl2(null);
                          navigate("/dashboard/change-password");
                        }}
                      >
                        Change Password
                      </ListItemButton>
                      <ListItemButton
                        onClick={() => {
                          setConfirmDialog(true);
                        }}
                      >
                        Log Out
                      </ListItemButton>
                    </Popover>
                  </div>
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer
              onClose={toggleDrawer}
              open={open}
              variant={isTablet ? "temporary" : "permanent"}
            >
              <Toolbar
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flex: 1,
                    flexDirection: "row",
                    alignItems: "center",
                    paddingTop: 8,
                    paddingBottom: 8,
                    paddingLeft: 8,
                  }}
                >
                  <img src={Images.frame} alt="frame" width={85} />
                </Box>
                <IconButton onClick={toggleDrawer}>
                  <MenuIcon style={{ color: "white" }} />
                </IconButton>
              </Toolbar>
              <Divider />
              <List component="nav" className="memubarsubData">
                {Menubar_Arr.map((v, i) => {
                  const active = v.navigate === location?.pathname;
                  const isNotAccess =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    (v?.title === "User Management" ||
                      v?.title === "Item Master" ||
                      v?.title === "General Dropdowns");

                  const isCallCenter =
                    v?.title !== "Service Centre" &&
                    userdata?.role === "call_centre";

                  const isFleet =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    userdata?.role !== "call_centre" &&
                    v?.title === "Fleet Management" &&
                    !fleetView;

                  const isService =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    userdata?.role !== "call_centre" &&
                    v?.title === "Service Centre" &&
                    !ServiceView;

                  const isDashboard =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    userdata?.role !== "call_centre" &&
                    v?.title === "Dashboard" &&
                    !dashboardView;

                  const isTyre =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    userdata?.role !== "call_centre" &&
                    v?.title === "Tyre History" &&
                    !tyreView;

                  const isScrap =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    userdata?.role !== "call_centre" &&
                    v?.title === "Scrap Analysis" &&
                    !scrapView;

                  const isInspection =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    userdata?.role !== "call_centre" &&
                    v?.title === "Inspection Management" &&
                    !inspectionView;

                  const isAudit =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    v?.title === "Audit Log";

                  const isVehicle =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    v?.title === "Vehicle Master";

                  const isTrailer =
                    userdata?.role !== "superadmin" &&
                    userdata?.role !== "admin" &&
                    v?.title === "Trailer Master";
                  return (
                    <>
                      {isNotAccess ||
                      isFleet ||
                      isService ||
                      isDashboard ||
                      isScrap ||
                      isTyre ||
                      isInspection ||
                      isCallCenter ||
                      isAudit ||
                      isVehicle ? null : (
                        <Tooltip title={v.title} placement="right" arrow>
                          <ListItemButton
                            disabled={!isOnline}
                            style={{
                              flex: 1,
                              backgroundColor: active
                                ? "rgba(255, 255, 255, 0.2)"
                                : null,
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              padding: 10,
                            }}
                            key={`menu_${v.id}`}
                            onClick={() => {
                              if (v.id === 8) {
                                setDrawerOpen(!drawerOpen);
                                setOpen(true);
                              } else {
                                setSubActive("");
                                if (active) {
                                  window.history.replaceState(
                                    {},
                                    document.title
                                  );
                                  window.location.reload(false);
                                } else {
                                  navigate(v.navigate);
                                }
                              }
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flex: !open ? 1 : null,
                                width: 30,
                              }}
                            >
                              {v?.id === 3 ? (
                                <ContentPasteSearch
                                  style={{
                                    color: active ? "#d8d8d8" : "#a3a6a9",
                                  }}
                                />
                              ) : (
                                <img
                                  src={active ? v.activeIcon : v.icon}
                                  alt={v.title}
                                  style={{
                                    width:
                                      v.id === 4
                                        ? 35
                                        : v.id === 6
                                        ? 30
                                        : v.id === 8
                                        ? 18
                                        : v.id === 9
                                        ? 30
                                        : v.id === 10
                                        ? 30
                                        : v.id === 12
                                        ? 30
                                        : 24,
                                    // margin: v.id === 4 ? "0 -4px" : "",
                                  }}
                                />
                              )}
                            </div>
                            {open && (
                              <ListItemText
                                style={{
                                  color: "white",
                                  marginLeft: 10,
                                }}
                                primaryTypographyProps={{ fontSize: 14 }}
                                primary={v.title}
                              />
                            )}
                            {v.id === 8 && open ? (
                              drawerOpen ? (
                                <ExpandLess style={{ color: color.white }} />
                              ) : (
                                <ExpandMore style={{ color: color.white }} />
                              )
                            ) : null}
                          </ListItemButton>
                        </Tooltip>
                      )}
                      {v.id === 8 && open ? (
                        <Collapse in={drawerOpen} timeout="auto" unmountOnExit>
                          <List component="nav" style={{ padding: 0 }}>
                            {v?.subData
                              // .sort((a, b) => {
                              //   return a.name.localeCompare(b.name);
                              // })
                              .map((sub, index) => {
                                return (
                                  <Tooltip
                                    title={sub.name}
                                    placement="right"
                                    arrow
                                  >
                                    <ListItemButton
                                      style={{
                                        flex: 1,
                                        backgroundColor:
                                          subActive === index
                                            ? "rgba(255, 255, 255, 0.2)"
                                            : null,
                                      }}
                                      sx={{ pl: 8 }}
                                      onClick={() => {
                                        setSubActive(index);
                                        navigate(
                                          "/dashboard/GeneralDropdowns",
                                          {
                                            state: { data: sub },
                                          }
                                        );
                                      }}
                                    >
                                      {open && (
                                        <ListItemText
                                          style={{
                                            color: "white",
                                            fontWeight: "bold",
                                            marginLeft: 10,
                                            whiteSpace: "pre-line",
                                          }}
                                          primary={sub.name}
                                          primaryTypographyProps={{
                                            fontSize: 14,
                                          }}
                                        />
                                      )}
                                    </ListItemButton>
                                  </Tooltip>
                                );
                              })}
                          </List>
                        </Collapse>
                      ) : null}
                    </>
                  );
                })}
              </List>
            </Drawer>
            <div className={"mainContainer"}>
              <Toolbar />
              <NavigationStack />
              <Grid
                item
                container
                justifyContent={"center"}
                alignItems="center"
                minHeight="60px"
              >
                <Typography variant="caption">
                  Fleet Kaptan™ 2022. All Rights Reserved.
                </Typography>
              </Grid>
            </div>
          </>
        )}
      </Box>
      <ConfirmDialog
        title={`Are you sure you want to Log Out?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            setRemoveBtnLoad(true);
            handleSubmit();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
      <NotificationPopup />
    </>
  );
}

export default DrawerMenu;
