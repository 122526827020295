/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Grid,
} from "@mui/material";
import useStyles from "./styles.js";
import { Close } from "@mui/icons-material";
import { color } from "../../../Config/theme";
import { Setting } from "../../../Utils/Setting.js";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../../Utils/APIHelper.js";
import moment from "moment";

function MModificationRequest(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    data = {},
  } = props;
  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [input1, setInput1] = useState("");
  const [loader, setLoader] = useState(false);
  const [loader1, setLoader1] = useState(false);
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : 700,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    padding: 0,
  };

  async function request(status) {
    status === 1 ? setLoader(true) : setLoader1(true);
    let endPoints = Setting.endpoints.approvemodificationrequest;
    const params = {
      "ApproveRequestForm[status]": status,
      "ApproveRequestForm[request_remark]": input1.trim(),
      "ApproveRequestForm[id]": data?.id,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        console.log(resp);
        resetFormData();
        onSave();
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
      setLoader1(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
      setLoader1(false);
    }
  }

  // this function for reset form data
  function resetFormData() {
    setInput1("");
    if (handleModal) {
      handleModal();
    }
  }

  function getDate() {
    const timestamp = data.requested_at;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return displayDate;
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData()}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={styles.modalHeader}>
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              Modification Request
            </Typography>
            <Close
              style={{ color: color.white, cursor: "pointer" }}
              onClick={() => resetFormData()}
            />
          </div>
          <div className={styles.modalMain}>
            <Grid
              container
              style={{
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={3} lg={3}>
                <Typography>Requested By : </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Typography>{data?.requested_by || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={3} lg={3}>
                <Typography>Requested On : </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Typography>{getDate()}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginBottom: 10,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={3} lg={3}>
                <Typography>Change Requested : </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Typography>{data?.change_requested || "-"}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              style={{
                marginBottom: 20,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={3} lg={3}>
                <Typography>Remarks : </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder={`Enter Remarks`}
                  rows={3}
                  multiline
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                  }}
                  value={input1}
                  className={styles.input}
                  onChange={(e) => {
                    setInput1(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                disabled={loader}
                onClick={() => {
                  request(1);
                }}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  `Approve`
                )}
              </Button>
              <Button
                variant="contained"
                disabled={loader1}
                onClick={() => {
                  request(2);
                }}
                style={{ marginLeft: 20, backgroundColor: color.secondary }}
              >
                {loader1 ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  `Reject`
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MModificationRequest;
