import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { color } from "../../../Config/theme";

import {
  MoreVert,
  Search,
  SearchOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { isArray, isEmpty, isObject } from "lodash";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Images from "../../../Config/Images";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import { ResetIcon } from "../../Icon/TableToggle";
import moment from "moment";

export default function TabRotationHistory(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleSearch = () => null,
    search = "",
    pageNo = 1,
    record = 10,
  } = props;

  const styles = useStyles();

  const [pageLoad, setPageLoad] = useState(true);
  const [tyreData, setTyreData] = useState({});
  // const [searchFilter, setSearchFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);

  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;
  const { token, isOnline, userdata } = useSelector((state) => state.auth);

  useEffect(() => {
    getSwitchHistory(false);
  }, [record, pageNo]);

  useEffect(() => {
    isEmpty(search) && getSwitchHistory(false);
  }, [search]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [pageLoad, pageNo]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getSwitchHistory(bool, v) {
    v && setDownloadLoader(true);
    !v && setPageLoad(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyreHistoryList
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : search
        }&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadSwitchData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setTyreData(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Rotation History List");
      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function downloadSwitchData(data) {
    const newData = data.map((row, index) => {
      const date = row?.created_at;
      const displayDate = !isEmpty(date)
        ? moment(date).format("DD-MM-yyyy")
        : "-";
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "No. of Tyres": `${row?.added_tyre_count}/${row?.no_of_tyres}`,
        "No. of Rotations": Number(row?.rotation_count),
        "Last Rotation Date": displayDate || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Tyre Rotation History List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Rotation History List.xlsx");
  }

  function resetFilterData() {
    handleSearch("");
  }

  const getDate = (item) => {
    const date = item?.created_at;
    const displayDate = !isEmpty(date)
      ? moment(date).format("DD-MM-yyyy")
      : "-";
    return displayDate;
  };
  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Grid container wrap="nowrap">
            <Grid
              id="jump_to_me"
              item
              xs={12}
              sm={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="tableTitle">Rotation History</Typography>
            </Grid>
            <Grid
              item
              container
              sm={3}
              gap={1.2}
              wrap="nowrap"
              justifyContent={"flex-end"}
            >
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disabled={isOnline ? false : true}
                  onClick={() => getSwitchHistory(false, 1)}
                >
                  {downloadLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: color.white,
                    color: "#D8D8D8",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton selected>Rotation History</ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      handleClick("ConditionHistory");
                    }}
                  >
                    Condition History
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      handleClick("LifeHistory");
                    }}
                  >
                    Life History
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid
            container
            gap={1}
            style={{ marginTop: 10 }}
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item sm={10}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && getSwitchHistory(true);
                  handleSearch(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter")
                    if (!isEmpty(search)) {
                      handlePageNo(1);
                      getSwitchHistory(false);
                    }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Fleet Name Vehicle No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Button
                varient="contained"
                onClick={() => {
                  if (!isEmpty(search)) {
                    getSwitchHistory(false);
                  }
                }}
                style={{
                  backgroundColor: color.secondary,
                }}
              >
                <SearchOutlined />
              </Button>
            </Grid>

            <Grid item>
              <Button
                fullWidth
                variant="contained"
                onClick={resetFilterData}
                disabled={!search}
              >
                <ResetIcon />
              </Button>
            </Grid>

            <Grid item>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(tyreData?.data) && !isEmpty(tyreData?.data) ? (
            <>
              {tyreData?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    onClick={(v, data) => {
                      handleClick("RotationList", item);
                    }}
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      padding: 15,
                      marginTop: 15,
                      overflow: "auto",
                      boxShadow: color.shadow,
                    }}
                    alignItems="center"
                  >
                    <Grid item container>
                      <Table className={styles.customtable}>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Fleet Name</TableCell>
                            <TableCell variant="head">Vehicle No.</TableCell>
                            <TableCell variant="head">No. of Tyres</TableCell>
                            <TableCell variant="head">
                              No. of Rotations
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>{item?.fleet_name || "-"}</TableCell>
                          <TableCell>{item?.vehicle_no || "-"}</TableCell>
                          <TableCell>
                            {/* {item?.no_of_tyres || "-"} */}

                            <span
                              style={{
                                color: item?.added_tyre_count !== 0 && "red",
                              }}
                            >
                              {item?.added_tyre_count}
                            </span>
                            <span>/{item?.no_of_tyres}</span>
                          </TableCell>
                          <TableCell>{item?.rotation_count}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Divider
                              variant="fullWidth"
                              style={{
                                marginTop: 15,
                                marginBottom: 15,
                              }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">
                              Last Rotation Date
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>{getDate(item)}</TableCell>
                        </TableRow>
                      </Table>
                    </Grid>
                  </Grid>
                );
              })}
              <Box
                style={{
                  margin: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(tyreData?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <Grid container wrap="nowrap">
            <Grid
              item
              container
              display="flex"
              alignItems="center"
              id="jump_to_me"
              backgroundColor={color.secondary}
              padding={"10px"}
              color={color.white}
              borderRadius={1}
            >
              <Grid item>
                <Typography variant="tableTitle">Tyre History</Typography>
              </Grid>

              <Grid item marginLeft={"auto"}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#BBBBBB",
                    minWidth: "unset",
                    color: "#000",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVert />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      handleClick("LifeHistory");
                    }}
                  >
                    Life History
                  </ListItemButton>
                  <ListItemButton selected>Rotation History</ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      handleClick("ConditionHistory");
                    }}
                  >
                    Condition History
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid marginTop={"10px"}>
            <Typography variant="tableTitle">Rotation History</Typography>
          </Grid>
          <Grid
            item
            container
            marginTop={"10px"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                disabled={isOnline ? false : true}
                onClick={() => getSwitchHistory(false, 1)}
              >
                {downloadLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid
            container
            gap={1}
            wrap="nowrap"
            style={{ paddingTop: 10 }}
            alignItems="center"
          >
            <Grid item xs={11} alignItems="center">
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && getSwitchHistory(true);
                  handleSearch(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(search)) {
                      handlePageNo(1);
                      getSwitchHistory(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Fleet Name and Vehicle No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Button
                varient="contained"
                onClick={() => {
                  if (!isEmpty(search)) {
                    getSwitchHistory(false);
                  }
                }}
                style={{
                  backgroundColor: color.secondary,
                  width: "100%",
                }}
              >
                <SearchOutlined />
              </Button>
            </Grid>
            <Grid item>
              <Button
                fullWidth
                variant="contained"
                onClick={resetFilterData}
                disabled={!search}
              >
                <ResetIcon />
              </Button>
            </Grid>
          </Grid>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(tyreData?.data) && !isEmpty(tyreData?.data) ? (
            <>
              {tyreData?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    onClick={(v, data) => {
                      handleClick("RotationList", item);
                    }}
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      overflow: "hidden",
                      marginTop: 14,
                      boxShadow: color.shadow,
                    }}
                  >
                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Fleet Name
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Vehicle No.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>{item?.fleet_name}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item?.vehicle_no}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          background: "#EEF8FF",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            No. of Tyres
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            No. of Rotations
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                          background: "#EEF8FF",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>
                            <span
                              style={{
                                color: item?.added_tyre_count !== 0 && "red",
                              }}
                            >
                              {item?.added_tyre_count}
                            </span>
                            <span>/{item?.no_of_tyres}</span>
                          </Typography>
                        </Grid>
                        <Grid item xs={6} wrap="wrap">
                          <Typography>{item?.rotation_count}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Last Rotation Date
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>{getDate(item)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Box
                style={{
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(tyreData?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}
