import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    // flex: 1,
    // overflow: "auto",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    "@media (max-width:425px)": {
      padding: 10,
    },
  },
  "table, td, tr, th": {
    border: `1px solid ${color.bordercolor} !important`,
    padding: 10,
    backgroundColor: "red",
  },
  "th, td": {
    textAlign: "center",
  },
  MuiTableCell: {
    root: {
      // border: `1px solid ${color.bordercolor} !important`,
      // width:'25%'
      backgroundColor: "pink",
    },
    head: {
      fontWeight: "bold",
    },
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  breadcrumb: {
    padding: "15px 0px",
  },
  linkStyle: {
    color: color.primary,
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 450,
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
