import { makeStyles } from "@mui/styles";
import { isTablet } from "react-device-detect";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    padding: isTablet ? 20 : 15,
    overflow: "auto",
    border: `1px solid ${color.bordercolor}`,
    margin: isTablet ? "0 20px" : 10,
  },
}));

export default useStyles;
