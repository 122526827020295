import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    display: "flex",
    padding: "0 20px 20px 20px",
    boxSizing: "border-box",
    // overflow: "hidden !important",
    marginBottom: 20,
    flexDirection: "column",
    "@media (max-width:425px)": {
      padding: "0 10px 10px 10px",
    },
    // "@media (max-device-width:870px)": {
    //   marginBottom: "8vw",
    // },
    // "@media (max-device-width:425px)": {
    //   marginBottom: "8vh",
    // },
  },
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
  },
  breadcrumb: {
    height: 20,
    marginTop: 10,
    display: "flex",
    marginBottom: 10,
    alignItems: "center",
  },
  modalHeader: {
    backgroundColor: color.primary,
    padding: 10,
    display: "flex",
    justifyContent: "space-between",
  },
  modalMain: {
    padding: 100,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
  },
  linkStyle: {
    color: color.primary,
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  modalHeader1: {
    backgroundColor: color.primary,
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
}));
export default useStyles;
