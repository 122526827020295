import { ArrowBackOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import { isMobile, isTablet } from "react-device-detect";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import TabTyreDetails from "../../../Components/TabFleet/TabTyreDetails";
import TabConditionDetails from "../../../Components/TabTyreHistory/TabConditionDetails";
import TabConditionHistory from "../../../Components/TabTyreHistory/TabConditionHistory";
import TabLifeHistory from "../../../Components/TabTyreHistory/TabLifeHistory";
import TabRotationHistory from "../../../Components/TabTyreHistory/TabRotataionHistory";
import TabRotationList from "../../../Components/TabTyreHistory/TabRotationList";
import RemoveTyre from "../../../Components/TyreHistory/RemoveTyre";
import Images from "../../../Config/Images";
import { color } from "../../../Config/theme";
import useStyles from "./styles";

export default function TabTyreHistory() {
  const [changeTab, setChangeTab] = useState(
    isTablet ? "RotationHistory" : "LifeHistory"
  );
  const [changeTab1, setChangeTab1] = useState(true);
  const [editData, setEditData] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [from, setFrom] = useState("");
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [pageNoS, setPageNoS] = useState(1);
  const [recordS, setRecordS] = useState(10);

  const [pageNoC, setPageNoC] = useState(1);
  const [recordC, setRecordC] = useState(10);

  const [pageNoL, setPageNoL] = useState(1);
  const [recordL, setRecordL] = useState(10);

  const [filterLH, setFilterLH] = useState("");
  const [search, setSearch] = useState("");
  const [searchCH, setSearchCH] = useState("");
  const [searchLH, setSearchLH] = useState("");
  const [datePicker, setDatePicker] = useState(null);

  const styles = useStyles();

  useEffect(() => {
    setPageNoS(1);
    setRecordS(10);
    setPageNoC(1);
    setRecordC(10);
    setPageNoL(1);
    setRecordL(10);
  }, [changeTab1]);

  return (
    <div className={styles.card}>
      {isTablet ? null : !isKeyboardOpen &&
        (changeTab === "RotationHistory" ||
          changeTab === "ConditionHistory" ||
          changeTab === "LifeHistory") ? (
        <Box
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            height: isMobile ? 30 : 40,
            position: "fixed",
            top: 14,
            left: 20,
            zIndex: 10,
          }}
        >
          <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
        </Box>
      ) : null}
      {changeTab === "tyreDetails" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? {
                      backgroundColor: color.secondary,
                      marginBottom: 10,
                    }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                from === "RotationList" && setChangeTab("RotationList");
                from === "ConditionDetails" && setChangeTab("ConditionDetails");
                from === "ConditionHistory" && setChangeTab("ConditionHistory");
                from === "LifeHistory" && setChangeTab("LifeHistory");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "removeTyre" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? {
                      backgroundColor: color.secondary,
                      marginBottom: 10,
                    }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("LifeHistory");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "RotationHistory" ? (
        <TabRotationHistory
          pageNo={pageNoS}
          record={recordS}
          handlePageNo={(page) => {
            setPageNoS(page);
          }}
          handleRecord={(rec) => {
            setRecordS(rec);
          }}
          search={search}
          handleSearch={(search) => {
            setSearch(search);
          }}
          handleClick={(v, data) => {
            if (v === "ConditionHistory") {
              setChangeTab("ConditionHistory");
              setChangeTab1(!changeTab1);
            } else if (v === "LifeHistory") {
              setChangeTab("LifeHistory");
              setFilterLH("");
              setChangeTab1(!changeTab1);
            } else if (v === "RotationList") {
              setChangeTab("RotationList");
            }
            setEditData(data);
          }}
        />
      ) : changeTab === "ConditionHistory" ? (
        <TabConditionHistory
          pageNo={pageNoC}
          record={recordC}
          handlePageNo={(page) => {
            setPageNoC(page);
          }}
          handleRecord={(rec) => {
            setRecordC(rec);
          }}
          search={searchCH}
          dateF={datePicker}
          handleDate={(date) => {
            setDatePicker(date);
          }}
          handleSearch={(search) => {
            setSearchCH(search);
          }}
          handleClick={(v, data) => {
            if (v === "RotationHistory") {
              setChangeTab("RotationHistory");
              setChangeTab1(!changeTab1);
            }
            if (v === "LifeHistory") {
              setChangeTab("LifeHistory");
              setChangeTab1(!changeTab1);
              setFilterLH("");
            }
            if (v === "tyreDetails") {
              setFrom("ConditionHistory");
              setChangeTab("tyreDetails");
              setVehicleData(data);
            }
            if (v === "ConditionDetails") {
              setChangeTab("ConditionDetails");
            }
            setEditData(data);
          }}
        />
      ) : changeTab === "LifeHistory" ? (
        <TabLifeHistory
          pageNo={pageNoL}
          record={recordL}
          filter={filterLH}
          handlePageNo={(page) => {
            setPageNoL(page);
          }}
          handleRecord={(rec) => {
            setRecordL(rec);
          }}
          search={searchLH}
          handleSearch={(search) => {
            setSearchLH(search);
          }}
          handleFilter={(flt) => {
            setFilterLH(flt);
          }}
          handleClick={(v, data) => {
            if (v === "RotationHistory") {
              setChangeTab("RotationHistory");
              setChangeTab1(!changeTab1);
            } else if (v === "ConditionHistory") {
              setChangeTab("ConditionHistory");
              setChangeTab1(!changeTab1);
            } else if (v === "tyreDetails") {
              setFrom("LifeHistory");
              setChangeTab("tyreDetails");
              setVehicleData(data);
            } else if (v === "Edit") {
              setChangeTab("removeTyre");
              setVehicleData(data);
            }
          }}
        />
      ) : changeTab === "RotationList" ? (
        <TabRotationList
          handleClick={(v, data) => {
            if (v === "cancel") {
              setChangeTab("RotationHistory");
            } else if ("tyreDetails") {
              setFrom("RotationList");
              setChangeTab("tyreDetails");
              setVehicleData(data);
            }
          }}
          vehicleData={editData}
        />
      ) : changeTab === "ConditionDetails" ? (
        <TabConditionDetails
          handleClick={(v, data) => {
            if (v === "cancel") {
              setChangeTab("ConditionHistory");
            }
            if (v === "tyreDetails") {
              setFrom("ConditionDetails");
              setChangeTab("tyreDetails");
              setVehicleData(data);
            }
          }}
          vehicleData={editData}
        />
      ) : changeTab === "tyreDetails" ? (
        <div>
          <TabTyreDetails
            tyreData={vehicleData}
            from="life"
            handleClick={(v) => {
              if (v === "cancel") {
                setChangeTab("RotationList");
              }
            }}
          />
        </div>
      ) : changeTab === "removeTyre" ? (
        <RemoveTyre
          from={"life"}
          vehicleDetails={vehicleData}
          handleClick={(v, data) => {
            if (v === "cancel") {
              setChangeTab("LifeHistory");
            }
          }}
        />
      ) : null}
    </div>
  );
}
