import React, { useEffect, useState } from "react";
import { color } from "../../../Config/theme.js";
import {
  Grid,
  Box,
  Typography,
  TextField,
  Button,
  useMediaQuery,
  CircularProgress,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import Images from "../../../Config/Images.js";
import { getApiData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { toast } from "react-toastify";
import useStyles from "./styles.js";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

const errorObj = {
  vNumberErr: false,
  vNumberMsg: "",
};
export default function VarifyUser(props) {
  const { handleClick = () => null } = props;
  const { token, userdata } = useSelector((state) => state.auth);
  const styles = useStyles();

  const [errObj, setErrObj] = useState(errorObj);
  const [vNumber, setVNumber] = useState("");
  const vehicleNo_Regex = Setting.JS_Regex.vehicleNo;
  const [subText, setSubText] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [vBtn, setVBtn] = useState(false);
  const [ticketBtn, setTicketBtn] = useState(true);
  const [verifyBtnLoader, setVerifyBtnLoader] = useState(false);
  const [countDetails, setCountDetails] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getCounts();
  }, []);

  async function getCounts() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.servicecounter}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data)) {
          setCountDetails(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function validation() {
    const error = { ...errObj };
    let valid = true;
    if (isEmpty(vNumber)) {
      valid = false;
      error.vNumberErr = true;
      error.vNumberMsg = "Please enter Vehicle No. ";
    } else if (!vehicleNo_Regex.test(vNumber)) {
      valid = false;
      error.vNumberErr = true;
      error.vNumberMsg = "Please enter Valid Vehicle No. ";
    }
    setErrObj(error);
    if (valid) {
      verifyFunction();
    }
  }

  async function verifyFunction(bool) {
    setVerifyBtnLoader(true);
    setSubText("");
    try {
      const response = await getApiData(
        `${Setting.endpoints.verifycaller}?vehicle_no=${vNumber.trim()}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setSubText("verified");
        setVBtn(true);
        setTicketBtn(false);
        setVehicleDetails(response?.data);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
        setVBtn(false);
        setTicketBtn(true);
        const error = { ...errObj };
        error.vNumberErr = true;
        error.vNumberMsg = "Please enter Valid Vehicle No. ";
        setErrObj(error);
      }
      setVerifyBtnLoader(false);
    } catch (error) {
      setVBtn(false);
      setTicketBtn(true);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setVerifyBtnLoader(false);
    }
  }

  function clearData() {
    setVNumber("");
  }

  return (
    <>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : (
        <>
          <Grid
            sx={{
              padding: "20px 0",
              alignItems: "center",
              columnCount: 4,
              gap: 2,
            }}
          >
            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={(v, e) => handleClick(1, {}, "")}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card1}
                  alt={Images.card1}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: md ? "14%" : "12%",
                    left: md ? "12%" : "10%",
                  }}
                >
                  {countDetails?.ongoing_ticket || 0}
                </Typography>
              </Grid>
            </Box>

            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={(v, e) =>
                handleClick("needfollowup", {}, "needfollowup")
              }
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card2}
                  alt={Images.card2}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: md ? "14%" : "12%",
                    left: md ? "12%" : "10%",
                  }}
                >
                  {countDetails?.need_follow_up || 0}
                </Typography>
              </Grid>
            </Box>

            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={(v, e) => {
                if (userdata?.role !== "call_centre") {
                  handleClick("OutstandingPayments", {}, "");
                }
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card3}
                  alt={Images.card3}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: md ? "14%" : "12%",
                    left: md ? "12%" : "10%",
                  }}
                >
                  {countDetails?.outstanding_payment || 0}
                </Typography>
              </Grid>
            </Box>

            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={(v, e) => handleClick(2, {}, "")}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card4}
                  alt={Images.card4}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: md ? "14%" : "12%",
                    left: md ? "12%" : "10%",
                  }}
                >
                  {countDetails?.resolved_ticket || 0}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          {userdata?.role !== "service_enginner" && (
            <Grid
              container
              lg={12}
              style={{ marginTop: 20 }}
              alignItems="center"
            >
              <Grid lg={1} sm={1.5} xs={12} style={{ marginTop: 5 }}>
                <Typography style={{ fontSize: 14 }}>
                  <b>Verify Caller : </b>
                </Typography>
              </Grid>

              <Grid lg={11} sm={10.5} xs={12}>
                <TextField
                  placeholder="Enter the Vehicle No."
                  fullWidth
                  value={vNumber}
                  error={errObj.vNumberErr}
                  helperText={errObj.vNumberErr ? errObj.vNumberMsg : null}
                  onChange={(e) => {
                    setSubText("");
                    setVNumber(e.target.value);
                    setErrObj({
                      ...errObj,
                      vNumberErr: false,
                      vNumberMsg: "",
                    });
                    if (vBtn && isEmpty(e.target.value)) {
                      setVBtn(false);
                    }
                  }}
                />
              </Grid>
            </Grid>
          )}
          {userdata?.role !== "service_enginner" &&
            (subText === "verified" ? (
              <Grid
                container
                lg={12}
                alignItems="center"
                style={{ marginTop: errObj.vNumberErr ? 20 : 10 }}
              >
                <Grid lg={1} sm={1.5} xs={12}></Grid>
                <Grid
                  lg={11}
                  sm={10.5}
                  xs={12}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                  // style={{
                  //   fontSize: 12,
                  // }}
                  >
                    Vehicle No.: {vNumber} Successfully Verified
                  </Typography>
                  <CheckCircleOutlineIcon
                    style={{ fontSize: 18, color: "#6FCF97", marginLeft: 2 }}
                  />
                </Grid>
              </Grid>
            ) : (
              <Grid
                container
                lg={12}
                alignItems="center"
                style={{ marginTop: errObj.vNumberErr ? 20 : 10 }}
              >
                <Grid lg={1} sm={1.5} xs={12}></Grid>
                <Grid lg={11} sm={10.5} xs={12}>
                  <Typography>
                    <b>Note : </b>Vehicle No. format is XX 00 X/XX/XXX 0000.
                    E.g. GJ 07 DC 1111
                  </Typography>
                </Grid>
              </Grid>
            ))}
          {userdata?.role !== "service_enginner" && (
            <Grid
              container
              lg={12}
              alignItems="center"
              style={{ marginTop: 20 }}
            >
              <Grid lg={1} sm={1.5}></Grid>
              <Grid lg={11} sm={10.5}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: vBtn ? "" : color.secondary,
                    marginRight: 20,
                  }}
                  onClick={validation}
                  disabled={vBtn}
                >
                  {verifyBtnLoader ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    " Verify"
                  )}
                </Button>
                <Button
                  disabled={ticketBtn}
                  variant="contained"
                  onClick={() => handleClick("generateTicket", vehicleDetails)}
                >
                  Generate Ticket
                </Button>
              </Grid>
            </Grid>
          )}
        </>
      )}
    </>
  );
}
