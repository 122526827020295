import React, { useEffect, useState } from "react";
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Pagination,
  CircularProgress,
  ListItemButton,
  Tooltip,
} from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { FilterAlt, Search } from "@mui/icons-material";
import useStyles from "./styles";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InfoIcon from "@mui/icons-material/Info";

export default function OngoingTickets(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    onGoing,
    from,
  } = props;
  const [ticketListRows, setTicketListRows] = useState({});
  const [pageLoad, setPageLoad] = useState(true);
  const styles = useStyles();

  // const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [paymentList, setPaymentList] = useState(false);
  const [issueList, setIssueList] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [reportLoader, setReportLoader] = useState(false);

  const { token, userdata } = useSelector((state) => state.auth);

  const paymentArr = [
    { label: "Bill Pending" },
    { label: "Payment Pending" },
    { label: "Payment Completed" },
  ];

  const issueArr = [
    { label: "Cut", value: "Cut" },
    { label: "Puncture", value: "Puncture" },
    { label: "Misalignment", value: "Misallignment" },
  ];
  const column = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    // {
    //   field: "action.",
    //   headerName: "Action",
    //   sortable: false,
    //   width: 70,
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip
    //         title={onGoing ? "Update Ticket" : "Resolved Ticket"}
    //         placement="right"
    //         arrow
    //       >
    //         <Edit
    //           style={{ margin: "auto", color: color.primary }}
    //           color={color.primary}
    //           onClick={() => {
    //             onGoing
    //               ? handleClick("generateTicket", params?.row, true)
    //               : handleClick("ticketId", params?.row);
    //           }}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "id",
      headerName: "Ticket ID",
      width: 180,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 240,
    },
    {
      field: "issue",
      headerName: "Issue",
      width: 360,
      renderCell: (params) => {
        let iss = params?.row?.issue;
        return !isEmpty(iss) ? (
          <Typography>
            {iss === "cut"
              ? "Cut"
              : iss === "misallignment"
              ? "Misallignment"
              : "Puncture"}
          </Typography>
        ) : (
          <Typography variant="tableHead">-</Typography>
        );
      },
    },
    {
      field: "payment_status",
      headerName: "Payment Status",
      width: 360,
    },
    {
      field: "created_at",
      headerName: "Added On",
      width: 360,
      renderCell: (params) => {
        const timestamp = params?.row?.created_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.created_at ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      width: 360,
    },
    {
      field: "resolved on",
      headerName: "Resolved On",
      width: 360,
      hide: onGoing ? true : false,
      renderCell: (params) => {
        const timestamp = params?.row?.resolved_on;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
              cursor: "pointer",
            }}
          >
            {params?.row?.resolved_on ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "resolved_by",
      headerName: "Resolved By",
      width: 360,
      hide: onGoing ? true : false,
    },
  ];

  useEffect(() => {
    getTicketList(false);
  }, [onGoing, record, pageNo, filter]);

  // useEffect(() => {
  //   setPageNo(1);
  // }, [record, onGoing]);

  // useEffect(() => {
  //   resetFilterData();
  // }, [onGoing]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getTicketList(false);
  }, [datePicker, searchFilter]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Service Center`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getTicketList(bool, v) {
    v && setReportLoader(true);
    !v && setPageLoad(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${Setting.endpoints.ticketlist}?status=${
          onGoing ? 0 : 1
        }&per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&filter=${filter}&date=${date}&payment_status=${
          onGoing && from === "needfollowup" ? "Bill Pending" : ""
        }&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data?.rows) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadReport(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setTicketListRows(listObj);
          }
        else {
          toast.error(response?.message || "No Data Found");
          setTicketListRows([]);
        }
        v &&
          auditSave(
            onGoing
              ? from === "needfollowup"
                ? "Need Follow-up List"
                : "Ongoing Tickets"
              : "Resolved Tickets"
          );
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
    setReportLoader(false);
  }

  function downloadReport(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.created_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const timestamp1 = row?.created_at;
      const date1 = new Date(timestamp1 * 1000);
      const displayDate1 = moment(date1).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Ticket ID": row?.id || "-",
        "Vehicle No.": row?.vehicle_no || "-",
        Issue: row?.issue || "-",
        "Payment Status": row?.payment_status || "-",
        "Added On": timestamp ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };

      const newObj1 = {
        "Sr. No.": index + 1,
        "Ticket ID": row?.id,
        "Vehicle No.": row?.vehicle_no,
        Issue: row?.issue || "-",
        "Payment Status": row?.payment_status || "-",
        "Added On": timestamp ? displayDate : "=",
        "Added By": row?.added_by || "-",
        "Resolved On": timestamp1 ? displayDate1 : "-",
        "Resolved By": row?.resolved_by || "-",
      };
      return onGoing ? newObj : newObj1;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      onGoing ? "Ongoing Tickets" : "Resolved Tickets"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(
      workBook,
      onGoing
        ? from === "needfollowup"
          ? "Need Follow-up List.xlsx"
          : "Ongoing Ticket List.xlsx"
        : "Resolved Ticket List.xlsx"
    );
  }

  function resetFilterData() {
    handleFilter("");
    setSearchFilter("");
    setDatePicker(null);
    handlePageNo(1);
  }

  return (
    <>
      <div style={{ paddingTop: 15 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item lg={8} style={{ display: "flex", alignItems: "center" }}>
            <Grid item lg={4}>
              <Select
                fullWidth
                placeholder="Filter"
                value={filter}
                IconComponent={FilterAlt}
                displayEmpty
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    setPaymentList(false);
                    setIssueList(false);
                  }
                }}
                style={{
                  color: filter === "" ? "#A2A2A2" : "",
                }}
              >
                <MenuItem value="" selected hidden disabled>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    setPaymentList(!paymentList);
                    setIssueList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Payment Status</Typography>
                  {paymentList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {paymentArr.map((v, i) => {
                  return (
                    <MenuItem
                      style={{
                        display: paymentList ? "flex" : "none",
                      }}
                      key={i}
                      value={v?.label}
                      disabled={from === "needfollowup" && i > 0}
                    >
                      {v?.label}
                    </MenuItem>
                  );
                })}
                <ListItemButton
                  onClick={() => {
                    setIssueList(!issueList);
                    setPaymentList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Issue</Typography>
                  {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {issueArr.map((v, i) => {
                  return (
                    <MenuItem
                      style={{
                        display: issueList ? "flex" : "none",
                      }}
                      key={i}
                      value={v?.value}
                    >
                      {v?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
          </Grid>

          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => getTicketList(false, 1)}
            >
              {reportLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                "Download"
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap={"nowrap"}
          style={{ marginTop: 10 }}
        >
          <Grid
            item
            lg={8}
            style={{ display: "flex", alignItems: "center" }}
            wrap={"nowrap"}
          >
            <Grid
              item
              lg={4}
              style={{ display: "flex", alignItems: "center" }}
              wrap={"nowrap"}
            >
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value) && getTicketList(true);
                  setSearchFilter(v?.target?.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Vehicle No. and Added By"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      handlePageNo(1);
                      getTicketList(false);
                    }
                  }
                }}
              />
            </Grid>
            <div style={{ marginLeft: 20 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  views={["year", "month", "day"]}
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    handlePageNo(1);
                    getTicketList(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={
                  filter === "" && isEmpty(searchFilter) && isNull(datePicker)
                    ? true
                    : false
                }
              >
                Reset
              </Button>
            </div>
          </Grid>
          <Grid
            item
            lg={1.6}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                handlePageNo(1);
                handleRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(ticketListRows?.data) && !isEmpty(ticketListRows?.data) ? (
          <>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                pageSize={record}
                rows={ticketListRows?.data}
                columns={column}
                disableColumnMenu
                hideFooter
                showCellRightBorder
                disableSelectionOnClick
                showColumnRightBorder
                autoHeight={true}
                onCellClick={(params) => {
                  userdata?.role !== "service_enginner" &&
                    (onGoing
                      ? handleClick("generateTicket", params?.row, true)
                      : handleClick("ticketId", params?.row));
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(ticketListRows?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={1}
                siblingCount={3}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  handlePageNo(e);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
    </>
  );
}
