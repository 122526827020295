import * as React from "react";
import Button from "@mui/material/Button";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Image from "../../../Config/Images";
import "./styles.css";
import { useState } from "react";
import { isEmpty, isNull } from "lodash";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
// import tyre from "../../../Assets/Lottie/tyre.json";

import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useLocation, useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

const errorObj = {
  passErr: false,
  passMsg: "",
  confpassErr: false,
  confpassMsg: "",
};

export default function PasswordRecovery() {
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [loader, setLoader] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword1, setShowPassword1] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const userid = !isNull(location?.state) && location?.state?.userid;

  useEffect(() => {
    document.title = Setting.page_name.Login;
  }, []);

  const handleSubmit = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.resetpassword;
    const params = {
      "ResetPasswordForm[password]": confPass,
      "ResetPasswordForm[user_id]": userid,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.status) {
        toast.success(resp?.message);
        navigate("/");
        clearData();
      } else {
        setNewPass("");
        setConfPass("");
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  const checkValidation = () => {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(newPass)) {
      valid = false;
      error.passErr = true;
      error.passMsg = "Please enter New Password";
    } else if (newPass.length < 6 || newPass.length > 15) {
      valid = false;
      error.passErr = true;
      error.passMsg = "The password length must Between 6 to 15 Character";
    }

    if (isEmpty(confPass)) {
      valid = false;
      error.confpassErr = true;
      error.confpassMsg = "Please confirm New Password";
    } else if (confPass.length < 6 || confPass.length > 15) {
      valid = false;
      error.confpassErr = true;
      error.confpassMsg = "The password length must Between 6 to 15 Character";
    } else if (confPass !== newPass) {
      valid = false;
      error.confpassErr = true;
      error.confpassMsg =
        "New Password and Confirm Password should be the same. Please try again.";
    }
    setErrObj(error);

    if (valid) {
      setErrObj(errorObj);
      handleSubmit();
    }
  };

  const clearData = () => {
    setNewPass("");
    setConfPass("");
  };

  return isMobile || isTablet ? (
    <Box
      component={"main"}
      style={{
        margin: 0,
        overflow: "hidden",
        height: "100vh",
        background: !isMobile
          ? `linear-gradient(to right,  #4573B8 40%, white 10%,white 50%,white 50%,white 75%,white 75%)`
          : `linear-gradient(to bottom,  #4573B8 40%, white 10%,white 50%,white 50%,white 75%,white 75%)`,
        display: "flex",
        alignItems: isMobile ? "unset" : "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "unset",
        }}
      >
        <div
          style={{
            width: isMobile ? "90%" : "40%",
            border: "none",
            boxShadow: isMobile
              ? "none"
              : "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
            padding: 20,
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={Image.text}
            style={{
              objectFit: "contain",
              maxWidth: isMobile ? "50%" : "80%",
              alignSelf: "center",
              height: "auto",
            }}
            alt="Go the extra mile"
          />

          {isMobile ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                width: "100%",
              }}
            >
              <img
                src={Image.loginImg}
                style={{
                  objectFit: "contain",
                  width: "80%",
                  height: "auto",
                  bottom: 0,
                  left: 0,
                }}
                alt="background_image"
              />
            </div>
          )}
        </div>
        <div
          variant="outlined"
          style={{
            width: isMobile ? "90%" : "60%",
            border: "none",
            boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Grid
            item
            style={{
              width: "100%",
              padding: 20,
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              container
              style={{
                marginBottom: 0,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={Image.frame1}
                height={60}
                alt="logo"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://fleetkaptan.com/")}
              />
              {/* <Typography
                style={{ width: "unset", fontWeight: "bold", fontSize: 20 }}
              >
                Fleet Kaptan
              </Typography> */}
            </Grid>
            <Typography
              style={{
                fontWeight: 700,
                fontSize: "20px",
                textAlign: "center",
                marginTop: 10,
              }}
            >
              Password Recovery
            </Typography>
            <div className="txtInp">
              <Typography
                style={{
                  color: "#4573B8",
                  fontWeight: "bold",
                  marginBottom: 8,
                }}
              >
                Enter New Password
              </Typography>
              <FormControl
                fullWidth
                error={errObj.passErr}
                style={{ marginBottom: errObj.passErr ? 30 : 20 }}
              >
                <OutlinedInput
                  value={newPass}
                  margin="normal"
                  required
                  type={showPassword ? "text" : "password"}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                  error={errObj.passErr}
                  helperText={errObj.passErr ? errObj.passMsg : null}
                  name="newPass"
                  placeholder="Please enter New Password"
                  id="newPass"
                  onChange={(e) => {
                    setNewPass(e.target.value);
                    setErrObj({ ...errObj, passErr: false, passMsg: "" });
                  }}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                    maxLength: 15,
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      document.querySelector("#password").focus();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confPass visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errObj.passErr ? (
                  <FormHelperText>{errObj.passMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
              <Typography
                style={{
                  color: "#4573B8",
                  fontWeight: "bold",
                  marginBottom: 8,
                }}
              >
                Confirm New Password
              </Typography>
              <FormControl
                fullWidth
                error={errObj.confpassErr}
                style={{
                  marginBottom: errObj.confpassErr ? 30 : 20,
                }}
              >
                <OutlinedInput
                  value={confPass}
                  margin="normal"
                  required
                  type={showPassword1 ? "text" : "password"}
                  style={{
                    width: "100%",
                    marginBottom: !isTablet && errObj.confpassMsg && 25,
                  }}
                  error={errObj.confpassErr}
                  helperText={errObj.confpassErr ? errObj.confpassMsg : null}
                  name="confPass"
                  placeholder="Please confirm New Password"
                  id="confPass"
                  onChange={(e) => {
                    setConfPass(e.target.value);
                    setErrObj({
                      ...errObj,
                      confpassErr: false,
                      confpassMsg: "",
                    });
                  }}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                    maxLength: 15,
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      checkValidation();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle confPass visibility"
                        onClick={() => setShowPassword1(!showPassword1)}
                      >
                        {!showPassword1 ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errObj.confpassErr ? (
                  <FormHelperText>{errObj.confpassMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </div>
            <Grid item container style={{ marginTop: 20 }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#4573B8",
                  fontWeight: "bold",
                }}
                onClick={checkValidation}
                justifyContent={"center"}
                variant="contained"
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={26} />
                ) : (
                  "Change Password"
                )}
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  ) : (
    <>
      <Grid
        container
        style={{
          flex: 1,
          overflow: "hidden",
          height: "100vh",
        }}
      >
        {isMobile ? null : (
          <Grid
            item
            container
            lg={4}
            md={4}
            style={{
              backgroundColor: "#4573B8",
              overflow: "hidden",
            }}
            alignItems="center"
            justifyContent="flex-end"
          >
            <div
              style={{
                width: "80%",
                maxWidth: 350,
                height: 600,
                backgroundColor: "#4573B8",
                border: "none",
                boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
                padding: 20,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: "1 1",
                }}
              >
                <img
                  src={Image.text}
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  alt="Go the extra mile"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "1 1",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <img
                  className="mecimg"
                  src={Image.loginImg}
                  style={{
                    objectFit: "contain",
                    position: "absolute",
                    width: "90%",
                    height: "auto",
                    bottom: -80,
                    left: -80,
                  }}
                  alt="background_image"
                />
              </div>
            </div>
          </Grid>
        )}
        <Grid
          item
          container
          lg={8}
          md={8}
          sm={12}
          style={{
            overflow: "hidden",
          }}
          justifyContent={isMobile ? "center" : "flex-start"}
          alignItems="center"
        >
          <Paper
            variant="outlined"
            style={{
              width: "80%",
              height: 600,
              border: "none",
              boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item style={{ width: isMobile ? "90%" : "50%" }}>
              <Grid
                item
                container
                style={{
                  marginBottom: 20,
                }}
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={Image.frame1}
                  height={150}
                  alt="logo"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open("https://fleetkaptan.com/")}
                />
                {/* <Typography
                  style={{ width: "unset", fontWeight: "bold", fontSize: 20 }}
                >
                  Fleet Kaptan
                </Typography> */}
              </Grid>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 700,
                  // fontSize: "40px",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                Password Recovery
              </Typography>
              <div className="txtInp">
                <Typography
                  style={{
                    color: "#4573B8",
                    fontWeight: "bold",
                    marginBottom: 8,
                  }}
                >
                  Enter New Password
                </Typography>
                <FormControl
                  fullWidth
                  error={errObj.passErr}
                  style={{ marginBottom: errObj.passErr ? 20 : 10 }}
                >
                  <OutlinedInput
                    value={newPass}
                    margin="normal"
                    required
                    type={showPassword ? "text" : "password"}
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                    error={errObj.passErr}
                    helperText={errObj.passErr ? errObj.passMsg : null}
                    name="newPass"
                    placeholder="Please enter New Password"
                    id="newPass"
                    onChange={(e) => {
                      setNewPass(e.target.value);
                      setErrObj({ ...errObj, passErr: false, passMsg: "" });
                    }}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                      maxLength: 15,
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        document.querySelector("#confPass").focus();
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confPass visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errObj.passErr ? (
                    <FormHelperText>{errObj.passMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
                <Typography
                  style={{
                    color: "#4573B8",
                    fontWeight: "bold",
                    marginBottom: 8,
                  }}
                >
                  Confirm New Password
                </Typography>
                <FormControl
                  fullWidth
                  error={errObj.confpassErr}
                  style={{ marginBottom: errObj.confpassErr ? 20 : 10 }}
                >
                  <OutlinedInput
                    value={confPass}
                    margin="normal"
                    required
                    type={showPassword1 ? "text" : "password"}
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                    error={errObj.confpassErr}
                    helperText={errObj.confpassErr ? errObj.confpassMsg : null}
                    name="confPass"
                    placeholder="Please confirm New Password"
                    id="confPass"
                    onChange={(e) => {
                      setConfPass(e.target.value);
                      setErrObj({
                        ...errObj,
                        confpassErr: false,
                        confpassMsg: "",
                      });
                    }}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                      maxLength: 15,
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        checkValidation();
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle confPass visibility"
                          onClick={() => setShowPassword1(!showPassword1)}
                        >
                          {!showPassword1 ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errObj.confpassErr ? (
                    <FormHelperText>{errObj.confpassMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
              <Grid item container style={{ marginTop: 20 }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#4573B8",
                    fontWeight: "bold",
                  }}
                  onClick={checkValidation}
                  justifyContent={"center"}
                  variant="contained"
                  disabled={loader}
                >
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={26} />
                  ) : (
                    "Change Password"
                  )}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
