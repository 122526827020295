import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
  },
  "table, td, tr, th": {
    border: `1px solid ${color.bordercolor} !important`,
    padding: 10,
    backgroundColor: "red",
  },
  "th, td": {
    textAlign: "center",
  },

  MuiTableCell: {
    root: {
      // border: `1px solid ${color.bordercolor} !important`,
      // width:'25%'
      backgroundColor: "pink",
    },
    head: {
      fontWeight: "bold",
    },
  },
  customtable: {
    "& tbody": {
      "&:nth-child(even)": {
        backgroundColor: "#F3F0F0",
      },
    },
    "& tr td,tr th": {
      border: "none !important",
      width: "20%",
      background: "transparent !important",
    },
  },
  customtableMobile: {
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: "#FBF8F8",
      },
    },
  },
  customtableTyreMileage: {
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: "#EEF8FF",
      },
    },
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  breadcrumb: {
    padding: "15px 0px",
  },
  linkStyle: {
    color: color.primary,
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 450,
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
  scrollBtn: {
    position: "absolute",
    padding: 5,
    bottom: 0,
    background:
      "linear-gradient(180deg, rgba(217, 248, 255, 0) 0%, rgba(54, 65, 80, 0.5) 100%)",
  },
}));
export default useStyles;
