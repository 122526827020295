import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  TableRow,
  TableCell,
  Table,
  CircularProgress,
  MenuItem,
  Select,
  Grid,
  Button,
  InputAdornment,
  TextField,
  Tooltip,
  TableHead,
  Divider,
  Pagination,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { ArrowBackOutlined } from "@mui/icons-material";
import { FilterAlt, Search, SearchOutlined } from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import Images from "../../../Config/Images";
import * as XLSX from "xlsx/xlsx.mjs";
import InfoIcon from "@mui/icons-material/Info";
import { isTablet } from "react-device-detect";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import { ResetIcon } from "../../Icon/TableToggle/index.js";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function TabRotationList(props) {
  const { handleClick = () => null, vehicleData } = props;
  const styles = useStyles();
  const { token, dropDownData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [datePicker, setDatePicker] = useState(null);
  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [switchList, setSwitchList] = useState({});
  const [vehicleDetail, setVehicleDetail] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [reportLoader, setReportLoader] = useState(false);

  useEffect(() => {
    getSwitchList(false);
  }, [filter, pageNo, record]);

  useEffect(() => {
    getVehicleDetails();
  }, []);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getSwitchList(false);
  }, [datePicker, searchFilter]);

  useEffect(() => {
    setPageNo(1);
  }, [record]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [pageNo, pageLoad]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(null);
  }, [pageNo, record]);

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${vehicleData.vehicle_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getSwitchList(bool, v) {
    setActiveIndex(null);
    !v && setPageLoad(true);
    v && setReportLoader(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreHistoryDetails}?vehicle_id=${
          vehicleData?.vehicle_id
        }&per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&filter=${filter}&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadRDetails(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setSwitchList(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Rotation Details");
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      setReportLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }
  function findTerrain() {
    let array = vehicleDetail?.terrain_ids?.split(",");
    let res = dropDownData?.terrain?.filter((item) => {
      let a = array?.includes(item?.id?.toString());
      return a;
    });
    return res.map((v, i) => (
      <>
        {v?.label}
        {i < res.length - 1 ? ", " : ""}
      </>
    ));
  }

  const created = (index) => {
    const timestamp = switchList?.data[index]?.created_at;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const condition = (index) => {
    return (
      <div
        style={{
          backgroundColor:
            switchList?.data[index]?.tyre_status === "Average"
              ? "#FFFDD9"
              : switchList?.data[index]?.tyre_status === "Good"
              ? "#D9FFE4"
              : "#FFD9D9",
          padding: 8,
          borderRadius: 4,
          marginRight: 10,
        }}
      >
        <Typography>{switchList?.data[index]?.tyre_status || "-"}</Typography>
      </div>
    );
  };

  function nsd(data, index) {
    const nsdArr = data[index]?.current_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return `${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
      groove2 !== 0 ? groove2.toFixed(2) : " - "
    }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
      groove4 !== 0 ? groove4.toFixed(2) : " - "
    } mm`;
  }

  function downloadRDetails(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.created_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const nsdArr = row?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": vehicleData?.fleet_name,
        "Vehicle No.": vehicleData?.vehicle_name,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Old Tyre Position": row?.old_position || "-",
        "New Tyre Position": row?.new_position || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.item_description || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(row?.avg_nsd) || "-",
        "Odometer Reading (Km)": Number(
          row?.mileage?.toString()?.replaceAll(",", "")
        ),
        "Tyre Condition": row?.tyre_status || "-",
        "Rotation Date": displayDate || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Rotation Details");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Rotation Details.xlsx");
  }

  function resetFilterData() {
    setSearchFilter("");
    setDatePicker(null);
    setFilter("");
    getSwitchList(true);
  }

  return (
    <>
      {isTablet ? (
        <div>
          <Box id="jump_to_me">
            <Button
              style={{ backgroundColor: color.secondary, marginBottom: "10px" }}
              onClick={() => {
                handleClick("cancel");
              }}
            >
              Back
            </Button>
          </Box>
          <div className={styles.container}>
            <Box
              style={{
                backgroundColor: color.primary,
                color: color.white,
                padding: "18px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography fontWeight={"bold"}>
                {vehicleData?.fleet_name} / {vehicleData?.vehicle_name}
              </Typography>
            </Box>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : (
              <div style={{ padding: 20 }}>
                <Table>
                  <TableRow>
                    <TableCell variant="head">Vehicle No.</TableCell>
                    <TableCell>{vehicleDetail?.vehicle_no || "-"}</TableCell>
                    <TableCell variant="head">Route Origin</TableCell>
                    <TableCell>{vehicleDetail?.route_origin || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Type</TableCell>
                    <TableCell>
                      {vehicleDetail?.vehicle_type_id || "-"}
                    </TableCell>
                    <TableCell variant="head">Route Destination</TableCell>
                    <TableCell>
                      {vehicleDetail?.route_destination || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Brand</TableCell>
                    <TableCell>
                      {vehicleDetail?.vehicle_brand_id || "-"}
                    </TableCell>
                    <TableCell variant="head">No. of Tyres</TableCell>
                    <TableCell>{vehicleDetail?.no_of_tyres || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Model</TableCell>
                    <TableCell>{vehicleDetail?.vehicle_model || "-"}</TableCell>
                    <TableCell variant="head">Rotations (Month)</TableCell>
                    <TableCell>
                      {vehicleDetail?.monthwise_rotationCount || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Terrain</TableCell>
                    <TableCell>{findTerrain()}</TableCell>
                    <TableCell variant="head">Rotations (Total)</TableCell>
                    <TableCell>
                      {vehicleDetail?.rotation_count || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Initial Reading</TableCell>
                    <TableCell>
                      {vehicleDetail?.initial_reading || "-"}{" "}
                      {vehicleDetail?.initial_reading ? "Km" : ""}
                    </TableCell>
                  </TableRow>
                </Table>
              </div>
            )}
          </div>
          <div
            className={styles.container}
            style={{ marginTop: 20, padding: 20 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs={12} sm={6}>
                <Typography variant="tableTitle">Rotation List</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  onClick={() => {
                    getSwitchList(false, 1);
                  }}
                  disable={isOnline ? false : true || reportLoader}
                >
                  {reportLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>
            <div style={{ marginTop: 10 }}>
              <Grid
                container
                gap={1}
                wrap="nowrap"
                justifyContent="space-between"
                alignItems="center"
              >
                <Grid item>
                  <Select
                    placeholder="Filter"
                    value={filter}
                    onChange={(value) => {
                      setPageNo(1);
                      setFilter(value.target.value);
                    }}
                    displayEmpty={true}
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value="" hidden selected disabled>
                      Filter
                    </MenuItem>
                    <MenuItem value={"Good"}>Good</MenuItem>
                    <MenuItem value={"Average"}>Average</MenuItem>
                    <MenuItem value={"Bad"}>Bad</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <Tooltip title="Records" placement="bottom" arrow>
                    <Grid item>
                      <Select
                        fullWidth
                        value={record}
                        onChange={(v) => {
                          setRecord(v.target.value);
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
            </div>
            <div style={{ marginTop: 10 }}>
              <Grid item container gap={1} wrap="nowrap" alignItems="center">
                <Grid item>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilter}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Tyre Serial No."
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                    onChange={(v) => {
                      isEmpty(v?.target?.value) && getSwitchList(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                          setPageNo(1);
                          getSwitchList(false);
                        }
                      }
                    }}
                  />
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      fullWidth
                      disableFuture
                      views={["year", "month", "day"]}
                      value={datePicker}
                      onChange={(newValue) => {
                        setDatePicker(newValue);
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{ svg: { color: color.primary } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                        setPageNo(1);
                        getSwitchList(false);
                      }
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={resetFilterData}
                    disabled={!searchFilter && !filter && !datePicker}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(switchList?.data) && !isEmpty(switchList?.data) ? (
                switchList?.data.map((item, index) => {
                  return (
                    <Grid
                      container
                      style={{
                        border: `1px solid ${color.bordercolor}`,
                        boxShadow: color.shadow,
                        padding: 10,
                        marginTop: 10,
                        overflow: "auto",
                      }}
                      alignItems="center"
                    >
                      <Grid item container>
                        <Table className={styles.customtable}>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Fleet Name</TableCell>
                              <TableCell variant="head">Vehicle No.</TableCell>
                              <TableCell variant="head">
                                Tyre Serial No.
                              </TableCell>
                              <TableCell variant="head">
                                Old Tyre Position
                              </TableCell>
                              <TableCell variant="head">
                                New Tyre Position
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableRow>
                            <TableCell>
                              {vehicleData.fleet_name || "-"}
                            </TableCell>
                            <TableCell>{item?.vehicle_no || "-"}</TableCell>
                            <TableCell>
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleClick("tyreDetails", item);
                                }}
                              >
                                <Typography
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    color: color.primary,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.tyre_serial_no}
                                </Typography>
                              </div>
                            </TableCell>
                            <TableCell>{item?.old_position || ""}</TableCell>
                            <TableCell>{item?.new_position || "-"}</TableCell>
                          </TableRow>
                          <TableRow variant="fullWidth">
                            <TableCell colSpan={5}>
                              <Divider
                                variant="fullWidth"
                                style={{
                                  marginTop: 15,
                                  marginBottom: 15,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Tyre Type</TableCell>
                              <TableCell variant="head">Tyre Brand</TableCell>
                              <TableCell variant="head">
                                Item Description
                              </TableCell>
                              <TableCell variant="head">
                                Tyre Composition
                              </TableCell>
                              <TableCell variant="head">Ply Rating</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableRow>
                            <TableCell>{item?.tyre_type || "-"}</TableCell>
                            <TableCell>{item?.tyre_brand || "-"}</TableCell>
                            <TableCell>
                              {item?.item_description || "-"}
                            </TableCell>
                            <TableCell>
                              {item?.tyre_composition || "-"}
                            </TableCell>
                            <TableCell>{item?.ply_rating || "-"}</TableCell>
                          </TableRow>
                          <TableRow variant="fullWidth">
                            <TableCell colSpan={5}>
                              <Divider
                                variant="fullWidth"
                                style={{
                                  marginTop: 15,
                                  marginBottom: 15,
                                }}
                              />
                            </TableCell>
                          </TableRow>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Current NSD</TableCell>
                              <TableCell variant="head">Avg. NSD</TableCell>
                              <TableCell variant="head">
                                Odometer Reading
                              </TableCell>
                              <TableCell variant="head">
                                Tyre Condition
                              </TableCell>
                              <TableCell variant="head">
                                Rotation Date
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableRow>
                            <TableCell>{nsd(item, index) || 0}</TableCell>
                            <TableCell>
                              {`${item?.avg_nsd} mm` || "-"}
                            </TableCell>
                            <TableCell>{item?.mileage || "-"} km</TableCell>
                            <TableCell>{condition(index) || "-"}</TableCell>
                            <TableCell colSpan={2}>
                              {created(index) || "-"}
                            </TableCell>
                          </TableRow>
                        </Table>
                      </Grid>
                    </Grid>
                  );
                })
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(switchList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    setPageNo(e);
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Box id="jump_to_me">
            {isKeyboardOpen && !isTablet ? null : (
              <Button
                style={{
                  backgroundColor: color.secondary,
                  position: "fixed",
                  top: 10,
                  zIndex: 10,
                }}
                onClick={() => {
                  handleClick("cancel");
                }}
              >
                <ArrowBackOutlined />
              </Button>
            )}
          </Box>
          <div
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 15,
            }}
          >
            <Typography fontWeight={"bold"}>
              {vehicleData?.fleet_name} / {vehicleData?.vehicle_name}
            </Typography>
          </div>
          <div
            className={styles.container}
            style={{
              padding: 10,
            }}
          >
            <div>
              <Grid
                container
                style={{
                  border: `1px solid ${color.bordercolor}`,
                  overflow: "auto",
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Vehicle No.</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Route Origin</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>{vehicleDetail?.vehicle_no || "-"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {vehicleDetail?.route_origin || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    background: "#FBF8F8",
                    padding: "10px 14px 0",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Vehicle Type</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>
                      Route Destination
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>
                      {vehicleDetail?.vehicle_type_id || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {vehicleDetail?.route_destination || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Vehicle Brand</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>No. of Tyres</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>
                      {vehicleDetail?.vehicle_brand_id || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{vehicleDetail?.no_of_tyres || "-"}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Vehicle Model</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>
                      Rotations (Month)
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>
                      {vehicleDetail?.vehicle_model || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {vehicleDetail?.monthwise_rotationCount || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Terrain</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>
                      Rotations (Total)
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>{findTerrain()}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {vehicleDetail?.rotation_count || "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Initial Reading</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>
                      {vehicleDetail?.initial_reading || "-"}
                      {vehicleDetail?.initial_reading ? " km" : "-"}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            style={{
              marginTop: 12,
              padding: 10,
              marginBottom: isTablet ? 0 : "8vh",
            }}
            className={styles.container}
          >
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item xs={6} sm={6} display="flex" alignItems="center">
                <Typography variant="tableTitle">Rotation List</Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                    marginLeft: 10,
                  }}
                  onClick={() => {
                    getSwitchList(false, 1);
                  }}
                  disabled={isOnline ? false : true || reportLoader}
                >
                  {reportLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              marginTop={"10px"}
              wrap="nowrap"
              gap={1}
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item>
                <Select
                  placeholder="Filter"
                  value={filter}
                  onChange={(value) => {
                    setPageNo(1);
                    setFilter(value.target.value);
                  }}
                  displayEmpty={true}
                  IconComponent={FilterAlt}
                  style={filter === "" ? { color: "#A2A2A2" } : {}}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem value="" hidden selected disabled>
                    Filter
                  </MenuItem>
                  <MenuItem value={"Good"}>Good</MenuItem>
                  <MenuItem value={"Average"}>Average</MenuItem>
                  <MenuItem value={"Bad"}>Bad</MenuItem>
                </Select>
              </Grid>
              <Grid item>
                <Select
                  value={record}
                  onChange={(v) => {
                    setRecord(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid item container marginTop={"10px"} alignItems="center">
              <TextField
                fullWidth
                placeholder="Search"
                value={searchFilter}
                className={styles.inputFieldStyle}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Tyre Serial No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
                onChange={(v) => {
                  isEmpty(v?.target?.value) && getSwitchList(true);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      setPageNo(1);
                      getSwitchList(false);
                    }
                  }
                }}
              />
            </Grid>
            <div>
              <Grid
                container
                wrap="nowrap"
                marginTop={"10px"}
                gap={1}
                alignItems="center"
              >
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      disableFuture
                      fullWidth
                      views={["year", "month", "day"]}
                      value={datePicker}
                      onChange={(newValue) => {
                        setDatePicker(newValue);
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{ svg: { color: color.primary } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                        setPageNo(1);
                        getSwitchList(false);
                      }
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={resetFilterData}
                    disabled={!searchFilter && !filter && !datePicker}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(switchList?.data) && !isEmpty(switchList?.data) ? (
                <>
                  {switchList?.data.map((item, index) => {
                    return (
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <Grid
                          container
                          id={`jump_to_me${index}`}
                          style={{
                            border: `1px solid ${color.bordercolor}`,
                            boxShadow: color.shadow,
                            marginTop: 20,
                            // height: activeIndex === index ? "auto" : 115,
                            overflow: "hidden",
                          }}
                        >
                          {activeIndex !== index ? (
                            <>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Vehicle No.
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Serial No.
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.vehicle_no || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleClick("tyreDetails", item);
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        color: color.primary,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.tyre_serial_no}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  background: "#EEF8FF",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Old Tyre Position
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    New Tyre Position
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.old_position || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.new_position || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Vehicle No.
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Serial No.
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.vehicle_no || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleClick("tyreDetails", item);
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        color: color.primary,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.tyre_serial_no}
                                    </Typography>
                                  </div>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  background: "#EEF8FF",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Old Tyre Position
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    New Tyre Position
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.old_position || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.new_position || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Fleet Name
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Type
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {vehicleData?.fleet_name || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_type || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Brand
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Item Description
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_brand || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.item_description || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Composition
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Ply Rating
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_composition || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.ply_rating || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Odometer Reading
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Condition
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.mileage || "-"} km
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {condition(index) || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Current NSD
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Avg. NSD
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {nsd(item, index) || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {`${item?.avg_nsd} mm` || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Typography fontWeight={"bold"}>
                                  Rotation Date
                                </Typography>
                                <Grid item xs={6}></Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {created(index) || "-"}
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}></Grid>
                              </Grid>
                            </>
                          )}
                          <div
                            style={{
                              zIindex: 1,
                              color: color.primary,
                              display: "flex",
                              justifyContent: "center",
                              position: "absolute",
                              bottom: -18,
                              left: "45%",
                            }}
                            onClick={() => {
                              activeIndex === index
                                ? setActiveIndex(null)
                                : setActiveIndex(index);
                            }}
                          >
                            {activeIndex === index ? (
                              <ExpandCircleDownIcon
                                style={{
                                  transform: "rotate(180deg)",
                                  fontSize: 30,
                                }}
                              />
                            ) : (
                              <ExpandCircleDownIcon style={{ fontSize: 30 }} />
                            )}
                          </div>
                        </Grid>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      marginTop: 30,
                      marginBottom: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(switchList?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        setPageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
