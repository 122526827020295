import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  TableRow,
  TableCell,
  Table,
  CircularProgress,
  MenuItem,
  Select,
  Grid,
  Button,
  TextField,
  Tooltip,
  TableHead,
  Divider,
  Pagination,
  Modal,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { ArrowBackOutlined } from "@mui/icons-material";
import { SearchOutlined } from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import Images from "../../../Config/Images";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function TabConditionDetails(props) {
  const { handleClick = () => null, vehicleData } = props;
  const styles = useStyles();
  const { token, isOnline, userdata } = useSelector((state) => state.auth);
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [pageLoad, setPageLoad] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [searchFilter, setSearchFilter] = useState("");
  const [conditionListRows, setConditionListRows] = useState({});
  const [tyreDetails, setTyreDetails] = useState({});
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);

  const [openCard, setOpenCard] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [value, setValue] = useState("");

  const [activeIndex, setActiveIndex] = useState(null);

  useEffect(() => {
    conditionDetailsList(false);
  }, [pageNo, record]);

  useEffect(() => {
    setPageNo(1);
  }, [record]);

  useEffect(() => {
    getTyreDetails();
  }, []);

  useEffect(() => {
    isNull(datePicker) && conditionDetailsList(false);
  }, [datePicker, datePicker1]);

  useEffect(() => {
    setPageNo(1);
  }, [record]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [pageLoad, pageNo]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(null);
  }, [pageNo, record]);

  const getTyreDetails = async () => {
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyreDetails
        }?tyre_id=${vehicleData?.tyre_serial_no?.replaceAll(
          " ",
          "%20"
        )}&vehicle_id=${
          vehicleData?.is_deleted == 1 ? vehicleData?.vehicle_id : ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setTyreDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function conditionDetailsList(bool, v) {
    setActiveIndex(null);
    !v && setPageLoad(true);
    v && setDownloadLoader(true);

    const fromDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePicker1)
      ? moment(datePicker1).format("YYYY-MM-DD")
      : "";

    try {
      const response = await getApiData(
        `${Setting.endpoints.conditionList}?tyre_id=${vehicleData?.tyre_id}&per_page=${record}&page=${pageNo}&from_date=${fromDate}&to_date=${toDate}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data?.rows) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadCDetails(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setConditionListRows(listObj);
          }
        else {
          toast.error(response?.message || "No Data Found");
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Condition Details");
      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setDownloadLoader(false);
    }
  }

  const created = (index) => {
    const timestamp = conditionListRows?.data[index]?.created_at;
    // const date = new Date(timestamp * 1000);
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const issue = (index) => {
    let array = conditionListRows?.data[index]?.issue;
    return !isEmpty(array) ? (
      <div
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {array.map((items, index) => {
          return (
            <Typography>
              {items}
              {index !== array.length - 1 ? ", " : ""}
            </Typography>
          );
        })}
      </div>
    ) : (
      <Typography variant="tableHead">-</Typography>
    );
  };

  const missue = (index) => {
    let array = conditionListRows?.data[index]?.mechanical_issue;
    return !isEmpty(array) ? (
      <div
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {array.map((items, index) => {
          return (
            <Typography>
              {items}
              {index !== array.length - 1 ? ", " : ""}
            </Typography>
          );
        })}
      </div>
    ) : (
      <Typography variant="tableHead">-</Typography>
    );
  };

  const removalIssue = (index) => {
    let array = conditionListRows?.data[index]?.removal_issue;
    return !isEmpty(array) ? (
      <div
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {array.map((items, index) => {
          return (
            <Typography>
              {items}
              {index !== array.length - 1 ? ", " : ""}
            </Typography>
          );
        })}
      </div>
    ) : (
      <Typography variant="tableHead">-</Typography>
    );
  };

  const image = (index) => {
    const value = conditionListRows?.data[index]?.issue_image;
    if (!isEmpty(value)) {
      return (
        <img
          style={{
            cursor: "pointer",
            width: 30,
            height: 30,
            borderRadius: 15,
            backgroundColor: !conditionListRows?.data[index]?.issue_image
              ? color.secondary
              : color.white,
          }}
          src={value || Images.profile}
          alt=""
          onClick={() => {
            setValue(value);
            setOpenCard(true);
          }}
        />
      );
    } else {
      return (
        <img
          style={{
            width: 30,
            height: 30,
            borderRadius: 15,
            backgroundColor: color.secondary,
          }}
          src={Images.profile}
          alt=""
        />
      );
    }
  };

  function downloadCDetails(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.created_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Tyre Serial No.": row?.tyre_serial_no,
        "Axle Type": row?.axle_type || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.item_description || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating || "-",
        "Old Condition": row?.old_condition || "-",
        "New Condition": row?.new_condition || "-",
        "Tyre Issue": row?.issue.toString() || "-",
        "Mechanical Issue": row?.mechanical_issue.toString() || "-",
        "Removal Issue": row?.removal_issue.toString() || "-",
        "Days from Last Change": row?.days_from_last_condition || "-",
        Date: timestamp ? displayDate : "-",
        Remarks: row?.remark || "-",
        Image: row?.issue_image || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Condition Details");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Condition Details.xlsx");
  }

  function resetFilterData() {
    setSearchFilter("");
    setDatePicker(null);
    setDatePicker1(null);
  }

  return (
    <>
      {isTablet ? (
        <div>
          <Box id="jump_to_me">
            <Button
              style={{ backgroundColor: color.secondary, marginBottom: "10px" }}
              onClick={() => {
                handleClick("cancel");
              }}
            >
              Back
            </Button>
          </Box>
          <div className={styles.container}>
            <Box
              style={{
                backgroundColor: color.primary,
                color: color.white,
                padding: "10px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography fontWeight={"bold"}>Condition Details</Typography>
              <Button
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                disable={isOnline ? false : true}
                onClick={() => conditionDetailsList(false, 1)}
              >
                {downloadLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Box>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : (
              <div style={{ padding: 20 }}>
                <Table>
                  <TableRow>
                    <TableCell variant="head">Fleet Name</TableCell>
                    <TableCell>{tyreDetails?.fleet_name || "-"}</TableCell>
                    <TableCell variant="head">Current Axle Type</TableCell>
                    <TableCell>{tyreDetails?.axle_type || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle No.</TableCell>
                    <TableCell>{tyreDetails?.vehicle_no || "-"}</TableCell>
                    <TableCell variant="head">
                      No. of Condition Changes
                    </TableCell>
                    <TableCell>
                      {tyreDetails?.condition_changes || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Type</TableCell>
                    <TableCell>{tyreDetails?.vehicle_type || "-"}</TableCell>

                    <TableCell variant="head">Current Condition</TableCell>
                    <TableCell>
                      <span
                        style={{
                          padding: 8,
                          borderRadius: 4,
                          backgroundColor:
                            tyreDetails?.tyre_condition === "Average"
                              ? "#FFFDD9"
                              : tyreDetails?.tyre_condition === "Good"
                              ? "#D9FFE4"
                              : tyreDetails?.tyre_condition === "Bad"
                              ? "#FFD9D9"
                              : "",
                        }}
                      >
                        {tyreDetails?.tyre_condition || "-"}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Brand</TableCell>
                    <TableCell>{tyreDetails?.vehicle_brand || "-"}</TableCell>
                    <TableCell variant="head">Current NSD</TableCell>
                    <TableCell>{`${tyreDetails?.NSD} mm` || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Model</TableCell>
                    <TableCell>{tyreDetails?.vehicle_model || "-"}</TableCell>
                    <TableCell variant="head">Current Tyre Pressure</TableCell>
                    <TableCell>
                      {(tyreDetails?.tyre_air_pressure &&
                        `${tyreDetails?.tyre_air_pressure} psi`) ||
                        "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Current Position </TableCell>
                    <TableCell>{tyreDetails?.tyre_position || "-"}</TableCell>
                  </TableRow>
                  <TableRow></TableRow>
                  <TableRow></TableRow>
                </Table>
              </div>
            )}
          </div>
          <div
            className={styles.container}
            style={{ marginTop: 20, padding: 20 }}
          >
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid item xs={12} sm={6}>
                <Typography variant="tableTitle">Condition Details</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              ></Grid>
            </Grid>
            <div style={{ marginTop: 14 }}>
              <Grid container gap={1} wrap="nowrap">
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      disableFuture
                      views={["year", "month", "day"]}
                      value={datePicker}
                      onChange={(newValue) => {
                        setDatePicker(newValue);
                        setDisable(false);
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{ svg: { color: color.primary } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item display={"flex"} alignItems={"center"}>
                  to
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      disabled={isNull(datePicker) ? true : disable}
                      minDate={datePicker}
                      views={["year", "month", "day"]}
                      disableFuture
                      value={datePicker1}
                      inputFormat="dd-MM-yyyy"
                      onChange={(newValue) => {
                        setDatePicker1(newValue);
                      }}
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{
                            svg: {
                              color:
                                disable || isNull(datePicker)
                                  ? ""
                                  : color.primary,
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                        setPageNo(1);
                        conditionDetailsList(false);
                      }
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    onClick={resetFilterData}
                    disabled={!searchFilter && !datePicker}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
                <Grid item>
                  <Tooltip title="Records" placement="bottom" arrow>
                    <Grid item>
                      <Select
                        value={record}
                        onChange={(v) => {
                          setRecord(v.target.value);
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(conditionListRows?.data) &&
                !isEmpty(conditionListRows?.data) ? (
                <>
                  {conditionListRows?.data.map((item, index) => {
                    return (
                      <Grid
                        container
                        style={{
                          border: `1px solid ${color.bordercolor}`,
                          boxShadow: color.shadow,
                          padding: 10,
                          marginTop: 10,
                          overflow: "auto",
                        }}
                        alignItems="center"
                      >
                        <Grid item container>
                          <Table className={styles.customtable}>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Tyre Serial No.
                                </TableCell>
                                <TableCell variant="head">Axle Type</TableCell>
                                <TableCell variant="head">Tyre Type</TableCell>
                                <TableCell variant="head">Tyre Brand</TableCell>
                                <TableCell variant="head">
                                  Item Description
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>
                                <div
                                  style={{
                                    display: "flex",
                                    flex: 1,
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    handleClick("tyreDetails", item);
                                  }}
                                >
                                  <Typography
                                    style={{
                                      whiteSpace: "pre-wrap",
                                      color: color.primary,
                                      fontWeight: "bold",
                                    }}
                                  >
                                    {item?.tyre_serial_no}
                                  </Typography>
                                </div>
                              </TableCell>
                              <TableCell>{item?.axle_type || "-"}</TableCell>
                              <TableCell>{item?.tyre_type || "-"}</TableCell>
                              <TableCell>{item?.tyre_brand || "-"}</TableCell>
                              <TableCell>
                                {item?.item_description || "-"}
                              </TableCell>
                            </TableRow>
                            <TableRow variant="fullWidth">
                              <TableCell colSpan={5}>
                                <Divider
                                  variant="fullWidth"
                                  style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Tyre Composition
                                </TableCell>
                                <TableCell variant="head">Ply Rating</TableCell>
                                <TableCell variant="head">
                                  Old Condition
                                </TableCell>
                                <TableCell variant="head">
                                  New Condition
                                </TableCell>
                                <TableCell variant="head">
                                  Removal Issue
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>
                                {item?.tyre_composition || "-"}
                              </TableCell>
                              <TableCell>{item?.ply_rating || "-"}</TableCell>
                              <TableCell>
                                <Typography
                                  variant="span"
                                  style={{
                                    padding: 5,
                                    marginRight: 8,
                                    borderRadius: 4,
                                    backgroundColor:
                                      item?.old_condition === "Average"
                                        ? "#FFFDD9"
                                        : item?.old_condition === "Good"
                                        ? "#D9FFE4"
                                        : item?.old_condition === "Bad"
                                        ? "#FFD9D9"
                                        : "",
                                  }}
                                >
                                  {item?.old_condition || "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                <Typography
                                  variant="span"
                                  style={{
                                    padding: 5,
                                    marginRight: 8,
                                    borderRadius: 4,
                                    backgroundColor:
                                      item?.new_condition === "Average"
                                        ? "#FFFDD9"
                                        : item?.new_condition === "Good"
                                        ? "#D9FFE4"
                                        : item?.new_condition === "Bad"
                                        ? "#FFD9D9"
                                        : "",
                                  }}
                                >
                                  {item?.new_condition || "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>{removalIssue(index)}</TableCell>
                            </TableRow>
                            <TableRow variant="fullWidth">
                              <TableCell colSpan={5}>
                                <Divider
                                  variant="fullWidth"
                                  style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">Tyre Issue</TableCell>
                                <TableCell
                                  variant="head"
                                  style={{ paddingRight: 10 }}
                                >
                                  Mechanical Issue
                                </TableCell>
                                <TableCell variant="head">Date</TableCell>
                                <TableCell variant="head">Remarks</TableCell>
                                <TableCell variant="head">
                                  Days from Last Change
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>{issue(index)}</TableCell>
                              <TableCell>{missue(index)}</TableCell>

                              <TableCell>{created(index) || "-"}</TableCell>
                              <TableCell>{item?.remark || "-"}</TableCell>
                              <TableCell>
                                {item?.days_from_last_condition || ""}
                              </TableCell>
                            </TableRow>
                            <TableRow variant="fullWidth">
                              <TableCell colSpan={5}>
                                <Divider
                                  variant="fullWidth"
                                  style={{
                                    marginTop: 15,
                                    marginBottom: 15,
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">Images</TableCell>
                              </TableRow>
                            </TableHead>
                            <TableRow>
                              <TableCell>{image(index) || "-"}</TableCell>
                            </TableRow>
                          </Table>
                        </Grid>
                      </Grid>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(
                        conditionListRows?.pagination?.totalPage
                      )}
                      defaultPage={pageNo}
                      boundaryCount={2}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        setPageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      ) : (
        <div>
          <Box id="jump_to_me">
            {isKeyboardOpen && !isTablet ? null : (
              <Button
                style={{
                  backgroundColor: color.secondary,
                  position: "fixed",
                  top: 10,
                  zIndex: 10,
                }}
                onClick={() => {
                  handleClick("cancel");
                }}
              >
                <ArrowBackOutlined />
              </Button>
            )}
          </Box>
          <div
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight={"bold"}>Condition Details</Typography>
            <Button
              style={{
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              disabled={isOnline ? false : true}
              onClick={() => conditionDetailsList(false, 1)}
            >
              {downloadLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                <img src={Images.Mdownload} alt="download" />
              )}
            </Button>
          </div>
          <div
            className={styles.container}
            style={{
              padding: 10,
            }}
          >
            <div
              style={{
                position: "relative",
              }}
            >
              <Grid
                container
                style={{
                  border: `1px solid ${color.bordercolor}`,
                  overflow: "auto",
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Fleet Name</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>
                      Current Axle Type
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>{tyreDetails?.fleet_name || "-"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{tyreDetails?.axle_type || "-"}</Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    background: "#FBF8F8",
                    padding: "10px 14px 0",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Vehicle No.</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>
                      No. of Condition Changes
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>{tyreDetails?.vehicle_no || "-"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {tyreDetails?.condition_changes || "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Vehicle Type</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>
                      Current Condition
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>{tyreDetails?.vehicle_type || "-"}</Typography>
                  </Grid>

                  <Grid item xs={6}>
                    <Typography marginTop="6px">
                      <span
                        style={{
                          padding: 5,
                          borderRadius: 4,
                          backgroundColor:
                            tyreDetails?.tyre_condition === "Average"
                              ? "#FFFDD9"
                              : tyreDetails?.tyre_condition === "Good"
                              ? "#D9FFE4"
                              : tyreDetails?.tyre_condition === "Bad"
                              ? "#FFD9D9"
                              : "",
                        }}
                      >
                        {tyreDetails?.tyre_condition || "-"}
                      </span>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Vehicle Brand</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Current NSD</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>{tyreDetails?.vehicle_brand || "-"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>{`${tyreDetails?.NSD} mm` || "-"}</Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>Vehicle Model</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>
                      Current Tyre Pressure
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>{tyreDetails?.vehicle_model || "-"}</Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography>
                      {(tyreDetails?.tyre_air_pressure &&
                        `${tyreDetails?.tyre_air_pressure} psi`) ||
                        "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "10px 14px 0",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography fontWeight={"bold"}>
                      Current Position
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "0 14px 10px",
                    background: "#FBF8F8",
                  }}
                  xs={12}
                >
                  <Grid item xs={6}>
                    <Typography>{tyreDetails?.tyre_position || "-"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          </div>
          <div
            style={{
              marginTop: 12,
              padding: 10,
              marginBottom: isTablet ? 0 : "8vh",
            }}
            className={styles.container}
          >
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item display="flex" alignItems="center">
                <Typography variant="tableTitle">Condition Details</Typography>
              </Grid>
              <Grid item>
                <Tooltip title="Records" placement="bottom" arrow>
                  <Grid item>
                    <Select
                      value={record}
                      onChange={(v) => {
                        setRecord(v.target.value);
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Grid>
                </Tooltip>
              </Grid>
            </Grid>

            <div>
              <Grid container paddingTop={"10px"} gap={1}>
                <Grid item container gap={1} wrap="nowrap" xs={12}>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        showToolbar={false}
                        disableFuture
                        views={["year", "month", "day"]}
                        value={datePicker}
                        onChange={(newValue) => {
                          setDatePicker(newValue);
                          setDisable(false);
                        }}
                        inputFormat="dd-MM-yyyy"
                        DialogProps={{ className: styles.datePicker }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD-MM-YYYY",
                            }}
                            sx={{ svg: { color: color.primary } }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item display={"flex"} alignItems={"center"}>
                    to
                  </Grid>
                  <Grid item>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        showToolbar={false}
                        disabled={isNull(datePicker) ? true : disable}
                        minDate={datePicker}
                        views={["year", "month", "day"]}
                        disableFuture
                        value={datePicker1}
                        inputFormat="dd-MM-yyyy"
                        onChange={(newValue) => {
                          setDatePicker1(newValue);
                        }}
                        DialogProps={{ className: styles.datePicker }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD-MM-YYYY",
                            }}
                            sx={{
                              svg: {
                                color:
                                  disable || isNull(datePicker)
                                    ? ""
                                    : color.primary,
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid item container gap={1} wrap="nowrap" xs={12}>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      style={{ backgroundColor: color.secondary }}
                      onClick={() => {
                        if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                          setPageNo(1);
                          conditionDetailsList(false);
                        }
                      }}
                    >
                      <SearchOutlined />
                      {/* Search */}
                    </Button>
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      fullWidth
                      variant="contained"
                      onClick={resetFilterData}
                      disabled={!searchFilter && !datePicker}
                    >
                      <ResetIcon />
                      {/* Reset */}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(conditionListRows?.data) &&
                !isEmpty(conditionListRows?.data) ? (
                <>
                  {conditionListRows?.data.map((item, index) => {
                    return (
                      <div style={{ marginTop: 10, position: "relative" }}>
                        <Grid
                          id={`jump_to_me${index}`}
                          container
                          style={{
                            border: `1px solid ${color.bordercolor}`,
                            boxShadow: color.shadow,
                            // height: activeIndex === index ? "auto" : 115,
                            overflow: "hidden",
                            marginTop: 20,
                          }}
                        >
                          {activeIndex !== index ? (
                            <>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Serial No.
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Days from Last Change
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleClick("tyreDetails", item);
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        color: color.primary,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.tyre_serial_no}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.days_from_last_condition || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  background: "#EEF8FF",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Old Condition
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    New Condition
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography
                                    variant="span"
                                    style={{
                                      padding: 5,
                                      marginRight: 8,
                                      borderRadius: 4,
                                      backgroundColor:
                                        item?.old_condition === "Average"
                                          ? "#FFFDD9"
                                          : item?.old_condition === "Good"
                                          ? "#D9FFE4"
                                          : item?.old_condition === "Bad"
                                          ? "#FFD9D9"
                                          : "",
                                    }}
                                  >
                                    {item?.old_condition || "-"}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography
                                    variant="span"
                                    style={{
                                      padding: 4,
                                      borderRadius: 4,
                                      backgroundColor:
                                        item?.new_condition === "Average"
                                          ? "#FFFDD9"
                                          : item?.new_condition === "Good"
                                          ? "#D9FFE4"
                                          : item?.new_condition === "Bad"
                                          ? "#FFD9D9"
                                          : "",
                                    }}
                                  >
                                    {item?.new_condition || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Serial No.
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Days from Last Change
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <div
                                    style={{
                                      display: "flex",
                                      flex: 1,
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      handleClick("tyreDetails", item);
                                    }}
                                  >
                                    <Typography
                                      style={{
                                        whiteSpace: "pre-wrap",
                                        color: color.primary,
                                        fontWeight: "bold",
                                      }}
                                    >
                                      {item?.tyre_serial_no}
                                    </Typography>
                                  </div>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.days_from_last_condition || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  background: "#EEF8FF",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Old Condition
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    New Condition
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography
                                    variant="span"
                                    style={{
                                      padding: 5,
                                      marginRight: 8,
                                      borderRadius: 4,
                                      backgroundColor:
                                        item?.old_condition === "Average"
                                          ? "#FFFDD9"
                                          : item?.old_condition === "Good"
                                          ? "#D9FFE4"
                                          : item?.old_condition === "Bad"
                                          ? "#FFD9D9"
                                          : "",
                                    }}
                                  >
                                    {item?.old_condition || "-"}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography
                                    variant="span"
                                    style={{
                                      padding: 4,
                                      borderRadius: 4,
                                      backgroundColor:
                                        item?.new_condition === "Average"
                                          ? "#FFFDD9"
                                          : item?.new_condition === "Good"
                                          ? "#D9FFE4"
                                          : item?.new_condition === "Bad"
                                          ? "#FFD9D9"
                                          : "",
                                    }}
                                  >
                                    {item?.new_condition || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Item Description
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Composition
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.item_description || "-"}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.tyre_composition}
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Ply Rating
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Type
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.ply_rating || "-"}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography>
                                    {item?.tyre_type || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Brand
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Tyre Issue
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography>
                                    {item?.tyre_brand || "-"}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography>{issue(index)}</Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Mechanical Issue
                                  </Typography>
                                </Grid>

                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Removal Issue
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>{missue(index)}</Typography>
                                </Grid>
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography>{removalIssue(index)}</Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Axle Type
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography fontWeight={"bold"}>
                                    Date
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "0 14px 10px",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>
                                    {item?.axle_type || "-"}
                                  </Typography>
                                </Grid>

                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography>
                                    {created(index) || "-"}
                                  </Typography>
                                </Grid>
                              </Grid>

                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6} style={{ marginTop: 5 }}>
                                  <Typography fontWeight={"bold"}>
                                    Remarks
                                  </Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  <Typography fontWeight={"bold"}>
                                    Images
                                  </Typography>
                                </Grid>
                              </Grid>
                              <Grid
                                item
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  padding: "10px 14px 0",
                                  background: "#EEF8FF",
                                }}
                                xs={12}
                              >
                                <Grid item xs={6}>
                                  <Typography>{item?.remark || "-"}</Typography>
                                </Grid>
                                <Grid item xs={6}>
                                  {image(index) || "-"}
                                </Grid>
                              </Grid>
                            </>
                          )}

                          <div
                            style={{
                              zIindex: 10,
                              color: color.primary,
                              display: "flex",
                              justifyContent: "center",
                              position: "absolute",
                              bottom: -18,
                              left: "45%",
                            }}
                            onClick={() => {
                              activeIndex === index
                                ? setActiveIndex(null)
                                : setActiveIndex(index);
                            }}
                          >
                            {activeIndex === index ? (
                              <div style={{ transform: "rotate(180deg)" }}>
                                <TableToggle />
                              </div>
                            ) : (
                              <TableToggle />
                            )}
                          </div>
                        </Grid>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      marginTop: 30,
                      marginBottom: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(
                        conditionListRows?.pagination?.totalPage
                      )}
                      defaultPage={pageNo}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        setPageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      <Modal
        className={styles.modal}
        open={openCard}
        onClose={() => {
          setOpenCard(false);
        }}
      >
        <img
          style={{
            backgroundColor: color.white,
            maxWidth: "70vw",
            maxHeight: "70vh",
            borderRadius: 4,
          }}
          src={value || "-"}
          alt={"user id"}
        />
      </Modal>
    </>
  );
}
