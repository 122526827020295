import { Map, Marker, GoogleApiWrapper, Circle } from "google-maps-react";
import { isArray, isEmpty } from "lodash";

function MapContainer(props) {
  const {
    data = {},
    km = 0,
    centerData = [],
    selectItemData = () => null,
    center = true,
  } = props;

  // const radius = km * 1000;
  return (
    <Map
      google={window.google}
      initialCenter={{ lat: data?.lat, lng: data?.lng }}
      center={center ? { lat: data?.lat, lng: data?.lng } : {}}
    >
      {isArray(centerData) &&
        !isEmpty(centerData) &&
        centerData.map((item, index) => {
          return (
            <Marker
              title={item?.address || "-"}
              position={{ lat: item?.latitude, lng: item?.longitude }}
              onClick={() => selectItemData(item)}
            />
          );
        })}
      {/* {data?.lat && data?.lng && (
        <Circle
          radius={radius}
          center={{ lat: data?.lat, lng: data?.lng }}
          strokeColor="transparent"
          strokeOpacity={0}
          strokeWeight={5}
          fillColor="#FF0000"
          fillOpacity={0.2}
        />
      )} */}
    </Map>
  );
}

export default MapContainer;

// export default GoogleApiWrapper({
//   apiKey: "AIzaSyCQ45xfD66jJngtVldi1Z2xFRFq1frJkfo",
// })(MapContainer);
