import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  CircularProgress,
  Pagination,
  MenuItem,
  Select,
  Grid,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery,
  ListItemButton,
  Tooltip,
  Box,
  Fade,
  Modal,
  Backdrop,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { DataGrid } from "@mui/x-data-grid";
import { Close, Delete, FilterAlt, Search } from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import { useTheme } from "@emotion/react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InfoIcon from "@mui/icons-material/Info";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as XLSX from "xlsx/xlsx.mjs";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import VehicleDetailTable from "../../Fleet/vehicleDetailsTable/index.js";
import ConfirmDialog from "../../ConfirmDialog/index.js";

const errorObj = {
  kmErr: false,
  kmMsg: "",
};

export default function InspectionDetails(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    inspectionData,
  } = props;
  const styles = useStyles();

  const { token, dropDownData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );
  const currency_Regex = Setting.JS_Regex.currency_Regex;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [visible, setVisible] = useState(false);
  const [kmReading, setKmReading] = useState("");
  const [errObj, setErrObj] = useState(errorObj);
  const [loader, setLoader] = useState(false);

  const [fleetDetails, setFleetDetails] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [pageLoad1, setPageLoad1] = useState(false);
  // const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [deleteData, setDeleteData] = useState({});
  const [rowData, setRowData] = useState({});
  const [inspectionHistoryList, setinspectionHistoryListRows] = useState({});

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : 760,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    padding: 0,
  };

  const InspectionHistoryColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    // {
    //   field: "vehicle_number",
    //   headerName: "Vehicle No.",
    //   width: 200,
    // },
    {
      field: "Inspection Reading",
      headerName: "Inspection Reading",
      width: 200,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <div style={{ paddingTop: 10, paddingBottom: 10 }}>
            <Button
              variant="contained"
              onClick={() => {
                setRowData(params?.row);
                converter(params?.row?.reading);
                setVisible(true);
              }}
            >
              {params?.row?.is_reading_pending === 0 ? "Edit" : "Add"}
            </Button>
          </div>
        );
      },
    },
    {
      field: "issue_noted",
      headerName: "Issues Noted",
      width: 200,
      renderCell: (params) => {
        const data = params?.row?.issue_noted;
        return isEmpty(params?.row?.issue_noted) ? (
          "-"
        ) : (
          <div style={{ display: "flex", flexDirection: "column" }}>
            {Object.keys(data).map((item) => {
              return (
                <Typography>
                  {item}: {data[item]}
                </Typography>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "capture_date",
      sortable: false,
      headerName: "Inspection Date",
      width: 220,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.capture_date ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 250,
    },
    {
      field: "vehicle_brand",
      headerName: "Vehicle Brand",
      width: 250,
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 250,
    },
    {
      field: "terrain_ids",
      headerName: "Terrain",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        let array = params?.row.terrain_ids.split(",");
        let res = dropDownData.terrain.filter((item) => {
          let a = array.includes(item?.id?.toString());
          return a;
        });
        return (
          <span
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {res.map((items, i) => {
              return (
                <Typography>
                  {items?.label}
                  {i < res.length - 1 ? ", " : ""}
                </Typography>
              );
            })}
          </span>
        );
      },
    },
    {
      field: "load_capicity",
      headerName: "Load Capacity",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.load_capicity}
            {params?.row?.load_capicity ? " Tonnes" : ""}
          </Typography>
        );
      },
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                color: params?.row?.added_tyre_count != (0 || "") ? "red" : "",
              }}
            >
              {params?.row?.added_tyre_count}
            </span>
            <span>/{params?.row?.no_of_tyres}</span>
          </>
        );
      },
    },
    {
      field: "initial_reading",
      headerName: "Initial Reading",
      width: 250,
      renderCell: (params) => {
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.initial_reading || "-"}{" "}
            {params?.row?.initial_reading ? "Km" : ""}
          </div>
        );
      },
    },
    {
      field: "inspection_monthly",
      headerName: "Inspections (Month)",
      width: 350,
    },
    {
      field: "inspection_overall",
      headerName: "Inspections (Overall)",
      width: 300,
    },
    {
      field: "inspected_by",
      headerName: "Added By",
      width: 220,
    },
    {
      field: "Delete",
      headerAlign: "center",
      headerName: "Delete",
      width: 80,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title="Delete" placement="left" arrow>
            <Delete
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => {
                setDeleteData(params?.row);
                setConfirmDialog(true);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    if (isOnline) {
      document.title = Setting.page_name.Inspection;
    }
  }, [isOnline]);

  useEffect(() => {
    getFleetDetails();
  }, []);

  useEffect(() => {
    setPageLoad1(true);
    getInspectionHistoryList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    if (inspectionData?.set === "dashboardDetails") {
      const displayDate = moment(inspectionData?.date);
      setDatePicker(displayDate);
    }
  }, []);

  useEffect(() => {
    isNull(datePicker) &&
      isEmpty(searchFilter) &&
      getInspectionHistoryList(false);
  }, [datePicker, searchFilter]);

  const submitData = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.addreading;

    const params = {
      "VehicleReading[fleet_id]": rowData?.fleet_id,
      "VehicleReading[vehicle_id]": rowData?.vehicle_id,
      "VehicleReading[inspection_date]": rowData?.capture_date,
      "VehicleReading[km_reading]": kmReading.replaceAll(",", ""),
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        resetModal();
        getInspectionHistoryList(false);
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  const getFleetDetails = async () => {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetdetails}?id=${inspectionData.fleet_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setFleetDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  async function getInspectionHistoryList(bool, v) {
    v && setReportLoader(true);
    !v && setPageLoad1(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    let endpoints = `${
      Setting.endpoints.inspectionhistorylist
    }?page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&vehicle_id=${inspectionData?.vehicle_id}&filter=${filter}&date=${
      inspectionData.date === undefined ? date : inspectionData.date
    }`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadInspectionHistoryList(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setinspectionHistoryListRows(listObj);
          }
          v && auditSave("Inspection History List");
        } else {
          toast.error(response?.message || "No Data Found");
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad1(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  async function deleteInspection() {
    setRemoveBtnLoad(true);
    let endPoints = `${Setting.endpoints.deleteInspection}?vehicle_id=${deleteData?.vehicle_id}&capture_date=${deleteData?.capture_date}`;
    try {
      const response = await getApiData(
        endPoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setConfirmDialog(false);
        toast.success(response?.message);
        setDeleteData({});
        getInspectionHistoryList();
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function resetFilterData() {
    setSearchFilter("");
    setDatePicker(null);
    handleFilter("");
    handlePageNo(1);
    inspectionData.date = undefined;
    getInspectionHistoryList(true);
  }

  function resetModal() {
    setVisible(false);
    setErrObj(errorObj);
    setKmReading("");
    setRowData({});
  }

  function downloadInspectionHistoryList(data) {
    const newData = data.map((row, index) => {
      let data = row?.issue_noted;
      let arr = [];
      let issueObj = Object.keys(data).map((item) => {
        arr.push(`${item}: ${data[item]}`);
      });

      const getTerrain = () => {
        let array = row?.terrain_ids?.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array?.includes(item?.id?.toString());
          return a;
        });
        const arr = [];
        res.map((v, i) => arr.push(v?.label));
        return arr.toString();
      };

      const newObj = {
        "Sr. No.": index + 1,
        // "Vehicle No.": row?.vehicle_number,
        "Issues Noted": arr.toString() || "-",
        "Inspection Date": row?.capture_date || "-",
        "Vehicle Type": row?.vehicle_type || "-",
        "Vehicle Brand": row?.vehicle_brand || "-",
        "Vehicle Model": row?.vehicle_model || "-",
        Terrain: getTerrain() || "-",
        "Load Capacity": `${row?.load_capicity}${
          row?.load_capicity ? " Tonnes" : "-"
        }`,
        "No. of Tyres": `${row?.added_tyre_count}/${row?.no_of_tyres}`,
        "Initial Reading (Km)": Number(
          row?.initial_reading.toString().replaceAll(",", "")
        ),
        "Inspections (Month)": Number(row?.inspection_monthly),
        " Inspections (Overall)": Number(row?.inspection_overall),
        "Added By": row?.inspected_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Inspection History List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inspection History List.xlsx");
  }

  function checkValidation() {
    let valid = true;
    const error = { ...errObj };

    let c = kmReading.replaceAll(",", "");
    if (isEmpty(kmReading)) {
      valid = false;
      error.kmErr = true;
      error.kmMsg = "Please enter Inspection Reading";
    } else if (!isEmpty(kmReading) && !currency_Regex.test(c)) {
      valid = false;
      error.kmErr = true;
      error.kmMsg = "Please enter valid Kms";
    }

    setErrObj(error);
    if (valid) {
      submitData();
    }
  }

  function numberWithCommas(x) {
    let c = x.replaceAll(",", "");

    return c.toString().split(".")[0].length > 3
      ? c
          .toString()
          .substring(0, c.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c.toString().substring(c.toString().split(".")[0].length - 3)
      : c.toString();
  }

  function converter(value) {
    let convert = numberWithCommas(value);
    setKmReading(convert);
    setErrObj({
      ...errObj,
      kmErr: false,
      kmMsg: "",
    });
  }

  return (
    <>
      {/* <div className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={"bold"}>Fleet Details</Typography>
        </Box>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : (
          <div style={{ padding: 20 }}>
            <Table>
              <TableRow>
                <TableCell variant="head">Name</TableCell>
                <TableCell>{fleetDetails?.name || "-"}</TableCell>
                <TableCell variant="head">Owner</TableCell>
                <TableCell>{fleetDetails?.owner_name || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Fleet Code</TableCell>
                <TableCell>{fleetDetails?.code || "-"}</TableCell>
                <TableCell variant="head">Owner’s No.</TableCell>
                <TableCell>{fleetDetails?.owner_phone || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Location</TableCell>
                <TableCell>{fleetDetails?.city || "-"}</TableCell>
                <TableCell variant="head">Manager</TableCell>
                <TableCell>{fleetDetails?.manager_name || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Unresolved Issues</TableCell>
                <TableCell
                  style={{
                    color: "red",
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  // onClick={() => {
                  //   navigate("/dashboard/FleetManagement", {
                  //     state: {
                  //       set: "fleetDetails",
                  //       id: fleetDetails?.id,
                  //       name: fleetDetails?.name,
                  //       vehicle_id: fleetDetails?.vehicle_id,
                  //     },
                  //   });
                  // }}
                >
                  {fleetDetails?.pending_issues_count || "-"}
                </TableCell>
                <TableCell variant="head">Manager’s No.</TableCell>
                <TableCell>{fleetDetails?.manager_phone || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Employee Name</TableCell>
                <TableCell>{fleetDetails?.analyzed_by || "-"}</TableCell>
                <TableCell variant="head">Employee Code</TableCell>
                <TableCell>
                  {fleetDetails?.sales_employee_code || "-"}
                </TableCell>
              </TableRow>
            </Table>
          </div>
        )}
      </div> */}
      <VehicleDetailTable vehicleData={{ id: inspectionData?.vehicle_id }} />

      <div className={styles.container} style={{ marginTop: 20, padding: 20 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="tableTitle">Inspection History</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: sm ? "unset" : "flex-end",
            }}
          >
            <Button
              disabled={isOnline ? false : true}
              variant="contained"
              style={{
                marginLeft: 10,
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              onClick={() => {
                getInspectionHistoryList(true, 1);
              }}
            >
              {reportLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                "Download"
              )}
            </Button>
          </Grid>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: 14 }}
          wrap="nowrap"
        >
          <Grid item lg={2}>
            <Select
              fullWidth
              placeholder="Filter"
              value={filter}
              onChange={(v) => {
                handlePageNo(1);
                handleFilter(v.target.value);
              }}
              onOpen={() => {
                if (isEmpty(filter.toString())) {
                  settypeList(false);
                  setbrandList(false);
                }
              }}
              displayEmpty
              IconComponent={FilterAlt}
              style={filter === "" ? { color: "#A2A2A2" } : {}}
              classes={{
                iconOpen: styles.iconOpen,
              }}
            >
              <MenuItem
                value={""}
                hidden
                selected
                disabled
                style={{ minWidth: 220 }}
              >
                Filter
              </MenuItem>
              <ListItemButton
                onClick={() => {
                  settypeList(!typeList);
                  setbrandList(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Vehicle Type</Typography>
                {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              {dropDownData.vehicle_type.map((item, index) => {
                return (
                  <MenuItem
                    style={{
                      display: typeList ? "flex" : "none",
                    }}
                    key={index}
                    value={item?.id}
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
              <ListItemButton
                onClick={() => {
                  setbrandList(!brandList);
                  settypeList(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Vehicle Brand</Typography>
                {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              {dropDownData.vehicle_brand.map((item, index) => {
                return (
                  <MenuItem
                    style={{
                      display: brandList ? "flex" : "none",
                    }}
                    key={index}
                    value={item?.id}
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid
            item
            lg={1.6}
            style={{
              marginLeft: 20,
              display: "flex",
              alignItems: "center",
              alignSelf: "flex-end",
            }}
          >
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                handlePageNo(1);
                handleRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          style={{ marginTop: 14 }}
          wrap="nowrap"
        >
          <Grid
            item
            lg={2}
            style={{ display: "flex", alignItems: "center" }}
            wrap={"nowrap"}
          >
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={searchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && getInspectionHistoryList(true);
                setSearchFilter(v?.target?.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    handlePageNo(1);
                    getInspectionHistoryList(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Vehicle No."
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <div style={{ marginLeft: 20 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                views={["year", "month", "day"]}
                disableFuture
                value={datePicker}
                onChange={(newValue) => {
                  setDatePicker(newValue);
                }}
                inputFormat="dd-MM-yyyy"
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{ svg: { color: color.primary } }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                  handlePageNo(1);
                  getInspectionHistoryList(false);
                }
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={resetFilterData}
              disabled={
                filter === "" && isEmpty(searchFilter) && isNull(datePicker)
                  ? true
                  : false
              }
            >
              Reset
            </Button>
          </div>
        </Grid>
        {pageLoad1 ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(inspectionHistoryList?.data) &&
          !isEmpty(inspectionHistoryList?.data) ? (
          <div style={{ marginTop: 20 }}>
            <DataGrid
              rows={inspectionHistoryList?.data}
              columns={InspectionHistoryColumn}
              disableColumnMenu
              hideFooter
              showCellRightBorder
              disableSelectionOnClick
              showColumnRightBorder
              autoHeight={true}
              getRowHeight={() => "auto"}
              onCellClick={(params) => {
                if (
                  params?.field === "Inspection Reading" ||
                  params?.field === "Delete"
                ) {
                  return null;
                } else {
                  handleClick("vehicleInspection", params?.row);
                }
              }}
            />
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(inspectionHistoryList?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  handlePageNo(e);
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>

      <Modal
        open={visible}
        onClose={() => resetModal()}
        closeAfterTransition
        disableAutoFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visible}>
          <Box sx={style}>
            <div className={styles.modalHeader}>
              <Typography
                style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
              >
                {rowData?.is_reading_pending === 0 ? "Edit" : "Add"}
              </Typography>
              <Close
                style={{ color: color.white, cursor: "pointer" }}
                onClick={() => resetModal()}
              />
            </div>
            <div className={styles.modalMain}>
              <Grid
                container
                alignItems={"center"}
                style={{
                  marginBottom: errObj.kmErr ? 30 : 20,
                  marginTop: 10,
                }}
              >
                <Grid item xs={12} md={3} lg={3}>
                  <CTypography required title={`Inspection Reading`} />
                </Grid>
                <Grid item xs={12} md={9} lg={9}>
                  <TextField
                    disabled={
                      userdata?.role === "fleet_owner" ||
                      userdata?.role === "fleet_manager"
                    }
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                    placeholder={`Enter Inspection Reading`}
                    value={kmReading}
                    className={styles.input}
                    error={errObj.kmErr}
                    helperText={errObj.kmErr ? errObj.kmMsg : null}
                    onChange={(e) => {
                      if (
                        e?.nativeEvent?.data === "0" ||
                        e?.nativeEvent?.data === "1" ||
                        e?.nativeEvent?.data === "2" ||
                        e?.nativeEvent?.data === "3" ||
                        e?.nativeEvent?.data === "4" ||
                        e?.nativeEvent?.data === "5" ||
                        e?.nativeEvent?.data === "6" ||
                        e?.nativeEvent?.data === "7" ||
                        e?.nativeEvent?.data === "8" ||
                        e?.nativeEvent?.data === "9" ||
                        e?.nativeEvent?.data === "." ||
                        isNull(e?.nativeEvent?.data)
                      ) {
                        // converter(e.target.value, "fitment");
                        setKmReading(e.target.value);
                        setErrObj({ ...errObj, kmErr: false, kmMsg: "" });
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Km</InputAdornment>
                      ),
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        checkValidation();
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={
                    (userdata?.role === "fleet_owner" ||
                      userdata?.role === "fleet_manager") &&
                    !isOnline
                  }
                  variant="contained"
                  onClick={checkValidation}
                >
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : rowData?.is_reading_pending === 0 ? (
                    "Save & Update"
                  ) : (
                    "Add Reading"
                  )}
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ConfirmDialog
        title={`Are you sure you want to Delete this Inspection?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteInspection();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </>
  );
}
