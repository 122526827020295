import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Autocomplete,
  FormHelperText,
  Checkbox,
  Popper,
  Divider,
} from "@mui/material";
import useStyles from "./styles.js";
import { Close } from "@mui/icons-material";
import { color } from "../../../Config/theme";
import { isArray, isEmpty, isNull } from "lodash";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import { getApiData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { toast } from "react-toastify";
import { styled } from "@mui/system";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const errorObj = {
  dateErr: false,
  dateMsg: "",
  fleetErr: false,
  fleetMsg: "",
  vehicleErr: false,
  vehicleMsg: "",
};

function CDateRange(props) {
  const {
    visible = false,
    handleModal = () => null,
    disableFleet = false,
    fleetId,
  } = props;
  const { token, fleetData, isOnline } = useSelector((state) => state.auth);
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);
  const [errObj, setErrObj] = useState(errorObj);
  const [vehicleList, setVehicleList] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [checkAll, setCheckAll] = React.useState(false);

  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : md ? 450 : 800,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
  };

  function closeModel() {
    setFleet([]);
    setVehicle([]);
    handleModal("cancel");
    setDatePicker(null);
    setDatePicker1(null);
  }

  useEffect(() => {
    if (visible && disableFleet) {
      getVehicleist();
    }
  }, [disableFleet, visible]);

  useEffect(() => {
    if (isArray(fleet) && !isEmpty(fleet)) {
      getVehicleist();
    } else {
      setVehicle([]);
      setCheckAll(false);
    }
  }, [fleet]);

  useEffect(() => {
    if (isNull(datePicker)) {
      setDatePicker1(null);
    }
  }, [datePicker]);

  async function getVehicleist() {
    const array = [];
    isArray(fleet) &&
      !isEmpty(fleet) &&
      fleet.map((v) => {
        array.push(v.id);
      });

    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicleData}?fleet_id=${
          disableFleet ? fleetId : array
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setVehicleList(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
      // setPageLoad(false);
    } catch (error) {
      // setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function handleSave() {
    const fleetArray = [];
    isArray(fleet) &&
      !isEmpty(fleet) &&
      fleet.map((v) => {
        fleetArray.push(v.id);
      });

    const vehicleArray = [];
    isArray(vehicle) &&
      !isEmpty(vehicle) &&
      vehicle.map((v) => {
        vehicleArray.push(v.id);
      });

    handleModal(
      "download",
      disableFleet ? fleetId : fleetArray,
      vehicleArray,
      datePicker,
      datePicker1,
      checkAll
    );
    setTimeout(() => {
      setFleet([]);
      setVehicle([]);
      setDatePicker(null);
      setDatePicker1(null);
    }, 500);
  }

  return (
    <Modal
      open={visible}
      onClose={() => closeModel()}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={styles.modalHeader}>
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              Download Report
            </Typography>
            <Close
              style={{ color: color.white, cursor: "pointer" }}
              onClick={() => closeModel()}
            />
          </div>
          <div style={{ padding: 20 }}>
            <Grid
              container
              // lg={8.4}
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 20,
              }}
            >
              <Grid
                item
                container
                alignItems={"center"}
                // wrap="nowrap"
              >
                <Grid item md={3} xs={12}>
                  <Typography>From Date :</Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disableFuture
                      views={["year", "month", "day"]}
                      value={datePicker}
                      onChange={(newValue) => {
                        setDatePicker(newValue);
                        setDisable(false);
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                            readOnly: true,
                          }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: !isNull(datePicker) && (
                              <InputAdornment position="start">
                                <IconButton
                                  style={{ lineHeight: 0 }}
                                  onClick={() => setDatePicker(null)}
                                >
                                  <Close style={{ fontSize: 20 }} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{ svg: { color: color.primary } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
              <Grid
                item
                container
                alignItems={"center"}
                marginTop={"20px"}
                // wrap="nowrap"
              >
                <Grid item md={3} xs={12}>
                  <Typography>To Date:</Typography>
                </Grid>
                <Grid item md={9} xs={12}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      disabled={isNull(datePicker) ? true : disable}
                      minDate={datePicker}
                      views={["year", "month", "day"]}
                      disableFuture
                      value={datePicker1}
                      inputFormat="dd-MM-yyyy"
                      onChange={(newValue) => {
                        setDatePicker1(newValue);
                      }}
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          InputProps={{
                            ...params.InputProps,
                            error: errObj.dateErr,
                            helperText: errObj.dateErr ? errObj.dateMsg : null,
                            startAdornment: !isNull(datePicker1) && (
                              <InputAdornment position="start">
                                <IconButton
                                  style={{ lineHeight: 0 }}
                                  onClick={() => setDatePicker1(null)}
                                >
                                  <Close style={{ fontSize: 20 }} />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            svg: {
                              color:
                                disable || isNull(datePicker)
                                  ? ""
                                  : color.primary,
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
            <div className={styles.modalMain}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  disabled={
                    !isOnline
                      ? true
                      : !isNull(datePicker) && isNull(datePicker1)
                  }
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Download
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                  onClick={closeModel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default CDateRange;
