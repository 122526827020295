import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { Check, Close, FilterAlt, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";
import ConfirmDialog from "../../ConfirmDialog";

function RemovalTyreRequest(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleFilter = () => null,
    handleSearch = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    searchFilter = "",
  } = props;
  // const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  const [unresolvedIssueRows, setUnresolvedIssueRows] = useState({});
  const [datePicker, setDatePicker] = useState(null);
  const styles = useStyles();

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [removeData, setRemoveData] = useState({});

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const { token, isOnline, userdata } = useSelector((state) => state.auth);

  const UnresolvedIssueColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "inspection_date",
      headerName: "Requested By",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.requested_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography>{params?.row?.requested_by || "-"}</Typography>
            <Typography>{timestamp ? displayDate : ""}</Typography>
          </div>
        );
      },
    },
    // {
    //   field: "change_requested",
    //   headerName: "Change Requested",
    //   width: lg ? 220 : "unset",
    //   flex: !lg ? 1 : "unset",
    //   sortable: false,
    //   renderCell: (params) => {
    //     return (
    //       <Typography
    //         style={{
    //           color: color.primary,
    //           fontWeight: "bold",
    //           cursor: "pointer",
    //         }}
    //         onClick={() => {
    //           handleClick("vehicleInspectionChanges", {
    //             vehicle_id: params?.row?.vehicle_id,
    //             capture_date: params?.row?.inspection_date,
    //             vehicle_no: params?.row?.vehicle_no,
    //             request_id: params?.row?.id,
    //             status: params?.row?.status,
    //           });
    //         }}
    //       >
    //         View
    //       </Typography>
    //     );
    //   },
    // },
    {
      field: "Status",
      headerName: "Status",
      sortable: false,
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return params?.row?.status === 0 &&
          (userdata?.role === "superadmin" || userdata?.role === "admin") ? (
          <div>
            <Button
              style={{
                width: 100,
                backgroundColor: color.green,
              }}
              onClick={() => {
                setConfirmDialog(true);
                setRemoveData({ status: 1, id: params?.row?.id });
              }}
            >
              Approve
              <Check />
            </Button>
            <Button
              style={{
                width: 100,
                backgroundColor: "#ff3333",
                marginLeft: 20,
              }}
              onClick={() => {
                setConfirmDialog(true);
                setRemoveData({ status: 2, id: params?.row?.id });
              }}
            >
              Reject
              <Close />
            </Button>
          </div>
        ) : (
          <div>
            <Typography
              style={{
                color:
                  params?.row?.status === 1
                    ? "#27AE60"
                    : params?.row?.status === 2
                    ? "#EB5757"
                    : "black",
              }}
            >
              {params?.row?.status === 1
                ? "Approved"
                : params?.row?.status === 2
                ? "Rejected"
                : "Pending"}
            </Typography>
            <Typography>{params?.row?.request_remark}</Typography>
          </div>
        );
      },
    },
  ];

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  const UpdateDateCall = async () => {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.removalrequestupdate}?id=${removeData?.id}&status=${removeData?.status}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setConfirmDialog(false);
        setRemoveData({});
        RemovalTyreRequestList(false);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
    }
  };

  useEffect(() => {
    if (isOnline) {
      document.title = Setting.page_name.Inspection;
    }
  }, [isOnline]);

  useEffect(() => {
    setPageLoad(true);
    RemovalTyreRequestList(false);
  }, [pageNo, record, filter]);

  // useEffect(() => {
  //   isNull(datePicker) &&
  //     isEmpty(searchFilter) &&
  //     RemovalTyreRequestList(false);
  // }, [datePicker, searchFilter]);

  useImperativeHandle(ref, () => ({
    unresolvedIssueRows: unresolvedIssueRows,
    RemovalTyreRequestList: () => {
      RemovalTyreRequestList(false, 1);
    },
  }));

  async function RemovalTyreRequestList(bool, v) {
    v && handleLoader(true);
    !v && setPageLoad(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyreremovalrequestlist
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : searchFilter
        }&filter=${filter}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadRemovalData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setUnresolvedIssueRows(listObj);
          }
        v && auditSave("Tyre Removal Request List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      handleLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
    handleLoader(false);
  }

  function downloadRemovalData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.requested_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Tyre Serial No.": row?.tyre_serial_no,
        "Requested By": `${row?.requested_by} ${
          row?.requested_at ? displayDate : "-"
        }`,
        Status:
          row?.status === 0
            ? "Pending"
            : row?.status === 1
            ? "Approved"
            : "Rejected",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Tyre Removal Request List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Removal Request List.xlsx");
  }

  function resetFilterData() {
    handleSearch("");
    setDatePicker(null);
    handleFilter("");
    handlePageNo(1);
    RemovalTyreRequestList(true);
  }

  return (
    <div className={styles.container}>
      <div style={{ marginTop: 14 }}>
        <Grid container alignItems="center" wrap="nowrap" gap={2}>
          <Grid item lg={2}>
            <Select
              fullWidth
              placeholder="Filter"
              value={filter}
              onChange={(v) => {
                handlePageNo(1);
                handleFilter(v.target.value);
              }}
              displayEmpty
              IconComponent={FilterAlt}
              style={filter === "" ? { color: "#A2A2A2" } : {}}
              classes={{
                iconOpen: styles.iconOpen,
              }}
            >
              <MenuItem value={""} hidden selected disabled>
                Filter
              </MenuItem>
              <MenuItem value={0}>Pending</MenuItem>
              <MenuItem value={1}>Approved</MenuItem>
              <MenuItem value={2}>Rejected</MenuItem>
              {/* <MenuItem value={3}>Partially Approved</MenuItem> */}
            </Select>
          </Grid>

          <Grid item lg={2.5} wrap={"nowrap"}>
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={searchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && RemovalTyreRequestList(true);
                handleSearch(v?.target?.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    handlePageNo(1);
                    RemovalTyreRequestList(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Tyre Serial No."
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* <div style={{ marginLeft: 20 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                views={["year", "month", "day"]}
                disableFuture
                value={datePicker}
                onChange={(newValue) => {
                  setDatePicker(newValue);
                }}
                inputFormat="dd-MM-yyyy"
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{ svg: { color: color.primary } }}
                  />
                )}
              />
            </LocalizationProvider>
          </div> */}
          <Grid item>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                  handlePageNo(1);
                  RemovalTyreRequestList(false);
                }
              }}
            >
              Search
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={resetFilterData}
              disabled={
                filter === "" && isEmpty(searchFilter) && isNull(datePicker)
                  ? true
                  : false
              }
            >
              Reset
            </Button>
          </Grid>

          <Grid
            item
            lg={1.6}
            style={{
              marginLeft: "auto",
              display: "flex",
              alignItems: "center",
              alignSelf: "flex-end",
            }}
          >
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                handlePageNo(1);
                handleRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems="center"
          style={{ marginTop: 14 }}
          wrap="nowrap"
        ></Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(unresolvedIssueRows?.data) &&
          !isEmpty(unresolvedIssueRows?.data) ? (
          <>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                rows={unresolvedIssueRows?.data}
                columns={UnresolvedIssueColumns}
                onCellClick={(params) => {
                  if (params?.field !== "action") {
                    handleClick("fleetDetails", params?.row);
                  }
                }}
                pageSize={record}
                disableColumnMenu
                autoHeight={true}
                hideFooter
                disableSelectionOnClick
                showCellRightBorder
                getRowHeight={() => "auto"}
                showColumnRightBorder
              />
            </div>
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(unresolvedIssueRows?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  handlePageNo(e);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
      <ConfirmDialog
        title={
          removeData?.status === 1
            ? `Are you sure you want to Approve Tyre Removal Request?`
            : `Are you sure you want to Reject Tyre Removal Request?`
        }
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            UpdateDateCall();
          } else {
            setConfirmDialog(false);
            setRemoveData({});
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </div>
  );
}
export default forwardRef(RemovalTyreRequest);
