import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Navigate,
  Route,
} from "react-router-dom";

import Login from "../Screens/Login/Login";
import Forgot from "../Screens/Login/Forgot";
import PasswordRecovery from "../Screens/Login/PasswordRecovery";
import Offline from "../Screens/Offline";
import { useSelector } from "react-redux";

function LoginStack() {
  const { isOnline } = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route exact path={"/"} element={<Navigate to="/login" />} />
      <Route path="/login" element={isOnline ? <Login /> : <Offline />} />
      <Route
        exact
        path={"/forgot-password"}
        element={isOnline ? <Forgot /> : <Offline />}
      />
      <Route
        exact
        path={"/forgot-password/password-recovery"}
        element={isOnline ? <PasswordRecovery /> : <Offline />}
      />
      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
}

export default LoginStack;
