import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Pagination,
  CircularProgress,
  Tooltip,
  ListItemButton,
  ImageListItem,
  ImageList,
  Modal,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { CopyAll, Edit, FilterAlt, Search } from "@mui/icons-material";
import useStyles from "./styles";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { color } from "../../../Config/theme";
import ConfirmDialog from "../../ConfirmDialog";
import * as XLSX from "xlsx/xlsx.mjs";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import moment from "moment";
import { isMobile } from "react-device-detect";

function LifeHistory(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleFilter = () => null,
    handleSearch = () => null,
    search = "",
    filter = "",
    pageNo = 1,
    record = 10,
  } = props;
  // const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  const [lifeHistoryRows, setLifeHistoryRows] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [deleteDataID, setDeleteDataID] = useState("");
  const [removalList, setRemovalList] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [image, setImage] = useState("");
  const styles = useStyles();

  const { token, dropDownData, userdata } = useSelector((state) => state.auth);

  const column = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      hide:
        userdata?.role === "superadmin" || userdata?.role === "admin"
          ? false
          : true,
      renderCell: (params) => {
        return (
          <Tooltip title="Edit" placement="right" arrow>
            <Edit
              style={{ color: color.primary, cursor: "pointer" }}
              onClick={() => {
                handleClick("Edit", params?.row);
              }}
            />
          </Tooltip>
        );
      },
    },

    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 220,
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 220,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
            }}
          >
            <div
              style={{ display: "flex", flex: 1 }}
              onClick={() => {
                handleClick("tyreDetails", params?.row);
              }}
            >
              <Typography
                style={{
                  whiteSpace: "pre-wrap",
                  color: color.primary,
                  fontWeight: "bold",
                }}
              >
                {params?.row?.tyre_serial_no}
              </Typography>
            </div>
            <CopyAll
              style={{ cursor: "pointer", marginLeft: 20 }}
              onClick={() => {
                navigator.clipboard.writeText(params?.row?.tyre_serial_no);
                toast.success("Copied!", { toastId: 10 });
              }}
            />
          </div>
        );
      },
    },
    {
      field: "tyre_condition",
      headerName: "Tyre Condition",
      width: 220,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.tyre_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.tyre_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.tyre_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.tyre_condition || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "tyre_position",
      headerName: "Tyre Position",
      width: 220,
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 220,
    },
    {
      field: "item_descryption",
      headerName: "Item Description",
      width: 300,
      renderCell: (params) => {
        return params?.row?.item_descryption || "-";
      },
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 220,
      renderCell: (params) => {
        return params?.row?.tyre_composition || "-";
      },
    },
    {
      field: "ply_rating",
      headerName: "Ply Rating",
      width: 220,
      renderCell: (params) => {
        return params?.row?.ply_rating || "-";
      },
    },
    {
      field: "avg_fitment_nsd",
      headerName: "Avg. Fitment NSD",
      width: 220,
      renderCell: (params) => {
        return (
          // <div
          //   style={{
          //     backgroundColor:
          //       params?.row?.avg_fitment_nsd < 5 ? "#FFD9D9" : "",
          //     padding: 8,
          //     borderRadius: 4,
          //   }}
          // >
          <Typography>{`${params?.row?.avg_fitment_nsd} mm` || "-"}</Typography>
          // </div>
        );
      },
    },
    {
      field: "tyre_fitment_km",
      headerName: "Tyre Fitment Reading",
      width: 220,
      renderCell: (params) => {
        return (
          <Typography>{params?.row?.tyre_fitment_km || "-"} km</Typography>
        );
      },
    },
    {
      field: "Current NSD",
      headerName: "Dismount NSD",
      width: 260,
      renderCell: (params) => {
        const nsdArr = params?.row?.current_nsd;
        let groove1 = 0;
        let groove2 = 0;
        let groove3 = 0;
        let groove4 = 0;
        let count1 = 1;
        let count2 = 1;
        let count3 = 1;
        let count4 = 1;
        isArray(nsdArr) &&
          !isEmpty(nsdArr) &&
          nsdArr.map((v) => {
            if (!isEmpty(v?.groove1)) {
              groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
              count1++;
            }
            if (!isEmpty(v?.groove2)) {
              groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
              count2++;
            }
            if (!isEmpty(v?.groove3)) {
              groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
              count3++;
            }
            if (!isEmpty(v?.groove4)) {
              groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
              count4++;
            }
          });
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
              groove2 !== 0 ? groove2.toFixed(2) : " - "
            }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
              groove4 !== 0 ? groove4.toFixed(2) : " - "
            } mm`}
          </Typography>
        );
      },
    },
    {
      field: "avg_nsd",
      headerName: "Avg. Dismount NSD",
      width: 220,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor: params?.row?.avg_nsd < 5 ? "#FFD9D9" : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{`${params?.row?.avg_nsd} mm` || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "dismount_km",
      headerName: "Dismount Reading",
      width: 220,
      renderCell: (params) => {
        return <Typography>{params?.row?.dismount_km || "-"} Km</Typography>;
      },
    },
    {
      field: "removal_type",
      headerName: "Removal Type",
      width: 220,
      renderCell: (params) => {
        return (
          <Typography style={{ whiteSpace: "pre-wrap" }}>
            {params?.row?.removal_type}
          </Typography>
        );
      },
    },
    {
      field: "removed_images",
      headerName: "Images",
      width: 180,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Typography
            onClick={() => {
              if (
                isArray(params?.row?.removal_images) &&
                !isEmpty(params?.row?.removal_images)
              ) {
                setImage(params?.row?.removal_images);
                setOpenCard(true);
              } else {
                return null;
              }
            }}
            color={
              isArray(params?.row?.removal_images) &&
              !isEmpty(params?.row?.removal_images)
                ? color.primary
                : color.disable
            }
          >
            View
          </Typography>
        );
      },
    },
    {
      field: "removed_at",
      headerName: "Removed On",
      width: 220,
      renderCell: (params) => {
        const timestamp = params?.row?.removed_at;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");

        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : "-"}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    lifeHistoryList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    filter === "" && isEmpty(search) && lifeHistoryList(false);
  }, [filter, search]);

  useImperativeHandle(ref, () => ({
    lifeHistoryRows: lifeHistoryRows,
    downloadLifeData: () => {
      lifeHistoryList(false, 1);
    },
  }));

  function downloadLifeData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.removed_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const nsdArr = row?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Condition": row?.tyre_condition || "-",
        "Tyre Position": row?.tyre_position || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.item_descryption || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating || "-",
        "Avg. Fitment NSD (mm)": row?.avg_fitment_nsd || "-",
        "Tyre Fitment Reading (Km)": Number(
          row?.tyre_fitment_km.toString().replaceAll(",", "")
        ),
        "Dismount NSD (mm)": `${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
          groove2 !== 0 ? groove2.toFixed(2) : " - "
        }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
          groove4 !== 0 ? groove4.toFixed(2) : " - "
        }`,
        "Avg. Dismount NSD (mm)": Number(row?.avg_nsd),
        "Dismount Reading (Km)": Number(
          row?.dismount_km.toString().replaceAll(",", "")
        ),
        "Removal Type": row?.removal_type || "-",
        Images: row?.removal_images?.toString() || "-",
        "Removed On": displayDate || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Life History List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Life History List.xlsx");
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function lifeHistoryList(bool, v) {
    v && handleLoader(true);
    !v && setPageLoad(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.lifehistorylist
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : search
        }&filter=${filter}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadLifeData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setLifeHistoryRows(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Life History List");
      setPageLoad(false);
      handleLoader(false);
    } catch (error) {
      setPageLoad(false);
      handleLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function deleteData() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.removelifehistory}?id=${deleteDataID}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setConfirmDialog(false);
        lifeHistoryList(false);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setConfirmDialog(false);
      setRemoveBtnLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function resetFilterData() {
    handleFilter("");
    handleSearch("");
    handlePageNo(1);
  }

  return (
    <>
      <div style={{ marginTop: 14 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid
            item
            lg={10.4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item lg={1.6}>
              <Select
                fullWidth
                placeholder="Filter"
                value={filter}
                IconComponent={FilterAlt}
                displayEmpty
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    setRemovalList(false);
                  }
                }}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value={""} disabled hidden selected>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    setRemovalList(!removalList);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Removal Type</Typography>
                  {removalList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.tyre_removal_issue.map((item, index) => {
                  return (
                    <MenuItem
                      style={{ display: removalList ? "flex" : "none" }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item lg={4} style={{ marginLeft: 20 }}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && lifeHistoryList(true);
                  handleSearch(v?.target?.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Tyre Serial No, Fleet Name and Vehicle No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(search)) {
                      handlePageNo(1);
                      lifeHistoryList(false);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item wrap="nowrap" style={{ marginLeft: 20 }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(search)) {
                    handlePageNo(1);
                    lifeHistoryList(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={filter === "" && isEmpty(search) ? true : false}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            lg={1.6}
            style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
          >
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                handlePageNo(1);
                handleRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(lifeHistoryRows?.data) &&
          !isEmpty(lifeHistoryRows?.data) ? (
          <>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                pageSize={record}
                rows={lifeHistoryRows?.data}
                columns={column}
                disableColumnMenu
                hideFooter
                showCellRightBorder
                disableSelectionOnClick
                showColumnRightBorder
                autoHeight={true}
                getRowHeight={() => "auto"}
              />
            </div>
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(lifeHistoryRows?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  handlePageNo(e);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
      <ConfirmDialog
        title={`Are you sure you want to Delete?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteData();
          } else {
            setDeleteDataID("");
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />

      <Modal
        onClose={() => {
          setOpenCard(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            minWidth: "40vw",
            minHeight: "65vh",
            backgroundColor: "white",
            padding: 10,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={1}
            gap={9}
            style={{
              overflowY: "scroll",
              maxHeight: "65vh",
              maxWidth: "100%",
            }}
          >
            {isArray(image) &&
              !isEmpty(image) &&
              image?.map((item, index) => {
                return (
                  <ImageListItem
                    key={index}
                    style={{
                      width: isMobile ? "60vw" : "100%",
                      height: isMobile ? "80vh" : "100%",
                      border: item?.selected
                        ? `1px solid ${color.primary}`
                        : item?.disable
                        ? `1px solid ${color.disable}`
                        : "",
                    }}
                  >
                    <img src={item} alt="" />
                  </ImageListItem>
                );
              })}
          </ImageList>
        </div>
      </Modal>
    </>
  );
}

export default forwardRef(LifeHistory);
