import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    boxSizing: "border-box",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none !important",
    },
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  autocomplete: {
    paddingLeft: "0px !important",
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
