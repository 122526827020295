import React from "react";
import {
  Backdrop,
  Fade,
  Grid,
  Modal,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { color } from "../../../Config/theme";
import Images from "../../../Config/Images";
import { Box } from "@mui/system";
import { Close } from "@mui/icons-material";
import { useTheme } from "@emotion/react";
import { isArray, isEmpty } from "lodash";

export default function TyreIssue(props) {
  const {
    visible,
    handleModal = () => null,
    modalData,
    from,
    index,
    side,
  } = props;
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 300,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    overFlow: "hidden",
  };

  function nsd() {
    const nsdArr = modalData?.latest_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return `${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
      groove2 !== 0 ? groove2.toFixed(2) : " - "
    }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
      groove4 !== 0 ? groove4.toFixed(2) : " - "
    } mm`;
  }

  return (
    <Modal
      open={visible}
      onClose={() => handleModal()}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style} style={{ overflow: "auto", position: "relative" }}>
          {from === "Axle" ? (
            <Grid
              container
              style={{ padding: 30, overflow: "hidden" }}
              justifyContent={"center"}
            >
              <Close
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 6,
                  top: 6,
                }}
                onClick={() => handleModal()}
              />
              <Grid>
                <Typography style={{ fontSize: 14 }} noWrap>
                  <b>Axle :</b> {index}
                </Typography>
                <Typography style={{ fontSize: 14, margin: "5px 0" }} noWrap>
                  <b>Side :</b> {side}
                </Typography>
                <Typography style={{ fontSize: 14 }} noWrap>
                  <b>Axle Issues :</b> {modalData?.issue?.toString() || "-"}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            <Grid
              container
              style={{ padding: 40, overflow: "hidden" }}
              justifyContent={"center"}
            >
              <Close
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 6,
                  top: 4,
                }}
                onClick={() => handleModal()}
              />
              <Grid
                container
                display="flex"
                flexDirection="column"
                alignItems={"center"}
              >
                <Grid item container justifyContent={"center"}>
                  <img
                    src={
                      modalData?.tyre_condition === "Good"
                        ? Images.goodWheel
                        : modalData?.tyre_condition === "Bad"
                        ? Images.badWheel
                        : Images.averageWheel
                    }
                    alt={Images.goodWheel}
                  />
                </Grid>
                <Grid item container flexDirection={"column"} marginTop={1}>
                  <Typography style={{ fontSize: 14 }} noWrap>
                    <b>Position :</b> {modalData?.tyre_position}
                  </Typography>
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: 14,
                    }}
                    noWrap
                  >
                    <b>TSN :</b> {modalData?.tyre_serial_no}
                  </Typography>
                  <Typography
                    style={{
                      color:
                        modalData?.tyre_condition === "Good"
                          ? color.green
                          : modalData?.tyre_condition === "Bad"
                          ? "#EB5757"
                          : "#F2994A",
                      fontWeight: "bold",
                      whiteSpace: "nowrap",
                      fontSize: 14,
                    }}
                  >
                    Condition : {modalData?.tyre_condition}
                  </Typography>
                  <Typography style={{ fontSize: 14 }}>
                    <b>Tyre Issue :</b>{" "}
                    {isArray(modalData?.tyre_issue) &&
                    !isEmpty(modalData?.tyre_issue)
                      ? modalData?.tyre_issue?.map((v, i) => (
                          <>
                            {v}
                            {i < modalData?.tyre_issue.length - 1 ? ", " : ""}
                          </>
                        ))
                      : "-"}
                  </Typography>
                  <Typography
                    style={{
                      fontSize: 14,
                    }}
                  >
                    <b>Latest NSD :</b> {nsd()}
                  </Typography>
                  {/* <Typography style={{ fontSize: 14 }}>
                    <b>Mechanical Issue :</b>{" "}
                    {isArray(modalData?.mechanical_issue) &&
                    !isEmpty(modalData?.mechanical_issue)
                      ? modalData?.mechanical_issue?.map((v, i) => (
                          <>
                            {v}
                            {i < modalData?.mechanical_issue.length - 1
                              ? ", "
                              : ""}
                          </>
                        ))
                      : "-"}
                  </Typography> */}
                </Grid>
              </Grid>
            </Grid>
          )}
        </Box>
      </Fade>
    </Modal>
  );
}
