import React, { useEffect, useState } from "react";
import { color } from "../../../Config/theme.js";
import useStyles from "./styles";
import { Setting } from "../../../Utils/Setting";
import { getApiData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { isArray, isEmpty, isNumber, isObject } from "lodash";
import {
  Box,
  CircularProgress,
  Grid,
  ListItemButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { toast } from "react-toastify";
import { Chart } from "react-google-charts";
import Images from "../../../Config/Images.js";
import TabVehicleAttention from "../../../Components/TabDashboard/TabVehicleAttention/index.js";
import { isTablet } from "react-device-detect";
import { useNavigate, useLocation } from "react-router-dom";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

export default function TabDashboard() {
  const styles = useStyles();
  const { token, userdata, dropDownData, fleetData } = useSelector(
    (state) => state.auth
  );
  const [dashboardData, setDashboardData] = useState({});
  const [tyreData, setTyreData] = useState([]);
  const [mechData, setmechData] = useState([]);
  const location = useLocation();

  const fromNotification = location?.state?.from === "notification";
  const [districtList, setDistrictList] = useState(false);
  const [RoList, setRoList] = useState(false);
  const [fleetList, setFleetList] = useState(false);

  const [mechLoader, setmechLoader] = useState(true);
  const [tyreLoader, settyreLoader] = useState(true);

  const [districtList1, setDistrictList1] = useState(false);
  const [RoList1, setRoList1] = useState(false);
  const [fleetList1, setFleetList1] = useState(false);
  const navigate = useNavigate();

  const [Mechtype, setMechType] = useState(
    userdata?.role === "service_enginner" ||
      userdata?.role === "fleet_owner" ||
      userdata?.role === "fleet_manager"
      ? "fleet"
      : "ro"
  );
  const [MechtypeId, setMechTypeId] = useState("");
  const [tyretypeId, setTyreTypeId] = useState("");
  const [tyretype, setTyreType] = useState(
    userdata?.role === "service_enginner" ||
      userdata?.role === "fleet_owner" ||
      userdata?.role === "fleet_manager"
      ? "fleet"
      : "ro"
  );

  useEffect(() => {
    if (fromNotification) {
      const section = document.querySelector("#vehicleIns");
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [fromNotification]);

  useEffect(() => {
    document.title = Setting.page_name.Dashboard;
    if (token !== "") {
      getDashboardData();
      // getroGraphdata("tyre");
      // getroGraphdata("mechanical");
    }
  }, [token]);

  useEffect(() => {
    getroGraphdata("mechanical");
  }, [MechtypeId]);

  useEffect(() => {
    getroGraphdata("tyre");
  }, [tyretypeId]);

  function creatRoGraph(data, type) {
    const dummy_Arr = [["", "Issues"]];

    if (isArray(data) && !isEmpty(data)) {
      data.map((item, index) => {
        const arr = [item?.issue_name, Number(item?.count)];
        dummy_Arr?.push(arr);
      });
      type === "tyre" && setTyreData(dummy_Arr);
      type === "mechanical" && setmechData(dummy_Arr);
    }

    type === "tyre" && settyreLoader(false);
    type === "mechanical" && setmechLoader(false);
  }

  async function getDashboardData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.systemdashboard}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          setDashboardData(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  async function getroGraphdata(category) {
    category === "tyre" && settyreLoader(true);
    category === "mechanical" && setmechLoader(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.rograph}?category=${category}&type=${
          category === "tyre" ? tyretype : Mechtype
        }&id=${category === "tyre" ? tyretypeId : MechtypeId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response?.data) &&
          isArray(response?.data?.issues_graph) &&
          !isEmpty(response.data?.issues_graph)
        ) {
          creatRoGraph(response?.data?.issues_graph, category);
        } else {
          category === "tyre" && setTyreData([]);
          category === "mechanical" && setmechData([]);
        }
      } else {
        toast.error(response?.message);
      }
      category === "tyre" && settyreLoader(false);
      category === "mechanical" && setmechLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      category === "mechanical" && setmechLoader(false);
      category === "tyre" && settyreLoader(false);
    }
  }
  const options = {
    colors: ["#EB5757", "#32C5D2"],
    legend: { position: "none" },
  };
  useEffect(() => {
    if (!fromNotification) {
      const section = document.querySelector("#jump_to_me");
      section.scrollIntoView({
        behavior: "auto",
        block: "end",
        inline: "start",
      });
    }
  }, []);

  return (
    <>
      {isTablet ? (
        <div className={styles.card} style={{ padding: 20 }}>
          <Grid item container>
            <Grid
              container
              className={styles.container}
              style={{
                height: 300,
                marginBottom: 15,
                padding: 15,
              }}
            >
              <Grid
                item
                container
                style={{
                  marginBottom: 20,
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
                wrap="nowrap"
                gap={1}
              >
                <Typography id="jump_to_me" variant="tableTitle">
                  Mechanical Issues
                </Typography>
                {userdata?.role === "service_enginner" ||
                userdata?.role === "fleet_owner" ||
                userdata?.role === "fleet_manager" ? (
                  <Select
                    value={MechtypeId}
                    onChange={(v) => {
                      setMechTypeId(v.target.value);
                    }}
                    displayEmpty
                    style={MechtypeId === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden>
                      Fleet
                    </MenuItem>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          selected={
                            item?.id === userdata?.last_assigned_fleet_id
                          }
                          value={item?.id}
                          onClick={() => setMechType("fleet")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    value={MechtypeId}
                    onChange={(v) => {
                      setMechTypeId(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(MechtypeId.toString())) {
                        setRoList(false);
                        setDistrictList(false);
                        setFleetList(false);
                      }
                    }}
                    displayEmpty
                    style={MechtypeId === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden>
                      Select
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setRoList(!RoList);
                        setDistrictList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Regional Office</Typography>
                      {RoList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData?.office_region?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: RoList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                          selected={
                            userdata?.role !== "service_enginner" &&
                            item?.id === 141
                          }
                          onClick={() => setMechType("ro")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setDistrictList(!districtList);
                        setRoList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>District</Typography>
                      {districtList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.district?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: districtList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                          onClick={() => setMechType("district")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setDistrictList(false);
                        setRoList(false);
                        setFleetList(!fleetList);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Fleet</Typography>
                      {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          selected={
                            (userdata?.role === "service_enginner" ||
                              userdata?.role === "fleet_owner" ||
                              userdata?.role === "fleet_manager") &&
                            item?.id === userdata?.last_assigned_fleet_id
                          }
                          style={{ display: fleetList ? "flex" : "none" }}
                          value={item?.id}
                          onClick={() => setMechType("fleet")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </Grid>
              {mechLoader ? (
                <div className={styles.dataMain}>
                  <CircularProgress
                    style={{
                      color: color.primary,
                    }}
                  />
                </div>
              ) : !isNumber(MechtypeId) ? (
                <div className={styles.dataMain}>
                  <Typography>
                    Please Select Value From The Above Dropdown
                  </Typography>
                </div>
              ) : isArray(mechData) && !isEmpty(mechData) ? (
                <div
                  style={{
                    width: "100%",
                    height: "75%",
                    overflowX: "scroll",
                  }}
                >
                  <Chart
                    chartType="Bar"
                    data={mechData}
                    width="1500px"
                    height="95%"
                    options={{
                      colors: [color.primary],
                      legend: { position: "none" },
                    }}
                  />
                </div>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </Grid>
            <Grid
              container
              className={styles.container}
              style={{ height: 300, padding: 15 }}
            >
              <Grid
                item
                container
                style={{
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginBottom: 20,
                }}
                wrap="nowrap"
                gap={1}
              >
                <Typography variant="tableTitle">Tyre Issues</Typography>
                {userdata?.role === "service_enginner" ||
                userdata?.role === "fleet_owner" ||
                userdata?.role === "fleet_manager" ? (
                  <Select
                    value={tyretypeId}
                    onChange={(v) => {
                      setTyreTypeId(v.target.value);
                    }}
                    displayEmpty
                    style={tyretypeId === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden>
                      Select
                    </MenuItem>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item?.id}
                          onClick={() => setTyreType("fleet")}
                          selected={
                            (userdata?.role === "service_enginner" ||
                              userdata?.role === "fleet_owner" ||
                              userdata?.role === "fleet_manager") &&
                            item?.id === userdata?.last_assigned_fleet_id
                          }
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    value={tyretypeId}
                    onChange={(v) => {
                      setTyreTypeId(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(tyretypeId.toString())) {
                        setRoList1(false);
                        setDistrictList1(false);
                        setFleetList1(false);
                      }
                    }}
                    displayEmpty
                    style={tyretypeId === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden>
                      Select
                    </MenuItem>
                    {userdata?.role !== "service_enginner" && (
                      <ListItemButton
                        onClick={() => {
                          setRoList1(!RoList1);
                          setDistrictList1(false);
                          setFleetList1(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Regional Office</Typography>
                        {RoList1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItemButton>
                    )}
                    {dropDownData?.office_region?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: RoList1 ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                          selected={
                            userdata?.role !== "service_enginner" &&
                            item?.id === 141
                          }
                          onClick={() => setTyreType("ro")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    {userdata?.role !== "service_enginner" && (
                      <ListItemButton
                        onClick={() => {
                          setDistrictList1(!districtList1);
                          setRoList1(false);
                          setFleetList1(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>District</Typography>
                        {districtList1 ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItemButton>
                    )}
                    {dropDownData?.district?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: districtList1 ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                          onClick={() => setTyreType("district")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setDistrictList1(false);
                        setRoList1(false);
                        setFleetList1(!fleetList1);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Fleet</Typography>
                      {fleetList1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: fleetList1 ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                          onClick={() => setTyreType("fleet")}
                          selected={
                            (userdata?.role === "service_enginner" ||
                              userdata?.role === "fleet_owner" ||
                              userdata?.role === "fleet_manager") &&
                            item?.id === userdata?.last_assigned_fleet_id
                          }
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </Grid>
              {tyreLoader ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : !isNumber(tyretypeId) ? (
                <div className={styles.dataMain}>
                  <Typography>
                    Please Select Value From The Above Dropdown
                  </Typography>
                </div>
              ) : isArray(tyreData) && !isEmpty(tyreData) ? (
                <div
                  style={{
                    width: "100%",
                    height: "75%",
                    overflowX: "scroll",
                  }}
                >
                  <Chart
                    chartType="Bar"
                    data={tyreData}
                    width="2000px"
                    height="95%"
                    options={{
                      colors: [color.primary],
                      legend: { position: "none" },
                    }}
                  />
                </div>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </Grid>
          </Grid>
          <Grid
            container
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={() => navigate("/dashboard/FleetManagement")}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card7}
                  alt={Images.card7}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: isTablet ? "9%" : "22%",
                    left: "10%",
                  }}
                >
                  {dashboardData?.totalfleet || 0}
                </Typography>
              </Grid>
            </Box>
            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={() =>
                navigate("/dashboard/FleetManagement", {
                  state: { set: "vehicleList" },
                })
              }
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card8}
                  alt={Images.card8}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: isTablet ? "9%" : "22%",
                    left: "10%",
                  }}
                >
                  {dashboardData?.totalVehicles || 0}
                </Typography>
              </Grid>
            </Box>
            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={() => {
                navigate("/dashboard/Inspection", {
                  state: { set: "inspectionHistory" },
                });
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card6}
                  alt={Images.card6}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: isTablet ? "9%" : "22%",
                    left: "10%",
                  }}
                >
                  {dashboardData?.inspected_today || 0}
                </Typography>
              </Grid>
            </Box>
            <Box
              style={{ width: "100%" }}
              onClick={() => {
                navigate("/dashboard/Inspection");
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card5}
                  alt={Images.card5}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: isTablet ? "9%" : "22%",
                    left: "10%",
                  }}
                >
                  {dashboardData?.total_tyre_issue || 0}
                </Typography>
              </Grid>
            </Box>
          </Grid>
          <div className={styles.container} id="vehicleIns">
            <TabVehicleAttention />
          </div>
        </div>
      ) : (
        <div className={styles.card} style={{ padding: 10 }}>
          <Grid
            container
            alignItems={"center"}
            justifyContent="space-between"
            style={{ minHeight: 60, marginBottom: 10, padding: 10 }}
            wrap="nowrap"
            backgroundColor={color.secondary}
            color={color.white}
            borderRadius={1}
          >
            <Typography variant="tableTitle">Dashboard</Typography>
          </Grid>
          <Grid
            className={styles.container}
            style={{ height: 400, marginBottom: 10, padding: 10 }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 20,
                boxSizing: "border-box",
              }}
            >
              <div style={{ width: "50%" }}>
                <Typography id="jump_to_me" variant="tableTitle">
                  Mechanical Issues
                </Typography>
              </div>
              <div
                style={{ width: "50%", justifyContent: "end", display: "flex" }}
              >
                {userdata?.role === "service_enginner" ||
                userdata?.role === "fleet_owner" ||
                userdata?.role === "fleet_manager" ? (
                  <Select
                    value={MechtypeId}
                    onChange={(v) => {
                      setMechTypeId(v.target.value);
                    }}
                    displayEmpty
                    style={MechtypeId === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                      select: styles.txt,
                    }}
                  >
                    <MenuItem value={""} disabled hidden>
                      Fleet
                    </MenuItem>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          selected={
                            item?.id === userdata?.last_assigned_fleet_id
                          }
                          value={item?.id}
                          onClick={() => setMechType("fleet")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    classes={{
                      iconOpen: styles.iconOpen,
                      select: styles.txt,
                    }}
                    value={MechtypeId}
                    onChange={(v) => {
                      setMechTypeId(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(MechtypeId.toString())) {
                        setRoList(false);
                        setDistrictList(false);
                        setFleetList(false);
                      }
                    }}
                    displayEmpty
                    style={MechtypeId === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""} disabled hidden>
                      Select
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setRoList(!RoList);
                        setDistrictList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Regional Office</Typography>
                      {RoList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData?.office_region?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: RoList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                          selected={
                            userdata?.role !== "service_enginner" &&
                            item?.id === 141
                          }
                          onClick={() => setMechType("ro")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setDistrictList(!districtList);
                        setRoList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>District</Typography>
                      {districtList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.district?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: districtList ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                          onClick={() => setMechType("district")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setDistrictList(false);
                        setRoList(false);
                        setFleetList(!fleetList);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Fleet</Typography>
                      {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          selected={
                            (userdata?.role === "service_enginner" ||
                              userdata?.role === "fleet_owner" ||
                              userdata?.role === "fleet_manager") &&
                            item?.id === userdata?.last_assigned_fleet_id
                          }
                          style={{ display: fleetList ? "flex" : "none" }}
                          value={item?.id}
                          onClick={() => setMechType("fleet")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </div>
            </div>
            {mechLoader ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : !isNumber(MechtypeId) ? (
              <div className={styles.dataMain}>
                <Typography>
                  Please Select Value From The Above Dropdown
                </Typography>
              </div>
            ) : isArray(mechData) && !isEmpty(mechData) ? (
              <div
                style={{
                  width: "100%",
                  height: "80%",
                  overflowX: "scroll",
                }}
              >
                <Chart
                  chartType="Bar"
                  data={mechData}
                  width="1500px"
                  height="98%"
                  options={{
                    colors: [color.primary],
                    legend: { position: "none" },
                  }}
                />
              </div>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </Grid>
          <Grid
            className={styles.container}
            style={{ height: 400, padding: 10 }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginBottom: 20,
                boxSizing: "border-box",
              }}
            >
              <div style={{ width: "50%" }}>
                <Typography variant="tableTitle">Tyre Issues</Typography>
              </div>
              <div
                style={{ width: "50%", justifyContent: "end", display: "flex" }}
              >
                {userdata?.role === "service_enginner" ||
                userdata?.role === "fleet_owner" ||
                userdata?.role === "fleet_manager" ? (
                  <Select
                    value={tyretypeId}
                    onChange={(v) => {
                      setTyreTypeId(v.target.value);
                    }}
                    displayEmpty
                    style={tyretypeId === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      select: styles.txt,
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden>
                      Select
                    </MenuItem>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          key={index}
                          value={item?.id}
                          onClick={() => setTyreType("fleet")}
                          selected={
                            (userdata?.role === "service_enginner" ||
                              userdata?.role === "fleet_owner" ||
                              userdata?.role === "fleet_manager") &&
                            item?.id === userdata?.last_assigned_fleet_id
                          }
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                ) : (
                  <Select
                    value={tyretypeId}
                    onChange={(v) => {
                      setTyreTypeId(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(tyretypeId.toString())) {
                        setRoList1(false);
                        setDistrictList1(false);
                        setFleetList1(false);
                      }
                    }}
                    displayEmpty
                    style={tyretypeId === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                      select: styles.txt,
                    }}
                  >
                    <MenuItem value={""} disabled hidden>
                      Select
                    </MenuItem>
                    {userdata?.role !== "service_enginner" && (
                      <ListItemButton
                        onClick={() => {
                          setRoList1(!RoList1);
                          setDistrictList1(false);
                          setFleetList1(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Regional Office</Typography>
                        {RoList1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItemButton>
                    )}
                    {dropDownData.office_region?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: RoList1 ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                          selected={
                            userdata?.role !== "service_enginner" &&
                            item?.id === 141
                          }
                          onClick={() => setTyreType("ro")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    {userdata?.role !== "service_enginner" && (
                      <ListItemButton
                        onClick={() => {
                          setDistrictList1(!districtList1);
                          setRoList1(false);
                          setFleetList1(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>District</Typography>
                        {districtList1 ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItemButton>
                    )}
                    {dropDownData.district?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: districtList1 ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                          onClick={() => setTyreType("district")}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setDistrictList1(false);
                        setRoList1(false);
                        setFleetList1(!fleetList1);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Fleet</Typography>
                      {fleetList1 ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {fleetData?.map((item, index) => {
                      return (
                        <MenuItem
                          style={{ display: fleetList1 ? "flex" : "none" }}
                          key={index}
                          value={item?.id}
                          onClick={() => setTyreType("fleet")}
                          selected={
                            (userdata?.role === "service_enginner" ||
                              userdata?.role === "fleet_owner" ||
                              userdata?.role === "fleet_manager") &&
                            item?.id === userdata?.last_assigned_fleet_id
                          }
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                )}
              </div>
            </div>
            {tyreLoader ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : !isNumber(tyretypeId) ? (
              <div className={styles.dataMain}>
                <Typography>
                  Please Select Value From The Above Dropdown
                </Typography>
              </div>
            ) : isArray(tyreData) && !isEmpty(tyreData) ? (
              <div
                style={{
                  width: "100%",
                  height: "80%",
                  overflowX: "scroll",
                }}
              >
                <Chart
                  chartType="Bar"
                  data={tyreData}
                  width="2000px"
                  height="98%"
                  options={{
                    colors: [color.primary],
                    legend: { position: "none" },
                  }}
                />
              </div>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </Grid>

          <Grid
            container
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Box display="flex" style={{ marginBottom: 10, width: "100%" }}>
              <Box
                style={{ width: "100%", marginRight: 10 }}
                onClick={() => navigate("/dashboard/FleetManagement")}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <img
                    src={Images.card7}
                    alt={Images.card7}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 800,
                      color: color.white,
                      position: "absolute",
                      bottom: isTablet ? "9%" : "22%",
                      left: "10%",
                    }}
                  >
                    {dashboardData?.totalfleet || 0}
                  </Typography>
                </Grid>
                {/* <CardBox
                  cardImage={Images.card7}
                  value={dashboardData?.totalfleet}
                /> */}
              </Box>
              <Box
                style={{ width: "100%" }}
                onClick={() =>
                  navigate("/dashboard/FleetManagement", {
                    state: { set: "vehicleList" },
                  })
                }
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <img
                    src={Images.card8}
                    alt={Images.card8}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 800,
                      color: color.white,
                      position: "absolute",
                      bottom: isTablet ? "9%" : "22%",
                      left: "10%",
                    }}
                  >
                    {dashboardData?.totalVehicles || 0}
                  </Typography>
                </Grid>
                {/* <CardBox
                  cardImage={Images.card8}
                  value={dashboardData?.totalVehicles}
                /> */}
              </Box>
            </Box>
            <Box item display="flex" style={{ width: "100%" }}>
              <Box
                style={{ width: "100%", marginRight: 10 }}
                onClick={() => {
                  navigate("/dashboard/Inspection", {
                    state: { set: "inspectionHistory" },
                  });
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <img
                    src={Images.card6}
                    alt={Images.card6}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 800,
                      color: color.white,
                      position: "absolute",
                      bottom: isTablet ? "9%" : "22%",
                      left: "10%",
                    }}
                  >
                    {dashboardData?.inspected_today || 0}
                  </Typography>
                </Grid>
                {/* <CardBox
                  cardImage={Images.card6}
                  value={dashboardData?.inspected_today}
                /> */}
              </Box>
              <Box
                style={{ width: "100%" }}
                onClick={() => {
                  navigate("/dashboard/Inspection");
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <img
                    src={Images.card5}
                    alt={Images.card5}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    style={{
                      fontSize: 20,
                      fontWeight: 800,
                      color: color.white,
                      position: "absolute",
                      bottom: isTablet ? "9%" : "22%",
                      left: "10%",
                    }}
                  >
                    {dashboardData?.total_tyre_issue || 0}
                  </Typography>
                </Grid>
                {/* <CardBox
                  cardImage={Images.card5}
                  value={dashboardData?.total_tyre_issue}
                /> */}
              </Box>
            </Box>
          </Grid>
          <div className={styles.container} id="vehicleIns">
            <TabVehicleAttention />
          </div>
        </div>
      )}
    </>
  );
}
