import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  OutlinedInput,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { isArray, isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm";
import useStyles from "./styles";
import { toast } from "react-toastify";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";

const errorObj = {
  vTrailerErr: false,
  vTrailerMsg: "",
  vNoErr: false,
  vNoMsg: "",
};

export default function AddTrailerMaster(props) {
  const { handleClick = () => null, editData = {} } = props;
  const styles = useStyles();
  const { token, isOnline } = useSelector((state) => state.auth);

  const [VehicleList, setVehicleList] = useState([]);
  const [vehicleNo, setVehicleNo] = useState({
    id: "",
    vehicle_no: "",
  });

  const trailerReg = Setting?.JS_Regex?.trailerReg;
  const isEdit = !isEmpty(editData);
  const [loader, setLoader] = useState(false);
  const [trailerNo, setTrailerNo] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  useEffect(() => {
    if (isEdit) {
      const vType = VehicleList?.find(
        (v) => v?.vehicle_no === editData?.vehicle_no
      );

      setTrailerNo(editData?.trailer_no || "");
      setVehicleNo(
        vType || {
          id: "",
          vehicle_no: "",
        }
      );
    }
  }, [VehicleList]);

  useEffect(() => {
    getHorseVehicleist();
  }, []);

  useEffect(() => {
    changeTrailer();
  }, [vehicleNo]);

  async function getHorseVehicleist() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.gethorsevehiclelist}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setVehicleList(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const changeTrailer = () => {
    if (!isEmpty(vehicleNo?.vehicle_no)) {
      const value = vehicleNo?.vehicle_no;
      const FValue = value?.substring(5);
      const RValue = FValue?.replaceAll(" ", "");
      setTrailerNo(RValue);
    }
  };

  const checkValidation = () => {
    let error = { ...errorObj };
    let valid = true;

    if (isEmpty(trailerNo)) {
      valid = false;
      error.vTrailerErr = true;
      error.vTrailerMsg = "Please Enter Trailer No.";
    } else if (!trailerReg.test(trailerNo)) {
      valid = false;
      error.vTrailerErr = true;
      error.vTrailerMsg =
        "Trailer No. Must have like Examples :- A1234 OR AB1234";
    }

    if (isEmpty(vehicleNo?.vehicle_no)) {
      valid = false;
      error.vNoErr = true;
      error.vNoMsg = "Please Select Vehicle No.";
    }

    setErrObj(error);
    if (valid) {
      submit();
    }
  };

  async function submit() {
    setLoader(true);

    const params = {
      "TrailerMaster[trailer_no]": trailerNo,
      "TrailerMaster[vehicle_id]": vehicleNo?.id,
    };
    if (isEdit) {
      params["TrailerMaster[id]"] = editData?.id;
    }

    try {
      const response = await getAPIProgressData(
        `${Setting.endpoints.addtrailer}`,
        "POST",
        params,
        true
      );
      if (response?.status) {
        toast.success(response?.message);
        handleClick("cancle");
        clearData();
      } else {
        toast.error(response?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("error=====>>>>>", error);
      toast.error(error.toString());
      setLoader(false);
    }
  }

  function clearData() {
    setTrailerNo("");
    setVehicleNo({
      id: "",
      vehicle_no: "",
    });
    setVehicleList([]);
  }

  return (
    <Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>
          {isEdit ? "Edit Trailer" : "Add Trailer"}
        </Typography>
      </Box>

      <Box className={styles.container}>
        <Grid
          container
          alignItems={"center"}
          // wrap="nowrap"
          marginBottom={errObj.vNoErr ? 4 : 3}
        >
          <Grid item xs={12} sm={12} md={3} lg={2.1}>
            <CTypography title="Vehicle No." required />
          </Grid>
          <Grid item xs={12} md={9} lg={9.9}>
            <FormControl
              fullWidth
              error={errObj.vNoErr}
              sx={{
                border: errObj.vNoErr ? "1px solid #ff1744" : null,
                borderRadius: 1,
              }}
            >
              <Autocomplete
                fullWidth
                isOptionEqualToValue={(option, value) =>
                  option.vehicle_no === value
                }
                // disabled={isEmpty(fleet?.label)}
                disableListWrap={true}
                options={VehicleList}
                value={vehicleNo?.vehicle_no}
                classes={{ input: `${styles.autocomplete}` }}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.vehicle_no) {
                    return option.vehicle_no;
                  }
                  // Regular option
                  return option.label;
                }}
                onChange={(v1, v) => {
                  setVehicleNo(v);
                  setErrObj({
                    ...errObj,
                    vNoErr: false,
                    vNoMsg: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={styles.inputDivStyle}
                    placeholder={
                      isEmpty(vehicleNo?.vehicle_no) ? "Select Vehicle" : null
                    }
                  />
                )}
                renderTags={(tagValue, getTagProps) => {
                  const renderTagsValue = tagValue;
                  return (
                    // <Typography noWrap={true} color="textPrimary">
                    renderTagsValue
                    // </Typography>
                  );
                }}
              />

              {errObj.vNoErr ? (
                <FormHelperText>{errObj.vNoMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems="center"
          id="vModel"
          // marginBottom={errObj.vTrailerErr ? 4 : 3}
        >
          <Grid item xs={12} sm={12} md={3} lg={2.1}>
            <CTypography title="Trailer No." required />
          </Grid>
          <Grid item xs={12} md={9} lg={9.9}>
            <FormControl fullWidth error={errObj?.vTrailerErr}>
              <OutlinedInput
                value={trailerNo}
                placeholder="Enter the Trailer No."
                onChange={(e) => {
                  setTrailerNo(e.target.value);
                  setErrObj({ ...errObj, vTrailerErr: false, vTrailerMsg: "" });
                }}
              />
              {errObj.vTrailerErr ? (
                <FormHelperText>{errObj.vTrailerMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={12} md={3} lg={2.1} />
          <Grid item xs={12} md={9} lg={9.9}>
            <Typography
              style={{
                marginTop: errObj.vTrailerErr ? 20 : 10,
                fontSize: 12,
              }}
            >
              <b>Note :</b> Please start with Character (up to 2) And the last
              four should be digits. For e.g. A1310 or AB1310.
            </Typography>
          </Grid>
        </Grid>

        <Grid container justifyContent={"center"} marginTop={5}>
          <Grid item>
            <Button
              variant="contained"
              onClick={checkValidation}
              style={{
                backgroundColor: isOnline ? color.primary : color.bordercolor,
                marginRight: 10,
                width: isMobile ? 120 : 130,
              }}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : isEdit ? (
                `Save & Update`
              ) : (
                `Add Trailer`
              )}
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              style={{
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
                width: isMobile ? 120 : 130,
              }}
              onClick={() => handleClick("cancle")}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
