import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting.js";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  createFilterOptions,
  FormHelperText,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  Modal,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles.js";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { cloneDeep, isArray, isEmpty, isNull, isObject } from "lodash";

import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm/index";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { tyre_condition } from "../../../Config/Static_Data.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { isTablet, isMobile } from "react-device-detect";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/styles";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import authActions from "../../../Redux/reducers/auth/actions";

const errorObj = {
  FNameErr: false,
  FNameMsg: "",
  soldErr: false,
  soldMsg: "",
  claimStatusErr: false,
  claimStatusMsg: "",
  casingStatusErr: false,
  casingStatusMsg: "",
  vehicleErr: false,
  vehicleMsg: "",
  categoryErr: false,
  categoryMsg: "",
  TSNErr: false,
  tyrePositionErr: false,
  noOfRowErr: false,
  rethreadErr: false,
  tyreConditionErr: false,
  axleTypeErr: false,
  fitmentKmErr: false,
  purchaseCostErr: false,
  castingErr: false,
  brandErr: false,
  brandMsg: "",
  fitmentKmMsg: "",
  purchaseCostMsg: "",
  castingMsg: "",
  tyrePositionMsg: "",
  rethreadMsg: "",
  noOfRowMsg: "",
  tyreConditionMsg: "",
  axleTypeMsg: "",
  TSNMsg: "",
  dateErr: false,
  dateMsg: "",
  fitmentDateErr: false,
  fitmentDateMsg: "",
  prereadingKmErr: false,
  prereadingKmMsg: "",
  tyretypeErr: false,
  tyretypeMsg: "",
};

export default function AddInventoryTyre(props) {
  const { handleClick = () => null, fromInsideType, storedCategory } = props;
  const styles = useStyles();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const { token, dropDownData, isOnline, fleetData, editInventoryData } =
    useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const { setEditInventoryData } = authActions;
  const TyrePositionRegex = Setting.JS_Regex.tyrePositionX;
  const currency_Regex = Setting.JS_Regex.currency_Regex;

  const [errObj, setErrObj] = useState(errorObj);
  const [noChange, setNoChange] = useState(false);
  const [editData, setEditData] = useState(cloneDeep(editInventoryData));
  const [vehicleList, setVehicleList] = useState([]);
  const [vehicleDetails, setVehicleDetail] = useState(false);

  const isEdit = !isEmpty(editData);

  const [fleet, setFleet] = useState({
    id: "",
    label: "",
  });
  const [vehicle, setVehicle] = useState({
    id: "",
    label: "",
  });
  const [category, setCategory] = useState("");
  const [loader, setLoader] = useState(false);
  const [serialNo, setserialNo] = useState("");
  const [tyreCondition, settyreCondition] = useState("");
  const [noOfRows, setNoOfRows] = useState("");
  const [tyreBrand, settyreBrand] = useState("");
  const [patternSize, setPAtternSize] = useState(null);
  const [tyreType, settyreType] = useState("");
  const [prereading, setprereading] = useState("");
  const [rethread, setrethread] = useState(1);
  const [tyreComposition, settyreComposition] = useState("");
  const [plyRating, setplyRating] = useState("");
  const [tyrePosition, settyrePosition] = useState("");
  const [treadPattern, settreadPattern] = useState("");
  const [sold, SetSold] = useState("");
  const [claimStatus, SetClaimStatus] = useState("");
  const [casingStatus, SetCasingStatus] = useState("");
  // const [tyrePattern, settyrePattern] = useState("");
  const [tyrePatternList, settyrePatternList] = useState([]);
  const [NSD, setNSD] = useState("");
  const [avgNSD, setAvgNSD] = useState("");

  const [change, setChange] = useState(false);

  const [formValues, setFormValues] = useState([
    {
      groove1: "",
      groove2: "",
      groove3: "",
      groove4: "",
      id: Math.floor(Math.random() * 100 + 1),
    },
  ]);
  const [axleType, setaxleType] = useState("");
  const [airPressure, setairPressure] = useState("");
  const [fitmentKm, setfitmentKm] = useState("");
  const [purchaseCost, setpurchaseCost] = useState("");
  const [castingCost, setcastingCost] = useState("");
  const [purchaseFrom, setpurchaseFrom] = useState("");
  const [readOnly, setReadOnly] = useState(false);
  const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
  const [datePicker, setDatePicker] = useState(null);
  const [fitmentDate, setFitmentDate] = useState(null);

  const [isChange, setIsChange] = useState(false);

  const [issueType, setissueType] = useState([]);
  const dummytyreArr = cloneDeep(dropDownData?.tyre_issue_scrap_analysis);
  const [issueArry, setissueArry] = useState(dummytyreArr);
  const [renderTagsValue, setrenderTagsValue] = useState([]);
  const [openCard, setOpenCard] = useState(false);

  const [remarks, setRemarks] = useState("");
  const [selectedFile, setSlectedFile] = useState([]);
  const [modal, openModal] = useState(false);
  const [displaySelectedFile, setDisplaySlectedFile] = useState([]);
  const [showImg, setShowImg] = useState("");
  const [pasteData, setPasteData] = useState({});

  const categoryListArray = [
    {
      label: "Casing",
    },
    {
      label: "Claim",
    },
    {
      label: "On Wheel",
    },
    {
      label: "Retreading",
    },
    {
      label: "Scrap",
    },
    {
      label: "Stock",
    },
  ];

  const isImageShow =
    isEdit &&
    ((editData?.category === "Stock" &&
      (category === "Claim" || category === "Scrap")) ||
      (editData?.category === "Retreading" && category === "Stock") ||
      (editData?.category === "Claim" && category === "Stock"));

  const filter = createFilterOptions(tyrePatternList);
  let rows = String(noOfRows).padStart(2, "0");

  useEffect(() => {
    setSlectedFile([]);
    setDisplaySlectedFile([]);
  }, [isImageShow]);

  useEffect(() => {
    setEditData(editInventoryData);
  }, [editInventoryData]);

  useEffect(() => {
    if (!isEmpty(pasteData)) {
      setprereading(pasteData?.mileage || 0);
      settyreBrand(pasteData?.tyre_brand_id);
      settyreComposition(pasteData?.tyre_composition_id);
      settreadPattern(pasteData?.tread_pattern_id);
      setairPressure(pasteData?.tyre_air_pressure);
      setplyRating(pasteData?.ply_rating_id);
      setNSD(pasteData?.original_nsd);
      setFormValues(pasteData?.current_nsd);
      setpurchaseCost(pasteData?.purchase_cost);
      setpurchaseFrom(pasteData?.purchased_from);
      setDatePicker(pasteData?.added_date);
    }
  }, [pasteData]);

  useEffect(() => {
    if (!isEmpty(pasteData) && !isEmpty(tyrePatternList)) {
      const data = tyrePatternList?.find(
        (v) => v?.id === pasteData?.tyre_pattern_size_id
      );
      setPAtternSize(data);
    }
  }, [pasteData, tyrePatternList]);

  useEffect(() => {
    if (isEdit) {
      editData?.scrap_issue?.map((ele, ind) => {
        const issIndex = dropDownData?.tyre_issue_scrap_analysis?.findIndex(
          (v) => v?.label === ele
        );
        SelectTyre(issIndex);
        setTimeout(() => handleSelect(), 200);
      });
      const fname = fleetData?.find(
        (v) => v?.id?.toString() === editData?.fleet_id?.toString()
      );
      setFleet(fname);
      setserialNo(editData?.tyre_serial_no || "");
      setCategory(
        fromInsideType === "inside#success"
          ? storedCategory || ""
          : editData?.category || ""
      );
      settyreCondition(editData?.tyre_condition || "");
      settyreBrand(editData?.tyre_brand_id || "");

      const type = dropDownData?.tyre_type?.find(
        (v) => v?.label?.toString() === editData?.tyre_type?.toString()
      );
      settyreType(type?.id || "");
      setprereading(editData?.old_mileage || "");
      settyrePosition(editData?.tyre_position || "");
      setrethread(editData?.no_of_retread || "");
      // settyrePattern(editData?.tyre_pattern || "");
      setaxleType(editData?.axle_type_id || "");
      setairPressure(editData?.tyre_air_pressure || "");
      setfitmentKm(editData?.tyre_fitment_km || "");
      setpurchaseCost(editData?.purchase_cost || "");
      setcastingCost(editData?.casing_value || "");
      setpurchaseFrom(editData?.purchased_from || "");
      setNoOfRows(editData?.selected_row || "");
      setDatePicker(editData?.capture_date || null);
      setFitmentDate(editData?.fitment_date || null);
      setRemarks(editData?.remark || "");
      SetSold(
        editData?.category === "Scrap" ? editData?.scrap_status?.toString() : ""
      );
      SetClaimStatus(
        editData?.category === "Claim" ? editData?.claim_status?.toString() : ""
      );
      SetCasingStatus(
        editData?.category === "Casing"
          ? editData?.casing_status?.toString()
          : ""
      );
      setFormValues(
        editData?.current_nsd || [
          {
            groove1: "",
            groove2: "",
            groove3: "",
            groove4: "",
            id: Math.floor(Math.random() * 100 + 1),
          },
        ]
      );
      if (editData?.is_new_tyre === 1) {
        settyreComposition(editData?.tyre_composition_id || "");
        setplyRating(editData?.ply_rating_id || "");
        settreadPattern(editData?.tread_pattern_id || "");
        setNSD(editData?.original_nsd || "");
      }
      if (editData?.is_new_tyre === 0) {
        setChange(true);
      }
    }

    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);

  useEffect(() => {
    if (fleet?.id) {
      getVehicleist();
    }
  }, [fleet]);

  useEffect(() => {
    if (isEdit && isArray(vehicleList) && !isEmpty(vehicleList)) {
      const vehicle = vehicleList?.find(
        (v) => v?.id?.toString() === editData?.vehicle_id?.toString()
      );
      setVehicle(vehicle);
    }
  }, [vehicleList]);

  useEffect(() => {
    if (vehicle?.id) {
      getVehicleDetails();
    }
  }, [vehicle]);

  useEffect(() => {
    if (isEdit) {
      setTimeout(() => {
        if (editData?.is_new_tyre === 1) {
          !isChange && setPAtternSize({ label: editData?.new_tyre_name });
        } else {
          const PatternData = tyrePatternList.find(
            (v) => editData?.tyre_pattern_size == v?.label
          );
          if (!isEmpty(PatternData)) {
            !isChange && setPAtternSize(PatternData);
          }
        }
      }, 200);
    }
  }, [tyrePatternList]);

  useEffect(() => {
    patternSizeData();
    if (editData?.is_new_tyre === 0) {
      autoFillData();
    }
  }, [tyreBrand]);

  useEffect(() => {
    setChange(!isEmpty(patternSize) && patternSize?.id ? true : false);
  }, [patternSize]);

  useEffect(() => {
    tyreType === 91 && !isEmpty(patternSize) && setReadOnly(true);
    (tyreType === 45 || tyreType === 46) && setReadOnly(false);
  }, [tyreType]);

  useEffect(() => {
    if (!isEdit || (isEdit && change)) {
      autoFillData();
    }
  }, [patternSize, change]);

  useEffect(() => {
    isArray(formValues) && !isEmpty(formValues) && findAvgNSD();
  }, [formValues]);

  function findAvgNSD() {
    let groove = 0;
    let divide = 0;
    formValues?.map((v, index) => {
      if (!isEmpty(v?.groove1)) {
        groove = Number(v?.groove1) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove2)) {
        groove = Number(v?.groove2) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove3)) {
        groove = Number(v?.groove3) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove4)) {
        groove = Number(v?.groove4) + groove;
        divide++;
      }
    });

    const mainAVG = groove / divide;
    setAvgNSD(isNaN(mainAVG) ? "" : mainAVG.toFixed(2));
  }

  function clearChangeData() {
    setPAtternSize("");
    settyreComposition("");
    setplyRating("");
    settreadPattern("");
    setNSD("");
  }

  const submitData = async () => {
    setLoader(true);

    const captureDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";

    const fDate = !isNull(fitmentDate)
      ? moment(fitmentDate).format("YYYY-MM-DD")
      : "";
    let endPoints = Setting.endpoints.addtyre;
    const params = {
      "Tyre[fleet_id]": fleet?.id,
      "Tyre[tyre_serial_no]": serialNo?.trim(),
      "Tyre[category]": category,
      "Tyre[tyre_condition]": tyreCondition,
      "Tyre[tyre_brand_id]": tyreBrand,
      "Tyre[tyre_type_id]": tyreType,
      "Tyre[tyre_composition_id]": tyreComposition,
      "Tyre[ply_rating_id]": plyRating,
      "Tyre[tyre_position]": tyrePosition,
      "Tyre[tread_pattern_id]": treadPattern,
      // "Tyre[tyre_pattern]": tyrePattern,
      "Tyre[tyre_pattern_size_id]": patternSize?.id || "",
      "Tyre[original_nsd]": NSD,
      "Tyre[current_nsd]": JSON.stringify(formValues),
      "Tyre[avg_nsd]": avgNSD,
      "Tyre[purchase_cost]": purchaseCost,
      "Tyre[purchased_from]": purchaseFrom,
      "Tyre[fitment_date]": fDate,
      "Tyre[capture_date]": captureDate,
      "Tyre[is_rethread]": tyreType === 45 ? 1 : 0,
    };
    params["Tyre[is_new_tyre]"] =
      !isEmpty(patternSize) && !patternSize?.id ? 1 : 0;
    if (!isEmpty(patternSize) && !patternSize?.id) {
      params["Tyre[new_tyre_name]"] = patternSize?.label;
    }
    if (category === "On Wheel") {
      params["Tyre[vehicle_id]"] = vehicle?.id;
      params["Tyre[axle_type_id]"] = axleType;
      params["Tyre[tyre_air_pressure]"] = airPressure;
      params["Tyre[tyre_fitment_km]"] = fitmentKm;
      params["Tyre[selected_row]"] = noOfRows;
    }
    if (category === "Scrap") {
      params["Tyre[scrap_issue_id]"] = issueType;
      params["Tyre[remark]"] = remarks;
      params["Tyre[scrap_status]"] =
        isEdit && editData?.category === "Scrap" ? sold : 0;
    }
    if (category === "Stock") {
      params["Tyre[casing_value]"] = castingCost;
    }
    if (tyreType === 45) {
      params["Tyre[no_of_retread]"] = rethread;
    }
    // if (tyreType === 45 || tyreType === 46 || tyreType === 91) {
    params["Tyre[old_mileage]"] = prereading;
    // }

    if (isEdit) {
      params["Tyre[id]"] = editData?.id;
      if (category === "Claim") {
        params["Tyre[claim_status]"] =
          editData?.category === "Claim" ? claimStatus : 0;
      }
      if (category === "Casing") {
        params["Tyre[casing_status]"] =
          editData?.category === "Casing" ? casingStatus : 0;
      }
      if (isArray(displaySelectedFile) && !isEmpty(displaySelectedFile)) {
        displaySelectedFile.map((item, index) => {
          params[`Tyre[tyre_images][${index}]`] = item;
        });
      }
    }
    // if (!isEdit) {
    params["Tyre[old_tsn]"] = isEdit
      ? editData?.old_tsn?.trim()
      : serialNo?.trim();
    // }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        handleClick("cancel");
        toast.success(resp?.message);
        clearData();
        dispatch(setEditInventoryData({}));
      } else {
        toast.error(resp?.message);
        if (resp?.message === "Tyre is already added on this position") {
          const error = { ...errObj };
          let scroll = false;
          let section = null;
          error.tyrePositionErr = true;
          error.tyrePositionMsg = "Tyre is already added on this position";
          if (!scroll) {
            scroll = true;
            section = document.querySelector("#tyrePosition");
          }
          setErrObj(error);

          if (section) {
            section.scrollIntoView({ behavior: "smooth", block: "center" });
          }
        }
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
      toast.error(error?.message?.toString());
    }
  };

  const patternSizeData = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyrepattern}?tyre_brand_id=${tyreBrand}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          settyrePatternList(response?.data);
        } else {
          settyrePatternList([]);
        }
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const getMileageData = async (tsn) => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.getMileage}?tyre_serial_no=${tsn}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (!isArray(response?.data?.mileage)) {
          setNoChange(true);
          settyreType(46);
          setPasteData(response?.data);
        } else {
          settyreType("");
        }
      } else {
        setNoChange(false);
        settyreType("");
        setprereading("");
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  async function getVehicleist() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicleData}?fleet_id=${fleet?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setVehicleList(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${vehicle.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const autoFillData = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.patterndata}?pattern_id=${patternSize?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        tyreType === 91 && setReadOnly(true);
        handleFillData(response?.data);
      } else {
        setReadOnly(false);
        handleFillData("");
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function handleFillData(data) {
    settyreComposition(data?.tyre_composition_id || "");
    setplyRating(data?.ply_rating_id || "");
    settreadPattern(data?.tyre_size || "");
    setNSD(data?.nsd || "");
    settreadPattern(data?.tread_pattern_id || "");
  }

  let handleChange = (i, e, key) => {
    let newFormValues = [...formValues];
    newFormValues.map((v, index) => {
      if (i === index) {
        if (key === 1) {
          v.groove1 = e;
        } else if (key === 2) {
          v.groove2 = e;
        } else if (key === 3) {
          v.groove3 = e;
        } else if (key === 4) {
          v.groove4 = e;
        }
      }
    });
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    if (formValues.length < 4) {
      setFormValues([
        ...formValues,
        {
          groove1: "",
          groove2: "",
          groove3: "",
          groove4: "",
          id: Math.floor(Math.random() * 100 + 1),
        },
      ]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  function clearData(bool) {
    settyreCondition("");
    settyreBrand("");
    settyreType("");
    setprereading("");
    settyreComposition("");
    setplyRating("");
    settyrePosition("");
    settreadPattern("");
    // settyrePattern("");
    setaxleType("");
    setairPressure("");
    setSlectedFile([]);
    setDisplaySlectedFile([]);
    openModal(false);
    setfitmentKm("");
    setpurchaseCost("");
    setcastingCost("");
    setpurchaseFrom("");
    setDatePicker(null);
    setFitmentDate(null);
    if (bool) {
      setVehicle({ id: "", label: "" });
      setVehicleList([]);
    }
  }

  function numberWithCommas(x) {
    let c = x?.replaceAll(",", "");

    return c?.toString().split(".")[0].length > 3
      ? c
          ?.toString()
          .substring(0, c?.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c?.toString().substring(c?.toString().split(".")[0].length - 3)
      : c?.toString();
  }

  function converter(value, from) {
    let convert = numberWithCommas(value);
    if (from === "fitment") {
      setfitmentKm(convert);
      setErrObj({
        ...errObj,
        fitmentKmErr: false,
        fitmentKmMsg: "",
      });
    } else if (from === "prereading") {
      setprereading(convert);
      setErrObj({
        ...errObj,
        prereadingKmErr: false,
        prereadingKmMsg: "",
      });
    } else if (from === "casting") {
      setcastingCost(convert);
      setErrObj({
        ...errObj,
        castingErr: false,
        castingMsg: "",
      });
    } else {
      setpurchaseCost(convert);
      setErrObj({
        ...errObj,
        purchaseCostErr: false,
        purchaseCostMsg: "",
        castingErr: false,
        castingMsg: "",
      });
    }
  }

  function uploadImg(file) {
    let dummy_Arr = [...selectedFile];
    let dummy_Arr1 = [...displaySelectedFile];
    if (file.length > 5 || file.length + selectedFile.length > 5) {
      toast.error("Cannot upload files more than 5");
    } else {
      Object.keys(file).map((item) => {
        dummy_Arr.push(URL.createObjectURL(file[item]));
        dummy_Arr1.push(file[item]);
      });
    }
    setSlectedFile(dummy_Arr);
    setDisplaySlectedFile(dummy_Arr1);
  }

  function removeImage(i) {
    let dummy_Arr = [...selectedFile];
    let dummy_Arr1 = [...displaySelectedFile];

    dummy_Arr.splice(i, 1);
    dummy_Arr1.splice(i, 1);

    setSlectedFile(dummy_Arr);
    setDisplaySlectedFile(dummy_Arr1);
  }

  function validation() {
    const error = { ...errObj };
    let valid = true;
    let section = null;
    let scroll = false;
    let nsdErr = false;
    let SeqErr = false;

    if (isEmpty(fleet?.label) || fleet === undefined || fleet === "") {
      valid = false;
      error.FNameErr = true;
      error.FNameMsg = "Please select the Fleet";
    }

    if (category === "On Wheel") {
      if (isEmpty(vehicle?.label) || vehicle === undefined || vehicle === "") {
        valid = false;
        error.vehicleErr = true;
        error.vehicleMsg = "Please select the Vehicle No.";
      }
    }

    if (isEmpty(serialNo)) {
      valid = false;
      error.TSNErr = true;
      error.TSNMsg = "Please enter Tyre Serial No.";
      scroll = true;
      section = document.querySelector("#serialNo");
    } else if (
      isEdit &&
      editData?.tyre_type_id === 45 &&
      serialNo.length > 18
    ) {
      valid = false;
      error.TSNErr = true;
      error.TSNMsg = "Please enter valid Tyre Serial No.";
      scroll = true;
      section = document.querySelector("#serialNo");
    } else if (editData?.tyre_type_id !== 45 && serialNo.length > 15) {
      valid = false;
      error.TSNErr = true;
      error.TSNMsg = "Please enter valid Tyre Serial No.";
      scroll = true;
      section = document.querySelector("#serialNo");
    }

    if (isEmpty(tyreCondition)) {
      valid = false;
      error.tyreConditionErr = true;
      error.tyreConditionMsg = "Please select Tyre Condition";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#tyreCondition");
      }
    }

    if (tyreType === 45 && isEmpty(rethread?.toString())) {
      valid = false;
      error.rethreadErr = true;
      error.rethreadMsg = "Please select No. of Retread";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#rethreadType");
      }
    }

    if (isEmpty(category)) {
      valid = false;
      error.categoryErr = true;
      error.categoryMsg = "Please select Category";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#category");
      }
    }

    if (
      isEdit &&
      editData?.category === "Scrap" &&
      category === "Scrap" &&
      isEmpty(sold?.toString())
    ) {
      valid = false;
      error.soldErr = true;
      error.soldMsg = "Please select Scrap Status";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#sold");
      }
    }

    if (
      isEdit &&
      editData?.category === "Claim" &&
      category === "Claim" &&
      isEmpty(claimStatus?.toString())
    ) {
      valid = false;
      error.claimStatusErr = true;
      error.claimStatusMsg = "Please select Claim Status";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#claim");
      }
    }

    if (
      isEdit &&
      editData?.category === "Casing" &&
      category === "Casing" &&
      isEmpty(casingStatus?.toString())
    ) {
      valid = false;
      error.casingStatusErr = true;
      error.casingStatusMsg = "Please select Casing Status";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#casing");
      }
    }

    if (category === "On Wheel" && isEmpty(noOfRows?.toString())) {
      valid = false;
      error.noOfRowErr = true;
      error.noOfRowMsg = "Please select Axle";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#noOfRows");
      }
    }
    if (category === "On Wheel") {
      if (isEmpty(tyrePosition)) {
        valid = false;
        error.tyrePositionErr = true;
        error.tyrePositionMsg = "Please enter Tyre Position";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#tyrePosition");
        }
      } else if (!TyrePositionRegex.test(tyrePosition)) {
        valid = false;
        error.tyrePositionErr = true;
        error.tyrePositionMsg = "Please enter valid Tyre Position";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#tyrePosition");
        }
      } else if (tyrePosition[1] !== rows[0] || tyrePosition[2] !== rows[1]) {
        valid = false;
        error.tyrePositionErr = true;
        error.tyrePositionMsg = "Please enter valid Tyre Position";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#tyrePosition");
        }
      }
    }

    if (isEmpty(tyreBrand?.toString())) {
      valid = false;
      error.brandErr = true;
      error.brandMsg = "Please select the Tyre Brand";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#tyreBrand");
      }
    }
    if (category === "On Wheel" && isEmpty(axleType?.toString())) {
      valid = false;
      error.axleTypeErr = true;
      error.axleTypeMsg = "Please select Axle Type";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#axleType");
      }
    }

    let c = fitmentKm?.replaceAll(",", "");
    if (!isEmpty(fitmentKm) && !currency_Regex.test(c)) {
      valid = false;
      error.fitmentKmErr = true;
      error.fitmentKmMsg = "Please enter valid Kms";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#fitmentKm");
      }
    }

    if (isEmpty(tyreType?.toString())) {
      valid = false;
      error.tyretypeErr = true;
      error.tyretypeMsg = "Please select Tyre Type";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#tyreType");
      }
    }

    if (tyreType === 45 || tyreType === 46) {
      let d = prereading?.toString()?.replace(/,/g, "");

      if (
        !isEmpty(prereading?.toString()) &&
        (!currency_Regex.test(d) || Number(prereading) === 0)
      ) {
        valid = false;
        error.prereadingKmErr = true;
        error.prereadingKmMsg = "Please enter valid Kms";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#prereading");
        }
      }
    }

    let v = purchaseCost?.replaceAll(",", "");
    if (!isEmpty(purchaseCost) && !currency_Regex.test(v)) {
      valid = false;
      error.purchaseCostErr = true;
      error.purchaseCostMsg = "Please enter valid Cost";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#purchaseCost");
      }
    }

    let cv = castingCost?.replaceAll(",", "");
    if (
      (category === "Stock" || category === "Claim") &&
      !isEmpty(castingCost) &&
      !currency_Regex.test(cv)
    ) {
      valid = false;
      error.castingErr = true;
      error.castingMsg = "Please enter valid Cost";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#casting");
      }
    }
    if (
      (category === "Stock" || category === "Claim") &&
      !isEmpty(purchaseCost) &&
      !isEmpty(castingCost) &&
      Number(v) < Number(cv)
    ) {
      valid = false;
      error.castingErr = true;
      error.castingMsg = "Casing Value cannot be greater than Purchase Cost";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#casting");
      }
    }

    if (category === "Scrap" && isEmpty(issueType.toString())) {
      valid = false;
      error.issueErr = true;
      error.issueMsg = "Please select Tyre Issue";
    }
    let foundDateInRange = moment(fitmentDate).isBetween(
      "2000-01-01",
      new Date()
    );
    if (
      !isNull(fitmentDate) &&
      (fitmentDate === "Invalid Date" || !foundDateInRange)
    ) {
      valid = false;
      error.fitmentDateErr = true;
      error.fitmentDateMsg = "Please enter valid Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#fDate");
      }
    }

    let foundAddedDateInRange = moment(datePicker).isBetween(
      "2000-01-01",
      new Date()
    );

    if (isNull(datePicker)) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please select Added Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    } else if (
      !isNull(datePicker) &&
      (datePicker === "Invalid Date" || !foundAddedDateInRange)
    ) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please enter valid Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    }

    formValues.map((v, index) => {
      v.nsdErr = false;
      if (!isEmpty(v?.groove1) && Math.ceil(v?.groove1) === 0) {
        valid = false;
        nsdErr = true;
        v.nsdErr = true;
        if (!scroll) {
          scroll = true;
          section = document.querySelector(`#nsdErr_${index}`);
        }
      }
      if (!isEmpty(v?.groove2)) {
        if (Math.ceil(v?.groove2) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        } else if (isEmpty(v?.groove1)) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        }
      }
      if (!isEmpty(v?.groove3)) {
        if (Math.ceil(v?.groove3) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        } else if (isEmpty(v?.groove1) || isEmpty(v?.groove2)) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        }
      }
      if (!isEmpty(v?.groove4)) {
        if (Math.ceil(v?.groove4) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        } else if (
          isEmpty(v?.groove1) ||
          isEmpty(v?.groove2) ||
          isEmpty(v?.groove3)
        ) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        }
      }
    });

    setErrObj(error);
    if (nsdErr) {
      toast.error("NSD value can't be 0", { toastId: 1 });
    }
    if (SeqErr) {
      toast.error("Please fill NSD value sequentially", { toastId: 2 });
    }
    if (valid) {
      submitData();
    }
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  function SelectTyre(index) {
    const DummyArr = [...issueArry];

    DummyArr.map((item, i) => {
      if (i === index) {
        item.selected = !item.selected;
      }
    });
    setissueArry(DummyArr);
  }

  function handleSelect() {
    // const DummyArr = [...issueArry];
    // const selected = DummyArr?.find((v) => v.selected === true);
    // if (!isEmpty(selected)) {
    //   setissueType(selected);
    // }

    setErrObj({ ...errObj, issueErr: false, issueMsg: "" });
    setOpenCard(false);
    const DummyArr = [...issueArry];
    const selected = DummyArr?.filter((v) => v.selected === true);
    const renderTagsValue =
      isArray(selected) && !isEmpty(selected)
        ? selected
            .map(function (elem) {
              return elem.label;
            })
            .join(", ")
        : "";
    const array = [];
    const array2 = [];
    if (!isEmpty(selected) && isArray(selected)) {
      selected.map((v) => {
        array.push(v.id);
        array2.push(v.label);
      });
      setissueType(array);
      setrenderTagsValue(renderTagsValue);
    } else {
      setrenderTagsValue("");
      setissueType([]);
    }
    setOpenCard(false);
  }

  function updateInspectionRedux() {
    let dummyObj = { ...editInventoryData };
    dummyObj.axle_type_id = axleType;
    dummyObj.capture_date = datePicker;
    dummyObj.casing_value = castingCost;
    dummyObj.category = category;
    dummyObj.current_nsd = formValues;
    dummyObj.fitment_date = fitmentDate;
    dummyObj.fleet_id = fleet?.id;
    dummyObj.fleet_name = fleet?.label;
    dummyObj.no_of_retread = rethread;
    dummyObj.ply_rating_id = plyRating;
    dummyObj.purchase_cost = purchaseCost;
    dummyObj.purchased_from = purchaseFrom;
    dummyObj.selected_row = noOfRows;
    dummyObj.tyre_air_pressure = airPressure;
    dummyObj.tyre_brand_id = tyreBrand;
    dummyObj.tyre_composition_id = tyreComposition;
    dummyObj.tyre_condition = tyreCondition;
    dummyObj.tyre_fitment_km = fitmentKm;
    dummyObj.tyre_pattern_size = patternSize?.label;
    dummyObj.tyre_position = tyrePosition;
    dummyObj.tyre_serial_no = serialNo;

    const type = dropDownData?.tyre_type?.find(
      (v) => v?.id?.toString() === tyreType?.toString()
    );
    dummyObj.tyre_type = type?.label;
    dispatch(setEditInventoryData(dummyObj));
  }

  return (
    <Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        id="jump_to_me"
      >
        <Typography fontWeight={"bold"}>
          {isEdit ? "Edit Tyre" : "Add Tyre"}
        </Typography>
      </Box>

      <Box className={styles.container}>
        <Grid
          container
          style={{ marginBottom: errObj.FNameErr ? 30 : 20 }}
          alignItems={"center"}
          id="fleetname"
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <CTypography required title={"Fleet Name"} />
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <FormControl
              fullWidth
              error={errObj.FNameErr}
              sx={{
                width: "100%",
                border: errObj.FNameErr ? "1px solid #ff1744" : null,
                borderRadius: 1,
              }}
            >
              <Autocomplete
                fullWidth
                isOptionEqualToValue={(option, value) => option.label === value}
                disableListWrap={true}
                options={fleetData}
                readOnly={isEdit}
                value={fleet?.label}
                classes={{ input: `${styles.autocomplete}` }}
                onChange={(v1, v) => {
                  clearData(true);
                  setFleet(v);
                  setErrObj({
                    ...errObj,
                    FNameErr: false,
                    FNameMsg: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={styles.inputDivStyle}
                    placeholder={isEmpty(fleet?.label) ? "Select Fleet" : null}
                  />
                )}
                // renderTags={(tagValue, getTagProps) => {
                //   const renderTagsValue = tagValue;
                //   console.log(
                //     "renderTagsValue====>>>>>",
                //     renderTagsValue
                //   );
                //   return (
                //     // <Typography noWrap={true} color="textPrimary">
                //     renderTagsValue
                //     // </Typography>
                //   );
                // }}
              />
              {errObj.FNameErr ? (
                <FormHelperText>{errObj.FNameMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.TSNErr ? 30 : 20 }}
          alignItems={"center"}
          id="serialNo"
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <CTypography required title={"Tyre Serial No."} />
          </Grid>

          <Grid item xs={12} sm={9} md={10} lg={10}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter Tyre Serial No."
                value={serialNo}
                onPaste={(e) => {
                  const tsn = e.clipboardData.getData("Text");
                  getMileageData(tsn);
                }}
                error={errObj.TSNErr}
                helperText={errObj.TSNErr ? errObj.TSNMsg : null}
                onChange={(event) => {
                  if (event?.nativeEvent?.data !== " ") {
                    setserialNo(event.target.value?.toUpperCase());
                    setNoChange(false);
                    !isEdit && settyreType("");
                    // setprereading(0);
                    setErrObj({
                      ...errObj,
                      TSNErr: false,
                      TSNMsg: "",
                    });
                  }
                }}
              />
              {isEdit ? null : (
                <Button
                  style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                  onClick={() => {
                    setserialNo(
                      Math.random()
                        ?.toString(36)
                        .replace(/[^a-z]+/g, "")
                        .substr(0, 5)
                    );
                    setErrObj({
                      ...errObj,
                      TSNErr: false,
                      TSNMsg: "",
                    });
                  }}
                >
                  Generate
                </Button>
              )}
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: errObj.categoryErr ? 30 : 20 }}
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <CTypography required title="Category" />
          </Grid>

          <Grid item xs={12} sm={9} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth error={errObj.categoryErr}>
                <Select
                  displayEmpty
                  value={category}
                  onChange={(event) => {
                    if (
                      isEdit &&
                      editData?.category === "On Wheel" &&
                      fromInsideType !== "inside#success"
                    ) {
                      updateInspectionRedux();
                      handleClick("removeTyre", event.target.value);
                    } else {
                      setCategory(event.target.value);
                      setErrObj({
                        ...errObj,
                        categoryErr: false,
                        categoryMsg: "",
                      });
                    }
                  }}
                  size={"medium"}
                  style={category === "" ? { color: "#A2A2A2" } : {}}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select the Category
                  </MenuItem>
                  {categoryListArray.map((item, index) => {
                    const disable =
                      item?.label === "On Wheel" &&
                      fromInsideType === "inside#success";
                    if (
                      isEdit &&
                      (editData?.category === "Scrap" ||
                        editData?.category === "Stock" ||
                        editData?.category === "Claim" ||
                        editData?.category === "Retreading" ||
                        editData?.category === "Casing")
                    ) {
                      return (
                        <MenuItem key={index} value={item?.label}>
                          {item?.label}
                        </MenuItem>
                      );
                    } else {
                      if (
                        item?.label === "On Wheel" ||
                        item?.label === "Scrap" ||
                        item?.label === "Stock"
                      ) {
                        return (
                          <MenuItem
                            key={index}
                            disabled={disable}
                            value={item?.label}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      }
                    }
                  })}
                </Select>
                {errObj.categoryErr ? (
                  <FormHelperText>
                    {errObj.categoryMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>
        {!isEmpty(category) && (
          <>
            {category === "Scrap" &&
              isEdit &&
              editData?.category === "Scrap" && (
                <Grid
                  container
                  style={{ marginBottom: errObj.soldErr ? 30 : 20 }}
                  alignItems={"center"}
                  id="sold"
                >
                  <Grid item xs={12} sm={3} md={2} lg={2}>
                    <CTypography required title="Scrap Status" />
                  </Grid>
                  <Grid item xs={12} sm={9} md={10} lg={10}>
                    <FormControl fullWidth error={errObj.soldErr}>
                      <Select
                        displayEmpty
                        value={sold}
                        onChange={(event) => {
                          SetSold(event.target.value);
                          setErrObj({
                            ...errObj,
                            soldErr: false,
                            soldMsg: "",
                          });
                        }}
                        size={"medium"}
                        style={sold === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} hidden disabled>
                          Select the Scrap Status
                        </MenuItem>

                        <MenuItem key={1} value={"1"}>
                          Sold
                        </MenuItem>
                        <MenuItem key={0} value={"0"}>
                          Unsold
                        </MenuItem>
                      </Select>
                      {errObj.soldErr ? (
                        <FormHelperText>{errObj.soldMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            {isEdit &&
              editData?.category === "Claim" &&
              category === "Claim" && (
                <Grid
                  container
                  style={{ marginBottom: errObj.claimStatusErr ? 30 : 20 }}
                  alignItems={"center"}
                  id="claim"
                >
                  <Grid item xs={12} sm={3} md={2} lg={2}>
                    <CTypography required title="Claim Status" />
                  </Grid>
                  <Grid item xs={12} sm={9} md={10} lg={10}>
                    <FormControl fullWidth error={errObj.claimStatusErr}>
                      <Select
                        displayEmpty
                        value={claimStatus}
                        onChange={(event) => {
                          SetClaimStatus(event.target.value);
                          setErrObj({
                            ...errObj,
                            claimStatusErr: false,
                            claimStatusMsg: "",
                          });
                        }}
                        size={"medium"}
                        style={claimStatus === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} hidden disabled>
                          Select the Claim Status
                        </MenuItem>
                        <MenuItem key={0} value={"0"}>
                          Pending
                        </MenuItem>
                        <MenuItem key={1} value={"1"}>
                          Accepted
                        </MenuItem>
                        <MenuItem key={2} value={"2"}>
                          Rejected
                        </MenuItem>
                      </Select>
                      {errObj.claimStatusErr ? (
                        <FormHelperText>{errObj.claimStatusMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            {isEdit &&
              editData?.category === "Casing" &&
              category === "Casing" && (
                <Grid
                  container
                  style={{ marginBottom: errObj.casingStatusErr ? 30 : 20 }}
                  alignItems={"center"}
                  id="casing"
                >
                  <Grid item xs={12} sm={3} md={2} lg={2}>
                    <CTypography required title="Casing Status" />
                  </Grid>
                  <Grid item xs={12} sm={9} md={10} lg={10}>
                    <FormControl fullWidth error={errObj.casingStatusErr}>
                      <Select
                        displayEmpty
                        value={casingStatus}
                        onChange={(event) => {
                          SetCasingStatus(event.target.value);
                          setErrObj({
                            ...errObj,
                            casingStatusErr: false,
                            casingStatusMsg: "",
                          });
                        }}
                        size={"medium"}
                        style={claimStatus === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} hidden disabled>
                          Select the Casing Status
                        </MenuItem>
                        <MenuItem key={0} value={"0"}>
                          Pending
                        </MenuItem>
                        <MenuItem key={1} value={"1"}>
                          Accepted
                        </MenuItem>
                        <MenuItem key={2} value={"2"}>
                          Rejected
                        </MenuItem>
                      </Select>
                      {errObj.casingStatusErr ? (
                        <FormHelperText>
                          {errObj.casingStatusMsg}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
            {category === "On Wheel" && (
              <Grid
                container
                style={{ marginBottom: errObj.vehicleErr ? 30 : 20 }}
                alignItems={"center"}
                id="vehicle"
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <CTypography required title={"Vehicle No."} />
                </Grid>
                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <FormControl
                    fullWidth
                    error={errObj.vehicleErr}
                    sx={{
                      width: "100%",
                      border: errObj.vehicleErr ? "1px solid #ff1744" : null,
                      borderRadius: 1,
                    }}
                  >
                    <Autocomplete
                      fullWidth
                      isOptionEqualToValue={(option, value) =>
                        option.label === value
                      }
                      readOnly={isEdit && editData?.category === "On Wheel"}
                      disabled={isEmpty(fleet?.label)}
                      disableListWrap={true}
                      options={vehicleList}
                      value={vehicle?.label}
                      classes={{ input: `${styles.autocomplete}` }}
                      onChange={(v1, v) => {
                        setVehicle(v);
                        setErrObj({
                          ...errObj,
                          vehicleErr: false,
                          vehicleMsg: "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles.inputDivStyle}
                          placeholder={
                            isEmpty(vehicle?.label)
                              ? "Select Vehicle No."
                              : null
                          }
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        const renderTagsValue = tagValue;
                        return (
                          // <Typography noWrap={true} color="textPrimary">
                          renderTagsValue
                          // </Typography>
                        );
                      }}
                    />

                    {errObj.vehicleErr ? (
                      <FormHelperText>{errObj.vehicleMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              style={{ marginBottom: errObj.tyreConditionErr ? 30 : 20 }}
              alignItems={"center"}
              id="tyreCondition"
            >
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <CTypography required title={"Tyre Condition"} />
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={10}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errObj.tyreConditionErr}>
                    <Select
                      displayEmpty
                      value={tyreCondition}
                      onChange={(event) => {
                        settyreCondition(event.target.value);
                        setErrObj({
                          ...errObj,
                          tyreConditionErr: false,
                          tyreConditionMsg: "",
                        });
                      }}
                      size={"medium"}
                      style={tyreCondition === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select the Tyre Condition
                      </MenuItem>
                      {tyre_condition.map((item, index) => (
                        <MenuItem key={index} value={item?.label}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errObj.tyreConditionErr ? (
                      <FormHelperText>
                        {errObj.tyreConditionMsg}
                        {}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.tyretypeErr ? 30 : 20 }}
              id="tyreType"
            >
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <CTypography required title={"Tyre Type"} />
              </Grid>

              <Grid item xs={12} sm={9} md={10} lg={10}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errObj.tyretypeErr}>
                    <Select
                      displayEmpty
                      value={tyreType}
                      onChange={(event) => {
                        settyreType(event.target.value);
                        setErrObj({
                          ...errObj,
                          tyretypeErr: false,
                          tyretypeMsg: "",
                        });
                      }}
                      size={"medium"}
                      style={tyreType === "" ? { color: "#A2A2A2" } : {}}
                      inputProps={{
                        readOnly: noChange,
                      }}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select the Tyre Type
                      </MenuItem>
                      {dropDownData.tyre_type.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errObj.tyretypeErr ? (
                      <FormHelperText>{errObj.tyretypeMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            {(tyreType === 45 || tyreType === 46) && (
              <Grid
                container
                alignItems={"center"}
                style={{ marginBottom: errObj.prereadingKmErr ? 30 : 20 }}
                id="prereading"
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <Typography>Previous Reading Km :</Typography>
                </Grid>

                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    value={prereading}
                    placeholder="Enter Previous Reading Km"
                    onChange={(e) => {
                      if (
                        e?.nativeEvent?.data === "0" ||
                        e?.nativeEvent?.data === "1" ||
                        e?.nativeEvent?.data === "2" ||
                        e?.nativeEvent?.data === "3" ||
                        e?.nativeEvent?.data === "4" ||
                        e?.nativeEvent?.data === "5" ||
                        e?.nativeEvent?.data === "6" ||
                        e?.nativeEvent?.data === "7" ||
                        e?.nativeEvent?.data === "8" ||
                        e?.nativeEvent?.data === "9" ||
                        e?.nativeEvent?.data === "." ||
                        isNull(e?.nativeEvent?.data)
                      ) {
                        // converter(e.target.value, "prereading");
                        setprereading(e.target.value);
                      }
                    }}
                    // inputProps={{
                    //   readOnly:
                    //     (isEdit && !isEmpty(editData?.old_mileage)) || noChange,
                    // }}
                    error={errObj.prereadingKmErr}
                    helperText={
                      errObj.prereadingKmErr ? errObj.prereadingKmMsg : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Km</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={2} />
                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <Typography
                    style={{
                      marginTop: errObj.prereadingKmErr ? 20 : 10,
                      fontSize: 12,
                    }}
                  >
                    <b>Note :</b> Please do not start value with 0.
                  </Typography>
                </Grid>
              </Grid>
            )}

            {tyreType === 45 && (
              <Grid
                container
                alignItems={"center"}
                style={{ marginBottom: errObj.rethreadErr ? 30 : 20 }}
                id="rethreadType"
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <CTypography required title="No. of Retread" />
                </Grid>

                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth error={errObj.rethreadErr}>
                      <Select
                        displayEmpty
                        value={rethread}
                        onChange={(event) => {
                          setrethread(event.target.value);
                          setErrObj({
                            ...errObj,
                            rethreadErr: false,
                            rethreadMsg: "",
                          });
                        }}
                        size={"medium"}
                        style={rethread === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} hidden disabled>
                          Select the No. of Retread
                        </MenuItem>
                        <MenuItem value={1} selected>
                          1
                        </MenuItem>
                        <MenuItem value={2}>2</MenuItem>
                        <MenuItem value={3}>3</MenuItem>
                      </Select>
                      {errObj.rethreadErr ? (
                        <FormHelperText>
                          {errObj.rethreadMsg}
                          {}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            )}

            {category === "On Wheel" && (
              <Grid
                container
                style={{ marginBottom: errObj.noOfRowErr ? 30 : 20 }}
                alignItems={"center"}
                id="noOfRows"
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <CTypography required title={"Select Axle"} />
                </Grid>
                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth error={errObj.noOfRowErr}>
                      <Select
                        displayEmpty
                        disabled={!isObject(vehicleDetails)}
                        value={noOfRows}
                        onChange={(event) => {
                          setNoOfRows(event.target.value);
                          setErrObj({
                            ...errObj,
                            noOfRowErr: false,
                            noOfRowMsg: "",
                          });
                        }}
                        size={"medium"}
                        style={noOfRows === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Select Axle
                        </MenuItem>
                        {arr.map((item, index) => {
                          const isLimit = item <= vehicleDetails?.no_of_rows;
                          return (
                            isLimit && (
                              <MenuItem key={index} value={item}>
                                {item}
                              </MenuItem>
                            )
                          );
                        })}
                      </Select>
                      {errObj.noOfRowErr ? (
                        <FormHelperText>
                          {errObj.noOfRowMsg}
                          {}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            )}

            {category === "On Wheel" && (
              <Grid
                container
                style={{ marginBottom: errObj.axleTypeErr ? 30 : 20 }}
                alignItems={"center"}
                id="axleType"
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <CTypography required title={"Axle Type"} />
                </Grid>
                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth error={errObj.axleTypeErr}>
                      <Select
                        displayEmpty
                        value={axleType}
                        onChange={(event) => {
                          setaxleType(event.target.value);
                          setErrObj({
                            ...errObj,
                            axleTypeErr: false,
                            axleTypeMsg: "",
                          });
                        }}
                        size={"medium"}
                        style={axleType === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Select the Axle Type
                        </MenuItem>
                        {dropDownData.axle_type.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item?.label}
                          </MenuItem>
                        ))}
                      </Select>
                      {errObj.axleTypeErr ? (
                        <FormHelperText>
                          {errObj.axleTypeMsg}
                          {}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Box>
                </Grid>
              </Grid>
            )}

            {category === "On Wheel" && (
              <Grid
                container
                style={{ marginBottom: errObj.tyrePositionErr ? 30 : 20 }}
                alignItems={"center"}
                id="tyrePosition"
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <CTypography required title={"Tyre Position"} />
                </Grid>
                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Enter Tyre Position"
                    value={tyrePosition}
                    error={errObj.tyrePositionErr}
                    helperText={
                      errObj.tyrePositionErr ? errObj.tyrePositionMsg : null
                    }
                    onChange={(e) => {
                      settyrePosition(e.target.value);
                      setErrObj({
                        ...errObj,
                        tyrePositionErr: false,
                        tyrePositionMsg: "",
                      });
                    }}
                    inputProps={{
                      maxLength: 4,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={3} md={2} lg={2} />
                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <Typography
                    style={{
                      marginTop: errObj.tyrePositionErr ? 20 : 10,
                      fontSize: 12,
                    }}
                  >
                    <b>Note :</b> Please enter the first character as D or C,
                    the subsequent two characters should be{" "}
                    {rows !== "00" ? rows : "numbers"}, and the last/fourth
                    character as either I or O. <br /> For e.g. The outer facing
                    tyre on the third row on the Driver's side of the vehicle
                    will be D{rows !== "00" ? rows : "03"}O.
                  </Typography>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              style={{ marginBottom: errObj.brandErr ? 30 : 20 }}
              alignItems={"center"}
              id="tyreBrand"
            >
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <CTypography required title={"Tyre Brand"} />
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={10}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errObj.brandErr}>
                    <Select
                      displayEmpty
                      value={tyreBrand}
                      onChange={(event) => {
                        settyreBrand(event.target.value);
                        setIsChange(true);
                        setErrObj({
                          ...errObj,
                          brandErr: false,
                          brandMsg: "",
                        });
                        clearChangeData();
                      }}
                      size={"medium"}
                      style={tyreBrand === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select the Tyre Brand
                      </MenuItem>
                      {dropDownData.tyre_brand.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                    {errObj.brandErr ? (
                      <FormHelperText>
                        {errObj.brandMsg}
                        {}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography>Item Description :</Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={10}>
                <FormControl variant="outlined" fullWidth>
                  <Autocomplete
                    fullWidth
                    disabled={isEmpty(tyreBrand?.toString()) ? true : false}
                    value={patternSize}
                    onChange={(event, newValue) => {
                      if (typeof newValue === "string") {
                        setPAtternSize({
                          label: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // Create a new value from the user input
                        setPAtternSize({
                          label: newValue.inputValue,
                        });
                      } else {
                        setPAtternSize(newValue);
                      }
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        v.defaultMuiPrevented = true;
                      }
                    }}
                    filterOptions={(options, params) => {
                      const filtered = filter(options, params);
                      const { inputValue } = params;
                      // Suggest the creation of a new value
                      const isExisting = options.some(
                        (option) => inputValue === option.label
                      );
                      if (inputValue !== "" && !isExisting) {
                        filtered.push({
                          inputValue,
                          label: `Add "${inputValue}"`,
                        });
                      }

                      return filtered;
                    }}
                    selectOnFocus
                    clearOnBlur
                    handleHomeEndKeys
                    id="free-solo-with-text-demo"
                    options={tyrePatternList}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.inputValue) {
                        return option.inputValue;
                      }
                      // Regular option
                      return option.label;
                    }}
                    renderOption={(props, option) => (
                      <li {...props}>{option.label}</li>
                    )}
                    freeSolo
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Enter the Item Description"
                      />
                    )}
                  />
                </FormControl>
              </Grid>
            </Grid>

            <Grid container alignItems={"center"} style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography>Tyre Composition :</Typography>
              </Grid>

              <Grid item xs={12} sm={9} md={10} lg={10}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      value={tyreComposition}
                      onChange={(event) => {
                        settyreComposition(event.target.value);
                      }}
                      inputProps={{ readOnly: readOnly }}
                      size={"medium"}
                      style={tyreComposition === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select the Tyre Composition
                      </MenuItem>
                      {dropDownData.tyre_composition.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography>Tread Pattern : </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={10}>
                <FormControl fullWidth>
                  <Select
                    displayEmpty
                    value={treadPattern}
                    onChange={(event) => {
                      settreadPattern(event.target.value);
                    }}
                    inputProps={{ readOnly: readOnly }}
                    size={"medium"}
                    style={treadPattern === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""} hidden disabled>
                      Select the Tread Pattern
                    </MenuItem>
                    {dropDownData.tread_pattern_data.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>

            <Grid container alignItems={"center"} style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography>Ply Rating :</Typography>
              </Grid>

              <Grid item xs={12} sm={9} md={10} lg={10}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      value={plyRating}
                      onChange={(event) => {
                        setplyRating(event.target.value);
                      }}
                      inputProps={{ readOnly: readOnly }}
                      size={"medium"}
                      style={plyRating === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select the Ply Rating
                      </MenuItem>
                      {dropDownData.ply_rating.map((item, index) => (
                        <MenuItem key={index} value={item?.id}>
                          {item?.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            {/* <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Item Description : </Typography>
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Item Description"
              value={tyrePattern}
              onChange={(e) => {
                settyrePattern(e.target.value);
              }}
            />
          </Grid>
        </Grid> */}

            <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography>Original NSD : </Typography>
              </Grid>

              <Grid item xs={12} sm={9} md={10} lg={10}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  value={NSD}
                  type={"number"}
                  placeholder="Enter Original NSD"
                  onChange={(v) => setNSD(v?.target?.value)}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                    readOnly: readOnly,
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {formValues.map((v, index) => {
              const isLast = index === formValues.length - 1;
              return (
                <Grid
                  key={`new_${v?.id}`}
                  container
                  style={{ marginBottom: 20 }}
                  alignItems={"center"}
                  id={`nsdErr_${index}`}
                >
                  <Grid item xs={12} sm={3} md={2}>
                    <Typography>
                      {index === 0 ? "Initial NSD : " : ""}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    container
                    xs={12}
                    sm={9}
                    md={10}
                    gap={1}
                    wrap={isTablet ? "nowrap" : "wrap"}
                    style={{
                      border: v?.nsdErr ? "1px solid red" : "",
                      padding: v?.nsdErr ? 5 : "",
                    }}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={2.4}
                      md={2.33}
                      // style={{ marginTop: isTablet ? 20 : 0 }}
                    >
                      <TextField
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Groove 1"
                        value={v.groove1}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                        }}
                        onChange={(e) => {
                          handleChange(index, e?.target?.value, 1);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2.4}
                      md={2.33}
                      // style={{ marginTop: md ? 20 : 0 }}
                    >
                      <TextField
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Groove 2"
                        value={v.groove2}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                        }}
                        onChange={(e) => {
                          handleChange(index, e?.target?.value, 2);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2.4}
                      md={2.33}
                      // style={{ marginTop: md ? 20 : 0 }}
                    >
                      <TextField
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Groove 3"
                        value={v.groove3}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                        }}
                        onChange={(e) => {
                          handleChange(index, e?.target?.value, 3);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={2.4}
                      md={2.33}
                      // style={{ marginTop: md ? 20 : 0 }}
                    >
                      <TextField
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Groove 4"
                        value={v.groove4}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                        }}
                        onChange={(e) => {
                          handleChange(index, e?.target?.value, 4);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sm={1}
                      md={2}
                      // style={{ marginTop: md ? 20 : 0 }}
                    >
                      <Button
                        style={{
                          backgroundColor: !isLast
                            ? color.secondary
                            : formValues.length === 4 && isLast
                            ? "rgba(0, 0, 0, 0.12)"
                            : color.primary,
                          width: "100%",
                          boxShadow:
                            formValues.length === 4 && isLast ? "unset" : "",
                        }}
                        disabled={formValues.length === 4 && isLast}
                        onClick={() =>
                          !isLast ? removeFormFields(index) : addFormFields()
                        }
                      >
                        {!isLast ? (
                          isTablet ? (
                            <RemoveIcon />
                          ) : (
                            "Remove"
                          )
                        ) : isTablet ? (
                          <AddIcon />
                        ) : (
                          "Add More"
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography>Avg. Initial NSD : </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={10}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter Current NSD"
                  value={avgNSD}
                  // onChange={(e) => {
                  //   settreadPattern(e.target.value);
                  // }}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                    readOnly: true,
                  }}
                />
              </Grid>
            </Grid>

            {category === "On Wheel" && (
              <Grid
                container
                style={{ marginBottom: 20 }}
                alignItems={"center"}
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <CTypography title="Initial Air Pressure" />
                </Grid>

                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    value={airPressure}
                    placeholder="Enter Initial Air Pressure in psi"
                    onChange={(e) => {
                      setairPressure(e.target.value);
                      setErrObj({
                        ...errObj,
                        airPressureErr: false,
                        airPressureMsg: "",
                      });
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">psi</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}

            {category === "On Wheel" && (
              <Grid
                container
                style={{ marginBottom: errObj.fitmentKmErr ? 30 : 20 }}
                alignItems={"center"}
                id="fitmentKm"
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <Typography>Tyre Fitment Reading :</Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    value={fitmentKm}
                    placeholder="Enter Fitment Reading in Km"
                    onChange={(e) => {
                      if (
                        e?.nativeEvent?.data === "0" ||
                        e?.nativeEvent?.data === "1" ||
                        e?.nativeEvent?.data === "2" ||
                        e?.nativeEvent?.data === "3" ||
                        e?.nativeEvent?.data === "4" ||
                        e?.nativeEvent?.data === "5" ||
                        e?.nativeEvent?.data === "6" ||
                        e?.nativeEvent?.data === "7" ||
                        e?.nativeEvent?.data === "8" ||
                        e?.nativeEvent?.data === "9" ||
                        e?.nativeEvent?.data === "." ||
                        isNull(e?.nativeEvent?.data)
                      ) {
                        // converter(e.target.value, "fitment");
                        setfitmentKm(e.target.value);
                      }
                    }}
                    error={errObj.fitmentKmErr}
                    helperText={
                      errObj.fitmentKmErr ? errObj.fitmentKmMsg : null
                    }
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Km</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid
              container
              style={{ marginBottom: errObj.purchaseCostErr ? 30 : 20 }}
              alignItems={"center"}
              id="purchaseCost"
            >
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography>Purchase Cost : </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={10}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  value={purchaseCost}
                  placeholder="Enter the Cost of Tyre"
                  onChange={(e) => {
                    if (
                      e?.nativeEvent?.data === "0" ||
                      e?.nativeEvent?.data === "1" ||
                      e?.nativeEvent?.data === "2" ||
                      e?.nativeEvent?.data === "3" ||
                      e?.nativeEvent?.data === "4" ||
                      e?.nativeEvent?.data === "5" ||
                      e?.nativeEvent?.data === "6" ||
                      e?.nativeEvent?.data === "7" ||
                      e?.nativeEvent?.data === "8" ||
                      e?.nativeEvent?.data === "9" ||
                      e?.nativeEvent?.data === "." ||
                      isNull(e?.nativeEvent?.data)
                    ) {
                      // converter(e.target.value);
                      setpurchaseCost(e.target.value);
                    }
                  }}
                  error={errObj.purchaseCostErr}
                  helperText={
                    errObj.purchaseCostErr ? errObj.purchaseCostMsg : null
                  }
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">₹</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            {(category === "Stock" || category === "Claim") && (
              <Grid
                container
                style={{ marginBottom: errObj.castingErr ? 30 : 20 }}
                alignItems={"center"}
                id="casting"
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <Typography>Casing Value : </Typography>
                </Grid>
                <Grid item xs={12} sm={9} md={10} lg={10}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    value={castingCost}
                    placeholder="Enter the Casing Value of Tyre"
                    onChange={(e) => {
                      if (
                        e?.nativeEvent?.data === "0" ||
                        e?.nativeEvent?.data === "1" ||
                        e?.nativeEvent?.data === "2" ||
                        e?.nativeEvent?.data === "3" ||
                        e?.nativeEvent?.data === "4" ||
                        e?.nativeEvent?.data === "5" ||
                        e?.nativeEvent?.data === "6" ||
                        e?.nativeEvent?.data === "7" ||
                        e?.nativeEvent?.data === "8" ||
                        e?.nativeEvent?.data === "9" ||
                        e?.nativeEvent?.data === "." ||
                        isNull(e?.nativeEvent?.data)
                      ) {
                        // converter(e.target.value, "casting");
                        setcastingCost(e.target.value);
                      }
                    }}
                    error={errObj.castingErr}
                    helperText={errObj.castingErr ? errObj.castingMsg : null}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">₹</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            )}

            <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <Typography>Purchased From : </Typography>
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={10}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  value={purchaseFrom}
                  placeholder="Enter the Tyre Seller "
                  onChange={(e) => {
                    setpurchaseFrom(e.target.value);
                  }}
                />
              </Grid>
            </Grid>
            {category === "Scrap" && (
              <Grid
                container
                style={{ marginBottom: errObj.issueErr ? 30 : 20 }}
                alignItems={"center"}
              >
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <CTypography required title={"Tyre Issue"} />
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                  <FormControl fullWidth error={errObj.issueErr}>
                    <OutlinedInput
                      style={{ width: "100%", margin: 0, cursor: "pointer" }}
                      placeholder="Select the Tyre Issue"
                      inputProps={{
                        readOnly: true,
                        style: { cursor: "pointer" },
                      }}
                      value={renderTagsValue}
                      onClick={() => {
                        if (isArray(issueArry) && !isEmpty(issueArry)) {
                          setOpenCard(true);
                        } else {
                          toast.warning(
                            "Please Add Scrap Analysis Tyre Issue First from General DropDowns"
                          );
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <ArrowDropDownIcon />
                        </InputAdornment>
                      }
                    />
                    {errObj.issueErr ? (
                      <FormHelperText>{errObj.issueMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            {category === "Scrap" && (
              <Grid
                container
                alignItems={"center"}
                style={{ marginBottom: 20 }}
              >
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Typography>Remarks : </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  lg={10}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Enter Remarks here"
                    value={remarks}
                    rows={4}
                    multiline
                    onChange={(e) => {
                      setRemarks(e.target.value);
                    }}
                  />
                </Grid>
              </Grid>
            )}
            {category === "On Wheel" && (
              <Grid
                container
                alignItems={"center"}
                id="fDate"
                style={{
                  marginBottom: errObj.fitmentDateErr ? 30 : 20,
                }}
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <Typography>Fitment Date :</Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={10}
                  lg={10}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      minDate={new Date(2000, 0, 1)}
                      showToolbar={false}
                      disableFuture
                      views={["year", "month", "day"]}
                      value={fitmentDate}
                      onChange={(newValue) => {
                        setFitmentDate(newValue);
                        setErrObj({
                          ...errObj,
                          fitmentDateErr: false,
                          fitmentDateMsg: "",
                        });
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "Please Enter Date",
                          }}
                          sx={{
                            svg: {
                              color: errObj.fitmentDateErr
                                ? "#ff1744"
                                : color.primary,
                            },
                          }}
                          error={errObj.fitmentDateErr}
                          helperText={errObj.fitmentDateMsg}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            )}

            {isImageShow && (
              <Grid
                container
                style={{ marginBottom: 20 }}
                id="upload"
                alignItems={"center"}
                wrap={isMobile && !isTablet ? "wrap" : "nowrap"}
              >
                <Grid item xs={12} sm={3} md={2} lg={2}>
                  <Typography>Upload Image : </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={10}
                  lg={10}
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    {!isEmpty(selectedFile) ? (
                      selectedFile.map((item, index) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                            }}
                          >
                            <Typography
                              style={{
                                color: isEmpty(selectedFile)
                                  ? color.disable
                                  : color.primary,
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setShowImg(item);
                                setTimeout(() => openModal(true), 200);
                              }}
                            >
                              View Image-{index + 1}
                            </Typography>
                            <IconButton
                              onClick={() => {
                                removeImage(index);
                              }}
                            >
                              <HighlightOffIcon style={{ color: "red" }} />
                            </IconButton>
                          </div>
                        );
                      })
                    ) : (
                      <Typography
                        style={{
                          color:
                            isEmpty(selectedFile) && isArray(selectedFile)
                              ? "#999"
                              : color.black,
                          maxWidth: "50vw",
                          overflow: "hidden",
                          wordWrap: "break-word",
                          whiteSpace: "break-spaces",
                        }}
                      >
                        No File Selected
                      </Typography>
                    )}
                  </div>
                  <Button
                    variant="contained"
                    component="label"
                    disabled={selectedFile?.length === 5}
                    style={{
                      backgroundColor:
                        selectedFile?.length === 5
                          ? color.disable
                          : color.secondary,
                      margin: "0 20px",
                      width: 100,
                    }}
                  >
                    <input
                      multiple
                      type="file"
                      onChange={(e) => {
                        uploadImg(e.target.files);
                      }}
                      accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg, application/pdf"
                      hidden
                    />
                    Upload File
                  </Button>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              alignItems={"center"}
              style={{
                marginBottom: errObj.dateErr ? 30 : 20,
              }}
              id="date"
            >
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <CTypography required title={"Added Date"} />
              </Grid>
              <Grid
                item
                xs={12}
                sm={9}
                md={10}
                lg={10}
                style={{ display: "flex", alignItems: "center" }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={new Date(2000, 0, 1)}
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                      setErrObj({
                        ...errObj,
                        dateErr: false,
                        dateMsg: "",
                      });
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Please Enter Date",
                        }}
                        sx={{
                          svg: {
                            color: errObj.dateErr ? "#ff1744" : color.primary,
                          },
                        }}
                        error={errObj.dateErr}
                        helperText={errObj.dateMsg}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          </>
        )}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginBottom: 20 }}
        >
          <Button
            disabled={isOnline ? false : true}
            onClick={validation}
            style={{
              backgroundColor: isOnline ? color.primary : color.bordercolor,
              marginRight: 10,
              width: 130,
            }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : isEdit ? (
              `Save & Update`
            ) : (
              `Add Tyre`
            )}
          </Button>
          <Button
            variant="contained"
            style={{
              backgroundColor:
                fromInsideType === "inside#success"
                  ? color?.disable
                  : color.secondary,
              width: 130,
            }}
            disabled={fromInsideType === "inside#success"}
            onClick={() => {
              handleClick("cancel");
              dispatch(setEditInventoryData({}));
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
      <Modal
        onClose={() => {
          setOpenCard(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 650,
            maxHeight: 650,
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: 550 }}
          >
            {issueArry.map((item, index) => {
              return (
                <ImageListItem
                  key={index}
                  style={{
                    width: 200,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      SelectTyre(index);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={handleSelect}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        onClose={() => {
          openModal(false);
          setShowImg("");
        }}
        open={modal}
      >
        <img
          style={{
            backgroundColor: color.white,
            maxWidth: md ? 400 : 800,
            maxHeight: "80vh",
            borderRadius: 4,
          }}
          src={showImg ? showImg : ""}
          alt=""
        />
      </Modal>
    </Box>
  );
}
