import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";
const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    overflowY: "scroll",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    "@media (max-width:425px)": {
      padding: 10,
    },
    "&::-webkit-scrollbar": {
      display: "none !important",
    },
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  selected: {
    borderWidth: 2,
    borderColor: color.primary,
    padding: 2,
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
