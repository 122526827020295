import React, { useEffect, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  FormHelperText,
  IconButton,
  ListItemText,
} from "@mui/material";
import useStyles from "./styles.js";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { CTypography } from "../../Fleet/AddFleetForm/index";
import { color } from "../../../Config/theme";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import ConfirmDialog from "../../ConfirmDialog/index.js";
import { Visibility, VisibilityOff } from "@mui/icons-material";

const errorObj = {
  privilegeErr: false,
  userIdErr: false,
  userpwdErr: false,
  userEmlErr: false,
  FnameErr: false,
  contactNumErr: false,
  empDesignationErr: false,
  selectedFileErr: false,
  userStatusErr: false,
  AddressErr: false,
  officecontactErr: false,
  officeRegionErr: false,
  secselectedFileErr: false,
  serviceEngineerErr: false,
  singleFleetErr: false,
  companyNameErr: false,
  singleFleetMsg: "",
  serviceEngineerMsg: "",
  privilegeMsg: "",
  userIdMsg: "",
  userpwdMsg: "",
  userEmlMsg: "",
  FnameMsg: "",
  contactNumMsg: "",
  empDesignationMsg: "",
  selectedFileMsg: "",
  userStatusMsg: "",
  AddressMsg: "",
  officecontactMsg: "",
  officeRegionMsg: "",
  secselectedFileMsg: "",
  companyNameMsg: "",
};

export default function AddUser(props) {
  const { editData = {}, handleClick = () => null } = props;
  const isEdit = !isEmpty(editData);
  const styles = useStyles();

  const { token, fleetData, dropDownData, isOnline } = useSelector(
    (state) => state.auth
  );

  const [errObj, setErrObj] = useState(errorObj);
  const [userId, setuserId] = useState("");
  const [userpwd, setuserpwd] = useState("");
  const [userEml, setuserEml] = useState("");
  const [Fname, setFname] = useState("");
  const [contactNum, setcontactNum] = useState("");
  const [salesEmpcode, setsalesEmpcode] = useState("");
  const [empDesignation, setempDesignation] = useState("");
  const [file, setFile] = useState("No File Selected");
  const [selectedFile, setSlectedFile] = useState(null);
  const [companyName, setCompanyName] = useState("");
  const [Address, setAddress] = useState("");
  const [officecontact, setofficecontact] = useState("");
  const [secfile, setsecFile] = useState("No File Selected");
  const [secselectedFile, setsecSlectedFile] = useState(null);
  const [thirddropDown, setthirddropDown] = useState("");
  const [secdropDown, setsecdropDown] = useState(10);
  const [dropDown, setdropDown] = useState("");
  const [singleFleet, setSinglefleet] = useState([]);

  const [seviceListArr, setSeviceListArr] = useState([]);
  const [serviceEngineer, setServiceEngineer] = useState([]);

  const [btnLoad, setBtnLoad] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const email_Regex = Setting.JS_Regex.email_Regex;
  const alphabatic_Regex = Setting.JS_Regex.alphabatic_Regex;

  useEffect(() => {
    if (isEdit) {
      setFname(editData?.name || "");
      setuserEml(editData?.email || "");
      setdropDown(editData?.role || "");
      setuserId(editData?.user_name || "");
      setsecdropDown(editData?.status || 10);
      setcontactNum(editData?.phone || "");
      const fileName = !isEmpty(editData?.user_photo)
        ? editData?.user_photo.split("/")
        : "No file selected";
      setFile(
        !isEmpty(editData?.user_photo)
          ? fileName[fileName.length - 1]
          : "No File Selected"
      );
      // setsalesEmpcode(editData?.sales_employee_code || "");
      setempDesignation(editData?.employee_designation || "");
      setAddress(editData?.address || "");
      setofficecontact(editData?.office_contact_no || "");
      const fileName1 = !isEmpty(editData?.business_pan_card)
        ? editData?.business_pan_card.split("/")
        : "No file selected";
      setsecFile(
        !isEmpty(editData?.business_pan_card)
          ? fileName1[fileName1.length - 1]
          : "No File Selected"
      );
      setsecSlectedFile(editData?.business_pan_card || null);
      setthirddropDown(editData?.office_region || "");
      setSlectedFile(editData?.user_photo || null);
      let res =
        isArray(fleetData) &&
        !isEmpty(fleetData) &&
        fleetData?.filter((item) => {
          let a = editData.fleet_allocated.includes(item.id);
          return a;
        });
      setSinglefleet(res || []);
      setCompanyName(editData?.company_name || "");
    } else {
      getUserList();
    }
  }, []);

  useEffect(() => {
    if (editData?.role === "fk_ro") {
      let res1 =
        isArray(seviceListArr) &&
        !isEmpty(seviceListArr) &&
        seviceListArr?.filter((item) => {
          let a = editData.service_engineer_ids.includes(item.id);
          return a;
        });
      setServiceEngineer(res1 || []);
    }
  }, [seviceListArr]);

  useEffect(() => {
    setErrObj(errorObj);
    if (!isEdit) {
      setServiceEngineer([]);
      setFile("No File Selected");
      setsecFile("No File Selected");
      setSlectedFile(null);
      setsecSlectedFile(null);
    }
  }, [dropDown]);

  useEffect(() => {
    getOficeRegionData();
  }, [thirddropDown]);

  async function getUserList(bool) {
    let endpoints = `${Setting.endpoints.userlist}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          const listObj = {
            pagination: response?.data?.pagination,
            data: response?.data?.rows || [],
          };
          // setsalesEmpcode(
          //   `E ${String(listObj?.pagination?.last_id + 1).padStart(4, 0)}`
          // );
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const getOficeRegionData = async () => {
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.serviceEngineerList
        }?unassigned=1&region=${thirddropDown}&user_id=${
          isEdit ? editData?.id : ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setSeviceListArr(response?.data);
        } else {
          setSeviceListArr([]);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const handleChange = (event) => {
    setdropDown(event.target.value);
    setErrObj({
      ...errObj,
      privilegeErr: false,
      privilegeMsg: "",
    });
  };

  const sechandleChange = (event) => {
    setsecdropDown(event.target.value);
    setErrObj({
      ...errObj,
      userStatusErr: false,
      userStatusMsg: "",
    });
  };

  const thirdhandleChange = (event) => {
    setthirddropDown(event.target.value);
    setErrObj({
      ...errObj,
      officeRegionErr: false,
      officeRegionMsg: "",
    });
  };

  const submitData = async () => {
    !isEdit && setBtnLoad(true);
    setRemoveBtnLoad(true);
    let endPoints = Setting.endpoints.adduser;

    const array = [];
    isArray(serviceEngineer) &&
      !isEmpty(serviceEngineer) &&
      serviceEngineer.map((v) => {
        array.push(v.id);
      });

    const array2 = [];
    isArray(singleFleet) &&
      !isEmpty(singleFleet) &&
      singleFleet.map((v) => {
        array2.push(v.id);
      });

    const params = {
      "User[name]": Fname,
      "User[email]": userEml,
      "User[role]": dropDown,
      "User[status]": secdropDown,
      "User[phone]": contactNum,
      "User[user_name]": userId,
    };
    if (!isEdit) {
      params["User[password_hash]"] = userpwd;
    }
    if (isEdit) {
      params["User[id]"] = editData?.id;
    }
    if (dropDown === "admin") {
      // params["User[sales_employee_code]"] = salesEmpcode;
      params["User[employee_designation]"] = empDesignation;
      params["User[user_photo]"] = selectedFile;
    }
    if (dropDown === "fk_ro") {
      // params["User[sales_employee_code]"] = salesEmpcode;
      params["User[employee_designation]"] = empDesignation;
      params["User[office_region]"] = thirddropDown;
      params["User[address]"] = Address;
      params["User[office_contact_no]"] = officecontact;
      params["User[service_engineer_allocated]"] = array;
      params["User[user_photo]"] = selectedFile;
    }
    if (dropDown === "service_enginner") {
      // params["User[sales_employee_code]"] = salesEmpcode;
      params["User[employee_designation]"] = empDesignation;
      params["User[office_region]"] = thirddropDown;
      params["User[user_photo]"] = selectedFile;
    }
    if (dropDown === "fleet_owner") {
      params["User[fleet_allocated]"] = array2;
      params["User[business_pan_card]"] = secselectedFile;
    }
    if (dropDown === "fleet_manager") {
      params["User[fleet_allocated]"] = array2;
    }
    if (dropDown === "call_centre") {
      params["User[company_name]"] = companyName;
      params["User[office_region]"] = thirddropDown;
      params["User[address]"] = Address;
      params["User[office_contact_no]"] = officecontact;
      params["User[user_photo]"] = selectedFile;
    }

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        handleClick("cancel");
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setBtnLoad(false);
      setRemoveBtnLoad(false);
    } catch (error) {
      console.log("ERRRRR", error);
      toast.error(error.toString());
      setBtnLoad(false);
      setRemoveBtnLoad(false);
    }
  };

  const deleteUser = async () => {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.deleteuser}?id=${editData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        handleClick("cancel");
        setConfirmDialog(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  };

  const clearData = () => {
    setFname("");
    setuserEml("");
    setdropDown("");
    setsecdropDown(10);
    setcontactNum("");
    setFile("No File Selected");
    // setsalesEmpcode("");
    setempDesignation("");
    setAddress("");
    setofficecontact("");
    setsecFile("No File Selected");
    setsecSlectedFile(null);
    setthirddropDown("");
    setSlectedFile(null);
    setSinglefleet([]);
    setServiceEngineer([]);
  };

  const checkValidation = () => {
    const error = { ...errObj };
    let valid = true;
    let section = null;
    let scroll = false;
    if (isEmpty(dropDown.toString())) {
      valid = false;
      scroll = true;
      error.privilegeErr = true;
      error.privilegeMsg = "Please select Privilege ";
      section = document.querySelector("#dropDown");
    }

    if (!isEdit && isEmpty(userId.trim())) {
      valid = false;
      error.userIdErr = true;
      error.userIdMsg = "Please enter Contact No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#userId");
      }
    }

    if (!isEdit && isEmpty(userpwd.trim())) {
      valid = false;
      error.userpwdErr = true;
      error.userpwdMsg = "Please enter the User Password";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#userpwd");
      }
    } else if (!isEdit && (userpwd.length < 6 || userpwd.length > 15)) {
      valid = false;
      error.userpwdErr = true;
      error.userpwdMsg = "The Password length must Between 6 to 15 Character";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#userpwd");
      }
    }

    if (isEmpty(Fname.trim())) {
      valid = false;
      error.FnameErr = true;
      error.FnameMsg = "Please enter the Name";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#Fname");
      }
    } else if (!alphabatic_Regex.test(Fname)) {
      valid = false;
      error.FnameErr = true;
      error.FnameMsg = "Please enter only Alphabets";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#Fname");
      }
    }
    if (!isEmpty(userEml.trim()) && !email_Regex.test(userEml)) {
      valid = false;
      error.userEmlErr = true;
      error.userEmlMsg = "Please enter valid Email";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#userEml");
      }
    }

    if (isEmpty(contactNum.trim())) {
      valid = false;
      error.contactNumErr = true;
      error.contactNumMsg = "Please enter Contact No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#contactNum");
      }
    } else if (contactNum.trim().length < 10) {
      valid = false;
      error.contactNumErr = true;
      error.contactNumMsg = "Please enter valid Contact No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#contactNum");
      }
    }

    if (isEmpty(secdropDown.toString())) {
      valid = false;
      error.userStatusErr = true;
      error.userStatusMsg = "Please enter the User Status";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#secdropDown");
      }
    }

    if (dropDown === "admin") {
      if (isEmpty(empDesignation.trim())) {
        valid = false;
        error.empDesignationErr = true;
        error.empDesignationMsg = "Please enter Emplyoee Designation";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#empDesignation");
        }
      }
      // if (isNull(selectedFile)) {
      //   valid = false;
      //   error.selectedFileErr = true;
      //   error.selectedFileMsg = "Please upload Photo ID";
      //   if (!scroll) {
      //     scroll = true;
      //     section = document.querySelector("#file");
      //   }
      // }
    }

    if (dropDown === "fk_ro") {
      if (isEmpty(empDesignation.trim())) {
        valid = false;
        error.empDesignationErr = true;
        error.empDesignationMsg = "Please enter Emplyoee Designation";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#empDesignation");
        }
      }
      if (isEmpty(thirddropDown.toString())) {
        valid = false;
        error.officeRegionErr = true;
        error.officeRegionMsg = "Please select the Regional Office";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#thirddropDown");
        }
      }
      if (isEmpty(Address.trim())) {
        valid = false;
        error.AddressErr = true;
        error.AddressMsg = "Please enter the Address";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#Address");
        }
      }
      if (isEmpty(officecontact)) {
        valid = false;
        error.officecontactErr = true;
        error.officecontactMsg = "Please enter the company Contact No.";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#officecontact");
        }
      } else if (officecontact.length < 10) {
        valid = false;
        error.officecontactErr = true;
        error.officecontactMsg = "Please enter valid Contact No.";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#officecontact");
        }
      }
      if (isEmpty(serviceEngineer)) {
        valid = false;
        error.serviceEngineerErr = true;
        error.serviceEngineerMsg = "Please select FK Tyre Care Executive";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#serviceEngineer");
        }
      }
      // if (isNull(selectedFile)) {
      //   valid = false;
      //   error.selectedFileErr = true;
      //   error.selectedFileMsg = "Please upload Photo ID";
      //   if (!scroll) {
      //     scroll = true;
      //     section = document.getElementById("#file");
      //   }
      // }
    }

    if (dropDown === "service_enginner") {
      if (isEmpty(empDesignation.trim())) {
        valid = false;
        error.empDesignationErr = true;
        error.empDesignationMsg = "Please enter Employee Designation";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#empDesignation");
        }
      }
      // if (isNull(selectedFile)) {
      //   valid = false;
      //   error.selectedFileErr = true;
      //   error.selectedFileMsg = "Please upload Photo ID";
      //   if (!scroll) {
      //     scroll = true;
      //     section = document.querySelector("#file");
      //   }
      // }
      if (isEmpty(thirddropDown.toString())) {
        valid = false;
        error.officeRegionErr = true;
        error.officeRegionMsg = "Please select the Regional Office";
      }
    }

    if (dropDown === "fleet_owner") {
      if (isEmpty(singleFleet.toString())) {
        valid = false;
        error.singleFleetErr = true;
        error.singleFleetMsg = "Please enter the Fleet Allocated";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#singleFleet");
        }
      }
      // if (isNull(secselectedFile)) {
      //   valid = false;
      //   error.selectedFileErr = true;
      //   error.selectedFileMsg = "Please upload Photo ID";
      //   if (!scroll) {
      //     scroll = true;
      //     section = document.querySelector("#secselectedFile");
      //   }
      // }
    }

    if (dropDown === "fleet_manager") {
      if (isEmpty(singleFleet.toString())) {
        valid = false;
        error.singleFleetErr = true;
        error.singleFleetMsg = "Please enter the Fleet Allocated";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#singleFleet");
        }
      }
    }

    if (dropDown === "call_centre") {
      // if (isNull(selectedFile)) {
      //   valid = false;
      //   error.selectedFileErr = true;
      //   error.selectedFileMsg = "Please upload Photo ID";
      //   if (!scroll) {
      //     scroll = true;
      //     section = document.querySelector("#file");
      //   }
      // }
      if (isEmpty(Address.trim())) {
        valid = false;
        error.AddressErr = true;
        error.AddressMsg = "Please enter the Address";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#Address");
        }
      }
      if (isEmpty(officecontact)) {
        valid = false;
        error.officecontactErr = true;
        error.officecontactMsg = "Please enter the Company Contact No.";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#officecontact");
        }
      } else if (officecontact.length < 10) {
        valid = false;
        error.officecontactErr = true;
        error.officecontactMsg = "Please enter valid Contact No.";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#officecontact");
        }
      }
      if (isEmpty(thirddropDown.toString())) {
        valid = false;
        error.officeRegionErr = true;
        error.officeRegionMsg = "Please select the Regional Office";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#thirddropDown");
        }
      }

      if (isEmpty(companyName)) {
        valid = false;
        error.companyNameErr = true;
        error.companyNameMsg = "Please enter the Company Name";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#companyName");
        }
      }
    }
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setErrObj(error);
    if (valid) {
      if (isEdit) {
        setIsEditDialog(true);
        setConfirmDialog(true);
      } else {
        submitData();
      }
    }
  };

  return (
    <Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>
          {isEdit ? "Edit User" : "Add User"}
        </Typography>
        {isEdit ? (
          <Button
            style={{ backgroundColor: color.secondary }}
            onClick={() => setConfirmDialog(true)}
          >
            Delete
          </Button>
        ) : null}
      </Box>

      <Box className={styles.container}>
        <Grid
          container
          style={{ marginBottom: errObj.contactNumErr ? 30 : 20 }}
          alignItems={"center"}
          id="contactNum"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Contact No."} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter the No. of the Contact Person"
              value={contactNum}
              error={errObj.contactNumErr}
              helperText={errObj.contactNumErr ? errObj.contactNumMsg : null}
              onChange={(e) => {
                setcontactNum(e.target.value.replace(/[^0-9]/g, ""));
                setuserId(e.target.value);
                setErrObj({
                  ...errObj,
                  contactNumErr: false,
                  contactNumMsg: "",
                  userIdErr: false,
                  userIdMsg: "",
                });
              }}
              inputProps={{
                onWheel: (event) => event.currentTarget.blur(),
                maxLength: 11,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: errObj.userIdErr ? 30 : 20 }}
          alignItems={"center"}
          id="userId"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"User ID"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter User ID"
              value={userId}
              error={errObj.userIdErr}
              helperText={errObj.userIdErr ? errObj.userIdMsg : null}
              inputProps={{ readOnly: true }}
              onChange={(e) => {
                setuserId(e.target.value);
                setErrObj({
                  ...errObj,
                  userIdErr: false,
                  userIdMsg: "",
                });
              }}
            />
          </Grid>
        </Grid>
        {isEdit ? null : (
          <Grid
            container
            style={{ marginBottom: errObj.userpwdErr ? 30 : 20 }}
            alignItems={"center"}
            id="userpwd"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"User Password"} />
            </Grid>

            <Grid item xs={12} md={10} lg={10}>
              <FormControl fullWidth error={errObj.userpwdErr}>
                <OutlinedInput
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter User Password"
                  value={userpwd}
                  type={showPassword ? "text" : "password"}
                  error={errObj.userpwdErr}
                  helperText={errObj.userpwdErr ? errObj.userpwdMsg : null}
                  onChange={(e) => {
                    setuserpwd(e.target.value);
                    setErrObj({
                      ...errObj,
                      userpwdErr: false,
                      userpwdMsg: "",
                    });
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errObj.userpwdErr ? (
                  <FormHelperText>{errObj.userpwdMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        <Grid
          container
          style={{ marginBottom: errObj.FnameErr ? 30 : 20 }}
          alignItems={"center"}
          id="Fname"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Name"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Name of the User"
              value={Fname}
              error={errObj.FnameErr}
              helperText={errObj.FnameErr ? errObj.FnameMsg : null}
              onChange={(e) => {
                setFname(e.target.value);
                setErrObj({ ...errObj, FnameErr: false, FnameMsg: "" });
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: errObj.userEmlErr ? 30 : 20 }}
          alignItems={"center"}
          id="userEml"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Email ID :</Typography>
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Email ID of the User"
              value={userEml}
              error={errObj.userEmlErr}
              helperText={errObj.userEmlErr ? errObj.userEmlMsg : null}
              onChange={(e) => {
                setuserEml(e.target.value);
                setErrObj({
                  ...errObj,
                  userEmlErr: false,
                  userEmlMsg: "",
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.userStatusErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"User Status"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10} id="secdropDown">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth error={errObj.userStatusErr}>
                <Select
                  id="demo-simple-select"
                  value={secdropDown}
                  onChange={sechandleChange}
                  size={"medium"}
                >
                  <MenuItem value={10}>Active</MenuItem>
                  <MenuItem value={9}>Inactive</MenuItem>
                </Select>
                {errObj.userStatusErr ? (
                  <FormHelperText>{errObj.userStatusMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: errObj.privilegeErr ? 30 : 20 }}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Privilege"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10} id="dropDown">
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth error={errObj.privilegeErr}>
                <Select
                  value={dropDown}
                  onChange={handleChange}
                  style={{ color: dropDown.length === 0 ? "#9ca3af" : "" }}
                  displayEmpty
                  size={"medium"}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select the Type of User
                  </MenuItem>
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem value={"fk_ro"}>FK RO</MenuItem>
                  <MenuItem value={"service_enginner"}>
                    FK Tyre Care Executive
                  </MenuItem>
                  <MenuItem value={"fleet_owner"}>Fleet Owner</MenuItem>
                  <MenuItem value={"fleet_manager"}>Fleet Manager</MenuItem>
                  <MenuItem value={"call_centre"}>Call Center</MenuItem>
                </Select>
                {errObj.privilegeErr ? (
                  <FormHelperText>{errObj.privilegeMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {(dropDown === "admin" ||
          dropDown === "fk_ro" ||
          dropDown === "service_enginner") && (
          <>
            {/* <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography required title={"Employee Code"} />
              </Grid>

              <Grid item xs={12} md={10} lg={10} id="salesEmpcode">
                <OutlinedInput
                  style={{ width: "100%", margin: 0 }}
                  value={salesEmpcode}
                  inputProps={{ readOnly: true }}
                />
              </Grid>
            </Grid> */}
            <Grid
              container
              style={{ marginBottom: errObj.empDesignationErr ? 30 : 20 }}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography required title={"Employee Designation"} />
              </Grid>
              <Grid item xs={12} md={10} lg={10} id="empDesignation">
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter Employee Designation"
                  value={empDesignation}
                  error={errObj.empDesignationErr}
                  helperText={
                    errObj.empDesignationErr ? errObj.empDesignationMsg : null
                  }
                  onChange={(e) => {
                    setempDesignation(e.target.value);
                    setErrObj({
                      ...errObj,
                      empDesignationErr: false,
                      empDesignationMsg: "",
                    });
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        {dropDown === "call_centre" && (
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.companyNameErr ? 30 : 20 }}
            id="companyName"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Company Name"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter the Company Name"
                value={companyName}
                onChange={(e) => {
                  setCompanyName(e.target.value);
                  setErrObj({
                    ...errObj,
                    companyNameErr: false,
                    companyNameMsg: "",
                  });
                }}
                error={errObj.companyNameErr}
                helperText={
                  errObj.companyNameErr ? errObj.companyNameMsg : null
                }
              ></TextField>
            </Grid>
          </Grid>
        )}
        {(dropDown === "fk_ro" || dropDown === "call_centre") && (
          <>
            <Grid
              container
              style={{ marginBottom: errObj.officeRegionErr ? 30 : 20 }}
              alignItems={"center"}
              id="thirddropDown"
            >
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography required title={"Regional Office"} />
              </Grid>
              <Grid item xs={12} md={10} lg={10}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errObj.officeRegionErr}>
                    <Select
                      id="demo-simple-select"
                      value={thirddropDown}
                      onChange={thirdhandleChange}
                      size={"medium"}
                      displayEmpty
                      style={{
                        color: thirddropDown.length === 0 ? "#9ca3af" : "",
                      }}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select Region
                      </MenuItem>
                      {dropDownData?.office_region.map((item) => {
                        return (
                          <MenuItem value={item?.id}>{item?.label}</MenuItem>
                        );
                      })}
                    </Select>
                    {errObj.officeRegionErr ? (
                      <FormHelperText>{errObj.officeRegionMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ marginBottom: errObj.AddressErr ? 30 : 20 }}
              alignItems={"center"}
              id="Address"
            >
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography required title={"Address"} />
              </Grid>
              <Grid item xs={12} md={10} lg={10}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter the Address"
                  rows={4}
                  multiline
                  value={Address}
                  error={errObj.AddressErr}
                  helperText={errObj.AddressErr ? errObj.AddressMsg : null}
                  onChange={(e) => {
                    setAddress(e.target.value);
                    setErrObj({
                      ...errObj,
                      AddressErr: false,
                      AddressMsg: "",
                    });
                  }}
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{ marginBottom: errObj.officecontactErr ? 30 : 20 }}
              alignItems={"center"}
              id="officecontact"
            >
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography required title={"Office Contact No."} />
              </Grid>
              <Grid item xs={12} md={10} lg={10}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter the Office Contact No."
                  value={officecontact}
                  error={errObj.officecontactErr}
                  helperText={
                    errObj.officecontactErr ? errObj.officecontactMsg : null
                  }
                  onChange={(e) => {
                    setofficecontact(e.target.value.replace(/[^0-9]/g, ""));
                    setErrObj({
                      ...errObj,
                      officecontactErr: false,
                      officecontactMsg: "",
                    });
                  }}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                    maxLength: 11,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </>
        )}
        {dropDown === "service_enginner" && (
          <Grid
            container
            style={{ marginBottom: errObj.officeRegionErr ? 30 : 20 }}
            alignItems={"center"}
            id="thirddropDown"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Regional Office"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth error={errObj.officeRegionErr}>
                  <Select
                    id="demo-simple-select"
                    value={thirddropDown}
                    onChange={thirdhandleChange}
                    size={"medium"}
                    displayEmpty
                    style={{
                      color: thirddropDown.length === 0 ? "#9ca3af" : "",
                    }}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Select Region
                    </MenuItem>
                    {dropDownData?.office_region.map((item) => {
                      return (
                        <MenuItem value={item?.id}>{item?.label}</MenuItem>
                      );
                    })}
                  </Select>
                  {errObj.officeRegionErr ? (
                    <FormHelperText>{errObj.officeRegionMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
        )}
        {dropDown === "fk_ro" && (
          <Grid
            container
            style={{ marginBottom: errObj.serviceEngineerErr ? 30 : 20 }}
            alignItems={"center"}
            id="serviceEngineer"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"FK Tyre Care Executive"} />
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <FormControl
                sx={{
                  width: "100%",
                  border: errObj.serviceEngineerErr
                    ? "1px solid #ff1744"
                    : null,
                  borderRadius: 1,
                }}
                error={errObj.serviceEngineerErr}
              >
                <Autocomplete
                  fullWidth
                  multiple
                  disableListWrap={true}
                  options={seviceListArr}
                  // filterSelectedOptions
                  value={serviceEngineer}
                  classes={{ input: `${styles.autocomplete}` }}
                  onChange={(v1, v) => {
                    setServiceEngineer(v);
                    setErrObj({
                      ...errObj,
                      serviceEngineerErr: false,
                      serviceEngineerMsg: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.inputDivStyle}
                      placeholder={
                        isEmpty(serviceEngineer)
                          ? "Search Service FK Tyre Care Executive"
                          : null
                      }
                    />
                  )}
                  renderTags={(tagValue, getTagProps) => {
                    const renderTagsValue = tagValue
                      .map(function (elem) {
                        return elem.label;
                      })
                      .join(", ");
                    return (
                      // <Typography
                      //   // style={{ maxWidth: 360 }}
                      //   noWrap={true}
                      //   color="textPrimary"
                      // >
                      renderTagsValue
                      // </Typography>
                    );
                  }}
                />
                {errObj.serviceEngineerErr ? (
                  <FormHelperText>{errObj.serviceEngineerMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        {(dropDown === "fleet_owner" || dropDown === "fleet_manager") && (
          <Grid
            container
            style={{ marginBottom: errObj.singleFleetErr ? 30 : 20 }}
            alignItems={"center"}
            id="singleFleet"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Fleets Allocated"} />
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <FormControl sx={{ width: "100%" }} error={errObj.singleFleetErr}>
                <Autocomplete
                  fullWidth
                  multiple
                  disableListWrap={true}
                  options={fleetData}
                  // filterSelectedOptions
                  value={singleFleet}
                  onChange={(v1, v) => {
                    setSinglefleet(v);
                    setErrObj({
                      ...errObj,
                      singleFleetErr: false,
                      singleFleetMsg: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.inputDivStyle}
                      placeholder={
                        isEmpty(singleFleet)
                          ? "Search/Select Fleet Allocated"
                          : null
                      }
                    />
                  )}
                  renderTags={(tagValue, getTagProps) => {
                    const renderTagsValue = tagValue
                      .map(function (elem) {
                        return elem.label;
                      })
                      .join(", ");
                    return (
                      // <Typography
                      //   // style={{ maxWidth: 360 }}
                      //   noWrap={true}
                      //   color="textPrimary"
                      // >
                      renderTagsValue
                      // </Typography>
                    );
                  }}
                />
                {errObj.singleFleetErr ? (
                  <FormHelperText>{errObj.singleFleetMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}
        {(dropDown === "admin" ||
          dropDown === "fk_ro" ||
          dropDown === "call_centre" ||
          dropDown === "service_enginner") && (
          <Grid
            container
            alignItems={"center"}
            style={{
              marginBottom: errObj.selectedFileErr ? 30 : 20,
            }}
            id="file"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Typography style={{ marginRight: 20 }}>
                Upload User ID :
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              lg={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                style={{
                  color:
                    !selectedFile || selectedFile === "" ? "#999" : color.black,
                }}
              >
                {file}
              </Typography>
              <Button
                variant="contained"
                component="label"
                style={{
                  backgroundColor: color.secondary,
                  margin: "0 20px",
                }}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0].name);
                    setSlectedFile(e.target.files[0]);
                    setErrObj({
                      ...errObj,
                      selectedFileErr: false,
                      selectedFileMsg: "",
                    });
                  }}
                  accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg"
                  hidden
                />
                Upload File
              </Button>
              {errObj.selectedFileErr ? (
                <Typography style={{ color: "red", fontSize: 12 }}>
                  {errObj.selectedFileMsg}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        )}
        {dropDown === "fleet_owner" && (
          <Grid
            container
            alignItems={"center"}
            style={{
              marginBottom: errObj.secselectedFileErr ? 30 : 20,
            }}
            id="secselectedFile"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <Typography style={{ marginRight: 20 }}>
                Upload Business PAN Card :
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              md={10}
              lg={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                style={{
                  color:
                    !secselectedFile || secselectedFile === ""
                      ? "#999"
                      : color.black,
                }}
              >
                {secfile}
              </Typography>

              <Button
                variant="contained"
                component="label"
                style={{
                  backgroundColor: color.secondary,
                  margin: "0 20px",
                }}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    setsecFile(e.target.files[0].name);
                    setsecSlectedFile(e.target.files[0]);
                    setErrObj({
                      ...errObj,
                      secselectedFileErr: false,
                      secselectedFileMsg: "",
                    });
                  }}
                  accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg, application/pdf"
                  hidden
                />
                Upload File
              </Button>
              {errObj.secselectedFileErr ? (
                <Typography style={{ color: "red", fontSize: 12 }}>
                  {errObj.secselectedFileMsg}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        )}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginBottom: 20 }}
        >
          <Button
            onClick={checkValidation}
            style={{
              backgroundColor: isOnline ? color.primary : color.bordercolor,
              marginRight: 10,
              width: 120,
            }}
            disabled={btnLoad || isOnline ? false : true}
          >
            {btnLoad ? (
              <CircularProgress style={{ color: "#fff" }} size={28} />
            ) : isEdit ? (
              "Save & Update"
            ) : (
              "Add User"
            )}
          </Button>
          <Button
            style={{
              backgroundColor: color.secondary,
              width: 120,
            }}
            onClick={() => {
              handleClick("cancel", "");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
      <ConfirmDialog
        title={`Are you sure you want to ${
          isEditDialog ? "Edit" : "Delete"
        } this User?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            if (isEditDialog) {
              submitData();
            } else {
              deleteUser();
            }
          } else {
            setConfirmDialog(false);
            setIsEditDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </Box>
  );
}
