import { makeStyles } from "@mui/styles";
import { isBrowser, isMobile, isTablet } from "react-device-detect";
import { color } from "../../../Config/theme";
const useStyles = makeStyles((theme) => ({
  input: {
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    padding: isBrowser || isTablet ? 20 : 10,
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    overflowY: "scroll",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    "@media (max-width:425px)": {
      padding: 10,
    },
    "&::-webkit-scrollbar": {
      display: "none !important",
    },
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
  customtableMobile: {
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: " #FBF8F8",
      },
    },
  },
}));
export default useStyles;
