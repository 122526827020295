import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { initializeApp } from "firebase/app";
import { useDispatch, useSelector } from "react-redux";
import "./App.css";
import "./globleStyle.scss";
import authActions from "./Redux/reducers/auth/actions";
import useOnlineStatus from "@rehooks/online-status";

import LoginStack from "./Navigation/loginStack";
import Drawer from "./Navigation/DrawerMenu";
import _, { isEmpty, isObject } from "lodash";
import {
  askForPermissionToReceiveNotifications,
  onMessageListener,
} from "./push-notification";
import { getApiData } from "./Utils/APIHelper";
import { Setting } from "./Utils/Setting";
import { toast, ToastContainer } from "react-toastify";

const firebaseConfig = {
  apiKey: "AIzaSyBwPD0xSlpzaZiaDeuxXtNRtYjlq_FmyPo",
  authDomain: "fleet-kaptan-486b0.firebaseapp.com",
  projectId: "fleet-kaptan-486b0",
  storageBucket: "fleet-kaptan-486b0.appspot.com",
  messagingSenderId: "708534617172",
  appId: "1:708534617172:web:a8062f474cdf4744a959c1",
  measurementId: "G-9FV1273B0N",
};

function App() {
  const { userdata, token, isOnline } = useSelector((state) => state.auth);
  const [isLogin, setIsLogin] = useState(true);
  const [userType, setUserType] = useState("");
  // const [net, setNet] = useState(true);
  const checkOl = window.navigator.onLine;
  const dispatch = useDispatch();
  const { setPermissionData, setOnlineStatus, setDropDownData, setFleetData } =
    authActions;

  const onlineStatus = useOnlineStatus();
  initializeApp(firebaseConfig);

  useEffect(() => {
    askForPermissionToReceiveNotifications();
    onMessageListener();
  }, []);

  useEffect(() => {
    window.history.replaceState({}, document.title);
  }, []);

  useEffect(() => {
    toast.clearWaitingQueue();
    dispatch(setOnlineStatus(onlineStatus));
  }, [onlineStatus]);

  // useEffect(() => {
  //   toast.clearWaitingQueue();
  //   dispatch(setOnlineStatus(checkOl));
  // }, [checkOl]);

  useEffect(() => {
    if (_.isObject(userdata) && !_.isEmpty(userdata)) {
      setIsLogin(true);
      setUserType(userdata?.role);
    } else {
      setIsLogin(false);
      setUserType("");
    }
  }, [userdata]);

  useEffect(() => {
    if (isLogin && token !== "") {
      getDropDownData();
      getFleetData();
    }
  }, [isLogin, token]);

  useEffect(() => {
    if (isLogin && token !== "") {
      rolePermission();
    }
    const interval = setInterval(() => {
      if (isLogin && token !== "") {
        rolePermission();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [isLogin, token]);

  const rolePermission = async () => {
    try {
      const response = await getApiData(
        Setting.endpoints.systemcommon,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response?.data)) {
          dispatch(setPermissionData(response?.data));
        }
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  async function getDropDownData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.dropDown}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          dispatch(setDropDownData(response?.data));
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  async function getFleetData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.FleetData}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          dispatch(setFleetData(response?.data));
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  return (
    <Router>
      {isOnline && (
        <ToastContainer
          autoClose={3000}
          pauseOnFocusLoss={false}
          toastClassName="toastStyle"
          // hideProgressBar
        />
      )}
      {isLogin ? <Drawer /> : <LoginStack />}
    </Router>
  );
}

export default App;
