import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting.js";
import Box from "@mui/material/Box";
import {
  FormControl,
  FormHelperText,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles.js";
import {
  cloneDeep,
  isArray,
  isEmpty,
  isNull,
  isObject,
  isUndefined,
} from "lodash";
import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm/index";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { isMobile, isTablet } from "react-device-detect";
import ConfirmDialog from "../../ConfirmDialog/index.js";
import { tyre_condition } from "../../../Config/Static_Data.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import SwapTyre from "../../TyreInventory/SwapTyre/index.js";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

const errorObj = {
  TyreSerErr: false,
  TyrePosErr: false,
  tyreConditionErr: false,
  OuterNSDErr: false,
  MiddleNSDErr: false,
  InnerNSDErr: false,
  DisKmErr: false,
  RemovalTypeErr: false,
  dateErr: false,
  categoryErr: false,
  issueErr: false,
  dateMsg: "",
  TyreSerMsg: "",
  tyreConditionMsg: "",
  TyrePosMsg: "",
  OuterNSDMsg: "",
  MiddleNSDMsg: "",
  InnerNSDMsg: "",
  DisKmMsg: "",
  RemovalTypeMsg: "",
  categoryMsg: "",
  issueMsg: "",
};

export default function RemoveTyre(props) {
  const {
    handleClick = () => null,
    vehicleDetails,
    fitmentReading,
    tyreData,
    captureDate,
    vehicleId,
    vehicleList,
    from,
  } = props;
  const isEdit = from === "life";
  const styles = useStyles();

  const { dropDownData, token } = useSelector((state) => state.auth);

  const currency_Regex = Setting.JS_Regex.currency_Regex;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [errObj, setErrObj] = useState(errorObj);

  const [loader, setLoader] = useState(false);
  const [serialNo, setserialNo] = useState("");
  const [tyrePosition, settyrePosition] = useState("");
  const [tyreCondition, settyreCondition] = useState("");
  const [avgNSD, setAvgNSD] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [formValues, setFormValues] = useState([
    {
      groove1: "",
      groove2: "",
      groove3: "",
      groove4: "",
      id: Math.floor(Math.random() * 100 + 1),
    },
  ]);
  const [dismountKm, setDismountKm] = useState("");
  const [removalType, setRemovalType] = useState({});
  const [openCard, setOpenCard] = useState(false);
  const [openCard1, setOpenCard1] = useState(false);
  const [category, setCategory] = useState("");
  const dummytyreArr = cloneDeep(dropDownData?.tyre_removal_issue);
  const dummytyreArr1 = cloneDeep(dropDownData?.tyre_issue_scrap_analysis);
  const [tyreRemovalArr, setTyreRemovalArr] = useState(dummytyreArr);
  const [issueArry, setissueArry] = useState(dummytyreArr1);

  const [modalData, setModalData] = useState({});

  const [tyreSelection, setTyreSelection] = useState([]);
  const [tyrePositionArr, setTyrePositionArr] = useState([]);
  const [datePicker, setDatePicker] = useState(new Date());

  const [tyreDetails, setTyreDetails] = useState({});

  const [renderTagsValue, setrenderTagsValue] = useState([]);
  const [issueType, setissueType] = useState([]);
  const [vehicleListM, setVehicleListM] = useState([]);

  const [visible, setVisible] = useState(false);

  const [selectedFile, setSlectedFile] = useState([]);
  const [displaySelectedFile, setDisplaySlectedFile] = useState([]);
  const [modal, openModal] = useState(false);
  const [showImg, setShowImg] = useState("");
  const [remarks, setRemarks] = useState("");

  useEffect(() => {
    if (from === "tyreInventory") {
      setTyreSelection([{ id: tyreData?.tyre_serial_no }]);
      setserialNo(tyreData?.tyre_serial_no);
    } else if (from === "addInspection") {
      setTyreSelection([{ id: tyreData?.tyre_serial_no }]);
      setserialNo(tyreData?.tyre_serial_no);
      setDatePicker(captureDate);
    } else if (isEdit) {
      const findInd = tyreRemovalArr?.findIndex(
        (v) => v?.id === vehicleDetails?.removal_type_id
      );
      SelectTyre(findInd);
      handleSelect();
      getTyres();
      setserialNo(vehicleDetails?.tyre_serial_no);
      settyreCondition(vehicleDetails?.tyre_condition);
      setTyrePositionArr([{ id: vehicleDetails?.tyre_position }]);
      settyrePosition(vehicleDetails?.tyre_position);
      setFormValues(
        vehicleDetails?.current_nsd || [
          {
            groove1: "",
            groove2: "",
            groove3: "",
            groove4: "",
            id: Math.floor(Math.random() * 100 + 1),
          },
        ]
      );
      setDismountKm(vehicleDetails?.dismount_km);
      setDatePicker(vehicleDetails?.removed_at);
      setRemarks();
    } else {
      getTyres();
      getTyresPositions();
    }
    getVehicleist();
  }, []);

  useEffect(() => {
    !isEmpty(serialNo) && getTyresDetails();
  }, [serialNo]);

  useEffect(() => {
    isArray(formValues) && !isEmpty(formValues) && findAvgNSD();
  }, [formValues]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_meR");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);

  useEffect(() => {
    if (isObject(tyreDetails) && !isEmpty(tyreDetails)) {
      settyreCondition(tyreDetails?.tyre_condition);
      setTyrePositionArr([{ id: tyreDetails?.tyre_position }]);
      settyrePosition(tyreDetails?.tyre_position);
      setErrObj({
        ...errObj,
        tyreConditionErr: false,
        tyreConditionMsg: "",
      });
    } else {
      settyreCondition("");
      setTyrePositionArr([]);
      settyrePosition("");
    }
  }, [tyreDetails]);

  const categoryListArray = [
    {
      label: "On Wheel",
    },
    {
      label: "Scrap",
    },
    {
      label: "Stock",
    },
    // {
    //   label: "Claim",
    // },
    // {
    //   label: "Retreading",
    // },
    // {
    //   label: "Casing",
    // },
  ];

  async function getVehicleist() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicleData}?fleet_id=${vehicleDetails?.fleet_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setVehicleListM(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function findAvgNSD() {
    let groove = 0;
    let divide = 0;
    formValues.map((v, index) => {
      if (!isEmpty(v?.groove1)) {
        groove = Number(v?.groove1) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove2)) {
        groove = Number(v?.groove2) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove3)) {
        groove = Number(v?.groove3) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove4)) {
        groove = Number(v?.groove4) + groove;
        divide++;
      }
    });

    const mainAVG = groove / divide;
    setAvgNSD(isNaN(mainAVG) ? "" : mainAVG.toFixed(2));
  }

  let handleChange = (i, e, key) => {
    let newFormValues = [...formValues];
    newFormValues.map((v, index) => {
      if (i === index) {
        if (key === 1) {
          v.groove1 = e;
        } else if (key === 2) {
          v.groove2 = e;
        } else if (key === 3) {
          v.groove3 = e;
        } else if (key === 4) {
          v.groove4 = e;
        }
      }
    });
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    if (formValues.length < 4) {
      setFormValues([
        ...formValues,
        {
          groove1: "",
          groove2: "",
          groove3: "",
          groove4: "",
          id: Math.floor(Math.random() * 100 + 1),
        },
      ]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const getTyres = async () => {
    try {
      const response = await getApiData(
        isEdit
          ? `${Setting.endpoints.tyreserialno}?vehicle_id=${vehicleDetails.vehicle_id}&tyre_serial_no=${vehicleDetails?.tyre_serial_no}`
          : `${Setting.endpoints.tyreserialno}?vehicle_id=${vehicleDetails.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setTyreSelection(response.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const getTyresPositions = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreposition}?vehicle_id=${vehicleDetails.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setTyrePositionArr(response.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const getTyresDetails = async () => {
    try {
      const response = await getApiData(
        isEdit && serialNo === vehicleDetails?.tyre_serial_no
          ? `${Setting.endpoints.tyreDetails}?tyre_id=${serialNo?.replaceAll(
              " ",
              "%20"
            )}&vehicle_id=${vehicleDetails?.vehicle_id}`
          : `${Setting.endpoints.tyreDetails}?tyre_id=${serialNo?.replaceAll(
              " ",
              "%20"
            )}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isObject(response.data) && !isEmpty(response.data)) {
          setTyreDetails(response?.data);
        } else {
          setTyreDetails({});
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const submitData = async () => {
    setLoader(true);
    const captureDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";

    let endPoints = Setting.endpoints.removeTyre;
    const params = {
      "RemoveTyre[vehicle_id]":
        from === "addInspection"
          ? vehicleId
          : from === "tyreInventory"
          ? tyreData?.vehicle_id
          : isEdit
          ? vehicleDetails?.vehicle_id
          : vehicleDetails?.id,
      "RemoveTyre[tyre_serial_no]": serialNo,
      "RemoveTyre[tyre_position]": tyrePosition,
      "RemoveTyre[tyre_condition]": tyreCondition,
      "RemoveTyre[current_nsd]": JSON.stringify(formValues),
      "RemoveTyre[avg_nsd]": avgNSD,
      "RemoveTyre[dismount_km]": dismountKm,
      "RemoveTyre[removal_type_id]": removalType?.id,
      "RemoveTyre[removed_at]": captureDate,
    };
    if (isEdit) {
      params["RemoveTyre[id]"] = vehicleDetails?.id;
    }
    if (from === "addInspection" || isUndefined(from)) {
      params["RemoveTyre[is_inspection]"] = 1;
      params["RemoveTyre[category]"] = category;
    }
    if (category === "Scrap") {
      params["RemoveTyre[scrap_issue_id]"] = issueType;
      params["RemoveTyre[remark]"] = remarks;
    } else if (category === "On Wheel") {
      params["RemoveTyre[swap_vehicle_id]"] = modalData?.vehicle?.id;
      params["RemoveTyre[swap_tyre_id]"] = modalData?.TSN;
      params["RemoveTyre[is_swap]"] = modalData?.SwapTyre;
      params["RemoveTyre[swap_nsd]"] = JSON.stringify(modalData?.DismountNSD);
      params["RemoveTyre[swap_avg_nsd]"] = modalData?.avgNSD;
      params["RemoveTyre[swap_vehicle_km]"] = modalData?.dismountKm;
      params["RemoveTyre[swap_removal_type_id]"] =
        modalData?.removalType?.id || "";
    }
    if (isArray(displaySelectedFile) && !isEmpty(displaySelectedFile)) {
      displaySelectedFile.map((item, index) => {
        params[`RemoveTyre[removal_images][${index}]`] = item;
      });
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        from === "addInspection" || from === "tyreInventory"
          ? handleClick("success", category)
          : handleClick("cancel");
        toast.success(resp?.message);
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
      setRemoveBtnLoad(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setRemoveBtnLoad(false);
      setLoader(false);
      toast.error(error.message.toString());
    }
  };

  function Validation() {
    const error = { ...errObj };
    let valid = true;
    let nsdErr = false;
    let SeqErr = false;
    let section = null;
    let scroll = false;

    if (isEmpty(serialNo)) {
      valid = false;
      error.TyreSerErr = true;
      error.TyreSerMsg = "Please select Tyre";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#selectTyre");
      }
    }
    if (!isEmpty(serialNo) && isEmpty(tyrePosition)) {
      valid = false;
      error.TyrePosErr = true;
      error.TyrePosMsg = "Please select Tyre Position";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#tyrePosition");
      }
    }

    if (isEmpty(tyreCondition)) {
      valid = false;
      error.tyreConditionErr = true;
      error.tyreConditionMsg = "Please select Tyre Condition";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#tyreCondition");
      }
    }

    let c = dismountKm?.replaceAll(",", "");
    let d =
      from === "tyreInventory"
        ? tyreData?.last_inspection_reading
        : fitmentReading?.replaceAll(",", "");
    if (isEmpty(dismountKm.trim())) {
      valid = false;
      error.DisKmErr = true;
      error.DisKmMsg =
        "The Dismount Reading should be greater than or equal to Last Inspection Reading";
    } else if (!isEmpty(dismountKm) && !currency_Regex.test(c)) {
      valid = false;
      error.DisKmErr = true;
      error.DisKmMsg = "Please enter valid Km";
    } else if (!isEmpty(dismountKm) && Number(c) - Number(d) < 0) {
      valid = false;
      error.DisKmErr = true;
      error.DisKmMsg =
        "The Dismount Reading should greater or equals to Last Inspection Reading";
    }

    if (isEmpty(removalType)) {
      valid = false;
      error.RemovalTypeErr = true;
      error.RemovalTypeMsg = "Please select Removal Type";
    }

    let foundAddedDateInRange = moment(datePicker).isBetween(
      "2000-01-01",
      new Date()
    );

    if (from === "addInspection" && isEmpty(category)) {
      valid = false;
      error.categoryErr = true;
      error.categoryMsg = "Please Select Category";
    }

    if (category === "Scrap" && isEmpty(issueType.toString())) {
      valid = false;
      error.issueErr = true;
      error.issueMsg = "Please select Tyre Issue";
    }

    if (isNull(datePicker)) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please select Dismount Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    } else if (
      !isNull(datePicker) &&
      (datePicker === "Invalid Date" || !foundAddedDateInRange)
    ) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please enter valid Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    }
    formValues.map((v, index) => {
      if (!isEmpty(v?.groove1) && Math.ceil(v?.groove1) === 0) {
        valid = false;
        nsdErr = true;
      }
      if (!isEmpty(v?.groove2)) {
        if (Math.ceil(v?.groove2) === 0) {
          valid = false;
          nsdErr = true;
        } else if (isEmpty(v?.groove1)) {
          valid = false;
          SeqErr = true;
        }
      }
      if (!isEmpty(v?.groove3)) {
        if (Math.ceil(v?.groove3) === 0) {
          valid = false;
          nsdErr = true;
        } else if (isEmpty(v?.groove1) || isEmpty(v?.groove2)) {
          valid = false;
          SeqErr = true;
        }
      }
      if (!isEmpty(v?.groove4)) {
        if (Math.ceil(v?.groove4) === 0) {
          valid = false;
          nsdErr = true;
        } else if (
          isEmpty(v?.groove1) ||
          isEmpty(v?.groove2) ||
          isEmpty(v?.groove3)
        ) {
          valid = false;
          SeqErr = true;
        }
      }
    });

    if (nsdErr) {
      toast.error("NSD value can't be 0", { toastId: 1 });
    }
    if (SeqErr) {
      toast.error("Please fill NSD value sequentially", { toastId: 2 });
    }
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setErrObj(error);
    if (valid) {
      setConfirmDialog(true);
    }
  }

  function clearData() {
    setErrObj(errorObj);
    setRemovalType("");
    settyrePosition("");
    setDismountKm("");
    setSlectedFile([]);
    setDisplaySlectedFile([]);
  }

  function SelectTyre(index, id, type) {
    setTyreRemovalArr(dummytyreArr);
    const DummyArr = [...dummytyreArr];

    DummyArr.map((item, i) => {
      if (type === "swap" && item?.id === id?.id) {
        item.selected = true;
      } else if (i === index) {
        item.selected = true;
      }
    });
    setTyreRemovalArr(DummyArr);
  }

  function selectIssue(index) {
    const DummyArr = [...issueArry];

    DummyArr.map((item, i) => {
      if (i === index) {
        item.selected = !item.selected;
      }
    });
    setissueArry(DummyArr);
  }

  function handleSelect() {
    const DummyArr = [...tyreRemovalArr];
    const selected = DummyArr?.find((v) => v.selected === true);
    if (!isEmpty(selected)) {
      setRemovalType(selected);
    }
    setErrObj({ ...errObj, RemovalTypeErr: false, RemovalTypeMsg: "" });
    setOpenCard(false);
  }

  function handleTyreIssue() {
    setErrObj({ ...errObj, issueErr: false, issueMsg: "" });
    setOpenCard1(false);
    const DummyArr = [...issueArry];
    const selected = DummyArr?.filter((v) => v.selected === true);
    const renderTagsValue =
      isArray(selected) && !isEmpty(selected)
        ? selected
            .map(function (elem) {
              return elem.label;
            })
            .join(", ")
        : "";
    const array = [];
    const array2 = [];
    if (!isEmpty(selected) && isArray(selected)) {
      selected.map((v) => {
        array.push(v.id);
        array2.push(v.label);
      });
      setissueType(array);
      setrenderTagsValue(renderTagsValue);
    } else {
      setrenderTagsValue("");
      setissueType([]);
    }
    setOpenCard1(false);
  }

  function numberWithCommas(x) {
    let c = x.replaceAll(",", "");

    return c.toString().split(".")[0].length > 3
      ? c
          .toString()
          .substring(0, c.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c.toString().substring(c.toString().split(".")[0].length - 3)
      : c.toString();
  }

  function converter(value, type) {
    let convert = numberWithCommas(value);
    if (type === "last") {
      return convert;
    } else {
      setDismountKm(convert);
      setErrObj({
        ...errObj,
        DisKmErr: false,
        DisKmMsg: "",
      });
    }
  }

  function uploadImg(file) {
    let dummy_Arr = [...selectedFile];
    let dummy_Arr1 = [...displaySelectedFile];
    if (file.length > 5 || file.length + selectedFile.length > 5) {
      toast.error("Cannot upload files more than 5");
    } else {
      Object.keys(file).map((item) => {
        dummy_Arr.push(URL.createObjectURL(file[item]));
        dummy_Arr1.push(file[item]);
      });
    }
    setSlectedFile(dummy_Arr);
    setDisplaySlectedFile(dummy_Arr1);
  }

  function removeImage(i) {
    let dummy_Arr = [...selectedFile];
    let dummy_Arr1 = [...displaySelectedFile];

    dummy_Arr.splice(i, 1);
    dummy_Arr1.splice(i, 1);

    setSlectedFile(dummy_Arr);
    setDisplaySlectedFile(dummy_Arr1);
  }

  return (
    <Box>
      <Box
        id="jump_to_meR"
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>Remove Tyre</Typography>
      </Box>
      <Box className={styles.container}>
        <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={4} sm={3} md={2} lg={2}>
            <Typography>Vehicle No :</Typography>
          </Grid>

          <Grid item xs={8} sm={9} md={10} lg={10}>
            <Typography>
              {from === "tyreInventory"
                ? tyreData?.vehicle_no
                : from === "addInspection"
                ? tyreDetails?.vehicle_no
                : vehicleDetails?.vehicle_no}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.TyreSerErr ? 30 : 20 }}
          alignItems={"center"}
          id="selectTyre"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Select Tyre"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <FormControl fullWidth error={errObj.TyreSerErr}>
              <Select
                displayEmpty
                value={serialNo}
                disabled={from === "addInspection"}
                onChange={(event) => {
                  setserialNo(event.target.value);
                  navigator.clipboard.writeText(event.target.value);
                  // toast.success("Copied!");
                  setErrObj({
                    ...errObj,
                    TyreSerErr: false,
                    TyreSerMsg: "",
                    TyrePosErr: false,
                    TyrePosMsg: "",
                  });
                }}
                size={"medium"}
                style={serialNo === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value={""} disabled hidden selected>
                  Select Tyre
                </MenuItem>
                {isArray(tyreSelection) &&
                  !isEmpty(tyreSelection) &&
                  tyreSelection.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      {item?.id}
                    </MenuItem>
                  ))}
              </Select>
              {errObj.TyreSerErr ? (
                <FormHelperText>
                  {errObj.TyreSerMsg}
                  {}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.tyreConditionErr ? 30 : 20 }}
          alignItems={"center"}
          id="tyreCondition"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tyre Condition"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth error={errObj.tyreConditionErr}>
                <Select
                  displayEmpty
                  disabled={isEdit && vehicleDetails?.tyre_condition}
                  value={tyreCondition}
                  onChange={(event) => {
                    settyreCondition(event.target.value);
                    setErrObj({
                      ...errObj,
                      tyreConditionErr: false,
                      tyreConditionMsg: "",
                    });
                  }}
                  size={"medium"}
                  style={tyreCondition === "" ? { color: "#A2A2A2" } : {}}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select the Tyre Condition
                  </MenuItem>
                  {tyre_condition.map((item, index) => (
                    <MenuItem key={index} value={item?.label}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                {errObj.tyreConditionErr ? (
                  <FormHelperText>
                    {errObj.tyreConditionMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 20 }}
          alignItems={"center"}
          id="tyrePosition"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tyre Position"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <FormControl fullWidth error={errObj.TyrePosErr}>
              <Select
                displayEmpty
                value={tyrePosition}
                disabled
                onChange={(event) => {
                  // settyrePosition(event.target.value);
                  // setErrObj({
                  //   ...errObj,
                  //   TyrePosErr: false,
                  //   TyrePosMsg: "",
                  // });
                }}
                size={"medium"}
                style={tyrePosition === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value={""} disabled hidden selected>
                  Tyre Position
                </MenuItem>
                {tyrePositionArr.map((item, index) => (
                  <MenuItem key={index} value={item?.id}>
                    {item?.id}
                  </MenuItem>
                ))}
              </Select>
              {errObj.TyrePosErr ? (
                <FormHelperText>{errObj.TyrePosMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        {formValues.map((v, index) => {
          const isLast = index === formValues.length - 1;
          return (
            <Grid
              key={`new_${v?.id}`}
              container
              style={{ marginBottom: 20 }}
              alignItems={"center"}
              id={`nsdErr_${index}`}
            >
              <Grid item xs={12} md={2}>
                <Typography>{index === 0 ? "Dismount NSD : " : ""}</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={10}
                style={{
                  display: !md ? "flex" : "unset",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={2.33}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Groove 1"
                    value={v.groove1}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value, 1);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.33} style={{ marginTop: md ? 20 : 0 }}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Groove 2"
                    value={v.groove2}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value, 2);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.33} style={{ marginTop: md ? 20 : 0 }}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Groove 3"
                    value={v.groove3}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value, 3);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.33} style={{ marginTop: md ? 20 : 0 }}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Groove 4"
                    value={v.groove4}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value, 4);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2} style={{ marginTop: md ? 20 : 0 }}>
                  <Button
                    style={{
                      backgroundColor: !isLast
                        ? color.secondary
                        : formValues.length === 4 && isLast
                        ? "rgba(0, 0, 0, 0.12)"
                        : color.primary,
                      width: "100%",
                      boxShadow:
                        formValues.length === 4 && isLast ? "unset" : "",
                    }}
                    disabled={formValues.length === 4 && isLast}
                    onClick={() =>
                      !isLast ? removeFormFields(index) : addFormFields()
                    }
                  >
                    {!isLast ? "Remove" : "Add More"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        <Grid
          container
          style={{ marginBottom: errObj.AvgNSDErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography title={"Avg. Dismount NSD"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              value={avgNSD}
              placeholder="Enter the Avg.Dismount NSD"
              inputProps={{ readOnly: true }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 20 }}>
          <Grid item xs={isTablet ? 5 : 6} sm={3} md={2} lg={2}>
            <Typography>Last Inspection Reading :</Typography>
          </Grid>

          <Grid item xs={isTablet ? 7 : 6} sm={9} md={10} lg={10}>
            <Typography>
              {from === "tyreInventory"
                ? converter(tyreData?.last_inspection_reading, "last")
                : isEdit
                ? vehicleDetails?.fitment_reading
                : fitmentReading || ""}
              {(isEdit && vehicleDetails?.fitment_reading) ||
              fitmentReading ||
              (from === "tyreInventory" && tyreData?.last_inspection_reading)
                ? " Km"
                : ""}
            </Typography>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.DisKmErr ? 10 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Dismount Reading"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              value={dismountKm}
              placeholder="Enter Dismount Reading in Km"
              onChange={(e) => {
                if (
                  e?.nativeEvent?.data === "0" ||
                  e?.nativeEvent?.data === "1" ||
                  e?.nativeEvent?.data === "2" ||
                  e?.nativeEvent?.data === "3" ||
                  e?.nativeEvent?.data === "4" ||
                  e?.nativeEvent?.data === "5" ||
                  e?.nativeEvent?.data === "6" ||
                  e?.nativeEvent?.data === "7" ||
                  e?.nativeEvent?.data === "8" ||
                  e?.nativeEvent?.data === "9" ||
                  e?.nativeEvent?.data === "." ||
                  isNull(e?.nativeEvent?.data)
                ) {
                  // converter(e.target.value, "");
                  setDismountKm(e.target.value);
                  setErrObj({
                    ...errObj,
                    DisKmErr: false,
                    DisKmMsg: "",
                  });
                }
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">Km</InputAdornment>
                ),
              }}
              error={errObj.DisKmErr}
              helperText={errObj.DisKmErr ? errObj.DisKmMsg : null}
              FormHelperTextProps={{
                style: {
                  position: "unset",
                },
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.RemovalTypeErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Removal Type"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <FormControl fullWidth error={errObj.RemovalTypeErr}>
              <OutlinedInput
                style={{ width: "100%", margin: 0, cursor: "pointer" }}
                placeholder="Select the Removal Type"
                inputProps={{
                  readOnly: true,
                  style: { cursor: "pointer" },
                }}
                value={removalType.label}
                onClick={() => {
                  if (isArray(tyreRemovalArr) && !isEmpty(tyreRemovalArr)) {
                    setOpenCard(true);
                  } else {
                    toast.warning(
                      "Please Add Tyre Issue Removal First from General DropDowns"
                    );
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                }
              />
              {errObj.RemovalTypeErr ? (
                <FormHelperText>{errObj.RemovalTypeMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        {(from === "addInspection" || isUndefined(from)) && (
          <Grid
            id="category"
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.categoryErr ? 30 : 20 }}
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Category"} />
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <FormControl fullWidth error={errObj.categoryErr}>
                <Select
                  displayEmpty
                  value={category}
                  onChange={(e) => {
                    setCategory(e.target.value);
                    setErrObj({
                      ...errObj,
                      categoryErr: false,
                      categoryMsg: "",
                    });
                    if (e.target.value === "On Wheel") {
                      setVisible(true);
                    }
                  }}
                  size={"medium"}
                  style={category === "" ? { color: "#A2A2A2" } : {}}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select the Category
                  </MenuItem>
                  {categoryListArray.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item.label}>
                        {item.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                {errObj.categoryErr ? (
                  <FormHelperText>{errObj.categoryMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}

        {category === "Scrap" && (
          <Grid
            container
            style={{ marginBottom: errObj.issueErr ? 30 : 20 }}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Tyre Issue"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <FormControl fullWidth error={errObj.issueErr}>
                <OutlinedInput
                  style={{ width: "100%", margin: 0, cursor: "pointer" }}
                  placeholder="Select the Tyre Issue"
                  inputProps={{
                    readOnly: true,
                    style: { cursor: "pointer" },
                  }}
                  value={renderTagsValue}
                  onClick={() => {
                    if (isArray(issueArry) && !isEmpty(issueArry)) {
                      setOpenCard1(true);
                    } else {
                      toast.warning(
                        "Please Add Scrap Analysis Tyre Issue First from General DropDowns"
                      );
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <ArrowDropDownIcon />
                    </InputAdornment>
                  }
                />
                {errObj.issueErr ? (
                  <FormHelperText>{errObj.issueMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}

        {category === "Scrap" && from !== "tyreInventory" && (
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.categoryErr ? 30 : 20 }}
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography title={"Remarks"} />
            </Grid>
            <Grid item xs={12} sm={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter Remarks here"
                value={remarks}
                rows={4}
                multiline
                onChange={(e) => {
                  setRemarks(e.target.value);
                }}
              />
            </Grid>
          </Grid>
        )}

        <Grid
          container
          style={{ marginBottom: 20 }}
          id="upload"
          alignItems={"center"}
          wrap={isMobile && !isTablet ? "wrap" : "nowrap"}
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <Typography>Upload Image : </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={10}
            lg={10}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {!isEmpty(selectedFile) ? (
                selectedFile.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: isEmpty(selectedFile)
                            ? color.disable
                            : color.primary,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowImg(item);
                          setTimeout(() => openModal(true), 200);
                        }}
                      >
                        View Image-{index + 1}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          removeImage(index);
                        }}
                      >
                        <HighlightOffIcon style={{ color: "red" }} />
                      </IconButton>
                    </div>
                  );
                })
              ) : (
                <Typography
                  style={{
                    color:
                      isEmpty(selectedFile) && isArray(selectedFile)
                        ? "#999"
                        : color.black,
                    maxWidth: "50vw",
                    overflow: "hidden",
                    wordWrap: "break-word",
                    whiteSpace: "break-spaces",
                  }}
                >
                  No File Selected
                </Typography>
              )}
            </div>

            <Button
              variant="contained"
              component="label"
              disabled={selectedFile?.length === 5}
              style={{
                backgroundColor:
                  selectedFile?.length === 5 ? color.disable : color.secondary,
                margin: "0 20px",
                width: 100,
              }}
            >
              <input
                type="file"
                multiple
                onChange={(e) => {
                  uploadImg(e.target.files);
                }}
                accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg, application/pdf"
                hidden
              />
              Upload File
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          style={{
            marginBottom: errObj.dateErr ? 30 : 20,
          }}
          id="date"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Dismount Date"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <FormControl
              error={errObj.dateErr}
              sx={{
                border: errObj.dateErr ? "1px solid #ff1744" : null,
                borderRadius: 1,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disabled={from === "addInspection"}
                  disableFuture
                  views={["year", "month", "day"]}
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                    setErrObj({
                      ...errObj,
                      dateErr: false,
                      dateMsg: "",
                    });
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "Please Enter Date",
                      }}
                      sx={{
                        svg: {
                          color: errObj.dateErr ? "#ff1744" : color.primary,
                        },
                      }}
                      error={errObj.dateErr}
                      helperText={errObj.dateMsg}
                    />
                  )}
                />
              </LocalizationProvider>
              {errObj.dateErr ? (
                <FormHelperText>{errObj.dateMsg}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginBottom: 20 }}
        >
          <Button
            onClick={() => Validation()}
            style={{
              backgroundColor: color.primary,
              marginRight: 10,
              width: 130,
            }}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              `Remove Tyre`
            )}
          </Button>
          <ConfirmDialog
            title={`Are you sure you want to Remove Tyre?`}
            visible={confirmDialog}
            handleModal={(bool) => {
              if (bool) {
                submitData();
                setRemoveBtnLoad(true);
              } else {
                setConfirmDialog(false);
              }
            }}
            btnLoad={removeBtnLoad}
          />
          <Button
            style={{
              backgroundColor: color.secondary,
              width: 130,
            }}
            onClick={() => {
              handleClick("cancel");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>

      <Modal
        onClose={() => {
          setOpenCard(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 650,
            maxHeight: "65vh",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={isMobile ? 1 : md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: "50vh" }}
          >
            {tyreRemovalArr.map((item, index) => {
              return (
                <ImageListItem
                  key={index}
                  style={{
                    width: isMobile ? "60vw" : 200,
                    height: 150,
                    border: item?.selected ? `1px solid ${color.primary}` : "",
                  }}
                  onClick={() => {
                    SelectTyre(index);
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: isMobile ? "60vw" : 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected ? color.primary : "",
                      color: item?.selected ? color.white : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={handleSelect}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>

      <Modal
        onClose={() => {
          setOpenCard1(false);
        }}
        open={openCard1}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 650,
            maxHeight: "65vh",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={isMobile ? 1 : md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: "50vh" }}
          >
            {issueArry.map((item, index) => {
              return (
                <ImageListItem
                  key={index}
                  style={{
                    width: isMobile ? "60vw" : 200,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      selectIssue(index);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: isMobile ? "60vw" : 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={handleTyreIssue}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        onClose={() => {
          openModal(false);
          setShowImg("");
        }}
        open={modal}
      >
        <img
          style={{
            backgroundColor: color.white,
            maxWidth: md ? 400 : 800,
            maxHeight: "80vh",
            borderRadius: 4,
          }}
          src={showImg ? showImg : ""}
          alt=""
        />
      </Modal>
      {(from === "addInspection" ||
        (isUndefined(from) && !isEmpty(vehicleListM))) && (
        <SwapTyre
          visible={visible}
          handleModal={(type) => {
            setVisible(false);
            if (type === "cancel") {
              setCategory("");
            }
          }}
          handleSuccess={(data) => {
            setModalData(data);
            // setRemovalType(data?.removalType);
            // SelectTyre("", data?.removalType, "swap");
          }}
          vehicleList1={isUndefined(from) ? vehicleListM : vehicleList}
          vehicleId={isUndefined(from) ? vehicleDetails?.id : vehicleId}
        />
      )}
    </Box>
  );
}
