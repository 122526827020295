import React, { useEffect, useMemo, useRef, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Pagination,
  Select,
  Switch,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { Edit, FilterAlt, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import {
  cloneDeep,
  flattenDeep,
  isArray,
  isEmpty,
  isNull,
  isObject,
  toNumber,
} from "lodash";
import moment from "moment";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import ConfirmDialog from "../../ConfirmDialog";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";
import Chart from "react-google-charts";
import html2canvas from "html2canvas";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import MDateRange from "../../Modal/MDateRange";

export default function VehicleManageMent(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    fleetData,
    name,
  } = props;
  const [tabValue, setTabValue] = useState(0);
  // const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const [vehicleListRows, setVehicleListRows] = useState({});

  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [terrainList, setterrainList] = useState(false);
  const [selectedFile, setSlectedFile] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);
  const [visible, setVisible] = useState(false);
  const [activeIndex, setSctiveIndex] = useState("");
  const [arrReportLoader, setArrReportLoader] = useState(false);

  const styles = useStyles();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [reportLoader, setReportLoader] = useState(false);
  const [reportLoader1, setReportLoader1] = useState(true);
  const [reportLoader2, setReportLoader2] = useState(true);
  const [fleetReportData, setFleetReportData] = useState([]);
  const [mechReportData, setMechReportData] = useState([]);
  const [fleetHealth, setFleetHealth] = useState([]);
  const [tyrePieData, setTyrePieData] = useState([]);
  const [mechPieData, setMechPieData] = useState([]);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const { token, dropDownData, isOnline, userdata, permissionData } =
    useSelector((state) => state.auth);

  const [km_mmArr, setKm_mmArr] = useState({});
  const [vehicleIdsM, setVehicleIds] = useState([]);
  const [tyreInspectionArr, setTyreInspectionArr] = useState({});

  const [mileageArr, setMileageArr] = useState({});
  const [mileageDates, setMileageDates] = useState({});

  const staticArr = [
    "Tyre Mileage Report",
    "Tyre Inspection Report",
    "Km/mm Report",
  ];

  const [staticArray, setStaticArr] = useState([...staticArr]);
  const [fleetHealthDisplay, setFleetHealthDisplay] = useState(true);

  // intialRender used for stop multiple time apiCall in
  const initialRender = useRef(true);

  useEffect(() => {
    if (!isEmpty(mileageArr) && mileageArr?.pagination?.isMore) {
      getMileageReport(
        fleetData?.id,
        vehicleIdsM,
        mileageDates?.fromDate,
        mileageDates?.toDate,
        isEmpty(vehicleIdsM) ? true : false,
        false
      );
    }
  }, [mileageArr]);

  useEffect(() => {
    if (!isEmpty(tyreInspectionArr) && tyreInspectionArr?.pagination?.isMore) {
      getinspectionreport(
        fleetData?.id,
        vehicleIdsM,
        mileageDates?.fromDate,
        mileageDates?.toDate,
        isEmpty(vehicleIdsM) ? true : false,
        false
      );
    }
  }, [tyreInspectionArr]);

  useEffect(() => {
    const dummy_Arr = [...staticArr];
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Fleet Health Summary Report") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              setFleetHealthDisplay(v?.isActive);
          }
          if (v?.label === "Tyre Mileage Report") {
            const findI = dummy_Arr.findIndex(
              (v) => v === "Tyre Mileage Report"
            );
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(findI, 1);
          }
          if (v?.label === "Tyre Inspection Report") {
            const findI = dummy_Arr.findIndex(
              (v) => v === "Tyre Inspection Report"
            );
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(findI, 1);
          }
        });
      });
    }
    setStaticArr(dummy_Arr);
  }, [permissionData]);

  const VehicleManagementColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 70,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const active =
          userdata?.role !== "fleet_owner" && params?.row?.status === 1;
        return (
          <Tooltip title={active ? "Edit" : ""} placement="right" arrow>
            <Edit
              style={{
                color: active ? color.primary : color.disable,
                cursor: "pointer",
              }}
              onClick={() => {
                if (active) {
                  handleClick("editVehicle", params?.row);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 140,
    },
    // {
    //   field: "vehicle_identifier",
    //   headerName: "Vehicle Identifier",
    //   width: 140,
    // },
    {
      field: "status",
      headerName: "Vehicle Status",
      width: 130,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Switch
            checked={params?.row?.status === 1 ? true : false}
            onChange={() => {
              changeVehicleStatus(params?.row);
            }}
          />
        );
      },
    },
    {
      field: "vehicle_type_id",
      headerName: "Vehicle Type",
      width: 160,
      renderCell: (params) => {
        let array = params?.row?.vehicle_type_id;
        let res = dropDownData?.vehicle_type?.find(
          (item) => array?.toString() === item?.id?.toString()
        );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_brand_id",
      headerName: "Vehicle Brand",
      width: 160,
      renderCell: (params) => {
        let array = params?.row?.vehicle_brand_id;
        let res = dropDownData?.vehicle_brand?.find(
          (item) => array?.toString() === item?.id?.toString()
        );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_model_name",
      headerName: "Vehicle Model",
      width: 180,
      renderCell: (params) => {
        return params?.row?.vehicle_model_name || "-";
      },
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: 160,
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                color: params?.row?.added_tyre_count != (0 || "") ? "red" : "",
              }}
            >
              {params?.row?.added_tyre_count}
            </span>
            <span>/{params?.row?.no_of_tyres}</span>
          </>
        );
      },
    },
    {
      field: "current_reading",
      headerName: "Initial Reading",
      width: 180,
      renderCell: (params) => {
        return (
          <Typography>{params?.row?.current_reading || "-"} Km</Typography>
        );
      },
    },
    {
      field: "terrain_ids",
      headerName: "Terrain",
      width: 200,
      renderCell: (params) => {
        let array = params?.row?.terrain_ids.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array.includes(item?.id?.toString());
          return a;
        });
        return (
          <div
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {res.map((items, i) => {
              return (
                <Typography>
                  {items?.label}
                  {i < res.length - 1 ? ", " : ""}
                </Typography>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "load_capicity",
      headerName: "Load Capacity",
      width: 160,
      renderCell: (params) => {
        return <Typography>{params?.row?.load_capicity} Tonnes</Typography>;
      },
    },
    {
      field: "inspection_monthly",
      headerName: "Inspections (Month)",
      width: 200,
    },
    {
      field: "inspection_overall",
      headerName: "Inspections (Overall)",
      width: 200,
    },
    {
      field: "driver_name",
      headerName: "Driver's Name",
      width: 160,
      renderCell: (params) => {
        return params?.row?.driver_name || "-";
      },
    },
    {
      field: "driver_phone",
      headerName: "Driver's No.",
      width: 140,
      renderCell: (params) => {
        return params?.row?.driver_phone || "-";
      },
    },
    {
      field: "goods_carried",
      headerName: "Goods Carried",
      width: 160,
      renderCell: (params) => {
        return params?.row?.goods_carried || "-";
      },
    },
    {
      field: "route_origin",
      headerName: "Route Origin",
      width: 140,
      renderCell: (params) => {
        return params?.row?.route_origin || "-";
      },
    },
    {
      field: "route_destination",
      headerName: "Route Destination",
      width: 180,
      renderCell: (params) => {
        return params?.row?.route_destination || "-";
      },
    },
    {
      field: "Alignments Claimed",
      headerName: "Alignments Claimed",
      width: 200,
      renderCell: (params) => {
        return <Typography>{params?.row?.alignment_count}/5</Typography>;
      },
    },
    {
      field: "capture_date",
      headerName: "Added On",
      width: 180,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.capture_date ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      width: 160,
      renderCell: (params) => {
        return params?.row?.added_by || "-";
      },
    },
  ];

  useEffect(() => {
    if (!isEmpty(km_mmArr) && km_mmArr?.pagination?.isMore) {
      getKmMmReport(vehicleIdsM, true, false);
    }
  }, [km_mmArr]);

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      setPageLoad(true);
      getVehicleist(false);
    }
  }, [record, pageNo, filter]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getVehicleist(false);
  }, [datePicker, datePicker1, searchFilter]);

  useEffect(() => {
    getTyreIssueGraphData();
    getFleetHealthGraphData();
    getTyreIssuePieData();
    getMechIssuePieData();
  }, []);

  const changeVehicleStatus = async (data) => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.changeVehicleStatus}?id=${data?.id}&status=${
          data?.status === 1 ? 0 : 1
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        getVehicleist(false, "", true);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Fleet Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getVehicleist(bool, v, isToggle) {
    !v && isToggle !== true && setPageLoad(true);
    v && setDownloadLoader(true);
    const fromDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePicker1)
      ? moment(datePicker1).format("YYYY-MM-DD")
      : "";

    const terrainMatch = dropDownData?.terrain?.find((v) => v?.id === filter);

    try {
      const response = await getApiData(
        `${
          Setting.endpoints.vehicleList
        }?per_page=${record}&page=${pageNo}&fleet_id=${fleetData?.id}&search=${
          bool ? "" : searchFilter
        }&filter=${isEmpty(terrainMatch) ? filter : ""}&terrain_ids=${
          !isEmpty(terrainMatch) ? filter : ""
        }&from_date=${fromDate}&to_date=${toDate}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadVehicleData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setVehicleListRows(listObj);
          }
        v && auditSave("Vehicle List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setDownloadLoader(false);
    }
  }

  function resetFilterData() {
    handleFilter("");
    setSearchFilter("");
    setDatePicker(null);
    setDatePicker1(null);
    handlePageNo(1);
  }

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.importvehicle;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          getVehicleist(false);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };

  function clearData() {
    setSlectedFile(null);
  }

  function downloadVehicleData(data) {
    const newData = data.map((row, index) => {
      let vehicleType =
        !isNull(row?.vehicle_type_id) &&
        dropDownData?.vehicle_type?.find(
          (item) => row?.vehicle_type_id?.toString() === item?.id?.toString()
        );
      let vehicleBrand =
        !isNull(row?.vehicle_brand_id) &&
        dropDownData?.vehicle_brand?.find(
          (item) => row?.vehicle_brand_id?.toString() === item?.id?.toString()
        );

      const getTerrain = () => {
        let array = row?.terrain_ids?.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array?.includes(item?.id?.toString());
          return a;
        });
        const arr = [];
        res.map((v, i) => arr.push(v?.label));
        return arr.toString();
      };

      const timestamp = row?.capture_date;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        ID: row?.id,
        "Vehicle No.": row?.vehicle_no,
        "Vehicle Status": row?.status === 1 ? "Active" : "In Active",
        "Vehicle Type": vehicleType?.label,
        "Vehicle Brand": vehicleBrand?.label,
        "Vehicle Model": row?.vehicle_model_name,
        "No. of Tyres": `${row?.added_tyre_count}/${row?.no_of_tyres}`,
        "Initial Reading (Km)": Number(
          row?.current_reading.toString().replaceAll(",", "")
        ),
        Terrain: getTerrain(),
        "Load Capacity": `${row?.load_capicity}${
          row?.load_capicity ? " Tonnes" : "-"
        }`,
        "Inspections (Month)": Number(row?.inspection_monthly),
        "Inspections (Overall)": Number(row?.inspection_overall),
        "Driver's Name": row?.driver_name || "-",
        "Driver's No.": row?.driver_phone || "-",
        "Goods Carried": row?.goods_carried || "-",
        "Route Origin": row?.route_origin || "-",
        "Route Destination": row?.route_destination || "-",
        "Alignments Claimed": `${row?.alignment_count || 0}/5`,
        "Added On": row?.capture_date ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Vehicle List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Vehicle List.xlsx");
  }

  async function getTyreIssueGraphData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetissueGraph}?fleet_id=${fleetData.id}&type=tyre`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          creatFleetGraph(response?.data, "t");
        }
      } else {
        toast.error(response?.message);
      }
      getMecIssueGraphData();
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  async function getFleetHealthGraphData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleethealthGraph}?fleet_id=${fleetData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          creatFleetHealthGraph(response?.data, "h");
        }
      } else {
        toast.error(response?.message);
      }
      getMecIssueGraphData();
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  async function getMecIssueGraphData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetissueGraph}?fleet_id=${fleetData.id}&type=mechanical`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          creatFleetGraph(response?.data, "m");
        }
      } else {
        toast.error(response?.message);
      }
      setReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  async function getTyreIssuePieData() {
    setReportLoader1(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetPie}?fleet_id=${fleetData.id}&type=tyre`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          createFleetPie(response?.data, "t");
        }
      } else {
        toast.error(response?.message);
      }
      setReportLoader1(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader1(false);
    }
  }

  async function getMechIssuePieData() {
    setReportLoader2(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetPie}?fleet_id=${fleetData.id}&type=mechanical`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          createFleetPie(response?.data, "m");
        }
      } else {
        toast.error(response?.message);
      }
      setReportLoader2(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader2(false);
    }
  }

  function creatFleetHealthGraph(data, type) {
    const dummy_Arr = [["Issue Type", "data"]];
    Object.keys(data).map((item) => {
      const arr = [
        item === "no_issue" ? "No Issue" : item === "major" ? "Major" : "Minor",
        data[item],
      ];
      dummy_Arr.push(arr);
    });

    setFleetHealth(dummy_Arr);
  }

  function createFleetPie(data, type) {
    const dummy_Arr = [
      type === "t" ? ["Tyre Issue", "data"] : ["Mechanical Issue", "data"],
    ];

    for (const [key, value] of Object.entries(data)) {
      const arr = [
        key === "unresolved"
          ? "Unresolved"
          : key === "partially_resolved"
          ? "Partially Resolved"
          : "Resolved",
        Number(value),
      ];
      dummy_Arr.push(arr);

      if (type === "t") {
        setTyrePieData(dummy_Arr);
      } else {
        setMechPieData(dummy_Arr);
      }
      setReportLoader(false);
    }
  }

  function creatFleetGraph(data, type) {
    const dummy_Arr = [
      type === "t" ? ["Tyre Issue", "data"] : ["Mechanical Issue", "data"],
    ];

    data.map((item) => {
      const arr = [item?.issue, Number(item?.percentage)];
      dummy_Arr.push(arr);
    });
    if (type === "t") {
      setFleetReportData(dummy_Arr);
    } else {
      setMechReportData(dummy_Arr);
    }
    setReportLoader(false);
  }

  const options = {
    colors: [color.primary],
    legend: { position: "none" },
    vAxis: { title: "Number of Vehicles with the Issues" },
  };

  async function getinspectionreport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll,
    bool
  ) {
    setArrReportLoader(true);

    let page = 0;
    const cPage =
      tyreInspectionArr &&
      tyreInspectionArr.pagination &&
      tyreInspectionArr.pagination.currentPage
        ? toNumber(tyreInspectionArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }

    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const from_date1 = !isNull(mileageDates?.fromDate)
      ? moment(mileageDates?.fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    const to_date1 = !isNull(mileageDates?.toDate)
      ? moment(mileageDates?.toDate).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        isAll
          ? `${Setting.endpoints.tyreInspectionReport}?fleet_id=${
              fleetData?.id
            }&is_all=${1}&page=${page}&from_date=${
              bool ? from_date : from_date1
            }&to_date=${
              bool
                ? isEmpty(to_date)
                  ? from_date
                  : to_date
                : isEmpty(to_date1)
                ? from_date1
                : to_date1
            }`
          : `${Setting.endpoints.tyreInspectionReport}?fleet_id=${
              fleetData?.id
            }&vehicle_ids=${vehicleIds}&page=${page}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (isObject(response) && !isEmpty(response) && response.status) {
        const obj = bool ? {} : cloneDeep(tyreInspectionArr);

        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};
        if (isArray(newListData) && !isEmpty(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
          if (!paginationDatas?.isMore) {
            downloadReports5(obj?.data);
            auditSave("Tyre Inspection Report");
            setTyreInspectionArr({});
            setArrReportLoader(false);
            setMileageDates({});
          } else {
            setTyreInspectionArr(obj);
          }
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
          setArrReportLoader(false);
        }
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
      setArrReportLoader(false);
    }
  }

  async function getKmMmReport(vehicleIds, isAll, bool) {
    setArrReportLoader(true);
    let page = 0;
    const cPage =
      km_mmArr && km_mmArr.pagination && km_mmArr.pagination.currentPage
        ? toNumber(km_mmArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }

    try {
      const response = await getApiData(
        isAll
          ? `${Setting.endpoints.kmReport}?fleet_id=${
              fleetData?.id
            }&is_all=${1}&vehicle_ids=${
              bool ? vehicleIds : vehicleIdsM
            }&page=${page}`
          : `${Setting.endpoints.kmReport}?fleet_id=${fleetData?.id}&vehicle_ids=${vehicleIds}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (isObject(response) && !isEmpty(response) && response.status) {
        const obj = bool ? {} : cloneDeep(km_mmArr);

        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};
        if (isArray(newListData) && !isEmpty(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
          if (!paginationDatas?.isMore) {
            setArrReportLoader(false);
            downloadReports7(obj?.data);
            auditSave("Km_mm Report");
            setKm_mmArr({});
            setVehicleIds([]);
          } else {
            setKm_mmArr(obj);
          }
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
          setArrReportLoader(false);
        }
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
      setArrReportLoader(false);
    }
  }

  function downloadReports7(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.first_inspection_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const lastNSDArr = item?.last_inspection_nsd;
      let lastGroove1 = 0;
      let lastGroove2 = 0;
      let lastGroove3 = 0;
      let lastGroove4 = 0;
      let lastCount1 = 1;
      let lastCount2 = 1;
      let lastCount3 = 1;
      let lastCount4 = 1;
      isArray(lastNSDArr) &&
        !isEmpty(lastNSDArr) &&
        lastNSDArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            lastGroove1 =
              (Number(lastGroove1) + Number(v?.groove1)) / lastCount1;
            lastCount1++;
          }
          if (!isEmpty(v?.groove2)) {
            lastGroove2 =
              (Number(lastGroove2) + Number(v?.groove2)) / lastCount2;
            lastCount2++;
          }
          if (!isEmpty(v?.groove3)) {
            lastGroove3 =
              (Number(lastGroove3) + Number(v?.groove3)) / lastCount3;
            lastCount3++;
          }
          if (!isEmpty(v?.groove4)) {
            lastGroove4 =
              (Number(lastGroove4) + Number(v?.groove4)) / lastCount4;
            lastCount4++;
          }
        });

      const first_inspection_date = !isEmpty(item?.first_inspection_date)
        ? moment(item?.first_inspection_date).format("DD-MM-yyyy")
        : "-";
      const last_inspection_date = !isEmpty(item?.last_inspection_date)
        ? moment(item?.last_inspection_date).format("DD-MM-yyyy")
        : "-";
      const unresolvedTyreIssue = item?.unresolved_tyre_issues;
      let arr = [];
      unresolvedTyreIssue.map((item) => {
        arr.push(`${item?.issue_name} : ${item?.count}`);
      });

      const unresolvedMechanicleIssue = item?.unresolved_mechanical_issues;
      let arr1 = [];
      unresolvedMechanicleIssue.map((item) => {
        arr1.push(`${item?.issue_name} : ${item?.count}`);
      });

      const resolvedIssues = item?.resolved_issue_list;
      let arr2 = [];
      isArray(resolvedIssues) &&
        resolvedIssues?.map((item) => {
          arr2.push(`${item}`);
        });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name || "-",
        "Vehicle No": item?.vehicle_no || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Axle Type": item?.axle_type || "-",
        "Axle Position": item?.tyre_position || "-",
        "Standard NSD (mm)": Number(item?.original_nsd) || "-",
        "Tyre Fitment Reading (km)":
          Number(item?.tyre_fitment_km.toString().replaceAll(",", "")) || "-",
        "First Inspection Date": first_inspection_date || "-",

        "First Inspection NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "First Inspection NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "First Inspection NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "First Inspection NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",

        "AVG First Inspection NSD":
          Number(item?.first_inspection_avg_nsd) || "-",
        "First Inspection Reading (km)":
          Number(
            item?.first_inspection_reading_km.toString().replaceAll(",", "")
          ) || "-",
        "Last Inspection Date": last_inspection_date || "-",
        "Last Inspection NSD Groove1 (mm)":
          Number(lastGroove1.toFixed(2)) || "-",
        "Last Inspection NSD Groove2 (mm)":
          Number(lastGroove2.toFixed(2)) || "-",
        "Last Inspection NSD Groove3 (mm)":
          Number(lastGroove3.toFixed(2)) || "-",
        "Last Inspection NSD Groove4 (mm)":
          Number(lastGroove4.toFixed(2)) || "-",

        "AVG Last Inspection  NSD":
          Number(item?.last_inspection_avg_nsd) || "-",
        "Last Inspection Reading (km)":
          Number(
            item?.last_inspection_reading_km.toString().replaceAll(",", "")
          ) || "-",
        Mileage: Number(item?.mileage) || "-",
        "Total Mileage": Number(item?.total_mileage) || "-",
        "Consumed NSD": item?.consumed_nsd || "-",
        "Percentage wear of tyre": item?.percentage_wear_of_tyre || "-",
        "Actual km/mm": item?.km_mm || "-",
        "Unresolved Tyre Issues": arr.toString() || "-",
        "Total Unresolved Issues": Number(item?.unresolved_issues_count) || "-",
        "Resolved Issues": arr2.toString() || "-",
        "Unresolved Mechanical Issues": arr1.toString() || "-",
        "Total Mechanical Issues":
          Number(item?.unresolved_mechanical_issues_count) || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Km_mm Report List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Km_mm Report.xlsx");
  }

  function downloadReports5(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const getTerrain = () => {
        let array = item?.terrain_ids?.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array?.includes(item?.id?.toString());
          return a;
        });
        const arr = [];
        res.map((v, i) => arr.push(v?.label));
        return arr?.toString();
      };
      const timestamp = item?.inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        Terrain: getTerrain() || "-",
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(item?.avg_nsd) || "-",
        "Tyre Pressure (psi)": item?.tyre_air_pressure || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Tyre Issue": item?.tyre_issue?.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue?.toString() || "-",
        "Consumed NSD (mm)": Number(item?.consumed_nsd) || "-",
        "KM/mm": item?.km_mm || "-",
        "Projected Mileage@100%": item?.projected_mileage_100 || "-",
        "Projected Mileage@85%": item?.projected_mileage_85 || "-",
        "Inspected Date": item?.inspected_at ? displayDate : "-",
        "Inspected By": item?.inspected_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Inspection Report List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Inspection Report.xlsx");
  }

  async function getCPKreport(from_date, to_date) {
    try {
      const response = await getApiData(
        `${Setting.endpoints.cpkReport}?fleet_id=${fleetData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReports6(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave("CPKm Analysis Master Report");
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  function downloadReports6(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        RO: item?.ro || "-",
        District: item?.district || "-",
        "Vehicle No": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        Cost: item?.cost ? `₹ ${item?.cost}` : "-",
        "Total Mileage (from the TSN page)": item?.mileage || "-",
        // "Dismount Reason": "",
        "Cost Per Km (Mileage/Purchase Cost)": item?.cost_per_km || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "CPKm Analysis Master Report"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "CPKm Analysis Master Report.xlsx");
  }

  async function getMileageReport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll,
    bool
  ) {
    setArrReportLoader(true);

    let page = 0;
    const cPage =
      mileageArr && mileageArr.pagination && mileageArr.pagination.currentPage
        ? toNumber(mileageArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }

    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const from_date1 = !isNull(mileageDates?.fromDate)
      ? moment(mileageDates?.fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    const to_date1 = !isNull(mileageDates?.toDate)
      ? moment(mileageDates?.toDate).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        isAll
          ? `${Setting.endpoints.tyreMileageReport}?fleet_id=${
              fleetData?.id
            }&is_all=${1}&from_date=${bool ? from_date : from_date1}&to_date=${
              bool
                ? isEmpty(to_date)
                  ? from_date
                  : to_date
                : isEmpty(to_date1)
                ? from_date1
                : to_date1
            }&page=${page}`
          : `${Setting.endpoints.tyreMileageReport}?fleet_id=${
              fleetData?.id
            }&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (isObject(response) && !isEmpty(response) && response.status) {
        const obj = bool ? {} : cloneDeep(mileageArr);

        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};
        if (isArray(newListData) && !isEmpty(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
          if (!paginationDatas?.isMore) {
            setArrReportLoader(false);
            downloadMileageReport(obj?.data);
            auditSave("Tyre Mileage Report");
            setMileageArr({});
            setMileageDates({});
          } else {
            setMileageArr(obj);
          }
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
          setArrReportLoader(false);
        }
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
      setArrReportLoader(false);
    }
  }

  function mileageHistory(data) {
    const one = Object.keys(data).map((item) => {
      let a = `${item} => ${data[item]}`;
      return a;
    });
    const arr = [];
    one.map((item1) => {
      arr.push(item1);
    });
    return arr;
  }

  function downloadMileageReport(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        // "Current NSD (mm)": `${groove1.toFixed(2) || 0}/${
        //   groove2.toFixed(2) || 0
        // }/${groove3.toFixed(2) || 0}/${groove4.toFixed(2) || 0}`,
        "Standard NSD (mm)": Number(item?.original_nsd) || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Current Avg NSD (mm)": Number(item?.avg_nsd) || "-",
        "Total Mileage (Km)": Number(
          item?.current_mileage.toString()?.replaceAll(",", "")
        ),
        "Cost (₹)":
          Number(item?.purchase_cost?.toString()?.replaceAll(",", "")) || "-",
        CPKm: item?.CPKm ? item?.CPKm : "-",
        "Mileage History":
          mileageHistory(item?.mileage_history).toString() || "-",
        "Percentage Wear of Tyre":
          `${item?.percentage_wear_of_tyre || 0}%` || "-",
        // "Dismount Reason": "",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Mileage Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Mileage Report.xlsx");
  }

  function printDocument(id, name, btn) {
    let note = null;
    if (id === "graph1" || id === "graph2") {
      note = document.getElementById(btn);
      note.style.opacity = 0;
    }
    const input = document.getElementById(id);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var a = document.createElement("a"); //Create <a>
      a.href = imgData; //Image Base64 Goes here
      a.download = `${name}.png`; //File name Here
      a.click(); //Downloaded file
    });
    // html2canvas(input).then((canvas) => {
    //   const imgData = canvas.toDataURL("image/png");
    //   const pdf = new jsPDF();
    //   pdf.addImage(imgData, "JPEG", 0, 0, 210, 297);
    //   pdf.save("download.pdf");

    //   // var a = document.createElement("a"); //Create <a>
    //   // a.href = imgData; //Image Base64 Goes here//File name Here
    //   // a.download = name; //File name Here
    //   // a.click(); //Downloaded file
    // });
    if (id === "graph1" || id === "graph2") {
      note.style.opacity = 1;
    }
  }

  const renderChart = useMemo(() => {
    return reportLoader ? (
      <div className={styles.dataMain}>
        <CircularProgress style={{ color: color.primary }} />
      </div>
    ) : isArray(fleetHealth) && !isEmpty(fleetHealth) ? (
      <Grid item style={{ display: "flex", flexDirection: "column" }}>
        <Chart
          chartType="PieChart"
          data={fleetHealth}
          height="300px"
          width="100%"
          options={{
            title: "",
            pieHole: 0.4,
            legend: { position: "none" },
            is3D: false,
            slices: {
              0: { color: "#EB5757" },
              1: { color: "#F2C94C" },
              2: { color: "#27AE60" },
            },
          }}
        />
        <Grid item container justifyContent="center">
          <Typography
            style={{
              display: "flex",
            }}
            fontWeight="bold"
          >
            <SquareRoundedIcon style={{ color: "#EB5757" }} />
            Major Issues
          </Typography>
          <Typography
            style={{
              display: "flex",
              paddingLeft: 8,
              paddingRight: 8,
            }}
            fontWeight="bold"
          >
            <SquareRoundedIcon style={{ color: "#F2C94C" }} />
            Minor Issues
          </Typography>
          <Typography
            style={{
              display: "flex",
            }}
            fontWeight="bold"
          >
            <SquareRoundedIcon style={{ color: "#27AE60" }} />
            No Issues
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <div className={styles.dataMain}>
        <Typography>No Data</Typography>
      </div>
    );
  }, [reportLoader, fleetHealth]);

  const reportChart1 = useMemo(() => {
    return reportLoader1 ? (
      <div className={styles.dataMain}>
        <CircularProgress style={{ color: color.primary }} />
      </div>
    ) : isArray(tyrePieData) && !isEmpty(tyrePieData) ? (
      <Grid item>
        <Chart
          chartType="PieChart"
          data={tyrePieData}
          height="300px"
          width="100%"
          options={{
            title: "",
            pieHole: 0.4,
            legend: { position: "none" },
            is3D: false,
            slices: {
              0: { color: "#EB5757" },
              1: { color: "#27AE60" },
              2: { color: "#F2C94C" },
            },
          }}
        />
      </Grid>
    ) : (
      <div className={styles.dataMain}>
        <Typography>No Data</Typography>
      </div>
    );
  }, [reportLoader1, tyrePieData]);

  const reportChart2 = useMemo(() => {
    return reportLoader2 ? (
      <div className={styles.dataMain}>
        <CircularProgress style={{ color: color.primary }} />
      </div>
    ) : isArray(mechPieData) && !isEmpty(mechPieData) ? (
      <Grid item>
        <Chart
          chartType="PieChart"
          data={mechPieData}
          height="300px"
          width="100%"
          options={{
            title: "",
            pieHole: 0.4,
            legend: { position: "none" },
            is3D: false,
            slices: {
              0: { color: "#EB5757" },
              1: { color: "#27AE60" },
              2: { color: "#F2C94C" },
            },
          }}
        />
      </Grid>
    ) : (
      <div className={styles.dataMain}>
        <Typography>No Data</Typography>
      </div>
    );
  }, [reportLoader2, mechPieData]);

  return (
    <div className={styles.container}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ height: 40 }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="tableTitle">Vehicle Management</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: sm ? "unset" : "flex-end" }}
        >
          {/* <Button variant="contained" component="label">
            <input
              type="file"
              onChange={(e) => {
                setSlectedFile(e.target.files[0]);
                setConfirmDialog(true);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              accept=".csv"
              hidden
            />
            Upload CSV
          </Button> */}
          {tabValue === 0 ? (
            <>
              <Button
                style={{ marginLeft: 10 }}
                variant="contained"
                onClick={() => {
                  handleClick("addVehicle", "");
                }}
                disabled={
                  userdata?.role === "fleet_owner" ||
                  userdata?.role === "fleet_manager"
                }
              >
                Add Vehicle
              </Button>
              <Button
                disabled={isOnline ? false : true}
                variant="contained"
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                  marginLeft: 10,
                }}
                onClick={() => {
                  getVehicleist(false, 1);
                }}
              >
                {downloadLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  "Download"
                )}
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "#BBBBBB" }}>
        <Tabs
          value={tabValue}
          onChange={(v, b) => {
            handlePageNo(1);
            handleRecord(10);
            setTabValue(b);
            handleFilter("");
          }}
          variant="scrollable"
        >
          <Tab className={styles.tabBtn} label="Vehicle" />
          <Tab className={styles.tabBtn} label="Report" />
        </Tabs>
      </Box>
      {tabValue === 0 ? (
        <div>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 14 }}
          >
            <Grid item lg={2}>
              <Select
                fullWidth
                placeholder="Filter"
                value={filter}
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    settypeList(false);
                    setbrandList(false);
                    setterrainList(false);
                  }
                }}
                displayEmpty
                IconComponent={FilterAlt}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
                classes={{
                  iconOpen: styles.iconOpen,
                }}
              >
                <MenuItem
                  value={""}
                  disabled
                  hidden
                  selected
                  style={{ minWidth: 220 }}
                >
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    settypeList(!typeList);
                    setbrandList(false);
                    setterrainList(false);
                  }}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Vehicle Type</Typography>
                  {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.vehicle_type.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: typeList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
                <ListItemButton
                  onClick={() => {
                    setbrandList(!brandList);
                    settypeList(false);
                    setterrainList(false);
                  }}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Vehicle Brand</Typography>
                  {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.vehicle_brand.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: brandList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
                <ListItemButton
                  onClick={() => {
                    setterrainList(!terrainList);
                    settypeList(false);
                    setbrandList(false);
                  }}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Terrain</Typography>
                  {terrainList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.terrain.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: terrainList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid
              item
              lg={1.6}
              style={{
                display: "flex",
                alignItems: "center",
                maxWidth: "unset",
              }}
            >
              <Typography
                style={{ fontSize: 14, marginRight: 10, whiteSpace: "nowrap" }}
              >
                Records :
              </Typography>
              <Select
                fullWidth
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
                // style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginTop: 20 }}
            wrap="nowrap"
          >
            <Grid item lg={2}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value) && getVehicleist(true);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    if (
                      !isEmpty(searchFilter) ||
                      (!isNull(datePicker) && !isNull(datePicker1))
                    ) {
                      handlePageNo(1);
                      getVehicleist(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Vehicle No. and Load Capacity"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              // lg={4}
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: 20,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  views={["year", "month", "day"]}
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                    setDisable(false);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                to
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disabled={isNull(datePicker) ? true : disable}
                  minDate={datePicker}
                  views={["year", "month", "day"]}
                  disableFuture
                  value={datePicker1}
                  inputFormat="dd-MM-yyyy"
                  onChange={(newValue) => {
                    setDatePicker1(newValue);
                  }}
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{
                        svg: {
                          color:
                            disable || isNull(datePicker) ? "" : color.primary,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item lg={4} wrap="nowrap" style={{ display: "flex" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                onClick={() => {
                  if (
                    !isEmpty(searchFilter) ||
                    (!isNull(datePicker) && !isNull(datePicker1))
                  ) {
                    handlePageNo(1);
                    getVehicleist(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={
                  filter === "" && isEmpty(searchFilter) && isNull(datePicker)
                    ? true
                    : false
                }
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(vehicleListRows?.data) &&
            !isEmpty(vehicleListRows?.data) ? (
            <>
              <div style={{ marginTop: 20 }}>
                <DataGrid
                  rows={vehicleListRows?.data}
                  columns={VehicleManagementColumns}
                  onCellClick={(params) => {
                    if (
                      params?.field !== "action" &&
                      params?.field !== "status"
                    ) {
                      handleClick("vehicleDetails", params?.row);
                    }
                  }}
                  pageSize={record}
                  disableColumnMenu
                  autoHeight={true}
                  hideFooter
                  disableSelectionOnClick
                  showCellRightBorder
                  showColumnRightBorder
                  getRowHeight={() => "auto"}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(vehicleListRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : null}
      {tabValue === 1 ? (
        <>
          {staticArray?.map((item, index) => {
            return (
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Typography>
                    {item} - {name}
                  </Typography>
                  <Button
                    disabled={
                      isOnline ? false : true || index === 5 ? true : ""
                    }
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginRight: 20,
                    }}
                    onClick={() => {
                      setSctiveIndex(index) || setVisible(true);
                    }}
                  >
                    {activeIndex === index && arrReportLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      "Download"
                    )}
                  </Button>
                </Grid>
                {index === staticArray.length - 1 ? "" : <Divider />}
              </div>
            );
          })}
          <Divider />

          <div id="mainGraph">
            {reportLoader ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(fleetReportData) && !isEmpty(fleetReportData) ? (
              <Grid
                id="graph1"
                container
                style={{ height: 350, marginTop: 20, width: "100%" }}
                alignItems="center"
              >
                <Grid item container alignItems={"center"}>
                  <Typography
                    variant="tableTitle"
                    style={{ marginBottom: 20, marginLeft: 10 }}
                  >
                    Tyre Issues
                  </Typography>
                  <Button
                    id="btn1"
                    disabled={isOnline ? false : true}
                    variant="contained"
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginBottom: 20,
                      marginLeft: "auto",
                      marginRight: 30,
                    }}
                    onClick={() => {
                      auditSave("Tyre Issues Graph");
                      printDocument("graph1", "Tyre Issues", "btn1");
                    }}
                  >
                    Download
                  </Button>
                </Grid>
                <Chart
                  chartType="Bar"
                  data={fleetReportData}
                  width="100%"
                  height="88%"
                  options={options}
                />
              </Grid>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
            <Divider />
            {reportLoader ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(mechReportData) && !isEmpty(mechReportData) ? (
              <Grid
                container
                style={{ height: 350, marginTop: 20 }}
                id="graph2"
              >
                <Grid item container alignItems={"center"}>
                  <Typography
                    variant="tableTitle"
                    style={{
                      marginBottom: 20,
                      marginLeft: 10,
                    }}
                  >
                    Mechanical Issues
                  </Typography>
                  <Button
                    id="btn2"
                    disabled={isOnline ? false : true}
                    variant="contained"
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginLeft: "auto",
                      marginBottom: 20,
                      marginRight: 30,
                    }}
                    onClick={() => {
                      auditSave("Mechanical Issues Graph");
                      printDocument("graph2", "Mechanical Issues", "btn2");
                    }}
                  >
                    Download
                  </Button>
                </Grid>
                <Chart
                  chartType="Bar"
                  data={mechReportData}
                  width="100%"
                  height="88%"
                  options={options}
                />
              </Grid>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
            <Divider />
            {fleetHealthDisplay && (
              <Grid
                container
                style={{ minHeight: 400, marginBottom: 20, marginTop: 20 }}
              >
                <Grid item container>
                  <Button
                    disabled={isOnline ? false : true}
                    variant="contained"
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginLeft: "auto",
                      marginBottom: 20,
                      marginRight: 30,
                    }}
                    onClick={() => {
                      auditSave("Fleet Health Graph");
                      printDocument("piegraph1", "Fleet Health");
                    }}
                  >
                    Download
                  </Button>
                </Grid>

                <Grid item container id="piegraph1">
                  <Grid item container flexDirection={"row"}>
                    <Grid item container flexDirection={"column"} xs={6}>
                      <Grid item textAlign={"center"}>
                        <Typography variant="tableTitle">
                          Fleet Health
                        </Typography>
                      </Grid>
                      {renderChart}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              style={{ minHeight: 400, marginBottom: 20, marginTop: 20 }}
              alignItems="center"
            >
              {(!isArray(tyrePieData) || isEmpty(tyrePieData)) &&
              (!isArray(mechPieData) || isEmpty(mechPieData)) ? null : (
                <Grid item container>
                  <Button
                    disabled={isOnline ? false : true}
                    variant="contained"
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginLeft: "auto",
                      marginBottom: 20,
                      marginRight: 30,
                    }}
                    onClick={() => {
                      auditSave("Fleet Issues Graph");
                      printDocument("piegraph2", "Fleet Issues");
                    }}
                  >
                    Download
                  </Button>
                </Grid>
              )}
              <Grid item container id="piegraph2">
                <Grid
                  item
                  container
                  flexDirection={"row"}
                  wrap={lg ? "wrap" : "nowrap"}
                >
                  <Grid
                    item
                    container
                    flexDirection={"column"}
                    xs={6}
                    style={{ flex: 1 }}
                  >
                    <Grid item textAlign={"center"}>
                      <Typography variant="tableTitle">Tyre Issues</Typography>
                    </Grid>
                    {reportChart1}
                  </Grid>
                  <Grid
                    item
                    container
                    flexDirection={"column"}
                    xs={6}
                    style={{ flex: 1 }}
                  >
                    <Grid item textAlign={"center"}>
                      <Typography variant="tableTitle">
                        Mechanical Issues
                      </Typography>
                    </Grid>
                    {reportChart2}
                  </Grid>
                </Grid>
                <Grid item container justifyContent="center">
                  <Typography
                    style={{
                      display: "flex",
                    }}
                    fontWeight="bold"
                  >
                    <SquareRoundedIcon style={{ color: "#EB5757" }} />
                    Unresolved Issues
                  </Typography>
                  <Typography
                    style={{
                      display: "flex",
                      paddingLeft: 8,
                      paddingRight: 8,
                    }}
                    fontWeight="bold"
                  >
                    <SquareRoundedIcon style={{ color: "#F2C94C" }} />
                    Partially Resolved Issues
                  </Typography>
                  <Typography
                    style={{
                      display: "flex",
                    }}
                    fontWeight="bold"
                  >
                    <SquareRoundedIcon style={{ color: "#27AE60" }} />
                    Resolved Issues
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
        </>
      ) : null}
      <ConfirmDialog
        title={`Are you sure you want to Upload CSV?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            handleOnSubmit();
          } else {
            setConfirmDialog(false);
            clearData();
          }
        }}
        btnLoad={removeBtnLoad}
      />
      <MDateRange
        disableFleet
        visible={visible}
        fleetId={fleetData?.id}
        disableDate={activeIndex === 2}
        handleModal={(type, fleetIds, vehicleIds, fromDate, toDate, isAll) => {
          if (type === "download") {
            if (staticArray[activeIndex] === "Tyre Mileage Report") {
              getMileageReport(
                fleetIds,
                vehicleIds,
                fromDate,
                toDate,
                isAll,
                true
              );
              setMileageDates({ fromDate: fromDate, toDate: toDate });
            } else if (staticArray[activeIndex] === "Tyre Inspection Report") {
              getinspectionreport(
                fleetIds,
                vehicleIds,
                fromDate,
                toDate,
                isAll,
                true
              );
              setVehicleIds(vehicleIds);
              setMileageDates({ fromDate: fromDate, toDate: toDate });
            } else if (staticArray[activeIndex] === "Km/mm Report") {
              getKmMmReport(vehicleIds, isAll, true);
              setVehicleIds(vehicleIds);
            } else {
              downloadReports5();
            }
          }
          setVisible(false);
        }}
      />
    </div>
  );
}
