import {
  Grid,
  Modal,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import html2canvas from "html2canvas";
import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isTablet } from "react-device-detect";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { color } from "../../../Config/theme.js";
import { getApiData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import useStyles from "./styles.js";

export default function TabTicketID(props) {
  const [ticketDetails, setTicketDetails] = useState({});
  const [vehicleDetails, setVehicleDetail] = useState({});
  const [openCard, setOpenCard] = useState(false);
  const { token } = useSelector((state) => state.auth);
  const styles = useStyles();

  const { handleClick = () => null, editData } = props;

  let section = null;
  let scroll = false;
  useEffect(() => {
    if (!isEmpty(editData)) {
      getTicketDetails();
      getVehicleDetails();
    }
    if (!scroll) {
      scroll = true;
      section = document.querySelector("#ticket");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }
  }, []);

  const getTicketDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.ticketdetails}?ticket_id=${editData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setTicketDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${editData?.vehicle_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function AddedOn() {
    const timestamp = ticketDetails?.created_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return timestamp ? displayDate : "";
  }

  function ResolvedOn() {
    const timestamp = ticketDetails?.resolved_on;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return timestamp ? displayDate : "";
  }

  const checkFunction = () => {
    if (!isEmpty(vehicleDetails) && !isEmpty(vehicleDetails?.rc_book)) {
      let Array = vehicleDetails?.rc_book.split(".");
      if (Array[Array.length - 1] === "pdf") {
        const pdfWindow = window.open();
        pdfWindow.location.href = vehicleDetails?.business_pan_card;
      } else {
        setOpenCard(true);
      }
    }
  };

  function printDocument(id, name) {
    const input = document.getElementById(id);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL(ticketDetails?.bill);
      var a = document.createElement("a"); //Create <a>
      a.href = imgData; //Image Base64 Goes here
      a.download = `${name} - Bill.png`; //File name Here
      a.click(); //Downloaded file
    });
  }

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: "20px 15px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight={"bold"} id="ticket">
              Resolved Ticket ID - {ticketDetails?.id}
            </Typography>
          </Box>
          <div style={{ padding: 20 }}>
            <Table>
              <TableRow>
                <TableCell variant="head">Ticket ID</TableCell>
                <TableCell>{ticketDetails?.id || "-"}</TableCell>
                <TableCell variant="head">Alignments Claimed</TableCell>
                <TableCell>{ticketDetails?.alignments_claimed}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Driver's Name</TableCell>
                <TableCell>{ticketDetails?.driver_name || "-"}</TableCell>
                <TableCell variant="head">Added On</TableCell>
                <TableCell>{AddedOn() || "- "}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Driver's No.</TableCell>
                <TableCell>{ticketDetails?.driver_number || "-"}</TableCell>
                <TableCell variant="head">Added By</TableCell>
                <TableCell>{ticketDetails?.added_by || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Goods Carried</TableCell>
                <TableCell>{ticketDetails?.good_carried || "-"}</TableCell>
                <TableCell variant="head">Resolved By</TableCell>
                <TableCell>{ticketDetails?.resolved_by || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Issue</TableCell>
                <TableCell>
                  {ticketDetails?.issue === "cut"
                    ? "Cut"
                    : ticketDetails?.issue === "misallignment"
                    ? "Misallignment"
                    : "Puncture" || "-"}
                </TableCell>
                <TableCell variant="head">Resolved On</TableCell>
                <TableCell>{ResolvedOn() || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Notified By</TableCell>
                <TableCell>
                  {ticketDetails?.notify_by === "driver"
                    ? "Driver"
                    : "Workshop Manager" || "-"}
                </TableCell>
                <TableCell variant="head">Route Origin</TableCell>
                <TableCell>{ticketDetails?.route_origin || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Bill</TableCell>
                <TableCell
                  style={{
                    color: color.primary,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href={ticketDetails?.bill}
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color:
                        ticketDetails?.bill === ""
                          ? color.bordercolor
                          : color.primary,
                      pointerEvents: ticketDetails?.bill === "" ? "none" : "",
                    }}
                  >
                    Download
                  </a>
                </TableCell>
                <TableCell variant="head">Route Destination</TableCell>
                <TableCell>{ticketDetails?.route_destination || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Payment Status</TableCell>
                <TableCell>{ticketDetails?.payment_status || "-"}</TableCell>
                <TableCell variant="head">Ticket Status</TableCell>
                <TableCell>
                  {ticketDetails?.status === 0 ? "Open" : "Resolved" || "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Service Center</TableCell>
                <TableCell>{ticketDetails?.service_center || "-"}</TableCell>
                <TableCell variant="head">Service Center No.</TableCell>
                <TableCell>{ticketDetails?.center_phone || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Service Center Address</TableCell>
                <TableCell colSpan={3}>
                  {ticketDetails?.center_location || "-"}
                </TableCell>
              </TableRow>
            </Table>
            {/* </div> */}
            <div style={{ paddingTop: 20 }}>
              {ticketDetails?.bill && (
                <img
                  style={{ width: "100%" }}
                  src={ticketDetails?.bill || "-"}
                  alt="bill"
                />
              )}
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <Typography fontWeight={"bold"}>Vehicle Details</Typography>
              </div>
              <Table>
                <TableRow>
                  <TableCell variant="head">Vehicle No.</TableCell>
                  <TableCell>{vehicleDetails?.vehicle_no || "-"}</TableCell>
                  <TableCell variant="head">Fleet Name</TableCell>
                  <TableCell>{vehicleDetails?.fleet_name || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Vehicle Type</TableCell>
                  <TableCell>
                    {vehicleDetails?.vehicle_type_id || "-"}
                  </TableCell>
                  <TableCell variant="head">Vehicle Brand</TableCell>
                  <TableCell>
                    {vehicleDetails?.vehicle_brand_id || "-"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Vehicle Model</TableCell>
                  <TableCell>{vehicleDetails?.vehicle_model || "-"}</TableCell>
                  <TableCell variant="head">Initial Reading</TableCell>
                  <TableCell>
                    {vehicleDetails?.initial_reading || "-"} Km
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">No. of Tyres</TableCell>
                  <TableCell>{vehicleDetails?.no_of_tyres || "-"}</TableCell>
                  <TableCell variant="head">Load Capacity</TableCell>
                  <TableCell>
                    {vehicleDetails?.load_capicity || "-"} Tonnes
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Alignments Claimed</TableCell>
                  <TableCell>
                    {vehicleDetails?.alignment_count || "-"}
                  </TableCell>
                  <TableCell variant="head">RC Book Image</TableCell>
                  <TableCell
                    style={{
                      color: vehicleDetails?.rc_book
                        ? color.primary
                        : color.bordercolor,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={checkFunction}
                  >
                    View
                  </TableCell>
                </TableRow>
              </Table>
            </div>
          </div>
          <Modal
            className={styles.modal}
            open={openCard}
            onClose={() => {
              setOpenCard(false);
            }}
          >
            <img
              style={{
                objectFit: "contain",
                borderRadius: 4,
                maxWidth: isTablet ? 550 : 330,
                maxHeight: isTablet ? 550 : 330,
              }}
              src={vehicleDetails.rc_book || "-"}
              alt={"Business PAN Card"}
            />
          </Modal>
        </div>
      ) : (
        <div className={styles.container}>
          <Grid container xs={12}>
            <Typography id="ticket" variant="tableTitle">
              Resolved Ticket ID : {ticketDetails?.id}
            </Typography>
          </Grid>
          <Grid
            container
            style={{
              border: `1px solid ${color.bordercolor}`,
              marginTop: 15,
            }}
          >
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Ticket ID</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Alignments Claimed</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.id}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{ticketDetails?.alignments_claimed}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Driver’s Name</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Added By</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
                background: "#FBF8F8",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.driver_name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{ticketDetails?.added_by}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Driver’s No.</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Added On</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.driver_number}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{AddedOn() || "-"}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
                background: "#FBF8F8",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Goods Carried</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Resolved By</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
                background: "#FBF8F8",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.good_carried}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{ticketDetails?.resolved_by}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Issues</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Resolved On</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.issue || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{ResolvedOn() || "-"}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Notified By</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Route Origin</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.notify_by}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{ticketDetails?.route_origin}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Bill</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Route Destination</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography
                  style={{
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                >
                  <a
                    href={ticketDetails?.bill}
                    target="_blank"
                    style={{
                      textDecoration: "none",
                      color:
                        ticketDetails?.bill === ""
                          ? color.bordercolor
                          : color.primary,
                      pointerEvents: ticketDetails?.bill === "" ? "none" : "",
                    }}
                  >
                    Download
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{ticketDetails?.route_destination}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Payment Status</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Ticket Status</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "0px 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.payment_status}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {ticketDetails?.status === 0 ? "Open" : "Resolved" || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Service Center</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Service Center No.</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.service_center}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{ticketDetails?.center_phone || "-"}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>
                  Service Centre Address
                </Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{ticketDetails?.center_location}</Typography>
              </Grid>
            </Grid>
          </Grid>
          <div id="bill">
            {ticketDetails?.bill && (
              <img
                style={{ width: "100%", marginTop: 15 }}
                src={ticketDetails?.bill || "-"}
                alt="bill"
              />
            )}
          </div>
          <Grid
            item
            xs={12}
            style={{ display: "flex", alignItems: "center", marginTop: 15 }}
          >
            <Typography variant="tableTitle">Vehicle Details</Typography>
          </Grid>
          <Grid
            container
            style={{
              border: `1px solid ${color.bordercolor}`,
              marginTop: 15,
            }}
          >
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Vehicle No.</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Fleet Name</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{vehicleDetails?.vehicle_no || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{vehicleDetails?.fleet_name || "-"}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Vehicle Type</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Vehicle Brand</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>
                  {vehicleDetails?.vehicle_type_id || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {vehicleDetails?.vehicle_brand_id || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Vehicle Model</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Initial Reading</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{vehicleDetails?.vehicle_model || "-"}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>
                  {vehicleDetails?.initial_reading || "-"}
                </Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>No. of Tyres</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Load Capacity</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                background: "#FBF8F8",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{vehicleDetails?.no_of_tyres}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography>{vehicleDetails?.load_capicity || "-"}</Typography>
              </Grid>
            </Grid>

            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "10px 14px 0",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>Alignments Claimed</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography fontWeight={"bold"}>RC Book Image</Typography>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                flexDirection: "row",
                padding: "0 14px 10px",
              }}
              xs={12}
            >
              <Grid item xs={6}>
                <Typography>{vehicleDetails?.alignment_count}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography
                  style={{
                    color: vehicleDetails?.rc_book
                      ? color.primary
                      : color.bordercolor,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={checkFunction}
                >
                  View
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Modal
            className={styles.modal}
            open={openCard}
            onClose={() => {
              setOpenCard(false);
            }}
          >
            <img
              style={{
                objectFit: "contain",
                borderRadius: 4,
                maxWidth: isTablet ? 550 : 330,
                maxHeight: isTablet ? 550 : 330,
              }}
              src={vehicleDetails.rc_book || "-"}
              alt={"Business PAN Card"}
            />
          </Modal>
        </div>
      )}
    </>
  );
}
