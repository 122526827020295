import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  useMediaQuery,
  useTheme,
  Grid,
  TextField,
  InputAdornment,
  IconButton,
  FormControl,
  Autocomplete,
  FormHelperText,
  Checkbox,
  Popper,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import useStyles from "./styles.js";
import { Close } from "@mui/icons-material";
import { color } from "../../../Config/theme";
import { isArray, isEmpty, isNull } from "lodash";
import { useSelector } from "react-redux";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import { getApiData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { toast } from "react-toastify";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import moment from "moment";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const errorObj = {
  dateErr: false,
  dateMsg: "",
  fleetErr: false,
  fleetMsg: "",
  vehicleErr: false,
  vehicleMsg: "",
  categoryErr: false,
  categoryMsg: "",
};

function MDateRange(props) {
  const {
    visible = false,
    handleModal = () => null,
    disableFleet = false,
    disableVehicle = false,
    disableDate = false,
    category = false,
    fleetId,
  } = props;
  const { token, fleetData, isOnline } = useSelector((state) => state.auth);
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);
  const [errObj, setErrObj] = useState(errorObj);
  const [vehicleList, setVehicleList] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [vehicle, setVehicle] = useState([]);
  const [checkAll, setCheckAll] = React.useState(false);
  const [categoryType, setCategoryType] = useState("");

  const [scrap, setScrap] = useState("");
  const [claim, setClaim] = useState("");
  const [Casing, setCasing] = useState("");

  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : md ? 450 : 800,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
  };

  function closeModel() {
    setFleet([]);
    setVehicle([]);
    setCategoryType("");
    handleModal("cancel");
    setDatePicker(null);
    setDatePicker1(null);
    setScrap("");
    setClaim("");
    setCasing("");
  }

  useEffect(() => {
    if (visible && disableFleet) {
      getVehicleist();
    }
  }, [disableFleet, visible]);

  useEffect(() => {
    if (isArray(fleet) && !isEmpty(fleet)) {
      getVehicleist();
    } else {
      setVehicle([]);
      setCheckAll(false);
    }
  }, [fleet]);

  useEffect(() => {
    if (isNull(datePicker)) {
      setDatePicker1(null);
    }
  }, [datePicker]);

  async function getVehicleist() {
    const array = [];
    isArray(fleet) &&
      !isEmpty(fleet) &&
      fleet.map((v) => {
        array.push(v.id);
      });

    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicleData}?fleet_id=${
          disableFleet ? fleetId : array
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setVehicleList(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
      // setPageLoad(false);
    } catch (error) {
      // setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function handleSave() {
    const fleetArray = [];
    isArray(fleet) &&
      !isEmpty(fleet) &&
      fleet.map((v) => {
        fleetArray.push(v.id);
      });

    const vehicleArray = [];
    isArray(vehicle) &&
      !isEmpty(vehicle) &&
      vehicle.map((v) => {
        vehicleArray.push(v.id);
      });

    handleModal(
      "download",
      disableFleet ? fleetId : fleetArray,
      vehicleArray,
      datePicker,
      disableVehicle
        ? isNull(datePicker1)
          ? moment(new Date()).format("yyyy-MM-DD")
          : datePicker1
        : datePicker1,
      checkAll,
      categoryType,
      scrap,
      claim,
      Casing
    );
    setTimeout(() => {
      setFleet([]);
      setVehicle([]);
      setCategoryType("");
      setDatePicker(null);
      setDatePicker1(null);
      setScrap("");
      setClaim("");
      setCasing("");
    }, 500);
  }

  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      setVehicle(vehicleList);
    } else {
      setVehicle([]);
    }
  };

  // function checkGetValidation() {
  //   const error = { ...errObj };
  //   let valid = true;

  //   if (isEmpty(fleet) || fleet === undefined || fleet === "") {
  //     valid = false;
  //     error.fleetErr = true;
  //     error.fleetMsg = "Please select the Fleet";
  //   }

  //   if (isEmpty(vehicle) || vehicle === undefined || vehicle === "") {
  //     valid = false;
  //     error.vehicleErr = true;
  //     error.vehicleMsg = "Please select the Vehicle No.";
  //   }
  //   if (!isNull(datePicker) && isNull(datePicker1)) {
  //     valid = false;
  //     error.dateErr = true;
  //     error.dateMsg = "Please select the Date";
  //   }
  //   setErrObj(error);
  //   if (valid) {
  //   }
  // }

  return (
    <Modal
      open={visible}
      onClose={() => closeModel()}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={styles.modalHeader}>
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              Download Report
            </Typography>
            <Close
              style={{ color: color.white, cursor: "pointer" }}
              onClick={() => closeModel()}
            />
          </div>
          <div style={{ padding: 20 }}>
            <Grid container alignItems={"center"} flexDirection="row">
              {!disableFleet && (
                <Grid
                  item
                  container
                  alignItems={"center"}
                  // wrap="nowrap"
                >
                  <Grid item md={3} xs={12}>
                    <CTypography required title={"Select Fleet"} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <FormControl fullWidth error={errObj.fleetErr}>
                      <Autocomplete
                        fullWidth
                        multiple
                        disableListWrap={true}
                        options={fleetData}
                        value={fleet?.label}
                        classes={{ input: `${styles.autocomplete}` }}
                        onChange={(v1, v) => {
                          setFleet(v);
                          setErrObj({
                            ...errObj,
                            fleetErr: false,
                            fleetMsg: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            style={{ fontSize: 1 }}
                            {...params}
                            className={styles.inputDivStyle}
                            placeholder={
                              isEmpty(fleet) ? "Search/Select Fleet" : null
                            }
                          />
                        )}
                        renderTags={(tagValue, getTagProps) => {
                          const renderTagsValue = tagValue
                            .map(function (elem) {
                              return elem.label;
                            })
                            .join(", ");
                          return disableVehicle && category ? (
                            <Typography
                              noWrap={true}
                              color="textPrimary"
                              style={{ fontSize: 16 }}
                            >
                              {renderTagsValue}
                            </Typography>
                          ) : (
                            renderTagsValue
                          );
                        }}
                      />
                      {errObj.fleetErr ? (
                        <FormHelperText>{errObj.fleetMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {!disableVehicle && (
                <Grid
                  item
                  container
                  alignItems={"center"}
                  marginTop={"20px"}
                  // wrap="nowrap"
                >
                  <Grid item md={3} xs={12}>
                    <CTypography required title={"Vehicle No"} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <FormControl fullWidth error={errObj.vehicleErr}>
                      <Autocomplete
                        fullWidth
                        multiple
                        disableCloseOnSelect
                        limitTags={3}
                        disableListWrap={true}
                        options={vehicleList}
                        size="small"
                        value={vehicle}
                        getOptionLabel={(option) => option.label}
                        onChange={(v1, v, reason) => {
                          if (reason === "selectOption") {
                            setVehicle(v);
                          } else if (reason === "removeOption") {
                            setCheckAll(false);
                            setVehicle(v);
                          } else if (reason === "clear") {
                            setVehicle([]);
                            setCheckAll(false);
                          }
                          setErrObj({
                            ...errObj,
                            vehicleErr: false,
                            vehicleMsg: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={styles.inputDivStyle}
                            placeholder={
                              isEmpty(vehicle)
                                ? "Search/Select Vehicle No."
                                : null
                            }
                          />
                        )}
                        PopperComponent={(param) => (
                          <Popper {...param}>
                            <Box
                              sx={{
                                backgroundColor: "white",
                                height: "45px",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Checkbox
                                checked={checkAll}
                                onChange={checkAllChange}
                                id="check-all1"
                                sx={{ marginRight: "8px" }}
                                onMouseDown={(e) => e.preventDefault()}
                              />
                              <Typography>Select All</Typography>
                            </Box>
                            <Divider />
                            <Box {...param} />
                          </Popper>
                        )}
                        renderOption={(props, option, { selected }) => (
                          <li {...props}>
                            <Checkbox
                              icon={icon}
                              checkedIcon={checkedIcon}
                              style={{ marginRight: 8 }}
                              checked={selected || checkAll}
                            />
                            {option?.label}
                          </li>
                        )}
                        renderTags={(tagValue, getTagProps) => {
                          let array = tagValue;
                          return (
                            <div
                              style={{ display: "flex", alignItems: "center" }}
                            >
                              {array.map((items, index) => {
                                const isTwo = index <= 1;
                                return isTwo ? (
                                  <Typography>
                                    {items?.label}
                                    {index !== array.length - 1 ? ", " : ""}
                                  </Typography>
                                ) : null;
                              })}
                              {array.length > 2 && (
                                <span
                                  style={{ color: color.placeholder }}
                                >{`+ ${array.length - 2} more`}</span>
                              )}
                            </div>
                          );
                        }}
                      />
                      {errObj.vehicleErr ? (
                        <FormHelperText>{errObj.vehicleMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {category && (
                <Grid
                  item
                  container
                  alignItems={"center"}
                  marginTop={"20px"}
                  // wrap="nowrap"
                >
                  <Grid item md={3} xs={12}>
                    <CTypography required title={"Category"} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <FormControl fullWidth error={errObj.categoryErr}>
                      <Select
                        fullWidth
                        displayEmpty
                        value={categoryType}
                        onChange={(e) => setCategoryType(e.target.value)}
                        style={categoryType === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Select Category
                        </MenuItem>
                        <MenuItem value={"Casing"}>Casing</MenuItem>
                        <MenuItem value={"Claim"}>Claim</MenuItem>
                        <MenuItem value={"On Wheel"}>On Wheel</MenuItem>
                        <MenuItem value={"Retreading"}>Retreading</MenuItem>
                        <MenuItem value={"Scrap"}>Scrap</MenuItem>
                        <MenuItem value={"Stock"}>Stock</MenuItem>
                      </Select>

                      {errObj.categoryErr ? (
                        <FormHelperText>{errObj.categoryMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              )}
              {categoryType === "Scrap" ? (
                <Grid
                  item
                  container
                  alignItems={"center"}
                  marginTop={"20px"}
                  // wrap="nowrap"
                >
                  <Grid item md={3} xs={12}>
                    <CTypography required title={"Scrap Status"} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <FormControl fullWidth error={errObj.categoryErr}>
                      <Select
                        fullWidth
                        displayEmpty
                        value={scrap}
                        onChange={(e) => setScrap(e.target.value)}
                        style={scrap === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Select Status
                        </MenuItem>
                        <MenuItem value={1}>Sold</MenuItem>
                        <MenuItem value={0}>Unsold</MenuItem>
                      </Select>

                      {errObj.categoryErr ? (
                        <FormHelperText>{errObj.categoryMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              ) : categoryType === "Claim" ? (
                <Grid
                  item
                  container
                  alignItems={"center"}
                  marginTop={"20px"}
                  // wrap="nowrap"
                >
                  <Grid item md={3} xs={12}>
                    <CTypography required title={"Claim Status"} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <FormControl fullWidth error={errObj.categoryErr}>
                      <Select
                        fullWidth
                        displayEmpty
                        value={claim}
                        onChange={(e) => setClaim(e.target.value)}
                        style={claim === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Select Status
                        </MenuItem>
                        <MenuItem value={0}>Pending</MenuItem>
                        <MenuItem value={1}>Accepted</MenuItem>
                        <MenuItem value={2}>Rejected</MenuItem>
                      </Select>

                      {errObj.categoryErr ? (
                        <FormHelperText>{errObj.categoryMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              ) : categoryType === "Casing" ? (
                <Grid
                  item
                  container
                  alignItems={"center"}
                  marginTop={"20px"}
                  // wrap="nowrap"
                >
                  <Grid item md={3} xs={12}>
                    <CTypography required title={"Casing Status"} />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <FormControl fullWidth error={errObj.categoryErr}>
                      <Select
                        fullWidth
                        displayEmpty
                        value={Casing}
                        onChange={(e) => setCasing(e.target.value)}
                        style={Casing === "" ? { color: "#A2A2A2" } : {}}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Select Status
                        </MenuItem>
                        <MenuItem value={0}>Pending</MenuItem>
                        <MenuItem value={1}>Accepted</MenuItem>
                        <MenuItem value={2}>Rejected</MenuItem>
                      </Select>

                      {errObj.categoryErr ? (
                        <FormHelperText>{errObj.categoryMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
            {!disableDate && (
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 20,
                }}
              >
                <Grid
                  item
                  container
                  alignItems={"center"}
                  // wrap="nowrap"
                >
                  <Grid item md={3} xs={12}>
                    <CTypography title="From Date" />
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disableFuture
                        views={["year", "month", "day"]}
                        value={datePicker}
                        onChange={(newValue) => {
                          setDatePicker(newValue);
                          setDisable(false);
                        }}
                        inputFormat="dd-MM-yyyy"
                        DialogProps={{ className: styles.datePicker }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD-MM-YYYY",
                              readOnly: true,
                            }}
                            InputProps={{
                              ...params.InputProps,
                              startAdornment: !isNull(datePicker) && (
                                <InputAdornment position="start">
                                  <IconButton
                                    style={{ lineHeight: 0 }}
                                    onClick={() => setDatePicker(null)}
                                  >
                                    <Close style={{ fontSize: 20 }} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            sx={{ svg: { color: color.primary } }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  alignItems={"center"}
                  marginTop={"20px"}
                  // wrap="nowrap"
                >
                  <Grid item md={3} xs={12}>
                    <Typography>To Date:</Typography>
                  </Grid>
                  <Grid item md={9} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        disabled={isNull(datePicker) ? true : disable}
                        minDate={datePicker}
                        views={["year", "month", "day"]}
                        disableFuture
                        value={datePicker1}
                        inputFormat="dd-MM-yyyy"
                        onChange={(newValue) => {
                          setDatePicker1(newValue);
                        }}
                        DialogProps={{ className: styles.datePicker }}
                        renderInput={(params) => (
                          <TextField
                            fullWidth
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD-MM-YYYY",
                            }}
                            InputProps={{
                              ...params.InputProps,
                              error: errObj.dateErr,
                              helperText: errObj.dateErr
                                ? errObj.dateMsg
                                : null,
                              startAdornment: !isNull(datePicker1) && (
                                <InputAdornment position="start">
                                  <IconButton
                                    style={{ lineHeight: 0 }}
                                    onClick={() => setDatePicker1(null)}
                                  >
                                    <Close style={{ fontSize: 20 }} />
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                            sx={{
                              svg: {
                                color:
                                  disable || isNull(datePicker)
                                    ? ""
                                    : color.primary,
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <div className={styles.modalMain}>
              <div style={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  disabled={
                    !isOnline
                      ? true
                      : disableDate
                      ? isEmpty(vehicle)
                      : disableFleet
                      ? isEmpty(vehicle)
                      : // ||(!isNull(datePicker) && isNull(datePicker1))
                      disableVehicle
                      ? isEmpty(fleet) ||
                        isEmpty(categoryType) ||
                        isNull(datePicker)
                      : isEmpty(fleet) || isEmpty(vehicle)
                    // ||(!isNull(datePicker) && isNull(datePicker1))
                  }
                  onClick={() => {
                    handleSave();
                  }}
                >
                  Download
                </Button>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                  onClick={closeModel}
                >
                  Cancel
                </Button>
              </div>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MDateRange;
