import React, { useEffect, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import GenerateTicket from "../../../Components/TabService/TabGenerateTicket/index";
import TabOngoingTickets from "../../../Components/TabService/TabOngoingTickets/index";
import TabResolvedTickets from "../../../Components/TabService/TabResolvedTickets/index";
import TabReports from "../../../Components/TabService/TabReports/index";
import TabTicketID from "../../../Components/TabService/TabTicketID/index.js";
import TabOutstanding from "../../../Components/TabService/TabOutstanding/index.js";
import TabNeedFollowUp from "../../../Components/TabService/TabNeedFollowUp/index.js";
import TabVerify from "../../../Components/TabService/TabVerify/index.js";
import {
  Box,
  Button,
  Grid,
  ListItemButton,
  Popover,
  Typography,
} from "@mui/material";
import Images from "../../../Config/Images";
import { isMobile, isTablet } from "react-device-detect";
import { ArrowBackOutlined, MoreVert, TuneOutlined } from "@mui/icons-material";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../../Components/ConfirmDialog";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { toast } from "react-toastify";
import useStyles from "./styles";
import { color } from "../../../Config/theme";

export default function TabServiceCentre(props) {
  useEffect(() => {
    document.title = Setting.page_name.ServiceCentre;
  }, []);
  const styles = useStyles();

  const { isOnline, userdata } = useSelector((state) => state.auth);
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [isEditData, setIsEditData] = useState(false);
  const [editData, setEditData] = useState({});
  const [ticketData, setTicketdata] = useState({});
  const [changeTab, setChangeTab] = useState("");
  const [from, setFrom] = useState("");

  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [selectedFile, setSlectedFile] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0);

  const [pageNoOT, setPageNoOT] = useState(1);
  const [recordOT, setRecordOT] = useState(10);
  const [filterOT, setFilterOT] = useState("");

  const [pageNoRT, setPageNoRT] = useState(1);
  const [recordRT, setRecordRT] = useState(10);
  const [filterRT, setFilterRT] = useState("");

  const [pageNoOP, setPageNoOP] = useState(1);
  const [recordOP, setRecordOP] = useState(10);

  const [pageNoNF, setPageNoNF] = useState(1);
  const [recordNF, setRecordNF] = useState(10);

  const [anchorEl, setAnchorEl] = useState(null);
  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.importservicecenter;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };
  function clearData() {
    setSlectedFile(null);
  }

  return (
    <>
      {changeTab === "" && !isKeyboardOpen && !isTablet && (
        <Box
          style={{
            display: "flex",
            flex: 1,
            flexDirection: "row",
            alignItems: "center",
            height: isMobile ? 30 : 40,
            position: "fixed",
            top: 14,
            left: 20,
            zIndex: 10,
          }}
        >
          <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
        </Box>
      )}
      {(changeTab === "TicketID" ||
        changeTab === "TabOutstanding" ||
        changeTab === "needfollowup") && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? {
                      backgroundColor: color.secondary,
                      marginBottom: 30,
                      top: 20,
                      left: 20,
                    }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      left: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}

      {changeTab === "GenerateTicket" && (
        <GenerateTicket
          handleClick={(v, data) => {
            if (v === "cancel") {
              setChangeTab("");
              setTicketdata({});
              setEditData({});
            } else if (v === "OngoingTickets") {
              setChangeTab("");
              setTicketdata({});
              setEditData({});
            }
          }}
          ticketData={ticketData}
          editData={editData}
        />
      )}
      {changeTab === "TicketID" && (
        <TabTicketID
          handleClick={(v, data) => {
            if (v === "cancle") {
              setChangeTab(null);
            } else if (v === "OngoingTickets") {
              setChangeTab("");
            } else if (v === "ResolvedTickets") {
              setChangeTab("");
            } else if (v === "Reports") {
              setChangeTab("Reports");
            }
          }}
          editData={editData}
        />
      )}
      {changeTab === "TabOutstanding" && (
        <TabOutstanding
          pageNo={pageNoOP}
          record={recordOP}
          handlePageNo={(page) => {
            setPageNoOP(page);
          }}
          handleRecord={(rec) => {
            setRecordOP(rec);
          }}
          handleClick={(v, data) => {
            if (v === "cancle") {
              setChangeTab("");
            } else if (v === "TicketID") {
              setChangeTab("TicketID");
              setEditData(data);
            }
          }}
          editData={editData}
        />
      )}
      {changeTab === "needfollowup" && (
        <TabNeedFollowUp
          pageNo={pageNoNF}
          record={recordNF}
          handlePageNo={(page) => {
            setPageNoNF(page);
          }}
          handleRecord={(rec) => {
            setRecordNF(rec);
          }}
          handleClick={(v, data, editData) => {
            if (v === "cancle") {
              setChangeTab(null);
            } else if (v === "GenerateTicket") {
              setChangeTab("GenerateTicket");
              setEditData(data);
              setIsEditData(editData);
            } else if (v === "OngoingTickets") {
              setChangeTab("");
            } else if (v === "ResolvedTickets") {
              setChangeTab("");
            } else if (v === "Reports") {
              setChangeTab("Reports");
            }
          }}
          from={from}
        />
      )}
      {changeTab === "" &&
        (isTablet ? (
          <div style={{ padding: 20 }}>
            <Grid
              container
              className={styles.container}
              style={{ padding: 20 }}
            >
              <Grid container wrap="nowrap">
                <Grid
                  item
                  xs={12}
                  sm={9}
                  md={9}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography variant="tableTitle">
                    {tabValue === 1
                      ? "Ongoing Tickets"
                      : tabValue === 2
                      ? "Resolved Tickets"
                      : tabValue === 3
                      ? "Reports"
                      : "Verify User"}
                  </Typography>
                </Grid>
                <Grid
                  item
                  container
                  sm={3}
                  gap={1.2}
                  wrap="nowrap"
                  justifyContent={"flex-end"}
                >
                  {userdata?.role !== "service_enginner" && (
                    <>
                      <Grid item>
                        <Button
                          variant="contained"
                          component="label"
                          style={{ backgroundColor: color.secondary }}
                        >
                          <input
                            type="file"
                            onChange={(e) => {
                              setSlectedFile(e.target.files[0]);
                              setConfirmDialog(true);
                            }}
                            onClick={(event) => {
                              event.target.value = null;
                            }}
                            accept=".csv"
                            hidden
                          />
                          Upload Center List
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{
                            backgroundColor: isOnline
                              ? color.secondary
                              : color.bordercolor,
                          }}
                          disabled={isOnline ? false : true}
                        >
                          <a
                            style={{
                              textDecoration: "none",
                              color: "white",
                              display: "flex",
                              textAlign: "center",
                            }}
                            href={Images.serviceCentercsv}
                            download={"service_center.csv"}
                          >
                            <img src={Images.Mdownload} alt="download" />
                          </a>
                        </Button>
                      </Grid>
                    </>
                  )}
                  <Grid item justifyContent={"flex-end"}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: color.white,
                        color: "#D8D8D8",
                      }}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                    </Button>
                    <Popover
                      onClose={() => setAnchorEl(null)}
                      anchorEl={anchorEl}
                      id={id}
                      open={pop}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <ListItemButton
                        selected={tabValue === 0}
                        onClick={() => {
                          setTabValue(0);
                          setAnchorEl(null);
                        }}
                      >
                        Verify User
                      </ListItemButton>
                      <ListItemButton
                        selected={tabValue === 1}
                        onClick={() => {
                          setPageNoOT(1);
                          setRecordOT(10);
                          setTabValue(1);
                          setFilterOT("");
                          setAnchorEl(null);
                        }}
                      >
                        Ongoing Tickets
                      </ListItemButton>
                      <ListItemButton
                        selected={tabValue === 2}
                        onClick={() => {
                          setPageNoRT(1);
                          setRecordRT(10);
                          setTabValue(2);
                          setFilterRT("");
                          setAnchorEl(null);
                        }}
                      >
                        Resolved Tickets
                      </ListItemButton>
                      <ListItemButton
                        selected={tabValue === 3}
                        onClick={() => {
                          setTabValue(3);
                          setAnchorEl(null);
                        }}
                      >
                        Reports
                      </ListItemButton>
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
              {tabValue === 0 && (
                <TabVerify
                  handleClick={(v, data) => {
                    if (v === "GenerateTicket") {
                      setChangeTab("GenerateTicket");
                      setIsEditData(false);
                      setTicketdata(data);
                    } else if (v === "OngoingTickets") {
                      setPageNoOT(1);
                      setRecordOT(10);
                      setTabValue(1);
                    } else if (v === "ResolvedTickets") {
                      setPageNoRT(1);
                      setRecordRT(10);
                      setTabValue(2);
                    } else if (v === "Reports") {
                      setChangeTab("Reports");
                    } else if (v === "needfollowup") {
                      setChangeTab("needfollowup");
                    } else if (v === "TabOutstanding") {
                      setPageNoOP(1);
                      setRecordOP(10);
                      setChangeTab("TabOutstanding");
                    }
                  }}
                />
              )}
              {tabValue === 1 && (
                <TabOngoingTickets
                  pageNo={pageNoOT}
                  record={recordOT}
                  filter={filterOT}
                  handlePageNo={(page) => {
                    setPageNoOT(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordOT(rec);
                  }}
                  handleFilter={(flt) => {
                    setFilterOT(flt);
                  }}
                  handleClick={(v, data, editData) => {
                    if (v === "cancle") {
                      setChangeTab(null);
                    } else if (v === "GenerateTicket") {
                      setChangeTab("GenerateTicket");
                      setEditData(data);
                      setIsEditData(editData);
                    } else if (v === "ResolvedTickets") {
                      setChangeTab("");
                    } else if (v === "Reports") {
                      setChangeTab("Reports");
                    }
                  }}
                  from={from}
                />
              )}
              {tabValue === 2 && (
                <TabResolvedTickets
                  pageNo={pageNoRT}
                  record={recordRT}
                  handlePageNo={(page) => {
                    setPageNoRT(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordRT(rec);
                  }}
                  handleFilter={(flt) => {
                    setFilterRT(flt);
                  }}
                  handleClick={(v, data) => {
                    if (v === "cancle") {
                      setChangeTab(null);
                    } else if (v === "OngoingTickets") {
                      setChangeTab("OngoingTickets");
                    } else if (v === "Reports") {
                      setChangeTab("Reports");
                    } else if (v === "TicketID") {
                      setChangeTab("TicketID");
                      setEditData(data);
                    }
                  }}
                />
              )}
              {tabValue === 3 && (
                <TabReports
                  handleClick={(v, data) => {
                    if (v === "cancle") {
                      setChangeTab(null);
                    } else if (v === "OngoingTickets") {
                      setChangeTab("OngoingTickets");
                    } else if (v === "ResolvedTickets") {
                      setChangeTab("ResolvedTickets");
                    }
                  }}
                />
              )}
            </Grid>
          </div>
        ) : (
          <div style={{ padding: 10 }}>
            <Grid
              container
              className={styles.container}
              style={{ padding: 10 }}
            >
              <Grid container xs={12} wrap="nowrap">
                <Grid
                  item
                  container
                  style={{ display: "flex", alignItems: "center" }}
                  backgroundColor={color.secondary}
                  padding={"10px"}
                  color={color.white}
                  borderRadius={1}
                >
                  <Grid item>
                    <Typography variant="tableTitle">Service Centre</Typography>
                  </Grid>

                  <Grid item marginLeft={"auto"}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#BBBBBB",
                        color: "#000",
                      }}
                      onClick={(event) => {
                        setAnchorEl(event.currentTarget);
                      }}
                    >
                      <MoreVert />
                    </Button>
                    <Popover
                      onClose={() => setAnchorEl(null)}
                      anchorEl={anchorEl}
                      id={id}
                      open={pop}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <ListItemButton
                        selected={tabValue === 0}
                        onClick={() => {
                          setTabValue(0);
                          setAnchorEl(null);
                        }}
                      >
                        Verify User
                      </ListItemButton>
                      <ListItemButton
                        selected={tabValue === 1}
                        onClick={() => {
                          setPageNoOT(1);
                          setRecordOT(10);
                          setTabValue(1);
                          setFilterOT("");
                          setAnchorEl(null);
                        }}
                      >
                        Ongoing Tickets
                      </ListItemButton>
                      <ListItemButton
                        selected={tabValue === 2}
                        onClick={() => {
                          setPageNoRT(1);
                          setRecordRT(10);
                          setTabValue(2);
                          setFilterRT("");
                          setAnchorEl(null);
                        }}
                      >
                        Resolved Tickets
                      </ListItemButton>
                      <ListItemButton
                        selected={tabValue === 3}
                        onClick={() => {
                          setTabValue(3);
                          setAnchorEl(null);
                        }}
                      >
                        Reports
                      </ListItemButton>
                    </Popover>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container paddingTop={"10px"}>
                <Typography variant="tableTitle">
                  {tabValue === 1
                    ? "Ongoing Tickets"
                    : tabValue === 2
                    ? "Resolved Tickets"
                    : tabValue === 3
                    ? "Reports"
                    : "Verify User"}
                </Typography>
              </Grid>
              {tabValue === 0 && userdata?.role !== "service_enginner" && (
                <Grid
                  item
                  container
                  marginTop={"10px"}
                  justifyContent={"space-between "}
                >
                  <Grid item>
                    <Button
                      variant="contained"
                      component="label"
                      style={{ backgroundColor: color.secondary }}
                    >
                      <input
                        type="file"
                        onChange={(e) => {
                          setSlectedFile(e.target.files[0]);
                          setConfirmDialog(true);
                        }}
                        onClick={(event) => {
                          event.target.value = null;
                        }}
                        accept=".csv"
                        hidden
                      />
                      Upload Center List
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                      }}
                      disabled={isOnline ? false : true}
                    >
                      <a
                        style={{
                          textDecoration: "none",
                          color: "white",
                          display: "flex",
                          textAlign: "center",
                        }}
                        href={Images.serviceCentercsv}
                        download={"service_center.csv"}
                      >
                        <img src={Images.Mdownload} alt="download" />
                      </a>
                    </Button>
                  </Grid>
                </Grid>
              )}

              {tabValue === 0 && (
                <TabVerify
                  handleClick={(v, data) => {
                    if (v === "GenerateTicket") {
                      setChangeTab("GenerateTicket");
                      setIsEditData(false);
                      setTicketdata(data);
                    } else if (v === "OngoingTickets") {
                      setPageNoOT(1);
                      setRecordOT(10);
                      setTabValue(1);
                    } else if (v === "ResolvedTickets") {
                      setPageNoRT(1);
                      setRecordRT(10);
                      setTabValue(2);
                    } else if (v === "Reports") {
                      setChangeTab("Reports");
                    } else if (v === "needfollowup") {
                      setPageNoNF(1);
                      setRecordNF(10);
                      setChangeTab("needfollowup");
                    } else if (v === "TabOutstanding") {
                      setPageNoOP(1);
                      setRecordOP(10);
                      setChangeTab("TabOutstanding");
                    }
                  }}
                />
              )}
              {tabValue === 1 && (
                <TabOngoingTickets
                  pageNo={pageNoOT}
                  record={recordOT}
                  filter={filterOT}
                  handlePageNo={(page) => {
                    setPageNoOT(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordOT(rec);
                  }}
                  handleFilter={(flt) => {
                    setFilterOT(flt);
                  }}
                  handleClick={(v, data, editData) => {
                    if (v === "cancle") {
                      setChangeTab("");
                    } else if (v === "GenerateTicket") {
                      setChangeTab("GenerateTicket");
                      setEditData(data);
                      setIsEditData(editData);
                    }
                  }}
                  from={from}
                />
              )}
              {tabValue === 2 && (
                <TabResolvedTickets
                  pageNo={pageNoRT}
                  record={recordRT}
                  filter={filterRT}
                  handlePageNo={(page) => {
                    setPageNoRT(page);
                  }}
                  handleRecord={(rec) => {
                    setRecordRT(rec);
                  }}
                  handleFilter={(flt) => {
                    setFilterRT(flt);
                  }}
                  handleClick={(v, data) => {
                    if (v === "cancle") {
                      setChangeTab("");
                    } else if (v === "TicketID") {
                      setChangeTab("TicketID");
                      setEditData(data);
                    }
                  }}
                />
              )}
              {tabValue === 3 && <TabReports />}
            </Grid>
          </div>
        ))}
      <ConfirmDialog
        title={`Are you sure you want to Upload Center List?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            handleOnSubmit();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </>
  );
}
