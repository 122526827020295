import React, { useEffect } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { useState } from "react";
import TyreManagement from "../TyreManagement/index.js";
import VehicleDetailTable from "../vehicleDetailsTable/index.js";
import AddTyre from "../../TyreHistory/AddTyre/index.js";
import RemoveTyre from "../../TyreHistory/RemoveTyre/index.js";
import SwitchTyre from "../../TyreHistory/SwitchTyre/index.js";
import TyreDetails from "../TyreDetails/index.js";

export default function VehicleDetail(props) {
  const { vehicleDetails, handleClick = () => null } = props;
  const styles = useStyles();

  const [changeTab, setChangeTab] = useState("");
  const [tyreData, setTyreData] = useState({});

  const [fitmentReading, setFitmentReading] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [filter, setFilter] = useState("");

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [changeTab]);

  return (
    <div>
      <Grid container id="jump_to_me">
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator="›">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="/dashboard/FleetManagement"
              className={styles.linkStyle}
            >
              Fleet Management
            </Link>
            <Link
              underline="hover"
              color="inherit"
              className={styles.linkStyle}
              onClick={() => {
                handleClick({
                  id: vehicleDetails?.fleet_id,
                  name: vehicleDetails?.fleet_name,
                });
              }}
              href="#"
            >
              {vehicleDetails?.fleet_name || ""}
            </Link>
            {changeTab === "addTyre" ||
            changeTab === "removeTyre" ||
            changeTab === "switchTyre" ||
            changeTab === "tyreDetails" ? (
              <Link
                underline="hover"
                color="inherit"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("");
                }}
                href="#"
              >
                {vehicleDetails?.vehicle_no || ""}
              </Link>
            ) : (
              <Typography className={styles.linkStyle}>
                {vehicleDetails?.vehicle_no || ""}
              </Typography>
            )}
            {changeTab === "addTyre" && (
              <Typography className={styles.linkStyle}>Add Tyre</Typography>
            )}
            {changeTab === "removeTyre" && (
              <Typography className={styles.linkStyle}>Remove Tyre</Typography>
            )}
            {changeTab === "switchTyre" && (
              <Typography className={styles.linkStyle}>Switch Tyre</Typography>
            )}
            {changeTab === "tyreDetails" && (
              <Typography className={styles.linkStyle}>
                {tyreData?.tyre_serial_no}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "addTyre" ? (
        <AddTyre
          vehicleDetails={vehicleDetails}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("");
            }
          }}
          editData={tyreData}
        />
      ) : changeTab === "removeTyre" ? (
        <RemoveTyre
          vehicleDetails={vehicleDetails}
          fitmentReading={fitmentReading}
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
            }
          }}
        />
      ) : changeTab === "switchTyre" ? (
        <SwitchTyre
          tyreDetails={tyreData}
          vehicleDetails={vehicleDetails}
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
            }
          }}
        />
      ) : changeTab === "tyreDetails" ? (
        <TyreDetails tyreData={tyreData} />
      ) : (
        <>
          <VehicleDetailTable vehicleData={vehicleDetails} />
          <TyreManagement
            vehicleData={vehicleDetails}
            pageNo={pageNo}
            record={record}
            filter={filter}
            handlePageNo={(page) => {
              setPageNo(page);
            }}
            handleRecord={(rec) => {
              setRecord(rec);
            }}
            handleFilter={(flt) => {
              setFilter(flt);
            }}
            handleClick={(type, data) => {
              if (type === "addTyre") {
                setChangeTab("addTyre");
              } else if (type === "removeTyre") {
                setChangeTab("removeTyre");
                setFitmentReading(data);
              } else if (type === "switchTyre") {
                setChangeTab("switchTyre");
              } else if (type === "tyreDetails") {
                setChangeTab("tyreDetails");
              }
              setTyreData(data);
            }}
          />
        </>
      )}
    </div>
  );
}
