import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    // "@media (max-width:425px)": {
    //   padding: "10px 10px 10px 10px",
    // },
    boxSizing: "border-box",
    // overflow: "hidden !important",
    // "@media (max-device-width:870px)": {
    //   marginBottom: "4vw",
    // },
    // "@media (max-device-width:425px)": {
    //   marginBottom: "4vh",
    // },
  },
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    overflow: "hidden",

    backgroundColor: color.white,
    width: "100%",
    border: `1px solid ${color.bordercolor}`,
  },
  header: {
    paddingBottom: 30,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
  },
}));
export default useStyles;
