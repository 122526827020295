import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  fleetMain: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },

  inputFieldStyle: {
    marginBottom: 0,
    marginTop: 0,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
  customtableMobile: {
    "& tr td": {
      border: "none !important",
      padding: 0,
      color: "#666666CC",
      width: "50%",
    },
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: "#EEF8FF",
      },
    },
  },
}));
export default useStyles;
