import React, { useEffect, useState, useRef } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { Box } from "@mui/system";
import FUserManagement from "../../../Components/Fleet/FUserManagement/index.js";
import ManageAllocate from "../../../Components/UserManagement/ManageAllocate/index.js";
import ManagePrivilege from "../../../Components/UserManagement/ManagePrivilege/index.js";
import AddUser from "../../../Components/UserManagement/AddUser/index.js";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";

export default function UserManagement() {
  const styles = useStyles();
  const { isOnline } = useSelector((state) => state.auth);
  const [userData, setUserData] = useState({});
  const [changeTab, setChangeTab] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);

  const [pageMA, setPageMA] = useState(1);
  const [recordMA, setRecordMA] = useState(10);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [reportLoader, setReportLoader] = useState(false);
  const [search, setSearch] = useState("");
  const [searchMA, setSearchMA] = useState("");

  const userManagement = useRef();
  const manageAllocate = useRef();

  useEffect(() => {
    document.title = Setting.page_name.UserManagement;
  }, []);

  return (
    <div className={styles.card}>
      <Grid container>
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator="›">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            {changeTab === "addUser" ? (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("");
                  setUserData({});
                }}
              >
                User Management
              </Link>
            ) : (
              <Typography className={styles.linkStyle}>
                User Management
              </Typography>
            )}
            {changeTab === "addUser" && (
              <Typography className={styles.linkStyle}>
                {!isEmpty(userData) ? "Edit User" : "Add User"}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>

      {changeTab === "addUser" ? (
        <AddUser
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("");
              setUserData({});
            }
          }}
          editData={userData}
        />
      ) : (
        <div className={styles.container}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="tableTitle">User Management</Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: sm ? "unset" : "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setChangeTab("addUser");
                }}
              >
                Add User
              </Button>
              {tabValue !== 2 && (
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disabled={isOnline ? false : true || reportLoader}
                  onClick={() => {
                    if (tabValue === 0) {
                      userManagement?.current?.downloadFUserData(1);
                    }
                    if (tabValue === 1) {
                      manageAllocate?.current?.downloadManageAllocateData(1);
                    }
                  }}
                >
                  {reportLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    "Download"
                  )}
                </Button>
              )}
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
            <Tabs
              value={tabValue}
              onChange={(v, b) => {
                setPageNo(1);
                setRecord(10);
                setPageMA(1);
                setRecordMA(10);
                setTabValue(b);
              }}
              variant="scrollable"
            >
              <Tab className={styles.tabBtn} label="User List" />
              <Tab className={styles.tabBtn} label="Manage Allocated Fleet" />
              <Tab className={styles.tabBtn} label="Manage Privileges" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <FUserManagement
              ref={userManagement}
              pageNo={pageNo}
              record={record}
              search={search}
              handleSearch={(search) => {
                setSearch(search);
              }}
              from="UserMangement"
              handleRecord={(rec) => {
                setRecord(rec);
              }}
              handleLoader={(v) => {
                setReportLoader(v);
              }}
              handlePageNo={(page) => {
                setPageNo(page);
              }}
              handleClick={(type, data) => {
                if (type === "editUser") {
                  setChangeTab("addUser");
                }
                setUserData(data);
              }}
            />
          )}
          {tabValue === 1 && (
            <ManageAllocate
              ref={manageAllocate}
              pageNo={pageMA}
              record={recordMA}
              search={searchMA}
              handleSearch={(search) => {
                setSearchMA(search);
              }}
              handleRecord={(rec) => {
                setRecordMA(rec);
              }}
              handleLoader={(v) => {
                setReportLoader(v);
              }}
              handlePageNo={(page) => {
                setPageMA(page);
              }}
            />
          )}
          {tabValue === 2 && <ManagePrivilege />}
        </div>
      )}
    </div>
  );
}
