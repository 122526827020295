import { createTheme } from "@mui/material/styles";
import red from "@mui/material/colors/red";
import { isMobile, isTablet } from "react-device-detect";
// import createBreakpoints from "@material-ui/core/styles/createBreakpoints";

// const breakpoints = createBreakpoints({});

// export const fonts = {
//   mont: "Mont-Regular",
//   bold: "Mont-Bold",
//   semiBold: "Mont-SemiBold",
// };

// const Mont = {
//   fontFamily: fonts.mont,
//   fontStyle: "normal",
//   fontDisplay: "swap",
//   fontWeight: 400,
//   src:
//     "url('/assets/fonts/Mont-Regular.ttf') format('ttf'), url('/assets/fonts/Mont-Regular.woff') format('woff')",
// };

export const color = {
  primary: "#3598DC",
  secondary: "#364150",
  white: "#ffff",
  darkGreen: "#264653",
  black: "rgba(0, 0, 0, 0.87)",
  lightOrange: "#F4A261",
  disable: "#00000061",
  green: "#34AB70",
  blue: "#062F6D",
  offWhite: "#E8E8E8",
  bordercolor: "#D8D8D8",
  placeholder: "#9ca3af",
  shadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
};

let theme = createTheme();
// Create a theme instance.
theme = createTheme(theme, {
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1200,
      xl: 1536,
    },
  },
  palette: {
    primary: {
      main: `${color.primary}`,
    },
    secondary: {
      main: `${color.secondary}`,
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#FFFFFF",
    },
  },
  components: {
    MuiGrid: {
      styleOverrides: {
        container: {
          margin: "0px auto",
        },
        root: {
          maxWidth: "unset !important",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          boxShadow: "0px 3px 6px #00000052",
          fontFamily: "ManropeRegular !important",
          textTransform: "none",
          padding: "8px 18px",
          fontSize: "14px",
          fontWeight: "500",
          color: color.white,
          height: 40,
          minWidth: "unset",
          whiteSpace: "nowrap",
          "&:disabled": {
            color: "rgb(0 0 0 / 50%) !important",
          },
          "@media (max-width: 768px)": {
            fontSize: "12px !important",
            minWidth: "unset !important",
            padding: "8px 10px",
          },
        },
        containedPrimary: {
          color: `${color.white}`,
          backgroundColor: color.primary,
          "&:hover": {},
        },
        outlinedPrimary: {
          border: `1px solid ${color.primary}`,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          fontFamily: "ManropeRegular !important",
          "&.MuiPaginationItem-root": {
            "&.Mui-selected": {
              backgroundColor: `${color.white} !important`,
              borderColor: color.primary,
            },
            "&.Mui-disabled": {
              backgroundColor: "#BBBBBB",
            },
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          // marginTop: 4,
          // marginBottom: 14,
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: "0px !important",
          color: `${color.primary}`,
          position: "absolute",
          bottom: -20,
          margin: 0,
          fontFamily: "ManropeRegular",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          fontFamily: "ManropeRegular !important",
          padding: "0px",
          height: 24,
          fontSize: 16,
          minHeight: "1.3375em !important",
          ":-webkit-autofill": {
            WebkitBoxShadow: "0 0 0 1000px white inset",
          },
        },
        root: {
          padding: isTablet ? 8 : "10px 14px",
          // border: `1px solid ${color.primary}`,
          "&.Mui-focused": {
            border: "0px !important",
          },
          "& > textarea": {
            padding: 0,
            textSizeAdjust: "100%",
          },
        },
        notchedOutline: {
          borderColor: color.bordercolor,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: "ManropeRegular !important",
          letterSpacing: "0.6px",
          [theme.breakpoints.down("lg")]: {
            fontSize: 14,
          },
          "@media (max-width: 768px)": {
            fontSize: 12,
          },
        },
        subtitle1: {
          fontSize: 28,
          fontWeight: "700",
          fontFamily: "ManropeRegular !important",
          letterSpacing: 1,
        },
        tableTitle: {
          fontWeight: "700",
          "@media (max-width: 768px)": {
            fontSize: "14px !important",
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          fontFamily: "ManropeRegular !important",
          color: color.black,
        },
        icon: {
          // position: "unset",
          marginRight: isTablet || isMobile ? 0 : 5,
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        root: {
          height: "4px !important",
          backgroundColor: `${color.secondary}`,
          "&.Mui-active": {
            backgroundColor: `${color.primary}`,
          },
          "&.Mui-completed": {
            backgroundColor: `${color.primary}`,
          },
        },
        line: {
          border: "0px !important",
        },
      },
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: "0px !important",
          margin: "0px !important",
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        iconContainer: {
          padding: "0px !important",
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          height: "45px !important",
          width: "45px !important",
          color: "#0000",
          border: "1px solid #1f4771",
          borderRadius: "23px !important",

          "&.Mui-active": {
            backgroundColor: `${color.white}`,
            border: "0px !important",
            "& > text": {
              fill: "#FFF",
            },
          },
          "&.Mui-completed": {
            backgroundColor: `${color.white}`,
            border: "0px !important",
          },
          [theme.breakpoints.down("md")]: {
            height: "35px !important",
            width: "35px !important",
            borderRadius: "18px !important",
          },
          [theme.breakpoints.down("sm")]: {
            height: "30px !important",
            width: "30px !important",
            borderRadius: "15px !important",
          },
        },
        text: {
          fontFamily: "ManropeRegular !important",
          fill: `${color.primary}`,

          "&.Mui-active": {
            fill: `${color.white}`,
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: "0px !important",
          paddingBottom: "0px !important",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: "ManropeRegular !important",
          color: "#3A5B7E",
          fontSize: "14px !important",
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          "&.MuiPaginationItem-icon": {
            // "&.Mui-selected": {
            //   color: color.white,
            // },
            // "&.Mui-disabled": {
            //   color: color.white,
            // },
          },
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          padding: "0px 9px !important",
          "&.Mui-disabled": {
            color: "rgba(52,86,123,0.5)",
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
        bar: {
          borderRadius: 4,
          backgroundColor: `${color.primary} !important`,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          padding: "12px 45px",
          textTransform: "capitalize",
          fontWeight: "700",
          letterSpacing: "0.6px",
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          padding: "8px",
          maxHeight: "40vh",
        },
      },
    },
    MuiDataGrid: {
      styleOverrides: {
        root: {
          fontFamily: "ManropeRegular !important",
          "& .MuiTypography-root": {
            fontSize: "0.875rem",
          },
          "& .MuiDataGrid-columnHeader": {
            "& .MuiDataGrid-iconButtonContainer": {
              visibility: "visible",
              width: "auto",
            },
          },
          "& .MuiDataGrid-sortIcon": {
            opacity: "0.5 !important",
          },
          "& .MuiDataGrid-columnHeader--sorted": {
            "& .MuiDataGrid-sortIcon": {
              opacity: "1 !important",
              backgroundColor: "rgba(0,0,0,0.15)",
              borderRadius: 50,
              padding: 2,
            },
          },
        },
        columnHeaders: {
          backgroundColor: "#EEF8FF",
          fontWeight: "600",
        },
        columnHeaderTitle: {
          fontWeight: "600",
          letterSpacing: "0.6px",
          "@media (max-width: 768px)": {
            fontSize: "14px !important",
          },
        },
        cellContent: {
          cursor: "pointer",
          "@media (max-width: 768px)": {
            fontSize: "12px !important",
          },
        },
        cell: {
          padding: 10,
        },
        row: {
          cursor: "pointer",
        },
      },
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        separator: {
          color: color.primary,
          marginLeft: "12px",
          marginRight: "12px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          fontFamily: "ManropeRegular !important",
          border: `1px solid ${color.bordercolor} !important`,
          width: "25%",
          "@media (max-width: 768px)": {
            fontSize: "12px !important",
            padding: 10,
          },
        },
        head: {
          fontWeight: "bold",
          backgroundColor: "#EEF8FF",
        },
      },
    },
    MuiImageListItem: {
      styleOverrides: {
        root: {
          cursor: "pointer",
          transition: "transform 0.15s ease-in-out",
          "&:hover": { transform: "scale3d(0.9, 0.9, 0.9)" },
          "&$selected": { backgroundColor: "blue" },
        },
      },
    },
    MuiImageListItemBar: {
      styleOverrides: {
        titleWrap: {
          padding: 4,
        },
        root: {
          fontFamily: "ManropeRegular !important",
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          "& .MuiOutlinedInput-root": {
            padding: "2px 10px",
            fontSize: "14px !important",
            fontFamily: "ManropeRegular !important",
          },
        },
        listbox: {
          padding: 8,
          "&::-webkit-scrollbar": {
            display: "none !important",
          },
        },
        option: {
          fontSize: "14px !important",
          fontFamily: "ManropeRegular !important",
          fontWeight: 400,
          color: "#3A5B7E",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontFamily: "ManropeRegular !important",
          borderRadius: 2,
          padding: 8,
          [theme.breakpoints.down("sm")]: {
            maxWidth: 150,
          },
        },
      },
    },
    MuiYearPicker: {
      styleOverrides: {
        root: {
          "& .PrivatePickersYear-root button": {
            fontFamily: "ManropeRegular !important",
          },
        },
      },
    },
    //   // MuiCssBaseline: {
    //   //   "@global": {
    //   //     "@font-face": [Mont, montSemiBold, montBold],
    //   //   },
    //   // },
  },
});

export default theme;
