import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme.js";

const useStyles = makeStyles((theme) => ({
  inputFieldStyle: {
    marginBottom: 0,
    marginTop: 0,
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  dataMain: {
    height: 500,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
