import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Image from "../../../Config/Images";
import "./styles.css";
import { useState } from "react";
import { isEmpty } from "lodash";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../../Redux/reducers/auth/actions";
// import tyre from "../../../Assets/Lottie/tyre.json";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";
import moment from "moment";

const errorObj = {
  useIdErr: false,
  passErr: false,
  userIdMsg: "",
  passMsg: "",
};

export default function Login() {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);
  const [showPassword, setShowPassword] = useState(false);

  const { useruuid } = useSelector((state) => state.auth);
  const { setUserData, setToken } = authActions;
  const navigate = useNavigate();

  useEffect(() => {
    document.title = Setting.page_name.Login;
  }, []);

  const handleSubmit = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.login;
    const params = {
      "LoginForm[user_name]": userId,
      "LoginForm[password]": password,
      "LoginForm[uuid]": useruuid,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.status) {
        // toast.success(resp?.message);
        dispatch(setUserData(resp?.data));
        dispatch(setToken(resp?.data?.access_token));
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  const checkValidation = () => {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(userId)) {
      valid = false;
      error.useIdErr = true;
      error.userIdMsg = "Please enter User ID";
    }

    if (isEmpty(password)) {
      valid = false;
      error.passErr = true;
      error.passMsg = "Please enter Password";
    } else if (password.length < 6 || password.length > 15) {
      valid = false;
      error.passErr = true;
      error.passMsg = "The password length must Between 6 to 15 Character";
    }
    setErrObj(error);

    if (valid) {
      setErrObj(errorObj);
      handleSubmit();
    }
  };

  function checkTime() {
    const today = moment();
    var beginningTime = moment("8:30am", "hh:mm A");
    var endTime = moment("7:30pm", "hh:mm A");

    const isStart = beginningTime.isBefore(today);
    const isEnd = endTime.isAfter(today);

    if (isStart && isEnd) {
      handleSubmit();
    } else {
      if (!isStart) {
        toast.error("Please try After 8:30 AM");
      } else {
        toast.error("Can't login After 7:30 PM");
      }
    }
  }

  const clearData = () => {
    setUserId("");
    setPassword("");
  };

  return isMobile || isTablet ? (
    <Grid
      container
      style={{
        overflow: "auto",
        margin: 0,
        height: "100vh",
        background: !isMobile
          ? `linear-gradient(to right,  #4573B8 40%, white 10%,white 50%,white 50%,white 75%,white 75%)`
          : `linear-gradient(to bottom,  #4573B8 40%, white 10%,white 50%,white 50%,white 75%,white 75%)`,
        display: "flex",
        alignItems: isMobile ? "unset" : "center",
        justifyContent: "center",
      }}
    >
      {/* {isMobile ? (
        <div
          style={{
            position: "absolute",
            top: "5%",
            width: "50%",
          }}
        >
          <img
            src={Image.text}
            style={{
              objectFit: "contain",
              height: "auto",
              width: "100%",
            }}
            alt="Go the extra mile"
          />
        </div>
      ) : null} */}
      {/* <Button
          className="btn"
          style={{
            backgroundColor: "#4573B8",
            position: "absolute",
            right: 40,
            top: 20,
            height: 60,
          }}
          onClick={() => {
            window.open("https://fleetkaptan.com/");
          }}
        >
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: tyre,
            }}
          />
          <span style={{ marginLeft: 8 }}>www.fleetkaptan.com</span>
        </Button> */}

      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "unset",
        }}
      >
        <div
          style={{
            width: isMobile ? "90%" : "40%",
            border: "none",
            boxShadow: isMobile
              ? "none"
              : "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
            padding: 20,
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
          }}
        >
          <img
            src={Image.text}
            style={{
              objectFit: "contain",
              maxWidth: isMobile ? "50%" : "80%",
              alignSelf: "center",
              height: "auto",
            }}
            alt="Go the extra mile"
          />

          {isMobile ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                width: "100%",
              }}
            >
              <img
                src={Image.loginImg}
                style={{
                  objectFit: "contain",
                  width: "80%",
                  height: "auto",
                  bottom: 0,
                  left: 0,
                }}
                alt="background_image"
              />
            </div>
          )}
        </div>
        <div
          variant="outlined"
          style={{
            width: isMobile ? "90%" : "60%",
            border: "none",
            boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Grid
            item
            style={{
              width: "100%",
              padding: 20,
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              container
              style={{
                marginBottom: 0,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={Image.frame1}
                height={isTablet ? 80 : 60}
                alt="logo"
                style={{ cursor: "pointer", marginBottom: 15 }}
                onClick={() => window.open("https://fleetkaptan.com/")}
              />
              {/* <Typography
                style={{ width: "unset", fontWeight: "bold", fontSize: 20 }}
              >
                Fleet Kaptan
              </Typography> */}
            </Grid>
            {/* <Typography
              style={{
                fontWeight: 700,
                fontSize: "30px",
                textAlign: "center",
              }}
            >
              Login
            </Typography> */}
            {/* <div>
              <Typography
                component="h5"
                variant="h6"
                fontWeight="bold"
                style={{
                  color: "#000000",
                  opacity: 0.5,
                  textAlign: "center",
                }}
              >
                Welcome!
              </Typography>
            </div> */}
            <div className="txtInp">
              <Typography
                style={{
                  color: "#4573B8",
                  fontWeight: "bold",
                  marginBottom: 8,
                }}
              >
                User ID
              </Typography>
              <TextField
                value={userId}
                margin="normal"
                required
                style={{
                  margin: 0,
                  marginBottom: errObj.useIdErr ? 30 : 20,
                }}
                placeholder="Please enter your User ID"
                id="email"
                name="Text"
                autoFocus
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    document.querySelector("#password").focus();
                  }
                }}
                onChange={(e) => {
                  setUserId(e.target.value.trim());
                  setErrObj({ ...errObj, useIdErr: false, userIdMsg: "" });
                }}
                inputProps={{ maxLength: 11 }}
                error={errObj.useIdErr}
                helperText={errObj.useIdErr ? errObj.userIdMsg : null}
              />
              <Typography
                style={{
                  color: "#4573B8",
                  fontWeight: "bold",
                  marginBottom: 8,
                }}
              >
                Password
              </Typography>
              <FormControl
                fullWidth
                error={errObj.passErr}
                style={{ marginBottom: errObj.passErr ? 20 : 10 }}
              >
                <OutlinedInput
                  value={password}
                  margin="normal"
                  required
                  type={showPassword ? "text" : "password"}
                  style={{
                    width: "100%",
                    margin: 0,
                  }}
                  error={errObj.passErr}
                  helperText={errObj.passErr ? errObj.passMsg : null}
                  name="password"
                  placeholder="Please enter your Password"
                  id="password"
                  autoComplete="current-password"
                  onChange={(e) => {
                    setPassword(e.target.value.trim());
                    setErrObj({ ...errObj, passErr: false, passMsg: "" });
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      checkValidation();
                    }
                  }}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        {!showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errObj.passErr ? (
                  <FormHelperText>{errObj.passMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </div>
            <Grid item container style={{ marginTop: 20 }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#4573B8",
                  fontWeight: "bold",
                }}
                onClick={checkValidation}
                justifyContent={"center"}
                variant="contained"
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={26} />
                ) : (
                  "Login"
                )}
              </Button>
            </Grid>

            <Grid item container justifyContent={"center"}>
              <Typography
                className="forgotPassword"
                onClick={() => {
                  navigate("/forgot-password");
                }}
              >
                Forgot password?
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Grid>
  ) : (
    <>
      <Grid
        container
        style={{
          flex: 1,
          overflow: "hidden",
          height: "100vh",
        }}
      >
        {/* <Button
      className="btn"
      style={{
        backgroundColor: "#4573B8",
        position: "absolute",
        right: 40,
        top: 20,
        height: 60,
      }}
      onClick={() => {
        window.open("https://fleetkaptan.com/");
      }}
    >
      <Lottie
        options={{
          loop: true,
          autoplay: true,
          animationData: tyre,
        }}
      />
      <span style={{ marginLeft: 8 }}>www.fleetkaptan.com</span>
    </Button> */}
        {isMobile ? null : (
          <Grid
            item
            container
            lg={4}
            md={4}
            style={{
              backgroundColor: "#4573B8",
              overflow: "hidden",
            }}
            alignItems="center"
            justifyContent="flex-end"
          >
            <div
              style={{
                width: "80%",
                maxWidth: 350,
                height: 600,
                backgroundColor: "#4573B8",
                border: "none",
                boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
                padding: 20,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: "1 1",
                }}
              >
                <img
                  src={Image.text}
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  alt="Go the extra mile"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "1 1",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <img
                  className="mecimg"
                  src={Image.loginImg}
                  style={{
                    objectFit: "contain",
                    position: "absolute",
                    width: "90%",
                    height: "auto",
                    bottom: -80,
                    left: -80,
                  }}
                  alt="background_image"
                />
              </div>
            </div>
          </Grid>
        )}
        <Grid
          item
          container
          lg={8}
          md={8}
          sm={12}
          style={{
            overflow: "hidden",
          }}
          justifyContent={isMobile ? "center" : "flex-start"}
          alignItems="center"
        >
          <Paper
            variant="outlined"
            style={{
              width: "80%",
              height: 600,
              border: "none",
              boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item style={{ width: isMobile ? "90%" : "50%" }}>
              <Grid
                item
                container
                style={
                  {
                    // marginBottom: 20,
                  }
                }
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={Image.frame1}
                  height={100}
                  alt="logo"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open("https://fleetkaptan.com/")}
                />
                {/* <Typography
                  style={{ width: "unset", fontWeight: "bold", fontSize: 20 }}
                >
                  Fleet Kaptan
                </Typography> */}
              </Grid>
              {/* <Typography
                style={{
                  fontWeight: 700,
                  fontSize: "40px",
                  textAlign: "center",
                }}
              >
                Login
              </Typography> */}
              {/* <div>
                <Typography
                  component="h5"
                  variant="h6"
                  fontWeight="bold"
                  style={{
                    color: "#000000",
                    opacity: 0.5,
                    textAlign: "center",
                  }}
                >
                  Welcome!
                </Typography>
              </div> */}
              <div className="txtInp">
                <Typography
                  style={{
                    color: "#4573B8",
                    fontWeight: "bold",
                    marginBottom: 8,
                  }}
                >
                  User ID
                </Typography>
                <TextField
                  value={userId}
                  margin="normal"
                  required
                  style={{
                    margin: 0,
                    marginBottom: errObj.useIdErr ? 30 : 20,
                  }}
                  placeholder="Please enter your User ID"
                  id="email"
                  name="Text"
                  autoFocus
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      document.querySelector("#password").focus();
                    }
                  }}
                  onChange={(e) => {
                    setUserId(e.target.value.trim());
                    setErrObj({ ...errObj, useIdErr: false, userIdMsg: "" });
                  }}
                  inputProps={{ maxLength: 11 }}
                  error={errObj.useIdErr}
                  helperText={errObj.useIdErr ? errObj.userIdMsg : null}
                />
                <Typography
                  style={{
                    color: "#4573B8",
                    fontWeight: "bold",
                    marginBottom: 8,
                  }}
                >
                  Password
                </Typography>
                <FormControl
                  fullWidth
                  error={errObj.passErr}
                  style={{ marginBottom: errObj.passErr ? 20 : 10 }}
                >
                  <OutlinedInput
                    value={password}
                    margin="normal"
                    required
                    type={showPassword ? "text" : "password"}
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                    error={errObj.passErr}
                    helperText={errObj.passErr ? errObj.passMsg : null}
                    name="password"
                    placeholder="Please enter your Password"
                    id="password"
                    autoComplete="current-password"
                    onChange={(e) => {
                      setPassword(e.target.value.trim());
                      setErrObj({ ...errObj, passErr: false, passMsg: "" });
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        checkValidation();
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {!showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />
                  {errObj.passErr ? (
                    <FormHelperText>{errObj.passMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </div>
              <Grid item container style={{ marginTop: 20 }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#4573B8",
                    fontWeight: "bold",
                  }}
                  onClick={checkValidation}
                  justifyContent={"center"}
                  variant="contained"
                  disabled={loader}
                >
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={26} />
                  ) : (
                    "Login"
                  )}
                </Button>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Typography
                  className="forgotPassword"
                  onClick={() => {
                    navigate("/forgot-password");
                  }}
                >
                  Forgot password?
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
