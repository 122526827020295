import {
  Box,
  Button,
  Grid,
  InputAdornment,
  MenuItem,
  TextField,
  Typography,
  Select,
  FormControl,
  FormHelperText,
  OutlinedInput,
  CircularProgress,
} from "@mui/material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import React, { useEffect, useState } from "react";
import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm";
import MapContainer from "../../MapContainer";
import PlaceAutoComplete from "../../../Components/PlaceAutoComplete";
import useStyles from "./styles";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const errorObj = {
  DriverNameErr: false,
  DriverNumberErr: false,
  IssueTypeErr: false,
  RouteOriginErr: false,
  RouteDestinationErr: false,
  GoodsCarriedErr: false,
  ServiceCentreAddressErr: false,
  CentreLocationErr: false,
  ContactNumberErr: false,
  TicketStatusErr: false,
  PaymentStatusErr: false,
  SelectedFileErr: false,
  remarksErr: false,

  DriverNameMsg: "",
  DriverNumberMsg: "",
  IssueTypeMsg: "",
  RouteOriginMsg: "",
  RouteDestinationMsg: "",
  GoodsCarriedMsg: "",
  ServiceCentreAddressMsg: "",
  CentreLocationMsg: "",
  ContactNumberMsg: "",
  TicketStatusMsg: "",
  PaymentStatusMsg: "",
  SelectedFileMsg: "",
  remarksMsg: "",
};
export default function GenerateTicket(props) {
  const { handleClick = () => null, ticketData = {}, editData = {} } = props;

  const { token, isOnline } = useSelector((state) => state.auth);

  const isEdit = !isEmpty(editData);
  const styles = useStyles();
  const [ticketId, setTicketId] = useState("");
  const [vehicleNo, setVehicleNo] = useState("");
  const [alignmentClaimed, setAlignmentClaimed] = useState(0);

  const [selectedLocation, setSelectedLocation] = useState({});
  const [centerList, setCenterList] = useState([]);
  const [garageData, setGarageData] = useState({});

  const [center, setCenter] = useState(true);

  const [driverName, setDriverName] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [issueType, setIssueType] = useState("");
  const [routeOrigin, setRouteOrigin] = useState("");
  const [routeDestination, setRouteDestination] = useState("");
  const [goodsCarried, setGoodsCarried] = useState("");
  const [serviceCentreAddress, setServiceCentreAddress] = useState("");
  const [centreLocation, setCentreLocation] = useState("");
  const [contactNumber, setContactNumber] = useState("");
  const [ticketStatus, setTicketStatus] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const [notified, setNotifid] = useState("");
  const [km, setkm] = useState(25);
  const [readOnly, setReadOnly] = useState(false);
  const [remarks, setRemarks] = useState("");
  const [file, setFile] = useState("No File Selected");
  const [selectedFile, setSlectedFile] = useState(null);

  const [loader, setLoader] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);

  useEffect(() => {
    setTicketId(isEdit ? editData?.id : ticketData?.ticket_id);
    setVehicleNo(isEdit ? editData?.vehicle_no : ticketData?.vehicle_no);
    setAlignmentClaimed(
      isEdit ? editData?.alignments_claimed : ticketData?.alignment_count
    );
    if (isEdit) {
      const fileName = editData?.bill.split("/");
      setReadOnly(true);
      setDriverName(editData?.driver_name || "");
      setDriverNumber(editData?.driver_number || "");
      setIssueType(editData?.issue || "");
      setRouteOrigin(editData?.route_origin || "");
      setRouteDestination(editData?.route_destination || "");
      setGoodsCarried(editData?.good_carried || "");
      setTicketStatus(editData?.status || 0);
      setNotifid(editData?.notify_by || "");
      setPaymentStatus(editData?.payment_status || "");
      setRemarks(editData?.remarks || "");
      setFile(fileName[fileName.length - 1] || "No File Selected");
      setSlectedFile(editData?.bill || null);
      setkm(editData?.km || "");
      setRemarks(editData?.remark || "");
      const locationObj = {
        location: editData?.location,
        lat: editData?.latitude,
        lng: editData?.longitude,
      };
      setSelectedLocation(locationObj);
    }
  }, []);

  useEffect(() => {
    if (isEdit && !isEmpty(centerList)) {
      const getCenterData = centerList.find(
        (item) => Number(item?.id) === Number(editData?.center_id)
      );
      setGarageData(getCenterData);
    }
  }, [centerList]);

  useEffect(() => {
    if (!isEmpty(garageData)) {
      setServiceCentreAddress(garageData?.address);
      setCentreLocation(garageData?.city);
      setContactNumber(garageData?.phone);
      setErrObj({
        ...errObj,
        ServiceCentreAddressErr: false,
        CentreLocationErr: false,
        ContactNumberErr: false,
        ServiceCentreAddressMsg: null,
        CentreLocationMsg: null,
        ContactNumberMsg: null,
      });
    }
  }, [garageData]);

  useEffect(() => {
    if (
      isEdit &&
      !isEmpty(selectedLocation) &&
      !isEmpty(selectedLocation?.location)
    ) {
      getCenterList();
    }
  }, [selectedLocation]);

  async function getCenterList() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.servicecenterlist}?lat=${selectedLocation?.lat}&long=${selectedLocation?.lng}&km=${km}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          setCenterList(response?.data);
        } else {
          setCenterList([]);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  const submitData = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.createticket;
    const params = {
      "Ticket[driver_name]": driverName,
      "Ticket[driver_number]": driverNumber,
      "Ticket[vehicle_id]": isEdit ? editData?.vehicle_id : ticketData?.id,
      "Ticket[issue]": issueType,
      "Ticket[route_origin]": routeOrigin,
      "Ticket[route_destination]": routeDestination,
      "Ticket[good_carried]": goodsCarried,
      "Ticket[center_id]": garageData?.id,
      "Ticket[alignments_claimed]": alignmentClaimed,
      "Ticket[status]": !isEdit ? 0 : ticketStatus,
      "Ticket[notify_by]": notified,
      "Ticket[km]": km,
      "Ticket[location]": selectedLocation?.location,
      "Ticket[lat]": selectedLocation?.lat,
      "Ticket[long]": selectedLocation?.lng,
    };

    if (issueType === "misallignment") {
      params["Ticket[payment_status]"] = paymentStatus;
      if (isObject(selectedFile)) {
        params["Ticket[bill]"] = selectedFile;
      }
    } else {
      params["Ticket[remark]"] = remarks;
    }
    if (isEdit) {
      params["Ticket[id]"] = editData?.id;
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        handleClick("cancel");
        // clearData();
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  const validation = () => {
    const error = { ...errObj };
    let valid = true;
    let section = null;
    let scroll = false;

    if (!isEdit) {
      if (isEmpty(driverName)) {
        valid = false;
        scroll = true;
        error.DriverNameErr = true;
        error.DriverNameMsg = "Please enter Driver name";
        section = document.querySelector("#DriverName");
      }
      // if (isEmpty(driverNumber.trim())) {
      //   valid = false;
      //   error.DriverNumberErr = true;
      //   error.DriverNumberMsg = "Please enter Driver No.";
      //   if (!scroll) {
      //     scroll = true;
      //     section = document.querySelector("#DriverNumber");
      //   }
      // } else if (driverNumber.length < 10) {
      //   valid = false;
      //   error.DriverNumberErr = true;
      //   error.DriverNumberMsg = "Please enter valid No.";
      //   if (!scroll) {
      //     scroll = true;
      //     section = document.querySelector("#DriverNumber");
      //   }
      // }
      if (isEmpty(issueType)) {
        valid = false;
        error.IssueTypeErr = true;
        error.IssueTypeMsg = "Please select Issue Type";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#IssueType");
        }
      }
      if (isEmpty(routeOrigin)) {
        valid = false;
        error.RouteOriginErr = true;
        error.RouteOriginMsg = "Please enter Route Origin";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#RouteOrigin");
        }
      }
      if (isEmpty(routeDestination)) {
        valid = false;
        error.RouteDestinationErr = true;
        error.RouteDestinationMsg = "Please enter Route Destination";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#RouteDestination");
        }
      }

      if (isEmpty(goodsCarried)) {
        valid = false;
        error.GoodsCarriedErr = true;
        error.GoodsCarriedMsg = "Please enter Goods Carried";
        if (!scroll) {
          scroll = true;
          section = document.querySelector("#GoodsCarried");
        }
      }
    }
    if (isEmpty(serviceCentreAddress)) {
      valid = false;
      error.ServiceCentreAddressErr = true;
      error.ServiceCentreAddressMsg = "Please select Service Center address";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#ServiceCentreAddress");
      }
    }
    if (isEmpty(centreLocation)) {
      valid = false;
      error.CentreLocationErr = true;
      error.CentreLocationMsg = "Please enter Centre Location";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#CentreLocation");
      }
    }
    if (isEmpty(contactNumber.trim())) {
      valid = false;
      error.ContactNumberErr = true;
      error.ContactNumberMsg = "Please enter Contact No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#ContactNumber");
      }
    } else if (contactNumber.length < 10) {
      valid = false;
      error.ContactNumberErr = true;
      error.ContactNumberMsg = "Please enter valid No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#ContactNumber");
      }
    }

    if (issueType !== "misallignment" && isEmpty(remarks)) {
      valid = false;
      error.remarksErr = true;
      error.remarksMsg = "Please enter Remarks";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#remarks");
      }
    }

    if (isEmpty(ticketStatus.toString()) && isEdit) {
      valid = false;
      error.TicketStatusErr = true;
      error.TicketStatusMsg = "Please select Ticket Status";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#TicketStatus");
      }
    }

    if (isEmpty(paymentStatus) && issueType === "misallignment") {
      valid = false;
      error.PaymentStatusErr = true;
      error.PaymentStatusMsg = "Please select Payment Status";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#PaymentStatus");
      }
    }
    // if (isNull(selectedFile) && issueType === "misallignment") {
    //   valid = false;
    //   error.SelectedFileErr = true;
    //   error.SelectedFileMsg = "Please upload Bill";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#selectedFile");
    //   }
    // }
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    setErrObj(error);
    if (valid) {
      submitData();
    }
  };

  return (
    <Box marginBottom={"20px"}>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>
          {isEdit ? "Resolve Ticket" : "Generate Ticket"}
        </Typography>
      </Box>
      <div className={styles.container}>
        <Grid container alignItems={"center"} style={{ marginBottom: 30 }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Ticket ID : </Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Typography>{!isEdit ? Number(ticketId) + 1 : ticketId}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 30, alignItems: "center" }}
          id="DriverName"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Driver's Name"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              fullWidth
              style={{ margin: 0 }}
              inputProps={{ readOnly: readOnly }}
              placeholder="Enter Driver's Name"
              value={driverName}
              error={errObj.DriverNameErr}
              helperText={errObj.DriverNameErr ? errObj.DriverNameMsg : null}
              onChange={(e) => {
                setDriverName(e.target.value);
                setErrObj({
                  ...errObj,
                  DriverNameErr: false,
                  DriverNameMsg: null,
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 30, alignItems: "center" }}
          id="DriverNumber"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography title={"Driver's No."} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              fullWidth
              style={{ margin: 0 }}
              placeholder="Enter Driver's No."
              inputProps={{
                onWheel: (event) => event.currentTarget.blur(),
                maxLength: 10,
                // readOnly: readOnly,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              value={driverNumber}
              error={errObj.DriverNumberErr}
              helperText={
                errObj.DriverNumberErr ? errObj.DriverNumberMsg : null
              }
              onChange={(e) => {
                setDriverNumber(e.target.value.replace(/[^0-9]/g, ""));
                setErrObj({
                  ...errObj,
                  DriverNumberErr: false,
                  DriverNumberMsg: null,
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 30, alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Vehicle No.</Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <OutlinedInput
              fullWidth
              inputProps={{ readOnly: true }}
              value={vehicleNo}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{
            marginBottom: errObj.IssueTypeErr ? 30 : 20,
            alignItems: "center",
          }}
          id="IssueType"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Issue Type"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <FormControl fullWidth error={errObj.IssueTypeErr}>
              <Select
                displayEmpty
                value={issueType}
                disabled={readOnly}
                onChange={(e) => {
                  setIssueType(e.target.value);
                  setErrObj({
                    ...errObj,
                    IssueTypeErr: false,
                    IssueTypeMsg: "",
                  });
                }}
                size={"medium"}
                style={issueType === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value={""} selected hidden disabled>
                  Select the Issue Type
                </MenuItem>
                <MenuItem value={"cut"}>Cut</MenuItem>
                <MenuItem value={"puncture"}>Puncture</MenuItem>
                <MenuItem
                  value={"misallignment"}
                  // disabled={editData?.alignments_claimed >= 5}
                >
                  Misalignment
                </MenuItem>
              </Select>
              {errObj.IssueTypeErr ? (
                <FormHelperText>{errObj.IssueTypeMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 30, alignItems: "center" }}
          id="RouteOrigin"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Route Origin"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Route Origin"
              value={routeOrigin}
              error={errObj.RouteOriginErr}
              helperText={errObj.RouteOriginErr ? errObj.RouteOriginMsg : null}
              onChange={(e) => {
                setRouteOrigin(e.target.value);
                setErrObj({
                  ...errObj,
                  RouteOriginErr: false,
                  RouteOriginMsg: null,
                });
              }}
              inputProps={{ readOnly: readOnly }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 30, alignItems: "center" }}
          id="RouteDestination"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Route Destination"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Route Destination"
              value={routeDestination}
              error={errObj.RouteDestinationErr}
              inputProps={{ readOnly: readOnly }}
              helperText={
                errObj.RouteDestinationErr ? errObj.RouteDestinationMsg : null
              }
              onChange={(e) => {
                setRouteDestination(e.target.value);
                setErrObj({
                  ...errObj,
                  RouteDestinationErr: false,
                  RouteDestinationMsg: null,
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 30, alignItems: "center" }}
          id="GoodsCarried"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Goods Carried"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Goods Carried"
              inputProps={{ readOnly: readOnly }}
              value={goodsCarried}
              error={errObj.GoodsCarriedErr}
              helperText={
                errObj.GoodsCarriedErr ? errObj.GoodsCarriedMsg : null
              }
              onChange={(e) => {
                setGoodsCarried(e.target.value);
                setErrObj({
                  ...errObj,
                  GoodsCarriedErr: false,
                  GoodsCarriedMsg: null,
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 30, alignItems: "center" }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Search Service Centre : </Typography>
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            md={10}
            lg={10}
            flexDirection="row"
            justifyContent={"space-between"}
            wrap="nowrap"
            alignItems={"center"}
          >
            <Grid item xs={12} md={8} lg={8}>
              <PlaceAutoComplete
                placeholder="Enter Location"
                style={{ width: "100%", margin: 0 }}
                onChange={(obj) => {
                  setCenter(true);
                  setSelectedLocation(obj);
                }}
                defaultValue={editData?.location}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginLeft: 20,
              }}
            >
              <Typography style={{ whiteSpace: "nowrap" }}>Kms : </Typography>
              <Select
                fullWidth
                value={km}
                onChange={(v) => {
                  setkm(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25} selected hidden>
                  25
                </MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Button
              style={{
                backgroundColor: color.secondary,
                display: "flex",
                marginLeft: 20,
              }}
              onClick={() => {
                if (isEmpty(selectedLocation)) {
                  toast.error("Please select location first");
                } else {
                  getCenterList();
                }
              }}
            >
              Search
            </Button>
          </Grid>
        </Grid>

        {!isEmpty(selectedLocation) && (
          <Grid container style={{ marginBottom: 30, textAlign: "center" }}>
            <Grid item xs={12} sm={12} md={2} lg={2}></Grid>
            <Grid item xs={12} md={10} lg={10}>
              <div style={{ position: "relative", width: "100%", height: 300 }}>
                <MapContainer
                  data={{
                    lat: selectedLocation?.lat,
                    lng: selectedLocation?.lng,
                  }}
                  // miles={km}
                  centerData={centerList}
                  selectItemData={(item) => {
                    setCenter(false);
                    setGarageData(item);
                  }}
                  center={center}
                />
              </div>
            </Grid>
          </Grid>
        )}

        <Grid container style={{ marginBottom: 30 }} id="ServiceCentreAddress">
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Service Centre"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Pre-Filled Address based on selection"
              rows={4}
              multiline
              value={serviceCentreAddress}
              error={errObj.ServiceCentreAddressErr}
              helperText={
                errObj.ServiceCentreAddressErr
                  ? errObj.ServiceCentreAddressMsg
                  : null
              }
              inputProps={{ readOnly: true }}
              onChange={(e) => {
                setServiceCentreAddress(e.target.value);
                setErrObj({
                  ...errObj,
                  ServiceCentreAddressErr: false,
                  ServiceCentreAddressMsg: null,
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 30, alignItems: "center" }}
          id="CentreLocation"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Centre Location"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Pre-Filled City based on selection"
              value={centreLocation}
              error={errObj.CentreLocationErr}
              helperText={
                errObj.CentreLocationErr ? errObj.CentreLocationMsg : null
              }
              inputProps={{ readOnly: true }}
              onChange={(e) => {
                setCentreLocation(e.target.value);
                setErrObj({
                  ...errObj,
                  CentreLocationErr: false,
                  CentreLocationMsg: null,
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 30, alignItems: "cenetr" }}
          id="ContactNumber"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Contact No."} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Contact No."
              inputProps={{
                onWheel: (event) => event.currentTarget.blur(),
                maxLength: 10,
                readOnly: true,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
              value={contactNumber}
              error={errObj.ContactNumberErr}
              helperText={
                errObj.ContactNumberErr ? errObj.ContactNumberMsg : null
              }
              onChange={(e) => {
                setContactNumber(e.target.value.replace(/[^0-9]/g, ""));
                setErrObj({
                  ...errObj,
                  ContactNumberErr: false,
                  ContactNumberMsg: null,
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid container alignItems={"center"} style={{ marginBottom: 30 }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Alignment Claimed : </Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Typography>{alignmentClaimed}/5</Typography>
          </Grid>
        </Grid>

        <Grid container alignItems={"center"} style={{ marginBottom: 30 }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Eligible to Claim : </Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Typography>{alignmentClaimed >= 5 ? "No" : "Yes"}</Typography>
          </Grid>
        </Grid>

        {isEdit && (
          <Grid
            container
            style={{ marginBottom: 30, alignItems: "center" }}
            id="TicketStatus"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Ticket Status"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <FormControl fullWidth error={errObj.TicketStatusErr}>
                <Select
                  value={ticketStatus}
                  onChange={(e) => {
                    setTicketStatus(e.target.value);
                    setErrObj({
                      ...errObj,
                      TicketStatusErr: false,
                      TicketStatusMsg: null,
                    });
                  }}
                  displayEmpty
                  style={ticketStatus === "" ? { color: "#A2A2A2" } : {}}
                  fullWidth
                >
                  <MenuItem value={""} selected hidden disabled>
                    Select the Ticket Status
                  </MenuItem>
                  <MenuItem value={0}>Open</MenuItem>
                  <MenuItem value={1}>Resolved</MenuItem>
                </Select>
                {errObj.TicketStatusErr ? (
                  <FormHelperText>{errObj.TicketStatusMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
        )}

        <Grid container style={{ marginBottom: 30, alignItems: "cenetr" }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Notified By : </Typography>
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Select
              fullWidth
              value={notified}
              onChange={(e) => {
                setNotifid(e.target.value);
              }}
              displayEmpty
              style={notified === "" ? { color: "#A2A2A2" } : {}}
            >
              <MenuItem value={""} selected hidden disabled>
                Select the Notified By
              </MenuItem>
              <MenuItem value={"driver"}>Driver</MenuItem>
              <MenuItem value={"workshop_manager"}>Workshop Manager</MenuItem>
            </Select>
          </Grid>
        </Grid>

        {issueType === "misallignment" ? (
          alignmentClaimed <= 4 && (
            <>
              <Grid
                container
                style={{ marginBottom: 30, alignItems: "center" }}
                id="PaymentStatus"
              >
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <CTypography required title={"Payment Status"} />
                </Grid>
                <Grid item xs={12} md={10} lg={10}>
                  <FormControl fullWidth error={errObj.PaymentStatusErr}>
                    <Select
                      fullWidth
                      value={paymentStatus}
                      onChange={(e) => {
                        setPaymentStatus(e.target.value);
                        setErrObj({
                          ...errObj,
                          PaymentStatusErr: false,
                          PaymentStatusMsg: "",
                        });
                      }}
                      displayEmpty
                      style={paymentStatus === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} selected hidden disabled>
                        Select the Payment Status
                      </MenuItem>
                      <MenuItem value={"Bill Pending"}>Bill Pending</MenuItem>
                      <MenuItem value={"Payment Pending"}>
                        Payment Pending
                      </MenuItem>
                      <MenuItem value={"Payment Completed"}>
                        Payment Completed
                      </MenuItem>
                    </Select>
                    {errObj.PaymentStatusErr ? (
                      <FormHelperText>{errObj.PaymentStatusMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </Grid>

              <Grid
                container
                style={{ marginBottom: 30, alignItems: "center" }}
                id="selectedFile"
              >
                <Grid item xs={12} sm={12} md={2} lg={2}>
                  <Typography style={{ marginRight: 20 }}>
                    Upload Bill :
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={10}
                  lg={10}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Typography
                    style={
                      !selectedFile || selectedFile === ""
                        ? { color: "#A2A2A2" }
                        : {}
                    }
                  >
                    {file}
                  </Typography>
                  <Button
                    variant="contained"
                    component="label"
                    style={{
                      backgroundColor: color.secondary,
                      margin: "0 20px",
                    }}
                  >
                    <input
                      type="file"
                      accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg"
                      hidden
                      onChange={(e) => {
                        setFile(e.target.files[0].name);
                        setSlectedFile(e.target.files[0]);
                        setErrObj({
                          ...errObj,
                          SelectedFileErr: false,
                          SelectedFileMsg: null,
                        });
                      }}
                    />
                    Upload File
                  </Button>
                  {errObj.SelectedFileErr ? (
                    <Typography style={{ color: "red", fontSize: 12 }}>
                      {errObj.SelectedFileMsg}
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            </>
          )
        ) : (
          <Grid container style={{ marginBottom: 30 }} id="remarks">
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Remarks"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Please Enter Remarks"
                rows={4}
                multiline
                value={remarks}
                error={errObj.remarksErr}
                helperText={errObj.remarksErr ? errObj.remarksMsg : null}
                onChange={(e) => {
                  setRemarks(e.target.value);
                  setErrObj({
                    ...errObj,
                    remarksErr: false,
                    remarksMsd: null,
                  });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid container alignItems={"center"} justifyContent={"center"}>
          <Button
            disabled={isOnline ? false : true || { loader }}
            onClick={validation}
            style={{
              backgroundColor: isOnline ? color.primary : color.bordercolor,
              marginRight: 10,
              width: 140,
            }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : isEdit ? (
              "Save & Update"
            ) : (
              "Generate Ticket"
            )}
          </Button>
          <Button
            style={{ backgroundColor: color.secondary, width: 140 }}
            onClick={() => handleClick("cancel")}
          >
            Cancel
          </Button>
        </Grid>
      </div>
    </Box>
  );
}
