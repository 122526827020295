import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Pagination,
  Popover,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { color } from "../../../Config/theme";
import {
  MoreVert,
  Search,
  SearchOutlined,
  TuneOutlined,
  Visibility,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Images from "../../../Config/Images";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import { ResetIcon } from "../../Icon/TableToggle";

export default function TabConditionHistory(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleSearch = () => null,
    handleDate = () => null,
    dateF = null,
    search = "",
    pageNo = 1,
    record = 10,
  } = props;

  const styles = useStyles();

  const [pageLoad, setPageLoad] = useState(true);
  const [conditionHistoryRows, setConditionHistoryRows] = useState({});
  const [filter, setFilter] = useState("");
  // const [searchFilter, setSearchFilter] = useState("");
  // const [datePicker, setDatePicker] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;
  const { token, isOnline, userdata } = useSelector((state) => state.auth);

  useEffect(() => {
    lifeHistoryList(false);
  }, [record, pageNo, filter]);

  useEffect(() => {
    isNull(dateF) && isEmpty(search) && lifeHistoryList(false);
  }, [dateF, search]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [pageNo, pageLoad]);

  function resetFilterData() {
    setFilter("");
    handleSearch("");
    handleDate(null);
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  const changedOn = (index) => {
    const timestamp = conditionHistoryRows?.data[index]?.last_condition_change;
    // const date = new Date(timestamp * 1000);
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return <Typography>{timestamp ? displayDate : ""}</Typography>;
  };

  function downloadConditionData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.last_condition_change;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Vehicle No.": row?.vehicle_no,
        "Tyre Serial No.": row?.tyre_serial_no,
        "No. of Condition Changes": Number(row?.condition_changed),
        "Last Condition Changes": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Tyre Condition History List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Condition History List.xlsx");
  }

  async function lifeHistoryList(bool, v) {
    !v && setPageLoad(true);
    v && setDownloadLoader(true);
    const date = !isNull(dateF) ? moment(dateF).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.conditionHistory
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : search
        }&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadConditionData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setConditionHistoryRows(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Condition History List");
      setDownloadLoader(false);
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Grid container wrap="nowrap">
            <Grid
              id="jump_to_me"
              item
              xs={12}
              sm={9}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="tableTitle">Condition History</Typography>
            </Grid>
            <Grid
              item
              container
              sm={3}
              gap={1.2}
              wrap="nowrap"
              justifyContent={"flex-end"}
            >
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disabled={isOnline ? false : true}
                  onClick={() => lifeHistoryList(false, 1)}
                >
                  {downloadLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: color.white,
                    color: "#D8D8D8",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      handleClick("RotationHistory");
                    }}
                  >
                    Rotation History
                  </ListItemButton>
                  <ListItemButton selected>Condition History</ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      handleClick("LifeHistory");
                    }}
                  >
                    Life History
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid container gap={1} style={{ marginTop: 15 }} wrap="nowrap">
            <Grid item>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && lifeHistoryList(true);
                  handleSearch(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter")
                    if (!isEmpty(search) || !isNull(dateF)) {
                      handlePageNo(1);
                      lifeHistoryList(false);
                    }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Tyre Serial No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  value={dateF}
                  onChange={(newValue) => {
                    handleDate(newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.dateF }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Button
                varient="contained"
                onClick={() => {
                  if (!isEmpty(search) || !isNull(dateF)) {
                    lifeHistoryList(false);
                  }
                }}
                style={{
                  backgroundColor: color.secondary,
                  width: "100%",
                }}
              >
                <SearchOutlined />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={resetFilterData}
                disabled={!search && !filter && !dateF}
              >
                <ResetIcon />
              </Button>
            </Grid>
            <Grid item>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(conditionHistoryRows?.data) &&
            !isEmpty(conditionHistoryRows?.data) ? (
            <>
              {conditionHistoryRows?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      padding: 15,
                      marginTop: 10,
                      overflow: "auto",
                      boxShadow: color.shadow,
                    }}
                    alignItems="center"
                  >
                    <Grid item container>
                      <Table className={styles.customtable}>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Fleet Name</TableCell>
                            <TableCell variant="head">Vehicle No.</TableCell>
                            <TableCell variant="head">
                              Tyre Serial No.
                            </TableCell>
                            <TableCell variant="head" colSpan={4}>
                              No. of Condition Changes
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>{item?.fleet_name || "-"}</TableCell>
                          <TableCell>{item?.vehicle_no || "-"}</TableCell>
                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                flex: 1,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                handleClick("tyreDetails", item);
                              }}
                            >
                              <Typography
                                style={{
                                  whiteSpace: "pre-wrap",
                                  color: color.primary,
                                  fontWeight: "bold",
                                }}
                              >
                                {item?.tyre_serial_no || "-"}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell>{item?.condition_changed || ""}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Divider
                              className={styles.divide}
                              variant="fullWidth"
                            />
                          </TableCell>
                        </TableRow>
                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} variant="head">
                              Last Condition Changes
                            </TableCell>
                            <TableCell
                              rowSpan={2}
                              colSpan={2}
                              style={{
                                textAlign: "end",
                              }}
                            >
                              <Button
                                style={{
                                  backgroundColor: color.primary,
                                  marginBottom: -25,
                                }}
                                onClick={() =>
                                  handleClick("ConditionDetails", item)
                                }
                              >
                                <Visibility />
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow style={{ margin: 10 }}>
                          <TableCell style={{ paddingBottom: 15 }} colSpan={2}>
                            {changedOn(index)}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </Grid>
                  </Grid>
                );
              })}
              <Box
                style={{
                  margin: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(conditionHistoryRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <Grid container wrap="nowrap">
            <Grid
              id="jump_to_me"
              item
              container
              display="flex"
              alignItems="center"
              backgroundColor={color.secondary}
              padding={"10px"}
              color={color.white}
              borderRadius={1}
            >
              <Grid item>
                <Typography variant="tableTitle">Tyre History</Typography>
              </Grid>

              <Grid item marginLeft={"auto"}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#BBBBBB",
                    minWidth: "unset",
                    color: "#000",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVert />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      handleClick("LifeHistory");
                    }}
                  >
                    Life History
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      handleClick("RotationHistory");
                    }}
                  >
                    Rotation History
                  </ListItemButton>
                  <ListItemButton selected>Condition History</ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid marginTop={"10px"}>
            <Typography variant="tableTitle">Condition History</Typography>
          </Grid>
          <Grid container wrap="nowrap" gap={1} style={{ paddingTop: 10 }}>
            <Grid item xs={10}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && lifeHistoryList(true);
                  handleSearch(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(search) || !isNull(dateF)) {
                      handlePageNo(1);
                      lifeHistoryList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Tyre Serial No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                disable={isOnline ? false : true}
                onClick={() => lifeHistoryList(false, 1)}
              >
                {downloadLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>
          <div style={{ paddingTop: 14 }}>
            <Grid container xs={12} gap={1} wrap="nowrap">
              <Grid item xs={9}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    value={dateF}
                    onChange={(newValue) => {
                      handleDate(newValue);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={1.5}>
                <Button
                  varient="contained"
                  onClick={() => {
                    if (!isEmpty(search) || !isNull(dateF)) {
                      lifeHistoryList(false);
                    }
                  }}
                  style={{
                    backgroundColor: color.secondary,
                    width: "100%",
                  }}
                >
                  <SearchOutlined />
                </Button>
              </Grid>

              <Grid item xs={1.5}>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={resetFilterData}
                  disabled={!search && !filter && !dateF}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
          </div>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(conditionHistoryRows?.data) &&
            !isEmpty(conditionHistoryRows?.data) ? (
            <>
              {conditionHistoryRows?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      overflow: "hidden",
                      marginTop: 14,
                      boxShadow: color.shadow,
                    }}
                  >
                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                        gap={1}
                      >
                        <Grid
                          item
                          xs={6}
                          onClick={() => handleClick("ConditionDetails", item)}
                        >
                          <Typography fontWeight={"bold"}>
                            Vehicle No.
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Tyre Serial No.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                        }}
                        xs={12}
                        gap={1}
                      >
                        <Grid
                          item
                          xs={6}
                          onClick={() => handleClick("ConditionDetails", item)}
                        >
                          <Typography>{item?.vehicle_no}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <div
                            style={{
                              display: "flex",
                              flex: 1,
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              handleClick("tyreDetails", item);
                            }}
                          >
                            <Typography
                              style={{
                                whiteSpace: "pre-wrap",
                                color: color.primary,
                                fontWeight: "bold",
                              }}
                            >
                              {item?.tyre_serial_no}
                            </Typography>
                          </div>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid
                      container
                      item
                      onClick={() => handleClick("ConditionDetails", item)}
                    >
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          background: "#EEF8FF",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                        gap={1}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            No. of Condition Changes
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Last Condition Changes
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                          background: "#EEF8FF",
                        }}
                        xs={12}
                        gap={1}
                      >
                        <Grid item xs={6}>
                          <Typography>{item?.condition_changed}</Typography>
                        </Grid>
                        <Grid item xs={6} wrap="wrap">
                          <Typography>{changedOn(index)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    {/* <Grid container item style={{ position: "relative" }}>
                      <Grid container item xs={12}>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "10px 8px 0 14px",
                          }}
                          xs={12}
                        ></Grid>
                        <Grid
                          item
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            padding: "0 8px 10px 14px",
                          }}
                          xs={12}
                        >
                          <Button
                            style={{
                              backgroundColor: color.primary,
                              marginLeft: "auto",
                              position: "absolute",
                              top: 7,
                              right: 10,
                            }}
                          >
                            <Visibility />
                          </Button>
                        </Grid>
                      </Grid>
                    </Grid> */}
                  </Grid>
                );
              })}
              <Box
                style={{
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(conditionHistoryRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}
