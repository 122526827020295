import React, { useState, useEffect } from "react";
import useStyles from "./styles.js";
import { color } from "../../../Config/theme.js";
import { Setting } from "../../../Utils/Setting.js";
import {
  Button,
  Grid,
  Typography,
  Box,
  Table,
  TableCell,
  TableRow,
  TableHead,
  Modal,
  Tooltip,
  CircularProgress,
} from "@mui/material";

import { useSelector } from "react-redux";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { isArray, isEmpty, isObject } from "lodash";
import moment from "moment";
import TabVehicleManagement from "../TabVehicleManagement/index.js";
import FAddUser from "../../Fleet/FAddUser/index.js";
import AddVehicle from "../../Fleet/AddVehicle/index.js";
import TabVehicleDetails from "../TabVehicleDetails/Index.js";
import TabUnresolvedIssue from "../TabUnresolvedIssue/index.js";
import TabIssueList from "../TabIssueList/index.js";
import { ArrowBackOutlined } from "@mui/icons-material";
import TabResolveIssue from "../TabResolveIssue/index.js";
import { isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";
import TabUserManagement from "../TabUserManagment/index.js";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function TabFleetDetails(props) {
  const styles = useStyles();
  const { handleClick = () => null, fleetData, from } = props;
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [fleetDetails, setFleetDetails] = useState({});
  const [openCard, setOpenCard] = useState(false);
  const [changeTab, setChangeTab] = useState("");
  const { token, isOnline, permissionData, userdata } = useSelector(
    (state) => state.auth
  );
  const [userData, setUserData] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [reportLoader, setReportLoader] = useState(false);
  const [reportLoader1, setReportLoader1] = useState(false);
  const [issueType, setIssueType] = useState("");
  const [issue, setIssue] = useState("");

  const navigate = useNavigate();

  const [tyreListDisplay, setTyreListdisplay] = useState(true);

  const [pageNoFU, setPageNoFU] = useState(1);

  const [pageNoUI, setPageNoUI] = useState(1);
  const [recordUI, setRecordUI] = useState(10);
  const [filterUI, setFilterUI] = useState("");

  const [pageNoIL, setPageNoIL] = useState(1);
  const [recordIL, setRecordIL] = useState(10);

  const [pageNoV, setPageNoV] = useState(1);
  const [recordV, setRecordV] = useState(10);
  const [filterV, setFilterV] = useState("");

  useEffect(() => {
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Tyre List") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              setTyreListdisplay(v?.isActive);
          }
        });
      });
    }
  }, [permissionData]);

  useEffect(() => {
    getFleetDetails();
  }, [changeTab]);

  useEffect(() => {
    if (from === "inspection") {
      setTimeout(() => setChangeTab("UnresolvedIssueList"), 200);
    }
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [changeTab]);

  const getFleetDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetdetails}?id=${fleetData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setFleetDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Fleet Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function AddedOn() {
    const timestamp = fleetDetails.created_at;
    const date = new Date(timestamp * 1000);
    return timestamp ? moment(date).format("DD-MM-yyyy") : "-";
  }

  const checkFunction = () => {
    if (!isEmpty(fleetDetails)) {
      let Array = fleetDetails?.business_pan_card.split(".");
      if (Array[Array.length - 1] === "pdf") {
        const pdfWindow = window.open();
        pdfWindow.location.href = fleetDetails?.business_pan_card;
      } else {
        setOpenCard(true);
      }
    }
  };

  async function getTyreListReport() {
    setReportLoader(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetTyreListReport}?fleet_id=${fleetData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReports1(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave("Tyre List");
      } else {
        toast.error(response?.message);
      }
      setReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error?.toString());
      setReportLoader(false);
    }
  }

  function nsd(data, index) {
    const nsdArr = data[index]?.current_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return `${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
      groove2 !== 0 ? groove2.toFixed(2) : " - "
    }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
      groove4 !== 0 ? groove4.toFixed(2) : " - "
    } mm`;
  }

  function downloadReports1(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        RO: item?.ro || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position,
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Mileage (Km)": Number(item?.mileage?.toString()?.replaceAll(",", "")),
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Tyre Air Pressure (psi)": item?.tyre_air_pressure || "-",
        "Tyre Issue": item?.tyre_issue?.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue?.toString() || "-",
        Remarks: item?.Remarks || "-",
        "Last Inspected On": item?.inspected_at ? displayDate : "-",
        "Last Inspected By": item?.inspected_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre List.xlsx");
  }

  async function getIssueReport() {
    setReportLoader1(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.pendingIssueReport}?fleet_id=${fleetData?.id}&status=0&is_all=1`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReports2(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
      } else {
        toast.error(response?.message);
      }
      setReportLoader1(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader1(false);
    }
  }

  function downloadReports2(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        RO: item?.fk_ro_id || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Tyre Issue": item?.tyre_issue.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue.toString() || "-",
        Remarks: item?.Remarks || "-",
        "Inspected On": item?.inspected_at ? displayDate : "-",
        "Inspected By": item?.inspected_by || "-",
      };

      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Unresolved Issue List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Unresolved Issue List.xlsx");
  }

  return (
    <>
      {changeTab === "vehicle" && (
        <Box id="jump_to_me">
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("fleetDetails")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "editVehicle" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("fleetDetails")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "UnresolvedIssue" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("fleetDetails")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "UnresolvedIssueList" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                if (from === "inspection") {
                  navigate("/dashboard/Inspection");
                } else {
                  setChangeTab("UnresolvedIssue");
                }
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "ResolveIssue" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("UnresolvedIssueList")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "user" ? (
        <>
          <Box>
            {isKeyboardOpen && !isTablet ? null : (
              <Button
                style={
                  isTablet
                    ? { backgroundColor: color.secondary, marginBottom: 10 }
                    : {
                        backgroundColor: color.secondary,
                        position: "fixed",
                        top: 10,
                        zIndex: 10,
                      }
                }
                onClick={() => setChangeTab("")}
              >
                {isTablet ? "Back" : <ArrowBackOutlined />}
              </Button>
            )}
          </Box>
          <FAddUser
            fleetData={fleetDetails}
            handleClick={(type) => {
              if (type === "cancel") {
                setChangeTab("");
                setUserData({});
                getFleetDetails();
              }
            }}
            editData={userData}
          />
        </>
      ) : changeTab === "vehicleDetails" ? (
        <TabVehicleDetails
          vehicleDetails={vehicleData}
          handleClick={(data) => {
            setChangeTab("fleetDetails");
          }}
        />
      ) : changeTab === "vehicle" ? (
        <AddVehicle
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
              setVehicleData({});
            }
          }}
          editData={vehicleData}
          fleetData={fleetDetails}
        />
      ) : changeTab === "UnresolvedIssueList" ? (
        <TabUnresolvedIssue
          vehicleData={from === "inspection" ? fleetData : vehicleData}
          pageNo={pageNoUI}
          record={recordUI}
          filter={filterUI}
          handlePageNo={(page) => {
            setPageNoUI(page);
          }}
          handleRecord={(rec) => {
            setRecordUI(rec);
          }}
          handleFilter={(flt) => {
            setFilterUI(flt);
          }}
          handleClick={(type, data, issType, iss, rec, page) => {
            if (type === "ResolveIssue") {
              setChangeTab("ResolveIssue");
              setVehicleDetails(data);
              setIssueType(issType);
              setIssue(iss);
            }
          }}
        />
      ) : changeTab === "UnresolvedIssue" ? (
        <TabIssueList
          fleetData={fleetDetails}
          pageNo={pageNoIL}
          record={recordIL}
          handlePageNo={(page) => {
            setPageNoIL(page);
          }}
          handleRecord={(rec) => {
            setRecordIL(rec);
          }}
          handleClick={(type, data) => {
            if (type === "UnresolvedIssueList") {
              setPageNoUI(1);
              setRecordUI(10);
              setFilterUI("");
              setChangeTab("UnresolvedIssueList");
              setVehicleData(data);
            }
          }}
        />
      ) : changeTab === "ResolveIssue" ? (
        <TabResolveIssue
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("UnresolvedIssueList");
            }
          }}
          vehicleDetails={vehicleDetails}
          fleetData={fleetDetails}
          from={"fleetdetails"}
          issueType={issueType}
          issue={issue}
        />
      ) : isTablet ? (
        <>
          <Box>
            <Button
              style={{ backgroundColor: color.secondary, marginBottom: 10 }}
              onClick={() => {
                handleClick("cancel");
              }}
            >
              Back
            </Button>
          </Box>
          <div className={styles.container}>
            <Box
              style={{
                backgroundColor: color.primary,
                color: color.white,
                padding: 10,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
              id="jump_to_me"
            >
              <Grid item container>
                <Typography fontWeight={"bold"}>Fleet Details</Typography>
              </Grid>
              {tyreListDisplay && (
                <Grid item container justifyContent={"flex-end"} wrap="nowrap">
                  <Tooltip title="Tabs" placement="bottom" arrow>
                    <Button
                      disabled={isOnline ? false : true}
                      style={{
                        marginLeft: 10,
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                      }}
                      onClick={() => {
                        getTyreListReport();
                      }}
                    >
                      {reportLoader ? (
                        <CircularProgress
                          style={{ color: color.white }}
                          size={24}
                        />
                      ) : (
                        "Download Tyre List"
                      )}
                    </Button>
                  </Tooltip>
                </Grid>
              )}
            </Box>
            <div style={{ padding: 20 }}>
              <Table>
                <TableRow>
                  <TableCell variant="head">Fleet Name</TableCell>
                  <TableCell>{fleetDetails?.name || "-"}</TableCell>
                  <TableCell variant="head">Owner</TableCell>
                  <TableCell>{fleetDetails?.owner_name || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Fleet Code</TableCell>
                  <TableCell>{fleetDetails?.code || "-"}</TableCell>
                  <TableCell variant="head">Owner’s No.</TableCell>
                  <TableCell>{fleetDetails?.owner_phone || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Location</TableCell>
                  <TableCell>{fleetDetails?.city || "-"}</TableCell>
                  <TableCell variant="head">Manager</TableCell>
                  <TableCell>{fleetDetails?.manager_name || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Address</TableCell>
                  <TableCell>{fleetDetails?.address || "-"}</TableCell>
                  <TableCell variant="head">Manager’s No.</TableCell>
                  <TableCell>{fleetDetails?.manager_phone || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">State</TableCell>
                  <TableCell>{fleetDetails?.state || "-"}</TableCell>
                  <TableCell variant="head">Unresolved Issues</TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      color:
                        fleetDetails?.pending_issues_count == 0
                          ? color.disable
                          : "red",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (fleetDetails?.pending_issues_count != 0) {
                        setPageNoIL(1);
                        setRecordIL(10);
                        setChangeTab("UnresolvedIssue");
                      }
                    }}
                  >
                    {fleetDetails?.pending_issues_count || "0"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">District</TableCell>
                  <TableCell>{fleetDetails?.distict || "-"}</TableCell>
                  <TableCell variant="head">Added On</TableCell>
                  <TableCell>{AddedOn() || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Regional Office</TableCell>
                  <TableCell>{fleetDetails?.office_region || "-"}</TableCell>
                  <TableCell variant="head">PAN Card</TableCell>
                  <TableCell
                    style={{
                      color: color.primary,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={checkFunction}
                  >
                    View
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">No. of Vehicles</TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      color:
                        fleetDetails?.vehicles_allocated == 0
                          ? color.disable
                          : "blue",
                    }}
                  >
                    {fleetDetails?.vehicles_allocated || "-"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <Modal
                    className={styles.modal}
                    open={openCard}
                    onClose={() => {
                      setOpenCard(false);
                    }}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        borderRadius: 4,
                        maxWidth: isTablet ? 550 : 330,
                        maxHeight: isTablet ? 550 : 330,
                      }}
                      src={fleetDetails.business_pan_card || "-"}
                      alt={"Business PAN Card"}
                    />
                  </Modal>
                </TableRow>
              </Table>
            </div>
          </div>

          <TabUserManagement
            fleetData={fleetData}
            handleClick={(type, data) => {
              if (type === "user") {
                setChangeTab("user");
                setUserData(data);
              }
            }}
          />

          <TabVehicleManagement
            fleetData={fleetData}
            name={fleetDetails?.name}
            pageNo={pageNoV}
            record={recordV}
            filter={filterV}
            handlePageNo={(page) => {
              setPageNoV(page);
            }}
            handleRecord={(rec) => {
              setRecordV(rec);
            }}
            handleFilter={(flt) => {
              setFilterV(flt);
            }}
            handleClick={(type, data) => {
              if (type === "vehicle") {
                setChangeTab("vehicle");
                setVehicleData({});
              } else if (type === "vehicleDetails") {
                setChangeTab("vehicleDetails");
                setVehicleData(data);
              } else if (type === "editVehicle") {
                setVehicleData(data);
                setTimeout(() => {
                  setChangeTab("vehicle");
                }, 200);
              }
            }}
          />
        </>
      ) : (
        <>
          <Box>
            {isKeyboardOpen && !isTablet ? null : (
              <Button
                style={{
                  backgroundColor: color.secondary,
                  marginBottom: 10,
                  position: "fixed",
                  top: 10,
                  zIndex: 10,
                }}
                onClick={() => {
                  handleClick("cancel");
                }}
              >
                <ArrowBackOutlined />
              </Button>
            )}
          </Box>
          <Grid
            container
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 10,
              alignItems: "center",
              justifyContent: "space-between",
            }}
            wrap="nowrap"
            id="jump_to_me"
          >
            <Grid item container>
              <Typography fontWeight={"bold"}>Fleet Details</Typography>
            </Grid>
            {tyreListDisplay && (
              <Grid item container justifyContent={"flex-end"} wrap="nowrap">
                <Tooltip title="Tabs" placement="bottom" arrow>
                  <Button
                    disabled={isOnline ? false : true}
                    style={{
                      marginLeft: 10,
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                    }}
                    onClick={() => {
                      getTyreListReport();
                    }}
                  >
                    {reportLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      "Download Tyre List"
                    )}
                  </Button>
                </Tooltip>
              </Grid>
            )}
          </Grid>
          <div className={styles.container} style={{ padding: 10 }}>
            <div>
              <Grid
                item
                container
                style={{
                  border: `1px solid ${color.bordercolor}`,
                  alignItems: "center",
                  overflow: "hidden",
                }}
              >
                <Grid item container>
                  <Table className={styles.customtableMobile}>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Fleet Name</TableCell>
                        <TableCell variant="head">Owner</TableCell>

                        {/* <TableCell variant="head">Contact No.</TableCell> */}
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.name || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.owner_name || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Fleet Code</TableCell>
                        <TableCell variant="head">Owner’s No.</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.code || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.owner_phone || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Location</TableCell>
                        <TableCell variant="head">Manager</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.city || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.manager_name || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Address</TableCell>
                        <TableCell variant="head">Manager’s No.</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.address || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.manager_phone || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">State</TableCell>
                        <TableCell variant="head">Unresolved Issues</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.state || "-"}
                        </TableCell>
                        <TableCell
                          style={{
                            fontWeight: "bold",
                            color:
                              fleetDetails?.pending_issues_count == 0
                                ? color.disable
                                : "red",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            if (fleetDetails?.pending_issues_count != 0) {
                              setPageNoIL(1);
                              setRecordIL(10);
                              setChangeTab("UnresolvedIssue");
                            }
                          }}
                        >
                          {fleetDetails?.pending_issues_count || "0"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">District</TableCell>
                        <TableCell variant="head">Added On</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.distict || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {AddedOn() || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Regional Office</TableCell>
                        <TableCell variant="head">PAN Card</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {fleetDetails?.office_region || "-"}
                        </TableCell>
                        <TableCell
                          variant="body"
                          style={{
                            color: color.primary,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={checkFunction}
                        >
                          View
                        </TableCell>
                        <Modal
                          className={styles.modal}
                          open={openCard}
                          onClose={() => {
                            setOpenCard(false);
                          }}
                        >
                          <img
                            style={{
                              objectFit: "contain",
                              borderRadius: 4,
                              maxWidth: "80vw",
                              maxHeight: "80vh",
                            }}
                            src={fleetDetails?.business_pan_card || "-"}
                            alt={"RC Book"}
                          />
                        </Modal>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell colSpan={2} variant="head">
                          No. of Vehicles
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          variant="body"
                          style={{
                            paddingBottom: 5,
                            fontWeight: "bold",
                            color:
                              fleetDetails?.vehicles_allocated == 0
                                ? color.disable
                                : "blue",
                          }}
                        >
                          {fleetDetails?.vehicles_allocated || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </Grid>
              </Grid>
            </div>
          </div>
          <TabUserManagement
            fleetData={fleetData}
            pageNo={pageNoFU}
            handlePageNo={(page) => {
              setPageNoFU(page);
            }}
            handleClick={(type, data) => {
              if (type === "user") {
                setChangeTab("user");
                setUserData(data);
              }
            }}
          />

          <TabVehicleManagement
            name={fleetDetails?.name}
            fleetData={fleetData}
            pageNo={pageNoV}
            record={recordV}
            filter={filterV}
            handlePageNo={(page) => {
              setPageNoV(page);
            }}
            handleRecord={(rec) => {
              setRecordV(rec);
            }}
            handleFilter={(flt) => {
              setFilterV(flt);
            }}
            handleClick={(type, data) => {
              if (type === "vehicle") {
                setChangeTab("vehicle");
                setVehicleData({});
              } else if (type === "vehicleDetails") {
                setChangeTab("vehicleDetails");
                setVehicleData(data);
              } else if (type === "editVehicle") {
                setVehicleData(data);
                setTimeout(() => {
                  setChangeTab("vehicle");
                }, 200);
              }
            }}
          />
        </>
      )}
    </>
  );
}
