import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    padding: 20,
    overflow: "auto",
    border: `1px solid ${color.bordercolor}`,
  },
  tabBtn: {
    padding: "12px 20px",
  },
  inputFieldStyle: {
    marginBottom: 0,
    marginTop: 0,
  },
  dataMain: {
    height: 500,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconOpen: {},
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
