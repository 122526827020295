import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    padding: 20,
    boxSizing: "border-box",
    // overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none !important",
    },
    backgroundColor: color.white,
    border: `1px solid ${color.bordercolor}`,
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  autocomplete: {
    paddingLeft: "0px !important",
  },
  inputFieldStyle: {
    marginBottom: 0,
    marginTop: 0,
  },
}));
export default useStyles;
