import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles";
import { Setting } from "../../../Utils/Setting";
import { color } from "../../../Config/theme.js";
import {
  Grid,
  Button,
  Typography,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Tooltip,
  Popover,
  ListItemButton,
  CircularProgress,
  TableHead,
  Pagination,
} from "@mui/material";
import { Search, FilterAlt, Edit } from "@mui/icons-material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import InfoIcon from "@mui/icons-material/Info";
import Images from "../../../Config/Images";
import { getApiData } from "../../../Utils/APIHelper.js";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isArray, isEmpty } from "lodash";
import { tyre_condition } from "../../../Config/Static_Data";
import moment from "moment";
import { isTablet } from "react-device-detect";
import TabTyreHealthVisualization from "../TabTyreHealthVisualization";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle";

export default function TabTyreList(props) {
  const styles = useStyles();
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    vehicleData,
  } = props;
  // const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [brandList, setbrandList] = useState(false);
  const [typeList, settypeList] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [conditionList, setconditionList] = useState(false);
  const [plyRating, setplyRating] = useState(false);
  const [tyreListRows, setTyreListRows] = useState({});
  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;
  const { token, dropDownData, userdata } = useSelector((state) => state.auth);
  const [activeIndex, setActiveIndex] = useState("");
  const isDisabled = isArray(tyreListRows?.rows) && isEmpty(tyreListRows?.rows);

  // intialRender used for stop multiple time apiCall in
  const initialRender = useRef(true);

  useEffect(() => {
    getTyreList(false);
  }, []);

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      setPageLoad(true);
      getTyreList(false);
    }
  }, [pageNo, record, filter]);

  useEffect(() => {
    const section = document.querySelector(`#jump_to_meT`);
    section.scrollIntoView({ behavior: "auto", block: "center" });
  }, [pageNo]);

  useEffect(() => {
    setActiveIndex(null);
  }, [pageNo, record]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);

  async function getTyreList(bool) {
    setActiveIndex(null);
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreList}?vehicle_id=${
          vehicleData?.id
        }&filter=${filter}&per_page=${record}&search=${
          bool ? "" : searchFilter
        }&page=${pageNo}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setTyreListRows(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }
  const Reset = () => {
    handleFilter("");
    handlePageNo(1);
    setSearchFilter("");
    getTyreList(true);
  };
  const TyreBrand = (index) => {
    let array = tyreListRows?.rows[index]?.tyre_brand_id;
    let res = dropDownData?.tyre_brand?.find((item) => array === item?.id);
    return <Typography>{res?.label}</Typography>;
  };
  const TyreSerialNo = (index) => {
    return (
      <div
        style={{ display: "flex", flex: 1 }}
        onClick={() => {
          handleClick("tyreDetails", tyreListRows?.rows[index]);
        }}
      >
        <Typography
          style={{
            whiteSpace: "pre-wrap",
            color: color.primary,
            fontWeight: "bold",
          }}
        >
          {tyreListRows?.rows[index]?.tyre_serial_no}
        </Typography>
      </div>
    );
  };
  const TyreCondition = (index) => {
    return (
      <Grid item display="flex" alignItems="center">
        <Grid
          item
          style={{
            backgroundColor:
              tyreListRows?.rows[index]?.tyre_condition === "Average"
                ? "#FFFDD9"
                : tyreListRows?.rows[index]?.tyre_condition === "Good"
                ? "#D9FFE4"
                : tyreListRows?.rows[index]?.tyre_condition === "Bad"
                ? "#FFD9D9"
                : "",
            padding: 6,
            borderRadius: 4,
          }}
        >
          <Typography>
            {tyreListRows?.rows[index]?.tyre_condition || "-"}
          </Typography>
        </Grid>
      </Grid>
    );
  };
  const OriginalNSD = (index) => {
    return (
      <Typography>{tyreListRows?.rows[index]?.original_nsd || 0} mm</Typography>
    );
  };

  const CurrentNSD = (index) => {
    const nsdArr = tyreListRows?.rows[index]?.current_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return (
      <Typography>
        {`${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
          groove2 !== 0 ? groove2.toFixed(2) : " - "
        }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
          groove4 !== 0 ? groove4.toFixed(2) : " - "
        } mm`}
      </Typography>
    );
  };

  const FitmentNSD = (index) => {
    return (
      <Typography style={{ fontSize: 14 }}>
        {(
          (Number(tyreListRows?.rows[index]?.outer_nsd || 0) +
            Number(tyreListRows?.rows[index]?.middle_nsd || 0) +
            Number(tyreListRows?.rows[index]?.inner_nsd || 0)) /
          3
        ).toFixed(2)}
        mm
      </Typography>
    );
  };
  const PlyRating = (index) => {
    let array = tyreListRows?.rows[index]?.ply_rating_id;
    let res = dropDownData?.ply_rating?.find((item) => array === item?.id);
    return <Typography>{res?.label}</Typography>;
  };
  const PurchaseCost = (index) => {
    return (
      <Typography>
        {tyreListRows?.rows[index]?.purchase_cost ? "₹ " : "-"}
        {tyreListRows?.rows[index]?.purchase_cost}
      </Typography>
    );
  };
  const AddedOn = (index) => {
    const timestamp = tyreListRows?.rows[index]?.capture_date;
    // const date = new Date(timestamp * 1000);

    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const getFitmentDate = (index) => {
    const timestamp = tyreListRows?.rows[index]?.fitment_date;
    // const date = new Date(timestamp * 1000);

    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : "-"}
      </div>
    );
  };

  return (
    <>
      {isTablet ? (
        <div
          className={styles.container}
          style={{ marginTop: 20, padding: 20 }}
        >
          <Grid
            container
            style={{
              justifyContent: "space-between",
              minHeight: 40,
              alignItems: "center",
            }}
            wrap="nowrap"
          >
            <Typography variant="tableTitle" id="jump_to_meT">
              {tabValue === 0 ? "Tyre List" : "Tyre & Axle Health"}
            </Typography>
            <Grid item alignItems="center" display="flex" wrap="nowrap">
              {tabValue === 0 ? (
                <Grid item alignItems="center" display="flex" wrap="nowrap">
                  <Button
                    style={{
                      marginLeft: 10,
                      color:
                        Number(vehicleData?.no_of_tyres) -
                          Number(tyreListRows?.pagination?.totalCount) ===
                        0
                          ? "#000"
                          : color.white,
                    }}
                    variant="contained"
                    onClick={() => {
                      handleClick("addTyre", "");
                    }}
                    disabled={
                      Number(vehicleData?.no_of_tyres) -
                        Number(tyreListRows?.pagination?.totalCount) ===
                      0
                    }
                  >
                    Add Tyre
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: 10,
                      backgroundColor: isDisabled
                        ? "rgba(0, 0, 0, 0.12)"
                        : color.secondary,
                      color: isDisabled ? "#000" : color.white,
                    }}
                    onClick={() => {
                      handleClick(
                        "removeTyre",
                        tyreListRows?.pagination?.fitment_reading
                      );
                    }}
                    disabled={isDisabled}
                  >
                    Remove Tyre
                  </Button>
                </Grid>
              ) : (
                ""
              )}
              {/* <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isDisabled
                      ? "rgba(0, 0, 0, 0.12)"
                      : color.secondary,
                    color: isDisabled ? "#000" : color.white,
                  }}
                  onClick={() => {
                    handleClick("switchTyre", tyreListRows);
                  }}
                  disabled={isDisabled}
                >
                  Switch Tyre
                </Button> */}
              <Tooltip title="Tabs" placement="bottom" arrow>
                <Button
                  style={{
                    marginLeft: 10,
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <img src={Images.tabs} alt="" />
                </Button>
              </Tooltip>
              <Popover
                className="popnoti"
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                id={id}
                open={pop}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ListItemButton
                  selected={tabValue === 0}
                  onClick={() => {
                    handlePageNo(1);
                    handleRecord(10);
                    handleFilter("");
                    setAnchorEl(null);
                    setTabValue(0);
                  }}
                >
                  Tyre List
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 1}
                  onClick={() => {
                    setAnchorEl(null);
                    setTabValue(1);
                  }}
                >
                  Tyre & Axle Health
                </ListItemButton>
              </Popover>
            </Grid>
          </Grid>
          {tabValue === 0 ? (
            <div>
              <Grid
                item
                alignItems={"center"}
                style={{ display: "flex", marginTop: 10 }}
                wrap="nowrap"
              >
                <Grid
                  item
                  style={{
                    backgroundColor: "#D9F8FF",
                    padding: 6,
                    marginRight: 10,
                  }}
                >
                  <Typography>{`Total Tyres: ${vehicleData?.no_of_tyres}`}</Typography>
                </Grid>
                <Grid
                  item
                  style={{
                    backgroundColor: "#D9FFE4",
                    padding: 6,
                    marginRight: 10,
                  }}
                >
                  <Typography>{`Added Tyres: ${tyreListRows?.pagination?.totalCount}`}</Typography>
                </Grid>
                <Grid style={{ backgroundColor: "#FFD9D9", padding: 6 }}>
                  <Typography>{`Remaining Tyres: ${
                    vehicleData?.no_of_tyres -
                    tyreListRows?.pagination?.totalCount
                  }`}</Typography>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 14,
                }}
                wrap="nowrap"
              >
                <Grid item display="flex" wrap="nowrap" alignItems={"center"}>
                  <Select
                    placeholder="Filter"
                    value={filter}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        setbrandList(false);
                        settypeList(false);
                        setconditionList(false);
                        setplyRating(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={
                      (filter === "" ? { color: "#A2A2A2" } : {},
                      { marginRight: 10 })
                    }
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setbrandList(!brandList);
                        settypeList(false);
                        setconditionList(false);
                        setplyRating(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Brand</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_brand.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: brandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        settypeList(!typeList);
                        setbrandList(false);
                        setconditionList(false);
                        setplyRating(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Axle Type</Typography>
                      {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.axle_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: typeList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setconditionList(!conditionList);
                        settypeList(false);
                        setplyRating(false);
                        setbrandList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Condition</Typography>
                      {conditionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {tyre_condition.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: conditionList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.label}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setplyRating(!plyRating);
                        setbrandList(false);
                        settypeList(false);
                        setconditionList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Ply Rating</Typography>
                      {plyRating ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.ply_rating.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: plyRating ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getTyreList(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilter)) {
                          handlePageNo(1);
                          getTyreList(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search style={{ fontSize: 20 }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Tyre Serial No., Tyre Position and Purchased From"
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary, marginLeft: 10 }}
                    onClick={() => {
                      if (!isEmpty(searchFilter)) {
                        getTyreList(false);
                      }
                    }}
                  >
                    <Search />
                  </Button>
                  <Button
                    variant="contained"
                    style={{ margin: "0 10px" }}
                    disabled={!searchFilter && !filter ? true : false}
                    onClick={() => Reset()}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
                <Grid item style={{ display: "flex" }}>
                  <Tooltip title="Record" placement="bottom" arrow>
                    <Select
                      value={record}
                      onChange={(v) => {
                        handlePageNo(1);
                        handleRecord(v.target.value);
                      }}
                      style={{ marginLeft: 10 }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Tooltip>
                </Grid>
              </Grid>

              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(tyreListRows?.rows) &&
                !isEmpty(tyreListRows?.rows) ? (
                <>
                  {tyreListRows?.rows?.map((item, index) => {
                    return (
                      <Grid
                        container
                        style={{
                          marginTop: 10,
                          border: `1px solid ${color.bordercolor}`,
                          overflow: "auto",
                        }}
                      >
                        <Table className={styles.customtable}>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Tyre Serial No.
                              </TableCell>
                              <TableCell variant="head">
                                Tyre Position
                              </TableCell>
                              <TableCell variant="head">Axle Type</TableCell>
                              <TableCell variant="head">
                                Tyre Condition
                              </TableCell>
                              <TableCell variant="head">Tyre Type</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {TyreSerialNo(index) || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.tyre_position || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.axle_type_name || "-"}
                              </TableCell>
                              {/* <TableCell>
                                {item?.tyre_air_pressure || "-"}
                                {item?.tyre_air_pressure ? " psi" : ""}
                              </TableCell> */}
                              <TableCell>
                                {TyreCondition(index) || "-"}
                              </TableCell>
                              <TableCell>{item?.tyre_type || "-"}</TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">Tyre Brand</TableCell>
                              <TableCell variant="head">
                                Item Description
                              </TableCell>
                              <TableCell variant="head">
                                Tyre Composition
                              </TableCell>
                              <TableCell variant="head">
                                Tread Pattern
                              </TableCell>
                              <TableCell variant="head">Ply Rating</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{TyreBrand(index) || "-"}</TableCell>
                              <TableCell>
                                {item?.is_new_tyre === 1
                                  ? item?.new_tyre_name
                                  : item?.tyre_pattern_size || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.tyre_composition || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.tread_pattern || "-"}
                              </TableCell>
                              <TableCell>{PlyRating(index) || "-"}</TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Intial Reading
                              </TableCell>
                              <TableCell variant="head">Original NSD</TableCell>
                              <TableCell variant="head">Initial NSD</TableCell>
                              <TableCell variant="head">
                                Avg. Initial NSD
                              </TableCell>
                              <TableCell variant="head">
                                Initial Air Pressure
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {item?.init_km || "-"}
                                {item?.init_km ? " km" : ""}
                              </TableCell>
                              <TableCell>{OriginalNSD(index) || "-"}</TableCell>
                              <TableCell>{CurrentNSD(index) || "-"}</TableCell>
                              <TableCell>
                                {`${item?.avg_nsd} mm` || "-"}
                              </TableCell>
                              <TableCell>
                                <Typography style={{ fontSize: 14 }}>
                                  {item?.tyre_air_pressure}
                                  {item?.tyre_air_pressure ? " psi" : "-"}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Purchase Cost
                              </TableCell>
                              <TableCell variant="head">Casing Value</TableCell>
                              <TableCell variant="head">
                                Purchased From
                              </TableCell>
                              <TableCell variant="head">Added On</TableCell>
                              <TableCell variant="head" colSpan={5}>
                                Fitment Date
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {PurchaseCost(index) || "-"}
                              </TableCell>
                              <TableCell>
                                <Typography>
                                  {item?.casing_value ? "₹ " : ""}
                                  {item?.casing_value || "-"}
                                </Typography>
                              </TableCell>
                              <TableCell>
                                {item?.purchased_from || "-"}
                              </TableCell>
                              <TableCell>{AddedOn(index) || "-"}</TableCell>
                              <TableCell colSpan={5}>
                                {getFitmentDate(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableBody>

                          <TableBody>
                            <TableRow>
                              <TableCell padding="10px 20px">
                                <Button
                                  variant="contained"
                                  disabled={userdata?.role === "fleet_owner"}
                                  onClick={() => {
                                    handleClick(
                                      "addTyre",
                                      tyreListRows?.rows[index]
                                    );
                                  }}
                                >
                                  <Edit />
                                </Button>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(tyreListRows?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={2}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        handlePageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </div>
          ) : (
            <TabTyreHealthVisualization vehicleData={vehicleData} />
          )}
        </div>
      ) : (
        <div
          className={styles.container}
          style={{ marginTop: 10, padding: 10 }}
        >
          <Grid
            container
            style={{
              minHeight: 40,
            }}
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Typography variant="tableTitle" id="jump_to_meT">
              {tabValue === 0 ? "Tyre List" : "Tyre & Axle Health"}
            </Typography>
            <Grid display="flex" wrap="nowrap">
              {tabValue === 0 && (
                <Grid display="flex" wrap="nowrap">
                  <Button
                    style={{
                      color:
                        Number(vehicleData?.no_of_tyres) -
                          Number(tyreListRows?.pagination?.totalCount) ===
                        0
                          ? "#000"
                          : color.white,
                    }}
                    variant="contained"
                    onClick={() => {
                      handleClick("addTyre", "");
                    }}
                    disabled={
                      Number(vehicleData?.no_of_tyres) -
                        Number(tyreListRows?.pagination?.totalCount) ===
                      0
                    }
                  >
                    Add Tyre
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: 10,
                      backgroundColor: isDisabled
                        ? "rgba(0, 0, 0, 0.12)"
                        : color.secondary,
                      color: isDisabled ? "#000" : color.white,
                    }}
                    onClick={() => {
                      handleClick(
                        "removeTyre",
                        tyreListRows?.pagination?.fitment_reading
                      );
                    }}
                    disabled={isDisabled}
                  >
                    Remove Tyre
                  </Button>
                  {/* <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isDisabled
                      ? "rgba(0, 0, 0, 0.12)"
                      : color.secondary,
                    color: isDisabled ? "#000" : color.white,
                  }}
                  onClick={() => {
                    handleClick("switchTyre", tyreListRows);
                  }}
                  disabled={isDisabled}
                >
                  Switch Tyre
                </Button> */}
                </Grid>
              )}
              <Grid>
                <Tooltip title="Tabs" placement="bottom" arrow>
                  <Button
                    style={{
                      backgroundColor: "#BBBBBB",
                      marginLeft: 10,
                    }}
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVertOutlinedIcon style={{ color: color.black }} />
                  </Button>
                </Tooltip>
                <Popover
                  className="popnoti"
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    selected={tabValue === 0}
                    onClick={() => {
                      handlePageNo(1);
                      handleRecord(10);
                      handleFilter("");
                      setAnchorEl(null);
                      setTabValue(0);
                    }}
                  >
                    Tyre List
                  </ListItemButton>
                  <ListItemButton
                    selected={tabValue === 1}
                    onClick={() => {
                      setAnchorEl(null);
                      setTabValue(1);
                    }}
                  >
                    Tyre & Axle Health
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          {tabValue === 0 ? (
            <div>
              <Grid
                item
                container
                style={{ marginTop: 10 }}
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
                columnGap={1}
              >
                <Typography
                  fullWidth
                  style={{ backgroundColor: "#D9F8FF", padding: 6 }}
                >{`Total Tyres: ${vehicleData?.no_of_tyres}`}</Typography>
                <Typography
                  fullWidth
                  style={{ backgroundColor: "#D9FFE4", padding: 6 }}
                >{`Added Tyres: ${tyreListRows?.pagination?.totalCount}`}</Typography>
                <Typography
                  fullWidth
                  style={{ backgroundColor: "#FFD9D9  ", padding: 6 }}
                >{`Remaining Tyres: ${
                  vehicleData?.no_of_tyres -
                  tyreListRows?.pagination?.totalCount
                }`}</Typography>
              </Grid>
              <Grid
                item
                style={{ marginTop: 10 }}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Select
                  placeholder="Filter"
                  value={filter}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleFilter(v.target.value);
                  }}
                  onOpen={() => {
                    if (isEmpty(filter.toString())) {
                      setbrandList(false);
                      settypeList(false);
                      setconditionList(false);
                      setplyRating(false);
                    }
                  }}
                  displayEmpty
                  IconComponent={FilterAlt}
                  style={filter === "" ? { color: "#A2A2A2" } : {}}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Filter
                  </MenuItem>
                  <ListItemButton
                    onClick={() => {
                      setbrandList(!brandList);
                      settypeList(false);
                      setconditionList(false);
                      setplyRating(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Brand</Typography>
                    {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.tyre_brand.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: brandList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      settypeList(!typeList);
                      setbrandList(false);
                      setconditionList(false);
                      setplyRating(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Axle Type</Typography>
                    {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.axle_type.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: typeList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setconditionList(!conditionList);
                      settypeList(false);
                      setplyRating(false);
                      setbrandList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Condition</Typography>
                    {conditionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {tyre_condition.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: conditionList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.label}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setplyRating(!plyRating);
                      setbrandList(false);
                      settypeList(false);
                      setconditionList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Ply Rating</Typography>
                    {plyRating ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.ply_rating.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: plyRating ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
                <Tooltip title="Record" placement="bottom" arrow>
                  <Select
                    value={record}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleRecord(v.target.value);
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Tooltip>
              </Grid>
              <Grid
                md={12}
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <Grid
                  item
                  container
                  display="flex"
                  wrap="nowrap"
                  alignItems={"center"}
                >
                  <Grid item xs={10}>
                    <TextField
                      fullWidth
                      placeholder="Search"
                      value={searchFilter}
                      onChange={(v) => {
                        isEmpty(v.target.value) && getTyreList(true);
                        setSearchFilter(v?.target?.value);
                      }}
                      onKeyDown={(v) => {
                        if (v.key === "Enter") {
                          if (!isEmpty(searchFilter)) {
                            handlePageNo(1);
                            getTyreList(false);
                          }
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search style={{ fontSize: 20 }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div style={{ lineHeight: 0, cursor: "pointer" }}>
                              <Tooltip
                                title="Search by Tyre Serial No., Tyre Position and Purchased From"
                                placement="bottom"
                                arrow
                              >
                                <InfoIcon style={{ fontSize: 20 }} />
                              </Tooltip>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid item xs={2} display="flex" wrap="nowrap">
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: color.secondary,
                        marginLeft: 10,
                      }}
                      onClick={() => {
                        if (!isEmpty(searchFilter)) {
                          getTyreList(false);
                        }
                      }}
                    >
                      <Search />
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginLeft: 10 }}
                      disabled={!searchFilter && !filter ? true : false}
                      onClick={() => Reset()}
                    >
                      <ResetIcon />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(tyreListRows?.rows) &&
                !isEmpty(tyreListRows?.rows) ? (
                <>
                  {tyreListRows?.rows?.map((item, index) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <Grid
                          item
                          container
                          id={`jump_to_me${index}`}
                          style={{
                            marginTop: 20,
                            border: `1px solid ${color.bordercolor}`,
                            alignItems: "center",
                            overflow: "hidden",
                          }}
                        >
                          {activeIndex !== index ? (
                            <Table className={styles.customtableMobile}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tyre Serial No.
                                  </TableCell>
                                  <TableCell variant="head">
                                    Tyre Position
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {TyreSerialNo(index) || "-"}
                                  </TableCell>
                                  <TableCell variant="body">
                                    {item?.tyre_position || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tyre Brand
                                  </TableCell>
                                  <TableCell variant="head">
                                    Item Description
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {TyreBrand(index) || ""}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.is_new_tyre === 1
                                      ? item?.new_tyre_name
                                      : item?.tyre_pattern_size || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          ) : (
                            <Table className={styles.customtableMobile}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tyre Serial No.
                                  </TableCell>
                                  <TableCell variant="head">
                                    Tyre Position
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {TyreSerialNo(index) || "-"}
                                  </TableCell>
                                  <TableCell variant="body">
                                    {item?.tyre_position || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tyre Brand
                                  </TableCell>
                                  <TableCell variant="head">
                                    Item Description
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {TyreBrand(index) || ""}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.is_new_tyre === 1
                                      ? item?.new_tyre_name
                                      : item?.tyre_pattern_size || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tyre Type
                                  </TableCell>
                                  <TableCell variant="head">
                                    Axle Type
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.tyre_type || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.axle_type_name || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tyre Condition
                                  </TableCell>

                                  <TableCell variant="head">
                                    Tyre Composition
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {TyreCondition(index) || "-"}
                                  </TableCell>

                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.tyre_composition || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tread Pattern
                                  </TableCell>
                                  <TableCell variant="head">
                                    Ply Rating
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.tread_pattern || "-"}
                                  </TableCell>
                                  <TableCell variant="body">
                                    {PlyRating(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Intial Reading
                                  </TableCell>
                                  <TableCell variant="head">
                                    Original NSD
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.init_km || "-"}
                                    {item?.init_km ? " km" : ""}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {OriginalNSD(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Initial NSD
                                  </TableCell>
                                  <TableCell variant="head">
                                    Avg. Initial NSD
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {CurrentNSD(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {`${item?.avg_nsd} mm` || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Initial Air Pressure
                                  </TableCell>
                                  <TableCell variant="head">
                                    Purchase Cost
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.tyre_air_pressure}
                                    {item?.tyre_air_pressure ? " psi" : "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {PurchaseCost(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell>Casing Value</TableCell>
                                  <TableCell variant="head">
                                    Purchased From
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.purchased_from || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.casing_value ? "₹ " : ""}
                                    {item?.casing_value || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">Added On</TableCell>
                                  <TableCell style={{ paddingBottom: 5 }}>
                                    Fitment Date
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {AddedOn(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getFitmentDate(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    rowSpan={2}
                                    colSpan={2}
                                    style={{ paddingBottom: 5 }}
                                  >
                                    <Button
                                      variant="contained"
                                      disabled={
                                        userdata?.role === "fleet_owner"
                                      }
                                      style={{
                                        marginRight: 10,
                                      }}
                                      onClick={() => {
                                        handleClick(
                                          "addTyre",
                                          tyreListRows?.rows[index]
                                        );
                                      }}
                                    >
                                      <Edit />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          )}
                          <div
                            onClick={(v) => {
                              if (activeIndex === index) {
                                setActiveIndex(null);
                              } else {
                                setActiveIndex(index);
                              }
                            }}
                            style={{
                              position: "absolute",
                              bottom: -18,
                              left: "45%",
                            }}
                          >
                            {activeIndex === index ? (
                              <div style={{ transform: "rotate(180deg)" }}>
                                <TableToggle />
                              </div>
                            ) : (
                              <TableToggle />
                            )}
                          </div>
                        </Grid>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(tyreListRows?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        handlePageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </div>
          ) : (
            <TabTyreHealthVisualization vehicleData={vehicleData} />
          )}
        </div>
      )}
    </>
  );
}
