import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting";
import Box from "@mui/material/Box";
import { FormHelperText, InputAdornment } from "@mui/material";
import useStyles from "./styles.js";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { isEmpty, isNull } from "lodash";

import { color } from "../../../Config/theme";
import { CTypography } from "../AddFleetForm/index.js";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import ConfirmDialog from "../../ConfirmDialog/index.js";
import { useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";

const errorObj = {
  privilegeErr: false,
  userIdErr: false,
  userpwdErr: false,
  userEmlErr: false,
  FnameErr: false,
  contactNumErr: false,
  selectedFileErr: false,
  selectedFileMsg: "",
  privilegeMsg: "",
  userIdMsg: "",
  userpwdMsg: "",
  userEmlMsg: "",
  FnameMsg: "",
  contactNumMsg: "",
};

export default function FAddUser(props) {
  const { handleClick = () => null, fleetData = {}, editData = {} } = props;
  const styles = useStyles();
  const isEdit = !isEmpty(editData);

  const email_Regex = Setting.JS_Regex.email_Regex;

  const [errObj, setErrObj] = useState(errorObj);
  const [loader, setLoader] = useState(false);
  const [privilege, setprivilege] = useState("");
  const [userId, setuserId] = useState("");
  const [userpwd, setuserpwd] = useState("");
  const [userEml, setuserEml] = useState("");
  const [Fname, setFname] = useState("");
  const [contactNum, setcontactNum] = useState("");
  const [file, setFile] = useState("No file selected");
  const [selectedFile, setSlectedFile] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const { isOnline } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isEdit) {
      const fileName = !isEmpty(editData?.business_pan_card)
        ? editData?.business_pan_card.split("/")
        : "No file selected";
      setprivilege(editData?.role || "");
      setuserId(editData?.user_name || "");
      setuserpwd(editData?.userpwd || "");
      setFname(editData?.name || "");
      setuserEml(editData?.email || "");
      setcontactNum(editData?.phone || "");
      setSlectedFile(editData?.business_pan_card || null);
      setFile(
        !isEmpty(editData?.business_pan_card)
          ? fileName[fileName.length - 1]
          : fileName
      );
    }
  }, []);

  // useEffect(() => {
  //   setErrObj(errorObj);

  //   if (privilege === "fleet_owner" && !isEdit) {
  //     setFname(fleetData?.owner_name || "");
  //     setcontactNum(fleetData?.owner_phone || "");
  //     setuserId(fleetData?.owner_phone || "");
  //   } else if (privilege === "fleet_manager" && !isEdit) {
  //     setFname("");
  //     setcontactNum("");
  //     setuserId("");
  //   }
  // }, [privilege]);

  useEffect(() => {
    if (isTablet || isMobile) {
      const section = document.querySelector("#jump_to_me");
      section.scrollIntoView({ behavior: "auto", block: "center" });
    }
  }, []);

  const submitData = async () => {
    setRemoveBtnLoad(true);
    !isEdit && setLoader(true);
    let endPoints = Setting.endpoints.addfleetuser;
    const params = {
      "User[name]": Fname,
      "User[email]": userEml,
      "User[fleet_allocated]": fleetData?.id,
      "User[phone]": contactNum,
      "User[role]": privilege,
    };
    if (!isEdit) {
      params["User[password_hash]"] = userpwd;
      params["User[user_name]"] = userId;
    }
    if (isEdit) {
      params["User[id]"] = editData?.id;
    }
    if (privilege === "fleet_owner") {
      params["User[business_pan_card]"] = selectedFile;
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        handleClick("cancel");
        clearData();
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
      setRemoveBtnLoad(false);
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setRemoveBtnLoad(false);
      setLoader(false);
    }
  };

  function clearData() {
    setprivilege("");
    setuserId("");
    setuserpwd("");
    setuserEml("");
    setFname("");
    setcontactNum("");
    setSlectedFile(null);
    setFile("No file selected");
  }

  const checkValidation = () => {
    const error = { ...errObj };
    let valid = true;
    if (isEmpty(privilege)) {
      valid = false;
      error.privilegeErr = true;
      error.privilegeMsg = "Please select Privilege ";
    }

    if (isEmpty(userId.trim()) && !isEdit) {
      valid = false;
      error.userIdErr = true;
      error.userIdMsg = "Please enter the User ID";
    } else if (contactNum !== userId && !isEdit) {
      valid = false;
      error.userIdErr = true;
      error.userIdMsg = "user ID & Contact No. must be Same";
    }

    if (isEmpty(userpwd.trim()) && !isEdit) {
      valid = false;
      error.userpwdErr = true;
      error.userpwdMsg = "Please enter the User Password";
    }

    if (isEmpty(Fname.trim())) {
      valid = false;
      error.FnameErr = true;
      error.FnameMsg = "Please enter the Name";
    }

    if (!isEmpty(userEml.trim()) && !email_Regex.test(userEml)) {
      valid = false;
      error.userEmlErr = true;
      error.userEmlMsg = "Please enter valid Email";
    }
    if (isEmpty(contactNum.trim())) {
      valid = false;
      error.contactNumErr = true;
      error.contactNumMsg = "Please enter Contact No.";
    } else if (contactNum.trim().length < 10) {
      valid = false;
      error.contactNumErr = true;
      error.contactNumMsg = "Please enter valid Contact No.";
    }
    if (privilege === "fleet_owner" && isNull(selectedFile)) {
      valid = false;
      error.selectedFileErr = true;
      error.selectedFileMsg = "Please upload PAN Card";
    }

    setErrObj(error);
    if (valid) {
      if (isEdit) {
        setConfirmDialog(true);
      } else {
        setLoader(true);
        submitData();
      }
    }
  };

  return (
    <Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
        }}
      >
        <Typography fontWeight={"bold"} id="jump_to_me">
          {isEdit ? "Edit User" : "Add User"}
        </Typography>
      </Box>

      <Box className={styles.container}>
        <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={4} sm={3} md={2} lg={2}>
            <Typography>Fleet Name:</Typography>
          </Grid>

          <Grid item xs={8} md={10} lg={10}>
            <Typography>{fleetData?.name}</Typography>
          </Grid>
        </Grid>
        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: errObj.privilegeErr ? 30 : 20 }}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Privilege"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth error={errObj.privilegeErr}>
                <Select
                  displayEmpty
                  value={privilege}
                  onChange={(event) => {
                    setprivilege(event.target.value);
                    setErrObj({
                      ...errObj,
                      privilegeErr: false,
                      privilegeMsg: "",
                    });
                  }}
                  size={"medium"}
                  style={privilege === "" ? { color: "#A2A2A2" } : {}}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select Privilege
                  </MenuItem>
                  <MenuItem value={"fleet_manager"}>Fleet Manager</MenuItem>
                  <MenuItem value={"fleet_owner"}>Fleet Owner</MenuItem>
                </Select>
                {errObj.privilegeErr ? (
                  <FormHelperText>
                    {errObj.privilegeMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.contactNumErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Contact No."} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Contact No. of the User"
              value={contactNum}
              error={errObj.contactNumErr}
              helperText={errObj.contactNumErr ? errObj.contactNumMsg : null}
              onChange={(e) => {
                setcontactNum(e.target.value.replace(/[^0-9]/g, ""));
                setuserId(e.target.value.replace(/[^0-9]/g, ""));
                setErrObj({
                  ...errObj,
                  contactNumErr: false,
                  contactNumMsg: "",
                  userIdErr: false,
                  userIdMsg: "",
                });
              }}
              inputProps={{
                onWheel: (event) => event.currentTarget.blur(),
                maxLength: 11,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">+91</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: errObj.userIdErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"User ID"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter User ID"
              value={userId}
              error={errObj.userIdErr}
              helperText={errObj.userIdErr ? errObj.userIdMsg : null}
              onChange={(e) => {
                setuserId(e.target.value.replace(/[^0-9]/g, ""));
                setErrObj({
                  ...errObj,
                  userIdErr: false,
                  userIdMsg: "",
                });
              }}
              inputProps={{ readOnly: true }}
            />
          </Grid>
        </Grid>

        {!isEdit && (
          <Grid
            container
            style={{ marginBottom: errObj.userpwdErr ? 30 : 20 }}
            alignItems={"center"}
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"User Password"} />
            </Grid>

            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter User Password"
                value={userpwd}
                error={errObj.userpwdErr}
                helperText={errObj.userpwdErr ? errObj.userpwdMsg : null}
                onChange={(e) => {
                  setuserpwd(e.target.value);
                  setErrObj({
                    ...errObj,
                    userpwdErr: false,
                    userpwdMsg: "",
                  });
                }}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          style={{ marginBottom: errObj.FnameErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Name"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter the Name"
              value={Fname}
              error={errObj.FnameErr}
              helperText={errObj.FnameErr ? errObj.FnameMsg : null}
              onChange={(e) => {
                setFname(e.target.value);
                setErrObj({ ...errObj, FnameErr: false, FnameMsg: "" });
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: errObj.userEmlErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Email ID :</Typography>
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Email ID of the User"
              value={userEml}
              error={errObj.userEmlErr}
              helperText={errObj.userEmlErr ? errObj.userEmlMsg : null}
              onChange={(e) => {
                setuserEml(e.target.value);
                setErrObj({
                  ...errObj,
                  userEmlErr: false,
                  userEmlMsg: "",
                });
              }}
            />
          </Grid>
        </Grid>
        {privilege === "fleet_owner" ? (
          <Grid
            container
            alignItems={"center"}
            style={{
              marginBottom: errObj.selectedFileErr ? 30 : 20,
            }}
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography
                required
                title={"Upload Business PAN Card"}
                style={{ marginRight: 20 }}
              />
            </Grid>

            <Grid
              item
              xs={12}
              md={10}
              lg={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                style={{
                  color:
                    !selectedFile || selectedFile === "" ? "#999" : color.black,
                }}
              >
                {file}
              </Typography>

              <Button
                variant="contained"
                component="label"
                style={{
                  backgroundColor: color.secondary,
                  margin: "0 20px",
                }}
              >
                Upload File
                <input
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0].name);
                    setSlectedFile(e.target.files[0]);
                    setErrObj({
                      ...errObj,
                      selectedFileErr: false,
                      selectedFileMsg: "",
                    });
                  }}
                  accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg, application/pdf"
                  hidden
                />
              </Button>
              {errObj.selectedFileErr ? (
                <Typography style={{ color: "red", fontSize: 12 }}>
                  {errObj.selectedFileMsg}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
        ) : null}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginBottom: 20 }}
        >
          <Button
            disabled={isOnline ? false : true}
            onClick={checkValidation}
            style={{
              backgroundColor: isOnline ? color.primary : color.bordercolor,
              marginRight: 10,
              width: isMobile ? 120 : 130,
            }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : isEdit ? (
              `Save & Update`
            ) : (
              `Add User`
            )}
          </Button>
          <Button
            style={{
              backgroundColor: color.secondary,
              width: isMobile ? 120 : 130,
            }}
            onClick={() => {
              handleClick("cancel");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
      <ConfirmDialog
        title={`Are you sure you want to Edit this User?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            setRemoveBtnLoad(true);
            submitData();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </Box>
  );
}
