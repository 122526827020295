import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";
import { Setting } from "../../../Utils/Setting";
const { isTablate, isMobile } = Setting;
const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    "@media (max-width:425px)": {
      padding: 10,
    },
  },
  "table, td, tr, th": {
    border: `1px solid ${color.bordercolor} !important`,
    padding: 10,
    backgroundColor: "red",
  },
  "th, td": {
    textAlign: "center",
  },
  MuiTableCell: {
    root: {
      backgroundColor: "pink",
    },
    head: {
      fontWeight: "bold",
    },
  },
  customtable: {
    "& tr td": {
      border: "none !important",
      padding: 0,
      color: "#666666CC",
      width: "50%",
    },
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
  },
  customtableMobile: {
    "& tr td": {
      border: "none !important",
      padding: 0,
      color: "#666666CC",
      width: "50%",
    },
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: " #FBF8F8",
      },
    },
  },
  scrollBtn: {
    position: "absolute",
    padding: 5,
    bottom: 0,
    background:
      "linear-gradient(180deg, rgba(217, 248, 255, 0) 0%, rgba(54, 65, 80, 0.5) 100%)",
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
}));
export default useStyles;
