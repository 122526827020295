import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import useStyles from "./styles";
import {
  Grid,
  Typography,
  TextField,
  InputAdornment,
  Select,
  MenuItem,
  Pagination,
  Button,
  CircularProgress,
  Tooltip,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isObject } from "lodash";
import { Search } from "@mui/icons-material";
import { color } from "../../../Config/theme";
import { Setting } from "../../../Utils/Setting";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment";

function SwitchHistory(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleSearch = () => null,
    search = "",
    pageNo = 1,
    record = 10,
  } = props;
  const { token, userdata } = useSelector((state) => state.auth);

  const styles = useStyles();
  // const [searchFilter, setSearchFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);

  const [tyreData, setTyreData] = useState({});
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const columns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                color: params?.row?.added_tyre_count !== 0 && "red",
              }}
            >
              {params?.row?.added_tyre_count}
            </span>
            <span>/{params?.row?.no_of_tyres}</span>
          </>
        );
      },
    },
    {
      field: "rotation_count",
      headerName: "No. of Rotations",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "created_at",
      headerName: "Last Rotation Date",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const date = params?.row?.created_at;
        const displayDate = !isEmpty(date)
          ? moment(date).format("DD-MM-yyyy")
          : "-";
        return (
          <>
            <Typography>{displayDate}</Typography>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getSwitchHistory(false);
  }, [record, pageNo]);

  useEffect(() => {
    isEmpty(search) && getSwitchHistory(false);
  }, [search]);

  useImperativeHandle(ref, () => ({
    tyreData: tyreData,
    downloadSwitchData: (v) => {
      getSwitchHistory(false, v);
    },
  }));

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadSwitchData(data) {
    const newData = data.map((row, index) => {
      const date = row?.created_at;
      const displayDate = !isEmpty(date)
        ? moment(date).format("DD-MM-yyyy")
        : "-";
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "No. of Tyres": `${row?.added_tyre_count}/${row?.no_of_tyres}`,
        "No. of Rotations": Number(row?.rotation_count),
        "Last Rotation Date": displayDate,
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Tyre Rotation History List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Rotation History List.xlsx");
  }

  async function getSwitchHistory(bool, v) {
    v && handleLoader(true);
    !v && setPageLoad(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyreHistoryList
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : search
        }&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadSwitchData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setTyreData(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Rotation History List");
      setPageLoad(false);
      handleLoader(false);
    } catch (error) {
      setPageLoad(false);
      handleLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function resetFilterData() {
    handleSearch("");
  }

  return (
    <>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 14, boxSizing: "border-box" }}
        wrap="nowrap"
      >
        <Grid item lg={10.4} style={{ display: "flex", alignItems: "center" }}>
          <Grid item lg={4}>
            <TextField
              fullWidth
              placeholder="Search"
              value={search}
              className={styles.inputFieldStyle}
              onChange={(v) => {
                isEmpty(v?.target?.value) && getSwitchHistory(true);
                handleSearch(v?.target?.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Fleet Name and Vehicle No."
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
              onKeyDown={(v) => {
                if (v.key === "Enter") {
                  if (!isEmpty(search)) {
                    handlePageNo(1);
                    getSwitchHistory(false);
                  }
                }
              }}
            />
          </Grid>
          <Grid item wrap="nowrap">
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary, marginLeft: 20 }}
              onClick={() => {
                if (!isEmpty(search)) {
                  handlePageNo(1);
                  getSwitchHistory(false);
                }
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={resetFilterData}
              disabled={isEmpty(search) ? true : false}
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          lg={1.6}
          style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
        >
          <Typography style={{ fontSize: 14 }}>Records:</Typography>
          <Select
            fullWidth
            style={{ marginLeft: 10 }}
            value={record}
            onChange={(index) => {
              handlePageNo(1);
              handleRecord(index.target.value);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(tyreData?.data) && !isEmpty(tyreData?.data) ? (
        <>
          <div style={{ marginTop: 20 }}>
            <DataGrid
              rows={tyreData?.data}
              columns={columns}
              pageSize={record}
              hideFooter
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              disableSelectionOnClick
              autoHeight={true}
              onCellClick={(params) => {
                if (params?.field !== "action") {
                  handleClick("switchList", params?.row);
                }
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              padding: "20px 0 0 0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(tyreData?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={2}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => {
                handlePageNo(e);
              }}
            />
          </div>
        </>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
    </>
  );
}

export default forwardRef(SwitchHistory);
