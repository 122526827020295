import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    display: "flex",
    padding: "20px 20px 0px 20px",
    boxSizing: "border-box",
    flexDirection: "column",
    marginBottom: 20,
    "@media (max-width:425px)": {
      padding: "0 10px 10px 10px",
    },
    // "@media (max-device-width:870px)": {
    //   marginBottom: "8vw",
    // },
    // "@media (max-device-width:425px)": {
    //   marginBottom: "8vh",
    // },
  },
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    // overflow: "auto",
    padding: 20,
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 400,
  },
}));
export default useStyles;
