import React, { useEffect, useRef, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import TyreDetails from "../../../Components/Fleet/TyreDetails/index.js";
import {
  cloneDeep,
  flattenDeep,
  isArray,
  isEmpty,
  isNull,
  isObject,
  toNumber,
} from "lodash";
import TyreInventoryView from "../../../Components/TyreInventory/TyreInentory/index.js";
import AddInventoryTyre from "../../../Components/TyreInventory/AddInventory/index.js";
import RemoveTyre from "../../../Components/TyreHistory/RemoveTyre/index.js";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../../Redux/reducers/auth/actions";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/system";
import { color } from "../../../Config/theme.js";
import { useTheme } from "@emotion/react";
import MDateRange from "../../../Components/Modal/MDateRange/index.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment";

export default function TyreInventory() {
  const styles = useStyles();
  const { isOnline, userdata } = useSelector((state) => state.auth);
  const { setEditInventoryData } = authActions;
  const dispatch = useDispatch();
  const location = useLocation();
  const [changeTab, setChangeTab] = useState("");
  const [editMasterData, setEditMasterData] = useState({});
  const [from, setFrom] = useState("");
  const [storedCategory, setStoredCategory] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const [pageNoIL, setPageNoIL] = useState(1);
  const [recordIL, setRecordIL] = useState(10);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");
  const [datePicker, setDatePicker] = useState(null);
  const [isFleet, setIsFleet] = useState(false);
  const tyreInvetory = useRef();

  const [tyreInventoryArr, setTyreInventoryArr] = useState({});
  const [downloadFilter, setDownloadFilter] = useState({});
  const [downLoader, setDownLoader] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const { token } = useSelector((state) => state.auth);

  const [visible, setVisible] = useState(false);

  const [tsn, setTsn] = useState(location?.state?.tyre_serial_no);
  const [reportLoader, setReportLoader] = useState(false);

  useEffect(() => {
    document.title = Setting.page_name.TyreInventory;
  }, []);

  useEffect(() => {
    // const section = document.querySelector("#jump_to_me");
    // section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [changeTab]);

  useEffect(() => {
    if (!isEmpty(tyreInventoryArr) && tyreInventoryArr?.pagination?.isMore) {
      getInventoryReport(
        downloadFilter?.type,
        downloadFilter?.fleetIds,
        downloadFilter?.vehicleIds,
        downloadFilter?.fromDate,
        downloadFilter?.toDate,
        downloadFilter?.isAll,
        downloadFilter?.category,
        downloadFilter?.scrap,
        downloadFilter?.claim,
        downloadFilter?.Casing,
        false
      );
    }
  }, [tyreInventoryArr]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre Inventory`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getInventoryReport(
    type,
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll,
    category,
    scrap,
    claim,
    Casing,
    bool
  ) {
    setDownLoader(true);

    let page = 0;
    const cPage =
      tyreInventoryArr &&
      tyreInventoryArr.pagination &&
      tyreInventoryArr.pagination.currentPage
        ? toNumber(tyreInventoryArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("yyyy-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("yyyy-MM-DD") : "";
    const status =
      category === "Scrap"
        ? scrap
        : category === "Claim"
        ? claim
        : category === "Casing"
        ? Casing
        : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.reportinventory
        }?fleet_id=${fleetIds}&category=${category}&from_date=${from_date}&to_date=${
          isEmpty(to_date) ? from_date : to_date
        }&status=${status}&page=${page}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (isObject(response) && !isEmpty(response) && response.status) {
        const obj = bool ? {} : cloneDeep(tyreInventoryArr);

        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};
        if (isArray(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        if (!obj?.pagination?.isMore) {
          setDownLoader(false);
          downloadReport(obj?.data);
          auditSave("Inventory report");
          setTyreInventoryArr({});
          setDownloadFilter({});
        } else {
          setTyreInventoryArr(obj);
        }
      } else {
        toast.error(response?.message || "No Data Found");
        setDownLoader(false);
        setDownloadFilter({});
      }
    } catch (error) {
      setDownLoader(false);
      setDownloadFilter({});
      toast.error(error.toString(), { toastId: 17 });
    }
  }

  function downloadReport(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const fDate = isEmpty(item?.fitment_date)
        ? "-"
        : moment(item?.fitment_date).format("DD-MM-yyyy");
      const displayDate = !isNull(item?.added_date)
        ? moment(item?.added_date).format("DD-MM-yyyy")
        : "-";
      const displayDate1 = moment(item?.date).format("DD-MM-yyyy");
      const newObj = {
        "Sr No.": index + 1,
        "Vehicle No.": item?.vehicle_no,
        Category: item?.category,
        "Axle Type": item?.axle_type,
        "Tyre Serial No.": item?.tyre_serial_no,
        "Tyre Brand": item?.tyre_brand,
        "Tyre Position": item?.tyre_position,
        "Tyre Composition": item?.tyre_composition || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Air Pressure": `${item?.tyre_air_pressure || "-"} psi`,
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Current Avg. NSD (mm)": item?.avg_nsd || "-",
        "Original NSD (mm)": item?.original_nsd || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Fitment Reading (Km)": isEmpty(item?.tyre_fitment_km)
          ? "-"
          : Number(item?.tyre_fitment_km?.toString()?.replaceAll(",", "")),
        "Fitment Date": fDate || "-",
        "Added Date": displayDate || "-",
        "Category Change Date": displayDate1 || "-",
      };

      const newObj1 = {
        "Sr No.": index + 1,
        Category: item?.category,
        Status:
          item?.category === "Claim"
            ? item?.claim_status === 0
              ? "Pending"
              : item?.claim_status === 1
              ? "Accepted"
              : "Rejected"
            : item?.category === "Casing"
            ? item?.casing_status === 0
              ? "Pending"
              : item?.casing_status === 1
              ? "Accepted"
              : "Rejected"
            : "-",
        "Tyre Serial No.": item?.tyre_serial_no,
        "Tyre Brand": item?.tyre_brand,
        "Tyre Composition": item?.tyre_composition || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Current Avg. NSD (mm)": Number(item?.avg_nsd) || "-",
        "Original NSD (mm)": item?.original_nsd || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Added Date": displayDate,
        "Category Change Date": displayDate1 || "-",
      };

      const newObj2 = {
        "Sr No.": index + 1,
        Category: item?.category,
        Status: item?.scrap_status === 0 ? "Unsold" : "Sold",
        "Tyre Serial No.": item?.tyre_serial_no,
        "Tyre Brand": item?.tyre_brand,
        "Tyre Composition": item?.tyre_composition || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        Issue: item?.scrap_issue ? item?.scrap_issue.toString() || "-" : "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Current Avg. NSD": item?.avg_nsd || "-",
        "Original NSD (mm)": item?.original_nsd || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Added Date": displayDate,
        "Category Change Date": displayDate1 || "-",
      };
      return item?.category === "On Wheel"
        ? newObj
        : item?.category === "Scrap"
        ? newObj2
        : newObj1;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Inventory Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inventory Report.xlsx");
  }

  return (
    <div className={styles.card}>
      <Grid container id="jump_to_me">
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator="›">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="#"
              className={styles.linkStyle}
              onClick={() => {
                setChangeTab("");
                setEditMasterData({});
                setFrom("");
                setTsn("");
                dispatch(setEditInventoryData({}));
              }}
            >
              Tyre Inventory
            </Link>
            {changeTab === "AddItemMaster" && (
              <Typography className={styles.linkStyle}>
                {isEmpty(editMasterData)
                  ? "Add Item Master"
                  : "Edit Item Master"}
              </Typography>
            )}
            {changeTab === "addTyre" && (
              <Typography className={styles.linkStyle}>Add Tyre</Typography>
            )}
            {changeTab === "removeTyre" && (
              <Typography className={styles.linkStyle}>Remove Tyre</Typography>
            )}
            {/* {changeTab === "tyreDetails" && (
              <Typography className={styles.linkStyle}>
                {editMasterData?.vehicle_no}
              </Typography>
            )} */}
            {changeTab === "tyreDetails" && (
              <Typography className={styles.linkStyle}>
                {editMasterData?.tyre_serial_no}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "removeTyre" ? (
        <RemoveTyre
          from={"tyreInventory"}
          tyreData={editMasterData}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setFrom("inside#cancel");
              setChangeTab("addTyre");
            } else if (type === "success") {
              setFrom("inside#success");
              setChangeTab("addTyre");
            }
          }}
        />
      ) : changeTab === "addTyre" ? (
        <AddInventoryTyre
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("");
              setFrom("");
              setTsn("");
            } else if (type === "removeTyre") {
              setStoredCategory(data);
              setChangeTab("removeTyre");
            }
          }}
          storedCategory={storedCategory}
          fromInsideType={from}
          editData={editMasterData}
        />
      ) : changeTab === "tyreDetails" ? (
        <TyreDetails tyreData={editMasterData} from={"tyreInventory"} />
      ) : (
        <div className={styles.container}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ height: 40 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="tableTitle">Tyre Inventory</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: sm ? "unset" : "flex-end",
              }}
            >
              {tabValue === 0 && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setChangeTab("addTyre");
                      dispatch(setEditInventoryData({}));
                    }}
                  >
                    Add Tyre
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => {
                      if (tabValue === 0) {
                        tyreInvetory?.current?.getitemInventoryList();
                      }
                    }}
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginLeft: 10,
                    }}
                    disabled={isOnline ? false : true || reportLoader}
                  >
                    {reportLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      "Download"
                    )}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
            <Tabs
              value={tabValue}
              onChange={(v, b) => {
                setRecordIL(10);
                setPageNoIL(1);
                setTabValue(b);
                setIsFleet(false);
                setFilter("");
              }}
              variant="scrollable"
            >
              <Tab className={styles.tabBtn} label="Tyre List" />
              <Tab className={styles.tabBtn} label=" Master Reports" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <TyreInventoryView
              ref={tyreInvetory}
              tsn={tsn}
              pageNo={pageNoIL}
              record={recordIL}
              filter={filter}
              isFleet={isFleet}
              search={search}
              date={datePicker}
              handlePageNo={(page) => {
                setPageNoIL(page);
              }}
              handleLoader={(v) => {
                setReportLoader(v);
              }}
              handleRecord={(rec) => {
                setRecordIL(rec);
              }}
              handleFilter={(flt) => {
                setFilter(flt);
              }}
              handleIsFleet={(isFleet) => {
                setIsFleet(isFleet);
              }}
              handleSearch={(search) => {
                setSearch(search);
              }}
              handledate={(date) => {
                setDatePicker(date);
              }}
              handleClick={(v, data) => {
                if (v === "tyreDetails") {
                  setChangeTab("tyreDetails");
                  setEditMasterData(data);
                } else if (v === "addTyre") {
                  setChangeTab("addTyre");
                  setEditMasterData(data);
                }
              }}
            />
          )}
          {tabValue === 1 && (
            <>
              <div style={{ padding: "0px 10px" }}>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Typography>Tyre Inventory Report</Typography>
                  <Button
                    disabled={isOnline ? false : true}
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginRight: 20,
                    }}
                    onClick={() => setVisible(true)}
                  >
                    {downLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      "Download"
                    )}
                  </Button>
                </Grid>
              </div>
              <MDateRange
                category
                disableVehicle
                visible={visible}
                handleModal={(
                  type,
                  fleetIds,
                  vehicleIds,
                  fromDate,
                  toDate,
                  isAll,
                  category,
                  scrap,
                  claim,
                  Casing
                ) => {
                  if (type === "download") {
                    getInventoryReport(
                      type,
                      fleetIds,
                      vehicleIds,
                      fromDate,
                      toDate,
                      isAll,
                      category,
                      scrap,
                      claim,
                      Casing,
                      true
                    );
                    setDownloadFilter({
                      type,
                      fleetIds,
                      vehicleIds,
                      fromDate,
                      toDate,
                      isAll,
                      category,
                      scrap,
                      claim,
                      Casing,
                    });
                  }
                  setVisible(false);
                }}
              />
            </>
          )}
        </div>
      )}
    </div>
  );
}
