import React, { useEffect, useMemo, useRef, useState } from "react";
import useStyles from "./styles.js";
import { color } from "../../../Config/theme.js";
import { Setting } from "../../../Utils/Setting";
import Images from "../../../Config/Images.js";
import {
  Grid,
  TextField,
  Typography,
  InputAdornment,
  Button,
  Select,
  MenuItem,
  Tooltip,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  ListItemButton,
  CircularProgress,
  Pagination,
  Popover,
  Switch,
} from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import {
  Search,
  Visibility,
  FilterAlt,
  Edit,
  TuneOutlined,
  MoreVert,
} from "@mui/icons-material";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import MDateRange from "../../Modal/MDateRange/index.js";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";

export default function TabFleetView(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handlePageNoV = () => null,
    handleRecord = () => null,
    handleRecordV = () => null,
    handleFilterFL = () => null,
    handleFilterV = () => null,
    handleSearch = () => null,
    handleSearchV = () => null,
    handleDatePicker = () => null,
    handleDatePicker1 = () => null,
    handleDatePickerV = () => null,
    handleDatePickerV1 = () => null,
    pageNo = 1,
    pageNoV = 1,
    record = 10,
    recordV = 10,
    from,
    tab = 0,
    filter = "",
    filterV = "",
    searchFilter = "",
    searchFilterV = "",
    datePicker = null,
    datePicker1 = null,
    datePickerV = null,
    datePickerV1 = null,
  } = props;
  const styles = useStyles();
  // const [searchFilter, setSearchFilter] = useState("");
  const [fleetListRows, setFleetListRows] = useState({});
  const [districtList, setDistrictList] = useState(false);
  const [RoList, setRoList] = useState(false);
  const { token, dropDownData, isOnline, userdata, permissionData } =
    useSelector((state) => state.auth);
  // const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  // const [datePicker, setDatePicker] = useState(null);
  // const [datePicker1, setDatePicker1] = useState(null);

  const [disable, setDisable] = useState(true);
  const [activeIndex, setactiveIndex] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [visible, setVisible] = useState(false);
  const [ind, setInd] = useState("");

  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [terrainList, setterrainList] = useState(false);

  const [vehicleListRows, setVehicleListRows] = useState({});
  // const [filterV, setFilterV] = useState("");
  // const [searchFilterV, setSearchFilterV] = useState("");
  const [pageLoadV, setPageLoadV] = useState(true);

  // const [datePickerV, setDatePickerV] = useState(null);
  // const [datePickerV1, setDatePickerV1] = useState(null);
  const [disableV, setDisableV] = useState(true);
  const [activeIndexV, setactiveIndexV] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);

  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;

  const staticArr = [
    "Resolved Issues Master Report",
    "Partially Resolved Issues Master Report",
    "Unresolved Issues Master Report",
    "NSD < 5 mm",
  ];

  const [staticArray, setStaticArr] = useState([...staticArr]);

  // master report download loader state
  const [arrReportLoader, setArrReportLoader] = useState(false);

  // intialRender used for stop multiple time apiCall in
  const initialRenderFleetList = useRef(true);
  const initialRenderVehicleList = useRef(true);

  useEffect(() => {
    const dummy_Arr = [...staticArr];
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Resolved Issues Report") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(0, 1);
          }
          if (v?.label === "Partially Resolved Issues Report") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(1, 1);
          }
          if (v?.label === "Unresolved Issues Report") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(2, 1);
          }
          if (v?.label === "NSD <5 mm") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(3, 1);
          }
        });
      });
    }
    setStaticArr(dummy_Arr);
  }, [permissionData]);

  useEffect(() => {
    // if (initialRenderFleetList?.current) {
    //   initialRenderFleetList.current = false;
    // } else {
    getFleetList(false);
    // }
  }, [record, pageNo, filter]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getFleetList(false);
    if (!isNull(datePicker)) {
      setDisable(false);
    }
  }, [datePicker, datePicker1, searchFilter]);

  useEffect(() => {
    isNull(datePickerV) && isEmpty(searchFilterV) && getVehicleist(false);
    if (!isNull(datePickerV)) {
      setDisableV(false);
    }
  }, [datePickerV, datePickerV1, searchFilterV]);

  useEffect(() => {
    setPageLoadV(true);
    getVehicleist(false);
  }, [recordV, pageNoV, filterV]);

  useEffect(() => {
    if (from === "vehicleList") {
      setTimeout(() => setTabValue(1), 200);
    }
  }, []);

  useEffect(() => {
    setactiveIndex(null);
  }, [pageNo, record]);

  useEffect(() => {
    setactiveIndexV(null);
  }, [pageNoV, recordV]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [pageLoad, pageNo, pageNoV]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);
  useEffect(() => {
    if (activeIndexV !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndexV}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndexV]);

  useEffect(() => {
    setTabValue(tab);
  }, [tab]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Fleet Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getFleetList(bool, v) {
    setactiveIndex(null);
    !v && setPageLoad(true);
    v && setDownloadLoader(true);

    const fromDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePicker1)
      ? moment(datePicker1).format("YYYY-MM-DD")
      : "";

    try {
      const response = await getApiData(
        `${
          Setting.endpoints.fleetList
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&from_date=${fromDate}&to_date=${toDate}&filter=${filter}&is_download=${
          v ? v : ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadFleetData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setFleetListRows(listObj);
          }
        v && auditSave("Fleet List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error?.toString());
      console.log("error ===>>>", error);
    }
  }

  function AddedOn(index) {
    const timestamp = fleetListRows?.data[index]?.capture_date;
    return moment(timestamp).format("DD-MM-yyyy");
  }
  function resetFilterData() {
    handleSearch("");
    handleDatePicker(null);
    handleDatePicker1(null);
    handleFilterFL("");
    handlePageNo(1);
  }
  const District = (index) => {
    let array = fleetListRows?.data[index].district_id;
    let res =
      !isNull(array) &&
      dropDownData?.district?.find(
        (item) => array?.toString() === item?.id?.toString()
      );
    return <Typography>{res?.label || "-"}</Typography>;
  };
  const ReginalOffice = (index) => {
    let array = fleetListRows?.data[index]?.office_region_id;
    let res =
      !isNull(array) &&
      dropDownData?.office_region?.find(
        (item) => array?.toString() === item?.id?.toString()
      );
    return <Typography>{res?.label || "-"}</Typography>;
  };

  function downloadFleetData(data) {
    const newData = data.map((row, index) => {
      let district =
        !isNull(row?.district_id) &&
        dropDownData?.district?.find(
          (item) => row?.district_id?.toString() === item?.id?.toString()
        );
      let Regional =
        !isNull(row?.office_region_id) &&
        dropDownData?.office_region?.find(
          (item) => row?.office_region_id?.toString() === item?.id?.toString()
        );
      const timestamp = row?.capture_date;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        ID: row?.id || "-",
        "Fleet Name": row?.name,
        "Fleet Code": row?.code,
        // Location: row?.city,
        District: district?.label || "-",
        "Regional Office": Regional?.label || "-",
        "No. of Vehicles": Number(row?.vehicles_allocated),
        "Unresolved Issues": row?.pending_issues_count || "-",
        "Contact Person": row?.contact_name || "-",
        "Mobile No.": row?.contact_phone || "-",
        Owner: row?.owner_name || "-",
        "Owner's No.": row?.owner_phone || "-",
        "Added On": timestamp ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Fleet List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Fleet List.xlsx");
  }

  async function getUnresolvedIssue(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll
  ) {
    setArrReportLoader(true);
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        isAll
          ? `${
              Setting.endpoints.pendingIssueReport
            }?fleet_id=${fleetIds}&status=${0}&is_all=${1}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`
          : `${
              Setting.endpoints.pendingIssueReport
            }?fleet_id=${fleetIds}&status=${0}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setArrReportLoader(false);
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReports1(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      auditSave("Unresolved Issues Report");
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error?.toString());
      setArrReportLoader(false);
    }
  }

  function downloadReports1(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        RO: item?.fk_ro_id || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Tyre Issue": item?.tyre_issue?.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue?.toString() || "-",
        Remarks: item?.remarks || "-",
        "Inspected By": item?.inspected_by || "-",
        "Inspected On": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Unresolved List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Unresolved Issue Report.xlsx");
  }

  // async function getpartiallyresolved(
  //   fleetIds,
  //   vehicleIds,
  //   fromDate,
  //   toDate,
  //   isAll
  // ) {
  //   const from_date = !isNull(fromDate)
  //     ? moment(fromDate).format("YYYY-MM-DD")
  //     : "";
  //   const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
  //   try {
  //     const response = await getApiData(
  //       isAll
  //         ? `${
  //             Setting.endpoints.pendingIssueReport
  //           }?fleet_id=${fleetIds}&status=${2}&is_all=${1}}&from_date=${from_date}&to_date=${to_date}`
  //         : `${
  //             Setting.endpoints.pendingIssueReport
  //           }?fleet_id=${fleetIds}&status=${2}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${to_date}`,
  //       "GET",
  //       {},
  //       { Authorization: `Bearer ${token}` }
  //     );
  //     if (response.status) {
  //       if (isArray(response?.data) && !isEmpty(response.data)) {
  //         downloadReports2(response?.data);
  //       } else {
  //         toast.error(response?.message ? response?.message : "No Data Found");
  //       }
  //     } else {
  //       toast.error(response?.message);
  //     }
  //   } catch (error) {
  //     console.log("error ===>>>", error);
  //     toast.error(error.toString());
  //     // setReportLoader(false);
  //   }
  // }

  function downloadReports2(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        RO: item?.fk_ro_id || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        Issue: item?.issue?.toString() || "-",
        Remarks: item?.remark || "-",
        "Inspected By": item?.inspected_by || "-",
        "Inspected On": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Partially Resolved List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Partially Resolved Issues Report.xlsx");
  }

  async function getResolvedissuereport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll,
    status
  ) {
    setArrReportLoader(true);
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        isAll
          ? `${
              Setting.endpoints.resolvedissuereport
            }?fleet_id=${fleetIds}&is_all=${1}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }&status=${status}`
          : `${
              Setting.endpoints.resolvedissuereport
            }?fleet_id=${fleetIds}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }&status=${status}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setArrReportLoader(false);
        if (isArray(response?.data) && !isEmpty(response.data)) {
          if (status === 1) {
            downloadReports3(response?.data);
          } else {
            downloadReports2(response?.data);
          }
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave(
          status === 1
            ? "Resolved Issues Report"
            : "Partially Resolved Issues Report"
        );
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error?.toString());
      setArrReportLoader(false);
    }
  }

  function downloadReports3(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const timestamp1 = item?.resolved_at;
      const date = new Date(timestamp1 * 1000);
      const displayDate1 = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        RO: item?.fleet_ro || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        Issue: item?.issue?.toString() || "-",
        Remarks: item?.remark || "-",
        "Inspected By": item?.inspected_by || "-",
        "Inspected On": timestamp ? displayDate : "-",
        "Resolved By": item?.resolved_by || "-",
        "Resolved On": timestamp1 ? displayDate1 : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Resolved Issues Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Resolved Issues Report.xlsx");
  }

  async function getavgNsdReport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll
  ) {
    setArrReportLoader(true);
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        isAll
          ? `${
              Setting.endpoints.avgnsdreport
            }?fleet_id=${fleetIds}&is_all=${1}}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`
          : `${
              Setting.endpoints.avgnsdreport
            }?fleet_id=${fleetIds}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setArrReportLoader(false);
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReports4(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
      } else {
        toast.error(response?.message);
        setArrReportLoader(false);
      }
      auditSave("NSD < 5 mm");
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error?.toString());
      setArrReportLoader(false);
    }
  }
  function nsd(data, index) {
    const nsdArr = data[index]?.current_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return `${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
      groove2 !== 0 ? groove2.toFixed(2) : "-"
    }/${groove3 !== 0 ? groove3.toFixed(2) : "-"}/${
      groove4 !== 0 ? groove4.toFixed(2) : "-"
    }`;
  }

  function downloadReports4(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        RO: item?.fleet_ro || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Mileage (Km)": Number(item?.mileage?.toString()?.replaceAll(",", "")),
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(item?.avg_nsd) || "-",
        "Tyre Issue": item?.tyre_issue?.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue?.toString() || "-",
        "Last Inspected By": item?.inspected_by || "-",
        "Last Inspected On": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "NSD < 5 mm");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "NSD < 5 mm.xlsx");
  }

  async function getVehicleist(bool, v, isToggle) {
    !isToggle && setactiveIndexV(null);
    !v && !isToggle && setPageLoadV(true);
    v && setDownloadLoader(true);
    const terrainMatch = dropDownData?.terrain?.find((v) => v?.id === filterV);

    const fromDate = !isNull(datePickerV)
      ? moment(datePickerV).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePickerV1)
      ? moment(datePickerV1).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.vehicleList
        }?per_page=${recordV}&page=${pageNoV}&search=${
          bool ? "" : searchFilterV
        }&filter=${isEmpty(terrainMatch) ? filterV : ""}&terrain_ids=${
          !isEmpty(terrainMatch) ? filterV : ""
        }&from_date=${fromDate}&to_date=${toDate}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadVehicleData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setVehicleListRows(listObj);
          }
        v && auditSave("Vehicle List");
      } else {
        toast.error(response?.message);
      }
      setPageLoadV(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoadV(false);
      toast.error(error?.toString());
      console.log("error ===>>>", error);
    }
  }

  function downloadVehicleData(data) {
    const newData = data.map((row, index) => {
      let vehicleType =
        !isNull(row?.vehicle_type_id) &&
        dropDownData?.vehicle_type?.find(
          (item) => row?.vehicle_type_id?.toString() === item?.id?.toString()
        );
      let vehicleBrand =
        !isNull(row?.vehicle_brand_id) &&
        dropDownData?.vehicle_brand?.find(
          (item) => row?.vehicle_brand_id?.toString() === item?.id?.toString()
        );

      const getTerrain = () => {
        let array = row?.terrain_ids?.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array?.includes(item?.id?.toString());
          return a;
        });
        const arr = [];
        res.map((v, i) => arr.push(v?.label));
        return arr?.toString();
      };

      const timestamp = row?.capture_date;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        ID: row?.id,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Vehicle Status": row?.status === 1 ? "Active" : "In Active",
        "Vehicle Type": vehicleType?.label,
        "Vehicle Brand": vehicleBrand?.label,
        "Vehicle Model": row?.vehicle_model_name,
        "No. of Tyres": `${row?.added_tyre_count}/${row?.no_of_tyres}`,
        "Initial Reading (Km)": Number(
          row?.current_reading.toString()?.replaceAll(",", "")
        ),
        Terrain: getTerrain() || "-",
        "Load Capacity": `${row?.load_capicity}${
          row?.load_capicity ? " Tonnes" : "|| " - ","
        }`,
        "Driver's Name": row?.driver_name || "-",
        "Driver's No.": row?.driver_phone || "-",
        "Goods Carried": row?.goods_carried || "-",
        "Route Origin": row?.route_origin || "-",
        "Route Destination": row?.route_destination || "-",
        // "Alignments Claimed": `${row?.alignment_count || 0}/5`,
        "Added On": timestamp ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Vehicle List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Vehicle List.xlsx");
  }

  function resetVehicleData() {
    handleFilterV("");
    handleSearchV("");
    handleDatePickerV(null);
    handleDatePickerV1(null);
    getVehicleist(true);
    handlePageNoV(1);
  }

  const getVehicleType = (index) => {
    let array = vehicleListRows?.data[index]?.vehicle_type_id;
    let res =
      !isNull(array) &&
      dropDownData?.vehicle_type?.find(
        (item) => array?.toString() === item?.id?.toString()
      );
    return <Typography>{res?.label}</Typography>;
  };

  const getVehicleBrand = (index) => {
    let array = vehicleListRows?.data[index]?.vehicle_brand_id;
    let res =
      !isNull(array) &&
      dropDownData?.vehicle_brand?.find(
        (item) => array?.toString() === item?.id?.toString()
      );
    return <Typography>{res?.label}</Typography>;
  };

  const getNoOfTyre = (index) => {
    return (
      <>
        <span
          style={{
            color:
              vehicleListRows?.data[index]?.added_tyre_count != (0 || "")
                ? "red"
                : "",
          }}
        >
          {vehicleListRows?.data[index]?.added_tyre_count}
        </span>
        <span>/{vehicleListRows?.data[index]?.no_of_tyres}</span>
      </>
    );
  };

  const getTerrain = (index) => {
    let array = vehicleListRows?.data[index].terrain_ids.split(",");
    let res = dropDownData.terrain.filter((item) => {
      let a = array.includes(item?.id?.toString());
      return a;
    });
    return (
      <span
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {res.map((items, i) => {
          return (
            <Typography>
              {items?.label}
              {i < res.length - 1 ? ", " : ""}
            </Typography>
          );
        })}
      </span>
    );
  };

  const getAddedOn = (index) => {
    const timestamp = vehicleListRows?.data[index]?.capture_date;
    // const date = new Date(timestamp * 1000);
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : "-"}
      </div>
    );
  };

  const changeVehicleStatus = async (data) => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.changeVehicleStatus}?id=${data?.id}&status=${
          data?.status === 1 ? 0 : 1
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        getVehicleist(false, "", true);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const renderMapFunction = useMemo(() => {
    return fleetListRows?.data?.map((item, index) => {
      return (
        <Grid
          item
          container
          style={{
            marginTop: 10,
            border: `1px solid ${color.bordercolor}`,
            padding: 10,
            alignItems: "center",
          }}
        >
          <Table className={styles.customtable}>
            <TableHead>
              <TableRow>
                <TableCell variant="head">ID</TableCell>
                <TableCell variant="head">Fleet Name</TableCell>
                <TableCell variant="head">Fleet Code</TableCell>
                <TableCell variant="head">District</TableCell>
                <TableCell variant="head">Regional Office</TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell>{item?.id || "-"}</TableCell>
              <TableCell>{item?.name || "-"}</TableCell>
              <TableCell>{item?.code || "-"}</TableCell>
              <TableCell>{District(index) || "-"}</TableCell>
              <TableCell>{ReginalOffice(index) || "-"}</TableCell>
            </TableRow>
            <TableRow variant="fullWidth">
              <TableCell colSpan={6}>
                <Divider
                  variant="fullWidth"
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableHead>
              <TableRow>
                <TableCell variant="head">No. of Vehicles</TableCell>
                <TableCell variant="head">Unresolved Issues</TableCell>
                <TableCell variant="head">Contact Person</TableCell>
                <TableCell variant="head">Mobile No.</TableCell>
                <TableCell variant="head">Owner</TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell>{item?.vehicles_allocated || "-"}</TableCell>
              <TableCell>
                <div
                  onClick={() => {
                    if (item?.pending_issues_count) {
                      handleClick("UnresolvedIssue", item);
                    } else {
                      return null;
                    }
                  }}
                >
                  <Typography
                    style={{
                      color: item?.pending_issues_count ? "red" : color.disable,
                      fontWeight: "bold",
                    }}
                  >
                    {item?.pending_issues_count || "0"}
                  </Typography>
                </div>
              </TableCell>
              <TableCell>{item?.contact_name || "-"}</TableCell>
              <TableCell>{item?.contact_phone || "-"}</TableCell>
              <TableCell>{item?.owner_name || "-"}</TableCell>
            </TableRow>
            <TableRow variant="fullWidth">
              <TableCell colSpan={6}>
                <Divider
                  variant="fullWidth"
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Owner’s No.</TableCell>
                <TableCell variant="head">Added On</TableCell>
                <TableCell variant="head">Added By</TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell style={{ paddingBottom: 10 }}>
                {item?.owner_phone || "-"}
              </TableCell>
              <TableCell style={{ paddingBottom: 10 }}>
                {AddedOn(index) || "-"}
              </TableCell>
              <TableCell style={{ paddingBottom: 10 }}>
                {item?.added_by || "-"}
              </TableCell>
            </TableRow>
            <TableRow variant="fullWidth">
              <TableCell colSpan={6}>
                <Divider
                  variant="fullWidth"
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                colSpan={5}
                style={{
                  padding: "10px 20px",
                }}
              >
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Tooltip title="Edit" placement="right" arrow>
                    <Button
                      variant="contained"
                      disabled={
                        userdata?.role === "service_enginner" ||
                        userdata?.role === "fleet_owner"
                      }
                      onClick={() => {
                        handleClick("addFleet", fleetListRows?.data[index]);
                      }}
                    >
                      <Edit />
                    </Button>
                  </Tooltip>
                  <Button
                    variant="contained"
                    onClick={() => handleClick("fleetDetails", item)}
                  >
                    <Visibility />
                  </Button>
                </Grid>
              </TableCell>
            </TableRow>
          </Table>
        </Grid>
      );
    });
  }, [fleetListRows]);

  const renderMapFunction1 = useMemo(() => {
    return vehicleListRows?.data?.map((item, index) => {
      return (
        <Grid
          item
          container
          style={{
            marginTop: 10,
            border: `1px solid ${color.bordercolor}`,
            padding: 10,
            alignItems: "center",
          }}
        >
          <Table className={styles.customtable}>
            <TableHead>
              <TableRow>
                <TableCell variant="head">ID</TableCell>
                <TableCell variant="head">Fleet Name</TableCell>
                <TableCell variant="head">Vehicle No.</TableCell>
                <TableCell variant="head">Vehicle Type</TableCell>
                <TableCell variant="head">Vehicle Brand</TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell>{item?.id || "-"}</TableCell>
              <TableCell>{item?.fleet_name || "-"}</TableCell>
              <TableCell>{item?.vehicle_no || "-"}</TableCell>
              <TableCell>{getVehicleType(index) || "-"}</TableCell>
              <TableCell>{getVehicleBrand(index) || "-"}</TableCell>
            </TableRow>
            <TableRow variant="fullWidth">
              <TableCell colSpan={6}>
                <Divider
                  variant="fullWidth"
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableHead>
              <TableRow>
                <TableCell variant="head">Vehicle Model</TableCell>
                <TableCell variant="head">No. of Tyres</TableCell>
                <TableCell variant="head">Initial Reading</TableCell>
                <TableCell variant="head">Terrain</TableCell>
                <TableCell variant="head">Load Capacity</TableCell>
              </TableRow>
            </TableHead>
            <TableRow>
              <TableCell>{item?.vehicle_model_name || "-"}</TableCell>
              <TableCell>{getNoOfTyre(index)}</TableCell>
              <TableCell>
                {item?.current_reading}
                {item?.current_reading ? " Km" : "-"}
              </TableCell>
              <TableCell>{getTerrain(index) || "-"}</TableCell>
              <TableCell>
                {item?.load_capicity}
                {item?.current_reading ? " Tonnes" : "-"}
              </TableCell>
            </TableRow>
            <TableRow variant="fullWidth">
              <TableCell colSpan={6}>
                <Divider
                  variant="fullWidth"
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                />
              </TableCell>
            </TableRow>

            <TableHead>
              <TableRow>
                <TableCell variant="head">Driver's Name</TableCell>
                <TableCell variant="head">Driver's No.</TableCell>
                <TableCell variant="head">Goods Carried</TableCell>
                <TableCell variant="head">Route Origin</TableCell>
                <TableCell style={{ position: "relative" }}>
                  Route Destination
                </TableCell>
              </TableRow>
            </TableHead>

            <TableRow>
              <TableCell>{item?.driver_name || "-"}</TableCell>
              <TableCell>{item?.driver_phone || "-"}</TableCell>
              <TableCell>{item?.goods_carried || "-"}</TableCell>
              <TableCell>{item?.route_origin || "-"}</TableCell>
              <TableCell>{item?.route_destination || "-"}</TableCell>
            </TableRow>
            <TableRow variant="fullWidth">
              <TableCell colSpan={6}>
                <Divider
                  variant="fullWidth"
                  style={{
                    marginTop: 15,
                    marginBottom: 15,
                  }}
                />
              </TableCell>
            </TableRow>
            <TableHead>
              <TableRow>
                {/* <TableCell variant="head">Alignments Claimed</TableCell> */}
                <TableCell variant="head">AddedOn</TableCell>
                <TableCell variant="head">Added By</TableCell>
                <TableCell variant="head">Vehicle Status</TableCell>
              </TableRow>
              <TableRow>
                {/* <TableCell variant="body">{item?.alignment_count}/5</TableCell> */}
                <TableCell variant="body">{getAddedOn(index)}</TableCell>
                <TableCell variant="body">{item?.added_by || "-"}</TableCell>
                <TableCell variant="body">
                  <Switch
                    checked={item?.status === 1 ? true : false}
                    onChange={() => {
                      changeVehicleStatus(item);
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow variant="fullWidth">
                <TableCell colSpan={6}>
                  <Divider
                    variant="fullWidth"
                    style={{
                      marginTop: 15,
                      marginBottom: 15,
                    }}
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={5} style={{ padding: "10px 20px" }}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Button
                      variant="contained"
                      disabled={
                        userdata?.role === "fleet_owner" || item?.status === 0
                      }
                      onClick={() => {
                        handleClick("editVehicle", item);
                      }}
                    >
                      <Edit />
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => handleClick("vehicleDetails", item)}
                    >
                      <Visibility />
                    </Button>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
          </Table>
        </Grid>
      );
    });
  }, [vehicleListRows]);

  return (
    <>
      {isTablet ? (
        <>
          <Grid container className={styles.container}>
            <Grid
              id="jump_to_me"
              container
              display="flex"
              alignItems={"center"}
              style={{ minHeight: 40 }}
              justifyContent="space-between"
              wrap="nowrap"
            >
              <Typography variant="tableTitle">
                {tabValue === 1
                  ? "Vehicle List"
                  : tabValue === 2
                  ? "Master Reports"
                  : "Fleet List"}
              </Typography>

              <Grid item display="flex" wrap="nowrap">
                {tabValue !== 2 && (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleClick("addFleet", "");
                      }}
                      style={{
                        backgroundColor:
                          userdata?.role === "service_enginner"
                            ? color.bordercolor
                            : color.primary,
                      }}
                      disabled={
                        userdata?.role === "service_enginner" ||
                        userdata?.role === "fleet_owner" ||
                        userdata?.role === "fleet_manager"
                      }
                    >
                      Add Fleet
                    </Button>

                    <Button
                      disabled={isOnline ? false : true}
                      variant="contained"
                      style={{
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                        marginLeft: 10,
                      }}
                      onClick={() => {
                        tabValue === 1
                          ? getVehicleist(false, 1)
                          : getFleetList(false, 1);
                      }}
                    >
                      {downloadLoader ? (
                        <CircularProgress
                          style={{ color: color.white }}
                          size={24}
                        />
                      ) : (
                        <img src={Images.Mdownload} alt="download" />
                      )}
                    </Button>
                  </>
                )}
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: color.white,
                    color: "#D8D8D8",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                </Button>

                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: isTablet ? "center" : "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    selected={tabValue === 0}
                    onClick={(event) => {
                      handleFilterFL("");
                      handleFilterV("");
                      handlePageNo(1);
                      handleRecord(10);
                      setTabValue(0);
                      setAnchorEl(null);
                      handleDatePicker(null);
                      handleDatePicker1(null);
                    }}
                  >
                    Fleet List
                  </ListItemButton>
                  <ListItemButton
                    selected={tabValue === 1}
                    onClick={() => {
                      handleFilterFL("");
                      handleFilterV("");
                      handlePageNoV(1);
                      handleRecordV(10);
                      setTabValue(1);
                      setAnchorEl(null);
                      handleDatePickerV(null);
                      handleDatePickerV1(null);
                    }}
                  >
                    Vehicle List
                  </ListItemButton>
                  <ListItemButton
                    selected={tabValue === 2}
                    onClick={() => {
                      handleFilterFL("");
                      handleFilterV("");
                      setTabValue(2);
                      setAnchorEl(null);
                    }}
                  >
                    Master Reports
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
            {tabValue === 0 && (
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  style={{ marginTop: 10 }}
                  wrap="nowrap"
                  gap={1}
                >
                  <Grid container item gap={1} display="flex" wrap="nowrap">
                    <Grid item>
                      <Select
                        value={filter}
                        onChange={(v) => {
                          handlePageNo(1);
                          handleFilterFL(v.target.value);
                        }}
                        onOpen={() => {
                          if (isEmpty(filter.toString())) {
                            setDistrictList(false);
                            setRoList(false);
                          }
                        }}
                        displayEmpty
                        IconComponent={FilterAlt}
                        style={filter === "" ? { color: "#A2A2A2" } : {}}
                        classes={{
                          iconOpen: styles.iconOpen,
                        }}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Filter
                        </MenuItem>
                        <ListItemButton
                          onClick={() => {
                            setDistrictList(!districtList);
                            setRoList(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>District</Typography>
                          {districtList ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </ListItemButton>
                        {dropDownData.district.map((item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: districtList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                        <ListItemButton
                          onClick={() => {
                            setRoList(!RoList);
                            setDistrictList(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Regional Office</Typography>
                          {RoList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        {dropDownData.office_region.map((item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: RoList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Grid>

                    <TextField
                      fullWidth
                      placeholder="Search"
                      value={searchFilter}
                      onChange={(v) => {
                        isEmpty(v.target.value) && getFleetList(true);
                        handleSearch(v?.target?.value);
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          if (
                            !isEmpty(searchFilter) ||
                            (!isNull(datePicker) && !isNull(datePicker1))
                          ) {
                            handlePageNo(1);
                            getFleetList(false);
                          }
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div style={{ lineHeight: 0 }}>
                              <Search style={{ fontSize: 20 }} />
                            </div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div style={{ lineHeight: 0, cursor: "pointer" }}>
                              <Tooltip
                                title="Search by Fleet Name, Fleet Code, Owner, Owner's No., Contact Person and Mobile No."
                                placement="bottom"
                                arrow
                              >
                                <InfoIcon style={{ fontSize: 20 }} />
                              </Tooltip>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Select
                      value={record}
                      onChange={(v) => {
                        handlePageNo(1);
                        handleRecord(v.target.value);
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <Grid
                  container
                  style={{ marginTop: 10 }}
                  alignItems={"center"}
                  wrap="nowrap"
                  gap={1}
                >
                  <Grid
                    item
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        showToolbar={false}
                        disableFuture
                        value={datePicker}
                        onChange={(newValue) => {
                          handleDatePicker(newValue);
                          setDisable(false);
                        }}
                        inputFormat="dd-MM-yyyy"
                        DialogProps={{ className: styles.datePicker }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD-MM-YYYY",
                            }}
                            sx={{ svg: { color: color.primary } }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                    <Typography style={{ marginLeft: 7, marginRight: 7 }}>
                      to
                    </Typography>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        showToolbar={false}
                        disabled={isNull(datePicker) ? true : disable}
                        minDate={datePicker}
                        disableFuture
                        value={datePicker1}
                        inputFormat="dd-MM-yyyy"
                        onChange={(newValue) => {
                          handleDatePicker1(newValue);
                        }}
                        DialogProps={{ className: styles.datePicker }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            inputProps={{
                              ...params.inputProps,
                              placeholder: "DD-MM-YYYY",
                            }}
                            sx={{
                              svg: {
                                color:
                                  disable || isNull(datePicker)
                                    ? ""
                                    : color.primary,
                              },
                            }}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </Grid>

                  <Grid item wrap="nowrap" style={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: color.secondary,
                      }}
                      onClick={() => {
                        if (
                          !isEmpty(searchFilter) ||
                          (!isNull(datePicker) && !isNull(datePicker1))
                        ) {
                          getFleetList(false);
                        }
                      }}
                    >
                      <Search />
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginLeft: 10 }}
                      disabled={!filter && !searchFilter && !datePicker}
                      onClick={resetFilterData}
                    >
                      <ResetIcon />
                    </Button>
                  </Grid>
                </Grid>

                {pageLoad ? (
                  <div className={styles.dataMain}>
                    <CircularProgress style={{ color: color.primary }} />
                  </div>
                ) : isArray(fleetListRows?.data) &&
                  !isEmpty(fleetListRows?.data) ? (
                  <>
                    {renderMapFunction}
                    <div
                      style={{
                        display: "flex",
                        marginTop: 20,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Pagination
                        count={Math.ceil(fleetListRows?.pagination?.totalPage)}
                        defaultPage={pageNo}
                        boundaryCount={2}
                        siblingCount={0}
                        variant="outlined"
                        shape="rounded"
                        onChange={(v, e) => {
                          handlePageNo(e);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className={styles.dataMain}>
                    <Typography>No Data</Typography>
                  </div>
                )}
              </>
            )}
            {tabValue === 1 && (
              <div>
                <div>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    style={{ marginTop: 10 }}
                    wrap="nowrap"
                    gap={1}
                  >
                    <Grid item container display="flex" wrap="nowrap" gap={1}>
                      <Grid item>
                        <Select
                          fullWidth
                          placeholder="Filter"
                          value={filterV}
                          onChange={(v) => {
                            handlePageNoV(1);
                            handleFilterV(v.target.value);
                          }}
                          onOpen={() => {
                            if (isEmpty(filterV.toString())) {
                              settypeList(false);
                              setbrandList(false);
                              setterrainList(false);
                            }
                          }}
                          displayEmpty
                          IconComponent={FilterAlt}
                          style={{
                            color: filterV === "" ? "#A2A2A2" : color.black,
                          }}
                          size={"medium"}
                          classes={{
                            iconOpen: styles.iconOpen,
                          }}
                        >
                          <MenuItem
                            value={""}
                            disabled
                            hidden
                            selected
                            style={{ minWidth: 220 }}
                          >
                            Filter
                          </MenuItem>
                          <ListItemButton
                            onClick={() => {
                              settypeList(!typeList);
                              setbrandList(false);
                              setterrainList(false);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>Vehicle Type</Typography>
                            {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </ListItemButton>
                          {dropDownData.vehicle_type.map((item, index) => {
                            return (
                              <MenuItem
                                style={{
                                  display: typeList ? "flex" : "none",
                                }}
                                key={index}
                                value={item?.id}
                              >
                                {item?.label}
                              </MenuItem>
                            );
                          })}
                          <ListItemButton
                            onClick={() => {
                              setbrandList(!brandList);
                              settypeList(false);
                              setterrainList(false);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>Vehicle Brand</Typography>
                            {brandList ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </ListItemButton>
                          {dropDownData.vehicle_brand.map((item, index) => {
                            return (
                              <MenuItem
                                style={{
                                  display: brandList ? "flex" : "none",
                                }}
                                key={index}
                                value={item?.id}
                              >
                                {item?.label}
                              </MenuItem>
                            );
                          })}
                          <ListItemButton
                            onClick={() => {
                              setterrainList(!terrainList);
                              settypeList(false);
                              setbrandList(false);
                            }}
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <Typography>Terrain</Typography>
                            {terrainList ? (
                              <ExpandLessIcon />
                            ) : (
                              <ExpandMoreIcon />
                            )}
                          </ListItemButton>
                          {dropDownData.terrain.map((item, index) => {
                            return (
                              <MenuItem
                                style={{
                                  display: terrainList ? "flex" : "none",
                                }}
                                key={index}
                                value={item?.id}
                              >
                                {item?.label}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </Grid>
                      <TextField
                        fullWidth
                        placeholder="Search"
                        className={styles.inputFieldStyle}
                        value={searchFilterV}
                        onChange={(v) => {
                          isEmpty(v.target.value) && getVehicleist(true);
                          handleSearchV(v?.target?.value);
                        }}
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            if (
                              !isEmpty(searchFilterV) ||
                              (!isNull(datePickerV) && !isNull(datePickerV1))
                            ) {
                              handlePageNoV(1);
                              getVehicleist(false);
                            }
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ lineHeight: 0 }}>
                                <Search style={{ fontSize: 20 }} />
                              </div>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ lineHeight: 0, cursor: "pointer" }}>
                                <Tooltip
                                  title="Search by Vehicle No., Load Capacity and Fleet Name"
                                  placement="bottom"
                                  arrow
                                >
                                  <InfoIcon style={{ fontSize: 20 }} />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Select
                        fullWidth
                        value={recordV}
                        onChange={(v) => {
                          handlePageNoV(1);
                          handleRecordV(v.target.value);
                        }}
                      >
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={25}>25</MenuItem>
                        <MenuItem value={50}>50</MenuItem>
                        <MenuItem value={100}>100</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    alignItems={"center"}
                    style={{ marginTop: 10 }}
                    wrap="nowrap"
                    gap={1}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          showToolbar={false}
                          disableFuture
                          views={["year", "month", "day"]}
                          value={datePickerV}
                          onChange={(newValue) => {
                            handleDatePickerV(newValue);
                            setDisableV(false);
                          }}
                          inputFormat="dd-MM-yyyy"
                          DialogProps={{ className: styles.datePicker }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "DD-MM-YYYY",
                              }}
                              sx={{ svg: { color: color.primary } }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                        to
                      </Typography>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          showToolbar={false}
                          disabled={isNull(datePickerV) ? true : disableV}
                          minDate={datePickerV}
                          views={["year", "month", "day"]}
                          disableFuture
                          value={datePickerV1}
                          inputFormat="dd-MM-yyyy"
                          onChange={(newValue) => {
                            handleDatePickerV1(newValue);
                          }}
                          DialogProps={{ className: styles.datePicker }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "DD-MM-YYYY",
                              }}
                              sx={{
                                svg: {
                                  color:
                                    disableV || isNull(datePickerV)
                                      ? ""
                                      : color.primary,
                                },
                              }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item wrap="nowrap" style={{ display: "flex" }}>
                      <Button
                        variant="contained"
                        style={{
                          backgroundColor: color.secondary,
                        }}
                        onClick={() => {
                          if (
                            !isEmpty(searchFilterV) ||
                            (!isNull(datePickerV) && !isNull(datePickerV1))
                          ) {
                            handlePageNoV(1);
                            getVehicleist(false);
                          }
                        }}
                      >
                        <Search />
                      </Button>
                      <Button
                        variant="contained"
                        style={{
                          marginLeft: 10,
                        }}
                        disabled={!filterV && !searchFilterV && !datePickerV}
                        onClick={resetVehicleData}
                      >
                        <ResetIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  {pageLoadV ? (
                    <div className={styles.dataMain}>
                      <CircularProgress style={{ color: color.primary }} />
                    </div>
                  ) : isArray(vehicleListRows?.data) &&
                    !isEmpty(vehicleListRows?.data) ? (
                    <>
                      {renderMapFunction1}
                      <div
                        style={{
                          display: "flex",
                          marginTop: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            vehicleListRows?.pagination?.totalPage
                          )}
                          defaultPage={pageNoV}
                          boundaryCount={2}
                          siblingCount={0}
                          variant="outlined"
                          shape="rounded"
                          onChange={(v, e) => {
                            handlePageNoV(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataMain}>
                      <Typography>No Data</Typography>
                    </div>
                  )}
                </div>
              </div>
            )}
            {tabValue === 2 &&
              staticArray?.map((item, index) => {
                return (
                  <div style={{ padding: "0px 10px 0px 10px" }}>
                    <Grid
                      container
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      wrap="nowrap"
                      gap={1}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      <Typography>{item}</Typography>
                      <Button
                        disable={isOnline ? false : true}
                        style={{
                          backgroundColor: isOnline
                            ? color.secondary
                            : color.bordercolor,
                        }}
                        onClick={() => setInd(index) || setVisible(true)}
                      >
                        {ind === index && arrReportLoader ? (
                          <CircularProgress
                            style={{ color: color.white }}
                            size={24}
                          />
                        ) : (
                          "Download"
                        )}
                      </Button>
                    </Grid>
                    {index === staticArray.length - 1 ? "" : <Divider />}
                  </div>
                );
              })}
          </Grid>
        </>
      ) : (
        <Grid container className={styles.container}>
          <Grid
            id="jump_to_me"
            container
            alignItems={"center"}
            justifyContent="space-between"
            style={{ minHeight: 60 }}
            wrap="nowrap"
            backgroundColor={color.secondary}
            padding={"10px"}
            color={color.white}
            borderRadius={1}
          >
            <Typography variant="tableTitle">Fleet Management</Typography>
            <Grid display="flex" wrap="nowrap">
              {tabValue !== 2 && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      handleClick("addFleet", "");
                    }}
                    style={{
                      backgroundColor:
                        userdata?.role === "service_enginner"
                          ? color.bordercolor
                          : color.primary,
                    }}
                    disabled={
                      userdata?.role === "service_enginner" ||
                      userdata?.role === "fleet_owner" ||
                      userdata?.role === "fleet_manager"
                    }
                  >
                    Add Fleet
                  </Button>
                </>
              )}
              <Button
                variant="contained"
                style={{
                  marginLeft: 10,
                  backgroundColor: "#BBBBBB",
                  color: color.secondary,
                }}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <MoreVert />
              </Button>
              <Popover
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                id={id}
                open={pop}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: isTablet ? "center" : "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ListItemButton
                  selected={tabValue === 0}
                  onClick={(event) => {
                    handleFilterFL("");
                    handleFilterV("");
                    handlePageNo(1);
                    handleRecord(10);
                    setTabValue(0);
                    setAnchorEl(null);
                    handleDatePicker(null);
                    handleDatePicker1(null);
                  }}
                >
                  Fleet List
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 1}
                  onClick={() => {
                    handleFilterFL("");
                    handleFilterV("");
                    handlePageNoV(1);
                    handleRecordV(10);
                    setTabValue(1);
                    setAnchorEl(null);
                    handleDatePickerV(null);
                    handleDatePickerV1(null);
                  }}
                >
                  Vehicle List
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 2}
                  onClick={() => {
                    handleFilterFL("");
                    handleFilterV("");
                    setTabValue(2);
                    setAnchorEl(null);
                  }}
                >
                  Master Reports
                </ListItemButton>
              </Popover>
            </Grid>
          </Grid>
          <Grid container style={{ marginTop: 10 }}>
            <Typography variant="tableTitle">
              {tabValue === 2
                ? "Master Reports"
                : tabValue === 1
                ? "Vehicle List"
                : "Fleet List"}
            </Typography>
          </Grid>
          {tabValue === 0 && (
            <Grid>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: 10 }}
                wrap="nowrap"
              >
                <Grid item container gap={1}>
                  <Grid item>
                    <Select
                      fullWidth
                      value={filter}
                      onChange={(v) => {
                        handlePageNo(1);
                        handleFilterFL(v.target.value);
                      }}
                      onOpen={() => {
                        if (isEmpty(filter.toString())) {
                          setDistrictList(false);
                          setRoList(false);
                        }
                      }}
                      displayEmpty
                      IconComponent={FilterAlt}
                      style={filter === "" ? { color: "#A2A2A2" } : {}}
                      classes={{
                        iconOpen: styles.iconOpen,
                      }}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Filter
                      </MenuItem>
                      <ListItemButton
                        onClick={() => {
                          setDistrictList(!districtList);
                          setRoList(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>District</Typography>
                        {districtList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItemButton>
                      {dropDownData.district.map((item, index) => {
                        return (
                          <MenuItem
                            style={{
                              display: districtList ? "flex" : "none",
                            }}
                            key={index}
                            value={item?.id}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                      <ListItemButton
                        onClick={() => {
                          setRoList(!RoList);
                          setDistrictList(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Regional Office</Typography>
                        {RoList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItemButton>
                      {dropDownData.office_region.map((item, index) => {
                        return (
                          <MenuItem
                            style={{
                              display: RoList ? "flex" : "none",
                            }}
                            key={index}
                            value={item?.id}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </Grid>
                  <Grid item>
                    <Select
                      fullWidth
                      value={record}
                      onChange={(v) => {
                        handlePageNo(1);
                        handleRecord(v.target.value);
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Grid>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    disabled={isOnline ? false : true}
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                    }}
                    onClick={() => getFleetList(false, 1)}
                  >
                    {downloadLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      <img src={Images.Mdownload} alt="download" />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
                wrap="nowrap"
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    value={datePicker}
                    onChange={(newValue) => {
                      handleDatePicker(newValue);
                      setDisable(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(datePicker) ? true : disable}
                    minDate={datePicker}
                    disableFuture
                    value={datePicker1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      handleDatePicker1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              disable || isNull(datePicker)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                container
                style={{ marginTop: 10 }}
                alignItems={"center"}
                wrap="nowrap"
              >
                <Grid item>
                  <TextField
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getFleetList(true);
                      handleSearch(v?.target?.value);
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        if (
                          !isEmpty(searchFilter) ||
                          (!isNull(datePicker) && !isNull(datePicker1))
                        ) {
                          handlePageNo(1);
                          getFleetList(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Fleet Name, Fleet Code, Owner, Owner's No., Contact Person and Mobile No."
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  wrap="nowrap"
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary, marginLeft: 10 }}
                    onClick={() => {
                      if (
                        !isEmpty(searchFilter) ||
                        (!isNull(datePicker) && !isNull(datePicker1))
                      ) {
                        getFleetList(false);
                      }
                    }}
                  >
                    <Search />
                  </Button>
                  <Button
                    fullWidth
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    disabled={
                      !filter && !searchFilter && !datePicker ? true : false
                    }
                    onClick={resetFilterData}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(fleetListRows?.data) &&
                !isEmpty(fleetListRows?.data) ? (
                <>
                  {fleetListRows?.data?.map((item, index) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <Grid
                          item
                          container
                          id={`jump_to_me${index}`}
                          style={{
                            marginTop: 20,
                            border: `1px solid ${color.bordercolor}`,
                            alignItems: "center",
                            overflow: "hidden",
                            boxShadow: color.shadow,
                          }}
                        >
                          {activeIndex !== index ? (
                            <Table className={styles.customtableMobile}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Fleet Name
                                  </TableCell>
                                  <TableCell variant="head">
                                    No. of Vehicles
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicles_allocated || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">Owner</TableCell>
                                  <TableCell variant="head">
                                    Owner’s No.
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.owner_name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.owner_phone || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          ) : (
                            <Table className={styles.customtableMobile}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Fleet Name
                                  </TableCell>
                                  <TableCell variant="head">
                                    No. of Vehicles
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicles_allocated || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">Owner</TableCell>
                                  <TableCell variant="head">
                                    Owner’s No.
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.owner_name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.owner_phone || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">District</TableCell>
                                  <TableCell variant="head">
                                    Regional Office
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {District(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {ReginalOffice(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Fleet Code
                                  </TableCell>
                                  <TableCell variant="head">
                                    Unresolved Issues
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.code || "-"}
                                  </TableCell>

                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    <div
                                      onClick={() => {
                                        if (item?.pending_issues_count) {
                                          handleClick("UnresolvedIssue", item);
                                        } else {
                                          return null;
                                        }
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          color: item?.pending_issues_count
                                            ? "red"
                                            : color.disable,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        {item?.pending_issues_count || "0"}
                                      </Typography>
                                    </div>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Contact Person
                                  </TableCell>
                                  <TableCell variant="head">
                                    Mobile No.
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.contact_name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.contact_phone || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">Added On</TableCell>
                                  <TableCell variant="head">Added By</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {AddedOn(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.added_by || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    rowSpan={2}
                                    style={{ padding: "10px 0px 10px 20px" }}
                                  >
                                    <Tooltip
                                      title="Edit"
                                      placement="right"
                                      arrow
                                    >
                                      <Button
                                        variant="contained"
                                        disabled={
                                          userdata?.role ===
                                            "service_enginner" ||
                                          userdata?.role === "fleet_owner"
                                        }
                                        onClick={() => {
                                          handleClick(
                                            "addFleet",
                                            fleetListRows?.data[index]
                                          );
                                        }}
                                      >
                                        <Edit />
                                      </Button>
                                    </Tooltip>
                                  </TableCell>
                                  <TableCell
                                    rowSpan={2}
                                    align="right"
                                    style={{ padding: "10px 20px 10px 0px" }}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: color.primary,
                                      }}
                                      onClick={() =>
                                        handleClick("fleetDetails", item)
                                      }
                                    >
                                      <Visibility />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          )}
                          <div
                            onClick={(v) => {
                              if (activeIndex === index) {
                                setactiveIndex(null);
                              } else {
                                setactiveIndex(index);
                              }
                            }}
                            style={{
                              position: "absolute",
                              bottom: -18,
                              left: "45%",
                            }}
                          >
                            {activeIndex === index ? (
                              <div style={{ transform: "rotate(180deg)" }}>
                                <TableToggle />
                              </div>
                            ) : (
                              <TableToggle />
                            )}
                          </div>
                        </Grid>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(fleetListRows?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        handlePageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </Grid>
          )}
          {tabValue === 1 && (
            <Grid>
              <Grid
                item
                container
                wrap="nowrap"
                gap={1}
                style={{ marginTop: 10 }}
              >
                <Grid item container gap={1} wrap="nowrap">
                  <Select
                    placeholder="Filter"
                    value={filterV}
                    onChange={(v) => {
                      handlePageNoV(1);
                      handleFilterV(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filterV.toString())) {
                        settypeList(false);
                        setbrandList(false);
                        setterrainList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={{
                      color: filterV === "" ? "#A2A2A2" : color.black,
                    }}
                    size={"medium"}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem
                      value={""}
                      disabled
                      hidden
                      selected
                      style={{ minWidth: 220 }}
                    >
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        settypeList(!typeList);
                        setbrandList(false);
                        setterrainList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Vehicle Type</Typography>
                      {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.vehicle_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: typeList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setbrandList(!brandList);
                        settypeList(false);
                        setterrainList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Vehicle Brand</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.vehicle_brand.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: brandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setterrainList(!terrainList);
                        settypeList(false);
                        setbrandList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Terrain</Typography>
                      {terrainList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.terrain.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: terrainList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <Select
                    value={recordV}
                    onChange={(v) => {
                      handlePageNoV(1);
                      handleRecordV(v.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={isOnline ? false : true}
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                    }}
                    onClick={() => getVehicleist(false, 1)}
                  >
                    {downloadLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      <img src={Images.Mdownload} alt="download" />
                    )}
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                container
                style={{
                  alignItems: "center",
                  marginTop: 10,
                }}
                wrap="nowrap"
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={datePickerV}
                    onChange={(newValue) => {
                      handleDatePickerV(newValue);
                      setDisableV(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(datePickerV) ? true : disableV}
                    minDate={datePickerV}
                    views={["year", "month", "day"]}
                    disableFuture
                    value={datePickerV1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      handleDatePickerV1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              disableV || isNull(datePickerV)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                style={{ marginTop: 10 }}
                wrap="nowrap"
              >
                <Grid item>
                  <TextField
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilterV}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getVehicleist(true);
                      handleSearchV(v?.target?.value);
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        if (
                          !isEmpty(searchFilterV) ||
                          (!isNull(datePickerV) && !isNull(datePickerV1))
                        ) {
                          handlePageNoV(1);
                          getVehicleist(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Vehicle No., Load Capacity and Fleet Name"
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item wrap="nowrap" style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary, marginLeft: 10 }}
                    onClick={() => {
                      if (
                        !isEmpty(searchFilterV) ||
                        (!isNull(datePickerV) && !isNull(datePickerV1))
                      ) {
                        handlePageNoV(1);
                        getVehicleist(false);
                      }
                    }}
                  >
                    <Search />
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    disabled={!filterV && !searchFilterV && !datePickerV}
                    onClick={resetVehicleData}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>

              {pageLoadV ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(vehicleListRows?.data) &&
                !isEmpty(vehicleListRows?.data) ? (
                <>
                  {vehicleListRows?.data?.map((item, index) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <Grid
                          item
                          container
                          id={`jump_to_me${index}`}
                          style={{
                            marginTop: 20,
                            border: `1px solid ${color.bordercolor}`,
                            alignItems: "center",
                            overflow: "hidden",
                            boxShadow: color.shadow,
                          }}
                        >
                          {activeIndexV !== index ? (
                            <Table className={styles.customtableMobile}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Fleet Name
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle No.
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.fleet_name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_no || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle Brand
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle Model
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getVehicleBrand(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_model_name || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          ) : (
                            <Table className={styles.customtableMobile}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Fleet Name
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle No.
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.fleet_name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_no || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle Brand
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle Model
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getVehicleBrand(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_model_name || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle Type
                                  </TableCell>
                                  <TableCell variant="head">
                                    No. of Tyres
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getVehicleType(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getNoOfTyre(index)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Initial Reading
                                  </TableCell>
                                  <TableCell variant="head">Terrain</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.current_reading}
                                    {item?.current_reading ? " Km" : "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getTerrain(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Load Capacity
                                  </TableCell>
                                  <TableCell variant="head">
                                    Driver's Name
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.load_capicity}
                                    {item?.current_reading ? " Tonnes" : "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.driver_name || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Driver's No.
                                  </TableCell>
                                  <TableCell variant="head">
                                    Goods Carried
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.driver_phone || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.goods_carried || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Route Origin
                                  </TableCell>
                                  <TableCell variant="head">
                                    Route Destination
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.route_origin || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.route_destination || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  {/* <TableCell variant="head">
                                    Alignments Claimed
                                  </TableCell> */}
                                  <TableCell variant="head">Added On</TableCell>
                                  <TableCell variant="head">Added By</TableCell>
                                </TableRow>
                                <TableRow>
                                  {/* <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.alignment_count}/5
                                  </TableCell> */}
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getAddedOn(index)}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.added_by || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle Status
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    <Switch
                                      checked={
                                        item?.status === 1 ? true : false
                                      }
                                      onChange={() => {
                                        changeVehicleStatus(item);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    style={{ padding: "10px 0px 10px 20px" }}
                                  >
                                    <Button
                                      variant="contained"
                                      disabled={
                                        userdata?.role === "fleet_owner" ||
                                        item?.status === 0
                                      }
                                      onClick={() =>
                                        handleClick("editVehicle", item)
                                      }
                                    >
                                      <Edit />
                                    </Button>
                                  </TableCell>
                                  <TableCell
                                    align="right"
                                    style={{ padding: "10px 20px 10px 0px" }}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: color.primary,
                                      }}
                                      onClick={() =>
                                        handleClick("vehicleDetails", item)
                                      }
                                    >
                                      <Visibility />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          )}
                          <div
                            onClick={(v) => {
                              if (activeIndexV === index) {
                                setactiveIndexV(null);
                              } else {
                                setactiveIndexV(index);
                              }
                            }}
                            style={{
                              position: "absolute",
                              bottom: -18,
                              left: "45%",
                            }}
                          >
                            {activeIndexV === index ? (
                              <div style={{ transform: "rotate(180deg)" }}>
                                <TableToggle />
                              </div>
                            ) : (
                              <TableToggle />
                            )}
                          </div>
                        </Grid>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      marginTop: 20,
                      marginBottom: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(vehicleListRows?.pagination?.totalPage)}
                      defaultPage={pageNoV}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        handlePageNoV(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </Grid>
          )}
          {tabValue === 2 &&
            staticArray?.map((item, index) => {
              return (
                <div>
                  <Grid
                    container
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    wrap="nowrap"
                    gap={1}
                    style={{ marginTop: 20, marginBottom: 20 }}
                  >
                    <Typography>{item}</Typography>
                    <Button
                      disabled={isOnline ? false : true}
                      style={{
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                      }}
                      onClick={() => {
                        setInd(index) || setVisible(true);
                      }}
                    >
                      {ind === index && arrReportLoader ? (
                        <CircularProgress
                          style={{ color: color.white }}
                          size={24}
                        />
                      ) : (
                        "Download"
                      )}
                    </Button>
                  </Grid>
                  {index === staticArray.length - 1 ? "" : <Divider />}
                </div>
              );
            })}
        </Grid>
      )}
      <MDateRange
        isSelect
        visible={visible}
        handleModal={(type, fleetIds, vehicleIds, fromDate, toDate, isAll) => {
          if (type === "download") {
            ind === 0
              ? getResolvedissuereport(
                  fleetIds,
                  vehicleIds,
                  fromDate,
                  toDate,
                  isAll,
                  1
                )
              : ind === 1
              ? getResolvedissuereport(
                  fleetIds,
                  vehicleIds,
                  fromDate,
                  toDate,
                  isAll,
                  2
                )
              : ind === 2
              ? getUnresolvedIssue(
                  fleetIds,
                  vehicleIds,
                  fromDate,
                  toDate,
                  isAll
                )
              : getavgNsdReport(fleetIds, vehicleIds, fromDate, toDate, isAll);
          }
          setVisible(false);
        }}
      />
    </>
  );
}
