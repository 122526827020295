import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  Button,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Pagination,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { color } from "../../../Config/theme.js";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Search } from "@mui/icons-material";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as XLSX from "xlsx/xlsx.mjs";

function InspectionHistory(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleSearch = () => null,
    handleDatePicker = () => null,
    pageNo = 1,
    record = 10,
    from = null,
    searchFilter = "",
    datePicker = null,
    data,
  } = props;
  const styles = useStyles();
  const { token, isOnline, userdata } = useSelector((state) => state.auth);
  const [inspectionHistoryList, setInspectionListRows] = useState({});

  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  const [isFromDashboard, setIsFromDashboard] = useState(from);
  // const [datePicker, setDatePicker] = useState(null);

  const InspectionHistoryColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 70,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 190,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 180,
    },
    {
      field: "inspected_by",
      headerName: "Last Inspected By",
      width: 175,
    },
    {
      field: "Inspected On",
      sortable: false,
      headerName: "Last Inspected On",
      width: 190,
      renderCell: (params) => {
        const timestamp = params?.row?.last_inspected_at;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <Typography>
            {params?.row?.last_inspected_at ? displayDate : ""}
          </Typography>
        );
      },
    },

    {
      field: "pending_issues_count",
      headerName: "Unresolved Issues",
      width: 200,
      renderCell: (params) => {
        const issueCount = params?.row?.pending_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              if (issueCount == 0) {
                return null;
              } else {
                handleClick("UnresolvedIssue", params?.row);
              }
              // navigate("/dashboard/FleetManagement", {
              //   state: {
              //     set: "fleetDetails",
              //     id: params?.row?.fleet_id,
              //     name: params?.row?.fleet_name,
              //     vehicle_id: params?.row?.vehicle_id,
              //     vehicle_no: params?.row?.vehicle_no,
              //   },
              // });
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : "red",
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "partially_resolved_issues_count",
      headerName: "Partially Resolved Issues",
      width: 235,
      renderCell: (params) => {
        const issueCount = params?.row?.partially_resolved_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              if (issueCount == 0) {
                return null;
              } else {
                handleClick("inspectionIssue", params?.row, "partiallyresolve");
              }
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : color.primary,
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "resolved_issues_count",
      headerName: "Resolved Issues",
      width: 180,
      renderCell: (params) => {
        const issueCount = params?.row?.resolved_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              if (issueCount == 0) {
                return null;
              } else {
                handleClick("inspectionIssue", params?.row, "resolve");
              }
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : color.primary,
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "inspection_monthly",
      headerName: "Inspections (Month)",
      width: 200,
    },
    {
      field: "inspection_overall",
      headerName: "Inspections (Overall)",
      width: 200,
    },
  ];
  useEffect(() => {
    if (from === "inspectionHistory") {
      handleDatePicker(moment(new Date()).format("YYYY-MM-DD"));
    }
    if (data?.from === "notificationS" && !isEmpty(data?.date)) {
      handleDatePicker(moment(data?.date).format("YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    if (isOnline) {
      document.title = Setting.page_name.Inspection;
    }
  }, [isOnline]);

  useEffect(() => {
    if (data?.from !== "notificationS") {
      setPageLoad(true);
      getInspectionList(false);
    }
  }, [pageNo, record]);

  useEffect(() => {
    if (
      data?.from !== "notificationS" &&
      isNull(datePicker) &&
      isEmpty(searchFilter)
    ) {
      getInspectionList(false);
    }
  }, [datePicker, searchFilter]);

  useEffect(() => {
    if (
      data?.from === "notificationS" &&
      !isEmpty(data?.date) &&
      !isNull(datePicker)
    ) {
      setPageLoad(true);
      getInspectionList(false);
    } else if (data?.from === "notificationS" && isEmpty(data?.date)) {
      setPageLoad(true);
      getInspectionList(false);
    }
  }, [datePicker, searchFilter, pageNo, record]);

  useImperativeHandle(ref, () => ({
    inspectionHistoryList: inspectionHistoryList,
    getInspectionList: () => {
      getInspectionList(false, 1);
    },
  }));

  function downloadFleetData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.last_inspected_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Last Inspected By": row?.inspected_by || "-",
        "Last Inspected On": row?.last_inspected_at ? displayDate : "-",
        "Unresolved Issues": row?.pending_issues_count || "-",
        "Partially Resolved Issues": Number(
          row?.partially_resolved_issues_count
        ),
        "Resolved Issues": Number(row?.resolved_issues_count),
        "Inspections (Month)": Number(row?.inspection_monthly),
        "Inspections (Overall)": Number(row?.inspection_overall),
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Inspection History List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inspection History List.xlsx");
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getInspectionList(bool, v) {
    v && handleLoader(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : isFromDashboard === "inspectionHistory"
      ? moment(new Date()).format("YYYY-MM-DD")
      : "";
    let endpoints = `${
      Setting.endpoints.inspectionlist
    }?page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&date=${date}&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadFleetData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setInspectionListRows(listObj);
          }
        v && auditSave("Inspection History List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      handleLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
    handleLoader(false);
  }

  function resetFilterData() {
    handleSearch("");
    setIsFromDashboard("");
    handleDatePicker(null);
    handlePageNo(1);
    window.history.state.usr.date = "";
  }

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 14 }}
        wrap={"nowrap"}
      >
        <Grid
          item
          lg={8}
          style={{ display: "flex", alignItems: "center" }}
          wrap={"nowrap"}
        >
          <Grid
            item
            lg={4}
            style={{ display: "flex", alignItems: "center" }}
            wrap={"nowrap"}
          >
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={searchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && getInspectionList(true);
                handleSearch(v?.target?.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    handlePageNo(1);
                    getInspectionList(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Fleet Name and Vehicle No."
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <div style={{ marginLeft: 20 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                disabled={
                  isFromDashboard === "inspectionHistory" ? true : false
                }
                disableFuture
                views={["year", "month", "day"]}
                value={datePicker}
                onChange={(newValue) => {
                  handleDatePicker(newValue);
                }}
                inputFormat="dd-MM-yyyy"
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{ svg: { color: color.primary } }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                  handlePageNo(1);
                  getInspectionList(false);
                }
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={resetFilterData}
              disabled={
                isEmpty(searchFilter) && isNull(datePicker) ? true : false
              }
            >
              Reset
            </Button>
          </div>
        </Grid>
        <Grid
          item
          lg={1.6}
          style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
        >
          <Typography style={{ fontSize: 14 }}>Records:</Typography>
          <Select
            fullWidth
            value={record}
            onChange={(v) => {
              handlePageNo(1);
              handleRecord(v.target.value);
            }}
            style={{ marginLeft: 10 }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(inspectionHistoryList?.data) &&
        !isEmpty(inspectionHistoryList?.data) ? (
        <div style={{ marginTop: 20 }}>
          <DataGrid
            rows={inspectionHistoryList?.data}
            columns={InspectionHistoryColumn}
            disableColumnMenu
            hideFooter
            showCellRightBorder
            disableSelectionOnClick
            showColumnRightBorder
            autoHeight={true}
            onCellClick={(params) => {
              if (
                params?.field !== "pending_issues_count" &&
                params?.field !== "resolved_issues_count" &&
                params?.field !== "partially_resolved_issues_count"
              ) {
                handleClick("inspectionHistory", params?.row);
              }
            }}
          />
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(inspectionHistoryList?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={2}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => {
                handlePageNo(e);
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
    </div>
  );
}

export default forwardRef(InspectionHistory);
