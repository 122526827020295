import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting";
import Box from "@mui/material/Box";
import {
  FormControlLabel,
  FormHelperText,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  Modal,
  OutlinedInput,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles.js";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { cloneDeep, isArray, isEmpty, isNull } from "lodash";
import { color } from "../../../Config/theme";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../ConfirmDialog/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { useTheme } from "@emotion/react";

const errorObj = {
  vNumberErr: false,
  odometerRunningErr: false,
  rowsErr: false,
  tyresErr: false,
  vehicleTypeErr: false,
  vehicleBrandErr: false,
  vModelErr: false,
  loadCapacityErr: false,
  goodsCarriedErr: false,
  terrainErr: false,
  routeOriginErr: false,
  routeDestinationErr: false,
  currentReadingErr: false,
  contactNumErr: false,
  driverNumberErr: false,
  driverNameErr: false,
  selectedFileErr: false,
  gpsErr: false,
  panDetailsErr: false,
  dateErr: false,
  dateMsg: "",
  panDetailsMsg: "",
  gpsMsg: "",
  driverNameMsg: "",
  odometerRunningMsg: "",
  vNumberMsg: "",
  rowsMsg: "",
  tyresMsg: "",
  vehicleTypeMsg: "",
  vehicleBrandMsg: "",
  vModelMsg: "",
  loadCapacityMsg: "",
  goodsCarriedMsg: "",
  terrainMsg: "",
  routeOriginMsg: "",
  routeDestinationMsg: "",
  currentReadingMsg: "",
  contactNumMsg: "",
  driverNumberMsg: "",
  selectedFileMsg: "",
  pyearErr: false,
  pyearMsg: "",
};

export default function AddVehicle(props) {
  const { handleClick = () => null, editData = {}, fleetData = {} } = props;
  const { token, dropDownData } = useSelector((state) => state.auth);
  const { isMobile } = Setting;

  const styles = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const isEdit = !isEmpty(editData);
  const alphabatic_Regex = Setting.JS_Regex.alphabatic_Regex;
  const vehicleNo_Regex = Setting.JS_Regex.vehicleNo;
  const currency_Regex = Setting.JS_Regex.currency_Regex;

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [isEditDialog, setIsEditDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  const [openCard, setOpenCard] = useState(false);
  const dummyVTypeArr = cloneDeep(dropDownData?.vehicle_type);
  const [vTypeArr, setVTypeArry] = useState(dummyVTypeArr);

  const [errObj, setErrObj] = useState(errorObj);
  const [loader, setLoader] = useState(false);
  const [pageLoad, setPageLoad] = useState(isEdit ? true : false);
  const [vNumber, setVNumber] = useState("");
  const [odometerRunning, setOdometerReading] = useState("");
  const [gps, setGPS] = useState("");
  const [rows, setRows] = useState("");
  const [tyres, setTyres] = useState("");
  const [vehicleType, setVehicleType] = useState({});
  const [vehicleBrand, setVehicleBrand] = useState("");
  const [vModel, setvModel] = useState("");
  const [goodsCarried, setGoodsCarried] = useState("");
  const [loadCapacity, setLoadCapacity] = useState("");
  const [terrain, setTerrain] = useState([]);
  const [routeOrigin, setRouteOrigin] = useState("");
  const [routeDestination, setRouteDestination] = useState("");
  const [currentReading, setCurrentReading] = useState("");
  const [driverName, setDriverName] = useState("");
  const [driverNumber, setDriverNumber] = useState("");
  const [file, setFile] = useState("No File Selected");
  const [selectedFile, setSlectedFile] = useState(null);
  const [datePicker, setDatePicker] = useState(new Date());
  const [purchaseDate, setPurchaseDate] = useState(null);
  const [vehicleModel, setVehicleModel] = useState([]);
  const [status, setStatus] = useState("1");
  const { isOnline } = useSelector((state) => state.auth);

  useEffect(() => {
    if (isEdit) {
      const fileName = editData?.rc_book.split("/");
      let array = editData.terrain_ids.split(",");
      let res = dropDownData.terrain.filter((item) => {
        let a = array.includes(item.id.toString());
        return a;
      });

      setOdometerReading(editData?.is_odo_meter || "0");
      setGPS(editData?.is_gps_meter || "0");
      setVNumber(editData?.vehicle_no || "");
      // setVIdentifier(editData?.vehicle_identifier || "");
      setTyres(editData?.no_of_tyres || "");
      setVehicleBrand(editData?.vehicle_brand_id || "");
      setvModel(editData?.vehicle_model || "");
      setLoadCapacity(editData?.load_capicity || "");
      setGoodsCarried(editData?.goods_carried || "");
      setTerrain(res || []);
      setRouteOrigin(editData?.route_origin || "");
      setRouteDestination(editData?.route_destination || "");
      setCurrentReading(editData?.current_reading || "");
      setDriverName(editData?.driver_name || "");
      setDriverNumber(editData?.driver_phone || "");
      setFile(fileName[fileName.length - 1] || "No File Selected");
      setSlectedFile(editData?.rc_book || null);
      setRows(editData?.no_of_rows || "");
      setPurchaseDate(editData?.purchase_year || null);
      setDatePicker(editData?.capture_date || new Date());
      setStatus(editData?.status.toString() || "0");
      setPageLoad(false);

      const vType = dropDownData?.vehicle_type?.find(
        (v) => v?.id === editData?.vehicle_type_id
      );

      const vTypeInd = dropDownData?.vehicle_type?.findIndex(
        (v) => v?.id === editData?.vehicle_type_id
      );
      setVehicleType(vType);
      SelectTyre(vTypeInd);
      // const fname = fleetData?.find((v) => v?.id === editData?.fleet_id);
      // const fname = fleetData?.find((v) => v?.id === editData?.fleet_id);
    } else {
      // setVIdentifier(`FK ${Math.random().toString(36).substr(2, 6)}`);
    }
  }, []);

  useEffect(() => {
    !isEmpty(vehicleBrand.toString()) && getVehicleMasterData();
  }, [vehicleBrand]);

  useEffect(() => {
    !isEmpty(vModel.toString()) && getDataByVehicle();
  }, [vModel]);

  const submitData = async () => {
    setRemoveBtnLoad(true);
    !isEdit && setLoader(true);
    let endPoints = Setting.endpoints.addvehicle;

    const array = [];
    terrain.map((v) => {
      array.push(v.id);
    });

    const captureDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";

    const pDate = !isNull(purchaseDate)
      ? moment(purchaseDate).format("YYYY")
      : "";

    const params = {
      "Vehicle[fleet_id]": !isEmpty(fleetData)
        ? fleetData?.id
        : editData?.fleet_id,
      "Vehicle[vehicle_no]": vNumber.trim(),
      // "Vehicle[vehicle_identifier]": VIdentifier,
      "Vehicle[is_odo_meter]": odometerRunning,
      "Vehicle[is_gps_meter]": gps,
      "Vehicle[no_of_rows]": rows,
      "Vehicle[no_of_tyres]": tyres,
      "Vehicle[vehicle_type_id]": vehicleType.id,
      "Vehicle[vehicle_brand_id]": vehicleBrand,
      "Vehicle[vehicle_model]": vModel,
      "Vehicle[load_capicity]": loadCapacity,
      "Vehicle[goods_carried]": goodsCarried,
      "Vehicle[terrain_ids]": array,
      "Vehicle[route_origin]": routeOrigin.trim(),
      "Vehicle[route_destination]": routeDestination.trim(),
      "Vehicle[current_reading]": currentReading,
      "Vehicle[driver_name]": driverName.trim(),
      "Vehicle[driver_phone]": driverNumber.trim(),
      "Vehicle[rc_book]": selectedFile,
      "Vehicle[capture_date]": captureDate,
      "Vehicle[purchase_year]": pDate,
      "Vehicle[status]": status,
    };
    if (isEdit) {
      params["Vehicle[id]"] = editData?.id;
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        handleClick("cancel");
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setRemoveBtnLoad(false);
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setRemoveBtnLoad(false);
      setLoader(false);
    }
  };

  const deleteVehicle = async () => {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.deletevehicle}?id=${editData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        handleClick("cancel");
        setConfirmDialog(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
    }
  };

  function clearData() {
    setVNumber("");
    // setVIdentifier("");
    setOdometerReading("");
    setGPS("");
    setTyres("");
    setVehicleType({});
    setVehicleBrand("");
    setvModel("");
    setLoadCapacity("");
    setGoodsCarried("");
    setTerrain([]);
    setRouteOrigin("");
    setRouteDestination("");
    setCurrentReading("");
    setDriverName("");
    setDriverNumber("");
    setFile("No File Selected");
    setSlectedFile(null);
    setDatePicker(new Date());
  }

  const checkValidation = () => {
    const error = { ...errObj };
    let valid = true;
    let scroll = false;
    let section = null;

    if (isEmpty(vNumber)) {
      valid = false;
      error.vNumberErr = true;
      error.vNumberMsg = "Please enter Vehicle No. ";
      scroll = true;
      section = document.querySelector("#vNumber");
    } else if (!vehicleNo_Regex.test(vNumber)) {
      valid = false;
      error.vNumberErr = true;
      error.vNumberMsg = "Please enter Valid Vehicle No. ";
      scroll = true;
      section = document.querySelector("#vNumber");
    }

    if (isEmpty(odometerRunning.toString())) {
      valid = false;
      error.odometerRunningErr = true;
      error.odometerRunningMsg = "Please select the Odometer is Running";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#odometer");
      }
    }

    if (isEmpty(gps.toString())) {
      valid = false;
      error.gpsErr = true;
      error.gpsMsg = "Please select the GPS Tracking is Running";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#gps");
      }
    }

    if (isEmpty(rows.toString())) {
      valid = false;
      error.rowsErr = true;
      error.rowsMsg = "Please enter No. of Axle";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#rows");
      }
    } else if (rows > 10) {
      valid = false;
      error.rowsErr = true;
      error.rowsMsg = "Maximum No. of Axle count allowed is 10.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#rows");
      }
    }
    if (isEmpty(tyres.toString())) {
      valid = false;
      error.tyresErr = true;
      error.tyresMsg = "Please enter No. of Tyres";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#tyres");
      }
    }

    if (isEmpty(vehicleType)) {
      valid = false;
      error.vehicleTypeErr = true;
      error.vehicleTypeMsg = "Please select Vehicle Type";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#vehicleType");
      }
    }

    if (isEmpty(vehicleBrand.toString())) {
      valid = false;
      error.vehicleBrandErr = true;
      error.vehicleBrandMsg = "Please select Vehicle Brand";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#vehicleBrand");
      }
    }

    if (isEmpty(vModel.toString())) {
      valid = false;
      error.vModelErr = true;
      error.vModelMsg = "Please enter Vehicle Model";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#vModel");
      }
    }

    if (isEmpty(loadCapacity)) {
      valid = false;
      error.loadCapacityErr = true;
      error.loadCapacityMsg = "Please enter the Load Capacity";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#loadCapacity");
      }
    }
    // if (isEmpty(goodsCarried.trim())) {
    //   valid = false;
    //   error.goodsCarriedErr = true;
    //   error.goodsCarriedMsg = "Please enter the Goods Carried by the Vehicles";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#goodsCarried");
    //   }
    // } else
    // if (!isEmpty(goodsCarried.trim()) && !alphabatic_Regex.test(goodsCarried)) {
    //   valid = false;
    //   error.goodsCarriedErr = true;
    //   error.goodsCarriedMsg = "Please enter only Alphabets";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#goodsCarried");
    //   }
    // }

    if (isEmpty(terrain)) {
      valid = false;
      error.terrainErr = true;
      error.terrainMsg = "Please select the Type of Terrain";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#terrain");
      }
    }

    // if (isEmpty(routeOrigin)) {
    //   valid = false;
    //   error.routeOriginErr = true;
    //   error.routeOriginMsg = "Please enter the Origin of Vehicle Route";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#routeOrigin");
    //   }
    // } else
    if (!isEmpty(routeOrigin) && !alphabatic_Regex.test(routeOrigin)) {
      valid = false;
      error.routeOriginErr = true;
      error.routeOriginMsg = "Please enter only Alphabets";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#routeOrigin");
      }
    }

    // if (isEmpty(routeDestination)) {
    //   valid = false;
    //   error.routeDestinationErr = true;
    //   error.routeDestinationMsg =
    //     "Please enter the Destination of Vehicle Route";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#routeDestination");
    //   }
    // } else
    if (
      !isEmpty(routeDestination) &&
      !alphabatic_Regex.test(routeDestination)
    ) {
      valid = false;
      error.routeDestinationErr = true;
      error.routeDestinationMsg = "Please enter only Alphabets";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#routeDestination");
      }
    }

    let c = currentReading.replaceAll(",", "");
    if (isEmpty(currentReading)) {
      valid = false;
      error.currentReadingErr = true;
      error.currentReadingMsg = "Please enter the Initial Reading";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#currentReading");
      }
    } else if (!isEmpty(currentReading) && !currency_Regex.test(c)) {
      valid = false;
      error.currentReadingErr = true;
      error.currentReadingMsg = "Please enter the valid Km";
    }

    // if (isEmpty(driverName)) {
    //   valid = false;
    //   error.driverNameErr = true;
    //   error.driverNameMsg = "Please enter Driver's Name";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#driverName");
    //   }
    // } else
    if (!isEmpty(driverName) && !alphabatic_Regex.test(driverName)) {
      valid = false;
      error.driverNameErr = true;
      error.driverNameMsg = "Please enter only Alphabets";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#driverName");
      }
    }

    // if (isEmpty(driverNumber)) {
    //   valid = false;
    //   error.driverNumberErr = true;
    //   error.driverNumberMsg = "Please enter Driver's Number";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#driverNumber");
    //   }
    // } else
    if (!isEmpty(driverNumber) && driverNumber.length < 10) {
      valid = false;
      error.driverNumberErr = true;
      error.driverNumberMsg = "Please enter Valid No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#driverNumber");
      }
    }

    let foundAddedDateInRange = moment(datePicker).isBetween(
      "2000-01-01",
      new Date()
    );

    if (isNull(datePicker) || isEmpty(datePicker.toString())) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please select Added Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    } else if (
      !isNull(datePicker) &&
      (datePicker === "Invalid Date" || !foundAddedDateInRange)
    ) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please enter valid Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    }

    let foundPurchaseDateInRange = moment(purchaseDate).isBetween(
      "1999",
      new Date()
    );

    if (
      !isNull(purchaseDate) &&
      (purchaseDate === "Invalid Date" || !foundPurchaseDateInRange)
    ) {
      valid = false;
      error.pyearErr = true;
      error.pyearMsg = "Please enter Valid Purchase Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#fDate");
      }
    }

    // if (isNull(selectedFile)) {
    //   valid = false;
    //   error.selectedFileErr = true;
    //   error.selectedFileMsg = "Please upload RC Book";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#selectedFile");
    //   }
    // }

    setErrObj(error);
    if (valid) {
      if (isEdit) {
        setIsEditDialog(true);
        setConfirmDialog(true);
      } else {
        submitData();
      }
    }
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  };

  const handleChange = (event) => {
    setTerrain(event?.target?.value);
    setErrObj({
      ...errObj,
      terrainErr: false,
      terrainMsg: "",
    });
  };

  function numberWithCommas(x) {
    let c = x.replaceAll(",", "");

    return c.toString().split(".")[0].length > 3
      ? c
          .toString()
          .substring(0, c.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c.toString().substring(c.toString().split(".")[0].length - 3)
      : c.toString();
  }

  function converter(value) {
    let convert = numberWithCommas(value);
    setCurrentReading(convert);
    setErrObj({
      ...errObj,
      currentReadingErr: false,
      currentReadingMsg: "",
    });
  }

  function SelectTyre(index, id) {
    setVTypeArry(dummyVTypeArr);
    const DummyArr = [...dummyVTypeArr];

    DummyArr.map((item, i) => {
      if (i === index) {
        item.selected = true;
      }
    });
    setVTypeArry(DummyArr);
  }

  function handleSelect() {
    const DummyArr = [...vTypeArr];
    const selected = DummyArr?.find((v) => v.selected === true);
    if (!isEmpty(selected)) {
      setVehicleType(selected);
    }
    setErrObj({ ...errObj, vehicleTypeErr: false, vehicleTypeMsg: "" });
    setOpenCard(false);
  }

  async function getDataByVehicle() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.getdatabyvehicle}?vehicle_model_id=${vModel}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        const vType = dropDownData?.vehicle_type?.find(
          (v) => v?.id === response?.data?.vehicle_type
        );

        const vTypeInd = dropDownData?.vehicle_type?.findIndex(
          (v) => v?.id === response?.data?.vehicle_type
        );
        setLoadCapacity(response?.data?.load_capicity || "");
        setRows(response?.data?.no_of_rows || "");
        setTyres(response?.data?.no_of_tyres || "");
        // setVehicleBrand(response?.data?.vehicle_brand || "");

        // setVehicleType(vType);
        // SelectTyre(vTypeInd);

        setErrObj({
          ...errObj,
          rowsErr: false,
          rowsMsg: "",
          tyresErr: false,
          tyresMsg: "",
          loadCapacityErr: false,
          loadCapacityMsg: "",
        });
      }
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast.error(error);
    }
  }

  async function getVehicleMasterData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehiclemodeldata}?vehicle_brand_id=${vehicleBrand}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        if (!isEmpty(response?.data)) {
          setVehicleModel(response?.data);
        } else {
          // toast.error("No Data Found");
        }
      }
    } catch (error) {
      console.log("ERROR=====>>>>>", error);
      toast.error(error);
    }
  }

  return (
    <Box>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : (
        <>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: "18px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"bold"}>
              {isEdit ? "Edit Vehicle" : "Add Vehicle"}
            </Typography>
            {isEdit ? (
              <Button
                style={{ backgroundColor: color.secondary }}
                onClick={() => setConfirmDialog(true)}
              >
                Delete
              </Button>
            ) : null}
          </Box>

          <Box className={styles.container}>
            <Grid
              container
              style={{ marginBottom: 20 }}
              alignItems={"center"}
              id="vNumber"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Vehicle No."} />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter Vehicle No."
                  value={vNumber}
                  error={errObj.vNumberErr}
                  helperText={errObj.vNumberErr ? errObj.vNumberMsg : null}
                  onChange={(e) => {
                    setVNumber(e.target.value);
                    setErrObj({
                      ...errObj,
                      vNumberErr: false,
                      vNumberMsg: "",
                    });
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={2.1} />
              <Grid item xs={12} md={9} lg={9.9}>
                <Typography
                  style={{
                    marginTop: errObj.vNumberErr ? 20 : 10,
                    fontSize: 12,
                  }}
                >
                  <b>Note :</b> Vehicle No. format is Like XX 00 X/XX/XXX 0000.
                  For e.g. :- GJ 07 DC 1111
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.odometerRunningErr ? 30 : 20 }}
              id="odometer"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Odometer Running"} />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9} maxWidth="100% !important">
                <FormControl fullWidth error={errObj.odometerRunningErr}>
                  <Select
                    displayEmpty
                    value={odometerRunning}
                    onChange={(event) => {
                      setOdometerReading(event.target.value);
                      setErrObj({
                        ...errObj,
                        odometerRunningErr: false,
                        odometerRunningMsg: "",
                      });
                    }}
                    size={"medium"}
                    style={odometerRunning === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Select the Odometer is Running
                    </MenuItem>
                    <MenuItem key={"yes"} value={"1"}>
                      Yes
                    </MenuItem>
                    <MenuItem key={"no"} value={"0"}>
                      No
                    </MenuItem>
                  </Select>
                  {errObj.odometerRunningErr ? (
                    <FormHelperText>{errObj.odometerRunningMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.vehicleBrandErr ? 30 : 20 }}
              id="vehicleBrand"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Vehicle Brand"} />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9} maxWidth="100% !important">
                <FormControl fullWidth error={errObj.vehicleBrandErr}>
                  <Select
                    displayEmpty
                    value={vehicleBrand}
                    // disabled={!isEmpty(vehicleModel)}
                    onChange={(event) => {
                      setVehicleBrand(event.target.value);
                      setErrObj({
                        ...errObj,
                        vehicleBrandErr: false,
                        vehicleBrandMsg: "",
                      });
                    }}
                    size={"medium"}
                    style={vehicleBrand === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Select the Brand of Vehicle
                    </MenuItem>
                    {dropDownData?.vehicle_brand?.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errObj.vehicleBrandErr ? (
                    <FormHelperText>{errObj.vehicleBrandMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.vModelErr ? 30 : 20 }}
              alignItems={"center"}
              id="vModel"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Vehicle Model"} />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <FormControl fullWidth error={errObj.vModelErr}>
                  <Select
                    displayEmpty
                    value={vModel}
                    disabled={isEmpty(vehicleBrand.toString())}
                    onChange={(e) => {
                      setvModel(e.target.value);
                      setErrObj({
                        ...errObj,
                        vModelErr: false,
                        vModelMsg: "",
                      });
                    }}
                    size={"medium"}
                    style={vModel === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Select the Model of Vehicle
                    </MenuItem>
                    {vehicleModel?.map((item, index) => (
                      <MenuItem key={index} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {errObj.vModelErr ? (
                    <FormHelperText>{errObj.vModelMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.vehicleTypeErr ? 30 : 20 }}
              id="vehicleType"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Vehicle Type"} />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9}>
                <FormControl fullWidth error={errObj.vehicleTypeErr}>
                  <OutlinedInput
                    style={{ width: "100%", margin: 0, cursor: "pointer" }}
                    placeholder="Select the Vehicle Type"
                    inputProps={{
                      readOnly: true,
                      style: { cursor: "pointer" },
                    }}
                    // disabled={!isEmpty(vModel.toString())}
                    value={vehicleType?.label}
                    onClick={() => {
                      if (isArray(vTypeArr) && !isEmpty(vTypeArr)) {
                        setOpenCard(true);
                      } else {
                        toast.warning(
                          "Please Add Vehicle Type First from General DropDowns"
                        );
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                  />
                  {errObj.vehicleTypeErr ? (
                    <FormHelperText>{errObj.vehicleTypeMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* <Grid
          container
          style={{ marginBottom: 20 }}
          alignItems={"center"}
          id="VIdentifier"
        >
          <Grid item xs={12} sm={12} md={3} lg={2.1}>
            <CTypography required title={"Vehicle Identifier"} />
          </Grid>
          <Grid item xs={12} md={9} lg={9.9}>
            <OutlinedInput
              style={{ width: "100%", margin: 0 }}
              value={VIdentifier}
              inputProps={{ readOnly: true }}
              endAdornment={
                isEdit ? null : (
                  <InputAdornment position="end">
                    <IconButton
                      onClick={() =>
                        setVIdentifier(
                          `FK ${Math.random().toString(36).substr(2, 6)}`
                        )
                      }
                      edge="end"
                    >
                      <AutorenewOutlinedIcon />
                    </IconButton>
                  </InputAdornment>
                )
              }
            />
          </Grid>
        </Grid> */}

            <Grid
              container
              style={{ marginBottom: errObj.rowsErr ? 30 : 20 }}
              alignItems={"center"}
              id="rows"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title="No. of Axle" />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter the No. of Axle"
                  // disabled={!isEmpty(vehicleModel)}
                  value={rows}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                  }}
                  error={errObj.rowsErr}
                  helperText={errObj.rowsErr ? errObj.rowsMsg : null}
                  onChange={(e) => {
                    setRows(e.target.value.replace(/[^0-9]/g, ""));
                    setErrObj({
                      ...errObj,
                      rowsErr: false,
                      rowsMsg: "",
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.tyresErr ? 30 : 20 }}
              alignItems={"center"}
              id="tyres"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title="No. of Tyres" />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter the No. of Tyres"
                  // disabled={!isEmpty(vehicleModel)}
                  value={tyres}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                    maxLength: 2,
                  }}
                  error={errObj.tyresErr}
                  helperText={errObj.tyresErr ? errObj.tyresMsg : null}
                  onChange={(e) => {
                    setTyres(e.target.value.replace(/[^0-9]/g, ""));
                    setErrObj({
                      ...errObj,
                      tyresErr: false,
                      tyresMsg: "",
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.loadCapacityErr ? 30 : 20 }}
              alignItems={"center"}
              id="loadCapacity"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Load Capacity"} />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <FormControl fullWidth error={errObj.loadCapacityErr}>
                  <OutlinedInput
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Enter the Load Capacity in Tonnes"
                    // disabled={!isEmpty(vehicleModel)}
                    value={loadCapacity}
                    onChange={(e) => {
                      setLoadCapacity(e.target.value);
                      setErrObj({
                        ...errObj,
                        loadCapacityErr: false,
                        loadCapacityMsg: "",
                      });
                    }}
                    endAdornment={
                      isEdit || !isEmpty(loadCapacity) ? null : (
                        <InputAdornment position="end">Tonnes</InputAdornment>
                      )
                    }
                  />
                  {errObj.loadCapacityErr ? (
                    <FormHelperText>{errObj.loadCapacityMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.gpsErr ? 30 : 20 }}
              id="gps"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"GPS Tracking"} />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9} maxWidth="100% !important">
                <FormControl fullWidth error={errObj.gpsErr}>
                  <Select
                    fullWidth
                    displayEmpty
                    value={gps}
                    onChange={(event) => {
                      setGPS(event.target.value);
                      setErrObj({
                        ...errObj,
                        gpsErr: false,
                        gpsMsg: "",
                      });
                    }}
                    size={"medium"}
                    style={gps === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Select the GPS Tracking is Running
                    </MenuItem>
                    <MenuItem key={1} value={"1"}>
                      Yes
                    </MenuItem>
                    <MenuItem key={0} value={"0"}>
                      No
                    </MenuItem>
                  </Select>
                  {errObj.gpsErr ? (
                    <FormHelperText>
                      {errObj.gpsMsg}
                      {}
                    </FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.goodsCarriedErr ? 30 : 20 }}
              alignItems={"center"}
              id="goodsCarried"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title={"Goods Carried"} />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter the Goods Carried by the Vehicle"
                  value={goodsCarried}
                  error={errObj.goodsCarriedErr}
                  helperText={
                    errObj.goodsCarriedErr ? errObj.goodsCarriedMsg : null
                  }
                  onChange={(e) => {
                    setGoodsCarried(e.target.value);
                    setErrObj({
                      ...errObj,
                      goodsCarriedErr: false,
                      goodsCarriedMsg: "",
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.terrainErr ? 30 : 20 }}
              id="terrain"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Terrain"} />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9} maxWidth="100% !important">
                <FormControl fullWidth error={errObj.terrainErr}>
                  <Select
                    fullWidth
                    displayEmpty
                    value={terrain}
                    onChange={handleChange}
                    size={"medium"}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return "Select the Type of Terrain (s) Vehicle Drives on";
                      } else {
                        const join = selected.map((item) => {
                          return item?.label;
                        });
                        return join.join(", ");
                      }
                    }}
                    multiple
                    style={{
                      color: terrain.length === 0 ? color.placeholder : "",
                    }}
                  >
                    <MenuItem value={0} disabled hidden selected>
                      Select the terrain
                    </MenuItem>
                    {dropDownData.terrain.map((item, index) => (
                      <MenuItem
                        key={index}
                        value={item}
                        style={{ justifyContent: "space-between" }}
                      >
                        {item?.label}
                        {/* <Checkbox checked={terrain.indexOf(item) > -1} /> */}
                      </MenuItem>
                    ))}
                  </Select>
                  {errObj.terrainErr ? (
                    <FormHelperText>{errObj.terrainMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.routeOriginErr ? 30 : 20 }}
              alignItems={"center"}
              id="routeOrigin"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title={"Route Origin"} />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter the Origin of the Vehicle Route"
                  value={routeOrigin}
                  error={errObj.routeOriginErr}
                  helperText={
                    errObj.routeOriginErr ? errObj.routeOriginMsg : null
                  }
                  onChange={(e) => {
                    setRouteOrigin(e.target.value);
                    setErrObj({
                      ...errObj,
                      routeOriginErr: false,
                      routeOriginMsg: "",
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.routeDestinationErr ? 30 : 20 }}
              alignItems={"center"}
              id="routeDestination"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title={"Route Destination"} />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter the Destination of the Vehicle Route"
                  value={routeDestination}
                  error={errObj.routeDestinationErr}
                  helperText={
                    errObj.routeDestinationErr
                      ? errObj.routeDestinationMsg
                      : null
                  }
                  onChange={(e) => {
                    setRouteDestination(e.target.value);
                    setErrObj({
                      ...errObj,
                      routeDestinationErr: false,
                      routeDestinationMsg: "",
                    });
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.currentReadingErr ? 30 : 20 }}
              alignItems={"center"}
              id="currentReading"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Initial Reading"} />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter Initial Reading in Km"
                  value={currentReading}
                  error={errObj.currentReadingErr}
                  helperText={
                    errObj.currentReadingErr ? errObj.currentReadingMsg : null
                  }
                  onChange={(e) => {
                    if (
                      e?.nativeEvent?.data === "0" ||
                      e?.nativeEvent?.data === "1" ||
                      e?.nativeEvent?.data === "2" ||
                      e?.nativeEvent?.data === "3" ||
                      e?.nativeEvent?.data === "4" ||
                      e?.nativeEvent?.data === "5" ||
                      e?.nativeEvent?.data === "6" ||
                      e?.nativeEvent?.data === "7" ||
                      e?.nativeEvent?.data === "8" ||
                      e?.nativeEvent?.data === "9" ||
                      e?.nativeEvent?.data === "." ||
                      isNull(e?.nativeEvent?.data)
                    ) {
                      // converter(e.target.value);
                      setCurrentReading(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Km</InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} sm={12} md={3} lg={2.1} />
              <Grid item xs={12} md={9} lg={9.9}>
                <Typography
                  style={{
                    marginTop: errObj.currentReadingErr ? 20 : 10,
                    fontSize: 12,
                  }}
                >
                  <b>Note :</b> If Odometer reading is not working in vehicle,
                  please enter value as 1,00,000.
                </Typography>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.driverNameErr ? 30 : 20 }}
              alignItems={"center"}
              id="driverName"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title={"Driver’s Name"} />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter Driver’s Name"
                  value={driverName}
                  onChange={(e) => {
                    setDriverName(e.target.value);
                    setErrObj({
                      ...errObj,
                      driverNameErr: false,
                      driverNameMsg: "",
                    });
                  }}
                  error={errObj.driverNameErr}
                  helperText={
                    errObj.driverNameErr ? errObj.driverNameMsg : null
                  }
                />
              </Grid>
            </Grid>
            <Grid
              container
              style={{ marginBottom: errObj.driverNumberErr ? 30 : 20 }}
              alignItems={"center"}
              id="driverNumber"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title={"Driver’s No."} />
              </Grid>

              <Grid item xs={12} md={9} lg={9.9}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder="Enter Driver’s No."
                  value={driverNumber}
                  error={errObj.driverNumberErr}
                  helperText={
                    errObj.driverNumberErr ? errObj.driverNumberMsg : null
                  }
                  onChange={(e) => {
                    setDriverNumber(e.target.value.replace(/[^0-9]/g, ""));
                    setErrObj({
                      ...errObj,
                      driverNumberErr: false,
                      driverNumberMsg: "",
                    });
                  }}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                    maxLength: 11,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+91</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.selectedFileErr ? 30 : 20 }}
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title="Status" required />
              </Grid>
              <Grid item>
                <FormControl>
                  <RadioGroup
                    row
                    value={status}
                    onChange={(e) => setStatus(e.target.value)}
                  >
                    <FormControlLabel
                      control={<Radio />}
                      value="1"
                      label="Active"
                    />
                    <FormControlLabel
                      control={<Radio />}
                      value="0"
                      label="In-active"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.selectedFileErr ? 30 : 20 }}
              id="selectedFile"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title={"RC Book Image"} />
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                lg={9.9}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  style={{
                    color:
                      !selectedFile || selectedFile === ""
                        ? "#999"
                        : color.black,
                  }}
                >
                  {file}
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  style={{ backgroundColor: color.secondary, margin: "0 20px" }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      setFile(e.target.files[0].name);
                      setSlectedFile(e.target.files[0]);
                      setErrObj({
                        ...errObj,
                        selectedFileErr: false,
                        selectedFileMsg: "",
                      });
                    }}
                    accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg, application/pdf"
                    hidden
                  />
                  Upload File
                </Button>
                {errObj.selectedFileErr ? (
                  <Typography style={{ color: "red", fontSize: 12 }}>
                    {errObj.selectedFileMsg}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.dateErr ? 30 : 20 }}
              id="date"
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={"Added Date"} />
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                lg={9.9}
                style={{ display: "flex", alignItems: "center" }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={new Date(2000, 0, 1)}
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                      setErrObj({
                        ...errObj,
                        dateErr: false,
                        dateMsg: "",
                      });
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color: errObj.dateErr ? "#ff1744" : color.primary,
                          },
                        }}
                        error={errObj.dateErr}
                        helperText={errObj.dateMsg}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              id="fDate"
              style={{
                marginBottom: 20,
              }}
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <Typography>Purchase Year :</Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={9}
                lg={9.9}
                style={{ display: "flex", alignItems: "center" }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    minDate={new Date(2000, 0, 1)}
                    showToolbar={false}
                    disableFuture
                    views={["year"]}
                    value={purchaseDate}
                    onChange={(newValue) => {
                      setPurchaseDate(newValue);
                      setErrObj({
                        ...errObj,
                        pyearErr: false,
                        pyearMsg: "",
                      });
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        sx={{
                          svg: {
                            color: errObj.pyearErr ? "#ff1744" : color.primary,
                          },
                        }}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "Please enter Year",
                        }}
                        error={errObj.pyearErr}
                        helperText={errObj.pyearMsg}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              style={{ marginBottom: 20 }}
            >
              <Button
                disabled={isOnline ? false : true}
                onClick={checkValidation}
                style={{
                  backgroundColor: isOnline ? color.primary : color.bordercolor,
                  marginRight: 10,
                  width: isMobile ? 120 : 130,
                }}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : isEdit ? (
                  `Save & Update`
                ) : (
                  `Add Vehicle`
                )}
              </Button>
              <Button
                style={{
                  backgroundColor: color.secondary,
                  width: isMobile ? 120 : 130,
                }}
                onClick={() => {
                  handleClick("cancel");
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Box>
          <ConfirmDialog
            title={`Are you sure you want to ${
              isEditDialog ? "Edit" : "Delete"
            } this Vehicle?`}
            visible={confirmDialog}
            handleModal={(bool) => {
              if (bool) {
                if (isEditDialog) {
                  submitData();
                } else {
                  deleteVehicle();
                }
              } else {
                setConfirmDialog(false);
                setIsEditDialog(false);
              }
            }}
            btnLoad={removeBtnLoad}
          />
        </>
      )}

      <Modal
        onClose={() => {
          setOpenCard(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "60vw",
            maxHeight: "65vh",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={isMobile ? 1 : md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: "50vh" }}
          >
            {vTypeArr?.map((item, index) => {
              return (
                <ImageListItem
                  key={index}
                  style={{
                    width: isMobile ? "60vw" : 200,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      SelectTyre(index);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={handleSelect}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
    </Box>
  );
}
