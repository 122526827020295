const Images = {
  notification: "/Assets/Images/notification.svg",
  profile: "/Assets/Images/profile.svg",
  frame: "/Assets/Images/frame4.png",
  frame2: "/Assets/Images/frame3.png",
  frame1: "/Assets/Images/logo.jpg",

  home: "/Assets/Images/home.svg",
  delivery: "/Assets/Images/delivery.svg",
  tyre: "/Assets/Images/tyre.svg",
  scrap: "/Assets/Images/scrapg.png",
  service: "/Assets/Images/service.svg",
  public: "/Assets/Images/public.svg",
  dropdown: "/Assets/Images/dropdown.svg",
  master: "/Assets/Images/itemg.svg",
  thistoryb: "/Assets/Images/HistoryBlue.svg",
  thistoryw: "/Assets/Images/clock.svg",
  thistoryg: "/Assets/Images/historyGrey.svg",
  historygrey: "/Assets/Images/clockg.svg",
  scrapw: "/Assets/Images/scrapw.png",
  scrapb: "/Assets/Images/scrapbc.png",
  scrapgray: "/Assets/Images/scrapgreyc.png",
  servicew: "/Assets/Images/servicew.svg",
  serviceg: "/Assets/Images/serviceg.svg",
  serviceb: "/Assets/Images/serviceb.svg",
  servicegray: "/Assets/Images/serviceGrey.svg",
  inventoryw: "/Assets/Images/tyreInventoryWhite.svg",
  inventorylg: "/Assets/Images/tyreInventoryLgrey.svg",
  inventoryg: "/Assets/Images/tyreInventoryGrey.svg",
  inventoryb: "/Assets/Images/tyreInventoryBlue.svg",
  inventoryGrey: "/Assets/Images/tyreInventoryGrey.svg",
  auditw: "/Assets/Images/auditw.svg",
  auditG: "/Assets/Images/auditG.svg",
  auditB: "/Assets/Images/auditB.svg",
  auditGrey: "/Assets/Images/auditGrey.svg",
  vmasterw: "/Assets/Images/vehicleMasterw.svg",
  vmasterg: "/Assets/Images/vehicleMasterg.svg",
  vmasterB: "/Assets/Images/vehicleMasterB.svg",
  vmasterGrey: "/Assets/Images/vehicleMasterGrey.svg",
  tmasterw: "/Assets/Images/horsew.svg",
  tmasterg: "/Assets/Images/horseg.svg",
  tmasterB: "/Assets/Images/horseB.svg",
  tmasterGrey: "/Assets/Images/horseGrey.svg",

  Ahome: "/Assets/Images/Ahome.svg",
  Adelivery: "/Assets/Images/Adelivery.svg",
  Atyre: "/Assets/Images/Atyre.svg",
  Apublic: "/Assets/Images/Apublic.svg",
  Amaster: "/Assets/Images/itemw.svg",
  text: "/Assets/Images/text.png",
  loginImg: "/Assets/Images/mechanic.png",

  Mdownload: "/Assets/Images/Mdownload.png",
  tabs: "/Assets/Images/tabs.svg",

  card1: "/Assets/Images/card1.svg",
  card2: "/Assets/Images/card2.svg",
  card3: "/Assets/Images/card3.svg",
  card4: "/Assets/Images/card4.svg",
  card5: "/Assets/Images/card5.svg",
  card6: "/Assets/Images/card6.svg",
  card7: "/Assets/Images/card7.svg",
  card8: "/Assets/Images/card8.svg",

  TruckW: "/Assets/Images/Truck.svg",
  goodWheel: "/Assets/Images/wheel-good 1.svg",
  badWheel: "/Assets/Images/wheel-bad.svg",
  averageWheel: "/Assets/Images/wheel-average.svg",

  itemMastercsv: "/Assets/CSV/itemsMaster.csv",
  serviceCentercsv: "/Assets/CSV/service_center.csv",
};

export default Images;
