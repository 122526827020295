import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { FilterAlt, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import MModificationRequest from "../../Modal/MModificationRequest";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import { ResetIcon } from "../../Icon/TableToggle";
import Images from "../../../Config/Images.js";

function ModificationRequest(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleFilter = () => null,
    handleSearch = () => null,
    handleDatePicker = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    searchFilter = "",
    datePicker = null,
  } = props;
  // const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  const [unresolvedIssueRows, setUnresolvedIssueRows] = useState({});
  const [modal, openModal] = useState(false);
  const [imgURL, setimgURL] = useState("");
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  // const [datePicker, setDatePicker] = useState(null);
  const [reportLoader, setReportLoader] = useState(false);
  const styles = useStyles();
  const { isOnline } = useSelector((state) => state.auth);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const { token, userdata } = useSelector((state) => state.auth);

  const UnresolvedIssueColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 180,
      flex: !lg ? 1 : "unset",
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 180,
      flex: !lg ? 1 : "unset",
    },
    {
      field: "inspection_date",
      headerName: "Requested By",
      width: 180,
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.inspection_date;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div style={{ display: "flex", flexDirection: "column" }}>
            <Typography>{params?.row?.requested_by || "-"}</Typography>
            <Typography>{timestamp ? displayDate : ""}</Typography>
          </div>
        );
      },
    },
    {
      field: "change_requested",
      headerName: "Change Requested",
      width: 180,
      flex: !lg ? 1 : "unset",
      sortable: false,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: color.primary,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              handleClick("vehicleInspectionChanges", {
                vehicle_id: params?.row?.vehicle_id,
                capture_date: params?.row?.inspection_date,
                vehicle_no: params?.row?.vehicle_no,
                request_id: params?.row?.id,
                status: params?.row?.status,
              });
            }}
          >
            View
          </Typography>
        );
      },
    },
    {
      field: "Status",
      headerName: "Status",
      sortable: false,
      width: 180,
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return (
          <div>
            <Typography
              style={{
                color:
                  params?.row?.status === 0
                    ? "black"
                    : params?.row?.status === 1
                    ? "#27AE60"
                    : params?.row?.status === 2
                    ? "#EB5757"
                    : "#ffc107",
              }}
            >
              {params?.row?.status === 0
                ? "Pending"
                : params?.row?.status === 1
                ? "Approved"
                : params?.row?.status === 2
                ? "Rejected"
                : "Partially Approved"}
            </Typography>
            <Typography>{params?.row?.request_remark}</Typography>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setPageLoad(true);
    ModificationRequestList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    const section = document.querySelector(`#jump_to_meM`);
    section.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  }, [pageNo]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function ModificationRequestList(bool, v) {
    !v && setPageLoad(true);
    v && handleLoader(true);
    v && setReportLoader(true);

    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.modificationlist
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : searchFilter
        }&filter=${filter}&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data?.rows) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadModificationData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setUnresolvedIssueRows(listObj);
          }
        else {
          toast.error(response?.message || "No Data Found");
        }
        v && auditSave("Modification Request List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      handleLoader(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
      handleLoader(false);
    }
  }

  function resetFilterData() {
    handleFilter("");
    handlePageNo(1);
    handleSearch("");
    handleDatePicker(null);
    ModificationRequestList(true);
  }
  const getRequested = (index) => {
    const timestamp = unresolvedIssueRows?.data[index]?.inspection_date;
    // const date = new Date(timestamp * 1000);
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <Typography>
          {unresolvedIssueRows?.data[index]?.requested_by || "-"}
        </Typography>
        <Typography>{timestamp ? displayDate : ""}</Typography>
      </div>
    );
  };

  const getChangeRequested = (index) => {
    return (
      <Typography
        style={{
          color: color.primary,
          fontWeight: "bold",
          cursor: "pointer",
        }}
        onClick={() => {
          handleClick("vehicleInspectionChanges", {
            vehicle_id: unresolvedIssueRows?.data[index]?.vehicle_id,
            capture_date: unresolvedIssueRows?.data[index]?.inspection_date,
            vehicle_no: unresolvedIssueRows?.data[index]?.vehicle_no,
            request_id: unresolvedIssueRows?.data[index]?.id,
            status: unresolvedIssueRows?.data[index]?.status,
          });
        }}
      >
        View
      </Typography>
    );
  };
  const getStatus = (index) => {
    return (
      <div>
        <Typography
          style={{
            color:
              unresolvedIssueRows?.data[index]?.status === 0
                ? "black"
                : unresolvedIssueRows?.data[index]?.status === 1
                ? "#27AE60"
                : unresolvedIssueRows?.data[index]?.status === 2
                ? "#EB5757"
                : "#ffc107",
          }}
        >
          {unresolvedIssueRows?.data[index]?.status === 0
            ? "Pending"
            : unresolvedIssueRows?.data[index]?.status === 1
            ? "Approved"
            : unresolvedIssueRows?.data[index]?.status === 2
            ? "Rejected"
            : "Partially Approved"}
        </Typography>
        <Typography>
          {unresolvedIssueRows?.data[index]?.request_remark}
        </Typography>
      </div>
    );
  };
  useImperativeHandle(ref, () => ({
    unresolvedIssueRows: unresolvedIssueRows,
    downloadModificationData: () => {
      ModificationRequestList(false, 1);
    },
  }));
  function downloadModificationData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.requested_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Requested By":
          `${row?.requested_by}
${timestamp ? displayDate : "-"}` || "-",
        Status:
          row?.status === 0
            ? "Pending"
            : row?.status === 1
            ? "Approved"
            : row?.status === 2
            ? "Rejected"
            : "Partially Approved",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Modification Request List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Modification Request List.xlsx");
  }

  return (
    <div>
      {isTablet ? (
        <>
          <div style={{ marginTop: 10 }}>
            <Grid
              id="jump_to_meM"
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Select
                placeholder="Filter"
                value={filter}
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                displayEmpty
                IconComponent={FilterAlt}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
                classes={{
                  iconOpen: styles.iconOpen,
                }}
              >
                <MenuItem value={""} hidden selected disabled>
                  Filter
                </MenuItem>
                <MenuItem value={0}>Pending</MenuItem>
                <MenuItem value={1}>Approved</MenuItem>
                <MenuItem value={2}>Rejected</MenuItem>
                <MenuItem value={3}>Partially Approved</MenuItem>
              </Select>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              container
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              wrap="nowrap"
            >
              <Grid item>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && ModificationRequestList(true);
                    handleSearch(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                        handlePageNo(1);
                        ModificationRequestList(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Fleet Name and Vehicle No."
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid style={{ marginLeft: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    views={["year", "month", "day"]}
                    disableFuture
                    value={datePicker}
                    onChange={(newValue) => {
                      handleDatePicker(newValue);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid
                item
                style={{ marginLeft: 10 }}
                display="flex"
                wrap="nowrap"
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      ModificationRequestList(false);
                    }
                  }}
                >
                  <Search />
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  disabled={!searchFilter && !datePicker && !filter}
                  onClick={resetFilterData}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(unresolvedIssueRows?.data) &&
              !isEmpty(unresolvedIssueRows?.data) ? (
              <>
                <div style={{ marginTop: 10 }}>
                  <DataGrid
                    rows={unresolvedIssueRows?.data}
                    columns={UnresolvedIssueColumns}
                    onCellClick={(params) => {
                      if (params?.field !== "action") {
                        handleClick("fleetDetails", params?.row);
                      }
                    }}
                    pageSize={record}
                    disableColumnMenu
                    autoHeight={true}
                    hideFooter
                    disableSelectionOnClick
                    showCellRightBorder
                    getRowHeight={() => "auto"}
                    showColumnRightBorder
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      unresolvedIssueRows?.pagination?.totalPage
                    )}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
          <MModificationRequest
            visible={visible}
            handleModal={() => {
              setVisible(false);
            }}
            data={modalData}
            onSave={() => {
              setPageLoad(true);
              ModificationRequestList();
            }}
            from="vehicleInspection"
          />
          <Modal
            className={styles.modal}
            onClose={() => {
              openModal(false);
            }}
            open={modal}
          >
            <img
              style={{
                backgroundColor: color.white,
                width: md ? 400 : 800,
                borderRadius: 4,
              }}
              src={imgURL ? imgURL : ""}
              alt=""
            />
          </Modal>
        </>
      ) : (
        <>
          <div>
            <Grid
              id="jump_to_meM"
              container
              justifyContent="space-between"
              alignItems="center"
              columnGap={1}
              wrap="nowrap"
              style={{ marginTop: 10 }}
            >
              <Select
                placeholder="Filter"
                value={filter}
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                displayEmpty
                IconComponent={FilterAlt}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
                classes={{
                  iconOpen: styles.iconOpen,
                }}
              >
                <MenuItem value={""} hidden selected disabled>
                  Filter
                </MenuItem>
                <MenuItem value={0}>Pending</MenuItem>
                <MenuItem value={1}>Approved</MenuItem>
                <MenuItem value={2}>Rejected</MenuItem>
                <MenuItem value={3}>Partially Approved</MenuItem>
              </Select>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              <Grid item marginLeft={"auto"}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  onClick={() => {
                    ModificationRequestList(false, 1);
                  }}
                  disabled={isOnline ? false : true}
                >
                  {reportLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid item container style={{ marginTop: 10 }}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value) && ModificationRequestList(true);
                  handleSearch(v?.target?.value);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      handlePageNo(1);
                      ModificationRequestList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Fleet Name and Vehicle No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              container
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              columnGap={1}
              wrap="nowrap"
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  views={["year", "month", "day"]}
                  disableFuture
                  value={datePicker}
                  onChange={(newValue) => {
                    handleDatePicker(newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    ModificationRequestList(false);
                  }
                }}
              >
                <Search />
              </Button>
              <Button
                variant="contained"
                disabled={!searchFilter && !datePicker && !filter}
                onClick={resetFilterData}
              >
                <ResetIcon />
              </Button>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(unresolvedIssueRows?.data) &&
              !isEmpty(unresolvedIssueRows?.data) ? (
              <>
                {unresolvedIssueRows?.data?.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      style={{
                        marginTop: 10,
                        border: `1px solid ${color.bordercolor}`,
                        alignItems: "center",
                      }}
                    >
                      <Grid item container>
                        <Table className={styles.customtable}>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Fleet Name</TableCell>
                              <TableCell variant="head">Vehicle No.</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.fleet_name || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.vehicle_no || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Requested By</TableCell>
                              <TableCell variant="head">
                                Change Requested
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {getRequested(index) || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {getChangeRequested(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Status</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {getStatus(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </Grid>
                    </Grid>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      unresolvedIssueRows?.pagination?.totalPage
                    )}
                    defaultPage={pageNo}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
          <MModificationRequest
            visible={visible}
            handleModal={() => {
              setVisible(false);
            }}
            data={modalData}
            onSave={() => {
              setPageLoad(true);
              ModificationRequestList();
            }}
            from="vehicleInspection"
          />
          <Modal
            className={styles.modal}
            onClose={() => {
              openModal(false);
            }}
            open={modal}
          >
            <img
              style={{
                objectFit: "contain",
                borderRadius: 4,
                maxWidth: isTablet ? 550 : 330,
                maxHeight: isTablet ? 550 : 330,
              }}
              src={imgURL ? imgURL : ""}
              alt=""
            />
          </Modal>
        </>
      )}
    </div>
  );
}
export default forwardRef(ModificationRequest);
