import {
  Autocomplete,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { color } from "../../../Config/theme";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles";
import { months } from "../../../Config/Static_Data";
import { useDispatch, useSelector } from "react-redux";
import ConfirmDialog from "../../ConfirmDialog";
import authActions from "../../../Redux/reducers/auth/actions";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { isMobile, isTablet } from "react-device-detect";

export function CTypography(props) {
  const { title, required, isDot, style } = props;
  return (
    <Typography style={style}>
      <span>{title}</span>
      <span style={{ color: "red" }}> {required ? "* " : null}</span>
      <span>{!isDot && ":"} </span>
    </Typography>
  );
}

const errorObj = {
  FNameErr: false,
  SECodeErr: false,
  FLocationErr: false,
  FStateErr: false,
  districtErr: false,
  ROErr: false,
  AddressErr: false,
  CPersonErr: false,
  CNumberErr: false,
  OwnerErr: false,
  ONumberErr: false,
  selectedFileErr: false,
  FNameMsg: "",
  SECodeMsg: "",
  FLocationMsg: "",
  FStateMsg: "",
  districtMsg: "",
  RoMsg: "",
  AddressMsg: "",
  CPersonMsg: "",
  CNumberMsg: "",
  OwnerMsg: "",
  ONumberMsg: "",
  selectedFileMsg: "",
  dateErr: false,
  dateMsg: "",
  panDetailsErr: false,
  panDetailsMsg: "",
};

export default function AddFleetForm(props) {
  const alphabatic_Regex = Setting.JS_Regex.alphabatic_Regex;
  const currency_Regex = Setting.JS_Regex.currency_Regex;

  const { handleClick = () => null, fleetData } = props;
  const isEdit = !isEmpty(fleetData);

  const { token, dropDownData, isOnline } = useSelector((state) => state.auth);

  const theme = useTheme();
  const styles = useStyles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const dispatch = useDispatch();
  const { setFleetData } = authActions;

  const [loader, setLoader] = useState(false);
  const [FName, setFName] = useState("");
  const [FCode, setFCode] = useState("");
  const [SECode, setSECode] = useState("");
  const [FLocation, setFLocation] = useState("");
  const [FState, setFState] = useState("");
  const [district, setDistrict] = useState("");
  const [regionalOffice, setRegionalOffice] = useState("");
  const [Address, setAddress] = useState("");
  const [CPerson, setCPerson] = useState("");
  const [CNumber, setCNumber] = useState("");
  const [Owner, setOwner] = useState("");
  const [ONumber, setONumber] = useState("");
  const [panDetails, setPanDetails] = useState("");
  const [file, setFile] = useState("No File Selected");
  const [selectedFile, setSlectedFile] = useState(null);
  const [errObj, setErrObj] = useState(errorObj);
  const [MonthlyUsage, setMonthlyUsage] = useState(months);
  const [datePicker, setDatePicker] = useState(new Date());

  const [isEditDialog, setIsEditDialog] = useState(false);
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  const [seviceListArr, setSeviceListArr] = useState([]);
  const [serviceEngineer, setServiceEngineer] = useState([]);

  const [pageLoad, setPageLoad] = useState(isEdit ? true : false);

  useEffect(() => {
    if (isEdit) {
      const parseMonthlyUsage =
        !isEmpty(fleetData.monthly_usage) &&
        JSON.parse(fleetData?.monthly_usage || "");
      const fileName = fleetData?.business_pan_card.split("/");
      setFName(fleetData?.name || "");
      // setFCode(fleetData?.code || "");
      setSECode(fleetData?.sales_employee_code || "");
      setFLocation(fleetData?.city || "");
      setFState(fleetData?.state || "");
      setAddress(fleetData?.address || "");
      setCPerson(fleetData?.contact_name || "");
      setCNumber(fleetData?.contact_phone || "");
      setOwner(fleetData?.owner_name || "");
      setONumber(fleetData?.owner_phone || "");
      setPanDetails(fleetData?.pan_details || "");
      setFile(fileName[fileName.length - 1] || "No File Selected");
      setSlectedFile(fleetData?.business_pan_card || null);
      setMonthlyUsage(parseMonthlyUsage || []);
      setDistrict(fleetData?.district_id || "");
      setRegionalOffice(fleetData?.office_region_id || "");
      setDatePicker(fleetData?.capture_date || "");
      setPageLoad(false);
      let res1 = seviceListArr.filter((item) => {
        let a = fleetData.service_engineer_ids.includes(item.id);
        return a;
      });
      setServiceEngineer(res1);
    }
  }, [seviceListArr]);

  useEffect(() => {
    if (!isEdit) {
      // getFleetList(false);
    }
    getOficeRegionData();
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, []);

  async function getFleetList(bool) {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetList}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          const listObj = {
            pagination: response?.data?.pagination,
            data: response?.data?.rows || [],
          };
          // setFleetListRows(listObj);
          // setFCode(
          //   `FK-${String(listObj?.pagination?.last_id + 1).padStart(6, 0)}`
          // );
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const getOficeRegionData = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.serviceEngineerList}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setSeviceListArr(response?.data);
        } else {
          setSeviceListArr([]);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const submitData = async () => {
    setRemoveBtnLoad(true);
    setLoader(true);
    let endPoints = Setting.endpoints.addfleet;

    const array = [];
    isArray(serviceEngineer) &&
      !isEmpty(serviceEngineer) &&
      serviceEngineer.map((v) => {
        array.push(v.id);
      });

    const captureDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";

    const params = {
      "Fleet[name]": FName,
      // "Fleet[code]": FCode,
      "Fleet[sales_employee_code]": SECode,
      "Fleet[city]": FLocation,
      "Fleet[state]": FState,
      "Fleet[address]": Address,
      "Fleet[contact_name]": CPerson,
      "Fleet[contact_phone]": CNumber,
      "Fleet[owner_name]": Owner,
      "Fleet[owner_phone]": ONumber,
      "Fleet[pan_details]": panDetails.trim(),
      "Fleet[business_pan_card]": selectedFile,
      "Fleet[monthly_usage]": JSON.stringify(MonthlyUsage),
      "Fleet[service_engineer_allocated]": array,
      "Fleet[district_id]": district,
      "Fleet[office_region_id]": regionalOffice,
      "Fleet[capture_date]": captureDate,
    };
    if (isEdit) {
      params["Fleet[id]"] = fleetData?.id;
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        handleClick("cancel");
        getFleetData();
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setRemoveBtnLoad(false);
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setRemoveBtnLoad(false);
      setLoader(false);
    }
  };

  async function getFleetData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.FleetData}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          dispatch(setFleetData(response?.data));
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  const validatior = () => {
    const error = { ...errObj };
    let valid = true;
    let section = null;
    let scroll = false;

    if (isEmpty(FName.trim())) {
      valid = false;
      scroll = true;
      error.FNameErr = true;
      error.FNameMsg = "Please enter Fleet Name";
      section = document.querySelector("#FName");
    }

    if (isEmpty(serviceEngineer)) {
      valid = false;
      error.SECodeErr = true;
      error.SECodeMsg = "Please select Employee Code";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#serviceEngineer");
      }
    }

    if (isEmpty(FLocation.trim())) {
      valid = false;
      error.FLocationErr = true;
      error.FLocationMsg = "Please enter Fleet City";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#FLocation");
      }
    } else if (!alphabatic_Regex.test(FLocation.trim())) {
      valid = false;
      error.FLocationErr = true;
      error.FLocationMsg = "Please enter only Alphabets";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#FLocation");
      }
    }

    if (isEmpty(FState.trim())) {
      valid = false;
      error.FStateErr = true;
      error.FStateMsg = "Please enter Fleet State";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#FState");
      }
    } else if (!alphabatic_Regex.test(FState.trim())) {
      valid = false;
      error.FStateErr = true;
      error.FStateMsg = "Please enter only Alphabets";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#FState");
      }
    }

    if (isEmpty(district.toString())) {
      valid = false;
      error.districtErr = true;
      error.districtMsg = "Please select District";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#district");
      }
    }

    if (isEmpty(regionalOffice.toString())) {
      valid = false;
      error.ROErr = true;
      error.RoMsg = "Please select Regional Office";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#regionalOffice");
      }
    }

    if (isEmpty(Address.trim())) {
      valid = false;
      error.AddressErr = true;
      error.AddressMsg = "Please enter Address";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#Address");
      }
    }
    if (isEmpty(CPerson.trim())) {
      valid = false;
      error.CPersonErr = true;
      error.CPersonMsg = "Please enter Contact Person Name";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#CPerson");
      }
    } else if (!alphabatic_Regex.test(CPerson.trim())) {
      valid = false;
      error.CPersonErr = true;
      error.CPersonMsg = "Please enter only Alphabets";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#CPerson");
      }
    }

    if (isEmpty(CNumber.trim())) {
      valid = false;
      error.CNumberErr = true;
      error.CNumberMsg = "Please enter Contact Person No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#CNumber");
      }
    } else if (CNumber.length < 10) {
      valid = false;
      error.CNumberErr = true;
      error.CNumberMsg = "Please enter valid No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#CNumber");
      }
    }

    if (isEmpty(Owner.trim())) {
      valid = false;
      error.OwnerErr = true;
      error.OwnerMsg = "Please enter Owner's Name";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#Owner");
      }
    } else if (!alphabatic_Regex.test(Owner.trim())) {
      valid = false;
      error.OwnerErr = true;
      error.OwnerMsg = "Please enter only Alphabets";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#Owner");
      }
    }

    if (isEmpty(ONumber.trim())) {
      valid = false;
      error.ONumberErr = true;
      error.ONumberMsg = "Please enter Owner's No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#ONumber");
      }
    } else if (ONumber.length < 10) {
      valid = false;
      error.ONumberErr = true;
      error.ONumberMsg = "Please enter valid No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#ONumber");
      }
    }

    // if (isEmpty(panDetails)) {
    //   valid = false;
    //   error.panDetailsErr = true;
    //   error.panDetailsMsg = "Please enter PAN Card & Aadhar details";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#panDetails");
    //   }
    // }

    // if (isNull(selectedFile)) {
    //   valid = false;
    //   error.selectedFileErr = true;
    //   error.selectedFileMsg = "Please upload PAN Card";
    //   if (!scroll) {
    //     scroll = true;
    //     section = document.querySelector("#selectedFile");
    //   }
    // }
    let foundAddedDateInRange = moment(datePicker).isBetween(
      "2000-01-01",
      new Date()
    );

    if (isNull(datePicker)) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please select Added Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    } else if (
      !isNull(datePicker) &&
      (datePicker === "Invalid Date" || !foundAddedDateInRange)
    ) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please enter valid Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    }

    const dummyArr = [...MonthlyUsage];

    dummyArr.map((v, i) => {
      let c = v.cost.replaceAll(",", "");
      if (isEmpty(v.tyres) && !isEmpty(v.cost)) {
        valid = false;
        v.tyreError = true;
        v.tyreErrorMsg = "Enter no. of Tyres";
        if (!scroll) {
          scroll = true;
          section = document.querySelector(`#month_${v.label}`);
        }
      }
      if (!isEmpty(v.tyres) && isEmpty(v.cost)) {
        valid = false;
        v.costError = true;
        v.costErrorMsg = "Enter the Cost of Tyres";
        if (!scroll) {
          scroll = true;
          section = document.querySelector(`#month_${v.label}`);
        }
      } else if (
        !isEmpty(v.tyres) &&
        !isEmpty(v.cost) &&
        !currency_Regex.test(c)
      ) {
        valid = false;
        v.costError = true;
        v.costErrorMsg = "Please Enter the valid Cost of Tyres";
        if (!scroll) {
          scroll = true;
          section = document.querySelector(`#month_${v.label}`);
        }
      }
      setMonthlyUsage(dummyArr);
    });

    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    setErrObj(error);
    if (valid) {
      if (isEdit) {
        setIsEditDialog(true);
        setConfirmDialog(true);
      } else {
        submitData();
      }
    }
  };

  function clearData() {
    setErrObj(errorObj);
    setFName("");
    setSECode("");
    setFLocation("");
    setFState("");
    setAddress("");
    setCPerson("");
    setCNumber("");
    setOwner("");
    setONumber("");
    setPanDetails("");
    setFile("No File Selected");
    setSlectedFile(null);
    setMonthlyUsage(months);
  }

  function numberWithCommas(x) {
    let c = x.replaceAll(",", "");

    return c.toString().split(".")[0].length > 3
      ? c
          .toString()
          .substring(0, c.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c.toString().substring(c.toString().split(".")[0].length - 3)
      : c.toString();
  }

  function setMonthlyData(type = "", value, index) {
    let convert = numberWithCommas(value);
    const dummyArr = [...MonthlyUsage];
    dummyArr.map((item, i) => {
      if (index === i) {
        if (type === "t") {
          item.tyres = value;
          item.tyreError = false;
          item.tyreErrorMsg = "";
        } else if (type === "c") {
          item.cost = convert;
          item.costError = false;
          item.costErrorMsg = "";
        } else {
          return null;
        }
      }
      setMonthlyUsage(dummyArr);
    });
  }

  const deleteFleet = async () => {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.deletefleet}?id=${fleetData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        handleClick("cancel");
        setConfirmDialog(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
    }
  };

  return (
    <Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: isMobile && !isTablet ? 15 : "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        id="jump_to_me"
      >
        <Typography fontWeight={"bold"}>
          {isEdit ? "Edit Fleet" : "Add Fleet"}
        </Typography>
        {isEdit ? (
          <Button
            style={{ backgroundColor: color.secondary }}
            onClick={() => setConfirmDialog(true)}
          >
            Delete
          </Button>
        ) : null}
      </Box>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : (
        <Box
          className={styles.container}
          style={{ backgroundColor: color.white }}
        >
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.FNameErr ? 30 : 20 }}
            id="FName"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Fleet Name"} />
            </Grid>

            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter Fleet’s name"
                value={FName}
                error={errObj.FNameErr}
                helperText={errObj.FNameErr ? errObj.FNameMsg : null}
                onChange={(e) => {
                  setFName(e.target.value);
                  setErrObj({ ...errObj, FNameErr: false, FNameMsg: "" });
                }}
              />
            </Grid>
          </Grid>
          {/* <Grid
            container
            style={{
              marginBottom: errObj.SECodeErr ? 30 : 20,
              alignItems: "center",
            }}
            id="FCode"
          >
            <Grid
              item
              xs={12}
              md={6}
              style={{ display: !md ? "flex" : "unset", alignItems: "center" }}
            >
              <Grid item xs={12} md={4}>
                <CTypography required title={"Fleet Code"} />
              </Grid>
              <Grid item xs={12} md={7.8}>
                <OutlinedInput
                  style={{ width: "100%", margin: 0 }}
                  inputProps={{ readOnly: true }}
                  value={FCode}
                />
              </Grid>
            </Grid>
          </Grid> */}
          <Grid
            container
            alignItems={"center"}
            style={{
              marginBottom: errObj.SECodeErr ? 30 : 20,
            }}
            id="serviceEngineer"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography required title={"Employee Code"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <FormControl
                sx={{
                  width: "100%",
                  border: errObj.SECodeErr ? "1px solid #ff1744" : null,
                  borderRadius: 1,
                }}
                error={errObj.SECodeErr}
              >
                <Autocomplete
                  fullWidth
                  multiple
                  disableListWrap={true}
                  options={seviceListArr}
                  // filterSelectedOptions
                  value={serviceEngineer}
                  classes={{ input: `${styles.autocomplete}` }}
                  onChange={(v1, v) => {
                    setServiceEngineer(v);
                    setErrObj({ ...errObj, SECodeErr: false, SECodeMsg: "" });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.inputDivStyle}
                      placeholder={
                        isEmpty(serviceEngineer)
                          ? "Search/Select Employee Code"
                          : null
                      }
                    />
                  )}
                  renderTags={(tagValue, getTagProps) => {
                    const renderTagsValue = tagValue
                      .map(function (elem) {
                        return elem.label;
                      })
                      .join(", ");
                    return (
                      // <Typography noWrap={true} color="textPrimary">
                      renderTagsValue
                      // </Typography>
                    );
                  }}
                />
                {errObj.SECodeErr ? (
                  <FormHelperText>{errObj.SECodeMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.FLocationErr ? 30 : 20 }}
            id="FLocation"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography required title={"Fleet City"} />
            </Grid>

            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter the Location (City) of the Fleet"
                value={FLocation}
                error={errObj.FLocationErr}
                helperText={errObj.FLocationErr ? errObj.FLocationMsg : null}
                onChange={(e) => {
                  setFLocation(e.target.value);
                  setErrObj({
                    ...errObj,
                    FLocationErr: false,
                    FLocationMsg: "",
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.FStateErr ? 30 : 20 }}
            id="FState"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography required title={"Fleet State"} />
            </Grid>

            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter the Location (State) of the Fleet"
                value={FState}
                error={errObj.FStateErr}
                helperText={errObj.FStateErr ? errObj.FStateMsg : null}
                onChange={(e) => {
                  setFState(e.target.value);
                  setErrObj({ ...errObj, FStateErr: false, FStateMsg: "" });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.districtErr ? 30 : 20 }}
            id="district"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"District"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <FormControl fullWidth error={errObj.districtErr}>
                <Select
                  displayEmpty
                  value={district}
                  onChange={(event) => {
                    setDistrict(event.target.value);
                    setErrObj({
                      ...errObj,
                      districtErr: false,
                      districtMsg: "",
                    });
                  }}
                  size={"medium"}
                  style={district === "" ? { color: "#A2A2A2" } : {}}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select the District
                  </MenuItem>
                  {dropDownData.district.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                {errObj.districtErr ? (
                  <FormHelperText>
                    {errObj.districtMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.ROErr ? 30 : 20 }}
            id="regionalOffice"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Regional Office"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10} maxWidth="100% !important">
              <FormControl fullWidth error={errObj.ROErr}>
                <Select
                  displayEmpty
                  value={regionalOffice}
                  onChange={(event) => {
                    setRegionalOffice(event.target.value);
                    setErrObj({
                      ...errObj,
                      ROErr: false,
                      RoMsg: "",
                    });
                  }}
                  size={"medium"}
                  style={regionalOffice === "" ? { color: "#A2A2A2" } : {}}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select the Regional Office
                  </MenuItem>
                  {dropDownData.office_region.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                {errObj.ROErr ? (
                  <FormHelperText>
                    {errObj.RoMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginBottom: errObj.AddressErr ? 30 : 20 }}
            id="Address"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography required title={"Address"} />
            </Grid>

            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter the Address with Pin Code"
                rows={4}
                multiline
                value={Address}
                error={errObj.AddressErr}
                helperText={errObj.AddressErr ? errObj.AddressMsg : null}
                onChange={(e) => {
                  setAddress(e.target.value);
                  setErrObj({ ...errObj, AddressErr: false, AddressMsg: "" });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.CPersonErr ? 30 : 20 }}
            id="CPerson"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography required title={"Contact Person"} />
            </Grid>

            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter the Name of the Contact Person"
                value={CPerson}
                error={errObj.CPersonErr}
                helperText={errObj.CPersonErr ? errObj.CPersonMsg : null}
                onChange={(e) => {
                  setCPerson(e.target.value);
                  setErrObj({ ...errObj, CPersonErr: false, CPersonMsg: "" });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.CNumberErr ? 30 : 20 }}
            id="CNumber"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography required title={"Contact No."} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter the No. of the Contact Person"
                inputProps={{
                  onWheel: (event) => event.currentTarget.blur(),
                  maxLength: 11,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                value={CNumber}
                className={styles.input}
                error={errObj.CNumberErr}
                helperText={errObj.CNumberErr ? errObj.CNumberMsg : null}
                onChange={(e) => {
                  setCNumber(e.target.value.replace(/[^0-9]/g, ""));
                  setErrObj({ ...errObj, CNumberErr: false, CNumberMsg: "" });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.OwnerErr ? 30 : 20 }}
            id="Owner"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography required title={"Owner"} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter Fleet Owner's Name"
                value={Owner}
                error={errObj.OwnerErr}
                helperText={errObj.OwnerErr ? errObj.OwnerMsg : null}
                onChange={(e) => {
                  setOwner(e.target.value);
                  setErrObj({ ...errObj, OwnerErr: false, OwnerMsg: "" });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.ONumberErr ? 30 : 20 }}
            id="ONumber"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography required title={"Owner's No."} />
            </Grid>
            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder=" Enter Fleet Owner’s No."
                inputProps={{
                  onWheel: (event) => event.currentTarget.blur(),
                  maxLength: 11,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+91</InputAdornment>
                  ),
                }}
                value={ONumber}
                className={styles.input}
                error={errObj.ONumberErr}
                helperText={errObj.ONumberErr ? errObj.ONumberMsg : null}
                onChange={(e) => {
                  setONumber(e.target.value.replace(/[^0-9]/g, ""));
                  setErrObj({ ...errObj, ONumberErr: false, ONumberMsg: "" });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            style={{ marginBottom: errObj.panDetailsErr ? 30 : 20 }}
            id="panDetails"
          >
            <Grid item xs={12} md={2} lg={2}>
              <CTypography title={"PAN & Aadhar Details"} />
            </Grid>

            <Grid item xs={12} md={10} lg={10}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter the PAN & Aadhar Details"
                rows={4}
                multiline
                value={panDetails}
                error={errObj.panDetailsErr}
                helperText={errObj.panDetailsErr ? errObj.panDetailsMsg : null}
                onChange={(e) => {
                  setPanDetails(e.target.value);
                  setErrObj({
                    ...errObj,
                    panDetailsErr: false,
                    AddressMsg: "",
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.selectedFileErr ? 30 : 20 }}
            id="selectedFile"
            wrap={isMobile && !isTablet ? "wrap" : "nowrap"}
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography
                // required
                title={"Upload Business PAN Card"}
                style={{ marginRight: 20 }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              lg={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography
                style={{
                  color:
                    !selectedFile || selectedFile === "" ? "#999" : color.black,
                  maxWidth: isMobile && !isTablet ? "50vw" : "unset",
                  overflow: "hidden",
                  wordWrap: "break-word",
                  whiteSpace: "break-spaces",
                }}
              >
                {file}
              </Typography>
              <Button
                variant="contained"
                component="label"
                style={{ backgroundColor: color.secondary, margin: "0 20px" }}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    setFile(e.target.files[0].name);
                    setSlectedFile(e.target.files[0]);
                    setErrObj({
                      ...errObj,
                      selectedFileErr: false,
                      selectedFileMsg: "",
                    });
                  }}
                  accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg, application/pdf"
                  hidden
                />
                Upload File
              </Button>
              {/* {errObj.selectedFileErr ? (
                <Typography style={{ color: "red", fontSize: 12 }}>
                  {errObj.selectedFileMsg}
                </Typography>
              ) : null} */}
            </Grid>
          </Grid>

          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.selectedFileErr ? 30 : 20 }}
            id="date"
          >
            <Grid item xs={12} sm={12} md={2} lg={2}>
              <CTypography required title={"Added Date"} />
            </Grid>
            <Grid
              item
              xs={12}
              md={10}
              lg={10}
              style={{ display: "flex", alignItems: "center" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  minDate={new Date(2000, 0, 1)}
                  showToolbar={false}
                  disableFuture
                  views={["year", "month", "day"]}
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                    setErrObj({
                      ...errObj,
                      dateErr: false,
                      dateMsg: "",
                    });
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{
                        svg: {
                          color: errObj.dateErr ? "#ff1744" : color.primary,
                        },
                      }}
                      error={errObj.dateErr}
                      helperText={errObj.dateMsg}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj ? 20 : 20 }}
          >
            <Typography style={{ fontWeight: "bold" }}>
              Monthly Tyres Usage and Cost :
            </Typography>
          </Grid>
          {MonthlyUsage.map((v, i) => {
            return (
              <Grid
                container
                key={`month_${v.label}`}
                style={{ marginBottom: 30 }}
                id={`month_${v.label}`}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display: !md ? "flex" : "unset",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography>
                      {v.label}
                      {" : "}
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7.8}>
                    <TextField
                      className={styles.input}
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Enter No. of Tyres Bought"
                      value={v.tyres}
                      inputProps={{
                        onWheel: (event) => event.currentTarget.blur(),
                      }}
                      onChange={(v) => {
                        setMonthlyData(
                          "t",
                          v?.target?.value.replace(/[^0-9]/g, ""),
                          i
                        );
                      }}
                      error={v?.tyreError}
                      helperText={v?.tyreErrorMsg}
                    />
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  style={{
                    display: !md ? "flex" : "unset",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    paddingTop: md ? 20 : 0,
                  }}
                >
                  <Grid item xs={12} md={4}>
                    <Typography style={{ textAlign: !md ? "center" : "left" }}>
                      Cost :
                    </Typography>
                  </Grid>
                  <Grid item xs={12} md={7.8}>
                    <TextField
                      className={styles.input}
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Enter the Total Amount of Tyres Purchased"
                      value={v.cost}
                      inputProps={{
                        onWheel: (event) => event.currentTarget.blur(),
                      }}
                      onChange={(e) => {
                        if (
                          e?.nativeEvent?.data === "0" ||
                          e?.nativeEvent?.data === "1" ||
                          e?.nativeEvent?.data === "2" ||
                          e?.nativeEvent?.data === "3" ||
                          e?.nativeEvent?.data === "4" ||
                          e?.nativeEvent?.data === "5" ||
                          e?.nativeEvent?.data === "6" ||
                          e?.nativeEvent?.data === "7" ||
                          e?.nativeEvent?.data === "8" ||
                          e?.nativeEvent?.data === "9" ||
                          e?.nativeEvent?.data === "." ||
                          isNull(e?.nativeEvent?.data)
                        ) {
                          setMonthlyData("c", e?.target?.value, i);
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">₹</InputAdornment>
                        ),
                      }}
                      error={v.costError}
                      helperText={v.costError ? v.costErrorMsg : null}
                    />
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
          <Grid container alignItems={"center"} justifyContent={"center"}>
            <Button
              disabled={isOnline ? false : true}
              onClick={validatior}
              style={{
                backgroundColor: isOnline ? color.primary : color.bordercolor,
                marginRight: 10,
                width: isMobile ? 120 : 130,
              }}
            >
              {loader ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : isEdit ? (
                "Save & Update"
              ) : (
                "Add Fleet"
              )}
            </Button>
            <Button
              style={{
                backgroundColor: color.secondary,
                width: isMobile ? 120 : 130,
              }}
              onClick={() => {
                handleClick("cancel");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </Box>
      )}
      <ConfirmDialog
        title={`Are you sure you want to ${
          isEditDialog ? "Edit" : "Delete"
        } this Fleet?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            if (isEditDialog) {
              submitData();
            } else {
              deleteFleet();
            }
          } else {
            setConfirmDialog(false);
            setIsEditDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </Box>
  );
}
