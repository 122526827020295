import { Delete, Edit, FilterAlt, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  ImageList,
  ImageListItem,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Modal,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { color } from "../../../Config/theme";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles";
import {
  cloneDeep,
  flattenDeep,
  isArray,
  isEmpty,
  isNull,
  isObject,
  toNumber,
} from "lodash";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import moment from "moment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as XLSX from "xlsx/xlsx.mjs";
import ConfirmDialog from "../../ConfirmDialog";
import authActions from "../../../Redux/reducers/auth/actions";
import { isMobile } from "react-device-detect";

function TyreInventoryView(props, ref) {
  const {
    tsn = "",
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleFilter = () => null,
    handleIsFleet = () => null,
    handleSearch = () => null,
    handledate = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    search = "",
    date = null,
    isFleet = false,
  } = props;
  const styles = useStyles();
  const { token, dropDownData, fleetData, userdata } = useSelector(
    (state) => state.auth
  );

  const { setEditInventoryData } = authActions;

  const dispatch = useDispatch();

  const [pageLoad, setPageLoad] = useState(false);

  // const [datePicker, setDatePicker] = useState(null);
  // const [filter, setFilter] = useState("");
  // const [searchFilter, setSearchFilter] = useState(tsn);
  const [inventoryData, setInventoryData] = useState({});

  const [fleetList, setFleetList] = useState(false);
  const [categoryList, setcategoryList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [compositionList, setCompositionList] = useState(false);
  const [ConditionList, setConditionList] = useState(false);
  const [typeList, setTypeList] = useState(false);

  const [tyreId, setTyreId] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  // const [isFleet, setIsFleet] = useState(false);
  const [downLoadArr, setDownLoadArr] = useState({});

  const [modal, openModal] = useState(false);
  const [imgArr, setImgArr] = useState("");

  useEffect(() => {
    isNull(date) && isEmpty(search) && getitemInventoryList(false);
  }, [date, search]);

  useState(() => {
    if (!isEmpty(tsn)) {
      handleSearch(tsn);
    }
  }, [tsn]);

  useEffect(() => {
    setPageLoad(true);
    getitemInventoryList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    if (!isEmpty(downLoadArr) && downLoadArr?.pagination?.isMore) {
      getDownloadInventoryList(false);
    }
  }, [downLoadArr]);

  function resetFilterData() {
    handledate(null);
    handleIsFleet(false);
    handleFilter("");
    handleSearch("");
    handlePageNo(1);
  }

  useImperativeHandle(ref, () => ({
    getitemInventoryList: () => {
      getDownloadInventoryList(true);
    },
  }));

  const inventoryColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title="Edit" placement="right" arrow>
            <Edit
              style={{ color: color.primary, cursor: "pointer" }}
              onClick={() => {
                handleClick("addTyre", params?.row);
                dispatch(setEditInventoryData(params?.row));
              }}
            />
          </Tooltip>
        );
      },
    },
    { field: "category", headerName: "Category", width: 200 },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 250,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              handleClick("tyreDetails", params?.row);
            }}
          >
            <Typography
              style={{
                whiteSpace: "pre-wrap",
                color: color.primary,
                fontWeight: "bold",
              }}
            >
              {params?.row?.tyre_serial_no}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 300,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.vehicle_no || "-"}</Typography>;
      },
    },
    {
      field: "vehicle_brand",
      headerName: "Vehicle Brand",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.vehicle_brand || "-"}</Typography>;
      },
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.vehicle_model || "-"}</Typography>;
      },
    },
    {
      field: "vehicle_type",
      headerName: "Vehicle Type",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.vehicle_type || "-"}</Typography>;
      },
    },
    {
      field: "axle_type",
      headerName: "Axle Type",
      width: 180,
      renderCell: (params) => {
        return <Typography>{params?.row?.axle_type || "-"}</Typography>;
      },
    },
    {
      field: "tyre_position",
      headerName: "Tyre Position",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.tyre_position || "-"}</Typography>;
      },
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 250,
    },
    {
      field: "tyre_pattern_size",
      headerName: "Item Description",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.tyre_pattern_size || "-"}</Typography>;
      },
    },
    {
      field: "tyre_fitment_km",
      headerName: "Tyre Fitment Reading (Km)",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography>{params?.row?.tyre_fitment_km || "-"} km</Typography>
        );
      },
    },
    {
      field: "total_mileage",
      headerName: "Tyre Mileage (Total in Km)",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.total_mileage || "-"} km</Typography>;
      },
    },
    {
      field: "vehicle_wise_mileage",
      headerName: "Tyre Mileage (Vehicle wise in Km)",
      width: 280,
      renderCell: (params) => {
        const one = Object.keys(params?.row?.vehicle_wise_mileage).map(
          (item) => {
            let a = `${item} => ${params?.row?.vehicle_wise_mileage[item]}`;
            return a;
          }
        );
        const arr = [];
        one.map((item1) => {
          arr.push(item1);
        });
        return <Typography>{arr.toString() || 0}</Typography>;
      },
    },
    {
      field: "original_nsd",
      headerName: "Original NSD (mm)",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography>{`${params?.row?.original_nsd} mm` || "-"}</Typography>
        );
      },
    },
    {
      field: "current_nsd",
      headerName: "Current NSD (mm)",
      width: 220,
      renderCell: (params) => {
        const nsdArr = params?.row?.current_nsd;
        let groove1 = 0;
        let groove2 = 0;
        let groove3 = 0;
        let groove4 = 0;
        let count1 = 1;
        let count2 = 1;
        let count3 = 1;
        let count4 = 1;
        isArray(nsdArr) &&
          !isEmpty(nsdArr) &&
          nsdArr.map((v) => {
            if (!isEmpty(v?.groove1)) {
              groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
              count1++;
            }
            if (!isEmpty(v?.groove2)) {
              groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
              count2++;
            }
            if (!isEmpty(v?.groove3)) {
              groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
              count3++;
            }
            if (!isEmpty(v?.groove4)) {
              groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
              count4++;
            }
          });
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
              groove2 !== 0 ? groove2.toFixed(2) : " - "
            }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
              groove4 !== 0 ? groove4.toFixed(2) : " - "
            } mm`}
          </Typography>
        );
      },
    },
    {
      field: "avg_nsd",
      headerName: "Current Avg. NSD (mm)",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${params?.row?.avg_nsd} mm` || "-"}
          </Typography>
        );
      },
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.tyre_composition || "-"}</Typography>;
      },
    },
    {
      field: "tyre_type",
      headerName: "Tyre Type",
      width: 250,
      renderCell: (params) => {
        return <Typography>{params?.row?.tyre_type || "-"}</Typography>;
      },
    },
    {
      field: "no_of_retread",
      headerName: "No. of Retread",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography style={{ fontSize: 14 }}>
            {params?.row?.no_of_retread || "-"}
          </Typography>
        );
      },
    },
    {
      field: "tyre_condition",
      headerName: "Tyre Condition",
      width: 250,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.tyre_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.tyre_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.tyre_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.tyre_condition || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "ply_rating_id",
      headerName: "Ply Rating",
      width: 180,
      renderCell: (params) => {
        let array = params?.row?.ply_rating_id;
        let res = dropDownData?.ply_rating?.find((item) => array == item?.id);
        return (
          <Typography style={{ fontSize: 14 }}>{res?.label || "-"}</Typography>
        );
      },
    },

    {
      field: "tyre_air_pressure",
      headerName: "Air Pressure",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography style={{ fontSize: 14 }}>
            {params?.row?.tyre_air_pressure}
            {params?.row?.tyre_air_pressure ? " psi" : "-"}
          </Typography>
        );
      },
    },

    {
      field: "capture_date",
      headerName: "Added Date",
      width: 250,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : "-"}
          </div>
        );
      },
    },

    {
      field: "last_changed_date",
      headerName: "Last Changed Date",
      width: 250,
      renderCell: (params) => {
        const timestamp = params?.row?.last_changed_date;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : "-"}
          </div>
        );
      },
    },
    {
      field: "tyre_images",
      headerName: "Images",
      width: 150,
      renderCell: (params) => {
        return (
          <div
            style={{
              flex: 1,
              alignItems: "center",
            }}
          >
            <Typography
              onClick={() => {
                if (
                  !isEmpty(params?.formattedValue) &&
                  isArray(params?.formattedValue)
                ) {
                  setImgArr(params?.formattedValue);
                  openModal(true);
                } else {
                  return null;
                }
              }}
              style={{
                color:
                  !isEmpty(params?.formattedValue) &&
                  isArray(params?.formattedValue)
                    ? color.primary
                    : color.disable,
                fontWeight: "bold",
                cursor: "pointer",
                textAlign: "center",
              }}
            >
              View
            </Typography>
          </div>
        );
      },
    },
    {
      field: "Delete",
      headerAlign: "center",
      headerName: "Delete",
      width: 80,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip
            title={params?.row?.category !== "Stock" ? "" : "Delete"}
            placement="left"
            arrow
          >
            <Delete
              style={{
                color:
                  params?.row?.category !== "Stock" ? color.disable : "red",
                cursor: "pointer",
              }}
              onClick={() => {
                if (params?.row?.category === "Stock") {
                  setConfirmDialog(true);
                  setTyreId(params?.row?.id);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  const categoryListArray = [
    {
      label: "Casing",
    },
    {
      label: "Claim",
    },
    {
      label: "On Wheel",
    },
    {
      label: "Retreading",
    },
    {
      label: "Scrap",
    },
    {
      label: "Stock",
    },
  ];

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre Inventory`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getitemInventoryList(bool, v) {
    !v && setPageLoad(true);
    v && handleLoader(true);
    const date1 = !isNull(date) ? moment(date).format("YYYY-MM-DD") : "";
    let endpoints = `${
      Setting.endpoints.itemInventoryList
    }?page=${pageNo}&per_page=${record}&search=${bool ? "" : search}&filter=${
      isFleet ? "" : filter
    }&date=${date1}&is_download=${v ? v : ""}&fleet_id=${
      isFleet ? filter.replaceAll("&", "%26") : ""
    }`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          const listObj = {
            pagination: response?.data?.pagination,
            data: response?.data?.rows || [],
          };

          setInventoryData(listObj);
        }
      } else {
        toast.error(response?.message, { toastId: 17 });
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString(), { toastId: 17 });
      console.log("error ===>>>", error);
    }
  }

  // get download inventoryList
  async function getDownloadInventoryList(bool) {
    handleLoader(true);
    const date1 = !isNull(date) ? moment(date).format("YYYY-MM-DD") : "";

    let page = 0;
    const cPage =
      downLoadArr &&
      downLoadArr.pagination &&
      downLoadArr.pagination.currentPage
        ? toNumber(downLoadArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }

    let endpoints = `${
      Setting.endpoints.itemInventoryList
    }?page=${page}&per_page=${record}&search=${bool ? "" : search}&filter=${
      isFleet ? "" : filter
    }&date=${date1}&fleet_id=${isFleet ? filter.replaceAll("&", "%26") : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        const obj = bool ? {} : cloneDeep(downLoadArr);
        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};

        if (isArray(newListData) && !isEmpty(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
          if (!paginationDatas?.isMore) {
            handleLoader(false);
            downloadInvetoryData(obj?.data);
            auditSave("Tyre Inventory List");
            setDownLoadArr({});
          } else {
            setDownLoadArr(obj);
          }
        }
      } else {
        toast.error(response?.message, { toastId: 17 });
      }

      handleLoader(false);
    } catch (error) {
      handleLoader(false);
      toast.error(error.toString(), { toastId: 17 });
      console.log("error ===>>>", error);
    }
  }

  async function tyreRemoveREquest() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreremovalrequest}?tyre_id=${tyreId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setConfirmDialog(false);
        getitemInventoryList(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  async function deleteTyre() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.deletetyre}?id=${tyreId}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setConfirmDialog(false);
        getitemInventoryList(false);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  function mileageHistory(data) {
    const one = Object.keys(data).map((item) => {
      let a = `${item} => ${data[item]}`;
      return a;
    });
    const arr = [];
    one.map((item1) => {
      arr.push(item1);
    });
    return arr;
  }

  function createImageArr(data) {
    let newStr = "";
    const URL1 = data[0] ? data[0] : "-";
    const URL2 = data[1] ? data[1] : "-";
    const URL3 = data[2] ? data[2] : "-";
    const URL4 = data[3] ? data[3] : "-";
    const URL5 = data[4] ? data[4] : "-";
    if (isArray(data) && !isEmpty(data)) {
      newStr = `1) ${URL1}\n2) ${URL2}\n3) ${URL3}\n4) ${URL4}\n5) ${URL5}`;
    }
    return newStr;
  }

  function downloadInvetoryData(data) {
    const newData = data?.map((item, index) => {
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const plyRating =
        !isNull(item?.ply_rating_id) &&
        dropDownData?.ply_rating.find(
          (v) => item?.ply_rating_id.toString() === v?.id.toString()
        );

      const one = Object.keys(item?.vehicle_wise_mileage).map((item1) => {
        let a = `${item1} => ${item?.vehicle_wise_mileage[item1]}`;
        return a;
      });
      const arr = [];
      one.map((item2) => {
        arr.push(item2);
      });

      const images = createImageArr(item?.tyre_images);

      const newObj = {
        "Sr. No.": index + 1,
        Category: item?.category,
        "Tyre Serial No.": item?.tyre_serial_no,
        "Fleet Name": item?.fleet_name,
        "Vehicle no.": item?.vehicle_no || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Position": item?.tyre_position || "- ",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.tyre_pattern_size || "-",
        "Tyre Fitment Reading (Km)": isEmpty(item?.tyre_fitment_km)
          ? "-"
          : Number(item?.tyre_fitment_km?.toString()?.replaceAll(",", "")),
        "Tyre Mileage (Total in Km)": Number(item?.total_mileage || "-"),
        "Tyre Mileage (Vehicle wise in Km)":
          mileageHistory(item?.vehicle_wise_mileage)?.toString() || "-",
        "Original NSD (mm)": item?.original_nsd || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Current Avg. NSD (mm)": item?.avg_nsd || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Tyre Type": item?.tyre_type || "-",
        "No. of Retread": item?.no_of_retread || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Ply Rating": plyRating?.label,
        "Air Pressure": `${item?.tyre_air_pressure || 0} psi`,
        "Added Date": moment(item?.capture_date).format("DD-MM-yyyy") || "-",
        "Last Changed Date":
          moment(item?.last_changed_date).format("DD-MM-yyyy") || "-",
        Images: images?.toString() || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Inventory List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Inventory List.xlsx");
  }

  const dataGridRender = useMemo(() => {
    return (
      <DataGrid
        rows={inventoryData?.data}
        columns={inventoryColumn}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
        autoHeight={true}
        getRowHeight={() => "auto"}
        onCellClick={(params) => {
          if (params?.field !== "action") {
            handleClick("fleetDetails", params?.row);
          }
        }}
      />
    );
  }, [inventoryData]);

  return (
    <div>
      <Grid container gap={1.2}>
        {filter !== "Casing" &&
        filter !== "Claim" &&
        filter !== "On Wheel" &&
        filter !== "Retreading" &&
        filter !== "Scrap" &&
        filter !== "Stock" ? (
          <Grid item>
            <Typography
              varient="tabletitle"
              style={{
                backgroundColor: "#D9F8FF",
                padding: 6,
                marginTop: 14,
                borderRadius: 4,
              }}
            >
              Total : {inventoryData?.pagination?.totalCount || 0}
            </Typography>
          </Grid>
        ) : null}
        {filter !== "Claim" &&
        filter !== "On Wheel" &&
        filter !== "Retreading" &&
        filter !== "Scrap" &&
        filter !== "Stock" ? (
          <Grid item>
            <Typography
              varient="tabletitle"
              style={{
                backgroundColor: "#D9FFE4",
                padding: 6,
                marginTop: 14,
                borderRadius: 4,
              }}
            >
              Casing : {inventoryData?.pagination?.casingCount || 0}
            </Typography>
          </Grid>
        ) : null}
        {filter !== "Casing" &&
        filter !== "On Wheel" &&
        filter !== "Retreading" &&
        filter !== "Scrap" &&
        filter !== "Stock" ? (
          <Grid item>
            <Typography
              varient="tabletitle"
              style={{
                backgroundColor: "#FFD9D9 ",
                padding: 6,
                marginTop: 14,
                borderRadius: 4,
              }}
            >
              Claim : {inventoryData?.pagination?.claimCount || 0}
            </Typography>
          </Grid>
        ) : null}
        {filter !== "Casing" &&
        filter !== "Claim" &&
        filter !== "Retreading" &&
        filter !== "Scrap" &&
        filter !== "Stock" ? (
          <Grid item>
            <Typography
              varient="tabletitle"
              style={{
                backgroundColor: "#D9F8FF",
                padding: 6,
                marginTop: 14,
                borderRadius: 4,
              }}
            >
              On Wheel : {inventoryData?.pagination?.onWheelCount || 0}
            </Typography>
          </Grid>
        ) : null}
        {filter !== "Casing" &&
        filter !== "Claim" &&
        filter !== "On Wheel" &&
        filter !== "Scrap" &&
        filter !== "Stock" ? (
          <Grid item>
            <Typography
              varient="tabletitle"
              style={{
                backgroundColor: "#D9FFE4",
                padding: 6,
                marginTop: 14,
                borderRadius: 4,
              }}
            >
              Retreading : {inventoryData?.pagination?.retredingCount || 0}
            </Typography>
          </Grid>
        ) : null}
        {filter !== "Casing" &&
        filter !== "Claim" &&
        filter !== "On Wheel" &&
        filter !== "Retreading" &&
        filter !== "Stock" ? (
          <Grid item>
            <Typography
              varient="tabletitle"
              style={{
                backgroundColor: "#FFD9D9",
                padding: 6,
                marginTop: 14,
                borderRadius: 4,
              }}
            >
              Scrap : {inventoryData?.pagination?.scrapCount || 0}
            </Typography>
          </Grid>
        ) : null}
        {filter !== "Casing" &&
        filter !== "Claim" &&
        filter !== "On Wheel" &&
        filter !== "Retreading" &&
        filter !== "Scrap" ? (
          <Grid item>
            <Typography
              varient="tabletitle"
              style={{
                backgroundColor: "#D9F8FF",
                padding: 6,
                marginTop: 14,
                borderRadius: 4,
              }}
            >
              Stock : {inventoryData?.pagination?.stockCount || 0}
            </Typography>
          </Grid>
        ) : null}
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 14 }}
        wrap="nowrap"
      >
        <Grid item lg={2}>
          <Select
            fullWidth
            placeholder="Filter"
            value={filter}
            onChange={(v) => {
              handlePageNo(1);
              handleFilter(v.target.value);
            }}
            onOpen={() => {
              if (isEmpty(filter.toString())) {
                setcategoryList(false);
                setbrandList(false);
                setFleetList(false);
                setTypeList(false);
                setCompositionList(false);
                setConditionList(false);
              }
            }}
            displayEmpty
            IconComponent={FilterAlt}
            style={filter === "" ? { color: "#A2A2A2" } : {}}
            classes={{
              iconOpen: styles.iconOpen,
            }}
          >
            <MenuItem value="" disabled hidden selected>
              Filter
            </MenuItem>

            <ListItemButton
              onClick={() => {
                setFleetList(!fleetList);
                setcategoryList(false);
                setbrandList(false);
                setCompositionList(false);
                setConditionList(false);
                setTypeList(false);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Fleet</Typography>
              {fleetList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            {fleetData.map((item, index) => {
              return (
                <MenuItem
                  style={{ display: fleetList ? "flex" : "none" }}
                  key={index}
                  value={item?.label}
                  onClick={() => {
                    handleIsFleet(true);
                  }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}

            <ListItemButton
              onClick={() => {
                setcategoryList(!categoryList);
                setbrandList(false);
                setFleetList(false);
                setCompositionList(false);
                setConditionList(false);
                setTypeList(false);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Category</Typography>
              {categoryList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            {categoryListArray.map((item, index) => {
              return (
                <MenuItem
                  style={{ display: categoryList ? "flex" : "none" }}
                  key={index}
                  value={item?.label}
                  onClick={() => {
                    handleIsFleet(false);
                  }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}
            <ListItemButton
              onClick={() => {
                setbrandList(!brandList);
                setcategoryList(false);
                setFleetList(false);
                setCompositionList(false);
                setConditionList(false);
                setTypeList(false);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Tyre Brand</Typography>
              {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            {dropDownData.tyre_brand.map((item, index) => {
              return (
                <MenuItem
                  style={{ display: brandList ? "flex" : "none" }}
                  key={index}
                  value={item?.id}
                  onClick={() => {
                    handleIsFleet(false);
                  }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}
            <ListItemButton
              onClick={() => {
                setTypeList(!typeList);
                setFleetList(false);
                setbrandList(false);
                setcategoryList(false);
                setCompositionList(false);
                setConditionList(false);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Tyre Type</Typography>
              {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            {dropDownData.tyre_type.map((item, index) => {
              return (
                <MenuItem
                  style={{ display: typeList ? "flex" : "none" }}
                  key={index}
                  value={item?.id}
                  onClick={() => {
                    handleIsFleet(false);
                  }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}

            <ListItemButton
              onClick={() => {
                setCompositionList(!compositionList);
                setbrandList(false);
                setFleetList(false);
                setcategoryList(false);
                setConditionList(false);
                setTypeList(false);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Tyre Composition</Typography>
              {compositionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            {dropDownData.tyre_composition.map((item, index) => {
              return (
                <MenuItem
                  style={{ display: compositionList ? "flex" : "none" }}
                  key={index}
                  value={item?.id}
                  onClick={() => {
                    handleIsFleet(false);
                  }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}

            <ListItemButton
              onClick={() => {
                setConditionList(!ConditionList);
                setbrandList(false);
                setFleetList(false);
                setcategoryList(false);
                setCompositionList(false);
                setTypeList(false);
              }}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Typography>Tyre Condition</Typography>
              {ConditionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            {dropDownData.tyre_condition.map((item, index) => {
              return (
                <MenuItem
                  style={{ display: ConditionList ? "flex" : "none" }}
                  key={index}
                  value={item?.id}
                  onClick={() => {
                    handleIsFleet(false);
                  }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid>
        <Grid
          item
          lg={1.6}
          style={{
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <Typography style={{ fontSize: 14 }}>Records:</Typography>
          <Select
            fullWidth
            value={record}
            onChange={(v) => {
              handlePageNo(1);
              handleRecord(v.target.value);
            }}
            style={{ marginLeft: 10 }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        style={{ marginTop: 14 }}
        wrap="nowrap"
      >
        <Grid
          item
          lg={2}
          style={{ display: "flex", alignItems: "center" }}
          wrap={"nowrap"}
        >
          <TextField
            fullWidth
            placeholder="Search"
            className={styles.inputFieldStyle}
            value={search}
            onChange={(v) => {
              isEmpty(v.target.value) && getitemInventoryList(true);
              handleSearch(v?.target?.value);
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter") {
                if (!isEmpty(search) || !isNull(date)) {
                  handlePageNo(1);
                  getitemInventoryList(false);
                }
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div style={{ lineHeight: 0 }}>
                    <Search style={{ fontSize: 20 }} />
                  </div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <div style={{ lineHeight: 0, cursor: "pointer" }}>
                    <Tooltip
                      title="Search by Tyre Serial No. and Item Description"
                      placement="bottom"
                      arrow
                    >
                      <InfoIcon style={{ fontSize: 20 }} />
                    </Tooltip>
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <div style={{ marginLeft: 20 }}>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              showToolbar={false}
              views={["year", "month", "day"]}
              disableFuture
              value={date}
              onChange={(newValue) => {
                handledate(newValue);
              }}
              inputFormat="dd-MM-yyyy"
              DialogProps={{ className: styles.date }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    placeholder: "DD-MM-YYYY",
                  }}
                  sx={{ svg: { color: color.primary } }}
                />
              )}
            />
          </LocalizationProvider>
        </div>
        <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
          <Button
            variant="contained"
            style={{ backgroundColor: color.secondary }}
            onClick={() => {
              if (!isEmpty(search) || !isNull(date)) {
                handlePageNo(1);
                getitemInventoryList(false);
              }
            }}
          >
            Search
          </Button>
          <Button
            variant="contained"
            style={{ marginLeft: 10 }}
            onClick={resetFilterData}
            disabled={
              filter === "" && isEmpty(search) && isNull(date) ? true : false
            }
          >
            Reset
          </Button>
        </div>
      </Grid>

      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(inventoryData?.data) && !isEmpty(inventoryData?.data) ? (
        <div style={{ marginTop: 20 }}>
          {dataGridRender}
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(inventoryData?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={2}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => {
                handlePageNo(e);
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
      <ConfirmDialog
        title={`Are you sure you want to Remove Tyre?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            if (userdata?.role === "superadmin" || userdata?.role === "admin") {
              deleteTyre();
            } else {
              tyreRemoveREquest();
            }
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          openModal(false);
        }}
        open={modal}
      >
        <div
          style={{
            minWidth: "40vw",
            minHeight: "65vh",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={1}
            gap={9}
            style={{
              overflowY: "scroll",
              maxHeight: "65vh",
              maxWidth: "100%",
            }}
          >
            {isArray(imgArr) &&
              !isEmpty(imgArr) &&
              imgArr.map((item, index) => {
                return (
                  <ImageListItem
                    key={index}
                    style={{
                      width: isMobile ? "60vw" : "100%",
                      height: isMobile ? "80vh" : "100%",
                    }}
                  >
                    <img src={`${item}`} alt="" />
                  </ImageListItem>
                );
              })}
          </ImageList>
        </div>
      </Modal>
    </div>
  );
}

export default forwardRef(TyreInventoryView);
