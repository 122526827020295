import React, { useEffect, useState, useRef } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Link,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { Box } from "@mui/system";
import { useLocation } from "react-router-dom";
import InspectionHistory from "../../../Components/InspectionManagement/InspectionHistory/index.js";
import InspectionDetails from "../../../Components/InspectionManagement/InspectionDetails/index.js";
import VehicleInspection from "../../../Components/InspectionManagement/VehicleInspection/index.js";
import AddInspection from "../../../Components/InspectionManagement/AddInspection/index.js";
import ResolveIssue from "../../../Components/InspectionManagement/ResolveIssue/index.js";
import ModificationRequest from "../../../Components/InspectionManagement/ModificationRequest/index.js";
import InspectionIssueDetails from "../../../Components/InspectionManagement/InspectionIssueDetails/index.js";
import { isNull } from "lodash";
import MasterReport from "../../../Components/InspectionManagement/Reports/Reports.js";
import { useSelector } from "react-redux";
import UnresolvedIssue from "../../../Components/Fleet/UnresolvedIssue/index.js";
import RemoveTyre from "../../../Components/TyreHistory/RemoveTyre/index.js";
import AddTyre from "../../../Components/TyreHistory/AddTyre/index.js";
import AddInspectionForm from "../../../Components/InspectionManagement/AddInspectionForm/index.js";
import RemovalTyreRequest from "../../../Components/InspectionManagement/RemovalTyreRequest/index.js";

export default function Inspection() {
  const styles = useStyles();
  const { isOnline } = useSelector((state) => state.auth);
  const location = useLocation();
  const fromDashboard =
    !isNull(location?.state) && location?.state?.set === "dashboardDetails";

  const dashboard = location?.state?.set === "inspectionHistory";
  const dashboard2addInspection = location?.state?.set === "addInspection";

  const [inspection, setInspectionData] = useState({});
  const [changeTab, setChangeTab] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [inspectionData, setInspactionData] = useState({});
  const [editRowData, setEditRowData] = useState({});
  const [tyreData, setTyreData] = useState({});
  const [removalData, setRemovalData] = useState({});
  const [scroll, setScroll] = useState("");
  const [from, setFrom] = useState("");
  const [isDirect, setisDirect] = useState(false);
  const [issueType, setIssueType] = useState("");
  const [issue, setIssue] = useState("");

  const [pageNoIH, setPageNoIH] = useState(1);
  const [recordIH, setRecordIH] = useState(10);
  const [searchFilterIH, setSearchFilterIH] = useState("");
  const [datePickerIH, setDatePickerIH] = useState(null);

  const [pageNoMR, setPageNoMR] = useState(1);
  const [recordMR, setRecordMR] = useState(10);
  const [filter, setFilter] = useState("");
  const [searchFilterMR, setSearchFilterMR] = useState("");
  const [datePickerMR, setDatePickerMR] = useState(null);

  const [pageNoID, setPageNoID] = useState(1);
  const [recordID, setRecordID] = useState(10);
  const [filterID, setFilterID] = useState("");

  const [pageNoUI, setPageNoUI] = useState(1);
  const [recordUI, setRecordUI] = useState(10);
  const [filterUI, setFilterUI] = useState("");

  const [pageNoPT, setPageNoPT] = useState(1);
  const [recordPT, setRecordPT] = useState(10);

  const [pageNoPM, setPageNoPM] = useState(1);
  const [recordPM, setRecordPM] = useState(10);

  const [pageNoRT, setPageNoRT] = useState(1);
  const [recordRT, setRecordRT] = useState(10);

  const [pageNoRM, setPageNoRM] = useState(1);
  const [recordRM, setRecordRM] = useState(10);

  const [pageNoTR, setPageNoTR] = useState(1);
  const [recordTR, setRecordTR] = useState(10);
  const [filterTR, setFilterTR] = useState("");
  const [searchFilterTR, setSearchFilterTR] = useState("");

  const [PtabValue, setPTabValue] = useState(0);
  const [RtabValue, setRTabValue] = useState(0);

  const [downloadLoader, setDownloadLoader] = useState(false);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const inspectionHistory = useRef();
  const modificationRequest = useRef();
  const removalTyreRequest = useRef();

  useEffect(() => {
    if (fromDashboard) {
      setChangeTab("inspectionHistory");
      setInspactionData(location?.state);
    }
  }, []);

  useEffect(() => {
    if (dashboard2addInspection) {
      let subData = {
        fleet: {
          label: location?.state?.data?.fleet_name,
          id: location?.state?.data?.fleet_id,
        },
        vehicle: {
          label: location?.state?.data?.vehicle_no,
          id: location?.state?.data?.vehicle_id,
        },
      };
      setRemovalData(subData);
      setFrom("dashboard");
      setChangeTab("addInspection");
    }
  }, []);

  useEffect(() => {
    document.title = Setting.page_name.Inspection;
    if (location?.state?.from === "notificationU") {
      setTabValue(1);
    } else if (location?.state?.from === "notificationR") {
      setTabValue(2);
    }
  }, []);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [changeTab]);

  return (
    <div className={styles.card}>
      <Grid container id="jump_to_me">
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator="›">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            {changeTab === "inspectionHistory" ||
            changeTab === "vehicleInspection" ||
            changeTab === "vehicleInspectionChanges" ||
            changeTab === "addInspection" ||
            changeTab === "resolveIssue" ||
            changeTab === "inspectionIssue" ||
            changeTab === "UnresolvedIssue" ||
            changeTab === "addInspectionForm" ? (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("");
                  setFrom("");
                  setScroll("");
                  setFilterUI("");
                }}
              >
                Inspection Management
              </Link>
            ) : (
              <Typography className={styles.linkStyle}>
                Inspection Management
              </Typography>
            )}
            {(changeTab === "inspectionHistory" ||
              changeTab === "vehicleInspection" ||
              changeTab === "resolveIssue" ||
              (changeTab === "addInspectionForm" &&
                from === "vehicleInspection")) &&
              from !== "issueDetails" && (
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  className={styles.linkStyle}
                  onClick={() => {
                    setChangeTab(from === "issue" ? "UnresolvedIssue" : "");
                    setFrom("");
                  }}
                >
                  {from !== "issue"
                    ? "Inspection History"
                    : "Unresolved Tyre List"}
                </Link>
              )}
            {(changeTab === "removeTyre" || changeTab === "addTyre") && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("addInspection");
                  setFrom("inside");
                }}
              >
                Add Inspection
              </Link>
            )}
            {changeTab === "removeTyre" && (
              <Typography className={styles.linkStyle}>Remove Tyre</Typography>
            )}
            {changeTab === "addTyre" && (
              <Typography className={styles.linkStyle}>Add Tyre</Typography>
            )}
            {(changeTab === "vehicleInspection" ||
              (from === "vehicleInspection" &&
                changeTab === "addInspectionForm") ||
              (!isDirect &&
                from !== "issueDetails" &&
                changeTab === "resolveIssue")) && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => setChangeTab("inspectionHistory")}
              >
                {inspectionData?.fleet_name}
              </Link>
            )}
            {changeTab === "inspectionHistory" && (
              <Typography className={styles.linkStyle}>
                {inspectionData?.fleet_name}
              </Typography>
            )}
            {!isDirect &&
              from !== "issueDetails" &&
              changeTab === "resolveIssue" && (
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  className={styles.linkStyle}
                  onClick={() => setChangeTab("vehicleInspection")}
                >
                  {inspectionData?.vehicle_no}
                </Link>
              )}
            {changeTab === "inspectionHistory" && (
              <Typography className={styles.linkStyle}>
                {inspectionData?.vehicle_no}
              </Typography>
            )}
            {from === "vehicleInspection" &&
              changeTab === "addInspectionForm" && (
                <Link
                  underline="hover"
                  color="inherit"
                  href="#"
                  className={styles.linkStyle}
                  onClick={() => {
                    setFrom("inside");
                    setChangeTab("vehicleInspection");
                  }}
                >
                  {inspectionData?.vehicle_no}
                </Link>
              )}
            {changeTab === "vehicleInspection" && (
              <Typography className={styles.linkStyle}>
                {inspectionData?.vehicle_no}
              </Typography>
            )}
            {(changeTab === "addInspection" ||
              changeTab === "addInspectionForm") && (
              <Typography className={styles.linkStyle}>
                {from === "vehicleInspection"
                  ? "Edit Inspection"
                  : "Add Inspection"}
              </Typography>
            )}
            {/* {changeTab === "inspectionIssue" && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("");
                  setFrom("");
                }}
              >
                Issue Resolution
              </Link>
            )} */}
            {from === "issueDetails" && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("inspectionIssue");
                  setFrom("");
                }}
              >
                {inspectionData?.fleet_name}
                {" / "}
                {inspectionData?.vehicle_no}
              </Link>
            )}
            {changeTab === "inspectionIssue" && (
              <Typography className={styles.linkStyle}>
                {inspectionData?.fleet_name}
                {" / "}
                {inspectionData?.vehicle_no}
              </Typography>
            )}
            {/* {changeTab === "resolveIssue" && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("vehicleInspection");
                  setTyreData("");
                }}
              >
                Unresolved Tyre List
              </Link>
            )} */}
            {changeTab === "resolveIssue" && (
              <Typography className={styles.linkStyle}>
                Resolve Issue
              </Typography>
            )}
            {changeTab === "vehicleInspectionChanges" && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("");
                  setInspectionData({});
                }}
              >
                Modification Request
              </Link>
            )}
            {changeTab === "vehicleInspectionChanges" && (
              <Typography className={styles.linkStyle}>
                {inspectionData?.vehicle_no}
              </Typography>
            )}
            {changeTab === "UnresolvedIssue" && (
              <Typography className={styles.linkStyle}>
                Unresolved Tyre List
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "inspectionHistory" ? (
        <InspectionDetails
          pageNo={pageNoID}
          record={recordID}
          filter={filterID}
          handlePageNo={(page) => {
            setPageNoID(page);
          }}
          handleRecord={(rec) => {
            setRecordID(rec);
          }}
          handleFilter={(flt) => {
            setFilterID(flt);
          }}
          handleLoader={(v) => setDownloadLoader(v)}
          handleClick={(type, data) => {
            if (type === "vehicleInspection") {
              setChangeTab("vehicleInspection");
              setInspactionData(data);
              setFrom("");
            }
          }}
          editData={inspection}
          inspectionData={inspectionData}
        />
      ) : changeTab === "inspectionIssue" ? (
        <InspectionIssueDetails
          inspectionData={inspectionData}
          scroll={scroll}
          PtabValue={PtabValue}
          tabValue={RtabValue}
          handlePTabValue={(tab) => {
            setPTabValue(tab);
          }}
          handleRTabValue={(tab) => {
            setRTabValue(tab);
          }}
          pageNoRT={pageNoRT}
          pageNoRM={pageNoRM}
          recordRT={recordRT}
          recordRM={recordRM}
          handlePageNoRT={(page) => {
            setPageNoRT(page);
          }}
          handleRecordRT={(rec) => {
            setRecordRT(rec);
          }}
          handlePageNoRM={(page) => {
            setPageNoRM(page);
          }}
          handleRecordRM={(rec) => {
            setRecordRM(rec);
          }}
          pageNoPT={pageNoPT}
          pageNoPM={pageNoPM}
          recordPT={recordPT}
          recordPM={recordPM}
          handlePageNoPT={(page) => {
            setPageNoPT(page);
          }}
          handleRecordPT={(rec) => {
            setRecordPT(rec);
          }}
          handlePageNoPM={(page) => {
            setPageNoPM(page);
          }}
          handleRecordPM={(rec) => {
            setRecordPM(rec);
          }}
          handleClick={(type, data, issType, iss) => {
            if (type === "resolveIssue") {
              setFrom("issueDetails");
              setTyreData(data);
              setIssueType(issType);
              setIssue(iss);
              setChangeTab("resolveIssue");
            }
          }}
          fleetData={inspectionData}
          vehicleDetails={tyreData}
        />
      ) : changeTab === "addInspection" ? (
        <AddInspection
          removalData={removalData}
          from={from}
          handleClick={(type, data, subData) => {
            if (type === "cancel") {
              setChangeTab("");
              setFrom("");
            } else if (type === "empty") {
              setFrom("");
              setRemovalData({});
            } else if (type === "removeTyre") {
              setChangeTab("removeTyre");
              setFrom("addInspection");
              setTyreData(data);
              setRemovalData(subData);
            } else if (type === "addInspectionForm") {
              setChangeTab("addInspectionForm");
              setTyreData(data);
              setRemovalData(subData);
            }
          }}
        />
      ) : changeTab === "addInspectionForm" ? (
        <AddInspectionForm
          removalData={removalData}
          tyreData={tyreData}
          from={from}
          handleClick={(type, data, frm) => {
            setFrom("inside");
            if (type === "cancel") {
              frm === "vehicleInspection"
                ? setTimeout(() => {
                    setChangeTab("vehicleInspection");
                  }, 200)
                : setChangeTab("addInspection");
              setTyreData({});
              setEditRowData({});
            } else if (type === "success") {
              setEditRowData(data);
              frm === "vehicleInspection"
                ? setTimeout(() => {
                    setChangeTab("vehicleInspection");
                  }, 200)
                : setChangeTab("addInspection");
            }
          }}
        />
      ) : changeTab === "vehicleInspection" ||
        changeTab === "vehicleInspectionChanges" ? (
        <VehicleInspection
          handleClick={(type, data, issType, iss) => {
            if (type === "resolveIssue") {
              setChangeTab("resolveIssue");
              setTyreData(data);
              setisDirect(false);
              setIssueType(issType);
              setIssue(iss);
            } else if (type === "inspectionHistory") {
              setChangeTab("inspectionHistory");
            } else if (type === "exit") {
              setChangeTab("");
              setFrom("");
              setTyreData({});
            } else if (type === "addInspectionForm") {
              setChangeTab("addInspectionForm");
              setTyreData(data);
              setFrom("vehicleInspection");
            }
          }}
          from={changeTab === "vehicleInspectionChanges" ? "modification" : ""}
          fromInside={from === "inside"}
          editData={inspection}
          editRowData={editRowData}
          inspectionData={inspectionData}
        />
      ) : changeTab === "resolveIssue" ? (
        <ResolveIssue
          handleClick={(type, data) => {
            if (type === "cancel") {
              from === "issueDetails"
                ? setChangeTab("inspectionIssue")
                : isDirect
                ? setChangeTab("UnresolvedIssue")
                : setChangeTab("vehicleInspection");
              setFrom("");
            }
          }}
          editData={inspection}
          fleetData={inspectionData}
          vehicleDetails={tyreData}
          from={from}
          issueType={issueType}
          issue={issue}
        />
      ) : changeTab === "UnresolvedIssue" ? (
        <UnresolvedIssue
          from="Inspection"
          vehicleData={inspectionData}
          pageNo={pageNoUI}
          record={recordUI}
          filter={filterUI}
          handlePageNo={(page) => {
            setPageNoUI(page);
          }}
          handleRecord={(rec) => {
            setRecordUI(rec);
          }}
          handleFilter={(flt) => {
            setFilterUI(flt);
          }}
          handleClick={(type, data, issType, iss) => {
            if (type === "ResolveIssue") {
              setChangeTab("resolveIssue");
              setTyreData(data);
              setFrom("issue");
              setIssueType(issType);
              setIssue(iss);
            }
          }}
        />
      ) : changeTab === "removeTyre" ? (
        <RemoveTyre
          from={from}
          tyreData={tyreData}
          vehicleList={removalData?.vehicleList}
          fitmentReading={removalData?.fitment_reading}
          captureDate={removalData?.captureDate}
          vehicleId={removalData?.vehicle?.id}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("addInspection");
              setTyreData({});
            } else if (type === "success") {
              if (data === "Scrap" || data === "Stock") {
                setChangeTab("addTyre");
              } else {
                setChangeTab("addInspection");
              }
            }
            setFrom("inside");
          }}
        />
      ) : changeTab === "addTyre" ? (
        <AddTyre
          from={from}
          tyreData={tyreData}
          vehicleId={removalData?.vehicle?.id}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("addInspection");
              setTyreData({});
            } else if (type === "success") {
              setChangeTab("addInspection");
            }
            setFrom("inside");
          }}
        />
      ) : (
        <div className={styles.container}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ height: 40 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="tableTitle">
                Inspection Management
              </Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: sm ? "unset" : "flex-end",
              }}
            >
              {tabValue !== 3 && (
                <>
                  <Button
                    variant="contained"
                    onClick={() => {
                      setChangeTab("addInspection");
                    }}
                  >
                    Add Inspection
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      if (tabValue === 0) {
                        inspectionHistory?.current?.getInspectionList();
                      }
                      if (tabValue === 1) {
                        modificationRequest?.current?.ModificationRequestList();
                      }
                      if (tabValue === 2) {
                        removalTyreRequest?.current?.RemovalTyreRequestList();
                      }
                    }}
                    disabled={isOnline ? false : true}
                  >
                    {downloadLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      "Download"
                    )}
                  </Button>
                </>
              )}
            </Grid>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
            <Tabs
              value={tabValue}
              onChange={(v, b) => {
                setPageNoIH(1);
                setRecordIH(10);
                setPageNoMR(1);
                setRecordMR(10);
                setPageNoTR(1);
                setRecordTR(10);
                setTabValue(b);
                setFilter("");
                setFilterTR("");
                setSearchFilterIH("");
                setDatePickerIH(null);
                setSearchFilterMR("");
                setDatePickerMR(null);
                setSearchFilterTR("");
              }}
              variant="scrollable"
            >
              <Tab className={styles.tabBtn} label="Inspection History" />
              {/* <Tab className={styles.tabBtn} label="Issue Resolution" /> */}
              <Tab className={styles.tabBtn} label="Modification Requests" />
              <Tab className={styles.tabBtn} label="Tyre Removal Requests" />
              <Tab className={styles.tabBtn} label=" Master Reports" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <InspectionHistory
              ref={inspectionHistory}
              pageNo={pageNoIH}
              record={recordIH}
              searchFilter={searchFilterIH}
              datePicker={datePickerIH}
              handlePageNo={(page) => {
                setPageNoIH(page);
              }}
              handleRecord={(rec) => {
                setRecordIH(rec);
              }}
              handleSearch={(s) => {
                setSearchFilterIH(s);
              }}
              handleDatePicker={(date) => {
                setDatePickerIH(date);
              }}
              handleLoader={(v) => setDownloadLoader(v)}
              handleClick={(type, data, scroll) => {
                if (type === "inspectionHistory") {
                  setPageNoID(1);
                  setRecordID(10);
                  setFilterID("");
                  setChangeTab("inspectionHistory");
                  setInspactionData(data);
                } else if (type === "inspectionIssue") {
                  setChangeTab("inspectionIssue");
                  setInspactionData(data);
                  setScroll(scroll);
                  setPageNoPT(1);
                  setPageNoPM(1);
                  setRecordPT(10);
                  setRecordPM(10);
                  setPTabValue(0);
                  setPageNoRT(1);
                  setRecordRT(10);
                  setPageNoRM(1);
                  setRecordRM(10);
                  setRTabValue(0);
                } else if (type === "UnresolvedIssue") {
                  setPageNoUI(1);
                  setRecordUI(10);
                  setChangeTab("UnresolvedIssue");
                  setInspactionData(data);
                  setisDirect(true);
                }
              }}
              from={dashboard ? "inspectionHistory" : ""}
              data={location?.state}
            />
          )}
          {/* {tabValue === 1 && (
            <IssueResolution
              ref={issueResolve}
              handleClick={(type, data) => {
                if (type === "inspectionIssue") {
                  setChangeTab("inspectionIssue");
                  setInspactionData(data);
                } else if (type === "vehicleInspection") {
                  setChangeTab("vehicleInspection");
                  setInspactionData(data);
                  setFrom("issue");
                }
              }}
            />
          )} */}
          {tabValue === 1 && (
            <ModificationRequest
              ref={modificationRequest}
              fleetData={inspectionData}
              pageNo={pageNoMR}
              record={recordMR}
              filter={filter}
              searchFilter={searchFilterMR}
              datePicker={datePickerMR}
              handlePageNo={(page) => {
                setPageNoMR(page);
              }}
              handleRecord={(rec) => {
                setRecordMR(rec);
              }}
              handleFilter={(flt) => {
                setFilter(flt);
              }}
              handleSearch={(s) => {
                setSearchFilterMR(s);
              }}
              handleDatePicker={(date) => {
                setDatePickerMR(date);
              }}
              handleLoader={(v) => setDownloadLoader(v)}
              handleClick={(type, data) => {
                if (type === "vehicleInspectionChanges") {
                  setChangeTab("vehicleInspectionChanges");
                  setInspactionData(data);
                }
              }}
            />
          )}
          {tabValue === 2 && (
            <RemovalTyreRequest
              ref={removalTyreRequest}
              filter={filterTR}
              pageNo={pageNoTR}
              record={recordTR}
              searchFilter={searchFilterTR}
              handlePageNo={(page) => {
                setPageNoTR(page);
              }}
              handleRecord={(rec) => {
                setRecordTR(rec);
              }}
              handleFilter={(flt) => {
                setFilterTR(flt);
              }}
              handleSearch={(s) => {
                setSearchFilterTR(s);
              }}
              handleLoader={(v) => setDownloadLoader(v)}
            />
          )}
          {tabValue === 3 && <MasterReport />}
        </div>
      )}
    </div>
  );
}
