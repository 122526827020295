import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  TableRow,
  TableCell,
  Table,
  CircularProgress,
  MenuItem,
  Select,
  Grid,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery,
  Tooltip,
  Popover,
  TableHead,
  Divider,
  ListItemButton,
  Pagination,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { ArrowBackOutlined, MoreVert } from "@mui/icons-material";
import { Edit, FilterAlt, Search, SearchOutlined } from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import { useTheme } from "@emotion/react";
import Images from "../../../Config/Images";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import * as XLSX from "xlsx/xlsx.mjs";
import InfoIcon from "@mui/icons-material/Info";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chart from "react-google-charts";
import html2canvas from "html2canvas";
import { isTablet } from "react-device-detect";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";
import CDateRange from "../../Modal/CDateRange/index.js";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function TabScrapDetails(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    fleetData,
  } = props;
  const styles = useStyles();
  const { token, dropDownData, isOnline, permissionData, userdata } =
    useSelector((state) => state.auth);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [tabValue, setTabValue] = useState(0);
  const [fleetDetails, setFleetDetails] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [pageLoad1, setPageLoad1] = useState(false);
  // const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [tyreCompositionList, settyreCompositionList] = useState(false);
  const [tyreBrandList, settyreBrandList] = useState(false);

  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;

  const [activeIndex, setActiveIndex] = useState(null);
  const [scrapListRows, setScrapListRows] = useState({});
  const [issueList, setIssueList] = useState(false);

  const [reportData, setReportData] = useState([]);
  const [reportLoader, setReportLoader] = useState(false);
  const [visible, setVisible] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [Nsd6Display, setNsd6Display] = useState(true);

  useEffect(() => {
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Scrap Analysis (NSD > 6 mm)") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              setNsd6Display(v?.isActive);
          }
        });
      });
    }
  }, [permissionData]);

  useEffect(() => {
    setPageLoad(true);
    getFleetDetails();
    getScrapGraphData();
  }, []);

  useEffect(() => {
    setPageLoad1(true);
    getscrapDetailsList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [pageLoad]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me_again");
    section.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  }, [pageNo]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(null);
  }, [pageNo, record, filter]);

  useEffect(() => {
    filter === "" && isEmpty(searchFilter) && getscrapDetailsList(false);
  }, [filter, searchFilter]);

  const getFleetDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetdetails}?id=${fleetData.fleet_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setFleetDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Scrap Analysis`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getscrapDetailsList(bool, v) {
    setActiveIndex(null);
    v && setDownloadLoader(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.scrapdetails
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : searchFilter
        }&fleet_id=${fleetData?.fleet_id}&filter=${filter}&is_download=${
          v ? v : ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadScrapData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setScrapListRows(listObj);
          }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Scrap Summary List");
      setPageLoad1(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function downloadScrapData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.created_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const nsdArr = row?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.tyre_pattern || "-",
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating_id || "-",
        "Tyre Issue": row?.issue?.toString() || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(row?.avg_nsd) || "-",
        Remarks: row?.remark || "-",
        "Added On": timestamp ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Scrap Summary List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Scrap Summary List.xlsx");
  }

  async function getNsdReport(fromDate, toDate) {
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";

    try {
      const response = await getApiData(
        `${Setting.endpoints.scrapnsdreport}?fleet_id=${fleetData.fleet_id}&from_date=${from_date}&to_date=${to_date}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReport(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
      }
      auditSave("NSD > 6 mm");
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error=====>>>>>", error);
    }
  }
  function downloadReport(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.added_date;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_descryption || "-",
        "Tyre Serial No.": item?.tyre_serial_no || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tread Pattern": item?.tread_pattern || "-",
        "Tyre Issue": item?.tyre_issue.toString() || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(item?.avg_nsd) || "-",
        "Added On": timestamp ? displayDate : "-",
        "Added By": item?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "NSD > 6 mm");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "NSD > 6 mm.xlsx");
  }

  function getDate(value) {
    const timestamp = value;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return <Typography>{timestamp ? displayDate : ""}</Typography>;
  }

  const nsd = (index) => {
    const nsdArr = scrapListRows?.data[index]?.current_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return (
      <Typography>
        {`${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
          groove2 !== 0 ? groove2.toFixed(2) : " - "
        }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
          groove4 !== 0 ? groove4.toFixed(2) : " - "
        } mm`}
      </Typography>
    );
  };

  const issue = (index) => {
    let array = scrapListRows?.data[index]?.issue;
    return (
      <div
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {array.map((items, index) => {
          const isTwo = index <= 1;
          return isTwo ? (
            <Typography>
              {items}
              {index !== array.length - 1 ? ", " : ""}
            </Typography>
          ) : null;
        })}
        {array.length > 2 && (
          <span style={{ color: color.placeholder }}>{`+ ${
            array.length - 2
          } more`}</span>
        )}
      </div>
    );
  };

  function creatScrapGraph(data) {
    const dummy_Arr = [["", "data"]];

    data.map((item) => {
      const arr = [item?.issue, Number(item?.count)];
      dummy_Arr.push(arr);
    });
    setReportData(dummy_Arr);
    setReportLoader(false);
  }

  async function getScrapGraphData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.scrapIssueReport}?fleet_id=${fleetData.fleet_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          creatScrapGraph(response?.data);
        }
      } else {
        toast.error(response?.message);
        setReportLoader(false);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }
  function printDocument() {
    const note = document.getElementById("downlaodChart");
    note.style.width = "1500px";
    note.style.overflowX = "unset";
    downloadCanvas("downlaodChart");
  }

  function downloadCanvas(id) {
    const input = document.getElementById(id);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var a = document.createElement("a"); //Create <a>
      a.href = imgData; //Image Base64 Goes here
      a.download = "Scrap Reports.png"; //File name Here
      a.click(); //Downloaded file
    });

    resetStyle(id);
  }

  function resetStyle(id) {
    const note = document.getElementById(id);
    note.style.width = "100%";
    note.style.overflowX = "scroll";
  }

  function resetFilterData() {
    setSearchFilter("");
    handleFilter("");
    handlePageNo(1);
  }

  return (
    <>
      {isTablet ? (
        <>
          <Box id="jump_to_me">
            <Button
              style={{ backgroundColor: color.secondary, marginBottom: "10px" }}
              onClick={() => {
                handleClick("cancel");
              }}
            >
              Back
            </Button>
          </Box>
          <div className={styles.container}>
            <Box
              style={{
                backgroundColor: color.primary,
                color: color.white,
                padding: "18px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography fontWeight={"bold"}>Fleet Details</Typography>
            </Box>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : (
              <div style={{ padding: 20 }}>
                <Table>
                  <TableRow>
                    <TableCell variant="head">Name</TableCell>
                    <TableCell>{fleetDetails?.name || "-" || "-"}</TableCell>
                    <TableCell variant="head">Owner</TableCell>
                    <TableCell>
                      {fleetDetails?.owner_name || "-" || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Fleet Code</TableCell>
                    <TableCell>{fleetDetails?.code || "-" || "-"}</TableCell>
                    <TableCell variant="head">Owner’s No.</TableCell>
                    <TableCell>
                      {fleetDetails?.owner_phone || "-" || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Location</TableCell>
                    <TableCell>{fleetDetails?.city || "-" || "-"}</TableCell>
                    <TableCell variant="head">Manager</TableCell>
                    <TableCell>
                      {fleetDetails?.manager_name || "-" || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">No. of Scrap Tyres </TableCell>
                    <TableCell>
                      {fleetDetails?.no_of_scrap_tyres || "-" || "-"}
                    </TableCell>
                    <TableCell variant="head">Manager’s No.</TableCell>
                    <TableCell>
                      {fleetDetails?.manager_phone || "-" || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Analyzed By</TableCell>
                    <TableCell>
                      {fleetDetails?.analyzed_by || "-" || "-"}
                    </TableCell>
                    <TableCell variant="head">Employee Code</TableCell>
                    <TableCell>
                      {fleetDetails?.sales_employee_code || "-"}
                    </TableCell>
                  </TableRow>
                </Table>
              </div>
            )}
          </div>
          <div
            className={styles.container}
            style={{ marginTop: 20, padding: 20 }}
          >
            <Grid
              id="jump_to_me_again"
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ height: 40 }}
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="tableTitle">Scrap Details</Typography>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  justifyContent: sm ? "unset" : "flex-end",
                }}
              >
                {tabValue === 0 && (
                  <>
                    <Button
                      style={{ marginLeft: 10 }}
                      variant="contained"
                      onClick={() => {
                        handleClick("addScrapTyre", {}, "details");
                      }}
                    >
                      Add Scrap Tyre
                    </Button>

                    <Button
                      style={{
                        marginLeft: 10,
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                      }}
                      disabled={isOnline ? false : true}
                      onClick={() => {
                        getscrapDetailsList(false, 1);
                      }}
                    >
                      {downloadLoader ? (
                        <CircularProgress
                          style={{ color: color.white }}
                          size={24}
                        />
                      ) : (
                        <img src={Images.Mdownload} alt="download" />
                      )}
                    </Button>
                  </>
                )}
                <Tooltip title="Tabs" placement="bottom" arrow>
                  <Button
                    style={{
                      marginLeft: 10,
                    }}
                    onClick={(event) => {
                      setAnchorEl(event.currentTarget);
                    }}
                  >
                    <img src={Images.tabs} alt="" />
                  </Button>
                </Tooltip>
                <Popover
                  className="popnoti"
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    selected={tabValue === 0}
                    onClick={() => {
                      handlePageNo(1);
                      handleRecord(10);
                      handleFilter("");
                      setAnchorEl(null);
                      setTabValue(0);
                    }}
                  >
                    Summary
                  </ListItemButton>
                  <ListItemButton
                    selected={tabValue === 1}
                    onClick={() => {
                      setAnchorEl(null);
                      setTabValue(1);
                    }}
                  >
                    Reports
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
            {tabValue === 0 ? (
              <div style={{ marginTop: 10 }}>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center"
                  wrap="nowrap"
                >
                  <Grid
                    container
                    item
                    md={8}
                    gap={1}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyConten: "space-between",
                    }}
                    wrap="nowrap"
                  >
                    <Grid item sm={3} md={5}>
                      <Select
                        fullWidth
                        placeholder="Filter"
                        value={filter}
                        onChange={(v) => {
                          handlePageNo(1);
                          handleFilter(v.target.value);
                        }}
                        onOpen={() => {
                          if (isEmpty(filter.toString())) {
                            settyreBrandList(false);
                            settyreCompositionList(false);
                            setIssueList(false);
                          }
                        }}
                        displayEmpty
                        IconComponent={FilterAlt}
                        style={filter === "" ? { color: "#A2A2A2" } : {}}
                        classes={{
                          iconOpen: styles.iconOpen,
                        }}
                      >
                        <MenuItem
                          value={""}
                          hidden
                          selected
                          style={{ minWidth: 220 }}
                        >
                          Filter
                        </MenuItem>
                        <ListItemButton
                          onClick={() => {
                            settyreBrandList(!tyreBrandList);
                            settyreCompositionList(false);
                            setIssueList(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Tyre Brand</Typography>
                          {tyreBrandList ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </ListItemButton>
                        {dropDownData.tyre_brand.map((item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: tyreBrandList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                        <ListItemButton
                          onClick={() => {
                            settyreCompositionList(!tyreCompositionList);
                            settyreBrandList(false);
                            setIssueList(false);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Tyre Composition</Typography>
                          {tyreCompositionList ? (
                            <ExpandLessIcon />
                          ) : (
                            <ExpandMoreIcon />
                          )}
                        </ListItemButton>
                        {dropDownData.tyre_composition.map((item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: tyreCompositionList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        })}
                        <ListItemButton
                          onClick={() => {
                            settyreCompositionList(false);
                            settyreBrandList(false);
                            setIssueList(!issueList);
                          }}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Typography>Issue</Typography>
                          {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                        </ListItemButton>
                        {dropDownData.tyre_issue_scrap_analysis.map(
                          (item, index) => {
                            return (
                              <MenuItem
                                style={{
                                  display: issueList ? "flex" : "none",
                                }}
                                key={index}
                                value={item?.id}
                              >
                                {item?.label}
                              </MenuItem>
                            );
                          }
                        )}
                      </Select>
                    </Grid>
                    <Grid item sm={8} md={8}>
                      <TextField
                        fullWidth
                        placeholder="Search"
                        className={styles.inputFieldStyle}
                        value={searchFilter}
                        onChange={(v) => {
                          isEmpty(v.target.value) && getscrapDetailsList(true);
                          setSearchFilter(v?.target?.value);
                        }}
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            if (!isEmpty(searchFilter)) {
                              handlePageNo(1);
                              getscrapDetailsList(false);
                            }
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ lineHeight: 0 }}>
                                <Search style={{ fontSize: 20 }} />
                              </div>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ lineHeight: 0, cursor: "pointer" }}>
                                <Tooltip
                                  title="Search by Tyre Serial No., Item Description, Avg Current NSD and Added By"
                                  placement="bottom"
                                  arrow
                                >
                                  <InfoIcon style={{ fontSize: 20 }} />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item md={2}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: color.secondary }}
                        onClick={() => {
                          if (!isEmpty(searchFilter)) {
                            getscrapDetailsList(false);
                          }
                        }}
                      >
                        <SearchOutlined />
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        variant="contained"
                        onClick={resetFilterData}
                        disabled={!searchFilter && !filter}
                      >
                        <ResetIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    md={4}
                    wrap="nowrap"
                    style={{
                      display: "flex",
                      justifyContent: "flex-end",
                    }}
                  >
                    <Tooltip title="Records" placement="bottom" arrow>
                      <Grid
                        item
                        md={3}
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Select
                          fullWidth
                          value={record}
                          onChange={(v) => {
                            handlePageNo(1);
                            handleRecord(v.target.value);
                          }}
                          style={{ marginLeft: 10 }}
                        >
                          <MenuItem value={10}>10</MenuItem>
                          <MenuItem value={25}>25</MenuItem>
                          <MenuItem value={50}>50</MenuItem>
                          <MenuItem value={100}>100</MenuItem>
                        </Select>
                      </Grid>
                    </Tooltip>
                  </Grid>
                </Grid>
                {pageLoad1 ? (
                  <div className={styles.dataMain}>
                    <CircularProgress style={{ color: color.primary }} />
                  </div>
                ) : isArray(scrapListRows?.data) &&
                  !isEmpty(scrapListRows?.data) ? (
                  <>
                    {scrapListRows?.data.map((item, index) => {
                      return (
                        <Grid
                          container
                          style={{
                            border: `1px solid ${color.bordercolor}`,
                            boxShadow: color.shadow,
                            padding: 10,
                            marginTop: 10,
                            overflow: "auto",
                          }}
                        >
                          <Grid item container>
                            <Table className={styles.customtable}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tyre Brand
                                  </TableCell>
                                  <TableCell variant="head">
                                    Item Description
                                  </TableCell>
                                  <TableCell variant="head">
                                    Tyre Serial No.
                                  </TableCell>
                                  <TableCell variant="head">
                                    Tyre Composition
                                  </TableCell>
                                  <TableCell variant="head">
                                    Ply Rating
                                  </TableCell>
                                </TableRow>
                              </TableHead>

                              <TableRow>
                                <TableCell>{item?.tyre_brand || "-"}</TableCell>
                                <TableCell>
                                  {item?.tyre_pattern || "-"}
                                </TableCell>
                                <TableCell>
                                  {item?.tyre_serial_no || "-"}
                                </TableCell>
                                <TableCell>
                                  {item?.tyre_composition || "-"}
                                </TableCell>
                                <TableCell>
                                  {item?.ply_rating_id || "-"}
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Divider
                                    variant="fullWidth"
                                    style={{
                                      marginTop: 10,
                                      marginBottom: 10,
                                      marginRight: 20,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Tyre Issue
                                  </TableCell>
                                  <TableCell colSpan={2} variant="head">
                                    Current NSD
                                  </TableCell>
                                  <TableCell variant="head">Avg. NSD</TableCell>
                                  <TableCell variant="head">Remarks</TableCell>
                                </TableRow>
                              </TableHead>

                              <TableRow>
                                <TableCell>{issue(index) || "-"}</TableCell>
                                <TableCell colSpan={2}>{nsd(index)}</TableCell>
                                <TableCell>
                                  {`${item?.avg_nsd} mm` || "-"}
                                </TableCell>
                                <TableCell>{item?.remark || "-"}</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell colSpan={5}>
                                  <Divider
                                    variant="fullWidth"
                                    style={{
                                      marginTop: 10,
                                      marginBottom: 10,
                                      marginRight: 20,
                                    }}
                                  />
                                </TableCell>
                              </TableRow>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">Added On</TableCell>
                                  <TableCell variant="head">Added By</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableRow>
                                <TableCell>
                                  {getDate(item?.capture_date) || "-"}
                                </TableCell>
                                <TableCell>{item?.added_by || "-"}</TableCell>
                              </TableRow>
                              <TableRow>
                                <Button
                                  style={{
                                    backgroundColor: color.primary,
                                    margin: "20px 5px",
                                  }}
                                  onClick={() => {
                                    handleClick(
                                      "editScrapTyre",
                                      item,
                                      "details"
                                    );
                                  }}
                                >
                                  <Edit />
                                </Button>
                              </TableRow>
                            </Table>
                          </Grid>
                        </Grid>
                      );
                    })}
                    <div
                      style={{
                        display: "flex",
                        padding: 20,
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Pagination
                        count={Math.ceil(scrapListRows?.pagination?.totalPage)}
                        defaultPage={pageNo}
                        boundaryCount={2}
                        siblingCount={0}
                        variant="outlined"
                        shape="rounded"
                        onChange={(v, e) => {
                          handlePageNo(e);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <div className={styles.dataMain}>
                    <Typography>No Data</Typography>
                  </div>
                )}
              </div>
            ) : tabValue === 1 ? (
              <div>
                {Nsd6Display && (
                  <>
                    <Grid
                      container
                      style={{
                        margin: "25px 0",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid item>
                        <Typography>{`NSD > 6 mm - ${fleetData?.name}`}</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contined"
                          style={{ backgroundColor: color.secondary }}
                          onClick={() => setVisible(true)}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )}

                {reportLoader ? (
                  <div className={styles.dataMain}>
                    <CircularProgress style={{ color: color.primary }} />
                  </div>
                ) : isArray(reportData) && !isEmpty(reportData) ? (
                  <>
                    <Grid container>
                      <Button
                        variant="contained"
                        onClick={() => {
                          auditSave("Scrap Report Graph");
                          printDocument();
                        }}
                        style={{
                          background: color.secondary,
                          marginLeft: "auto",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item container>
                      <div
                        id="downlaodChart"
                        style={{
                          width: "100%",
                          height: "100%",
                          overflowX: "scroll",
                        }}
                      >
                        <div style={{ minHeight: "40px" }}>
                          <Typography
                            variant="tableTitle"
                            style={{ marginBottom: 20 }}
                          >
                            {`Scrap Analysis  - ${fleetData?.name}`}
                          </Typography>
                        </div>
                        <Chart
                          chartType="Bar"
                          data={reportData}
                          width="1500px"
                          height="98%"
                          options={{
                            colors: ["#32C5D2"],
                            legend: { position: "none" },
                          }}
                        />
                      </div>
                    </Grid>
                  </>
                ) : (
                  <div className={styles.dataMain}>
                    <Typography>No Data</Typography>
                  </div>
                )}
              </div>
            ) : null}
            <CDateRange
              disableFleet
              disableVehicle
              visible={visible}
              handleModal={(
                type,
                fleetIds,
                vehicleIds,
                fromDate,
                toDate,
                isAll
              ) => {
                if (type === "download") {
                  getNsdReport(fromDate, toDate);
                }
                setVisible(false);
              }}
            />
          </div>
        </>
      ) : (
        <>
          <Box id="jump_to_me">
            {isKeyboardOpen && !isTablet ? null : (
              <Button
                style={{
                  backgroundColor: color.secondary,
                  position: "absolute",
                  top: 10,
                  zIndex: 10,
                }}
                onClick={() => {
                  handleClick("cancel");
                }}
              >
                <ArrowBackOutlined />
              </Button>
            )}
          </Box>
          <div
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 15,
            }}
          >
            <Typography fontWeight={"bold"}>Fleet Details</Typography>
          </div>
          <div
            className={styles.container}
            style={{
              padding: 10,
            }}
          >
            <Grid
              container
              style={{
                border: `1px solid ${color.bordercolor}`,
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 14px 0",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Name</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Owner</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0 14px 10px",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography>{fleetDetails?.name || "-"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{fleetDetails?.owner_name || "-"}</Typography>
                </Grid>
              </Grid>

              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  background: "#FBF8F8",
                  padding: "10px 14px 0",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Fleet Code</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Owner’s No.</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0 14px 10px",
                  background: "#FBF8F8",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography>{fleetDetails?.code || "-"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{fleetDetails?.owner_phone || "-"}</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 14px 0",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Location</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Manager</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0 14px 10px",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography>{fleetDetails?.city || "-"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{fleetDetails?.manager_name || "-"}</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 14px 0",
                  background: "#FBF8F8",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>
                    No. of Scrap Tyres
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Manager’s No.</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0 14px 10px",
                  background: "#FBF8F8",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography>
                    {fleetDetails?.no_of_scrap_tyres || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>{fleetDetails?.manager_phone || "-"}</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "10px 14px 0",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Analyzed By</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography fontWeight={"bold"}>Employee Code</Typography>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  flexDirection: "row",
                  padding: "0 14px 10px",
                }}
                xs={12}
              >
                <Grid item xs={6}>
                  <Typography>{fleetDetails?.analyzed_by || "-"}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography>
                    {fleetDetails?.sales_employee_code || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </div>
          <div style={{ padding: 10 }} className={styles.container}>
            <Grid
              id="jump_to_me_again"
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              style={{ height: 40 }}
            >
              <Grid item xs={6} sm={6} display="flex" alignItems="center">
                <Typography variant="tableTitle">
                  {tabValue === 1 ? "Reports" : "Scrap Details"}
                </Typography>
              </Grid>
              <Grid item xs={6} display="flex" justifyContent="flex-end">
                {tabValue === 0 && (
                  <>
                    <Button
                      variant="contained"
                      onClick={() => {
                        handleClick("addScrapTyre", {}, "detils");
                      }}
                    >
                      Add Scrap Tyre
                    </Button>
                    <Button
                      style={{
                        marginLeft: 10,
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                      }}
                      disabled={isOnline ? false : true}
                      onClick={() => {
                        if (tabValue === 0) {
                          getscrapDetailsList(false, 1);
                        }
                      }}
                    >
                      {downloadLoader ? (
                        <CircularProgress
                          style={{ color: color.white }}
                          size={24}
                        />
                      ) : (
                        <img src={Images.Mdownload} alt="download" />
                      )}
                    </Button>
                  </>
                )}
                <Button
                  variant="contained"
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                  style={{
                    backgroundColor: "#BBBBBB",
                    minWidth: "unset",
                    color: "#000000",
                    marginLeft: 10,
                  }}
                >
                  <MoreVert />
                </Button>
                <Popover
                  className="popnoti"
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    selected={tabValue === 0}
                    onClick={() => {
                      handlePageNo(1);
                      handleRecord(10);
                      handleFilter("");
                      setAnchorEl(null);
                      setTabValue(0);
                    }}
                  >
                    Summary
                  </ListItemButton>
                  <ListItemButton
                    selected={tabValue === 1}
                    onClick={() => {
                      setAnchorEl(null);
                      setTabValue(1);
                    }}
                  >
                    Reports
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
            {tabValue === 0 ? (
              <>
                <Grid
                  container
                  marginTop={"15px"}
                  justifyContent="space-between"
                  wrap="nowrap"
                  gap={1}
                >
                  <Grid item>
                    <Select
                      placeholder="Filter"
                      value={filter}
                      onChange={(v) => {
                        handlePageNo(1);
                        handleFilter(v.target.value);
                      }}
                      onOpen={() => {
                        if (isEmpty(filter.toString())) {
                          settyreBrandList(false);
                          settyreCompositionList(false);
                          setIssueList(false);
                        }
                      }}
                      displayEmpty
                      IconComponent={FilterAlt}
                      style={filter === "" ? { color: "#A2A2A2" } : {}}
                      classes={{
                        iconOpen: styles.iconOpen,
                      }}
                    >
                      <MenuItem
                        value={""}
                        hidden
                        selected
                        style={{ minWidth: 220 }}
                      >
                        Filter
                      </MenuItem>
                      <ListItemButton
                        onClick={() => {
                          settyreBrandList(!tyreBrandList);
                          settyreCompositionList(false);
                          setIssueList(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Tyre Brand</Typography>
                        {tyreBrandList ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItemButton>
                      {dropDownData.tyre_brand.map((item, index) => {
                        return (
                          <MenuItem
                            style={{
                              display: tyreBrandList ? "flex" : "none",
                            }}
                            key={index}
                            value={item?.id}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                      <ListItemButton
                        onClick={() => {
                          settyreCompositionList(!tyreCompositionList);
                          settyreBrandList(false);
                          setIssueList(false);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Tyre Composition</Typography>
                        {tyreCompositionList ? (
                          <ExpandLessIcon />
                        ) : (
                          <ExpandMoreIcon />
                        )}
                      </ListItemButton>
                      {dropDownData.tyre_composition.map((item, index) => {
                        return (
                          <MenuItem
                            style={{
                              display: tyreCompositionList ? "flex" : "none",
                            }}
                            key={index}
                            value={item?.id}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                      <ListItemButton
                        onClick={() => {
                          settyreCompositionList(false);
                          settyreBrandList(false);
                          setIssueList(!issueList);
                        }}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Issue</Typography>
                        {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                      </ListItemButton>
                      {dropDownData.tyre_issue_scrap_analysis.map(
                        (item, index) => {
                          return (
                            <MenuItem
                              style={{
                                display: issueList ? "flex" : "none",
                              }}
                              key={index}
                              value={item?.id}
                            >
                              {item?.label}
                            </MenuItem>
                          );
                        }
                      )}
                    </Select>
                  </Grid>
                  <Grid item>
                    <Select
                      value={record}
                      onChange={(v) => {
                        handlePageNo(1);
                        handleRecord(v.target.value);
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Grid>
                </Grid>
                <div>
                  <Grid
                    container
                    wrap="nowrap"
                    marginTop={"10px"}
                    gap={1}
                    alignItems="center"
                  >
                    <Grid item xs={10}>
                      <TextField
                        fullWidth
                        placeholder="Search"
                        className={styles.inputFieldStyle}
                        value={searchFilter}
                        onChange={(v) => {
                          isEmpty(v.target.value) && getscrapDetailsList(true);
                          setSearchFilter(v?.target?.value);
                        }}
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            if (!isEmpty(searchFilter)) {
                              handlePageNo(1);
                              getscrapDetailsList(false);
                            }
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <div style={{ lineHeight: 0 }}>
                                <Search style={{ fontSize: 20 }} />
                              </div>
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <div style={{ lineHeight: 0, cursor: "pointer" }}>
                                <Tooltip
                                  title="Search by Tyre Serial No., Item Description, Avg Current NSD and Added By"
                                  placement="bottom"
                                  arrow
                                >
                                  <InfoIcon style={{ fontSize: 20 }} />
                                </Tooltip>
                              </div>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                    <Grid item xs={1.6}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: color.secondary }}
                        onClick={() => {
                          if (!isEmpty(searchFilter)) {
                            getscrapDetailsList(false);
                          }
                        }}
                      >
                        <SearchOutlined />
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        variant="contained"
                        onClick={resetFilterData}
                        disabled={!searchFilter && !filter}
                      >
                        <ResetIcon />
                      </Button>
                    </Grid>
                  </Grid>
                  {pageLoad1 ? (
                    <div className={styles.dataMain}>
                      <CircularProgress style={{ color: color.primary }} />
                    </div>
                  ) : isArray(scrapListRows?.data) &&
                    !isEmpty(scrapListRows?.data) ? (
                    <>
                      {scrapListRows?.data.map((item, index) => {
                        return (
                          <div style={{ position: "relative" }}>
                            <Grid
                              id={`jump_to_me${index}`}
                              container
                              style={{
                                border: `1px solid ${color.bordercolor}`,
                                boxShadow: color.shadow,
                                // height: activeIndex === index ? "auto" : 130,
                                overflow: "hidden",
                                marginTop: 20,
                              }}
                            >
                              {activeIndex !== index ? (
                                <>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "10px 14px 0",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Tyre Brand
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Item Description
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "0 14px 10px",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item?.tyre_brand || "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item?.tyre_pattern || "-"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      background: "#EEF8FF",
                                      padding: "10px 14px 0",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Tyre Serial No.
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Tyre Issue
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "0 14px 12px",
                                      background: "#EEF8FF",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item?.tyre_serial_no || "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>
                                        {issue(index) || "-"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </>
                              ) : (
                                <>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "10px 14px 0",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Tyre Brand
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Item Description
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "0 14px 10px",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item?.tyre_brand || "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item?.tyre_pattern || "-"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      background: "#EEF8FF",
                                      padding: "10px 14px 0",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Tyre Serial No.
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Tyre Issue
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "0 14px 12px",
                                      background: "#EEF8FF",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item?.tyre_serial_no || "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>
                                        {issue(index) || "-"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "15px 14px 0",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Ply Rating
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Tyre Composition
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "0 14px 10px",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Grid item xs={6}>
                                        <Typography>
                                          {item?.ply_rating_id || "-"}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Grid item xs={6}>
                                        <Typography>
                                          {item?.tyre_composition || "-"}
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "10px 14px 0",
                                      background: "#EEF8FF",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Current NSD
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Avg. NSD
                                      </Typography>
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "0 14px 10px",
                                      background: "#EEF8FF",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography>
                                        {nsd(index) || "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography>
                                        {`${item?.avg_nsd} mm` || "-"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "10px 14px 0",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Remarks
                                      </Typography>
                                    </Grid>
                                    <Grid>
                                      <Grid item xs={6}>
                                        <Typography fontWeight={"bold"}>
                                          Added On
                                        </Typography>
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "0 14px 10px",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item?.remark || "-"}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={6}>
                                      {getDate(item?.capture_date) || "-"}
                                    </Grid>
                                  </Grid>

                                  <Grid
                                    item
                                    container
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "10px 14px 0",
                                      background: "#EEF8FF",
                                      position: "relative",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}>
                                      <Button
                                        style={{
                                          backgroundColor: color.primary,
                                          position: "absolute",
                                          left: 20,
                                        }}
                                        onClick={() => {
                                          handleClick(
                                            "editScrapTyre",
                                            item,
                                            "details"
                                          );
                                        }}
                                      >
                                        <Edit />
                                      </Button>
                                    </Grid>
                                    <Grid item xs={6}>
                                      <Typography fontWeight={"bold"}>
                                        Added By
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                  <Grid
                                    item
                                    style={{
                                      display: "flex",
                                      flexDirection: "row",
                                      padding: "0 14px 10px",
                                      background: "#EEF8FF",
                                    }}
                                    xs={12}
                                  >
                                    <Grid item xs={6}></Grid>
                                    <Grid item xs={6}>
                                      <Typography>
                                        {item.added_by || "-"}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </>
                              )}
                              <div
                                style={{
                                  zIindex: 10,
                                  color: color.primary,
                                  display: "flex",
                                  justifyContent: "center",
                                  position: "absolute",
                                  bottom: -18,
                                  left: "45%",
                                }}
                                onClick={() => {
                                  activeIndex === index
                                    ? setActiveIndex(null)
                                    : setActiveIndex(index);
                                }}
                              >
                                {activeIndex === index ? (
                                  <div style={{ transform: "rotate(180deg)" }}>
                                    <TableToggle />
                                  </div>
                                ) : (
                                  <TableToggle />
                                )}
                              </div>
                            </Grid>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          display: "flex",
                          marginTop: 30,
                          marginBottom: 20,
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <Pagination
                          count={Math.ceil(
                            scrapListRows?.pagination?.totalPage
                          )}
                          defaultPage={pageNo}
                          boundaryCount={1}
                          siblingCount={0}
                          variant="outlined"
                          shape="rounded"
                          onChange={(v, e) => {
                            handlePageNo(e);
                          }}
                        />
                      </div>
                    </>
                  ) : (
                    <div className={styles.dataMain}>
                      <Typography>No Data</Typography>
                    </div>
                  )}
                </div>
              </>
            ) : null}
            {tabValue === 1 ? (
              <div>
                {Nsd6Display && (
                  <>
                    <Grid
                      container
                      style={{
                        display: "flex",
                        margin: "20px 0",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      wrap="nowrap"
                      gap={1}
                    >
                      <Grid item>
                        <Typography>{`NSD > 6 mm - ${fleetData?.name}`}</Typography>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          style={{ backgroundColor: color.secondary }}
                          onClick={() => setVisible(true)}
                        >
                          Download
                        </Button>
                      </Grid>
                    </Grid>
                    <Divider />
                  </>
                )}

                {reportLoader ? (
                  <div className={styles.dataMain}>
                    <CircularProgress style={{ color: color.primary }} />
                  </div>
                ) : isArray(reportData) && !isEmpty(reportData) ? (
                  <>
                    <Grid container>
                      <Button
                        variant="contained"
                        onClick={() => {
                          auditSave("Scrap Report Graph");
                          printDocument();
                        }}
                        style={{
                          background: color.secondary,
                          marginLeft: "auto",
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item container>
                      <div
                        id="downlaodChart"
                        style={{
                          width: "100%",
                          height: "100%",
                          overflowX: "scroll",
                        }}
                      >
                        <div style={{ minHeight: "40px" }}>
                          <Typography
                            variant="tableTitle"
                            style={{ marginBottom: 20 }}
                          >
                            {`Scrap Analysis  - ${fleetData?.name}`}
                          </Typography>
                        </div>
                        <Chart
                          chartType="Bar"
                          data={reportData}
                          width="1500px"
                          height="98%"
                          options={{
                            colors: ["#32C5D2"],
                            legend: { position: "none" },
                          }}
                        />
                      </div>
                    </Grid>
                  </>
                ) : (
                  <div className={styles.dataMain}>
                    <Typography>No Data</Typography>
                  </div>
                )}
                <CDateRange
                  disableFleet
                  disableVehicle
                  visible={visible}
                  handleModal={(
                    type,
                    fleetIds,
                    vehicleIds,
                    fromDate,
                    toDate,
                    isAll
                  ) => {
                    if (type === "download") {
                      getNsdReport(fromDate, toDate);
                    }
                    setVisible(false);
                  }}
                />
              </div>
            ) : null}
          </div>
        </>
      )}
    </>
  );
}
