const actions = {
  SET_USER_DATA: "auth/SET_USER_DATA",
  CLEAR_ALL_STORAGE_DATA: "auth/CLEAR_ALL_STORAGE_DATA",
  SET_USER_UUID: "auth/SET_USER_UUID",
  SET_TOKEN: "auth/SET_TOKEN",
  DROPDOWN_DATA: "auth/DROPDOWN_DATA",
  SET_DISPLAY_NOTIFICATION_POP_UP: "auth/SET_DISPLAY_NOTIFICATION_POP_UP",
  SET_NOTI_DATA: "auth/SET_NOTI_DATA",
  FLEET_DATA: "auth/FLEET_DATA",
  ADD_INSPECTION_DATA: "auth/ADD_INSPECTION_DATA",
  EDIT_INVENTORY_DATA: "auth/EDIT_INVENTORY_DATA",
  EDIT_INSPECTION_DATA: "auth/EDIT_INSPECTION_DATA",
  DUMMY_INSPECTION_DATA: "auth/DUMMY_INSPECTION_DATA",
  CHANGE_ARRAY: "auth/CHANGE_ARRAY",
  PERMISSION_DATA: "auth/PERMISSION_DATA",
  ONLINE_STATUS: "auth/ONLINE_STATUS",
  SET_COUNTDOWN: "auth/SET_COUNTDOWN",
  READING: "auth/READING",
  REMARKS: "auth/REMARKS",

  setUserData: (userdata) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_DATA,
      userdata,
    }),

  clearAllData: () => (dispatch) =>
    dispatch({
      type: actions.CLEAR_ALL_STORAGE_DATA,
    }),

  displayNotificationPopUp: (isNotifiy) => (dispatch) =>
    dispatch({
      type: actions.SET_DISPLAY_NOTIFICATION_POP_UP,
      isNotifiy,
    }),

  setNotiData: (notiData) => (dispatch) =>
    dispatch({
      type: actions.SET_NOTI_DATA,
      notiData,
    }),

  setCountDown: (countDown) => (dispatch) =>
    dispatch({
      type: actions.SET_COUNTDOWN,
      countDown,
    }),

  setInspectionData: (inspectionData) => (dispatch) =>
    dispatch({
      type: actions.ADD_INSPECTION_DATA,
      inspectionData,
    }),

  setEditInventoryData: (editInventoryData) => (dispatch) =>
    dispatch({
      type: actions.EDIT_INVENTORY_DATA,
      editInventoryData,
    }),

  setListRows: (List) => (dispatch) =>
    dispatch({
      type: actions.EDIT_INSPECTION_DATA,
      List,
    }),

  setDummyListArray: (dummyListArray) => (dispatch) =>
    dispatch({
      type: actions.DUMMY_INSPECTION_DATA,
      dummyListArray,
    }),

  setReduxChangeArray: (reduxChangeArray) => (dispatch) =>
    dispatch({
      type: actions.CHANGE_ARRAY,
      reduxChangeArray,
    }),

  setPermissionData: (permissionData) => (dispatch) =>
    dispatch({
      type: actions.PERMISSION_DATA,
      permissionData,
    }),

  setOnlineStatus: (isOnline) => (dispatch) =>
    dispatch({
      type: actions.ONLINE_STATUS,
      isOnline,
    }),

  setUserUUID: (useruuid) => (dispatch) =>
    dispatch({
      type: actions.SET_USER_UUID,
      useruuid,
    }),

  setToken: (token) => (dispatch) =>
    dispatch({
      type: actions.SET_TOKEN,
      token,
    }),

  setDropDownData: (dropDownData) => (dispatch) =>
    dispatch({
      type: actions.DROPDOWN_DATA,
      dropDownData,
    }),

  setFleetData: (fleetData) => (dispatch) =>
    dispatch({
      type: actions.FLEET_DATA,
      fleetData,
    }),
  setInspectionReading: (inspectionReading) => (dispatch) =>
    dispatch({
      type: actions.READING,
      inspectionReading,
    }),
  setInspectionRemarks: (inspectionRemarks) => (dispatch) => {
    dispatch({
      type: actions.REMARKS,
      inspectionRemarks,
    });
  },
};

export default actions;
