import React, { useState, useEffect } from "react";
import {
  Button,
  Typography,
  Grid,
  FormControl,
  CircularProgress,
  FormHelperText,
  InputAdornment,
  IconButton,
  OutlinedInput,
} from "@mui/material";
import { isEmpty } from "lodash";
import { color } from "../../Config/theme.js";
import useStyles from "./styles.js";
import { Setting } from "../../Utils/Setting";
import { getAPIProgressData } from "../../Utils/APIHelper";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useSelector } from "react-redux";
const errorObj = {
  oldPassErr: false,
  newPassErr: false,
  confPassErr: false,
  oldPassMsg: "",
  newPassMsg: "",
  confPassMsg: "",
};

export default function ChangePassword() {
  const styles = useStyles();
  const navigate = useNavigate();

  const [errObj, setErrObj] = useState(errorObj);
  const [loader, setLoader] = useState(false);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [confPass, setConfPass] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showConfPassword, setShowConfPassword] = useState(false);
  const { isOnline } = useSelector((state) => state.auth);
  useEffect(() => {
    document.title = Setting.page_name.ChangePass;
  }, []);

  async function submitPassword() {
    setLoader(true);
    let endPoints = Setting.endpoints.changePassword;
    const params = {
      "ChangePasswordForm[cpassword]": oldPass,
      "ChangePasswordForm[password]": confPass,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        navigate("/dashboard");
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  }

  const checkValidation = () => {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(oldPass)) {
      valid = false;
      error.oldPassErr = true;
      error.oldPassMsg = "Please enter Old Password";
    }

    if (isEmpty(newPass)) {
      valid = false;
      error.newPassErr = true;
      error.newPassMsg = "Please enter New Password";
    } else if (newPass.length < 6 || newPass.length > 15) {
      valid = false;
      error.newPassErr = true;
      error.newPassMsg = "The Password length must Between 6 to 15 Character";
    }

    if (isEmpty(confPass)) {
      valid = false;
      error.confPassErr = true;
      error.confPassMsg = "Please enter Confirm Password";
    } else if (confPass !== newPass) {
      valid = false;
      error.confPassErr = true;
      error.confPassMsg =
        "New Password and Confirm Password should be the same. Please try again.";
    }
    setErrObj(error);
    if (valid) {
      clearData();
      submitPassword();
    }
  };

  const clearData = () => {
    setErrObj(errorObj);
    setOldPass("");
    setNewPass("");
    setConfPass("");
  };
  return (
    <div className={styles.card}>
      <FormControl className={styles.container}>
        <Typography fontWeight={"bold"} className={styles.header}>
          Change Password
        </Typography>
        <Grid
          container
          className={styles.row}
          style={{ paddingBottom: errObj.oldPassErr ? 30 : 20 }}
          alignItems="center"
          xs={12}
          md={12}
          lg={12}
        >
          <Grid item xs={12} md={12} lg={2}>
            <Typography className={styles.label}>Old Password :</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={10} className={styles.input}>
            <FormControl fullWidth error={errObj.oldPassErr}>
              <OutlinedInput
                type={showOldPassword ? "text" : "password"}
                value={oldPass}
                className={styles.input}
                placeholder="Enter Current Password"
                error={errObj.oldPassErr}
                helperText={errObj.oldPassErr ? errObj.oldPassMsg : null}
                onChange={(e) => {
                  setOldPass(e.target.value.trim());
                  setErrObj({ ...errObj, oldPassErr: false, oldPassMsg: "" });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowOldPassword(!showOldPassword)}
                    >
                      {!showOldPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errObj.oldPassErr ? (
                <FormHelperText>{errObj.oldPassMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          className={styles.row}
          style={{ paddingBottom: errObj.newPassErr ? 30 : 20 }}
          alignItems="center"
          xs={12}
          md={12}
          lg={12}
        >
          <Grid item xs={12} md={12} lg={2}>
            <Typography className={styles.label}>New Password :</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={10} className={styles.input}>
            <FormControl fullWidth error={errObj.newPassErr}>
              <OutlinedInput
                type={showPassword ? "text" : "password"}
                value={newPass}
                className={styles.input}
                placeholder="Enter New Password"
                onChange={(e) => {
                  setNewPass(e.target.value.trim());
                  setErrObj({ ...errObj, newPassErr: false, newPassMsg: "" });
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {!showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errObj.newPassErr ? (
                <FormHelperText>{errObj.newPassMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ paddingBottom: errObj.confPassErr ? 30 : 20 }}
          className={styles.row}
          alignItems="center"
          xs={12}
          md={12}
          lg={12}
        >
          <Grid item xs={12} md={12} lg={2}>
            <Typography className={styles.label}>Confirm Password :</Typography>
          </Grid>
          <Grid item xs={12} md={12} lg={10} className={styles.input}>
            <FormControl fullWidth error={errObj.confPassErr}>
              <OutlinedInput
                type={showConfPassword ? "text" : "password"}
                value={confPass}
                className={styles.input}
                placeholder="Confirm New Password"
                error={errObj.confPassErr}
                helperText={errObj.confPassErr ? errObj.confPassMsg : null}
                onChange={(e) => {
                  setConfPass(e.target.value.trim());
                  setErrObj({ ...errObj, confPassErr: false, confPassMsg: "" });
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    ev.preventDefault();
                    checkValidation();
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfPassword(!showConfPassword)}
                    >
                      {!showConfPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {errObj.confPassErr ? (
                <FormHelperText>{errObj.confPassMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ alignItems: "center" }}
          justifyContent="center"
          spacing={2}
          disabled={loader}
        >
          <Button
            disabled={isOnline ? false : true}
            onClick={checkValidation}
            style={{
              backgroundColor: isOnline ? color.primary : color.bordercolor,
              marginRight: 10,
              width: 100,
            }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Submit"
            )}
          </Button>
          <Button
            style={{
              backgroundColor: color.secondary,
              width: 100,
            }}
            onClick={() => {
              navigate(-1);
            }}
          >
            Cancel
          </Button>
        </Grid>
      </FormControl>
    </div>
  );
}
