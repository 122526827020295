import React, { useEffect, useState, useRef } from "react";
import useStyles from "./styles.js";
import { color } from "../../../Config/theme";
import { Setting } from "../../../Utils/Setting";
import {
  Breadcrumbs,
  Button,
  Grid,
  Box,
  Link,
  Tab,
  Tabs,
  Typography,
  CircularProgress,
} from "@mui/material";
import LifeHistory from "../../../Components/TyreHistory/LifeHistory/index.js";
import ConditionHistory from "../../../Components/TyreHistory/ConditionHistory/index.js";
import ConditionDetails from "../../../Components/TyreHistory/ConditionDetails/index.js";
import SwitchList from "../../../Components/TyreHistory/SwitchList/index.js";
import SwitchHistory from "../../../Components/TyreHistory/SwitchHistory/index.js";
import TyreDetails from "../../../Components/Fleet/TyreDetails/index.js";
import { useSelector } from "react-redux";
import RemoveTyre from "../../../Components/TyreHistory/RemoveTyre/index.js";
export default function TyreHistory() {
  const styles = useStyles();
  const { isOnline } = useSelector((state) => state.auth);
  const [changeTab, setChangeTab] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [vehicleData, setVehicleData] = useState({});
  const [tyreListData, setTyreListData] = useState({});
  const [from, setFrom] = useState("");

  const switchHistory = useRef();
  const conditionHistory = useRef();
  const lifeHistory = useRef();

  const [pageNoS, setPageNoS] = useState(1);
  const [recordS, setRecordS] = useState(10);

  const [pageNoC, setPageNoC] = useState(1);
  const [recordC, setRecordC] = useState(10);

  const [pageNoL, setPageNoL] = useState(1);
  const [recordL, setRecordL] = useState(10);
  const [downloadLoader, setDownloadLoader] = useState(false);

  const [search, setSearch] = useState("");
  const [searchCH, setSearchCH] = useState("");
  const [searchLH, setSearchLH] = useState("");
  const [datePicker, setDatePicker] = useState(null);

  const [filterLH, setFilterLH] = useState("");

  useEffect(() => {
    document.title = Setting.page_name.TyreHistory;
  }, []);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [changeTab]);

  return (
    <Grid className={styles.card}>
      <Grid container id="jump_to_me">
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator=">">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            {changeTab === "conditonDetails" ||
            changeTab === "switchList" ||
            changeTab === "tyreDetails" ||
            changeTab === "removeTyre" ? (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => setChangeTab("")}
              >
                Tyre History
              </Link>
            ) : (
              <Typography className={styles.linkStyle}>Tyre History</Typography>
            )}
            {(changeTab === "switchList" || from === "switchList") && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setFrom("");
                  setChangeTab("");
                }}
              >
                Rotation History
              </Link>
            )}

            {changeTab === "switchList" && (
              <Typography className={styles.linkStyle}>
                {vehicleData?.fleet_name} / {vehicleData?.vehicle_name}
              </Typography>
            )}
            {from === "switchList" && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setFrom("");
                  setChangeTab("switchList");
                }}
              >
                Rotation List
              </Link>
            )}

            {(changeTab === "conditonDetails" ||
              from === "conditonDetails" ||
              from === "conditionHistory") && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setFrom("");
                  setChangeTab("");
                }}
              >
                Condition History
              </Link>
            )}
            {from === "conditonDetails" && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setFrom("");
                  setChangeTab("conditonDetails");
                }}
              >
                Condition List
              </Link>
            )}
            {changeTab === "conditonDetails" && (
              <Typography className={styles.linkStyle}>
                {vehicleData?.fleet_name} / {vehicleData?.vehicle_no}
              </Typography>
            )}

            {(changeTab === "removeTyre" || from === "lifeHistory") && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setFrom("");
                  setChangeTab("");
                }}
              >
                Life History
              </Link>
            )}
            {changeTab === "removeTyre" && (
              <Typography className={styles.linkStyle}>Remove Tyre</Typography>
            )}

            {changeTab === "tyreDetails" && (
              <Typography className={styles.linkStyle}>
                {tyreListData?.vehicle_no}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "switchList" ? (
        <SwitchList
          handleClick={(v, data) => {
            if (v === "tyreDetails") {
              setFrom("switchList");
              setChangeTab("tyreDetails");
              setTyreListData(data);
            }
          }}
          vehicleData={vehicleData}
        />
      ) : changeTab === "conditonDetails" ? (
        <ConditionDetails
          handleClick={(v, data) => {
            if (v === "tyreDetails") {
              setFrom("conditonDetails");
              setChangeTab("tyreDetails");
              setTyreListData(data);
            }
          }}
          vehicleData={vehicleData}
        />
      ) : changeTab === "tyreDetails" ? (
        <TyreDetails tyreData={tyreListData} from={"life"} />
      ) : changeTab === "removeTyre" ? (
        <RemoveTyre
          from={"life"}
          vehicleDetails={vehicleData}
          handleClick={(v, data) => {
            if (v === "cancel") {
              setChangeTab("");
            }
          }}
        />
      ) : (
        <div className={styles.container}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item xs={12} sm={6}>
              <Typography variant="tableTitle">Tyre History</Typography>
            </Grid>
            <Button
              variant="contained"
              style={{
                marginLeft: 10,
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              onClick={() => {
                if (tabValue === 0) {
                  switchHistory?.current?.downloadSwitchData(1);
                }
                if (tabValue === 1) {
                  conditionHistory?.current?.downloadConditionData(1);
                }
                if (tabValue === 2) {
                  lifeHistory?.current?.downloadLifeData(1);
                }
              }}
              disabled={isOnline ? false : true}
            >
              {downloadLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                "Download"
              )}
            </Button>
          </Grid>
          <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
            <Tabs
              value={tabValue}
              onChange={(v, b) => {
                setPageNoS(1);
                setRecordS(10);
                setPageNoC(1);
                setRecordC(10);
                setPageNoL(1);
                setRecordL(10);
                setTabValue(b);
                setFilterLH("");
              }}
              variant="scrollable"
            >
              <Tab className={styles.tabBtn} label="Rotation History" />
              <Tab className={styles.tabBtn} label="Condition History" />
              <Tab className={styles.tabBtn} label="Life History" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <SwitchHistory
              ref={switchHistory}
              pageNo={pageNoS}
              record={recordS}
              search={search}
              handleSearch={(search) => {
                setSearch(search);
              }}
              handleLoader={(v) => setDownloadLoader(v)}
              handlePageNo={(page) => {
                setPageNoS(page);
              }}
              handleRecord={(rec) => {
                setRecordS(rec);
              }}
              handleClick={(v, data) => {
                if (v === "switchList") {
                  setChangeTab("switchList");
                  setVehicleData(data);
                }
              }}
            />
          )}
          {tabValue === 1 && (
            <ConditionHistory
              ref={conditionHistory}
              pageNo={pageNoC}
              record={recordC}
              search={searchCH}
              dateF={datePicker}
              handleDate={(date) => {
                setDatePicker(date);
              }}
              handleSearch={(search) => {
                setSearchCH(search);
              }}
              handleLoader={(v) => setDownloadLoader(v)}
              handlePageNo={(page) => {
                setPageNoC(page);
              }}
              handleRecord={(rec) => {
                setRecordC(rec);
              }}
              handleClick={(v, data) => {
                if (v === "conditonDetails") {
                  setChangeTab("conditonDetails");
                  setVehicleData(data);
                } else if (v === "tyreDetails") {
                  setFrom("conditionHistory");
                  setChangeTab("tyreDetails");
                  setTyreListData(data);
                }
              }}
            />
          )}
          {tabValue === 2 && (
            <LifeHistory
              ref={lifeHistory}
              pageNo={pageNoL}
              record={recordL}
              filter={filterLH}
              search={searchLH}
              handleSearch={(search) => {
                setSearchLH(search);
              }}
              handleLoader={(v) => setDownloadLoader(v)}
              handlePageNo={(page) => {
                setPageNoL(page);
              }}
              handleRecord={(rec) => {
                setRecordL(rec);
              }}
              handleFilter={(flt) => {
                setFilterLH(flt);
              }}
              handleClick={(v, data) => {
                if (v === "tyreDetails") {
                  setFrom("lifeHistory");
                  setChangeTab("tyreDetails");
                  setTyreListData(data);
                } else if (v === "Edit") {
                  setChangeTab("removeTyre");
                  setVehicleData(data);
                }
              }}
            />
          )}
        </div>
      )}
    </Grid>
  );
}
