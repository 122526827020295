import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import TabScrapAnalysisComponent from "../../../Components/TabScrap/TabScrapAnalysis/index.js";
import TabScrapDetails from "../../../Components/TabScrap/TabScrapDetails/index.js";
import TabAddScrapTyre from "../../../Components/TabScrap/TabAddScrapTyre/index.js";
import { Box } from "@mui/system";
import Images from "../../../Config/Images.js";
import { isMobile, isTablet } from "react-device-detect";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import { useLocation } from "react-router-dom";

export default function TabScrapAnalysis() {
  const location = useLocation();
  const data = location?.state;
  const styles = useStyles();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [fleetData, setFleetData] = useState({});
  const [changeTab, setChangeTab] = useState("");
  const [editScrapTyreData, setEditScrapTyreData] = useState({});

  const [pageNoSA, setPageNoSA] = useState(1);
  const [recordSA, setRecordSA] = useState(10);

  const [pageNoSD, setPageNoSD] = useState(1);
  const [recordSD, setRecordSD] = useState(10);
  const [filterSD, setFilterSD] = useState("");
  const [search, setSearch] = useState("");
  const [datePickerT, setDatePickerT] = useState(null);
  const [datePickerF, setDatePickerF] = useState(null);

  const [from, setFrom] = useState("");

  useEffect(() => {
    document.title = Setting.page_name.ScrapAnalysis;
  }, []);
  return (
    <div className={styles.card}>
      {isTablet
        ? null
        : !isKeyboardOpen &&
          changeTab === "" && (
            <Box
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                height: isMobile ? 30 : 40,
                position: "fixed",
                top: 14,
                left: 20,
                zIndex: 10,
              }}
            >
              <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
            </Box>
          )}
      {changeTab === "addScrapTyre" ? (
        <TabAddScrapTyre
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab(from === "main" ? "" : "scrapDetails");
            }
          }}
          editData={editScrapTyreData}
          fleetDetails={fleetData}
          from={from}
        />
      ) : changeTab === "scrapDetails" ? (
        <TabScrapDetails
          pageNo={pageNoSD}
          record={recordSD}
          filter={filterSD}
          handlePageNo={(page) => {
            setPageNoSD(page);
          }}
          handleRecord={(rec) => {
            setRecordSD(rec);
          }}
          handleFilter={(flt) => {
            setFilterSD(flt);
          }}
          handleClick={(type, data, from) => {
            if (type === "editScrapTyre") {
              setChangeTab("addScrapTyre");
              setEditScrapTyreData(data);
              setFrom("details");
            } else if (type === "addScrapTyre") {
              setChangeTab("addScrapTyre");
              setEditScrapTyreData("");
              setFrom("details");
            } else if (type === "cancel") {
              setChangeTab("");
              setEditScrapTyreData("");
            }
          }}
          fleetData={fleetData}
        />
      ) : (
        <TabScrapAnalysisComponent
          pageNo={pageNoSA}
          record={recordSA}
          handlePageNo={(page) => {
            setPageNoSA(page);
          }}
          handleRecord={(rec) => {
            setRecordSA(rec);
          }}
          search={search}
          dateF={datePickerF}
          dateT={datePickerT}
          handleDateF={(date) => {
            setDatePickerF(date);
          }}
          handleDateT={(date) => {
            setDatePickerT(date);
          }}
          handleSearch={(search) => {
            setSearch(search);
          }}
          handleClick={(type, data) => {
            if (type === "addScrapTyre") {
              setChangeTab("addScrapTyre");
              setFrom("main");
            } else if (type === "scrapDetails") {
              setChangeTab("scrapDetails");
              setFleetData(data);
              setPageNoSD(1);
              setRecordSD(10);
              setFilterSD("");
            }
          }}
          fleetData={fleetData}
          data={data}
        />
      )}
    </div>
  );
}
