import React, { useEffect, useMemo, useState } from "react";
import { Edit, Search, SearchOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { isArray, isEmpty, isObject } from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import theme, { color } from "../../../Config/theme";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx/xlsx.mjs";
import useStyles from "./styles";
import Images from "../../../Config/Images";
import { ResetIcon } from "../../Icon/TableToggle";
import { isTablet } from "react-device-detect";

export default function TabTrailerMasterVeiw(props) {
  const { handleClick = () => null } = props;
  const { token, userdata } = useSelector((state) => state.auth);
  const styles = useStyles();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [searchFilter, setSearchFilter] = useState("");
  const [reportLoader, setReportLoader] = useState(false);
  const [pageLoad, setPageLoad] = useState(false);
  const [record, setRecord] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [vmasterList, setVmasterList] = useState({});

  useEffect(() => {
    getTrailerMasterList(true);
  }, []);

  useEffect(() => {
    getTrailerMasterList(false);
  }, [record, pageNo]);

  const tMasterListColumn = [
    {
      field: "Sr. No. ",
      headerName: "Sr. No.",
      width: 70,
      headerAlign: "Center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const ind = params.api.getRowIndex(params?.row?.id) + 1;
        return <Typography>{ind}</Typography>;
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 70,
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip title="Edit" placement="right" arrow>
    //         <Edit
    //           style={{ color: color.primary, cursor: "pointer" }}
    //           onClick={() => handleClick("editTrailer", params?.row)}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "trailer_no",
      headerName: "Trailer No.",
      flex: 1,
      width: md ? "unset" : 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      flex: 1,
      width: 200,
      renderCell: (params) => {
        return <Typography>{params?.row?.vehicle_no || "-"}</Typography>;
      },
    },
  ];

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Vehicle Master`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getTrailerMasterList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.trailerlist
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        if (isObject(response?.data) && !isEmpty(response?.data)) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadVehileMasterList(response?.data?.rows);
          } else {
            let listObj = {};
            listObj.pagination = response?.data?.pagination;
            listObj.data = response?.data?.rows;
            setVmasterList(listObj);
          }
        } else {
          toast.error(response?.message);
        }
        v && auditSave("Vehicle Master List");
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      setReportLoader(false);
      toast.error(error?.toSyring());
      console.log("ERROR)=====>>>>>", error);
    }
  }

  function downloadVehileMasterList(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        "Trailer No.": item?.trailer_no,
        "Vehicle No.": item?.vehicle_no,
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Trailer Master List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Trailer Master List.xlsx");
  }

  const dataGrid = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={vmasterList?.data}
        columns={tMasterListColumn}
        pageSize={record}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        getRowHeight={() => "auto"}
        showColumnRightBorder
        disableSelectionOnClick
      />
    );
  }, [vmasterList]);

  function resetFilters() {
    setSearchFilter("");
    getTrailerMasterList(true);
  }

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Grid
            id="jump_to_me"
            item
            container
            display="flex"
            alignItems="center"
            backgroundColor={color.secondary}
            padding={"10px"}
            color={color.white}
            borderRadius={1}
            minHeight="60px"
          >
            <Grid item>
              <Typography variant="tableTitle">Trailer Master</Typography>
            </Grid>
          </Grid>

          <Grid
            item
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
            wrap="nowrap"
            gap={1}
            style={{ marginTop: 14 }}
          >
            <Select
              value={record}
              displayEmpty
              onChange={(e) => {
                setRecord(e.target.value);
                setPageNo(1);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Grid display="flex" alignItems={"center"} wrap="nowrap" gap={1}>
              {userdata?.role !== "service_enginner" && (
                <Button
                  variant="contained"
                  onClick={() => handleClick("addTrailer")}
                >
                  Add Trailer
                </Button>
              )}
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => getTrailerMasterList(true, 1)}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid
            display={"flex"}
            gap={1}
            marginTop={"14px"}
            marginBottom={"14px"}
            alignItems="center"
          >
            <TextField
              placeholder="Search"
              // fullWidth
              value={searchFilter}
              onChange={(e) => {
                isEmpty(e.target.value) && getTrailerMasterList(true);
                setSearchFilter(e.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter" && !isEmpty(searchFilter)) {
                  getTrailerMasterList(false);
                  setPageNo(1);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Trailer No."
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <Grid item display="flex" gap={1} alignItems="center">
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(searchFilter)) {
                    getTrailerMasterList(false);
                    setPageNo(1);
                  }
                }}
              >
                <SearchOutlined />
              </Button>
              <Button
                variant="contained"
                disabled={isEmpty(searchFilter)}
                onClick={resetFilters}
              >
                <ResetIcon />
              </Button>
            </Grid>
          </Grid>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(vmasterList?.data) && !isEmpty(vmasterList?.data) ? (
            <>
              {dataGrid}
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(vmasterList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => setPageNo(e)}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <Grid
            id="jump_to_me"
            item
            container
            display="flex"
            alignItems="center"
            backgroundColor={color.secondary}
            padding={"10px"}
            color={color.white}
            borderRadius={1}
            minHeight="60px"
          >
            <Grid item>
              <Typography variant="tableTitle">Trailer Master</Typography>
            </Grid>
          </Grid>

          <Grid
            item
            display="flex"
            alignItems={"center"}
            justifyContent="space-between"
            wrap="nowrap"
            gap={1}
            style={{ marginTop: 14 }}
          >
            <Select
              value={record}
              displayEmpty
              onChange={(e) => {
                setRecord(e.target.value);
                setPageNo(1);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Grid display="flex" alignItems={"center"} wrap="nowrap" gap={1}>
              {userdata?.role !== "service_enginner" && (
                <Button
                  variant="contained"
                  onClick={() => handleClick("addTrailer")}
                >
                  Add Trailer
                </Button>
              )}
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => getTrailerMasterList(true, 1)}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid display={"flex"} gap={1} marginTop={"14px"} alignItems="center">
            <TextField
              placeholder="Search"
              fullWidth
              value={searchFilter}
              onChange={(e) => {
                isEmpty(e.target.value) && getTrailerMasterList(true);
                setSearchFilter(e.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter" && !isEmpty(searchFilter)) {
                  getTrailerMasterList(false);
                  setPageNo(1);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Trailer No."
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <Grid item display="flex" gap={1} alignItems="center">
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(searchFilter)) {
                    getTrailerMasterList(false);
                    setPageNo(1);
                  }
                }}
              >
                <SearchOutlined />
              </Button>
              <Button
                variant="contained"
                disabled={isEmpty(searchFilter)}
                onClick={resetFilters}
              >
                <ResetIcon />
              </Button>
            </Grid>
          </Grid>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(vmasterList?.data) && !isEmpty(vmasterList?.data) ? (
            <>
              {vmasterList?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    id={`jump_to_me${index}`}
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      boxShadow: color.shadow,
                      overflow: "hidden",
                      marginTop: 20,
                    }}
                    onClick={(params) => {
                      handleClick("auditDetails");
                      // setData(item);
                    }}
                  >
                    <Table className={styles.CustomtableMobile}>
                      <TableHead>
                        <TableRow>
                          {/* <TableCell variant="head">Vehicle No.</TableCell> */}
                          <TableCell variant="head">Trailer No.</TableCell>
                          <TableCell variant="head">Vehicle No.</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {item?.trailer_no || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {item?.vehicle_no || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </Grid>
                );
              })}
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(vmasterList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => setPageNo(e)}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}
