import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  ImageList,
  ImageListItem,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Modal,
  Pagination,
  Popover,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { color } from "../../../Config/theme";

import {
  CopyAll,
  Edit,
  FilterAlt,
  MoreVert,
  Search,
  SearchOutlined,
  TuneOutlined,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Images from "../../../Config/Images";
import * as XLSX from "xlsx/xlsx.mjs";
import { isMobile, isTablet } from "react-device-detect";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle";
import moment from "moment";

export default function TabLifeHistory(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    handleSearch = () => null,
    search = "",
    pageNo = 1,
    record = 10,
    filter = "",
  } = props;

  const styles = useStyles();

  const [pageLoad, setPageLoad] = useState(true);
  const [lifeHistoryRows, setLifeHistoryRows] = useState({});
  // const [filter, setFilter] = useState("");
  // const [searchFilter, setSearchFilter] = useState("");
  const [datePicker, setDatePicker] = useState(null);
  const [removalList, setRemovalList] = useState(false);
  const [activeIndex, setactiveIndex] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [image, setImage] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);

  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;
  const { token, dropDownData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );

  useEffect(() => {
    lifeHistoryList(false);
  }, [record, pageNo, filter]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(search) && lifeHistoryList(false);
  }, [datePicker, search]);

  function resetFilterData() {
    handleFilter("");
    handleSearch("");
    setDatePicker(null);
    handlePageNo(1);
  }

  const nsd = (index) => {
    const nsdArr = lifeHistoryRows?.data[index]?.current_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return (
      <Typography>
        {`${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
          groove2 !== 0 ? groove2.toFixed(2) : " - "
        }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
          groove4 !== 0 ? groove4.toFixed(2) : " - "
        } mm`}
      </Typography>
    );
  };

  const getRemoveAt = (index) => {
    const timestamp = lifeHistoryRows?.data[index]?.removed_at;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");

    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadLifeData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.removed_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const nsdArr = row?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Condition": row?.tyre_condition || "-",
        "Tyre Position": row?.tyre_position || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.item_descryption || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating || "-",
        "Avg. Fitment NSD (mm)": row?.avg_fitment_nsd || "-",
        "Tyre Fitment Reading (Km)": Number(
          row?.tyre_fitment_km.toString().replaceAll(",", "")
        ),
        "Dismount NSD (mm)": `${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
          groove2 !== 0 ? groove2.toFixed(2) : " - "
        }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
          groove4 !== 0 ? groove4.toFixed(2) : " - "
        } mm`,
        "Avg. Dismount NSD (mm)": Number(row?.avg_nsd) || "-",
        "Dismount Reading (Km)": Number(
          row?.dismount_km.toString().replaceAll(",", "")
        ),
        "Removal Type": row?.removal_type || "-",
        Images: row?.removal_images?.toString() || "-",
        "Removed On": displayDate || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Life History List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Life History List.xlsx");
  }

  async function lifeHistoryList(bool, v) {
    setactiveIndex(null);
    !v && setPageLoad(true);
    v && setDownloadLoader(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.lifehistorylist
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : search
        }&filter=${filter}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadLifeData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setLifeHistoryRows(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Life History List");
      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [pageLoad, pageNo]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Grid container wrap="nowrap">
            <Grid
              id="jump_to_me"
              item
              xs={12}
              sm={9}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography variant="tableTitle">Life History</Typography>
            </Grid>
            <Grid
              item
              container
              sm={3}
              gap={1.2}
              wrap="nowrap"
              justifyContent={"flex-end"}
            >
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disabled={isOnline ? false : true}
                  onClick={() => lifeHistoryList(false, 1)}
                >
                  {downloadLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: color.white,
                    color: "#D8D8D8",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    onClick={() => {
                      handleClick("RotationHistory");
                    }}
                  >
                    Rotation History
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      handleClick("ConditionHistory");
                    }}
                  >
                    Condition History
                  </ListItemButton>
                  <ListItemButton selected>Life History</ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid container gap={1} style={{ marginTop: 15 }} wrap="nowrap">
            <Grid item sm={2.5}>
              <Select
                fullWidth
                placeholder="Filter"
                value={filter}
                IconComponent={FilterAlt}
                displayEmpty
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    setRemovalList(false);
                  }
                }}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value={""} disabled hidden selected>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    setRemovalList(!removalList);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Removal Type</Typography>
                  {removalList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.tyre_removal_issue.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: removalList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item sm={7}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && lifeHistoryList(true);
                  handleSearch(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter")
                    if (!isEmpty(search) || !isNull(datePicker)) {
                      handlePageNo(1);
                      lifeHistoryList(false);
                    }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Tyre Serial No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>

            <Grid item>
              <Button
                varient="contained"
                onClick={() => {
                  if (!isEmpty(search) || !isNull(datePicker)) {
                    lifeHistoryList(false);
                  }
                }}
                style={{
                  backgroundColor: color.secondary,
                }}
              >
                <SearchOutlined />
              </Button>
            </Grid>

            <Grid item>
              <Button
                fullWidth
                variant="contained"
                onClick={resetFilterData}
                disabled={!search && !filter}
              >
                <ResetIcon />
              </Button>
            </Grid>

            <Grid item>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(lifeHistoryRows?.data) &&
            !isEmpty(lifeHistoryRows?.data) ? (
            <>
              {lifeHistoryRows?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      padding: 15,
                      marginTop: 10,
                      overflow: "auto",
                      boxShadow: color.shadow,
                    }}
                    alignItems="center"
                  >
                    <Grid item container>
                      <Table className={styles.customtable}>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Fleet Name</TableCell>
                            <TableCell variant="head">Vehicle No.</TableCell>
                            <TableCell variant="head">
                              Tyre Serial No.
                            </TableCell>
                            <TableCell variant="head">Tyre Condition</TableCell>
                            <TableCell variant="head">Tyre Position</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>{item?.fleet_name || "-"}</TableCell>
                          <TableCell>{item?.vehicle_no || "-"}</TableCell>
                          <TableCell style={{ whiteSpace: "nowrap" }}>
                            <div style={{ display: "flex" }}>
                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleClick("tyreDetails", item);
                                }}
                              >
                                <Typography
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    color: color.primary,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.tyre_serial_no}
                                </Typography>
                              </div>

                              <CopyAll
                                style={{
                                  cursor: "pointer",
                                  marginTop: 5,
                                  marginLeft: 10,
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.tyre_serial_no
                                  );
                                  toast.success("Copied!", { toastId: 10 });
                                }}
                              />
                            </div>
                          </TableCell>
                          <TableCell>
                            {" "}
                            <div
                              style={{
                                backgroundColor:
                                  item?.tyre_condition === "Average"
                                    ? "#FFFDD9"
                                    : item?.tyre_condition === "Good"
                                    ? "#D9FFE4"
                                    : item?.tyre_condition === "Bad"
                                    ? "#FFD9D9"
                                    : "",
                                padding: 8,
                                borderRadius: 4,
                              }}
                            >
                              <Typography>
                                {item?.tyre_condition || "-"}
                              </Typography>
                            </div>
                          </TableCell>
                          <TableCell>{item?.tyre_position || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Divider
                              className={styles.divide}
                              variant="fullWidth"
                            />
                          </TableCell>
                        </TableRow>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Tyre Brand</TableCell>

                            <TableCell variant="head">
                              Item Description
                            </TableCell>
                            <TableCell variant="head">
                              Tyre Composition
                            </TableCell>
                            <TableCell variant="head">Ply Rating</TableCell>
                            <TableCell variant="head">
                              Avg. Fitment NSD
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>{item?.tyre_brand || "-"} </TableCell>

                          <TableCell>{item?.item_descryption || "-"}</TableCell>
                          <TableCell>{item?.tyre_composition || "-"}</TableCell>
                          <TableCell>{item?.ply_rating || "-"}</TableCell>
                          <TableCell>
                            <Typography>
                              {`${item?.avg_fitment_nsd} mm` || "-"}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Divider
                              className={styles.divide}
                              variant="fullWidth"
                            />
                          </TableCell>
                        </TableRow>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">
                              Tyre Fitment Reading
                            </TableCell>
                            <TableCell variant="head">Dismount NSD</TableCell>
                            <TableCell variant="head">
                              Avg. Dismount NSD
                            </TableCell>
                            <TableCell variant="head">
                              Dismount Reading
                            </TableCell>
                            <TableCell variant="head">Removal Type</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>
                            {item?.tyre_fitment_km || "-"} km
                          </TableCell>
                          <TableCell>{nsd(index)}</TableCell>
                          <TableCell>
                            <Typography
                              variant="span"
                              style={{
                                backgroundColor:
                                  item?.avg_nsd < 5 ? "#FFD9D9" : "",
                                padding: item?.avg_nsd < 5 ? 8 : 0,
                                borderRadius: 4,
                              }}
                            >
                              {`${item?.avg_nsd} mm` || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography>
                              {item?.dismount_km || "-"} Km
                            </Typography>
                          </TableCell>

                          <TableCell>
                            <Typography style={{ whiteSpace: "pre-wrap" }}>
                              {item?.removal_type}
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Divider
                              className={styles.divide}
                              variant="fullWidth"
                            />
                          </TableCell>
                        </TableRow>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Removed On</TableCell>
                            <TableCell variant="head">Images</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>
                            <Typography>{item?.removed_at || "-"}</Typography>
                          </TableCell>
                          <TableCell>
                            <Typography
                              onClick={() => {
                                if (
                                  isArray(item?.removal_images) &&
                                  !isEmpty(item?.removal_images)
                                ) {
                                  setImage(item?.removal_images);
                                  setOpenCard(true);
                                } else {
                                  return null;
                                }
                              }}
                              color={
                                isArray(item?.removal_images) &&
                                !isEmpty(item?.removal_images)
                                  ? color.primary
                                  : color.disable
                              }
                            >
                              View
                            </Typography>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          {(userdata?.role === "superadmin" ||
                            userdata?.role === "admin") && (
                            <Button
                              variant="contained"
                              style={{
                                margin: "20px 0 10px 5px",
                              }}
                              onClick={() => {
                                handleClick("Edit", item);
                              }}
                            >
                              <Tooltip title="Edit" placement="right" arrow>
                                <Edit />
                              </Tooltip>
                            </Button>
                          )}
                        </TableRow>
                      </Table>
                    </Grid>
                  </Grid>
                );
              })}
              <Box
                style={{
                  margin: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(lifeHistoryRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <Grid container wrap="nowrap">
            <Grid
              id="jump_to_me"
              item
              container
              display="flex"
              alignItems="center"
              backgroundColor={color.secondary}
              padding={"10px"}
              color={color.white}
              borderRadius={1}
            >
              <Grid item>
                <Typography variant="tableTitle">Tyre History</Typography>
              </Grid>

              <Grid item marginLeft={"auto"}>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: "#BBBBBB",
                    minWidth: "unset",
                    color: "#000",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVert />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton selected>Life History</ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      handleClick("RotationHistory");
                    }}
                  >
                    Rotation History
                  </ListItemButton>
                  <ListItemButton
                    onClick={() => {
                      handleClick("ConditionHistory");
                    }}
                  >
                    Condition History
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>
          </Grid>
          <Grid marginTop={"10px"}>
            <Typography variant="tableTitle">Life History</Typography>
          </Grid>
          <Grid
            container
            style={{ marginTop: 10 }}
            gap={1}
            wrap="nowrap"
            alignItems="center"
          >
            <Grid item>
              <Select
                placeholder="Filter"
                value={filter}
                IconComponent={FilterAlt}
                displayEmpty
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    setRemovalList(false);
                  }
                }}
                style={
                  (filter === "" ? { color: "#A2A2A2" } : {}, { maxWidth: 220 })
                }
              >
                <MenuItem value={""} disabled hidden selected>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    setRemovalList(!removalList);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Removal Type</Typography>
                  {removalList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.tyre_removal_issue.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: removalList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Grid item marginLeft={"auto"}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                disabled={isOnline ? false : true}
                onClick={() => lifeHistoryList(false, 1)}
              >
                {downloadLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>
          <div style={{ marginTop: 10 }}>
            <Grid container xs={12} gap={1} wrap="nowrap" alignItems="center">
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={search}
                  onChange={(v) => {
                    isEmpty(v.target.value) && lifeHistoryList(true);
                    handleSearch(v?.target?.value);
                  }}
                  onKeyDown={(v) => {
                    if (v.key === "Enter") {
                      if (!isEmpty(search) || !isNull(datePicker)) {
                        handlePageNo(1);
                        lifeHistoryList(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Tyre Serial No."
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={1}>
                <Button
                  varient="contained"
                  onClick={() => {
                    if (!isEmpty(search) || !isNull(datePicker)) {
                      lifeHistoryList(false);
                    }
                  }}
                  style={{
                    backgroundColor: color.secondary,
                    width: "100%",
                  }}
                >
                  <SearchOutlined />
                </Button>
              </Grid>
              <Grid item xs={1}>
                <Button
                  fullWidth
                  variant="contained"
                  onClick={resetFilterData}
                  disabled={!search && !filter}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
          </div>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(lifeHistoryRows?.data) &&
            !isEmpty(lifeHistoryRows?.data) ? (
            <>
              {lifeHistoryRows?.data?.map((item, index) => {
                return (
                  <div style={{ position: "relative" }}>
                    <Grid
                      id={`jump_to_me${index}`}
                      container
                      style={{
                        marginTop: 20,
                        border: `1px solid ${color.bordercolor}`,
                        alignItems: "center",
                        // height: activeIndex === index ? "auto" : 120,
                        overflow: "hidden",
                        boxShadow: color.shadow,
                      }}
                    >
                      {activeIndex !== index ? (
                        <>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px 14px 0",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Fleet Name
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Vehicle No.
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography>{item?.fleet_name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{item?.vehicle_no}</Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              background: "#EEF8FF",
                              padding: "10px 14px 0",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Tyre Serial No.
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Tyre Condition
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                              background: "#EEF8FF",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid
                              item
                              xs={6}
                              container
                              wrap="nowrap"
                              alignItems={"center"}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleClick("tyreDetails", item);
                                }}
                              >
                                <Typography
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    color: color.primary,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.tyre_serial_no}
                                </Typography>
                              </div>
                              <CopyAll
                                style={{
                                  cursor: "pointer",
                                  marginRight: 55,
                                  marginTop: 3,
                                  color: color.primary,
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.tyre_serial_no
                                  );
                                  toast.success("Copied!", { toastId: 10 });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: 5 }}>
                              <Typography
                                variant="span"
                                style={{
                                  backgroundColor:
                                    item?.tyre_condition === "Average"
                                      ? "#FFFDD9"
                                      : item?.tyre_condition === "Good"
                                      ? "#D9FFE4"
                                      : item?.tyre_condition === "Bad"
                                      ? "#FFD9D9"
                                      : "",
                                  padding: 4,
                                  borderRadius: 4,
                                }}
                              >
                                {item?.tyre_condition || "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                        </>
                      ) : (
                        <>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px 14px 0",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Fleet Name
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Vehicle No.
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography>{item?.fleet_name}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{item?.vehicle_no}</Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              background: "#EEF8FF",
                              padding: "10px 14px 0",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Tyre Serial No.
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Tyre Condition
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                              background: "#EEF8FF",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid
                              item
                              xs={6}
                              container
                              wrap="nowrap"
                              alignItems={"center"}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flex: 1,
                                  cursor: "pointer",
                                }}
                                onClick={() => {
                                  handleClick("tyreDetails", item);
                                }}
                              >
                                <Typography
                                  style={{
                                    whiteSpace: "pre-wrap",
                                    color: color.primary,
                                    fontWeight: "bold",
                                  }}
                                >
                                  {item?.tyre_serial_no}
                                </Typography>
                              </div>
                              <CopyAll
                                style={{
                                  cursor: "pointer",
                                  marginRight: 55,
                                  marginTop: 3,
                                  color: color.primary,
                                }}
                                onClick={() => {
                                  navigator.clipboard.writeText(
                                    item?.tyre_serial_no
                                  );
                                  toast.success("Copied!", { toastId: 10 });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6} style={{ marginTop: 5 }}>
                              <Typography
                                variant="span"
                                style={{
                                  backgroundColor:
                                    item?.tyre_condition === "Average"
                                      ? "#FFFDD9"
                                      : item?.tyre_condition === "Good"
                                      ? "#D9FFE4"
                                      : item?.tyre_condition === "Bad"
                                      ? "#FFD9D9"
                                      : "",
                                  padding: 4,
                                  borderRadius: 4,
                                }}
                              >
                                {item?.tyre_condition || "-"}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px 14px 0",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Tyre Position
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Tyre Brand
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography>
                                {item?.tyre_position || "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{item?.tyre_brand || "-"}</Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px 14px 0",
                              background: "#EEF8FF",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Item Description
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Tyre Composition
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                              background: "#EEF8FF",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography>
                                {item?.item_descryption || "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>
                                {item?.tyre_composition || "-"}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px 14px 0",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Ply Rating
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Avg. Fitment NSD
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography>{item?.ply_rating || "-"}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>
                                {`${item?.avg_fitment_nsd} mm` || "-"}
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px 14px 0",
                              background: "#EEF8FF",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Tyre Fitment Reading
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Dismount NSD
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                              background: "#EEF8FF",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography>
                                {item?.tyre_fitment_km || "-"} km
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>{nsd(index)}</Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px 14px 0",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Avg. Dismount NSD
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Dismount Reading
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6} style={{ marginTop: 5 }}>
                              <Typography
                                variant="span"
                                style={{
                                  backgroundColor:
                                    item?.avg_nsd < 5 ? "#FFD9D9" : "",
                                  padding: 4,
                                  borderRadius: 4,
                                }}
                              >
                                {`${item?.avg_nsd} mm` || "-"}
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>
                                {item?.dismount_km || "-"} Km
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "10px 14px 0",
                              background: "#EEF8FF",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Removal Type
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography fontWeight={"bold"}>
                                Removed On
                              </Typography>
                            </Grid>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                              background: "#EEF8FF",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}>
                              <Typography>{item?.removal_type}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Typography>
                                {getRemoveAt(index) || "-"}
                              </Typography>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6} style={{ marginTop: 5 }}>
                              {(userdata?.role === "superadmin" ||
                                userdata?.role === "admin") && (
                                <Button
                                  style={{ backgroundColor: color.primary }}
                                  onClick={() => {
                                    handleClick("Edit", item);
                                  }}
                                >
                                  <Tooltip title="Edit" placement="right" arrow>
                                    <Edit />
                                  </Tooltip>
                                </Button>
                              )}
                            </Grid>
                            <Grid item xs={6}>
                              <Grid item>
                                <Typography fontWeight={"bold"}>
                                  Images
                                </Typography>
                              </Grid>
                              <Grid item>
                                <Typography
                                  style={{
                                    marginTop: "5px",
                                  }}
                                  onClick={() => {
                                    if (
                                      isArray(item?.removal_images) &&
                                      !isEmpty(item?.removal_images)
                                    ) {
                                      setImage(item?.removal_images);
                                      setOpenCard(true);
                                    } else {
                                      return null;
                                    }
                                  }}
                                  color={
                                    isArray(item?.removal_images) &&
                                    !isEmpty(item?.removal_images)
                                      ? color.primary
                                      : color.disable
                                  }
                                >
                                  View
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid
                            item
                            style={{
                              display: "flex",
                              flexDirection: "row",
                              padding: "0 14px 10px",
                            }}
                            xs={12}
                            gap={2}
                          >
                            <Grid item xs={6}></Grid>
                          </Grid>
                        </>
                      )}
                    </Grid>
                    <div
                      onClick={(v) => {
                        if (activeIndex === index) {
                          setactiveIndex(null);
                        } else {
                          setactiveIndex(index);
                        }
                      }}
                      style={{
                        position: "absolute",
                        bottom: -18,
                        left: "45%",
                      }}
                    >
                      {activeIndex === index ? (
                        <div style={{ transform: "rotate(180deg)" }}>
                          <TableToggle />
                        </div>
                      ) : (
                        <TableToggle />
                      )}
                    </div>
                  </div>
                );
              })}
              <Box
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(lifeHistoryRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
      <Modal
        onClose={() => {
          setOpenCard(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "80vw",
            maxHeight: "80vh",
            backgroundColor: "white",
            padding: 10,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={1}
            gap={9}
            style={{
              overflowY: "scroll",
              maxHeight: "60vh",
            }}
          >
            {isArray(image) &&
              !isEmpty(image) &&
              image?.map((item, index) => {
                return (
                  <ImageListItem key={index}>
                    <img
                      width={"100%"}
                      src={`${item}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </ImageListItem>
                );
              })}
          </ImageList>
        </div>
      </Modal>
    </>
  );
}
