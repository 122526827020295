import React, { useEffect } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Link,
  Modal,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { isArray, isEmpty, isObject } from "lodash";
import { useState } from "react";
import FUserManagement from "../FUserManagement/index.js";
import VehicleManageMent from "../VehicleManageMent/index.js";
import FAddUser from "../FAddUser/index.js";
import AddVehicle from "../AddVehicle/index.js";
import UnresolvedIssue from "../UnresolvedIssue/index.js";
import IssueList from "../IssueList/index.js";
import ResolveIssue from "../../InspectionManagement/ResolveIssue/index.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import VehicleDetail from "../VehicleDetail/index.js";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import * as XLSX from "xlsx/xlsx.mjs";

export default function FleetDetails(props) {
  const { fleetData, from } = props;
  const styles = useStyles();
  const { token, isOnline, permissionData, userdata } = useSelector(
    (state) => state.auth
  );

  const [openCard, setOpenCard] = useState(false);
  const [changeTab, setChangeTab] = useState("");
  const [fleetDetails, setFleetDetails] = useState({});
  const navigate = useNavigate();

  const [userData, setUserData] = useState({});
  const [vehicleData, setVehicleData] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [reportLoader, setReportLoader] = useState(false);
  const [issueType, setIssueType] = useState("");
  const [issue, setIssue] = useState("");

  const [pageNoFU, setPageNoFU] = useState(1);

  const [pageNoUI, setPageNoUI] = useState(1);
  const [recordUI, setRecordUI] = useState(10);
  const [filterUI, setFilterUI] = useState("");

  const [pageNoIL, setPageNoIL] = useState(1);
  const [recordIL, setRecordIL] = useState(10);

  const [pageNoV, setPageNoV] = useState(1);
  const [recordV, setRecordV] = useState(10);
  const [filterV, setFilterV] = useState("");

  const [tyreListDisplay, setTyreListdisplay] = useState(true);

  useEffect(() => {
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Tyre List") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              setTyreListdisplay(v?.isActive);
          }
        });
      });
    }
  }, [permissionData]);

  useEffect(() => {
    getFleetDetails();
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [changeTab]);

  useEffect(() => {
    if (from === "inspection") {
      setTimeout(() => setChangeTab("UnresolvedIssueList"), 200);
    }
  }, []);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Fleet Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  const getFleetDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetdetails}?id=${fleetData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setFleetDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const checkFunction = () => {
    if (!isEmpty(fleetDetails)) {
      let Array = fleetDetails?.business_pan_card.split(".");
      if (Array[Array.length - 1] === "pdf") {
        const pdfWindow = window.open();
        pdfWindow.location.href = fleetDetails?.business_pan_card;
      } else {
        setOpenCard(true);
      }
    }
  };

  function AddedOn() {
    const timestamp = fleetDetails.created_at;
    const date = new Date(timestamp * 1000);
    return timestamp ? moment(date).format("DD-MM-yyyy") : "-";
  }

  async function getTyreListReport() {
    setReportLoader(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetTyreListReport}?fleet_id=${fleetData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReports1(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave("Tyre List");
      } else {
        toast.error(response?.message);
      }
      setReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  function downloadReports1(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        RO: item?.ro || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position,
        "Axle Type": item?.axle_type,
        "Tyre Serial No": item?.tyre_serial_no,
        "Tyre Type": item?.tyre_type,
        "Tyre Brand": item?.tyre_brand,
        "Item Description": item?.item_description,
        "Tyre Composition": item?.tyre_composition,
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Mileage (Km)": Number(item?.mileage?.toString()?.replaceAll(",", "")),
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        // "Current NSD (mm)": nsd(data, index) || "-",
        "Tyre Air Pressure (psi)": item?.tyre_air_pressure || "-",
        "Tyre Issue": item?.tyre_issue?.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue?.toString() || "-",
        // Remarks: item?.Remarks,
        "Last Inspected By": item?.inspected_by || "-",
        "Last Inspected On": item?.inspected_at ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre List.xlsx");
  }

  return (
    <div>
      {changeTab === "vehicleDetails" ? null : (
        <Grid container id="jump_to_me">
          <Grid item xs={12} className={styles.breadcrumb}>
            <Breadcrumbs separator="›">
              <Link
                underline="hover"
                color="inherit"
                href="/"
                className={styles.linkStyle}
              >
                Dashboard
              </Link>
              <Link
                underline="hover"
                color="inherit"
                href=""
                className={styles.linkStyle}
                onClick={() => {
                  navigate("/dashboard/FleetManagement");
                }}
              >
                Fleet Management
              </Link>
              {changeTab === "user" ||
              changeTab === "vehicle" ||
              changeTab === "UnresolvedIssue" ||
              changeTab === "UnresolvedIssueList" ||
              changeTab === "ResolveIssue" ? (
                <Link
                  underline="hover"
                  color="inherit"
                  className={styles.linkStyle}
                  onClick={() => {
                    setChangeTab("");
                    setUserData({});
                    setVehicleData({});
                  }}
                  href="#"
                >
                  {fleetData?.name || ""}
                </Link>
              ) : (
                <Typography className={styles.linkStyle}>
                  {fleetData?.name || ""}
                </Typography>
              )}
              {changeTab === "user" && (
                <Typography className={styles.linkStyle}>
                  {!isEmpty(userData) ? "Edit User" : "Add User"}
                </Typography>
              )}
              {changeTab === "vehicle" && (
                <Typography className={styles.linkStyle}>
                  {!isEmpty(vehicleData) ? "Edit Vehicle" : "Add Vehicle"}
                </Typography>
              )}

              {(changeTab === "UnresolvedIssueList" ||
                changeTab === "ResolveIssue") && (
                <Link
                  underline="hover"
                  color="inherit"
                  className={styles.linkStyle}
                  onClick={() => {
                    setFilterUI("");
                    setChangeTab("UnresolvedIssue");
                  }}
                  href="#"
                >
                  Unresolved Issues
                </Link>
              )}
              {changeTab === "ResolveIssue" && (
                <Link
                  underline="hover"
                  color="inherit"
                  className={styles.linkStyle}
                  onClick={() => {
                    setChangeTab("UnresolvedIssueList");
                  }}
                  href="#"
                >
                  Unresolved Tyre List
                </Link>
              )}
              {changeTab === "UnresolvedIssueList" && (
                <Typography className={styles.linkStyle}>
                  Unresolved Tyre List
                </Typography>
              )}
              {changeTab === "UnresolvedIssue" && (
                <Typography className={styles.linkStyle}>
                  Unresolved Issue
                </Typography>
              )}
              {changeTab === "ResolveIssue" && (
                <Typography className={styles.linkStyle}>
                  Resolve Issue
                </Typography>
              )}
            </Breadcrumbs>
          </Grid>
        </Grid>
      )}
      {changeTab === "user" ? (
        <FAddUser
          fleetData={fleetDetails}
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
              setUserData({});
              getFleetDetails();
            }
          }}
          editData={userData}
        />
      ) : changeTab === "vehicleDetails" ? (
        <VehicleDetail
          vehicleDetails={vehicleData}
          handleClick={(data) => {
            setChangeTab("fleetDetails");
          }}
        />
      ) : changeTab === "vehicle" ? (
        <AddVehicle
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
              setVehicleData({});
            }
          }}
          editData={vehicleData}
          fleetData={fleetDetails}
        />
      ) : changeTab === "UnresolvedIssueList" ? (
        <UnresolvedIssue
          vehicleData={from === "inspection" ? fleetData : vehicleData}
          pageNo={pageNoUI}
          record={recordUI}
          filter={filterUI}
          handlePageNo={(page) => {
            setPageNoUI(page);
          }}
          handleRecord={(rec) => {
            setRecordUI(rec);
          }}
          handleFilter={(flt) => {
            setFilterUI(flt);
          }}
          handleClick={(type, data, issType, iss) => {
            if (type === "ResolveIssue") {
              setChangeTab("ResolveIssue");
              setVehicleDetails(data);
              setIssueType(issType);
              setIssue(iss);
            }
          }}
        />
      ) : changeTab === "UnresolvedIssue" ? (
        <IssueList
          fleetData={fleetDetails}
          pageNo={pageNoIL}
          record={recordIL}
          handlePageNo={(page) => {
            setPageNoIL(page);
          }}
          handleRecord={(rec) => {
            setRecordIL(rec);
          }}
          handleClick={(type, data) => {
            if (type === "UnresolvedIssueList") {
              setPageNoUI(1);
              setRecordUI(10);
              setChangeTab("UnresolvedIssueList");
              setVehicleData(data);
            }
          }}
        />
      ) : changeTab === "ResolveIssue" ? (
        <ResolveIssue
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("UnresolvedIssueList");
            }
          }}
          vehicleDetails={vehicleDetails}
          fleetData={fleetDetails}
          from={"fleetdetails"}
          issueType={issueType}
          issue={issue}
        />
      ) : (
        <>
          <div className={styles.container}>
            <Box
              style={{
                backgroundColor: color.primary,
                color: color.white,
                padding: "18px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Grid item container>
                <Typography fontWeight={"bold"}>Fleet Details</Typography>
              </Grid>
              {tyreListDisplay && (
                <Grid item container justifyContent={"flex-end"} wrap="nowrap">
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10, backgroundColor: color.secondary }}
                    onClick={() => {
                      getTyreListReport();
                    }}
                    disable={reportLoader || isOnline ? false : true}
                  >
                    {reportLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      "Download Tyre List"
                    )}
                  </Button>
                </Grid>
              )}
            </Box>
            <div style={{ padding: 20 }}>
              <Table>
                <TableRow>
                  <TableCell variant="head">Fleet Name</TableCell>
                  <TableCell>{fleetDetails?.name || "-"}</TableCell>
                  <TableCell variant="head">Owner</TableCell>
                  <TableCell>{fleetDetails?.owner_name || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Fleet Code</TableCell>
                  <TableCell>{fleetDetails?.code || "-"}</TableCell>
                  <TableCell variant="head">Owner’s No.</TableCell>
                  <TableCell>{fleetDetails?.owner_phone || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Location</TableCell>
                  <TableCell>{fleetDetails?.city || "-"}</TableCell>
                  <TableCell variant="head">Manager</TableCell>
                  <TableCell>{fleetDetails?.manager_name || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Address</TableCell>
                  <TableCell>{fleetDetails?.address || "-"}</TableCell>
                  <TableCell variant="head">Manager’s No.</TableCell>
                  <TableCell>{fleetDetails?.manager_phone || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">State</TableCell>
                  <TableCell>{fleetDetails?.state || "-"}</TableCell>
                  <TableCell variant="head">Unresolved Issues</TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      color:
                        fleetDetails?.pending_issues_count == 0
                          ? color.disable
                          : "red",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      if (fleetDetails?.pending_issues_count != 0) {
                        setPageNoIL(1);
                        setRecordIL(10);
                        setChangeTab("UnresolvedIssue");
                      }
                    }}
                  >
                    {fleetDetails?.pending_issues_count || "0"}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">District</TableCell>
                  <TableCell>{fleetDetails?.distict || "-"}</TableCell>
                  <TableCell variant="head">Added On</TableCell>
                  <TableCell>{AddedOn() || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Regional Office</TableCell>
                  <TableCell>{fleetDetails?.office_region || "-"}</TableCell>
                  <TableCell variant="head">PAN Card</TableCell>
                  <TableCell
                    style={{
                      color: color.primary,
                      fontWeight: "bold",
                      cursor: "pointer",
                    }}
                    onClick={checkFunction}
                  >
                    View
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">No. of Vehicles</TableCell>
                  <TableCell
                    style={{
                      fontWeight: "bold",
                      color: "blue",
                    }}
                  >
                    {fleetDetails?.vehicles_allocated || "-"}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <Modal
                    className={styles.modal}
                    open={openCard}
                    onClose={() => {
                      setOpenCard(false);
                    }}
                  >
                    <img
                      style={{
                        objectFit: "contain",
                        borderRadius: 4,
                        maxWidth: 550,
                        maxHeight: 550,
                      }}
                      src={fleetDetails.business_pan_card || "-"}
                      alt={"Business PAN Card"}
                    />
                  </Modal>
                </TableRow>
              </Table>
            </div>
          </div>
          <FUserManagement
            fleetData={fleetData}
            pageNo={pageNoFU}
            handlePageNo={(page) => {
              setPageNoFU(page);
            }}
            handleClick={(type, data) => {
              if (type === "addUser") {
                setChangeTab("user");
              } else if (type === "editUser") {
                setUserData(data);
                setTimeout(() => {
                  setChangeTab("user");
                }, 200);
              }
            }}
            from="FleetManagement"
          />
          <VehicleManageMent
            name={fleetDetails?.name}
            fleetData={fleetData}
            pageNo={pageNoV}
            record={recordV}
            filter={filterV}
            handlePageNo={(page) => {
              setPageNoV(page);
            }}
            handleRecord={(rec) => {
              setRecordV(rec);
            }}
            handleFilter={(flt) => {
              setFilterV(flt);
            }}
            handleClick={(type, data) => {
              if (type === "addVehicle") {
                setChangeTab("vehicle");
              } else if (type === "vehicleDetails") {
                setChangeTab("vehicleDetails");
                setVehicleData(data);
              } else if (type === "editVehicle") {
                setVehicleData(data);
                setTimeout(() => {
                  setChangeTab("vehicle");
                }, 200);
              }
            }}
          />
        </>
      )}
    </div>
  );
}
