import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import "./styles.js";
import {
  Grid,
  Typography,
  Button,
  Box,
  TableRow,
  TableCell,
  Table,
  MenuItem,
  Select,
  Pagination,
  CircularProgress,
  TableBody,
  TableHead,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { isArray, isEmpty, isObject } from "lodash";
import { getApiData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import Images from "../../../Config/Images.js";

export default function TabTyreDetails(props) {
  const { tyreData, from } = props;
  const styles = useStyles();
  const [record, setRecord] = useState(10);
  const [record1, setRecord1] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [pageNo1, setPageNo1] = useState(1);
  const [tyreListRows, setTyreListRows] = useState({});
  const [tyreDetails, setTyreDetails] = useState({});
  const [pageLoad, setPageLoad] = useState(true);
  const [pageLoad1, setPageLoad1] = useState(true);
  const [inventoryTyreListRows, setInventoryTyreListRows] = useState({});
  const [reportLoader, setReportLoader] = useState(false);
  const [reportLoader1, setReportLoader1] = useState(false);

  const { token, isOnline } = useSelector((state) => state.auth);

  useEffect(() => {
    gettyreList(false);
  }, [pageNo, record]);

  useEffect(() => {
    getInventorytyreList(false);
  }, [pageNo1, record1]);

  useEffect(() => {
    getTyreDetails();
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);

  async function gettyreList(v) {
    v && setReportLoader(true);
    !v && setPageLoad(true);

    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyremileagehistory
        }?per_page=${record}&page=${pageNo}&tyre_serial_no=${
          tyreData?.tyre_serial_no
        }&is_download=${v ? 1 : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadTDetails(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setTyreListRows(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  const getTyreDetails = async () => {
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyreDetails
        }?tyre_id=${tyreData?.tyre_serial_no?.replaceAll(
          " ",
          "%20"
        )}&vehicle_id=${
          from === "life" || from === "tyreInventory"
            ? tyreData?.vehicle_id
            : ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setTyreDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function downloadTDetails(data) {
    const newData = data.map((row, index) => {
      // const timestamp = row?.created_at;
      // const date = new Date(timestamp * 1000);
      // const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        // "Old Position": row?.old_position || "-",
        // "New Position": row?.new_position || "-",
        "Vehicle No.": row?.vehicle_no,
        "Mileage (Km)": Number(row?.mileage),
        // "Tyre Issues": row?.issues.toString() || "-",
        // "Mechanical Issues": row?.mechanical_issues.toString() || "-",
        // "Rotation Date": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Mileage History");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Mileage History.xlsx");
  }

  async function getInventorytyreList(v) {
    !v && setPageLoad1(true);
    v && setReportLoader1(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyrecategoryhistory
        }?per_page=${record1}&page=${pageNo1}&tyre_serial_no=${
          tyreData?.tyre_serial_no
        }&is_download=${v ? 1 : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response?.status) {
      }
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadTHistory(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setInventoryTyreListRows(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad1(false);
      setReportLoader1(false);
    } catch (error) {
      setPageLoad1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader1(false);
      setPageLoad1(false);
    }
  }

  function downloadTHistory(data) {
    const newData = data.map((item, index) => {
      const date = item?.created_at;
      const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Old Category.": item?.old_category,
        "New Category.": item?.new_category,
        "Changed Date": displayDate,
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre History");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre History.xlsx");
  }

  const getTyreIssue = (index) => {
    let array = tyreListRows?.data[index]?.issues;
    return !isEmpty(array) ? (
      <div
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {array.map((items, index) => {
          return (
            <Typography>
              {items}
              {index !== array.length - 1 ? ", " : ""}
            </Typography>
          );
        })}
      </div>
    ) : (
      <Typography variant="tableHead">-</Typography>
    );
  };

  const getMechanicalIssue = (index) => {
    let array = tyreListRows?.data[index]?.mechanical_issues;
    return !isEmpty(array) ? (
      <div
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {array.map((items, index) => {
          return (
            <Typography>
              {items}
              {index !== array.length - 1 ? ", " : ""}
            </Typography>
          );
        })}
      </div>
    ) : (
      <Typography variant="tableHead">-</Typography>
    );
  };

  const getRotationDate = (index) => {
    const timestamp = tyreListRows?.data[index]?.created_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };
  return (
    <>
      {isTablet ? (
        <>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            id="jump_to_me"
          >
            <Typography fontWeight={"bold"}>Tyre Details</Typography>
            <Button
              variant="contained"
              style={{
                marginLeft: 10,
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              onClick={() => gettyreList(true)}
              disabled={isOnline ? false : true}
            >
              {reportLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                <img src={Images.Mdownload} alt="download" />
              )}
            </Button>
          </Box>
          <div className={styles.container}>
            <div style={{ padding: 20 }}>
              <Table>
                <TableRow>
                  <TableCell variant="head">Fleet Name</TableCell>
                  <TableCell>{tyreDetails?.fleet_name || "-"}</TableCell>
                  <TableCell variant="head">Current Condition</TableCell>
                  <TableCell>
                    <span
                      style={{
                        padding: 8,
                        borderRadius: 4,
                        backgroundColor:
                          tyreDetails?.tyre_condition === "Average"
                            ? "#FFFDD9"
                            : tyreDetails?.tyre_condition === "Good"
                            ? "#D9FFE4"
                            : tyreDetails?.tyre_condition === "Bad"
                            ? "#FFD9D9"
                            : "",
                      }}
                    >
                      {tyreDetails?.tyre_condition || "-"}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Vehicle No.</TableCell>
                  <TableCell>{tyreDetails?.vehicle_no || "-"}</TableCell>
                  <TableCell variant="head">Tyre Type</TableCell>
                  <TableCell>{tyreDetails?.tyre_type || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Vehicle Type</TableCell>
                  <TableCell>{tyreDetails?.vehicle_type || "-"}</TableCell>
                  <TableCell variant="head">Tyre Brand</TableCell>
                  <TableCell>{tyreDetails?.tyre_brand || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Vehicle Brand</TableCell>
                  <TableCell>{tyreDetails?.vehicle_brand || "-"}</TableCell>
                  <TableCell variant="head">Item Description</TableCell>
                  <TableCell>
                    {tyreDetails?.is_new_tyre === 1
                      ? tyreDetails?.new_tyre_name
                      : tyreDetails?.tyre_pattern_size}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Vehicle Model</TableCell>
                  <TableCell>{tyreDetails?.vehicle_model || "-"}</TableCell>
                  <TableCell variant="head">Tyre Composition</TableCell>
                  <TableCell>{tyreDetails?.tyre_composition || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">No. of Changes</TableCell>
                  <TableCell>{tyreDetails?.condition_changes || "-"}</TableCell>
                  <TableCell variant="head">Ply Rating</TableCell>
                  <TableCell>{tyreDetails?.ply_rating || "-"}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Current NSD</TableCell>
                  <TableCell>
                    <span
                      style={{
                        color: color.white,
                        padding: 8,
                        borderRadius: 4,
                        backgroundColor: color.primary,
                      }}
                    >
                      {`${tyreDetails?.NSD} mm` || "-"}
                    </span>
                  </TableCell>
                  <TableCell variant="head">Current Position </TableCell>
                  <TableCell>
                    <span
                      style={{
                        color: color.white,
                        padding: 8,
                        borderRadius: 4,
                        backgroundColor: color.primary,
                      }}
                    >
                      {tyreDetails?.tyre_position || "-"}
                    </span>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell variant="head">Current Tyre Pressure</TableCell>
                  <TableCell>
                    <span
                      style={{
                        color: color.white,
                        padding: 8,
                        borderRadius: 4,
                        backgroundColor: color.primary,
                      }}
                    >
                      {(tyreDetails?.tyre_air_pressure &&
                        `${tyreDetails?.tyre_air_pressure} psi`) ||
                        "-"}
                    </span>
                  </TableCell>
                  <TableCell variant="head">Current Axle Type</TableCell>
                  <TableCell>
                    <span
                      style={{
                        color: color.white,
                        padding: 8,
                        borderRadius: 4,
                        backgroundColor: color.primary,
                      }}
                    >
                      {tyreDetails?.axle_type || "-"}
                    </span>
                  </TableCell>
                </TableRow>
              </Table>
            </div>
          </div>

          <div
            className={styles.container}
            style={{ marginTop: 20, marginBottom: 20, padding: 20 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                lg={10}
                md={10}
                sm={10}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="tableTitle">
                  Tyre Mileage History
                </Typography>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    setPageNo(1);
                    setRecord(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container flexDirection="row" style={{ marginTop: 10 }}>
              <div
                style={{
                  backgroundColor: "#D9F8FF",
                  padding: 6,
                  borderRadius: 4,
                  marginRight: 10,
                }}
              >
                <Typography>
                  {`Total: ${tyreListRows?.pagination?.total_mileage || "-"}`}
                  Km
                </Typography>
              </div>
              <div
                style={{
                  backgroundColor: "rgb(217, 255, 228)",
                  padding: 6,
                  borderRadius: 4,
                }}
              >
                <Typography>
                  {`Previous Reading: ${
                    tyreListRows?.pagination?.previous_reading_km || "-"
                  }`}
                  Km
                </Typography>
              </div>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(tyreListRows?.data) && !isEmpty(tyreListRows?.data) ? (
              <>
                <Box
                  style={{ marginTop: 20 }}
                  sx={{
                    "& .super-app-theme--0": {
                      bgcolor: "#ffd9d94d",
                      "&:hover": {
                        bgcolor: "#ffd9d94d",
                      },
                    },
                  }}
                >
                  {tyreListRows?.data?.map((item, index) => {
                    return (
                      <Grid
                        container
                        style={{
                          marginTop: 10,
                          border: `1px solid ${color.bordercolor}`,
                          overflow: "auto",
                        }}
                      >
                        <Table className={styles.customtable}>
                          <TableBody>
                            <TableRow>
                              {/* <TableCell variant="head">Old Position</TableCell> */}
                              <TableCell variant="head">Vehicle No.</TableCell>
                              <TableCell variant="head">Mileage</TableCell>
                              {/* <TableCell variant="head">Tyre Issue</TableCell> */}
                            </TableRow>
                            <TableRow>
                              {/* <TableCell>{item?.old_position || "-"}</TableCell> */}
                              <TableCell>{item?.vehicle_no || "-"}</TableCell>
                              <TableCell>
                                <Typography>
                                  {item?.mileage || "0"} km
                                </Typography>
                              </TableCell>
                              {/* <TableCell>{getTyreIssue(index)}</TableCell> */}
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    );
                  })}
                </Box>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(tyreListRows?.pagination?.totalPage)}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      setPageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>

          <div
            className={styles.container}
            style={{ marginTop: 20, marginBottom: 20, padding: 20 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                lg={10}
                md={10}
                sm={10}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="tableTitle">Tyre History</Typography>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    setPageNo1(1);
                    setRecord1(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>

                <Button
                  disabled={isOnline ? false : true}
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  onClick={() => getInventorytyreList(true)}
                >
                  {reportLoader1 ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>

            {pageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(inventoryTyreListRows?.data) &&
              !isEmpty(inventoryTyreListRows?.data) ? (
              <>
                {inventoryTyreListRows?.data?.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      style={{
                        marginTop: 15,
                        border: `1px solid ${color.bordercolor}`,
                        alignItems: "center",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <Table className={styles.customtableTyreMileage}>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Old Category</TableCell>
                            <TableCell variant="head">New Category</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.old_category || "-"}
                            </TableCell>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.new_category || "-"}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} variant="head">
                              Changed Date
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {moment(item?.created_at).format("DD-MM-yyyy") ||
                                "-"}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </Grid>
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      inventoryTyreListRows?.pagination?.totalPage
                    )}
                    defaultPage={pageNo1}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      setPageNo1(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            id="jump_to_me"
          >
            <Typography fontWeight={"bold"}>Tyre Details</Typography>
            <Button
              disabled={isOnline ? false : true}
              variant="contained"
              style={{
                marginLeft: 10,
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              onClick={() => gettyreList(true)}
            >
              {reportLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                <img src={Images.Mdownload} alt="download" />
              )}
            </Button>
          </Box>
          <div className={styles.container}>
            <div style={{ padding: 10 }}>
              <Grid
                item
                container
                style={{
                  border: `1px solid ${color.bordercolor}`,
                  alignItems: "center",
                  overflow: "hidden",
                  position: "relative",
                }}
              >
                <Grid item container>
                  <Table className={styles.customtableMobile}>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Fleet Name</TableCell>
                        <TableCell variant="head">Current Condition</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 12 }}>
                          {tyreDetails?.fleet_name || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 12 }}>
                          <div>
                            <span
                              style={{
                                padding: 8,
                                borderRadius: 4,
                                backgroundColor:
                                  tyreDetails?.tyre_condition === "Average"
                                    ? "#FFFDD9"
                                    : tyreDetails?.tyre_condition === "Good"
                                    ? "#D9FFE4"
                                    : tyreDetails?.tyre_condition === "Bad"
                                    ? "#FFD9D9"
                                    : "",
                              }}
                            >
                              {tyreDetails?.tyre_condition || "-"}
                            </span>
                          </div>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Vehicle No.</TableCell>
                        <TableCell variant="head">Tyre Type</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.vehicle_no || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.tyre_type || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Vehicle Type</TableCell>
                        <TableCell variant="head">Tyre Brand</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.vehicle_type || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.tyre_brand || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Vehicle Brand</TableCell>
                        <TableCell variant="head">Item Description</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.vehicle_brand || "-"}
                        </TableCell>
                        <TableCell variant="body">
                          {tyreDetails?.is_new_tyre === 1
                            ? tyreDetails?.new_tyre_name
                            : tyreDetails?.tyre_pattern_size}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Vehicle Model</TableCell>
                        <TableCell variant="head">Tyre Composition</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.vehicle_model || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.tyre_composition || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">No. of Changes</TableCell>
                        <TableCell variant="head">Ply Rating</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.condition_changes || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {tyreDetails?.ply_rating || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Current NSD</TableCell>
                        <TableCell variant="head">Current Position</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 12 }}>
                          <span
                            style={{
                              color: color.white,
                              padding: 8,
                              borderRadius: 4,
                              backgroundColor: color.primary,
                            }}
                          >
                            {`${tyreDetails?.NSD} mm` || "-"}
                          </span>
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 12 }}>
                          <span
                            style={{
                              color: color.white,
                              padding: 8,
                              borderRadius: 4,
                              backgroundColor: color.primary,
                            }}
                          >
                            {tyreDetails?.tyre_position || "-"}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">
                          Current Tyre Pressure
                        </TableCell>
                        <TableCell variant="head">Current Axle Type</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 12 }}>
                          <span
                            style={{
                              color: color.white,
                              padding: 8,
                              borderRadius: 4,
                              backgroundColor: color.primary,
                            }}
                          >
                            {(tyreDetails?.tyre_air_pressure &&
                              `${tyreDetails?.tyre_air_pressure} psi`) ||
                              "-"}
                          </span>
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 12 }}>
                          <span
                            style={{
                              color: color.white,
                              padding: 8,
                              borderRadius: 4,
                              backgroundColor: color.primary,
                            }}
                          >
                            {tyreDetails?.axle_type || "-"}
                          </span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  </Table>
                </Grid>
              </Grid>
            </div>
          </div>

          <div
            className={styles.container}
            style={{ marginTop: 10, marginBottom: 10, padding: 10 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                lg={10}
                md={10}
                sm={10}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="tableTitle">
                  Tyre Mileage History
                </Typography>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    setPageNo(1);
                    setRecord(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container flexDirection="row" style={{ marginTop: 10 }}>
              <div
                style={{
                  backgroundColor: "#D9F8FF",
                  padding: 6,
                  marginRight: 10,
                  borderRadius: 4,
                }}
              >
                <Typography>
                  {`Total: ${tyreListRows?.pagination?.total_mileage || "-"}`}
                  Km
                </Typography>
              </div>
              <div
                style={{
                  backgroundColor: "rgb(217, 255, 228)",
                  padding: 6,
                  borderRadius: 4,
                }}
              >
                <Typography>
                  {`Previous Reading: ${
                    tyreListRows?.pagination?.previous_reading_km || "-"
                  }`}
                  Km
                </Typography>
              </div>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(tyreListRows?.data) && !isEmpty(tyreListRows?.data) ? (
              <>
                {tyreListRows?.data?.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      style={{
                        marginTop: 10,
                        border: `1px solid ${color.bordercolor}`,
                        alignItems: "center",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <Table className={styles.customtableTyreMileage}>
                        {/* <TableHead>
                          <TableRow>
                            <TableCell variant="head">Old Position</TableCell>
                            <TableCell variant="head">New Position</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.old_position || "-"}
                            </TableCell>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.new_position || "-"}
                            </TableCell>
                          </TableRow>
                        </TableHead> */}
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Vehicle No.</TableCell>
                            <TableCell variant="head">Mileage</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.vehicle_no || "-"}
                            </TableCell>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.mileage || "0"} km
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        {/* <TableHead>
                          <TableRow>
                            <TableCell variant="head">
                              Mechanical Issue
                            </TableCell>
                            <TableCell variant="head">Rotation Date</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {getMechanicalIssue(index)}
                            </TableCell>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {getRotationDate(index) || "-"}
                            </TableCell>
                          </TableRow>
                        </TableHead> */}
                      </Table>
                    </Grid>
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(tyreListRows?.pagination?.totalPage)}
                    defaultPage={pageNo}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      setPageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>

          <div
            className={styles.container}
            style={{ marginTop: 10, marginBottom: 10, padding: 10 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid
                item
                lg={10}
                md={10}
                sm={10}
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography variant="tableTitle">Tyre History</Typography>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    setPageNo1(1);
                    setRecord1(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>

                <Button
                  disabled={isOnline ? false : true}
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  onClick={() => getInventorytyreList(true)}
                >
                  {reportLoader1 ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>

            {pageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(inventoryTyreListRows?.data) &&
              !isEmpty(inventoryTyreListRows?.data) ? (
              <>
                {inventoryTyreListRows?.data?.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      style={{
                        marginTop: 10,
                        border: `1px solid ${color.bordercolor}`,
                        alignItems: "center",
                        overflow: "hidden",
                        position: "relative",
                      }}
                    >
                      <Table className={styles.customtableTyreMileage}>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Old Category</TableCell>
                            <TableCell variant="head">New Category</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.old_category || "-"}
                            </TableCell>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.new_category || "-"}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableHead>
                          <TableRow>
                            <TableCell colSpan={2} variant="head">
                              Changed Date
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={2}
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {moment(item?.created_at).format("DD-MM-yyyy") ||
                                "-"}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </Grid>
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      inventoryTyreListRows?.pagination?.totalPage
                    )}
                    defaultPage={pageNo1}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      setPageNo1(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
