import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    "@media (max-width:425px)": {
      padding: 10,
    },
  },
  fleetMain: {
    backgroundColor: color.white,
    marginTop: 20,
    borderRadius: 4,
    padding: 20,
    overflow: "auto",
    border: `1px solid ${color.bordercolor}`,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  iconOpen: {},
  "table, td, tr, th": {
    border: `1px solid ${color.bordercolor} !important`,
    padding: 10,
    backgroundColor: "red",
  },
  "th, td": {
    textAlign: "center",
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
