import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  Button,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Pagination,
  Tooltip,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Search, SearchOutlined, Visibility } from "@mui/icons-material";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";
import Images from "../../../Config/Images.js";

function TabInspectionHistory(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleSearch = () => null,
    handleDatePicker = () => null,
    pageNo = 1,
    record = 10,
    from = null,
    searchFilter = "",
    datePicker = null,
    data,
  } = props;
  const styles = useStyles();
  const [isFromDashboard, setIsFromDashboard] = useState(from);
  const [inspectionHistoryList, setInspectionListRows] = useState({});
  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  // const [datePicker, setDatePicker] = useState(null);
  const { token } = useSelector((state) => state.auth);
  const [activeIndex, setActiveIndex] = useState(null);
  const [reportLoader, setReportLoader] = useState(false);

  const { isOnline, userdata } = useSelector((state) => state.auth);

  const InspectionHistoryColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 180,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 220,
    },

    {
      field: "inspected_by",
      headerName: "Last Inspected By",
      width: 180,
    },
    {
      field: "Last Inspected On",
      sortable: false,
      headerName: "Last Inspected On",
      width: 180,
      renderCell: (params) => {
        const timestamp = params?.row?.last_inspected_at;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },

    {
      field: "pending_issues_count",
      headerName: "Unresolved Issues",
      width: 180,
      renderCell: (params) => {
        const issueCount = params?.row?.pending_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              if (issueCount === 0) {
                return null;
              } else {
                handleClick("UnresolvedIssue", params?.row);
              }
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : "red",
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "partially_resolved_issues_count",
      headerName: "Partially Resolved Issues",
      width: 230,
      renderCell: (params) => {
        const issueCount = params?.row?.partially_resolved_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              if (issueCount == 0) {
                return null;
              } else {
                handleClick("inspectionIssue", params?.row, "partiallyresolve");
              }
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : color.primary,
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "resolved_issues_count",
      headerName: "Resolved Issues",
      width: 180,
      renderCell: (params) => {
        const issueCount = params?.row?.resolved_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              if (issueCount == 0) {
                return null;
              } else {
                handleClick("inspectionIssue", params?.row, "resolve");
              }
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : color.primary,
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },

    {
      field: "inspection_monthly",
      headerName: "Inspections (Month)",
      width: 200,
    },
    {
      field: "inspection_overall",
      headerName: "Inspections (Overall)",
      width: 200,
    },
  ];

  useEffect(() => {
    if (token !== "") {
      setPageLoad(true);
      getInspectionList(false);
    }
  }, [pageNo, record, token]);

  useEffect(() => {
    if (from === "inspectionHistory") {
      handleDatePicker(moment(new Date()).format("YYYY-MM-DD"));
    }
    if (data?.from === "notificationS") {
      handleDatePicker(moment(data?.date).format("YYYY-MM-DD"));
    }
  }, []);

  useEffect(() => {
    if (token !== "") {
      isNull(datePicker) && isEmpty(searchFilter) && getInspectionList(false);
    }
  }, [datePicker, searchFilter]);

  useEffect(() => {
    setActiveIndex(null);
  }, [pageNo, record]);

  useEffect(() => {
    const section = document.querySelector(`#jump_to_meV`);
    section.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  }, [pageNo, pageLoad]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);

  useImperativeHandle(ref, () => ({
    inspectionHistoryList: inspectionHistoryList,
    downloadFleetData: () => {
      getInspectionList(false, 1);
    },
  }));

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadFleetData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.last_inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Last Inspected By": row?.inspected_by || "-",
        "Last Inspected On": timestamp ? displayDate : "-",
        "Unresolved Issues": Number(row?.pending_issues_count),
        "Partially Resolved Issues": Number(
          row?.partially_resolved_issues_count
        ),
        "Resolved Issues": Number(row?.resolved_issues_count),
        "Inspections (Month)": Number(row?.inspection_monthly),
        "Inspections (Overall)": Number(row?.inspection_overall),
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Inspection History List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inspection History List.xlsx");
  }
  async function getInspectionList(bool, v) {
    v && setReportLoader(true);
    v && handleLoader(true);
    setActiveIndex(null);
    !v && setPageLoad(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : isFromDashboard === "inspectionHistory"
      ? moment(new Date()).format("YYYY-MM-DD")
      : "";
    let endpoints = `${
      Setting.endpoints.inspectionlist
    }?page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&date=${date}&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data?.rows) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadFleetData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setInspectionListRows(listObj);
          }
        else {
          toast.error(response?.message || "No Data Found");
        }
        v && auditSave("Inspection History List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setReportLoader(false);
      handleLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      handleLoader(false);
      setReportLoader(false);
    }
  }

  function resetFilterData() {
    handleSearch("");
    setIsFromDashboard("");
    handleDatePicker(null);
    window.history.state.usr.date = "";
    handlePageNo(1);
  }

  const getLastInspected = (index) => {
    const timestamp = inspectionHistoryList?.data[index]?.last_inspected_at;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const UnresolvedIssues = (index) => {
    const issueCount = inspectionHistoryList?.data[index]?.pending_issues_count;
    return (
      <div
        style={{ display: "flex", flex: 1 }}
        onClick={() => {
          if (issueCount === 0) {
            return null;
          } else {
            handleClick("UnresolvedIssue", inspectionHistoryList?.data[index]);
          }
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            color: issueCount == 0 ? color.disable : "red",
            cursor: "pointer",
          }}
        >
          {issueCount}
        </Typography>
      </div>
    );
  };
  const ResolvedIssues = (index) => {
    const issueCount =
      inspectionHistoryList?.data[index]?.resolved_issues_count;
    return (
      <div
        style={{ display: "flex", flex: 1 }}
        onClick={() => {
          if (issueCount == 0) {
            return null;
          } else {
            handleClick(
              "inspectionIssue",
              inspectionHistoryList?.data[index],
              "resolve"
            );
          }
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            color: issueCount == 0 ? color.disable : color.primary,
            cursor: "pointer",
          }}
        >
          {issueCount}
        </Typography>
      </div>
    );
  };
  const PartialResolveIssues = (index) => {
    const issueCount =
      inspectionHistoryList?.data[index]?.partially_resolved_issues_count;
    return (
      <div
        style={{ display: "flex", flex: 1 }}
        onClick={() => {
          if (issueCount == 0) {
            return null;
          } else {
            handleClick(
              "inspectionIssue",
              inspectionHistoryList?.data[index],
              "partiallyresolve"
            );
          }
        }}
      >
        <Typography
          style={{
            fontWeight: "bold",
            color: issueCount == 0 ? color.disable : color.primary,
            cursor: "pointer",
          }}
        >
          {issueCount}
        </Typography>
      </div>
    );
  };

  return (
    <>
      {isTablet ? (
        <div>
          <Grid
            id="jump_to_meV"
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 10 }}
            wrap={"nowrap"}
          >
            <Grid
              item
              lg={8}
              style={{ display: "flex", alignItems: "center" }}
              wrap={"nowrap"}
            >
              <Grid
                item
                lg={4}
                style={{ display: "flex", alignItems: "center" }}
                wrap={"nowrap"}
              >
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getInspectionList(true);
                    handleSearch(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                        handlePageNo(1);
                        getInspectionList(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Fleet Name and Vehicle No."
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <div style={{ marginLeft: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={
                      isFromDashboard === "inspectionHistory" ? true : false
                    }
                    disableFuture
                    value={datePicker}
                    onChange={(newValue) => {
                      handleDatePicker(newValue);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div
                style={{ marginLeft: 10, display: "flex", flexWrap: "nowrap" }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      getInspectionList(false);
                    }
                  }}
                >
                  <SearchOutlined />
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  disabled={!searchFilter && !datePicker}
                  onClick={resetFilterData}
                >
                  <ResetIcon />
                </Button>
              </div>
            </Grid>
            <Grid
              item
              lg={1.6}
              style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
            >
              <Select
                fullWidth
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(inspectionHistoryList?.data) &&
            !isEmpty(inspectionHistoryList?.data) ? (
            <div style={{ marginTop: 10 }}>
              <DataGrid
                rows={inspectionHistoryList?.data}
                columns={InspectionHistoryColumn}
                disableColumnMenu
                hideFooter
                showCellRightBorder
                disableSelectionOnClick
                showColumnRightBorder
                autoHeight={true}
                onCellClick={(params) => {
                  if (
                    params?.field !== "pending_issues_count" &&
                    params?.field !== "resolved_issues_count" &&
                    params?.field !== "partially_resolved_issues_count"
                  ) {
                    handleClick("inspectionHistory", params?.row);
                  }
                }}
              />
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(
                    inspectionHistoryList?.pagination?.totalPage
                  )}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Grid
            id="jump_to_meV"
            container
            alignItems="center"
            style={{ marginTop: 10 }}
            columnGap={1}
            wrap={"nowrap"}
          >
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disabled={
                    isFromDashboard === "inspectionHistory" ? true : false
                  }
                  disableFuture
                  value={datePicker}
                  onChange={(newValue) => {
                    handleDatePicker(newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Select
                fullWidth
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Grid item marginLeft={"auto"}>
              <Button
                variant="contained"
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                onClick={() => {
                  getInspectionList(false, 1);
                }}
                disabled={isOnline ? false : true}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            style={{ display: "flex", alignItems: "center", marginTop: 10 }}
            wrap={"nowrap"}
          >
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={searchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && getInspectionList(true);
                handleSearch(v?.target?.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    handlePageNo(1);
                    getInspectionList(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Fleet Name and Vehicle No."
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <Grid
              style={{ marginLeft: 10, display: "flex", flexWrap: "nowrap" }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    getInspectionList(false);
                  }
                }}
              >
                <SearchOutlined />
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                disabled={!searchFilter && !datePicker}
                onClick={resetFilterData}
              >
                <ResetIcon />
              </Button>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(inspectionHistoryList?.data) &&
            !isEmpty(inspectionHistoryList?.data) ? (
            <>
              {inspectionHistoryList?.data?.map((item, index) => {
                return (
                  <div style={{ position: "relative" }}>
                    <Grid
                      item
                      id={`jump_to_me${index}`}
                      container
                      style={{
                        border: `1px solid ${color.bordercolor}`,
                        alignItems: "center",
                        boxShadow: color.shadow,
                        overflow: "hidden",
                        marginTop: 20,
                      }}
                    >
                      <Grid item container>
                        <Table className={styles.customtable}>
                          {activeIndex !== index ? (
                            <>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle No.
                                  </TableCell>
                                  <TableCell variant="head">
                                    Last Inspected On
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell variant="body">
                                    {item?.vehicle_no || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getLastInspected(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Last Inspected By
                                  </TableCell>
                                  <TableCell variant="head">
                                    Unresolved Issues
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.inspected_by || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {UnresolvedIssues(index)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </>
                          ) : (
                            <>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle No.
                                  </TableCell>
                                  <TableCell variant="head">
                                    Last Inspected On
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell variant="body">
                                    {item?.vehicle_no || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getLastInspected(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Last Inspected By
                                  </TableCell>
                                  <TableCell variant="head">
                                    Unresolved Issues
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.inspected_by || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {UnresolvedIssues(index)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Partially Resolved Issues
                                  </TableCell>
                                  <TableCell variant="head">
                                    Resolved Issues
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {PartialResolveIssues(index)}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {ResolvedIssues(index)}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Fleet Name
                                  </TableCell>
                                  <TableCell variant="head">
                                    Inspections
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.fleet_name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {
                                      <div>
                                        <Typography>
                                          Month: {item?.inspection_monthly}
                                        </Typography>
                                        <Typography>
                                          Overall: {item?.inspection_overall}
                                        </Typography>
                                      </div>
                                    }
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    colSpan={2}
                                    align="right"
                                    style={{ padding: "10px 20px 10px 0" }}
                                  >
                                    <Button
                                      style={{ backgroundColor: color.primary }}
                                      onClick={() =>
                                        handleClick("inspectionHistory", item)
                                      }
                                    >
                                      <Visibility />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </>
                          )}
                        </Table>
                      </Grid>
                      <div
                        style={{
                          zIindex: 10,
                          color: color.primary,
                          display: "flex",
                          justifyContent: "center",
                          position: "absolute",
                          bottom: -18,
                          left: "45%",
                        }}
                        onClick={() => {
                          activeIndex === index
                            ? setActiveIndex(null)
                            : setActiveIndex(index);
                        }}
                      >
                        {activeIndex === index ? (
                          <div style={{ transform: "rotate(180deg)" }}>
                            <TableToggle />
                          </div>
                        ) : (
                          <TableToggle />
                        )}
                      </div>
                    </Grid>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  marginTop: 30,
                  marginBottom: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(
                    inspectionHistoryList?.pagination?.totalPage
                  )}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}
export default forwardRef(TabInspectionHistory);
