import React, { useEffect, useState } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Link,
  Modal,
  Tooltip,
  Fade,
  Backdrop,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from "@mui/x-data-grid";
import MDropDown from "../../../Components/Modal/MDropDown/index.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isObject } from "lodash";
import { toast } from "react-toastify";
import authActions from "../../../Redux/reducers/auth/actions";
import { Close, CopyAll, Delete, Edit } from "@mui/icons-material";
import ConfirmDialog from "../../../Components/ConfirmDialog/index.js";
import { CTypography } from "../../../Components/Fleet/AddFleetForm/index.js";
import { Box } from "@mui/system";

export default function GeneralDropdowns() {
  const styles = useStyles();

  const location = useLocation();
  const data = location?.state?.data;
  const type = data?.type;

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [file, setFile] = useState("No File Selected");
  const [selectedFile, setSlectedFile] = useState(null);
  const [mediaModal, setMediaModal] = useState(false);

  const [visible, setVisible] = useState(false);
  const { dropDownData } = useSelector((state) => state.auth);
  const [dropDownArr, setDropDownArr] = useState([]);
  const [pageLoad, setPageLoad] = useState(true);
  const [editData, setEditData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [modal, openModal] = useState(false);
  const [imgURL, setimgURL] = useState("");
  const dispatch = useDispatch();
  const { setDropDownData } = authActions;
  const { token } = useSelector((state) => state.auth);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: md ? 300 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    padding: 0,
  };

  const DropDownColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        return <Typography>{index}</Typography>;
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "label",
      headerName: "Label",
      flex: 1,
    },
    {
      field: "image",
      headerName: "Image",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      hide:
        data?.type === "tyre_issue_scrap_analysis" ||
        data?.type === "tyre_issue_inspection" ||
        data?.type === "vehicle_type" ||
        data?.type === "tyre_removal_issue" ||
        data?.type === "service_centre_issue" ||
        data?.type === "mechanical_issue"
          ? false
          : true,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: isEmpty(params?.row?.image)
                ? color.disable
                : color.primary,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              setimgURL(params?.row?.image);
              setTimeout(
                () => !isEmpty(params?.row?.image) && openModal(true),
                200
              );
            }}
          >
            View
          </Typography>
        );
      },
    },
    {
      field: "issue_type",
      headerName: "Issue Type",
      headerAlign: "center",
      align: "center",
      width: 150,
      hide:
        data?.type === "tyre_issue_inspection" ||
        data?.type === "tyre_issue_scrap_analysis" ||
        data?.type === "tyre_removal_issue" ||
        data?.type === "mechanical_issue"
          ? false
          : true,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              fontWeight: "bold",
              cursor: "pointer",
            }}
          >
            {params?.row?.issue_type === "major"
              ? "Major"
              : params?.row?.issue_type === "minor"
              ? "Minor"
              : params?.row?.issue_type === "no_issue"
              ? "No Issue"
              : "-"}
          </Typography>
        );
      },
    },
    {
      field: "Edit",
      headerName: "Edit",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title="Edit" placement="right" arrow>
            <Edit
              style={{ color: color.primary, cursor: "pointer" }}
              onClick={() => {
                setEditData(params?.row);
                setVisible(true);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "Delete",
      headerAlign: "center",
      headerName: "Delete",
      width: 100,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Delete
            style={{
              color:
                (type === "tyre_issue_inspection" &&
                  params?.row.label === "N/A") ||
                (type === "mechanical_issue" && params?.row.label === "N/A") ||
                (type === "tyre_type" &&
                  (params?.row.id === 91 ||
                    params?.row.id === 45 ||
                    params?.row.id === 46))
                  ? color.disable
                  : "red",
              cursor: "pointer",
            }}
            onClick={() => {
              if (
                (type === "tyre_issue_inspection" &&
                  params?.row.label === "N/A") ||
                (type === "tyre_type" &&
                  (params?.row.id === 91 ||
                    params?.row.id === 45 ||
                    params?.row.id === 46))
              ) {
                return null;
              } else {
                setEditData(params?.row);
                setConfirmDialog(true);
              }
            }}
          />
        );
      },
    },
  ];

  useEffect(() => {
    setPageLoad(true);
    setDropDownArr([]);
    document.title = Setting.page_name.GeneralDropdowns;

    if (type === "vehicle_type") {
      setDropDownArr(dropDownData?.vehicle_type);
    } else if (type === "vehicle_brand") {
      setDropDownArr(dropDownData?.vehicle_brand);
    } else if (type === "terrain") {
      setDropDownArr(dropDownData?.terrain);
    } else if (type === "tyre_condition") {
      setDropDownArr(dropDownData?.tyre_condition);
    } else if (type === "tyre_brand") {
      setDropDownArr(dropDownData?.tyre_brand);
    } else if (type === "tyre_type") {
      setDropDownArr(dropDownData?.tyre_type);
    } else if (type === "ply_rating") {
      setDropDownArr(dropDownData?.ply_rating);
    } else if (type === "tyre_composition") {
      setDropDownArr(dropDownData?.tyre_composition);
    } else if (type === "axle_type") {
      setDropDownArr(dropDownData?.axle_type);
    } else if (type === "tyre_issue_inspection") {
      setDropDownArr(dropDownData?.tyre_issue_inspection);
    } else if (type === "tyre_issue_scrap_analysis") {
      setDropDownArr(dropDownData?.tyre_issue_scrap_analysis);
    } else if (type === "office_region") {
      setDropDownArr(dropDownData?.office_region);
    } else if (type === "tyre_removal_issue") {
      setDropDownArr(dropDownData?.tyre_removal_issue);
    } else if (type === "district") {
      setDropDownArr(dropDownData?.district);
    } else if (type === "service_centre_issue") {
      setDropDownArr(dropDownData?.service_centre_issue || []);
    } else if (type === "tread_pattern") {
      setDropDownArr(dropDownData?.tread_pattern_data || []);
    } else if (type === "mechanical_issue") {
      setDropDownArr(dropDownData?.mechanical_issue || []);
    }
    setPageLoad(false);
  }, [data, dropDownData]);

  async function getDropDownData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.dropDown}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          dispatch(setDropDownData(response?.data));
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  async function deleteData() {
    setRemoveBtnLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.deleteDropDown}?id=${editData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setConfirmDialog(false);
        setEditData({});
        getDropDownData();
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function uploadImg(file) {
    let endPoints = Setting.endpoints.uploadImg;
    const params = {
      "ImageForm[photo]": file,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        const url = resp?.data;
        const UrlName = url.substring(url.indexOf("uploads/") + 8);
        setFile(UrlName);
        setTimeout(() => setMediaModal(true), 200);
      } else {
        toast.error(resp?.message);
      }
      // setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      // setLoader(false);
    }
  }

  function modalClose() {
    setMediaModal(false);
    setSlectedFile(null);
    setFile("No File Selected");
  }

  return (
    <div className={styles.card}>
      <Grid container>
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator="›">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            <Typography
              // underline="hover"
              color="inherit"
              className={styles.linkStyle}
            >
              General Dropdowns
            </Typography>
            <Typography className={styles.linkStyle}>{data?.name}</Typography>
          </Breadcrumbs>
        </Grid>
      </Grid>
      <div className={styles.container}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ backgroundColor: color.primary, padding: 20 }}
        >
          <Grid xs={6} sm={6} alignItems="center">
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              {data?.name}
            </Typography>
          </Grid>
          {type !== "Media_Uploads" && (
            <Grid
              xs={6}
              sm={6}
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  setVisible(true);
                }}
              >
                Add Data
              </Button>
            </Grid>
          )}
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : type !== "Media_Uploads" &&
          isArray(dropDownArr) &&
          isEmpty(dropDownArr) ? (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        ) : type !== "Media_Uploads" ? (
          <div>
            <DataGrid
              rows={dropDownArr}
              columns={DropDownColumns}
              autoHeight={true}
              disableColumnMenu
              hideFooter
              disableSelectionOnClick
              showCellRightBorder
              showColumnRightBorder
            />
          </div>
        ) : (
          <div style={{ padding: 20 }}>
            <Grid container alignItems={"center"} style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography
                  title={"Upload Media"}
                  style={{ marginRight: 20 }}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={10}
                lg={10}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography
                  style={{
                    color:
                      !selectedFile || selectedFile === ""
                        ? "#999"
                        : color.black,
                  }}
                >
                  {file}
                </Typography>
                <Button
                  variant="contained"
                  component="label"
                  style={{ backgroundColor: color.secondary, margin: "0 20px" }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      uploadImg(e.target.files[0]);
                      setSlectedFile(e.target.files[0]);
                    }}
                    accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg, application/pdf"
                    hidden
                  />
                  Upload File
                </Button>
              </Grid>
            </Grid>
          </div>
        )}
      </div>
      <MDropDown
        visible={visible}
        handleModal={() => {
          setVisible(false);
          setEditData({});
        }}
        data={data}
        onSave={() => {
          getDropDownData();
          setEditData({});
        }}
        editData={editData}
      />
      <ConfirmDialog
        title={`Are you sure you want to Delete ${data?.name}?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteData();
          } else {
            setEditData({});
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
      <Modal
        className={styles.modal}
        onClose={() => {
          openModal(false);
        }}
        open={modal}
      >
        <img
          style={{
            objectFit: "contain",
            borderRadius: 4,
            maxWidth: 550,
            maxHeight: 550,
          }}
          src={imgURL ? imgURL : ""}
          alt=""
        />
      </Modal>
      <Modal
        open={mediaModal}
        closeAfterTransition
        disableAutoFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        onClose={() => {
          modalClose();
        }}
      >
        <Fade in={mediaModal}>
          <Box sx={style}>
            <div className={styles.modalHeader1}>
              <Typography
                style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
              >
                Media Name
              </Typography>
              <Close
                style={{ color: color.white, cursor: "pointer" }}
                onClick={() => modalClose()}
              />
            </div>
            <div className={styles.modalMain}>
              <Typography>{file}</Typography>
              <CopyAll
                style={{ cursor: "pointer", marginLeft: 20 }}
                onClick={() => {
                  navigator.clipboard.writeText(file);
                  toast.success("Copied!", { toastId: 10 });
                }}
              />
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}
