import React, { useEffect, useMemo, useRef, useState } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Tab,
  Tabs,
  Box,
  Button,
  Grid,
  TextField,
  Select,
  MenuItem,
  InputAdornment,
  Pagination,
  CircularProgress,
  ListItemButton,
  Tooltip,
  Divider,
  Switch,
} from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import { color } from "../../../Config/theme.js";
import { Edit, FilterAlt, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import InfoIcon from "@mui/icons-material/Info";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import MDateRange from "../../Modal/MDateRange/index.js";

export default function FleetView(props) {
  const {
    handleClick = () => null,
    handlePageNoFL = () => null,
    handlePageNoV = () => null,
    handleRecordFL = () => null,
    handleRecordV = () => null,
    handleFilterFL = () => null,
    handleFilterV = () => null,
    handleSearch = () => null,
    handleSearchV = () => null,
    handleDatePicker = () => null,
    handleDatePicker1 = () => null,
    handleDatePickerV = () => null,
    handleDatePickerV1 = () => null,
    pageNo = 1,
    pageNoV = 1,
    record = 10,
    recordV = 10,
    from,
    tab = 0,
    filter = "",
    filterV = "",
    searchFilter = "",
    searchFilterV = "",
    datePicker = null,
    datePicker1 = null,
    datePickerV = null,
    datePickerV1 = null,
  } = props;
  const styles = useStyles();
  const [tabValue, setTabValue] = useState(0);

  // const [filter, setFilter] = useState("");
  // const [searchFilter, setSearchFilter] = useState("");

  const [districtList, setDistrictList] = useState(false);
  const [RoList, setRoList] = useState(false);

  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [terrainList, setterrainList] = useState(false);

  // const [filterV, setFilterV] = useState("");
  // const [searchFilterV, setSearchFilterV] = useState("");

  const [pageLoad, setPageLoad] = useState(true);
  const [pageLoadV, setPageLoadV] = useState(true);

  const [fleetListRows, setFleetListRows] = useState({});
  const [vehicleListRows, setVehicleListRows] = useState({});

  // const [datePicker, setDatePicker] = useState(null);
  // const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);
  // const [datePickerV, setDatePickerV] = useState(null);
  // const [datePickerV1, setDatePickerV1] = useState(null);
  const [disableV, setDisableV] = useState(true);
  const [visible, setVisible] = useState(false);
  const [ind, setInd] = useState("");
  const [reportLoader, setReportLoader] = useState(false);

  const { token, dropDownData, isOnline, userdata, permissionData } =
    useSelector((state) => state.auth);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const staticArr = [
    "Resolved Issues Master Report",
    "Partially Resolved Issues Master Report",
    "Unresolved Issues Master Report",
    "NSD < 5 mm",
  ];

  const [staticArray, setStaticArr] = useState([...staticArr]);

  // master report download loader state
  const [arrReportLoader, setArrReportLoader] = useState(false);

  // intialRender used for stop multiple time apiCall in
  const initialRenderFleetList = useRef(true);
  const initialRenderVehicleList = useRef(true);

  useEffect(() => {
    const dummy_Arr = [...staticArr];
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Resolved Issues Report") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(0, 1);
          }
          if (v?.label === "Partially Resolved Issues Report") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(1, 1);
          }
          if (v?.label === "Unresolved Issues Report") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(2, 1);
          }
          if (v?.label === "NSD <5 mm") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(3, 1);
          }
        });
      });
    }
    setStaticArr(dummy_Arr);
  }, [permissionData]);

  const FleetManagementColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const active =
          userdata?.role === "service_enginner" ||
          userdata?.role === "fleet_owner"
            ? false
            : true;
        return (
          <Tooltip title={active ? "Edit" : ""} placement="right" arrow>
            <Edit
              style={{
                color: active ? color.primary : color.disable,
                cursor: "pointer",
              }}
              onClick={() => {
                if (active) {
                  handleClick("addFleet", params?.row);
                } else {
                  return null;
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "name",
      headerName: "Fleet Name",
      width: 260,
    },
    {
      field: "code",
      headerName: "Fleet Code",
      width: 140,
    },
    {
      field: "district_id",
      headerName: "District",
      width: 140,
      renderCell: (params) => {
        let array = params?.row?.district_id;
        let res =
          !isNull(array) &&
          dropDownData?.district?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "office_region_id",
      headerName: "Regional Office",
      width: 160,
      renderCell: (params) => {
        let array = params?.row?.office_region_id;
        let res =
          !isNull(array) &&
          dropDownData?.office_region?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicles_allocated",
      headerName: "No. of Vehicles",
      width: 160,
    },
    {
      field: "pending_issues_count",
      headerName: "Unresolved Issues",
      width: 180,
      renderCell: (params) => {
        const issueCount = params?.row?.pending_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              if (issueCount == 0) {
                return null;
              } else {
                handleClick("UnresolvedIssue", params?.row);
              }
            }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : "red",
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "contact_name",
      headerName: "Contact Person",
      width: 180,
    },
    {
      field: "contact_phone",
      headerName: "Mobile No.",
      width: 150,
    },
    {
      field: "owner_name",
      headerName: "Owner",
      width: 140,
    },
    {
      field: "owner_phone",
      headerName: "Owner's No.",
      width: 150,
    },
    {
      field: "capture_date",
      headerName: "Added On",
      width: 180,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      width: 150,
    },
  ];

  const VehicleManagementColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          pageNoV === 1 ? index : (pageNoV - 1) * recordV + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const active =
          userdata?.role !== "fleet_owner" && params?.row?.status === 1;
        return (
          <Tooltip title={active ? "Edit" : ""} placement="right" arrow>
            <Edit
              style={{
                color: active ? color.primary : color.disable,
                cursor: "pointer",
              }}
              onClick={() => {
                if (active) {
                  handleClick("editVehicle", params?.row);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 140,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 180,
    },

    {
      field: "status",
      headerName: "Vehicle Status",
      width: 130,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Switch
            checked={params?.row?.status === 1 ? true : false}
            onChange={() => {
              changeVehicleStatus(params?.row);
            }}
          />
        );
      },
    },

    {
      field: "vehicle_type_id",
      headerName: "Vehicle Type",
      width: 160,
      renderCell: (params) => {
        let array = params?.row?.vehicle_type_id;
        let res =
          !isNull(array) &&
          dropDownData?.vehicle_type?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_brand_id",
      headerName: "Vehicle Brand",
      width: 180,
      renderCell: (params) => {
        let array = params?.row?.vehicle_brand_id;
        let res =
          !isNull(array) &&
          dropDownData?.vehicle_brand?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_model_name",
      headerName: "Vehicle Model",
      width: 180,
      sortable: false,
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: 150,
      renderCell: (params) => {
        return (
          <>
            <span
              style={{
                color: params?.row?.added_tyre_count != (0 || "") ? "red" : "",
              }}
            >
              {params?.row?.added_tyre_count}
            </span>
            <span>/{params?.row?.no_of_tyres}</span>
          </>
        );
      },
    },
    {
      field: "current_reading",
      headerName: "Initial Reading",
      width: 180,
      sortable: false,
      renderCell: (params) => {
        return <Typography>{params?.row?.current_reading} Km</Typography>;
      },
    },
    {
      field: "terrain_ids",
      headerName: "Terrain",
      sortable: false,
      width: 200,
      renderCell: (params) => {
        let array = params?.row.terrain_ids.split(",");
        let res = dropDownData.terrain.filter((item) => {
          let a = array.includes(item?.id?.toString());
          return a;
        });
        return (
          <span
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {res.map((items, i) => {
              return (
                <Typography>
                  {items?.label}
                  {i < res.length - 1 ? ", " : ""}
                </Typography>
              );
            })}
          </span>
        );
      },
    },
    {
      field: "load_capicity",
      headerName: "Load Capacity",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return <Typography>{params?.row?.load_capicity} Tonnes</Typography>;
      },
    },
    {
      field: "driver_name",
      headerName: "Driver's Name",
      width: 160,
      renderCell: (params) => {
        return params?.row?.driver_name || "-";
      },
    },
    {
      field: "driver_phone",
      headerName: "Driver's No.",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.driver_phone || "-";
      },
    },
    {
      field: "goods_carried",
      headerName: "Goods Carried",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.goods_carried || "-";
      },
    },
    {
      field: "route_origin",
      headerName: "Route Origin",
      width: 140,
      renderCell: (params) => {
        return params?.row?.route_origin || "-";
      },
    },
    {
      field: "route_destination",
      headerName: "Route Destination",
      width: 180,
      sortable: false,
      renderCell: (params) => {
        return params?.row?.route_destination || "-";
      },
    },
    // {
    //   field: "Alignments Claimed",
    //   headerName: "Alignments Claimed",
    //   width: 180,
    //   sortable: false,
    //   renderCell: (params) => {
    //     return <Typography>{`${params?.row?.alignment_count}/5`}</Typography>;
    //   },
    // },
    {
      field: "created_at",
      headerName: "Added On",
      width: 180,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      width: 140,
    },
  ];

  const DataGridRender = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={fleetListRows?.data}
        columns={FleetManagementColumns}
        onCellClick={(params) => {
          if (
            params?.field !== "action" &&
            params?.field !== "pending_issues_count"
          ) {
            handleClick("fleetDetails", params?.row);
          }
        }}
        pageSize={record}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
      />
    );
  }, [fleetListRows]);

  const DataGridRender1 = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={vehicleListRows?.data}
        columns={VehicleManagementColumns}
        onCellClick={(params) => {
          if (params?.field !== "action" && params?.field !== "status") {
            handleClick("vehicleDetails", params?.row);
          }
        }}
        pageSize={recordV}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        getRowHeight={() => "auto"}
        showColumnRightBorder
        disableSelectionOnClick
      />
    );
  }, [vehicleListRows]);

  useEffect(() => {
    if (from === "vehicleList") {
      setTimeout(() => setTabValue(1), 200);
    }
  }, []);

  useEffect(() => {
    // if (initialRenderFleetList?.current) {
    //   initialRenderFleetList.current = false;
    // } else {
    getFleetList(false);
    // }
  }, [record, pageNo, filter, from]);

  useEffect(() => {
    // if (initialRenderVehicleList?.current) {
    //   initialRenderVehicleList.current = false;
    // } else {
    setPageLoadV(true);
    getVehicleist(false);
    // }
  }, [recordV, pageNoV, filterV]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getFleetList(false);
    if (!isNull(datePicker)) {
      setDisable(false);
    }
  }, [datePicker, datePicker1, searchFilter]);

  useEffect(() => {
    isNull(datePickerV) && isEmpty(searchFilterV) && getVehicleist(false);
    if (!isNull(datePickerV)) {
      setDisableV(false);
    }
  }, [datePickerV, datePickerV1, searchFilterV]);

  useEffect(() => {
    setTabValue(tab);
  }, [tab]);

  const changeVehicleStatus = async (data) => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.changeVehicleStatus}?id=${data?.id}&status=${
          data?.status === 1 ? 0 : 1
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        getVehicleist(false, "", true);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Fleet Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getFleetList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);

    const fromDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePicker1)
      ? moment(datePicker1).format("YYYY-MM-DD")
      : "";

    try {
      const response = await getApiData(
        `${
          Setting.endpoints.fleetList
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&from_date=${fromDate}&to_date=${toDate}&filter=${filter}&is_download=${
          v ? v : ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadFleetData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setFleetListRows(listObj);
          }
        v && auditSave("Fleet List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error?.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  async function getVehicleist(bool, v, isToggle) {
    !v && isToggle !== true && setPageLoadV(true);
    v && setReportLoader(true);
    const terrainMatch = dropDownData?.terrain?.find((v) => v?.id === filterV);

    const fromDate = !isNull(datePickerV)
      ? moment(datePickerV).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePickerV1)
      ? moment(datePickerV1).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.vehicleList
        }?per_page=${recordV}&page=${pageNoV}&search=${
          bool ? "" : searchFilterV
        }&filter=${isEmpty(terrainMatch) ? filterV : ""}&terrain_ids=${
          !isEmpty(terrainMatch) ? filterV : ""
        }&from_date=${fromDate}&to_date=${toDate}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadVehicleData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setVehicleListRows(listObj);
          }
        v && auditSave("Vehicle List");
      } else {
        toast.error(response?.message);
      }
      setPageLoadV(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoadV(false);
      toast.error(error?.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  function resetFilterData() {
    handleSearch("");
    handleDatePicker(null);
    handleDatePicker1(null);
    handleFilterFL("");
    handlePageNoFL(1);
  }

  function resetVehicleData() {
    handleFilterV("");
    handleSearchV("");
    handleDatePickerV(null);
    handleDatePickerV1(null);
    handlePageNoV(1);
  }

  function downloadFleetData(data) {
    const newData = data.map((row, index) => {
      let district =
        !isNull(row?.district_id) &&
        dropDownData?.district?.find(
          (item) => row?.district_id?.toString() === item?.id?.toString()
        );
      let Regional =
        !isNull(row?.office_region_id) &&
        dropDownData?.office_region?.find(
          (item) => row?.office_region_id?.toString() === item?.id?.toString()
        );
      const timestamp = row?.capture_date;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        ID: row?.id,
        "Fleet Name": row?.name,
        "Fleet Code": row?.code || "-",
        // Location: row?.city,
        District: district?.label || "-",
        "Regional Office": Regional?.label || "-",
        "No. of Vehicles": Number(row?.vehicles_allocated),
        "Unresolved Issues": row?.pending_issues_count || "-",
        "Contact Person": row?.contact_name || "-",
        "Mobile No.": row?.contact_phone || "-",
        Owner: row?.owner_name || "-",
        "Owner's No.": row?.owner_phone || "-",
        "Added On": timestamp ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Fleet List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Fleet List.xlsx");
  }

  function downloadVehicleData(data) {
    const newData = data.map((row, index) => {
      let vehicleType =
        !isNull(row?.vehicle_type_id) &&
        dropDownData?.vehicle_type?.find(
          (item) => row?.vehicle_type_id?.toString() === item?.id?.toString()
        );
      let vehicleBrand =
        !isNull(row?.vehicle_brand_id) &&
        dropDownData?.vehicle_brand?.find(
          (item) => row?.vehicle_brand_id?.toString() === item?.id?.toString()
        );

      const getTerrain = () => {
        let array = row?.terrain_ids?.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array?.includes(item?.id?.toString());
          return a;
        });
        const arr = [];
        res.map((v, i) => arr.push(v?.label));
        return arr?.toString();
      };

      const timestamp = row?.capture_date;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        ID: row?.id,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Vehicle Status": row?.status === 1 ? "Active" : "In Active",
        "Vehicle Type": vehicleType?.label,
        "Vehicle Brand": vehicleBrand?.label,
        "Vehicle Model": row?.vehicle_model_name,
        "No. of Tyres": `${row?.added_tyre_count}/${row?.no_of_tyres}`,
        "Initial Reading (Km)": Number(
          row?.current_reading.toString()?.replaceAll(",", "")
        ),
        Terrain: getTerrain() || "-",
        "Load Capacity": `${row?.load_capicity}${
          row?.load_capicity ? " Tonnes" : "-"
        }`,
        "Driver's Name": row?.driver_name || "-",
        "Driver's No.": row?.driver_phone || "-",
        "Goods Carried": row?.goods_carried || "-",
        "Route Origin": row?.route_origin || "-",
        "Route Destination": row?.route_destination || "-",
        // "Alignments Claimed": `${row?.alignment_count || 0}/5`,
        "Added On": timestamp ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Vehicle List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Vehicle List.xlsx");
  }

  async function getUnresolvedIssue(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll
  ) {
    setArrReportLoader(true);
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        isAll
          ? `${
              Setting.endpoints.pendingIssueReport
            }?fleet_id=${fleetIds}&status=${0}&is_all=${1}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`
          : `${
              Setting.endpoints.pendingIssueReport
            }?fleet_id=${fleetIds}&status=${0}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setArrReportLoader(false);
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReports1(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave("Unresolved Issues Report");
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error?.toString());
      setArrReportLoader(false);
    }
  }

  function downloadReports1(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1 || "-",
        "Fleet Name": item?.fleet_name || "-",
        RO: item?.fk_ro_id || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Tyre Issue": item?.tyre_issue?.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue?.toString() || "-",
        Remarks: item?.remarks || "-",
        "Inspected By": item?.inspected_by || "-",
        "Inspected On": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Unresolved List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Unresolved List.xlsx");
  }

  // async function getpartiallyresolved(
  //   fleetIds,
  //   vehicleIds,
  //   fromDate,
  //   toDate,
  //   isAll
  // ) {
  //   const from_date = !isNull(fromDate)
  //     ? moment(fromDate).format("YYYY-MM-DD")
  //     : "";
  //   const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
  //   try {
  //     const response = await getApiData(
  //       isAll
  //         ? `${
  //             Setting.endpoints.pendingIssueReport
  //           }?fleet_id=${fleetIds}&status=${2}&is_all=${1}}&from_date=${from_date}&to_date=${to_date}`
  //         : `${
  //             Setting.endpoints.pendingIssueReport
  //           }?fleet_id=${fleetIds}&status=${2}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${to_date}`,
  //       "GET",
  //       {},
  //       { Authorization: `Bearer ${token}` }
  //     );
  //     if (response.status) {
  //       if (isArray(response?.data) && !isEmpty(response.data)) {
  //         downloadReports2(response?.data);
  //       } else {
  //         toast.error(response?.message ? response?.message : "No Data Found");
  //       }
  //     } else {
  //       toast.error(response?.message);
  //     }
  //   } catch (error) {
  //     console.log("error ===>>>", error);
  //     toast.error(error.toString());
  //     // setReportLoader(false);
  //   }
  // }

  function downloadReports2(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name || "-",
        RO: item?.fk_ro_id || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        Issue: item?.issue.toString() || "-",
        Remarks: item?.remarks || "-",
        "Inspected By": item?.inspected_by || "-",
        "Inspected On": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Partially Resolved Issue Report"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Partially Resolved Issues Report.xlsx");
  }

  async function getResolvedissuereport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll,
    status
  ) {
    setArrReportLoader(true);
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        isAll
          ? `${
              Setting.endpoints.resolvedissuereport
            }?fleet_id=${fleetIds}&is_all=${1}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }&status=${status}`
          : `${
              Setting.endpoints.resolvedissuereport
            }?fleet_id=${fleetIds}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }&status=${status}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setArrReportLoader(false);
        if (isArray(response?.data) && !isEmpty(response.data)) {
          if (status === 1) {
            downloadReports3(response?.data);
          } else {
            downloadReports2(response?.data);
          }
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave(
          status === 1
            ? "Resolved Issues Report"
            : "Partially Resolved Issues Report"
        );
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setArrReportLoader(false);
    }
  }

  function downloadReports3(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const timestamp1 = item?.resolved_at;
      const date = new Date(timestamp1 * 1000);
      const displayDate1 = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1 || "-",
        "Fleet Name": item?.fleet_name || "-",
        RO: item?.fleet_ro || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        Issue: item?.issue.toString() || "-",
        Remarks: item?.remarks || "-",
        "Inspected By": item?.inspected_by || "-",
        "Inspected On": timestamp ? displayDate : "-",
        "Resolved By": item?.resolved_by || "-",
        "Resolved On": timestamp1 ? displayDate1 : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Resolved Issues Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Resolved Issues Report.xlsx");
  }

  async function getavgNsdReport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll
  ) {
    setArrReportLoader(true);
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        isAll
          ? `${
              Setting.endpoints.avgnsdreport
            }?fleet_id=${fleetIds}&is_all=${1}}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`
          : `${
              Setting.endpoints.avgnsdreport
            }?fleet_id=${fleetIds}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setArrReportLoader(false);
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReports4(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave("NSD < 5 mm");
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setArrReportLoader(false);
    }
  }

  function downloadReports4(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.inspected_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const nsdArr = data[index]?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1 || "-",
        "Fleet Name": item?.fleet_name,
        RO: item?.fleet_ro || "-",
        District: item?.district || "-",
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Mileage (Km)": Number(item?.mileage.toString()?.replaceAll(",", "")),
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(item?.avg_nsd) || "-",
        "Tyre Issue": item?.tyre_issue.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue.toString() || "-",
        "Last Inspected By": item?.inspected_by || "-",
        "Last Inspected On": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "NSD < 5 mm");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "NSD < 5 mm.xlsx");
  }

  return (
    <div className={styles.fleetMain}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ height: 40 }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="tableTitle">Fleet Management</Typography>
        </Grid>
        {tabValue !== 2 && (
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: sm ? "unset" : "flex-end",
            }}
          >
            <Button
              variant="contained"
              onClick={() => {
                handleClick("addFleet", "");
              }}
              disabled={
                userdata?.role === "service_enginner" ||
                userdata?.role === "fleet_owner" ||
                userdata?.role === "fleet_manager"
              }
            >
              Add Fleet
            </Button>
            <Button
              disabled={isOnline ? false : true}
              variant="contained"
              style={{
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
                marginLeft: 10,
              }}
              disable={reportLoader || isOnline ? false : true}
              onClick={() => {
                tabValue === 1
                  ? getVehicleist(false, 1)
                  : getFleetList(false, 1);
              }}
            >
              {reportLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                "Download"
              )}
            </Button>
          </Grid>
        )}
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
        <Tabs
          value={tabValue}
          onChange={(v, b) => {
            handleFilterFL("");
            handleFilterV("");
            handleSearch("");
            handleSearchV("");
            handlePageNoFL(1);
            handlePageNoV(1);
            handleRecordFL(10);
            handleRecordV(10);
            handleDatePicker(null);
            handleDatePicker1(null);
            handleDatePickerV(null);
            handleDatePickerV1(null);
            setTabValue(b);
          }}
          variant="scrollable"
        >
          <Tab className={styles.tabBtn} label="Fleet List" />
          <Tab className={styles.tabBtn} label="Vehicle List" />
          <Tab className={styles.tabBtn} label="Master Reports" />
          {/* <Tab className={styles.tabBtn} label="Tyre List" /> */}
        </Tabs>
      </Box>
      <div>
        {tabValue === 0 ? (
          <div>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 14 }}
              wrap="nowrap"
            >
              <Grid item lg={2}>
                <Select
                  fullWidth
                  value={filter}
                  onChange={(v) => {
                    handlePageNoFL(1);
                    handleFilterFL(v.target.value);
                  }}
                  onOpen={() => {
                    if (isEmpty(filter.toString())) {
                      setDistrictList(false);
                      setRoList(false);
                    }
                  }}
                  displayEmpty
                  IconComponent={FilterAlt}
                  style={filter === "" ? { color: "#A2A2A2" } : {}}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Filter
                  </MenuItem>
                  <ListItemButton
                    onClick={() => {
                      setDistrictList(!districtList);
                      setRoList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>District</Typography>
                    {districtList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.district.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: districtList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setRoList(!RoList);
                      setDistrictList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Regional Office</Typography>
                    {RoList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.office_region.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: RoList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                }}
              >
                <Typography style={{ fontSize: 14 }}>Records:</Typography>
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    handlePageNoFL(1);
                    handleRecordFL(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ marginTop: 20 }}
              alignItems={"center"}
              wrap="nowrap"
            >
              <Grid item lg={2}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getFleetList(true);
                    handleSearch(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (
                        !isEmpty(searchFilter) ||
                        (!isNull(datePicker) && !isNull(datePicker1))
                      ) {
                        handlePageNoFL(1);
                        getFleetList(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Fleet Name, Fleet Code, Owner, Owner's No., Contact Person and Mobile No."
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                // lg={8.4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={datePicker}
                    onChange={(newValue) => {
                      handleDatePicker(newValue);
                      setDisable(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(datePicker) ? true : disable}
                    minDate={datePicker}
                    views={["year", "month", "day"]}
                    disableFuture
                    value={datePicker1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      handleDatePicker1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              disable || isNull(datePicker)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Grid item lg={4} wrap="nowrap" style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                    onClick={() => {
                      if (
                        !isEmpty(searchFilter) ||
                        (!isNull(datePicker) && !isNull(datePicker1))
                      ) {
                        handlePageNoFL(1);
                        getFleetList(false);
                      }
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={resetFilterData}
                    disabled={
                      filter === "" &&
                      isEmpty(searchFilter) &&
                      isNull(datePicker)
                        ? true
                        : false
                    }
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(fleetListRows?.data) &&
              !isEmpty(fleetListRows?.data) ? (
              <>
                <div style={{ marginTop: 20 }}>{DataGridRender}</div>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(fleetListRows?.pagination?.totalPage)}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNoFL(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        ) : null}
        {tabValue === 1 ? (
          <div>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 14 }}
              wrap="nowrap"
            >
              <Grid item lg={2}>
                <Select
                  fullWidth
                  placeholder="Filter"
                  value={filterV}
                  onChange={(v) => {
                    handlePageNoV(1);
                    handleFilterV(v.target.value);
                  }}
                  onOpen={() => {
                    if (isEmpty(filter.toString())) {
                      settypeList(false);
                      setbrandList(false);
                      setterrainList(false);
                    }
                  }}
                  displayEmpty
                  IconComponent={FilterAlt}
                  style={{
                    color: filterV === "" ? "#A2A2A2" : color.black,
                  }}
                  size={"medium"}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem
                    value={""}
                    disabled
                    hidden
                    selected
                    style={{ minWidth: 220 }}
                  >
                    Filter
                  </MenuItem>
                  <ListItemButton
                    onClick={() => {
                      settypeList(!typeList);
                      setbrandList(false);
                      setterrainList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Vehicle Type</Typography>
                    {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.vehicle_type.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: typeList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setbrandList(!brandList);
                      settypeList(false);
                      setterrainList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Vehicle Brand</Typography>
                    {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.vehicle_brand.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: brandList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setterrainList(!terrainList);
                      settypeList(false);
                      setbrandList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Terrain</Typography>
                    {terrainList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.terrain.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: terrainList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid
                item
                lg={1.6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography style={{ fontSize: 14 }}>Records:</Typography>
                <Select
                  fullWidth
                  value={recordV}
                  onChange={(v) => {
                    handlePageNoV(1);
                    handleRecordV(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              style={{ marginTop: 20 }}
              wrap="nowrap"
            >
              <Grid item lg={2}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilterV}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getVehicleist(true);
                    handleSearchV(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (!isEmpty(searchFilterV)) {
                        handlePageNoV(1);
                        getVehicleist(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Vehicle No., Load Capacity and Fleet Name"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                // lg={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 20,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={datePickerV}
                    onChange={(newValue) => {
                      handleDatePickerV(newValue);
                      setDisableV(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(datePickerV) ? true : disableV}
                    minDate={datePickerV}
                    views={["year", "month", "day"]}
                    disableFuture
                    value={datePickerV1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      handleDatePickerV1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              disableV || isNull(datePickerV)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Grid item lg={4} wrap="nowrap" style={{ display: "flex" }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                    onClick={() => {
                      if (
                        !isEmpty(searchFilterV) ||
                        (!isNull(datePickerV) && !isNull(datePickerV1))
                      ) {
                        handlePageNoV(1);
                        getVehicleist(false);
                      }
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={resetVehicleData}
                    disabled={
                      filterV === "" &&
                      isEmpty(searchFilterV) &&
                      isNull(datePickerV)
                        ? true
                        : false
                    }
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            {pageLoadV ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(vehicleListRows?.data) &&
              !isEmpty(vehicleListRows?.data) ? (
              <>
                <div style={{ marginTop: 20 }}>{DataGridRender1}</div>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(vehicleListRows?.pagination?.totalPage)}
                    defaultPage={pageNoV}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNoV(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        ) : null}
        {tabValue === 2 &&
          staticArray?.map((item, index) => {
            return (
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Typography>{item}</Typography>
                  <Button
                    disabled={isOnline ? false : true}
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginRight: 20,
                    }}
                    onClick={() => setInd(index) || setVisible(true)}
                  >
                    {ind === index && arrReportLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      "Download"
                    )}
                  </Button>
                </Grid>
                {index === staticArray.length - 1 ? "" : <Divider />}
              </div>
            );
          })}
        <MDateRange
          isSelect
          visible={visible}
          handleModal={(
            type,
            fleetIds,
            vehicleIds,
            fromDate,
            toDate,
            isAll
          ) => {
            if (type === "download") {
              ind === 0
                ? getResolvedissuereport(
                    fleetIds,
                    vehicleIds,
                    fromDate,
                    toDate,
                    isAll,
                    1
                  )
                : ind === 1
                ? getResolvedissuereport(
                    fleetIds,
                    vehicleIds,
                    fromDate,
                    toDate,
                    isAll,
                    2
                  )
                : ind === 2
                ? getUnresolvedIssue(
                    fleetIds,
                    vehicleIds,
                    fromDate,
                    toDate,
                    isAll
                  )
                : getavgNsdReport(
                    fleetIds,
                    vehicleIds,
                    fromDate,
                    toDate,
                    isAll
                  );
            }
            setVisible(false);
          }}
        />
      </div>
    </div>
  );
}
