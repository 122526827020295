import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting.js";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  FormHelperText,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles.js";
import FormControl from "@mui/material/FormControl";
import { cloneDeep, isArray, isEmpty, isNull } from "lodash";

import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm/index";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment";
import { ArrowBackOutlined } from "@mui/icons-material";
import { isMobile, isTablet } from "react-device-detect";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const errorObj = {
  FNameErr: false,
  FNameMsg: "",
  TSNErr: false,
  TSNMsg: "",
  brandErr: false,
  brandMsg: "",
  compositionErr: false,
  compositionMsg: "",
  plyratingErr: false,
  plyratingMsg: "",
  patternErr: false,
  patternMsg: "",
  tPatternErr: false,
  tPatternMsg: "",
  ONSDErr: false,
  ONSDMsg: "",
  MNSDErr: false,
  MNSDMsg: "",
  INSDErr: false,
  INSDMsg: "",
  issueErr: false,
  issueMsg: "",
  dateErr: false,
  dateMsg: "",
};

export default function AddScrapTyre(props) {
  const { handleClick = () => null, editData = {}, from, fleetDetails } = props;
  const styles = useStyles();
  const isEdit = !isEmpty(editData);

  const { token, fleetData, dropDownData, isOnline } = useSelector(
    (state) => state.auth
  );
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [errObj, setErrObj] = useState(errorObj);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const [loader, setLoader] = useState(false);
  const [fleetname, setFleetname] = useState("");
  const [serialNo, setserialNo] = useState("");
  const [tyreBrand, settyreBrand] = useState("");
  const [tyreComposition, settyreComposition] = useState("");
  const [plyRating, setplyRating] = useState("");
  const [treadPattern, settreadPattern] = useState("");
  const [avgNSD, setAvgNSD] = useState("");
  const [patternSize, setPAtternSize] = useState(null);
  const [tyrePatternList, settyrePatternList] = useState([]);
  const [NSD, setNSD] = useState("");
  const [readOnly, setReadOnly] = useState(false);

  const [remarks, setRemarks] = useState("");

  const [formValues, setFormValues] = useState([
    {
      groove1: "",
      groove2: "",
      groove3: "",
      groove4: "",
      id: Math.floor(Math.random() * 100 + 1),
    },
  ]);
  const [openCard, setOpenCard] = useState(false);
  const [issueType, setissueType] = useState([]);
  const dummytyreArr = cloneDeep(dropDownData?.tyre_issue_scrap_analysis);
  const [issueArry, setissueArry] = useState(dummytyreArr);
  const [datePicker, setDatePicker] = useState(new Date());
  const [renderTagsValue, setrenderTagsValue] = useState([]);

  useEffect(() => {
    const fname = fleetData?.find((v) => v?.id === editData?.fleet_id);
    const f1name = fleetData?.find(
      (v) => v?.id?.toString() === fleetDetails?.fleet_id
    );

    const brand = dropDownData?.tyre_brand?.find(
      (v) => v?.label === editData?.tyre_brand
    );
    // const composition = dropDownData?.tyre_composition?.find(
    //   (v) => v?.label === editData?.tyre_composition
    // );
    // const plyrating = dropDownData?.ply_rating?.find(
    //   (v) => v?.label === editData?.ply_rating_id
    // );

    const itemdescription =
      (isEdit &&
        !isEmpty(tyrePatternList) &&
        tyrePatternList?.find((v) => v?.id === editData?.tyre_pattern_id)) ||
      "";

    if (from === "details" && !isEdit) {
      setFleetname(f1name);
    }

    if (isEdit) {
      editData?.issue?.map((ele, ind) => {
        const issIndex = dropDownData?.tyre_issue_scrap_analysis?.findIndex(
          (v) => v?.label === ele
        );
        SelectTyre(issIndex);
        setTimeout(() => handleSelect(), 200);
      });
      setFleetname(fname);
      setserialNo(editData?.tyre_serial_no || "");
      settyreBrand(brand || "");
      // settyreComposition(composition?.id);
      // setplyRating(plyrating);
      // settreadPattern(editData?.tread_pattern_id || "");
      setPAtternSize(itemdescription);
      setissueType(editData?.issue_ids);
      setDatePicker(editData?.capture_date || null);
      setFormValues(
        editData?.current_nsd || [
          {
            groove1: "",
            groove2: "",
            groove3: "",
            groove4: "",
            id: Math.floor(Math.random() * 100 + 1),
          },
        ]
      );
      if (editData?.is_new_tyre === 1) {
        settyreComposition(editData?.tyre_composition_id || "");
        setplyRating(editData?.ply_rating_id || "");
        settreadPattern(editData?.tread_pattern_id || "");
        setNSD(editData?.original_nsd || "");
      }
    }
  }, []);

  useEffect(() => {
    if (isEdit) {
      setTimeout(() => {
        const PatternData = tyrePatternList.find(
          (v) => editData?.tyre_pattern_id == v?.id
        );
        if (!isEmpty(PatternData)) {
          setPAtternSize(PatternData);
        }
      }, 200);
    }
  }, [tyrePatternList]);

  useEffect(() => {
    patternSizeData();
    if (editData?.is_new_tyre === 0) {
      autoFillData();
    }
  }, [tyreBrand]);

  useEffect(() => {
    autoFillData();
  }, [patternSize]);

  useEffect(() => {
    isArray(formValues) && !isEmpty(formValues) && findAvgNSD();
  }, [formValues]);

  function findAvgNSD() {
    let groove = 0;
    let divide = 0;
    formValues.map((v, index) => {
      if (!isEmpty(v?.groove1)) {
        groove = Number(v?.groove1) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove2)) {
        groove = Number(v?.groove2) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove3)) {
        groove = Number(v?.groove3) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove4)) {
        groove = Number(v?.groove4) + groove;
        divide++;
      }
    });

    const mainAVG = groove / divide;
    setAvgNSD(isNaN(mainAVG) ? "" : mainAVG.toFixed(2));
  }

  let handleChange = (i, e, key) => {
    let newFormValues = [...formValues];
    newFormValues.map((v, index) => {
      if (i === index) {
        if (key === 1) {
          v.groove1 = e;
        } else if (key === 2) {
          v.groove2 = e;
        } else if (key === 3) {
          v.groove3 = e;
        } else if (key === 4) {
          v.groove4 = e;
        }
      }
    });
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    if (formValues.length < 4) {
      setFormValues([
        ...formValues,
        {
          groove1: "",
          groove2: "",
          groove3: "",
          groove4: "",
          id: Math.floor(Math.random() * 100 + 1),
        },
      ]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  const submitData = async () => {
    setLoader(true);

    const captureDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : null;
    let endPoints = Setting.endpoints.addscraptyre;
    const params = {
      "ScrapTyreHistory[fleet_id]": fleetname?.id,
      "ScrapTyreHistory[tyre_serial_no]": serialNo?.trim(),
      "ScrapTyreHistory[tyre_brand_id]": tyreBrand?.id,
      "ScrapTyreHistory[tyre_composition_id]": tyreComposition,
      "ScrapTyreHistory[ply_rating_id]": plyRating?.id,
      "ScrapTyreHistory[tyre_pattern_id]": patternSize?.id,
      "ScrapTyreHistory[tread_pattern_id]": treadPattern,
      "Tyre[original_nsd]": NSD,
      "ScrapTyreHistory[current_nsd]": JSON.stringify(formValues),
      "ScrapTyreHistory[avg_nsd]": avgNSD,
      "ScrapTyreHistory[scrap_issue_id]": issueType,
      "ScrapTyreHistory[remark]": remarks,
      "ScrapTyreHistory[capture_date]": captureDate,
    };
    if (isEdit) {
      params["ScrapTyreHistory[id]"] = editData?.id;
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        handleClick("cancel");
        toast.success(resp?.message);
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
      toast.error(error.message.toString());
    }
  };

  function clearData() {
    settyreBrand("");
    settyreComposition("");
    setPAtternSize("");
    setplyRating("");
    settreadPattern("");
    setissueType([]);
    setNSD("");
    setDatePicker(new Date());
  }

  const patternSizeData = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyrepattern}?tyre_brand_id=${tyreBrand?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          settyrePatternList(response?.data);
        } else {
          settyrePatternList([]);
        }
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const autoFillData = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.patterndata}?pattern_id=${patternSize?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setReadOnly(true);
        handleFillData(response?.data);
      } else {
        setReadOnly(false);
        handleFillData("");
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };
  function handleFillData(data) {
    const plyrating = dropDownData?.ply_rating?.find(
      (v) => v?.id === data?.ply_rating_id
    );
    settyreComposition(data?.tyre_composition_id || "");
    setplyRating(plyrating || "");
    // settreadPattern(data?.tyre_size || "");
    setNSD(data?.nsd || "");
    settreadPattern(data?.tread_pattern_id || "");

    setErrObj({
      ...errObj,
      compositionErr: false,
      compositionMsg: "",
      plyratingErr: false,
      plyratingMsg: "",
      tPatternErr: false,
      tPatternMsg: "",
    });
  }

  function clearChangeData() {
    setPAtternSize(null);
    settyreComposition("");
    setplyRating("");
    settreadPattern("");
    setNSD("");
  }

  // const getVehicleBrand = () => {
  //   let array = vehicleDetails.vehicle_brand_id;
  //   let res = dropDownData?.vehicle_brand?.find(
  //     (item) => array.toString() === item?.id.toString()
  //   );
  //   return res?.label;
  // };

  function validation() {
    const error = { ...errObj };
    let valid = true;
    let scroll = false;
    let section = null;
    let nsdErr = false;
    let SeqErr = false;

    if (isNull(fleetname) || fleetname === undefined || fleetname === "") {
      valid = false;
      scroll = true;
      error.FNameErr = true;
      error.FNameMsg = "Please select Fleet Name";
      section = document.querySelector("#fleetname");
    }

    if (
      isNull(patternSize) ||
      patternSize === undefined ||
      patternSize === ""
    ) {
      valid = false;
      scroll = true;
      error.itemDesctiptionErr = true;
      error.itemDesctiptionMsg = "Please select Item Description";
      section = document.querySelector("#itemdescription");
    }

    if (isEmpty(serialNo)) {
      valid = false;
      error.TSNErr = true;
      error.TSNMsg = "Please enter Tyre Serial No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#serialNo");
      }
    } else if (serialNo.length > 15) {
      valid = false;
      error.TSNErr = true;
      error.TSNMsg = "Please enter valid Tyre Serial No.";
    }

    if (isNull(tyreBrand) || tyreBrand === undefined || tyreBrand === "") {
      valid = false;
      error.brandErr = true;
      error.brandMsg = "Please select Tyre Brand";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#tyreBrand");
      }
    }

    if (
      isNull(tyreComposition) ||
      tyreComposition === undefined ||
      tyreComposition === ""
    ) {
      valid = false;
      error.compositionErr = true;
      error.compositionMsg = "Please select Tyre Composition";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#tyreComposition");
      }
    }

    if (isNull(plyRating) || plyRating === undefined || plyRating === "") {
      valid = false;
      error.plyratingErr = true;
      error.plyratingMsg = "Please select Ply Rating";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#plyRating");
      }
    }

    if (isEmpty(treadPattern.toString())) {
      valid = false;
      error.tPatternErr = true;
      error.tPatternMsg = "Please enter Tread Pattern";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#treadPattern");
      }
    }
    if (isEmpty(issueType.toString())) {
      valid = false;
      error.issueErr = true;
      error.issueMsg = "Please select Tyre Issue";
    }

    if (isNull(datePicker)) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please select Added Date";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#date");
      }
    }

    formValues.map((v, index) => {
      if (!isEmpty(v?.groove1) && Math.ceil(v?.groove1) === 0) {
        valid = false;
        nsdErr = true;
        if (!scroll) {
          scroll = true;
          section = document.querySelector(`#nsdErr_${index}`);
        }
      }
      if (!isEmpty(v?.groove2)) {
        if (Math.ceil(v?.groove2) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
        } else if (isEmpty(v?.groove1)) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
        }
      }
      if (!isEmpty(v?.groove3)) {
        if (Math.ceil(v?.groove3) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
        } else if (isEmpty(v?.groove1) || isEmpty(v?.groove2)) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
        }
      }
      if (!isEmpty(v?.groove4)) {
        if (Math.ceil(v?.groove4) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
        } else if (
          isEmpty(v?.groove1) ||
          isEmpty(v?.groove2) ||
          isEmpty(v?.groove3)
        ) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
        }
      }
    });

    if (nsdErr) {
      toast.error("NSD value can't be 0", { toastId: 1 });
    }
    if (SeqErr) {
      toast.error("Please fill NSD value sequentially", { toastId: 2 });
    }
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }

    setErrObj(error);
    if (valid) {
      submitData();
    }
  }

  function SelectTyre(index) {
    const DummyArr = [...issueArry];

    DummyArr.map((item, i) => {
      if (i === index) {
        item.selected = !item.selected;
      }
    });
    setissueArry(DummyArr);
  }

  function handleSelect() {
    // const DummyArr = [...issueArry];
    // const selected = DummyArr?.find((v) => v.selected === true);
    // if (!isEmpty(selected)) {
    //   setissueType(selected);
    // }

    setErrObj({ ...errObj, issueErr: false, issueMsg: "" });
    setOpenCard(false);
    const DummyArr = [...issueArry];
    const selected = DummyArr?.filter((v) => v.selected === true);
    const renderTagsValue =
      isArray(selected) && !isEmpty(selected)
        ? selected
            .map(function (elem) {
              return elem.label;
            })
            .join(", ")
        : "";
    const array = [];
    const array2 = [];
    if (!isEmpty(selected) && isArray(selected)) {
      selected.map((v) => {
        array.push(v.id);
        array2.push(v.label);
      });
      setissueType(array);
      setrenderTagsValue(renderTagsValue);
    } else {
      setrenderTagsValue("");
      setissueType([]);
    }
    setOpenCard(false);
  }

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, []);

  return (
    <Box>
      <Box id="jump_to_me">
        {isKeyboardOpen && !isTablet ? null : (
          <Button
            style={
              isTablet
                ? { backgroundColor: color.secondary, marginBottom: "10px" }
                : {
                    backgroundColor: color.secondary,
                    position: "fixed",
                    top: 10,
                    zIndex: 10,
                  }
            }
            onClick={() => {
              handleClick("cancel");
            }}
          >
            {isTablet ? "Back" : <ArrowBackOutlined />}
          </Button>
        )}
      </Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: !isTablet ? 15 : "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>
          {isEdit ? "Edit Scrap Tyre" : "Add Scrap Tyre"}
        </Typography>
      </Box>

      <Box className={styles.container}>
        <Grid
          container
          style={{ marginBottom: errObj.TSNErr ? 30 : 20 }}
          alignItems={"center"}
          id="fleetname"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Fleet Name"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <FormControl
              sx={{
                width: "100%",
                border: errObj.FNameErr ? "1px solid #ff1744" : null,
                borderRadius: 1,
              }}
              error={errObj.FNameErr}
            >
              <Autocomplete
                disabled={from === "details" && !isEdit}
                fullWidth
                disableListWrap={true}
                options={fleetData}
                // filterSelectedOptions
                value={fleetname}
                onChange={(v1, v) => {
                  setFleetname(v);
                  setErrObj({
                    ...errObj,
                    FNameErr: false,
                    FNameMsg: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={styles.inputDivStyle}
                    placeholder={
                      isEmpty(fleetname) ? "Search/Select Fleet Name" : null
                    }
                  />
                )}
              />
              {errObj.FNameErr ? (
                <FormHelperText>{errObj.FNameMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: errObj.TSNErr ? 30 : 20 }}
          alignItems={"center"}
          id="serialNo"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tyre Serial No."} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter Tyre Serial No."
                value={serialNo}
                error={errObj.TSNErr}
                helperText={errObj.TSNErr ? errObj.TSNMsg : null}
                onChange={(event) => {
                  if (event?.nativeEvent?.data !== " ") {
                    setserialNo(event.target.value?.toUpperCase());
                    setErrObj({
                      ...errObj,
                      TSNErr: false,
                      TSNMsg: "",
                    });
                  }
                }}
              />
              {isEdit ? null : (
                <Button
                  style={{ backgroundColor: color.secondary, marginLeft: 10 }}
                  onClick={() => {
                    setserialNo(
                      Math.random()
                        .toString(36)
                        .replace(/[^a-z]+/g, "")
                        .substr(0, 5)
                    );
                    setErrObj({
                      ...errObj,
                      TSNErr: false,
                      TSNMsg: "",
                    });
                  }}
                >
                  Generate
                </Button>
              )}
            </div>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: errObj.brandErr ? 30 : 20 }}
          alignItems={"center"}
          id="tyreBrand"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tyre Brand"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  border: errObj.brandErr ? "1px solid #ff1744" : null,
                  borderRadius: 1,
                }}
                error={errObj.brandErr}
              >
                <Autocomplete
                  fullWidth
                  disableListWrap={true}
                  options={dropDownData?.tyre_brand}
                  // filterSelectedOptions
                  value={tyreBrand}
                  onChange={(v1, v) => {
                    settyreBrand(v);
                    setErrObj({
                      ...errObj,
                      brandErr: false,
                      brandMsg: "",
                    });
                    clearChangeData();
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.inputDivStyle}
                      placeholder={
                        isEmpty(tyreBrand)
                          ? "Search/Select the Tyre Brand"
                          : null
                      }
                    />
                  )}
                />
                {errObj.brandErr ? (
                  <FormHelperText>{errObj.brandMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.patternErr ? 30 : 20 }}
          alignItems={"center"}
          id="tyrePattern"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Item Description"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <FormControl
              variant="outlined"
              fullWidth
              sx={{
                width: "100%",
                border: errObj.itemDesctiptionErr ? "1px solid #ff1744" : null,
                borderRadius: 1,
              }}
              error={errObj.itemDesctiptionErr}
            >
              <Autocomplete
                id={"itemdescription"}
                fullWidth
                disabled={isEmpty(tyreBrand?.toString()) ? true : false}
                value={patternSize}
                onChange={(event, newValue) => {
                  if (typeof newValue === "string") {
                    setPAtternSize({
                      label: newValue,
                    });
                  } else if (newValue && newValue.inputValue) {
                    // Create a new value from the user input
                    setPAtternSize({
                      label: newValue.inputValue,
                    });
                  } else {
                    setPAtternSize(newValue);
                  }
                  setErrObj({
                    ...errObj,
                    itemDesctiptionErr: false,
                    itemDesctiptionMsg: "",
                  });
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    v.defaultMuiPrevented = true;
                  }
                }}
                // filterOptions={(options, params) => {
                //   const filtered = filter(options, params);
                //   const { inputValue } = params;
                //   // Suggest the creation of a new value
                //   const isExisting = options.some(
                //     (option) => inputValue === option.label
                //   );
                //   // if (inputValue !== "" && !isExisting) {
                //   //   filtered.push({
                //   //     inputValue,
                //   //     label: `Add "${inputValue}"`,
                //   //   });
                //   // }

                //   return filtered;
                // }}
                selectOnFocus
                clearOnBlur
                handleHomeEndKeys
                options={tyrePatternList}
                getOptionLabel={(option) => {
                  // Value selected with enter, right from the input
                  if (typeof option === "string") {
                    return option;
                  }
                  // Add "xxx" option created dynamically
                  if (option.inputValue) {
                    return option.inputValue;
                  }
                  // Regular option
                  return option.label;
                }}
                renderOption={(props, option) => (
                  <li {...props}>{option.label}</li>
                )}
                freeSolo
                renderInput={(params) => (
                  <TextField
                    {...params}
                    placeholder="Select the Item Description"
                  />
                )}
              />
              {errObj.itemDesctiptionErr ? (
                <FormHelperText>{errObj.itemDesctiptionMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: errObj.compositionErr ? 30 : 20 }}
          id="tyreComposition"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tyre Composition"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth error={errObj.compositionErr}>
                <Select
                  displayEmpty
                  value={tyreComposition}
                  onChange={(event) => {
                    settyreComposition(event.target.value);
                    setErrObj({
                      ...errObj,
                      compositionErr: false,
                      compositionMsg: "",
                    });
                  }}
                  inputProps={{ readOnly: readOnly }}
                  size={"medium"}
                  style={tyreComposition === "" ? { color: "#A2A2A2" } : {}}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select the Tyre Composition
                  </MenuItem>
                  {dropDownData.tyre_composition.map((item, index) => (
                    <MenuItem key={index} value={item?.id}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                {errObj.compositionErr ? (
                  <FormHelperText>{errObj.compositionMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.tPatternErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tread Pattern"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <FormControl fullWidth error={errObj.tPatternErr}>
              <Select
                displayEmpty
                value={treadPattern}
                onChange={(event) => {
                  settreadPattern(event.target.value);
                  setErrObj({
                    ...errObj,
                    tPatternErr: false,
                    tPatternMsg: "",
                  });
                }}
                size={"medium"}
                style={treadPattern === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value={""} hidden selected disabled>
                  Select the Tread Pattern
                </MenuItem>
                {dropDownData.tread_pattern_data.map((item, index) => (
                  <MenuItem key={index} value={item?.id}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
              {errObj.tPatternErr ? (
                <FormHelperText>
                  {errObj.tPatternMsg}
                  {}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: errObj.plyratingErr ? 30 : 20 }}
          id="plyRating"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Ply Rating"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                sx={{
                  width: "100%",
                  border: errObj.plyratingErr ? "1px solid #ff1744" : null,
                  borderRadius: 1,
                }}
                error={errObj.plyratingErr}
              >
                <Autocomplete
                  fullWidth
                  disableListWrap={true}
                  options={dropDownData?.ply_rating}
                  // filterSelectedOptions
                  value={plyRating}
                  onChange={(v1, v) => {
                    setplyRating(v);
                    setErrObj({
                      ...errObj,
                      plyratingErr: false,
                      plyratingMsg: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.inputDivStyle}
                      placeholder={
                        isEmpty(plyRating)
                          ? "Search/Select the Ply Rating"
                          : null
                      }
                    />
                  )}
                />
                {errObj.plyratingErr ? (
                  <FormHelperText>{errObj.plyratingMsg}</FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        {formValues.map((v, index) => {
          const isLast = index === formValues.length - 1;
          return (
            <Grid
              key={`new_${v?.id}`}
              container
              style={{ marginBottom: 20 }}
              alignItems={"center"}
              id={`nsdErr_${index}`}
            >
              <Grid item xs={12} md={2}>
                <Typography>{index === 0 ? "Current NSD : " : ""}</Typography>
              </Grid>
              <Grid
                item
                container
                xs={12}
                md={10}
                style={{
                  display: !md ? "flex" : "unset",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Grid item xs={12} md={2.33}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Groove 1"
                    value={v.groove1}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value, 1);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.33} style={{ marginTop: md ? 20 : 0 }}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Groove 2"
                    value={v.groove2}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value, 2);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.33} style={{ marginTop: md ? 20 : 0 }}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Groove 3"
                    value={v.groove3}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value, 3);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2.33} style={{ marginTop: md ? 20 : 0 }}>
                  <TextField
                    style={{ width: "100%", margin: 0 }}
                    placeholder="Groove 4"
                    value={v.groove4}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                    onChange={(e) => {
                      handleChange(index, e?.target?.value, 4);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2} style={{ marginTop: md ? 20 : 0 }}>
                  <Button
                    style={{
                      backgroundColor: !isLast
                        ? color.secondary
                        : formValues.length === 4 && isLast
                        ? "rgba(0, 0, 0, 0.12)"
                        : color.primary,
                      width: "100%",
                      boxShadow:
                        formValues.length === 4 && isLast ? "unset" : "",
                    }}
                    disabled={formValues.length === 4 && isLast}
                    onClick={() =>
                      !isLast ? removeFormFields(index) : addFormFields()
                    }
                  >
                    {!isLast ? "Remove" : "Add More"}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Average NSD : </Typography>
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              value={avgNSD}
              type={"number"}
              placeholder="Enter Average NSD (mm)"
              // onChange={(v) => setNSD(v?.target?.value)}
              inputProps={{
                onWheel: (event) => event.currentTarget.blur(),
                readOnly: true,
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">mm</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.issueErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tyre Issue"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            {/* <FormControl
              sx={{
                width: "100%",
                border: errObj.issueErr ? "1px solid #ff1744" : null,
                borderRadius: 1,
              }}
              error={errObj.issueErr}
            >
              <Autocomplete
                fullWidth
                disableListWrap={true}
                options={dropDownData?.tyre_issue_scrap_analysis}
                // filterSelectedOptions
                value={issue}
                onChange={(v1, v) => {
                  setIssue(v);
                  setErrObj({
                    ...errObj,
                    issueErr: false,
                    issueMsg: "",
                  });
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    className={styles.inputDivStyle}
                    placeholder={
                      isEmpty(issue) ? "Search/Select the Issue" : null
                    }
                  />
                )}
              />
              {errObj.issueErr ? (
                <FormHelperText>{errObj.issueMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl> */}
            <FormControl fullWidth error={errObj.issueErr}>
              <OutlinedInput
                style={{ width: "100%", margin: 0, cursor: "pointer" }}
                placeholder="Select the Tyre Issue"
                inputProps={{
                  readOnly: true,
                  style: { cursor: "pointer" },
                }}
                value={renderTagsValue}
                onClick={() => {
                  if (isArray(issueArry) && !isEmpty(issueArry)) {
                    setOpenCard(true);
                  } else {
                    toast.warning(
                      "Please Add Scrap Analysis Tyre Issue First from General DropDowns"
                    );
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                }
              />
              {errObj.issueErr ? (
                <FormHelperText>{errObj.issueMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container alignItems={"center"} style={{ marginBottom: 20 }}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <Typography>Remarks</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Remarks here"
              value={remarks}
              rows={4}
              multiline
              onChange={(e) => {
                setRemarks(e.target.value);
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: errObj.selectedFileErr ? 30 : 20 }}
          id="date"
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Added Date"} />
          </Grid>
          <Grid
            item
            xs={12}
            md={10}
            lg={10}
            style={{ display: "flex", alignItems: "center" }}
          >
            <FormControl
              error={errObj.dateErr}
              sx={{
                border: errObj.dateErr ? "1px solid #ff1744" : null,
                borderRadius: 1,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  views={["year", "month", "day"]}
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                    setErrObj({
                      ...errObj,
                      dateErr: false,
                      dateMsg: "",
                    });
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{
                        svg: {
                          color: errObj.dateErr ? "#ff1744" : color.primary,
                        },
                      }}
                      error={false}
                    />
                  )}
                />
              </LocalizationProvider>
              {errObj.dateErr ? (
                <FormHelperText>{errObj.dateMsg}</FormHelperText>
              ) : null}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginBottom: 20 }}
        >
          <Button
            onClick={validation}
            disabled={isOnline ? false : true}
            style={{
              backgroundColor: isOnline ? color.primary : color.bordercolor,
              marginRight: 10,
              width: 120,
            }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : isEdit ? (
              `Save & Update`
            ) : (
              `Add Scrap Tyre`
            )}
          </Button>
          <Button
            style={{
              backgroundColor: color.secondary,
              width: 120,
            }}
            onClick={() => {
              handleClick("cancel");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
      <Modal
        onClose={() => {
          setOpenCard(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 650,
            maxHeight: "65vh",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={isMobile ? 1 : md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: "50vh" }}
          >
            {issueArry.map((item, index) => {
              return (
                <ImageListItem
                  key={index}
                  style={{
                    width: isMobile ? "60vw" : 200,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      SelectTyre(index);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: isMobile ? "60vw" : 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={handleSelect}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
    </Box>
  );
}
