import React, { useEffect, useState } from "react";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import useStyles from "./Styles";
import VehicleMasterVeiw from "../../../Components/VehicleMaster/VehicleMasterView";
import { Setting } from "../../../Utils/Setting";
import AddVehicleMaster from "../../../Components/VehicleMaster/AddVehicleMaster";
import { type } from "@testing-library/user-event/dist/type";

export default function VehicleMaster() {
  const styles = useStyles();

  const [changeTab, setChangeTab] = useState("");
  const [data, setData] = useState({});

  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    document.title = Setting.page_name.VehicleMaster;
  }, []);

  return (
    <div className={styles.card}>
      <Grid container>
        <Grid item className={styles.breadcrumb}>
          <Breadcrumbs separator=">">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="#"
              className={styles.linkStyle}
              onClick={() => setChangeTab("")}
            >
              Vehicle Master
            </Link>
            {changeTab === "addVehicle" && (
              <Typography className={styles.linkStyle}>Add Vehicle</Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "addVehicle" ? (
        <AddVehicleMaster
          editData={data}
          handleClick={(v, type) => {
            if (v === "cancle") {
              setChangeTab("");
            }
          }}
        />
      ) : (
        <VehicleMasterVeiw
          pageNo={pageNo}
          record={record}
          filter={filter}
          search={search}
          handlePageNo={(page) => {
            setPageNo(page);
          }}
          handleRecord={(rec) => {
            setRecord(rec);
          }}
          handleFilter={(flt) => {
            setFilter(flt);
          }}
          handleSearch={(search) => {
            setSearch(search);
          }}
          handleClick={(v, type) => {
            if (v === "addVehicle") {
              setChangeTab("addVehicle");
              setData({});
            }
            if (v === "editVehicle") {
              setChangeTab("addVehicle");
              setData(type);
            }
          }}
        />
      )}
    </div>
  );
}
