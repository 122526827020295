/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  TextField,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Grid,
  FormControl,
  FormHelperText,
  Select,
  MenuItem,
} from "@mui/material";
import useStyles from "./styles.js";
import { Close } from "@mui/icons-material";
import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import { isEmpty, isNull } from "lodash";
import { Setting } from "../../../Utils/Setting.js";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../../Utils/APIHelper.js";
import AddIcon from "@mui/icons-material/Add";
import CancelIcon from "@mui/icons-material/Cancel";
import { useSelector } from "react-redux";
const errorObj = {
  InputErr: false,
  InputMsg: "",
  hourseErr: false,
  hourseMsg: "",
  imgErr: false,
  imgMsg: "",
};

function MDropDown(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    data = {},
    editData = {},
  } = props;
  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const isEdit = !isEmpty(editData);
  const [input, setInput] = useState("");
  const [errObj, setErrObj] = useState(errorObj);
  const [loader, setLoader] = useState(false);
  const [selectedFile, setSlectedFile] = useState(null);
  const [displayImg, setDisplayImg] = useState("");
  const [bool, setBool] = useState(false);
  const [issueType, setissueType] = useState("no_issue");
  const [hourseT, setHourseT] = useState("");
  const { isOnline } = useSelector((state) => state.auth);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 300 : md ? 550 : 700,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    padding: 0,
  };

  useEffect(() => {
    if (visible) {
      setInput(editData?.label || "");
      setSlectedFile(editData?.image || null);
      setDisplayImg(editData?.image || "");
      setissueType(editData?.issue_type || "no_issue");
      setHourseT(editData?.is_horse_trailer?.toString() || "");
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  async function addDropDown() {
    setLoader(true);
    let endPoints = Setting.endpoints.addDropDown;
    const params = {
      "DropDown[type]": data?.type,
      "DropDown[value]": input.trim(),
    };

    if (
      data?.type === "tyre_issue_scrap_analysis" ||
      data?.type === "tyre_issue_inspection" ||
      data?.type === "vehicle_type" ||
      data?.type === "tyre_removal_issue" ||
      data?.type === "mechanical_issue"
    ) {
      // if (data?.type === "vehicle_type") {
      //   params["DropDown[is_horse_trailer]"] = hourseT;
      // }
      if (bool) {
        params["DropDown[image]"] = selectedFile;
      }
    }

    if (
      data?.type === "tyre_issue_inspection" ||
      data?.type === "mechanical_issue" ||
      data?.type === "tyre_issue_scrap_analysis" ||
      data?.type === "tyre_removal_issue"
    ) {
      params["DropDown[issue_type]"] = issueType;
    }
    // if (
    //   data?.type === "tyre_issue_inspection" ||
    //   data?.type === "mechanical_issue"
    // ) {
    //   params["DropDown[issue_type]"] = issueType;
    // }
    if (isEdit) {
      params["DropDown[id]"] = editData?.id;
    }

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        console.log(resp);
        resetFormData();
        onSave();
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  }

  // this function for reset form data
  function resetFormData() {
    setInput("");
    setHourseT("");
    setSlectedFile(null);
    setissueType("no_issue");
    setDisplayImg("");
    setErrObj(errorObj);
    setBool(false);
    if (handleModal) {
      handleModal();
    }
  }

  function checkValidation() {
    let valid = true;
    const error = { ...errObj };

    if (isEmpty(input)) {
      valid = false;
      error.InputErr = true;
      error.InputMsg = `Please enter ${data?.name}`;
    }

    // if (data?.type === "vehicle_type" && isEmpty(hourseT)) {
    //   valid = false;
    //   error.hourseErr = true;
    //   error.hourseMsg = "Please Select Horse Transfer";
    // }

    if (
      (data?.type === "tyre_issue_scrap_analysis" ||
        data?.type === "tyre_issue_inspection" ||
        data?.type === "vehicle_type" ||
        data?.type === "tyre_removal_issue" ||
        data?.type === "mechanical_issue") &&
      isNull(selectedFile)
    ) {
      valid = false;
      error.imgErr = true;
      error.imgMsg = `Please upload Image`;
    }

    setErrObj(error);
    if (valid) {
      addDropDown();
    }
  }

  function uploadItem(file) {
    const displayUrl = URL.createObjectURL(file);
    setDisplayImg(displayUrl);
    setSlectedFile(file);
    setBool(true);
    setErrObj({
      ...errObj,
      imgErr: false,
      imgMsg: "",
    });
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData()}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={styles.modalHeader}>
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              {isEdit ? `Edit ${data?.name}` : `Add ${data?.name}`}
            </Typography>
            <Close
              style={{ color: color.white, cursor: "pointer" }}
              onClick={() => resetFormData()}
            />
          </div>
          <div className={styles.modalMain}>
            <Grid
              container
              alignItems={"center"}
              style={{
                marginBottom: errObj.InputErr ? 30 : 20,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={3} lg={3.5}>
                <CTypography required title={`${data?.name}`} />
              </Grid>
              <Grid item xs={12} md={9} lg={8.5}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  placeholder={`Enter ${data?.name}`}
                  inputProps={{
                    onWheel: (event) => event.currentTarget.blur(),
                    readOnly: editData?.label === "N/A" ? true : false,
                  }}
                  value={input}
                  className={styles.input}
                  error={errObj.InputErr}
                  helperText={errObj.InputErr ? errObj.InputMsg : null}
                  onChange={(e) => {
                    setInput(e.target.value);
                    setErrObj({ ...errObj, InputErr: false, InputMsg: "" });
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      ev.preventDefault();
                      checkValidation();
                    }
                  }}
                />
              </Grid>
            </Grid>
            {(data?.type === "tyre_issue_inspection" ||
              data?.type === "mechanical_issue" ||
              data?.type === "tyre_issue_scrap_analysis" ||
              data?.type === "tyre_removal_issue") && (
              <Grid
                container
                alignItems={"center"}
                style={{
                  marginBottom: errObj.InputErr ? 30 : 20,
                }}
              >
                <Grid item xs={12} md={3} lg={3.5}>
                  <CTypography required title={"Select Issue"} />
                </Grid>
                <Grid item xs={12} md={9} lg={8.5}>
                  <Select
                    fullWidth
                    value={issueType}
                    onChange={(v) => {
                      setissueType(v.target.value);
                    }}
                    disabled={editData?.label === "N/A" ? true : false}
                  >
                    <MenuItem value={"no_issue"} selected>
                      No Issue
                    </MenuItem>
                    <MenuItem value={"major"}>Major Issue</MenuItem>
                    <MenuItem value={"minor"}>Minor Issue</MenuItem>
                  </Select>
                </Grid>
              </Grid>
            )}

            {/* {data?.type === "vehicle_type" && (
              <Grid
                container
                alignItems={"center"}
                style={{ marginBottom: errObj.hourseErr ? 30 : 20 }}
              >
                <Grid item xs={12} md={3} lg={3.5}>
                  <CTypography required title={`Horse Trailer`} />
                </Grid>
                <Grid item xs={12} md={9} lg={8.5}>
                  <FormControl fullWidth error={errObj.hourseErr}>
                    <Select
                      fullWidth
                      displayEmpty
                      value={hourseT}
                      onChange={(e) => {
                        setHourseT(e.target.value);
                        setErrObj({
                          ...errObj,
                          hourseErr: false,
                          hourseMsg: "",
                        });
                      }}
                      size={"medium"}
                      style={hourseT === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select Horse Transfer
                      </MenuItem>
                      <MenuItem value={"1"}>Yes</MenuItem>
                      <MenuItem value={"0"}>No</MenuItem>
                    </Select>
                    {errObj?.hourseErr ? (
                      <FormHelperText>{errObj?.hourseMsg}</FormHelperText>
                    ) : null}
                  </FormControl>
                </Grid>
              </Grid>
            )} */}

            {(data?.type === "tyre_issue_scrap_analysis" ||
              data?.type === "tyre_issue_inspection" ||
              data?.type === "vehicle_type" ||
              data?.type === "tyre_removal_issue" ||
              data?.type === "mechanical_issue") && (
              <Grid
                container
                style={{
                  marginBottom: errObj.InputErr ? 30 : 20,
                  marginTop: 10,
                }}
              >
                <Grid item xs={12} md={3} lg={3.5}>
                  <CTypography required title={`Upload Image`} />
                </Grid>
                <Grid item xs={12} md={9} lg={8.5}>
                  <FormControl fullWidth error={errObj.imgErr}>
                    {!displayImg ? (
                      <Button
                        variant="contained"
                        component="label"
                        fullWidth
                        className={styles.upload}
                      >
                        <input
                          type="file"
                          onChange={(e) => {
                            uploadItem(e.target.files[0]);
                          }}
                          accept="image/jpeg, image/png, image/jpg, .svg"
                          hidden
                        />
                        <AddIcon />
                        Upload Image
                      </Button>
                    ) : (
                      <div style={{ position: "relative" }}>
                        <CancelIcon
                          className={styles.closeBtn}
                          onClick={() => {
                            setSlectedFile(null);
                            setDisplayImg("");
                          }}
                        />
                        <img
                          src={displayImg}
                          style={{
                            width: "100%",
                            height: 150,
                            objectFit: "cover",
                            border: `1px solid ${color.bordercolor}`,
                          }}
                          alt="Image"
                        />
                      </div>
                    )}
                    {errObj.imgErr ? (
                      <FormHelperText>
                        {errObj.imgMsg}
                        {}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                disabled={isOnline ? false : true || { loader }}
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                variant="contained"
                onClick={checkValidation}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : isEdit ? (
                  `Save & Update`
                ) : (
                  `Add ${data?.name}`
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MDropDown;
