import { makeStyles } from "@mui/styles";
import { isTablet } from "react-device-detect";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: color.white,
    borderRadius: 4,
    padding: isTablet ? 20 : 10,
    overflow: "auto",
    border: `1px solid ${color.bordercolor}`,
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
  dataMain: {
    height: 500,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  customtable: {
    "& tr td": {
      border: "none !important",
      padding: 0,
      color: "#666666CC",
      width: "20%",
    },
    "& thead th": {
      border: "none !important",
      padding: 0,
      width: "20%",
      background: "transparent !important",
    },
  },
}));

export default useStyles;
