import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import ScrapAnalysisComponent from "../../../Components/Scrap/ScrapAnalysis/index.js";
import AddScrapTyre from "../../../Components/Scrap/AddScrapTyre/index.js";
import ScrapDetails from "../../../Components/Scrap/ScrapDetails/index.js";
import { isEmpty } from "lodash";

export default function ScrapAnalysis() {
  const styles = useStyles();
  const location = useLocation();
  const data = location?.state;
  const [fleetData, setFleetData] = useState({});
  const [changeTab, setChangeTab] = useState("");
  const [editScrapTyreData, setEditScrapTyreData] = useState({});

  const [pageNoSA, setPageNoSA] = useState(1);
  const [recordSA, setRecordSA] = useState(10);

  const [pageNoSD, setPageNoSD] = useState(1);
  const [recordSD, setRecordSD] = useState(10);
  const [filterSD, setFilterSD] = useState("");
  const [search, setSearch] = useState("");
  const [datePickerT, setDatePickerT] = useState(null);
  const [datePickerF, setDatePickerF] = useState(null);

  const [from, setFrom] = useState("");

  useEffect(() => {
    document.title = Setting.page_name.ScrapAnalysis;
  }, []);
  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [changeTab]);

  return (
    <div className={styles.card}>
      <Grid container id="jump_to_me">
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator="›">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            {changeTab === "addScrapTyre" || changeTab === "scrapDetails" ? (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => setChangeTab("")}
              >
                Scrap Analysis
              </Link>
            ) : (
              <Typography className={styles.linkStyle}>
                Scrap Analysis
              </Typography>
            )}
            {changeTab === "scrapDetails" && (
              <Typography className={styles.linkStyle}>
                {fleetData?.name}
              </Typography>
            )}
            {changeTab === "addScrapTyre" && from === "details" && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => setChangeTab("scrapDetails")}
              >
                {fleetData?.name}
              </Link>
            )}
            {changeTab === "addScrapTyre" && (
              <Typography className={styles.linkStyle}>
                {isEmpty(editScrapTyreData)
                  ? "Add Scrap Tyre"
                  : "Edit Scrap Tyre"}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "addScrapTyre" ? (
        <AddScrapTyre
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab(from === "main" ? "" : "scrapDetails");
              setEditScrapTyreData({});
            }
          }}
          editData={editScrapTyreData}
          fleetDetails={fleetData}
          from={from}
        />
      ) : changeTab === "scrapDetails" ? (
        <ScrapDetails
          pageNo={pageNoSD}
          record={recordSD}
          filter={filterSD}
          handlePageNo={(page) => {
            setPageNoSD(page);
          }}
          handleRecord={(rec) => {
            setRecordSD(rec);
          }}
          handleFilter={(flt) => {
            setFilterSD(flt);
          }}
          handleClick={(type, data, from) => {
            if (type === "cancel") {
              setChangeTab("scrapDetails");
            } else if (type === "addScrapTyre") {
              setChangeTab("addScrapTyre");
              setEditScrapTyreData(data);
              setFrom("details");
            }
          }}
          fleetData={fleetData}
        />
      ) : (
        <ScrapAnalysisComponent
          pageNo={pageNoSA}
          record={recordSA}
          search={search}
          dateF={datePickerF}
          dateT={datePickerT}
          handleDateF={(date) => {
            setDatePickerF(date);
          }}
          handleDateT={(date) => {
            setDatePickerT(date);
          }}
          handleSearch={(search) => {
            setSearch(search);
          }}
          handlePageNo={(page) => {
            setPageNoSA(page);
          }}
          handleRecord={(rec) => {
            setRecordSA(rec);
          }}
          handleClick={(type, data) => {
            if (type === "addScrapTyre") {
              setChangeTab("addScrapTyre");
              setFrom("main");
            } else if (type === "scrapDetails") {
              setChangeTab("scrapDetails");
              setFleetData(data);
              setPageNoSD(1);
              setRecordSD(10);
              setFilterSD("");
            }
          }}
          data={data}
        />
      )}
    </div>
  );
}
