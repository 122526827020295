import React, { useEffect } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting.js";
import {
  Box,
  Grid,
  Modal,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { isMobile, isTablet } from "react-device-detect";
import { getApiData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";

export default function TabVehicleDetailTable(props) {
  const { vehicleDetail } = props;
  const styles = useStyles();
  const [vehicleDetails, setVehicleDetail] = useState({});
  const { dropDownData, token } = useSelector((state) => state.auth);
  const [openCard, setOpenCard] = useState(false);

  useEffect(() => {
    getVehicleDetails();
  }, []);

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${vehicleDetail?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const getTerrain = () => {
    let array = vehicleDetails?.terrain_ids?.split(",");
    let res = dropDownData?.terrain.filter((item) => {
      let a = array?.includes(item?.id?.toString());
      return a;
    });
    return res.map((v, i) => (
      <>
        {v?.label}
        {i < res.length - 1 ? ", " : ""}
      </>
    ));
  };

  const checkFunction = () => {
    if (!isEmpty(vehicleDetails) && !isEmpty(vehicleDetails?.rc_book)) {
      let Array = vehicleDetails?.rc_book?.split(".");
      if (Array[Array.length - 1] === "pdf") {
        const pdfWindow = window.open();
        pdfWindow.location.href = vehicleDetails?.rc_book;
      } else {
        setOpenCard(true);
      }
    }
  };

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);
  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Box
            id="jump_to_me"
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: "18px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight={"bold"}>Vehicle Details</Typography>
          </Box>
          <div style={{ margin: 20 }}>
            <Table>
              <TableRow>
                <TableCell variant="head">Vehicle No.</TableCell>
                <TableCell>{vehicleDetails?.vehicle_no || "-"}</TableCell>
                <TableCell variant="head">No. of Tyres</TableCell>
                <TableCell>
                  {vehicleDetails?.added_tyre_count} /
                  {vehicleDetails?.no_of_tyres || "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Type</TableCell>
                <TableCell>{vehicleDetails?.vehicle_type_id || "-"}</TableCell>
                <TableCell variant="head">Initial Reading</TableCell>
                <TableCell>
                  {vehicleDetails?.initial_reading || "-"} Km
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Brand</TableCell>
                <TableCell>{vehicleDetails?.vehicle_brand_id || "-"}</TableCell>
                <TableCell variant="head">Route Origin</TableCell>
                <TableCell>{vehicleDetails?.route_origin || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Model</TableCell>
                <TableCell>{vehicleDetails?.vehicle_model || "-"}</TableCell>
                <TableCell variant="head">Route Destination</TableCell>
                <TableCell>
                  {vehicleDetails?.route_destination || "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Terrain</TableCell>
                <TableCell>{getTerrain()}</TableCell>
                <TableCell variant="head">Driver’s Name</TableCell>
                <TableCell>{vehicleDetails?.driver_name || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Load Capacity</TableCell>
                <TableCell>
                  {vehicleDetails?.load_capicity || "-"}
                  {vehicleDetails?.load_capicity ? " Tonnes" : ""}
                </TableCell>
                <TableCell variant="head">Driver’s No.</TableCell>
                <TableCell>{vehicleDetails?.driver_phone || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Goods Carried</TableCell>
                <TableCell>{vehicleDetails?.goods_carried || "-"}</TableCell>
                <TableCell variant="head">Alignments Claimed</TableCell>
                <TableCell>
                  {`${vehicleDetails?.alignment_count}/5` || "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">RC Book Image</TableCell>
                <TableCell
                  style={{
                    color:
                      !isEmpty(vehicleDetails) &&
                      !isEmpty(vehicleDetails?.rc_book)
                        ? color.primary
                        : color.disable,
                    fontWeight: "bold",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    checkFunction();
                  }}
                >
                  View
                </TableCell>
                <Modal
                  className={styles.modal}
                  open={openCard}
                  onClose={() => {
                    setOpenCard(false);
                  }}
                >
                  <img
                    style={{
                      objectFit: "contain",
                      borderRadius: 4,
                      maxWidth: isTablet ? 550 : 330,
                      maxHeight: isTablet ? 550 : 330,
                    }}
                    src={vehicleDetails.rc_book || "-"}
                    alt={"RC Book"}
                  />
                </Modal>
              </TableRow>
            </Table>
          </div>
        </div>
      ) : (
        <>
          <Box
            id="jump_to_me"
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 15,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight={"bold"}>Vehicle Details</Typography>
          </Box>
          <div className={styles.container} style={{ padding: 10 }}>
            <div style={{ position: "relative" }}>
              <Grid
                item
                container
                style={{
                  border: `1px solid ${color.bordercolor}`,
                  alignItems: "center",
                }}
              >
                <Grid item container>
                  <Table className={styles.customtableMobile}>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Vehicle No.</TableCell>
                        <TableCell variant="head">No. of Tyres</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.vehicle_no || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.added_tyre_count} /
                          {vehicleDetails?.no_of_tyres || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Vehicle Type</TableCell>
                        <TableCell variant="head">Initial Reading</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.vehicle_type_id || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.initial_reading || "-"} Km
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Vehicle Brand</TableCell>
                        <TableCell variant="head">Route Origin</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.vehicle_brand_id || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.route_origin || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Vehicle Model</TableCell>
                        <TableCell variant="head">Route Destination</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.vehicle_model || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.route_destination || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Terrain</TableCell>
                        <TableCell variant="head">Driver’s Name</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {getTerrain() || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.driver_name || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Load Capacity</TableCell>
                        <TableCell variant="head">Driver’s No.</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.load_capicity || "-"}
                          {vehicleDetails?.load_capicity ? " Tonnes" : ""}
                        </TableCell>
                        <TableCell
                          variant="body"
                          style={{
                            paddingBottom: 5,
                          }}
                        >
                          {vehicleDetails?.driver_phone || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head">Goods Carried</TableCell>
                        <TableCell variant="head">Alignments Claimed</TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {vehicleDetails?.goods_carried || "-"}
                        </TableCell>
                        <TableCell variant="body" style={{ paddingBottom: 5 }}>
                          {`${vehicleDetails?.alignment_count}/5` || "-"}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableHead>
                      <TableRow>
                        <TableCell variant="head" colSpan={2}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              wrap: "nowrap",
                            }}
                          >
                            <Typography
                              style={{
                                fontWeight: "bold",
                              }}
                            >
                              RC Book Image
                            </Typography>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell
                          colSpan={2}
                          style={{
                            color:
                              !isEmpty(vehicleDetails) &&
                              !isEmpty(vehicleDetails?.rc_book)
                                ? color.primary
                                : color.disable,
                            fontWeight: "bold",
                            cursor: "pointer",
                          }}
                          onClick={() => {
                            checkFunction();
                          }}
                        >
                          View
                        </TableCell>
                        <Modal
                          className={styles.modal}
                          open={openCard}
                          onClose={() => {
                            setOpenCard(false);
                          }}
                        >
                          <img
                            style={{
                              objectFit: "contain",
                              borderRadius: 4,
                              maxWidth: isMobile ? 330 : 550,
                              maxHeight: isMobile ? 330 : 550,
                            }}
                            src={vehicleDetails.rc_book || "-"}
                            alt={"RC Book"}
                          />
                        </Modal>
                      </TableRow>
                    </TableHead>
                  </Table>
                </Grid>
              </Grid>
            </div>
          </div>
        </>
      )}
    </>
  );
}
