import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  InputAdornment,
  Button,
  Typography,
  Pagination,
  CircularProgress,
  useTheme,
  useMediaQuery,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import useStyles from "./styles";
import { useSelector } from "react-redux";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { toast } from "react-toastify";
import { color } from "../../../Config/theme";
import moment from "moment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import color from "../../../Config/theme/color";
import * as XLSX from "xlsx/xlsx.mjs";
import InfoIcon from "@mui/icons-material/Info";

function ConditionHistory(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleSearch = () => null,
    search = "",
    handleDate = () => null,
    dateF = null,
    pageNo = 1,
    record = 10,
  } = props;
  // const [datePicker, setDatePicker] = useState(null);
  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  const [conditionHistoryRows, setConditionHistoryRows] = useState({});
  const styles = useStyles();

  const { token, userdata } = useSelector((state) => state.auth);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const column = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              handleClick("tyreDetails", params?.row);
            }}
          >
            <Typography
              style={{
                whiteSpace: "pre-wrap",
                color: color.primary,
                fontWeight: "bold",
              }}
            >
              {params?.row?.tyre_serial_no}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "condition_changed",
      headerName: "No. of Condition Changes",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "last_condition_change",
      headerName: "Last Condition Change",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.last_condition_change;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");

        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    lifeHistoryList(false);
  }, [pageNo, record]);

  useEffect(() => {
    isNull(dateF) && isEmpty(search) && lifeHistoryList(false);
  }, [dateF, search]);

  useImperativeHandle(ref, () => ({
    conditionHistoryRows: conditionHistoryRows,
    downloadConditionData: (v) => {
      lifeHistoryList(false, v);
    },
  }));

  function downloadConditionData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.last_condition_change;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "No. of Condition Changes": Number(row?.condition_changed),
        "Last Condition Changes": displayDate || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Tyre Condition History List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Condition History List.xlsx");
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function lifeHistoryList(bool, v) {
    v && handleLoader(true);
    !v && setPageLoad(true);
    const date = !isNull(dateF) ? moment(dateF).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.conditionHistory
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : search
        }&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadConditionData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setConditionHistoryRows(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Condition History List");
      handleLoader(false);
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      handleLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function resetFilterData() {
    handleSearch("");
    handleDate(null);
  }

  return (
    <>
      <div style={{ marginTop: 14 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid
            item
            lg={10.4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid
              item
              lg={4}
              style={{ display: "flex", alignItems: "center" }}
              wrap={"nowrap"}
            >
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && lifeHistoryList(true);
                  handleSearch(v?.target?.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Tyre Serial No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(search) || !isNull(dateF)) {
                      handlePageNo(1);
                      lifeHistoryList(false);
                    }
                  }
                }}
              />
            </Grid>
            <div style={{ marginLeft: 20 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  views={["year", "month", "day"]}
                  value={dateF}
                  onChange={(newValue) => {
                    handleDate(newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.dateF }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(search) || !isNull(dateF)) {
                    handlePageNo(1);
                    lifeHistoryList(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={isEmpty(search) && isNull(dateF) ? true : false}
              >
                Reset
              </Button>
            </div>
          </Grid>

          <Grid
            item
            lg={1.6}
            style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
          >
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                handlePageNo(1);
                handleRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(conditionHistoryRows?.data) &&
          !isEmpty(conditionHistoryRows?.data) ? (
          <>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                pageSize={record}
                rows={conditionHistoryRows?.data}
                columns={column}
                disableColumnMenu
                hideFooter
                showCellRightBorder
                disableSelectionOnClick
                showColumnRightBorder
                autoHeight={true}
                onCellClick={(params) => {
                  if (params?.field !== "tyre_serial_no") {
                    handleClick("conditonDetails", params?.row);
                  }
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(conditionHistoryRows?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  handlePageNo(e);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
    </>
  );
}

export default forwardRef(ConditionHistory);
