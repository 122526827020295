import React, { useState } from "react";
import useStyles from "./styles.js";
import Images from "../../../Config/Images";
import { Grid, Typography, Box, CircularProgress } from "@mui/material";
import TyreIssue from "../../Modal/TyreIssue/index.js";
import { useEffect } from "react";
import { isArray, isEmpty, isObject } from "lodash";
import { getApiData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { color } from "../../../Config/theme.js";

export default function TabTyreHealthVisualization(props) {
  const { vehicleData } = props;
  const { token } = useSelector((state) => state.auth);

  const styles = useStyles();
  const [visible, setVisible] = useState(false);

  const [modalData, setModalData] = useState({});

  const [healthData, setHealthData] = useState([]);
  const [issueCount, setissueCount] = useState({});
  const [pageLoad, setPageLoad] = useState(true);

  const [tyreHealthLeft, setTyreHealthLeft] = useState([]);
  const [tyreHealthRight, setTyreHealthRight] = useState([]);

  useEffect(() => {
    const dummyArr = [...healthData];
    const leftArr = [];
    const rightArr = [];

    isArray(dummyArr) &&
      dummyArr?.map((item, index) => {
        let a = [
          { tyre_position: "121O" },
          { tyre_position: "211M" },
          { tyre_position: "111I" },
        ];
        let b = [
          { tyre_position: "112O" },
          { tyre_position: "121M" },
          { tyre_position: "111I" },
        ];

        item?.tyres?.find((v) => {
          if (v?.tyre_position[0] === "C") {
            if (v?.tyre_position[3] === "I") {
              a.splice(2, 1, v);
            } else if (v?.tyre_position[3] === "M") {
              a.splice(1, 1, v);
            } else if (v?.tyre_position[3] === "O") {
              a.splice(0, 1, v);
            }
          } else if (v?.tyre_position[0] === "D") {
            if (v?.tyre_position[3] === "I") {
              b.splice(2, 1, v);
            } else if (v?.tyre_position[3] === "M") {
              b.splice(1, 1, v);
            } else if (v?.tyre_position[3] === "O") {
              b.splice(0, 1, v);
            }
          }
        });
        leftArr.push(a);
        rightArr.push(b);
      });

    setTyreHealthLeft(leftArr);
    setTyreHealthRight(rightArr);
  }, [healthData]);

  useEffect(() => {
    setPageLoad(true);
    getTyreHealth();
    getTyreIssueCount();
  }, []);

  async function getTyreHealth(bool) {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreHealth}?vehicle_id=${vehicleData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          setHealthData(response?.data);
        } else {
          setHealthData([]);
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getTyreIssueCount() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.issueCount}?vehicle_id=${vehicleData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          setissueCount(response?.data);
        } else {
          setissueCount({});
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const handleModal = (item, type) => {
    if (type === "open") {
      setModalData(item);
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  return (
    <>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(healthData) && !isEmpty(healthData) ? (
        <div>
          <Grid
            container
            style={{ marginTop: 30 }}
            justifyContent="space-around"
            gap={2}
            wrap="wrap-reverse"
          >
            <Grid
              xs={12}
              lg={3.8}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Box style={{ width: 250, position: "relative" }}>
                <img
                  src={Images.TruckW}
                  alt={Image.TruckW}
                  style={{ width: "100%", marginTop: 40 }}
                />
                <Grid
                  style={{
                    position: "absolute",
                    top: 230,
                    left: -35,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: 400,
                  }}
                >
                  {tyreHealthLeft?.map((v, i) => (
                    <Grid item container columnGap={0.5}>
                      {isArray(v) &&
                        !isEmpty(v) &&
                        v?.map((item, index) => {
                          return (
                            <>
                              {item?.tyre_position[3] === "O" ? (
                                <div
                                  onClick={() => {
                                    if (item?.tyre_condition) {
                                      handleModal(item, "open");
                                    }
                                  }}
                                  style={{
                                    width: 12,
                                    backgroundColor:
                                      item?.tyre_condition === "Good"
                                        ? "#6FCF97"
                                        : item?.tyre_condition === "Bad"
                                        ? "#EB5757"
                                        : item?.tyre_condition === "Average"
                                        ? "#F2C94C"
                                        : color.bordercolor,
                                    height: 50,
                                    borderRadius: 5,
                                  }}
                                />
                              ) : item?.tyre_position[3] === "I" ? (
                                <div
                                  onClick={() => {
                                    if (item?.tyre_condition) {
                                      handleModal(item, "open");
                                    }
                                  }}
                                  style={{
                                    width: 12,
                                    backgroundColor:
                                      item?.tyre_condition === "Good"
                                        ? "#6FCF97"
                                        : item?.tyre_condition === "Bad"
                                        ? "#EB5757"
                                        : item?.tyre_condition === "Average"
                                        ? "#F2C94C"
                                        : color.bordercolor,
                                    height: 50,
                                    borderRadius: 5,
                                  }}
                                />
                              ) : null}
                            </>
                          );
                        })}
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  style={{
                    position: "absolute",
                    top: 230,
                    left: 35,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: 400,
                  }}
                >
                  {healthData?.map((v, i) => (
                    <Grid
                      item
                      container
                      style={{
                        backgroundColor: color.white,
                        height: 20,
                        width: 180,
                        borderRadius: 30,
                        boxShadow: "0px 0px 10px #000",
                        position: "relative",
                      }}
                    >
                      <Grid
                        xs={6}
                        style={{
                          backgroundColor:
                            v?.axle_data?.c?.condition === "Good"
                              ? "#6FCF97"
                              : v?.axle_data?.c?.condition === "Average"
                              ? "#F2C94C"
                              : v?.axle_data?.c?.condition === "Bad"
                              ? "#EB5757"
                              : color?.bordercolor,
                          borderTopLeftRadius: 4,
                          borderBottomLeftRadius: 4,
                        }}
                      />
                      <Typography
                        style={{
                          position: "absolute",
                          fontSize: 12,
                          // color: color.white,
                          left: "40%",
                          top: 2,
                        }}
                      >
                        {`Axle ${i + 1}`}
                      </Typography>
                      <Grid
                        xs={6}
                        style={{
                          backgroundColor:
                            v?.axle_data?.d?.condition === "Good"
                              ? "#6FCF97"
                              : v?.axle_data?.d?.condition === "Average"
                              ? "#F2C94C"
                              : v?.axle_data?.d?.condition === "Bad"
                              ? "#EB5757"
                              : color?.bordercolor,
                          borderTopRightRadius: 4,
                          borderBottomRightRadius: 4,
                        }}
                      />
                    </Grid>
                  ))}
                </Grid>
                <Grid
                  style={{
                    position: "absolute",
                    top: 230,
                    right: -35,
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-around",
                    height: 400,
                  }}
                >
                  {tyreHealthRight?.map((v, i) => (
                    <Grid
                      item
                      container
                      columnGap={0.5}
                      flexDirection="row-reverse"
                    >
                      {isArray(v) &&
                        !isEmpty(v) &&
                        v?.map((item, index) => {
                          return (
                            <>
                              {item?.tyre_position[3] === "I" ? (
                                <div
                                  onClick={() => {
                                    if (item?.tyre_condition) {
                                      handleModal(item, "open");
                                    }
                                  }}
                                  style={{
                                    width: 12,
                                    backgroundColor:
                                      item?.tyre_condition === "Good"
                                        ? "#6FCF97"
                                        : item?.tyre_condition === "Bad"
                                        ? "#EB5757"
                                        : item?.tyre_condition === "Average"
                                        ? "#F2C94C"
                                        : color.bordercolor,
                                    height: 50,
                                    borderRadius: 5,
                                  }}
                                />
                              ) : item?.tyre_position[3] === "O" ? (
                                <div
                                  onClick={() => {
                                    if (item?.tyre_condition) {
                                      handleModal(item, "open");
                                    }
                                  }}
                                  style={{
                                    width: 12,
                                    backgroundColor:
                                      item?.tyre_condition === "Good"
                                        ? "#6FCF97"
                                        : item?.tyre_condition === "Bad"
                                        ? "#EB5757"
                                        : item?.tyre_condition === "Average"
                                        ? "#F2C94C"
                                        : color.bordercolor,
                                    height: 50,
                                    borderRadius: 5,
                                  }}
                                />
                              ) : null}
                            </>
                          );
                        })}
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Grid xs={12} lg={7.8}>
              <Grid md={12} item display="flex" flexWrap="nowrap" gap={2}>
                <Grid
                  xs={5.8}
                  sm={5.8}
                  md={3.8}
                  lg={5.8}
                  xl={3.8}
                  className={styles.cardBox}
                >
                  <Typography className={styles.cardHead}>No Issues</Typography>
                  <Typography className={styles.cardValue}>
                    {issueCount?.no_issue || 0}
                  </Typography>
                </Grid>
                <Grid
                  xs={5.8}
                  sm={5.8}
                  md={3.8}
                  lg={5.8}
                  xl={3.8}
                  className={styles.cardBox}
                >
                  <Typography className={styles.cardHead}>
                    Minor Issues
                  </Typography>
                  <Typography className={styles.cardValue}>
                    {issueCount?.minor || 0}
                  </Typography>
                </Grid>
                <Grid
                  xs={5.8}
                  sm={5.8}
                  md={3.8}
                  lg={5.8}
                  xl={3.8}
                  className={styles.cardBox}
                >
                  <Typography className={styles.cardHead}>
                    Major Issues
                  </Typography>
                  <Typography className={styles.cardValue}>
                    {issueCount?.major || 0}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
      <TyreIssue
        visible={visible}
        handleModal={handleModal}
        modalData={modalData}
      />
    </>
  );
}
