import { makeStyles } from "@mui/styles";
import { isTablet } from "react-device-detect";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
    padding: isTablet ? 20 : 10,
    border: `1px solid ${color.bordercolor}`,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
    width: "100%",
  },
  customtable: {
    "& tbody": {
      "&:nth-child(even)": {
        backgroundColor: "#F3F0F0",
      },
    },
    "& tr td,tr th": {
      border: "none !important",
      width: "20%",
      background: "transparent !important",
    },
  },

  customtableMobile: {
    "& tr td": {
      border: "none !important",
      padding: 0,
      color: "#666666CC",
      width: "50%",
    },
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: "#EEF8FF",
      },
    },
  },
  scrollBtn: {
    position: "absolute",
    padding: 5,
    bottom: 0,
    background:
      "linear-gradient(180deg, rgba(217, 248, 255, 0) 0%, rgba(54, 65, 80, 0.5) 100%)",
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
