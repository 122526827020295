import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";
import { isTablet } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flexDirection: "column",
    overflow: "auto",
    border: `1px solid ${color.bordercolor}`,
    padding: isTablet ? 20 : 10,
    flex: 1,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
  },
  CustomtableMobile: {
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: "#EEF8FF",
      },
    },
  },
}));
export default useStyles;
