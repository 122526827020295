import React, { useEffect, useState } from "react";
import {
  Typography,
  Backdrop,
  Fade,
  Box,
  Modal,
  Button,
  CircularProgress,
  useMediaQuery,
  useTheme,
  Grid,
  MenuItem,
  Checkbox,
  FormHelperText,
  Select,
  FormControl,
  Autocomplete,
  TextField,
} from "@mui/material";
import useStyles from "./styles.js";
import { Close } from "@mui/icons-material";
import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import { isArray, isEmpty } from "lodash";
import { Setting } from "../../../Utils/Setting.js";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";

const errorObj = {
  fleetAllocatedErr: false,
  fleetAllocatedMsg: "",
};

function MAllocatedFleet(props) {
  const {
    visible = false,
    handleModal = () => null,
    onSave = () => null,
    editData = {},
  } = props;

  const [fleetAllocated, setFleetAllocated] = useState([]);

  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const { fleetData } = useSelector((state) => state.auth);
  const [errObj, setErrObj] = useState(errorObj);
  const [loader, setLoader] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 290 : 700,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    padding: 0,
  };

  useEffect(() => {
    if (visible) {
      let array = editData.fleet_ids;
      let res =
        isArray(fleetData) &&
        !isEmpty(fleetData) &&
        fleetData.filter((item) => {
          let a = array.includes(item.id);
          return a;
        });
      setFleetAllocated(res);
    }
    if (!visible) {
      resetFormData();
    }
  }, [visible]);

  async function addFleetAllocated() {
    setLoader(true);
    let endPoints = Setting.endpoints.changefleetallocated;
    const array = [];
    isArray(fleetAllocated) &&
      !isEmpty(fleetAllocated) &&
      fleetAllocated.map((v) => {
        array.push(v.id);
      });
    const params = {
      "ChangeAllocatedFleetForm[user_id]": editData?.id,
      "ChangeAllocatedFleetForm[role]": editData?.role,
    };
    params[`ChangeAllocatedFleetForm[fleet_allocated]`] = array;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        resetFormData();
        onSave();
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
      toast.error(error.toString());
    }
  }

  // this function for reset form data
  function resetFormData() {
    setErrObj(errorObj);
    setFleetAllocated([]);
    setLoader(false);
    if (handleModal) {
      handleModal();
    }
  }

  const handleChange = (event) => {
    setFleetAllocated(event?.target?.value);
    setErrObj({
      ...errObj,
      fleetAllocatedErr: false,
      fleetAllocatedMsg: "",
    });
  };

  function checkValidation() {
    let valid = true;
    const error = { ...errObj };

    if (isEmpty(fleetAllocated)) {
      valid = false;
      error.fleetAllocatedErr = true;
      error.fleetAllocatedMsg = "Please select the Fleet Allocated";
    }

    setErrObj(error);
    if (valid) {
      addFleetAllocated();
    }
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData()}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <div className={styles.modalHeader}>
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              Change Allocated Fleet
            </Typography>
            <Close
              style={{ color: color.white, cursor: "pointer" }}
              onClick={() => resetFormData()}
            />
          </div>
          <div className={styles.modalMain}>
            <Grid
              container
              alignItems={"center"}
              style={{
                marginBottom: 20,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={3} lg={3}>
                <Typography>User ID : </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Typography>{editData?.user_name || "User ID"}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              style={{
                marginBottom: 20,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} md={3} lg={3}>
                <Typography>Name : </Typography>
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Typography>{editData?.name || "User Name"}</Typography>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              style={{
                marginBottom: 20,
                marginTop: 10,
              }}
            >
              <Grid item xs={12} sm={12} md={3} lg={3}>
                <CTypography required title={"Fleets Allocated"} />
              </Grid>
              <Grid item xs={12} md={9} lg={9}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth error={errObj.fleetAllocatedErr}>
                    <Autocomplete
                      fullWidth
                      multiple
                      disableListWrap={true}
                      options={fleetData}
                      // filterSelectedOptions
                      value={fleetAllocated}
                      classes={{ input: `${styles.autocomplete}` }}
                      onChange={(v1, v) => {
                        setFleetAllocated(v);
                        setErrObj({
                          ...errObj,
                          fleetAllocatedErr: false,
                          fleetAllocatedMsg: "",
                        });
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          className={styles.inputDivStyle}
                          placeholder={
                            isEmpty(fleetAllocated)
                              ? "Search/Select the Allocated Fleet"
                              : null
                          }
                        />
                      )}
                      renderTags={(tagValue, getTagProps) => {
                        const renderTagsValue = tagValue
                          .map(function (elem) {
                            return elem.label;
                          })
                          .join(", ");
                        return (
                          // <Typography noWrap={true} color="textPrimary">
                          renderTagsValue
                          // </Typography>
                        );
                      }}
                    />
                    {errObj.fleetAllocatedErr ? (
                      <FormHelperText>
                        {errObj.fleetAllocatedMsg}
                      </FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <div style={{ display: "flex", justifyContent: "center" }}>
              <Button
                variant="contained"
                disabled={loader}
                onClick={checkValidation}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  "Update"
                )}
              </Button>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary, marginLeft: 10 }}
                disabled={loader}
                onClick={() => {
                  resetFormData();
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </Box>
      </Fade>
    </Modal>
  );
}
export default MAllocatedFleet;
