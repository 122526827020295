import React, { useEffect } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { useState } from "react";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import { isEmpty } from "lodash";
import { getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { Setting } from "../../../Utils/Setting.js";
import moment from "moment";
import { tyre_condition } from "../../../Config/Static_Data.js";
import { useSelector } from "react-redux";
import { isTablet } from "react-device-detect";

const errorObj = {
  remarksErr: false,
  remarksMsg: "",
  statusErr: false,
  statusMsg: "",
  mstatusErr: false,
  mstatusMsg: "",
};

export default function TabResolveIssue(props) {
  const {
    vehicleDetails,
    handleClick = () => null,
    fleetData,
    from,
    issueType,
    issue,
  } = props;
  const { isOnline, dropDownData } = useSelector((state) => state.auth);
  const [tyreCondition, setTyreCondition] = useState(
    vehicleDetails?.tyre_condition || ""
  );
  const [issueStatus, setIssueStatus] = useState("");
  const [remarks, setRemarks] = useState("");
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  const [issueDisable, setIssueDisable] = useState(false);

  const styles = useStyles();
  const [errObj, setErrObj] = useState(errorObj);

  useEffect(() => {
    if (isOnline) {
      document.title = Setting.page_name.Inspection;
    }
  }, [isOnline]);

  useEffect(() => {
    if (
      from !== "fleetdetails" &&
      from !== "inspection" &&
      from !== "fleet" &&
      from !== "issueDetails"
    ) {
      if (vehicleDetails?.status !== 0) {
        setIssueStatus(vehicleDetails?.status || "");
        if (vehicleDetails?.status === 1) {
          setIssueDisable(true);
        }
      }
    } else if (from === "issueDetails") {
      setIssueStatus("1");
    }
  }, []);

  const validatior = () => {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(remarks.trim())) {
      valid = false;
      error.remarksErr = true;
      error.remarksMsg = "Please enter Remarks";
    }

    if (isEmpty(issueStatus.toString())) {
      valid = false;
      error.statusErr = true;
      error.statusMsg = "Please select Tyre Issue Status";
    }

    setErrObj(error);
    if (valid) {
      submitData();
    }
  };

  const submitData = async () => {
    setRemoveBtnLoad(false);
    let endPoints = Setting.endpoints.resolveissue;

    let issId =
      issueType === "tyre"
        ? dropDownData?.tyre_issue_inspection?.find(
            (item) => issue?.toString() === item?.label?.toString()
          )
        : issueType === "mechanical"
        ? dropDownData?.mechanical_issue?.find(
            (item) => issue?.toString() === item?.label?.toString()
          )
        : "";

    const params = {
      "ResolutionForm[id]":
        from === "issueDetails"
          ? vehicleDetails?.inspection_id
          : vehicleDetails?.id,
      "ResolutionForm[vehicle_id]": vehicleDetails?.vehicle_id,
      "ResolutionForm[condition]": tyreCondition,
      "ResolutionForm[status]": issueStatus,
      "ResolutionForm[remark]": remarks,
      "ResolutionForm[type]": issueType,
      "ResolutionForm[issue_id]": issId?.id,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        handleClick("cancel");
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      console.log("ERRRRR", error);
      toast.error(error.toString());
      setRemoveBtnLoad(false);
    }
  };

  function clearData() {
    setErrObj(errorObj);
  }

  function getInspectedDate() {
    const timestamp = vehicleDetails?.capture_date;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return vehicleDetails?.capture_date ? displayDate : "";
  }

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);
  return isTablet ? (
    <>
      <Box
        id="jump_to_me"
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight={"bold"}>Resolve Issue</Typography>
      </Box>
      <div className={styles.container}>
        <Table>
          <TableRow>
            <TableCell variant="head">Date Reported</TableCell>
            <TableCell>{getInspectedDate()}</TableCell>
            <TableCell variant="head">Tyre Type</TableCell>
            <TableCell>{vehicleDetails?.tyre_type || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Reported By</TableCell>
            <TableCell>{vehicleDetails?.inspected_by || "-"}</TableCell>
            <TableCell variant="head">Tyre Brand</TableCell>
            <TableCell>{vehicleDetails?.tyre_brand || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Tyre Serial No.</TableCell>
            <TableCell>{vehicleDetails?.tyre_serial_no || "-"}</TableCell>
            <TableCell variant="head">Item Description</TableCell>
            <TableCell>{vehicleDetails?.tyre_pattern_size || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Vehicle No.</TableCell>
            <TableCell>{vehicleDetails?.vehicle_no || "-"}</TableCell>
            <TableCell variant="head">Tyre Issue</TableCell>
            <TableCell>
              <div
                style={{
                  fontSize: 14,
                  padding: 8,
                  borderRadius: 4,
                  backgroundColor: issue && "rgba(53, 152, 220, 0.1)",
                  width: "fit-content",
                }}
              >
                <Typography>{issue || "-"}</Typography>
              </div>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Fleet Name</TableCell>
            <TableCell>
              {from === "fleetdetails"
                ? fleetData?.name
                : from === "fleet"
                ? vehicleDetails?.fleet_name
                : fleetData?.fleet_name || "-"}
            </TableCell>
          </TableRow>
        </Table>
        <div style={{ marginTop: 20 }}>
          {from !== "issueDetails" && (
            <Grid container alignItems={"center"} style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={3} md={3} lg={2.4}>
                <Typography>Tyre Condition:</Typography>
              </Grid>

              <Grid item xs={12} sm={9} md={9} lg={9.6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      value={tyreCondition}
                      onChange={(event) => {
                        setTyreCondition(event.target.value);
                      }}
                      size={"medium"}
                      style={tyreCondition === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} hiddend selected disabled>
                        Select the tyre Condition
                      </MenuItem>
                      {tyre_condition?.map((item) => {
                        return (
                          <MenuItem
                            value={item?.label}
                            selected={
                              item.label === vehicleDetails?.tyre_condition
                            }
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.statusErr ? 30 : 20 }}
          >
            <Grid item xs={12} sm={3} md={3} lg={2.4}>
              <CTypography required title={"Issue Status"} />
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9.6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth error={errObj.statusErr}>
                  <Select
                    disabled={issueDisable || from === "issueDetails"}
                    displayEmpty
                    value={issueStatus}
                    onChange={(event) => {
                      setIssueStatus(event.target.value);
                      setErrObj({
                        ...errObj,
                        statusErr: false,
                        statusMsg: "",
                      });
                    }}
                    size={"medium"}
                    style={issueStatus === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""}>Select the Tyre Issue Status</MenuItem>
                    <MenuItem value={2}>Partially Resolve</MenuItem>
                    <MenuItem value={1}>Resolve</MenuItem>
                  </Select>
                  {errObj.statusErr ? (
                    <FormHelperText>{errObj.statusMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.mstatusErr ? 30 : 20 }}
          >
            <Grid item xs={12} sm={3} md={3} lg={2.4}>
              <CTypography required title={"Mechanical Issue Status"} />
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9.6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth error={errObj.mstatusErr}>
                  <Select
                    disabled={missueDisable}
                    displayEmpty
                    value={mIssueStatus}
                    onChange={(event) => {
                      setmIssueStatus(event.target.value);
                      setErrObj({
                        ...errObj,
                        mstatusErr: false,
                        mstatusMsg: "",
                      });
                    }}
                    size={"medium"}
                    style={mIssueStatus === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""}>
                      Select the Mechanical Issue Status
                    </MenuItem>
                    <MenuItem value={0}>Pending</MenuItem>
                    <MenuItem value={2}>Partially Resolve</MenuItem>
                    <MenuItem value={1}>Resolve</MenuItem>
                    <MenuItem value={2}>Closed</MenuItem>
                  </Select>
                  {errObj.mstatusErr ? (
                    <FormHelperText>{errObj.mstatusMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid> */}
          <Grid container style={{ marginBottom: errObj.remarksErr ? 30 : 20 }}>
            <Grid item xs={12} sm={3} md={3} lg={2.4}>
              <CTypography required title={"Remarks"} />
            </Grid>

            <Grid item xs={12} sm={9} md={9} lg={9.6}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Write Resolution Remarks"
                rows={4}
                multiline
                value={remarks}
                error={errObj.remarksErr}
                helperText={errObj.remarksErr ? errObj.remarksMsg : null}
                onChange={(e) => {
                  setRemarks(e.target.value);
                  setErrObj({
                    ...errObj,
                    remarksErr: false,
                    remarksMsg: "",
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            style={{ marginTop: 40 }}
          >
            <Button
              onClick={validatior}
              style={{
                backgroundColor: color.primary,
                marginRight: 10,
              }}
            >
              {removeBtnLoad ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                "Resolve"
              )}
            </Button>
            <Button
              style={{
                backgroundColor: color.secondary,
              }}
              onClick={() => {
                handleClick("cancel");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </div>
      </div>
    </>
  ) : (
    <>
      <Box
        id="jump_to_me"
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight={"bold"}>Resolve Issue</Typography>
      </Box>
      <div className={styles.container}>
        <div style={{ position: "relative" }}>
          <Grid
            item
            container
            style={{
              border: `1px solid ${color.bordercolor}`,
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Grid item container>
              <Table className={styles.customtable}>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Date Reported</TableCell>
                    <TableCell variant="head">Tyre Type</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      {getInspectedDate() || "-"}
                    </TableCell>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      {vehicleDetails?.tyre_type || "-"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Reported By</TableCell>
                    <TableCell variant="head">Tyre Brand</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      {vehicleDetails?.inspected_by || "-"}
                    </TableCell>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      {vehicleDetails?.tyre_brand || "-"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Tyre Serial No.</TableCell>
                    <TableCell variant="head">Item Description</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      {vehicleDetails?.tyre_serial_no || "-"}
                    </TableCell>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      {vehicleDetails?.tyre_pattern_size || "-"}
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Vehicle No.</TableCell>
                    <TableCell variant="head">Issue</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      {vehicleDetails?.vehicle_no || "-"}
                    </TableCell>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      <div
                        style={{
                          fontSize: 14,
                          padding: 8,
                          borderRadius: 4,
                          backgroundColor: issue && "rgba(53, 152, 220, 0.1)",
                          width: "fit-content",
                        }}
                      >
                        <Typography>{issue || "-"}</Typography>
                      </div>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableHead>
                  <TableRow>
                    <TableCell variant="head">Fleet Name</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="body" style={{ paddingBottom: 5 }}>
                      {from === "fleetdetails"
                        ? fleetData?.name
                        : from === "fleet"
                        ? vehicleDetails?.fleet_name
                        : fleetData?.fleet_name || "-"}
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Grid>
          </Grid>
        </div>

        <div style={{ marginTop: 20 }}>
          {from !== "issueDetails" && (
            <Grid container alignItems={"center"} style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={12} md={3} lg={2.4}>
                <Typography>Tyre Condition:</Typography>
              </Grid>

              <Grid item xs={12} md={9} lg={9.6}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <Select
                      displayEmpty
                      value={tyreCondition}
                      onChange={(event) => {
                        setTyreCondition(event.target.value);
                      }}
                      size={"medium"}
                      style={tyreCondition === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} hiddend selected disabled>
                        Select the tyre Condition
                      </MenuItem>
                      {tyre_condition?.map((item) => {
                        return (
                          <MenuItem
                            value={item?.label}
                            selected={
                              item.label === vehicleDetails?.tyre_condition
                            }
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Grid>
            </Grid>
          )}
          <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.statusErr ? 30 : 20 }}
          >
            <Grid item xs={12} sm={12} md={3} lg={2.4}>
              <CTypography required title={"Issue Status"} />
            </Grid>

            <Grid item xs={12} md={9} lg={9.6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth error={errObj.statusErr}>
                  <Select
                    disabled={issueDisable || from === "issueDetails"}
                    displayEmpty
                    value={issueStatus}
                    onChange={(event) => {
                      setIssueStatus(event.target.value);
                      setErrObj({
                        ...errObj,
                        statusErr: false,
                        statusMsg: "",
                      });
                    }}
                    size={"medium"}
                    style={issueStatus === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""}>Select the Tyre Issue Status</MenuItem>
                    <MenuItem value={2}>Partially Resolve</MenuItem>
                    <MenuItem value={1}>Resolve</MenuItem>
                  </Select>
                  {errObj.statusErr ? (
                    <FormHelperText>{errObj.statusMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid>
          {/* <Grid
            container
            alignItems={"center"}
            style={{ marginBottom: errObj.mstatusErr ? 30 : 20 }}
          >
            <Grid item xs={12} sm={12} md={3} lg={2.4}>
              <CTypography required title={"Mechanical Issue Status"} />
            </Grid>

            <Grid item xs={12} md={9} lg={9.6}>
              <Box sx={{ minWidth: 120 }}>
                <FormControl fullWidth error={errObj.mstatusErr}>
                  <Select
                    disabled={missueDisable}
                    displayEmpty
                    value={mIssueStatus}
                    onChange={(event) => {
                      setmIssueStatus(event.target.value);
                      setErrObj({
                        ...errObj,
                        mstatusErr: false,
                        mstatusMsg: "",
                      });
                    }}
                    size={"medium"}
                    style={mIssueStatus === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""}>
                      Select the Mechanical Issue Status
                    </MenuItem>
                    <MenuItem value={0}>Pending</MenuItem>
                    <MenuItem value={2}>Partially Resolve</MenuItem>
                    <MenuItem value={1}>Resolve</MenuItem>
                  </Select>
                  {errObj.mstatusErr ? (
                    <FormHelperText>{errObj.mstatusMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Box>
            </Grid>
          </Grid> */}
          <Grid container style={{ marginBottom: errObj.remarksErr ? 30 : 20 }}>
            <Grid item xs={12} sm={12} md={3} lg={2.4}>
              <CTypography required title={"Remarks"} />
            </Grid>

            <Grid item xs={12} md={9} lg={9.6}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Write Resolution Remarks"
                rows={4}
                multiline
                value={remarks}
                error={errObj.remarksErr}
                helperText={errObj.remarksErr ? errObj.remarksMsg : null}
                onChange={(e) => {
                  setRemarks(e.target.value);
                  setErrObj({
                    ...errObj,
                    remarksErr: false,
                    remarksMsg: "",
                  });
                }}
              />
            </Grid>
          </Grid>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            style={{ marginTop: 40 }}
          >
            <Button
              onClick={validatior}
              style={{
                backgroundColor: color.primary,
                marginRight: 10,
              }}
            >
              {removeBtnLoad ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                "Resolve"
              )}
            </Button>
            <Button
              style={{
                backgroundColor: color.secondary,
              }}
              onClick={() => {
                handleClick("cancel");
              }}
            >
              Cancel
            </Button>
          </Grid>
        </div>
      </div>
    </>
  );
}
