import { Edit, FilterAlt, Search, SearchOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { color } from "../../../Config/theme";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx/xlsx.mjs";
import useStyles from "./styles";
import Images from "../../../Config/Images";
import { ResetIcon } from "../../Icon/TableToggle";
import { isTablet } from "react-device-detect";

export default function TabVehicleMasterView(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    handleSearch = () => null,
    search = "",
    pageNo = 1,
    record = 10,
    filter = "",
  } = props;
  const { token, dropDownData, userdata } = useSelector((state) => state.auth);
  const styles = useStyles();

  // const [filter, setFilter] = useState("");
  // const [searchFilter, setSearchFilter] = useState("");
  const [reportLoader, setReportLoader] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  // const [record, setRecord] = useState(10);
  // const [pageNo, setPageNo] = useState(1);
  const [vmasterList, setVmasterList] = useState({});

  const [brandList, setbrandList] = useState(false);
  const [typeList, settypeList] = useState(false);

  useEffect(() => {
    getVehileMasterList(false);
  }, [filter]);

  useEffect(() => {
    getVehileMasterList(false);
  }, [pageNo, record]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);

  const vMasterListColumn = [
    {
      field: "Sr. No. ",
      headerName: "Sr. No.",
      width: 70,
      headerAlign: "Center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      sortable: false,
      headerAlign: "center",
      align: "center",
      width: 70,
      renderCell: (params) => {
        return (
          <Tooltip title="Edit" placement="right" arrow>
            <Edit
              style={{ color: color.primary, cursor: "pointer" }}
              onClick={() => handleClick("editVehicle", params?.row)}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "vehicle_brand",
      headerName: "Vehicle Brand",
      width: 220,
      renderCell: (params) => {
        let arr = params?.row?.vehicle_brand;
        let res =
          !isNull(arr) &&
          dropDownData?.vehicle_brand?.find(
            (item) => arr.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "vehicle_model",
      headerName: "Vehicle Model",
      width: 220,
    },
    {
      field: "no_of_rows",
      headerName: "No. of Axle",
      width: 140,
      renderCell: (params) => {
        return <Typography>{params?.row?.no_of_rows || "-"}</Typography>;
      },
    },
    {
      field: "no_of_rows_horse",
      headerName: "No. of Axle in Horse",
      width: 190,
      renderCell: (params) => {
        return <Typography>{params?.row?.no_of_rows_horse || "-"}</Typography>;
      },
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Tyres",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.no_of_tyres || "-"}</Typography>;
      },
    },
    {
      field: "no_of_tyres_horse",
      headerName: "No. of Tyres in Horse",
      width: 200,
      renderCell: (params) => {
        return <Typography>{params?.row?.no_of_tyres_horse || "-"}</Typography>;
      },
    },
    {
      field: "load_capicity",
      headerName: "Load Capacity",
      width: 150,
      renderCell: (params) => {
        return <Typography>{params?.row?.load_capicity || "-"}</Typography>;
      },
    },
  ];

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Vehicle Master`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getVehileMasterList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.vehiclemasterlist
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : search
        }&filter=${filter}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        if (isObject(response?.data) && !isEmpty(response?.data)) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadVehileMasterList(response?.data?.rows);
          } else {
            let listObj = {};
            listObj.pagination = response?.data?.pagination;
            listObj.data = response?.data?.rows;
            setVmasterList(listObj);
          }
        } else {
          toast.error(response?.message);
        }
        v && auditSave("Vehicle Master List");
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      setReportLoader(false);
      toast.error(error?.toString());
      console.log("ERROR)=====>>>>>", error);
    }
  }

  function downloadVehileMasterList(data) {
    const newData = data.map((item, index) => {
      const arr = item?.vehicle_brand;
      const brand = dropDownData?.vehicle_brand?.find(
        (item1) => arr.toString() === item1?.id.toString()
      );

      const newObj = {
        "Sr. No.": index + 1,
        "Vehicle Brand": brand?.label,
        "Vehicle Model": item?.vehicle_model,
        // "Vehicle Type": type?.label,
        "No. of Axle": Number(item?.no_of_rows) || 0,
        "No. of Axle in Horse": Number(item?.no_of_rows_horse) || 0,
        "No. of Tyre": Number(item?.no_of_tyres) || 0,
        "No. of Tyre in Horse": Number(item?.no_of_tyres_horse) || 0,
        "Load Capacity": Number(item?.load_capicity) || 0,
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Vehicle Master List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Vehicle Master List.xlsx");
  }

  const dataGrid = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={vmasterList?.data}
        columns={vMasterListColumn}
        pageSize={record}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        getRowHeight={() => "auto"}
        showColumnRightBorder
        disableSelectionOnClick
      />
    );
  }, [vmasterList]);

  function resetFilters() {
    handleFilter("");
    handleSearch("");
    getVehileMasterList(true);
    handlePageNo(1);
  }

  const getVehicleBrand = (params) => {
    let arr = params?.vehicle_brand;
    let res =
      !isNull(arr) &&
      dropDownData?.vehicle_brand?.find(
        (item) => arr.toString() === item?.id?.toString()
      );
    return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
  };

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Grid
            id="jump_to_me"
            item
            style={{ height: 40 }}
            display={"flex"}
            justifyContent="space-between"
            alignItems={"center"}
          >
            <Typography variant="tableTitle">Vehicle Master</Typography>
            <Grid display={"flex"} gap={1.2}>
              <Button
                variant="contained"
                onClick={() => handleClick("addVehicle")}
              >
                Add Vehicle
              </Button>

              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => getVehileMasterList(true, 1)}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            container
            display={"flex"}
            justifyContent="space-between"
            gap={1}
            wrap="nowrap"
            style={{ marginTop: 14 }}
          >
            {/* filter */}

            <Select
              displayEmpty
              placeholder="Filter"
              IconComponent={FilterAlt}
              value={filter}
              onChange={(e) => {
                handleFilter(e.target.value);
                handlePageNo(1);
              }}
              onOpen={() => {
                if (isEmpty(filter.toString())) {
                  setbrandList(false);
                  settypeList(false);
                }
              }}
              style={filter === "" ? { color: "#A2A2A2" } : {}}
              classes={{
                iconOpen: styles.iconOpen,
              }}
            >
              <MenuItem value={""} hidden disabled selected>
                Filter
              </MenuItem>
              <ListItemButton
                onClick={() => {
                  setbrandList(!brandList);
                  settypeList(false);
                }}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Typography>Vehicle Brand</Typography>
                {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              {dropDownData?.vehicle_brand.map((item, index) => {
                return (
                  <MenuItem
                    key={index}
                    value={item?.id}
                    style={{ display: brandList ? "flex" : "none" }}
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
            </Select>

            {/* record */}
            <Select
              value={record}
              displayEmpty
              onChange={(e) => {
                handleRecord(e.target.value);
                handlePageNo(1);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>

          <Grid
            item
            display={"flex"}
            alignItems="center"
            wrap="nowrap"
            gap={1}
            marginTop={"14px"}
            style={{ marginBottom: 14 }}
          >
            <TextField
              placeholder="Search"
              value={search}
              onChange={(e) => {
                isEmpty(e.target.value) && getVehileMasterList(true);
                handleSearch(e.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter" && !isEmpty(search)) {
                  getVehileMasterList(false);
                  handlePageNo(1);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Vehicle Model"
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                if (!isEmpty(search)) {
                  getVehileMasterList(false);
                  handlePageNo(1);
                }
              }}
            >
              <SearchOutlined />
            </Button>
            <Button
              variant="contained"
              disabled={isEmpty(search) && filter === ""}
              onClick={resetFilters}
            >
              <ResetIcon />
            </Button>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(vmasterList?.data) && !isEmpty(vmasterList?.data) ? (
            <>
              {dataGrid}

              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(vmasterList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => handlePageNo(e)}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <Grid
            id="jump_to_me"
            item
            container
            display="flex"
            alignItems="center"
            backgroundColor={color.secondary}
            padding={"10px"}
            color={color.white}
            borderRadius={1}
            minHeight="60px"
            justifyContent="space-between"
            gap={1}
            wrap="nowrap"
          >
            <Grid item>
              <Typography variant="tableTitle">Vehicle Master</Typography>
            </Grid>
            <Button
              variant="contained"
              onClick={() => handleClick("addVehicle")}
            >
              Add Vehicle
            </Button>
          </Grid>

          <Grid
            item
            container
            display={"flex"}
            justifyContent="space-between"
            gap={1}
            wrap="nowrap"
            style={{ marginTop: 14 }}
          >
            <Grid display={"flex"} alignItems="center" gap={1}>
              {/* filter */}
              <Select
                fullWidth
                displayEmpty
                placeholder="Filter"
                IconComponent={FilterAlt}
                value={filter}
                onChange={(e) => {
                  handleFilter(e.target.value);
                  handlePageNo(1);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    setbrandList(false);
                    settypeList(false);
                  }
                }}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
                classes={{
                  iconOpen: styles.iconOpen,
                }}
              >
                <MenuItem value={""} hidden disabled selected>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    setbrandList(!brandList);
                    settypeList(false);
                  }}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Typography>Vehicle Brand</Typography>
                  {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData?.vehicle_brand.map((item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      value={item?.id}
                      style={{ display: brandList ? "flex" : "none" }}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>

              {/* record */}
              <Select
                fullWidth
                value={record}
                displayEmpty
                onChange={(e) => {
                  handleRecord(e.target.value);
                  handlePageNo(1);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => getVehileMasterList(true, 1)}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid
            item
            display={"flex"}
            alignItems="center"
            wrap="nowrap"
            gap={1}
            marginTop={"14px"}
          >
            <TextField
              placeholder="Search"
              fullWidth
              value={search}
              onChange={(e) => {
                isEmpty(e.target.value) && getVehileMasterList(true);
                handleSearch(e.target.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter" && !isEmpty(search)) {
                  getVehileMasterList(false);
                  handlePageNo(1);
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Vehicle Model"
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                if (!isEmpty(search)) {
                  getVehileMasterList(false);
                  handlePageNo(1);
                }
              }}
            >
              <SearchOutlined />
            </Button>
            <Button
              variant="contained"
              disabled={isEmpty(search) && filter === ""}
              onClick={resetFilters}
            >
              <ResetIcon />
            </Button>
          </Grid>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(vmasterList?.data) && !isEmpty(vmasterList?.data) ? (
            <>
              {vmasterList?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      boxShadow: color.shadow,
                      overflow: "hidden",
                      marginTop: 20,
                    }}
                  >
                    <Table className={styles.CustomtableMobile}>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Vehicle Brand</TableCell>
                          <TableCell variant="head">Vehicle Model</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {getVehicleBrand(item) || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {item?.vehicle_model || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">No. of Axle</TableCell>
                          <TableCell variant="head">
                            No. of Axle in Horsel
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {item?.no_of_rows || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {item?.no_of_rows_horse || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">No. of Tyre</TableCell>
                          <TableCell variant="head">
                            No. of Tyre in Horse
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {item?.no_of_tyres || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {item?.no_of_tyres_horse || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                            rowSpan={2}
                          >
                            <Button
                              variant="contained"
                              onClick={() => {
                                handleClick(
                                  "addFleet",
                                  handleClick("editVehicle", item)
                                );
                              }}
                            >
                              <Edit />
                            </Button>
                          </TableCell>
                          <TableCell variant="head">Load Capacity</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {item?.load_capicity || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </Grid>
                );
              })}

              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(vmasterList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => handlePageNo(e)}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}
