import {
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { Box } from "@mui/system";
import { cloneDeep, isArray, isEmpty } from "lodash";
import React, { useState, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm";
import useStyles from "./styles";
import { toast } from "react-toastify";
import { useTheme } from "@emotion/react";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";

const errorObj = {
  vModelErr: false,
  vModelMsg: "",
  vBrandErr: false,
  vBrandMsg: "",
  vTypeEr: false,
  vTypeMsg: "",
  capacityErr: false,
  capacityMsg: "",
  axleErr: false,
  axleMsg: "",
  tyreErr: false,
  tyreMsg: "",
  hourseErr: false,
  hourseMsg: "",
};

export default function AddVehicleMaster(props) {
  const { handleClick = () => null, editData = {} } = props;
  const styles = useStyles();
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const { token, dropDownData, isOnline } = useSelector((state) => state.auth);

  const isEdit = !isEmpty(editData);
  const dummyArr = cloneDeep(dropDownData?.vehicle_type);
  const [vTypeArr, setVTypeArry] = useState(dummyArr);
  const [openCard, setOpenCard] = useState(false);
  const [hourseT, setHourseT] = useState("");

  const [pageLoad, setPageLoad] = useState(isEdit ? true : false);
  const [loader, setLoader] = useState(false);
  const [vehicleModel, setVehicleModel] = useState("");
  const [vehicleBrand, setVehicleBrand] = useState("");
  const [vehicleType, setVehicleType] = useState({});
  const [loadCapacity, setLoadCapacity] = useState("");
  const [tyres, setTyres] = useState("");
  const [axle, setAxle] = useState("");
  const [errObj, setErrObj] = useState(errorObj);

  useEffect(() => {
    if (isEdit) {
      // const vType = dropDownData?.vehicle_type?.find(
      //   (v) => v?.id === editData?.vehicle_type
      // );

      const vTypeId = dropDownData?.vehicle_type.findIndex(
        (v) => v.id === editData?.vehicle_type
      );

      selectType(vTypeId);
      setVehicleModel(editData?.vehicle_model || "");
      // setVehicleType(vType || "");
      setVehicleBrand(editData?.vehicle_brand || "");
      setLoadCapacity(editData?.load_capicity || "");
      setHourseT(editData?.is_horse_trailer?.toString());
      setTyres(
        (editData?.is_horse_trailer === 1
          ? editData?.no_of_tyres_horse
          : editData?.no_of_tyres) || ""
      );
      setAxle(
        (editData?.is_horse_trailer === 1
          ? editData?.no_of_rows_horse
          : editData?.no_of_rows) || ""
      );
    }
    setPageLoad(false);
  }, []);

  const checkValidation = () => {
    let error = { ...errorObj };
    let valid = true;

    if (isEmpty(vehicleModel)) {
      valid = false;
      error.vModelErr = true;
      error.vModelMsg = "Please Select Vehicle Model";
    }

    if (isEmpty(vehicleBrand.toString())) {
      valid = false;
      error.vBrandErr = true;
      error.vBrandMsg = "Please Select Vehicle Brand";
    }

    if (isEmpty(hourseT)) {
      valid = false;
      error.hourseErr = true;
      error.hourseMsg = "Please Select Horse Transfer";
    }

    // if (isEmpty(vehicleType)) {
    //   valid = false;
    //   error.vTypeEr = true;
    //   error.vTypeMsg = "Please Select Vehicle Type";
    // }

    if (isEmpty(loadCapacity)) {
      valid = false;
      error.capacityErr = true;
      error.capacityMsg = "Please Enter Load Capacity";
    }

    if (isEmpty(axle.toString())) {
      valid = false;
      error.axleErr = true;
      error.axleMsg = "Please Enter No. of Axle.";
    } else if (hourseT === "1" && axle > 3) {
      valid = false;
      error.axleErr = true;
      error.axleMsg = "Maximum No. of Axle count allowed is 3.";
    } else if (hourseT === "0" && axle > 10) {
      valid = false;
      error.axleErr = true;
      error.axleMsg = "Maximum No. of Axle count allowed is 10.";
    }

    if (isEmpty(tyres.toString())) {
      valid = false;
      error.tyreErr = true;
      error.tyreMsg = "Please Enter the No. of Tyres";
    }

    setErrObj(error);
    if (valid) {
      submit();
    }
  };

  async function submit() {
    setLoader(true);

    const params = {
      "VehicleMaster[vehicle_model]": vehicleModel,
      "VehicleMaster[vehicle_brand]": vehicleBrand,
      // "VehicleMaster[vehicle_type]": vehicleType?.id,
      "VehicleMaster[load_capicity]": loadCapacity,
      "VehicleMaster[is_horse_trailer]": hourseT,
    };
    if (hourseT === "1") {
      params["VehicleMaster[no_of_tyres_horse]"] = tyres;
      params["VehicleMaster[no_of_rows_horse]"] = axle;
    } else {
      params["VehicleMaster[no_of_tyres]"] = tyres;
      params["VehicleMaster[no_of_rows]"] = axle;
    }
    if (isEdit) {
      params["VehicleMaster[id]"] = editData?.id;
    }

    try {
      const response = await getAPIProgressData(
        `${Setting.endpoints.addvehiclemaster}`,
        "POST",
        params,
        true
      );
      if (response?.status) {
        toast.success(response?.message);
        handleClick("cancle");
        clearData();
      } else {
        toast.error(response?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("error=====>>>>>", error);
      toast.error(error.toString());
      setLoader(false);
    }
  }

  // async function checkHorseTrailer() {
  //   try {
  //     const response = await getApiData(
  //       `${Setting.endpoints.checkhorsetrailer}?vehicle_type_id=${vehicleType?.id}`,
  //       "GET",
  //       {},
  //       { Authorization: `Bearer ${token}` }
  //     );
  //     if (response?.status) {
  //       setHorseTrailer(response?.status);
  //     } else setHorseTrailer(response?.status);
  //   } catch (error) {
  //     console.log("ERROR=====>>>>>", error);
  //     toast.error(error);
  //   }
  // }

  function selectType(index) {
    setVTypeArry(dummyArr);
    const arr = [...dummyArr];

    arr.map((item, ind) => {
      if (ind === index) {
        item.selected = true;
      }
    });
    setVTypeArry(arr);
  }

  function handleSelect() {
    const arr = [...vTypeArr];
    const selected = arr?.find((item) => item?.selected === true);
    if (!isEmpty(selected)) {
      setVehicleType(selected);
    }
    setErrObj({ ...errObj, vTypeEr: false, vTypeMsg: "" });
    setOpenCard(false);
  }

  function clearData() {
    setVehicleBrand("");
    setVehicleModel("");
    // setVehicleType({});
    setLoadCapacity("");
    setTyres("");
    setAxle("");
  }

  return (
    <Box>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : (
        <>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: "18px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography fontWeight={"bold"}>
              {isEdit ? "Edit Vehicle" : "Add Vehicle"}
            </Typography>
            {/* {isEdit ? (
              <Button style={{ backgroundColor: color.secondary }}>
                Delete
              </Button>
            ) : null} */}
          </Box>

          <Box className={styles.container}>
            <Grid
              container
              alignItems="center"
              id="vBrand"
              marginBottom={errObj.vBrandErr ? 4 : 3}
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title="Vehicle Brand" required />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9}>
                <FormControl fullWidth error={errObj.vBrandErr}>
                  <Select
                    fullWidth
                    displayEmpty
                    value={vehicleBrand}
                    style={vehicleBrand === "" ? { color: "#A2A2A2" } : {}}
                    onChange={(e) => {
                      setVehicleBrand(e.target.value);
                      setErrObj({ ...errObj, vBrandErr: false, vBrandMsg: "" });
                    }}
                  >
                    <MenuItem value={""} selected hidden disabled>
                      Select the Brand of Vehicle
                    </MenuItem>
                    {dropDownData?.vehicle_brand.map((item, index) => {
                      return (
                        <MenuItem key={index} value={item?.id}>
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {errObj.vBrandErr ? (
                    <FormHelperText>{errObj.vBrandMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems="center"
              id="vModel"
              marginBottom={errObj.vModelErr ? 4 : 3}
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title="Vehicle Model" required />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9}>
                <FormControl fullWidth error={errObj?.vModelErr}>
                  <OutlinedInput
                    value={vehicleModel}
                    placeholder="Select the Vehicle Model"
                    onChange={(e) => {
                      setVehicleModel(e.target.value);
                      setErrObj({ ...errObj, vModelErr: false, vModelMsg: "" });
                    }}
                  />
                  {errObj.vModelErr ? (
                    <FormHelperText>{errObj.vModelMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            {/* <Grid
              container
              alignItems="center"
              id="vType"
              marginBottom={errObj.vTypeEr ? 4 : 3}
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title="Vehicle Type" required />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9}>
                <FormControl fullWidth error={errObj?.vTypeEr}>
                  <OutlinedInput
                    value={vehicleType?.label}
                    placeholder="Select the Vehicle Type"
                    inputProps={{
                      readOnly: true,
                      style: { cursor: "pointer" },
                    }}
                    onClick={(e) => {
                      if (isArray(vTypeArr) && !isEmpty(vTypeArr)) {
                        setOpenCard(true);
                      } else {
                        toast.warning(
                          "Please Add Vehicle Type First from General DropDowns"
                        );
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                  />
                  {errObj.vTypeEr ? (
                    <FormHelperText>{errObj.vTypeMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid> */}

            <Grid
              container
              alignItems="center"
              id="lCapacity"
              marginBottom={errObj.capacityErr ? 4 : 3}
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography title="Load Capacity" required />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9}>
                <FormControl fullWidth error={errObj.capacityErr}>
                  <OutlinedInput
                    value={loadCapacity}
                    placeholder="Enter the Load Capacity in Tonnes"
                    onChange={(e) => {
                      setLoadCapacity(e.target.value);
                      setErrObj({
                        ...errObj,
                        capacityErr: false,
                        capacityMsg: "",
                      });
                    }}
                    endAdornment={
                      isEdit || !isEmpty(loadCapacity) ? null : (
                        <InputAdornment position="end">Tonnes</InputAdornment>
                      )
                    }
                  />
                  {errObj.capacityErr ? (
                    <FormHelperText>{errObj.capacityMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.hourseErr ? 30 : 20 }}
            >
              <Grid item xs={12} sm={12} md={3} lg={2.1}>
                <CTypography required title={`Horse Trailer`} />
              </Grid>
              <Grid item xs={12} md={9} lg={9.9}>
                <FormControl fullWidth error={errObj.hourseErr}>
                  <Select
                    fullWidth
                    displayEmpty
                    value={hourseT}
                    onChange={(e) => {
                      setHourseT(e.target.value);
                      setErrObj({
                        ...errObj,
                        hourseErr: false,
                        hourseMsg: "",
                      });
                      setTyres("");
                      setAxle("");
                    }}
                    size={"medium"}
                    style={hourseT === "" ? { color: "#A2A2A2" } : {}}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Select Horse Transfer
                    </MenuItem>
                    <MenuItem value={"1"}>Yes</MenuItem>
                    <MenuItem value={"0"}>No</MenuItem>
                  </Select>
                  {errObj?.hourseErr ? (
                    <FormHelperText>{errObj?.hourseMsg}</FormHelperText>
                  ) : null}
                </FormControl>
              </Grid>
            </Grid>

            {!isEmpty(hourseT) && (
              <Grid
                container
                alignItems="center"
                id="vType"
                marginBottom={errObj.axleErr ? 4 : 3}
              >
                <Grid item xs={12} sm={12} md={3} lg={2.1}>
                  <CTypography
                    title={
                      hourseT === "1"
                        ? "Total No. of Axle in Horse"
                        : "Total No. of Axle"
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={9.9}>
                  <FormControl fullWidth error={errObj.axleErr}>
                    <OutlinedInput
                      placeholder={
                        hourseT === "1"
                          ? "Enter No. of Axle in Horse "
                          : "Enter the No. of Axle"
                      }
                      value={axle}
                      onChange={(e) => {
                        setAxle(e.target.value.replace(/[^0-9]/g, ""));
                        setErrObj({ ...errObj, axleErr: false, axleMsg: "" });
                      }}
                    />
                    {errObj.axleErr ? (
                      <FormHelperText>{errObj.axleMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {!isEmpty(hourseT) && (
              <Grid
                container
                alignItems="center"
                id="vType"
                marginBottom={errObj.tyreErr ? 4 : 3}
              >
                <Grid item xs={12} sm={12} md={3} lg={2.1}>
                  <CTypography
                    title={
                      hourseT === "1"
                        ? "Total No. of Tyres in Horse"
                        : "Total No. of Tyres"
                    }
                    required
                  />
                </Grid>
                <Grid item xs={12} md={9} lg={9.9}>
                  <FormControl fullWidth error={errObj.tyreErr}>
                    <OutlinedInput
                      placeholder={
                        hourseT === "1"
                          ? "Enter the No. Tyres in Horse"
                          : "Enter the No. of Tyres "
                      }
                      value={tyres}
                      inputProps={{ maxLength: 2 }}
                      onChange={(e) => {
                        setTyres(e.target.value.replace(/[^0-9]/g, ""));
                        setErrObj({ ...errObj, tyreErr: false, tyreMsg: "" });
                      }}
                    />
                    {errObj.tyreErr ? (
                      <FormHelperText>{errObj.tyreMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid container justifyContent={"center"} marginTop={5}>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={checkValidation}
                  style={{
                    backgroundColor: isOnline
                      ? color.primary
                      : color.bordercolor,
                    marginRight: 10,
                    width: isMobile ? 120 : 130,
                  }}
                >
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : isEdit ? (
                    `Save & Update`
                  ) : (
                    `Add Vehicle`
                  )}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                    width: isMobile ? 120 : 130,
                  }}
                  onClick={() => handleClick("cancle")}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}

      <Modal
        open={openCard}
        onClose={() => setOpenCard(false)}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: "60vw",
            maxHeight: "65vh",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={isMobile ? 1 : md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: "50vh" }}
          >
            {vTypeArr.map((item, index) => {
              return (
                <ImageListItem
                  key={index}
                  style={{
                    width: isMobile ? "60vw" : 200,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      selectType(index);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={handleSelect}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
    </Box>
  );
}
