import React, { useEffect, useMemo, useState } from "react";
import { Edit, Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { isArray, isEmpty, isObject } from "lodash";
import InfoIcon from "@mui/icons-material/Info";
import theme, { color } from "../../../Config/theme";
import useStyles from "./Styles";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import * as XLSX from "xlsx/xlsx.mjs";

export default function TrailerMasterVeiw(props) {
  const { handleClick = () => null } = props;
  const { token, dropDownData, userdata } = useSelector((state) => state.auth);
  const styles = useStyles();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const [searchFilter, setSearchFilter] = useState("");
  const [reportLoader, setReportLoader] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [record, setRecord] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [vmasterList, setVmasterList] = useState({});

  useEffect(() => {
    getTrailerMasterList(true);
  }, [searchFilter]);

  useEffect(() => {
    getTrailerMasterList(false);
    setPageLoad(true);
  }, [record, pageNo]);

  const tMasterListColumn = [
    {
      field: "Sr. No. ",
      headerName: "Sr. No.",
      width: 70,
      headerAlign: "Center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   sortable: false,
    //   headerAlign: "center",
    //   align: "center",
    //   width: 70,
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip title="Edit" placement="right" arrow>
    //         <Edit
    //           style={{ color: color.primary, cursor: "pointer" }}
    //           onClick={() => handleClick("editTrailer", params?.row)}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "trailer_no",
      headerName: "Trailer No.",
      flex: 1,
      width: lg ? "unset" : 220,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 200,
      renderCell: (params) => {
        return <Typography>{params?.row?.vehicle_no || "-"}</Typography>;
      },
    },
  ];

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Vehicle Master`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getTrailerMasterList(bool, v) {
    !v && setPageLoad(false);
    v && setReportLoader(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.trailerlist
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        if (isObject(response?.data) && !isEmpty(response?.data)) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadVehileMasterList(response?.data?.rows);
          } else {
            let listObj = {};
            listObj.pagination = response?.data?.pagination;
            listObj.data = response?.data?.rows;
            setVmasterList(listObj);
          }
        } else {
          toast.error(response?.message);
        }
        v && auditSave("Vehicle Master List");
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      setReportLoader(false);
      toast.error(error?.toSyring());
      console.log("ERROR)=====>>>>>", error);
    }
  }

  function downloadVehileMasterList(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        "Trailer No.": item?.trailer_no,
        "Vehicle No.": item?.vehicle_no,
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Trailer Master List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Trailer Master List.xlsx");
  }

  const dataGrid = useMemo(() => {
    return (
      <DataGrid
        autoHeight={true}
        rows={vmasterList?.data}
        columns={tMasterListColumn}
        pageSize={record}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        getRowHeight={() => "auto"}
        showColumnRightBorder
        disableSelectionOnClick
      />
    );
  }, [vmasterList]);

  function resetFilters() {
    setSearchFilter("");
  }

  return (
    <div className={styles.container}>
      <Grid
        container
        display="flex"
        alignItems={"center"}
        justifyContent="space-between"
        gap={1.2}
        wrap="nowrap"
      >
        <Grid item>
          <Typography variant="tableTitle">Trailer Master</Typography>
        </Grid>
        <Grid item display="flex" gap={1.2}>
          {userdata?.role !== "service_enginner" && (
            <Button
              variant="contained"
              onClick={() => handleClick("addTrailer")}
            >
              Add Trailer
            </Button>
          )}
          <Button
            variant="contained"
            style={{ backgroundColor: color.secondary }}
            onClick={() => getTrailerMasterList(true, 1)}
          >
            {reportLoader ? (
              <CircularProgress style={{ color: color.white }} size={24} />
            ) : (
              "Download"
            )}
          </Button>
        </Grid>
      </Grid>

      <Grid container gap={1.3} marginTop={"15px"} alignItems="center">
        {/* <Grid item lg={2}>
          <Select
            fullWidth
            displayEmpty
            placeholder="Filter"
            IconComponent={FilterAlt}
            value={filter}
            onChange={(e) => {
              setFilter(e.target.value);
            }}
            onOpen={() => {
              if (isEmpty(filter.toString())) {
                setbrandList(false);
                settypeList(false);
              }
            }}
            style={filter === "" ? { color: "#A2A2A2" } : {}}
            classes={{
              iconOpen: styles.iconOpen,
            }}
          >
            <MenuItem value={""} hidden disabled selected>
              Filter
            </MenuItem>
            <ListItemButton
              onClick={() => {
                setbrandList(!brandList);
                settypeList(false);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Vehicle Brand</Typography>
              {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            {dropDownData?.vehicle_brand.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  value={item?.id}
                  style={{ display: brandList ? "flex" : "none" }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}

            <ListItemButton
              onClick={() => {
                setbrandList(false);
                settypeList(!typeList);
              }}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <Typography>Vehicle Type</Typography>
              {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </ListItemButton>
            {dropDownData?.vehicle_type.map((item, index) => {
              return (
                <MenuItem
                  key={index}
                  value={item?.id}
                  style={{ display: typeList ? "flex" : "none" }}
                >
                  {item?.label}
                </MenuItem>
              );
            })}
          </Select>
        </Grid> */}
        <Grid item lg={2.4}>
          <TextField
            placeholder="Search"
            fullWidth
            value={searchFilter}
            onChange={(e) => {
              setSearchFilter(e.target.value);
            }}
            onKeyPress={(ev) => {
              if (ev.key === "Enter" && !isEmpty(searchFilter)) {
                getTrailerMasterList(false);
                setPageNo(1);
              }
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <div style={{ lineHeight: 0 }}>
                    <Search style={{ fontSize: 20 }} />
                  </div>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <div style={{ lineHeight: 0, cursor: "pointer" }}>
                    <Tooltip
                      title="Search by Trailer No."
                      placement="bottom"
                      arrow
                    >
                      <InfoIcon style={{ fontSize: 20 }} />
                    </Tooltip>
                  </div>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            style={{ backgroundColor: color.secondary }}
            onClick={() => {
              if (!isEmpty(searchFilter)) {
                getTrailerMasterList(false);
                setPageNo(1);
              }
            }}
          >
            Search
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            disabled={isEmpty(searchFilter)}
            onClick={resetFilters}
          >
            Reset
          </Button>
        </Grid>
        <Grid
          item
          lg={1.5}
          style={{
            display: "flex",
            alignItems: "center",
            marginLeft: "auto",
          }}
        >
          <Typography style={{ fontSize: 14 }}>Records:</Typography>
          <Select
            fullWidth
            value={record}
            displayEmpty
            onChange={(e) => {
              setRecord(e.target.value);
              setPageNo(1);
            }}
            style={{ marginLeft: 10 }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>

      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(vmasterList?.data) && !isEmpty(vmasterList?.data) ? (
        <>
          <div style={{ marginTop: 20 }}>{dataGrid}</div>
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(vmasterList?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={2}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => setPageNo(e)}
            />
          </div>
        </>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
    </div>
  );
}
