import React, { useEffect, useMemo, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { useSelector } from "react-redux";
import { color } from "../../../Config/theme.js";
import { isMobile, isTablet } from "react-device-detect";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import Images from "../../../Config/Images.js";
import { isArray, isEmpty, isObject } from "lodash";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { toast } from "react-toastify";
import { getApiData } from "../../../Utils/APIHelper.js";
import {
  ArrowBackOutlined,
  Delete,
  Search,
  SearchOutlined,
  Visibility,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import TableToggle, {
  ResetIcon,
} from "../../../Components/Icon/TableToggle/index.js";
import TabAuditDetails from "../../../Components/TabAuditLog/TabAuditDetails/index.js";
import { DataGrid } from "@mui/x-data-grid";

export default function TabAuditLog() {
  const isKeyboardOpen = useDetectKeyboardOpen();
  const styles = useStyles();
  const { isOnline } = useSelector((state) => state.auth);
  const { token } = useSelector((state) => state.auth);
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [pageLoad, setPageLoad] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [auditData, setInventoryData] = useState({});
  const [reportLoader, setReportLoader] = useState(false);
  const [changeTab, setChangeTab] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    document.title = Setting.page_name.AuditLog;
  }, []);
  useEffect(() => {
    getAuditList();
  }, []);

  useEffect(() => {
    isEmpty(searchFilter) && getAuditList(false);
  }, [searchFilter]);

  useEffect(() => {
    setPageLoad(true);
    getAuditList(false);
  }, [pageNo, record]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [changeTab]);

  function resetFilterData() {
    setSearchFilter("");
    getAuditList(true);
  }

  async function getAuditList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    let endpoints = `${
      Setting.endpoints.auditList
    }?page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadInvetoryData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setInventoryData(listObj);
          }
        }
      } else {
        toast.error(response?.message, { toastId: 17 });
      }
      setReportLoader(false);
      setPageLoad(false);
    } catch (error) {
      setReportLoader(false);
      setPageLoad(false);
      toast.error(error.toString(), { toastId: 17 });
      console.log("error ===>>>", error);
    }
    setReportLoader(false);
    setPageLoad(false);
  }

  function downloadInvetoryData(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        Name: item?.name,
        "Audit Date": moment(item?.capture_date).format("DD-MM-yyyy") || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Audit List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Audit List.xlsx");
  }

  const getDate = (item) => {
    const Date = item?.audit_date;
    const displayDate = moment(Date).format("DD-MM-yyyy");
    return <Typography>{displayDate || "-"}</Typography>;
  };

  const inventoryColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: "unset",
      flex: 1,
    },
    {
      field: "audit_date",
      headerName: "Audit Date",
      width: 300,
      renderCell: (params) => {
        const Date = params?.row?.audit_date;
        const displayDate = moment(Date).format("DD-MM-yyyy");
        return <Typography>{displayDate || "-"}</Typography>;
      },
    },
  ];
  const dataGridRender = useMemo(() => {
    return (
      <DataGrid
        rows={auditData?.data}
        columns={inventoryColumn}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
        autoHeight={true}
        getRowHeight={() => "auto"}
        onCellClick={(params) => {
          setChangeTab("auditDetails");
          setData(params?.row);
        }}
      />
    );
  }, [auditData]);
  return (
    <div className={styles.card}>
      {isTablet
        ? null
        : !isKeyboardOpen &&
          changeTab === "" && (
            <Box
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                height: isMobile ? 30 : 40,
                position: "fixed",
                top: 14,
                left: 20,
                zIndex: 10,
              }}
            >
              <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
            </Box>
          )}

      {changeTab === "auditDetails" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              variant="contained"
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}

      {isTablet ? (
        <div className={styles.container}>
          {changeTab === "auditDetails" ? (
            <div id="jump_to_me">
              <TabAuditDetails auditData={data} />
            </div>
          ) : (
            <>
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <Grid id="jump_to_me" item>
                  <Typography variant="tableTitle">Audit Log</Typography>
                </Grid>
                <Grid>
                  <Grid item>
                    <Button
                      style={{
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                        minWidth: "unset",
                      }}
                      disabled={isOnline ? false : true}
                      onClick={() => getAuditList(false, 1)}
                    >
                      {reportLoader ? (
                        <CircularProgress
                          style={{ color: color.white }}
                          size={24}
                        />
                      ) : (
                        <img src={Images.Mdownload} alt="download" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginTop: 14 }}
              >
                <Grid display="flex" alignItems="center" gap={1}>
                  <TextField
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getAuditList(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        if (!isEmpty(searchFilter)) {
                          setPageNo(1);
                          getAuditList(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Name"
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter)) {
                        getAuditList(false);
                      }
                    }}
                  >
                    <SearchOutlined />
                  </Button>
                  <Button
                    variant="contained"
                    onClick={resetFilterData}
                    disabled={!searchFilter}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
                <Grid>
                  <Select
                    value={record}
                    onChange={(v) => {
                      setPageNo(1);
                      setRecord(v.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(auditData?.data) && !isEmpty(auditData?.data) ? (
                <div style={{ marginTop: 20 }}>
                  {dataGridRender}
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(auditData?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        setPageNo(e);
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          {changeTab === "auditDetails" ? (
            <div id="jump_to_me">
              <TabAuditDetails auditData={data} />
            </div>
          ) : (
            <>
              <Grid container wrap="nowrap">
                <Grid
                  id="jump_to_me"
                  item
                  container
                  display="flex"
                  alignItems="center"
                  backgroundColor={color.secondary}
                  padding={"10px"}
                  color={color.white}
                  borderRadius={1}
                  minHeight="60px"
                >
                  <Grid item>
                    <Typography variant="tableTitle">Audit Log</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                style={{ margin: "10px 0px" }}
                alignItems={"center"}
                wrap="nowrap"
                columnGap={1}
                justifyContent={"space-between"}
              >
                <Grid item container xs={9} gap={1} wrap="nowrap">
                  <Select
                    value={record}
                    onChange={(v) => {
                      setPageNo(1);
                      setRecord(v.target.value);
                    }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Grid>

                <Grid
                  item
                  container
                  wrap="nowrap"
                  gap={1}
                  justifyContent={"flex-end"}
                >
                  <Grid item>
                    <Button
                      style={{
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                        minWidth: "unset",
                      }}
                      disabled={isOnline ? false : true}
                      onClick={() => getAuditList(false, 1)}
                    >
                      {reportLoader ? (
                        <CircularProgress
                          style={{ color: color.white }}
                          size={24}
                        />
                      ) : (
                        <img src={Images.Mdownload} alt="download" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                container
                wrap="nowrap"
                marginTop={"10px"}
                gap={1}
                alignItems="center"
              >
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getAuditList(true);
                    setSearchFilter(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (!isEmpty(searchFilter)) {
                        setPageNo(1);
                        getAuditList(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Name"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (!isEmpty(searchFilter)) {
                      getAuditList(false);
                    }
                  }}
                >
                  <SearchOutlined />
                </Button>
                <Button
                  variant="contained"
                  onClick={resetFilterData}
                  disabled={!searchFilter}
                >
                  <ResetIcon />
                </Button>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(auditData?.data) && !isEmpty(auditData?.data) ? (
                <>
                  {auditData?.data?.map((item, index) => {
                    return (
                      <div style={{ position: "relative" }}>
                        <Grid
                          container
                          id={`jump_to_me${index}`}
                          style={{
                            border: `1px solid ${color.bordercolor}`,
                            boxShadow: color.shadow,
                            overflow: "hidden",
                            marginTop: 20,
                          }}
                          onClick={(params) => {
                            setChangeTab("auditDetails");
                            setData(item);
                          }}
                        >
                          <Table
                            className={
                              styles.InspectionHistory_CustomtableMobile
                            }
                          >
                            <TableHead>
                              <TableRow>
                                {/* <TableCell variant="head">Vehicle No.</TableCell> */}
                                <TableCell variant="head">Name</TableCell>
                                <TableCell variant="head">Audit Date</TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.name || "-"}
                                </TableCell>
                                <TableCell style={{ paddingBottom: 5 }}>
                                  {getDate(item)}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </Grid>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      display: "flex",
                      padding: 20,
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(auditData?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        setPageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </>
          )}
        </div>
      )}
    </div>
  );
}
