import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  useMediaQuery,
  useTheme,
  Button,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Pagination,
  Modal,
  Tooltip,
  ListItemButton,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Edit, FilterAlt, Search } from "@mui/icons-material";
import Images from "../../../Config/Images.js";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";
import moment from "moment";

function FUserManagement(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleSearch = () => null,
    search = "",
    fleetData,
    from,
    pageNo = 1,
    record = 10,
  } = props;
  const { token, userdata, dropDownData, isOnline } = useSelector(
    (state) => state.auth
  );

  const [userManagementList, setFleetListRows] = useState({});

  const [pageLoad, setPageLoad] = useState(true);
  const [filter, setFilter] = useState("");
  // const [searchFilter, setSearchFilter] = useState("");
  const [openCard, setOpenCard] = useState(false);
  const [value, setValue] = useState("");

  const [privilegesList, setPrivilegesList] = useState(false);
  const [RoList, setRoList] = useState(false);
  const [userStatusList, setUserStatusList] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);

  const privilegeList = [
    { label: "Admin", id: "admin" },
    { label: "FK RO", id: "fk_ro" },
    { label: "FK Tyre Care Executive", id: "service_enginner" },
    { label: "Fleet Owner", id: "fleet_owner" },
    { label: "Fleet Manager", id: "fleet_manager" },
    { label: "Call Center", id: "call_centre" },
  ];

  const userStatus = [
    { label: "Active", id: 10 },
    { label: "Inactive", id: 9 },
  ];

  const styles = useStyles();
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  // intialRender used for stop multiple time apiCall in
  const initialRender = useRef(true);

  const FuserManagementListColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const active =
          params?.row?.role === "fleet_manager" ||
          params?.row?.role === "fleet_owner";
        return (
          <Tooltip title={active ? "Edit" : ""} placement="right" arrow>
            <Edit
              style={{
                color: active ? color.primary : color.disable,
                cursor: "pointer",
              }}
              onClick={() => {
                if (active) {
                  handleClick("editUser", params?.row);
                } else {
                  return null;
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "user_name",
      headerName: "User ID",
      width: 140,
      renderCell: (params) => {
        return params?.row?.user_name || "-";
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 140,
      renderCell: (params) => {
        return params?.row?.name || "-";
      },
    },
    {
      field: "role",
      headerName: "Privilege",
      width: 215,
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              backgroundColor:
                params?.row?.role === "fleet_manager"
                  ? "#D9F8FF"
                  : params?.row?.role === "admin"
                  ? "#D9FFE4"
                  : params?.row?.role === "fleet_owner"
                  ? "#FFFDD9"
                  : params?.row?.role === "fk_ro"
                  ? "#FFD9D9"
                  : params?.row?.role === "call_centre"
                  ? "#d1dbff"
                  : "#F5D9FF",
              padding: 8,
              borderRadius: 6,
            }}
          >
            <Typography>
              {params?.row?.role === "fleet_manager"
                ? "Fleet Manager"
                : params?.row?.role === "admin"
                ? "Admin"
                : params?.row?.role === "fleet_owner"
                ? "Fleet Owner"
                : params?.row?.role === "fk_ro"
                ? "FK RO"
                : params?.row?.role === "call_centre"
                ? "Call Centre"
                : "FK Tyre Care Executive"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "sales_employee_code",
      headerName: "Employee Code",
      width: 180,
      renderCell: (params) => {
        return params?.row?.sales_employee_code || "-";
      },
    },
    {
      field: "employee_designation",
      headerName: "Employee Designation",
      width: 220,
      renderCell: (params) => {
        return params?.row?.employee_designation || "-";
      },
    },
    {
      field: "user_photo",
      headerName: "ID",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const value =
          params?.row?.role === "fleet_owner"
            ? params?.row?.business_pan_card
            : params?.row?.user_photo;
        if (!isEmpty(value)) {
          let Array = value.split(".");
          return Array[Array.length - 1] === "pdf" ? (
            <Typography
              style={{
                color: color.primary,
                cursor: "pointer",
              }}
              onClick={() => {
                const pdfWindow = window.open();
                pdfWindow.location.href = value;
              }}
            >
              View
            </Typography>
          ) : (
            <img
              style={{
                cursor: "pointer",
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: !userManagementList.user_photo
                  ? color.secondary
                  : color.white,
              }}
              src={value || Images.profile}
              alt=""
              onClick={() => {
                setValue(value);
                setOpenCard(true);
              }}
            />
          );
        } else {
          return (
            <img
              style={{
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: color.secondary,
              }}
              src={Images.profile}
              alt=""
            />
          );
        }
      },
    },
    {
      field: "email",
      headerName: "Email ID",
      width: 250,
      renderCell: (params) => {
        return params?.row?.email || "-";
      },
    },
    {
      field: "phone",
      headerName: "Contact No.",
      width: 140,
      renderCell: (params) => {
        return params?.row?.phone || "-";
      },
    },

    // {
    //   field: "password",
    //   sortable: false,
    //   headerName: "Password",
    //   width: 120,
    // },
    {
      field: "status",
      headerName: "User Status",
      width: 140,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.status === 10 ? "#D9FFE4" : "#FFD9D9",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>
              {params?.row?.status === 10 ? "Active" : "Inactive"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Added On",
      width: 180,
      renderCell: (params) => {
        const timestamp = params?.row?.created_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.created_at ? displayDate : "-"}
          </div>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Added By ",
      width: 180,
      renderCell: (params) => {
        return params?.row?.added_by || "-";
      },
    },
  ];

  const userManagementListColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const inActive =
          params?.row?.role === "admin" && userdata?.role === "admin";
        return (
          <Tooltip title={inActive ? "" : "Edit"} placement="right" arrow>
            <Edit
              style={{
                color: inActive ? color.disable : color.primary,
                cursor: "pointer",
              }}
              onClick={() => {
                if (inActive) {
                  return null;
                } else {
                  handleClick("editUser", params?.row);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "user_name",
      headerName: "User ID",
      width: 140,
    },
    {
      field: "name",
      headerName: "Name",
      width: 140,
    },
    {
      field: "email",
      headerName: "Email ID",
      width: 250,
      renderCell: (params) => {
        return params?.row?.email || "-";
      },
    },
    {
      field: "phone",
      headerName: "Contact No.",
      width: 140,
    },
    {
      field: "role",
      headerName: "Privilege",
      width: 215,
      renderCell: (params) => {
        return (
          <div
            style={{
              cursor: "pointer",
              display: "flex",
              backgroundColor:
                params?.row?.role === "fleet_manager"
                  ? "#D9F8FF"
                  : params?.row?.role === "admin"
                  ? "#D9FFE4"
                  : params?.row?.role === "fleet_owner"
                  ? "#FFFDD9"
                  : params?.row?.role === "fk_ro"
                  ? "#FFD9D9"
                  : params?.row?.role === "call_centre"
                  ? "#d1dbff"
                  : "#F5D9FF",
              padding: 8,
              borderRadius: 6,
            }}
          >
            <Typography>
              {params?.row?.role === "fleet_manager"
                ? "Fleet Manager"
                : params?.row?.role === "admin"
                ? "Admin"
                : params?.row?.role === "fleet_owner"
                ? "Fleet Owner"
                : params?.row?.role === "fk_ro"
                ? "FK RO"
                : params?.row?.role === "call_centre"
                ? "Call Centre"
                : "FK Tyre Care Executive"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "sales_employee_code",
      headerName: "Employee Code",
      width: 180,
      renderCell: (params) => {
        return params?.row?.sales_employee_code || "-";
      },
    },
    {
      field: "employee_designation",
      headerName: "Employee Designation",
      width: 220,
      renderCell: (params) => {
        return params?.row?.employee_designation || "-";
      },
    },
    {
      field: "fleet_name",
      headerName: "Associated Fleet",
      width: 180,
      renderCell: (params) => {
        let array = params?.row?.fleet_name;
        return isEmpty(params?.row?.fleet_name) ? (
          "-"
        ) : (
          <divlast_inspected_at
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {array.map((items, index) => {
              const isTwo = index <= 1;
              return isTwo ? (
                <Typography>
                  {items}
                  {index !== array.length - 1 ? ", " : ""}
                </Typography>
              ) : null;
            })}
            {array.length > 2 && (
              <span style={{ color: color.placeholder }}>{`+ ${
                array.length - 2
              } more`}</span>
            )}
          </divlast_inspected_at>
        );
      },
    },
    {
      field: "user_photo",
      headerName: "ID",
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const value =
          params?.row?.role === "fleet_owner"
            ? params?.row?.business_pan_card
            : params?.row?.user_photo;
        if (!isEmpty(value)) {
          let Array = value.split(".");
          return params?.row?.role === "fleet_owner" ? (
            <Typography
              style={{
                color: color.primary,
                cursor: "pointer",
              }}
              onClick={() => {
                if (Array[Array.length - 1] === "pdf") {
                  const pdfWindow = window.open();
                  pdfWindow.location.href = value;
                } else {
                  setValue(value);
                  setOpenCard(true);
                }
              }}
            >
              View
            </Typography>
          ) : (
            <img
              style={{
                cursor: "pointer",
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: !userManagementList.user_photo
                  ? color.secondary
                  : color.white,
              }}
              src={value || Images.profile}
              alt=""
              onClick={() => {
                setValue(value);
                setOpenCard(true);
              }}
            />
          );
        } else {
          return (
            <img
              style={{
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: color.secondary,
              }}
              src={Images.profile}
              alt=""
            />
          );
        }
      },
    },
    {
      field: "office_region",
      headerName: "Regional Office",
      width: 180,
      renderCell: (params) => {
        let array = params?.row?.office_region;
        let res =
          !isEmpty(array) &&
          dropDownData?.office_region?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return (
          <Typography style={{ fontSize: 14 }}>{res?.label || "-"}</Typography>
        );
      },
    },
    {
      field: "address",
      headerName: "Address",
      width: 180,
      renderCell: (params) => {
        return params?.row?.address || "-";
      },
    },
    {
      field: "office_contact_no",
      sortable: false,
      headerName: "Office Contact",
      width: 180,
      renderCell: (params) => {
        return params?.row?.office_contact_no || "-";
      },
    },
    // {
    //   field: "password",
    //   sortable: false,
    //   headerName: "Password",
    //   width: 120,
    // },
    {
      field: "status",
      headerName: "User Status",
      width: 140,
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.status === 10 ? "Active" : "Inactive"}
          </Typography>
        );
      },
    },
    // {
    //   field: "Remarks",
    //   sortable: false,
    //   headerName: "Remarks",
    //   width: 160,
    // },
  ];

  useImperativeHandle(ref, () => ({
    userManagementList: userManagementList,
    downloadFUserData: (v) => {
      getUserList(false, v);
    },
  }));

  function downloadFUserData(data) {
    const newData = data.map((row, index) => {
      let array = row?.office_region;
      let res =
        !isEmpty(array) &&
        dropDownData?.office_region?.find(
          (item) => array?.toString() === item?.id?.toString()
        );
      // const timestamp = row?.created_at;
      // const date = new Date(timestamp * 1000);
      // const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "User ID": row?.user_name || "-",
        Name: row?.name || "-",
        "Email ID": row?.email || "-",
        "Contact No.": row?.phone || "-",

        Privilege:
          row?.role === "fleet_manager"
            ? "Fleet Manager"
            : row?.role === "admin"
            ? "Admin"
            : row?.role === "fleet_owner"
            ? "Fleet Owner"
            : row?.role === "fk_ro"
            ? "FK RO"
            : row?.role === "call_centre"
            ? "Call Centre"
            : "FK Tyre Care Executive" || "-",
        "Employee Code": row?.sales_employee_code || "-",
        "Employee Designation": row?.employee_designation || "-",
        "Associated Fleet": row?.fleet_name.toString() || "-",
        ID:
          row?.role === "fleet_owner"
            ? row?.business_pan_card
            : row?.user_photo || "-",
        "Regional Office": res?.label || "",
        Address: row?.address || "-",
        "Office Contact": row?.office_contact_no || "-",
        "User Status": row?.status === 10 ? "Active" : "Inactive",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "User List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "User List.xlsx");
  }

  useEffect(() => {
    getUserList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      filter === "" && isEmpty(search) && getUserList(false);
    }
  }, [filter, search]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From ${
      from === "UserMangement" ? "User Management" : "Fleet Management"
    }`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getUserList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    v && handleLoader(true);
    let endpoints =
      from === "UserMangement"
        ? `${
            Setting.endpoints.userlist
          }?page=${pageNo}&per_page=${record}&search=${
            bool ? "" : search
          }&filter=${filter}&is_download=${v ? v : ""}`
        : `${Setting.endpoints.userlist}?fleet_id=${
            fleetData?.id
          }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            from === "UserMangement"
              ? downloadFUserData(response?.data?.rows)
              : downloadUserData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setFleetListRows(listObj);
          }
        }
        v && auditSave("User List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setReportLoader(false);
      handleLoader(false);
    } catch (error) {
      setPageLoad(false);
      handleLoader(false);
      setReportLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function downloadUserData(data) {
    const newData = data.map((row, index) => {
      let role =
        row?.role === "fleet_manager"
          ? "Fleet Manager"
          : row?.role === "admin"
          ? "Admin"
          : row?.role === "fleet_owner"
          ? "Fleet Owner"
          : row?.role === "fk_ro"
          ? "FK RO"
          : row?.role === "call_centre"
          ? "Call Centre"
          : "FK Tyre Care Executive";

      let status = row?.status === 10 ? "Active" : "Inactive";

      const timestamp = row?.created_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "User ID": row?.user_name || "-",
        Name: row?.name || "-",
        Privilege: role || "-",
        "Employee Code": row?.sales_employee_code || "-",
        "Employee Designation": row?.employee_designation || "-",
        ID: row?.user_photo || "-",
        "Email ID": row?.email || "-",
        "Contact No.": row?.phone || "-",
        "User Status": status || "-",
        "Added On": displayDate || "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "User List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "User List.xlsx");
  }

  function resetFilterData() {
    setFilter("");
    handleSearch("");
  }

  return (
    <div className={from !== "UserMangement" ? styles.fleetMain : null}>
      {from === "UserMangement" ? (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: 14 }}
          wrap="nowrap"
        >
          <Grid
            item
            lg={10.4}
            style={{ display: "flex", alignItems: "center" }}
          >
            <Grid item lg={2}>
              <Select
                fullWidth
                placeholder="Filter"
                value={filter}
                onChange={(v) => {
                  handlePageNo(1);
                  setFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    setPrivilegesList(false);
                    setRoList(false);
                    setUserStatusList(false);
                  }
                }}
                displayEmpty
                IconComponent={FilterAlt}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
                classes={{
                  iconOpen: styles.iconOpen,
                }}
              >
                <MenuItem value={""} disabled hidden selected>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    setPrivilegesList(!privilegesList);
                    setRoList(false);
                    setUserStatusList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Privilege</Typography>
                  {privilegesList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {privilegeList.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: privilegesList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
                <ListItemButton
                  onClick={() => {
                    setRoList(!RoList);
                    setPrivilegesList(false);
                    setUserStatusList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Regional Office</Typography>
                  {RoList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.office_region.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: RoList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
                <ListItemButton
                  onClick={() => {
                    setPrivilegesList(false);
                    setRoList(false);
                    setUserStatusList(!userStatusList);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>User Status</Typography>
                  {userStatusList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {userStatus.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: userStatusList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid container alignItems={"center"} lg={10} style={{ margin: 0 }}>
              <Grid item lg={3} style={{ marginLeft: 20 }}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={search}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getUserList(true);
                    handleSearch(v?.target?.value);
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by User ID, Name,Email ID and Employee Code"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                  onKeyDown={(v) => {
                    if (v.key === "Enter") {
                      if (!isEmpty(search)) {
                        handlePageNo(1);
                        getUserList(false);
                      }
                    }
                  }}
                />
              </Grid>
              <Grid item wrap="nowrap" style={{ marginLeft: 20 }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (!isEmpty(search)) {
                      handlePageNo(1);
                      getUserList(false);
                    }
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  onClick={resetFilterData}
                  disabled={filter === "" && isEmpty(search) ? true : false}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item lg={1.6} style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                handlePageNo(1);
                handleRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
      ) : (
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ padding: 10 }}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="tableTitle">User Management</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: sm ? "unset" : "flex-end",
            }}
          >
            <Button
              variant="contained"
              disabled={
                userdata?.role !== "superadmin" && userdata?.role !== "admin"
              }
              onClick={() => {
                handleClick("addUser", "");
              }}
            >
              Add User
            </Button>
            <Button
              variant="contained"
              disabled={isOnline ? false : true || reportLoader}
              style={{
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
                marginLeft: 10,
              }}
              onClick={() => getUserList(false, 1)}
            >
              {reportLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                "Download"
              )}
            </Button>
          </Grid>
        </Grid>
      )}
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(userManagementList?.data) &&
        !isEmpty(userManagementList?.data) ? (
        <div style={{ marginTop: 20 }}>
          <DataGrid
            rows={userManagementList?.data}
            columns={
              from === "UserMangement"
                ? userManagementListColumn
                : FuserManagementListColumn
            }
            disableColumnMenu
            hideFooter
            showCellRightBorder
            disableSelectionOnClick
            showColumnRightBorder
            autoHeight={true}
            getRowHeight={() => "auto"}
          />
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(userManagementList?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={2}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => {
                handlePageNo(e);
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
      <Modal
        className={styles.modal}
        open={openCard}
        onClose={() => {
          setOpenCard(false);
        }}
      >
        <img
          style={{
            objectFit: "contain",
            borderRadius: 4,
            maxWidth: 550,
            maxHeight: 550,
          }}
          src={value || "-"}
          alt={"user id"}
        />
      </Modal>
    </div>
  );
}

export default forwardRef(FUserManagement);
