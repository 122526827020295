import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting.js";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  Backdrop,
  Fade,
  FormHelperText,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  Modal,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles.js";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { cloneDeep, isArray, isEmpty, isNull } from "lodash";

import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm/index";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getApiData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@mui/styles";
import { Close } from "@mui/icons-material";

const errorObj = {
  vehicleErr: false,
  vehicleMsg: "",
  tyretypeErr: false,
  tyretypeMsg: "",
  tyreswapErr: false,
  tyreswapMsg: "",
  DisKmErr: false,
  DisKmMsg: "",
  RemovalTypeErr: false,
  RemovalTypeMsg: "",
};

export default function SwapTyre(props) {
  const {
    handleModal = () => null,
    handleSuccess = () => null,
    visible = true,
    vehicleList1 = [],
    vehicleId,
    captureDate,
  } = props;
  const styles = useStyles();

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 320 : 700,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    padding: 0,
  };

  const { token, dropDownData, isOnline } = useSelector((state) => state.auth);

  const currency_Regex = Setting.JS_Regex.currency_Regex;

  const [errObj, setErrObj] = useState(errorObj);
  const [vehicleList, setVehicleList] = useState([]);
  const [tyreList, setTyreList] = useState([]);
  const [loader, setLoader] = useState(false);

  const [vehicle, setVehicle] = useState({
    id: "",
    label: "",
  });
  const [TSN, setTSN] = useState("");
  const [SwapTyre, setSwapTyre] = useState("");
  const [lastReading, setLastReading] = useState("");
  const [dismountKm, setDismpountKm] = useState("");
  const [removalType, setRemovalType] = useState({});
  const dummytyreArr = cloneDeep(dropDownData?.tyre_removal_issue);
  const [tyreRemovalArr, setTyreRemovalArr] = useState(dummytyreArr);
  const [formValues, setFormValues] = useState([
    {
      groove1: "",
      groove2: "",
      groove3: "",
      groove4: "",
      id: Math.floor(Math.random() * 100 + 1),
    },
  ]);
  const [avgNSD, setAvgNSD] = useState("");
  const [openCard, setOpenCard] = useState(false);

  useEffect(() => {
    removeCurrentVehicle();
  }, []);

  useEffect(() => {
    if (vehicle?.id) {
      getTyreList();
      getVehicleDetails();
    }
  }, [vehicle]);

  useEffect(() => {
    isArray(formValues) && !isEmpty(formValues) && findAvgNSD();
  }, [formValues]);

  // this function for reset form data
  function resetFormData(type) {
    setVehicle({
      id: "",
      label: "",
    });
    setTSN("");
    setSwapTyre("");
    setLastReading("");
    setFormValues([
      {
        groove1: "",
        groove2: "",
        groove3: "",
        groove4: "",
        id: Math.floor(Math.random() * 100 + 1),
      },
    ]);
    setTyreRemovalArr(dummytyreArr);
    setAvgNSD("");
    setRemovalType({});
    setDismpountKm("");
    setErrObj(errorObj);
    if (handleModal) {
      handleModal(type);
    }
  }

  async function getTyreList() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.addinspectiontyrelist}?vehicle_id=${vehicle?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response.data.data) && !isEmpty(response.data.data)) {
          setTyreList(response?.data?.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${vehicle.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        converter(response?.data?.fitment_reading, "lastreading");
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function removeCurrentVehicle() {
    const dummyArr = [...vehicleList1];
    const ind = dummyArr.findIndex((v) => v?.id === vehicleId);
    dummyArr.splice(ind, 1);
    setVehicleList(dummyArr);

    // const removalType = tyreRemovalArr?.find((v) => v?.id === 89);
    // setRemovalType(removalType);
  }

  function findAvgNSD() {
    let groove = 0;
    let divide = 0;
    formValues?.map((v, index) => {
      if (!isEmpty(v?.groove1)) {
        groove = Number(v?.groove1) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove2)) {
        groove = Number(v?.groove2) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove3)) {
        groove = Number(v?.groove3) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove4)) {
        groove = Number(v?.groove4) + groove;
        divide++;
      }
    });

    const mainAVG = groove / divide;
    setAvgNSD(isNaN(mainAVG) ? "" : mainAVG.toFixed(2));
  }

  let handleChange = (i, e, key) => {
    let newFormValues = [...formValues];
    newFormValues.map((v, index) => {
      if (i === index) {
        if (key === 1) {
          v.groove1 = e;
        } else if (key === 2) {
          v.groove2 = e;
        } else if (key === 3) {
          v.groove3 = e;
        } else if (key === 4) {
          v.groove4 = e;
        }
      }
    });
    setFormValues(newFormValues);
  };

  let addFormFields = () => {
    if (formValues.length < 4) {
      setFormValues([
        ...formValues,
        {
          groove1: "",
          groove2: "",
          groove3: "",
          groove4: "",
          id: Math.floor(Math.random() * 100 + 1),
        },
      ]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
  };

  function numberWithCommas(x) {
    let c = x?.replaceAll(",", "");

    return c?.toString().split(".")[0].length > 3
      ? c
          ?.toString()
          .substring(0, c?.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c?.toString().substring(c?.toString().split(".")[0].length - 3)
      : c?.toString();
  }

  function converter(value, from) {
    let convert = numberWithCommas(value);
    if (from === "lastreading") {
      setLastReading(convert);
    } else if (from === "dismount") {
      setDismpountKm(convert);
      setErrObj({
        ...errObj,
        DisKmErr: false,
        DisKmMsg: "",
      });
    }
  }

  function validation() {
    const error = { ...errObj };
    let valid = true;
    let section = null;
    let scroll = false;
    let nsdErr = false;
    let SeqErr = false;

    if (isEmpty(vehicle?.label) || vehicle === undefined || vehicle === "") {
      valid = false;
      error.vehicleErr = true;
      error.vehicleMsg = "Please select the Vehicle No.";
    }

    if (isEmpty(TSN?.toString())) {
      valid = false;
      error.tyretypeErr = true;
      error.tyretypeMsg = "Please select Tyre Serial No.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#TSN");
      }
    }

    if (isEmpty(SwapTyre)) {
      valid = false;
      error.tyreswapErr = true;
      error.tyreswapMsg = "Please Select you want to Swap Tyre or Not.";
      if (!scroll) {
        scroll = true;
        section = document.querySelector("#swapTyre");
      }
    }

    let c = dismountKm?.replaceAll(",", "");
    let d = lastReading?.replaceAll(",", "");
    if (isEmpty(dismountKm.trim())) {
      valid = false;
      error.DisKmErr = true;
      error.DisKmMsg =
        "The Dismount Reading should be greater than or equal to Last Inspection Reading";
    } else if (!isEmpty(dismountKm) && !currency_Regex.test(c)) {
      valid = false;
      error.DisKmErr = true;
      error.DisKmMsg = "Please enter valid Km";
    } else if (!isEmpty(dismountKm) && Number(c) - Number(d) < 0) {
      valid = false;
      error.DisKmErr = true;
      error.DisKmMsg =
        "The Dismount Reading should greater or equals to Last Inspection Reading";
    }

    if (isEmpty(removalType)) {
      valid = false;
      error.RemovalTypeErr = true;
      error.RemovalTypeMsg = "Please select Removal Type";
    }

    formValues.map((v, index) => {
      if (!isEmpty(v?.groove1) && Math.ceil(v?.groove1) === 0) {
        valid = false;
        nsdErr = true;
      }
      if (!isEmpty(v?.groove2)) {
        if (Math.ceil(v?.groove2) === 0) {
          valid = false;
          nsdErr = true;
        } else if (isEmpty(v?.groove1)) {
          valid = false;
          SeqErr = true;
        }
      }
      if (!isEmpty(v?.groove3)) {
        if (Math.ceil(v?.groove3) === 0) {
          valid = false;
          nsdErr = true;
        } else if (isEmpty(v?.groove1) || isEmpty(v?.groove2)) {
          valid = false;
          SeqErr = true;
        }
      }
      if (!isEmpty(v?.groove4)) {
        if (Math.ceil(v?.groove4) === 0) {
          valid = false;
          nsdErr = true;
        } else if (
          isEmpty(v?.groove1) ||
          isEmpty(v?.groove2) ||
          isEmpty(v?.groove3)
        ) {
          valid = false;
          SeqErr = true;
        }
      }
    });

    const data = {
      vehicle: vehicle,
      TSN: TSN,
      SwapTyre: SwapTyre,
      DismountNSD: formValues,
      avgNSD: avgNSD,
      dismountKm: dismountKm,
      removalType: removalType,
    };

    setErrObj(error);
    if (nsdErr) {
      toast.error("NSD value can't be 0", { toastId: 1 });
    }
    if (SeqErr) {
      toast.error("Please fill NSD value sequentially", { toastId: 2 });
    }
    if (valid) {
      resetFormData("success");
      handleSuccess(data);
    }
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }

  function SelectTyre(index) {
    setTyreRemovalArr(dummytyreArr);
    const DummyArr = [...dummytyreArr];

    DummyArr.map((item, i) => {
      if (i === index) {
        item.selected = true;
      }
    });
    setTyreRemovalArr(DummyArr);
  }

  function handleSelect() {
    const DummyArr = [...tyreRemovalArr];
    const selected = DummyArr?.find((v) => v.selected === true);
    if (!isEmpty(selected)) {
      setRemovalType(selected);
    }
    setErrObj({ ...errObj, RemovalTypeErr: false, RemovalTypeMsg: "" });
    setOpenCard(false);
  }

  return (
    <Modal
      open={visible}
      onClose={() => resetFormData("cancel")}
      closeAfterTransition
      disableAutoFocus
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={visible}>
        <Box sx={style}>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: "18px 20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
            id="jump_to_me"
          >
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              Swap Tyre
            </Typography>
            <Close
              style={{ color: color.white, cursor: "pointer" }}
              onClick={() => resetFormData("cancel")}
            />
          </Box>

          <Box className={styles.container}>
            <Grid
              container
              style={{ marginBottom: errObj.vehicleErr ? 30 : 20 }}
              alignItems={"center"}
              id="vehicle"
            >
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <CTypography required title={"Vehicle No."} />
              </Grid>
              <Grid item xs={12} sm={9} md={10} lg={10}>
                <FormControl
                  fullWidth
                  error={errObj.vehicleErr}
                  sx={{
                    width: "100%",
                    border: errObj.vehicleErr ? "1px solid #ff1744" : null,
                    borderRadius: 1,
                  }}
                >
                  <Autocomplete
                    fullWidth
                    isOptionEqualToValue={(option, value) =>
                      option.label === value
                    }
                    disableListWrap={true}
                    options={vehicleList}
                    value={vehicle?.label}
                    classes={{ input: `${styles.autocomplete}` }}
                    onChange={(v1, v) => {
                      setVehicle(v);
                      setTSN("");
                      setSwapTyre("");
                      setErrObj({
                        ...errObj,
                        vehicleErr: false,
                        vehicleMsg: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.inputDivStyle}
                        placeholder={
                          isEmpty(vehicle?.label) ? "Select Vehicle" : null
                        }
                      />
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      const renderTagsValue = tagValue;
                      return (
                        // <Typography noWrap={true} color="textPrimary">
                        renderTagsValue
                        // </Typography>
                      );
                    }}
                  />

                  {errObj.vehicleErr ? (
                    <FormHelperText>{errObj.vehicleMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.tyretypeErr ? 30 : 20 }}
              id="TSN"
            >
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <CTypography required title={"Select TSN"} />
              </Grid>

              <Grid item xs={12} sm={9} md={10} lg={10}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl
                    fullWidth
                    error={errObj.tyretypeErr}
                    sx={{
                      width: "100%",
                      border: errObj.tyretypeErr ? "1px solid #ff1744" : null,
                      borderRadius: 1,
                    }}
                  >
                    <Select
                      displayEmpty
                      value={TSN}
                      onChange={(event) => {
                        setTSN(event.target.value);
                        setErrObj({
                          ...errObj,
                          tyretypeErr: false,
                          tyretypeMsg: "",
                        });
                      }}
                      disabled={isEmpty(tyreList)}
                      size={"medium"}
                      style={TSN === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select the TSN
                      </MenuItem>
                      {isArray(tyreList) &&
                        tyreList.map((item, index) => (
                          <MenuItem key={index} value={item?.id}>
                            {item?.tyre_serial_no}
                          </MenuItem>
                        ))}
                    </Select>
                    {errObj.tyretypeErr ? (
                      <FormHelperText>{errObj.tyretypeMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              style={{ marginBottom: errObj.tyreswapErr ? 30 : 20 }}
              id="swapTyre"
            >
              <Grid item xs={12} sm={3} md={2} lg={2}>
                <CTypography required title={"Swap Tyre?"} />
              </Grid>

              <Grid item xs={12} sm={9} md={10} lg={10}>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl
                    fullWidth
                    error={errObj.tyreswapErr}
                    sx={{
                      width: "100%",
                      border: errObj.tyreswapErr ? "1px solid #ff1744" : null,
                      borderRadius: 1,
                    }}
                  >
                    <Select
                      displayEmpty
                      value={SwapTyre}
                      disabled={isEmpty(TSN.toString())}
                      onChange={(event) => {
                        setSwapTyre(event.target.value);
                        setErrObj({
                          ...errObj,
                          tyreswapErr: false,
                          tyreswapMsg: "",
                        });
                      }}
                      size={"medium"}
                      style={SwapTyre === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select the Option
                      </MenuItem>
                      <MenuItem value={"1"}>Yes</MenuItem>
                      <MenuItem value={"0"}>No</MenuItem>
                    </Select>
                    {errObj.tyreswapErr ? (
                      <FormHelperText>{errObj.tyretypeMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Box>
              </Grid>
            </Grid>

            {formValues.map((v, index) => {
              const isLast = index === formValues.length - 1;
              return (
                <Grid
                  key={`new_${v?.id}`}
                  container
                  style={{ marginBottom: 20 }}
                  alignItems={"center"}
                  id={`nsdErr_${index}`}
                >
                  <Grid item xs={12} md={2}>
                    <Typography>
                      {index === 0 ? "Dismount NSD : " : ""}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md={10}
                    style={{
                      display: !md ? "flex" : "unset",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Grid item xs={12} md={2.33}>
                      <TextField
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Groove 1"
                        value={v.groove1}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                        }}
                        onChange={(e) => {
                          handleChange(index, e?.target?.value, 1);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2.33}
                      style={{ marginTop: md ? 20 : 0 }}
                    >
                      <TextField
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Groove 2"
                        value={v.groove2}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                        }}
                        onChange={(e) => {
                          handleChange(index, e?.target?.value, 2);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2.33}
                      style={{ marginTop: md ? 20 : 0 }}
                    >
                      <TextField
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Groove 3"
                        value={v.groove3}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                        }}
                        onChange={(e) => {
                          handleChange(index, e?.target?.value, 3);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2.33}
                      style={{ marginTop: md ? 20 : 0 }}
                    >
                      <TextField
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Groove 4"
                        value={v.groove4}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                        }}
                        onChange={(e) => {
                          handleChange(index, e?.target?.value, 4);
                        }}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={2}
                      style={{ marginTop: md ? 20 : 0 }}
                    >
                      <Button
                        style={{
                          backgroundColor: !isLast
                            ? color.secondary
                            : formValues.length === 4 && isLast
                            ? "rgba(0, 0, 0, 0.12)"
                            : color.primary,
                          width: "100%",
                          boxShadow:
                            formValues.length === 4 && isLast ? "unset" : "",
                        }}
                        disabled={formValues.length === 4 && isLast}
                        onClick={() =>
                          !isLast ? removeFormFields(index) : addFormFields()
                        }
                      >
                        {!isLast ? "Remove" : "Add More"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}

            <Grid
              container
              style={{ marginBottom: errObj.AvgNSDErr ? 30 : 20 }}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography title={"Avg. Dismount NSD"} />
              </Grid>
              <Grid item xs={12} md={10} lg={10}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  value={avgNSD}
                  placeholder="Enter the Avg.Dismount NSD"
                  inputProps={{ readOnly: true }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">mm</InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>

            <Grid container style={{ marginBottom: 20 }}>
              <Grid item xs={isTablet ? 5 : 7} sm={3} md={2} lg={2}>
                <Typography>Last Inspection Reading :</Typography>
              </Grid>

              <Grid item xs={isTablet ? 7 : 5} sm={9} md={10} lg={10}>
                <Typography>{lastReading || "-"}</Typography>
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.DisKmErr ? 10 : 20 }}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography required title={"Dismount Reading"} />
              </Grid>
              <Grid item xs={12} md={10} lg={10}>
                <TextField
                  style={{ width: "100%", margin: 0 }}
                  value={dismountKm}
                  placeholder="Enter Dismount Reading in Km"
                  onChange={(e) => {
                    if (
                      e?.nativeEvent?.data === "0" ||
                      e?.nativeEvent?.data === "1" ||
                      e?.nativeEvent?.data === "2" ||
                      e?.nativeEvent?.data === "3" ||
                      e?.nativeEvent?.data === "4" ||
                      e?.nativeEvent?.data === "5" ||
                      e?.nativeEvent?.data === "6" ||
                      e?.nativeEvent?.data === "7" ||
                      e?.nativeEvent?.data === "8" ||
                      e?.nativeEvent?.data === "9" ||
                      e?.nativeEvent?.data === "." ||
                      isNull(e?.nativeEvent?.data)
                    ) {
                      // converter(e.target.value, "dismount");
                      setDismpountKm(e.target.value);
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">Km</InputAdornment>
                    ),
                  }}
                  error={errObj.DisKmErr}
                  helperText={errObj.DisKmErr ? errObj.DisKmMsg : null}
                  FormHelperTextProps={{
                    style: {
                      position: "unset",
                    },
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              style={{ marginBottom: errObj.RemovalTypeErr ? 30 : 20 }}
              alignItems={"center"}
            >
              <Grid item xs={12} sm={12} md={2} lg={2}>
                <CTypography required title={"Removal Type"} />
              </Grid>
              <Grid item xs={12} md={10} lg={10}>
                <FormControl fullWidth error={errObj.RemovalTypeErr}>
                  <OutlinedInput
                    style={{ width: "100%", margin: 0, cursor: "pointer" }}
                    placeholder="Select the Removal Type"
                    inputProps={{
                      readOnly: true,
                      style: { cursor: "pointer" },
                    }}
                    value={removalType.label}
                    onClick={() => {
                      if (isArray(tyreRemovalArr) && !isEmpty(tyreRemovalArr)) {
                        setOpenCard(true);
                      } else {
                        toast.warning(
                          "Please Add Tyre Issue Removal First from General DropDowns"
                        );
                      }
                    }}
                    endAdornment={
                      <InputAdornment position="end">
                        <ArrowDropDownIcon />
                      </InputAdornment>
                    }
                  />
                  {errObj.RemovalTypeErr ? (
                    <FormHelperText>{errObj.RemovalTypeMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>

            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              style={{ marginBottom: 20 }}
            >
              <Button
                disabled={isOnline ? false : true}
                onClick={validation}
                style={{
                  backgroundColor: isOnline ? color.primary : color.bordercolor,
                  marginRight: 10,
                  width: 130,
                }}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : (
                  `Swap Tyre`
                )}
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: false ? color?.disable : color.secondary,
                  width: 130,
                }}
                onClick={() => {
                  resetFormData("cancel");
                }}
              >
                Cancel
              </Button>
            </Grid>
          </Box>

          <Modal
            onClose={() => {
              setOpenCard(false);
            }}
            open={openCard}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                maxWidth: 650,
                maxHeight: "65vh",
                backgroundColor: "white",
                padding: 20,
                borderRadius: 4,
              }}
            >
              <ImageList
                cols={isMobile ? 1 : md ? 2 : 3}
                gap={9}
                style={{ overflowY: "scroll", maxHeight: "50vh" }}
              >
                {tyreRemovalArr.map((item, index) => {
                  return (
                    <ImageListItem
                      key={index}
                      style={{
                        width: isMobile ? "60vw" : 200,
                        height: 150,
                        border: item?.selected
                          ? `1px solid ${color.primary}`
                          : "",
                      }}
                      onClick={() => {
                        SelectTyre(index);
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: `1px solid ${color.bordercolor}`,
                        }}
                      >
                        <img
                          style={{
                            height: 118,
                            width: isMobile ? "60vw" : 200,
                          }}
                          src={`${item.image}?w=100&fit=crop&auto=format`}
                          alt=""
                        />
                      </div>
                      <ImageListItemBar
                        position="below"
                        title={item?.label}
                        style={{
                          textAlign: "center",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          backgroundColor: item?.selected ? color.primary : "",
                          color: item?.selected ? color.white : "",
                        }}
                      />
                    </ImageListItem>
                  );
                })}
              </ImageList>
              <div style={{ marginTop: 20 }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.primary }}
                  fullWidth
                  onClick={handleSelect}
                >
                  Select
                </Button>
              </div>
            </div>
          </Modal>
        </Box>
      </Fade>
    </Modal>
  );
}
