import React, { useEffect, useState } from "react";
import { Box, Button } from "@mui/material";
import { Setting } from "../../../Utils/Setting";
import AddVehicleMaster from "../../../Components/VehicleMaster/AddVehicleMaster";
import useStyles from "./styles";
import TabVehicleMasterView from "../../../Components/TabVehicleMaster/TabVehicleMasterView";
import Images from "../../../Config/Images";
import { isMobile, isTablet } from "react-device-detect";
import { color } from "../../../Config/theme";
import { ArrowBackOutlined } from "@mui/icons-material";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function TabVehicleMaster() {
  const styles = useStyles();
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [changeTab, setChangeTab] = useState("");
  const [data, setData] = useState({});

  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [filter, setFilter] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    document.title = Setting.page_name.VehicleMaster;
  }, []);

  return (
    <div className={styles.card}>
      {isTablet
        ? null
        : !isKeyboardOpen &&
          changeTab === "" && (
            <Box
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                height: isMobile ? 30 : 40,
                position: "fixed",
                top: 14,
                left: 20,
                zIndex: 10,
              }}
            >
              <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
            </Box>
          )}

      {changeTab === "addVehicle" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              variant="contained"
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "addVehicle" ? (
        <AddVehicleMaster
          editData={data}
          handleClick={(v, type) => {
            if (v === "cancle") {
              setChangeTab("");
            }
          }}
        />
      ) : (
        <TabVehicleMasterView
          pageNo={pageNo}
          record={record}
          filter={filter}
          search={search}
          handlePageNo={(page) => {
            setPageNo(page);
          }}
          handleRecord={(rec) => {
            setRecord(rec);
          }}
          handleFilter={(flt) => {
            setFilter(flt);
          }}
          handleSearch={(search) => {
            setSearch(search);
          }}
          handleClick={(v, type) => {
            if (v === "addVehicle") {
              setChangeTab("addVehicle");
              setData({});
            }
            if (v === "editVehicle") {
              setChangeTab("addVehicle");
              setData(type);
            }
          }}
        />
      )}
    </div>
  );
}
