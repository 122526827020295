import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    backgroundColor: color.primary,
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
  modalMain: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  upload: {
    backgroundColor: "transparent",
    height: 150,
    border: `2px dotted ${color.bordercolor}`,
    color: color.bordercolor,
    boxShadow: "none",
  },
  closeBtn: {
    position: "absolute",
    right: -10,
    top: -10,
    borderRadius: 20,
    padding:0,
    boxShadow: "0px 0px 10px #00000052",
    color: color.primary,
    cursor: "pointer",
  },
}));
export default useStyles;
