import React, { useState, useEffect, useRef } from "react";
import useStyles from "./styles.js";
import { color } from "../../../Config/theme.js";
import { Setting } from "../../../Utils/Setting.js";
import {
  Button,
  Grid,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableHead,
  TextField,
  Select,
  MenuItem,
  Tooltip,
  InputAdornment,
  TableBody,
  ListItemButton,
  CircularProgress,
  Popover,
  Pagination,
  Divider,
  Switch,
} from "@mui/material";
import {
  Edit,
  FilterAlt,
  Search,
  TuneOutlined,
  Visibility,
} from "@mui/icons-material";
import * as XLSX from "xlsx/xlsx.mjs";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import {
  isEmpty,
  isNull,
  isObject,
  isArray,
  toNumber,
  cloneDeep,
  flattenDeep,
} from "lodash";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import Images from "../../../Config/Images.js";
import Chart from "react-google-charts";
import html2canvas from "html2canvas";
import SquareRoundedIcon from "@mui/icons-material/SquareRounded";
import { isTablet } from "react-device-detect";
import MDateRange from "../../Modal/MDateRange/index.js";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";

export default function TabVehicleManagement(props) {
  const styles = useStyles();
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    fleetData,
    name,
  } = props;
  const [pageLoad, setPageLoad] = useState(true);
  // const [filter, setFilter] = useState("");
  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [terrainList, setterrainList] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const { token, dropDownData, isOnline, userdata, permissionData } =
    useSelector((state) => state.auth);
  const [vehicleListRows, setVehicleListRows] = useState({});
  const [disable, setDisable] = useState(true);
  const [tabValue, setTabValue] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeIndex, setActiveIndex] = useState(null);
  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;

  const [visible, setVisible] = useState(false);
  const [arrActiveIndex, setArrActiveIndex] = useState("");
  const [arrReportLoader, setArrReportLoader] = useState(false);

  const [fleetReportData, setFleetReportData] = useState([]);
  const [mechReportData, setMechReportData] = useState([]);
  const [reportLoader, setReportLoader] = useState(false);
  const [fleetHealth, setFleetHealth] = useState([]);

  const [reportLoader1, setReportLoader1] = useState(true);
  const [reportLoader2, setReportLoader2] = useState(true);
  const [tyrePieData, setTyrePieData] = useState([]);
  const [mechPieData, setMechPieData] = useState([]);

  const [km_mmArr, setKm_mmArr] = useState({});
  const [tyreInspectionArr, setTyreInspectionArr] = useState({});
  const [vehicleIdsM, setVehicleIds] = useState([]);

  const [mileageArr, setMileageArr] = useState({});
  const [mileageDates, setMileageDates] = useState({});

  const staticArr = [
    "Tyre Mileage Report",
    "Tyre Inspection Report",
    "Km/mm Report",
  ];

  const [staticArray, setStaticArr] = useState([...staticArr]);
  const [fleetHealthDisplay, setFleetHealthDisplay] = useState(true);
  const [downloadLoader, setDownloadLoader] = useState(false);

  // intialRender used for stop multiple time apiCall in
  const initialRender = useRef(true);

  useEffect(() => {
    if (!isEmpty(mileageArr) && mileageArr?.pagination?.isMore) {
      getMileageReport(
        fleetData?.id,
        vehicleIdsM,
        mileageDates?.fromDate,
        mileageDates?.toDate,
        isEmpty(vehicleIdsM) ? true : false,
        false
      );
    }
  }, [mileageArr]);

  useEffect(() => {
    const dummy_Arr = [...staticArr];
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Fleet Health Summary Report") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              setFleetHealthDisplay(v?.isActive);
          }
          if (v?.label === "Tyre Mileage Report") {
            const findI = dummy_Arr.findIndex(
              (v) => v === "Tyre Mileage Report"
            );
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(findI, 1);
          }
          if (v?.label === "Tyre Inspection Report") {
            const findI = dummy_Arr.findIndex(
              (v) => v === "Tyre Inspection Report"
            );
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(findI, 1);
          }
        });
      });
    }
    setStaticArr(dummy_Arr);
  }, [permissionData]);

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      setPageLoad(true);
      getVehicleist(false);
      getTyreIssueGraphData();
    }
  }, [record, pageNo, filter]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getVehicleist(false);
  }, [datePicker, datePicker1, searchFilter]);

  useEffect(() => {
    getTyreIssueGraphData();
    getFleetHealthGraphData();
    getTyreIssuePieData();
    getMechIssuePieData();
  }, []);

  useEffect(() => {
    const section = document.querySelector(`#jump_to_meV`);
    section.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  }, [pageNo]);

  useEffect(() => {
    if (!isEmpty(km_mmArr) && km_mmArr?.pagination?.isMore) {
      getKmMmReport(vehicleIdsM, true, false);
    }
  }, [km_mmArr]);

  useEffect(() => {
    if (!isEmpty(tyreInspectionArr) && tyreInspectionArr?.pagination?.isMore) {
      getinspectionreport(
        fleetData?.id,
        vehicleIdsM,
        mileageDates?.fromDate,
        mileageDates?.toDate,
        isEmpty(vehicleIdsM) ? true : false,
        false
      );
    }
  }, [tyreInspectionArr]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_meV${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);

  useEffect(() => {
    setActiveIndex(null);
  }, [pageNo, record]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Fleet Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getVehicleist(bool, v, isToggle) {
    !isToggle && setActiveIndex(null);
    !v && !isToggle && setPageLoad(true);
    v && setDownloadLoader(true);
    const fromDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePicker1)
      ? moment(datePicker1).format("YYYY-MM-DD")
      : "";

    const terrainMatch = dropDownData?.terrain?.find((v) => v?.id === filter);

    try {
      const response = await getApiData(
        `${
          Setting.endpoints.vehicleList
        }?per_page=${record}&page=${pageNo}&fleet_id=${fleetData?.id}&search=${
          bool ? "" : searchFilter
        }&filter=${isEmpty(terrainMatch) ? filter : ""}&terrain_ids=${
          !isEmpty(terrainMatch) ? filter : ""
        }&from_date=${fromDate}&to_date=${toDate}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadVehicleData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setVehicleListRows(listObj);
          }
        v && auditSave("Vehicle List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function resetFilterData() {
    handleFilter("");
    setSearchFilter("");
    handlePageNo(1);
    setDatePicker(null);
    setDatePicker1(null);
  }
  const getVehicleType = (index) => {
    let array = vehicleListRows?.data[index]?.vehicle_type_id;
    let res = dropDownData?.vehicle_type?.find(
      (item) => array?.toString() === item?.id?.toString()
    );
    return res?.label;
  };

  const getNoOfTyre = (index) => {
    return (
      <>
        <span
          style={{
            color:
              vehicleListRows?.data[index]?.added_tyre_count != (0 || "")
                ? "red"
                : "",
          }}
        >
          {vehicleListRows?.data[index]?.added_tyre_count}
        </span>
        <span>/{vehicleListRows?.data[index]?.no_of_tyres}</span>
      </>
    );
  };

  const getVehicleBrand = (index) => {
    let array = vehicleListRows?.data[index]?.vehicle_brand_id;
    let res = dropDownData?.vehicle_brand?.find(
      (item) => array?.toString() === item?.id?.toString()
    );
    return res?.label;
  };

  const getTerrain = (index) => {
    let array = vehicleListRows?.data[index]?.terrain_ids?.split(",");
    let res = dropDownData?.terrain.filter((item) => {
      let a = array.includes(item?.id?.toString());
      return a;
    });
    return res.map((v, i) => (
      <>
        {v?.label}
        {i < res.length - 1 ? ", " : ""}
      </>
    ));
  };
  const AddedOn = (index) => {
    const timestamp = vehicleListRows?.data[index]?.capture_date;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  function downloadVehicleData(data) {
    const newData = data.map((row, index) => {
      let vehicleType =
        !isNull(row?.vehicle_type_id) &&
        dropDownData?.vehicle_type?.find(
          (item) => row?.vehicle_type_id?.toString() === item?.id?.toString()
        );
      let vehicleBrand =
        !isNull(row?.vehicle_brand_id) &&
        dropDownData?.vehicle_brand?.find(
          (item) => row?.vehicle_brand_id?.toString() === item?.id?.toString()
        );

      const getTerrain = () => {
        let array = row?.terrain_ids?.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array?.includes(item?.id?.toString());
          return a;
        });
        const arr = [];
        res.map((v, i) => arr.push(v?.label));
        return arr.toString();
      };

      const timestamp = row?.capture_date;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        ID: row?.id || "-",
        "Vehicle No.": row?.vehicle_no,
        "Vehicle Status": row?.status === 1 ? "Active" : "In Active",
        "Vehicle Type": vehicleType?.label,
        "Vehicle Brand": vehicleBrand?.label,
        "Vehicle Model": row?.vehicle_model_name,
        "No. of Tyres": `${row?.added_tyre_count}/${row?.no_of_tyres}`,
        "Initial Reading (Km)": Number(
          row?.current_reading.toString()?.replaceAll(",", "")
        ),
        Terrain: getTerrain() || "-",
        "Load Capacity": `${row?.load_capicity}${
          row?.load_capicity ? " Tonnes" : "-"
        }`,
        "Inspections (Month)": Number(row?.inspection_monthly),
        "Inspections (Overall)": Number(row?.inspection_overall),
        "Driver's Name": row?.driver_name || "-",
        "Driver's No.": row?.driver_phone || "-",
        "Goods Carried": row?.goods_carried || "-",
        "Route Origin": row?.route_origin || "-",
        "Route Destination": row?.route_destination || "-",
        "Alignments Claimed": `${row?.alignment_count || 0}/5`,
        "Added On": row?.capture_date ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Vehicle List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Vehicle List.xlsx");
  }

  async function getTyreIssueGraphData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetissueGraph}?fleet_id=${fleetData.id}&type=tyre`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          creatFleetGraph(response?.data, "t");
        }
      } else {
        toast.error(response?.message);
      }
      getMecIssueGraphData();
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  async function getMecIssueGraphData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetissueGraph}?fleet_id=${fleetData.id}&type=mechanical`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          creatFleetGraph(response?.data, "m");
        }
      } else {
        toast.error(response?.message);
      }
      setReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  function creatFleetGraph(data, type) {
    const dummy_Arr = [
      type === "t" ? ["Tyre Issue", "data"] : ["Mechanical Issue", "data"],
    ];

    data.map((item) => {
      const arr = [item?.issue, Number(item?.percentage)];
      dummy_Arr.push(arr);
    });
    if (type === "t") {
      setFleetReportData(dummy_Arr);
    } else {
      setMechReportData(dummy_Arr);
    }
    setReportLoader(false);
  }

  async function getFleetHealthGraphData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleethealthGraph}?fleet_id=${fleetData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          creatFleetHealthGraph(response?.data, "h");
        }
      } else {
        toast.error(response?.message);
      }
      getMecIssueGraphData();
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  function creatFleetHealthGraph(data, type) {
    const dummy_Arr = [["Issue Type", "data"]];
    Object.keys(data).map((item) => {
      const arr = [
        item === "no_issue" ? "No Issue" : item === "major" ? "Major" : "Minor",
        data[item],
      ];
      dummy_Arr.push(arr);
    });

    setFleetHealth(dummy_Arr);
  }

  async function getinspectionreport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll,
    bool
  ) {
    setArrReportLoader(true);

    let page = 0;
    const cPage =
      tyreInspectionArr &&
      tyreInspectionArr.pagination &&
      tyreInspectionArr.pagination.currentPage
        ? toNumber(tyreInspectionArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }

    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const from_date1 = !isNull(mileageDates?.fromDate)
      ? moment(mileageDates?.fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    const to_date1 = !isNull(mileageDates?.toDate)
      ? moment(mileageDates?.toDate).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        isAll
          ? `${Setting.endpoints.tyreInspectionReport}?fleet_id=${
              fleetData?.id
            }&is_all=${1}&page=${page}&from_date=${
              bool ? from_date : from_date1
            }&to_date=${
              bool
                ? isEmpty(to_date)
                  ? from_date
                  : to_date
                : isEmpty(to_date1)
                ? from_date1
                : to_date1
            }`
          : `${Setting.endpoints.tyreInspectionReport}?fleet_id=${
              fleetData?.id
            }&vehicle_id=${vehicleIds}&page=${page}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (isObject(response) && !isEmpty(response) && response.status) {
        const obj = bool ? {} : cloneDeep(tyreInspectionArr);

        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};
        if (isArray(newListData) && !isEmpty(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
          if (!paginationDatas?.isMore) {
            setArrReportLoader(false);
            downloadReports5(obj?.data);
            auditSave("Tyre Inspection Report");
            setTyreInspectionArr({});
            setMileageDates({});
          } else {
            setTyreInspectionArr(obj);
          }
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
          setArrReportLoader(false);
        }
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
      setArrReportLoader(false);
    }
  }

  function downloadReports5(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const getTerrain = () => {
        let array = item?.terrain_ids?.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array?.includes(item?.id?.toString());
          return a;
        });
        const arr = [];
        res.map((v, i) => arr.push(v?.label));
        return arr?.toString();
      };

      const timestamp = item?.inspected_at;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        Terrain: getTerrain() || "-",
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(item?.avg_nsd) || "-",
        "Tyre Pressure (psi)": item?.tyre_air_pressure || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Tyre Issue": item?.tyre_issue?.toString() || "-",
        "Mechanical Issue": item?.mechanical_issue?.toString() || "-",
        "Consumed NSD (mm)": Number(item?.consumed_nsd) || "-",
        "KM/mm": item?.km_mm || "-",
        "Projected Mileage@100%": item?.projected_mileage_100 || "-",
        "Projected Mileage@85%": item?.projected_mileage_85 || "-",
        "Inspected Date": timestamp ? displayDate : "-",
        "Inspected By": item?.inspected_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Inspection Report List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Inspection Report.xlsx");
  }

  async function getMileageReport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll,
    bool
  ) {
    setArrReportLoader(true);

    let page = 0;
    const cPage =
      mileageArr && mileageArr.pagination && mileageArr.pagination.currentPage
        ? toNumber(mileageArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }

    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const from_date1 = !isNull(mileageDates?.fromDate)
      ? moment(mileageDates?.fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    const to_date1 = !isNull(mileageDates?.toDate)
      ? moment(mileageDates?.toDate).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        isAll
          ? `${Setting.endpoints.tyreMileageReport}?fleet_id=${
              fleetData?.id
            }&is_all=${1}&from_date=${bool ? from_date : from_date1}&to_date=${
              bool
                ? isEmpty(to_date)
                  ? from_date
                  : to_date
                : isEmpty(to_date1)
                ? from_date1
                : to_date1
            }&page=${page}`
          : `${Setting.endpoints.tyreMileageReport}?fleet_id=${
              fleetData?.id
            }&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (isObject(response) && !isEmpty(response) && response.status) {
        const obj = bool ? {} : cloneDeep(mileageArr);

        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};
        if (isArray(newListData) && !isEmpty(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
          if (!paginationDatas?.isMore) {
            setArrReportLoader(false);
            downloadMileageReport(obj?.data);
            auditSave("Tyre Mileage Report");
            setMileageArr({});
            setMileageDates({});
          } else {
            setMileageArr(obj);
          }
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
          setArrReportLoader(false);
        }
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
      setArrReportLoader(false);
    }
  }

  function mileageHistory(data) {
    const one = Object.keys(data).map((item) => {
      let a = `${item} => ${data[item]}`;
      return a;
    });
    const arr = [];
    one.map((item1) => {
      arr.push(item1);
    });
    return arr;
  }

  function downloadMileageReport(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        // "Current NSD (mm)": `${groove1.toFixed(2) || 0}/${
        //   groove2.toFixed(2) || 0
        // }/${groove3.toFixed(2) || 0}/${groove4.toFixed(2) || 0}`,
        "Standard NSD": Number(item?.original_nsd) || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Current Avg NSD": Number(item?.avg_nsd) || "-",
        "Total Mileage (Km)": Number(
          item?.current_mileage?.toString()?.replaceAll(",", "")
        ),
        "Cost (₹)":
          Number(item?.purchase_cost?.toString()?.replaceAll(",", "")) || "-",
        CPKm: item?.CPKm ? item?.CPKm : "-",
        "Mileage History":
          mileageHistory(item?.mileage_history).toString() || "-",
        "Percentage Wear of Tyre":
          `${item?.percentage_wear_of_tyre || 0}%` || "-",
        // "Dismount Reason": "",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Mileage Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Mileage Report.xlsx");
  }

  const options = {
    colors: [color.primary],
    legend: { position: "none" },
    vAxis: { title: "Number of Vehicles with the Issues" },
  };

  function printDocument(id, name, btn) {
    let note = null;
    if (id === "graph1" || id === "graph2") {
      note = document.getElementById(btn);
      note.style.opacity = 0;
    }

    const input = document.getElementById(id);
    input.style.width = "1500px";
    input.style.overflowX = "unset";
    downloadCanvas(id, name);
    note.style.opacity = 1;
  }

  function downloadCanvas(id, name) {
    const input = document.getElementById(id);
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var a = document.createElement("a"); //Create <a>
      a.href = imgData; //Image Base64 Goes here//File name Here
      a.download = `${name}.png`; //File name Here
      a.click(); //Downloaded file
    });

    resetStyle(id);
  }

  function resetStyle(id) {
    const note = document.getElementById(id);
    note.style.width = "100%";
    note.style.overflowX = "scroll";
  }

  async function getTyreIssuePieData() {
    setReportLoader1(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetPie}?fleet_id=${fleetData.id}&type=tyre`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          createFleetPie(response?.data, "t");
        }
      } else {
        toast.error(response?.message);
      }
      setReportLoader1(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader1(false);
    }
  }

  async function getMechIssuePieData() {
    setReportLoader2(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetPie}?fleet_id=${fleetData.id}&type=mechanical`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          createFleetPie(response?.data, "m");
        }
      } else {
        toast.error(response?.message);
      }
      setReportLoader2(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader2(false);
    }
  }

  function createFleetPie(data, type) {
    const dummy_Arr = [
      type === "t" ? ["Tyre Issue", "data"] : ["Mechanical Issue", "data"],
    ];

    for (const [key, value] of Object.entries(data)) {
      const arr = [
        key === "unresolved"
          ? "Unresolved"
          : key === "partially_resolved"
          ? "Partially Resolved"
          : "Resolved",
        Number(value),
      ];
      dummy_Arr.push(arr);

      if (type === "t") {
        setTyrePieData(dummy_Arr);
      } else {
        setMechPieData(dummy_Arr);
      }
      setReportLoader(false);
    }
  }

  const changeVehicleStatus = async (data) => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.changeVehicleStatus}?id=${data?.id}&status=${
          data?.status === 1 ? 0 : 1
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        getVehicleist(false, "", true);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  async function getKmMmReport(vehicleIds, isAll, bool) {
    setArrReportLoader(true);
    let page = 0;
    const cPage =
      km_mmArr && km_mmArr.pagination && km_mmArr.pagination.currentPage
        ? toNumber(km_mmArr.pagination.currentPage)
        : 0;

    if (bool) {
      page = 1;
    } else {
      page = cPage + 1;
    }

    try {
      const response = await getApiData(
        isAll
          ? `${Setting.endpoints.kmReport}?fleet_id=${
              fleetData?.id
            }&is_all=${1}&vehicle_ids=${
              bool ? vehicleIds : vehicleIdsM
            }&page=${page}`
          : `${Setting.endpoints.kmReport}?fleet_id=${fleetData?.id}&vehicle_ids=${vehicleIds}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (isObject(response) && !isEmpty(response) && response.status) {
        const obj = bool ? {} : cloneDeep(km_mmArr);

        const newListData =
          response && response.data && response.data.rows
            ? response.data.rows
            : [];
        const paginationDatas =
          response && response.data && response.data.pagination
            ? response.data.pagination
            : {};
        if (isArray(newListData) && !isEmpty(newListData)) {
          if (isArray(obj.data) && obj.data.length > 0) {
            obj.data = flattenDeep([...obj.data, newListData]);
          } else {
            obj.data = newListData;
          }
          obj.pagination = paginationDatas;
          if (!paginationDatas?.isMore) {
            setArrReportLoader(false);
            downloadReports7(obj?.data);
            auditSave("Km_mm Report");
            setKm_mmArr({});
            setVehicleIds([]);
          } else {
            setKm_mmArr(obj);
          }
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
          setArrReportLoader(false);
        }
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
      setArrReportLoader(false);
    }
  }

  function downloadReports7(data) {
    const newData = data.map((item, index) => {
      const nsdArr = item?.first_inspection_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });

      const lastNSDArr = item?.last_inspection_nsd;
      let lastGroove1 = 0;
      let lastGroove2 = 0;
      let lastGroove3 = 0;
      let lastGroove4 = 0;
      let lastCount1 = 1;
      let lastCount2 = 1;
      let lastCount3 = 1;
      let lastCount4 = 1;
      isArray(lastNSDArr) &&
        !isEmpty(lastNSDArr) &&
        lastNSDArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            lastGroove1 =
              (Number(lastGroove1) + Number(v?.groove1)) / lastCount1;
            lastCount1++;
          }
          if (!isEmpty(v?.groove2)) {
            lastGroove2 =
              (Number(lastGroove2) + Number(v?.groove2)) / lastCount2;
            lastCount2++;
          }
          if (!isEmpty(v?.groove3)) {
            lastGroove3 =
              (Number(lastGroove3) + Number(v?.groove3)) / lastCount3;
            lastCount3++;
          }
          if (!isEmpty(v?.groove4)) {
            lastGroove4 =
              (Number(lastGroove4) + Number(v?.groove4)) / lastCount4;
            lastCount4++;
          }
        });

      const first_inspection_date = !isEmpty(item?.first_inspection_date)
        ? moment(item?.first_inspection_date).format("DD-MM-yyyy")
        : "-";
      const last_inspection_date = !isEmpty(item?.last_inspection_date)
        ? moment(item?.last_inspection_date).format("DD-MM-yyyy")
        : "-";

      const unresolvedTyreIssue = item?.unresolved_tyre_issues;
      let arr = [];
      unresolvedTyreIssue.map((item) => {
        arr.push(`${item?.issue_name} : ${item?.count}`);
      });

      const unresolvedMechanicleIssue = item?.unresolved_mechanical_issues;
      let arr1 = [];
      unresolvedMechanicleIssue.map((item) => {
        arr1.push(`${item?.issue_name} : ${item?.count}`);
      });

      const resolvedIssues = item?.resolved_issue_list;
      let arr2 = [];
      isArray(resolvedIssues) &&
        resolvedIssues.map((item) => {
          arr2.push(`${item}`);
        });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name || "-",
        "Vehicle No": item?.vehicle_no || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_description || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Tyre Type": item?.tyre_type || "-",
        "Axle Type": item?.axle_type || "-",
        "Axle Position": item?.tyre_position || "-",
        "Standard NSD (mm)": Number(item?.original_nsd) || "-",
        "Tyre Fitment Reading (km)":
          Number(item?.tyre_fitment_km.toString().replaceAll(",", "")) || "-",
        "First Inspection Date": first_inspection_date || "-",

        "First Inspection NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "First Inspection NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "First Inspection NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "First Inspection NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",

        "AVG First Inspection NSD":
          Number(item?.first_inspection_avg_nsd) || "-",
        "First Inspection Reading (km)":
          Number(
            item?.first_inspection_reading_km.toString().replaceAll(",", "")
          ) || "-",
        "Last Inspection Date": last_inspection_date || "-",
        "Last Inspection NSD Groove1 (mm)":
          Number(lastGroove1.toFixed(2)) || "-",
        "Last Inspection NSD Groove2 (mm)":
          Number(lastGroove2.toFixed(2)) || "-",
        "Last Inspection NSD Groove3 (mm)":
          Number(lastGroove3.toFixed(2)) || "-",
        "Last Inspection NSD Groove4 (mm)":
          Number(lastGroove4.toFixed(2)) || "-",

        "AVG Last Inspection  NSD":
          Number(item?.last_inspection_avg_nsd) || "-",
        "Last Inspection Reading (km)":
          Number(
            item?.last_inspection_reading_km.toString().replaceAll(",", "")
          ) || "-",
        Mileage: Number(item?.mileage) || "-",
        "Total Mileage": Number(item?.total_mileage) || "-",
        "Consumed NSD": item?.consumed_nsd || "-",
        "Percentage wear of tyre": item?.percentage_wear_of_tyre || "-",
        "Actual km/mm": item?.km_mm || "-",
        "Unresolved Tyre Issues": arr.toString() || "-",
        "Total Unresolved Issues": Number(item?.unresolved_issues_count) || "-",
        "Resolved Issues": arr2.toString() || "-",
        "Unresolved Mechanical Issues": arr1.toString() || "-",
        "Total Mechanical Issues":
          Number(item?.unresolved_mechanical_issues_count) || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Km_mm Report List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Km_mm Report.xlsx");
  }

  return (
    <>
      {isTablet ? (
        <div className={styles.container} style={{ marginTop: 20 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            style={{ minHeight: 40 }}
            wrap="nowrap"
          >
            <Typography variant="tableTitle" id="jump_to_meV">
              {tabValue === 0 ? "Vehicle Management" : "Reports"}
            </Typography>
            <Grid display="flex" wrap="nowrap" justifyContent={"flex-end"}>
              {tabValue === 0 ? (
                <>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.primary }}
                    onClick={() => {
                      handleClick("vehicle", "");
                    }}
                    disabled={
                      userdata?.role === "fleet_owner" ||
                      userdata?.role === "fleet_manager"
                    }
                  >
                    Add Vehicle
                  </Button>
                  <Button
                    disabled={isOnline ? false : true}
                    variant="contained"
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      getVehicleist(false, 1);
                    }}
                  >
                    {downloadLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      <img src={Images.Mdownload} alt="download" />
                    )}
                  </Button>
                </>
              ) : null}
              <Button
                variant="contained"
                style={{
                  marginLeft: 10,
                  backgroundColor: color.white,
                  color: "#D8D8D8",
                }}
                onClick={(event) => {
                  setAnchorEl(event.currentTarget);
                }}
              >
                <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
              </Button>
              <Popover
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                id={id}
                open={pop}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: isTablet ? "center" : "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ListItemButton
                  selected={tabValue === 0}
                  onClick={(event) => {
                    handlePageNo(1);
                    handleRecord(10);
                    setTabValue(0);
                    setAnchorEl(null);
                    handleFilter("");
                  }}
                >
                  Vehicle Management
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 1}
                  onClick={() => {
                    setTabValue(1);
                    setAnchorEl(null);
                  }}
                >
                  Reports
                </ListItemButton>
              </Popover>
            </Grid>
          </Grid>

          {tabValue === 0 ? (
            <>
              <Grid container style={{ marginTop: 14 }} wrap="nowrap" gap={1}>
                <Grid item container display="flex" wrap="nowrap" gap={1}>
                  <Select
                    placeholder="Filter"
                    value={filter}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        settypeList(false);
                        setbrandList(false);
                        setterrainList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem
                      value={""}
                      hidden
                      selected
                      style={{ minWidth: 220 }}
                    >
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        settypeList(!typeList);
                        setbrandList(false);
                        setterrainList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Vehicle Type</Typography>
                      {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.vehicle_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: typeList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setbrandList(!brandList);
                        settypeList(false);
                        setterrainList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Vehicle Brand</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.vehicle_brand.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: brandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setterrainList(!terrainList);
                        settypeList(false);
                        setbrandList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Terrain</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.terrain.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: brandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getVehicleist(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                          handlePageNo(1);
                          getVehicleist(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search style={{ fontSize: 20 }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Vehicle no, Load Capacity and Fleet Name"
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>

                <Grid item>
                  <Tooltip title="Record" placement="bottom" arrow>
                    <Select
                      value={record}
                      onChange={(v) => {
                        handlePageNo(1);
                        handleRecord(v.target.value);
                      }}
                    >
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={25}>25</MenuItem>
                      <MenuItem value={50}>50</MenuItem>
                      <MenuItem value={100}>100</MenuItem>
                    </Select>
                  </Tooltip>
                </Grid>
              </Grid>
              <Grid
                container
                style={{
                  marginTop: 10,
                }}
                wrap="nowrap"
                gap={1}
              >
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      disableFuture
                      value={datePicker}
                      onChange={(newValue) => {
                        setDatePicker(newValue);
                        setDisable(false);
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{ svg: { color: color.primary } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item display={"flex"} alignItems={"center"}>
                  <Typography>to</Typography>
                </Grid>
                <Grid item>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      disabled={isNull(datePicker) ? true : disable}
                      minDate={datePicker}
                      disableFuture
                      value={datePicker1}
                      inputFormat="dd-MM-yyyy"
                      onChange={(newValue) => {
                        setDatePicker1(newValue);
                      }}
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{
                            svg: {
                              color:
                                disable || isNull(datePicker)
                                  ? ""
                                  : color.primary,
                            },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (
                        !isEmpty(searchFilter) ||
                        (!isNull(datePicker) && !isNull(datePicker1))
                      ) {
                        getVehicleist(false);
                      }
                    }}
                  >
                    <Search />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={!searchFilter && !datePicker && !filter}
                    onClick={resetFilterData}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(vehicleListRows?.data) &&
                !isEmpty(vehicleListRows?.data) ? (
                <>
                  {vehicleListRows?.data?.map((item, index) => {
                    return (
                      <Grid
                        container
                        style={{
                          marginTop: 10,
                          border: `1px solid ${color.bordercolor}`,
                          overflow: "auto",
                        }}
                      >
                        <Table className={styles.customtable}>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">Vehicle No.</TableCell>
                              <TableCell variant="head">ID</TableCell>
                              <TableCell variant="head">No. of Tyres</TableCell>
                              <TableCell variant="head">Vehicle Type</TableCell>
                              <TableCell variant="head">
                                Vehicle Brand
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{item?.vehicle_no || "-"}</TableCell>
                              <TableCell>{item?.id || "-"}</TableCell>
                              <TableCell>{getNoOfTyre(index) || "-"}</TableCell>
                              <TableCell>
                                {getVehicleType(index) || "-"}
                              </TableCell>
                              <TableCell>
                                {getVehicleBrand(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Vehicle Model
                              </TableCell>
                              <TableCell variant="head">
                                Initial Reading
                              </TableCell>
                              <TableCell variant="head">Terrain</TableCell>
                              <TableCell variant="head">
                                Load Capacity
                              </TableCell>
                              <TableCell variant="head">Inspections</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {item?.vehicle_model_name || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.current_reading || "-"} Km
                              </TableCell>
                              <TableCell>{getTerrain(index) || "-"}</TableCell>
                              <TableCell>
                                {item?.load_capicity || "-"} Tonnes
                              </TableCell>
                              <TableCell>
                                <Typography style={{ whiteSpace: "nowrap" }}>
                                  This Month : {item?.inspection_monthly}
                                </Typography>
                                <Typography style={{ whiteSpace: "nowrap" }}>
                                  Overall : {item?.inspection_overall}
                                </Typography>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Driver’s Name
                              </TableCell>
                              <TableCell variant="head">Driver’s No.</TableCell>
                              <TableCell variant="head">
                                Goods Carried
                              </TableCell>
                              <TableCell variant="head">Route Origin</TableCell>
                              <TableCell variant="head">
                                Route Destination
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{item?.driver_name || "-"}</TableCell>
                              <TableCell>{item.driver_phone || "-"}</TableCell>
                              <TableCell>
                                {item?.goods_carried || "-"}
                              </TableCell>
                              <TableCell>{item?.route_origin || "-"}</TableCell>
                              <TableCell>
                                {item?.route_destination || "-"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Alignments Claimed
                              </TableCell>
                              <TableCell variant="head">Added On</TableCell>
                              <TableCell variant="head">Added By</TableCell>
                              <TableCell variant="head" colSpan={2}>
                                Vehicle Status
                              </TableCell>
                            </TableRow>
                            <TableRow rowSpan={2}>
                              <TableCell>
                                {item?.alignment_count + "/5" || "-"}
                              </TableCell>
                              <TableCell>{AddedOn(index) || "-"}</TableCell>
                              <TableCell>{item?.added_by || "-"}</TableCell>
                              <TableCell colSpan={2}>
                                <Switch
                                  checked={item?.status === 1 ? true : false}
                                  onChange={() => {
                                    changeVehicleStatus(item);
                                  }}
                                />
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell
                                colSpan={5}
                                style={{ padding: "10px 20px" }}
                              >
                                <Grid
                                  container
                                  justifyContent="space-between"
                                  alignItems="center"
                                >
                                  <Button
                                    variant="contained"
                                    disabled={
                                      userdata?.role === "fleet_owner" ||
                                      item?.status === 0
                                    }
                                    onClick={() => {
                                      handleClick(
                                        "editVehicle",
                                        vehicleListRows?.data[index]
                                      );
                                    }}
                                  >
                                    <Tooltip
                                      title="Edit"
                                      placement="right"
                                      arrow
                                    >
                                      <Edit />
                                    </Tooltip>
                                  </Button>
                                  <Button
                                    variant="contained"
                                    onClick={() => {
                                      handleClick("vehicleDetails", item);
                                    }}
                                  >
                                    <Visibility />
                                  </Button>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    );
                  })}
                  <div
                    style={{
                      padding: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(vehicleListRows?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={2}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        handlePageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </>
          ) : (
            <>
              {staticArray?.map((item, index) => {
                return (
                  <div style={{ padding: "0px 10px 0px 10px" }}>
                    <Grid
                      container
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      wrap="nowrap"
                      style={{ marginTop: 20, marginBottom: 20 }}
                      gap={1}
                    >
                      <Typography>
                        {item} - {name}
                      </Typography>
                      <Button
                        disabled={
                          isOnline ? false : true || index === 5 ? true : ""
                        }
                        style={{
                          backgroundColor: isOnline
                            ? color.secondary
                            : color.bordercolor,
                        }}
                        onClick={() => {
                          setArrActiveIndex(index) || setVisible(true);
                        }}
                      >
                        {arrActiveIndex === index && arrReportLoader ? (
                          <CircularProgress
                            style={{ color: color.white }}
                            size={24}
                          />
                        ) : (
                          "Download"
                        )}
                      </Button>
                    </Grid>
                    {index === staticArray.length - 1 ? "" : <Divider />}
                  </div>
                );
              })}

              <Divider style={{ marginTop: 10 }} />

              <Grid container id="mainGraph">
                {reportLoader ? (
                  <div className={styles.dataMain}>
                    <CircularProgress style={{ color: color.primary }} />
                  </div>
                ) : isArray(fleetReportData) && !isEmpty(fleetReportData) ? (
                  <Grid
                    id="graph1"
                    container
                    alignItems="center"
                    style={{ maxHeight: 350, marginBottom: 20 }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      style={{ marginBottom: 20, marginTop: 20 }}
                    >
                      <Typography variant="tableTitle">Tyre Issues</Typography>
                      <Button
                        id="btn1"
                        disabled={isOnline ? false : true}
                        variant="contained"
                        style={{
                          backgroundColor: isOnline
                            ? color.secondary
                            : color.bordercolor,
                          marginLeft: "auto",
                          marginRight: 10,
                        }}
                        onClick={() => {
                          auditSave("Tyre Issues Graph");
                          printDocument("graph1", "Tyre Issues", "btn1");
                        }}
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item container>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          overflowX: "scroll",
                        }}
                      >
                        <Chart
                          chartType="Bar"
                          data={fleetReportData}
                          width="1500px"
                          height="92%"
                          options={options}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <div className={styles.dataMain}>
                    <Typography>No Data</Typography>
                  </div>
                )}
                <div style={{ width: "100%" }}>
                  <Divider fullWidth />
                </div>
                {reportLoader ? (
                  <div className={styles.dataMain}>
                    <CircularProgress style={{ color: color.primary }} />
                  </div>
                ) : isArray(mechReportData) && !isEmpty(mechReportData) ? (
                  <Grid
                    id="graph2"
                    container
                    alignItems="center"
                    style={{ maxHeight: 350, marginBottom: 20 }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      style={{ marginBottom: 20, marginTop: 20 }}
                    >
                      <Typography variant="tableTitle">
                        Mechanical Issues
                      </Typography>
                      <Button
                        id="btn2"
                        disabled={isOnline ? false : true}
                        variant="contained"
                        style={{
                          backgroundColor: isOnline
                            ? color.secondary
                            : color.bordercolor,
                          marginLeft: "auto",
                          marginRight: 10,
                        }}
                        onClick={() => {
                          auditSave("Mechanical Issues Graph");
                          printDocument("graph2", "Mechanical Issues", "btn2");
                        }}
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item container>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          overflowX: "scroll",
                        }}
                      >
                        <Chart
                          chartType="Bar"
                          data={mechReportData}
                          width="1500px"
                          height="92%"
                          options={options}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <div className={styles.dataMain}>
                    <Typography>No Data</Typography>
                  </div>
                )}
                <div style={{ width: "100%" }}>
                  <Divider fullWidth />
                </div>
                {fleetHealthDisplay && (
                  <>
                    {reportLoader ? (
                      <div className={styles.dataMain}>
                        <CircularProgress style={{ color: color.primary }} />
                      </div>
                    ) : isArray(fleetHealth) && !isEmpty(fleetHealth) ? (
                      <Grid
                        container
                        alignItems="center"
                        style={{ height: 400, marginBottom: 20, marginTop: 20 }}
                      >
                        <Grid item container>
                          <Button
                            disabled={isOnline ? false : true}
                            variant="contained"
                            style={{
                              backgroundColor: isOnline
                                ? color.secondary
                                : color.bordercolor,
                              marginLeft: "auto",
                              marginRight: 10,
                            }}
                            onClick={() => {
                              auditSave("Fleet Health Graph");
                              printDocument("piegraph1", "Fleet Health");
                            }}
                          >
                            Download
                          </Button>
                        </Grid>
                        <Grid
                          item
                          container
                          id="piegraph1"
                          flexDirection={"column"}
                        >
                          <Grid item>
                            <Typography variant="tableTitle">
                              Fleet Health
                            </Typography>
                          </Grid>
                          <Chart
                            chartType="PieChart"
                            data={fleetHealth}
                            height="300px"
                            width="100%"
                            options={{
                              title: "",
                              pieHole: 0.4,
                              legend: { position: "none" },
                              is3D: false,
                              slices: {
                                0: { color: "#EB5757" },
                                1: { color: "#F2C94C" },
                                2: { color: "#27AE60" },
                              },
                            }}
                          />
                          <Grid item container justifyContent="center">
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              fontWeight="bold"
                            >
                              <SquareRoundedIcon style={{ color: "#EB5757" }} />
                              Major Issues
                            </Typography>
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: 8,
                                paddingRight: 8,
                              }}
                              fontWeight="bold"
                            >
                              <SquareRoundedIcon style={{ color: "#F2C94C" }} />
                              Minor Issues
                            </Typography>
                            <Typography
                              style={{
                                alignItems: "center",
                                display: "flex",
                              }}
                              fontWeight="bold"
                            >
                              <SquareRoundedIcon style={{ color: "#27AE60" }} />
                              No Issues
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <div className={styles.dataMain}>
                        <Typography>No Data</Typography>
                      </div>
                    )}
                  </>
                )}
              </Grid>

              <Grid
                container
                style={{ minHeight: 400, marginBottom: 20, marginTop: 20 }}
                alignItems="center"
              >
                {(!isArray(tyrePieData) || isEmpty(tyrePieData)) &&
                (!isArray(mechPieData) || isEmpty(mechPieData)) ? null : (
                  <Grid item container>
                    <Button
                      disabled={isOnline ? false : true}
                      variant="contained"
                      style={{
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                        marginLeft: "auto",
                        marginBottom: 20,
                        marginRight: 10,
                      }}
                      onClick={() => {
                        auditSave("Fleet Issues Graph");
                        printDocument("piegraph2", "Fleet Issues");
                      }}
                    >
                      Download
                    </Button>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  id="piegraph2"
                  display="flex"
                  flexDirection="column"
                >
                  <Grid
                    item
                    container
                    flexDirection={"column"}
                    xs={6}
                    style={{ flex: 1 }}
                  >
                    <Grid item>
                      <Typography variant="tableTitle">Tyre Issues</Typography>
                    </Grid>
                    {reportLoader1 ? (
                      <div className={styles.dataMain}>
                        <CircularProgress style={{ color: color.primary }} />
                      </div>
                    ) : isArray(tyrePieData) && !isEmpty(tyrePieData) ? (
                      <Grid item container>
                        <Chart
                          chartType="PieChart"
                          data={tyrePieData}
                          height="300px"
                          width="100%"
                          options={{
                            title: "",
                            pieHole: 0.4,
                            legend: { position: "none" },
                            is3D: false,
                            slices: {
                              0: { color: "#EB5757" },
                              1: { color: "#27AE60" },
                              2: { color: "#F2C94C" },
                            },
                          }}
                        />
                      </Grid>
                    ) : (
                      <div className={styles.dataMain}>
                        <Typography>No Data</Typography>
                      </div>
                    )}
                  </Grid>

                  <Grid
                    item
                    container
                    flexDirection={"column"}
                    xs={6}
                    style={{ flex: 1 }}
                  >
                    <Grid item>
                      <Typography variant="tableTitle">
                        Mechanical Issues
                      </Typography>
                    </Grid>
                    {reportLoader2 ? (
                      <div className={styles.dataMain}>
                        <CircularProgress style={{ color: color.primary }} />
                      </div>
                    ) : isArray(mechPieData) && !isEmpty(mechPieData) ? (
                      <Grid item container>
                        <Chart
                          chartType="PieChart"
                          data={mechPieData}
                          height="300px"
                          width="100%"
                          options={{
                            title: "",
                            pieHole: 0.4,
                            legend: { position: "none" },
                            is3D: false,
                            slices: {
                              0: { color: "#EB5757" },
                              1: { color: "#27AE60" },
                              2: { color: "#F2C94C" },
                            },
                          }}
                        />
                      </Grid>
                    ) : (
                      <div className={styles.dataMain}>
                        <Typography>No Data</Typography>
                      </div>
                    )}
                  </Grid>
                  <Grid item container justifyContent="center">
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      fontWeight="bold"
                    >
                      <SquareRoundedIcon style={{ color: "#EB5757" }} />
                      Unresolved Issues
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        paddingLeft: 8,
                        paddingRight: 8,
                        alignItems: "center",
                      }}
                      fontWeight="bold"
                    >
                      <SquareRoundedIcon style={{ color: "#F2C94C" }} />
                      Partially Resolved Issues
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      fontWeight="bold"
                    >
                      <SquareRoundedIcon style={{ color: "#27AE60" }} />
                      Resolved Issues
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      ) : (
        <div className={styles.container} style={{ marginTop: 10 }}>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item xs={5}>
              <Typography wrap="wrap" variant="tableTitle" id="jump_to_meV">
                {tabValue === 0 ? "Vehicle Management" : "Reports"}
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              display="flex"
              wrap="nowrap"
              justifyContent={"flex-end"}
            >
              {tabValue === 0 ? (
                <>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.primary }}
                    onClick={() => {
                      handleClick("vehicle", "");
                    }}
                    disabled={
                      userdata?.role === "fleet_owner" ||
                      userdata?.role === "fleet_manager"
                    }
                  >
                    Add Vehicle
                  </Button>
                  <Button
                    disabled={isOnline ? false : true}
                    variant="contained"
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginLeft: 10,
                    }}
                    onClick={() => {
                      getVehicleist(false, 1);
                    }}
                  >
                    {downloadLoader ? (
                      <CircularProgress
                        style={{ color: color.white }}
                        size={24}
                      />
                    ) : (
                      <img src={Images.Mdownload} alt="download" />
                    )}
                  </Button>
                </>
              ) : null}
              <Tooltip title="Tabs" placement="bottom" arrow>
                <Button
                  style={{
                    marginLeft: 10,
                    backgroundColor: "#BBBBBB",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVertOutlinedIcon style={{ color: color.black }} />
                </Button>
              </Tooltip>
              <Popover
                className="popnoti"
                onClose={() => setAnchorEl(null)}
                anchorEl={anchorEl}
                id={id}
                open={pop}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <ListItemButton
                  selected={tabValue === 0}
                  onClick={() => {
                    handlePageNo(1);
                    handleRecord(10);
                    setAnchorEl(null);
                    setTabValue(0);
                    handleFilter("");
                  }}
                >
                  Vehicle Management
                </ListItemButton>
                <ListItemButton
                  selected={tabValue === 1}
                  onClick={() => {
                    setAnchorEl(null);
                    setTabValue(1);
                  }}
                >
                  Report
                </ListItemButton>
              </Popover>
            </Grid>
          </Grid>
          {tabValue === 0 ? (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center"
                style={{ marginTop: 10 }}
                wrap="nowrap"
              >
                <Grid item>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={filter}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        settypeList(false);
                        setbrandList(false);
                        setterrainList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem
                      value={""}
                      hidden
                      selected
                      style={{ minWidth: 220 }}
                    >
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        settypeList(!typeList);
                        setbrandList(false);
                        setterrainList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Vehicle Type</Typography>
                      {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.vehicle_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: typeList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setbrandList(!brandList);
                        settypeList(false);
                        setterrainList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Vehicle Brand</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.vehicle_brand.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: brandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setterrainList(!terrainList);
                        settypeList(false);
                        setbrandList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Terrain</Typography>
                      {terrainList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.terrain.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: terrainList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Select
                    fullWidth
                    value={record}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleRecord(v.target.value);
                    }}
                    style={{ marginLeft: 10 }}
                  >
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={25}>25</MenuItem>
                    <MenuItem value={50}>50</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </Grid>
              </Grid>
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                      setDisable(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(datePicker) ? true : disable}
                    minDate={datePicker}
                    disableFuture
                    value={datePicker1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      setDatePicker1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              disable || isNull(datePicker)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: 10,
                }}
                wrap="nowrap"
              >
                <Grid item xs={10}>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getVehicleist(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                          handlePageNo(1);
                          getVehicleist(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Search style={{ fontSize: 20 }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Vehicle no, Load Capacity and Fleet Name"
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item display="flex" xs={2} wrap="nowrap">
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary, marginLeft: 10 }}
                    onClick={() => {
                      if (
                        !isEmpty(searchFilter) ||
                        (!isNull(datePicker) && !isNull(datePicker1))
                      ) {
                        getVehicleist(false);
                      }
                    }}
                  >
                    <Search />
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    disabled={!searchFilter && !datePicker && !filter}
                    onClick={resetFilterData}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>
              {pageLoad ? (
                <div className={styles.dataMain}>
                  <CircularProgress style={{ color: color.primary }} />
                </div>
              ) : isArray(vehicleListRows?.data) &&
                !isEmpty(vehicleListRows?.data) ? (
                <>
                  {vehicleListRows?.data?.map((item, index) => {
                    return (
                      <div
                        style={{
                          position: "relative",
                        }}
                      >
                        <Grid
                          item
                          container
                          id={`jump_to_meV${index}`}
                          style={{
                            marginTop: 20,
                            border: `1px solid ${color.bordercolor}`,
                            alignItems: "center",
                            overflow: "hidden",
                            boxShadow: color.shadow,
                          }}
                        >
                          {activeIndex !== index ? (
                            <Table className={styles.customtableMobile}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle No.
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle Brand
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_no || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getVehicleBrand(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    No. of Tyres
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle Model
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getNoOfTyre(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_model_name || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </Table>
                          ) : (
                            <Table className={styles.customtableMobile}>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle No.
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle Brand
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_no || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getVehicleBrand(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    No. of Tyres
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle Model
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getNoOfTyre(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_model_name || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle Type
                                  </TableCell>
                                  <TableCell variant="head">
                                    Initial Reading
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getVehicleType(index) || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.current_reading || "-"} Km
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Load Capacity
                                  </TableCell>
                                  <TableCell variant="head">Terrain</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.load_capicity || "-"}
                                    {item?.load_capicity ? " Tonnes" : ""}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getTerrain(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Goods Carried
                                  </TableCell>
                                  <TableCell variant="head">
                                    Inspections Performed
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.goods_carried || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    <Typography>
                                      This Month : {item?.inspection_monthly}
                                    </Typography>
                                    <Typography>
                                      Overall : {item?.inspection_overall}
                                    </Typography>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Driver’s Name
                                  </TableCell>
                                  <TableCell variant="head">
                                    Driver’s No.
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.driver_name || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item.driver_phone || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Route Origin
                                  </TableCell>
                                  <TableCell variant="head">
                                    Route Destination
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.route_origin || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.route_destination || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Alignments Claimed
                                  </TableCell>
                                  <TableCell variant="head" colSpan={2}>
                                    Added On
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.alignment_count || "-"}/5
                                  </TableCell>
                                  <TableCell
                                    colSpan={2}
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {AddedOn(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">Added by</TableCell>
                                  <TableCell variant="head" colSpan={2}>
                                    Vehicle Status
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.added_by || "-"}
                                  </TableCell>
                                  <TableCell
                                    colSpan={2}
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    <Switch
                                      checked={
                                        item?.status === 1 ? true : false
                                      }
                                      onChange={() => {
                                        changeVehicleStatus(item);
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableCell
                                  style={{ padding: "10px 0px 10px 20px" }}
                                >
                                  <Button
                                    disabled={
                                      userdata?.role === "fleet_owner" ||
                                      item?.status === 0
                                    }
                                    variant="contained"
                                    onClick={() => {
                                      handleClick(
                                        "editVehicle",
                                        vehicleListRows?.data[index]
                                      );
                                    }}
                                  >
                                    {
                                      <Tooltip
                                        title="Edit"
                                        placement="right"
                                        arrow
                                      >
                                        <Edit />
                                      </Tooltip>
                                    }
                                  </Button>
                                </TableCell>
                                <TableCell
                                  rowSpan={2}
                                  align="right"
                                  style={{ padding: "10px 20px 10px 0px" }}
                                >
                                  <Button
                                    style={{
                                      backgroundColor: color.primary,
                                    }}
                                    onClick={() =>
                                      handleClick("vehicleDetails", item)
                                    }
                                  >
                                    <Visibility />
                                  </Button>
                                </TableCell>
                              </TableHead>
                            </Table>
                          )}
                          <div
                            onClick={(v) => {
                              if (activeIndex === index) {
                                setActiveIndex(null);
                              } else {
                                setActiveIndex(index);
                              }
                            }}
                            style={{
                              position: "absolute",
                              bottom: -18,
                              left: "45%",
                            }}
                          >
                            {activeIndex === index ? (
                              <div style={{ transform: "rotate(180deg)" }}>
                                <TableToggle />
                              </div>
                            ) : (
                              <TableToggle />
                            )}
                          </div>
                        </Grid>
                      </div>
                    );
                  })}
                  <div
                    style={{
                      marginTop: 20,
                      marginBottom: 20,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Pagination
                      count={Math.ceil(vehicleListRows?.pagination?.totalPage)}
                      defaultPage={pageNo}
                      boundaryCount={1}
                      siblingCount={0}
                      variant="outlined"
                      shape="rounded"
                      onChange={(v, e) => {
                        handlePageNo(e);
                      }}
                    />
                  </div>
                </>
              ) : (
                <div className={styles.dataMain}>
                  <Typography>No Data</Typography>
                </div>
              )}
            </>
          ) : (
            <>
              {staticArray?.map((item, index) => {
                return (
                  <div>
                    <Grid
                      container
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                      wrap="nowrap"
                      style={{ marginTop: 20, marginBottom: 20 }}
                      gap={1}
                    >
                      <Typography>
                        {item} - {name}
                      </Typography>
                      <Button
                        disabled={
                          isOnline ? false : true || index === 5 ? true : ""
                        }
                        style={{
                          backgroundColor: isOnline
                            ? color.secondary
                            : color.bordercolor,
                        }}
                        onClick={() => {
                          setArrActiveIndex(index) || setVisible(true);
                        }}
                      >
                        {arrActiveIndex === index && arrReportLoader ? (
                          <CircularProgress
                            style={{ color: color.white }}
                            size={24}
                          />
                        ) : (
                          "Download"
                        )}
                      </Button>
                    </Grid>
                    {index === staticArray.length - 1 ? "" : <Divider />}
                  </div>
                );
              })}

              <Divider style={{ marginTop: 10 }} />

              <Grid container id="mainGraph">
                {reportLoader ? (
                  <div className={styles.dataMain}>
                    <CircularProgress style={{ color: color.primary }} />
                  </div>
                ) : isArray(fleetReportData) && !isEmpty(fleetReportData) ? (
                  <Grid
                    id="graph1"
                    container
                    alignItems="center"
                    style={{ maxHeight: 350, marginBottom: 20 }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      wrap="nowrap"
                      gap={1}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      <Typography variant="tableTitle">Tyre Issues</Typography>
                      <Button
                        id="btn1"
                        disabled={isOnline ? false : true}
                        variant="contained"
                        style={{
                          backgroundColor: isOnline
                            ? color.secondary
                            : color.bordercolor,
                          marginLeft: "auto",
                        }}
                        onClick={() => {
                          auditSave("Tyre Issues Graph");
                          printDocument("graph1", "Tyre Issues", "btn1");
                        }}
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item container>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          overflowX: "scroll",
                        }}
                      >
                        <Chart
                          chartType="Bar"
                          data={fleetReportData}
                          width="1500px"
                          height="92%"
                          options={options}
                        />
                      </div>
                    </Grid>
                  </Grid>
                ) : (
                  <div className={styles.dataMain}>
                    <Typography>No Data</Typography>
                  </div>
                )}
                <div style={{ width: "100%" }}>
                  <Divider fullWidth />
                </div>
                {reportLoader ? (
                  <div className={styles.dataMain}>
                    <CircularProgress style={{ color: color.primary }} />
                  </div>
                ) : isArray(mechReportData) && !isEmpty(mechReportData) ? (
                  <Grid
                    id="graph2"
                    container
                    alignItems="center"
                    style={{ maxHeight: 350, marginBottom: 20 }}
                  >
                    <Grid
                      item
                      container
                      alignItems="center"
                      wrap="nowrap"
                      gap={1}
                      style={{ marginTop: 20, marginBottom: 20 }}
                    >
                      <Typography variant="tableTitle">
                        Mechanical Issues
                      </Typography>
                      <Button
                        id="btn2"
                        disabled={isOnline ? false : true}
                        variant="contained"
                        style={{
                          backgroundColor: isOnline
                            ? color.secondary
                            : color.bordercolor,
                          marginLeft: "auto",
                        }}
                        onClick={() => {
                          auditSave("Mechanical Issues Graph");
                          printDocument("graph2", "Mechanical Issues", "btn2");
                        }}
                      >
                        Download
                      </Button>
                    </Grid>
                    <Grid item container>
                      <div
                        style={{
                          width: "100%",
                          height: "100%",
                          overflowX: "scroll",
                        }}
                      >
                        <Chart
                          chartType="Bar"
                          data={mechReportData}
                          width="1500px"
                          height="92%"
                          options={options}
                        />
                      </div>
                    </Grid>
                    <Grid item xs={4}></Grid>
                  </Grid>
                ) : (
                  <div className={styles.dataMain}>
                    <Typography>No Data</Typography>
                  </div>
                )}
                <div style={{ width: "100%" }}>
                  <Divider fullWidth />
                </div>
                {fleetHealthDisplay && (
                  <>
                    {reportLoader ? (
                      <div className={styles.dataMain}>
                        <CircularProgress style={{ color: color.primary }} />
                      </div>
                    ) : isArray(fleetHealth) && !isEmpty(fleetHealth) ? (
                      <Grid
                        container
                        alignItems="center"
                        style={{ height: 400, marginBottom: 20, marginTop: 20 }}
                      >
                        <Grid item container>
                          <Button
                            disabled={isOnline ? false : true}
                            variant="contained"
                            style={{
                              backgroundColor: isOnline
                                ? color.secondary
                                : color.bordercolor,
                              marginLeft: "auto",
                              marginRight: 10,
                            }}
                            onClick={() => {
                              auditSave("Fleet Health Graph");
                              printDocument("piegraph1", "Fleet Health");
                            }}
                          >
                            Download
                          </Button>
                        </Grid>
                        <Grid
                          item
                          container
                          id="piegraph1"
                          flexDirection={"column"}
                        >
                          <Grid item>
                            <Typography variant="tableTitle">
                              Fleet Health
                            </Typography>
                          </Grid>

                          <Chart
                            chartType="PieChart"
                            data={fleetHealth}
                            height="300px"
                            width="100%"
                            options={{
                              title: "",
                              pieHole: 0.4,
                              legend: { position: "none" },
                              is3D: false,
                              slices: {
                                0: { color: "#EB5757" },
                                1: { color: "#F2C94C" },
                                2: { color: "#27AE60" },
                              },
                            }}
                          />
                          <Grid item container justifyContent="center">
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              fontWeight="bold"
                            >
                              <SquareRoundedIcon style={{ color: "#EB5757" }} />
                              Major Issues
                            </Typography>
                            <Typography
                              style={{
                                display: "flex",
                                paddingLeft: 8,
                                paddingRight: 8,
                                alignItems: "center",
                              }}
                              fontWeight="bold"
                            >
                              <SquareRoundedIcon style={{ color: "#F2C94C" }} />
                              Minor Issues
                            </Typography>
                            <Typography
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                              fontWeight="bold"
                            >
                              <SquareRoundedIcon style={{ color: "#27AE60" }} />
                              No Issues
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                    ) : (
                      <div className={styles.dataMain}>
                        <Typography>No Data</Typography>
                      </div>
                    )}
                  </>
                )}
              </Grid>
              <Divider />
              <Grid
                container
                style={{ minHeight: 400, marginBottom: 20, marginTop: 20 }}
                alignItems="center"
              >
                {(!isArray(tyrePieData) || isEmpty(tyrePieData)) &&
                (!isArray(mechPieData) || isEmpty(mechPieData)) ? null : (
                  <Grid item container>
                    <Button
                      disabled={isOnline ? false : true}
                      variant="contained"
                      style={{
                        backgroundColor: isOnline
                          ? color.secondary
                          : color.bordercolor,
                        marginLeft: "auto",
                        marginBottom: 20,
                      }}
                      onClick={() => {
                        auditSave("Fleet Issues Graph");
                        printDocument("piegraph2", "Fleet Issues");
                      }}
                    >
                      Download
                    </Button>
                  </Grid>
                )}
                <Grid
                  item
                  container
                  id="piegraph2"
                  display="flex"
                  flexDirection="column"
                >
                  <Grid item container flexDirection={"column"} xs={6}>
                    <Grid item>
                      <Typography variant="tableTitle">Tyre Issues</Typography>
                    </Grid>
                    {reportLoader1 ? (
                      <div className={styles.dataMain}>
                        <CircularProgress style={{ color: color.primary }} />
                      </div>
                    ) : isArray(tyrePieData) && !isEmpty(tyrePieData) ? (
                      <Grid container item>
                        <Chart
                          chartType="PieChart"
                          data={tyrePieData}
                          height="300px"
                          width="100%"
                          options={{
                            title: "",
                            pieHole: 0.4,
                            legend: { position: "none" },
                            is3D: false,
                            slices: {
                              0: { color: "#EB5757" },
                              1: { color: "#27AE60" },
                              2: { color: "#F2C94C" },
                            },
                          }}
                        />
                      </Grid>
                    ) : (
                      <div className={styles.dataMain}>
                        <Typography>No Data</Typography>
                      </div>
                    )}
                  </Grid>
                  <Grid item container flexDirection={"column"} xs={6}>
                    <Grid item>
                      <Typography variant="tableTitle">
                        Mechanical Issues
                      </Typography>
                    </Grid>
                    {reportLoader2 ? (
                      <div className={styles.dataMain}>
                        <CircularProgress style={{ color: color.primary }} />
                      </div>
                    ) : isArray(mechPieData) && !isEmpty(mechPieData) ? (
                      <Grid container item>
                        <Chart
                          chartType="PieChart"
                          data={mechPieData}
                          height="300px"
                          width="100%"
                          options={{
                            title: "",
                            pieHole: 0.4,
                            legend: { position: "none" },
                            is3D: false,
                            slices: {
                              0: { color: "#EB5757" },
                              1: { color: "#27AE60" },
                              2: { color: "#F2C94C" },
                            },
                          }}
                        />
                      </Grid>
                    ) : (
                      <div className={styles.dataMain}>
                        <Typography>No Data</Typography>
                      </div>
                    )}
                  </Grid>

                  <Grid item container justifyContent="center">
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      fontWeight="bold"
                    >
                      <SquareRoundedIcon style={{ color: "#EB5757" }} />
                      Unresolved Issues
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        paddingLeft: 8,
                        paddingRight: 8,
                        alignItems: "center",
                      }}
                      fontWeight="bold"
                    >
                      <SquareRoundedIcon style={{ color: "#F2C94C" }} />
                      Partially Resolved Issues
                    </Typography>
                    <Typography
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      fontWeight="bold"
                    >
                      <SquareRoundedIcon style={{ color: "#27AE60" }} />
                      Resolved Issues
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </div>
      )}

      <MDateRange
        disableFleet
        visible={visible}
        fleetId={fleetData?.id}
        disableDate={arrActiveIndex === 2}
        handleModal={(type, fleetIds, vehicleIds, fromDate, toDate, isAll) => {
          if (type === "download") {
            if (staticArray[arrActiveIndex] === "Tyre Mileage Report") {
              getMileageReport(
                fleetIds,
                vehicleIds,
                fromDate,
                toDate,
                isAll,
                true
              );
              setMileageDates({ fromDate: fromDate, toDate: toDate });
            } else if (
              staticArray[arrActiveIndex] === "Tyre Inspection Report"
            ) {
              getinspectionreport(
                fleetIds,
                vehicleIds,
                fromDate,
                toDate,
                isAll,
                true
              );
              setMileageDates({ fromDate: fromDate, toDate: toDate });
              setVehicleIds(vehicleIds);
            } else if (staticArray[arrActiveIndex] === "Km/mm Report") {
              getKmMmReport(vehicleIds, isAll, true);
              setVehicleIds(vehicleIds);
            } else {
              downloadReports5();
            }
          }
          setVisible(false);
        }}
      />
    </>
  );
}
