import React, { useEffect, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import { color } from "../../../Config/theme.js";
import { Delete, Edit, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ConfirmDialog from "../../ConfirmDialog";
import InfoIcon from "@mui/icons-material/Info";
import Images from "../../../Config/Images";
import moment from "moment";

export default function ItemDetails(props) {
  const {
    handleClick = () => null,
    handlePageNoIL = () => null,
    handlePageNoPR = () => null,
    handleRecordIL = () => null,
    handleRecordPR = () => null,
    handleSearch = () => null,
    search = "",
    pageNoIL = 1,
    pageNoPR = 1,
    recordIL = 10,
    recordPR = 10,
    handleTab = () => null,
    tabValue = 0,
  } = props;
  const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  const [editData, setEditData] = useState({});
  const [masterListRows, setMasterListRows] = useState({});
  const { isOnline } = useSelector((state) => state.auth);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  const [selectedFile, setSlectedFile] = useState(null);

  const [confirmDialog1, setConfirmDialog1] = useState(false);
  const [removeBtnLoad1, setRemoveBtnLoad1] = useState(false);
  const styles = useStyles();
  const theme = useTheme();

  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [reportLoader, setReportLoader] = useState(false);

  const { token, dropDownData, userdata } = useSelector((state) => state.auth);

  const UnresolvedIssueColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          pageNoIL === 1 ? index : (pageNoIL - 1) * recordIL + index;

        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title="Edit" placement="right" arrow>
            <Edit
              style={{ color: color.primary, cursor: "pointer" }}
              onClick={() => {
                handleClick("EditItemMaster", params?.row);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "brand_name",
      headerName: "Tyre Brand",
      width: 200,
    },
    {
      field: "tyre_pattern_size",
      headerName: "Item Description",
      width: 300,
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 180,
    },
    {
      field: "tread_pattern",
      headerName: "Tread Pattern",
      width: 200,
    },
    {
      field: "ply_rating",
      headerName: "Ply Rating",
      width: 180,
    },
    {
      field: "nsd",
      headerName: "NSD",
      width: 200,
      renderCell: (params) => {
        return <Typography>{`${params?.row?.nsd} mm` || "-"}</Typography>;
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      width: 200,
    },

    {
      field: "Delete",
      headerAlign: "center",
      headerName: "Delete",
      width: 80,
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Delete
            style={{ color: "red", cursor: "pointer" }}
            onClick={() => {
              setEditData(params?.row);
              setConfirmDialog(true);
            }}
          />
        );
      },
    },
  ];

  const pendingColumns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          pageNoPR === 1 ? index : (pageNoPR - 1) * recordPR + index;

        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "new_request",
      headerName: "New Request",
      width: md ? 220 : "unset",
      flex: !md ? 1 : "unset",
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 180,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              handleClick("tyreDetails", params?.row);
            }}
          >
            <Typography
              style={{
                whiteSpace: "pre-wrap",
                color: color.primary,
                fontWeight: "bold",
              }}
            >
              {params?.row?.tyre_serial_no}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "requested_by",
      headerName: "Requested By",
      width: md ? 220 : "unset",
      flex: !md ? 1 : "unset",
    },
    {
      field: "requested_at",
      headerName: "Requested At",
      width: md ? 220 : "unset",
      flex: !md ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.requested_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.requested_at ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    masterList(false);
  }, [pageNoIL, recordIL, filter]);

  useEffect(() => {
    masterList(false);
  }, [pageNoPR, recordPR, filter]);

  useEffect(() => {
    // clearData();
    masterList(false);
  }, [tabValue]);

  useEffect(() => {
    isEmpty(search) && masterList(false);
  }, [search]);

  useEffect(() => {
    filter === "" && isEmpty(search) && masterList(false);
  }, [filter, search]);

  function resetFilterData() {
    setFilter("");
    handleSearch("");
  }

  async function deleteItem() {
    setRemoveBtnLoad(true);
    let endPoints = `${Setting.endpoints.deleteitem}?id=${editData?.id}`;
    try {
      const response = await getApiData(
        endPoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setConfirmDialog(false);
        setEditData({});
        masterList();
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Item Master`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function masterList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    try {
      const response = await getApiData(
        `${
          tabValue === 0
            ? Setting.endpoints.itemmasterList
            : Setting.endpoints.pendingrequest
        }?page=${
          tabValue === 0 ? (bool ? 1 : pageNoIL) : bool ? 1 : pageNoPR
        }&per_page=${
          tabValue === 0 ? (bool ? 10 : recordIL) : bool ? 10 : recordPR
        }&search=${bool ? "" : search.replaceAll("+", "%2B")}&filter=${
          bool ? "" : filter
        }&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            tabValue === 1
              ? downloadPendingRequest(response?.data?.rows)
              : downloadItemList(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setMasterListRows(listObj);
          }
        }
        v && auditSave(tabValue === 1 ? "Pending Request List" : "Item List");
      } else {
        setMasterListRows({});
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      setReportLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const handleOnSubmit = async () => {
    setRemoveBtnLoad1(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.importmaster;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          masterList();
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad1(false);
        setConfirmDialog1(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad1(false);
        setConfirmDialog1(false);
        toast.error(error.message.toString());
      }
    }
  };

  function clearData() {
    setSlectedFile(null);
    handleSearch("");
    setFilter("");
  }

  function downloadItemList(data) {
    const newData = data.map((item, index) => {
      let tyreBrand =
        !isNull(item?.tyre_brand_id) &&
        dropDownData?.tyre_brand?.find(
          (v) => item?.tyre_brand_id?.toString() === v?.id?.toString()
        );
      const newObj = {
        "Sr. No.": index + 1,
        ID: item?.id || "-",
        "Tyre Brand": tyreBrand?.label || "-",
        "Item Description": item?.tyre_pattern_size || "-",
        "Tyre Composition": item?.tyre_composition || "-",
        "Tread Pattern": item?.tread_pattern || "-",
        "Ply Rating": item?.ply_rating || "-",
        "Added By": item?.added_by || "-",
        "NSD (mm)": Number(item?.nsd) || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Item List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Item List.xlsx");
  }

  function downloadPendingRequest(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.requested_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "New Request": item?.new_request || "-",
        "Tyre Serial No.": item?.tyre_serial_no || "-",
        "Requested By": item?.requested_by || "-",
        "Requested At": item?.requested_at ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Pending Request List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Pending Request List.xlsx");
  }

  return (
    <div className={styles.container}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="tableTitle">Item Master</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: sm ? "unset" : "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              handleClick("AddItemMaster", "");
            }}
          >
            Add Item
          </Button>
          <Button
            variant="contained"
            component="label"
            disabled={isOnline ? false : true || reportLoader}
            style={{
              marginLeft: 10,
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
            onClick={() => {
              masterList(false, 1);
            }}
          >
            {reportLoader ? (
              <CircularProgress style={{ color: color.white }} size={24} />
            ) : (
              "Download"
            )}
          </Button>
          <Button
            variant="contained"
            component="label"
            style={{ marginLeft: 10, backgroundColor: color.secondary }}
          >
            <input
              type="file"
              onChange={(e) => {
                setSlectedFile(e.target.files[0]);
                setConfirmDialog1(true);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              accept=".csv"
              hidden
            />
            Upload CSV
          </Button>
          <Button
            variant="contained"
            component="label"
            disabled={isOnline ? false : true}
            style={{
              marginLeft: 10,
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
          >
            <a
              style={{ textDecoration: "none", color: "white" }}
              href={Images.itemMastercsv}
              download={"itemsMaster.csv"}
            >
              Download Template
            </a>
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
        <Tabs
          value={tabValue}
          onChange={(v, b) => {
            handlePageNoIL(1);
            handleRecordIL(10);
            handlePageNoPR(1);
            handleRecordPR(10);
            handleTab(b);
            setSlectedFile(null);
            handleSearch("");
            setFilter("");
          }}
          variant="scrollable"
        >
          <Tab className={styles.tabBtn} label="Item List" />
          <Tab className={styles.tabBtn} label="Pending Request" />
        </Tabs>
      </Box>
      <div style={{ paddingTop: 14 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid
            item
            lg={10.4}
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <Grid item lg={1.6}>
              <Select
                fullWidth
                placeholder="Filter"
                value={filter}
                onChange={(v) => {
                  setPageNo(1);
                  setFilter(v.target.value);
                }}
                displayEmpty
                IconComponent={FilterAlt}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
                classes={{
                  iconOpen: styles.iconOpen,
                }}
              >
                <MenuItem value={""} disabled hidden selected>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    settyreBrandList(!tyreBrandList);
                    settyreCompositionList(false);
                    setPlyRating(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Tyre Brand</Typography>
                  {tyreBrandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {tyreBrandList &&
                  dropDownData.tyre_brand.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                <ListItemButton
                  onClick={() => {
                    setPlyRating(!plyRating);
                    settyreBrandList(false);
                    settyreCompositionList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Ply Rating</Typography>
                  {plyRating ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {plyRating &&
                  dropDownData.ply_rating.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                <ListItemButton
                  onClick={() => {
                    settyreCompositionList(!tyreCompositionList);
                    settyreBrandList(false);
                    setPlyRating(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Tyre Composition</Typography>
                  {tyreCompositionList ? (
                    <ExpandLessIcon />
                  ) : (
                    <ExpandMoreIcon />
                  )}
                </ListItemButton>
                {tyreCompositionList &&
                  dropDownData.tyre_composition.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    );
                  })}
              </Select>
            </Grid> */}
            <Grid item lg={4}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && masterList(true);
                  handleSearch(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(search)) {
                      tabValue === 0 ? handlePageNoIL(1) : handlePageNoPR(1);
                      masterList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title={
                            tabValue === 0
                              ? "Search by Item Description"
                              : "Search by New  Request"
                          }
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item style={{ marginLeft: 20 }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(search)) {
                    tabValue === 0 ? handlePageNoIL(1) : handlePageNoPR(1);
                    masterList(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={filter === "" && isEmpty(search) ? true : false}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          <Grid
            item
            lg={1.6}
            style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
          >
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            {tabValue === 0 ? (
              <Select
                fullWidth
                value={recordIL}
                onChange={(v) => {
                  handlePageNoIL(1);
                  handleRecordIL(v.target.value);
                }}
                style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            ) : (
              <Select
                fullWidth
                value={recordPR}
                onChange={(v) => {
                  handlePageNoPR(1);
                  handleRecordPR(v.target.value);
                }}
                style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            )}
          </Grid>
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(masterListRows?.data) && !isEmpty(masterListRows?.data) ? (
          <>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                rows={masterListRows?.data}
                columns={
                  tabValue === 0 ? UnresolvedIssueColumns : pendingColumns
                }
                disableColumnMenu
                hideFooter
                showCellRightBorder
                disableSelectionOnClick
                showColumnRightBorder
                autoHeight={true}
                onCellClick={(params) => {
                  if (tabValue === 1 && params?.field !== "tyre_serial_no") {
                    handleClick("addTyre", params?.row);
                  }
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {tabValue === 0 ? (
                <Pagination
                  count={Math.ceil(masterListRows?.pagination?.totalPage)}
                  defaultPage={pageNoIL}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNoIL(e);
                  }}
                />
              ) : (
                <Pagination
                  count={Math.ceil(masterListRows?.pagination?.totalPage)}
                  defaultPage={pageNoPR}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNoPR(e);
                  }}
                />
              )}
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
      <ConfirmDialog
        title={`Are you sure you want to Delete this Item Master?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteItem();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
      <ConfirmDialog
        title={`Are you sure you want to Upload CSV?`}
        visible={confirmDialog1}
        handleModal={(bool) => {
          if (bool) {
            handleOnSubmit();
          } else {
            setConfirmDialog1(false);
            clearData();
          }
        }}
        btnLoad={removeBtnLoad1}
      />
    </div>
  );
}
