import React, { useEffect, useState } from "react";
import "./styles.js";
import { color } from "../../../Config/theme.js";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Typography,
  Grid,
  TextField,
  Button,
  CircularProgress,
  Box,
} from "@mui/material";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { getApiData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { getAPIProgressData } from "../../../Utils/APIHelper.js";
import Images from "../../../Config/Images.js";
import ConfirmDialog from "../../../Components/ConfirmDialog/index.js";
import { isTablet } from "react-device-detect";

const errorObj = {
  vNumberErr: false,
  vNumberMsg: "",
};

export default function TabVerify(props) {
  useEffect(() => {
    document.title = Setting.page_name.ServiceCentre;
  }, []);
  const { handleClick = () => null } = props;
  const vehicleNo_Regex = Setting.JS_Regex.vehicleNo;
  const { token, userdata } = useSelector((state) => state.auth);

  const [errObj, setErrObj] = useState(errorObj);
  const [ticketBtn, setTicketBtn] = useState(true);
  const [subText, setSubText] = useState("");
  const [vNumber, setVNumber] = useState("");
  const [vBtn, setVBtn] = useState(false);
  const [countDetails, setCountDetails] = useState({});
  const [pageLoad, setPageLoad] = useState(true);
  const [verifyBtnLoader, setVerifyBtnLoader] = useState(false);
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [selectedFile, setSlectedFile] = useState(null);
  const [confirmDialog, setConfirmDialog] = useState(false);

  const styles = useStyles();

  useEffect(() => {
    getCounts();
  }, []);

  async function getCounts() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.servicecounter}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data)) {
          setCountDetails(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function validation() {
    const error = { ...errObj };
    let valid = true;
    if (isEmpty(vNumber)) {
      valid = false;
      error.vNumberErr = true;
      error.vNumberMsg = "Please enter Vehicle No. ";
    } else if (!vehicleNo_Regex.test(vNumber)) {
      valid = false;
      error.vNumberErr = true;
      error.vNumberMsg = "Please enter Valid Vehicle No. ";
    }
    setErrObj(error);
    if (valid) {
      verifyFunction();
    }
  }

  async function verifyFunction(bool) {
    setVerifyBtnLoader(true);
    setSubText("");
    try {
      const response = await getApiData(
        `${Setting.endpoints.verifycaller}?vehicle_no=${vNumber.trim()}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setSubText("verified");
        setVBtn(true);
        setTicketBtn(false);
        setVehicleDetails(response?.data);
        toast.success(response?.message);
      } else {
        toast.error(response?.message);
        setVBtn(false);
        setTicketBtn(true);
        const error = { ...errObj };
        error.vNumberErr = true;
        error.vNumberMsg = "Please enter Valid Vehicle No. ";
        setErrObj(error);
      }
      setVerifyBtnLoader(false);
    } catch (error) {
      setVBtn(false);
      setTicketBtn(true);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setVerifyBtnLoader(false);
    }
  }

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.importservicecenter;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };

  function clearData() {
    setSlectedFile(null);
  }

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Grid
            container
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={(v, e) => {
                handleClick("OngoingTickets");
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card1}
                  alt={Images.card1}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: "15%",
                    left: isTablet ? "12%" : "10%",
                  }}
                >
                  {countDetails?.ongoing_ticket || 0}
                </Typography>
              </Grid>
            </Box>
            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={(v, e) => {
                handleClick("needfollowup");
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card2}
                  alt={Images.card2}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: "15%",
                    left: isTablet ? "12%" : "10%",
                  }}
                >
                  {countDetails?.need_follow_up || 0}
                </Typography>
              </Grid>
            </Box>
            <Box
              style={{ width: "100%", marginRight: 20 }}
              onClick={(v, e) => {
                handleClick("TabOutstanding");
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card3}
                  alt={Images.card3}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: "15%",
                    left: isTablet ? "12%" : "10%",
                  }}
                >
                  {countDetails?.outstanding_payment || 0}
                </Typography>
              </Grid>
            </Box>
            <Box
              style={{ width: "100%" }}
              onClick={(v, e) => {
                handleClick("ResolvedTickets");
              }}
            >
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  position: "relative",
                  width: "100%",
                }}
              >
                <img
                  src={Images.card4}
                  alt={Images.card4}
                  style={{ width: "100%" }}
                />
                <Typography
                  style={{
                    fontSize: "3vw",
                    fontWeight: 800,
                    color: color.white,
                    position: "absolute",
                    bottom: "15%",
                    left: isTablet ? "12%" : "10%",
                  }}
                >
                  {countDetails?.resolved_ticket || 0}
                </Typography>
              </Grid>
            </Box>
          </Grid>

          {userdata?.role !== "service_enginner" && (
            <Grid container lg={12} style={{ paddingBottom: 20 }}>
              <Grid
                lg={1}
                sm={2}
                xs={3}
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                }}
              >
                <Typography>
                  <b>Verify Caller :</b>
                </Typography>
              </Grid>

              <Grid lg={10} sm={10} xs={9}>
                <TextField
                  placeholder="Enter the Vehicle No."
                  fullWidth
                  value={vNumber}
                  error={errObj.vNumberErr}
                  helperText={errObj.vNumberErr ? errObj.vNumberMsg : null}
                  onChange={(e) => {
                    setSubText("");
                    setVNumber(e.target.value);
                    setErrObj({
                      ...errObj,
                      vNumberErr: false,
                      vNumberMsg: "",
                    });
                    if (vBtn && isEmpty(e.target.value)) {
                      setVBtn(false);
                    }
                  }}
                />
              </Grid>

              {subText === "verified" ? (
                <Grid
                  container
                  lg={12}
                  alignItems="center"
                  style={{ marginTop: errObj.vNumberErr ? 20 : 10 }}
                >
                  <Grid lg={1} sm={2} xs={4}></Grid>
                  <Grid
                    lg={11}
                    sm={10}
                    xs={8}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Typography>
                      Vehicle No.: {vNumber} Successfully Verified
                    </Typography>
                    <CheckCircleOutlineIcon
                      style={{ fontSize: 18, color: "#6FCF97", marginLeft: 5 }}
                    />
                  </Grid>
                </Grid>
              ) : (
                <Grid
                  container
                  lg={12}
                  alignItems="center"
                  style={{ marginTop: errObj.vNumberErr ? 20 : 10 }}
                >
                  <Grid lg={1} sm={2} xs={12}></Grid>
                  <Grid lg={11} sm={10} xs={12}>
                    <Typography>
                      <b>Note : </b>Vehicle No. format is XX 00 XX 0000. E.g. GJ
                      07 DC 1111
                    </Typography>
                  </Grid>
                </Grid>
              )}
              <Grid
                container
                lg={12}
                alignItems="center"
                style={{ marginTop: 20 }}
              >
                <Grid lg={1} sm={2}></Grid>
                <Grid lg={11} sm={10}>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: vBtn ? "" : color.secondary,
                      marginRight: 20,
                    }}
                    onClick={validation}
                    disabled={vBtn}
                  >
                    {verifyBtnLoader ? (
                      <CircularProgress style={{ color: "#fff" }} size={18} />
                    ) : (
                      " Verify"
                    )}
                  </Button>
                  <Button
                    disabled={ticketBtn}
                    variant="contained"
                    onClick={() => {
                      handleClick("GenerateTicket", vehicleDetails);
                    }}
                  >
                    Generate Ticket
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
          <ConfirmDialog
            title={`Are you sure you want to Upload Center List?`}
            visible={confirmDialog}
            handleModal={(bool) => {
              if (bool) {
                handleOnSubmit();
              } else {
                setConfirmDialog(false);
              }
            }}
            btnLoad={removeBtnLoad}
          />
        </div>
      ) : (
        <div>
          <Grid
            container
            style={{
              marginTop: 10,
              marginBottom: 10,
            }}
          >
            <Box display="flex" style={{ marginBottom: 10, width: "100%" }}>
              <Box
                style={{ width: "100%", marginRight: 10 }}
                onClick={(v, e) => {
                  handleClick("OngoingTickets");
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <img
                    src={Images.card1}
                    alt={Images.card1}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    style={{
                      fontSize: "3vw",
                      fontWeight: 800,
                      color: color.white,
                      position: "absolute",
                      bottom: isTablet ? "9%" : "22%",
                      left: "10%",
                    }}
                  >
                    {countDetails?.ongoing_ticket || 0}
                  </Typography>
                </Grid>
              </Box>
              <Box
                style={{ width: "100%" }}
                onClick={(v, e) => {
                  handleClick("needfollowup");
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <img
                    src={Images.card2}
                    alt={Images.card2}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    style={{
                      fontSize: "3vw",
                      fontWeight: 800,
                      color: color.white,
                      position: "absolute",
                      bottom: isTablet ? "9%" : "22%",
                      left: "10%",
                    }}
                  >
                    {countDetails?.need_follow_up || 0}
                  </Typography>
                </Grid>
              </Box>
            </Box>
            <Box item display="flex" style={{ width: "100%" }}>
              <Box
                style={{ width: "100%", marginRight: 10 }}
                onClick={(v, e) => {
                  handleClick("TabOutstanding");
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <img
                    src={Images.card3}
                    alt={Images.card3}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    style={{
                      fontSize: "3vw",
                      fontWeight: 800,
                      color: color.white,
                      position: "absolute",
                      bottom: isTablet ? "9%" : "22%",
                      left: "10%",
                    }}
                  >
                    {countDetails?.outstanding_payment || 0}
                  </Typography>
                </Grid>
              </Box>
              <Box
                style={{ width: "100%" }}
                onClick={(v, e) => {
                  handleClick("ResolvedTickets");
                }}
              >
                <Grid
                  item
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    position: "relative",
                    width: "100%",
                  }}
                >
                  <img
                    src={Images.card4}
                    alt={Images.card4}
                    style={{ width: "100%" }}
                  />
                  <Typography
                    style={{
                      fontSize: "3vw",
                      fontWeight: 800,
                      color: color.white,
                      position: "absolute",
                      bottom: isTablet ? "9%" : "22%",
                      left: "10%",
                    }}
                  >
                    {countDetails?.resolved_ticket || 0}
                  </Typography>
                </Grid>
              </Box>
            </Box>
          </Grid>

          {userdata?.role !== "service_enginner" && (
            <>
              <Grid
                container
                style={{
                  marginTop: 15,
                  alignItems: "center",
                }}
              >
                <Grid item xs={4}>
                  <Typography fontWeight={"bold"}>Verify Caller :</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    placeholder="Enter the Vehicle No."
                    fullWidth
                    value={vNumber}
                    error={errObj.vNumberErr}
                    helperText={errObj.vNumberErr ? errObj.vNumberMsg : null}
                    onChange={(e) => {
                      setSubText("");
                      setVNumber(e.target.value);
                      setErrObj({
                        ...errObj,
                        vNumberErr: false,
                        vNumberMsg: "",
                      });
                      if (vBtn && isEmpty(e.target.value)) {
                        setVBtn(false);
                      }
                    }}
                  />
                </Grid>
                {subText === "verified" ? (
                  <Grid
                    container
                    lg={12}
                    alignItems="center"
                    style={{ marginTop: errObj.vNumberErr ? 20 : 10 }}
                  >
                    <Grid lg={1} sm={1.5} xs={4}></Grid>
                    <Grid
                      lg={11}
                      sm={10.5}
                      xs={8}
                      style={{ display: "flex", alignItems: "center" }}
                    >
                      <Typography>
                        Vehicle No.:
                        <br /> {vNumber} Successfully Verified
                        <CheckCircleOutlineIcon
                          style={{
                            fontSize: 20,
                            color: "#6FCF97",
                            marginLeft: 10,
                            marginBottom: -5,
                          }}
                        />
                      </Typography>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid
                    container
                    lg={12}
                    alignItems="center"
                    style={{ marginTop: errObj.vNumberErr ? 20 : 10 }}
                  >
                    <Grid lg={1} sm={2} xs={4}></Grid>
                    <Grid lg={11} sm={10} xs={8}>
                      <Typography>
                        <b>Note : </b>Vehicle No. format is XX 00 X/XX/XXX 0000.
                        E.g. GJ 07 DC 1111
                      </Typography>
                    </Grid>
                  </Grid>
                )}
              </Grid>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: 15,
                }}
              >
                <Button
                  style={{
                    width: "40%",
                    backgroundColor: vBtn ? "" : color.secondary,
                  }}
                  variant="contained"
                  onClick={validation}
                  disabled={vBtn}
                >
                  {verifyBtnLoader ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : (
                    " Verify"
                  )}
                </Button>
                <Button
                  sx={{ width: "40%", marginLeft: 1.4 }}
                  disabled={ticketBtn}
                  variant="contained"
                  onClick={() => {
                    handleClick("GenerateTicket", vehicleDetails);
                  }}
                >
                  Generate Ticket
                </Button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
}
