import React, { useEffect } from "react";
import "./styles.js";
import useStyles from "./styles.js";
import {
  Box,
  CircularProgress,
  Modal,
  Table,
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { useState } from "react";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { getApiData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { toast } from "react-toastify";

export default function VehicleDetailTable(props) {
  const { vehicleData } = props;
  const styles = useStyles();

  const { dropDownData, token } = useSelector((state) => state.auth);

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [pageLoad, setPageLoad] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [vehicleDetails, setVehicleDetail] = useState(false);

  const getTerrain = () => {
    if (!isEmpty(vehicleDetails)) {
      let array = vehicleDetails?.terrain_ids?.split(",");
      let res = dropDownData?.terrain.filter((item) => {
        let a = array.includes(item?.id?.toString());
        return a;
      });
      return res.map((v, i) => (
        <>
          {v?.label}
          {i < res.length - 1 ? ", " : ""}
        </>
      ));
    }
  };

  const checkFunction = () => {
    if (!isEmpty(vehicleDetails) && !isEmpty(vehicleDetails?.rc_book)) {
      let Array = vehicleDetails?.rc_book.split(".");
      if (Array[Array.length - 1] === "pdf") {
        const pdfWindow = window.open();
        pdfWindow.location.href = vehicleDetails?.rc_book;
      } else {
        setOpenCard(true);
      }
    }
  };

  useEffect(() => {
    getVehicleDetails();
  }, []);

  const getVehicleDetails = async () => {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${vehicleData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  return (
    <div className={styles.container}>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography fontWeight={"bold"}>Vehicle Details</Typography>
      </Box>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : (
        <div style={{ margin: 20 }}>
          <Table>
            <TableRow>
              <TableCell variant="head">Vehicle No.</TableCell>
              <TableCell>{vehicleDetails?.vehicle_no || "-"}</TableCell>
              <TableCell variant="head">No. of Tyres</TableCell>
              <TableCell>
                {vehicleDetails?.added_tyre_count} /
                {vehicleDetails?.no_of_tyres || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Type</TableCell>
              <TableCell>{vehicleDetails?.vehicle_type_id || "-"}</TableCell>
              <TableCell variant="head">Initial Reading</TableCell>
              <TableCell>{vehicleDetails?.initial_reading || "-"} Km</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Brand</TableCell>
              <TableCell>{vehicleDetails?.vehicle_brand_id || "-"}</TableCell>
              <TableCell variant="head">Route Origin</TableCell>
              <TableCell>{vehicleDetails?.route_origin || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Model</TableCell>
              <TableCell>{vehicleDetails?.vehicle_model || "-"}</TableCell>
              <TableCell variant="head">Route Destination</TableCell>
              <TableCell>{vehicleDetails?.route_destination || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Terrain</TableCell>
              <TableCell>{getTerrain()}</TableCell>
              <TableCell variant="head">Driver’s Name</TableCell>
              <TableCell>{vehicleDetails?.driver_name || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Load Capacity</TableCell>
              <TableCell>
                {vehicleDetails?.load_capicity || ""} Tonnes
              </TableCell>
              <TableCell variant="head">Driver’s No.</TableCell>
              <TableCell>{vehicleDetails?.driver_phone || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Goods Carried</TableCell>
              <TableCell>{vehicleDetails?.goods_carried || "-"}</TableCell>
              <TableCell variant="head">Alignments Claimed</TableCell>
              <TableCell>
                {`${vehicleDetails?.alignment_count}/5` || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">RC Book Image</TableCell>
              <TableCell
                style={{
                  color:
                    !isEmpty(vehicleDetails) &&
                    !isEmpty(vehicleDetails?.rc_book)
                      ? color.primary
                      : color.disable,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  checkFunction();
                }}
              >
                View
              </TableCell>
              <Modal
                className={styles.modal}
                open={openCard}
                onClose={() => {
                  setOpenCard(false);
                }}
              >
                <img
                  style={{
                    objectFit: "contain",
                    borderRadius: 4,
                    maxWidth: 550,
                    maxHeight: 550,
                  }}
                  src={vehicleDetails.rc_book || "-"}
                  alt={"RC Book"}
                />
              </Modal>
            </TableRow>
          </Table>
        </div>
      )}
    </div>
  );
}
