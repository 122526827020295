import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { toast } from "react-toastify";
import { color } from "../../../Config/theme";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import * as XLSX from "xlsx/xlsx.mjs";
import MDateRange from "../../Modal/MDateRange";
import { useSelector } from "react-redux";

export default function MasterReport() {
  const staticArr = ["Inspection Report", "Issues Analysis Report"];

  const [visible, setVisible] = useState(false);
  const [ind, setInd] = useState("");

  const { token, isOnline, permissionData, userdata } = useSelector(
    (state) => state.auth
  );

  const [staticArray, setStaticArr] = useState([...staticArr]);

  // master report download loader state
  const [arrReportLoader, setArrReportLoader] = useState(false);

  useEffect(() => {
    const dummy_Arr = [...staticArr];
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Inspection Report") {
            const findI = dummy_Arr.findIndex((v) => v === "Inspection Report");
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(findI, 1);
          }
          if (v?.label === "Issue Analysis Report") {
            const findI = dummy_Arr.findIndex(
              (v) => v === "Issues Analysis Report"
            );
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              !v?.isActive &&
              dummy_Arr.splice(findI, 1);
          }
        });
      });
    }
    setStaticArr(dummy_Arr);
  }, [permissionData]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function inspectionreport(
    fleetIds,
    vehicleIds,
    fromDate,
    toDate,
    isAll
  ) {
    setArrReportLoader(true);
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        isAll
          ? `${
              Setting.endpoints.inspectionmasterreport
            }?fleet_id=${fleetIds}&is_all=${1}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`
          : `${
              Setting.endpoints.inspectionmasterreport
            }?fleet_id=${fleetIds}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setArrReportLoader(false);
        if (isArray(response?.data) && !isEmpty(response?.data)) {
          downloadReport1(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave("Inspection Report");
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error=====>>>>>", error);
      toast.error(error.toString());
      setArrReportLoader(false);
    }
  }

  function downloadReport1(data) {
    const newData = data?.map((item, index) => {
      let unresolveIss = Object.keys(item?.unresolved_tyre_issues_noted).map(
        (v) => {
          return `${v} : ${item?.unresolved_tyre_issues_noted[v]}`;
        }
      );

      let unresolveMechIss = Object.keys(
        item?.unresolved_mechanical_issues_noted
      ).map((v) => {
        return `${v} : ${item?.unresolved_mechanical_issues_noted[v]}`;
      });

      let parseresolveIss = Object.keys(
        item?.partially_resolved_tyre_issues_noted
      ).map((v) => {
        return `${v} : ${item?.partially_resolved_tyre_issues_noted[v]}`;
      });

      let parseresolvemechIss = Object.keys(
        item?.partially_resolved_mechanical_issues_noted
      ).map((v) => {
        return `${v} : ${item?.partially_resolved_mechanical_issues_noted[v]}`;
      });

      let resolveIss = Object.keys(item?.resolved_tyre_issues_noted).map(
        (v) => {
          return `${v} : ${item?.resolved_tyre_issues_noted[v]}`;
        }
      );

      let resolvemechIss = Object.keys(
        item?.resolved_mechanical_issues_noted
      ).map((v) => {
        return `${v} : ${item?.resolved_mechanical_issues_noted[v]}`;
      });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model,
        "No. of Tyres": Number(item?.no_of_tyres),
        "Unresolved Tyre Issues( Total )": Number(item?.unresolved_tyre_issues),
        "Unresolved Tyre Issues( Issues Noted )":
          item?.unresolved_tyre_issues > 0 ? unresolveIss?.toString() : "-",
        "Unresolved Mechanical Issues( Total )": Number(
          item?.unresolved_mechanical_issues
        ),
        "Unresolved Mechanical Issues( Issues Noted )":
          item?.unresolved_mechanical_issues > 0
            ? unresolveMechIss?.toString()
            : "-",
        "Partially Resolved Tyre Issues( Total )": Number(
          item?.partially_resolved_tyre_issues
        ),
        "Partially Resolved Tyre Issues( Issues Noted )":
          item?.partially_resolved_tyre_issues > 0
            ? parseresolveIss?.toString()
            : "-",
        "Partially Resolved Mechanical Issues( Total )": Number(
          item?.partially_resolved_mechanical_issues
        ),
        "Partially Resolved Mechanical Issues( Issues Noted )":
          item?.partially_resolved_mechanical_issues > 0
            ? parseresolvemechIss?.toString()
            : "-",
        "Resolved Tyre Issues( Total )": Number(item?.resolved_tyre_issues),
        "Resolved Tyre Issues( Issues Noted )":
          item?.resolved_tyre_issues > 0 ? resolveIss?.toString() : "-",
        "Resolved Mechanical Issues( Total )": Number(
          item?.resolved_mechanical_issues
        ),
        "Resolved Mechanical Issues( Issues Noted )":
          item?.resolved_mechanical_issues > 0
            ? resolvemechIss?.toString()
            : "-",
        "Initial Reading (Km)": Number(
          item?.initial_reading?.toString()?.replaceAll(",", "")
        ),
        "Current Reading (Km)": Number(
          item?.current_reading?.toString()?.replaceAll(",", "")
        ),
        "Mileage (Km)": Number(item?.mileage?.toString()?.replaceAll(",", "")),
        "Inspections (Month)": Number(item?.inspection_monthly),
        "Inspections (Overall)": Number(item?.inspection_overall),
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Inspection Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inspection Report.xlsx");
  }

  async function issueanalysis(fleetIds, vehicleIds, fromDate, toDate, isAll) {
    setArrReportLoader(true);
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        isAll
          ? `${
              Setting.endpoints.issueAnalysis
            }?fleet_id=${fleetIds}&is_all=${1}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`
          : `${
              Setting.endpoints.issueAnalysis
            }?fleet_id=${fleetIds}&vehicle_ids=${vehicleIds}&from_date=${from_date}&to_date=${
              isEmpty(to_date) ? from_date : to_date
            }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        setArrReportLoader(false);
        if (isArray(response?.data) && !isEmpty(response?.data)) {
          downloadReport2(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
        auditSave("Issues Analysis Report");
      } else {
        setArrReportLoader(false);
        toast.error(response?.message);
      }
      setArrReportLoader(false);
    } catch (error) {
      console.log("error=====>>>>>", error);
      toast.error(error.toString());
      setArrReportLoader(false);
    }
  }

  function downloadReport2(data) {
    const newData = data?.map((item, index) => {
      let iss = Object.keys(item?.tyre_issues).map((v) => {
        return `${v} : ${item?.tyre_issues[v]}`;
      });

      let Mechiss = Object.keys(item?.mechanical_issues).map((v) => {
        return `${v} : ${item?.mechanical_issues[v]}`;
      });

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": item?.fleet_name,
        "Vehicle No.": item?.vehicle_no,
        "Vehicle Type": item?.vehicle_type,
        "Vehicle Brand": item?.vehicle_brand,
        "Vehicle Model": item?.vehicle_model || "-",
        "Tyre Serial No": item?.tyre_serial_no || "-",
        "Tyre Brand": item?.tyre_brand || "-",
        "Item Description": item?.item_descryption || "-",
        "Tyre Issue": !isEmpty(iss) ? iss?.toString() : "-",
        // Frequency: item?.frequency,
        "Axle Type": item?.axle_type || "-",
        "Mechanical Issue": !isEmpty(Mechiss) ? Mechiss?.toString() : "-",
        // "Frequency of Mech. Issue": item?.frequency,
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Issues Analysis Report");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Issues Analysis Report.xlsx");
  }
  return (
    <>
      {staticArray?.map((item, index) => {
        return (
          <>
            <div style={{ padding: isMobile ? 0 : "0px 10px 0px 10px" }}>
              <Grid
                container
                display="flex"
                alignItems="center"
                justifyContent="space-between"
                style={{ marginTop: 20, marginBottom: 20 }}
              >
                <Typography>{item}</Typography>
                <Button
                  disabled={isOnline ? false : true}
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                    marginRight: isMobile ? 0 : 20,
                  }}
                  onClick={() => {
                    setInd(index) || setVisible(true);
                  }}
                >
                  {ind === index && arrReportLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    "Download"
                  )}
                </Button>
              </Grid>
              {index === staticArray.length - 1 ? "" : <Divider />}
            </div>
            <MDateRange
              isSelect
              visible={visible}
              handleModal={(
                type,
                fleetIds,
                vehicleIds,
                fromDate,
                toDate,
                isAll
              ) => {
                if (type === "download") {
                  ind === 0
                    ? inspectionreport(
                        fleetIds,
                        vehicleIds,
                        fromDate,
                        toDate,
                        isAll
                      )
                    : issueanalysis(
                        fleetIds,
                        vehicleIds,
                        fromDate,
                        toDate,
                        isAll
                      );
                }
                setVisible(false);
              }}
            />
          </>
        );
      })}
    </>
  );
}
