import React from "react";
import { color } from "../../../Config/theme";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import FilterAltOffSharpIcon from "@mui/icons-material/FilterAltOffSharp";

export default function TableToggle() {
  return (
    <ExpandCircleDownIcon style={{ fontSize: 30, color: color.primary }} />
  );
}
export function ResetIcon() {
  return <FilterAltOffSharpIcon />;
}
