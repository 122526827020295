import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Pagination,
  Button,
  Tooltip,
  ListItemButton,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Edit, FilterAlt, Search } from "@mui/icons-material";
import MAllocatedFleet from "../../Modal/MAllocatedFleet/index.js";
import * as XLSX from "xlsx/xlsx.mjs";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

function ManageAllocate(props, ref) {
  const {
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleSearch = () => null,
    search = "",
    pageNo = 1,
    record = 10,
  } = props;

  const privilegeList = [
    { label: "Admin", id: "admin" },
    { label: "FK RO", id: "fk_ro" },
    { label: "FK Tyre Care Executive", id: "service_enginner" },
    { label: "Fleet Owner", id: "fleet_owner" },
    { label: "Fleet Manager", id: "fleet_manager" },
    { label: "Call Center", id: "call_centre" },
  ];

  const styles = useStyles();
  const [manageAllocateList, setManageAllocateList] = useState({});

  const [pageLoad, setPageLoad] = useState(true);
  const [filter, setFilter] = useState("");
  // const [searchFilter, setSearchFilter] = useState("");
  const [paramData, setParamData] = useState({});
  const [visible, setVisible] = useState(false);
  const { token, dropDownData, userdata } = useSelector((state) => state.auth);
  const [privilegesList, setPrivilegesList] = useState(false);

  const ManageAllocateListColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip title="Edit" placement="right" arrow>
            <Edit
              style={{ color: color.primary, cursor: "pointer" }}
              onClick={() => {
                setParamData(params?.row);
                setTimeout(() => {
                  setVisible(true);
                }, 200);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 140,
    },
    {
      field: "sales_employee_code",
      headerName: "Employee Code",
      width: 180,
    },
    {
      field: "address",
      headerName: "Regional Office",
      width: 180,
      renderCell: (params) => {
        let array = params?.row?.address;
        let res =
          !isNull(array) &&
          dropDownData?.office_region?.find(
            (item) => array?.toString() === item?.id?.toString()
          );
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "fleet_allocated",
      headerName: "Fleets Allocated",
      width: 180,
      renderCell: (params) => {
        let array = params?.row?.fleet_allocated;
        return (
          <div
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {array.map((items, index) => {
              const isTwo = index <= 1;
              return isTwo ? (
                <Typography>
                  {items}
                  {index !== array.length - 1 ? ", " : ""}
                </Typography>
              ) : null;
            })}
            {array.length > 2 && (
              <span style={{ color: color.placeholder }}>{`+ ${
                array.length - 2
              } more`}</span>
            )}
          </div>
        );
      },
    },
    {
      field: "role",
      headerName: "Privilege",
      width: 215,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              backgroundColor:
                params?.row?.role === "fleet_manager"
                  ? "#D9F8FF"
                  : params?.row?.role === "admin"
                  ? "#D9FFE4"
                  : params?.row?.role === "fleet_owner"
                  ? "#FFFDD9"
                  : params?.row?.role === "fk_ro"
                  ? "#FFD9D9"
                  : params?.row?.role === "call_centre"
                  ? "#d1dbff"
                  : "#F5D9FF",
              padding: 8,
              borderRadius: 6,
            }}
          >
            <Typography>
              {params?.row?.role === "fleet_manager"
                ? "Fleet Manager"
                : params?.row?.role === "admin"
                ? "Admin"
                : params?.row?.role === "fleet_owner"
                ? "Fleet Owner"
                : params?.row?.role === "fk_ro"
                ? "FK RO"
                : params?.row?.role === "call_centre"
                ? "Call Centre"
                : "FK Tyre Care Executive"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "vehicles_allocated",
      headerName: "Vehicles Allocated",
      width: 180,
    },
    {
      field: "unresolved_issue",
      headerName: "Unresolved Issues",
      width: 180,
    },
    {
      field: "status",
      headerName: "User Status",
      width: 140,
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.status === 10 ? "Active" : "Inactive"}
          </Typography>
        );
      },
    },
  ];

  useEffect(() => {
    getUserList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    filter === "" && isEmpty(search) && getUserList(false);
  }, [filter, search]);

  function resetFilterData() {
    setFilter("");
    handleSearch("");
  }

  useImperativeHandle(ref, () => ({
    manageAllocateList: manageAllocateList,
    downloadManageAllocateData: (v) => {
      getUserList(false, v);
    },
  }));

  function downloadManageAllocateData(data) {
    const newData = data.map((row, index) => {
      let array = row?.address;
      let res =
        !isEmpty(array) &&
        dropDownData?.office_region?.find(
          (item) => array?.toString() === item?.id?.toString()
        );

      const newObj = {
        "Sr. No.": index + 1,
        Name: row?.name,
        "Employee Code": row?.sales_employee_code || "-",
        "Regional Office": res?.label || "" || "-",
        "Fleets Allocated": row?.fleet_allocated.toString() || "-",
        Privilege:
          row?.role === "fleet_manager"
            ? "Fleet Manager"
            : row?.role === "admin"
            ? "Admin"
            : row?.role === "fleet_owner"
            ? "Fleet Owner"
            : row?.role === "fk_ro"
            ? "FK RO"
            : row?.role === "call_centre"
            ? "Call Centre"
            : "FK Tyre Care Executive" || "-",
        "Vehicles Allocated": row?.vehicles_allocated || "-",
        "Unresolved Issues": row?.unresolved_issue || "-",
        "User Status": row?.status === 10 ? "Active" : "Inactive",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "User Allocated Fleet List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "User Allocated Fleet List.xlsx");
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From User Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getUserList(bool, v) {
    !v && setPageLoad(true);
    v && handleLoader(true);
    let endpoints = `${
      Setting.endpoints.allocatedlist
    }?page=${pageNo}&per_page=${record}&filter=${filter}&search=${
      bool ? "" : search
    }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadManageAllocateData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setManageAllocateList(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("User Allocated Fleet List");
      setPageLoad(false);
      handleLoader(false);
    } catch (error) {
      setPageLoad(false);
      handleLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap="nowrap"
        style={{ marginTop: 14 }}
      >
        <Grid item lg={10.4} style={{ display: "flex", alignItems: "center" }}>
          <Grid item lg={2}>
            <Select
              fullWidth
              placeholder="Filter"
              value={filter}
              onChange={(v) => {
                handlePageNo(1);
                setFilter(v.target.value);
              }}
              onOpen={() => {
                if (isEmpty(filter.toString())) {
                  setPrivilegesList(false);
                }
              }}
              displayEmpty
              IconComponent={FilterAlt}
              style={filter === "" ? { color: "#A2A2A2" } : {}}
              classes={{
                iconOpen: styles.iconOpen,
              }}
            >
              <MenuItem value={""} disabled hidden selected>
                Filter
              </MenuItem>
              <ListItemButton
                onClick={() => {
                  setPrivilegesList(!privilegesList);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Privilege</Typography>
                {privilegesList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              {privilegeList.map((item, index) => {
                return (
                  <MenuItem
                    style={{ display: privilegesList ? "flex" : "none" }}
                    key={index}
                    value={item?.id}
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
          <Grid container alignItems={"center"} lg={10} style={{ margin: 0 }}>
            <Grid item lg={4} style={{ marginLeft: 20 }}>
              <TextField
                fullWidth
                placeholder="Search by Name"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  if (isEmpty(v.target.value)) {
                    getUserList(true);
                  }
                  handleSearch(v?.target?.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(search)) {
                      handlePageNo(1);
                      getUserList(false);
                    }
                  }
                }}
              />
            </Grid>
            <Grid item style={{ marginLeft: 20 }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(search)) {
                    handlePageNo(1);
                    getUserList(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={filter === "" && isEmpty(search) ? true : false}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item lg={1.6} style={{ display: "flex", alignItems: "center" }}>
          <Typography style={{ fontSize: 14 }}>Records:</Typography>
          <Select
            fullWidth
            value={record}
            onChange={(v) => {
              handlePageNo(1);
              handleRecord(v.target.value);
            }}
            style={{ marginLeft: 10 }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(manageAllocateList?.data) &&
        !isEmpty(manageAllocateList?.data) ? (
        <div style={{ marginTop: 20 }}>
          <DataGrid
            rows={manageAllocateList?.data}
            columns={ManageAllocateListColumn}
            disableColumnMenu
            hideFooter
            showCellRightBorder
            disableSelectionOnClick
            showColumnRightBorder
            autoHeight={true}
            getRowHeight={() => "auto"}
          />
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(manageAllocateList?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={2}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => {
                handlePageNo(e);
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
      <MAllocatedFleet
        visible={visible}
        editData={paramData}
        handleModal={() => {
          setVisible(false);
        }}
        onSave={getUserList}
      />
    </div>
  );
}

export default forwardRef(ManageAllocate);
