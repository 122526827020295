import types from "./actions";

const initialState = {
  useruuid: "",
  userdata: {},
  isNotifiy: false,
  token: "",
  dropDownData: {},
  fleetData: [],
  inspectionData: [],
  List: [],
  dummyListArray: [],
  reduxChangeArray: [],
  permissionData: {},
  isOnline: true,
  countDown: true,
  inspectionReading: "",
  inspectionRemarks: "",
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_USER_DATA:
      localStorage.setItem("userData", JSON.stringify(action.userdata));
      return {
        ...state,
        userdata: action.userdata,
      };

    case types.SET_TOKEN:
      return {
        ...state,
        token: action.token,
      };

    case types.SET_DISPLAY_NOTIFICATION_POP_UP:
      return {
        ...state,
        isNotifiy: action.isNotifiy,
      };

    case types.SET_NOTI_DATA:
      return {
        ...state,
        notiData: action.notiData,
      };

    case types.SET_COUNTDOWN:
      return {
        ...state,
        countDown: action.countDown,
      };

    case types.SET_USER_UUID:
      return {
        ...state,
        useruuid: action.useruuid,
      };

    case types.DROPDOWN_DATA:
      return {
        ...state,
        dropDownData: action.dropDownData,
      };

    case types.ADD_INSPECTION_DATA:
      return {
        ...state,
        inspectionData: action.inspectionData,
      };

    case types.EDIT_INSPECTION_DATA:
      return {
        ...state,
        List: action.List,
      };

    case types.EDIT_INVENTORY_DATA:
      return {
        ...state,
        editInventoryData: action.editInventoryData,
      };

    case types.DUMMY_INSPECTION_DATA:
      return {
        ...state,
        dummyListArray: action.dummyListArray,
      };

    case types.CHANGE_ARRAY:
      return {
        ...state,
        reduxChangeArray: action.reduxChangeArray,
      };

    case types.ONLINE_STATUS:
      return {
        ...state,
        isOnline: action.isOnline,
      };

    case types.PERMISSION_DATA:
      return {
        ...state,
        permissionData: action.permissionData,
      };

    case types.FLEET_DATA:
      return {
        ...state,
        fleetData: action.fleetData,
      };

    case types.CLEAR_ALL_STORAGE_DATA:
      // localStorage.clear();
      localStorage.removeItem("userData");
      return {
        ...state,
        userdata: {},
      };
    case types.READING:
      return {
        ...state,
        inspectionReading: action.inspectionReading,
      };
    case types.REMARKS:
      return {
        ...state,
        inspectionRemarks: action.inspectionRemarks,
      };
    default:
      return state;
  }
}
