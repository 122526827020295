// import { useTheme } from "@emotion/react";
import {
  CircularProgress,
  Grid,
  MenuItem,
  Select,
  Typography,
  // useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
// import useStyles from "./styles.js";
import { Manage_Privileges } from "../../../Config/Static_Data.js";

import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { cloneDeep, isEmpty } from "lodash";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

export default function ManagePrivilege(props) {
  const { token } = useSelector((state) => state.auth);

  // const theme = useTheme();
  // const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [userType, setUserType] = useState("");
  const [permission, setPermission] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  // const [pageLoad,setPageLoad] = useState(false);

  useEffect(() => {
    if (!isEmpty(userType)) {
      getPermissionList();
    }
  }, [userType]);

  const reportDropdownList = [
    {
      id: 1,
      label: "Enable",
    },
    {
      id: 2,
      label: "Disable",
    },
  ];

  async function getPermissionList() {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.permissionList}?role=${userType}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setPermission(response.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function changePermission() {
    let endPoints = Setting.endpoints.savepermission;
    const params = {
      "UserRolePermission[permission]": JSON.stringify(permission),
      "UserRolePermission[role]": userType,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
      // setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      // setLoader(false);
    }
  }

  function SelectDropdown(value, name, index) {
    const DummyArr = { ...permission };

    Object.keys(DummyArr).map((item) => {
      if (item === name) {
        permission[item].map((v, i) => {
          if (i === index) {
            v.value = value;
            if (value === "Disable") {
              v.isActive = false;
            } else {
              v.isActive = true;
            }
          }
        });
      }
    });
    changePermission();
    setPermission(DummyArr);
  }

  return (
    <>
      <Grid container alignItems="center" marginTop={2}>
        <Grid item xs={12} sm={2}>
          <Typography>User Type : </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
          }}
        >
          <Select
            fullWidth
            displayEmpty
            value={userType}
            onChange={(event) => {
              setUserType(event.target.value);
            }}
            size={"medium"}
            style={userType === "" ? { color: "#A2A2A2" } : {}}
          >
            <MenuItem value={""} disabled hidden selected>
              Select the Type of User
            </MenuItem>
            <MenuItem value={"service_enginner"}>
              FK Tyre Care Executive
            </MenuItem>
            <MenuItem value={"fk_ro"}>FK RO</MenuItem>
            <MenuItem value={"fleet_owner"}>Fleet Owner</MenuItem>
            <MenuItem value={"fleet_manager"}>Fleet Manager</MenuItem>
          </Select>
        </Grid>
      </Grid>
      <Grid container alignItems="center" marginTop={2}>
        {pageLoad ? (
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            style={{ height: 550 }}
          >
            <CircularProgress size={40} />
          </Grid>
        ) : userType === "" ? (
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            style={{ height: 550 }}
          >
            <Typography color={color.placeholder}>
              Select a user type to manage their privileges
            </Typography>
          </Grid>
        ) : (
          Object.keys(permission).map((item) => {
            return (
              <>
                {item === "Reports" && (
                  <Typography
                    variant="tableTitle"
                    style={{ fontSize: 18, fontWeight: "bold" }}
                    marginTop={3}
                  >
                    {item}
                  </Typography>
                )}
                <Grid container>
                  {permission[item].map((v, index) => {
                    return (
                      <Grid
                        container
                        alignItems={"center"}
                        marginTop={1.5}
                        borderBottom={`2px solid ${color.bordercolor}`}
                      >
                        <Grid
                          container
                          alignItems={"center"}
                          marginBottom={1.5}
                          wrap="nowrap"
                        >
                          <Grid item md={8} lg={9}>
                            <Typography
                              style={
                                item !== "Reports"
                                  ? {
                                      fontSize: 18,
                                      fontWeight: "bold",
                                    }
                                  : {}
                              }
                            >
                              {v.label}
                            </Typography>
                          </Grid>
                          <Grid item md={1.5} lg={1}>
                            {
                              <Typography
                                style={{
                                  backgroundColor: v.isActive
                                    ? "#D9FFE4"
                                    : "#FFD9D9",
                                  padding: 8,
                                  borderRadius: 4,
                                  float: "right",
                                  marginRight: 20,
                                }}
                              >
                                {v.isActive ? "Enabled" : "Disabled"}
                              </Typography>
                            }
                          </Grid>
                          <Grid item md={2.5} lg={2}>
                            <Select
                              fullWidth
                              value={v.value}
                              disabled={item === "User Management"}
                              onChange={(event) => {
                                SelectDropdown(event.target.value, item, index);
                              }}
                              size={"medium"}
                            >
                              {reportDropdownList.map((value) => {
                                return (
                                  <MenuItem value={value.label}>
                                    {value.label}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </>
            );
          })
        )}
      </Grid>
    </>
  );
}
