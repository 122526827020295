import { Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { isArray, isEmpty, isObject } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import { color } from "../../../Config/theme";
import { getApiData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import useStyles from "../AuditList/styles";
import { DataGrid } from "@mui/x-data-grid";
import { useTheme } from "@emotion/react";
import * as XLSX from "xlsx/xlsx.mjs";

export default function AuditDetails(props) {
  const { auditData } = props;
  const styles = useStyles();
  const { token } = useSelector((state) => state.auth);

  const [searchFilter, setSearchFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [record, setRecord] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [auditList, setAuditList] = useState({});
  const [reportLoader, setReportLoader] = useState(false);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const auditColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "activity",
      headerName: "Activity",
      width: lg ? 260 : "unset",
      flex: 1,
    },
    {
      field: "audit_date",
      headerName: "Audit Date",
      width: 300,
      renderCell: (params) => {
        const Date = params?.row?.audit_date;
        const displayDate = moment(Date).format("DD-MM-yyyy");
        return <Typography>{displayDate || "-"}</Typography>;
      },
    },
  ];

  useEffect(() => {
    getAuditDetail(false);
  }, [record, pageNo]);

  useEffect(() => {
    isEmpty(searchFilter) && getAuditDetail(false);
  }, [searchFilter]);

  async function getAuditDetail(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    let date = !isEmpty(auditData?.audit_date)
      ? moment(auditData?.audit_date).format("YYYY-MM-DD")
      : "";
    let endpoints = `${Setting.endpoints.auditDetails}?user_id=${
      auditData?.user_id
    }&audit_date=${date}&page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadAuditData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setAuditList(listObj);
          }
        }
      } else {
        toast.error(response?.message, { toastId: 17 });
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      toast.error(error.toString(), { toastId: 17 });
      console.log("error ===>>>", error);
      setPageLoad(false);
      setReportLoader(false);
    }
  }

  function downloadAuditData(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        Activity: item?.activity,
        "Audit Date": moment(item?.capture_date).format("DD-MM-yyyy") || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Audit Details");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Audit Details.xlsx");
  }

  function resetFilterData() {
    setSearchFilter("");
  }

  const dataGridRender = useMemo(() => {
    return (
      <DataGrid
        rows={auditList?.data}
        columns={auditColumn}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
        autoHeight={true}
        getRowHeight={() => "auto"}
      />
    );
  }, [auditList]);

  return (
    <div className={styles.container}>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ height: 40 }}
      >
        <Grid item xs={12} sm={6}>
          <Typography variant="tableTitle">Audit Details</Typography>
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            style={{ backgroundColor: color.secondary }}
            onClick={() => getAuditDetail(true, 1)}
          >
            {reportLoader ? (
              <CircularProgress style={{ color: color.white }} size={24} />
            ) : (
              "Download"
            )}
          </Button>
        </Grid>
      </Grid>

      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 14 }}
        wrap="nowrap"
      >
        <Grid style={{ display: "flex" }} alignItems={"center"}>
          <Grid item alignItems={"center"} wrap={"nowrap"}>
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={searchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && getAuditDetail(true);
                setSearchFilter(v?.target?.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  if (!isEmpty(searchFilter)) {
                    getAuditDetail(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip title="Search by Name" placement="bottom" arrow>
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                if (!isEmpty(searchFilter)) {
                  getAuditDetail(false);
                }
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={resetFilterData}
              disabled={isEmpty(searchFilter) ? true : false}
            >
              Reset
            </Button>
          </div>
        </Grid>
        <Grid
          item
          lg={1.6}
          style={{
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <Typography style={{ fontSize: 14 }}>Records:</Typography>
          <Select
            fullWidth
            value={record}
            onChange={(v) => {
              setRecord(v.target.value);
            }}
            style={{ marginLeft: 10 }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>

      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(auditList?.data) && !isEmpty(auditList?.data) ? (
        <div style={{ marginTop: 20 }}>
          {dataGridRender}
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(auditList?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={2}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => {
                setPageNo(e);
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
    </div>
  );
}
