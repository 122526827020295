import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { color } from "../../../Config/theme.js";
import {
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
  InputAdornment,
  Pagination,
  CircularProgress,
  Tooltip,
  Modal,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { Search } from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { toast } from "react-toastify";
import { Setting } from "../../../Utils/Setting";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import { useSelector } from "react-redux";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

export default function OutstandingPayments(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    pageNo = 1,
    record = 10,
  } = props;
  const styles = useStyles();
  const [pageLoad, setPageLoad] = useState(false);
  const [searchFilter, setSearchFilter] = useState("");
  const [datePicker, setDatePicker] = useState(null);
  const [ListRows, setListRows] = useState(true);

  const [modal, openModal] = useState(false);
  const [imgURL, setimgURL] = useState("");
  const { token, userdata } = useSelector((state) => state.auth);
  const [reportLoader, setReportLoader] = useState(false);

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const columns = [
    {
      field: "Sr. No.",
      headerName: "Sr. No.",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "id",
      headerName: "Ticket ID",
      width: 120,
    },
    {
      field: "center_name",
      headerName: "Service Centre",
      width: 200,
    },

    {
      field: "phone",
      headerName: "Contact",
      width: 200,
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 200,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 200,
    },
    {
      field: "bill",
      headerName: "Invoice Details",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography
            style={{
              color: isEmpty(params?.row?.bill) ? color.disable : color.primary,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              setimgURL(params?.row?.bill);
              !isEmpty(params?.row?.bill) && openModal(true);
            }}
          >
            View
          </Typography>
        );
      },
    },
    {
      field: "created_at",
      headerName: "Added On",
      width: 250,
      renderCell: (params) => {
        const timestamp = params?.row?.created_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.created_at ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getOutstandingList(false);
  }, [record, pageNo]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getOutstandingList(false);
  }, [datePicker, searchFilter]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Service Center`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getOutstandingList(bool, v) {
    v && setReportLoader(true);
    !v && setPageLoad(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.outstandingpaymentlist
        }?&per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data?.rows) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadOutstanding(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setListRows(listObj);
          }
        else {
          toast.error(response?.message || "No Data Found");
        }
        v && auditSave("Outstanding Payments List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }
  function downloadOutstanding(data) {
    const newData = data.map((item, index) => {
      const timestamp = item?.created_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Ticket ID": item.id,
        "Service Cemter": item?.center_name || "-",
        Contact: item?.phone || "-",
        "Fleet Name": item?.fleet_name || "-",
        "Vehicle No.": item?.vehicle_no || "-",
        "Invoice Details": item?.bill || "-",
        "Added On": timestamp ? displayDate : "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Outstanding Payments List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Outstanding Payments List.xlsx");
  }

  function resetFilterData() {
    setSearchFilter("");
    setDatePicker(null);
  }
  return (
    <>
      <Grid item container style={{ justifyContent: "space-between" }}>
        <Typography variant="tableTitle">Outstanding Payments</Typography>
        <Button
          onClick={() => getOutstandingList(false, 1)}
          style={{ backgroundColor: color.secondary }}
        >
          {reportLoader ? (
            <CircularProgress style={{ color: color.white }} size={24} />
          ) : (
            "Download "
          )}
        </Button>
      </Grid>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        wrap={"nowrap"}
        style={{ marginTop: 10 }}
      >
        <Grid
          item
          lg={8}
          style={{ display: "flex", alignItems: "center" }}
          wrap={"nowrap"}
        >
          <Grid
            item
            lg={4}
            style={{ display: "flex", alignItems: "center" }}
            wrap={"nowrap"}
          >
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={searchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && getOutstandingList(true);
                setSearchFilter(v?.target?.value);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Vehicle No., Centre Name and Phone"
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
              onKeyDown={(v) => {
                if (v.key === "Enter") {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    handlePageNo(1);
                    getOutstandingList(false);
                  }
                }
              }}
            />
          </Grid>
          <div style={{ marginLeft: 20 }}>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                disableFuture
                views={["year", "month", "day"]}
                value={datePicker}
                onChange={(newValue) => {
                  setDatePicker(newValue);
                }}
                inputFormat="dd-MM-yyyy"
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{ svg: { color: color.primary } }}
                  />
                )}
              />
            </LocalizationProvider>
          </div>
          <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                  handlePageNo(1);
                  getOutstandingList(false, 1);
                }
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={resetFilterData}
              disabled={
                isEmpty(searchFilter) && isNull(datePicker) ? true : false
              }
            >
              Reset
            </Button>
          </div>
        </Grid>
        <Grid
          item
          lg={1.6}
          style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
        >
          <Typography style={{ fontSize: 14 }}>Records:</Typography>
          <Select
            fullWidth
            value={record}
            onChange={(v) => {
              handlePageNo(1);
              handleRecord(v.target.value);
            }}
            style={{ marginLeft: 10 }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>
      {pageLoad ? (
        <div>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(ListRows?.data) && !isEmpty(ListRows?.data) ? (
        <>
          <div style={{ marginTop: 20 }}>
            <DataGrid
              rows={ListRows?.data}
              columns={columns}
              pageSize={record}
              hideFooter
              disableColumnMenu
              showCellRightBorder
              showColumnRightBorder
              disableSelectionOnClick
              autoHeight={true}
              onCellClick={(params) => {
                if (params.field !== "bill") {
                  handleClick("ticketId", params?.row, "outstanding");
                }
              }}
            />
          </div>
          <div
            style={{
              display: "flex",
              padding: "20px 0 0 0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(ListRows?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={1}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => {
                handlePageNo(e);
              }}
            />
          </div>
        </>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
      <Modal
        className={styles.modal}
        onClose={() => {
          openModal(false);
        }}
        open={modal}
      >
        <img
          style={{
            objectFit: "contain",
            borderRadius: 4,
            maxWidth: 550,
            maxHeight: 550,
          }}
          src={imgURL ? imgURL : ""}
          alt=""
        />
      </Modal>
    </>
  );
}
