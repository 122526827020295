import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Image from "../../../Config/Images";
import "./styles.css";
import { useState } from "react";
import { isEmpty } from "lodash";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useDispatch, useSelector } from "react-redux";
import authActions from "../../../Redux/reducers/auth/actions";
// import tyre from "../../../Assets/Lottie/tyre.json";
import { color } from "../../../Config/theme";

import {
  CircularProgress,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Paper,
} from "@mui/material";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

const errorObj = {
  numErr: false,
  numMsg: "",
};

export default function Forgot() {
  const [num, setNum] = useState("");
  const [otp, setOTP] = useState("");
  const [userid, setUserID] = useState("");
  const [loader, setLoader] = useState(false);
  const [errObj, setErrObj] = useState(errorObj);
  const [showPassword, setShowPassword] = useState(false);
  const [otpscr, setOtpscr] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = Setting.page_name.Login;
  }, []);

  const handleSubmit = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.sendotp;
    const params = {
      phone: num,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.status) {
        toast.success(resp?.message);
        setOtpscr(true);
        setUserID(resp?.data?.user_id);
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  const verifyOtp = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.verifyotp;
    const params = {
      "VerifyOtpForm[otp]": otp,
      "VerifyOtpForm[user_id]": userid,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.status) {
        toast.success(resp?.message);
        clearData();
        navigate("/forgot-password/password-recovery", {
          state: {
            userid: userid,
          },
        });
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  const resendOtp = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.resendOtp;
    const params = {
      user_id: userid,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params);
      if (resp?.status) {
        toast.success(resp?.message);
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  const checkValidation = () => {
    const error = { ...errObj };
    let valid = true;

    if (!otpscr) {
      if (isEmpty(num)) {
        valid = false;
        error.numErr = true;
        error.numMsg = "Please enter Mobile No.";
      } else if (num.length < 10) {
        valid = false;
        error.numErr = true;
        error.numMsg = "Please enter Valid Mobile No.";
      }
    } else {
      if (isEmpty(otp)) {
        valid = false;
        error.otpErr = true;
        error.otpMsg = "Please enter OTP";
      } else if (otp.length !== 4) {
        valid = false;
        error.otpErr = true;
        error.otpMsg = "The OTP length must be 4 Digit";
      }
    }
    setErrObj(error);

    if (valid) {
      setErrObj(errorObj);
      if (otpscr) {
        verifyOtp();
      } else {
        handleSubmit();
      }
    }
  };

  const clearData = () => {
    setNum("");
    setOTP("");
  };

  return isMobile || isTablet ? (
    <Box
      component={"main"}
      style={{
        margin: 0,
        overflow: "hidden",
        height: "100vh",
        background: !isMobile
          ? `linear-gradient(to right,  #4573B8 40%, white 10%,white 50%,white 50%,white 75%,white 75%)`
          : `linear-gradient(to bottom,  #4573B8 40%, white 10%,white 50%,white 50%,white 75%,white 75%)`,
        display: "flex",
        alignItems: isMobile ? "unset" : "center",
        justifyContent: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: isMobile ? "column" : "row",
          alignItems: isMobile ? "center" : "unset",
          overflow: "auto",
        }}
      >
        <div
          style={{
            width: isMobile ? "90%" : "40%",
            border: "none",
            boxShadow: isMobile
              ? "none"
              : "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
            padding: 20,
            boxSizing: "border-box",
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "space-between",
          }}
        >
          <img
            src={Image.text}
            style={{
              objectFit: "contain",
              maxWidth: isMobile ? "50%" : "80%",
              alignSelf: "center",
              height: "auto",
            }}
            alt="Go the extra mile"
          />

          {isMobile ? null : (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                position: "relative",
                width: "100%",
              }}
            >
              <img
                src={Image.loginImg}
                style={{
                  objectFit: "contain",
                  width: "80%",
                  height: "auto",
                  bottom: 0,
                  left: 0,
                }}
                alt="background_image"
              />
            </div>
          )}
        </div>
        <div
          variant="outlined"
          style={{
            width: isMobile ? "90%" : "60%",
            border: "none",
            boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            backgroundColor: "white",
          }}
        >
          <Grid
            item
            style={{
              width: "100%",
              padding: 20,
              backgroundColor: "white",
            }}
          >
            <Grid
              item
              container
              style={{
                marginBottom: 0,
              }}
              justifyContent="center"
              alignItems="center"
            >
              <img
                src={Image.frame1}
                height={70}
                alt="logo"
                style={{ cursor: "pointer" }}
                onClick={() => window.open("https://fleetkaptan.com/")}
              />
              {/* <Typography
                style={{ width: "unset", fontWeight: "bold", fontSize: 20 }}
              >
                Fleet Kaptan
              </Typography> */}
            </Grid>
            <Typography
              variant="subtitle1"
              style={{
                fontWeight: 700,
                fontSize: "20px",
                textAlign: "center",
                margin: "20px 0px",
              }}
            >
              Password Recovery
            </Typography>
            <div></div>
            <div className="txtInp">
              {!otpscr ? (
                <>
                  <Typography
                    style={{
                      color: "#4573B8",
                      fontWeight: "bold",
                      marginBottom: 8,
                    }}
                  >
                    Enter Phone No. Linked To Your Account
                  </Typography>
                  <TextField
                    fullWidth
                    value={num}
                    margin="normal"
                    required
                    style={{
                      margin: 0,
                      marginBottom: errObj.numErr ? 30 : 20,
                    }}
                    placeholder="Please enter Phone No."
                    id="number"
                    name="Text"
                    autoFocus
                    onChange={(e) => {
                      setNum(e.target.value.replace(/[^0-9]/g, ""));
                      setErrObj({ ...errObj, numErr: false, numMsg: "" });
                    }}
                    error={errObj.numErr}
                    helperText={errObj.numErr ? errObj.numMsg : null}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                      maxLength: 11,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">+91</InputAdornment>
                      ),
                    }}
                  />
                </>
              ) : (
                <>
                  <Typography
                    style={{
                      color: "#4573B8",
                      fontWeight: "bold",
                      marginBottom: 8,
                    }}
                  >
                    Enter OTP
                  </Typography>
                  <FormControl
                    fullWidth
                    error={errObj.otpErr}
                    style={{ marginBottom: errObj.otpErr ? 30 : 20 }}
                  >
                    <OutlinedInput
                      value={otp}
                      margin="normal"
                      required
                      type={showPassword ? "text" : "password"}
                      style={{
                        width: "100%",
                        margin: 0,
                      }}
                      error={errObj.otpErr}
                      helperText={errObj.otpErr ? errObj.otpMsg : null}
                      name="otp"
                      placeholder="Please enter your OTP"
                      id="otp"
                      autoComplete="current-otp"
                      onChange={(e) => {
                        setOTP(e.target.value.replace(/[^0-9]/g, ""));
                        setErrObj({ ...errObj, otpErr: false, otpMsg: "" });
                      }}
                      inputProps={{
                        onWheel: (event) => event.currentTarget.blur(),
                        maxLength: 4,
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          ev.preventDefault();
                          checkValidation();
                        }
                      }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle otp visibility"
                            onClick={() => setShowPassword(!showPassword)}
                          >
                            {!showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {errObj.otpErr ? (
                      <FormHelperText>{errObj.otpMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                  <Grid item container>
                    <Typography>Did't Received OTP?</Typography>
                    <Typography
                      sx={{
                        color: "#4573B8",
                        fontWeight: "bold",
                        marginLeft: "8px",
                        ":hover": {
                          textDecoration: "underline",
                          cursor: "pointer",
                        },
                      }}
                      onClick={resendOtp}
                    >
                      Resend OTP
                    </Typography>
                  </Grid>
                </>
              )}
            </div>
            <Grid item container style={{ marginTop: 20 }}>
              <Button
                fullWidth
                style={{
                  backgroundColor: "#4573B8",
                  fontWeight: "bold",
                }}
                onClick={checkValidation}
                justifyContent={"center"}
                variant="contained"
                disabled={loader}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={26} />
                ) : otpscr ? (
                  "Submit"
                ) : (
                  "Send OTP"
                )}
              </Button>
            </Grid>
            <Grid item container justifyContent={"center"}>
              <Typography
                className="forgotPassword"
                onClick={() => {
                  navigate("/");
                  setUserID("");
                }}
              >
                Back to Login?
              </Typography>
            </Grid>
          </Grid>
        </div>
      </div>
    </Box>
  ) : (
    <>
      <Grid
        container
        style={{
          flex: 1,
          overflow: "hidden",
          height: "100vh",
        }}
      >
        {isMobile ? null : (
          <Grid
            item
            container
            lg={4}
            md={4}
            style={{
              backgroundColor: "#4573B8",
              overflow: "hidden",
            }}
            alignItems="center"
            justifyContent="flex-end"
          >
            <div
              style={{
                width: "80%",
                maxWidth: 350,
                height: 600,
                backgroundColor: "#4573B8",
                border: "none",
                boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
                padding: 20,
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flex: "1 1",
                }}
              >
                <img
                  src={Image.text}
                  style={{
                    objectFit: "contain",
                    maxWidth: "100%",
                    height: "auto",
                  }}
                  alt="Go the extra mile"
                />
              </div>
              <div
                style={{
                  display: "flex",
                  flex: "1 1",
                  position: "relative",
                  justifyContent: "center",
                }}
              >
                <img
                  className="mecimg"
                  src={Image.loginImg}
                  style={{
                    objectFit: "contain",
                    position: "absolute",
                    width: "90%",
                    height: "auto",
                    bottom: -80,
                    left: -80,
                  }}
                  alt="background_image"
                />
              </div>
            </div>
          </Grid>
        )}
        <Grid
          item
          container
          lg={8}
          md={8}
          sm={12}
          style={{
            overflow: "hidden",
          }}
          justifyContent={isMobile ? "center" : "flex-start"}
          alignItems="center"
        >
          <Paper
            variant="outlined"
            style={{
              width: "80%",
              height: 600,
              border: "none",
              boxShadow: "0px 0px 50px 10px rgba(0, 0, 0, 0.1)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Grid item style={{ width: isMobile ? "90%" : "50%" }}>
              <Grid
                item
                container
                style={
                  {
                    // marginBottom: 20,
                  }
                }
                justifyContent="center"
                alignItems="center"
              >
                <img
                  src={Image.frame1}
                  height={150}
                  alt="logo"
                  style={{ cursor: "pointer" }}
                  onClick={() => window.open("https://fleetkaptan.com/")}
                />
                {/* <Typography
                  style={{ width: "unset", fontWeight: "bold", fontSize: 20 }}
                >
                  Fleet Kaptan
                </Typography> */}
              </Grid>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 700,
                  // fontSize: "40px",
                  textAlign: "center",
                  margin: "20px 0px",
                }}
              >
                Password Recovery
              </Typography>
              <div className="txtInp">
                {!otpscr ? (
                  <>
                    <Typography
                      style={{
                        color: "#4573B8",
                        fontWeight: "bold",
                        marginBottom: 8,
                      }}
                    >
                      Enter Phone No. Linked To Your Account
                    </Typography>
                    <TextField
                      fullWidth
                      value={num}
                      margin="normal"
                      required
                      style={{
                        margin: 0,
                        marginBottom: errObj.numErr ? 30 : 20,
                      }}
                      placeholder="Please enter Phone No."
                      id="number"
                      name="Text"
                      autoFocus
                      onChange={(e) => {
                        setNum(e.target.value.replace(/[^0-9]/g, ""));
                        setErrObj({ ...errObj, numErr: false, numMsg: "" });
                      }}
                      onKeyPress={(ev) => {
                        if (ev.key === "Enter") {
                          ev.preventDefault();
                          checkValidation();
                        }
                      }}
                      error={errObj.numErr}
                      helperText={errObj.numErr ? errObj.numMsg : null}
                      inputProps={{
                        onWheel: (event) => event.currentTarget.blur(),
                        maxLength: 11,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">+91</InputAdornment>
                        ),
                      }}
                    />
                  </>
                ) : (
                  <>
                    <Typography
                      style={{
                        color: "#4573B8",
                        fontWeight: "bold",
                        marginBottom: 8,
                      }}
                    >
                      Enter OTP
                    </Typography>
                    <FormControl
                      fullWidth
                      error={errObj.otpErr}
                      style={{ marginBottom: errObj.otpErr ? 30 : 20 }}
                    >
                      <OutlinedInput
                        value={otp}
                        margin="normal"
                        required
                        type={showPassword ? "text" : "password"}
                        style={{
                          width: "100%",
                          margin: 0,
                        }}
                        error={errObj.otpErr}
                        helperText={errObj.otpErr ? errObj.otpMsg : null}
                        name="otp"
                        placeholder="Please enter your OTP"
                        id="otp"
                        autoComplete="current-otp"
                        onChange={(e) => {
                          setOTP(e.target.value.replace(/[^0-9]/g, ""));
                          setErrObj({ ...errObj, otpErr: false, otpMsg: "" });
                        }}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                          maxLength: 4,
                        }}
                        onKeyPress={(ev) => {
                          if (ev.key === "Enter") {
                            ev.preventDefault();
                            checkValidation();
                          }
                        }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle otp visibility"
                              onClick={() => setShowPassword(!showPassword)}
                            >
                              {!showPassword ? (
                                <Visibility />
                              ) : (
                                <VisibilityOff />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {errObj.otpErr ? (
                        <FormHelperText>{errObj.otpMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                    <Grid item container>
                      <Typography>Did't Received OTP?</Typography>
                      <Typography
                        sx={{
                          color: "#4573B8",
                          fontWeight: "bold",
                          marginLeft: "8px",
                          ":hover": {
                            textDecoration: "underline",
                            cursor: "pointer",
                          },
                        }}
                        onClick={resendOtp}
                      >
                        Resend OTP
                      </Typography>
                    </Grid>
                  </>
                )}
              </div>

              <Grid item container style={{ marginTop: 20 }}>
                <Button
                  fullWidth
                  style={{
                    backgroundColor: "#4573B8",
                    fontWeight: "bold",
                  }}
                  onClick={checkValidation}
                  justifyContent={"center"}
                  variant="contained"
                  disabled={loader}
                >
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={26} />
                  ) : otpscr ? (
                    "Submit"
                  ) : (
                    "Send OTP"
                  )}
                </Button>
              </Grid>
              <Grid item container justifyContent={"center"}>
                <Typography
                  className="forgotPassword"
                  onClick={() => {
                    navigate("/");
                    setUserID("");
                  }}
                >
                  Back to Login?
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
