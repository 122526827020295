import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  TableRow,
  TableCell,
  Table,
  CircularProgress,
  Pagination,
  MenuItem,
  Select,
  Grid,
  Button,
  InputAdornment,
  TextField,
  useMediaQuery,
  ListItemButton,
  Tooltip,
  Tabs,
  Tab,
  Divider,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Edit, FilterAlt, Search } from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import { useTheme } from "@emotion/react";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chart from "react-google-charts";
import html2canvas from "html2canvas";
import CDateRange from "../../Modal/CDateRange/index.js";

export default function ScrapDetails(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    fleetData,
  } = props;
  const styles = useStyles();

  const { token, dropDownData, isOnline, permissionData, userdata } =
    useSelector((state) => state.auth);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const [tabValue, setTabValue] = useState(0);
  const [fleetDetails, setFleetDetails] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [pageLoad1, setPageLoad1] = useState(false);
  // const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [tyreBrandList, settyreBrandList] = useState(false);
  const [tyreCompositionList, settyreCompositionList] = useState(false);
  const [issueList, setIssueList] = useState(false);
  const [scrapListRows, setScrapListRows] = useState({});
  const [visible, setVisible] = useState(false);

  const [reportData, setReportData] = useState([]);
  const [reportLoader, setReportLoader] = useState(false);
  const [downloadLoader, setDownloadLoader] = useState(false);

  const scrapDetailsColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title="Edit" placement="right" arrow>
            <Edit
              style={{ color: color.primary, cursor: "pointer" }}
              onClick={() => {
                handleClick("addScrapTyre", params?.row, "details");
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 220,
    },
    {
      field: "tyre_pattern",
      headerName: "Item Description",
      width: 300,
    },
    // {
    //   field: "tread_pattern",
    //   headerName: "Tread Pattern",
    //   width: 250,
    // },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 180,
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 200,
    },
    {
      field: "ply_rating_id",
      headerName: "Ply Rating",
      width: 200,
    },
    {
      field: "Tyre issue",
      headerName: "Tyre Issue",
      width: 220,
      renderCell: (params) => {
        let array = params?.row?.issue;
        return !isEmpty(array) ? (
          <div
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {array.map((items, index) => {
              const isTwo = index <= 1;
              return isTwo ? (
                <Typography>
                  {items}
                  {index !== array.length - 1 ? ", " : ""}
                </Typography>
              ) : null;
            })}
            {array.length > 2 && (
              <span style={{ color: color.placeholder }}>{`+ ${
                array.length - 2
              } more`}</span>
            )}
          </div>
        ) : (
          <Typography variant="tableHead">-</Typography>
        );
      },
    },
    {
      field: "NSD",
      headerName: "Current NSD",
      width: 220,
      renderCell: (params) => {
        const nsdArr = params?.row?.current_nsd;
        let groove1 = 0;
        let groove2 = 0;
        let groove3 = 0;
        let groove4 = 0;
        let count1 = 1;
        let count2 = 1;
        let count3 = 1;
        let count4 = 1;
        isArray(nsdArr) &&
          !isEmpty(nsdArr) &&
          nsdArr.map((v) => {
            if (!isEmpty(v?.groove1)) {
              groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
              count1++;
            }
            if (!isEmpty(v?.groove2)) {
              groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
              count2++;
            }
            if (!isEmpty(v?.groove3)) {
              groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
              count3++;
            }
            if (!isEmpty(v?.groove4)) {
              groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
              count4++;
            }
          });
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
              groove2 !== 0 ? groove2.toFixed(2) : " - "
            }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
              groove4 !== 0 ? groove4.toFixed(2) : " - "
            } mm`}
          </Typography>
        );
      },
    },
    {
      field: "avg_nsd",
      headerName: "Avg. NSD",
      width: 220,
      renderCell: (params) => {
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${params?.row?.avg_nsd} mm` || "-"}
          </Typography>
        );
      },
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: 220,
      renderCell: (params) => {
        return params?.row?.remark || "-";
      },
    },
    {
      field: "capture_date",
      headerName: "Added On",
      width: 220,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
            onClick={() => {
              console.log(params?.row);
            }}
          >
            {params?.row?.capture_date ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Added By",
      width: 220,
    },
  ];

  const [Nsd6Display, setNsd6Display] = useState(true);

  useEffect(() => {
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Scrap Analysis (NSD > 6 mm)") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              setNsd6Display(v?.isActive);
          }
        });
      });
    }
  }, [permissionData]);

  function resetFilterData() {
    handleFilter("");
    setSearchFilter("");
    handlePageNo(1);
  }

  useEffect(() => {
    getFleetDetails();
    getScrapGraphData();
  }, []);

  useEffect(() => {
    getscrapDetailsList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    filter === "" && isEmpty(searchFilter) && getscrapDetailsList(false);
  }, [filter, searchFilter]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Scrap Analysis`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  const getFleetDetails = async () => {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetdetails}?id=${fleetData.fleet_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setFleetDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  async function getscrapDetailsList(bool, v) {
    v && setDownloadLoader(true);
    !v && setPageLoad1(true);
    let endpoints = `${
      Setting.endpoints.scrapdetails
    }?page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&fleet_id=${fleetData?.fleet_id}&filter=${filter}&is_download=${
      v ? v : ""
    }`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadScrapData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setScrapListRows(listObj);
          }
        setDownloadLoader(false);
        v && auditSave("Scrap Summary List");
      } else {
        toast.error(response?.message);
      }
      setPageLoad1(false);
    } catch (error) {
      setPageLoad1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function downloadScrapData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.created_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const nsdArr = row?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.tyre_pattern || "-",
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating_id || "-",
        "Tyre Issue": row?.issue?.toString() || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(row?.avg_nsd) || "-",
        Remarks: row?.remark || "-",
        "Added On": row?.created_at ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Scrap Summary List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Scrap Summary List.xlsx");
  }

  function creatScrapGraph(data) {
    const dummy_Arr = [["", "data"]];

    data.map((item) => {
      const arr = [item?.issue, Number(item?.count)];
      dummy_Arr.push(arr);
    });
    setReportData(dummy_Arr);
    setReportLoader(false);
  }

  async function getScrapGraphData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.scrapIssueReport}?fleet_id=${fleetData.fleet_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          creatScrapGraph(response?.data);
        }
      } else {
        toast.error(response?.message);
        setReportLoader(false);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  function printDocument() {
    const input = document.getElementById("downlaodChart");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      var a = document.createElement("a"); //Create <a>
      a.href = imgData; //Image Base64 Goes here
      a.download = "Scrap Reports.png"; //File name Here
      a.click(); //Downloaded file
    });
  }

  async function getNsdReport(fromDate, toDate) {
    const from_date = !isNull(fromDate)
      ? moment(fromDate).format("YYYY-MM-DD")
      : "";
    const to_date = !isNull(toDate) ? moment(toDate).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        `${Setting.endpoints.scrapnsdreport}?fleet_id=${fleetData?.fleet_id}&from_date=${from_date}&to_date=${to_date}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response?.data)) {
          downloadNsdReport(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
      } else {
        toast.error(response?.message);
      }
      auditSave("NSD > 6 mm");
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  const downloadNsdReport = (data) => {
    const newData = data.map((row, index) => {
      const timestamp = row?.added_date;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const nsdArr = row?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.item_descryption || "-",
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating || "-",
        "Tread pattern": row?.tread_pattern || "-",
        "Tyre Issue": row?.tyre_issue?.toString() || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(row?.avg_nsd) || "-",
        "Added On": row?.added_date ? displayDate : "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "NSD > 6 mm");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "NSD > 6 mm.xlsx");
  };
  return (
    <>
      <div className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={"bold"}>Fleet Details</Typography>
        </Box>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : (
          <div style={{ padding: 20 }}>
            <Table>
              <TableRow>
                <TableCell variant="head">Name</TableCell>
                <TableCell>{fleetDetails?.name || "-"}</TableCell>
                <TableCell variant="head">Owner</TableCell>
                <TableCell>{fleetDetails?.owner_name || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Fleet Code</TableCell>
                <TableCell>{fleetDetails?.code || "-"}</TableCell>
                <TableCell variant="head">Owner’s No.</TableCell>
                <TableCell>{fleetDetails?.owner_phone || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Location</TableCell>
                <TableCell>{fleetDetails?.city || "-"}</TableCell>
                <TableCell variant="head">Manager</TableCell>
                <TableCell>{fleetDetails?.manager_name || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">No. of Scrap Tyres </TableCell>
                <TableCell>{fleetDetails?.no_of_scrap_tyres || "-"}</TableCell>
                <TableCell variant="head">Manager’s No.</TableCell>
                <TableCell>{fleetDetails?.manager_phone || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Analyzed By</TableCell>
                <TableCell>{fleetDetails?.analyzed_by || "-"}</TableCell>
                <TableCell variant="head">Employee Code</TableCell>
                <TableCell>
                  {fleetDetails?.sales_employee_code || "-"}
                </TableCell>
              </TableRow>
            </Table>
          </div>
        )}
      </div>
      <div className={styles.container} style={{ marginTop: 20, padding: 20 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ height: 40 }}
        >
          <Grid item xs={12} sm={6}>
            <Typography variant="tableTitle">Scrap Details</Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={6}
            style={{
              display: "flex",
              justifyContent: sm ? "unset" : "flex-end",
            }}
          >
            {tabValue === 0 && (
              <Grid>
                <Button
                  style={{ marginLeft: 10 }}
                  variant="contained"
                  onClick={() => {
                    handleClick("addScrapTyre", {}, "details");
                  }}
                >
                  Add Scrap Tyre
                </Button>

                <Button
                  variant="contained"
                  disabled={isOnline ? false : true}
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  onClick={() => {
                    if (tabValue === 0) {
                      getscrapDetailsList(false, 1);
                    }
                  }}
                >
                  {downloadLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    "Download"
                  )}
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: "#BBBBBB" }}>
          <Tabs
            value={tabValue}
            onChange={(v, b) => {
              handlePageNo(1);
              handleRecord(10);
              handleFilter("");
              setTabValue(b);
            }}
            variant="scrollable"
          >
            <Tab className={styles.tabBtn} label="Summary" />
            <Tab className={styles.tabBtn} label="Reports" />
          </Tabs>
        </Box>
        {tabValue === 0 ? (
          <div>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 14 }}
              wrap="nowrap"
            >
              <Grid
                item
                lg={10.4}
                wrap="nowrap"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item lg={1.6}>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={filter}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        settyreBrandList(false);
                        settyreCompositionList(false);
                        setIssueList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem
                      value={""}
                      hidden
                      selected
                      style={{ minWidth: 220 }}
                    >
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        settyreBrandList(!tyreBrandList);
                        settyreCompositionList(false);
                        setIssueList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Brand</Typography>
                      {tyreBrandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_brand.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: tyreBrandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        settyreCompositionList(!tyreCompositionList);
                        settyreBrandList(false);
                        setIssueList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Composition</Typography>
                      {tyreCompositionList ? (
                        <ExpandLessIcon />
                      ) : (
                        <ExpandMoreIcon />
                      )}
                    </ListItemButton>
                    {dropDownData.tyre_composition.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: tyreCompositionList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        settyreCompositionList(false);
                        settyreBrandList(false);
                        setIssueList(!issueList);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Issue</Typography>
                      {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_issue_scrap_analysis.map(
                      (item, index) => {
                        return (
                          <MenuItem
                            style={{
                              display: issueList ? "flex" : "none",
                            }}
                            key={index}
                            value={item?.id}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      }
                    )}
                  </Select>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  lg={10.4}
                  wrap="nowrap"
                  style={{ margin: 0 }}
                >
                  <Grid item lg={4} style={{ marginLeft: 20 }}>
                    <TextField
                      fullWidth
                      placeholder="Search"
                      className={styles.inputFieldStyle}
                      value={searchFilter}
                      onChange={(v) => {
                        isEmpty(v.target.value) && getscrapDetailsList(true);
                        setSearchFilter(v?.target?.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <div style={{ lineHeight: 0 }}>
                              <Search style={{ fontSize: 20 }} />
                            </div>
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <div style={{ lineHeight: 0, cursor: "pointer" }}>
                              <Tooltip
                                title="Search by Tyre Serial No., Item Description, Avg Current NSD and Added By"
                                placement="bottom"
                                arrow
                              >
                                <InfoIcon style={{ fontSize: 20 }} />
                              </Tooltip>
                            </div>
                          </InputAdornment>
                        ),
                      }}
                      onKeyDown={(v) => {
                        if (v.key === "Enter") {
                          if (!isEmpty(searchFilter)) {
                            handlePageNo(1);
                            getscrapDetailsList(false);
                          }
                        }
                      }}
                    />
                  </Grid>
                  <Grid item wrap="nowrap" style={{ marginLeft: 20 }}>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: color.secondary }}
                      onClick={() => {
                        if (!isEmpty(searchFilter)) {
                          handlePageNo(1);
                          getscrapDetailsList(false);
                        }
                      }}
                    >
                      Search
                    </Button>
                    <Button
                      variant="contained"
                      style={{ marginLeft: 10 }}
                      onClick={resetFilterData}
                      disabled={
                        filter === "" && isEmpty(searchFilter) ? true : false
                      }
                    >
                      Reset
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: 10,
                }}
              >
                <Typography style={{ fontSize: 14 }}>Records:</Typography>
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleRecord(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {pageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(scrapListRows?.data) &&
              !isEmpty(scrapListRows?.data) ? (
              <div style={{ marginTop: 20 }}>
                <DataGrid
                  rows={scrapListRows?.data}
                  columns={scrapDetailsColumn}
                  disableColumnMenu
                  hideFooter
                  showCellRightBorder
                  disableSelectionOnClick
                  showColumnRightBorder
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                />
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(scrapListRows?.pagination?.totalPage)}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        ) : null}
        {tabValue === 1 ? (
          <div>
            {Nsd6Display && (
              <div style={{ padding: "0px 10px 0px 10px" }}>
                <Grid
                  container
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                  style={{ marginTop: 20, marginBottom: 20 }}
                >
                  <Typography>{`NSD > 6 mm - ${fleetData?.name}`}</Typography>
                  <Button
                    disabled={isOnline ? false : true}
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                      marginRight: 10,
                    }}
                    onClick={() => setVisible(true)}
                  >
                    Download
                  </Button>
                </Grid>
              </div>
            )}
            <Divider />
            {reportLoader ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(reportData) && !isEmpty(reportData) ? (
              <>
                <Grid
                  item
                  container
                  justifyContent="flex-end"
                  style={{ padding: 20 }}
                >
                  <Button
                    disabled={isOnline ? false : true}
                    style={{
                      backgroundColor: isOnline
                        ? color.secondary
                        : color.bordercolor,
                    }}
                    onClick={() => {
                      auditSave("Scrap Reports Graph");
                      printDocument();
                    }}
                  >
                    Download
                  </Button>
                </Grid>
                <div
                  id="downlaodChart"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                    padding: 20,
                    height: 400,
                  }}
                >
                  <Typography variant="tableTitle" style={{ marginBottom: 20 }}>
                    {`Scrap Analysis - ${fleetData?.name}`}
                  </Typography>
                  <Chart
                    chartType="Bar"
                    data={reportData}
                    width="100%"
                    height="98%"
                    options={{
                      colors: ["#32C5D2"],
                      legend: { position: "none" },
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        ) : null}
      </div>
      <CDateRange
        isSelect
        disableFleet={true}
        disableVehicle={true}
        visible={visible}
        handleModal={(type, fleetIds, vehicleIds, fromDate, toDate, isAll) => {
          if (type === "download") {
            getNsdReport(fromDate, toDate);
          }
          setVisible(false);
        }}
      />
    </>
  );
}
