import React, { useEffect, useMemo, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  CircularProgress,
  MenuItem,
  Select,
  Grid,
  Button,
  TextField,
  FormControl,
  FormHelperText,
  ImageListItemBar,
  ImageListItem,
  ImageList,
  Modal,
  Autocomplete,
  Table,
  TableRow,
  TableHead,
  TableCell,
  Backdrop,
  useMediaQuery,
} from "@mui/material";
import Divider from "@mui/material/Divider";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { cloneDeep, isArray, isEmpty, isNull, isObject } from "lodash";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import authActions from "../../../Redux/reducers/auth/actions";
import moment from "moment";
import ConfirmDialog from "../../ConfirmDialog/index.js";
import { isTablet } from "react-device-detect";
import { ResetIcon } from "../../Icon/TableToggle";
import { Close, Delete, Edit, SearchOutlined } from "@mui/icons-material";
import Images from "../../../Config/Images.js";
import { useTheme } from "@emotion/react";

const errorObj = {
  kmErr: false,
  kmMsg: "",
  DkmErr: false,
  DkmMsg: "",
  dateErr: false,
  dateMsg: "",
  fleetErr: false,
  fleetMsg: "",
  vehicleErr: false,
  vehicleMsg: "",
  horseVehicleErr: false,
  horseVehicleMsg: "",
  remarkErr: false,
  remarkMsg: "",
  tyreswapErr: false,
  tyreswapMsg: "",
};

export default function TabAddInspection(props) {
  const { handleClick = () => null, from, removalData, scroll } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const { setFleetData, setInspectionData } = authActions;

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { token, fleetData, dropDownData, inspectionData, isOnline } =
    useSelector((state) => state.auth);

  const [horseTrailer, setHorseTrailer] = useState({
    id: "",
    trailer_no: "",
  });
  const [errObj, setErrObj] = useState(errorObj);
  const [pageLoad, setPageLoad] = useState(false);
  const [stopCall, setstopCall] = useState(false);
  const [tyreScroll, setTyreScroll] = useState(scroll);

  const [fleet, setFleet] = useState({ id: "", label: "" });
  const [vehicle, setVehicle] = useState({ id: "", label: "" });
  const [tyreList, setTyreList] = useState([]);
  const [fReading, setFReading] = useState("");
  const [kmReading, setKmReading] = useState("");
  const [remarks, setRemarks] = useState("");
  const [remarksChcek, setRemarksCheck] = useState("");
  const [datePicker, setDatePicker] = useState(new Date());
  const [confirmDialog1, setConfirmDialog1] = useState(false);
  const [removeBtnLoad1, setRemoveBtnLoad1] = useState(false);

  const [inspectedDate, setInspectedDate] = useState("");
  const [inspecteBy, setInspectedBy] = useState("");

  const [swapModal, setSwapModal] = useState(false);
  const [openInfo, setOpenInfo] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [ind, setInd] = useState("");
  const dummytyreArr = cloneDeep(dropDownData?.tyre_issue_inspection);
  const [tyreRemovalArr, setTyreRemovalArr] = useState(dummytyreArr);

  const [isMechanicle, setIsMechanicle] = useState(false);

  const [modal, openModal] = useState(false);
  const [imgURL, setimgURL] = useState("");

  const dummymechanicleArr = cloneDeep(dropDownData?.mechanical_issue);
  const [mechanicleRemovalArr, setMechanicleRemovalArr] =
    useState(dummymechanicleArr);

  const arrData = isMechanicle ? mechanicleRemovalArr : tyreRemovalArr;

  const [tyreSerial, setTyreSerial] = useState([]);
  const [vehicleList, setVehicleList] = useState([]);
  const [horseTrailerList, setHorseTrailerList] = useState([]);
  const [tsnList, setTSNList] = useState([]);
  const [selectedTSN, setselectedTSN] = useState([]);
  const [isHorse, setIsHorse] = useState(0);
  const [tsnInfo, setTSNinfo] = useState([]);

  const [disableReading, setDisableReading] = useState(false);
  const [readingErr, setReadingErr] = useState(false);
  const [SwapTrailer, setSwapTrailer] = useState("");
  const [loader, setLoader] = useState(false);
  const [idealTrailer, setIdealTrailer] = useState(1);
  const [dkmReading, setDKmReading] = useState("");
  const [lastInspection, setLastInspection] = useState("");

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 320 : 500,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
  };

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: sm ? 320 : 600,
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
  };

  useEffect(() => {
    if (from === "inside") {
      setDatePicker(removalData?.captureDate);
    }
  }, [from, removalData]);

  useEffect(() => {
    if (from === "inside" || from === "dashboard") {
      setFleet(removalData?.fleet);
    }
  }, [fleetData]);

  useEffect(() => {
    if (from === "inside" || from === "dashboard") {
      setVehicle(removalData?.vehicle);
    }
  }, [vehicleList]);

  useEffect(() => {
    if (from === "inside" && !stopCall) {
      if (!isEmpty(fleet?.label) && !isEmpty(vehicle?.label)) {
        checkGetValidation();
        setstopCall(true);
      }
    }
  }, [vehicle]);

  useEffect(() => {
    resetFormData(true);
    getVehicleist();
    getTSNist();
  }, [fleet]);

  useEffect(() => {
    resetFormData(false);
  }, [vehicle, datePicker]);

  useEffect(() => {
    getFleetData();
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);

  useEffect(() => {
    if (
      !pageLoad &&
      isArray(tyreList) &&
      !isEmpty(tyreList) &&
      !isEmpty(tyreScroll)
    ) {
      const section = document.querySelector(`#jump_to_me${scroll}`);
      section.scrollIntoView({ behavior: "smooth", block: "end" });
    }
  }, [tyreScroll, pageLoad]);

  useEffect(() => {
    setTyreList(cloneDeep(inspectionData));
  }, [inspectionData]);

  useEffect(() => {
    const dummy_arr = [...tyreList];
    let valid = false;

    isArray(dummy_arr) &&
      dummy_arr.map((item) => {
        if (item?.is_added === 1) {
          valid = true;
        }
      });

    if (valid) {
      setDisableReading(true);
    } else if (!valid && !disableReading) {
      setDisableReading(false);
    }
  }, [tyreList]);

  useEffect(() => {
    createTSNArr();
  }, [tyreList]);

  useEffect(() => {
    if (!isEmpty(horseTrailer?.id?.toString())) {
      checkTrailerIdeal();
    }
  }, [horseTrailer]);

  async function getFleetData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.FleetData}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          dispatch(setFleetData(response?.data));
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  function setCurrentNSD(arr) {
    arr.map((item, index) => {
      if (isEmpty(item?.current_nsd)) {
        item.current_nsd = [
          {
            groove1: "",
            groove2: "",
            groove3: "",
            groove4: "",
            id: Math.floor(Math.random() * 1000 + 1) + index,
          },
        ];
      }
    });

    dispatch(setInspectionData(arr));
  }

  async function checkTrailerIdeal() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.checktrailer}?id=${horseTrailer?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          setIdealTrailer(response?.data?.is_ideal);
          setLastInspection(response?.data?.reading);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  async function getHorseVehicleist() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.listtrailer}?fleet_id=${fleet?.id}&vehicle_id=${vehicle?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setHorseTrailerList(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getTSNist() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.getTSNdata}?fleet_id=${fleet?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setTSNList(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getTSNinfo() {
    const TSNIDS = [];
    selectedTSN.map((item) => {
      TSNIDS.push(item?.id);
    });
    try {
      const response = await getApiData(
        `${Setting.endpoints.getTSNinfo}?ids=${TSNIDS}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setTSNinfo(response?.data);
          setOpenInfo(true);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function findAvgNSD() {
    const dummy_arr = [...tyreList];

    dummy_arr.map((item, index) => {
      let groove = 0;
      let divide = 0;

      item?.current_nsd.map((v, index) => {
        if (!isEmpty(v?.groove1)) {
          groove = Number(v?.groove1) + groove;
          divide++;
        }
        if (!isEmpty(v?.groove2)) {
          groove = Number(v?.groove2) + groove;
          divide++;
        }
        if (!isEmpty(v?.groove3)) {
          groove = Number(v?.groove3) + groove;
          divide++;
        }
        if (!isEmpty(v?.groove4)) {
          groove = Number(v?.groove4) + groove;
          divide++;
        }
      });
      const mainAVG = groove / divide;
      item.avg_nsd = isNaN(mainAVG) ? "" : mainAVG.toFixed(2);
    });

    dispatch(setInspectionData(dummy_arr));
  }

  async function getVehicleist() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicleData}?fleet_id=${fleet?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (!isEmpty(response.data) && isArray(response?.data)) {
          setVehicleList(response?.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getTyreList() {
    setPageLoad(true);
    const displayDate = moment(datePicker).format("YYYY-MM-DD");
    try {
      const response = await getApiData(
        `${Setting.endpoints.addinspectiontyrelist}?vehicle_id=${vehicle?.id}&capture_date=${displayDate}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        converter(response?.data?.fitment_reading);
        setInspectedDate(response?.data?.last_inspected_date);
        setInspectedBy(response?.data?.last_inspected_by);
        setRemarks(response?.data?.global_remark);
        setIsHorse(response?.data?.is_horse_trailer);
        setRemarksCheck(response?.data?.global_remark);
        !isEmpty(response?.data?.reading) &&
          converter(response?.data?.reading, "fitment");
        if (response?.data?.reading && !isEmpty(response?.data?.reading)) {
          setDisableReading(true);
        } else {
          setDisableReading(false);
        }
        if (isArray(response.data.data) && !isEmpty(response.data.data)) {
          setCurrentNSD(response?.data.data);
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const getTyreSerial = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreserialno}?vehicle_id=${vehicle?.id}&is_inspection=1`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setTyreSerial(response.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  async function addMainInspection() {
    setRemoveBtnLoad1(true);

    const captureDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";

    let endPoints = Setting.endpoints.saveinspection;
    const params = {
      "SaveInspectionForm[vehicle_id]": vehicle?.id,
      "SaveInspectionForm[global_remark]": remarks,
      "SaveInspectionForm[capture_date]": captureDate,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        handleClick("cancel", "");
        setConfirmDialog1(false);
      } else {
        toast.error(resp?.message);
      }
      setRemoveBtnLoad1(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setRemoveBtnLoad1(false);
    }
  }

  function createTSNArr() {
    const dummy_Arr = [...tyreSerial];
    isArray(tyreList) &&
      !isEmpty(tyreList) &&
      tyreList?.map((item) => {
        if (item?.is_added === 1) {
          const remove = dummy_Arr?.findIndex(
            (v) => v?.tyre_serial_no === item?.tyre_serial_no
          );

          remove !== -1 && dummy_Arr.splice(remove, 1);
        }
      });

    setTyreSerial(dummy_Arr);
  }

  function arrChange(value, index, type, nsdI) {
    const dummy_arr = [...tyreList];
    let Obj1 = {};
    let Obj2 = {};

    dummy_arr.map((item, i) => {
      if (i === index) {
        if (type === "pressure") {
          item.tyre_air_pressure = value;
        } else if (type === "condition") {
          item.condition = value;
        } else if (type === "position") {
          item.tyre_position = value;
        } else if (type === "serial") {
          Obj1 = { ...item };
          dummy_arr.map((v) => {
            if (v?.tyre_serial_no === value.toString()) {
              Obj2 = { ...v };
              if (v?.is_added === 0) {
                item.tyre_brand = Obj2.tyre_brand;
                item.tyre_pattern_size = Obj2.tyre_pattern_size;
                item.current_nsd = Obj2.current_nsd;
                item.avg_nsd = Obj2.avg_nsd;
                item.tyre_air_pressure = Obj2.tyre_air_pressure;
                item.tyre_issue = Obj2.tyre_issue;
                // item.mechanical_issue = Obj2.mechanical_issue;
                item.condition = Obj2.condition;
                item.issue_image = Obj2.issue_image;
                item.remark = Obj2.remark;

                v.tyre_brand = Obj1.tyre_brand;
                v.tyre_pattern_size = Obj1.tyre_pattern_size;
                v.current_nsd = Obj1.current_nsd;
                v.avg_nsd = Obj1.avg_nsd;
                v.tyre_air_pressure = Obj1.tyre_air_pressure;
                v.tyre_issue = Obj1.tyre_issue;
                // v.mechanical_issue = Obj1.mechanical_issue;
                v.condition = Obj1.condition;
                v.issue_image = Obj1.issue_image;
                v.tyre_serial_no = Obj1.tyre_serial_no;
                v.remark = Obj1.remark;
              }
            }
          });
          item.tyre_serial_no = value;
        } else if (type === "image") {
          item.issue_image = value;
        } else if (type === "issue") {
          item.tyre_issue = value;
        } else if (type === "mechanicalIssue") {
          item.mechanical_issue = value;
        } else if (type === "remarks") {
          item.remark = value;
        }

        item?.current_nsd.map((v, ii) => {
          if (ii === nsdI) {
            if (type === 1) {
              v.groove1 = value;
            } else if (type === 2) {
              v.groove2 = value;
            } else if (type === 3) {
              v.groove3 = value;
            } else if (type === 4) {
              v.groove4 = value;
            }
          }
        });
      }
    });
    (type === 1 || type === 2 || type === 3 || type === 4) && findAvgNSD();
    dispatch(setInspectionData(dummy_arr));
  }

  function SelectTyre(index) {
    const DummyArr = isMechanicle
      ? [...mechanicleRemovalArr]
      : [...tyreRemovalArr];

    DummyArr.map((item, i) => {
      if (i === index) {
        if (item?.selected) {
          if (item?.label === "N/A") {
            DummyArr.map((item1, i) => {
              if (item1?.label !== "N/A") {
                item1.disable = false;
              } else {
                item1.selected = false;
              }
            });
          } else {
            item.selected = false;
          }
        } else {
          if (item?.label === "N/A") {
            DummyArr.map((item1, i) => {
              if (item1?.label !== "N/A") {
                item1.selected = false;
                item1.disable = true;
              } else {
                item1.selected = true;
              }
            });
          } else {
            item.selected = true;
          }
        }
      }
    });
    isMechanicle
      ? setMechanicleRemovalArr(DummyArr)
      : setTyreRemovalArr(DummyArr);
  }

  function handleSelect() {
    const DummyArr = isMechanicle
      ? [...mechanicleRemovalArr]
      : [...tyreRemovalArr];
    const selected = DummyArr?.filter((v) => v.selected === true);
    const array = [];
    if (!isEmpty(selected) && isArray(selected)) {
      selected.map((v) => {
        array.push(v.id);
      });
      arrChange(array, ind, isMechanicle ? "mechanicalIssue" : "issue");
    }
    setOpenCard(false);
    setIsMechanicle(false);
  }

  function checkMainValidation() {
    const dummy_arr = [...tyreList];
    const error = { ...errObj };
    let valid = true;
    let showErr = false;

    if (isEmpty(remarks)) {
      valid = false;
      error.remarkErr = true;
      error.remarkMsg = "Please Enter Remarks";
    }
    dummy_arr.map((item) => {
      if (item?.is_added === 0) {
        showErr = true;
        valid = false;
      }
    });

    setErrObj(error);
    if (showErr) {
      toast.error(
        "Before proceeding ahead, please save inspection data tyre wise",
        { toastId: 34 }
      );
    }
    if (valid) {
      setConfirmDialog1(true);
    }
  }

  function checkGetValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(fleet?.label) || fleet === undefined || fleet === "") {
      valid = false;
      error.fleetErr = true;
      error.fleetMsg = "Please select the Fleet";
    }

    if (isEmpty(vehicle?.label) || vehicle === undefined || vehicle === "") {
      valid = false;
      error.vehicleErr = true;
      error.vehicleMsg = "Please select the Vehicle No.";
    }

    let foundAddedDateInRange = moment(datePicker).isBetween(
      "2000-01-01",
      new Date()
    );
    if (isNull(datePicker)) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please select Added Date";
    } else if (
      !isNull(datePicker) &&
      (datePicker === "Invalid Date" || !foundAddedDateInRange)
    ) {
      valid = false;
      error.dateErr = true;
      error.dateMsg = "Please enter valid Date";
    }

    setErrObj(error);
    if (valid) {
      resetFormData(false);
      getTyreSerial();
      getTyreList();
      handleClick("empty", "");
      getHorseVehicleist();
    }
  }

  function resetFormData(bool) {
    setErrObj(errorObj);
    setTyreRemovalArr(dummytyreArr);
    setMechanicleRemovalArr(dummymechanicleArr);
    dispatch(setInspectionData([]));
    setselectedTSN([]);
    setTSNinfo([]);
    setOpenInfo(false);
    setHorseTrailer({
      id: "",
      trailer_no: "",
    });
    setSwapTrailer("");
    setIdealTrailer(1);
    setSwapModal(false);
    setKmReading("");
    setLastInspection("");
    setRemarks("");
    setRemarksCheck("");
    if (bool) {
      setVehicle({});
      setVehicleList([]);
    }
  }

  function numberWithCommas(x) {
    let c = x.replaceAll(",", "");

    return c.toString().split(".")[0].length > 3
      ? c
          .toString()
          .substring(0, c.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c.toString().substring(c.toString().split(".")[0].length - 3)
      : c.toString();
  }

  function converter(value, type) {
    let convert = numberWithCommas(value);
    if (type !== "fitment") {
      setFReading(convert);
    } else {
      setKmReading(convert);
    }
    setErrObj({
      ...errObj,
      kmErr: false,
      kmMsg: "",
    });
  }
  const getNSD = (index) => {
    const formValues = tyreList[index]?.current_nsd;
    return (
      <div>
        {isTablet ? (
          <>
            {formValues?.map((v, ii) => {
              return (
                <Grid
                  key={`new_${v?.id}_${index}`}
                  container
                  style={{
                    marginBottom: 10,
                    border: v?.nsdErr ? "1px solid red" : "",
                  }}
                  alignItems={"center"}
                  id={`nsdErr_${index}`}
                  display="flex"
                  wrap="nowrap"
                >
                  <TextField
                    disabled={tyreList[index]?.is_added === 1}
                    className={styles.inputField}
                    style={{ width: 90, marginRight: 10 }}
                    placeholder="Groove 1"
                    value={v.groove1}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                      readOnly: true,
                    }}
                    onChange={(e) => {
                      arrChange(e?.target?.value, index, 1, ii);
                    }}
                  />
                  <TextField
                    disabled={tyreList[index]?.is_added === 1}
                    className={styles.inputField}
                    style={{ width: 90, marginRight: 10 }}
                    placeholder="Groove 2"
                    value={v.groove2}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                      readOnly: true,
                    }}
                    onChange={(e) => {
                      arrChange(e?.target?.value, index, 2, ii);
                    }}
                  />
                  <TextField
                    disabled={tyreList[index]?.is_added === 1}
                    className={styles.inputField}
                    style={{ width: 90, marginRight: 10 }}
                    placeholder="Groove 3"
                    value={v.groove3}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                      readOnly: true,
                    }}
                    onChange={(e) => {
                      arrChange(e?.target?.value, index, 3, ii);
                    }}
                  />
                  <TextField
                    disabled={tyreList[index]?.is_added === 1}
                    className={styles.inputField}
                    style={{ width: 90, marginRight: 10 }}
                    placeholder="Groove 4"
                    value={v.groove4}
                    type={"number"}
                    inputProps={{
                      onWheel: (event) => event.currentTarget.blur(),
                      readOnly: true,
                    }}
                    onChange={(e) => {
                      arrChange(e?.target?.value, index, 4, ii);
                    }}
                  />
                </Grid>
              );
            })}
          </>
        ) : (
          <>
            {formValues?.map((v, ii) => {
              const isLast = ii === formValues.length - 1;
              return (
                <Grid
                  key={`new_${v?.id}_${index}`}
                  container
                  style={{
                    marginBottom: 10,
                    border: v?.nsdErr ? "1px solid red" : "",
                  }}
                  alignItems={"center"}
                  id={`nsdErr_${index}`}
                  display="flex"
                  wrap="nowrap"
                >
                  <Grid item xs={10} display="flex" wrap="nowrap" columnGap={1}>
                    <Grid
                      item
                      wrap="nowrap"
                      style={{ marginTop: 5, marginRight: 10 }}
                    >
                      <TextField
                        disabled={tyreList[index]?.is_added === 1}
                        className={styles.inputField}
                        style={{ width: "100%", marginBottom: 5 }}
                        placeholder="Groove 1"
                        value={v.groove1}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                          readOnly: true,
                        }}
                        onChange={(e) => {
                          arrChange(e?.target?.value, index, 1, ii);
                        }}
                      />
                      <TextField
                        disabled={tyreList[index]?.is_added === 1}
                        className={styles.inputField}
                        style={{ width: "100%" }}
                        placeholder="Groove 2"
                        value={v.groove2}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                          readOnly: true,
                        }}
                        onChange={(e) => {
                          arrChange(e?.target?.value, index, 2, ii);
                        }}
                      />
                    </Grid>

                    <Grid item wrap="nowrap" style={{ marginTop: 5 }}>
                      <TextField
                        disabled={tyreList[index]?.is_added === 1}
                        className={styles.inputField}
                        style={{ width: "100%", marginBottom: 5 }}
                        placeholder="Groove 3"
                        value={v.groove3}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                          readOnly: true,
                        }}
                        onChange={(e) => {
                          arrChange(e?.target?.value, index, 3, ii);
                        }}
                      />
                      <TextField
                        disabled={tyreList[index]?.is_added === 1}
                        className={styles.inputField}
                        style={{ width: "100%" }}
                        placeholder="Groove 4"
                        value={v.groove4}
                        type={"number"}
                        inputProps={{
                          onWheel: (event) => event.currentTarget.blur(),
                          readOnly: true,
                        }}
                        onChange={(e) => {
                          arrChange(e?.target?.value, index, 4, ii);
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
          </>
        )}
      </div>
    );
  };

  const getAvgNSD = (index) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
          backgroundColor:
            tyreList[index]?.avg_nsd && tyreList[index]?.avg_nsd < 5
              ? "red"
              : color.white,
          borderRadius: 4,
        }}
      >
        <TextField
          fullWidth
          value={tyreList[index]?.avg_nsd}
          inputProps={{ readOnly: true }}
          disabled={tyreList[index]?.is_added === 1}
          placeholder="Avg NSD"
          sx={{
            input: {
              color:
                tyreList[index]?.avg_nsd &&
                tyreList[index]?.avg_nsd < 5 &&
                "#fff",
              "-webkit-text-fill-color":
                tyreList[index]?.avg_nsd &&
                tyreList[index]?.avg_nsd < 5 &&
                "#fff !important",
            },
          }}
        />
      </div>
    );
  };
  const getPSI = (index) => {
    return (
      <div id={`pressureErr_${index}`}>
        <TextField
          disabled={tyreList[index]?.is_added === 1}
          fullWidth
          error={tyreList[index]?.tyrePressureErr}
          value={tyreList[index]?.tyre_air_pressure}
          placeholder="Add Tyre Pressure"
          style={{ backgroundColor: color.white }}
          onChange={(v) => {
            arrChange(v?.target?.value, index, "pressure");
          }}
          inputProps={{
            readOnly: true,
          }}
        />
      </div>
    );
  };

  const getTyreIssue = (index) => {
    const value = tyreList[index]?.tyre_issue;
    let selected =
      isArray(value) && !isEmpty(value)
        ? dropDownData?.tyre_issue_inspection?.filter((item) => {
            let a = value.includes(item.id);
            return a;
          })
        : tyreList[index]?.is_added &&
          dropDownData?.tyre_issue_inspection?.filter((item) => {
            let a = item.id.toString() === "70";
            return a;
          });
    const renderTagsValue =
      isArray(selected) && !isEmpty(selected)
        ? selected
            .map(function (elem) {
              return elem.label;
            })
            .join(", ")
        : "";
    return <Typography>{renderTagsValue || "-"}</Typography>;
  };

  const getMechanicleIssue = (index) => {
    const value = tyreList[index]?.mechanical_issue;
    let selected =
      isArray(value) && !isEmpty(value)
        ? dropDownData?.mechanical_issue?.filter((item) => {
            let a = value.includes(item.id);
            return a;
          })
        : tyreList[index]?.is_added &&
          dropDownData?.mechanical_issue?.filter((item) => {
            let b = item.id.toString() === "116";
            return b;
          });
    const renderTagsValue =
      isArray(selected) && !isEmpty(selected)
        ? selected
            .map(function (elem) {
              return elem.label;
            })
            .join(", ")
        : "";
    return <Typography>{renderTagsValue || "-"}</Typography>;
  };
  const getTyreCondition = (index) => {
    return (
      <div>
        <Typography
          variant="span"
          style={{
            backgroundColor:
              tyreList[index]?.condition === "Average"
                ? "#FFFDD9"
                : tyreList[index]?.condition === "Good"
                ? "#D9FFE4"
                : tyreList[index]?.condition === "Bad"
                ? "#FFD9D9"
                : "",
            padding: 4,
            borderRadius: 4,
          }}
        >
          {tyreList[index]?.condition || "-"}
        </Typography>
      </div>
    );
  };

  const getRemarks = (index) => {
    return (
      <div
        style={{
          display: "flex",
          flex: 1,
          justifyContent: "space-between",
        }}
      >
        <TextField
          disabled={tyreList[index]?.is_added === 1}
          fullWidth
          className={styles.inputField}
          value={tyreList[index]?.remark}
          placeholder="Enter Remarks here"
          onChange={(v) => arrChange(v?.target?.value, index, "remarks")}
          inputProps={{
            readOnly: true,
          }}
          rows={2}
          multiline
        />
      </div>
    );
  };

  const getIssueImage = (index) => {
    return (
      <Typography
        onClick={() => {
          if (
            !isEmpty(tyreList[index]?.issue_image) &&
            isArray(tyreList[index]?.issue_image)
          ) {
            setimgURL(tyreList[index]?.issue_image);
            openModal(true);
          } else {
            return null;
          }
        }}
        style={{
          color:
            !isEmpty(tyreList[index]?.issue_image) &&
            isArray(tyreList[index]?.issue_image)
              ? color.primary
              : color.disable,
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        View
      </Typography>
    );
  };

  const MobTableRender = useMemo(() => {
    return (
      <>
        {tyreList?.map((item, index) => {
          return (
            <Grid
              container
              key={index}
              id={`jump_to_me${item?.tyre_position}`}
              style={{
                border: `1px solid ${color.bordercolor}`,
                marginBottom: 10,
                overflow: "auto",
              }}
              alignItems="center"
            >
              <Grid item container>
                <Table className={styles.customeTableMobile}>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head">Position</TableCell>
                      <TableCell variant="head">Tyre Brand</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">
                        {item?.tyre_position || "-"}
                      </TableCell>
                      <TableCell variant="body">
                        {item?.tyre_brand || "-"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head">Item Description</TableCell>
                      <TableCell variant="head">Tyre Condition</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body">
                        {item?.tyre_pattern_size || "-"}
                      </TableCell>
                      <TableCell variant="body" style={{ paddingBottom: 10 }}>
                        {getTyreCondition(index) || "-"}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" colSpan={2}>
                        Tyre Serial No.
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell variant="body" colSpan={2}>
                        {item?.is_added === 1 ? (
                          <Typography>{item?.tyre_serial_no}</Typography>
                        ) : (
                          <FormControl
                            fullWidth
                            style={{ alignSelf: "flex-start" }}
                          >
                            <Select
                              disabled={item?.is_added === 1}
                              displayEmpty
                              value={item?.tyre_serial_no}
                              onChange={(v) => {
                                arrChange(v?.target?.value, index, "serial");
                              }}
                              size={"medium"}
                              style={
                                tyreList?.tyre_serial_no === ""
                                  ? { color: "#A2A2A2" }
                                  : {}
                              }
                            >
                              <MenuItem value={""} disabled hidden selected>
                                Select the Serial No.
                              </MenuItem>
                              {tyreSerial.map((item, index) => (
                                <MenuItem
                                  key={index}
                                  value={item?.tyre_serial_no}
                                >
                                  {item?.tyre_serial_no}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" colSpan={2}>
                        NSD
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>{getNSD(index)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head">Avg. Initial NSD</TableCell>
                      <TableCell variant="head">Tyre Pressure</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{getAvgNSD(index)}</TableCell>
                      <TableCell>{getPSI(index)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head">Tyre Issue</TableCell>
                      <TableCell variant="head">Mechanical Issue</TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell>{getTyreIssue(index)}</TableCell>
                      <TableCell>{getMechanicleIssue(index)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" colSpan={2}>
                        Remarks
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>{getRemarks(index)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell variant="head" colSpan={2}>
                        Images
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell colSpan={2}>{getIssueImage(index)}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <Button
                          disabled={
                            !isOnline || item?.is_added === 1 || readingErr
                          }
                          variant="contained"
                          onClick={() => {
                            let subData = {
                              fleet: fleet,
                              vehicle: vehicle,
                              fitment_reading: fReading,
                              km_reading:
                                document.getElementById("currentReading").value,
                              captureDate: datePicker,
                            };
                            if (item?.is_added !== 1) {
                              handleClick("addInspectionForm", item, subData);
                            }
                          }}
                        >
                          <Edit />
                        </Button>
                      </TableCell>
                      <TableCell align="right">
                        <Button
                          variant="contained"
                          onClick={() => {
                            let subData = {
                              fleet: fleet,
                              vehicle: vehicle,
                              vehicleList: vehicleList,
                              fitment_reading: fReading,
                              km_reading:
                                document.getElementById("currentReading").value,
                              captureDate: datePicker,
                            };
                            if (item?.is_added !== 1) {
                              handleClick("removeTyre", item, subData);
                            }
                          }}
                          disabled={
                            !isOnline || item?.is_added === 1 || readingErr
                          }
                          style={{
                            backgroundColor:
                              !(
                                !isOnline ||
                                item?.is_added === 1 ||
                                readingErr
                              ) && "red",
                          }}
                        >
                          <Delete />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                </Table>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }, [tyreList, tyreSerial, readingErr]);

  const TabTableRender = useMemo(() => {
    return (
      <>
        {tyreList?.map((item, index) => {
          return (
            <Grid
              id={`jump_to_me${item?.tyre_position}`}
              container
              style={{
                border: `1px solid ${color.bordercolor}`,
                padding: 10,
                marginTop: 15,
                overflow: "auto",
              }}
              alignItems="center"
            >
              <Grid item container justifyContent="space-between" gap={1}>
                <Grid>
                  <Typography style={{ fontWeight: "bold" }}>
                    Tyre Serial No.
                  </Typography>
                  {item?.is_added === 1 ? (
                    <Typography>{item?.tyre_serial_no}</Typography>
                  ) : (
                    <FormControl style={{ alignSelf: "flex-start" }}>
                      <Select
                        fullWidth
                        disabled={item?.is_added === 1}
                        displayEmpty
                        value={item?.tyre_serial_no}
                        onChange={(v) => {
                          arrChange(v?.target?.value, index, "serial");
                        }}
                        style={{
                          minWidth: 150,
                          color: tyreList?.tyre_serial_no === "" && "#A2A2A2",
                        }}
                      >
                        <MenuItem value={""} disabled hidden selected>
                          Select the Serial No.
                        </MenuItem>
                        {tyreSerial.map((item, index) => (
                          <MenuItem key={index} value={item?.tyre_serial_no}>
                            {item?.tyre_serial_no}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  )}
                </Grid>
                <Grid>
                  <Typography style={{ fontWeight: "bold" }}>
                    Tyre Brand
                  </Typography>
                  <Typography>{item?.tyre_brand || "-"}</Typography>
                </Grid>
                <Grid>
                  <Typography style={{ fontWeight: "bold" }}>
                    Item Description
                  </Typography>
                  <Typography>{item?.tyre_pattern_size || "-"}</Typography>
                </Grid>
                <Grid>
                  <Typography style={{ fontWeight: "bold" }}>
                    Position
                  </Typography>
                  <Typography>{item?.tyre_position || "-"}</Typography>
                </Grid>
              </Grid>
              <div style={{ width: "100%" }}>
                <Divider
                  fullWidth
                  style={{ marginBottom: 10, marginTop: 10 }}
                />
              </div>

              <Grid
                item
                sm={12}
                style={{ backgroundColor: "#F3F0F0", padding: 10 }}
              >
                <Typography style={{ fontWeight: 600 }}>NSD</Typography>
                {getNSD(index)}
              </Grid>
              <Grid item container columnGap={1} style={{ marginTop: 10 }}>
                <Grid item xs={12} style={{ padding: 10 }}>
                  <Grid item display="flex" columnGap={1}>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: 600 }}>
                        Avg. Initial NSD
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography style={{ fontWeight: 600 }}>
                        Tyre Pressure
                      </Typography>
                    </Grid>
                  </Grid>
                  <Grid display="flex" columnGap={1} style={{ marginTop: 10 }}>
                    <Grid item xs={6} style={{ marginRight: 10 }}>
                      {getAvgNSD(index)}
                    </Grid>
                    <Grid item xs={6}>
                      {getPSI(index)}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  style={{
                    backgroundColor: "#F3F0F0",
                    padding: 10,
                    marginTop: 10,
                  }}
                >
                  <Grid item display="flex" columnGap={1}>
                    <Grid xs={6}>
                      <Typography style={{ fontWeight: 600 }}>
                        Tyre Issue
                      </Typography>
                    </Grid>
                    <Grid xs={6}>
                      <Typography style={{ fontWeight: 600 }}>
                        Mechanical Issue
                      </Typography>
                    </Grid>
                  </Grid>

                  <Grid
                    item
                    display="flex"
                    columnGap={1}
                    style={{ marginTop: 10 }}
                  >
                    <Grid xs={6} style={{ marginRight: 10 }}>
                      {getTyreIssue(index) || "-"}
                    </Grid>
                    <Grid xs={6}>{getMechanicleIssue(index) || "-"}</Grid>
                  </Grid>
                </Grid>
                <Grid item container xs={12} wrap="nowrap">
                  <Grid
                    item
                    xs={6}
                    style={{
                      padding: 10,
                    }}
                  >
                    <Grid xs={12}>
                      <Typography style={{ fontWeight: 600 }}>
                        Tyre Condition
                      </Typography>
                    </Grid>
                    <Grid xs={12} style={{ marginTop: 5 }}>
                      {getTyreCondition(index)}
                    </Grid>
                  </Grid>

                  <Grid item xs={6} style={{ padding: 10 }}>
                    <Grid item container wrap="nowrap">
                      <Grid item xs={12}>
                        <Typography style={{ fontWeight: 600 }}>
                          Remarks
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item container wrap="nowrap" style={{ marginTop: 5 }}>
                      <Grid xs={12} style={{ marginRight: 10 }}>
                        {getRemarks(index)}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  style={{
                    padding: 10,
                    backgroundColor: "#F3F0F0",
                  }}
                >
                  <Grid item xs={12}>
                    <Typography style={{ fontWeight: 600 }}>Images</Typography>
                  </Grid>
                  <Grid xs={12}>{getIssueImage(index)}</Grid>
                </Grid>
              </Grid>

              <Grid
                item
                container
                wrap="nowrap"
                style={{
                  justifyContent: "space-between",
                  padding: 15,
                }}
              >
                <Button
                  disabled={!isOnline || item?.is_added === 1 || readingErr}
                  variant="contained"
                  onClick={() => {
                    let subData = {
                      fleet: fleet,
                      vehicle: vehicle,
                      fitment_reading: fReading,
                      km_reading:
                        document.getElementById("currentReading").value,
                      captureDate: datePicker,
                    };
                    handleClick("addInspectionForm", item, subData);
                  }}
                >
                  <Edit />
                </Button>
                <Button
                  variant="contained"
                  onClick={() => {
                    let subData = {
                      fleet: fleet,
                      vehicle: vehicle,
                      vehicleList: vehicleList,
                      fitment_reading: fReading,
                      km_reading:
                        document.getElementById("currentReading").value,
                      captureDate: datePicker,
                    };
                    if (item?.is_added !== 1) {
                      handleClick("removeTyre", item, subData);
                    }
                  }}
                  disabled={!isOnline || item?.is_added === 1 || readingErr}
                  style={{
                    backgroundColor:
                      !(!isOnline || item?.is_added === 1 || readingErr) &&
                      "red",
                  }}
                >
                  <Delete />
                </Button>
              </Grid>
            </Grid>
          );
        })}
      </>
    );
  }, [tyreList, tyreSerial, readingErr]);

  function swapValidation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(horseTrailer?.id?.toString())) {
      valid = false;
      error.horseVehicleErr = true;
      error.horseVehicleMsg = "Please select Trailer";
    }

    if (idealTrailer === 0 && isEmpty(dkmReading?.toString())) {
      valid = false;
      error.DkmErr = true;
      error.DkmMsg = "Please Enter Dismount Km.";
    } else if (
      idealTrailer === 0 &&
      Number(dkmReading) < Number(lastInspection)
    ) {
      valid = false;
      error.DkmErr = true;
      error.DkmMsg =
        "Dismount Km. must be greater or equal to Last Inspection Reading";
    }

    if (idealTrailer === 0 && isEmpty(SwapTrailer?.toString())) {
      valid = false;
      error.tyreswapErr = true;
      error.tyreswapMsg = "Please select Swap Trailer";
    }

    setErrObj(error);
    if (valid) {
      swapTrailer();
    }
  }

  async function swapTrailer() {
    setLoader(true);

    let endPoints = Setting.endpoints.changeTrailer;
    const params = {
      "TrailerForm[vehicle_id]": vehicle?.id,
      "TrailerForm[swap_trailer_id]": horseTrailer?.id,
      "TrailerForm[is_swap]": SwapTrailer,
      "TrailerForm[dismount_trailer_reading]": dkmReading,
      "TrailerForm[vehicle_inspection_reading]": isEmpty(kmReading)
        ? fReading.replaceAll(",", "")
        : kmReading,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        setSwapModal(false);
        setHorseTrailer({
          id: "",
          trailer_no: "",
        });
        setSwapTrailer("");
        setIdealTrailer(1);
        setLastInspection("");
        setDKmReading("");
        getTyreSerial();
        getTyreList();
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  }

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Box
            id="jump_to_me"
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: "18px 20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight={"bold"}>Tyre Inspection</Typography>
          </Box>
          <div style={{ padding: 20 }}>
            <Grid
              item
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid container flexDirection="row" wrap="nowrap">
                <Grid
                  xs={6}
                  alignItems={"center"}
                  style={{ marginBottom: errObj.fleetErr ? 30 : 20 }}
                >
                  <CTypography fullWidth required title={"Select Fleet"} />
                  <Grid item>
                    <FormControl fullWidth error={errObj.fleetErr}>
                      <Autocomplete
                        fullWidth
                        isOptionEqualToValue={(option, value) =>
                          option.label === value
                        }
                        disableListWrap={true}
                        options={fleetData}
                        value={fleet?.label}
                        classes={{ input: `${styles.autocomplete}` }}
                        onChange={(v1, v) => {
                          setFleet(v);
                          setErrObj({
                            ...errObj,
                            fleetErr: false,
                            fleetMsg: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={styles.inputDivStyle}
                            placeholder={
                              isEmpty(fleet?.label) ? "Select Fleet" : null
                            }
                          />
                        )}
                        renderTags={(tagValue, getTagProps) => {
                          const renderTagsValue = tagValue;
                          return (
                            <Typography noWrap={true} color="textPrimary">
                              {renderTagsValue}
                            </Typography>
                          );
                        }}
                      />
                      {errObj.fleetErr ? (
                        <FormHelperText>{errObj.fleetMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={6}
                  alignItems={"center"}
                  style={{
                    marginBottom: errObj.vehicleErr ? 30 : 20,
                    marginLeft: 10,
                  }}
                >
                  <CTypography required title={"Vehicle No"} />
                  <Grid item>
                    <FormControl fullWidth error={errObj.vehicleErr}>
                      <Autocomplete
                        fullWidth
                        isOptionEqualToValue={(option, value) =>
                          option.label === value
                        }
                        disabled={isEmpty(fleet?.label)}
                        disableListWrap={true}
                        options={vehicleList}
                        value={vehicle?.label}
                        classes={{ input: `${styles.autocomplete}` }}
                        onChange={(v1, v) => {
                          setVehicle(v);
                          setErrObj({
                            ...errObj,
                            vehicleErr: false,
                            vehicleMsg: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={styles.inputDivStyle}
                            placeholder={
                              isEmpty(vehicle?.label) ? "Select Vehicle" : null
                            }
                          />
                        )}
                        renderTags={(tagValue, getTagProps) => {
                          const renderTagsValue = tagValue;
                          return (
                            <Typography noWrap={true} color="textPrimary">
                              {renderTagsValue}
                            </Typography>
                          );
                        }}
                      />

                      {errObj.vehicleErr ? (
                        <FormHelperText>{errObj.vehicleMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
              <Grid container>
                <Grid
                  xs={6}
                  alignItems={"center"}
                  style={{ marginBottom: errObj.selectedFileErr ? 30 : 20 }}
                  id="date"
                >
                  <Grid item xs={12}>
                    <CTypography required title={"Added Date"} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error={errObj.dateErr} fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          showToolbar={false}
                          disableFuture
                          views={["year", "month", "day"]}
                          value={datePicker}
                          onChange={(newValue) => {
                            setDatePicker(newValue);
                            setErrObj({
                              ...errObj,
                              dateErr: false,
                              dateMsg: "",
                            });
                          }}
                          inputFormat="dd-MM-yyyy"
                          DialogProps={{ className: styles.datePicker }}
                          renderInput={(params) => (
                            <TextField
                              fullWidth
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "DD-MM-YYYY",
                              }}
                              sx={{ svg: { color: color.primary } }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {errObj.dateErr ? (
                        <FormHelperText>{errObj.dateMsg}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Button
              fullWidth
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                checkGetValidation();
                setTyreScroll("");
              }}
            >
              Get Tyre List
            </Button>

            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(tyreList) && !isEmpty(tyreList) ? (
              <>
                <Grid
                  container
                  alignItems={"center"}
                  style={{
                    marginBottom: errObj.kmErr ? 40 : 20,
                    marginTop: 20,
                  }}
                >
                  <Grid container style={{ marginBottom: 20 }}>
                    <Grid item xs={5}>
                      <Typography>Last Inspection Reading : </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Typography>{fReading}</Typography>
                    </Grid>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography>Current Reading : </Typography>
                  </Grid>
                  <Grid item xs={7}>
                    <FormControl fullWidth error={errObj.kmErr}>
                      <TextField
                        id={"currentReading"}
                        style={{ width: "100%", margin: 0 }}
                        placeholder="Enter the Current Reading of the Vehicle"
                        value={kmReading}
                        disabled={disableReading}
                        error={errObj.kmErr}
                        // helperText={errObj.kmMsg}
                        onChange={(e) => {
                          if (
                            e?.nativeEvent?.data === "0" ||
                            e?.nativeEvent?.data === "1" ||
                            e?.nativeEvent?.data === "2" ||
                            e?.nativeEvent?.data === "3" ||
                            e?.nativeEvent?.data === "4" ||
                            e?.nativeEvent?.data === "5" ||
                            e?.nativeEvent?.data === "6" ||
                            e?.nativeEvent?.data === "7" ||
                            e?.nativeEvent?.data === "8" ||
                            e?.nativeEvent?.data === "9" ||
                            e?.nativeEvent?.data === "." ||
                            isNull(e?.nativeEvent?.data)
                          ) {
                            const fValue = fReading.replaceAll(",", "");
                            // converter(e.target.value, "fitment");
                            setKmReading(e.target.value);
                            if (
                              !isEmpty(e.target.value) &&
                              e.target.value < Number(fValue)
                            ) {
                              setErrObj({
                                ...errObj,
                                kmErr: true,
                                kmMsg:
                                  "The Current Reading should be greater than or equal to Last Inspection Reading",
                              });
                              setReadingErr(true);
                            } else {
                              setErrObj({
                                ...errObj,
                                kmErr: false,
                                kmMsg: "",
                              });
                              setReadingErr(false);
                            }
                          }
                        }}
                      />
                      {errObj.kmErr && (
                        <FormHelperText style={{ bottom: -40 }}>
                          {errObj.kmMsg}
                        </FormHelperText>
                      )}
                    </FormControl>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 20 }}>
                  <Grid item xs={5}>
                    <Typography>Last Inspected Date : </Typography>
                  </Grid>

                  <Grid item xs={7}>
                    <Typography>
                      {inspectedDate
                        ? moment(inspectedDate).format("DD-MM-yyyy")
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid container style={{ marginBottom: 20 }}>
                  <Grid item xs={5}>
                    <Typography>Last Inspected by:</Typography>
                  </Grid>

                  <Grid item xs={7}>
                    <Typography>{inspecteBy || "-"}</Typography>
                  </Grid>
                </Grid>
                {isHorse === 1 && (
                  <Grid style={{ marginBottom: 20 }}>
                    <Button
                      disabled={!isOnline || readingErr}
                      variant="contained"
                      onClick={() => setSwapModal(true)}
                      style={{ width: 130, marginRight: 20 }}
                    >
                      Change Trailer
                    </Button>

                    <FormControl
                      sx={{
                        width: "50%",
                        border: errObj.SECodeErr ? "1px solid #ff1744" : null,
                        borderRadius: 1,
                      }}
                      style={{
                        marginRight: 10,
                      }}
                      error={errObj.SECodeErr}
                    >
                      <Autocomplete
                        fullWidth
                        multiple
                        disableListWrap={true}
                        options={tsnList}
                        // filterSelectedOptions
                        value={selectedTSN}
                        classes={{ input: `${styles.autocomplete}` }}
                        onChange={(v1, v) => {
                          setselectedTSN(v);
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={styles.inputDivStyle}
                            placeholder={
                              isEmpty(selectedTSN) ? "Search/Select TSN" : null
                            }
                          />
                        )}
                        renderTags={(tagValue, getTagProps) => {
                          const renderTagsValue = tagValue
                            .map(function (elem) {
                              return elem.label;
                            })
                            .join(", ");
                          return (
                            // <Typography noWrap={true} color="textPrimary">
                            renderTagsValue
                            // </Typography>
                          );
                        }}
                      />
                      {errObj.SECodeErr ? (
                        <FormHelperText>{errObj.SECodeMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>

                    <Button
                      varient="contained"
                      onClick={() => {
                        if (!isEmpty(selectedTSN)) {
                          getTSNinfo();
                        } else {
                          toast.error("Please Select TSN First");
                        }
                      }}
                      style={{
                        marginRight: 10,
                        backgroundColor: color.secondary,
                      }}
                    >
                      <SearchOutlined />
                    </Button>
                    <Button
                      variant="contained"
                      onClick={() => {
                        setselectedTSN([]);
                      }}
                      disabled={isEmpty(selectedTSN)}
                    >
                      <ResetIcon />
                    </Button>
                  </Grid>
                )}

                {TabTableRender}

                <Grid container style={{ margin: "20px 0" }}>
                  <Grid item xs={3}>
                    <CTypography required title={"Remarks"} />
                  </Grid>

                  <Grid item xs={9}>
                    <TextField
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Enter Remarks here"
                      value={remarks}
                      rows={4}
                      multiline
                      onChange={(e) => {
                        setRemarks(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>

                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    disabled={!isOnline}
                    variant="contained"
                    onClick={checkMainValidation}
                    style={{ width: 100 }}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: color.secondary,
                      marginLeft: 10,

                      width: 100,
                    }}
                    onClick={() => {
                      handleClick("cancel", "");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </div>
      ) : (
        <>
          <Box
            id="jump_to_me"
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 15,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography fontWeight={"bold"}>Tyre Inspection</Typography>
          </Box>

          <Grid className={styles.container} style={{ padding: 10 }}>
            <Grid
              item
              container
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Grid
                container
                // alignItems={"center"}
                flexDirection="row"
              >
                <Grid
                  xs={12}
                  alignItems={"center"}
                  style={{ marginBottom: errObj.fleetErr ? 30 : 20 }}
                >
                  <CTypography fullWidth required title={"Select Fleet"} />
                  <Grid item>
                    <FormControl fullWidth error={errObj.fleetErr}>
                      <Autocomplete
                        fullWidth
                        isOptionEqualToValue={(option, value) =>
                          option.label === value
                        }
                        disableListWrap={true}
                        options={fleetData}
                        value={fleet?.label}
                        classes={{ input: `${styles.autocomplete}` }}
                        onChange={(v1, v) => {
                          setFleet(v);
                          setErrObj({
                            ...errObj,
                            fleetErr: false,
                            fleetMsg: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={styles.inputDivStyle}
                            placeholder={
                              isEmpty(fleet?.label) ? "Select Fleet" : null
                            }
                          />
                        )}
                        renderTags={(tagValue, getTagProps) => {
                          const renderTagsValue = tagValue;
                          return (
                            <Typography noWrap={true} color="textPrimary">
                              {renderTagsValue}
                            </Typography>
                          );
                        }}
                      />
                      {errObj.fleetErr ? (
                        <FormHelperText>{errObj.fleetMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  xs={12}
                  alignItems={"center"}
                  style={{
                    marginBottom: errObj.vehicleErr ? 30 : 20,
                  }}
                >
                  <CTypography required title={"Vehicle No"} />
                  <Grid item>
                    <FormControl fullWidth error={errObj.vehicleErr}>
                      <Autocomplete
                        fullWidth
                        isOptionEqualToValue={(option, value) =>
                          option.label === value
                        }
                        disabled={isEmpty(fleet?.label)}
                        disableListWrap={true}
                        options={vehicleList}
                        value={vehicle?.label}
                        classes={{ input: `${styles.autocomplete}` }}
                        onChange={(v1, v) => {
                          setVehicle(v);
                          setErrObj({
                            ...errObj,
                            vehicleErr: false,
                            vehicleMsg: "",
                          });
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            className={styles.inputDivStyle}
                            placeholder={
                              isEmpty(vehicle?.label) ? "Select Vehicle" : null
                            }
                          />
                        )}
                        renderTags={(tagValue, getTagProps) => {
                          const renderTagsValue = tagValue;
                          return (
                            <Typography noWrap={true} color="textPrimary">
                              {renderTagsValue}
                            </Typography>
                          );
                        }}
                      />

                      {errObj.vehicleErr ? (
                        <FormHelperText>{errObj.vehicleMsg}</FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  style={{
                    marginBottom: errObj.selectedFileErr ? 30 : 20,
                  }}
                  id="date"
                >
                  <Grid item xs={12}>
                    <CTypography required title={"Added Date"} />
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl error={errObj.dateErr} fullWidth>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                          showToolbar={false}
                          disableFuture
                          views={["year", "month", "day"]}
                          value={datePicker}
                          onChange={(newValue) => {
                            setDatePicker(newValue);
                            setErrObj({
                              ...errObj,
                              dateErr: false,
                              dateMsg: "",
                            });
                          }}
                          inputFormat="dd-MM-yyyy"
                          DialogProps={{ className: styles.datePicker }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              inputProps={{
                                ...params.inputProps,
                                placeholder: "DD-MM-YYYY",
                              }}
                              sx={{ svg: { color: color.primary } }}
                            />
                          )}
                        />
                      </LocalizationProvider>
                      {errObj.dateErr ? (
                        <FormHelperText>{errObj.dateMsg}</FormHelperText>
                      ) : null}
                    </FormControl>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Button
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                checkGetValidation();
                setTyreScroll("");
              }}
            >
              Get Tyre List
            </Button>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(tyreList) && !isEmpty(tyreList) ? (
              <>
                <Grid
                  container
                  alignItems={"center"}
                  wrap="nowrap"
                  style={{
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  <Grid container>
                    <Grid item xs={5} sm={3} md={3} lg={3}>
                      <Typography>Last Inspection Reading : </Typography>
                    </Grid>
                    <Grid item xs={7} sm={9} md={9} lg={9}>
                      <Typography>{fReading}</Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  wrap={isTablet ? "nowrap" : "wrap"}
                  style={{
                    marginBottom: errObj.kmErr ? 60 : 20,
                  }}
                >
                  <Grid item xs={5} sm={3} md={3} lg={3}>
                    <Typography>Current Reading : </Typography>
                  </Grid>
                  <Grid item xs={7} sm={9} md={9} lg={9}>
                    <FormControl fullWidth error={errObj.kmErr}>
                      <TextField
                        id={"currentReading"}
                        fullWidth
                        style={{
                          width: "100%",
                          margin: 0,
                        }}
                        placeholder="Enter the Current Reading of the Vehicle"
                        disabled={disableReading}
                        value={kmReading}
                        error={errObj.kmErr}
                        // helperText={errObj.kmErr ? errObj.kmMsg : null}
                        onChange={(e) => {
                          if (
                            e?.nativeEvent?.data === "0" ||
                            e?.nativeEvent?.data === "1" ||
                            e?.nativeEvent?.data === "2" ||
                            e?.nativeEvent?.data === "3" ||
                            e?.nativeEvent?.data === "4" ||
                            e?.nativeEvent?.data === "5" ||
                            e?.nativeEvent?.data === "6" ||
                            e?.nativeEvent?.data === "7" ||
                            e?.nativeEvent?.data === "8" ||
                            e?.nativeEvent?.data === "9" ||
                            e?.nativeEvent?.data === "." ||
                            isNull(e?.nativeEvent?.data)
                          ) {
                            const fValue = fReading.replaceAll(",", "");
                            // converter(e.target.value, "fitment");
                            setKmReading(e.target.value);
                            if (
                              !isEmpty(e.target.value) &&
                              e.target.value < Number(fValue)
                            ) {
                              setErrObj({
                                ...errObj,
                                kmErr: true,
                                kmMsg:
                                  "The Current Reading should be greater than or equal to Last Inspection Reading",
                              });
                              setReadingErr(true);
                            } else {
                              setErrObj({
                                ...errObj,
                                kmErr: false,
                                kmMsg: "",
                              });
                              setReadingErr(false);
                            }
                          }
                        }}
                      />
                      {errObj.kmErr ? (
                        <FormHelperText style={{ bottom: -60 }}>
                          {errObj.kmMsg}
                        </FormHelperText>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: 20 }}>
                  <Grid item xs={5} sm={3} md={3} lg={3}>
                    <Typography>Last Inspected Date : </Typography>
                  </Grid>

                  <Grid item xs={7} sm={9} md={9} lg={9}>
                    <Typography>
                      {inspectedDate
                        ? moment(inspectedDate).format("DD-MM-yyyy")
                        : "-"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container style={{ marginBottom: 20 }}>
                  <Grid item xs={5} sm={3} md={3} lg={3}>
                    <Typography>Last Inspected by:</Typography>
                  </Grid>

                  <Grid item xs={7} sm={9} md={9} lg={9}>
                    <Typography>{inspecteBy || "-"}</Typography>
                  </Grid>
                </Grid>
                {isHorse === 1 && (
                  <>
                    <Grid container style={{ marginBottom: 20 }}>
                      <FormControl
                        sx={{
                          flex: 1,
                          border: errObj.SECodeErr ? "1px solid #ff1744" : null,
                          borderRadius: 1,
                          marginRight: 10,
                        }}
                        style={{
                          marginRight: 10,
                        }}
                        error={errObj.SECodeErr}
                      >
                        <Autocomplete
                          fullWidth
                          multiple
                          disableListWrap={true}
                          options={tsnList}
                          // filterSelectedOptions
                          value={selectedTSN}
                          classes={{ input: `${styles.autocomplete}` }}
                          onChange={(v1, v) => {
                            setselectedTSN(v);
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              className={styles.inputDivStyle}
                              placeholder={
                                isEmpty(selectedTSN)
                                  ? "Search/Select TSN"
                                  : null
                              }
                            />
                          )}
                          renderTags={(tagValue, getTagProps) => {
                            const renderTagsValue = tagValue
                              .map(function (elem) {
                                return elem.label;
                              })
                              .join(", ");
                            return (
                              // <Typography noWrap={true} color="textPrimary">
                              renderTagsValue
                              // </Typography>
                            );
                          }}
                        />
                        {errObj.SECodeErr ? (
                          <FormHelperText>{errObj.SECodeMsg}</FormHelperText>
                        ) : (
                          ""
                        )}
                      </FormControl>
                      <Button
                        varient="contained"
                        onClick={() => {
                          if (!isEmpty(selectedTSN)) {
                            getTSNinfo();
                          } else {
                            toast.error("Please Select TSN First");
                          }
                        }}
                        style={{
                          marginRight: 10,
                          backgroundColor: color.secondary,
                        }}
                      >
                        <SearchOutlined />
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setselectedTSN([]);
                        }}
                        disabled={isEmpty(selectedTSN)}
                      >
                        <ResetIcon />
                      </Button>
                    </Grid>

                    <Button
                      disabled={!isOnline || readingErr}
                      variant="contained"
                      onClick={() => setSwapModal(true)}
                      style={{ width: 130, marginRight: 20, marginBottom: 20 }}
                    >
                      Change Trailer
                    </Button>
                  </>
                )}
                {MobTableRender}
                <Grid
                  container
                  style={{ margin: "10px 0 20px" }}
                  wrap={isTablet ? "nowrap" : "wrap"}
                >
                  <Grid item xs={12} sm={3} md={3} lg={3}>
                    <CTypography required title={"Remarks"} />
                  </Grid>
                  <Grid item xs={12} sm={9} md={9} lg={9}>
                    <TextField
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Enter Remarks here"
                      value={remarks}
                      rows={4}
                      disabled={!isEmpty(remarksChcek)}
                      helperText={errObj.remarkErr ? errObj.remarkMsg : ""}
                      error={errObj.remarkErr}
                      multiline
                      onChange={(e) => {
                        setRemarks(e.target.value);
                        setErrObj({
                          ...errObj,
                          remarkErr: false,
                          remarkMsg: "",
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <Button
                    disabled={!isOnline}
                    style={{
                      width: 100,
                    }}
                    variant="contained"
                    onClick={checkMainValidation}
                  >
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      backgroundColor: color.secondary,
                      marginLeft: 10,
                      width: 100,
                    }}
                    onClick={() => {
                      handleClick("cancel", "");
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </Grid>
        </>
      )}
      <Modal
        onClose={() => {
          setOpenCard(false);
          setIsMechanicle(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "80vw",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={isTablet ? 2 : 1}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: "60vh" }}
          >
            {arrData.map((item, index) => {
              return (
                <ImageListItem
                  key={`${index}_${item?.id}`}
                  style={{
                    width: isTablet ? 200 : 250,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      SelectTyre(index);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={handleSelect}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          openModal(false);
        }}
        open={modal}
      >
        <div
          style={{
            maxWidth: "80vw",
            maxHeight: "80vh",
            backgroundColor: "white",
            padding: 10,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={1}
            style={{ overflowY: "scroll", maxHeight: "60vh" }}
          >
            {isArray(imgURL) &&
              !isEmpty(imgURL) &&
              imgURL.map((item, index) => {
                return (
                  <ImageListItem key={index}>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={`${item}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </ImageListItem>
                );
              })}
          </ImageList>
        </div>
      </Modal>

      <Modal
        onClose={() => {
          setOpenInfo(false);
        }}
        closeAfterTransition
        disableAutoFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={openInfo}
      >
        <Box sx={style}>
          <div className={styles.modalHeader}>
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              TSN Info
            </Typography>
            <Close
              style={{ color: color.white, cursor: "pointer" }}
              onClick={() => setOpenInfo(false)}
            />
          </div>
          <div style={{ padding: 20 }}>
            {isArray(tsnInfo) && !isEmpty(tsnInfo) ? (
              tsnInfo.map((item, index) => {
                return (
                  <Grid container style={{ marginBottom: 10 }}>
                    <Grid item xs={5}>
                      {item?.tyre_serial_no}
                    </Grid>
                    <Grid item xs={2}>{`=>`}</Grid>
                    <Grid item xs={5}>
                      {item?.vehicle_no}
                    </Grid>
                    {/* {`${item?.tyre_serial_no} => ${item?.vehicle_no}`} */}
                  </Grid>
                );
              })
            ) : (
              <div
                style={{
                  height: 350,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                No Data
              </div>
            )}
          </div>
        </Box>
      </Modal>

      <Modal
        onClose={() => {
          setSwapModal(false);
          setHorseTrailer({
            id: "",
            trailer_no: "",
          });
          setLastInspection("");
          setSwapTrailer("");
          setDKmReading("");
          setIdealTrailer(1);
          setErrObj({
            ...errObj,
            horseVehicleErr: false,
            horseVehicleMsg: "",
            tyreswapErr: false,
            tyreswapMsg: "",
            DkmErr: false,
            DkmMsg: "",
          });
        }}
        closeAfterTransition
        disableAutoFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        open={swapModal}
      >
        <Box sx={style1}>
          <div className={styles.modalHeader}>
            <Typography
              style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
            >
              Change Trailer
            </Typography>
            <Close
              style={{ color: color.white, cursor: "pointer" }}
              onClick={() => {
                setSwapModal(false);
                setHorseTrailer({
                  id: "",
                  trailer_no: "",
                });
                setIdealTrailer(1);
                setDKmReading("");
                setLastInspection("");
                setSwapTrailer("");
                setErrObj({
                  ...errObj,
                  horseVehicleErr: false,
                  horseVehicleMsg: "",
                  tyreswapErr: false,
                  tyreswapMsg: "",
                  DkmErr: false,
                  DkmMsg: "",
                });
              }}
            />
          </div>
          <div style={{ padding: 20 }}>
            <Grid
              xs={12}
              alignItems={"center"}
              style={{
                marginBottom: errObj.horseVehicleErr ? 30 : 20,
              }}
            >
              <CTypography fullWidth required title={"Select Trailer"} />
              <Grid item>
                <FormControl
                  fullWidth
                  error={errObj.horseVehicleErr}
                  sx={{
                    border: errObj.horseVehicleErr ? "1px solid #ff1744" : null,
                    borderRadius: 1,
                  }}
                >
                  <Autocomplete
                    fullWidth
                    isOptionEqualToValue={(option, value) =>
                      option.trailer_no === value
                    }
                    // disabled={isEmpty(fleet?.label)}
                    disableListWrap={true}
                    options={horseTrailerList}
                    value={horseTrailer?.trailer_no}
                    classes={{ input: `${styles.autocomplete}` }}
                    getOptionLabel={(option) => {
                      // Value selected with enter, right from the input
                      if (typeof option === "string") {
                        return option;
                      }
                      // Add "xxx" option created dynamically
                      if (option.trailer_no) {
                        return option.trailer_no;
                      }
                      // Regular option
                      return option.label;
                    }}
                    onChange={(v1, v) => {
                      setHorseTrailer(v);
                      setErrObj({
                        ...errObj,
                        horseVehicleErr: false,
                        horseVehicleMsg: "",
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        className={styles.inputDivStyle}
                        placeholder={
                          isEmpty(horseTrailer?.vehicle_no)
                            ? "Select Vehicle"
                            : null
                        }
                      />
                    )}
                    renderTags={(tagValue, getTagProps) => {
                      const renderTagsValue = tagValue;
                      return (
                        // <Typography noWrap={true} color="textPrimary">
                        renderTagsValue
                        // </Typography>
                      );
                    }}
                  />
                  {errObj.horseVehicleErr ? (
                    <FormHelperText>{errObj.horseVehicleMsg}</FormHelperText>
                  ) : (
                    ""
                  )}
                </FormControl>
              </Grid>
            </Grid>
            {idealTrailer === 0 && (
              <Grid
                xs={12}
                alignItems={"center"}
                style={{
                  marginBottom: errObj.tyreswapErr ? 30 : 20,
                }}
                id="swapTyre"
              >
                <CTypography fullWidth required title={"Swap Trailer?"} />

                <Grid item>
                  <FormControl fullWidth error={errObj.tyreswapErr}>
                    <Select
                      displayEmpty
                      value={SwapTrailer}
                      onChange={(event) => {
                        setSwapTrailer(event.target.value);
                        setErrObj({
                          ...errObj,
                          tyreswapErr: false,
                          tyreswapMsg: "",
                        });
                      }}
                      size={"medium"}
                      style={SwapTrailer === "" ? { color: "#A2A2A2" } : {}}
                    >
                      <MenuItem value={""} disabled hidden selected>
                        Select the Option
                      </MenuItem>
                      <MenuItem value={"1"}>Yes</MenuItem>
                      <MenuItem value={"0"}>No</MenuItem>
                    </Select>
                    {errObj.tyreswapErr ? (
                      <FormHelperText>{errObj.tyreswapMsg}</FormHelperText>
                    ) : (
                      ""
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}

            {idealTrailer === 0 && (
              <Grid
                container
                alignItems={"center"}
                style={{ marginBottom: 20 }}
              >
                <Grid item xs={12}>
                  <CTypography title={"Last Inspection Reading"} />
                </Grid>
                <Typography>{lastInspection} Km</Typography>
              </Grid>
            )}

            {idealTrailer === 0 && (
              <Grid
                xs={12}
                alignItems={"center"}
                style={{
                  marginBottom: errObj.DkmErr ? 60 : 20,
                }}
              >
                <CTypography fullWidth required title={"Dismount Km"} />
                <Grid item>
                  <FormControl fullWidth error={errObj.DkmErr}>
                    <TextField
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Enter the Dismount Km."
                      value={dkmReading}
                      error={errObj.DkmErr}
                      onChange={(e) => {
                        if (
                          e?.nativeEvent?.data === "0" ||
                          e?.nativeEvent?.data === "1" ||
                          e?.nativeEvent?.data === "2" ||
                          e?.nativeEvent?.data === "3" ||
                          e?.nativeEvent?.data === "4" ||
                          e?.nativeEvent?.data === "5" ||
                          e?.nativeEvent?.data === "6" ||
                          e?.nativeEvent?.data === "7" ||
                          e?.nativeEvent?.data === "8" ||
                          e?.nativeEvent?.data === "9" ||
                          e?.nativeEvent?.data === "." ||
                          isNull(e?.nativeEvent?.data)
                        ) {
                          setDKmReading(e.target.value);

                          setErrObj({
                            ...errObj,
                            DkmErr: false,
                            DkmMsg: "",
                          });
                        }
                      }}
                    />
                    {errObj.DkmErr && (
                      <FormHelperText
                        style={{
                          bottom: !isEmpty(dkmReading) ? -40 : -20,
                        }}
                      >
                        {errObj.DkmMsg}
                      </FormHelperText>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            )}

            <Grid
              container
              alignItems={"center"}
              justifyContent={"center"}
              style={{ marginBottom: 20 }}
            >
              <Button
                disabled={isOnline ? false : true}
                onClick={swapValidation}
                style={{
                  backgroundColor: isOnline ? color.primary : color.bordercolor,
                  marginRight: 10,
                  width: 130,
                }}
              >
                {loader ? (
                  <CircularProgress style={{ color: "#fff" }} size={18} />
                ) : isEmpty(SwapTrailer) ? (
                  "Add Trailer"
                ) : SwapTrailer === "1" ? (
                  `Swap Trailer`
                ) : (
                  "Remove Trailer"
                )}
              </Button>
              <Button
                variant="contained"
                style={{
                  backgroundColor: false ? color?.disable : color.secondary,
                  width: 130,
                }}
                onClick={() => {
                  setSwapModal(false);
                  setHorseTrailer({
                    id: "",
                    trailer_no: "",
                  });
                  setSwapTrailer("");
                  setIdealTrailer(1);
                  setLastInspection("");
                  setDKmReading("");
                  setErrObj({
                    ...errObj,
                    horseVehicleErr: false,
                    horseVehicleMsg: "",
                    tyreswapErr: false,
                    tyreswapMsg: "",
                    DkmErr: false,
                    DkmMsg: "",
                  });
                }}
              >
                Cancel
              </Button>
            </Grid>
          </div>
        </Box>
      </Modal>

      <ConfirmDialog
        title={`Are you sure you want to Add Inspection?`}
        visible={confirmDialog1}
        handleModal={(bool) => {
          if (bool) {
            addMainInspection();
          } else {
            setConfirmDialog1(false);
          }
        }}
        btnLoad={removeBtnLoad1}
      />
    </>
  );
}
