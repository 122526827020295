import { Grid, Typography, Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";
import { color } from "../../../Config/theme";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import * as XLSX from "xlsx/xlsx.mjs";
import { toast } from "react-toastify";
import moment from "moment";
import CDateRange from "../../Modal/CDateRange";

export default function Reports() {
  const [visible, setVisible] = useState(false);
  const { token, isOnline, permissionData, userdata } = useSelector(
    (state) => state.auth
  );
  const [reportLoader, setReportLoader] = useState(false);

  const [ticketListDisplay, setTicketListdisplay] = useState(true);

  useEffect(() => {
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Ticket Master List") {
            userdata?.role !== "superadmin" &&
              userdata?.role !== "admin" &&
              setTicketListdisplay(v?.isActive);
          }
        });
      });
    }
  }, [permissionData]);

  async function getTicketList(from_date, to_date) {
    setReportLoader(true);
    const fromDate = !isNull(from_date)
      ? moment(from_date).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(to_date) ? moment(to_date).format("YYYY-MM-DD") : "";

    try {
      const response = await getApiData(
        `${Setting.endpoints.ticketMasterReport}?from_date=${fromDate}&to_date=${toDate}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          downloadReport(response?.data);
        } else {
          toast.error(response?.message ? response?.message : "No Data Found");
        }
      } else {
        toast.error(response?.message);
      }
      auditSave("Ticket Master List");
      setReportLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
      setReportLoader(false);
    }
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Service Center`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadReport(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        "Ticket ID": item?.ticket_id || "-",
        "Fleet Name": item?.fleet_name || "-",
        "Vehicle No.": item?.vehicle_no || "-",
        "Vehicle Type": item?.vehicle_type || "-",
        "Vehicle Brand": item?.vehicle_brand || "-",
        "Vehicle Model": item?.vehicle_model || "-",
        "Route Origin": item?.route_origin || "-",
        "Route Destination": item?.route_destination || "-",
        "Goods Carried": item?.good_carried || "-",
        "Load Capacity": `${item?.load_capicity}${
          item?.load_capicity ? " Tonnes" : "-"
        }`,
        Issue: item?.issue || "-",
        "Payment Status": item?.payment_status || "-",
        "Notified By": item?.notify_by || "-",
        "Added On": item?.added_on || "-",
        "Added By": item?.added_by || "-",
      };
      return newObj;
    });

    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Ticket Master List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Ticket Master List.xlsx");
  }

  return (
    <>
      {ticketListDisplay && (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <Typography fontWeight={500}>Ticket Master List</Typography>
          <Button
            variant="contained"
            style={{
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
            disabled={isOnline ? false : true || reportLoader}
            onClick={() => setVisible(true)}
          >
            {reportLoader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              "Download"
            )}
          </Button>
        </Grid>
      )}
      <CDateRange
        isSelect
        disableFleet={true}
        disableVehicle={true}
        visible={visible}
        handleModal={(type, fleetIds, vehicleIds, fromDate, toDate, isAll) => {
          if (type === "download") {
            getTicketList(fromDate, toDate);
          }
          setVisible(false);
        }}
      />

      {/* <Divider />
      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <Typography>Vehicle Route and Issues</Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: color.secondary }}
        >
          Download
        </Button>
      </Grid>
      <Divider />

      <Grid
        item
        xs={12}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "15px",
        }}
      >
        <Typography>Vehicle Type, Goods Carried and Issues</Typography>
        <Button
          variant="contained"
          style={{ backgroundColor: color.secondary }}
        >
          Download
        </Button>
      </Grid> */}
    </>
  );
}
