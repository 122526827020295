import { makeStyles } from "@mui/styles";
import { isTablet } from "react-device-detect";
import { color } from "../../../Config/theme.js";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    "@media (max-width:425px)": {
      padding: 10,
    },
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
  cardBox: {
    border: "1px solid #D8D8D8",
    borderRadius: "5px",
    display: "flex",
    height: isTablet ? 140 : "",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: isTablet ? 20 : 10,
  },
  cardHead: {
    fontWeight: 700,
    fontSize: isTablet ? 16 : 12,
  },
  cardValue: {
    fontWeight: 800,
    fontSize: isTablet ? 22 : 16,
  },
}));
export default useStyles;
