import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Dashboard from "../Screens/Home/Dashboard";
import FleetManagement from "../Screens/Home/FleetManagement";
import Inspection from "../Screens/Home/Inspection";
import ScrapAnalysis from "../Screens/Home/ScrapAnalysis";
import ServiceCentre from "../Screens/Home/ServiceCentre";
import TyreHistory from "../Screens/Home/TyreHistory";
import UserManagement from "../Screens/Home/UserManagement";
import GeneralDropdowns from "../Screens/Home/GeneralDropdowns";
import ItemMaster from "../Screens/Home/ItemMaster";
import TyreInventory from "../Screens/Home/TyreInventory";

import ChangePassword from "../Screens/ChangePassword";
import Notification from "../Screens/Notification";

//Tablet
import TabScrapAnalysis from "../Screens/TabHome/TabScrapAnalysis";
import TabInspection from "../Screens/TabHome/TabInspection";
import TabFleetManagement from "../Screens/TabHome/TabFleetManagement";
import TabServiceCentre from "../Screens/TabHome/TabServiceCentre";
import { useSelector } from "react-redux";
import Offline from "../Screens/Offline";
import { isMobile, isTablet } from "react-device-detect";
import TabDashboard from "../Screens/TabHome/TabDashboard";
import TabTyreHistory from "../Screens/TabHome/TabTyreHistory";
import { isEmpty, isObject } from "lodash";
import TabTyreInventory from "../Screens/TabHome/TabTyreInventory";
import AuditLog from "../Screens/Home/AuditLog";
import VehicleMaster from "../Screens/Home/VehicleMaster";
import TrailerMaster from "../Screens/Home/TrailerMaster";
import TabAuditLog from "../Screens/TabHome/TabAuditLog";
import TabVehicleMaster from "../Screens/TabHome/TabVehicleMaster";
import TabTrailerMaster from "../Screens/TabHome/TabTrailerMaster";

function NavigationStack() {
  const { userdata, permissionData } = useSelector((state) => state.auth);
  // const { isTablet } = Setting;

  const [dashboardView, setDashboardView] = useState(true);
  const [fleetView, setFleetView] = useState(true);
  const [inspectionView, setInspectionView] = useState(true);
  const [scrapView, setScrapView] = useState(true);
  const [tyreView, setTyreView] = useState(true);
  const [ServiceView, setServiceView] = useState(true);

  const [dropURL, setDropURL] = useState("");

  useEffect(() => {
    let dtdt = "";
    if (userdata?.role !== "superadmin" && userdata?.role !== "admin") {
      if (userdata?.role === "call_centre") {
        dtdt = "/dashboard/ServiceCentre";
      } else if (dashboardView) {
        dtdt = "/dashboard";
      } else if (fleetView) {
        dtdt = "/dashboard/FleetManagement";
      } else if (inspectionView) {
        dtdt = "/dashboard/Inspection";
      } else if (scrapView) {
        dtdt = "/dashboard/ScrapAnalysis";
      } else if (tyreView) {
        dtdt = "/dashboard/TyreHistory";
      } else if (ServiceView) {
        dtdt = "/dashboard/ServiceCentre";
      } else {
        dtdt = "/dashboard";
      }
    } else {
      dtdt = "/dashboard";
    }

    setTimeout(() => setDropURL(dtdt), 1000);
  }, [
    dashboardView,
    fleetView,
    inspectionView,
    scrapView,
    tyreView,
    ServiceView,
  ]);

  useEffect(() => {
    if (
      isObject(permissionData?.permission) &&
      !isEmpty(permissionData?.permission)
    ) {
      Object.keys(permissionData?.permission).map((item) => {
        permissionData?.permission[item].map((v, index) => {
          if (v?.label === "Dashboard") {
            setDashboardView(v?.isActive);
          } else if (v?.label === "Fleet Management") {
            setFleetView(v?.isActive);
          } else if (v?.label === "Inspection Management") {
            setInspectionView(v?.isActive);
          } else if (v?.label === "Scrap Analysis") {
            setScrapView(v?.isActive);
          } else if (v?.label === "Service Centre") {
            setServiceView(v?.isActive);
          } else if (v?.label === "Tyre History") {
            setTyreView(v?.isActive);
          }
        });
      });
    } else {
      setDashboardView(true);
      setFleetView(true);
      setScrapView(true);
      setTyreView(true);
      setServiceView(true);
      setInspectionView(true);
    }
  }, [permissionData]);

  return isTablet || isMobile ? (
    <Routes>
      <Route
        exact
        path={"/"}
        element={
          <Navigate
            to={
              userdata?.role === "call_centre"
                ? "/dashboard/ServiceCentre"
                : dropURL || "/dashboard"
            }
          />
        }
      />
      {dashboardView && (
        <Route exact path={"/dashboard"} element={<TabDashboard />} />
      )}
      {inspectionView && (
        <Route path="/dashboard/Inspection" element={<TabInspection />} />
      )}
      {scrapView && (
        <Route path="/dashboard/ScrapAnalysis" element={<TabScrapAnalysis />} />
      )}
      {fleetView && (
        <Route
          path="/dashboard/FleetManagement"
          element={<TabFleetManagement />}
        />
      )}
      {ServiceView && (
        <Route path="/dashboard/ServiceCentre" element={<TabServiceCentre />} />
      )}
      {tyreView && (
        <Route path="/dashboard/TyreHistory" element={<TabTyreHistory />} />
      )}
      <Route path="/dashboard/TyreInventory" element={<TabTyreInventory />} />
      {(userdata?.role === "superadmin" || userdata?.role === "admin") && (
        <Route path="/dashboard/AuditLog" element={<TabAuditLog />} />
      )}
      {(userdata?.role === "superadmin" || userdata?.role === "admin") && (
        <Route path="/dashboard/VehicleMaster" element={<TabVehicleMaster />} />
      )}
      {/* {(userdata?.role === "superadmin" || userdata?.role === "admin") && ( */}
      <Route path="/dashboard/TrailerMaster" element={<TabTrailerMaster />} />
      {/* )} */}
      <Route path="/dashboard/change-password" element={<ChangePassword />} />
      <Route path="/dashboard/notification" element={<Notification />} />

      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  ) : (
    <Routes>
      <Route
        exact
        path={"/"}
        element={
          <Navigate
            to={
              userdata?.role === "call_centre"
                ? "/dashboard/ServiceCentre"
                : dropURL || "/dashboard"
            }
          />
        }
      />
      {dashboardView && <Route path="/dashboard" element={<Dashboard />} />}
      {fleetView && (
        <Route
          path="/dashboard/FleetManagement"
          element={<FleetManagement />}
        />
      )}
      {inspectionView && (
        <Route path="/dashboard/Inspection" element={<Inspection />} />
      )}
      {scrapView && (
        <Route path="/dashboard/ScrapAnalysis" element={<ScrapAnalysis />} />
      )}
      {ServiceView && (
        <Route path="/dashboard/ServiceCentre" element={<ServiceCentre />} />
      )}
      {tyreView && (
        <Route path="/dashboard/TyreHistory" element={<TyreHistory />} />
      )}
      {(userdata?.role === "superadmin" || userdata?.role === "admin") && (
        <Route path="/dashboard/UserManagement" element={<UserManagement />} />
      )}
      {(userdata?.role === "superadmin" || userdata?.role === "admin") && (
        <Route path="/dashboard/VehicleMaster" element={<VehicleMaster />} />
      )}
      {(userdata?.role === "superadmin" || userdata?.role === "admin") && (
        <Route path="/dashboard/ItemMaster" element={<ItemMaster />} />
      )}
      {/* {(userdata?.role === "superadmin" || userdata?.role === "admin") && ( */}
      <Route path="/dashboard/TrailerMaster" element={<TrailerMaster />} />
      {/* )} */}
      {(userdata?.role === "superadmin" || userdata?.role === "admin") && (
        <Route
          path="/dashboard/GeneralDropdowns"
          element={<GeneralDropdowns />}
        />
      )}
      <Route path="/dashboard/TyreInventory" element={<TyreInventory />} />
      {(userdata?.role === "superadmin" || userdata?.role === "admin") && (
        <Route path="/dashboard/AuditLog" element={<AuditLog />} />
      )}

      <Route path="/dashboard/change-password" element={<ChangePassword />} />
      <Route path="/dashboard/notification" element={<Notification />} />

      <Route path="*" element={<Navigate to={"/"} />} />
    </Routes>
  );
}

export default NavigationStack;
