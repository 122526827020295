import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Modal,
  Pagination,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { color } from "../../../Config/theme";
import { Search, SearchOutlined, Visibility } from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData } from "../../../Utils/APIHelper";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import moment from "moment";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import Images from "../../../Config/Images";
import * as XLSX from "xlsx/xlsx.mjs";
import ConfirmDialog from "../../ConfirmDialog";
import { isTablet } from "react-device-detect";
import { ResetIcon } from "../../Icon/TableToggle";

export default function TabOutstanding(props) {
  const styles = useStyles();
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    pageNo = 1,
    record = 10,
  } = props;
  const [selectedFile, setSlectedFile] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [listRows, setListRows] = useState(true);
  const [imgURL, setimgURL] = useState("");
  const [modal, openModal] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const { token, isOnline, userdata } = useSelector((state) => state.auth);

  useEffect(() => {
    getOutstandingList(false);
  }, [record, pageNo]);

  // useEffect(() => {
  //   setPageNo(1);
  // }, [record]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getOutstandingList(false);
  }, [datePicker, searchFilter]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [pageLoad, pageNo]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Service Centre`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getOutstandingList(bool, v) {
    v && setReportLoader(true);
    !v && setPageLoad(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.outstandingpaymentlist
        }?&per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadOutstandingpData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setListRows(listObj);
          }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Outstanding List");
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  function resetFilterData() {
    setSearchFilter("");
    setDatePicker(null);
  }

  const addedOn = (index) => {
    const timestamp = listRows?.data[index]?.created_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.importservicecenter;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };

  function clearData() {
    setSlectedFile(null);
  }
  function downloadOutstandingpData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.created_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        ID: row?.id,
        " Service Centre": row?.center_name || "-",
        Contact: row?.phone || "-",
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no || "-",
        "Invoice Details": row?.bill || "-",
        "Added On": displayDate || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Outstanding List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Outstanding List.xlsx");
  }
  return (
    <>
      {isTablet ? (
        <div className={styles.container} id="jump_to_me">
          <Grid container wrap="nowrap" justifyContent="space-between" gap={2}>
            <Grid item display="flex" alignItems="center">
              <Typography variant="tableTitle">Outstanding Payments</Typography>
            </Grid>
            <Grid item gap={1} wrap="nowrap" justifyContent="flex-end">
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disabled={isOnline ? false : true}
                  onClick={() => getOutstandingList(false, 1)}
                >
                  {reportLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid container gap={1} style={{ marginTop: 15 }} wrap="nowrap">
            <Grid item>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      handlePageNo(1);
                      getOutstandingList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Vehicle No., Centre Name and Phone"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Button
                varient="contained"
                onClick={() => {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    getOutstandingList(false);
                  }
                }}
                style={{
                  backgroundColor: color.secondary,
                  width: "100%",
                }}
              >
                <SearchOutlined />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={resetFilterData}
                disabled={!searchFilter && !datePicker}
              >
                <ResetIcon />
              </Button>
            </Grid>
            <Grid item marginLeft={"auto"}>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(listRows?.data) && !isEmpty(listRows?.data) ? (
            <>
              {listRows?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      padding: 15,
                      marginTop: 10,
                      overflow: "auto",
                      boxShadow: color.shadow,
                    }}
                    alignItems="center"
                  >
                    <Grid item container>
                      <Table className={styles.customtable}>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Ticket ID</TableCell>
                            <TableCell variant="head">Service Centre</TableCell>
                            <TableCell variant="head">Contact</TableCell>
                            <TableCell variant="head">Added On</TableCell>
                            <TableCell variant="head">Vehicle No.</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>{item?.id || "-"}</TableCell>
                          <TableCell>{item?.center_name || "-"}</TableCell>
                          <TableCell>{item?.phone || "-"}</TableCell>
                          <TableCell>{addedOn(index) || "-"}</TableCell>
                          <TableCell>{item?.vehicle_no || "-"}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Divider
                              variant="fullWidth"
                              style={{ margin: "15px 0" }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">
                              Invoice Details
                            </TableCell>
                            <TableCell
                              variant="head"
                              style={{ paddingLeft: 3 }}
                            >
                              Fleet Name
                            </TableCell>
                            <TableCell colSpan={3}>
                              <Grid container>
                                <Button
                                  style={{
                                    backgroundColor: color.primary,
                                    margin: "0 20px -10px auto",
                                  }}
                                  onClick={() => {
                                    handleClick("TicketID", item);
                                  }}
                                >
                                  <Visibility />
                                </Button>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell
                            style={{
                              color: isEmpty(item.bill)
                                ? color.disable
                                : color.primary,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setimgURL(item.bill);
                              !isEmpty(item.bill) && openModal(true);
                            }}
                          >
                            view
                          </TableCell>
                          <TableCell style={{ paddingLeft: 3 }}>
                            {item?.fleet_name || "-"}
                          </TableCell>
                        </TableRow>
                      </Table>
                    </Grid>
                  </Grid>
                );
              })}
              <Box
                style={{
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(listRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
              <ConfirmDialog
                title={`Are you sure you want to Upload Center List?`}
                visible={confirmDialog}
                handleModal={(bool) => {
                  if (bool) {
                    handleOnSubmit();
                  } else {
                    setConfirmDialog(false);
                  }
                }}
                btnLoad={removeBtnLoad}
              />
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container}>
          <Grid
            container
            backgroundColor={color.secondary}
            padding={"20px 10px"}
            color={color.white}
            borderRadius={1}
          >
            <Typography variant="tableTitle" id="jump_to_me">
              Service Center
            </Typography>
          </Grid>
          <Grid marginTop={"10px"}>
            <Typography variant="tableTitle">Outstanding Payments</Typography>
          </Grid>
          <div style={{ paddingTop: 14 }}>
            <Grid
              container
              wrap="nowrap"
              xs={12}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <TextField
                style={{ width: "80%", marginRight: 10 }}
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      handlePageNo(1);
                      getOutstandingList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Vehicle No., Centre Name and Phone"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />

              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
              <Button
                variant="contained"
                style={{
                  marginLeft: 10,
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                disabled={isOnline ? false : true}
                onClick={() => getOutstandingList(false, 1)}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </div>
          <div style={{ paddingTop: 14 }}>
            <Grid container xs={12} gap={1} wrap="nowrap">
              <Grid item xs={9}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={1.5}>
                <Button
                  varient="contained"
                  style={{
                    backgroundColor: color.secondary,
                    width: "100%",
                  }}
                  onClick={() => {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      getOutstandingList(false);
                    }
                  }}
                >
                  <SearchOutlined />
                </Button>
              </Grid>

              <Grid item xs={1.5}>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={resetFilterData}
                  disabled={!searchFilter && !datePicker}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
          </div>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(listRows?.data) && !isEmpty(listRows?.data) ? (
            <>
              {listRows?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      marginTop: 14,
                      boxShadow: color.shadow,
                    }}
                  >
                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>Ticket ID</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Service Centre
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>{item.id}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.center_name}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          background: "#EEF8FF",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>Contact</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Fleet Name
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                          background: "#EEF8FF",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>{item.phone}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.fleet_name}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Vehicle No.
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Invoice Details
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>{item.vehicle_no}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography
                            style={{
                              color: isEmpty(item.bill)
                                ? color.disable
                                : color.primary,
                              fontWeight: "bold",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              setimgURL(item.bill);
                              !isEmpty(item.bill) && openModal(true);
                            }}
                          >
                            view
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                          background: "#EEF8FF",
                          position: "relative",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>Added On</Typography>
                        </Grid>
                        <Grid container item xs={6}>
                          <Button
                            style={{
                              backgroundColor: color.primary,
                              position: "absolute",
                              right: 35,
                            }}
                            onClick={() => {
                              handleClick("TicketID", item);
                            }}
                          >
                            <Visibility />
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 15px",
                          background: "#EEF8FF",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6} wrap="wrap">
                          <Typography>{addedOn(index)}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Box
                style={{
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(listRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
              <ConfirmDialog
                title={`Are you sure you want to Upload Center List?`}
                visible={confirmDialog}
                handleModal={(bool) => {
                  if (bool) {
                    handleOnSubmit();
                  } else {
                    setConfirmDialog(false);
                  }
                }}
                btnLoad={removeBtnLoad}
              />
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
      <Modal
        style={{
          display: "flex",
          flex: 1,
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={() => {
          openModal(false);
        }}
        open={modal}
      >
        <img
          style={{
            backgroundColor: color.white,
            width: 250,
            borderRadius: 4,
          }}
          src={imgURL ? imgURL : ""}
          alt="Bill"
        />
      </Modal>
    </>
  );
}
