import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import {
  Box,
  Grid,
  Table,
  TableCell,
  TableRow,
  Typography,
  Select,
  TextField,
  MenuItem,
  InputAdornment,
  Button,
  Pagination,
  CircularProgress,
  Tooltip,
} from "@mui/material";
import { color } from "../../../Config/theme";
import { DataGrid } from "@mui/x-data-grid";
import { FilterAlt, Search } from "@mui/icons-material";

import { Setting } from "../../../Utils/Setting";
import { useSelector } from "react-redux";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { toast } from "react-toastify";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper";

export default function SwitchList(props) {
  const { vehicleData, handleClick = () => null } = props;
  const { token, dropDownData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );

  const styles = useStyles();
  const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [record, setRecord] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [pageLoad, setPageLoad] = useState(true);
  const [datePicker, setDatePicker] = useState(null);

  const [switchList, setSwitchList] = useState({});
  const [vehicleDetail, setVehicleDetail] = useState({});
  const [reportLoader, setReportLoader] = useState(false);

  const columns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 70,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 70,
    //   headerAlign: "center",
    //   sortable: false,
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip title="Remove" placement="right" arrow>
    //         <Delete
    //           style={{ color: color.primary, cursor: "pointer" }}
    //           // onClick={() => {
    //           //   handleClick("editVehicle", params?.row);
    //           // }}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 250,
      sortable: true,
      renderCell: (params) => {
        const fleetName = vehicleData?.fleet_name;
        return <Typography>{fleetName}</Typography>;
      },
    },
    {
      field: "vehicle_name",
      headerName: "Vehicle No.",
      width: 180,
      sortable: true,
      renderCell: (params) => {
        const vehicleName = vehicleData?.vehicle_name;
        return <Typography>{vehicleName}</Typography>;
      },
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 180,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              handleClick("tyreDetails", params?.row);
            }}
          >
            <Typography
              style={{
                whiteSpace: "pre-wrap",
                color: color.primary,
                fontWeight: "bold",
              }}
            >
              {params?.row?.tyre_serial_no}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "old_position",
      headerName: "Old Tyre Position",
      width: 180,
      sortable: true,
    },

    {
      field: "new_position",
      headerName: "New Tyre Position",
      width: 180,
      sortable: true,
    },
    {
      field: "tyre_type",
      headerName: "Tyre Type",
      width: 140,
      sortable: true,
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 140,
      sortable: true,
    },
    {
      field: "item_description",
      headerName: "Item Description",
      width: 300,
      sortable: true,
      renderCell: (params) => {
        return params?.row?.item_description || "-";
      },
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 180,
      sortable: true,
    },
    {
      field: "ply_rating",
      headerName: "Ply Rating",
      width: 130,
      sortable: true,
    },
    {
      field: "current_nsd",
      headerName: "Current NSD",
      width: 240,
      sortable: true,
      renderCell: (params) => {
        const nsdArr = params?.row?.current_nsd;
        let groove1 = 0;
        let groove2 = 0;
        let groove3 = 0;
        let groove4 = 0;
        let count1 = 1;
        let count2 = 1;
        let count3 = 1;
        let count4 = 1;
        isArray(nsdArr) &&
          !isEmpty(nsdArr) &&
          nsdArr.map((v) => {
            if (!isEmpty(v?.groove1)) {
              groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
              count1++;
            }
            if (!isEmpty(v?.groove2)) {
              groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
              count2++;
            }
            if (!isEmpty(v?.groove3)) {
              groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
              count3++;
            }
            if (!isEmpty(v?.groove4)) {
              groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
              count4++;
            }
          });
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
              groove2 !== 0 ? groove2.toFixed(2) : " - "
            }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
              groove4 !== 0 ? groove4.toFixed(2) : " - "
            } mm`}
          </Typography>
        );
      },
    },
    {
      field: "avg_nsd",
      headerName: "Avg. NSD",
      width: 160,
      sortable: true,
      renderCell: (params) => {
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${params?.row?.avg_nsd} mm` || "-"}
          </Typography>
        );
      },
    },
    {
      field: "mileage",
      headerName: "Odometer Reading",
      width: 180,
      sortable: true,
      renderCell: (params) => {
        return <Typography>{params?.row?.mileage || "0"} km</Typography>;
      },
    },
    {
      field: "tyre_condition",
      headerName: "Tyre Condition",
      width: 180,
      sortable: true,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.tyre_status === "Average"
                  ? "#FFFDD9"
                  : params?.row?.tyre_status === "Good"
                  ? "#D9FFE4"
                  : "#FFD9D9",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.tyre_status || "-"}</Typography>
          </div>
        );
      },
    },

    {
      field: "created_at",
      headerName: "Rotation Date",
      width: 200,
      // sortable: true,
      renderCell: (params) => {
        const timestamp = params?.row?.created_at;
        const displayDate = moment(params?.row?.created_at).format(
          "DD-MM-yyyy"
        );
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getSwitchList(false);
  }, [filter, pageNo, record]);

  useEffect(() => {
    getVehicleDetails();
  }, []);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getSwitchList(false);
  }, [datePicker, searchFilter]);

  async function getSwitchList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreHistoryDetails}?vehicle_id=${
          vehicleData?.vehicle_id
        }&per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&filter=${filter}&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadRDetails(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setSwitchList(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Rotation Details");
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      setReportLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${vehicleData.vehicle_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  function findTerrain() {
    let array = vehicleDetail?.terrain_ids?.split(",");
    let res = dropDownData?.terrain?.filter((item) => {
      let a = array?.includes(item?.id?.toString());
      return a;
    });
    return res.map((v, i) => (
      <>
        {v?.label}
        {i < res.length - 1 ? ", " : ""}
      </>
    ));
  }

  function resetFilterData() {
    setSearchFilter("");
    setDatePicker(null);
    setFilter("");
  }

  function nsd(data, index) {
    const nsdArr = data[index]?.current_nsd;
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(nsdArr) &&
      !isEmpty(nsdArr) &&
      nsdArr.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });
    return `${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
      groove2 !== 0 ? groove2.toFixed(2) : " - "
    }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
      groove4 !== 0 ? groove4.toFixed(2) : " - "
    } mm`;
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadRDetails(data) {
    const newData = data.map((row, index) => {
      const displayDate = moment(row?.created_at).format("DD-MM-yyyy");
      const nsdArr = row?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": vehicleData?.fleet_name,
        "Vehicle No.": vehicleData?.vehicle_name,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Old Tyre Position": row?.old_position || "-",
        "New Tyre Position": row?.new_position || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.item_description || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": Number(row?.avg_nsd) || "-",
        "Odometer Reading (Km)": Number(
          row?.mileage?.toString()?.replaceAll(",", "")
        ),
        "Tyre Condition": row?.tyre_status || "-",
        "Rotation Date": displayDate || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Rotation Details");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Rotation Details.xlsx");
  }

  return (
    <>
      <Grid className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography variant="tableTitle">
            {vehicleData?.fleet_name} / {vehicleData?.vehicle_name} - Rotation
            History
          </Typography>
        </Box>
        <div style={{ padding: 20 }}>
          <Table>
            <TableRow>
              <TableCell variant="head">Vehicle No.</TableCell>
              <TableCell>{vehicleDetail?.vehicle_no || "-"}</TableCell>
              <TableCell variant="head">Route Origin</TableCell>
              <TableCell>{vehicleDetail?.route_origin || "-"}</TableCell>
            </TableRow>
            <TableRow>
              {/* <TableCell variant="head">vehicle Identifier</TableCell>
              <TableCell>{vehicleDetail?.vehicle_identifier || "-"}</TableCell> */}
              {/* <TableCell variant="head">Route Origin</TableCell>
              <TableCell>{vehicleDetail?.route_origin || "-"}</TableCell> */}
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Type</TableCell>
              <TableCell>{vehicleDetail?.vehicle_type_id || "-"}</TableCell>
              <TableCell variant="head">Route Destination</TableCell>
              <TableCell>{vehicleDetail?.route_destination || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Brand</TableCell>
              <TableCell>{vehicleDetail?.vehicle_brand_id || "-"}</TableCell>
              <TableCell variant="head">No. of Tyres</TableCell>
              <TableCell>{vehicleDetail?.no_of_tyres || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Model</TableCell>
              <TableCell>{vehicleDetail?.vehicle_model || "-"}</TableCell>
              <TableCell variant="head">Rotations (Month)</TableCell>
              <TableCell>
                {vehicleDetail?.monthwise_rotationCount || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Terrain</TableCell>
              <TableCell>{findTerrain()}</TableCell>
              <TableCell variant="head">Rotations (Total)</TableCell>
              <TableCell>{vehicleDetail?.rotation_count || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Initial Reading</TableCell>
              <TableCell>
                {vehicleDetail?.initial_reading || "-"}{" "}
                {vehicleDetail?.initial_reading ? "Km" : ""}
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </Grid>
      <Grid className={styles.container} style={{ marginTop: 20, padding: 20 }}>
        <Grid
          item
          container
          style={{
            marginTop: 10,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            flexWrap: "nowrap",
          }}
        >
          <Typography variant="tableTitle">Rotation List</Typography>
          <Button
            variant="contained"
            style={{
              marginLeft: 10,
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
            disabled={isOnline ? false : true || reportLoader}
            onClick={() => getSwitchList(false, 1)}
          >
            {reportLoader ? (
              <CircularProgress style={{ color: color.white }} size={24} />
            ) : (
              "Download"
            )}
          </Button>
        </Grid>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          style={{ marginTop: 14, boxSizing: "border-box" }}
          wrap="nowrap"
        >
          <Grid item lg={2}>
            <Select
              fullWidth
              placeholder="Filter"
              value={filter}
              onChange={(value) => {
                setPageNo(1);
                setFilter(value.target.value);
              }}
              displayEmpty={true}
              IconComponent={FilterAlt}
              style={filter === "" ? { color: "#A2A2A2" } : {}}
              classes={{
                iconOpen: styles.iconOpen,
              }}
            >
              <MenuItem value="" hidden selected disabled>
                Filter
              </MenuItem>
              <MenuItem value={"Good"}>Good</MenuItem>
              <MenuItem value={"Average"}>Average</MenuItem>
              <MenuItem value={"Bad"}>Bad</MenuItem>
            </Select>
          </Grid>
          <Grid item lg={8.4} style={{ display: "flex", alignItems: "center" }}>
            <TextField
              placeholder="Search"
              className={styles.inputFieldStyle}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Tyre Serial No."
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
              onChange={(v) => {
                isEmpty(v?.target?.value) && getSwitchList(true);
                setSearchFilter(v?.target?.value);
              }}
              style={{ marginLeft: 20 }}
              onKeyDown={(v) => {
                if (v.key === "Enter") {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    setPageNo(1);
                    getSwitchList(false);
                  }
                }
              }}
            />
            <div style={{ marginLeft: 20 }}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  views={["year", "month", "day"]}
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    setPageNo(1);
                    getSwitchList(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={
                  filter === "" && isEmpty(searchFilter) && isNull(datePicker)
                    ? true
                    : false
                }
              >
                Reset
              </Button>
            </div>
          </Grid>
          <Grid
            item
            lg={1.6}
            style={{ display: "flex", alignItems: "center", marginLeft: 20 }}
          >
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              style={{ marginLeft: 10 }}
              value={record}
              onChange={(index) => {
                setPageNo(1);
                setRecord(index.target.value);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(switchList?.data) && !isEmpty(switchList?.data) ? (
          <>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                rows={switchList?.data}
                columns={columns}
                pageSize={record}
                hideFooter
                disableColumnMenu
                showCellRightBorder
                showColumnRightBorder
                disableSelectionOnClick
                autoHeight={true}
              />
            </div>
            <div
              style={{
                display: "flex",
                padding: "20px 0 0 0",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(switchList?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  setPageNo(e);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </Grid>
    </>
  );
}
