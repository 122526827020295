import React, { useEffect, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import ConfirmDialog from "../../ConfirmDialog";
import Images from "../../../Config/Images";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import { ResetIcon } from "../../Icon/TableToggle";

export default function TabScrapAnalysisComponent(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleSearch = () => null,
    search = "",
    handleDateT = () => null,
    dateT = null,
    handleDateF = () => null,
    dateF = null,
    pageNo = 1,
    record = 10,
    data,
  } = props;
  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  const [scrapListRows, setScrapListRows] = useState({});
  const [selectedFile, setSlectedFile] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  // const [datePicker, setDatePicker] = useState(null);
  // const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);
  const [downloadLoader, setDownloadLoader] = useState(false);

  const styles = useStyles();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { token, isOnline, userdata } = useSelector((state) => state.auth);

  const UnresolvedIssueColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "Fleet Name",
      width: 200,
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Scrap Tyres Analysed",
      width: 250,
      renderCell: (params) => {
        return (
          <Typography style={{ color: color.primary, fontWeight: "bold" }}>
            {params?.row?.no_of_tyres}
          </Typography>
        );
      },
    },

    {
      field: "capture_date",
      headerName: "Last Added On",
      width: 160,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return <Typography>{timestamp ? displayDate : ""}</Typography>;
      },
    },
    {
      field: "added_by",
      headerName: "Last Added By",
      width: 150,
    },
  ];

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, [pageLoad, pageNo]);

  useEffect(() => {
    if (!isEmpty(data) && data?.from === "notification") {
      const newDate = moment(data?.date);
      handleDateT(newDate);
      setDisable(false);
      handleDateF(newDate);
    }
  }, [data]);

  useEffect(() => {
    setPageLoad(true);
    ScrapList(false);
  }, [pageNo, record]);

  useEffect(() => {
    setTimeout(() => {
      if (data?.from === "notification" && !isNull(dateT) && !isNull(dateF)) {
        ScrapList(false);
      }
    }, 500);
  }, [dateT, dateF]);

  useEffect(() => {
    isNull(dateT) && isEmpty(search) && ScrapList(false);
  }, [dateT, dateF, search]);
  function clearData() {
    setSlectedFile(null);
  }
  function added_on(index) {
    const timestamp = scrapListRows?.data[index]?.capture_date;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return <Typography>{timestamp ? displayDate : ""}</Typography>;
  }

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.uploadCSV;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          ScrapList(false);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };
  function resetFilterData() {
    handleSearch("");
    handleDateT(null);
    handleDateF(null);
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Scrap Analysis`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function ScrapList(bool, v) {
    v && setDownloadLoader(true);
    !v && setPageLoad(true);
    const fromDate = !isNull(dateT) ? moment(dateT).format("YYYY-MM-DD") : "";
    const toDate = !isNull(dateF) ? moment(dateF).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.scraplist
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : search
        }&from_date=${fromDate}&to_date=${toDate}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadScrapData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setScrapListRows(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Scrap Analysis List");
      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setDownloadLoader(false);
    }
  }
  function downloadScrapData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.capture_date;
      const displayDate = moment(timestamp).format("DD-MM-yyyy");

      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.name,
        "No. of Scrap Tyres Analysed": Number(row?.no_of_tyres),
        "Last Added On": displayDate || "-",
        "Last Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Scrap Analysis List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Scrap Analysis List.xlsx");
  }

  return (
    <>
      {isTablet ? (
        <div className={styles.container}>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item sm={6} id="jump_to_me">
              <Typography variant="tableTitle">Scrap Analysis</Typography>
            </Grid>
            <Grid
              item
              sm={6}
              style={{
                display: "flex",
                justifyContent: sm ? "unset" : "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  handleClick("addScrapTyre");
                }}
              >
                Add Scrap Tyre
              </Button>
              {/* <Button
                variant="contained"
                component="label"
                style={{ marginLeft: 10, backgroundColor: color.secondary }}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    setSlectedFile(e.target.files[0]);
                    setConfirmDialog(true);
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  accept=".csv"
                  hidden
                />
                Upload CSV
              </Button> */}
              <Button
                style={{
                  marginLeft: 10,
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                  minWidth: "unset",
                }}
                disabled={isOnline ? false : true}
                onClick={() => ScrapList(false, 1)}
              >
                {downloadLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>
          <div>
            <Grid
              item
              container
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
              }}
              wrap="nowrap"
              columnGap={1}
            >
              <Grid item container alignItems={"center"} xs={11} wrap="nowrap">
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    value={dateT}
                    onChange={(newValue) => {
                      handleDateT(newValue);
                      setDisable(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(dateT) ? true : disable}
                    minDate={dateT}
                    disableFuture
                    value={dateF}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      handleDateF(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              disable || isNull(dateT) ? "" : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1} alignSelf={"flex-end"} textAlign="end">
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleRecord(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              gap={1}
              container
              style={{ marginTop: 10 }}
              alignItems={"center"}
              wrap="nowrap"
            >
              <Grid item xs={10} sm={10} md={6}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={search}
                  onChange={(v) => {
                    isEmpty(v.target.value) && ScrapList(true);
                    handleSearch(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (!isEmpty(search) || !isNull(dateT)) {
                        handlePageNo(1);
                        ScrapList(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Fleet Name"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (
                      !isEmpty(search) ||
                      (!isNull(dateT) && !isNull(dateF))
                    ) {
                      handlePageNo(1);
                      ScrapList(false);
                    }
                  }}
                >
                  <Search />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  onClick={resetFilterData}
                  disabled={!search && !dateT}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(scrapListRows?.data) &&
              !isEmpty(scrapListRows?.data) ? (
              <>
                <div style={{ marginTop: 10, overflow: "auto" }}>
                  <DataGrid
                    rows={scrapListRows?.data}
                    columns={UnresolvedIssueColumns}
                    disableColumnMenu
                    hideFooter
                    showCellRightBorder
                    disableSelectionOnClick
                    showColumnRightBorder
                    autoHeight={true}
                    onCellClick={(params) => {
                      if (params?.field !== "action") {
                        handleClick("scrapDetails", params?.row);
                      }
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(scrapListRows?.pagination?.totalPage)}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
          <ConfirmDialog
            title={`Are you sure you want to Upload CSV?`}
            visible={confirmDialog}
            handleModal={(bool) => {
              if (bool) {
                handleOnSubmit();
              } else {
                setConfirmDialog(false);
                clearData();
              }
            }}
            btnLoad={removeBtnLoad}
          />
        </div>
      ) : (
        <div className={styles.container}>
          <Grid
            container
            xs={6}
            sm={6}
            id="jump_to_me"
            backgroundColor={color.secondary}
            alignItems="center"
            color={color.white}
            borderRadius={1}
            minHeight="60px"
            padding={"10px"}
          >
            <Typography variant="tableTitle">Scrap Analysis</Typography>
          </Grid>
          <Grid
            container
            style={{ margin: "10px 0px" }}
            alignItems={"center"}
            wrap="nowrap"
            columnGap={1}
            justifyContent={"space-between"}
          >
            <Grid item container xs={9} gap={1} wrap="nowrap">
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>

            {/* <Grid item xs={3}>
              <Button
                variant="contained"
                component="label"
                style={{
                  backgroundColor: color.secondary,
                }}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    setSlectedFile(e.target.files[0]);
                    setConfirmDialog(true);
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  accept=".csv"
                  hidden
                />
                Upload CSV
              </Button>

            </Grid> */}
            <Grid
              item
              container
              wrap="nowrap"
              gap={1}
              justifyContent={"flex-end"}
            >
              <Grid item>
                <Button
                  variant="contained"
                  onClick={() => handleClick("addScrapTyre")}
                >
                  Add Scrap Tyre
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                    minWidth: "unset",
                  }}
                  disabled={isOnline ? false : true}
                  onClick={() => ScrapList(false, 1)}
                >
                  {downloadLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container alignItems={"center"} xs={10} wrap="nowrap">
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                disableFuture
                value={dateT}
                onChange={(newValue) => {
                  handleDateT(newValue);
                  setDisable(false);
                }}
                inputFormat="dd-MM-yyyy"
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{ svg: { color: color.primary } }}
                  />
                )}
              />
            </LocalizationProvider>
            <Typography style={{ marginLeft: 10, marginRight: 10 }}>
              to
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                disabled={isNull(dateT) ? true : disable}
                minDate={dateT}
                disableFuture
                value={dateF}
                inputFormat="dd-MM-yyyy"
                onChange={(newValue) => {
                  handleDateF(newValue);
                }}
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{
                      svg: {
                        color: disable || isNull(dateT) ? "" : color.primary,
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
          </Grid>
          <Grid
            container
            style={{ marginTop: 10 }}
            alignItems={"center"}
            wrap="nowrap"
            justifyContent="space-between"
          >
            <Grid item xs={10} sm={8} md={6}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && ScrapList(true);
                  handleSearch(v?.target?.value);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    if (!isEmpty(search) || !isNull(dateT)) {
                      handlePageNo(1);
                      ScrapList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Fleet Name"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              xs={2}
              wrap="nowrap"
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginLeft: 10,
              }}
            >
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(search) || (!isNull(dateT) && !isNull(dateF))) {
                    handlePageNo(1);
                    ScrapList(false);
                  }
                }}
              >
                <Search />
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={!search && !dateT}
              >
                <ResetIcon />
              </Button>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(scrapListRows?.data) && !isEmpty(scrapListRows?.data) ? (
            <>
              {scrapListRows?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    onClick={() => {
                      handleClick("scrapDetails", item);
                    }}
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      overflow: "hidden",
                      marginTop: 10,
                      boxShadow: color.shadow,
                    }}
                  >
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "10px 14px 0",
                      }}
                      xs={12}
                    >
                      <Grid item xs={6}>
                        <Typography fontWeight={"bold"}>Fleet Name</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={"bold"}>
                          No. of Scrap Tyres Analysed
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "0 14px 10px",
                      }}
                      xs={12}
                    >
                      <Grid item xs={6}>
                        <Typography>{item?.name}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography
                          style={{ color: color.primary, fontWeight: "bold" }}
                        >
                          {item?.no_of_tyres}
                        </Typography>
                      </Grid>
                    </Grid>

                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        background: "#EEF8FF",
                        padding: "10px 14px 0",
                      }}
                      xs={12}
                    >
                      <Grid item xs={6}>
                        <Typography fontWeight={"bold"}>
                          Last Added On
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography fontWeight={"bold"}>
                          Last Added By
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid
                      item
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        padding: "0 14px 10px",
                        background: "#EEF8FF",
                      }}
                      xs={12}
                    >
                      <Grid item xs={6}>
                        <Typography>{added_on(index)}</Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <Typography>{item?.added_by}</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(scrapListRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
          <ConfirmDialog
            title={`Are you sure you want to Upload Center List?`}
            visible={confirmDialog}
            handleModal={(bool) => {
              if (bool) {
                handleOnSubmit();
              } else {
                setConfirmDialog(false);
              }
            }}
            btnLoad={removeBtnLoad}
          />
        </div>
      )}
    </>
  );
}
