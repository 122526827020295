import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import ItemDetails from "../../../Components/ItemMaster/ItemDetails/index.js";
import { isEmpty } from "lodash";
import AddItemMaster from "../../../Components/ItemMaster/AddItemMaster/index.js";
import AddTyre from "../../../Components/TyreHistory/AddTyre/index.js";
import TyreDetails from "../../../Components/Fleet/TyreDetails/index.js";

export default function ItemMaster() {
  const styles = useStyles();
  const [changeTab, setChangeTab] = useState("");
  const [editMasterData, setEditMasterData] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const [pageNoIL, setPageNoIL] = useState(1);
  const [recordIL, setRecordIL] = useState(10);

  const [pageNoPR, setPageNoPR] = useState(1);
  const [recordPR, setRecordPR] = useState(10);
  const [search, setSearch] = useState("");

  useEffect(() => {
    document.title = Setting.page_name.ItemMaster;
  }, []);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [changeTab]);

  return (
    <div className={styles.card}>
      <Grid container id="jump_to_me">
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator="›">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            {changeTab === "AddItemMaster" ||
            changeTab === "addTyre" ||
            changeTab === "tyreDetails" ? (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab("");
                  setEditMasterData({});
                }}
              >
                Item Master
              </Link>
            ) : (
              <Typography className={styles.linkStyle}>Item Master</Typography>
            )}
            {changeTab === "AddItemMaster" && (
              <Typography className={styles.linkStyle}>
                {isEmpty(editMasterData)
                  ? "Add Item Master"
                  : "Edit Item Master"}
              </Typography>
            )}
            {changeTab === "addTyre" && (
              <Typography className={styles.linkStyle}>Edit Tyre</Typography>
            )}
            {changeTab === "tyreDetails" && (
              <Typography className={styles.linkStyle}>
                {editMasterData?.vehicle_no}
              </Typography>
            )}
            {changeTab === "tyreDetails" && (
              <Typography className={styles.linkStyle}>
                {editMasterData?.tyre_serial_no}
              </Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "AddItemMaster" ? (
        <AddItemMaster
          editData={editMasterData}
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
              setEditMasterData({});
            }
          }}
        />
      ) : changeTab === "tyreDetails" ? (
        <TyreDetails tyreData={editMasterData} />
      ) : changeTab === "addTyre" ? (
        <AddTyre
          vehicleDetails={editMasterData}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("");
            }
          }}
          from="itemMaster"
          editData={editMasterData}
        />
      ) : (
        <ItemDetails
          tabValue={tabValue}
          pageNoIL={pageNoIL}
          pageNoPR={pageNoPR}
          recordIL={recordIL}
          recordPR={recordPR}
          search={search}
          handleSearch={(search) => {
            setSearch(search);
          }}
          handleTab={(tab) => {
            setTabValue(tab);
          }}
          handlePageNoIL={(page) => {
            setPageNoIL(page);
          }}
          handleRecordIL={(rec) => {
            setRecordIL(rec);
          }}
          handlePageNoPR={(page) => {
            setPageNoPR(page);
          }}
          handleRecordPR={(rec) => {
            setRecordPR(rec);
          }}
          handleClick={(type, data) => {
            if (type === "AddItemMaster") {
              setChangeTab("AddItemMaster");
            } else if (type === "EditItemMaster") {
              setChangeTab("AddItemMaster");
              setEditMasterData(data);
            } else if (type === "addTyre") {
              setChangeTab("addTyre");
              setEditMasterData(data);
            } else if (type === "tyreDetails") {
              setChangeTab("tyreDetails");
              setEditMasterData(data);
            }
          }}
        />
      )}
    </div>
  );
}
