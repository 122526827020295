import React, { useState, useEffect } from "react";
import useStyles from "./styles.js";
import { color } from "../../../Config/theme.js";
import { Setting } from "../../../Utils/Setting.js";
import {
  Button,
  Grid,
  Typography,
  Table,
  TableCell,
  TableRow,
  TableHead,
  CircularProgress,
  Pagination,
  TableBody,
  Modal,
} from "@mui/material";
import * as XLSX from "xlsx/xlsx.mjs";
import { useSelector } from "react-redux";
import { getApiData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { isEmpty, isObject, isArray } from "lodash";
import moment from "moment";
import Images from "../../../Config/Images.js";
import { isTablet } from "react-device-detect";
import { Edit, Visibility } from "@mui/icons-material";
import TableToggle from "../../Icon/TableToggle/index.js";

export default function TabUserManagement(props) {
  const styles = useStyles();
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    pageNo,
    fleetData,
  } = props;
  const [pageLoad, setPageLoad] = useState(true);
  const { token, isOnline, userdata } = useSelector((state) => state.auth);
  const [userManagementList, setFleetListRows] = useState({});
  const [activeIndex, setactiveIndex] = useState("");
  const [value, setValue] = useState("");
  const [openCard, setOpenCard] = useState(false);

  useEffect(() => {
    getUserList(false);
  }, []);

  useEffect(() => {
    const section = document.querySelector(`#jump_to_meU`);
    section.scrollIntoView({ behavior: "auto", block: "center" });
  }, [pageNo]);

  useEffect(() => {
    setactiveIndex(null);
  }, [pageNo]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [activeIndex]);

  async function getUserList(bool, v) {
    setactiveIndex(null);
    let endpoints = `${Setting.endpoints.userlist}?fleet_id=${
      fleetData?.id
    }&is_download=${v ? v : ""}`;

    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadUserData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setFleetListRows(listObj);
          }
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function downloadUserData(data) {
    const newData = data.map((row, index) => {
      let role =
        row?.role === "fleet_manager"
          ? "Fleet Manager"
          : row?.role === "admin"
          ? "Admin"
          : row?.role === "fleet_owner"
          ? "Fleet Owner"
          : row?.role === "fk_ro"
          ? "FK RO"
          : row?.role === "call_centre"
          ? "Call Centre"
          : "FK Tyre Care Executive";

      let status = row?.status === 10 ? "Active" : "Inactive";

      const timestamp = row?.created_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "User ID": row?.user_name || "-",
        Name: row?.name || "-",
        Privilege: role || "-",
        "Employee Code": row?.sales_employee_code || "-",
        "Employee Designation": row?.employee_designation || "-",
        ID: row?.user_photo || "-",
        "Email ID": row?.email || "-",
        "Contact No.": row?.phone || "-",
        "User Status": status || "-",
        "Added On": displayDate || "-",
        "Added By": row?.added_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "User List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "User List.xlsx");
  }

  const getPrivilage = (index) => {
    let role = userManagementList?.data[index]?.role;
    return (
      <Typography
        style={{
          cursor: "pointer",
          backgroundColor:
            role === "fleet_manager"
              ? "#D9F8FF"
              : role === "admin"
              ? "#D9FFE4"
              : role === "fleet_owner"
              ? "#FFFDD9"
              : role === "fk_ro"
              ? "#FFD9D9"
              : role === "call_centre"
              ? "#d1dbff"
              : "#F5D9FF",
          borderRadius: 4,
          padding: 4,
        }}
      >
        {role === "fleet_manager"
          ? "Fleet Manager"
          : role === "admin"
          ? "Admin"
          : role === "fleet_owner"
          ? "Fleet Owner"
          : role === "fk_ro"
          ? "FK RO"
          : role === "call_centre"
          ? "Call Centre"
          : "FK Tyre Care Executive"}
      </Typography>
    );
  };

  const getStatus = (index) => {
    let status = userManagementList?.data[index]?.status;
    return (
      <Typography
        variant="span"
        style={{
          backgroundColor: status === 10 ? "#D9FFE4" : "#FFD9D9",
          padding: 4,
          borderRadius: 4,
        }}
      >
        {status === 10 ? "Active" : "Inactive"}
      </Typography>
    );
  };

  const getId = (index) => {
    const value =
      userManagementList?.data[index]?.role === "fleet_owner"
        ? userManagementList?.data[index]?.business_pan_card
        : userManagementList?.data[index]?.user_photo;
    if (!isEmpty(value)) {
      let Array = value.split(".");
      return Array[Array.length - 1] === "pdf" ? (
        <Typography
          style={{
            color: color.primary,
            cursor: "pointer",
          }}
          onClick={() => {
            const pdfWindow = window.open();
            pdfWindow.location.href = value;
          }}
        >
          View
        </Typography>
      ) : (
        <img
          style={{
            cursor: "pointer",
            width: 30,
            height: 30,
            borderRadius: 15,
            backgroundColor: !userManagementList.user_photo
              ? color.secondary
              : color.white,
          }}
          src={value || Images.profile}
          alt=""
          onClick={() => {
            setValue(value);
            setOpenCard(true);
          }}
        />
      );
    } else {
      return (
        <img
          style={{
            width: 30,
            height: 30,
            borderRadius: 15,
            backgroundColor: color.secondary,
          }}
          src={Images.profile}
          alt=""
        />
      );
    }
  };

  const addedOn = (index) => {
    const timestamp = userManagementList?.data[index]?.created_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return <Typography>{displayDate}</Typography>;
  };

  return (
    <>
      {isTablet ? (
        <div className={styles.container} style={{ marginTop: 20 }}>
          <Grid
            container
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Typography variant="tableTitle" id="jump_to_meU">
              User Management
            </Typography>
            <Grid item>
              <Button
                variant="contained"
                disabled={
                  userdata?.role !== "superadmin" && userdata?.role !== "admin"
                }
                onClick={() => {
                  handleClick("user");
                }}
              >
                Add User
              </Button>
              <Button
                disabled={isOnline ? false : true}
                variant="contained"
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                  marginLeft: 10,
                }}
                onClick={() => {
                  getUserList(false, 1);
                }}
              >
                <img src={Images.Mdownload} alt="download" />
              </Button>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(userManagementList?.data) &&
            !isEmpty(userManagementList?.data) ? (
            <>
              {userManagementList?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    style={{
                      marginTop: 10,
                      border: `1px solid ${color.bordercolor}`,
                      overflow: "auto",
                    }}
                  >
                    <Table className={styles.customtable}>
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">User ID</TableCell>
                          <TableCell variant="head">Name</TableCell>
                          <TableCell variant="head">Privilege</TableCell>
                          <TableCell variant="head">Employee Code</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{item?.user_name || "-"}</TableCell>
                          <TableCell>{item?.name || "-"}</TableCell>
                          <TableCell>{getPrivilage(index) || "-"}</TableCell>
                          <TableCell>
                            {item?.sales_employee_code || "-"}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">
                            Employee Designation
                          </TableCell>
                          <TableCell variant="head">ID</TableCell>
                          <TableCell variant="head">Email ID</TableCell>
                          <TableCell variant="head">Contact No.</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>
                            {item?.employee_designation || "-"}
                          </TableCell>
                          <TableCell>{getId(index) || "-"}</TableCell>
                          <TableCell>
                            <Typography
                              style={{
                                wordWrap: "break-word",
                                whiteSpace: "break-spaces",
                              }}
                            >
                              {item?.email || "-"}
                            </Typography>
                          </TableCell>
                          <TableCell>{item?.phone || "-"}</TableCell>
                        </TableRow>
                      </TableBody>
                      <TableBody>
                        <TableRow>
                          <TableCell variant="head">User Status</TableCell>
                          <TableCell variant="head">Added On</TableCell>
                          <TableCell variant="head">Added By</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell>{getStatus(index) || "-"}</TableCell>
                          <TableCell>{addedOn(index) || "-"}</TableCell>
                          <TableCell>{item?.added_by || "-"}</TableCell>
                        </TableRow>
                      </TableBody>
                      <TableBody>
                        <TableRow>
                          <TableCell colSpan={4} padding="10px 20px">
                            <Button
                              variant="contained"
                              disabled={
                                item?.role === "fleet_manager" ||
                                item?.role === "fleet_owner"
                              }
                              onClick={() => {
                                handleClick("user", item);
                              }}
                            >
                              <Edit />
                            </Button>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Grid>
                );
              })}
              <div
                style={{
                  padding: 10,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(userManagementList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div className={styles.container} style={{ marginTop: 10 }}>
          <Grid
            container
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            wrap="nowrap"
          >
            <Grid item xs={5}>
              <Typography wrap="wrap" variant="tableTitle" id="jump_to_meU">
                User Management
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              display="flex"
              wrap="nowrap"
              justifyContent={"flex-end"}
            >
              <Button
                variant="contained"
                disabled={
                  userdata?.role !== "superadmin" && userdata?.role !== "admin"
                }
                onClick={() => {
                  handleClick("user");
                }}
              >
                Add User
              </Button>
              <Button
                disabled={isOnline ? false : true}
                variant="contained"
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                  marginLeft: 10,
                }}
                onClick={() => {
                  getUserList(false, 1);
                }}
              >
                <img src={Images.Mdownload} alt="download" />
              </Button>
            </Grid>
          </Grid>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(userManagementList?.data) &&
            !isEmpty(userManagementList?.data) ? (
            <>
              {userManagementList?.data?.map((item, index) => {
                return (
                  <div
                    style={{
                      position: "relative",
                    }}
                  >
                    <Grid
                      item
                      container
                      id={`jump_to_me${index}`}
                      style={{
                        marginTop: 20,
                        border: `1px solid ${color.bordercolor}`,
                        alignItems: "center",
                        overflow: "hidden",
                        boxShadow: color.shadow,
                      }}
                    >
                      {activeIndex !== index ? (
                        <Table className={styles.customtableMobile}>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">User ID</TableCell>
                              <TableCell variant="head">Name</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.user_name || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.name || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Privilege</TableCell>
                              <TableCell variant="head">User Status</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 10 }}
                              >
                                {getPrivilage(index) || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 10 }}
                              >
                                {getStatus(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      ) : (
                        <Table className={styles.customtableMobile}>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">User ID</TableCell>
                              <TableCell variant="head">Name</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.user_name || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.name || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Privilege</TableCell>
                              <TableCell variant="head">User Status</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 10 }}
                              >
                                {getPrivilage(index) || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 10 }}
                              >
                                {getStatus(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">
                                Employee code
                              </TableCell>
                              <TableCell variant="head">
                                Employee Designation
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.sales_employee_code || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.employee_designation || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">ID</TableCell>
                              <TableCell variant="head">Email id</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {getId(index) || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.email || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Contact No.</TableCell>
                              <TableCell variant="head">Added On</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.phone || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {addedOn(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell
                                rowSpan={2}
                                style={{ paddingBottom: 5 }}
                              >
                                <Button
                                  variant="contained"
                                  onClick={() => {
                                    handleClick("user", item);
                                  }}
                                  disabled={
                                    item?.role === "fleet_manager" ||
                                    item?.role === "fleet_owner"
                                  }
                                >
                                  <Edit />
                                </Button>
                              </TableCell>
                              <TableCell variant="head">Added By</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.added_by || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      )}
                      <div
                        onClick={(v) => {
                          if (activeIndex === index) {
                            setactiveIndex(null);
                          } else {
                            setactiveIndex(index);
                          }
                        }}
                        style={{
                          position: "absolute",
                          bottom: -18,
                          left: "45%",
                        }}
                      >
                        {activeIndex === index ? (
                          <div style={{ transform: "rotate(180deg)" }}>
                            <TableToggle />
                          </div>
                        ) : (
                          <TableToggle />
                        )}
                      </div>
                    </Grid>
                  </div>
                );
              })}
              <div
                style={{
                  marginTop: 20,
                  marginBottom: 20,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(userManagementList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
      <Modal
        className={styles.modal}
        open={openCard}
        onClose={() => {
          setOpenCard(false);
        }}
      >
        <img
          style={{
            objectFit: "contain",
            borderRadius: 4,
            maxWidth: isTablet ? 550 : 330,
            maxHeight: isTablet ? 550 : 330,
          }}
          src={value || "-"}
          alt={"user id"}
        />
      </Modal>
    </>
  );
}
