import React, { useEffect, useState } from "react";
import {
  Typography,
  Box,
  Table,
  TableRow,
  TableCell,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import useStyles from "./styles.js";
import { color } from "../../../Config/theme";
import { getApiData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import moment from "moment";

export default function TicketId(props) {
  const { handleClick = () => null, editData } = props;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const styles = useStyles();
  const { token } = useSelector((state) => state.auth);
  const [ticketDetails, setTicketDetails] = useState({});
  const [vehicleDetails, setVehicleDetail] = useState({});
  const [openCard, setOpenCard] = useState(false);

  useEffect(() => {
    if (!isEmpty(editData)) {
      getTicketDetails();
      getVehicleDetails();
    }
  }, []);

  const getTicketDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.ticketdetails}?ticket_id=${editData.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setTicketDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${editData?.vehicle_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function AddedOn() {
    const timestamp = ticketDetails?.created_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return timestamp ? displayDate : "";
  }

  function ResolvedOn() {
    const timestamp = ticketDetails?.resolved_on;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return timestamp ? displayDate : "";
  }
  const checkFunction = () => {
    if (!isEmpty(vehicleDetails) && !isEmpty(vehicleDetails?.rc_book)) {
      let Array = vehicleDetails?.rc_book.split(".");
      if (Array[Array.length - 1] === "pdf") {
        const pdfWindow = window.open();
        pdfWindow.location.href = vehicleDetails?.business_pan_card;
      } else {
        setOpenCard(true);
      }
    }
  };

  return (
    <div>
      <div className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={"bold"}>
            Resolved Ticket ID - {ticketDetails?.id}
          </Typography>
        </Box>
      </div>
      <div
        className={styles.container}
        style={{ padding: 20, marginBottom: 20 }}
      >
        <Table>
          <TableRow>
            <TableCell variant="head">Ticket ID</TableCell>
            <TableCell>{ticketDetails?.id || "-"}</TableCell>
            <TableCell variant="head">Alignments Claimed</TableCell>
            <TableCell>{ticketDetails?.alignments_claimed}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Driver's Name</TableCell>
            <TableCell>{ticketDetails?.driver_name || "-"}</TableCell>
            <TableCell variant="head">Added By</TableCell>
            <TableCell>{ticketDetails?.added_by || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Driver's No.</TableCell>
            <TableCell>{ticketDetails?.driver_number || "-"}</TableCell>
            <TableCell variant="head">Added On</TableCell>
            <TableCell>{AddedOn() || "- "}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Goods Carried</TableCell>
            <TableCell>{ticketDetails?.good_carried || "-"}</TableCell>
            <TableCell variant="head">Resolved By</TableCell>
            <TableCell>{ticketDetails?.resolved_by || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Issue</TableCell>
            <TableCell>
              {ticketDetails?.issue === "cut"
                ? "Cut"
                : ticketDetails?.issue === "misallignment"
                ? "Misalignment"
                : "Puncture" || "-"}
            </TableCell>
            <TableCell variant="head">Resolved On</TableCell>
            <TableCell>{ResolvedOn() || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Notified By</TableCell>
            <TableCell>
              {ticketDetails?.notify_by === "driver"
                ? "Driver"
                : "Workshop Manager" || "-"}
            </TableCell>
            <TableCell variant="head">Route Origin</TableCell>
            <TableCell>{ticketDetails?.route_origin || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Bill</TableCell>
            <TableCell
              style={{
                color: color.primary,
                fontWeight: "bold",
                cursor: "pointer",
              }}
            >
              <a
                href={ticketDetails?.bill}
                target="_blank"
                style={{
                  textDecoration: "none",
                  color:
                    ticketDetails?.bill === ""
                      ? color.bordercolor
                      : color.primary,
                  pointerEvents: ticketDetails?.bill === "" ? "none" : "",
                }}
              >
                Download
              </a>
            </TableCell>
            <TableCell variant="head">Route Destination</TableCell>
            <TableCell>{ticketDetails?.route_destination || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Payment Status</TableCell>
            <TableCell>{ticketDetails?.payment_status || "-"}</TableCell>
            <TableCell variant="head">Ticket Status</TableCell>
            <TableCell>
              {ticketDetails?.status === 0 ? "Open" : "Resolved" || "-"}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Service Center</TableCell>
            <TableCell>{ticketDetails?.service_center || "-"}</TableCell>
            <TableCell variant="head">Service Center No.</TableCell>
            <TableCell>{ticketDetails?.center_phone || "-"}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell variant="head">Service Center Address</TableCell>
            <TableCell colSpan={3}>
              {ticketDetails?.center_location || "-"}
            </TableCell>
          </TableRow>
        </Table>
        {/* </div> */}
        <div style={{ paddingTop: 20 }}>
          {ticketDetails?.bill && (
            <img
              style={{ width: "100%" }}
              src={ticketDetails?.bill || "-"}
              alt="bill"
            />
          )}
          <div style={{ padding: "20px 2px" }}>
            <Typography fontWeight={"bold"}>Vehicle Details</Typography>
          </div>
          <Table>
            <TableRow>
              <TableCell variant="head">Vehicle No.</TableCell>
              <TableCell>{vehicleDetails?.vehicle_no || "-"}</TableCell>
              <TableCell variant="head">Fleet Name</TableCell>
              <TableCell>{vehicleDetails?.fleet_name || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Type</TableCell>
              <TableCell>{vehicleDetails?.vehicle_type_id || "-"}</TableCell>
              <TableCell variant="head">Vehicle Brand</TableCell>
              <TableCell>{vehicleDetails?.vehicle_brand_id || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Model</TableCell>
              <TableCell>{vehicleDetails?.vehicle_model || "-"}</TableCell>
              <TableCell variant="head">Initial Reading</TableCell>
              <TableCell>{vehicleDetails?.initial_reading || "-"} Km</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">No. of Tyres</TableCell>
              <TableCell>{vehicleDetails?.no_of_tyres || "-"}</TableCell>
              <TableCell variant="head">Load Capacity</TableCell>
              <TableCell>
                {vehicleDetails?.load_capicity || "-"} Tonnes
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Alignments Claimed</TableCell>
              <TableCell>{vehicleDetails?.alignment_count || "-"}</TableCell>
              <TableCell variant="head">RC Book Image</TableCell>
              <TableCell
                style={{
                  color: vehicleDetails?.rc_book
                    ? color.primary
                    : color.bordercolor,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={checkFunction}
              >
                View
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>
      <Modal
        className={styles.modal}
        open={openCard}
        onClose={() => {
          setOpenCard(false);
        }}
      >
        <img
          style={{
            objectFit: "contain",
            borderRadius: 4,
            maxWidth: 550,
            maxHeight: 550,
          }}
          src={vehicleDetails.rc_book || "-"}
          alt={"Business PAN Card"}
        />
      </Modal>
    </div>
  );
}
