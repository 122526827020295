import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    // flex: 1,
    // overflow: "auto",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    "@media (max-width:425px)": {
      padding: 10,
    },
  },
  fleetMain: {
    backgroundColor: color.white,
    marginTop: 20,
    borderRadius: 4,
    padding: 20,
    overflow: "auto",
    border: `1px solid ${color.bordercolor}`,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
  modal: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  iconOpen: {},
  "table, td, tr, th": {
    border: `1px solid ${color.bordercolor} !important`,
    padding: 10,
    backgroundColor: "red",
  },
  "th, td": {
    textAlign: "center",
  },
  modalHeader: {
    backgroundColor: color.primary,
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
  modalMain: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  upload: {
    backgroundColor: "transparent",
    height: 150,
    border: `2px dotted ${color.bordercolor}`,
    color: color.bordercolor,
    boxShadow: "none",
  },
  closeBtn: {
    position: "absolute",
    right: -10,
    top: -10,
    borderRadius: 20,
    padding: 0,
    boxShadow: "0px 0px 10px #00000052",
    color: color.primary,
    cursor: "pointer",
  },
}));
export default useStyles;
