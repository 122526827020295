import React, { useEffect, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import AddTrailerMaster from "../../../Components/TrailerMaster/AddTrailerMaster";
import useStyles from "./styles";
import TabTrailerMasterVeiw from "../../../Components/TabTrailerMaster/TabTrailerMasterView";
import { isMobile, isTablet } from "react-device-detect";
import { Box, Button } from "@mui/material";
import Images from "../../../Config/Images";
import { color } from "../../../Config/theme";
import { ArrowBackOutlined } from "@mui/icons-material";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function TabTrailerMaster() {
  const styles = useStyles();
  const isKeyboardOpen = useDetectKeyboardOpen();
  const [changeTab, setChangeTab] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    document.title = Setting.page_name.TrailerMaster;
  }, []);

  return (
    <div className={styles.card}>
      {isTablet
        ? null
        : !isKeyboardOpen &&
          changeTab === "" && (
            <Box
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                height: isMobile ? 30 : 40,
                position: "fixed",
                top: 14,
                left: 20,
                zIndex: 10,
              }}
            >
              <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
            </Box>
          )}

      {changeTab === "addTrailer" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              variant="contained"
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}

      {changeTab === "addTrailer" ? (
        <AddTrailerMaster
          editData={data}
          handleClick={(v, type) => {
            if (v === "cancle") {
              setChangeTab("");
            }
          }}
        />
      ) : (
        <TabTrailerMasterVeiw
          handleClick={(v, type) => {
            if (v === "addTrailer") {
              setChangeTab("addTrailer");
              setData({});
            }
            if (v === "editTrailer") {
              setChangeTab("addTrailer");
              setData(type);
            }
          }}
        />
      )}
    </div>
  );
}
