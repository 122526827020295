import { makeStyles } from "@mui/styles";
import { isTablet } from "react-device-detect";
import { color } from "../../../Config/theme";
import { Setting } from "../../../Utils/Setting";
const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    display: "flex",
    boxSizing: "border-box",
    overflow: "hidden !important",
    flexDirection: "column",
    padding: isTablet ? 20 : 10,
  },
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    overflow: "auto",
    padding: isTablet ? 20 : 10,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
  },
  customtable: {
    "& tr td": {
      border: "none !important",
      padding: 0,
      color: "#666666CC",
      width: "20%",
    },
    "& thead th": {
      border: "none !important",
      padding: 0,
      width: "20%",
      background: "transparent !important",
    },
  },

  customtableMobile: {
    "& tr td": {
      border: "none !important",
      padding: 0,
      color: "#666666CC",
      width: "50%",
    },
    "& thead th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      width: "50%",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: "#EEF8FF",
      },
    },
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
