import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  TableRow,
  TableCell,
  Table,
  CircularProgress,
  Pagination,
  MenuItem,
  Select,
  Grid,
  Button,
  InputAdornment,
  TextField,
  ListItemButton,
  Tooltip,
  Tabs,
  Tab,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import { Delete, Edit, FilterAlt, Search } from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";
import ConfirmDialog from "../../ConfirmDialog";

import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";

export default function InspectionIssueDetails(props) {
  const {
    handleClick = () => null,
    inspectionData,
    scroll,
    handlePTabValue = () => null,
    handleRTabValue = () => null,
    handlePageNoPT = () => null,
    handleRecordPT = () => null,
    handlePageNoPM = () => null,
    handleRecordPM = () => null,
    handlePageNoRT = () => null,
    handleRecordRT = () => null,
    handlePageNoRM = () => null,
    handleRecordRM = () => null,
    PtabValue = 0,
    tabValue = 0,
    pageNoPT = 1,
    recordPT = 10,
    pageNoPM = 1,
    recordPM = 10,
    pageNoRT = 1,
    recordRT = 10,
    pageNoRM = 1,
    recordRM = 10,
  } = props;
  const styles = useStyles();

  const { token, dropDownData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );

  const [resolutionDetails, setResolutionDetails] = useState({});
  const [PresolutionDetails, setPResolutionDetails] = useState({});
  const [vehicleDetail, setVehicleDetail] = useState({});

  const [pageLoad, setPageLoad] = useState(false);
  const [pageLoad1, setPageLoad1] = useState(false);
  const [pageLoad2, setPageLoad2] = useState(false);

  const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const [Pfilter, setPFilter] = useState("");
  const [PsearchFilter, setPSearchFilter] = useState("");

  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);

  const [PtypeList, setPtypeList] = useState(false);
  const [PbrandList, setPbrandList] = useState(false);
  const [PdatePicker, setPDatePicker] = useState(null);
  const [PdatePicker1, setPDatePicker1] = useState(null);
  const [Pdisable, setPDisable] = useState(true);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [issueData, setIssueData] = useState({});

  const InspectionIssueColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          tabValue === 0
            ? pageNoRT === 1
              ? index
              : (pageNoRT - 1) * recordRT + index
            : pageNoRM === 1
            ? index
            : (pageNoRM - 1) * recordRM + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 200,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 200,
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 200,
    },
    {
      field: "tyre_position",
      headerName: "Tyre Position",
      width: 200,
    },
    {
      field: "axle_type",
      sortable: false,
      headerName: "Axle Type",
      width: 180,
    },
    {
      field: "tyre_type",
      headerName: "Tyre Type",
      width: 200,
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 200,
    },
    {
      field: "tyre_pattern_size",
      headerName: "Item Description",
      width: 300,
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 200,
    },
    {
      field: "issue",
      headerName: tabValue === 1 ? "Mechanical Issue" : "Tyre Issue",
      width: 200,
      renderCell: (params) => {
        let array = params?.row?.issue;
        return !isEmpty(array) ? (
          <div
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {array.map((items, index) => {
              const isTwo = index <= 1;
              return isTwo ? (
                <Typography>
                  {items}
                  {index !== array.length - 1 ? ", " : ""}
                </Typography>
              ) : null;
            })}
            {array.length > 2 && (
              <span style={{ color: color.placeholder }}>{`+ ${
                array.length - 2
              } more`}</span>
            )}
          </div>
        ) : (
          <Typography variant="tableHead">-</Typography>
        );
      },
    },
    {
      field: "issue_identified_at",
      headerName: "Identified On",
      width: 200,
      renderCell: (params) => {
        const timestamp = params?.row?.issue_identified_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.issue_identified_at ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "issue_identified_by",
      headerName: "Identified By",
      width: 250,
    },
    {
      field: "resolved_at",
      headerName: "Resolved On",
      width: 250,
      renderCell: (params) => {
        const timestamp = params?.row?.resolved_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.resolved_at ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "resolved_by",
      headerName: "Resolved By",
      width: 250,
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: 250,
    },
  ];

  const PInspectionIssueColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex =
          PtabValue === 0
            ? pageNoPT === 1
              ? index
              : (pageNoPT - 1) * 10 + index
            : pageNoPM === 1
            ? index
            : (pageNoPM - 1) * 10 + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={"Edit"} placement="right" arrow>
            <Edit
              style={{
                color: color.primary,
                cursor: "pointer",
              }}
              onClick={() => {
                handleClick(
                  "resolveIssue",
                  params?.row,
                  PtabValue === 0 ? "tyre" : "mechanical",
                  params?.row?.issue[0]
                );
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "fleet_name",
      headerName: "Fleet Name",
      width: 200,
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: 200,
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 200,
    },
    {
      field: "tyre_position",
      headerName: "Tyre Position",
      width: 200,
    },
    {
      field: "axle_type",
      sortable: false,
      headerName: "Axle Type",
      width: 180,
    },
    {
      field: "tyre_type",
      headerName: "Tyre Type",
      width: 200,
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 200,
    },
    {
      field: "tyre_pattern_size",
      headerName: "Item Description",
      width: 300,
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 200,
    },
    {
      field: "issue",
      headerName: PtabValue === 1 ? "Mechanical Issue" : "Tyre Issue",
      width: 200,
      renderCell: (params) => {
        let array = params?.row?.issue;
        return !isEmpty(array) ? (
          <div
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {array.map((items, index) => {
              const isTwo = index <= 1;
              return isTwo ? (
                <Typography>
                  {items}
                  {index !== array.length - 1 ? ", " : ""}
                </Typography>
              ) : null;
            })}
            {array.length > 2 && (
              <span style={{ color: color.placeholder }}>{`+ ${
                array.length - 2
              } more`}</span>
            )}
          </div>
        ) : (
          <Typography variant="tableHead">-</Typography>
        );
      },
    },
    {
      field: "issue_identified_at",
      headerName: "Identified On",
      width: 200,
      renderCell: (params) => {
        const timestamp = params?.row?.issue_identified_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.issue_identified_at ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "issue_identified_by",
      headerName: "Identified By",
      width: 250,
    },
    {
      field: "resolved_at",
      headerName: "Resolved On",
      width: 250,
      renderCell: (params) => {
        const timestamp = params?.row?.resolved_at;
        const date = new Date(timestamp * 1000);
        const displayDate = moment(date).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.resolved_at ? displayDate : ""}
          </div>
        );
      },
    },
    {
      field: "resolved_by",
      headerName: "Resolved By",
      width: 250,
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: 250,
    },
    {
      field: "Delete",
      headerName: "Delete",
      width: 70,
      hide:
        userdata?.role === "superadmin" || userdata?.role === "admin"
          ? false
          : true,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        return (
          <Tooltip title={"Delete"} placement="left" arrow>
            <Delete
              style={{
                color: "red",
                cursor: "pointer",
              }}
              onClick={() => {
                setConfirmDialog(true);
                setIssueData(params?.row);
              }}
            />
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = Setting.page_name.Inspection;
    setPageLoad(true);
    getVehicleDetails();
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (scroll) {
        const section = document.querySelector(`#${scroll}`);
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }, [pageLoad]);

  useEffect(() => {
    setPageLoad1(true);
    getResolutionDetails(false);
  }, [pageNoRT, pageNoRM, recordRT, recordRM, filter, tabValue]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getResolutionDetails(false);
  }, [datePicker, datePicker1, searchFilter]);

  useEffect(() => {
    setPageLoad2(true);
    getPResolutionDetails(false);
  }, [pageNoPT, pageNoPM, recordPT, recordPM, Pfilter, PtabValue]);

  useEffect(() => {
    isNull(PdatePicker) &&
      isEmpty(PsearchFilter) &&
      getPResolutionDetails(false);
  }, [PdatePicker, PdatePicker1, PsearchFilter]);

  useEffect(() => {
    if (isOnline) {
      document.title = Setting.page_name.Inspection;
    }
  }, [isOnline]);

  useEffect(() => {
    setSearchFilter("");
    setFilter("");
  }, [tabValue]);

  useEffect(() => {
    setPSearchFilter("");
    setPFilter("");
  }, [PtabValue]);

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${inspectionData.vehicle_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  const deleteIssue = async () => {
    setLoader(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.deleteIssue}?id=${issueData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setConfirmDialog(false);
        getPResolutionDetails(false);
      }
      setLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setLoader(false);
    }
  };

  function resetFilterData() {
    setFilter("");
    setSearchFilter("");
    setDatePicker(null);
    setDatePicker1(null);
  }

  function resetPFilterData() {
    setPFilter("");
    setPSearchFilter("");
    setPDatePicker(null);
    setPDatePicker1(null);
  }

  async function getResolutionDetails(bool, v) {
    !v && setPageLoad1(true);
    const fromDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePicker1)
      ? moment(datePicker1).format("YYYY-MM-DD")
      : "";

    let endpoints = `${Setting.endpoints.resolutionDetail}?type=${
      tabValue === 1 ? "mechanical" : "tyre"
    }&page=${tabValue === 0 ? pageNoRT : pageNoRM}&per_page=${
      tabValue ? recordRT : recordRM
    }&search=${bool ? "" : searchFilter}&vehicle_id=${
      inspectionData?.vehicle_id
    }&status=1&from_date=${fromDate}&to_date=${toDate}&filter=${filter}&is_download=${
      v ? v : ""
    }`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            tabValue === 1
              ? downloadResolutionData("mech", response?.data?.rows, "resolved")
              : downloadResolutionData("", response?.data?.rows, "resolved");
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setResolutionDetails(listObj);
          }
        v &&
          auditSave(
            tabValue === 1
              ? "Mechanical Resolution List"
              : "Tyre Resolution List"
          );
      } else {
        toast.error(response?.message);
      }
      setPageLoad1(false);
    } catch (error) {
      setPageLoad1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getPResolutionDetails(bool, v) {
    !v && setPageLoad2(true);
    const fromDate = !isNull(PdatePicker)
      ? moment(PdatePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(PdatePicker1)
      ? moment(PdatePicker1).format("YYYY-MM-DD")
      : "";

    let endpoints = `${Setting.endpoints.resolutionDetail}?type=${
      PtabValue === 1 ? "mechanical" : "tyre"
    }&page=${PtabValue === 0 ? pageNoPT : pageNoPM}&per_page=${
      PtabValue === 0 ? recordPT : recordPM
    }&search=${bool ? "" : PsearchFilter}&vehicle_id=${
      inspectionData?.vehicle_id
    }&status=2&from_date=${fromDate}&to_date=${toDate}&filter=${Pfilter}&is_download=${
      v ? v : ""
    }`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            PtabValue === 1
              ? downloadResolutionData("mech", response?.data?.rows, "")
              : downloadResolutionData("", response?.data?.rows, "");
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setPResolutionDetails(listObj);
          }
        v &&
          auditSave(
            PtabValue === 1
              ? "Partially Mechanical Resolution List"
              : "Partially Tyre Resolution List"
          );
      } else {
        toast.error(response?.message);
      }
      setPageLoad2(false);
    } catch (error) {
      setPageLoad2(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadResolutionData(tab, data, title) {
    const newData = data.map((row, index) => {
      const timestamp = row?.issue_identified_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");

      const timestamp1 = row?.resolved_at;
      const date1 = new Date(timestamp1 * 1000);
      const displayDate1 = moment(date1).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Position": row?.tyre_position || "-",
        "Axle Type": row?.axle_type || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.tyre_pattern_size || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Tyre Issue": row?.issue.toString() || "-",
        "Identified On": row?.issue_identified_at ? displayDate : "|| " - ",",
        "Identified By": row?.issue_identified_by || "-",
        "Resolved On": row?.resolved_at ? displayDate1 : "|| " - ",",
        "Resolved By": row?.resolved_by || "-",
        Remarks: row?.remark || "-",
      };

      const newObj1 = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Position": row?.tyre_position || "-",
        "Axle Type": row?.axle_type || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.tyre_pattern_size || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Mechanical Issue": row?.issue.toString() || "-",
        "Identified On": row?.issue_identified_at ? displayDate : "-",
        "Identified By": row?.issue_identified_by || "-",
        "Resolved On": row?.resolved_at ? displayDate1 : "-",
        "Resolved By": row?.resolved_by || "-",
        Remarks: row?.remark || "-",
      };

      return tab === "mech" ? newObj1 : newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      title === "resolved"
        ? tab === "mech"
          ? "Mechanical Resolution List"
          : "Tyre Resolution List"
        : tab === "mech"
        ? "Partially Mechanical Resolution"
        : "Partially Tyre Resolution"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(
      workBook,
      title === "resolved"
        ? tab === "mech"
          ? "Mechanical Resolution List.xlsx"
          : "Tyre Resolution List.xlsx"
        : tab === "mech"
        ? "Partially Mechanical Resolution List.xlsx"
        : "Partially Tyre Resolution List.xlsx"
    );
  }

  const getTerrain = () => {
    let array = vehicleDetail?.terrain_ids?.split(",");
    let res = dropDownData?.terrain.filter((item) => {
      let a = array?.includes(item?.id?.toString());
      return a;
    });
    return res.map((v, i) => (
      <div>
        {v?.label}
        {i < res.length - 1 ? ", " : ""}
      </div>
    ));
  };

  const getLastInspectionDate = () => {
    const timestamp = vehicleDetail?.last_inspected_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return timestamp ? displayDate : "";
  };

  return (
    <>
      <div className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={"bold"}>
            {inspectionData?.fleet_name}
            {" / "}
            {inspectionData?.vehicle_no}
          </Typography>
        </Box>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : (
          <div style={{ padding: 20 }}>
            <Table>
              <TableRow>
                <TableCell variant="head">Vehicle No.</TableCell>
                <TableCell>{vehicleDetail?.vehicle_no || "-"}</TableCell>
                <TableCell variant="head">No. of Tyres</TableCell>
                <TableCell>
                  {vehicleDetail?.added_tyre_count}
                  {" / "}
                  {vehicleDetail?.no_of_tyres || "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Type</TableCell>
                <TableCell>{vehicleDetail?.vehicle_type_id || "-"}</TableCell>
                <TableCell variant="head">Initial Reading</TableCell>
                <TableCell>
                  {vehicleDetail?.initial_reading || "-"}
                  {vehicleDetail?.initial_reading ? " Km" : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Brand</TableCell>
                <TableCell>{vehicleDetail?.vehicle_brand_id || "-"}</TableCell>
                <TableCell variant="head">Current Reading</TableCell>
                <TableCell>
                  {vehicleDetail?.fitment_reading}
                  {vehicleDetail?.fitment_reading ? " Km" : ""}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Model</TableCell>
                <TableCell>{vehicleDetail?.vehicle_model || "-"}</TableCell>
                <TableCell variant="head">Inspection Date</TableCell>
                <TableCell>{getLastInspectionDate()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Terrain</TableCell>
                <TableCell>{getTerrain()}</TableCell>
                <TableCell variant="head">Inspected By</TableCell>
                <TableCell>{vehicleDetail?.inspected_by || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Route Origin</TableCell>
                <TableCell>{vehicleDetail?.route_origin || "-"}</TableCell>
                <TableCell variant="head">Inspections (Month)</TableCell>
                <TableCell>
                  {vehicleDetail?.inspection_monthly || "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Route Destination</TableCell>
                <TableCell>{vehicleDetail?.route_destination || "-"}</TableCell>
                <TableCell variant="head">Inspections (Overall)</TableCell>
                <TableCell>
                  {vehicleDetail?.inspection_overall || "-"}
                </TableCell>
              </TableRow>
            </Table>
          </div>
        )}
      </div>
      <div
        id="resolve"
        className={styles.container}
        style={{ marginTop: 20, padding: 20, marginBottom: 20 }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Typography variant="tableTitle">Resolved Issue List</Typography>
          </Grid>
          <Button
            variant="contained"
            disabled={isOnline ? false : true}
            style={{
              marginLeft: 10,
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
            onClick={() => getResolutionDetails(false, 1)}
          >
            Download
          </Button>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
          <Tabs
            value={tabValue}
            onChange={(v, b) => {
              handlePageNoRT(1);
              handleRecordRT(10);
              handlePageNoRM(1);
              handleRecordRM(10);
              handleRTabValue(b);
            }}
            variant="scrollable"
          >
            <Tab className={styles.tabBtn} label="Tyre" />
            <Tab className={styles.tabBtn} label="Mechanical" />
          </Tabs>
        </Box>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          style={{ marginTop: 14 }}
        >
          <Grid item lg={2}>
            <Select
              fullWidth
              placeholder="Filter"
              value={filter}
              onChange={(v) => {
                tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                setFilter(v.target.value);
              }}
              onOpen={() => {
                if (isEmpty(filter.toString())) {
                  settypeList(false);
                  setbrandList(false);
                }
              }}
              displayEmpty
              IconComponent={FilterAlt}
              style={filter === "" ? { color: "#A2A2A2" } : {}}
              classes={{
                iconOpen: styles.iconOpen,
              }}
            >
              <MenuItem
                value={""}
                hidden
                selected
                disabled
                style={{ minWidth: 220 }}
              >
                Filter
              </MenuItem>
              <ListItemButton
                onClick={() => {
                  settypeList(!typeList);
                  setbrandList(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Tyre Brand</Typography>
                {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              {dropDownData.tyre_brand.map((item, index) => {
                return (
                  <MenuItem
                    style={{
                      display: typeList ? "flex" : "none",
                    }}
                    key={index}
                    value={item?.id}
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
              <ListItemButton
                onClick={() => {
                  setbrandList(!brandList);
                  settypeList(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Axle Type</Typography>
                {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              {dropDownData.axle_type.map((item, index) => {
                return (
                  <MenuItem
                    style={{
                      display: brandList ? "flex" : "none",
                    }}
                    key={index}
                    value={item?.id}
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
              {/* <ListItemButton
                  onClick={() => {
                    setIssueList(!issueList);
                    settypeList(false);
                    setbrandList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Issue</Typography>
                  {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {issueList &&
                  dropDownData.tyre_issue_inspection.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    );
                  })} */}
            </Select>
          </Grid>
          <Grid item lg={1.6} style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={tabValue === 0 ? recordRT : recordRM}
              onChange={(v) => {
                tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                tabValue === 0
                  ? handleRecordRT(v.target.value)
                  : handleRecordRM(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginTop: 20 }}
          alignItems={"center"}
          wrap="nowrap"
        >
          <Grid item lg={2}>
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={searchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && getResolutionDetails(true);
                setSearchFilter(v?.target?.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  if (
                    !isEmpty(searchFilter) ||
                    !isNull(datePicker) ||
                    !isNull(datePicker1)
                  ) {
                    tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                    getResolutionDetails(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Tyre Serial No. and Resolved By"
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                disableFuture
                views={["year", "month", "day"]}
                value={datePicker}
                onChange={(newValue) => {
                  setDatePicker(newValue);
                  setDisable(false);
                }}
                inputFormat="dd-MM-yyyy"
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{ svg: { color: color.primary } }}
                  />
                )}
              />
            </LocalizationProvider>
            <Typography style={{ marginLeft: 10, marginRight: 10 }}>
              to
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                disabled={isNull(datePicker) ? true : disable}
                minDate={datePicker}
                views={["year", "month", "day"]}
                disableFuture
                value={datePicker1}
                inputFormat="dd-MM-yyyy"
                onChange={(newValue) => {
                  setDatePicker1(newValue);
                }}
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{
                      svg: {
                        color:
                          disable || isNull(datePicker) ? "" : color.primary,
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary, marginLeft: 20 }}
              onClick={() => {
                if (
                  !isEmpty(searchFilter) ||
                  (!isNull(datePicker) && !isNull(datePicker1))
                ) {
                  tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                  getResolutionDetails(false);
                }
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={resetFilterData}
              disabled={
                filter === "" && isEmpty(searchFilter) && isNull(datePicker)
                  ? true
                  : false
              }
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        {pageLoad1 ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(resolutionDetails?.data) &&
          !isEmpty(resolutionDetails?.data) ? (
          <div style={{ marginTop: 20 }}>
            <DataGrid
              rows={resolutionDetails?.data}
              columns={InspectionIssueColumn}
              disableColumnMenu
              hideFooter
              showCellRightBorder
              disableSelectionOnClick
              showColumnRightBorder
              getRowHeight={() => "auto"}
              autoHeight={true}
              onCellClick={(params) => {
                if (params?.field !== "action") {
                  handleClick("vehicleInspection", params?.row);
                }
              }}
            />
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(resolutionDetails?.pagination?.totalPage)}
                defaultPage={tabValue === 0 ? pageNoRT : pageNoRM}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  tabValue === 0 ? handlePageNoRT(e) : handlePageNoRM(e);
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>

      <div
        id="partiallyresolve"
        className={styles.container}
        style={{ padding: 20, marginBottom: 20 }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item>
            <Typography variant="tableTitle">
              Partially Resolved Issue List
            </Typography>
          </Grid>
          <Button
            disabled={isOnline ? false : true}
            variant="contained"
            style={{
              marginLeft: 10,
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
            onClick={() => getPResolutionDetails(false, 1)}
          >
            Download
          </Button>
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
          <Tabs
            value={PtabValue}
            onChange={(v, b) => {
              handlePageNoPT(1);
              handleRecordPT(10);
              handlePageNoPM(1);
              handleRecordPM(10);
              handlePTabValue(b);
            }}
            variant="scrollable"
          >
            <Tab className={styles.tabBtn} label="Tyre" />
            <Tab className={styles.tabBtn} label="Mechanical" />
          </Tabs>
        </Box>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
          style={{ marginTop: 14 }}
        >
          <Grid item lg={2}>
            <Select
              fullWidth
              placeholder="Filter"
              value={Pfilter}
              onChange={(v) => {
                PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                setPFilter(v.target.value);
              }}
              onOpen={() => {
                if (isEmpty(Pfilter.toString())) {
                  setPtypeList(false);
                  setPbrandList(false);
                }
              }}
              displayEmpty
              IconComponent={FilterAlt}
              style={Pfilter === "" ? { color: "#A2A2A2" } : {}}
              classes={{
                iconOpen: styles.iconOpen,
              }}
            >
              <MenuItem
                value={""}
                hidden
                selected
                disabled
                style={{ minWidth: 220 }}
              >
                Filter
              </MenuItem>
              <ListItemButton
                onClick={() => {
                  setPtypeList(!PtypeList);
                  setPbrandList(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Tyre Brand</Typography>
                {PtypeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              {dropDownData.tyre_brand.map((item, index) => {
                return (
                  <MenuItem
                    style={{
                      display: PtypeList ? "flex" : "none",
                    }}
                    key={index}
                    value={item?.id}
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
              <ListItemButton
                onClick={() => {
                  setPbrandList(!PbrandList);
                  setPtypeList(false);
                }}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Typography>Axle Type</Typography>
                {PbrandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </ListItemButton>
              {dropDownData.axle_type.map((item, index) => {
                return (
                  <MenuItem
                    style={{
                      display: PbrandList ? "flex" : "none",
                    }}
                    key={index}
                    value={item?.id}
                  >
                    {item?.label}
                  </MenuItem>
                );
              })}
              {/* <ListItemButton
                  onClick={() => {
                    setIssueList(!issueList);
                    settypeList(false);
                    setbrandList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Issue</Typography>
                  {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {issueList &&
                  dropDownData.tyre_issue_inspection.map((item, index) => {
                    return (
                      <MenuItem key={index} value={item?.id}>
                        {item?.label}
                      </MenuItem>
                    );
                  })} */}
            </Select>
          </Grid>
          <Grid item lg={1.6} style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            {PtabValue === 0 ? (
              <Select
                fullWidth
                value={recordPT}
                onChange={(v) => {
                  handlePageNoPT(1);
                  handleRecordPT(v.target.value);
                }}
                style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            ) : (
              <Select
                fullWidth
                value={recordPM}
                onChange={(v) => {
                  handlePageNoPM(1);
                  handleRecordPM(v.target.value);
                }}
                style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            )}
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginTop: 20 }}
          alignItems={"center"}
          wrap="nowrap"
        >
          <Grid item lg={2}>
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={PsearchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && getPResolutionDetails(true);
                setPSearchFilter(v?.target?.value);
              }}
              onKeyDown={(ev) => {
                if (ev.key === "Enter") {
                  if (
                    !isEmpty(PsearchFilter) ||
                    !isNull(PdatePicker) ||
                    !isNull(PdatePicker1)
                  ) {
                    PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                    getPResolutionDetails(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip
                        title="Search by Tyre Serial No. and Resolved By"
                        placement="bottom"
                        arrow
                      >
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: 20,
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                disableFuture
                views={["year", "month", "day"]}
                value={PdatePicker}
                onChange={(newValue) => {
                  setPDatePicker(newValue);
                  setPDisable(false);
                }}
                inputFormat="dd-MM-yyyy"
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{ svg: { color: color.primary } }}
                  />
                )}
              />
            </LocalizationProvider>
            <Typography style={{ marginLeft: 10, marginRight: 10 }}>
              to
            </Typography>
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <DatePicker
                showToolbar={false}
                disabled={isNull(PdatePicker) ? true : Pdisable}
                minDate={PdatePicker}
                views={["year", "month", "day"]}
                disableFuture
                value={PdatePicker1}
                inputFormat="dd-MM-yyyy"
                onChange={(newValue) => {
                  setPDatePicker1(newValue);
                }}
                DialogProps={{ className: styles.datePicker }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    inputProps={{
                      ...params.inputProps,
                      placeholder: "DD-MM-YYYY",
                    }}
                    sx={{
                      svg: {
                        color:
                          Pdisable || isNull(PdatePicker) ? "" : color.primary,
                      },
                    }}
                  />
                )}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary, marginLeft: 20 }}
              onClick={() => {
                if (
                  !isEmpty(PsearchFilter) ||
                  (!isNull(PdatePicker) && !isNull(PdatePicker1))
                ) {
                  PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                  getPResolutionDetails(false);
                }
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={resetPFilterData}
              disabled={
                Pfilter === "" && isEmpty(PsearchFilter) && isNull(PdatePicker)
                  ? true
                  : false
              }
            >
              Reset
            </Button>
          </Grid>
        </Grid>
        {pageLoad2 ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(PresolutionDetails?.data) &&
          !isEmpty(PresolutionDetails?.data) ? (
          <div style={{ marginTop: 20 }}>
            <DataGrid
              rows={PresolutionDetails?.data}
              columns={PInspectionIssueColumn}
              disableColumnMenu
              hideFooter
              showCellRightBorder
              disableSelectionOnClick
              showColumnRightBorder
              getRowHeight={() => "auto"}
              autoHeight={true}
              onCellClick={(params) => {
                if (params?.field !== "action") {
                  handleClick("vehicleInspection", params?.row);
                }
              }}
            />
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(PresolutionDetails?.pagination?.totalPage)}
                defaultPage={PtabValue === 0 ? pageNoPT : pageNoPM}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  PtabValue === 0 ? handlePageNoPT(e) : handlePageNoPM(e);
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
      <ConfirmDialog
        title={`Are you sure you want to Delete Issue?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteIssue();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={loader}
      />
    </>
  );
}
