import React, { useEffect, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { color } from "../../../Config/theme.js";
import { Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import ConfirmDialog from "../../ConfirmDialog";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import * as XLSX from "xlsx/xlsx.mjs";

export default function ScrapAnalysisComponent(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleSearch = () => null,
    search = "",
    handleDateT = () => null,
    dateT = null,
    handleDateF = () => null,
    dateF = null,
    pageNo = 1,
    record = 10,
    data,
  } = props;
  const [pageLoad, setPageLoad] = useState(true);
  // const [searchFilter, setSearchFilter] = useState("");
  const [scrapListRows, setScrapListRows] = useState({});
  const [selectedFile, setSlectedFile] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  // const [datePicker, setDatePicker] = useState(null);
  const [disable, setDisable] = useState(true);
  // const [datePicker1, setDatePicker1] = useState(null);
  const [reportLoader, setReportLoader] = useState(false);

  const styles = useStyles();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const { token, isOnline, userdata } = useSelector((state) => state.auth);

  const UnresolvedIssueColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 100,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "Fleet Name",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "no_of_tyres",
      headerName: "No. of Scrap Tyres Analysed",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return (
          <Typography style={{ color: color.primary, fontWeight: "bold" }}>
            {params?.row?.no_of_tyres}
          </Typography>
        );
      },
    },
    {
      field: "capture_date",
      headerName: "Last Added On",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <Typography>
            {params?.row?.capture_date ? displayDate : ""}
          </Typography>
        );
      },
    },
    {
      field: "added_by",
      headerName: "Last Added By",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
  ];

  useEffect(() => {
    if (!isEmpty(data) && data?.from === "notification") {
      const newDate = moment(data?.date);
      handleDateT(newDate);
      setDisable(false);
      handleDateF(newDate);
    }
  }, [data]);

  useEffect(() => {
    ScrapList(false);
  }, [pageNo, record]);

  useEffect(() => {
    setTimeout(() => {
      if (data?.from === "notification" && !isNull(dateT) && !isNull(dateF)) {
        ScrapList(false);
      }
    }, 500);
  }, [dateT, dateF]);

  useEffect(() => {
    isNull(dateT) && isEmpty(search) && ScrapList(false);
  }, [dateT, dateF, search]);

  function clearData() {
    setSlectedFile(null);
  }

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.uploadCSV;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          ScrapList(false);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };
  function resetFilterData() {
    handleSearch("");
    handleDateT(null);
    handleDateF(null);
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Scrap Analysis`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function ScrapList(bool, v) {
    v && setReportLoader(true);
    !v && setPageLoad(true);
    const fromDate = !isNull(dateT) ? moment(dateT).format("YYYY-MM-DD") : "";
    const toDate = !isNull(dateF) ? moment(dateF).format("YYYY-MM-DD") : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.scraplist
        }?page=${pageNo}&per_page=${record}&search=${
          bool ? "" : search
        }&from_date=${fromDate}&to_date=${toDate}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            downloadScrapData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setScrapListRows(listObj);
          }
        }
        v && auditSave("Scrap Analysis Report");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  function downloadScrapData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.capture_date;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.name || "-",
        "No. of Scrap Tyres Analysed": Number(row?.no_of_tyres),
        "Last Added On": row?.added_on ? displayDate : "-",
        "Last Added By": row?.added_by || "-",
      };

      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Scrap Analysis List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Scrap Analysis List.xlsx");
  }

  return (
    <div className={styles.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="tableTitle">Scrap Analysis</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{
            display: "flex",
            justifyContent: sm ? "unset" : "flex-end",
          }}
        >
          <Button
            variant="contained"
            onClick={() => {
              handleClick("addScrapTyre");
            }}
          >
            Add Scrap Tyre
          </Button>
          <Button
            variant="contained"
            component="label"
            style={{ marginLeft: 10, backgroundColor: color.secondary }}
          >
            <input
              type="file"
              onChange={(e) => {
                setSlectedFile(e.target.files[0]);
                setConfirmDialog(true);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              accept=".csv"
              hidden
            />
            Upload CSV
          </Button>
          <Button
            disabled={isOnline ? false : true}
            variant="contained"
            style={{
              marginLeft: 10,
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
            onClick={() => ScrapList(false, 1)}
          >
            {reportLoader ? (
              <CircularProgress style={{ color: color.white }} size={24} />
            ) : (
              "Download"
            )}
          </Button>
        </Grid>
      </Grid>
      <div style={{ paddingTop: 14 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap={"nowrap"}
        >
          <Grid
            item
            lg={10.4}
            style={{ display: "flex", alignItems: "center" }}
            wrap={"nowrap"}
          >
            <Grid
              item
              md={4}
              lg={2.5}
              style={{ display: "flex", alignItems: "center" }}
              wrap={"nowrap"}
            >
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={search}
                onChange={(v) => {
                  isEmpty(v.target.value) && ScrapList(true);
                  handleSearch(v?.target?.value);
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Fleet Name"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (
                      !isEmpty(search) ||
                      (!isNull(dateT) && !isNull(dateF))
                    ) {
                      handlePageNo(1);
                      ScrapList(false);
                    }
                  }
                }}
              />
            </Grid>
            <div
              style={{ marginLeft: 20, display: "flex", alignItems: "center" }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  views={["year", "month", "day"]}
                  value={dateT}
                  onChange={(newValue) => {
                    handleDateT(newValue);
                    setDisable(false);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                to
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disabled={isNull(dateT) ? true : disable}
                  minDate={dateT}
                  views={["year", "month", "day"]}
                  disableFuture
                  value={dateF}
                  inputFormat="dd-MM-yyyy"
                  onChange={(newValue) => {
                    handleDateF(newValue);
                  }}
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{
                        svg: {
                          color: disable || isNull(dateT) ? "" : color.primary,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </div>
            <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(search) || (!isNull(dateT) && !isNull(dateF))) {
                    handlePageNo(1);
                    ScrapList(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={isEmpty(search) && isNull(dateT) ? true : false}
              >
                Reset
              </Button>
            </div>
          </Grid>
          <Grid
            item
            lg={1.6}
            style={{ marginLeft: 10, display: "flex", alignItems: "center" }}
          >
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                handlePageNo(1);
                handleRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(scrapListRows?.data) && !isEmpty(scrapListRows?.data) ? (
          <>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                rows={scrapListRows?.data}
                columns={UnresolvedIssueColumns}
                disableColumnMenu
                hideFooter
                showCellRightBorder
                disableSelectionOnClick
                showColumnRightBorder
                autoHeight={true}
                onCellClick={(params) => {
                  if (params?.field !== "action") {
                    handleClick("scrapDetails", params?.row);
                  }
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(scrapListRows?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  handlePageNo(e);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
      <ConfirmDialog
        title={`Are you sure you want to Upload CSV?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            handleOnSubmit();
          } else {
            setConfirmDialog(false);
            clearData();
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </div>
  );
}
