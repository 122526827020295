import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting.js";
import Box from "@mui/material/Box";
import {
  Autocomplete,
  FormHelperText,
  InputAdornment,
  MenuItem,
  Select,
} from "@mui/material";
import useStyles from "./styles.js";
import FormControl from "@mui/material/FormControl";
import { isEmpty } from "lodash";
import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm/index";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const errorObj = {
  brandErr: false,
  brandMsg: "",
  compositionErr: false,
  compositionMsg: "",
  plyratingErr: false,
  plyratingMsg: "",
  sizeErr: false,
  sizeMsg: "",
  patternErr: false,
  patternMsg: "",
  nsdErr: false,
  nsdMsg: "",
};

export default function AddItemMaster(props) {
  const { handleClick = () => null, editData = {} } = props;
  const styles = useStyles();
  const isEdit = !isEmpty(editData);

  const { dropDownData } = useSelector((state) => state.auth);
  const [errObj, setErrObj] = useState(errorObj);

  const [loader, setLoader] = useState(false);
  const [tyreBrand, settyreBrand] = useState("");
  const [tyreComposition, settyreComposition] = useState("");
  const [plyRating, setplyRating] = useState("");
  const [treadPattern, settreadPattern] = useState("");
  const [tyreSize, settyreSize] = useState("");
  const [NSD, setNSD] = useState("");
  const { isOnline } = useSelector((state) => state.auth);

  useEffect(() => {
    const brand = dropDownData?.tyre_brand?.find(
      (v) => v?.id === editData?.tyre_brand_id
    );
    const composition = dropDownData?.tyre_composition?.find(
      (v) => v?.label === editData?.tyre_composition
    );
    const plyrating = dropDownData?.ply_rating?.find(
      (v) => v?.id === editData?.ply_rating_id
    );

    if (isEdit) {
      settyreBrand(brand);
      settyreComposition(composition);
      setplyRating(plyrating);
      settreadPattern(editData?.tread_pattern_id || "");
      settyreSize(editData?.tyre_pattern_size || "");
      setNSD(editData?.nsd || "");
    }
  }, []);

  const submitData = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.additemmaster;
    const params = {
      "ItemMaster[tyre_brand_id]": tyreBrand?.id,
      "ItemMaster[tyre_pattern_size]": tyreSize,
      "ItemMaster[tyre_composition_id]": tyreComposition?.id,
      "ItemMaster[ply_rating_id]": plyRating?.id,
      "ItemMaster[tread_pattern_id]": treadPattern,
      "ItemMaster[nsd]": NSD,
    };
    if (isEdit) {
      params["ItemMaster[id]"] = editData?.id;
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        handleClick("cancel");
        toast.success(resp?.message);
        clearData();
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
      toast.error(error.message.toString());
    }
  };

  function clearData() {
    settyreBrand("");
    settyreComposition("");
    setplyRating("");
    settreadPattern("");
    settyreSize("");
    setNSD("");
  }

  function validation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(tyreBrand.toString())) {
      valid = false;
      error.brandErr = true;
      error.brandMsg = "Please select Tyre Brand";
    }

    if (isEmpty(tyreComposition.toString())) {
      valid = false;
      error.compositionErr = true;
      error.compositionMsg = "Please select Tyre Composition";
    }

    if (isEmpty(plyRating.toString())) {
      valid = false;
      error.plyratingErr = true;
      error.plyratingMsg = "Please select Ply Rating";
    }

    if (isEmpty(tyreSize)) {
      valid = false;
      error.sizeErr = true;
      error.sizeMsg = "Please enter Item Description";
    }

    if (isEmpty(treadPattern.toString())) {
      valid = false;
      error.patternErr = true;
      error.patternMsg = "Please enter Tread Pattern";
    }

    if (isEmpty(NSD)) {
      valid = false;
      error.nsdErr = true;
      error.nsdMsg = "Please enter NSD";
    }

    setErrObj(error);
    if (valid) {
      submitData();
    }
  }

  return (
    <Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>
          {isEdit ? "Edit Item Master" : "Add Item Master"}
        </Typography>
      </Box>

      <Box className={styles.container}>
        <Grid
          container
          style={{ marginBottom: errObj.brandErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tyre Brand"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  border: errObj.plyratingErr ? "1px solid #ff1744" : null,
                  borderRadius: 1,
                }}
                error={errObj.brandErr}
              >
                <Autocomplete
                  fullWidth
                  disableListWrap={true}
                  options={dropDownData?.tyre_brand}
                  // filterSelectedOptions
                  value={tyreBrand}
                  onChange={(v1, v) => {
                    settyreBrand(v);
                    setErrObj({
                      ...errObj,
                      brandErr: false,
                      brandMsg: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.inputDivStyle}
                      placeholder={
                        isEmpty(tyreBrand)
                          ? "Search/Select the Tyre Brand"
                          : null
                      }
                    />
                  )}
                />
                {errObj.brandErr ? (
                  <FormHelperText>
                    {errObj.brandMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.sizeErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Item Description"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter the Item Description"
              value={tyreSize}
              error={errObj.sizeErr}
              helperText={errObj.sizeErr ? errObj.sizeMsg : null}
              onChange={(e) => {
                settyreSize(e.target.value.toUpperCase());
                setErrObj({
                  ...errObj,
                  sizeErr: false,
                  sizeMsg: "",
                });
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: errObj.compositionErr ? 30 : 20 }}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tyre Composition"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                fullWidth
                sx={{
                  width: "100%",
                  border: errObj.plyratingErr ? "1px solid #ff1744" : null,
                  borderRadius: 1,
                }}
                error={errObj.compositionErr}
              >
                <Autocomplete
                  fullWidth
                  disableListWrap={true}
                  options={dropDownData?.tyre_composition}
                  // filterSelectedOptions
                  value={tyreComposition}
                  onChange={(v1, v) => {
                    settyreComposition(v);
                    setErrObj({
                      ...errObj,
                      compositionErr: false,
                      compositionMsg: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.inputDivStyle}
                      placeholder={
                        isEmpty(tyreComposition)
                          ? "Search/Select the Tyre Composition"
                          : null
                      }
                    />
                  )}
                />
                {errObj.compositionErr ? (
                  <FormHelperText>
                    {errObj.compositionMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          style={{ marginBottom: errObj.plyratingErr ? 30 : 20 }}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Ply Rating"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl
                sx={{
                  width: "100%",
                  border: errObj.plyratingErr ? "1px solid #ff1744" : null,
                  borderRadius: 1,
                }}
                error={errObj.plyratingErr}
              >
                <Autocomplete
                  fullWidth
                  disableListWrap={true}
                  options={dropDownData?.ply_rating}
                  // filterSelectedOptions
                  value={plyRating}
                  onChange={(v1, v) => {
                    setplyRating(v);
                    setErrObj({
                      ...errObj,
                      plyratingErr: false,
                      plyratingMsg: "",
                    });
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      className={styles.inputDivStyle}
                      placeholder={
                        isEmpty(plyRating)
                          ? "Search/Select the Ply Rating"
                          : null
                      }
                    />
                  )}
                />
                {errObj.plyratingErr ? (
                  <FormHelperText>
                    {errObj.plyratingMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.patternErr ? 30 : 20 }}
          alignItems={"center"}
        >
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"Tread Pattern"} />
          </Grid>
          <Grid item xs={12} md={10} lg={10}>
            <FormControl fullWidth error={errObj.patternErr}>
              <Select
                displayEmpty
                value={treadPattern}
                onChange={(event) => {
                  settreadPattern(event.target.value);
                  setErrObj({
                    ...errObj,
                    patternErr: false,
                    patternMsg: "",
                  });
                }}
                size={"medium"}
                style={treadPattern === "" ? { color: "#A2A2A2" } : {}}
              >
                <MenuItem value={""} hidden disabled>
                  Select the Tread Pattern
                </MenuItem>
                {dropDownData.tread_pattern_data.map((item, index) => (
                  <MenuItem key={index} value={item?.id}>
                    {item?.label}
                  </MenuItem>
                ))}
              </Select>
              {errObj.patternErr ? (
                <FormHelperText>
                  {errObj.patternMsg}
                  {}
                </FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={12} sm={12} md={2} lg={2}>
            <CTypography required title={"NSD"} />
          </Grid>

          <Grid item xs={12} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              value={NSD}
              type={"number"}
              placeholder="Enter NSD"
              onChange={(v) => {
                const nsdValue = v?.target?.value;
                setNSD(nsdValue);
                setErrObj({
                  ...errObj,
                  nsdErr: false,
                  nsdMsg: "",
                });
              }}
              inputProps={{
                onWheel: (event) => event.currentTarget.blur(),
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="start">mm</InputAdornment>
                ),
              }}
              error={errObj.nsdErr}
              helperText={errObj.nsdErr ? errObj.nsdMsg : ""}
            />
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginBottom: 20 }}
        >
          <Button
            disabled={isOnline ? false : true}
            onClick={validation}
            style={{
              backgroundColor: isOnline ? color.primary : color.bordercolor,
              marginRight: 10,
              width: 140,
            }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : isEdit ? (
              `Save & Update`
            ) : (
              `Add Item Master`
            )}
          </Button>
          <Button
            style={{
              backgroundColor: color.secondary,
              width: 140,
            }}
            onClick={() => {
              handleClick("cancel");
              clearData();
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}
