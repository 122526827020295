import { makeStyles } from "@mui/styles";
import { isTablet } from "react-device-detect";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    padding: isTablet ? 20 : 10,
    display: "flex",
    boxSizing: "border-box",
    overflow: "hidden !important",
    flexDirection: "column",
  },
}));
export default useStyles;
