import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import "./styles.js";
import {
  Grid,
  Typography,
  useTheme,
  Button,
  Box,
  TableRow,
  TableCell,
  Table,
  MenuItem,
  Select,
  TextField,
  Pagination,
  CircularProgress,
  Modal,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { color } from "../../../Config/theme.js";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import moment from "moment";
import Images from "../../../Config/Images.js";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx/xlsx.mjs";

export default function ConditionDetails(props) {
  const { vehicleData, handleClick = () => null } = props;
  const styles = useStyles();
  const [record, setRecord] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [conditionListRows, setConditionListRows] = useState({});
  const [tyreDetails, setTyreDetails] = useState({});
  const [pageLoad, setPageLoad] = useState(true);

  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);
  const [openCard, setOpenCard] = useState(false);
  const [value, setValue] = useState("");
  const { token, isOnline, userdata } = useSelector((state) => state.auth);
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [reportLoader, setReportLoader] = useState(false);

  const conditionListColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 70,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   sortable: false,
    //   width: 70,
    //   renderCell: () => {
    //     return (
    //       <Delete
    //         style={{ margin: "auto", color: color.primary }}
    //         color={color.primary}
    //       />
    //     );
    //   },
    // },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 180,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              handleClick("tyreDetails", params?.row);
            }}
          >
            <Typography
              style={{
                whiteSpace: "pre-wrap",
                color: color.primary,
                fontWeight: "bold",
              }}
            >
              {params?.row?.tyre_serial_no}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "axle_type",
      headerName: "Axle Type",
      width: 140,
    },
    {
      field: "tyre_type",
      headerName: "Tyre Type",
      width: 140,
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 160,
    },
    {
      field: "item_description",
      headerName: "Item Description",
      width: 300,
      renderCell: (params) => {
        return params?.row?.item_description || "-";
      },
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 180,
    },
    {
      field: "ply_rating",
      headerName: "Ply Rating",
      width: 140,
    },

    {
      field: "old_condition",
      headerName: "Old Condition",
      width: 160,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.old_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.old_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.old_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.old_condition || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "new_condition",
      headerName: "New Condition",
      width: 160,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.new_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.new_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.new_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.new_condition || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "issue",
      headerName: "Tyre Issue",
      width: 180,
      renderCell: (params) => {
        let array = params?.row?.issue;
        return !isEmpty(array) ? (
          <div
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {isEmpty(array)
              ? "-"
              : array.map((items, index) => {
                  return (
                    <Typography>
                      {items}
                      {index !== array.length - 1 ? ", " : ""}
                    </Typography>
                  );
                })}
          </div>
        ) : (
          <Typography variant="tableHead">-</Typography>
        );
      },
    },
    {
      field: "mechanical_issue",
      headerName: "Mechanical Issue",
      width: 200,
      renderCell: (params) => {
        let array = params?.row?.mechanical_issue;
        return !isEmpty(array) ? (
          <div
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {isEmpty(array)
              ? "-"
              : array.map((items, index) => {
                  return (
                    <Typography>
                      {items}
                      {index !== array.length - 1 ? ", " : ""}
                    </Typography>
                  );
                })}
          </div>
        ) : (
          <Typography variant="tableHead">-</Typography>
        );
      },
    },
    {
      field: "removal_issue",
      headerName: "Removal Issue",
      width: 200,
      renderCell: (params) => {
        let array = params?.row?.removal_issue;
        return !isEmpty(array) ? (
          <div
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {isEmpty(array)
              ? "-"
              : array.map((items, index) => {
                  return (
                    <Typography>
                      {items}
                      {index !== array.length - 1 ? ", " : ""}
                    </Typography>
                  );
                })}
          </div>
        ) : (
          <Typography variant="tableHead">-</Typography>
        );
      },
    },
    {
      field: "days_from_last_condition",
      headerName: "Days from Last Change",
      width: 220,
    },
    {
      field: "Condition Time",
      headerName: "Date",
      width: 200,
      renderCell: (params) => {
        const timestamp = params?.row?.created_at;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },

    {
      field: "remark",
      headerName: "Remarks",
      width: 150,
      renderCell: (params) => {
        return params?.row?.remark || "-";
      },
    },
    {
      field: "issue_image",
      headerName: "Image",
      headerAlign: "center",
      align: "center",
      width: 140,
      sortable: false,
      renderCell: (params) => {
        const value = params?.row?.issue_image;
        if (!isEmpty(value)) {
          return (
            <img
              style={{
                cursor: "pointer",
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: !params?.row?.issue_image
                  ? color.secondary
                  : color.white,
              }}
              src={value || Images.profile}
              alt=""
              onClick={() => {
                setValue(value);
                setOpenCard(true);
              }}
            />
          );
        } else {
          return (
            <img
              style={{
                width: 30,
                height: 30,
                borderRadius: 15,
                backgroundColor: color.secondary,
              }}
              src={Images.profile}
              alt=""
            />
          );
        }
      },
    },
  ];

  useEffect(() => {
    conditionDetailsList(false);
  }, [pageNo, record]);

  useEffect(() => {
    getTyreDetails();
  }, []);

  useEffect(() => {
    isNull(datePicker) && conditionDetailsList(false);
  }, [datePicker, datePicker1]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Tyre History`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function conditionDetailsList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);

    const fromDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePicker1)
      ? moment(datePicker1).format("YYYY-MM-DD")
      : "";

    try {
      const response = await getApiData(
        `${Setting.endpoints.conditionList}?tyre_id=${vehicleData?.tyre_id}&per_page=${record}&page=${pageNo}&from_date=${fromDate}&to_date=${toDate}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data?.rows) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadCDetails(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setConditionListRows(listObj);
          }
        else {
          toast.error(response?.message || "No Data Found");
        }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Tyre Condition Details");
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setReportLoader(false);
    }
  }

  const getTyreDetails = async () => {
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyreDetails
        }?tyre_id=${vehicleData?.tyre_serial_no?.replaceAll(
          " ",
          "%20"
        )}&vehicle_id=${
          vehicleData?.is_deleted == 1 ? vehicleData?.vehicle_id : ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setTyreDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function downloadCDetails(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.created_at;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Axle Type": row?.axle_type || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.item_description || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating || "-",
        "Old Condition": row?.old_condition || "-",
        "New Condition": row?.new_condition || "-",
        "Tyre Issue": row?.issue.toString() || "-",
        "Mechanical Issue": row?.mechanical_issue.toString() || "-",
        "Removal Issue": row?.removal_issue.toString() || "-",
        "Days from Last Change": row?.days_from_last_condition || "-",
        Date: displayDate || "-",
        Remarks: row?.remark || "-",
        Image: row?.issue_image || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Condition Details");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Condition Details.xlsx");
  }

  function resetFilterData() {
    setDatePicker(null);
    setDatePicker1(null);
  }

  return (
    <>
      <div className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={"bold"}>Condition Details</Typography>
          <Button
            variant="contained"
            style={{
              marginLeft: 10,
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
            disabled={isOnline ? false : true}
            onClick={() => conditionDetailsList(true, 1)}
          >
            {reportLoader ? (
              <CircularProgress style={{ color: color.white }} size={24} />
            ) : (
              "Download Condition Details"
            )}
          </Button>
        </Box>
        <div style={{ padding: 20 }}>
          <Table>
            <TableRow>
              <TableCell variant="head">Fleet Name</TableCell>
              <TableCell>{tyreDetails?.fleet_name || "-"}</TableCell>
              <TableCell variant="head">Current Axle Type</TableCell>
              <TableCell
              // style={{
              //   fontWeight: "bold",
              //   color: "blue",
              // }}
              >
                {tyreDetails?.axle_type || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle No.</TableCell>
              <TableCell>{tyreDetails?.vehicle_no || "-"}</TableCell>
              <TableCell variant="head">No. of Condition Changes</TableCell>
              <TableCell
              // style={{
              //   fontWeight: "bold",
              //   color: "blue",
              // }}
              >
                {tyreDetails?.condition_changes || "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Type</TableCell>
              <TableCell>{tyreDetails?.vehicle_type || "-"}</TableCell>
              <TableCell variant="head">Current Condition</TableCell>
              <TableCell>
                <span
                  style={{
                    padding: 8,
                    borderRadius: 4,
                    backgroundColor:
                      tyreDetails?.tyre_condition === "Average"
                        ? "#FFFDD9"
                        : tyreDetails?.tyre_condition === "Good"
                        ? "#D9FFE4"
                        : tyreDetails?.tyre_condition === "Bad"
                        ? "#FFD9D9"
                        : "",
                  }}
                >
                  {tyreDetails?.tyre_condition || "-"}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Brand</TableCell>
              <TableCell>{tyreDetails?.vehicle_brand || "-"}</TableCell>

              <TableCell variant="head">Current NSD</TableCell>
              <TableCell>{`${tyreDetails?.NSD} mm` || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Model</TableCell>
              <TableCell>{tyreDetails?.vehicle_model || "-"}</TableCell>
              <TableCell variant="head">Current Tyre Pressure</TableCell>
              <TableCell>
                {(tyreDetails?.tyre_air_pressure &&
                  `${tyreDetails?.tyre_air_pressure} psi`) ||
                  "-"}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Current Position </TableCell>
              <TableCell
              // style={{
              //   fontWeight: "bold",
              //   color: "blue",
              // }}
              >
                {tyreDetails?.tyre_position || "-"}
              </TableCell>
            </TableRow>
            <TableRow></TableRow>
            <TableRow></TableRow>
          </Table>
        </div>
      </div>

      <div
        className={styles.container}
        style={{ marginTop: 20, marginBottom: 20, padding: 20 }}
      >
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item xs={12} sm={6}>
            <Typography variant="tableTitle">Condition Details</Typography>
          </Grid>
        </Grid>
        <div style={{ marginTop: 14 }}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid
              item
              lg={10.4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                lg={4}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                      setDisable(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(datePicker) ? true : disable}
                    minDate={datePicker}
                    views={["year", "month", "day"]}
                    disableFuture
                    value={datePicker1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      setDatePicker1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              disable || isNull(datePicker)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item lg={4} wrap="nowrap" style={{ display: "flex" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                  onClick={() => {
                    if (!isNull(datePicker) && !isNull(datePicker1)) {
                      setPageNo(1);
                      conditionDetailsList(false);
                    }
                  }}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  onClick={resetFilterData}
                  disabled={isNull(datePicker) ? true : false}
                >
                  Reset
                </Button>
              </Grid>
            </Grid>
            <Grid
              item
              lg={1.6}
              style={{ display: "flex", alignItems: "center", marginLeft: 10 }}
            >
              <Typography style={{ fontSize: 14 }}>Records:</Typography>
              <Select
                fullWidth
                value={record}
                onChange={(v) => {
                  setPageNo(1);
                  setRecord(v.target.value);
                }}
                style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
        </div>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(conditionListRows?.data) &&
          !isEmpty(conditionListRows?.data) ? (
          <>
            <div style={{ marginTop: 20 }}>
              <DataGrid
                rows={conditionListRows?.data}
                columns={conditionListColumns}
                disableColumnMenu
                pageSize={record}
                hideFooter
                showCellRightBorder
                disableSelectionOnClick
                showColumnRightBorder
                autoHeight={true}
                getRowHeight={() => "auto"}
              />
            </div>
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(conditionListRows?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  setPageNo(e);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
      <Modal
        className={styles.modal}
        open={openCard}
        onClose={() => {
          setOpenCard(false);
        }}
      >
        <img
          style={{
            backgroundColor: color.white,
            maxWidth: 550,
            maxHeight: 550,
            borderRadius: 4,
          }}
          src={value || "-"}
          alt={"user id"}
        />
      </Modal>
    </>
  );
}
