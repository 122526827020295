import React, { useEffect, useState } from "react";
import { color } from "./../../../Config/theme";
import { Setting } from "../../../Utils/Setting";
import OngoingTickets from "../../../Components/ServiceCenter/OngoingTickets/index.js";
import ResolvedTickets from "../../../Components/ServiceCenter/ResolvedTickets/index.js";
import Reports from "../../../Components/ServiceCenter/Reports/index.js";
import TicketID from "../../../Components/ServiceCenter/TicketID/index.js";
import VarifyUser from "../../../Components/ServiceCenter/VarifyUser";
import OutstandingPayments from "../../../Components/ServiceCenter/OutstandingPayments";
import {
  Grid,
  Breadcrumbs,
  Box,
  Link,
  Tab,
  Tabs,
  Typography,
  Button,
} from "@mui/material";
import "./styles.js";
import useStyles from "./styles.js";
import ConfirmDialog from "../../../Components/ConfirmDialog";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { toast } from "react-toastify";
import GenerateTicket from "../../../Components/ServiceCenter/GenerateTicket";
import Images from "../../../Config/Images";
import { useSelector } from "react-redux";

export default function ServiceCentre() {
  const styles = useStyles();
  const [changeTab, setChangeTab] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [selectedFile, setSlectedFile] = useState(null);
  const [ticketData, setTicketdata] = useState({});
  const [isEditData, setIsEditData] = useState(false);
  const [editData, setEditData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const { userdata, isOnline } = useSelector((state) => state.auth);
  const [from, setFrom] = useState("");

  const [pageNoOT, setPageNoOT] = useState(1);
  const [recordOT, setRecordOT] = useState(10);
  const [filterOT, setFilterOT] = useState("");

  const [pageNoRT, setPageNoRT] = useState(1);
  const [recordRT, setRecordRT] = useState(10);
  const [filterRT, setFilterRT] = useState("");

  const [pageNoOP, setPageNoOP] = useState(1);
  const [recordOP, setRecordOP] = useState(10);

  useEffect(() => {
    document.title = Setting.page_name.ServiceCentre;
  }, []);

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.importservicecenter;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };

  function clearData() {
    setSlectedFile(null);
  }

  return (
    <div className={styles.card}>
      <Grid container>
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator=">">
            <Link
              underline="hover"
              color="inherit"
              href={userdata?.role === "call_centre" ? "#" : "/"}
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            {changeTab === "OngoingTickets" ||
            changeTab === "ticketId" ||
            changeTab === "OutstandingPayments" ||
            changeTab === "generateTicket" ? (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setEditData({});
                  setChangeTab("");
                }}
              >
                Service Centre
              </Link>
            ) : (
              <Typography className={styles.linkStyle}>
                Service Centre
              </Typography>
            )}
            {changeTab === "generateTicket" && !isEditData ? (
              <Typography className={styles.linkStyle}>
                Generate Ticket
              </Typography>
            ) : null}
            {changeTab === "ticketId" ||
            (changeTab === "generateTicket" && isEditData) ? (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab(
                    from === "outstanding"
                      ? "OutstandingPayments"
                      : "ResolveTicket"
                  );
                }}
              >
                {editData?.vehicle_no}
              </Link>
            ) : null}
            {changeTab === "ticketId" ||
            (changeTab === "generateTicket" && isEditData) ? (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
                onClick={() => {
                  setChangeTab(
                    from === "outstanding"
                      ? "OutstandingPayments"
                      : "ResolveTicket"
                  );
                }}
              >
                {from === "outstanding"
                  ? "Outstanding Payments"
                  : "Resolved Ticket"}
              </Link>
            ) : null}
            {changeTab === "ticketId" ||
            (changeTab === "generateTicket" && isEditData) ? (
              <Typography className={styles.linkStyle}>
                Ticket ID - {editData?.id}
              </Typography>
            ) : null}
            {changeTab === "OutstandingPayments" ? (
              <Typography className={styles.linkStyle}>
                Outstanding Payments
              </Typography>
            ) : null}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "generateTicket" ? (
        <GenerateTicket
          handleClick={(v, data) => {
            if (v === "cancel") {
              setChangeTab("");
              setTicketdata({});
              setEditData({});
            }
          }}
          ticketData={ticketData}
          editData={editData}
        />
      ) : changeTab === "ticketId" ? (
        <TicketID handleClick={(v, data) => null} editData={editData} />
      ) : changeTab === "OutstandingPayments" ? (
        <div className={styles.container}>
          <OutstandingPayments
            handlePageNo={(page) => {
              setPageNoOP(page);
            }}
            handleRecord={(rec) => {
              setRecordOP(rec);
            }}
            pageNo={pageNoOP}
            record={recordOP}
            handleClick={(v, data, from) => {
              if (v === "ticketId") {
                setChangeTab("ticketId");
                setEditData(data);
                setFrom(from);
              }
            }}
          />
        </div>
      ) : (
        <div className={styles.container}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography variant="tableTitle">Service Centre</Typography>
            {userdata?.role !== "service_enginner" && (
              <div>
                <Button
                  variant="contained"
                  component="label"
                  style={{ backgroundColor: color.secondary }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      setSlectedFile(e.target.files[0]);
                      setConfirmDialog(true);
                    }}
                    onClick={(event) => {
                      event.target.value = null;
                    }}
                    accept=".csv"
                    hidden
                  />
                  Upload Center List
                </Button>
                <Button
                  variant="contained"
                  component="label"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disabled={isOnline ? false : true}
                >
                  <a
                    style={{ textDecoration: "none", color: "white" }}
                    href={Images.serviceCentercsv}
                    download={"service_center.csv"}
                  >
                    Download Template
                  </a>
                </Button>
              </div>
            )}
          </div>
          <Box sx={{ borderBottom: 1, borderColor: color.bordercolor }}>
            <Tabs
              value={tabValue}
              onChange={(v, b) => {
                setPageNoOT(1);
                setRecordOT(10);
                setPageNoRT(1);
                setRecordRT(10);
                setFrom("");
                setTabValue(b);
                setFilterOT("");
                setFilterRT("");
              }}
              variant="scrollable"
            >
              <Tab className={styles.tabBtn} label="Verify User" />/
              <Tab className={styles.tabBtn} label="Ongoing Tickets" />
              <Tab className={styles.tabBtn} label="Resolved Tickets" />
              <Tab className={styles.tabBtn} label="Reports" />
            </Tabs>
          </Box>
          {tabValue === 0 && (
            <VarifyUser
              handleClick={(v, data, from) => {
                if (v === "OutstandingPayments") {
                  setPageNoOP(1);
                  setRecordOP(10);
                  setChangeTab("OutstandingPayments");
                } else if (v === 1) {
                  setTabValue(1);
                } else if (v === 2) {
                  setPageNoRT(1);
                  setRecordRT(10);
                  setTabValue(2);
                } else if (v === "generateTicket") {
                  setChangeTab("generateTicket");
                  setIsEditData(false);
                  setTicketdata(data);
                } else if (v === "needfollowup") {
                  setPageNoOT(1);
                  setRecordOT(10);
                  setTabValue(1);
                  setFrom(from);
                }
              }}
            />
          )}
          {(tabValue === 1 || tabValue === 2) && (
            <OngoingTickets
              pageNo={tabValue === 1 ? pageNoOT : pageNoRT}
              record={tabValue === 1 ? recordOT : recordRT}
              filter={tabValue === 1 ? filterOT : filterRT}
              handlePageNo={(page) => {
                tabValue === 1 ? setPageNoOT(page) : setPageNoRT(page);
              }}
              handleRecord={(rec) => {
                tabValue === 1 ? setRecordOT(rec) : setRecordRT(rec);
              }}
              handleFilter={(flt) => {
                tabValue === 1 ? setFilterOT(flt) : setFilterRT(flt);
              }}
              handleClick={(v, data, editData) => {
                if (v === "generateTicket") {
                  setChangeTab("generateTicket");
                  setEditData(data);
                  setIsEditData(editData);
                } else if (v === "ticketId") {
                  setChangeTab("ticketId");
                  setEditData(data);
                  setIsEditData(editData);
                }
              }}
              onGoing={tabValue === 1 ? true : false}
              from={from}
            />
          )}
          {tabValue === 3 && <Reports />}
        </div>
      )}
      <ConfirmDialog
        title={`Are you sure you want to Upload Center List?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            handleOnSubmit();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </div>
  );
}
