import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import "./styles.js";
import {
  Grid,
  Typography,
  useTheme,
  Button,
  Box,
  TableRow,
  TableCell,
  Table,
  MenuItem,
  Select,
  Pagination,
  CircularProgress,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { color } from "../../../Config/theme.js";
import { isArray, isEmpty, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { Setting } from "../../../Utils/Setting.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as XLSX from "xlsx/xlsx.mjs";

export default function TyreDetails(props) {
  const { tyreData, from } = props;
  const styles = useStyles();
  const [record, setRecord] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [pageLoad, setPageLoad] = useState(true);
  const [tyreListRows, setTyreListRows] = useState({});

  const [record1, setRecord1] = useState(10);
  const [pageNo1, setPageNo1] = useState(1);
  const [pageLoad1, setPageLoad1] = useState(true);
  const [inventoryTyreListRows, setInventoryTyreListRows] = useState({});
  const [tyreDetails, setTyreDetails] = useState({});

  // const [datePicker, setDatePicker] = useState(null);
  // const [datePicker1, setDatePicker1] = useState(null);
  const [reportLoader, setReportLoader] = useState(false);
  const [reportLoader1, setReportLoader1] = useState(false);

  const { token, isOnline, userdata } = useSelector((state) => state.auth);
  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const tyreListColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    // {
    //   field: "vehicle_no",
    //   headerName: "Vehicle No.",
    //   width: lg ? 220 : "unset",
    //   flex: !lg ? 1 : "unset",
    // },
    // {
    //   field: "tyre_serial_no",
    //   headerName: "Tyre Serial No.",
    //   width: 180,
    // },
    // {
    //   field: "old_position",
    //   headerName: "Old Position",
    //   width: lg ? 220 : "unset",
    //   flex: !lg ? 1 : "unset",
    // },
    // {
    //   field: "new_position",
    //   headerName: "New Position",
    //   width: lg ? 220 : "unset",
    //   flex: !lg ? 1 : "unset",
    // },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "mileage",
      headerName: "Mileage",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        return <Typography>{params?.row?.mileage || "0"} km</Typography>;
      },
    },
    // {
    //   field: "issue",
    //   headerName: "Tyre Issue",
    //   width: 140,
    //   renderCell: (params) => {
    //     let array = params?.row?.issues;
    //     return !isEmpty(array) ? (
    //       <div
    //         style={{
    //           overflow: "hidden",
    //           wordWrap: "break-word",
    //           whiteSpace: "break-spaces",
    //         }}
    //       >
    //         {array.map((items, index) => {
    //           return (
    //             <Typography>
    //               {items}
    //               {index !== array.length - 1 ? ", " : ""}
    //             </Typography>
    //           );
    //         })}
    //       </div>
    //     ) : (
    //       <Typography variant="tableHead">-</Typography>
    //     );
    //   },
    // },
    // {
    //   field: "mechanical_issue",
    //   headerName: "Mechanical Issue",
    //   width: 200,
    //   renderCell: (params) => {
    //     let array = params?.row?.mechanical_issues;
    //     return !isEmpty(array) ? (
    //       <div
    //         style={{
    //           overflow: "hidden",
    //           wordWrap: "break-word",
    //           whiteSpace: "break-spaces",
    //         }}
    //       >
    //         {array.map((items, index) => {
    //           return (
    //             <Typography>
    //               {items}
    //               {index !== array.length - 1 ? ", " : ""}
    //             </Typography>
    //           );
    //         })}
    //       </div>
    //     ) : (
    //       <Typography variant="tableHead">-</Typography>
    //     );
    //   },
    // },
    // {
    //   field: "created_at",
    //   headerName: "Rotation Date",
    //   width: 250,
    //   renderCell: (params) => {
    //     const timestamp = params?.row?.created_at;
    //     const date = new Date(timestamp * 1000);
    //     const displayDate = moment(date).format("DD-MM-yyyy");
    //     return (
    //       <div
    //         style={{
    //           flex: 1,
    //           display: "flex",
    //         }}
    //       >
    //         {params?.row?.created_at ? displayDate : ""}
    //       </div>
    //     );
    //   },
    // },
  ];

  const inventoryTyreListColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      sortable: false,
      headerAlign: "center",
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "old_category",
      headerName: "Old Category",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "new_category",
      headerName: "New Category",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "created_at",
      headerName: "Changed Date",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
  ];

  useEffect(() => {
    gettyreList(false);
  }, [pageNo, record]);

  useEffect(() => {
    getInventorytyreList(false);
  }, [pageNo1, record1]);

  useEffect(() => {
    getTyreDetails();
  }, []);

  const auditSave = async (reportName, v) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From ${
      v ? "Tyre Inventory" : "Fleet Management"
    }`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function gettyreList(v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);

    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyremileagehistory
        }?per_page=${record}&page=${pageNo}&tyre_serial_no=${
          tyreData?.tyre_serial_no
        }&is_download=${v ? 1 : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadTDetails(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setTyreListRows(listObj);
          }
        }
        v && auditSave("Tyre Mileage History");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      setReportLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getInventorytyreList(v) {
    !v && setPageLoad1(true);
    v && setReportLoader1(true);
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyrecategoryhistory
        }?per_page=${record1}&page=${pageNo1}&tyre_serial_no=${
          tyreData?.tyre_serial_no
        }&is_download=${v ? 1 : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadITDetails(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setInventoryTyreListRows(listObj);
          }
        }
        v && auditSave("Tyre History", 1);
      } else {
        toast.error(response?.message);
      }
      setPageLoad1(false);
      setReportLoader1(false);
    } catch (error) {
      setPageLoad1(false);
      setReportLoader1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const getTyreDetails = async () => {
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.tyreDetails
        }?tyre_id=${tyreData?.tyre_serial_no?.replaceAll(
          " ",
          "%20"
        )}&vehicle_id=${
          from === "life" || from === "tyreInventory"
            ? tyreData?.vehicle_id || ""
            : ""
        }`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setTyreDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function downloadTDetails(data) {
    const newData = data.map((row, index) => {
      // const timestamp = row?.created_at;
      // const date = new Date(timestamp * 1000);
      // const displayDate = moment(date).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        // "Old Position": row?.old_position || "-",
        // "New Position": row?.new_position || "-",
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Vehicle No.": row?.vehicle_no,
        "Mileage (Km)": Number(row?.mileage?.toString()?.replaceAll(",", "")),
        // "Tyre Issues": row?.issues.toString() || "-",
        // "Mechanical Issues": row?.mechanical_issues.toString() || "-",
        // "Rotation Date": displayDate || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre Mileage History");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre Mileage History.xlsx");
  }

  function downloadITDetails(data) {
    const newData = data.map((row, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        "Old Category": row?.old_category,
        "New Category": row?.new_category,
        "Changed Date": row?.created_at,
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre History");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre History.xlsx");
  }

  return (
    <>
      <div className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={"bold"}>Tyre Details</Typography>
          <Button
            disabled={isOnline ? false : true || reportLoader}
            variant="contained"
            style={{
              marginLeft: 10,
              backgroundColor: isOnline ? color.secondary : color.bordercolor,
            }}
            onClick={() => gettyreList(true)}
          >
            {reportLoader ? (
              <CircularProgress style={{ color: color.white }} size={24} />
            ) : (
              "Download Mileage History"
            )}
          </Button>
        </Box>
        <div style={{ padding: 20 }}>
          <Table>
            <TableRow>
              <TableCell variant="head">Fleet Name</TableCell>
              <TableCell>{tyreDetails?.fleet_name || "-"}</TableCell>
              <TableCell variant="head">Current Condition</TableCell>
              <TableCell>
                <span
                  style={{
                    padding: 8,
                    borderRadius: 4,
                    backgroundColor:
                      tyreDetails?.tyre_condition === "Average"
                        ? "#FFFDD9"
                        : tyreDetails?.tyre_condition === "Good"
                        ? "#D9FFE4"
                        : tyreDetails?.tyre_condition === "Bad"
                        ? "#FFD9D9"
                        : "",
                  }}
                >
                  {tyreDetails?.tyre_condition || "-"}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle No.</TableCell>
              <TableCell>{tyreDetails?.vehicle_no || "-"}</TableCell>
              <TableCell variant="head">Tyre Type</TableCell>
              <TableCell>{tyreDetails?.tyre_type || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Type</TableCell>
              <TableCell>{tyreDetails?.vehicle_type || "-"}</TableCell>
              <TableCell variant="head">Tyre Brand</TableCell>
              <TableCell>{tyreDetails?.tyre_brand || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Brand</TableCell>
              <TableCell>{tyreDetails?.vehicle_brand || "-"}</TableCell>
              <TableCell variant="head">Item Description</TableCell>
              <TableCell>
                {tyreDetails?.is_new_tyre === 1
                  ? tyreDetails?.new_tyre_name
                  : tyreDetails?.tyre_pattern_size}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Vehicle Model</TableCell>
              <TableCell>{tyreDetails?.vehicle_model || "-"}</TableCell>
              <TableCell variant="head">Tyre Composition</TableCell>
              <TableCell>{tyreDetails?.tyre_composition || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">No. of Changes</TableCell>
              <TableCell>{tyreDetails?.condition_changes || "-"}</TableCell>
              <TableCell variant="head">Ply Rating</TableCell>
              <TableCell>{tyreDetails?.ply_rating || "-"}</TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Current NSD</TableCell>
              <TableCell>
                <span
                  style={{
                    color: color.white,
                    padding: 8,
                    borderRadius: 4,
                    backgroundColor: color.primary,
                  }}
                >
                  {`${tyreDetails?.NSD} mm` || "-"}
                </span>
              </TableCell>
              <TableCell variant="head">Current Position </TableCell>
              <TableCell>
                <span
                  style={{
                    color: color.white,
                    padding: 8,
                    borderRadius: 4,
                    backgroundColor: color.primary,
                  }}
                >
                  {tyreDetails?.tyre_position || "-"}
                </span>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell variant="head">Current Tyre Pressure</TableCell>
              <TableCell>
                <span
                  style={{
                    color: color.white,
                    padding: 8,
                    borderRadius: 4,
                    backgroundColor: color.primary,
                  }}
                >
                  {(tyreDetails?.tyre_air_pressure &&
                    `${tyreDetails?.tyre_air_pressure} psi`) ||
                    "-"}
                </span>
              </TableCell>
              <TableCell variant="head">Current Axle Type</TableCell>
              <TableCell>
                <span
                  style={{
                    color: color.white,
                    padding: 8,
                    borderRadius: 4,
                    backgroundColor: color.primary,
                  }}
                >
                  {tyreDetails?.axle_type || "-"}
                </span>
              </TableCell>
            </TableRow>
          </Table>
        </div>
      </div>

      <div
        className={styles.container}
        style={{ marginTop: 20, marginBottom: 20, padding: 20 }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid
            item
            lg={10}
            md={10}
            sm={10}
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="tableTitle">Tyre Mileage History</Typography>
          </Grid>
          <Grid item lg={1.6} style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                setPageNo(1);
                setRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        <Grid container flexDirection="row" style={{ marginTop: 10 }}>
          <div
            style={{
              backgroundColor: "#D9F8FF",
              padding: 6,
              borderRadius: 4,
              marginRight: 10,
            }}
          >
            <Typography>
              {`Total: ${tyreListRows?.pagination?.total_mileage || "-"}`} Km
            </Typography>
          </div>
          <div
            style={{
              backgroundColor: "rgb(217, 255, 228)",
              padding: 6,
              borderRadius: 4,
            }}
          >
            <Typography>
              {`Previous Reading: ${
                tyreListRows?.pagination?.previous_reading_km || "-"
              }`}
              Km
            </Typography>
          </div>
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(tyreListRows?.data) && !isEmpty(tyreListRows?.data) ? (
          <>
            <Box style={{ marginTop: 20 }}>
              <DataGrid
                rows={tyreListRows?.data}
                columns={tyreListColumns}
                disableColumnMenu
                getRowClassName={(params) =>
                  `super-app-theme--${params.row.is_previous_mileage_added}`
                }
                pageSize={record1}
                hideFooter
                showCellRightBorder
                disableSelectionOnClick
                showColumnRightBorder
                autoHeight={true}
                getRowHeight={() => "auto"}
              />
            </Box>
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(tyreListRows?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  setPageNo(e);
                }}
              />
            </div>
          </>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
      {from === "tyreInventory" && (
        <div
          className={styles.container}
          style={{ marginBottom: 20, padding: 20 }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid
              item
              lg={10}
              md={10}
              sm={10}
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="tableTitle">Tyre History</Typography>
            </Grid>
            <>
              <Grid
                item
                lg={1.6}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography style={{ fontSize: 14 }}>Records:</Typography>
                <Select
                  fullWidth
                  value={record1}
                  onChange={(v) => {
                    setPageNo1(1);
                    setRecord1(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
              <Button
                variant="contained"
                onClick={() => {
                  getInventorytyreList(true);
                }}
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                  marginLeft: 10,
                }}
                disabled={isOnline ? false : true || reportLoader1}
              >
                {reportLoader1 ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  "Download"
                )}
              </Button>
            </>
          </Grid>
          {pageLoad1 ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(inventoryTyreListRows?.data) &&
            !isEmpty(inventoryTyreListRows?.data) ? (
            <>
              <Box style={{ marginTop: 20 }}>
                <DataGrid
                  rows={inventoryTyreListRows?.data}
                  columns={inventoryTyreListColumns}
                  disableColumnMenu
                  getRowClassName={(params) =>
                    `super-app-theme--${params.row.is_previous_mileage_added}`
                  }
                  pageSize={record1}
                  hideFooter
                  showCellRightBorder
                  disableSelectionOnClick
                  showColumnRightBorder
                  autoHeight={true}
                  getRowHeight={() => "auto"}
                />
              </Box>
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(
                    inventoryTyreListRows?.pagination?.totalPage
                  )}
                  defaultPage={pageNo1}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    setPageNo1(e);
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}
