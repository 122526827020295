import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    display: "flex",
    boxSizing: "border-box",
    flexDirection: "column",
  },
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    border: `1px solid ${color.bordercolor}`,
    overflow: "auto",
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
    width: "100%",
  },
  txt: {
    whiteSpace: "pre-wrap !important",
  },
}));
export default useStyles;
