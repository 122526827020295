import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
    position: "relative",
  },
}));
export default useStyles;
