import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import TabFleetDetails from "../../../Components/TabFleet/TabFleetDetails/index.js";
import TabVehicleDetails from "../../../Components/TabFleet/TabVehicleDetails/Index.js";
import TabFleetView from "../../../Components/TabFleet/TabFleetView/index.js";
import AddFleetForm from "../../../Components/Fleet/AddFleetForm/index.js";
import AddVehicle from "../../../Components/Fleet/AddVehicle/index.js";
import { isEmpty, isNull } from "lodash";
import { Button, Box, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import Images from "../../../Config/Images.js";
import { ArrowBackOutlined } from "@mui/icons-material";
import { isMobile, isTablet } from "react-device-detect";
import useDetectKeyboardOpen from "use-detect-keyboard-open";
import TabUnresolvedIssue from "../../../Components/TabFleet/TabUnresolvedIssue/index.js";
import TabIssueList from "../../../Components/TabFleet/TabIssueList/index.js";
import TabResolveIssue from "../../../Components/TabFleet/TabResolveIssue/index.js";
import { color } from "../../../Config/theme.js";

export default function TabFleetManagement() {
  const styles = useStyles();
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [changeTab, setChangeTab] = useState("");
  const [fleetData, setFleetData] = useState({});
  const [fromVehicle, setFromVehicle] = useState({});
  const [issueData, setIssueData] = useState({});
  const [resolveData, setResolveData] = useState({});
  const [issueType, setIssueType] = useState("");
  const [issue, setIssue] = useState("");

  const [tabValue, setTabValue] = useState(0);

  const [pageNoUI, setPageNoUI] = useState(1);
  const [recordUI, setRecordUI] = useState(10);
  const [filterUI, setFilterUI] = useState("");

  const [pageNoIL, setPageNoIL] = useState(1);
  const [recordIL, setRecordIL] = useState(10);

  const [pageNoFL, setPageNoFL] = useState(1);
  const [recordFL, setRecordFL] = useState(10);
  const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);

  const [pageNoV, setPageNoV] = useState(1);
  const [recordV, setRecordV] = useState(10);
  const [filterV, setFilterV] = useState("");
  const [searchFilterV, setSearchFilterV] = useState("");
  const [datePickerV, setDatePickerV] = useState(null);
  const [datePickerV1, setDatePickerV1] = useState(null);

  const [pageNoFD, setPageNoFD] = useState(1);
  const [recordFD, setRecordFD] = useState(10);

  const location = useLocation();

  const fromInspection =
    !isNull(location?.state) && location?.state?.set === "fleetDetails";
  const fromDeshboard =
    !isNull(location?.state) && location?.state?.set === "dashboardDetails";
  const dashboard = location?.state?.set === "vehicleList";

  useEffect(() => {
    if (fromInspection) {
      setChangeTab("fleetDetails");
      setFleetData(location?.state);
    }
    if (fromDeshboard) {
      setChangeTab("vehicleDetails");
      setFleetData(location?.state);
    }
    if (dashboard) {
      setChangeTab("");
    }
  }, []);

  useEffect(() => {
    setFromVehicle({});
    document.title = Setting.page_name.FleetManagement;
  }, []);

  useEffect(() => {
    if (changeTab === "editVehicle") {
      const section = document.querySelector("#jump_to_me");
      section.scrollIntoView({ behavior: "auto", block: "end" });
    }
  }, [changeTab]);
  return (
    <div className={styles.card}>
      {isTablet
        ? ""
        : !isKeyboardOpen &&
          changeTab === "" && (
            <Box
              style={{
                display: "flex",
                flex: 1,
                flexDirection: "row",
                alignItems: "center",
                height: isMobile ? 30 : 40,
                position: "fixed",
                top: 14,
                left: 20,
                zIndex: 10,
              }}
            >
              <img src={Images.frame} alt="frame" style={{ height: "160%" }} />
            </Box>
          )}
      {changeTab === "addFleet" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "editVehicle" && (
        <Box id="jump_to_me">
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "UnresolvedIssue" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "UnresolvedIssueList" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("UnresolvedIssue");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "ResolveIssue" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => setChangeTab("UnresolvedIssueList")}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}

      {changeTab === "fleetDetails" ? (
        <TabFleetDetails
          pageNo={pageNoFD}
          record={recordFD}
          handlePageNo={(page) => {
            setPageNoFD(page);
          }}
          handleRecord={(rec) => {
            setRecordFD(rec);
          }}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("");
            } else if (type === "vehicleDetails") {
              setChangeTab("vehicleDetails");
              setFleetData(data);
            } else if (type === "editVehicle") {
              setChangeTab("editVehicle");
              setFleetData(data);
            } else if (type === "vehicle") {
              setChangeTab("vehicle");
            }
          }}
          from={fromInspection ? "inspection" : ""}
          fleetData={!isEmpty(fromVehicle) ? fromVehicle : fleetData}
        />
      ) : changeTab === "vehicleDetails" ? (
        <TabVehicleDetails
          handleClick={(data) => {
            setFromVehicle(data);
            setTimeout(() => {
              setChangeTab("");
            }, 200);
          }}
          vehicleDetails={fleetData}
          fromDeshboard={fromDeshboard}
        />
      ) : changeTab === "addFleet" ? (
        <AddFleetForm
          handleClick={(v) => {
            if (v === "cancel") {
              setChangeTab("");
            }
          }}
          fleetData={fleetData}
        />
      ) : changeTab === "vehicle" ? (
        <AddVehicle
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
              setFleetData({});
            }
          }}
        />
      ) : changeTab === "editVehicle" ? (
        <AddVehicle
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
              setFleetData({});
            }
          }}
          editData={fleetData}
        />
      ) : changeTab === "UnresolvedIssue" ? (
        <TabIssueList
          fleetData={fleetData}
          pageNo={pageNoIL}
          record={recordIL}
          handlePageNo={(page) => {
            setPageNoIL(page);
          }}
          handleRecord={(rec) => {
            setRecordIL(rec);
          }}
          handleClick={(type, data) => {
            if (type === "UnresolvedIssueList") {
              setPageNoUI(1);
              setRecordUI(10);
              setFilterUI("");
              setChangeTab("UnresolvedIssueList");
              setIssueData(data);
            }
          }}
        />
      ) : changeTab === "UnresolvedIssueList" ? (
        <TabUnresolvedIssue
          vehicleData={issueData}
          pageNo={pageNoUI}
          record={recordUI}
          filter={filterUI}
          handlePageNo={(page) => {
            setPageNoUI(page);
          }}
          handleRecord={(rec) => {
            setRecordUI(rec);
          }}
          handleFilter={(flt) => {
            setFilterUI(flt);
          }}
          handleClick={(type, data, issType, iss) => {
            if (type === "ResolveIssue") {
              setChangeTab("ResolveIssue");
              setResolveData(data);
              setIssueType(issType);
              setIssue(iss);
            }
          }}
        />
      ) : changeTab === "ResolveIssue" ? (
        <TabResolveIssue
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("UnresolvedIssueList");
            }
          }}
          vehicleDetails={resolveData}
          from={"fleet"}
          issueType={issueType}
          issue={issue}
        />
      ) : (
        <TabFleetView
          pageNo={pageNoFL}
          pageNoV={pageNoV}
          record={recordFL}
          recordV={recordV}
          filter={filter}
          filterV={filterV}
          searchFilter={searchFilter}
          searchFilterV={searchFilterV}
          datePicker={datePicker}
          datePicker1={datePicker1}
          datePickerV={datePickerV}
          datePickerV1={datePickerV1}
          handlePageNo={(page) => {
            setPageNoFL(page);
          }}
          handlePageNoV={(page) => {
            setPageNoV(page);
          }}
          handleRecord={(rec) => {
            setRecordFL(rec);
          }}
          handleRecordV={(rec) => {
            setRecordV(rec);
          }}
          handleFilterFL={(flt) => {
            setFilter(flt);
          }}
          handleFilterV={(flt) => {
            setFilterV(flt);
          }}
          handleSearch={(s) => {
            setSearchFilter(s);
          }}
          handleSearchV={(s) => {
            setSearchFilterV(s);
          }}
          handleDatePicker={(date) => {
            setDatePicker(date);
          }}
          handleDatePicker1={(date) => {
            setDatePicker1(date);
          }}
          handleDatePickerV={(date) => {
            setDatePickerV(date);
          }}
          handleDatePickerV1={(date) => {
            setDatePickerV1(date);
          }}
          handleClick={(v, data) => {
            if (v === "addFleet") {
              setTabValue(0);
              setChangeTab("addFleet");
            } else if (v === "fleetDetails") {
              setTabValue(0);
              setChangeTab("fleetDetails");
            } else if (v === "editVehicle") {
              setTabValue(1);
              setChangeTab("editVehicle");
            } else if (v === "vehicleDetails") {
              setTabValue(1);
              setChangeTab("vehicleDetails");
            } else if (v === "UnresolvedIssue") {
              setTabValue(0);
              setPageNoIL(1);
              setRecordIL(10);
              setChangeTab("UnresolvedIssue");
            }
            setFleetData(data);
          }}
          from={dashboard ? "vehicleList" : ""}
          tab={tabValue}
        />
      )}
    </div>
  );
}
