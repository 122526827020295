import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";
const useStyles = makeStyles((theme) => ({
  input: {
    "& input[type=number]::-webkit-outer-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
    },
  },
  dataMain: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  card: {
    flex: 1,
    display: "flex",
    // padding: "0 20px 20px 20px",
    boxSizing: "border-box",
    overflow: "hidden !important",
    flexDirection: "column",
    "@media (max-width:425px)": {
      padding: "0 10px 10px 10px",
    },
  },
  //   },
  container: {
    padding: 20,
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none !important",
    },
    display: "flex",
    flex: 1,
    flexDirection: "column",
    border: `1px solid ${color.bodercolor}`,
    "@media (max-width:425px)": {
      padding: 10,
    },
  },
  autocomplete: {
    paddingLeft: "0px !important",
  },
}));
export default useStyles;
