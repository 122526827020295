import { makeStyles } from "@mui/styles";
const useStyles = makeStyles((theme) => ({
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
  },
}));
export default useStyles;
