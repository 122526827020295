import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting.js";
import Box from "@mui/material/Box";
import {
  FormHelperText,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  InputAdornment,
  Modal,
  OutlinedInput,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles.js";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { cloneDeep, isArray, isEmpty, isNull, isObject } from "lodash";
import { color } from "../../../Config/theme";
import { CTypography } from "../../Fleet/AddFleetForm/index";
import {
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { tyre_condition } from "../../../Config/Static_Data.js";
import ConfirmDialog from "../../ConfirmDialog/index.js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTheme } from "@emotion/react";
import moment from "moment";
import { isMobile, isTablet } from "react-device-detect";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
const errorObj = {
  issueErr: false,
  issueMsg: "",
  mechErr: false,
  mechMsg: "",
};

export default function AddInspectionForm(props) {
  const { handleClick = () => null, removalData, tyreData, from } = props;
  const styles = useStyles();
  const isEdit = from === "vehicleInspection";
  const currency_Regex = Setting.JS_Regex.currency_Regex;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  const { dropDownData, isOnline, token } = useSelector((state) => state.auth);

  const [errObj, setErrObj] = useState(errorObj);

  const [loader, setLoader] = useState(false);
  const [disabled, setdisabled] = useState(isEdit);
  const [imgLoader, setImgLoader] = useState(false);
  const [modal, openModal] = useState(false);
  const [serialNo, setserialNo] = useState("");
  const [tyreCondition, settyreCondition] = useState("");
  const [tyreBrand, settyreBrand] = useState("");
  const [patternSize, setPAtternSize] = useState(null);
  const [tyrePosition, settyrePosition] = useState("");
  const [avgNSD, setAvgNSD] = useState("");
  const [remark, setRemark] = useState("");

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  const [issueType, setissueType] = useState([]);
  const [mechType, setmechType] = useState([]);
  const dummytyreArr = cloneDeep(dropDownData?.tyre_issue_inspection);
  const [issueArry, setissueArry] = useState(dummytyreArr);
  const [renderTagsValue, setrenderTagsValue] = useState("");
  const [renderMTagsValue, setrenderMTagsValue] = useState("");
  const [file, setFile] = useState("No File Selected");
  const [selectedFile, setSlectedFile] = useState([]);
  const [showImg, setShowImg] = useState("");
  const dummymechanicleArr = cloneDeep(dropDownData?.mechanical_issue);
  const [mechanicleRemovalArr, setMechanicleRemovalArr] =
    useState(dummymechanicleArr);

  const [openCard, setOpenCard] = useState(false);
  const [openMCard, setOpenMCard] = useState(false);
  const [tyreSerial, setTyreSerial] = useState([]);

  const [formValues, setFormValues] = useState([
    {
      groove1: "",
      groove2: "",
      groove3: "",
      groove4: "",
      id: Math.floor(Math.random() * 100 + 1),
    },
  ]);
  const [airPressure, setairPressure] = useState("");

  useEffect(() => {
    if (isEdit) {
      if (isArray(tyreData?.issue_ids) && !isEmpty(tyreData?.issue_ids)) {
        tyreData?.issue_ids?.map((ele, ind) => {
          const issIndex = dropDownData?.tyre_issue_inspection?.findIndex(
            (v) => v?.id?.toString() === ele?.toString()
          );
          SelectTyre(issIndex);
          setTimeout(() => handleSelect(), 100);
        });
      } else {
        // SelectTyre(0);
        setTimeout(() => handleSelect(), 100);
      }
    } else {
      if (isArray(tyreData?.tyre_issue) && !isEmpty(tyreData?.tyre_issue)) {
        tyreData?.tyre_issue?.map((ele, ind) => {
          const issIndex = dropDownData?.tyre_issue_inspection?.findIndex(
            (v) => v?.id?.toString() === ele?.toString()
          );
          SelectTyre(issIndex);
          setTimeout(() => handleSelect(), 100);
        });
      } else {
        // SelectTyre(0);
        setTimeout(() => handleSelect(), 100);
      }
    }

    if (isEdit) {
      if (
        isArray(tyreData?.mechanical_issue_ids) &&
        !isEmpty(tyreData?.mechanical_issue_ids)
      ) {
        tyreData?.mechanical_issue_ids?.map((ele, ind) => {
          const issMIndex = dropDownData?.mechanical_issue?.findIndex(
            (v) => v?.id?.toString() === ele?.toString()
          );
          SelectTyre(issMIndex, "mechanical");
          setTimeout(() => handleSelect("mechanical"), 300);
        });
      } else {
        // SelectTyre(0, "mechanical");
        setTimeout(() => handleSelect("mechanical"), 300);
      }
    } else {
      if (
        isArray(tyreData?.mechanical_issue) &&
        !isEmpty(tyreData?.mechanical_issue)
      ) {
        tyreData?.mechanical_issue?.map((ele, ind) => {
          const issMIndex = dropDownData?.mechanical_issue?.findIndex(
            (v) => v?.id?.toString() === ele?.toString()
          );
          SelectTyre(issMIndex, "mechanical");
          setTimeout(() => handleSelect("mechanical"), 300);
        });
      } else {
        // SelectTyre(0, "mechanical");
        setTimeout(() => handleSelect("mechanical"), 300);
      }
    }

    setserialNo(tyreData?.tyre_serial_no || "");
    settyreCondition(
      isEdit ? tyreData?.tyre_condition : tyreData?.condition || ""
    );
    settyreBrand(tyreData?.tyre_brand || "");
    setPAtternSize(tyreData?.tyre_pattern_size || "");
    settyrePosition(tyreData?.tyre_position || "");
    setairPressure(tyreData?.tyre_air_pressure || "");
    setFormValues(
      tyreData?.current_nsd || [
        {
          groove1: "",
          groove2: "",
          groove3: "",
          groove4: "",
          id: Math.floor(Math.random() * 100 + 1),
        },
      ]
    );
    setRemark(tyreData?.remark || "");

    const fileName = !isEmpty(tyreData?.issue_image) && tyreData?.issue_image;
    setFile(fileName[fileName.length - 1] || "No File Selected");
    if (!isEmpty(tyreData?.issue_image) && isArray(tyreData?.issue_image)) {
      setSlectedFile(tyreData?.issue_image);
    } else if (!isEmpty(tyreData?.issue_image)) {
      setSlectedFile(tyreData?.issue_image.split(","));
    }

    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, []);

  useEffect(() => {
    isArray(formValues) && !isEmpty(formValues) && findAvgNSD();
  }, [formValues]);

  useEffect(() => {
    getTyreSerial();
  }, []);

  const getTyreSerial = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreserialno}?vehicle_id=${removalData?.vehicle?.id}&is_inspection=1`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setTyreSerial(response.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function findAvgNSD() {
    let groove = 0;
    let divide = 0;
    formValues?.map((v, index) => {
      if (!isEmpty(v?.groove1)) {
        groove = Number(v?.groove1) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove2)) {
        groove = Number(v?.groove2) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove3)) {
        groove = Number(v?.groove3) + groove;
        divide++;
      }
      if (!isEmpty(v?.groove4)) {
        groove = Number(v?.groove4) + groove;
        divide++;
      }
    });

    const mainAVG = groove / divide;
    setAvgNSD(isNaN(mainAVG) ? "" : mainAVG.toFixed(2));
  }

  function SelectTyre(index, type) {
    const DummyArr =
      type === "mechanical" ? [...mechanicleRemovalArr] : [...issueArry];

    DummyArr.map((item, i) => {
      if (i === index) {
        if (item?.selected) {
          if (item?.label === "N/A") {
            DummyArr.map((item1, i) => {
              if (item1?.label !== "N/A") {
                item1.disable = false;
              } else {
                item1.selected = false;
              }
            });
          } else {
            item.selected = false;
          }
        } else {
          if (item?.label === "N/A") {
            DummyArr.map((item1, i) => {
              if (item1?.label !== "N/A") {
                item1.selected = false;
                item1.disable = true;
              } else {
                item1.selected = true;
              }
            });
          } else {
            item.selected = true;
          }
        }
      }
    });
    type === "mechanical"
      ? setMechanicleRemovalArr(DummyArr)
      : setissueArry(DummyArr);
  }

  function handleSelect(type) {
    type === "mechanical"
      ? setErrObj({ ...errObj, mechErr: false, mechMsg: "" })
      : setErrObj({ ...errObj, issueErr: false, issueMsg: "" });
    type === "mechanical" ? setOpenMCard(false) : setOpenCard(false);
    const DummyArr =
      type === "mechanical" ? [...mechanicleRemovalArr] : [...issueArry];
    const selected = DummyArr?.filter((v) => v.selected === true);
    const rtVal =
      isArray(selected) && !isEmpty(selected)
        ? selected
            .map(function (elem) {
              return elem.label;
            })
            .join(", ")
        : "";
    const array = [];
    const array2 = [];
    if (!isEmpty(selected) && isArray(selected)) {
      selected.map((v) => {
        array.push(v.id);
        array2.push(v.label);
      });
      type === "mechanical" ? setmechType(array) : setissueType(array);
      type === "mechanical"
        ? setrenderMTagsValue(rtVal)
        : setrenderTagsValue(rtVal);
    } else {
      if (type === "mechanical") {
        setrenderMTagsValue("");
        // setMechanicleRemovalArr([]);
      } else {
        setrenderTagsValue("");
        // setissueType([]);
      }
    }
    type === "mechanical" ? setOpenMCard(false) : setOpenCard(false);
  }

  async function addInspection() {
    setRemoveBtnLoad(true);

    const url = selectedFile.toString();

    const isNA = issueType?.includes(70);
    const isNA2 = mechType?.includes(116);

    const tyreId = tyreSerial.find((v) => v?.tyre_serial_no === serialNo);

    let endPoints = Setting.endpoints.addinspection;
    const params = {
      "Inspection[fleet_id]": removalData?.fleet?.id,
      "Inspection[vehicle_id]": removalData?.vehicle?.id,
      "Inspection[km_reading]":
        removalData?.km_reading?.replaceAll(",", "") || "",
      "Inspection[capture_date]":
        (removalData?.captureDate &&
          moment(removalData?.captureDate).format("YYYY-MM-DD")) ||
        "",
      "Inspection[tyre_id]": tyreId?.id,
      "Inspection[tyre_issue]": isNA ? "" : issueType,
      "Inspection[mechanical_issue]": isNA2 ? "" : mechType,
      "Inspection[issue_image]": url,
      "Inspection[avg_nsd]": avgNSD,
      "Inspection[current_nsd]": JSON.stringify(formValues),
      "Inspection[condition]": tyreCondition,
      "Inspection[tyre_position]": tyrePosition,
      "Inspection[remark]": remark,
      "Inspection[tyre_air_pressure]": airPressure,
    };

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        handleClick("success", tyreData?.tyre_position, from);
        setConfirmDialog(false);
      } else {
        toast.error(resp?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setRemoveBtnLoad(false);
    }
  }

  function changeInspectionData() {
    const dummyRow = { ...tyreData };

    const url = selectedFile;
    const isNA = issueType?.includes(70);
    const isNA2 = mechType?.includes(116);

    dummyRow.current_nsd = formValues;
    dummyRow.avg_nsd = avgNSD;
    dummyRow.tyre_air_pressure = airPressure;
    dummyRow.issue_ids = isNA ? "" : issueType;
    dummyRow.mechanical_issue_ids = isNA2 ? "" : mechType;
    dummyRow.tyre_condition = tyreCondition;
    dummyRow.remark = remark;
    dummyRow.issue_image = url;
    setConfirmDialog(false);
    setTimeout(() => {
      handleClick("success", dummyRow, from);
    }, 100);
  }

  const handleChange = (i, e, key) => {
    let newFormValues = [...formValues];
    newFormValues.map((v, index) => {
      if (i === index) {
        if (key === 1) {
          v.groove1 = e;
        } else if (key === 2) {
          v.groove2 = e;
        } else if (key === 3) {
          v.groove3 = e;
        } else if (key === 4) {
          v.groove4 = e;
        }
      }
    });
    setFormValues(newFormValues);
    setdisabled(false);
  };

  let addFormFields = () => {
    if (formValues.length < 4) {
      setFormValues([
        ...formValues,
        {
          groove1: "",
          groove2: "",
          groove3: "",
          groove4: "",
          id: Math.floor(Math.random() * 100 + 1),
        },
      ]);
    }
  };

  let removeFormFields = (i) => {
    let newFormValues = [...formValues];
    newFormValues.splice(i, 1);
    setFormValues(newFormValues);
    setdisabled(false);
  };

  async function uploadImgApi(file) {
    setImgLoader(true);
    let endPoints = Setting.endpoints.multiUploadImg;
    const params = {};
    if (isObject(file) && !isEmpty(file)) {
      Object.keys(file).map((item) => {
        params[`ImageForm[photo][${item}]`] = file[item];
      });
    }

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        respImage(resp?.data);
        setdisabled(false);
      } else {
        toast.error(resp?.message);
      }
      setImgLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setImgLoader(false);
    }
    setImgLoader(false);
  }

  // upload image validation function
  function uploadImg(file) {
    if (file.length > 5 || file.length + selectedFile.length > 5) {
      toast.error("Cannot upload files more than 5");
    } else {
      uploadImgApi(file);
    }
  }

  // set responce image in selectedFile state
  function respImage(file) {
    let dummy_Arr = [...selectedFile];
    if (isArray(file) && !isEmpty(file)) {
      file?.map((item, index) => {
        dummy_Arr.push(item);
      });
    }
    setSlectedFile(dummy_Arr);
  }

  function removeImage(i) {
    let dummy_Arr = [...selectedFile];

    dummy_Arr.splice(i, 1);

    setSlectedFile(dummy_Arr);
    setdisabled(false);
  }

  function clearData() {
    settyreCondition("");
    settyreBrand("");
    settyrePosition("");
    // settyrePattern("");
    setairPressure("");
  }

  function checkValidation(data) {
    const error = { ...errObj };
    let valid = true;
    let nsdErr = false;
    let section = null;
    let SeqErr = false;
    let scroll = false;
    let nsdReqErr = false;

    let c = removalData?.kmReading?.replaceAll(",", "") || "";
    if (!isEmpty(removalData?.kmReading) && !currency_Regex.test(c)) {
      valid = false;
      error.kmErr = true;
      error.kmMsg = "Please enter valid Current Reading";
    }

    formValues.map((v, index) => {
      v.nsdErr = false;
      if (index === 0) {
        if (
          isEmpty(v?.groove1) &&
          isEmpty(v?.groove2) &&
          isEmpty(v?.groove3) &&
          isEmpty(v?.groove4)
        ) {
          valid = false;
          v.nsdErr = true;
          nsdReqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
        }
      }

      if (Math.ceil(v?.groove1) === 0) {
        valid = false;
        nsdErr = true;
        v.nsdErr = true;
        if (!scroll) {
          scroll = true;
          section = document.querySelector(`#nsdErr_${index}`);
        }
      }

      if (!isEmpty(v?.groove2)) {
        if (Math.ceil(v?.groove2) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        } else if (isEmpty(v?.groove1)) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        }
      }

      if (!isEmpty(v?.groove3)) {
        if (Math.ceil(v?.groove3) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        } else if (isEmpty(v?.groove1) || isEmpty(v?.groove2)) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        }
      }

      if (!isEmpty(v?.groove4)) {
        if (Math.ceil(v?.groove4) === 0) {
          valid = false;
          nsdErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        } else if (
          isEmpty(v?.groove1) ||
          isEmpty(v?.groove2) ||
          isEmpty(v?.groove3)
        ) {
          valid = false;
          SeqErr = true;
          if (!scroll) {
            scroll = true;
            section = document.querySelector(`#nsdErr_${index}`);
          }
          v.nsdErr = true;
        }
      }
    });

    if (isEmpty(issueType.toString())) {
      valid = false;
      error.issueErr = true;
      error.issueMsg = "Please select Tyre Issue";
    }

    if (isEmpty(mechType.toString())) {
      valid = false;
      error.mechErr = true;
      error.mechMsg = "Please select Mechanical Issue";
    }

    if (nsdErr) {
      toast.error("NSD value can't be 0", { toastId: 1 });
    }
    if (SeqErr) {
      toast.error("Please fill NSD value sequentially", { toastId: 2 });
    }
    if (nsdReqErr) {
      toast.error("Please enter NSD value", { toastId: 3 });
    }
    // });
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
    // if (!positionCheck) {
    //   toast.warn(`Position Note: Please enter the first character as D or C, the subsequent
    //   two characters as numbers, and the last/fourth character as either
    //   I, M, or O`);
    // }
    setErrObj(error);
    if (valid && !nsdErr && !SeqErr && !nsdReqErr) {
      // setSaveData(data);
      setConfirmDialog(true);
    }
  }

  return (
    <Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
        id="jump_to_me"
      >
        <Typography fontWeight={"bold"}>
          {isEdit ? "Edit Inspection" : "Add Inspection"}
        </Typography>
      </Box>
      <Box className={styles.container}>
        <Grid
          container
          style={{ marginBottom: errObj.tyrePositionErr ? 30 : 20 }}
          alignItems={"center"}
          id="tyrePosition"
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <Typography>Tyre Position</Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Tyre Position"
              disabled={true}
              value={tyrePosition}
              inputProps={{
                maxLength: 4,
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid
          container
          style={{ marginBottom: 20 }}
          alignItems={"center"}
          id="serialNo"
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <Typography>Tyre Serial No. :</Typography>
          </Grid>

          <Grid item xs={12} sm={9} md={10} lg={10}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <TextField
                style={{ width: "100%", margin: 0 }}
                placeholder="Enter Tyre Serial No."
                value={serialNo}
                disabled={true}
              />
            </div>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.brandErr ? 30 : 20 }}
          alignItems={"center"}
          id="tyreBrand"
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <Typography>Tyre Brand : </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <Typography>{tyreBrand}</Typography>
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <Typography>Item Description :</Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <Typography>{patternSize}</Typography>
          </Grid>
        </Grid>
        <Grid container alignItems={"center"}>
          {formValues.map((v, index) => {
            const isLast = index === formValues.length - 1;
            return (
              <Grid
                key={`new_${v?.id}`}
                container
                style={{ marginBottom: 20 }}
                alignItems={"center"}
                id={`nsdErr_${index}`}
              >
                <Grid item xs={12} sm={3} md={2}>
                  <CTypography
                    required={index === 0 ? true : false}
                    isDot={index === 0 ? false : true}
                    title={index === 0 && "NSD"}
                  ></CTypography>
                </Grid>
                <Grid
                  item
                  container
                  xs={12}
                  sm={9}
                  md={10}
                  gap={1}
                  wrap={isMobile && !isTablet ? "wrap" : "nowrap"}
                  style={{
                    border: v?.nsdErr ? "1px solid red" : "",
                    padding: v?.nsdErr ? 5 : "",
                  }}
                >
                  <Grid
                    item
                    xs={12}
                    sm={2.4}
                    md={2.33}
                    // style={{ marginTop: isTablet ? 20 : 0 }}
                  >
                    <TextField
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Groove 1"
                      value={v.groove1}
                      type={"number"}
                      inputProps={{
                        onWheel: (event) => event.currentTarget.blur(),
                      }}
                      onChange={(e) => {
                        handleChange(index, e?.target?.value, 1);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2.4}
                    md={2.33}
                    // style={{ marginTop: md ? 20 : 0 }}
                  >
                    <TextField
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Groove 2"
                      value={v.groove2}
                      type={"number"}
                      inputProps={{
                        onWheel: (event) => event.currentTarget.blur(),
                      }}
                      onChange={(e) => {
                        handleChange(index, e?.target?.value, 2);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2.4}
                    md={2.33}
                    // style={{ marginTop: md ? 20 : 0 }}
                  >
                    <TextField
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Groove 3"
                      value={v.groove3}
                      type={"number"}
                      inputProps={{
                        onWheel: (event) => event.currentTarget.blur(),
                      }}
                      onChange={(e) => {
                        handleChange(index, e?.target?.value, 3);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={2.4}
                    md={2.33}
                    // style={{ marginTop: md ? 20 : 0 }}
                  >
                    <TextField
                      style={{ width: "100%", margin: 0 }}
                      placeholder="Groove 4"
                      value={v.groove4}
                      type={"number"}
                      inputProps={{
                        onWheel: (event) => event.currentTarget.blur(),
                      }}
                      onChange={(e) => {
                        handleChange(index, e?.target?.value, 4);
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={1}
                    md={2}
                    // style={{ marginTop: md ? 20 : 0 }}
                  >
                    <Button
                      style={{
                        backgroundColor: !isLast
                          ? color.secondary
                          : formValues.length === 4 && isLast
                          ? "rgba(0, 0, 0, 0.12)"
                          : color.primary,
                        width: isMobile ? "100%" : 100,
                        boxShadow:
                          formValues.length === 4 && isLast ? "unset" : "",
                      }}
                      disabled={formValues.length === 4 && isLast}
                      onClick={() =>
                        !isLast ? removeFormFields(index) : addFormFields()
                      }
                    >
                      {!isLast ? "Remove" : "Add More"}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            );
          })}
        </Grid>
        <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <Typography>Avg. Initial NSD : </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter Current NSD"
              value={avgNSD}
              // onChange={(e) => {
              //   settreadPattern(e.target.value);
              // }}
              inputProps={{
                onWheel: (event) => event.currentTarget.blur(),
                readOnly: true,
              }}
            />
          </Grid>
        </Grid>
        <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <CTypography title="Tyre Pressure" />
          </Grid>

          <Grid item xs={12} sm={9} md={10} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              value={airPressure}
              placeholder="Enter Tyre Air Pressure in psi"
              onChange={(e) => {
                setdisabled(false);
                setairPressure(e.target.value);
                setErrObj({
                  ...errObj,
                  airPressureErr: false,
                  airPressureMsg: "",
                });
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">psi</InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.issueErr ? 30 : 20 }}
          alignItems={"center"}
          id="tyreissue"
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <CTypography required title={"Tyre Issue"} />
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <FormControl fullWidth error={errObj?.issueErr}>
              <OutlinedInput
                style={{ width: "100%", margin: 0, cursor: "pointer" }}
                placeholder="Select the Tyre Issue"
                inputProps={{
                  readOnly: true,
                  style: { cursor: "pointer" },
                }}
                value={renderTagsValue}
                onClick={() => {
                  if (isArray(issueArry) && !isEmpty(issueArry)) {
                    setOpenCard(true);
                  } else {
                    toast.warning(
                      "Please Add Tyre Issue Inspection First from General DropDowns"
                    );
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                }
              />
              {errObj.issueErr ? (
                <FormHelperText>{errObj.issueMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.mechErr ? 30 : 20 }}
          alignItems={"center"}
          id="mechanicalIssue"
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <CTypography required title={"Mechanical Issue"} />
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <FormControl fullWidth error={errObj?.mechErr}>
              <OutlinedInput
                disabled={tyreData?.is_added === 1}
                style={{ width: "100%", margin: 0, cursor: "pointer" }}
                placeholder="Select Mechanical Issues"
                inputProps={{
                  readOnly: true,
                  style: { cursor: "pointer" },
                }}
                value={renderMTagsValue}
                onClick={() => {
                  if (
                    isArray(mechanicleRemovalArr) &&
                    !isEmpty(mechanicleRemovalArr)
                  ) {
                    setOpenMCard(true);
                  } else {
                    toast.warning(
                      "Please Add Mechanical Issue from General DropDowns"
                    );
                  }
                }}
                endAdornment={
                  <InputAdornment position="end">
                    <ArrowDropDownIcon />
                  </InputAdornment>
                }
              />
              {errObj.mechErr ? (
                <FormHelperText>{errObj.mechMsg}</FormHelperText>
              ) : (
                ""
              )}
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: errObj.tyreConditionErr ? 30 : 20 }}
          alignItems={"center"}
          id="tyreCondition"
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <CTypography required title={"Tyre Condition"} />
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <Box sx={{ minWidth: 120 }}>
              <FormControl fullWidth error={errObj.tyreConditionErr}>
                <Select
                  displayEmpty
                  value={tyreCondition}
                  onChange={(event) => {
                    setdisabled(false);
                    settyreCondition(event.target.value);
                    setErrObj({
                      ...errObj,
                      tyreConditionErr: false,
                      tyreConditionMsg: "",
                    });
                  }}
                  size={"medium"}
                  style={tyreCondition === "" ? { color: "#A2A2A2" } : {}}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Select the Tyre Condition
                  </MenuItem>
                  {tyre_condition.map((item, index) => (
                    <MenuItem key={index} value={item?.label}>
                      {item?.label}
                    </MenuItem>
                  ))}
                </Select>
                {errObj.tyreConditionErr ? (
                  <FormHelperText>
                    {errObj.tyreConditionMsg}
                    {}
                  </FormHelperText>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>
          </Grid>
        </Grid>

        <Grid container style={{ marginBottom: 20 }} id="remark">
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <Typography>Remarks : </Typography>
          </Grid>
          <Grid item xs={12} sm={9} md={10} lg={10}>
            <TextField
              onKeyDown={(event) => event.stopPropagation()}
              disabled={tyreData?.is_added === 1}
              fullWidth
              value={remark}
              placeholder="Enter Remarks here"
              onChange={(e) => {
                setdisabled(false);
                setRemark(e.target.value);
              }}
              rows={2}
              multiline
            />
          </Grid>
        </Grid>

        <Grid
          container
          style={{ marginBottom: 20 }}
          id="upload"
          alignItems={"center"}
          wrap={isMobile && !isTablet ? "wrap" : "nowrap"}
        >
          <Grid item xs={12} sm={3} md={2} lg={2}>
            <Typography>Upload Image : </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={9}
            md={10}
            lg={10}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <div style={{ display: "flex", flexDirection: "column" }}>
              {!isEmpty(selectedFile) && isArray(selectedFile) ? (
                selectedFile.map((item, index) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        style={{
                          color: isEmpty(selectedFile)
                            ? color.disable
                            : color.primary,
                          fontWeight: "bold",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setShowImg(item);
                          setTimeout(() => openModal(true), 200);
                        }}
                      >
                        View Image-{index + 1}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          removeImage(index);
                        }}
                      >
                        <HighlightOffIcon style={{ color: "red" }} />
                      </IconButton>
                    </div>
                  );
                })
              ) : (
                <Typography
                  style={{
                    color:
                      isEmpty(selectedFile) && isArray(selectedFile)
                        ? "#999"
                        : color.black,
                    maxWidth: "50vw",
                    overflow: "hidden",
                    wordWrap: "break-word",
                    whiteSpace: "break-spaces",
                  }}
                >
                  No File Selected
                </Typography>
              )}
            </div>
            <Button
              variant="contained"
              component="label"
              disabled={selectedFile?.length === 5}
              style={{
                backgroundColor:
                  selectedFile?.length === 5 ? color.disable : color.secondary,
                margin: "0 20px",
                width: 100,
              }}
            >
              <input
                type="file"
                multiple
                onChange={(e) => {
                  uploadImg(e.target.files);
                }}
                accept="image/jpeg, image/tiff, image/bmp, image/heic, image/png, image/jpg, application/pdf"
                hidden
              />
              {imgLoader ? (
                <CircularProgress style={{ color: "#fff" }} size={18} />
              ) : (
                "Upload File"
              )}
            </Button>
          </Grid>
        </Grid>

        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginBottom: 20 }}
        >
          <Button
            variant="contained"
            disabled={isOnline && !disabled ? false : true}
            onClick={() => checkValidation(tyreData)}
            style={{
              marginRight: 10,
              width: 130,
            }}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : !isEdit ? (
              `Save`
            ) : (
              `Save & Update`
            )}
          </Button>
          <Button
            style={{
              backgroundColor: color.secondary,
              width: 130,
            }}
            onClick={() => {
              handleClick("cancel", tyreData?.tyre_position, from);
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
      <ConfirmDialog
        title={`Are you sure you want to ${
          isEdit ? "Edit" : "Add"
        } Inspection?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            if (isEdit) {
              changeInspectionData();
            } else {
              addInspection();
            }
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
        subTitle={
          !isEdit
            ? "Once saved, you won't be able to edit the details. These details can be edited once you complete the entire inspection of the vehicle."
            : ""
        }
      />
      <Modal
        onClose={() => {
          setOpenCard(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "60vw",
            maxHeight: "65vh",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={isMobile ? 1 : md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: "50vh" }}
          >
            {issueArry.map((item, index) => {
              return (
                <ImageListItem
                  key={`${index}_${item?.id}`}
                  style={{
                    width: isMobile ? "60vw" : 200,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      SelectTyre(index, "");
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={() => {
                setdisabled(false);
                handleSelect();
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        onClose={() => {
          setShowImg("");
          setOpenMCard(false);
        }}
        open={openMCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: "60vw",
            maxHeight: "65vh",
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={isMobile ? 1 : md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: "50vh" }}
          >
            {mechanicleRemovalArr.map((item, index) => {
              return (
                <ImageListItem
                  key={`${index}_${item?.id}`}
                  style={{
                    width: isMobile ? "60vw" : 200,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      SelectTyre(index, "mechanical");
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={() => {
                setdisabled(false);
                handleSelect("mechanical");
              }}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        className={styles.modal}
        onClose={() => {
          openModal(false);
        }}
        open={modal}
      >
        <img
          style={{
            backgroundColor: color.white,
            maxWidth: md ? "90vw" : 800,
            maxHeight: "80vh",
            borderRadius: 4,
          }}
          src={showImg ? showImg : ""}
          alt=""
        />
      </Modal>
    </Box>
  );
}
