import React, {
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
  useRef,
} from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  Button,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Pagination,
  Tooltip,
  useMediaQuery,
  useTheme,
  Box,
  Table,
  TableRow,
  TableCell,
  TableHead,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Search } from "@mui/icons-material";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import { ResetIcon } from "../../Icon/TableToggle/index.js";
import Images from "../../../Config/Images.js";

function TabIssueList(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    pageNo = 1,
    record = 10,
    fleetData,
  } = props;
  const styles = useStyles();
  const [issueHistoryList, setIssueListRows] = useState({});
  const [pageLoad, setPageLoad] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const [downloadLoader, setDownloadLoader] = useState(false);
  const { token, isOnline, userdata } = useSelector((state) => state.auth);

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  const issueHistoryColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "pending_issues_count",
      headerName: "Unresolved Issues",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const issueCount = params?.row?.pending_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            // onClick={() => {
            //   navigate("/dashboard/FleetManagement", {
            //     state: {
            //       set: "fleetDetails",
            //       id: params?.row?.fleet_id,
            //       name: params?.row?.fleet_name,
            //       vehicle_id: params?.row?.vehicle_id,
            //     },
            //   });
            // }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : "red",
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },
    // {
    //   field: "resolved_issues_count",
    //   headerName: "Resolved Issues",
    //   width: lg ? 220 : "unset",
    //   flex: !lg ? 1 : "unset",
    // },
    {
      field: "inspected_by",
      headerName: "Last Inspected By",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "Last Inspected On",
      sortable: false,
      headerName: "Last Inspected On",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.inspected_at;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  // intialRender used for stop multiple time apiCall in
  useEffect(() => {
    setPageLoad(true);
    getSovedIssueList(false);
  }, [pageNo, record]);

  useEffect(() => {
    const section = document.querySelector(`#jump_to_me`);
    section.scrollIntoView({ behavior: "auto", block: "center" });
  }, [pageNo, pageLoad]);

  useImperativeHandle(ref, () => ({
    downloadFleetData: () => {
      downloadFleetData();
    },
  }));

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Fleet Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadFleetData(data) {
    const newData = data.map((item, index) => {
      const getInspectedOn = () => {
        const timestamp = item?.last_updated_at;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return item?.inspected_at ? displayDate : "";
      };

      const newObj = {
        "Sr. No.": index + 1,
        "Vehicle No.": item?.vehicle_no || "-",
        "Unresolved Issues": item?.pending_issues_count || "-",
        "Last Inspected By": item?.inspected_by || "-",
        "Last Inspected On": getInspectedOn() || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Unresolved Issue");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Unresolved Issue.xlsx");
  }

  async function getSovedIssueList(bool, v) {
    v && setDownloadLoader(true);
    !v && setPageLoad(true);
    let endpoints = `${Setting.endpoints.issueList}?fleet_id=${
      fleetData?.id
    }&page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadFleetData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setIssueListRows(listObj);
          }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Unresolved Issues");
      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setDownloadLoader(false);
    }
  }

  const UnresolvedIssue = (index) => {
    const issueCount = issueHistoryList?.data[index]?.pending_issues_count;
    return (
      <div style={{ display: "flex", flex: 1 }}>
        <Typography
          style={{
            fontWeight: "bold",
            color: issueCount == 0 ? color.disable : "red",
            cursor: "pointer",
          }}
        >
          {issueCount}
        </Typography>
      </div>
    );
  };
  const InspectedOn = (index) => {
    const timestamp = issueHistoryList?.data[index]?.inspected_at;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };
  return (
    <>
      {isTablet ? (
        <>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="jump_to_me"
              fontWeight={"bold"}
            >{`${fleetData?.name} - Unresolved Issues`}</Typography>
            <Button
              disabled={isOnline ? false : true}
              variant="contained"
              style={{
                marginLeft: 10,
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              onClick={() => {
                getSovedIssueList(false, 1);
              }}
            >
              {downloadLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                <img src={Images.Mdownload} alt="download" />
              )}
            </Button>
          </Box>
          <div className={styles.container}>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              gap={1}
            >
              <Grid
                item
                lg={10}
                gap={1}
                wrap="nowrap"
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Grid item lg={4}>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getSovedIssueList(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilter)) {
                          handlePageNo(1);
                          getSovedIssueList(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Vehicle No."
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item lg={1}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter)) {
                        handlePageNo(1);
                        getSovedIssueList(false);
                      }
                    }}
                  >
                    <Search />
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    disabled={!searchFilter}
                    onClick={() => {
                      setSearchFilter("");
                      getSovedIssueList(true);
                    }}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid
                item
                lg={1.5}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleRecord(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(issueHistoryList?.data) &&
              !isEmpty(issueHistoryList?.data) ? (
              <div style={{ marginTop: 10 }}>
                <DataGrid
                  rows={issueHistoryList?.data}
                  columns={issueHistoryColumn}
                  disableColumnMenu
                  hideFooter
                  showCellRightBorder
                  disableSelectionOnClick
                  showColumnRightBorder
                  autoHeight={true}
                  onCellClick={(params) => {
                    handleClick("UnresolvedIssueList", params?.row);
                  }}
                />
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(issueHistoryList?.pagination?.totalPage)}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="jump_to_me"
              fontWeight={"bold"}
            >{`${fleetData?.name} - Unresolved Issues`}</Typography>
            <Button
              disabled={isOnline ? false : true}
              variant="contained"
              style={{
                marginLeft: 10,
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              onClick={() => {
                getSovedIssueList(false, 1);
              }}
            >
              {downloadLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                <img src={Images.Mdownload} alt="download" />
              )}
            </Button>
          </Box>
          <div className={styles.container}>
            <Grid
              container
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 10 }}
              wrap="nowrap"
            >
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getSovedIssueList(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilter)) {
                          handlePageNo(1);
                          getSovedIssueList(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Vehicle No."
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid
                  item
                  style={{ marginLeft: 10 }}
                  display="flex"
                  wrap="nowrap"
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter)) {
                        handlePageNo(1);
                        getSovedIssueList(false);
                      }
                    }}
                  >
                    <Search />
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    disabled={!searchFilter}
                    onClick={() => {
                      setSearchFilter("");
                      getSovedIssueList(true);
                    }}
                  >
                    <ResetIcon />
                  </Button>
                </Grid>
              </Grid>
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleRecord(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(issueHistoryList?.data) &&
              !isEmpty(issueHistoryList?.data) ? (
              <div>
                {issueHistoryList?.data?.map((item, index) => {
                  return (
                    <Grid
                      item
                      container
                      style={{
                        marginTop: 10,
                        border: `1px solid ${color.bordercolor}`,
                        alignItems: "center",
                      }}
                    >
                      <Grid
                        item
                        container
                        onClick={() => {
                          handleClick(
                            "UnresolvedIssueList",
                            issueHistoryList?.data[index]
                          );
                        }}
                      >
                        <Table className={styles.customtableMobile}>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">Vehicle No.</TableCell>
                              <TableCell variant="head">
                                Unresolved Issues
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.vehicle_no || "-"}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {UnresolvedIssue(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head">
                                Resolved Issues
                              </TableCell>
                              <TableCell variant="head">
                                Last Inspected By
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.resolved_issues_count}
                              </TableCell>
                              <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.inspected_by || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableHead>
                            <TableRow>
                              <TableCell variant="head" colSpan={2}>
                                Last Inspected On
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell
                                variant="body"
                                colSpan={2}
                                style={{ paddingBottom: 5 }}
                              >
                                {InspectedOn(index) || "-"}
                              </TableCell>
                            </TableRow>
                          </TableHead>
                        </Table>
                      </Grid>
                    </Grid>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(issueHistoryList?.pagination?.totalPage)}
                    defaultPage={pageNo}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}

export default forwardRef(TabIssueList);
