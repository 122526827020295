import React, { useEffect, useRef, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { Edit, FilterAlt, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import moment from "moment";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { tyre_condition } from "../../../Config/Static_Data";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import InfoIcon from "@mui/icons-material/Info";
import { isTablet } from "react-device-detect";
import * as XLSX from "xlsx/xlsx.mjs";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";
import Images from "../../../Config/Images";

export default function TabUnresolvedIssue(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    vehicleData,
    from,
  } = props;
  // const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const [unresolvedIssueRows, setUnresolvedIssueRows] = useState({});
  const [axleList, setaxleList] = useState(false);
  const [conditionList, setconditionList] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [activeIndex, setactiveIndex] = useState(null);
  const [downloadLoader, setDownloadLoader] = useState(false);
  const styles = useStyles();
  const { token, dropDownData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );

  const UnresolvedIssueColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    // {
    //   field: "action",
    //   headerName: "Action",
    //   width: 70,
    //   headerAlign: "center",
    //   sortable: false,
    //   align: "center",
    //   renderCell: (params) => {
    //     return (
    //       <Tooltip title="Edit" placement="right" arrow>
    //         <Edit
    //           style={{ color: color.primary, cursor: "pointer" }}
    //           onClick={() => {
    //             handleClick("ResolveIssue", params?.row);
    //           }}
    //         />
    //       </Tooltip>
    //     );
    //   },
    // },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 180,
    },
    {
      field: "tyre_position",
      headerName: "Tyre Position",
      width: 180,
    },
    {
      field: "axle_type",
      headerName: "Axle Type",
      width: 180,
    },
    {
      field: "tyre_condition",
      headerName: "Tyre Condition",
      width: 200,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.tyre_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.tyre_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.tyre_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.tyre_condition || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "issues",
      headerName: "Tyre Issue",
      width: 140,
      renderCell: (params) => {
        let res = params?.row?.issues;
        return (
          <div
            style={{
              flex: 1,
            }}
          >
            {isArray(res) && !isEmpty(res) ? (
              res?.map((items, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      padding: "4px 0px",
                    }}
                  >
                    <Tooltip title="Resolve" placement="left" arrow>
                      <Edit
                        style={{
                          color: color.primary,
                          cursor: "pointer",
                          fontSize: 18,
                          paddingRight: 4,
                        }}
                        onClick={() => {
                          handleClick(
                            "ResolveIssue",
                            params?.row,
                            "tyre",
                            items,
                            record,
                            pageNo
                          );
                        }}
                      />
                    </Tooltip>
                    <Typography style={{ width: "100%" }}>
                      {items}
                      {i < res.length - 1 ? ", " : ""}
                    </Typography>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  padding: "4px 0px",
                }}
              >
                <Edit
                  style={{
                    color: color.disable,
                    cursor: "pointer",
                    fontSize: 18,
                    paddingRight: 4,
                  }}
                />
                <Typography style={{ width: "100%" }}>-</Typography>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "mechanical_issues",
      headerName: "Mechanical Issue",
      width: 200,
      renderCell: (params) => {
        let res = params?.row?.mechanical_issues;
        return (
          <div
            style={{
              flex: 1,
            }}
          >
            {isArray(res) && !isEmpty(res) ? (
              res?.map((items, i) => {
                return (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      padding: "4px 0px",
                    }}
                  >
                    <Tooltip title="Resolve" placement="left" arrow>
                      <Edit
                        style={{
                          color: color.primary,
                          cursor: "pointer",
                          fontSize: 18,
                          paddingRight: 4,
                        }}
                        onClick={() => {
                          handleClick(
                            "ResolveIssue",
                            params?.row,
                            "mechanical",
                            items,
                            record,
                            pageNo
                          );
                        }}
                      />
                    </Tooltip>
                    <Typography>
                      {items}
                      {i < res.length - 1 ? ", " : ""}
                    </Typography>
                  </div>
                );
              })
            ) : (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  padding: "4px 0px",
                }}
              >
                <Edit
                  style={{
                    color: color.disable,
                    cursor: "pointer",
                    fontSize: 18,
                    paddingRight: 4,
                  }}
                />
                <Typography style={{ width: "100%" }}>-</Typography>
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "no_of_days",
      headerName: "No. of Days Since Last Inspection",
      width: 200,
    },
    {
      field: "inspected_by",
      headerName: "Inspected by",
      width: 200,
    },
    {
      field: "Inspected On",
      headerName: "Inspected On",
      width: 180,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {timestamp ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  // intialRender used for stop multiple time apiCall in
  const initialRender = useRef(true);

  useEffect(() => {
    setPageLoad(true);
    getunresolveIssueList(false);
  }, [filter, pageNo, record, vehicleData]);

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      isNull(datePicker) &&
        isEmpty(searchFilter) &&
        getunresolveIssueList(false);
    }
  }, [datePicker, searchFilter]);

  useEffect(() => {
    setactiveIndex(null);
  }, [pageNo, record]);

  useEffect(() => {
    const section = document.querySelector(`#jump_to_me`);
    section.scrollIntoView({ behavior: "auto", block: "center" });
  }, [pageNo, pageLoad]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [activeIndex]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From ${
      from === "Inspection" ? "Inspection Managemnet" : "Fleet Management"
    }`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  async function getunresolveIssueList(bool, v) {
    v && setDownloadLoader(true);
    !v && setactiveIndex(null);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.unresolveissue
        }?per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&vehicle_id=${
          vehicleData?.vehicle_id
        }&filter=${filter}&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadUnresolveIssueList(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setUnresolvedIssueRows(listObj);
          }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Unresolved Tyre List");

      setPageLoad(false);
      setDownloadLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
      setDownloadLoader(false);
    }
  }

  function resetFilterData() {
    handleFilter("");
    setSearchFilter("");
    handlePageNo(1);
    setDatePicker(null);
    getunresolveIssueList(true);
  }

  const Action = (index) => {
    return (
      <Tooltip title="Edit" placement="right" arrow>
        <Edit
          style={{ color: color.primary, cursor: "pointer" }}
          onClick={() => {
            handleClick("ResolveIssue", unresolvedIssueRows?.data[index]);
          }}
        />
      </Tooltip>
    );
  };
  const TyreCondition = (index) => {
    return (
      <div>
        <span
          style={{
            backgroundColor:
              unresolvedIssueRows?.data[index]?.tyre_condition === "Average"
                ? "#FFFDD9"
                : unresolvedIssueRows?.data[index]?.tyre_condition === "Good"
                ? "#D9FFE4"
                : unresolvedIssueRows?.data[index]?.tyre_condition === "Bad"
                ? "#FFD9D9"
                : "",
            padding: 8,
            borderRadius: 4,
          }}
        >
          {unresolvedIssueRows?.data[index]?.tyre_condition || "-"}
        </span>
      </div>
    );
  };
  const TyreIssue = (index) => {
    let res = unresolvedIssueRows?.data[index]?.issues;
    return (
      <div
        style={{
          flex: 1,
        }}
      >
        {isArray(res) && !isEmpty(res) ? (
          res?.map((items, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  padding: "4px 0px",
                }}
              >
                <Tooltip title="Resolve" placement="bottom" arrow>
                  <Edit
                    style={{
                      color: color.primary,
                      cursor: "pointer",
                      fontSize: 18,
                      paddingRight: 4,
                    }}
                    onClick={() => {
                      handleClick(
                        "ResolveIssue",
                        unresolvedIssueRows?.data[index],
                        "tyre",
                        items,
                        record,
                        pageNo
                      );
                    }}
                  />
                </Tooltip>
                <Typography style={{ width: "100%" }}>
                  {items}
                  {i < res.length - 1 ? ", " : ""}
                </Typography>
              </div>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "4px 0px",
            }}
          >
            <Edit
              style={{
                color: color.disable,
                cursor: "pointer",
                fontSize: 18,
                paddingRight: 4,
              }}
            />
            <Typography style={{ width: "100%" }}>-</Typography>
          </div>
        )}
      </div>
    );
  };
  const MechanicleIssue = (index) => {
    let res = unresolvedIssueRows?.data[index]?.mechanical_issues;
    return (
      <div
        style={{
          flex: 1,
        }}
      >
        {isArray(res) && !isEmpty(res) ? (
          res?.map((items, i) => {
            return (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  padding: "4px 0px",
                }}
              >
                <Tooltip title="Resolve" placement="bottom" arrow>
                  <Edit
                    style={{
                      color: color.primary,
                      cursor: "pointer",
                      fontSize: 18,
                      paddingRight: 4,
                    }}
                    onClick={() => {
                      handleClick(
                        "ResolveIssue",
                        unresolvedIssueRows?.data[index],
                        "mechanical",
                        items,
                        record,
                        pageNo
                      );
                    }}
                  />
                </Tooltip>
                <Typography>
                  {items}
                  {i < res.length - 1 ? ", " : ""}
                </Typography>
              </div>
            );
          })
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              padding: "4px 0px",
            }}
          >
            <Edit
              style={{
                color: color.disable,
                cursor: "pointer",
                fontSize: 18,
                paddingRight: 4,
              }}
            />
            <Typography style={{ width: "100%" }}>-</Typography>
          </div>
        )}
      </div>
    );
  };
  const InspectedOn = (index) => {
    const timestamp = unresolvedIssueRows?.data[index]?.capture_date;
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  function downloadUnresolveIssueList(data) {
    const newData = data?.map((item, index) => {
      const getInspectedOn = () => {
        const timestamp = item?.capture_date;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return item?.capture_date ? displayDate : "";
      };
      const newObj = {
        "Sr. No.": index + 1,
        "Tyre Serial No.": item?.tyre_serial_no || "-",
        "Tyre Position": item?.tyre_position || "-",
        "Axle Type": item?.axle_type || "-",
        "Tyre Condition": item?.tyre_condition || "-",
        "Tyre Issue": item?.issues.toString() || "-",
        "Mechanical Issue": item?.mechanical_issues.toString() || "-",
        "No. of Days Since Last Inspection": item?.no_of_days || "-",
        "Inspected By": item?.inspected_by || "-",
        "Inspected On": getInspectedOn() || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Unresolved Tyre List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Unresolved Tyre List.xlsx");
  }

  return (
    <>
      {isTablet ? (
        <>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="jump_to_me"
              fontWeight={"bold"}
            >{`${vehicleData?.vehicle_no} - Unresolved Issues`}</Typography>
            <Button
              disabled={isOnline ? false : true}
              variant="contained"
              style={{
                marginLeft: 10,
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              onClick={() => {
                getunresolveIssueList(false, 1);
              }}
            >
              {downloadLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                <img src={Images.Mdownload} alt="download" />
              )}
            </Button>
          </Box>
          <div className={styles.container}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid
                item
                lg={10.4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item lg={2}>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={filter}
                    onChange={(v) => {
                      handlePageNo(1);
                      handleFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        setaxleList(false);
                        setconditionList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem value={""} disabled hidden selected>
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setaxleList(!axleList);
                        setconditionList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Axle Type</Typography>
                      {axleList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.axle_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: axleList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setaxleList(false);
                        setconditionList(!conditionList);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Condition</Typography>
                      {conditionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {tyre_condition.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: conditionList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.label}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleRecord(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              container
              alignItems={"center"}
              style={{ marginTop: 10 }}
              wrap="nowrap"
            >
              <Grid
                item
                lg={10.4}
                style={{ display: "flex", alignItems: "center" }}
                wrap={"nowrap"}
              >
                <Grid item lg={2}>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getunresolveIssueList(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyDown={(v) => {
                      if (v.key === "Enter") {
                        if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                          handlePageNo(1);
                          getunresolveIssueList(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Tyre Serial No. and Tyre Position"
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <div style={{ marginLeft: 10 }}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      showToolbar={false}
                      disableFuture
                      views={["year", "month", "day"]}
                      value={datePicker}
                      onChange={(newValue) => {
                        setDatePicker(newValue);
                      }}
                      inputFormat="dd-MM-yyyy"
                      DialogProps={{ className: styles.datePicker }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                            placeholder: "DD-MM-YYYY",
                          }}
                          sx={{ svg: { color: color.primary } }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </div>
                <div
                  style={{
                    marginLeft: 10,
                    display: "flex",
                    flexWrap: "nowrap",
                  }}
                >
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                        handlePageNo(1);
                        getunresolveIssueList(false);
                      }
                    }}
                  >
                    <Search />
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    disabled={!searchFilter && !filter && !datePicker}
                    onClick={resetFilterData}
                  >
                    <ResetIcon />
                  </Button>
                </div>
              </Grid>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(unresolvedIssueRows?.data) &&
              !isEmpty(unresolvedIssueRows?.data) ? (
              <>
                <div style={{ marginTop: 10 }}>
                  <DataGrid
                    rows={unresolvedIssueRows?.data}
                    columns={UnresolvedIssueColumns}
                    onCellClick={(params) => {
                      if (params?.field !== "action") {
                        handleClick("fleetDetails", params?.row);
                      }
                    }}
                    pageSize={record}
                    disableColumnMenu
                    autoHeight={true}
                    hideFooter
                    disableSelectionOnClick
                    showCellRightBorder
                    getRowHeight={() => "auto"}
                    showColumnRightBorder
                    filterModel={{
                      items: [
                        {
                          id: 1,
                          columnField: "vehicle_identifier",
                          operatorValue: "contains",
                          value: searchFilter,
                        },
                      ],
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      unresolvedIssueRows?.pagination?.totalPage
                    )}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 10,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              id="jump_to_me"
              fontWeight={"bold"}
            >{`${vehicleData?.vehicle_no} - Unresolved Issues`}</Typography>
            <Button
              disabled={isOnline ? false : true}
              variant="contained"
              style={{
                marginLeft: 10,
                backgroundColor: isOnline ? color.secondary : color.bordercolor,
              }}
              onClick={() => {
                getunresolveIssueList(false, 1);
              }}
            >
              {downloadLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                <img src={Images.Mdownload} alt="download" />
              )}
            </Button>
          </Box>
          <div className={styles.container}>
            <Grid
              item
              container
              style={{ display: "flex", alignItems: "center" }}
              wrap="nowrap"
            >
              <Grid item xs={1}>
                <Select
                  fullWidth
                  placeholder="Filter"
                  value={filter}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleFilter(v.target.value);
                  }}
                  onOpen={() => {
                    if (isEmpty(filter.toString())) {
                      setaxleList(false);
                      setconditionList(false);
                    }
                  }}
                  displayEmpty
                  IconComponent={FilterAlt}
                  style={filter === "" ? { color: "#A2A2A2" } : {}}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Filter
                  </MenuItem>
                  <ListItemButton
                    onClick={() => {
                      setaxleList(!axleList);
                      setconditionList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Axle Type</Typography>
                    {axleList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.axle_type.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: axleList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setaxleList(false);
                      setconditionList(!conditionList);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Condition</Typography>
                    {conditionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {tyre_condition.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: conditionList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.label}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item xs={10} style={{ marginLeft: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={1} style={{ marginLeft: 10 }}>
                <Select
                  fullWidth
                  value={record}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleRecord(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              wrap={"nowrap"}
            >
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getunresolveIssueList(true);
                    setSearchFilter(v?.target?.value);
                  }}
                  onKeyDown={(v) => {
                    if (v.key === "Enter") {
                      if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                        handlePageNo(1);
                        getunresolveIssueList(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Tyre Serial No. and Tyre Position"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={1}
                style={{
                  marginLeft: 10,
                  display: "flex",
                  flexWrap: "nowrap",
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      handlePageNo(1);
                      getunresolveIssueList(false);
                    }
                  }}
                >
                  <Search />
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  disabled={!searchFilter && !filter && !datePicker}
                  onClick={resetFilterData}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(unresolvedIssueRows?.data) &&
              !isEmpty(unresolvedIssueRows?.data) ? (
              <>
                {unresolvedIssueRows?.data?.map((item, index) => {
                  return (
                    <div style={{ position: "relative" }}>
                      <Grid
                        item
                        container
                        id={`jump_to_me${index}`}
                        style={{
                          marginTop: 20,
                          border: `1px solid ${color.bordercolor}`,
                          alignItems: "center",
                          overflow: "hidden",
                          boxShadow: color.shadow,
                        }}
                      >
                        {activeIndex !== index ? (
                          <Table className={styles.customtableMobile}>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Tyre Serial No.
                                </TableCell>
                                <TableCell variant="head">
                                  Tyre Position
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.tyre_serial_no || "-"}
                                </TableCell>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.tyre_position || "-"}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">Axle Type</TableCell>
                                <TableCell variant="head" colSpan={2}>
                                  Inspected On
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.axle_type || "-"}
                                </TableCell>
                                <TableCell
                                  colSpan={2}
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {InspectedOn(index) || "-"}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        ) : (
                          <Table className={styles.customtableMobile}>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Tyre Serial No.
                                </TableCell>
                                <TableCell variant="head">
                                  Tyre Position
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.tyre_serial_no || "-"}
                                </TableCell>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.tyre_position || "-"}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">Axle Type</TableCell>
                                <TableCell variant="head" colSpan={2}>
                                  Inspected On
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.axle_type || "-"}
                                </TableCell>
                                <TableCell
                                  colSpan={2}
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {InspectedOn(index) || "-"}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">Tyre Issue</TableCell>
                                <TableCell variant="head">
                                  Mechanical Issue
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 10 }}
                                >
                                  {TyreIssue(index)}
                                </TableCell>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {MechanicleIssue(index)}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableHead>
                              <TableRow>
                                <TableCell variant="head">
                                  Tyre Condition
                                </TableCell>

                                <TableCell variant="head">
                                  Inspected By
                                </TableCell>
                              </TableRow>
                              <TableRow>
                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 10 }}
                                >
                                  {TyreCondition(index) || "-"}
                                </TableCell>

                                <TableCell
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.inspected_by || "-"}
                                </TableCell>
                              </TableRow>
                            </TableHead>

                            <TableHead>
                              <TableCell variant="head" colSpan={2}>
                                No. of Days Since Last Inspection
                              </TableCell>
                              <TableRow>
                                <TableCell
                                  colSpan={2}
                                  variant="body"
                                  style={{ paddingBottom: 5 }}
                                >
                                  {item?.no_of_days || "0"}
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        )}
                        <div
                          onClick={(v) => {
                            if (activeIndex === index) {
                              setactiveIndex(null);
                            } else {
                              setactiveIndex(index);
                            }
                          }}
                          style={{
                            position: "absolute",
                            bottom: -18,
                            left: "45%",
                          }}
                        >
                          {activeIndex === index ? (
                            <div style={{ transform: "rotate(180deg)" }}>
                              <TableToggle />
                            </div>
                          ) : (
                            <TableToggle />
                          )}
                        </div>
                      </Grid>
                    </div>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      unresolvedIssueRows?.pagination?.totalPage
                    )}
                    defaultPage={pageNo}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      )}
    </>
  );
}
