import { makeStyles } from "@mui/styles";
import { color } from "../../Config/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: 20,
    flex: 1,
    "@media (max-width:425px)": {
      padding: 10,
    },
  },
  container: {
    padding: 20,
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    width: "100%",
    border: `1px solid ${color.bordercolor}`,

    "@media (max-width:425px)": {
      padding: 10,
    },
  },
  header: {
    paddingBottom: 30,
  },
  row: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: 0,
  },
  input: {
    width: "100%",
  },
  label: {
    // minWidth: 150,
  },
}));
export default useStyles;
