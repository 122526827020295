import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  TableRow,
  TableCell,
  Table,
  CircularProgress,
  Grid,
  useMediaQuery,
  Button,
  ImageListItemBar,
  ImageListItem,
  ImageList,
  Modal,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Tooltip,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import {
  cloneDeep,
  filter,
  isArray,
  isEmpty,
  isNull,
  isObject,
  isUndefined,
} from "lodash";
import moment from "moment";
import { useTheme } from "@emotion/react";
import * as XLSX from "xlsx/xlsx.mjs";
import { ArrowForward, Check, Close, Edit } from "@mui/icons-material";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ConfirmDialog from "../../ConfirmDialog/index.js";
import authActions from "../../../Redux/reducers/auth/actions";

export default function VehicleInspection(props) {
  const {
    handleClick = () => null,
    inspectionData,
    from,
    fromInside,
    editRowData,
  } = props;
  const styles = useStyles();
  const dispatch = useDispatch();
  const {
    token,
    dropDownData,
    userdata,
    isOnline,
    List,
    dummyListArray,
    reduxChangeArray,
    inspectionReading,
    inspectionRemarks,
  } = useSelector((state) => state.auth);
  const {
    setListRows,
    setDummyListArray,
    setReduxChangeArray,
    setInspectionReading,
    setInspectionRemarks,
  } = authActions;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));
  const [vInspectionDetails, setvInspectionDetails] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [pageLoad1, setPageLoad1] = useState(false);
  const [modal, openModal] = useState(false);
  const [loader, setLoader] = useState(false);
  const [imgURL, setimgURL] = useState("");

  const [inspectionTyreList, setinspectionTyreListRows] = useState([]);
  const [changesArr, setChangesArr] = useState([]);
  const [isRequested, setIsRequested] = useState(false);

  const [isEditRow, setIsEditRow] = useState(false);
  const [tyreSerial, setTyreSerial] = useState([]);

  const [openCard, setOpenCard] = useState(false);
  const [ind, setInd] = useState("");
  const dummytyreArr = cloneDeep(dropDownData?.tyre_issue_inspection);
  const [tyreRemovalArr, setTyreRemovalArr] = useState(dummytyreArr);

  const [isMechanicle, setIsMechanicle] = useState(false);
  const dummymechanicleArr = cloneDeep(dropDownData?.mechanical_issue);
  const [mechanicleRemovalArr, setMechanicleRemovalArr] =
    useState(dummymechanicleArr);

  const [datePicker, setDatePicker] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);

  const [dateErr, setDateErr] = useState(false);
  const [dateMsg, setDateMsg] = useState("");
  const [isDateChange, setIsDateChange] = useState(false);
  const [newDate, setNewDate] = useState("");
  const [isApprove, setIsApporve] = useState(0);
  const [isFinal, setIsFinal] = useState(0);
  const [reading, setReading] = useState([]);

  useEffect(() => {
    fromInside && setIsEditRow(true);
    if (!fromInside) {
      dispatch(setListRows([]));
      dispatch(setDummyListArray([]));
      dispatch(setReduxChangeArray([]));
    }
  }, [fromInside]);

  useEffect(() => {
    if (fromInside && !isEmpty(editRowData)) {
      let dummyTyreListArray = [...List];
      let changeInd = dummyTyreListArray?.findIndex(
        (v) => v?.id === editRowData?.id
      );
      checkObjChange();
      dummyTyreListArray.splice(changeInd, 1, editRowData);
      dispatch(setListRows(dummyTyreListArray));
    }
  }, [fromInside, editRowData]);

  function checkObjChange() {
    const dummy_change_arr = [...reduxChangeArray];

    let OrginalObj = dummyListArray.find((v) => v?.id === editRowData?.id);
    const isSame = JSON.stringify(OrginalObj) === JSON.stringify(editRowData);
    if (!isSame) {
      if (isArray(dummy_change_arr) && isEmpty(dummy_change_arr)) {
        dummy_change_arr.push(editRowData);
      } else {
        const asd = dummy_change_arr.find((v) => v?.id === editRowData?.id);
        isUndefined(asd) && dummy_change_arr.push(editRowData);
        if (!isUndefined(asd)) {
          const index = dummy_change_arr.indexOf(asd);
          dummy_change_arr[index] = editRowData;
        }
      }
    }

    dispatch(setReduxChangeArray(dummy_change_arr));
  }

  useEffect(() => {
    setinspectionTyreListRows(cloneDeep(List));
  }, [List]);

  useEffect(() => {
    setChangesArr(cloneDeep(reduxChangeArray));
  }, [reduxChangeArray]);

  useEffect(() => {
    !fromInside && getinspectiontyreList();
  }, []);

  useEffect(() => {
    if (!isEmpty(inspectionReading)) {
      setReading(inspectionReading);
    }
    if (!isEmpty(inspectionRemarks)) {
      setRemarks(inspectionRemarks);
    }
  }, [inspectionReading, inspectionRemarks]);

  const arrData = isMechanicle ? mechanicleRemovalArr : tyreRemovalArr;
  const nsdObj = {
    groove1: "",
    groove2: "",
    groove3: "",
    groove4: "",
    id: Math.floor(Math.random() * 1000 + 1),
  };

  const tyreListColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const ishighlight =
          from === "modification" &&
          inspectionData?.status !== 0 &&
          params?.row?.new_status;
        return (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor:
                params?.row?.sameNo || ishighlight === 2
                  ? "red"
                  : ishighlight === 1
                  ? color.green
                  : "unset",
              height: 20,
              width: 20,
              borderRadius: 10,
              color:
                params?.row?.sameNo || ishighlight === 1 || ishighlight === 2
                  ? color.white
                  : color.black,
            }}
          >
            <Typography>{index}</Typography>
          </div>
        );
      },
    },
    {
      field: "Actions",
      headerName: "Actions",
      headerAlign: "center",
      width: 140,
      hide: !isEditRow,
      align: "center",
      renderCell: (params) => {
        return (
          <Tooltip
            title={params?.row?.is_deleted === 1 ? "" : "Edit"}
            placement="left"
            arrow
          >
            <Edit
              style={{
                color:
                  params?.row?.is_deleted === 1 ? color.disable : color.primary,
                cursor: "pointer",
              }}
              onClick={() => {
                params?.row?.is_deleted !== 1 &&
                  handleClick("addInspectionForm", params?.row);
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "tyre_position",
      headerName: "Tyre Position",
      width: 160,
    },
    {
      field: "axle_type",
      headerName: "Axle Type",
      width: 160,
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: from === "modification" ? 340 : 220,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id);
        const isChange =
          from === "modification" &&
          params?.row?.new_tyre_serial_no &&
          params?.row?.tyre_serial_no !== params?.row?.new_tyre_serial_no;
        return !isEditRow ? (
          isChange ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: color.placeholder,
                  borderRadius: 4,
                  padding: "6px 8px",
                  color: color.white,
                }}
              >
                <Typography>{params?.row?.tyre_serial_no}</Typography>
              </div>
              <ArrowForward />
              <div
                style={{
                  backgroundColor: color.primary,
                  borderRadius: 4,
                  padding: "6px 8px",
                  color: color.white,
                }}
              >
                <Typography>{params?.row?.new_tyre_serial_no}</Typography>
              </div>
            </div>
          ) : (
            <Typography>{params?.row?.tyre_serial_no}</Typography>
          )
        ) : params?.row?.is_deleted === 1 ? (
          <Typography>{params?.row?.tyre_serial_no}</Typography>
        ) : (
          <FormControl fullWidth error={params?.row?.sameNo}>
            <Select
              displayEmpty
              value={params?.row?.tyre_serial_no}
              onChange={(v) => {
                arrChange(v?.target?.value, index, "serial");
              }}
              size={"medium"}
              style={
                params?.row?.tyre_serial_no === "" ? { color: "#A2A2A2" } : {}
              }
            >
              <MenuItem value={""} disabled hidden selected>
                Select the Serial No.
              </MenuItem>
              {tyreSerial.map((item, index) => (
                <MenuItem key={index} value={item?.tyre_serial_no}>
                  {item?.tyre_serial_no}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "tyre_type",
      headerName: "Tyre Type",
      width: 140,
    },
    {
      field: "tyre_brand",
      headerName: "Tyre Brand",
      width: 140,
    },
    {
      field: "tyre_pattern_size",
      headerName: "Item Description",
      width: 300,
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 220,
    },
    {
      field: "ply_rating",
      headerName: "Ply Rating",
      width: 220,
    },
    {
      field: "NSD",
      headerName: "Current NSD",
      width: !isEditRow ? 470 : 600,
      renderCell: (params) => {
        const i = params.api.getRowIndex(params.row.id);
        const data = params?.row?.current_nsd;
        const isChange =
          from === "modification" &&
          params?.row?.new_current_nsd &&
          JSON.stringify(params?.row?.current_nsd) !==
            JSON.stringify(params?.row?.new_current_nsd);
        const formValues = findperticulerAVG(params?.row?.current_nsd);
        const formValues1 = isChange
          ? findperticulerAVG(params?.row?.new_current_nsd)
          : [];

        return !isEditRow ? (
          isChange ? (
            <div
              style={{
                display: "flex",
                flex: 1,
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  backgroundColor: color.placeholder,
                  borderRadius: 4,
                  padding: "6px 8px",
                  color: color.white,
                }}
              >
                <Typography style={{ fontSize: 14 }}>
                  {`${
                    formValues?.groove1 !== 0
                      ? formValues?.groove1.toFixed(2)
                      : " - "
                  }/${
                    formValues?.groove2 !== 0
                      ? formValues?.groove2.toFixed(2)
                      : " - "
                  }/${
                    formValues?.groove3 !== 0
                      ? formValues?.groove3.toFixed(2)
                      : " - "
                  }/${
                    formValues?.groove4 !== 0
                      ? formValues?.groove4.toFixed(2)
                      : " - "
                  } mm`}
                </Typography>
              </div>
              <ArrowForward />
              <div
                style={{
                  backgroundColor: color.primary,
                  borderRadius: 4,
                  padding: "6px 8px",
                  color: color.white,
                }}
              >
                <Typography style={{ fontSize: 14 }}>
                  {`${
                    formValues1?.groove1 !== 0
                      ? formValues1?.groove1.toFixed(2)
                      : " - "
                  }/${
                    formValues1?.groove2 !== 0
                      ? formValues1?.groove2.toFixed(2)
                      : " - "
                  }/${
                    formValues1?.groove3 !== 0
                      ? formValues1?.groove3.toFixed(2)
                      : " - "
                  }/${
                    formValues1?.groove4 !== 0
                      ? formValues1?.groove4.toFixed(2)
                      : " - "
                  } mm`}
                </Typography>
              </div>
            </div>
          ) : (
            <Typography style={{ fontSize: 14 }}>
              {`${
                formValues?.groove1 !== 0
                  ? formValues?.groove1.toFixed(2)
                  : " - "
              }/${
                formValues?.groove2 !== 0
                  ? formValues?.groove2.toFixed(2)
                  : " - "
              }/${
                formValues?.groove3 !== 0
                  ? formValues?.groove3.toFixed(2)
                  : " - "
              }/${
                formValues?.groove4 !== 0
                  ? formValues?.groove4.toFixed(2)
                  : " - "
              } mm`}
            </Typography>
          )
        ) : params?.row?.is_deleted === 1 ? (
          <Typography style={{ fontSize: 14 }}>
            {`${
              formValues?.groove1 !== 0 ? formValues?.groove1.toFixed(2) : " - "
            }/${
              formValues?.groove2 !== 0 ? formValues?.groove2.toFixed(2) : " - "
            }/${
              formValues?.groove3 !== 0 ? formValues?.groove3.toFixed(2) : " - "
            }/${
              formValues?.groove4 !== 0 ? formValues?.groove4.toFixed(2) : " - "
            } mm`}
          </Typography>
        ) : (
          <div>
            {data?.map((v, index) => {
              return (
                <Grid
                  key={`new_${v?.id}_${i}`}
                  container
                  // style={{ marginBottom: 20 }}
                  alignItems={"center"}
                  id={`nsdErr_${index}`}
                  wrap="nowrap"
                >
                  <TextField
                    style={{ width: "25%", margin: "8px 8px 8px 8px" }}
                    placeholder="Groove 1"
                    value={v.groove1}
                    type={"number"}
                    inputProps={{
                      readOnly: true,
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                  />
                  <TextField
                    style={{ width: "25%", margin: "8px 8px 8px 8px" }}
                    placeholder="Groove 2"
                    value={v.groove2}
                    type={"number"}
                    inputProps={{
                      readOnly: true,
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                  />
                  <TextField
                    style={{ width: "25%", margin: "8px 8px 8px 8px" }}
                    placeholder="Groove 3"
                    value={v.groove3}
                    type={"number"}
                    inputProps={{
                      readOnly: true,
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                  />
                  <TextField
                    style={{ width: "25%", margin: "8px 8px 8px 8px" }}
                    placeholder="Groove 4"
                    value={v.groove4}
                    type={"number"}
                    inputProps={{
                      readOnly: true,
                      onWheel: (event) => event.currentTarget.blur(),
                    }}
                  />
                </Grid>
              );
            })}
          </div>
        );
      },
    },
    {
      field: "avg_nsd",
      headerName: "Avg. NSD",
      width: 240,
      renderCell: (params) => {
        const isChange =
          from === "modification" &&
          params?.row?.new_avg_nsd &&
          params?.row?.avg_nsd?.toString() !==
            params?.row?.new_avg_nsd?.toString();
        return isChange ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color.placeholder,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography>{`${params?.row?.avg_nsd} mm` || "-"}</Typography>
            </div>
            <ArrowForward />
            <div
              style={{
                backgroundColor: color.primary,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography>{`${params?.row?.new_avg_nsd} mm` || "-"}</Typography>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              backgroundColor:
                params?.row?.avg_nsd && params?.row?.avg_nsd < 5 && "red",
              padding: 6,
              borderRadius: 4,
            }}
          >
            <Typography
              style={{
                color:
                  params?.row?.avg_nsd &&
                  params?.row?.avg_nsd < 5 &&
                  color.white,
              }}
            >
              {`${params?.row?.avg_nsd} mm` || "-"}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "tyre_air_pressure",
      headerName: "Tyre Pressure",
      width: 240,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id);
        const isChange =
          from === "modification" &&
          params?.row?.new_tyre_air_pressure &&
          params?.row?.tyre_air_pressure !== params?.row?.new_tyre_air_pressure;
        return params?.row?.is_deleted === 1 ? (
          <Typography>{`${
            params?.row?.tyre_air_pressure || 0
          } psi`}</Typography>
        ) : isChange ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color.placeholder,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography>{`${
                params?.row?.tyre_air_pressure || 0
              } psi`}</Typography>
            </div>
            <ArrowForward />
            <div
              style={{
                backgroundColor: color.primary,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography>{`${
                params?.row?.new_tyre_air_pressure || 0
              } psi`}</Typography>
            </div>
          </div>
        ) : (
          <Typography>{`${
            params?.row?.tyre_air_pressure || 0
          } psi`}</Typography>
        );
      },
    },
    {
      field: "tyre_condition",
      headerName: "Tyre Condition",
      width: from === "modification" ? 200 : 170,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id);
        const isChange =
          from === "modification" &&
          params?.row?.new_tyre_condition &&
          params?.row?.tyre_condition !== params?.row?.new_tyre_condition;
        return isChange ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor:
                  params?.row?.tyre_condition === "Average"
                    ? "#FFFDD9"
                    : params?.row?.tyre_condition === "Good"
                    ? "#D9FFE4"
                    : params?.row?.tyre_condition === "Bad"
                    ? "#FFD9D9"
                    : "",
                borderRadius: 4,
                padding: "6px 8px",
              }}
            >
              <Typography>{params?.row?.tyre_condition}</Typography>
            </div>
            <ArrowForward />
            <div
              style={{
                backgroundColor:
                  params?.row?.new_tyre_condition === "Average"
                    ? "#FFFDD9"
                    : params?.row?.new_tyre_condition === "Good"
                    ? "#D9FFE4"
                    : params?.row?.new_tyre_condition === "Bad"
                    ? "#FFD9D9"
                    : "",
                borderRadius: 4,
                padding: "6px 8px",
              }}
            >
              <Typography>{params?.row?.new_tyre_condition}</Typography>
            </div>
          </div>
        ) : (
          <div
            style={{
              backgroundColor:
                params?.row?.tyre_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.tyre_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.tyre_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: "6px 8px",
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.tyre_condition || "-"}</Typography>
          </div>
        );
      },
    },
    {
      field: "issues",
      headerName: "Tyre Issue",
      width: from === "modification" ? 300 : 300,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id);
        const isChange =
          from === "modification" &&
          params?.row?.new_tyre_issue_ids &&
          JSON.stringify(params?.row?.issue_ids) !==
            JSON.stringify(params?.row?.new_tyre_issue_ids);

        const value = findIssueValue(
          params?.row?.issue_ids,
          dropDownData?.tyre_issue_inspection,
          false
        );
        const value1 = findIssueValue(
          params?.row?.new_tyre_issue_ids,
          dropDownData?.tyre_issue_inspection,
          false
        );

        return isChange ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color.placeholder,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography>{value?.renderTagsValue}</Typography>
            </div>
            <ArrowForward />
            <div
              style={{
                backgroundColor: color.primary,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography>{value1?.renderTagsValue}</Typography>
            </div>
          </div>
        ) : (
          <Typography>{value?.renderTagsValue}</Typography>
        );
      },
    },
    {
      field: "Mechanicle Issues",
      headerName: "Mechanical Issue",
      width: 240,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id);
        const isChange =
          from === "modification" &&
          params?.row?.new_mechanical_issue_ids &&
          JSON.stringify(params?.row?.mechanical_issue_ids) !==
            JSON.stringify(params?.row?.new_mechanical_issue_ids);
        const value = findIssueValue(
          params?.row?.mechanical_issue_ids,
          dropDownData?.mechanical_issue,
          true
        );
        const value1 = findIssueValue(
          params?.row?.new_mechanical_issue_ids,
          dropDownData?.mechanical_issue,
          true
        );
        return isChange ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color.placeholder,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography>{value?.renderTagsValue}</Typography>
            </div>
            <ArrowForward />
            <div
              style={{
                backgroundColor: color.primary,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography>{value1?.renderTagsValue}</Typography>
            </div>
          </div>
        ) : (
          <Typography>{value?.renderTagsValue}</Typography>
        );
      },
    },
    {
      field: "remark",
      headerName: "Remarks",
      width: 300,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id);
        const isChange =
          from === "modification" &&
          !isUndefined(params?.row?.new_remark) &&
          params?.row?.remark !== params?.row?.new_remark;
        return params?.row?.is_deleted === 1 ? (
          <Typography
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {params?.row?.remark || "-"}
          </Typography>
        ) : isChange ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color.placeholder,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography
                style={{
                  overflow: "hidden",
                  wordWrap: "break-word",
                  whiteSpace: "break-spaces",
                }}
              >
                {params?.row?.remark || "-"}
              </Typography>
            </div>
            <ArrowForward />
            <div
              style={{
                backgroundColor: color.primary,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography
                style={{
                  overflow: "hidden",
                  wordWrap: "break-word",
                  whiteSpace: "break-spaces",
                }}
              >
                {params?.row?.new_remark || "-"}
              </Typography>
            </div>
          </div>
        ) : (
          <Typography
            style={{
              overflow: "hidden",
              wordWrap: "break-word",
              whiteSpace: "break-spaces",
            }}
          >
            {params?.row?.remark || "-"}
          </Typography>
        );
      },
    },
    {
      field: "issue_image",
      headerName: "Image",
      width: 320,
      align: "center",
      headerAlign: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id);
        const url = params?.row?.issue_image;
        // const UrlName = url?.substring(url.indexOf("uploads/") + 8);

        const isChange =
          from === "modification" &&
          params?.row?.new_issue_image &&
          JSON.stringify(params?.row?.issue_image) !==
            JSON.stringify(params?.row?.new_issue_image);
        return !isEditRow && isChange ? (
          <div
            style={{
              display: "flex",
              flex: 1,
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div
              style={{
                backgroundColor: color.placeholder,
                borderRadius: 4,
                padding: "6px 8px",
              }}
            >
              <Typography
                style={{
                  color: isEmpty(params?.row?.issue_image)
                    ? color.disable
                    : color.white,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setimgURL(params?.row?.issue_image);
                  setTimeout(
                    () => !isEmpty(params?.row?.issue_image) && openModal(true),
                    200
                  );
                }}
              >
                Old Image
              </Typography>
            </div>
            <ArrowForward />
            <div
              style={{
                backgroundColor: color.primary,
                borderRadius: 4,
                padding: "6px 8px",
                color: color.white,
              }}
            >
              <Typography
                style={{
                  color: isEmpty(params?.row?.new_issue_image)
                    ? color.disable
                    : color.white,
                  fontWeight: "bold",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setimgURL(params?.row?.new_issue_image);
                  setTimeout(
                    () =>
                      !isEmpty(params?.row?.new_issue_image) && openModal(true),
                    200
                  );
                }}
              >
                New Image
              </Typography>
            </div>
          </div>
        ) : (
          <Typography
            style={{
              color: isEmpty(params?.row?.issue_image)
                ? color.disable
                : color.primary,
              fontWeight: "bold",
              cursor: "pointer",
            }}
            onClick={() => {
              setimgURL(params?.row?.issue_image);
              setTimeout(
                () => !isEmpty(params?.row?.issue_image) && openModal(true),
                200
              );
            }}
          >
            View
          </Typography>
        );
      },
    },
    // {
    //   field: "capture_date",
    //   sortable: false,
    //   headerName: "Inspection Date",
    //   width: 220,
    //   renderCell: (params) => {
    //     const timestamp = params?.row?.capture_date;
    //     // const date = new Date(timestamp * 1000);
    //     const displayDate = moment(timestamp).format("DD-MM-yyyy");
    //     return (
    //       <div
    //         style={{
    //           flex: 1,
    //           display: "flex",
    //
    //         }}
    //       >
    //         {params?.row?.capture_date ? displayDate : ""}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "inspected_by",
      headerName: "Inspected By",
      width: 160,
    },
    {
      field: "action taken",
      headerName: "Actions",
      hide:
        from !== "modification" ||
        (userdata?.role !== "superadmin" && userdata?.role !== "admin") ||
        inspectionData?.status !== 0,
      sortable: false,
      width: 240,
      renderCell: (params) => {
        const id = params?.row?.id;
        const noChange = !params?.row?.new_tyre_issue_ids;
        return params?.row?.isApprove === 1 ? (
          <div
            style={{
              borderRadius: 4,
              padding: "6px 8px",
              backgroundColor: color.green,
            }}
          >
            <Typography style={{ color: color.white }}>Approved</Typography>
          </div>
        ) : params?.row?.isApprove === 2 ? (
          <div
            style={{
              borderRadius: 4,
              padding: "6px 8px",
              backgroundColor: "#ff3333",
            }}
          >
            <Typography style={{ color: color.white }}>Rejected</Typography>
          </div>
        ) : noChange ? (
          <div
            style={{
              borderRadius: 4,
              padding: "6px 8px",
              backgroundColor: color.secondary,
            }}
          >
            <Typography style={{ color: color.white }}>No Changes</Typography>
          </div>
        ) : (
          <div>
            <Button
              style={{
                width: 100,
                backgroundColor: color.green,
              }}
              onClick={() => UpdateRequest(1, id)}
            >
              Approve
              <Check />
            </Button>
            <Button
              style={{
                width: 100,
                backgroundColor: "#ff3333",
                marginLeft: 20,
              }}
              onClick={() => UpdateRequest(2, id)}
            >
              Reject
              <Close />
            </Button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getVehicleInspectionDetails();
    getTyreSerial();
  }, []);

  useEffect(() => {
    setDatePicker(inspectionData?.capture_date);
  }, [inspectionData]);

  useEffect(() => {
    if (isOnline) {
      document.title = Setting.page_name.Inspection;
    }
  }, [isOnline]);

  function findIssueValue(value, data, isM) {
    let selected =
      isArray(value) && !isEmpty(value)
        ? data?.filter((item) => {
            let a = value?.toString().includes(item.id.toString());
            return a;
          })
        : data?.filter((item) => {
            let a = item.id.toString() === "70";
            let b = item.id.toString() === "116";
            return isM ? b : a;
          });
    const renderTagsValue =
      isArray(selected) && !isEmpty(selected)
        ? selected
            .map(function (elem) {
              return elem.label;
            })
            .join(", ")
        : "";

    return { renderTagsValue: renderTagsValue, selected: selected };
  }

  function findperticulerAVG(formValues) {
    let groove1 = 0;
    let groove2 = 0;
    let groove3 = 0;
    let groove4 = 0;
    let count1 = 1;
    let count2 = 1;
    let count3 = 1;
    let count4 = 1;
    isArray(formValues) &&
      !isEmpty(formValues) &&
      formValues?.map((v) => {
        if (!isEmpty(v?.groove1)) {
          groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
          count1++;
        }
        if (!isEmpty(v?.groove2)) {
          groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
          count2++;
        }
        if (!isEmpty(v?.groove3)) {
          groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
          count3++;
        }
        if (!isEmpty(v?.groove4)) {
          groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
          count4++;
        }
      });

    return {
      groove1: groove1,
      groove2: groove2,
      groove3: groove3,
      groove4: groove4,
    };
  }

  async function sendRequest() {
    setLoader(true);
    let endPoints = Setting.endpoints.modificationrequest;
    const params = {
      "ModificationForm[vehicle_id]": inspectionData.vehicle_id,
      "ModificationForm[inspection_date]":
        moment(datePicker).format("YYYY-MM-DD"),
      "ModificationForm[old_date]": inspectionData?.capture_date,
      "ModificationForm[is_date_changes]": isDateChange ? 1 : 0,
    };

    isArray(changesArr) &&
      !isEmpty(changesArr) &&
      changesArr.map((item, index) => {
        const isNA = item?.issue_ids?.includes("70");
        const isNA2 = item?.mechanical_issue_ids?.includes("116");

        const url = item?.issue_image;
        const UrlName = url.toString();

        params[`ModificationForm[issue_id][${index}]`] = item?.id;
        params[`ModificationForm[tyre_serial_no][${index}]`] =
          item?.tyre_serial_no;
        params[`ModificationForm[tyre_air_pressure][${index}]`] =
          item?.tyre_air_pressure;
        params[`ModificationForm[current_nsd][${index}]`] = JSON.stringify(
          item?.current_nsd
        );
        params[`ModificationForm[avg_nsd][${index}]`] = item?.avg_nsd;
        params[`ModificationForm[tyre_issue][${index}]`] = isNA
          ? []
          : item?.issue_ids;
        params[`ModificationForm[mechanical_issue][${index}]`] = isNA2
          ? []
          : item?.mechanical_issue_ids;
        params[`ModificationForm[condition][${index}]`] = item?.tyre_condition;
        params[`ModificationForm[issue_image][${index}]`] = UrlName;
        params[`ModificationForm[remark][${index}]`] = item?.remark
          ? item?.remark
          : "";
      });
    console.log("params====>>>>>", params);

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        getVehicleInspectionDetails();
        handleClick("inspectionHistory");
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  }

  async function updateInspection() {
    setLoader(true);
    let endPoints = Setting.endpoints.updateinspection;
    let TotalChange = 0;
    let approve = 0;
    let reject = 0;
    const params = {
      "UpdateInspectionForm[vehicle_id]": inspectionData.vehicle_id,
      "UpdateInspectionForm[request_id]":
        (userdata?.role === "admin" || userdata?.role === "superadmin") &&
        isEditRow
          ? ""
          : inspectionData?.request_id,

      "UpdateInspectionForm[new_date]":
        (userdata?.role === "admin" || userdata?.role === "superadmin") &&
        isEditRow
          ? moment(datePicker).format("YYYY-MM-DD")
          : isApprove === 1 && from === "modification"
          ? moment(newDate).format("YYYY-MM-DD")
          : inspectionData?.capture_date,
      "UpdateInspectionForm[old_date]": inspectionData?.capture_date,
    };

    if (
      !moment(newDate).isSame(inspectionData.capture_date) &&
      !isEmpty(newDate)
    ) {
      TotalChange++;
      if (isApprove === 1) {
        approve++;
      } else {
        reject++;
      }
    }

    isArray(inspectionTyreList) &&
      !isEmpty(inspectionTyreList) &&
      inspectionTyreList.map((item, index) => {
        const noChanges = !item?.new_tyre_issue_ids;
        const isDeleted = item?.is_deleted;
        if (!noChanges) {
          TotalChange++;
          if (item?.isApprove === 1) {
            approve++;
          } else {
            reject++;
          }
        }

        const tyreSno =
          from === "modification" &&
          item.new_tyre_serial_no &&
          item.tyre_serial_no !== item.new_tyre_serial_no &&
          item?.isApprove === 1
            ? item?.new_tyre_id
            : item?.tyre_id;
        // const tyreId = tyreSerial.find((v) => v?.tyre_serial_no === tyreSno);

        const issueIdchange =
          from === "modification" &&
          item?.new_tyre_issue_ids &&
          JSON.stringify(item?.issue_ids) !==
            JSON.stringify(item?.new_tyre_issue_ids) &&
          item?.isApprove === 1
            ? item?.new_tyre_issue_ids
            : item?.issue_ids;

        const mechanicalissueChange =
          from === "modification" &&
          item?.new_mechanical_issue_ids &&
          JSON.stringify(item?.mechanical_issue_ids) !==
            JSON.stringify(item?.new_mechanical_issue_ids) &&
          item?.isApprove === 1
            ? item?.new_mechanical_issue_ids
            : item?.mechanical_issue_ids;

        const currentnsdChange =
          from === "modification" &&
          item?.new_current_nsd &&
          JSON.stringify(item?.current_nsd) !==
            JSON.stringify(item?.new_current_nsd) &&
          item?.isApprove === 1
            ? item?.new_current_nsd
            : item?.current_nsd;

        const avgNSDChange =
          from === "modification" &&
          item?.new_avg_nsd &&
          item?.avg_nsd?.toString() !== item?.new_avg_nsd?.toString() &&
          item?.isApprove === 1
            ? item?.new_avg_nsd
            : item?.avg_nsd;

        const tyreConditionChange =
          from === "modification" &&
          item?.new_tyre_condition &&
          item?.tyre_condition !== item?.new_tyre_condition &&
          item?.isApprove === 1
            ? item?.new_tyre_condition
            : item?.tyre_condition;

        const tyrePressureChange =
          from === "modification" &&
          item?.new_tyre_air_pressure &&
          item?.tyre_air_pressure !== item?.new_tyre_air_pressure &&
          item?.isApprove === 1
            ? item?.new_tyre_air_pressure
            : item?.tyre_air_pressure;

        const remarkChange =
          from === "modification" &&
          !isUndefined(item?.new_remark) &&
          item?.remark !== item?.new_remark &&
          item?.isApprove === 1
            ? item?.new_remark || ""
            : item?.remark || "";

        const tyreImageChange =
          from === "modification" &&
          item?.new_issue_image &&
          item?.issue_image !== item?.new_issue_image &&
          item?.isApprove === 1
            ? item?.new_issue_image
            : item?.issue_image;

        const url = tyreImageChange;
        const UrlName = url.toString();
        if (
          (!isDeleted && from !== "modification") ||
          from === "modification"
        ) {
          params[`UpdateInspectionForm[id][${index}]`] = item?.id;
          params[`UpdateInspectionForm[tyre_id][${index}]`] =
            from === "modification" && isDeleted ? item?.tyre_id : tyreSno;
          params[`UpdateInspectionForm[tyre_issue][${index}]`] = issueIdchange;
          params[`UpdateInspectionForm[mechanical_issue][${index}]`] =
            mechanicalissueChange;
          params[`UpdateInspectionForm[current_nsd][${index}]`] =
            JSON.stringify(currentnsdChange);
          params[`UpdateInspectionForm[avg_nsd][${index}]`] = avgNSDChange;
          params[`UpdateInspectionForm[condition][${index}]`] =
            tyreConditionChange;
          params[`UpdateInspectionForm[tyre_position][${index}]`] =
            item?.tyre_position;
          params[`UpdateInspectionForm[tyre_air_pressure][${index}]`] =
            tyrePressureChange;

          params[`UpdateInspectionForm[issue_image][${index}]`] = UrlName;
          params[`UpdateInspectionForm[remark][${index}]`] = remarkChange;
        }
      });

    params["UpdateInspectionForm[request_status]"] =
      TotalChange === approve ? 1 : TotalChange === reject ? 2 : 3;

    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        handleClick("exit");
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  }

  const getVehicleInspectionDetails = async () => {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${inspectionData.vehicle_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setvInspectionDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  const UpdateDateCall = async (req) => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.updateDate}?request_id=${inspectionData?.request_id}&status=${req}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setIsApporve(req);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  const UpdateRequest = async (req, id) => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.updaterequest}?request_id=${inspectionData?.request_id}&status=${req}&issue_id=${id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        updateRequestArr(req, id);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  async function getinspectiontyreList(bool) {
    setPageLoad1(true);
    let endpoints = `${Setting.endpoints.inspectiontyrelist}?vehicle_id=${
      inspectionData.vehicle_id
    }&capture_date=${inspectionData.capture_date}&request_id=${
      inspectionData?.request_id || ""
    }`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.data)
        ) {
          // setinspectionTyreListRows(response?.data?.data);
          dispatch(setDummyListArray(response?.data?.data));
          dispatch(setListRows(response?.data?.data));
          setIsRequested(response?.data?.is_requested);
          setNewDate(response?.data?.changed_date);
          dispatch(setInspectionRemarks(response?.data?.global_remark));
          setIsFinal(response?.data?.changed_date_status);
          converter(response?.data?.reading);
        }
      } else {
        toast.error(response?.message);
        dispatch(setDummyListArray([]));
        dispatch(setListRows([]));
      }
      setPageLoad1(false);
    } catch (error) {
      setPageLoad1(false);
      toast.error(error?.toString());
      console.log("error ===>>>", error);
    }
  }

  const getTyreSerial = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreserialno}?vehicle_id=${inspectionData.vehicle_id}&is_inspection=1`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (isArray(response.data) && !isEmpty(response.data)) {
          setTyreSerial(response.data);
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  function updateRequestArr(req, id) {
    const dummy_arr = [...inspectionTyreList];

    dummy_arr.map((item, i) => {
      if (id === item?.id) {
        if (req === 1) {
          item.isApprove = 1;
        } else if (req === 2) {
          item.isApprove = 2;
        }
      }
    });

    dispatch(setListRows(dummy_arr));
  }

  function findAvgNSD() {
    const dummy_arr = [...inspectionTyreList];

    dummy_arr.map((item, index) => {
      let groove = 0;
      let divide = 0;

      item?.current_nsd.map((v, index) => {
        if (!isEmpty(v?.groove1)) {
          groove = Number(v?.groove1) + groove;
          divide++;
        }
        if (!isEmpty(v?.groove2)) {
          groove = Number(v?.groove2) + groove;
          divide++;
        }
        if (!isEmpty(v?.groove3)) {
          groove = Number(v?.groove3) + groove;
          divide++;
        }
        if (!isEmpty(v?.groove4)) {
          groove = Number(v?.groove4) + groove;
          divide++;
        }
      });
      const mainAVG = groove / divide;
      item.avg_nsd = isNaN(mainAVG) ? "" : mainAVG.toFixed(2);
    });

    dispatch(setListRows(dummy_arr));
  }

  function arrChange(value, index, type, nsdI) {
    const dummy_arr = [...inspectionTyreList];
    const dummy_change_arr = [...changesArr];
    let Obj1 = {};
    let Obj2 = {};

    let change = {};

    dummy_arr.map((item, i) => {
      if (i === index) {
        if (type === "condition") {
          item.tyre_condition = value;
        } else if (type === "serial") {
          Obj1 = { ...item };
          dummy_arr.map((v) => {
            if (v?.tyre_serial_no === value?.toString()) {
              Obj2 = { ...v };
              item.tyre_brand = Obj2.tyre_brand;
              item.tyre_pattern_size = Obj2.tyre_pattern_size;
              item.current_nsd = Obj2.current_nsd;
              item.avg_nsd = Obj2.avg_nsd;
              item.tyre_air_pressure = Obj2.tyre_air_pressure;
              item.issue_ids = Obj2.issue_ids;
              // item.mechanical_issue_ids = Obj2.mechanical_issue_ids;
              item.tyre_condition = Obj2.tyre_condition;
              item.issue_image = Obj2.issue_image;
              item.remark = Obj2.remark;
              item.ply_rating = Obj2.ply_rating;
              item.tyre_type = Obj2.tyre_type;
              item.inspected_by = Obj2.inspected_by;
              item.tyre_id = Obj2.tyre_id;

              v.tyre_brand = Obj1.tyre_brand;
              v.tyre_pattern_size = Obj1.tyre_pattern_size;
              v.current_nsd = Obj1.current_nsd;
              v.avg_nsd = Obj1.avg_nsd;
              v.tyre_air_pressure = Obj1.tyre_air_pressure;
              v.issue_ids = Obj1.issue_ids;
              // v.mechanical_issue_ids = Obj1.mechanical_issue_ids;
              v.tyre_condition = Obj1.tyre_condition;
              v.issue_image = Obj1.issue_image;
              v.tyre_serial_no = Obj1.tyre_serial_no;
              v.remark = Obj1.remark;
              v.ply_rating = Obj1.ply_rating;
              v.tyre_type = Obj1.tyre_type;
              v.inspected_by = Obj1.inspected_by;
              v.tyre_id = Obj1.tyre_id;
              change = { ...v };
            }
          });
          item.tyre_serial_no = value;
        } else if (type === "image") {
          item.issue_image = value;
          item.addImage = true;
        } else if (type === "issue") {
          item.issue_ids = value;
        } else if (type === "mechanicalIssue") {
          item.mechanical_issue_ids = value;
        } else if (type === "pressure") {
          item.tyre_air_pressure = value;
        } else if (type === "remarks") {
          item.remark = value;
        }

        item?.current_nsd.map((v, ii) => {
          if (ii === nsdI) {
            if (type === 1) {
              v.groove1 = value;
            } else if (type === 2) {
              v.groove2 = value;
            } else if (type === 3) {
              v.groove3 = value;
            } else if (type === 4) {
              v.groove4 = value;
            }
          }
        });

        if (isArray(dummy_change_arr) && isEmpty(dummy_change_arr)) {
          dummy_change_arr.push(item);
          type === "serial" && dummy_change_arr.push(change);
        } else {
          const asd = dummy_change_arr.find((v) => v?.id === item?.id);
          const asd1 = dummy_change_arr.find((v) => v?.id === change?.id);
          isUndefined(asd) && dummy_change_arr.push(item);
          type === "serial" &&
            isUndefined(asd1) &&
            dummy_change_arr.push(change);
          if (!isUndefined(asd)) {
            const index = dummy_change_arr.indexOf(asd);
            dummy_change_arr[index] = item;
          }
          if (!isUndefined(asd1)) {
            const index1 = dummy_change_arr.indexOf(asd1);
            dummy_change_arr[index1] = change;
          }
        }
      }
    });
    findAvgNSD();
    dispatch(setReduxChangeArray(dummy_change_arr));
    dispatch(setListRows(dummy_arr));
  }

  function SelectTyre(index) {
    const DummyArr = isMechanicle
      ? [...mechanicleRemovalArr]
      : [...tyreRemovalArr];

    DummyArr.map((item, i) => {
      if (i === index) {
        if (item?.selected) {
          if (item?.label === "N/A") {
            DummyArr.map((item1, i) => {
              if (item1?.label !== "N/A") {
                item1.disable = false;
              } else {
                item1.selected = false;
              }
            });
          } else {
            item.selected = false;
          }
        } else {
          if (item?.label === "N/A") {
            DummyArr.map((item1, i) => {
              if (item1?.label !== "N/A") {
                item1.selected = false;
                item1.disable = true;
              } else {
                item1.selected = true;
              }
            });
          } else {
            item.selected = true;
          }
        }
      }
    });
    isMechanicle
      ? setMechanicleRemovalArr(DummyArr)
      : setTyreRemovalArr(DummyArr);
  }

  function handleSelect() {
    const DummyArr = isMechanicle
      ? [...mechanicleRemovalArr]
      : [...tyreRemovalArr];
    const selected = DummyArr?.filter((v) => v.selected === true);
    const array = [];
    if (!isEmpty(selected) && isArray(selected)) {
      selected.map((v) => {
        array.push(v.id.toString());
      });
      arrChange(array, ind, isMechanicle ? "mechanicalIssue" : "issue");
    }
    setOpenCard(false);
    setIsMechanicle(false);
  }

  const getLastInspectionDate = () => {
    const timestamp = inspectionData?.capture_date;
    // const date = new Date(timestamp * 1000);
    const displayDate = moment(timestamp).format("DD-MM-yyyy");
    return inspectionData?.capture_date ? displayDate : "";
  };

  function checkValidation() {
    const dummy_arr = [...inspectionTyreList];
    let valid = true;
    let nsdErr = false;
    let isSame = false;
    let SeqErr = false;

    const lookup = dummy_arr.reduce((a, e) => {
      a[e.tyre_serial_no] = ++a[e.tyre_serial_no] || 0;
      return a;
    }, {});

    const arr = dummy_arr.filter((e) => lookup[e.tyre_serial_no]);

    const indexs = [];
    if (isArray(arr) && arr.length > 0) {
      filter(dummy_arr, (item, index) => {
        arr.map((val) => {
          if (item === val) {
            indexs.push(index);
          }
        });
      });
    }

    let dateInRange = moment(datePicker).isBetween("2000-01-01", new Date());
    if (isNull(datePicker)) {
      valid = false;
      setDateErr(true);
      setDateMsg("Please select Inspection Date");
    } else if (!isNull(datePicker) && !dateInRange) {
      valid = false;
      setDateErr(true);
      setDateMsg("Plaese Enter Valid Date");
    }

    dummy_arr.map((item, index) => {
      item.sameNo = false;
      item.tyrePressureErr = false;
      // item.tyreIssueErr = false;
      // item.mechanicalIssueErr = false;

      // if (isEmpty(item?.issue_ids)) {
      //   valid = false;
      //   item.tyreIssueErr = true;
      // }
      // if (isEmpty(item?.tyre_air_pressure)) {
      //   valid = false;
      //   item.tyrePressureErr = true;
      // } else
      if (isArray(item?.current_nsd) && !isEmpty(item?.current_nsd)) {
        item?.current_nsd.map((v) => {
          if (Math.ceil(v?.groove1) === 0) {
            valid = false;
            nsdErr = true;
          }
          if (!isEmpty(v?.groove2)) {
            if (Math.ceil(v?.groove2) === 0) {
              valid = false;
              nsdErr = true;
            } else if (isEmpty(v?.groove1)) {
              valid = false;
              SeqErr = true;
            }
          }
          if (!isEmpty(v?.groove3)) {
            if (Math.ceil(v?.groove3) === 0) {
              valid = false;
              nsdErr = true;
            } else if (isEmpty(v?.groove1) || isEmpty(v?.groove2)) {
              valid = false;
              SeqErr = true;
            }
          }
          if (!isEmpty(v?.groove4)) {
            if (Math.ceil(v?.groove4) === 0) {
              valid = false;
              nsdErr = true;
            } else if (
              isEmpty(v?.groove1) ||
              isEmpty(v?.groove2) ||
              isEmpty(v?.groove3)
            ) {
              valid = false;
              SeqErr = true;
            }
          }
        });
      }
    });

    isArray(indexs) &&
      !isEmpty(indexs) &&
      indexs.map((ii) => {
        dummy_arr[ii].sameNo = true;
        isSame = true;
      });

    if (!valid) {
      toast.error("Please fill all the required fields");
    }
    if (nsdErr) {
      toast.error("NSD value can't be 0", { toastId: 1 });
    }
    if (SeqErr) {
      toast.error("Please fill NSD value sequentially", { toastId: 2 });
    }
    if (isSame) {
      toast.error("Tyre Serial No. should be Unique");
    }

    dispatch(setListRows(dummy_arr));
    if (valid && !nsdErr && !isSame) {
      setConfirmDialog(true);
    }
  }

  function checkUpdateValidation() {
    let valid = true;

    inspectionTyreList.map((item) => {
      const noChanges = !item?.new_tyre_issue_ids;
      if (
        (!item?.isApprove && !noChanges) ||
        (!isEmpty(newDate) && isApprove === 0)
      ) {
        valid = false;
      }
    });

    if (!valid) {
      toast.error("Please take Action in All Changes");
    }
    if (valid) {
      setConfirmDialog(true);
    }
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadTyreData() {
    const newData = inspectionTyreList?.map((row, index) => {
      const nsdArr = row?.current_nsd;
      let groove1 = 0;
      let groove2 = 0;
      let groove3 = 0;
      let groove4 = 0;
      let count1 = 1;
      let count2 = 1;
      let count3 = 1;
      let count4 = 1;
      isArray(nsdArr) &&
        !isEmpty(nsdArr) &&
        nsdArr.map((v) => {
          if (!isEmpty(v?.groove1)) {
            groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
            count1++;
          }
          if (!isEmpty(v?.groove2)) {
            groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
            count2++;
          }
          if (!isEmpty(v?.groove3)) {
            groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
            count3++;
          }
          if (!isEmpty(v?.groove4)) {
            groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
            count4++;
          }
        });
      const timestamp = row?.capture_date;
      // const date = new Date(timestamp * 1000);
      const displayDate = moment(timestamp).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Tyre Position": row?.tyre_position || "-",
        "Axle Type": row?.axle_type || "-",
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.tyre_pattern_size || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Ply Rating": row?.ply_rating || "-",
        "Current NSD Groove1 (mm)": Number(groove1.toFixed(2)) || "-",
        "Current NSD Groove2 (mm)": Number(groove2.toFixed(2)) || "-",
        "Current NSD Groove3 (mm)": Number(groove3.toFixed(2)) || "-",
        "Current NSD Groove4 (mm)": Number(groove4.toFixed(2)) || "-",
        "Avg. NSD (mm)": row?.avg_nsd || "-",
        "Tyre Pressure (psi)": row?.tyre_air_pressure || "-",
        "Tyre Condition": row?.tyre_condition || "-",
        "Tyre Issue": row?.issues?.toString() || "-",
        "Mechanical Issue": row?.mechanical_issues?.toString() || "-",
        Remarks: row?.remark || "-",
        Images: row?.issue_image || "-",
        "Inspection Date": row?.inspected_at ? displayDate : "-",
        "Inspected By": row?.inspected_by || "-",
      };
      return newObj;
    });

    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Tyre List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Tyre List.xlsx");
  }

  function numberWithCommas(x) {
    let c = x.replaceAll(",", "");

    return c.toString().split(".")[0].length > 3
      ? c
          .toString()
          .substring(0, c.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c.toString().substring(c.toString().split(".")[0].length - 3)
      : c.toString();
  }

  function converter(value) {
    let convert = numberWithCommas(value);
    dispatch(setInspectionReading(convert));
  }

  return (
    <>
      <div className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography fontWeight={"bold"}>
            {inspectionData?.vehicle_no} - Inspection History
          </Typography>
        </Box>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : (
          <div style={{ padding: 20 }}>
            <Table>
              <TableRow>
                <TableCell variant="head">Vehicle No.</TableCell>
                <TableCell>{vInspectionDetails?.vehicle_no || "-"}</TableCell>
                <TableCell variant="head">Route Origin</TableCell>
                <TableCell>{vInspectionDetails?.route_origin || "-"}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Type</TableCell>
                <TableCell>
                  {vInspectionDetails?.vehicle_type_id || "-"}
                </TableCell>
                <TableCell variant="head">Route Destination</TableCell>
                <TableCell>
                  {vInspectionDetails?.route_destination || "-"}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Brand</TableCell>
                <TableCell>
                  {vInspectionDetails?.vehicle_brand_id || "-"}
                </TableCell>
                <TableCell variant="head">Initial Reading </TableCell>
                <TableCell>
                  {vInspectionDetails?.initial_reading || "-"} Km
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">Vehicle Model</TableCell>
                <TableCell>
                  {vInspectionDetails?.vehicle_model || "-"}
                </TableCell>
                <TableCell variant="head">Inspection Date</TableCell>
                <TableCell>{getLastInspectionDate()}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell variant="head">No. of Tyres</TableCell>
                <TableCell>
                  {vInspectionDetails?.added_tyre_count || ""}
                  {" / "}
                  {vInspectionDetails?.no_of_tyres || ""}
                </TableCell>
                <TableCell variant="head">Last Inspected By</TableCell>
                <TableCell>{vInspectionDetails?.inspected_by || ""}</TableCell>
              </TableRow>
              {/* <TableRow>
                <TableCell variant="head">Unresolved Issues</TableCell>
                <TableCell>dummy</TableCell>
                <TableCell variant="head">Remarks</TableCell>
                <TableCell>dummy</TableCell>
              </TableRow> */}
              <TableRow>
                <TableCell variant="head">Inspections (Month)</TableCell>
                <TableCell>
                  {vInspectionDetails?.inspection_monthly || "-"}
                </TableCell>
                <TableCell variant="head">Inspections (Overall)</TableCell>
                <TableCell>
                  {vInspectionDetails?.inspection_overall || "-"}
                </TableCell>
              </TableRow>
            </Table>
          </div>
        )}
      </div>
      <div className={styles.container} style={{ marginTop: 20, padding: 20 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Typography variant="tableTitle">Tyre List</Typography>
          <div>
            {(userdata?.role !== "fleet_owner" ||
              userdata?.role !== "fleet_manager") &&
              from !== "modification" && (
                <Button
                  variant="contained"
                  onClick={() => {
                    setIsEditRow(true);
                  }}
                  disabled={
                    isEditRow ||
                    isRequested === 1 ||
                    !isArray(inspectionTyreList) ||
                    isEmpty(inspectionTyreList)
                  }
                >
                  Edit
                </Button>
              )}
            {from !== "modification" && (
              <Button
                disabled={isOnline ? false : true}
                style={{
                  marginLeft: 10,
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                variant="contained"
                onClick={() => {
                  downloadTyreData();
                  auditSave("Tyre List");
                }}
              >
                Download
              </Button>
            )}
          </div>
        </Grid>
        {pageLoad1 ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(inspectionTyreList) && !isEmpty(inspectionTyreList) ? (
          <div style={{ marginTop: 20 }}>
            <DataGrid
              rows={inspectionTyreList}
              columns={tyreListColumn}
              disableColumnMenu
              hideFooter
              showCellRightBorder
              disableSelectionOnClick
              showColumnRightBorder
              autoHeight={true}
              getRowHeight={() => "auto"}
            />
            {from === "modification" && !isEmpty(newDate) ? (
              <div
                style={{
                  display: "flex",
                  flex: 1,
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: 10,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <CTypography title={"Inspection Date"} />
                  {!moment(newDate).isSame(inspectionData.capture_date) ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginLeft: 10,
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: color.placeholder,
                          borderRadius: 4,
                          padding: "6px 8px",
                          color: color.white,
                        }}
                      >
                        <Typography>
                          {moment(inspectionData.capture_date).format(
                            "DD-MM-yyyy"
                          )}
                        </Typography>
                      </div>
                      <ArrowForward />
                      <div
                        style={{
                          backgroundColor: color.primary,
                          borderRadius: 4,
                          padding: "6px 8px",
                          color: color.white,
                        }}
                      >
                        <Typography>
                          {moment(newDate).format("DD-MM-yyyy")}
                        </Typography>
                      </div>
                    </div>
                  ) : (
                    <Typography style={{ marginLeft: 10 }}>
                      {moment(datePicker).format("DD-MM-yyyy")}
                    </Typography>
                  )}
                </div>
                {isApprove === 1 ||
                (inspectionData?.status !== 0 && isFinal === 1) ? (
                  <div
                    style={{
                      borderRadius: 4,
                      padding: "6px 8px",
                      backgroundColor: color.green,
                    }}
                  >
                    <Typography style={{ color: color.white }}>
                      Approved
                    </Typography>
                  </div>
                ) : isApprove === 2 ||
                  (inspectionData?.status !== 0 && isFinal === 2) ? (
                  <div
                    style={{
                      borderRadius: 4,
                      padding: "6px 8px",
                      backgroundColor: "#ff3333",
                    }}
                  >
                    <Typography style={{ color: color.white }}>
                      Rejected
                    </Typography>
                  </div>
                ) : (userdata?.role === "admin" ||
                    userdata?.role === "superadmin") &&
                  inspectionData?.status === 0 ? (
                  <div>
                    <Button
                      style={{
                        width: 100,
                        backgroundColor: color.green,
                      }}
                      onClick={() => UpdateDateCall(1)}
                    >
                      Approve
                      <Check />
                    </Button>
                    <Button
                      style={{
                        width: 100,
                        backgroundColor: "#ff3333",
                        marginLeft: 20,
                      }}
                      onClick={() => UpdateDateCall(2)}
                    >
                      Reject
                      <Close />
                    </Button>
                  </div>
                ) : null}
              </div>
            ) : (
              <>
                <Grid
                  container
                  alignItems={"center"}
                  style={{ marginBottom: 20, marginTop: 20 }}
                  id="date"
                >
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CTypography required title={"Inspection Date"} />
                  </Grid>
                  {isEditRow ? (
                    <Grid item xs={12} md={9} lg={9}>
                      <FormControl>
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                          <DatePicker
                            minDate={new Date(2000, 0, 1)}
                            showToolbar={false}
                            disableFuture
                            views={["year", "month", "day"]}
                            value={datePicker}
                            onChange={(newValue) => {
                              setDatePicker(newValue);
                              setDateErr(false);
                              setDateMsg("");
                              if (
                                !isNull(datePicker) &&
                                moment(newValue).format("DD-MM-yyy") ===
                                  moment(inspectionData?.capture_date).format(
                                    "DD-MM-yyy"
                                  )
                              ) {
                                setIsDateChange(false);
                              } else {
                                setIsDateChange(true);
                              }
                            }}
                            inputFormat="dd-MM-yyyy"
                            DialogProps={{ className: styles.datePicker }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                inputProps={{
                                  ...params.inputProps,
                                  placeholder: "DD-MM-YYYY",
                                }}
                                sx={{ svg: { color: color.primary } }}
                                error={dateErr}
                                helperText={dateErr ? dateMsg : ""}
                              />
                            )}
                          />
                        </LocalizationProvider>
                        {/* {dateErr ? (
                          <FormHelperText>{dateMsg}</FormHelperText>
                        ) : null} */}
                      </FormControl>
                    </Grid>
                  ) : (
                    <Grid item xs={12} md={9} lg={9}>
                      <Typography>
                        {moment(datePicker).format("DD-MM-yyyy")}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CTypography title={"Inspection Reading"} />
                  </Grid>
                  <Grid item xs={12} md={9} lg={9}>
                    <Typography>
                      {!isEmpty(reading) ? reading : "-"}
                      {!isEmpty(reading) && " Km"}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid
                  container
                  alignItems={"center"}
                  style={{ marginBottom: 20, marginTop: 20 }}
                >
                  <Grid item xs={12} sm={12} md={2} lg={2}>
                    <CTypography title={"Remarks"} />
                  </Grid>
                  <Grid item xs={12} md={9} lg={9}>
                    <Typography>{remarks || "-"}</Typography>
                  </Grid>
                </Grid>
              </>
            )}
            {(isEditRow ||
              (from === "modification" &&
                inspectionData?.status === 0 &&
                (userdata?.role === "admin" ||
                  userdata?.role === "superadmin"))) && (
              <Grid
                container
                alignItems={"center"}
                justifyContent={"center"}
                style={{ marginTop: 20 }}
              >
                <Button
                  style={{
                    width: 130,
                  }}
                  variant="contained"
                  onClick={() => {
                    if (from === "modification") {
                      checkUpdateValidation();
                    } else {
                      checkValidation();
                    }
                  }}
                  disabled={
                    loader ||
                    (from !== "modification" &&
                      isEmpty(changesArr) &&
                      !isDateChange) ||
                    !isOnline
                  }
                >
                  Save
                </Button>
                <Button
                  variant="contained"
                  // disabled={!isDateChange ? false : true}
                  style={{
                    backgroundColor: color.secondary,
                    marginLeft: 10,
                    width: 130,
                  }}
                  onClick={() => {
                    if (from === "modification") {
                      handleClick("exit");
                    } else if (
                      from !== "modification" &&
                      isEmpty(changesArr) &&
                      isDateChange
                    ) {
                      handleClick("inspectionHistory");
                    } else {
                      setIsEditRow(false);
                    }
                  }}
                >
                  Cancel
                </Button>
              </Grid>
            )}
          </div>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
      <Modal
        className={styles.modal}
        onClose={() => {
          openModal(false);
        }}
        open={modal}
      >
        <div
          style={{
            maxWidth: 650,
            maxHeight: 650,
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList cols={1} style={{ overflowY: "scroll", maxHeight: 550 }}>
            {isArray(imgURL) &&
              !isEmpty(imgURL) &&
              imgURL.map((item, index) => {
                return (
                  <ImageListItem key={index}>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                      }}
                      src={`${item}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </ImageListItem>
                );
              })}
          </ImageList>
        </div>
      </Modal>
      <Modal
        onClose={() => {
          setOpenCard(false);
          setIsMechanicle(false);
        }}
        open={openCard}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            maxWidth: 650,
            maxHeight: 650,
            backgroundColor: "white",
            padding: 20,
            borderRadius: 4,
          }}
        >
          <ImageList
            cols={md ? 2 : 3}
            gap={9}
            style={{ overflowY: "scroll", maxHeight: 550 }}
          >
            {arrData.map((item, index) => {
              return (
                <ImageListItem
                  key={index}
                  style={{
                    width: 200,
                    height: 150,
                    border: item?.selected
                      ? `1px solid ${color.primary}`
                      : item?.disable
                      ? `1px solid ${color.disable}`
                      : "",
                  }}
                  onClick={() => {
                    if (item?.disable) {
                      return null;
                    } else {
                      SelectTyre(index);
                    }
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      border: `1px solid ${color.bordercolor}`,
                    }}
                  >
                    <img
                      style={{ height: 118, width: 200 }}
                      src={`${item.image}?w=100&fit=crop&auto=format`}
                      alt=""
                    />
                  </div>
                  <ImageListItemBar
                    position="below"
                    title={item?.label}
                    style={{
                      textAlign: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      backgroundColor: item?.selected
                        ? color.primary
                        : item?.disable
                        ? color?.disable
                        : "",
                      color: item?.selected
                        ? color.white
                        : item?.disable
                        ? color.white
                        : "",
                    }}
                  />
                </ImageListItem>
              );
            })}
          </ImageList>
          <div style={{ marginTop: 20 }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.primary }}
              fullWidth
              onClick={handleSelect}
            >
              Select
            </Button>
          </div>
        </div>
      </Modal>
      <ConfirmDialog
        title={`Are you sure you want to Edit Inspection?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            if (
              (isEditRow &&
                (userdata?.role === "admin" ||
                  userdata?.role === "superadmin")) ||
              from === "modification"
            ) {
              updateInspection();
            } else {
              sendRequest();
            }
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={loader}
      />
    </>
  );
}
