import { Search, SearchOutlined } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { isArray, isEmpty, isObject } from "lodash";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import InfoIcon from "@mui/icons-material/Info";
import { color } from "../../../Config/theme";
import { getApiData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import { DataGrid } from "@mui/x-data-grid";
import * as XLSX from "xlsx/xlsx.mjs";
import useStyles from "./styles";
import { ResetIcon } from "../../Icon/TableToggle";
import Images from "../../../Config/Images";
import { isTablet } from "react-device-detect";

export default function TabAuditDetails(props) {
  const { auditData } = props;
  const styles = useStyles();
  const { token } = useSelector((state) => state.auth);

  const [searchFilter, setSearchFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [record, setRecord] = useState(10);
  const [pageNo, setPageNo] = useState(1);
  const [auditList, setAuditList] = useState({});
  const [reportLoader, setReportLoader] = useState(false);

  const auditColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "activity",
      headerName: "Activity",
      flex: 1,
    },
    {
      field: "audit_date",
      headerName: "Audit Date",
      flex: 1,
      renderCell: (params) => {
        const Date = params?.row?.audit_date;
        const displayDate = moment(Date).format("DD-MM-yyyy");
        return <Typography>{displayDate || "-"}</Typography>;
      },
    },
  ];

  useEffect(() => {
    getAuditDetail(false);
  }, [record, pageNo]);

  useEffect(() => {
    isEmpty(searchFilter) && getAuditDetail(false);
  }, [searchFilter]);

  async function getAuditDetail(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    let date = !isEmpty(auditData?.audit_date)
      ? moment(auditData?.audit_date).format("YYYY-MM-DD")
      : "";
    let endpoints = `${Setting.endpoints.auditDetails}?user_id=${
      auditData?.user_id
    }&audit_date=${date}&page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response?.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadAuditData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setAuditList(listObj);
          }
        }
      } else {
        toast.error(response?.message, { toastId: 17 });
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      toast.error(error.toString(), { toastId: 17 });
      console.log("error ===>>>", error);
      setPageLoad(false);
      setReportLoader(false);
    }
  }

  function downloadAuditData(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        Activity: item?.activity,
        "Audit Date": moment(item?.capture_date).format("DD-MM-yyyy") || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Audit Details");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Audit Details.xlsx");
  }

  function resetFilterData() {
    setSearchFilter("");
  }

  const getDate = (item) => {
    const Date = item?.row?.audit_date;
    const displayDate = moment(Date).format("DD-MM-yyyy");
    return <Typography>{displayDate || "-"}</Typography>;
  };

  const dataGridRender = useMemo(() => {
    return (
      <DataGrid
        rows={auditList?.data}
        columns={auditColumn}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
        autoHeight={true}
        getRowHeight={() => "auto"}
      />
    );
  }, [auditList]);

  return (
    <>
      {isTablet ? (
        <div>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ height: 40 }}
          >
            <Grid item>
              <Typography variant="tableTitle">Audit Details</Typography>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => getAuditDetail(true, 1)}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>

          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 14 }}
            wrap="nowrap"
          >
            <Grid style={{ display: "flex" }} alignItems={"center"} gap={1}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value) && getAuditDetail(true);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    if (!isEmpty(searchFilter)) {
                      getAuditDetail(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Name"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
              <div style={{ display: "flex", wrap: "nowrap" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (!isEmpty(searchFilter)) {
                      getAuditDetail(false);
                    }
                  }}
                >
                  <SearchOutlined />
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  onClick={resetFilterData}
                  disabled={isEmpty(searchFilter) ? true : false}
                >
                  <ResetIcon />
                </Button>
              </div>
            </Grid>
            <Grid
              item
              lg={1.6}
              style={{
                marginLeft: 20,
                display: "flex",
                alignItems: "center",
                alignSelf: "flex-end",
              }}
            >
              <Select
                fullWidth
                value={record}
                onChange={(v) => {
                  setRecord(v.target.value);
                }}
                style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(auditList?.data) && !isEmpty(auditList?.data) ? (
            <div style={{ marginTop: 20 }}>
              {dataGridRender}
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(auditList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    setPageNo(e);
                  }}
                />
              </div>
            </div>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Grid
            container
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            gap={1}
            style={{ height: 40 }}
          >
            <Typography variant="tableTitle">Audit Details</Typography>
          </Grid>
          <Grid
            item
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 1,
              wrap: "nowrap",
            }}
          >
            <Select
              value={record}
              onChange={(v) => {
                setRecord(v.target.value);
              }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => getAuditDetail(true, 1)}
            >
              {reportLoader ? (
                <CircularProgress style={{ color: color.white }} size={24} />
              ) : (
                <img src={Images.Mdownload} alt="download" />
              )}
            </Button>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ marginTop: 14 }}
            wrap="nowrap"
            gap={1}
          >
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={searchFilter}
              onChange={(v) => {
                isEmpty(v.target.value) && getAuditDetail(true);
                setSearchFilter(v?.target?.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  if (!isEmpty(searchFilter)) {
                    getAuditDetail(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip title="Search by Name" placement="bottom" arrow>
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
            <Grid style={{ display: "flex", wrap: "nowrap" }} gap={1}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(searchFilter)) {
                    getAuditDetail(false);
                  }
                }}
              >
                <SearchOutlined />
              </Button>
              <Button
                variant="contained"
                onClick={resetFilterData}
                disabled={isEmpty(searchFilter) ? true : false}
              >
                <ResetIcon />
              </Button>
            </Grid>
          </Grid>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(auditList?.data) && !isEmpty(auditList?.data) ? (
            <>
              {auditList?.data?.map((item, index) => {
                return (
                  <div style={{ position: "relative" }}>
                    <Grid
                      container
                      style={{
                        border: `1px solid ${color.bordercolor}`,
                        boxShadow: color.shadow,
                        overflow: "hidden",
                        marginTop: 20,
                      }}
                    >
                      <Table className={styles.CustomtableMobile}>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Activity</TableCell>
                            <TableCell variant="head">Audit Date</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              variant="body"
                              style={{ paddingBottom: 5 }}
                            >
                              {item?.activity || "-"}
                            </TableCell>
                            <TableCell style={{ paddingBottom: 5 }}>
                              {getDate(item)}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </Grid>
                  </div>
                );
              })}
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(auditList?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    setPageNo(e);
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}
