import React, { useState, useEffect } from "react";
import {
  CardActionArea,
  CircularProgress,
  Divider,
  Grid,
  ListItemIcon,
  ListItemText,
  Pagination,
  Typography,
} from "@mui/material";
import useStyles from "./styles.js";
import { Setting } from "../../Utils/Setting";
import { color } from "../../Config/theme";
import { Box } from "@mui/system";
import { getApiData } from "../../Utils/APIHelper.js";
import authActions from "../../Redux/reducers/auth/actions";
import { useDispatch, useSelector } from "react-redux";
import { isArray, isEmpty, isObject } from "lodash";
import Images from "../../Config/Images.js";
import { isMobile, isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { ContentPasteSearch } from "@mui/icons-material";

export default function Notification() {
  const navigate = useNavigate();
  const styles = useStyles();
  const { token } = useSelector((state) => state.auth);
  const [notificationList, setNotificationList] = useState({});
  const dispatch = useDispatch();
  const { setPermissionData } = authActions;
  const [pageNo, setPageNo] = useState(1);
  const [pageLoad, setPageLoad] = useState(true);

  useEffect(() => {
    document.title = Setting.page_name.Notification;
  }, []);

  useEffect(() => {
    getNotificationList();
  }, [pageNo]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [pageLoad, pageNo]);

  const getNotificationList = async () => {
    setPageLoad(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.notificationlist}?page=${pageNo}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response?.data) &&
          isArray(response?.data?.rows) &&
          !isEmpty(response?.data?.rows)
        ) {
          setNotificationList(response?.data);
        } else {
          setNotificationList({});
        }
        rolePermission();
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      console.log("error ===>>>", error);
    }
  };

  const rolePermission = async () => {
    try {
      const response = await getApiData(
        Setting.endpoints.systemcommon,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        dispatch(setPermissionData(response?.data));
      }
    } catch (error) {
      console.log("error ===>>>", error);
    }
  };

  return (
    <Box
      className={styles.card}
      style={{
        padding: !isTablet && isMobile ? "10px 10px 0px" : "15px 15px 0px",
      }}
    >
      <div className={styles.container}>
        <Box
          style={{
            backgroundColor: color.primary,
            color: color.white,
            padding: "18px 20px",
          }}
        >
          <Typography fontWeight={"bold"} id="jump_to_me">
            {"Notification"}
          </Typography>
        </Box>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(notificationList?.rows) &&
          !isEmpty(notificationList?.rows) ? (
          <div className={styles.card} style={{ padding: 10 }}>
            {notificationList?.rows?.map((v, i) => {
              return isMobile ? (
                <>
                  <CardActionArea
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      if (v?.type === "scrap_summary") {
                        navigate("/dashboard/ScrapAnalysis", {
                          state: {
                            date: v?.notification_date,
                            from: "notification",
                          },
                        });
                      } else if (v?.type === "inspection_summary") {
                        navigate("/dashboard/Inspection", {
                          state: {
                            date: v?.notification_date,
                            from: "notificationS",
                          },
                        });
                      } else if (
                        v?.type === "inspection_update" ||
                        v?.type === "modification_request"
                      ) {
                        navigate("/dashboard/Inspection", {
                          state: {
                            from: "notificationU",
                          },
                        });
                      } else if (v?.type === "fleet_allocation") {
                        navigate("/dashboard/FleetManagement", {
                          state: {
                            date: v?.notification_date,
                            from: "notification",
                          },
                        });
                      } else if (v?.type === "resolution_summary") {
                        navigate("/dashboard/Inspection");
                      } else if (v?.type === "inspection_reminder") {
                        navigate("/dashboard", {
                          state: {
                            from: "notification",
                          },
                        });
                      } else if (
                        v?.type === "tyre_removal_request" ||
                        v?.type === "removal_request_update"
                      ) {
                        navigate("/dashboard/Inspection", {
                          state: {
                            from: "notificationR",
                          },
                        });
                      } else {
                        return null;
                      }
                    }}
                  >
                    <div
                      style={{
                        cursor: "pointer",
                        display: "flex",
                        flex: 1,
                        justifyContent: "space-between",
                      }}
                    >
                      <Grid container wrap="nowrap" gap={1}>
                        <Grid
                          item
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <ListItemIcon>
                            {v?.type === "inspection_update" ||
                            v?.type === "inspection_summary" ||
                            v?.type === "resolution_summary" ||
                            v?.type === "inspection_reminder" ||
                            v?.type === "modification_request" ||
                            v?.type === "removal_request_update" ? (
                              <ContentPasteSearch
                                style={{
                                  width: 33,
                                  height: 35,
                                  backgroundColor: "#2b3643",
                                  padding: 6,
                                  borderRadius: 4,
                                  margin: "0 auto",
                                  color: "#a3a6a9",
                                }}
                              />
                            ) : (
                              <img
                                src={
                                  v?.type === "scrap_summary" ||
                                  v?.type === "scrap_status_change"
                                    ? Images.scrapw
                                    : v?.type === "fleet_allocation" ||
                                      v?.type === "new_tyre_request"
                                    ? Images.delivery
                                    : v?.type === "tyre_removal_request" ||
                                      v?.type === "claim_status_change"
                                    ? Images.inventorylg
                                    : null
                                }
                                alt=""
                                style={{
                                  width: 35,
                                  height: 35,
                                  backgroundColor: "#2b3643",
                                  padding: 5,
                                  borderRadius: 4,
                                  margin: "0 auto",
                                }}
                              />
                            )}
                          </ListItemIcon>
                        </Grid>

                        <Grid item>
                          <Grid>
                            <ListItemText
                              style={{
                                whiteSpace: "pre-wrap",
                                fontSize: 18,
                              }}
                            >
                              {v?.title}
                            </ListItemText>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                whiteSpace: "pre-wrap",
                                fontSize: 14,
                              }}
                            >
                              {v?.message}
                            </Typography>
                          </Grid>
                          <Grid>
                            <Typography
                              style={{
                                marginBottom: 10,
                                whiteSpace: "pre-wrap",
                                fontSize: 12,
                                color: color.placeholder,
                              }}
                            >
                              {v?.format_time}
                            </Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      {i < notificationList?.rows?.length - 1 ? (
                        <Divider variant="fullWidth" />
                      ) : (
                        ""
                      )}
                    </div>
                  </CardActionArea>
                </>
              ) : (
                <>
                  <CardActionArea
                    style={{
                      cursor: "pointer",
                      display: "flex",
                      flex: 1,
                      justifyContent: "space-between",
                    }}
                    onClick={() => {
                      if (v?.type === "scrap_summary") {
                        navigate("/dashboard/ScrapAnalysis", {
                          state: {
                            date: v?.notification_date,
                            from: "notification",
                          },
                        });
                      } else if (v?.type === "inspection_summary") {
                        navigate("/dashboard/Inspection", {
                          state: {
                            date: v?.notification_date,
                            from: "notificationS",
                          },
                        });
                      } else if (
                        v?.type === "inspection_update" ||
                        v?.type === "modification_request"
                      ) {
                        navigate("/dashboard/Inspection", {
                          state: {
                            from: "notificationU",
                          },
                        });
                      } else if (v?.type === "fleet_allocation") {
                        navigate("/dashboard/FleetManagement", {
                          state: {
                            date: v?.notification_date,
                            from: "notification",
                          },
                        });
                      } else if (v?.type === "resolution_summary") {
                        navigate("/dashboard/Inspection");
                      } else if (v?.type === "inspection_reminder") {
                        navigate("/dashboard", {
                          state: {
                            from: "notification",
                          },
                        });
                      } else if (
                        v?.type === "tyre_removal_request" ||
                        v?.type === "removal_request_update"
                      ) {
                        navigate("/dashboard/Inspection", {
                          state: {
                            from: "notificationR",
                          },
                        });
                      } else {
                        return null;
                      }
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <ListItemIcon>
                        {v?.type === "inspection_update" ||
                        v?.type === "inspection_summary" ||
                        v?.type === "resolution_summary" ||
                        v?.type === "inspection_reminder" ||
                        v?.type === "modification_request" ||
                        v?.type === "removal_request_update" ? (
                          <ContentPasteSearch
                            style={{
                              width: 33,
                              height: 35,
                              backgroundColor: "#2b3643",
                              padding: 6,
                              borderRadius: 4,
                              margin: "0 auto",
                              color: "#a3a6a9",
                            }}
                          />
                        ) : (
                          <img
                            src={
                              v?.type === "scrap_summary" ||
                              v?.type === "scrap_status_change"
                                ? Images.scrap
                                : v?.type === "fleet_allocation" ||
                                  v?.type === "new_tyre_request"
                                ? Images.delivery
                                : v?.type === "tyre_removal_request" ||
                                  v?.type === "claim_status_change"
                                ? Images.inventorylg
                                : null
                            }
                            alt=""
                            style={{
                              width: 33,
                              height: 35,
                              backgroundColor: "#2b3643",
                              padding: 6,
                              borderRadius: 4,
                              margin: "0 auto",
                            }}
                          />
                        )}
                      </ListItemIcon>
                      <div>
                        <ListItemText
                          style={{
                            whiteSpace: "pre-wrap",
                            fontSize: 18,
                          }}
                        >
                          {v?.title}
                        </ListItemText>
                        <Typography
                          style={{
                            whiteSpace: "pre-wrap",
                            fontSize: 14,
                          }}
                        >
                          {v?.message}
                        </Typography>
                      </div>
                    </div>
                    <Typography
                      style={{
                        marginTop: 10,
                        whiteSpace: "pre-wrap",
                        fontSize: 12,
                        color: color.placeholder,
                      }}
                    >
                      {v?.format_time}
                    </Typography>
                  </CardActionArea>
                  {i < notificationList?.rows?.length - 1 ? (
                    <Divider variant="fullWidth" />
                  ) : (
                    ""
                  )}
                </>
              );
            })}
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(notificationList?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={isMobile ? 1 : 2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  setPageNo(e);
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
    </Box>
  );
}
