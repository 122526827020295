import React, { useEffect, useRef, useState } from "react";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles.js";
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Pagination,
  Select,
  Tab,
  Tabs,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { Edit, FilterAlt, Search } from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { isArray, isEmpty, isObject } from "lodash";
import moment from "moment";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ConfirmDialog from "../../ConfirmDialog";
import { tyre_condition } from "../../../Config/Static_Data";
import InfoIcon from "@mui/icons-material/Info";
import Images from "../../../Config/Images";
import TyreIssue from "../../Modal/TyreIssue";

export default function TyreManagement(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    vehicleData,
  } = props;
  const [tabValue, setTabValue] = useState(0);
  // const [filter, setFilter] = useState("");
  const [pageLoad, setPageLoad] = useState(true);
  const [pageLoad1, setPageLoad1] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const [tyreListRows, setTyreListRows] = useState({});
  const [selectedFile, setSlectedFile] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);

  const [brandList, setbrandList] = useState(false);
  const [typeList, settypeList] = useState(false);
  const [conditionList, setconditionList] = useState(false);
  const [plyRating, setplyRating] = useState(false);
  const [visible, setVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  const [healthData, setHealthData] = useState([]);
  const [issueCount, setissueCount] = useState({});
  const [from, setFrom] = useState("");
  const [index, setIndex] = useState(0);
  const [side, setSide] = useState("");

  const [tyreHealthLeft, setTyreHealthLeft] = useState([]);
  const [tyreHealthRight, setTyreHealthRight] = useState([]);
  const isDisabled = isArray(tyreListRows?.data) && isEmpty(tyreListRows?.data);

  const styles = useStyles();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const { token, dropDownData, userdata } = useSelector((state) => state.auth);

  // intialRender used for stop multiple time apiCall in
  const initialRender = useRef(true);

  useEffect(() => {
    const dummyArr = [...healthData];
    const leftArr = [];
    const rightArr = [];

    isArray(dummyArr) &&
      dummyArr?.map((item, index) => {
        let a = [
          { tyre_position: "121O" },
          { tyre_position: "211M" },
          { tyre_position: "111I" },
        ];
        let b = [
          { tyre_position: "112O" },
          { tyre_position: "121M" },
          { tyre_position: "111I" },
        ];

        item?.tyres?.find((v) => {
          if (v?.tyre_position[0] === "C") {
            if (v?.tyre_position[3] === "I") {
              a.splice(2, 1, v);
            } else if (v?.tyre_position[3] === "M") {
              a.splice(1, 1, v);
            } else if (v?.tyre_position[3] === "O") {
              a.splice(0, 1, v);
            }
          } else if (v?.tyre_position[0] === "D") {
            if (v?.tyre_position[3] === "I") {
              b.splice(2, 1, v);
            } else if (v?.tyre_position[3] === "M") {
              b.splice(1, 1, v);
            } else if (v?.tyre_position[3] === "O") {
              b.splice(0, 1, v);
            }
          }
        });
        leftArr.push(a);
        rightArr.push(b);
      });

    setTyreHealthLeft(leftArr);
    setTyreHealthRight(rightArr);
  }, [healthData]);

  useEffect(() => {
    if (tabValue === 1) {
      setPageLoad1(true);
      getTyreHealth();
      getTyreIssueCount();
    }
  }, [tabValue]);

  const tyreManagementColumns = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "action",
      headerName: "Action",
      width: 70,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const active = userdata?.role !== "fleet_owner";
        return (
          <Tooltip title={active ? "Edit" : ""} placement="right" arrow>
            <Edit
              style={{
                color: active ? color.primary : color.disable,
                cursor: "pointer",
              }}
              onClick={() => {
                if (active) {
                  handleClick("addTyre", params?.row);
                }
              }}
            />
          </Tooltip>
        );
      },
    },
    {
      field: "tyre_serial_no",
      headerName: "Tyre Serial No.",
      width: 220,
      renderCell: (params) => {
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            onClick={() => {
              handleClick("tyreDetails", params?.row);
            }}
          >
            <Typography
              style={{
                whiteSpace: "pre-wrap",
                color: color.primary,
                fontWeight: "bold",
              }}
            >
              {params?.row?.tyre_serial_no}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "tyre_position",
      headerName: "Tyre Position",
      width: 220,
    },
    {
      field: "axle_type_id",
      headerName: "Axle Type",
      width: 220,
      renderCell: (params) => {
        let array = params?.row?.axle_type_id;
        let res = dropDownData?.axle_type?.find((item) => array == item?.id);
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "tyre_condition",
      headerName: "Tyre Condition",
      width: 220,
      renderCell: (params) => {
        return (
          <div
            style={{
              backgroundColor:
                params?.row?.tyre_condition === "Average"
                  ? "#FFFDD9"
                  : params?.row?.tyre_condition === "Good"
                  ? "#D9FFE4"
                  : params?.row?.tyre_condition === "Bad"
                  ? "#FFD9D9"
                  : "",
              padding: 8,
              borderRadius: 4,
            }}
          >
            <Typography>{params?.row?.tyre_condition || "-"}</Typography>
          </div>
        );
      },
    },
    { field: "tyre_type", headerName: "Tyre Type", width: 140 },
    {
      field: "tyre_brand_id",
      headerName: "Tyre Brand",
      width: 140,
      renderCell: (params) => {
        let array = params?.row?.tyre_brand_id;
        let res = dropDownData?.tyre_brand?.find((item) => array == item?.id);
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "tyre_pattern_size",
      headerName: "Item Description",
      width: 300,
      renderCell: (params) => {
        let text =
          params?.row?.is_new_tyre === 1
            ? params?.row?.new_tyre_name
            : params?.row?.tyre_pattern_size;

        return <Typography>{text}</Typography>;
      },
    },
    {
      field: "tyre_composition",
      headerName: "Tyre Composition",
      width: 200,
    },
    {
      field: "tread_pattern",
      headerName: "Tread Pattern",
      width: 180,
    },
    {
      field: "ply_rating_id",
      headerName: "Ply Rating",
      width: 180,
      renderCell: (params) => {
        let array = params?.row?.ply_rating_id;
        let res = dropDownData?.ply_rating?.find((item) => array == item?.id);
        return <Typography style={{ fontSize: 14 }}>{res?.label}</Typography>;
      },
    },
    {
      field: "init_km",
      headerName: "Initial Reading",
      width: 180,
      renderCell: (params) => {
        return <Typography>{params?.row?.init_km || "-"} Km</Typography>;
      },
    },
    {
      field: "original_nsd",
      headerName: "Original NSD",
      width: 140,
      renderCell: (params) => {
        return (
          <Typography>{`${params?.row?.original_nsd} mm` || "-"}</Typography>
        );
      },
    },
    {
      field: "Current NSD",
      headerName: "Initial NSD",
      width: 220,
      renderCell: (params) => {
        const nsdArr = params?.row?.current_nsd;
        let groove1 = 0;
        let groove2 = 0;
        let groove3 = 0;
        let groove4 = 0;
        let count1 = 1;
        let count2 = 1;
        let count3 = 1;
        let count4 = 1;
        isArray(nsdArr) &&
          !isEmpty(nsdArr) &&
          nsdArr.map((v) => {
            if (!isEmpty(v?.groove1)) {
              groove1 = (Number(groove1) + Number(v?.groove1)) / count1;
              count1++;
            }
            if (!isEmpty(v?.groove2)) {
              groove2 = (Number(groove2) + Number(v?.groove2)) / count2;
              count2++;
            }
            if (!isEmpty(v?.groove3)) {
              groove3 = (Number(groove3) + Number(v?.groove3)) / count3;
              count3++;
            }
            if (!isEmpty(v?.groove4)) {
              groove4 = (Number(groove4) + Number(v?.groove4)) / count4;
              count4++;
            }
          });
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${groove1 !== 0 ? groove1.toFixed(2) : " - "}/${
              groove2 !== 0 ? groove2.toFixed(2) : " - "
            }/${groove3 !== 0 ? groove3.toFixed(2) : " - "}/${
              groove4 !== 0 ? groove4.toFixed(2) : " - "
            } mm`}
          </Typography>
        );
      },
    },
    {
      field: "avg_nsd",
      headerName: "Avg. Initial NSD",
      width: 180,
      renderCell: (params) => {
        return (
          <Typography style={{ fontSize: 14 }}>
            {`${params?.row?.avg_nsd} mm` || "-"}
          </Typography>
        );
      },
    },
    {
      field: "tyre_air_pressure",
      headerName: "Initial Air Pressure",
      width: 200,
      renderCell: (params) => {
        return (
          <Typography style={{ fontSize: 14 }}>
            {params?.row?.tyre_air_pressure}
            {params?.row?.tyre_air_pressure ? " psi" : "-"}
          </Typography>
        );
      },
    },

    // {
    //   field: "Current Km",
    //   headerName: "Current Km",
    //   width: 200,
    // },

    {
      field: "purchase_cost",
      headerName: "Purchase Cost",
      width: 180,
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.purchase_cost ? "₹ " : ""}
            {params?.row?.purchase_cost || "-"}
          </Typography>
        );
      },
    },
    {
      field: "casing_value",
      headerName: "Casing Value",
      width: 180,
      renderCell: (params) => {
        return (
          <Typography>
            {params?.row?.casing_value ? "₹ " : ""}
            {params?.row?.casing_value || "-"}
          </Typography>
        );
      },
    },
    {
      field: "purchased_from",
      headerName: "Purchased From",
      width: 180,
      renderCell: (params) => {
        return <Typography>{params?.row?.purchased_from || "-"}</Typography>;
      },
    },
    {
      field: "capture_date",
      headerName: "Added On",
      width: 180,
      renderCell: (params) => {
        const timestamp = params?.row?.capture_date;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.capture_date ? displayDate : "-"}
          </div>
        );
      },
    },
    {
      field: "fitment_date",
      headerName: "Fitment Date",
      width: 180,
      renderCell: (params) => {
        const timestamp = params?.row?.fitment_date;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
            onClick={() => {
              console.log(params?.row);
            }}
          >
            {params?.row?.fitment_date ? displayDate : "-"}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setPageLoad(true);
    getTyreList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      filter === "" && isEmpty(searchFilter) && getTyreList(false);
    }
  }, [filter, searchFilter]);

  async function getTyreList(bool) {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreList}?vehicle_id=${
          vehicleData?.id
        }&filter=${filter}&per_page=${record}&search=${
          bool ? "" : searchFilter
        }&page=${pageNo}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          const listObj = {
            pagination: response?.data?.pagination,
            data: response?.data?.rows || [],
          };
          setTyreListRows(listObj);
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getTyreHealth(bool) {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreHealth}?vehicle_id=${vehicleData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isArray(response?.data) && !isEmpty(response.data)) {
          setHealthData(response?.data);
        } else {
          setHealthData([]);
        }
      } else {
        toast.error(response?.message);
      }
      setPageLoad1(false);
    } catch (error) {
      setPageLoad1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getTyreIssueCount() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.issueCount}?vehicle_id=${vehicleData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (isObject(response?.data) && !isEmpty(response.data)) {
          setissueCount(response?.data);
        } else {
          setissueCount({});
        }
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.importtyre;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          getTyreList(false);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };

  function clearData() {
    setSlectedFile(null);
  }

  function resetFilterData() {
    handleFilter("");
    setSearchFilter("");
    handlePageNo(1);
  }

  const handleModal = (item, type, i, side) => {
    if (type === "open") {
      setModalData(item);
      setVisible(true);
      setFrom("");
    } else if (type === "Axle") {
      setFrom("Axle");
      setModalData(item);
      setIndex(i + 1);
      setSide(side);
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  return (
    <div className={styles.container}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Typography variant="tableTitle">Tyre Management</Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          style={{ display: "flex", justifyContent: sm ? "unset" : "flex-end" }}
        >
          {/* <Button variant="contained" component="label">
            <input
              type="file"
              onChange={(e) => {
                setSlectedFile(e.target.files[0]);
                setConfirmDialog(true);
              }}
              onClick={(event) => {
                event.target.value = null;
              }}
              accept=".csv"
              hidden
            />
            Upload CSV
          </Button> */}
          <Button
            style={{
              marginLeft: 10,
              color:
                Number(vehicleData?.no_of_tyres) -
                  Number(tyreListRows?.pagination?.totalCount) ===
                0
                  ? "#000"
                  : color.white,
            }}
            variant="contained"
            onClick={() => {
              handleClick("addTyre", "");
            }}
            disabled={
              Number(vehicleData?.no_of_tyres) -
                Number(tyreListRows?.pagination?.totalCount) ===
              0
            }
          >
            Add Tyre
          </Button>
          <Button
            variant="contained"
            style={{
              marginLeft: 10,
              backgroundColor: isDisabled
                ? "rgba(0, 0, 0, 0.12)"
                : color.secondary,
              color: isDisabled ? "#000" : color.white,
            }}
            onClick={() => {
              handleClick(
                "removeTyre",
                tyreListRows?.pagination?.fitment_reading
              );
            }}
            disabled={isDisabled}
          >
            Remove Tyre
          </Button>
        </Grid>
      </Grid>
      <Box sx={{ borderBottom: 1, borderColor: "#BBBBBB" }}>
        <Tabs
          value={tabValue}
          onChange={(v, b) => {
            handlePageNo(1);
            handleRecord(10);
            setTabValue(b);
            handleFilter("");
          }}
          variant="scrollable"
        >
          <Tab className={styles.tabBtn} label="Tyre List" />
          <Tab className={styles.tabBtn} label="Tyre & Axle Health" />
        </Tabs>
      </Box>
      {tabValue === 0 ? (
        <div>
          <Grid container flexDirection="row" style={{ marginTop: 10 }}>
            <div
              style={{
                backgroundColor: "#D9F8FF",
                padding: 6,
                borderRadius: 4,
              }}
            >
              <Typography>{`Total Tyres: ${vehicleData?.no_of_tyres}`}</Typography>
            </div>
            <div
              style={{
                backgroundColor: "#D9FFE4",
                padding: 6,
                marginLeft: 10,
                borderRadius: 4,
              }}
            >
              <Typography>{`Added Tyres: ${tyreListRows?.pagination?.totalCount}`}</Typography>
            </div>
            <div
              style={{
                backgroundColor: "#FFD9D9",
                padding: 6,
                marginLeft: 10,
                borderRadius: 4,
              }}
            >
              <Typography>{`Remaining Tyres: ${
                vehicleData?.no_of_tyres - tyreListRows?.pagination?.totalCount
              }`}</Typography>
            </div>
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            wrap="nowrap"
            style={{ marginTop: 14 }}
          >
            <Grid
              item
              lg={10.4}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Grid item lg={2}>
                <Select
                  fullWidth
                  placeholder="Filter"
                  value={filter}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleFilter(v.target.value);
                  }}
                  onOpen={() => {
                    if (isEmpty(filter.toString())) {
                      setbrandList(false);
                      settypeList(false);
                      setconditionList(false);
                      setplyRating(false);
                    }
                  }}
                  displayEmpty
                  IconComponent={FilterAlt}
                  style={filter === "" ? { color: "#A2A2A2" } : {}}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem value={""} disabled hidden selected>
                    Filter
                  </MenuItem>
                  <ListItemButton
                    onClick={() => {
                      setbrandList(!brandList);
                      settypeList(false);
                      setconditionList(false);
                      setplyRating(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Brand</Typography>
                    {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.tyre_brand.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: brandList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      settypeList(!typeList);
                      setbrandList(false);
                      setconditionList(false);
                      setplyRating(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Axle Type</Typography>
                    {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.axle_type.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: typeList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setconditionList(!conditionList);
                      settypeList(false);
                      setplyRating(false);
                      setbrandList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Condition</Typography>
                    {conditionList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {tyre_condition.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: conditionList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.label}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setplyRating(!plyRating);
                      setbrandList(false);
                      settypeList(false);
                      setconditionList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Ply Rating</Typography>
                    {plyRating ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.ply_rating.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: plyRating ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid
                container
                alignItems={"center"}
                lg={10}
                style={{ margin: 0 }}
              >
                <Grid item lg={4} style={{ marginLeft: 20 }}>
                  <TextField
                    fullWidth
                    placeholder="Search"
                    className={styles.inputFieldStyle}
                    value={searchFilter}
                    onChange={(v) => {
                      isEmpty(v.target.value) && getTyreList(true);
                      setSearchFilter(v?.target?.value);
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        if (!isEmpty(searchFilter)) {
                          handlePageNo(1);
                          getTyreList(false);
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <div style={{ lineHeight: 0 }}>
                            <Search style={{ fontSize: 20 }} />
                          </div>
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <div style={{ lineHeight: 0, cursor: "pointer" }}>
                            <Tooltip
                              title="Search by Tyre Serial No., Tyre Position and Purchased From"
                              placement="bottom"
                              arrow
                            >
                              <InfoIcon style={{ fontSize: 20 }} />
                            </Tooltip>
                          </div>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
                <Grid item wrap="nowrap" style={{ marginLeft: 20 }}>
                  <Button
                    variant="contained"
                    style={{ backgroundColor: color.secondary }}
                    onClick={() => {
                      if (!isEmpty(searchFilter)) {
                        handlePageNo(1);
                        getTyreList(false);
                      }
                    }}
                  >
                    Search
                  </Button>
                  <Button
                    variant="contained"
                    style={{ marginLeft: 10 }}
                    onClick={resetFilterData}
                    disabled={
                      filter === "" && isEmpty(searchFilter) ? true : false
                    }
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              lg={1.6}
              style={{ display: "flex", alignItems: "center" }}
            >
              <Typography style={{ fontSize: 14 }}>Records:</Typography>
              <Select
                fullWidth
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
                style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(tyreListRows?.data) && !isEmpty(tyreListRows?.data) ? (
            <>
              <div style={{ marginTop: 20 }}>
                <DataGrid
                  rows={tyreListRows?.data}
                  columns={tyreManagementColumns}
                  onCellClick={(params) => {
                    if (params?.field !== "action") {
                      // handleClick("fleetDetails", params?.row);
                    }
                  }}
                  pageSize={record}
                  disableColumnMenu
                  autoHeight={true}
                  hideFooter
                  disableSelectionOnClick
                  showCellRightBorder
                  showColumnRightBorder
                />
              </div>
              <div
                style={{
                  display: "flex",
                  padding: 20,
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(tyreListRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </div>
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : tabValue === 1 ? (
        <div>
          {pageLoad1 ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(healthData) && !isEmpty(healthData) ? (
            <div>
              <Grid
                container
                style={{ marginTop: 30 }}
                justifyContent="space-around"
                gap={2}
                wrap="wrap-reverse"
              >
                <Grid
                  xs={12}
                  lg={3.8}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Box style={{ width: 250, position: "relative" }}>
                    <img
                      src={Images.TruckW}
                      alt={Image.TruckW}
                      style={{ width: "100%", marginTop: 40 }}
                    />
                    <Grid
                      style={{
                        position: "absolute",
                        top: 230,
                        left: -20,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        height: 400,
                      }}
                    >
                      {tyreHealthLeft?.map((v, i) => (
                        <Grid item container columnGap={0.5}>
                          {isArray(v) &&
                            !isEmpty(v) &&
                            v?.map((item, index) => {
                              return (
                                <>
                                  {item?.tyre_position[3] === "O" ? (
                                    <div
                                      onClick={() => {
                                        if (item?.tyre_condition) {
                                          handleModal(item, "open");
                                        }
                                      }}
                                      style={{
                                        width: 12,
                                        backgroundColor:
                                          item?.tyre_condition === "Good"
                                            ? "#6FCF97"
                                            : item?.tyre_condition === "Bad"
                                            ? "#EB5757"
                                            : item?.tyre_condition === "Average"
                                            ? "#F2C94C"
                                            : color.bordercolor,
                                        height: 50,
                                        borderRadius: 5,
                                      }}
                                    />
                                  ) : item?.tyre_position[3] === "I" ? (
                                    <div
                                      onClick={() => {
                                        if (item?.tyre_condition) {
                                          handleModal(item, "open");
                                        }
                                      }}
                                      style={{
                                        width: 12,
                                        backgroundColor:
                                          item?.tyre_condition === "Good"
                                            ? "#6FCF97"
                                            : item?.tyre_condition === "Bad"
                                            ? "#EB5757"
                                            : item?.tyre_condition === "Average"
                                            ? "#F2C94C"
                                            : color.bordercolor,
                                        height: 50,
                                        borderRadius: 5,
                                      }}
                                    />
                                  ) : null}
                                </>
                              );
                            })}
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      style={{
                        position: "absolute",
                        top: 230,
                        left: 35,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        height: 400,
                      }}
                    >
                      {healthData?.map((v, i) => (
                        <Grid
                          item
                          container
                          style={{
                            backgroundColor: color.white,
                            height: 20,
                            width: 180,
                            borderRadius: 30,
                            boxShadow: "0px 0px 10px #000",
                            position: "relative",
                          }}
                        >
                          <Grid
                            xs={6}
                            style={{
                              backgroundColor:
                                v?.axle_data?.c?.condition === "Good"
                                  ? "#6FCF97"
                                  : v?.axle_data?.c?.condition === "Average"
                                  ? "#F2C94C"
                                  : v?.axle_data?.c?.condition === "Bad"
                                  ? "#EB5757"
                                  : color?.bordercolor,
                              borderTopLeftRadius: 4,
                              borderBottomLeftRadius: 4,
                            }}
                            onClick={() => {
                              handleModal(
                                v?.axle_data?.c,
                                "Axle",
                                i,
                                "Co-driver"
                              );
                            }}
                          />
                          <Typography
                            style={{
                              position: "absolute",
                              fontSize: 12,
                              // color: color.white,
                              left: "40%",
                              top: 2,
                            }}
                          >
                            {`Axle ${i + 1}`}
                          </Typography>
                          <Grid
                            xs={6}
                            style={{
                              backgroundColor:
                                v?.axle_data?.d?.condition === "Good"
                                  ? "#6FCF97"
                                  : v?.axle_data?.d?.condition === "Average"
                                  ? "#F2C94C"
                                  : v?.axle_data?.d?.condition === "Bad"
                                  ? "#EB5757"
                                  : color?.bordercolor,
                              borderTopRightRadius: 4,
                              borderBottomRightRadius: 4,
                            }}
                            onClick={() => {
                              handleModal(v?.axle_data?.d, "Axle", i, "Driver");
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                    <Grid
                      style={{
                        position: "absolute",
                        top: 230,
                        right: -20,
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-around",
                        height: 400,
                      }}
                    >
                      {tyreHealthRight?.map((v, i) => (
                        <Grid
                          item
                          container
                          columnGap={0.5}
                          flexDirection="row-reverse"
                        >
                          {isArray(v) &&
                            !isEmpty(v) &&
                            v?.map((item, index) => {
                              return (
                                <>
                                  {item?.tyre_position[3] === "I" ? (
                                    <div
                                      onClick={() => {
                                        if (item?.tyre_condition) {
                                          handleModal(item, "open");
                                        }
                                      }}
                                      style={{
                                        width: 12,
                                        backgroundColor:
                                          item?.tyre_condition === "Good"
                                            ? "#6FCF97"
                                            : item?.tyre_condition === "Bad"
                                            ? "#EB5757"
                                            : item?.tyre_condition === "Average"
                                            ? "#F2C94C"
                                            : color.bordercolor,
                                        height: 50,
                                        borderRadius: 5,
                                      }}
                                    />
                                  ) : item?.tyre_position[3] === "O" ? (
                                    <div
                                      onClick={() => {
                                        if (item?.tyre_condition) {
                                          handleModal(item, "open");
                                        }
                                      }}
                                      style={{
                                        width: 12,
                                        backgroundColor:
                                          item?.tyre_condition === "Good"
                                            ? "#6FCF97"
                                            : item?.tyre_condition === "Bad"
                                            ? "#EB5757"
                                            : item?.tyre_condition === "Average"
                                            ? "#F2C94C"
                                            : color.bordercolor,
                                        height: 50,
                                        borderRadius: 5,
                                      }}
                                    />
                                  ) : null}
                                </>
                              );
                            })}
                        </Grid>
                      ))}
                    </Grid>
                  </Box>
                </Grid>
                <Grid xs={12} lg={7.8}>
                  <Grid md={12} item display="flex" flexWrap="wrap" gap={2}>
                    <Grid
                      xs={12}
                      sm={5.8}
                      md={3.8}
                      lg={5.8}
                      xl={3.8}
                      className={styles.cardBox}
                    >
                      <Typography className={styles.cardHead}>
                        No Issues
                      </Typography>
                      <Typography className={styles.cardValue}>
                        {issueCount?.no_issue || 0}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={5.8}
                      md={3.8}
                      lg={5.8}
                      xl={3.8}
                      className={styles.cardBox}
                    >
                      <Typography className={styles.cardHead}>
                        Minor Issues
                      </Typography>
                      <Typography className={styles.cardValue}>
                        {issueCount?.minor || 0}
                      </Typography>
                    </Grid>
                    <Grid
                      xs={12}
                      sm={5.8}
                      md={3.8}
                      lg={5.8}
                      xl={3.8}
                      className={styles.cardBox}
                    >
                      <Typography className={styles.cardHead}>
                        Major Issues
                      </Typography>
                      <Typography className={styles.cardValue}>
                        {issueCount?.major || 0}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
          <ConfirmDialog
            title={`Are you sure you want to Upload CSV?`}
            visible={confirmDialog}
            handleModal={(bool) => {
              if (bool) {
                handleOnSubmit();
              } else {
                setConfirmDialog(false);
                clearData();
              }
            }}
            btnLoad={removeBtnLoad}
          />
          <TyreIssue
            visible={visible}
            handleModal={handleModal}
            modalData={modalData}
            from={from}
            index={index}
            side={side}
          />
        </div>
      ) : null}
    </div>
  );
}
