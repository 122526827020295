import React, { useEffect, useState } from "react";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import useStyles from "./Styles";
import { Setting } from "../../../Utils/Setting";
import TrailerMasterVeiw from "../../../Components/TrailerMaster/TrailerMasterView";
import AddTrailerMaster from "../../../Components/TrailerMaster/AddTrailerMaster";

export default function TrailerMaster() {
  const styles = useStyles();

  const [changeTab, setChangeTab] = useState("");
  const [data, setData] = useState({});

  useEffect(() => {
    document.title = Setting.page_name.TrailerMaster;
  }, []);

  return (
    <div className={styles.card}>
      <Grid container>
        <Grid item className={styles.breadcrumb}>
          <Breadcrumbs separator=">">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="#"
              className={styles.linkStyle}
              onClick={() => setChangeTab("")}
            >
              Trailer Master
            </Link>
            {changeTab === "addTrailer" && (
              <Typography className={styles.linkStyle}>Add Trailer</Typography>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "addTrailer" ? (
        <AddTrailerMaster
          editData={data}
          handleClick={(v, type) => {
            if (v === "cancle") {
              setChangeTab("");
            }
          }}
        />
      ) : (
        <TrailerMasterVeiw
          handleClick={(v, type) => {
            if (v === "addTrailer") {
              setChangeTab("addTrailer");
              setData({});
            }
            if (v === "editTrailer") {
              setChangeTab("addTrailer");
              setData(type);
            }
          }}
        />
      )}
    </div>
  );
}
