import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  ListItemButton,
  MenuItem,
  Pagination,
  Select,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import useStyles from "./styles";
import { color } from "../../../Config/theme";
import {
  FilterAlt,
  Search,
  SearchOutlined,
  Visibility,
} from "@mui/icons-material";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import { getApiData } from "../../../Utils/APIHelper";
import moment from "moment";
import { Setting } from "../../../Utils/Setting";
import { toast } from "react-toastify";
import { getAPIProgressData } from "../../../Utils/APIHelper";
import { useSelector } from "react-redux";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as XLSX from "xlsx/xlsx.mjs";
import Images from "../../../Config/Images";
import ConfirmDialog from "../../ConfirmDialog";
import { isTablet } from "react-device-detect";
import { ResetIcon } from "../../Icon/TableToggle";

export default function TabResolvedTickets(props) {
  const styles = useStyles();
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
  } = props;

  const [ticketListRows, setTicketListRows] = useState(true);
  const [selectedFile, setSlectedFile] = useState(null);
  const [searchFilter, setSearchFilter] = useState("");
  // const [filter, setFilter] = useState("");
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [paymentList, setPaymentList] = useState(false);
  const [issueList, setIssueList] = useState(false);
  const [pageLoad, setPageLoad] = useState(true);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [reportLoader, setReportLoader] = useState(false);

  const { token, isOnline, userdata } = useSelector((state) => state.auth);

  useEffect(() => {
    getTicketList(false);
  }, [record, pageNo, filter]);

  // useEffect(() => {
  //   setPageNo(1);
  // }, [record]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getTicketList(false);
  }, [datePicker, searchFilter]);

  function resetFilterData() {
    handleFilter("");
    setSearchFilter("");
    setDatePicker(null);
    handlePageNo(1);
  }

  useEffect(() => {
    getTicketList();
  }, []);

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [pageNo]);

  async function getTicketList(bool, v) {
    v && setReportLoader(true);
    !v && setPageLoad(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    try {
      const response = await getApiData(
        `${
          Setting.endpoints.ticketlist
        }?status=${1}&per_page=${record}&page=${pageNo}&search=${
          bool ? "" : searchFilter
        }&filter=${filter}&date=${date}&is_download=${v ? v : ""}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadResolvedData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setTicketListRows(listObj);
          }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Resolved Tickets List");
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const addedOn = (index) => {
    const timestamp = ticketListRows?.data[index]?.created_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const resolvedon = (index) => {
    const timestamp = ticketListRows?.data[index]?.resolved_on;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const handleOnSubmit = async () => {
    setRemoveBtnLoad(true);
    if (selectedFile) {
      let endPoints = Setting.endpoints.importservicecenter;
      const params = {
        "CsvForm[file]": selectedFile,
      };
      try {
        const resp = await getAPIProgressData(endPoints, "POST", params, true);
        if (resp?.status) {
          toast.success(resp?.message);
          clearData();
        } else {
          toast.error(resp?.message);
        }
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
      } catch (error) {
        console.log("ERRRRR", error);
        setRemoveBtnLoad(false);
        setConfirmDialog(false);
        toast.error(error.message.toString());
      }
    }
  };

  function clearData() {
    setSlectedFile(null);
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Service Centre`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadResolvedData(data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.created_at;
      const timestampR = row?.resolved_on;
      const date = new Date(timestamp * 1000);
      const dateR = new Date(timestampR * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");
      const displayDateR = moment(dateR).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        ID: row?.id || "-",
        "Vehicle No.": row?.vehicle_no,
        Issue: row?.issue || "-",
        "Payment Status": row?.payment_status || "-",
        "Added On": displayDate || "-",
        "Added By": row?.added_by || "-",
        "Resolved On": displayDateR || "-",
        "Resolved By": row?.resolved_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Resolved Tickets List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Resolved Tickets List.xlsx");
  }
  const paymentArr = [
    { label: "Bill Pending" },
    { label: "Payment Pending" },
    { label: "Payment Completed" },
  ];

  const issueArr = [
    { label: "Cut", value: "Cut" },
    { label: "Puncture", value: "Puncture" },
    { label: "Misalignment", value: "Misallignment" },
  ];

  return (
    <>
      {isTablet ? (
        <div>
          <Grid
            container
            gap={1}
            style={{ marginTop: 10, justifyContent: "space-between" }}
            wrap="nowrap"
          >
            <Grid item>
              <Select
                id="jump_to_me"
                classes={{
                  iconOpen: styles.iconOpen,
                  select: styles.txt,
                }}
                fullWidth
                placeholder="Filter"
                value={filter}
                IconComponent={FilterAlt}
                displayEmpty
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    setPaymentList(false);
                    setIssueList(false);
                  }
                }}
                style={{
                  color: filter === "" ? "#A2A2A2" : "",
                  marginRight: 10,
                }}
              >
                <MenuItem value="" selected hidden>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    setPaymentList(!paymentList);
                    setIssueList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Payment Status</Typography>
                  {paymentList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {paymentArr.map((v, i) => {
                  return (
                    <MenuItem
                      style={{
                        display: paymentList ? "flex" : "none",
                      }}
                      key={i}
                      value={v?.label}
                    >
                      {v?.label}
                    </MenuItem>
                  );
                })}
                <ListItemButton
                  onClick={() => {
                    setIssueList(!issueList);
                    setPaymentList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Issue</Typography>
                  {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {issueArr.map((v, i) => {
                  return (
                    <MenuItem
                      style={{
                        display: issueList ? "flex" : "none",
                      }}
                      key={i}
                      value={v?.value}
                    >
                      {v?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <Grid container gap={1} style={{ marginTop: 10 }} wrap="nowrap">
            <Grid item>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value) && getTicketList(true);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      handlePageNo(1);
                      getTicketList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Vehicle No. and Added By"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item>
              <Button
                varient="contained"
                onClick={() => {
                  if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                    getTicketList(false);
                  }
                }}
                style={{
                  backgroundColor: color.secondary,
                  width: "100%",
                }}
              >
                <SearchOutlined />
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{ width: "100%" }}
                onClick={resetFilterData}
                disabled={!searchFilter && !filter && !datePicker}
              >
                <ResetIcon />
              </Button>
            </Grid>
          </Grid>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(ticketListRows?.data) &&
            !isEmpty(ticketListRows?.data) ? (
            <>
              {ticketListRows?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    style={{
                      border: `1px solid ${color.bordercolor}`,
                      padding: 15,
                      marginTop: 10,
                      overflow: "auto",
                      boxShadow: color.shadow,
                    }}
                    alignItems="center"
                  >
                    <Grid item container>
                      <Table className={styles.customtable}>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Ticket ID</TableCell>
                            <TableCell variant="head">Vehicle No.</TableCell>
                            <TableCell variant="head">Issue</TableCell>
                            <TableCell variant="head">Payment Status</TableCell>
                            <TableCell variant="head">Added On</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>{item?.id || "-"}</TableCell>
                          <TableCell>{item?.vehicle_no || "-"}</TableCell>
                          <TableCell>{item?.issue || "-"}</TableCell>
                          <TableCell>{item?.payment_status || "-"}</TableCell>
                          <TableCell>{addedOn(index)}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell colSpan={5}>
                            <Divider
                              variant="fullWidth"
                              style={{ margin: "15px 0" }}
                            />
                          </TableCell>
                        </TableRow>
                        <TableHead>
                          <TableRow>
                            <TableCell variant="head">Added By</TableCell>
                            <TableCell variant="head">Resolved On</TableCell>
                            <TableCell variant="head">Resolved By</TableCell>
                            <TableCell colSpan={2} rowSpan={3}>
                              <Grid container>
                                <Button
                                  style={{
                                    backgroundColor: color.primary,
                                    margin: "0 20px -10px auto",
                                  }}
                                  onClick={() => {
                                    handleClick("TicketID", item);
                                  }}
                                >
                                  <Visibility />
                                </Button>
                              </Grid>
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableRow>
                          <TableCell>{item?.added_by || "-"}</TableCell>
                          <TableCell>{resolvedon(index)}</TableCell>
                          <TableCell>{item?.resolved_by || "-"}</TableCell>
                        </TableRow>
                      </Table>
                    </Grid>
                  </Grid>
                );
              })}
              <Box
                style={{
                  margin: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(ticketListRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={2}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
              <ConfirmDialog
                title={`Are you sure you want to Upload Center List?`}
                visible={confirmDialog}
                handleModal={(bool) => {
                  if (bool) {
                    handleOnSubmit();
                  } else {
                    setConfirmDialog(false);
                  }
                }}
                btnLoad={removeBtnLoad}
              />
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      ) : (
        <div>
          <Grid container gap={1} wrap="nowrap" style={{ paddingTop: 10 }}>
            <Grid item>
              <Select
                id="jump_to_me"
                classes={{
                  iconOpen: styles.iconOpen,
                  select: styles.txt,
                }}
                placeholder="Filter"
                value={filter}
                IconComponent={FilterAlt}
                displayEmpty
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    setPaymentList(false);
                    setIssueList(false);
                  }
                }}
                style={{
                  color: filter === "" ? "#A2A2A2" : "",
                  marginRight: 10,
                }}
              >
                <MenuItem value="" selected hidden>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    setPaymentList(!paymentList);
                    setIssueList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Payment Status</Typography>
                  {paymentList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {paymentArr.map((v, i) => {
                  return (
                    <MenuItem
                      style={{
                        display: paymentList ? "flex" : "none",
                      }}
                      key={i}
                      value={v?.label}
                    >
                      {v?.label}
                    </MenuItem>
                  );
                })}
                <ListItemButton
                  onClick={() => {
                    setIssueList(!issueList);
                    setPaymentList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Issue</Typography>
                  {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {issueArr.map((v, i) => {
                  return (
                    <MenuItem
                      style={{
                        display: issueList ? "flex" : "none",
                      }}
                      key={i}
                      value={v?.value}
                    >
                      {v?.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </Grid>
            <Grid item marginLeft={"auto"}>
              <Button
                variant="contained"
                component="label"
                style={{ backgroundColor: color.secondary }}
              >
                <input
                  type="file"
                  onChange={(e) => {
                    setSlectedFile(e.target.files[0]);
                    setConfirmDialog(true);
                  }}
                  onClick={(event) => {
                    event.target.value = null;
                  }}
                  accept=".csv"
                  hidden
                />
                Upload Center List
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                }}
                disabled={isOnline ? false : true}
                onClick={() => getTicketList(false, 1)}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  <img src={Images.Mdownload} alt="download" />
                )}
              </Button>
            </Grid>
          </Grid>
          <Grid container wrap="nowrap" gap={1} style={{ paddingTop: 10 }}>
            <Grid item container>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value) && getTicketList(true);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      handlePageNo(1);
                      getTicketList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Vehicle No. and Added By"
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
          </Grid>
          <div style={{ paddingTop: 14 }}>
            <Grid container xs={12} gap={1} wrap="nowrap" alignItems="center">
              <Grid item xs={9}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        fullWidth
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={1.5}>
                <Button
                  varient="contained"
                  style={{
                    backgroundColor: color.secondary,
                    width: "100%",
                  }}
                  onClick={() => {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      getTicketList(false);
                    }
                  }}
                >
                  <SearchOutlined />
                </Button>
              </Grid>

              <Grid item xs={1.5}>
                <Button
                  variant="contained"
                  style={{ width: "100%" }}
                  onClick={resetFilterData}
                  disabled={!searchFilter && !filter && !datePicker}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
          </div>

          {pageLoad ? (
            <div className={styles.dataMain}>
              <CircularProgress style={{ color: color.primary }} />
            </div>
          ) : isArray(ticketListRows?.data) &&
            !isEmpty(ticketListRows?.data) ? (
            <>
              {ticketListRows?.data?.map((item, index) => {
                return (
                  <Grid
                    container
                    onClick={() => {
                      handleClick("TicketID", item);
                    }}
                    style={{
                      boxShadow: color.shadow,
                      border: `1px solid ${color.bordercolor}`,
                      marginTop: 14,
                    }}
                  >
                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>Ticket ID</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Vehicle No.
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>{item.id}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.vehicle_no}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          background: "#EEF8FF",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>Issue</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Payment Status
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                          background: "#EEF8FF",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>{item.issue || "-"}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.payment_status}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>Added On</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>Added By</Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography>{addedOn(index)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography>{item.added_by}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <Grid container item>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "10px 14px 0",
                          background: "#EEF8FF",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Resolved On
                          </Typography>
                        </Grid>
                        <Grid item xs={6}>
                          <Typography fontWeight={"bold"}>
                            Resolved By
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          padding: "0 14px 10px",
                          background: "#EEF8FF",
                        }}
                        xs={12}
                      >
                        <Grid item xs={6} wrap="wrap">
                          <Typography>{resolvedon(index)}</Typography>
                        </Grid>
                        <Grid item xs={6} wrap="wrap">
                          <Typography>{item.resolved_by}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                );
              })}
              <Box
                style={{
                  marginTop: 15,
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Pagination
                  count={Math.ceil(ticketListRows?.pagination?.totalPage)}
                  defaultPage={pageNo}
                  boundaryCount={1}
                  siblingCount={0}
                  variant="outlined"
                  shape="rounded"
                  onChange={(v, e) => {
                    handlePageNo(e);
                  }}
                />
              </Box>
              <ConfirmDialog
                title={`Are you sure you want to Upload Center List?`}
                visible={confirmDialog}
                handleModal={(bool) => {
                  if (bool) {
                    handleOnSubmit();
                  } else {
                    setConfirmDialog(false);
                  }
                }}
                btnLoad={removeBtnLoad}
              />
            </>
          ) : (
            <div className={styles.dataMain}>
              <Typography>No Data</Typography>
            </div>
          )}
        </div>
      )}
    </>
  );
}
