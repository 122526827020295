import React, { useEffect, useState } from "react";
import { Button, Box } from "@mui/material";
import TabTyreList from "../TabTyreList";
import TabVehicleDetailTable from "../TabVehicleDetalsTable";
import AddTyre from "../../TyreHistory/AddTyre";
import RemoveTyre from "../../TyreHistory/RemoveTyre";
import SwitchTyre from "../../TyreHistory/SwitchTyre";
import { ArrowBackOutlined } from "@mui/icons-material";
import TabTyreDetails from "../TabTyreDetails";
import { isTablet } from "react-device-detect";
import { useNavigate } from "react-router-dom";
import { color } from "../../../Config/theme";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function TabVehicleDetails(props) {
  const { vehicleDetails, handleClick = () => null, fromDeshboard } = props;
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [changeTab, setChangeTab] = useState("");
  const [tyreData, setTyreData] = useState({});
  const [fitmentReading, setFitmentReading] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [record, setRecord] = useState(10);
  const [filter, setFilter] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    const section = document.querySelector("#jump_to_me");
    section.scrollIntoView({
      behavior: "auto",
      block: "end",
    });
  }, []);
  return (
    <>
      {changeTab === "" && (
        <Box id="jump_to_me">
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                return changeTab === "tyreHealthVisualization"
                  ? setChangeTab("")
                  : fromDeshboard
                  ? navigate("/")
                  : handleClick("cancel");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "addTyre" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "removeTyre" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "tyreDetails" && (
        <Box>
          {isKeyboardOpen && !isTablet ? null : (
            <Button
              style={
                isTablet
                  ? { backgroundColor: color.secondary, marginBottom: 10 }
                  : {
                      backgroundColor: color.secondary,
                      position: "fixed",
                      top: 10,
                      zIndex: 10,
                    }
              }
              onClick={() => {
                setChangeTab("");
              }}
            >
              {isTablet ? "Back" : <ArrowBackOutlined />}
            </Button>
          )}
        </Box>
      )}
      {changeTab === "addTyre" ? (
        <AddTyre
          vehicleDetails={vehicleDetails}
          handleClick={(type, data) => {
            if (type === "cancel") {
              setChangeTab("");
            }
          }}
          editData={tyreData}
        />
      ) : changeTab === "removeTyre" ? (
        <RemoveTyre
          vehicleDetails={vehicleDetails}
          fitmentReading={fitmentReading}
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
            }
          }}
        />
      ) : changeTab === "switchTyre" ? (
        <SwitchTyre
          tyreDetails={tyreData}
          vehicleDetails={vehicleDetails}
          handleClick={(type) => {
            if (type === "cancel") {
              setChangeTab("");
            }
          }}
        />
      ) : changeTab === "tyreDetails" ? (
        <TabTyreDetails tyreData={tyreData} />
      ) : (
        <>
          <TabVehicleDetailTable vehicleDetail={vehicleDetails} />

          <TabTyreList
            vehicleData={vehicleDetails}
            pageNo={pageNo}
            record={record}
            filter={filter}
            handlePageNo={(page) => {
              setPageNo(page);
            }}
            handleRecord={(rec) => {
              setRecord(rec);
            }}
            handleFilter={(flt) => {
              setFilter(flt);
            }}
            handleClick={(type, data) => {
              if (type === "addTyre") {
                setChangeTab("addTyre");
              } else if (type === "removeTyre") {
                setChangeTab("removeTyre");
                setFitmentReading(data);
              } else if (type === "switchTyre") {
                setChangeTab("switchTyre");
              } else if (type === "tyreDetails") {
                setChangeTab("tyreDetails");
              }
              setTyreData(data);
            }}
          />
        </>
      )}
    </>
  );
}
