import React, { useEffect, useRef, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  Link,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useSelector } from "react-redux";
import { color } from "../../../Config/theme.js";
import { useTheme } from "@emotion/react";
import AuditList from "../../../Components/AuditLog/AuditList/index.js";
import AuditDetails from "../../../Components/AuditLog/AuditDetails/index.js";

export default function AuditLog() {
  const styles = useStyles();
  const { isOnline } = useSelector((state) => state.auth);
  const [changeTab, setChangeTab] = useState("");
  const [data, setData] = useState({});
  const [pageNoIL, setPageNoIL] = useState(1);
  const [recordIL, setRecordIL] = useState(10);
  const auditLog = useRef();

  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down("sm"));

  const [reportLoader, setReportLoader] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    document.title = Setting.page_name.AuditLog;
  }, []);

  useEffect(() => {
    // const section = document.querySelector("#jump_to_me");
    // section.scrollIntoView({ behavior: "auto", block: "end" });
  }, [changeTab]);

  return (
    <div className={styles.card}>
      <Grid container id="jump_to_me">
        <Grid item xs={12} className={styles.breadcrumb}>
          <Breadcrumbs separator="›">
            <Link
              underline="hover"
              color="inherit"
              href="/"
              className={styles.linkStyle}
            >
              Dashboard
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href="#"
              className={styles.linkStyle}
              onClick={() => {
                setChangeTab("");
              }}
            >
              Audit Log
            </Link>
            {changeTab === "auditDetails" && (
              <Link
                underline="hover"
                color="inherit"
                href="#"
                className={styles.linkStyle}
              >
                Audit Details
              </Link>
            )}
          </Breadcrumbs>
        </Grid>
      </Grid>
      {changeTab === "auditDetails" ? (
        <AuditDetails auditData={data} />
      ) : (
        <div className={styles.container}>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            style={{ height: 40 }}
          >
            <Grid item xs={12} sm={6}>
              <Typography variant="tableTitle">Audit Log</Typography>
            </Grid>

            <Grid
              item
              xs={12}
              sm={6}
              style={{
                display: "flex",
                justifyContent: sm ? "unset" : "flex-end",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  auditLog?.current?.getAuditList();
                }}
                style={{
                  backgroundColor: isOnline
                    ? color.secondary
                    : color.bordercolor,
                  marginLeft: 10,
                }}
                disabled={isOnline ? false : true || reportLoader}
              >
                {reportLoader ? (
                  <CircularProgress style={{ color: color.white }} size={24} />
                ) : (
                  "Download"
                )}
              </Button>
            </Grid>
          </Grid>
          <AuditList
            ref={auditLog}
            pageNo={pageNoIL}
            record={recordIL}
            search={search}
            handleSearch={(search) => {
              setSearch(search);
            }}
            handlePageNo={(page) => {
              setPageNoIL(page);
            }}
            handleLoader={(v) => {
              setReportLoader(v);
            }}
            handleRecord={(rec) => {
              setRecordIL(rec);
            }}
            handleClick={(v, data) => {
              if (v === "auditDetails") {
                setChangeTab("auditDetails");
                setData(data);
              }
            }}
          />
        </div>
      )}
    </div>
  );
}
