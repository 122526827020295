import Images from "./Images";
import { color } from "./theme";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";

export const candidate_dashboard_menu_link = [
  {
    id: 1,
    title: "Jobs",
    link: "/candidate/jobs",
  },
  {
    id: 2,
    title: "Preference",
    link: "/candidate/preference",
  },
  {
    id: 3,
    title: "My Profile",
    link: "/candidate/profile",
  },
  {
    id: 4,
    title: "Account",
    link: "/candidate/account",
  },
];

export const employer_dashboard_menu_link = [
  {
    id: 1,
    title: "Jobs",
    link: "/employer/jobs",
  },
  {
    id: 2,
    title: "My Company",
    link: "/employer/company",
  },
  {
    id: 3,
    title: "My Account",
    link: "/employer/account",
  },
];

export const Menubar_Arr = [
  {
    id: 1,
    icon: Images.home,
    activeIcon: Images.Ahome,
    title: "Dashboard",
    navigate: "/dashboard",
  },
  {
    id: 2,
    icon: Images.delivery,
    activeIcon: Images.Adelivery,
    title: "Fleet Management",
    navigate: "/dashboard/FleetManagement",
  },
  {
    id: 3,
    icon: Images.tyre,
    activeIcon: Images.Atyre,
    title: "Inspection Management",
    navigate: "/dashboard/Inspection",
  },
  {
    id: 4,
    icon: Images.scrap,
    activeIcon: Images.scrapw,
    title: "Scrap Analysis",
    navigate: "/dashboard/ScrapAnalysis",
  },
  {
    id: 5,
    icon: Images.historygrey,
    activeIcon: Images.thistoryw,
    title: "Tyre History",
    navigate: "/dashboard/TyreHistory",
  },

  {
    id: 7,
    icon: Images.public,
    activeIcon: Images.Apublic,
    title: "User Management",
    navigate: "/dashboard/UserManagement",
  },
  {
    id: 12,
    icon: Images.vmasterg,
    activeIcon: Images.vmasterw,
    title: "Vehicle Master",
    navigate: "/dashboard/VehicleMaster",
  },
  {
    id: 13,
    icon: Images.tmasterg,
    activeIcon: Images.tmasterw,
    title: "Trailer Master",
    navigate: "/dashboard/TrailerMaster",
  },
  {
    id: 9,
    icon: Images.master,
    activeIcon: Images.Amaster,
    title: "Item Master",
    navigate: "/dashboard/ItemMaster",
  },
  {
    id: 10,
    icon: Images.inventorylg,
    activeIcon: Images.inventoryw,
    title: "Tyre Inventory",
    navigate: "/dashboard/TyreInventory",
  },
  {
    id: 11,
    icon: Images.auditG,
    activeIcon: Images.auditw,
    title: "Audit Log",
    navigate: "/dashboard/AuditLog",
  },
  {
    id: 6,
    icon: Images.servicegray,
    activeIcon: Images.servicew,
    title: "Service Centre",
    navigate: "/dashboard/ServiceCentre",
  },
  {
    id: 8,
    icon: Images.dropdown,
    activeIcon: Images.dropdown,
    title: "General Dropdowns",
    navigate: "",
    subData: [
      {
        id: 9,
        name: "Axle Type",
        type: "axle_type",
      },
      {
        id: 13,
        name: "District",
        type: "district",
      },
      {
        id: 12,
        name: "Regional Office",
        type: "office_region",
      },
      {
        id: 15,
        name: "Service Centre Issue",
        type: "service_centre_issue",
      },
      {
        id: 3,
        name: "Terrain",
        type: "terrain",
      },
      {
        id: 16,
        name: "Tread Pattern",
        type: "tread_pattern",
      },
      {
        id: 5,
        name: "Tyre Brand",
        type: "tyre_brand",
      },
      {
        id: 8,
        name: "Tyre Composition",
        type: "tyre_composition",
      },
      {
        id: 10,
        name: "Tyre Issue: \n Inspection",
        type: "tyre_issue_inspection",
      },
      {
        id: 13,
        name: "Tyre Issue: \n Removal",
        type: "tyre_removal_issue",
      },
      {
        id: 11,
        name: "Tyre Issue: \n Scrap Analysis",
        type: "tyre_issue_scrap_analysis",
      },
      {
        id: 17,
        name: "Mechanical Issue",
        type: "mechanical_issue",
      },
      {
        id: 7,
        name: "Tyre Ply Rating",
        type: "ply_rating",
      },
      {
        id: 6,
        name: "Tyre Type",
        type: "tyre_type",
      },
      {
        id: 2,
        name: "Vehicle Brand",
        type: "vehicle_brand",
      },
      {
        id: 1,
        name: "Vehicle Type",
        type: "vehicle_type",
      },
      // {
      //   id: 4,
      //   name: "Tyre Condition",
      //   type: "tyre_condition",
      // },

      // {
      //   id: 14,
      //   name: "Media Upload",
      //   type: "Media_Uploads",
      // },
    ],
  },
];

export const months = [
  {
    label: "January",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "February",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "March",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "April",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "May",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "June",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "July",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "August",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "September",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "October",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "November",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
  {
    label: "December",
    tyres: "",
    cost: "",
    tyreError: false,
    tyreErrorMsg: "",
    costError: false,
    costErrorMsg: "",
  },
];

export const tyre_condition = [
  { label: "Good", id: 32 },
  { label: "Average", id: 33 },
  { label: "Bad", id: 34 },
];
