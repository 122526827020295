import React, { useEffect, useState, forwardRef, useRef } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  Button,
  Grid,
  CircularProgress,
  Select,
  MenuItem,
  InputAdornment,
  TextField,
  Pagination,
  Tooltip,
  useMediaQuery,
  useTheme,
  Box,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { DataGrid } from "@mui/x-data-grid";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { isArray, isEmpty, isObject } from "lodash";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Search } from "@mui/icons-material";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import * as XLSX from "xlsx/xlsx.mjs";

function IssueList(props, ref) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    pageNo = 1,
    record = 10,
    fleetData,
  } = props;
  const styles = useStyles();
  const [issueHistoryList, setIssueListRows] = useState({});
  const [pageLoad, setPageLoad] = useState(true);
  const [searchFilter, setSearchFilter] = useState("");
  const { token, isOnline, userdata } = useSelector((state) => state.auth);
  const [reportLoader, setReportLoader] = useState(false);

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));

  // intialRender used for stop multiple time apiCall in
  const initialRender = useRef(true);

  const issueHistoryColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      sortable: false,
      align: "center",
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "vehicle_no",
      headerName: "Vehicle No.",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "pending_issues_count",
      headerName: "Unresolved Issues",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const issueCount = params?.row?.pending_issues_count;
        return (
          <div
            style={{ display: "flex", flex: 1 }}
            // onClick={() => {
            //   navigate("/dashboard/FleetManagement", {
            //     state: {
            //       set: "fleetDetails",
            //       id: params?.row?.fleet_id,
            //       name: params?.row?.fleet_name,
            //       vehicle_id: params?.row?.vehicle_id,
            //     },
            //   });
            // }}
          >
            <Typography
              style={{
                fontWeight: "bold",
                color: issueCount == 0 ? color.disable : "red",
                cursor: "pointer",
              }}
            >
              {issueCount}
            </Typography>
          </div>
        );
      },
    },
    // {
    //   field: "resolved_issues_count",
    //   headerName: "Resolved Issues",
    //   width: lg ? 220 : "unset",
    //   flex: !lg ? 1 : "unset",
    // },
    {
      field: "inspected_by",
      headerName: "Last Inspected By",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
    },
    {
      field: "Last Inspected On",
      sortable: false,
      headerName: "Last Inspected On",
      width: lg ? 220 : "unset",
      flex: !lg ? 1 : "unset",
      renderCell: (params) => {
        const timestamp = params?.row?.inspected_at;
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return (
          <div
            style={{
              flex: 1,
              display: "flex",
            }}
          >
            {params?.row?.inspected_at ? displayDate : ""}
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    if (initialRender?.current) {
      initialRender.current = false;
    } else {
      getSovedIssueList(false);
    }
  }, [pageNo, record]);

  useEffect(() => {
    isEmpty(searchFilter) && getSovedIssueList(false);
  }, [searchFilter]);

  function resetFilterData() {
    setSearchFilter("");
  }

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Fleet Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  function downloadFleetData(data) {
    const newData = data.map((item, index) => {
      const getInspectedOn = () => {
        const timestamp = item?.last_updated_at;
        // const date = new Date(timestamp * 1000);
        const displayDate = moment(timestamp).format("DD-MM-yyyy");
        return item?.inspected_at ? displayDate : "";
      };

      const newObj = {
        "Sr. No.": index + 1,
        "Vehicle No.": item?.vehicle_no || "-",
        "Unresolved Issues": item?.pending_issues_count || "-",
        "Last Inspected By": item?.inspected_by || "-",
        "Last Inspected On": getInspectedOn() || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Unresolved Issue");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Unresolved Issue.xlsx");
  }

  async function getSovedIssueList(bool, v) {
    !v && setPageLoad(true);
    v && setReportLoader(true);
    let endpoints = `${Setting.endpoints.issueList}?fleet_id=${
      fleetData?.id
    }&page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadFleetData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setIssueListRows(listObj);
          }
        v && auditSave("Unresolved Issue");
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
      setReportLoader(false);
    } catch (error) {
      setReportLoader(false);
      setPageLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  return (
    <div className={styles.container}>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          fontWeight={"bold"}
        >{`${fleetData?.name} - Unresolved Issues`}</Typography>
        <Button
          disabled={isOnline ? false : true || reportLoader}
          variant="contained"
          style={{
            marginLeft: 10,
            backgroundColor: isOnline ? color.secondary : color.bordercolor,
          }}
          onClick={() => {
            getSovedIssueList(false, 1);
          }}
        >
          {reportLoader ? (
            <CircularProgress style={{ color: color.white }} size={24} />
          ) : (
            "Download Issues Report"
          )}
        </Button>
      </Box>
      <div style={{ padding: 20 }}>
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          // style={{ marginTop: 14 }}
          wrap="nowrap"
        >
          <Grid
            item
            lg={10.4}
            style={{ display: "flex", alignItems: "center" }}
          >
            {/* <Grid item lg={1.6}>
            <Select
              fullWidth
              placeholder="Filter"
              value={filter}
              onChange={(v) => {
                setFilter(v.target.value);
              }}
              displayEmpty
              IconComponent={FilterAlt}
              style={filter === "" ? { color: "#A2A2A2" } : {}}
              classes={{
                iconOpen: styles.iconOpen,
              }}
            >
              <MenuItem value={""} disabled hidden selected>
                Filter
              </MenuItem>
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid> */}
            <Grid item lg={4}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value) && getSovedIssueList(true);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyDown={(v) => {
                  if (v.key === "Enter") {
                    if (!isEmpty(searchFilter)) {
                      handlePageNo(1);
                      getSovedIssueList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Vehicle No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item style={{ marginLeft: 20 }}>
              <Button
                variant="contained"
                style={{ backgroundColor: color.secondary }}
                onClick={() => {
                  if (!isEmpty(searchFilter)) {
                    handlePageNo(1);
                    getSovedIssueList(false);
                  }
                }}
              >
                Search
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: 10 }}
                onClick={resetFilterData}
                disabled={isEmpty(searchFilter) ? true : false}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
          <Grid item lg={1.6} style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ fontSize: 14 }}>Records:</Typography>
            <Select
              fullWidth
              value={record}
              onChange={(v) => {
                handlePageNo(1);
                handleRecord(v.target.value);
              }}
              style={{ marginLeft: 10 }}
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Grid>
        </Grid>
        {pageLoad ? (
          <div className={styles.dataMain}>
            <CircularProgress style={{ color: color.primary }} />
          </div>
        ) : isArray(issueHistoryList?.data) &&
          !isEmpty(issueHistoryList?.data) ? (
          <div style={{ marginTop: 20 }}>
            <DataGrid
              rows={issueHistoryList?.data}
              columns={issueHistoryColumn}
              disableColumnMenu
              hideFooter
              showCellRightBorder
              disableSelectionOnClick
              showColumnRightBorder
              autoHeight={true}
              onCellClick={(params) => {
                handleClick("UnresolvedIssueList", params?.row);
              }}
            />
            <div
              style={{
                display: "flex",
                padding: 20,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pagination
                count={Math.ceil(issueHistoryList?.pagination?.totalPage)}
                defaultPage={pageNo}
                boundaryCount={2}
                siblingCount={0}
                variant="outlined"
                shape="rounded"
                onChange={(v, e) => {
                  handlePageNo(e);
                }}
              />
            </div>
          </div>
        ) : (
          <div className={styles.dataMain}>
            <Typography>No Data</Typography>
          </div>
        )}
      </div>
    </div>
  );
}

export default forwardRef(IssueList);
