import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
    marginBottom: 20,
    "@media (max-width:425px)": {
      padding: 10,
      marginBottom: 10,
    },
  },
  tabBtn: {
    padding: "12px 20px",
  },
  inputFieldStyle: {
    marginBottom: 0,
    marginTop: 0,
  },
  dataMain: {
    height: 500,
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconOpen: {},
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
  customtable: {
    "& thead tr th": {
      border: "none !important",
      padding: "5px 10px 0 10px",
      background: "transparent !important",
    },
    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: "#EEF8FF",
      },
    },
  },
}));
export default useStyles;
