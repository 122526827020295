import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  modalHeader: {
    backgroundColor: color.primary,
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
  modalMain: {
    padding: 20,
    display: "flex",
    flexDirection: "column",
  },
  autocomplete: {
    paddingLeft: "0px !important",
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },
}));
export default useStyles;
