import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import {
  Typography,
  TableRow,
  TableCell,
  TableBody,
  Table,
  CircularProgress,
  Pagination,
  MenuItem,
  Select,
  Grid,
  Button,
  InputAdornment,
  TextField,
  ListItemButton,
  Modal,
  TableHead,
  Fade,
  Backdrop,
  Tooltip,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import {
  FilterAlt,
  Search,
  Visibility,
  Close,
  ArrowBackOutlined,
  Delete,
} from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Images from "../../../Config/Images.js";
import * as XLSX from "xlsx/xlsx.mjs";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";
import { isTablet } from "react-device-detect";
import TabVehicleDetailTable from "../../TabFleet/TabVehicleDetalsTable/index.js";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";
import ConfirmDialog from "../../ConfirmDialog/index.js";
import { useNavigate } from "react-router-dom";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

const errorObj = {
  kmErr: false,
  kmMsg: "",
};
export default function TabInspectionDetails(props) {
  const {
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleFilter = () => null,
    pageNo = 1,
    record = 10,
    filter = "",
    inspectionData,
  } = props;
  const styles = useStyles();
  const { token, dropDownData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );
  const navigate = useNavigate();
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [fleetDetails, setFleetDetails] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  const [pageLoad1, setPageLoad1] = useState(false);
  // const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [inspectionHistoryList, setinspectionHistoryListRows] = useState({});
  const [activeIndex, setActiveIndex] = useState(null);
  const [rowData, setRowData] = useState({});
  const [kmReading, setKmReading] = useState("");
  const [errObj, setErrObj] = useState(errorObj);
  const [visible, setVisible] = useState(false);
  const currency_Regex = Setting.JS_Regex.currency_Regex;
  const [loader, setLoader] = useState(false);
  const [deleteData, setDeleteData] = useState({});
  const [confirmDialog, setConfirmDialog] = useState(false);
  const [removeBtnLoad, setRemoveBtnLoad] = useState(false);
  const [reportLoader, setReportLoader] = useState(false);

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "80vw",
    bgcolor: "background.paper",
    borderRadius: 1,
    boxShadow: 24,
    p: 4,
    padding: 0,
  };

  useEffect(() => {
    setPageLoad(true);
    getFleetDetails();
  }, []);

  useEffect(() => {
    setPageLoad1(true);
    getInspectionHistoryList(false);
  }, [pageNo, record, filter]);

  useEffect(() => {
    isNull(datePicker) &&
      isEmpty(searchFilter) &&
      getInspectionHistoryList(false);
  }, [datePicker, searchFilter]);

  useEffect(() => {
    setActiveIndex(null);
  }, [pageNo, record]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_meV");
    section.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  }, [pageLoad]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_meI");
    section.scrollIntoView({
      behavior: "auto",
      block: "center",
    });
  }, [pageNo]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [activeIndex]);

  const submitData = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.addreading;

    const params = {
      "VehicleReading[fleet_id]": rowData?.fleet_id,
      "VehicleReading[vehicle_id]": rowData?.vehicle_id,
      "VehicleReading[inspection_date]": rowData?.capture_date,
      "VehicleReading[km_reading]": kmReading.replaceAll(",", ""),
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        toast.success(resp?.message);
        resetModal();
        getInspectionHistoryList(false);
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };
  const getFleetDetails = async () => {
    setActiveIndex(null);
    try {
      const response = await getApiData(
        `${Setting.endpoints.fleetdetails}?id=${inspectionData.fleet_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setFleetDetails(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  async function getInspectionHistoryList(bool, v) {
    !v && setPageLoad1(true);
    v && setReportLoader(true);
    const date = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";

    let endpoints = `${
      Setting.endpoints.inspectionhistorylist
    }?page=${pageNo}&per_page=${record}&search=${
      bool ? "" : searchFilter
    }&vehicle_id=${inspectionData?.vehicle_id}&filter=${filter}&date=${
      inspectionData.date === undefined ? date : inspectionData.date
    }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            downloadInspectionHistoryList(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setinspectionHistoryListRows(listObj);
          }
      } else {
        toast.error(response?.message);
      }
      v && auditSave("Inspection History List");
      setPageLoad1(false);
      setReportLoader(false);
    } catch (error) {
      setPageLoad1(false);
      setReportLoader(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function resetFilterData() {
    setSearchFilter("");
    setDatePicker(null);
    handleFilter("");
    handlePageNo(1);
    inspectionData.date = undefined;
    getInspectionHistoryList(true);
  }

  function downloadInspectionHistoryList(data) {
    const newData = data.map((row, index) => {
      let data = row?.issue_noted;
      let arr = [];
      let issueObj = Object.keys(data).map((item) => {
        arr.push(`${item}: ${data[item]}`);
      });
      const getTerrain = () => {
        let array = row?.terrain_ids?.split(",");
        let res = dropDownData?.terrain.filter((item) => {
          let a = array?.includes(item?.id?.toString());
          return a;
        });
        const arr = [];
        res.map((v, i) => arr.push(v?.label));
        return arr.toString();
      };
      const newObj = {
        "Sr. No.": index + 1,
        // "Vehicle No.": row?.vehicle_number,
        "Issues Noted": arr.toString() || "-",
        "Inspection Date": row?.capture_date || "-",
        "Vehicle Type": row?.vehicle_type || "-",
        "Vehicle Brand": row?.vehicle_brand || "-",
        "Vehicle Model": row?.vehicle_model || "-",
        Terrain: getTerrain() || "-",
        "Load Capacity": `${row?.load_capicity}${
          row?.load_capicity ? " Tonnes" : "-"
        }`,
        "No. of Tyres": `${row?.added_tyre_count}/${row?.no_of_tyres}`,
        "Initial Reading (km)": Number(
          row?.initial_reading.toString()?.replaceAll(",", "")
        ),
        "Inspections (Month)": Number(row?.inspection_monthly),
        " Inspections (Overall)": Number(row?.inspection_overall),
        "Added By": row?.inspected_by || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      "Inspection History List"
    );
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Inspection History List.xlsx");
  }

  async function deleteInspection() {
    setRemoveBtnLoad(true);
    let endPoints = `${Setting.endpoints.deleteInspection}?vehicle_id=${deleteData?.vehicle_id}&capture_date=${deleteData?.capture_date}`;
    try {
      const response = await getApiData(
        endPoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setConfirmDialog(false);
        toast.success(response?.message);
        setDeleteData({});
        setActiveIndex("");
        getInspectionHistoryList();
      } else {
        toast.error(response?.message);
      }
      setRemoveBtnLoad(false);
    } catch (error) {
      setRemoveBtnLoad(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  function resetModal() {
    setVisible(false);
    setErrObj(errorObj);
    setKmReading("");
    setRowData({});
  }
  function checkValidation() {
    let valid = true;
    const error = { ...errObj };

    let c = kmReading.replaceAll(",", "");
    if (isEmpty(kmReading)) {
      valid = false;
      error.kmErr = true;
      error.kmMsg = "Please enter Inspection Reading";
    } else if (!isEmpty(kmReading) && !currency_Regex.test(c)) {
      valid = false;
      error.kmErr = true;
      error.kmMsg = "Please enter valid Kms";
    }

    setErrObj(error);
    if (valid) {
      submitData();
    }
  }
  const InspectionReading = (index) => {
    return (
      <div>
        <Button
          variant="contained"
          onClick={() => {
            setRowData(inspectionHistoryList?.data[index]);
            converter(inspectionHistoryList?.data[index]?.reading);
            setVisible(true);
          }}
        >
          {inspectionHistoryList?.data[index]?.is_reading_pending === 0
            ? "Edit"
            : "Add"}
        </Button>
      </div>
    );
  };
  function numberWithCommas(x) {
    let c = x.replaceAll(",", "");

    return c.toString().split(".")[0].length > 3
      ? c
          .toString()
          .substring(0, c.toString().split(".")[0].length - 3)
          .replace(/\B(?=(\d{2})+(?!\d))/g, ",") +
          "," +
          c.toString().substring(c.toString().split(".")[0].length - 3)
      : c.toString();
  }
  function converter(value) {
    let convert = numberWithCommas(value);
    setKmReading(convert);
    setErrObj({
      ...errObj,
      kmErr: false,
      kmMsg: "",
    });
  }
  const getNoOftyre = (index) => {
    return (
      <>
        <span
          style={{
            color:
              inspectionHistoryList?.data[index]?.added_tyre_count != (0 || "")
                ? "red"
                : "",
          }}
        >
          {inspectionHistoryList?.data[index]?.added_tyre_count}
        </span>
        <span>/{inspectionHistoryList?.data[index]?.no_of_tyres}</span>
      </>
    );
  };
  const Terrain = (index) => {
    let array = inspectionHistoryList?.data[index]?.terrain_ids.split(",");
    let res = dropDownData.terrain.filter((item) => {
      let a = array.includes(item?.id?.toString());
      return a;
    });
    return (
      <span
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {res.map((items, i) => {
          return (
            <Typography>
              {items?.label}
              {i < res.length - 1 ? ", " : ""}
            </Typography>
          );
        })}
      </span>
    );
  };
  const getAddedOn = (index) => {
    const timestamp = inspectionHistoryList?.data[index]?.capture_date;
    const displayDate = moment(timestamp).format("DD-MM-YYYY");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };
  useEffect(() => {
    if (inspectionData?.set === "dashboardDetails") {
      const displayDate = moment(inspectionData?.date);
      setDatePicker(displayDate);
    }
  }, []);

  return (
    <>
      {isTablet ? (
        <>
          <Box>
            <Button
              id="jump_to_meV"
              style={{ backgroundColor: color.secondary, marginBottom: 10 }}
              onClick={() => {
                if (inspectionData?.set === "dashboardDetails") {
                  navigate("dashboard");
                } else {
                  handleClick("cancel");
                }
              }}
            >
              Back
            </Button>
          </Box>
          <div>
            <TabVehicleDetailTable
              vehicleDetail={{ id: inspectionData?.vehicle_id }}
            />
          </div>
          <div
            className={styles.container}
            style={{ marginTop: 20, padding: 20 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="tableTitle" id="jump_to_meI">
                  Inspection History
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disabled={isOnline ? false : true || reportLoader}
                  onClick={() => getInspectionHistoryList(false, 1)}
                >
                  {reportLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 10 }}
              wrap="nowrap"
            >
              <Select
                placeholder="Filter"
                value={filter}
                onChange={(v) => {
                  handlePageNo(1);
                  handleFilter(v.target.value);
                }}
                onOpen={() => {
                  if (isEmpty(filter.toString())) {
                    settypeList(false);
                    setbrandList(false);
                  }
                }}
                displayEmpty
                IconComponent={FilterAlt}
                style={filter === "" ? { color: "#A2A2A2" } : {}}
                classes={{
                  iconOpen: styles.iconOpen,
                }}
              >
                <MenuItem value={""} hidden selected style={{ minWidth: 220 }}>
                  Filter
                </MenuItem>
                <ListItemButton
                  onClick={() => {
                    settypeList(!typeList);
                    setbrandList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Vehicle Type</Typography>
                  {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.vehicle_type.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: typeList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
                <ListItemButton
                  onClick={() => {
                    setbrandList(!brandList);
                    settypeList(false);
                  }}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Typography>Vehicle Brand</Typography>
                  {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </ListItemButton>
                {dropDownData.vehicle_brand.map((item, index) => {
                  return (
                    <MenuItem
                      style={{
                        display: brandList ? "flex" : "none",
                      }}
                      key={index}
                      value={item?.id}
                    >
                      {item?.label}
                    </MenuItem>
                  );
                })}
              </Select>
              <Select
                value={record}
                onChange={(v) => {
                  handlePageNo(1);
                  handleRecord(v.target.value);
                }}
                style={{ marginLeft: 10 }}
              >
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={25}>25</MenuItem>
                <MenuItem value={50}>50</MenuItem>
                <MenuItem value={100}>100</MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              lg={8}
              style={{ marginTop: 10, display: "flex", alignItems: "center" }}
            >
              <Grid
                item
                lg={4}
                style={{ display: "flex", alignItems: "center" }}
                wrap={"nowrap"}
              >
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getInspectionHistoryList(true);
                    setSearchFilter(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                        handlePageNo(1);
                        getInspectionHistoryList(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Vehicle No."
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <div style={{ marginLeft: 10 }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </div>
              <div style={{ marginLeft: 10, display: "flex", wrap: "nowrap" }}>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      getInspectionHistoryList(false);
                    }
                  }}
                >
                  <Search />
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  disabled={!searchFilter && !datePicker && !filter}
                  onClick={resetFilterData}
                >
                  <ResetIcon />
                </Button>
              </div>
            </Grid>
            {pageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(inspectionHistoryList?.data) &&
              !isEmpty(inspectionHistoryList?.data) ? (
              <>
                {inspectionHistoryList?.data?.map((item, index) => {
                  return (
                    <div style={{ marginTop: 10 }}>
                      <Grid
                        container
                        style={{
                          border: `1px solid ${color.bordercolor}`,
                          boxShadow: color.shadow,
                        }}
                        overflow="auto"
                      >
                        <Table className={styles.customtable}>
                          <TableBody>
                            <TableRow>
                              {/* <TableCell variant="head">Vehicle No.</TableCell> */}
                              <TableCell variant="head" align="center">
                                Inspection Reading
                              </TableCell>
                              <TableCell variant="head">Issues Noted</TableCell>
                              <TableCell variant="head">
                                Inspection Date
                              </TableCell>
                              <TableCell variant="head">Vehicle Type</TableCell>
                              <TableCell variant="head">
                                Vehicle Brand
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              {/* <TableCell>
                                {item?.vehicle_number || "-"}
                              </TableCell> */}
                              <TableCell align="center">
                                {InspectionReading(index) || "-"}
                              </TableCell>
                              <TableCell>
                                {isEmpty(item?.issue_noted)
                                  ? "-"
                                  : Object.keys(item?.issue_noted)?.map(
                                      (key) => {
                                        return (
                                          <Typography>
                                            {key}:{item?.issue_noted[key]}
                                          </Typography>
                                        );
                                      }
                                    )}
                              </TableCell>
                              <TableCell>{getAddedOn(index) || "-"}</TableCell>
                              <TableCell>{item?.vehicle_type || "-"}</TableCell>
                              <TableCell>
                                {item?.vehicle_brand || "-"}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Vehicle Model
                              </TableCell>
                              <TableCell variant="head">Terrain</TableCell>
                              <TableCell variant="head">
                                Load Capacity
                              </TableCell>
                              <TableCell variant="head">No. of Tyres</TableCell>
                              <TableCell variant="head">
                                Initial Reading
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {item?.vehicle_model || "-"}
                              </TableCell>
                              <TableCell>{Terrain(index) || "-"}</TableCell>
                              <TableCell>
                                {item?.load_capicity || "-"} Tonnes
                              </TableCell>
                              <TableCell>{getNoOftyre(index) || "-"}</TableCell>
                              <TableCell>
                                {item?.initial_reading || "-"} Km
                              </TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Inspections (Month)
                              </TableCell>
                              <TableCell variant="head">
                                Inspections (Overall)
                              </TableCell>
                              <TableCell variant="head">Added By</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {item?.inspection_monthly || "0"}
                              </TableCell>
                              <TableCell>
                                {item?.inspection_overall || "0"}
                              </TableCell>
                              <TableCell>{item?.inspected_by || "-"}</TableCell>
                            </TableRow>
                          </TableBody>

                          <TableBody>
                            <TableRow>
                              <TableCell colSpan={5}>
                                <Grid
                                  container
                                  justifyContent={"space-between"}
                                  alignItems={"center"}
                                  padding="10px 20px"
                                >
                                  <Button
                                    style={{
                                      backgroundColor: "red",
                                    }}
                                    onClick={() => {
                                      setDeleteData(item);
                                      setConfirmDialog(true);
                                    }}
                                  >
                                    <Delete />
                                  </Button>
                                  <Button
                                    style={{
                                      backgroundColor: color.primary,
                                    }}
                                    onClick={() => {
                                      handleClick("vehicleInspection", item);
                                    }}
                                  >
                                    <Visibility />
                                  </Button>
                                </Grid>
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </div>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    marginTop: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      inspectionHistoryList?.pagination?.totalPage
                    )}
                    defaultPage={pageNo}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Box>
            {isKeyboardOpen && !isTablet ? null : (
              <Button
                style={{
                  backgroundColor: color.secondary,
                  position: "fixed",
                  top: 10,
                  zIndex: 10,
                }}
                onClick={() => {
                  if (inspectionData?.set === "dashboardDetails") {
                    navigate("dashboard");
                  } else {
                    handleClick("cancel");
                  }
                }}
              >
                {<ArrowBackOutlined />}
              </Button>
            )}
          </Box>
          <div id="jump_to_meV">
            <TabVehicleDetailTable
              vehicleDetail={{ id: inspectionData?.vehicle_id }}
            />
          </div>
          <div
            className={styles.container}
            style={{ marginTop: 10, padding: 10 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item>
                <Typography variant="tableTitle" id="jump_to_meI">
                  Inspection History
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disable={isOnline ? false : true || reportLoader}
                  onClick={() => getInspectionHistoryList(false, 1)}
                >
                  {reportLoader ? (
                    <CircularProgress
                      style={{ color: color.white }}
                      size={24}
                    />
                  ) : (
                    <img src={Images.Mdownload} alt="download" />
                  )}
                </Button>
              </Grid>
            </Grid>
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 10 }}
              wrap="nowrap"
              gap={1}
            >
              <Grid item>
                <Select
                  placeholder="Filter"
                  value={filter}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleFilter(v.target.value);
                  }}
                  onOpen={() => {
                    if (isEmpty(filter.toString())) {
                      settypeList(false);
                      setbrandList(false);
                    }
                  }}
                  displayEmpty
                  IconComponent={FilterAlt}
                  style={filter === "" ? { color: "#A2A2A2" } : {}}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem
                    value={""}
                    disabled
                    hidden
                    selected
                    style={{ minWidth: 220 }}
                  >
                    Filter
                  </MenuItem>
                  <ListItemButton
                    onClick={() => {
                      settypeList(!typeList);
                      setbrandList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Vehicle Type</Typography>
                    {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.vehicle_type.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: typeList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setbrandList(!brandList);
                      settypeList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Vehicle Brand</Typography>
                    {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.vehicle_brand.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: brandList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item>
                <Select
                  value={record}
                  onChange={(v) => {
                    handlePageNo(1);
                    handleRecord(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }}>
              <TextField
                fullWidth
                placeholder="Search"
                className={styles.inputFieldStyle}
                value={searchFilter}
                onChange={(v) => {
                  isEmpty(v.target.value) && getInspectionHistoryList(true);
                  setSearchFilter(v?.target?.value);
                }}
                onKeyPress={(ev) => {
                  if (ev.key === "Enter") {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      handlePageNo(1);
                      getInspectionHistoryList(false);
                    }
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <div style={{ lineHeight: 0 }}>
                        <Search style={{ fontSize: 20 }} />
                      </div>
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <div style={{ lineHeight: 0, cursor: "pointer" }}>
                        <Tooltip
                          title="Search by Vehicle No."
                          placement="bottom"
                          arrow
                        >
                          <InfoIcon style={{ fontSize: 20 }} />
                        </Tooltip>
                      </div>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid
              item
              lg={8}
              style={{ marginTop: 10, display: "flex", alignItems: "center" }}
              columnGap={1}
              wrap="nowrap"
            >
              <Grid item>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (!isEmpty(searchFilter) || !isNull(datePicker)) {
                      getInspectionHistoryList(false);
                    }
                  }}
                >
                  <Search />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={!searchFilter && !datePicker && !filter}
                  onClick={resetFilterData}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
            {pageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(inspectionHistoryList?.data) &&
              !isEmpty(inspectionHistoryList?.data) ? (
              <>
                {inspectionHistoryList?.data?.map((item, index) => {
                  return (
                    <div style={{ position: "relative" }}>
                      <Grid
                        container
                        id={`jump_to_me${index}`}
                        style={{
                          border: `1px solid ${color.bordercolor}`,
                          boxShadow: color.shadow,
                          overflow: "hidden",
                          marginTop: 20,
                        }}
                      >
                        <Table
                          className={styles.InspectionHistory_CustomtableMobile}
                        >
                          {activeIndex !== index ? (
                            <>
                              <TableHead>
                                <TableRow>
                                  {/* <TableCell variant="head">Vehicle No.</TableCell> */}
                                  <TableCell variant="head">
                                    Inspection Reading
                                  </TableCell>
                                  <TableCell variant="head">
                                    Inspection Date
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  {/* <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.vehicle_number || "-"}
                              </TableCell> */}
                                  <TableCell style={{ paddingBottom: 5 }}>
                                    {InspectionReading(index)}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getAddedOn(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle Type
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle Brand
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_type || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_brand || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </>
                          ) : (
                            <>
                              <TableHead>
                                <TableRow>
                                  {/* <TableCell variant="head">Vehicle No.</TableCell> */}
                                  <TableCell variant="head">
                                    Inspection Reading
                                  </TableCell>
                                  <TableCell variant="head">
                                    Inspection Date
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  {/* <TableCell
                                variant="body"
                                style={{ paddingBottom: 5 }}
                              >
                                {item?.vehicle_number || "-"}
                              </TableCell> */}
                                  <TableCell style={{ paddingBottom: 5 }}>
                                    {InspectionReading(index)}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getAddedOn(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle Type
                                  </TableCell>
                                  <TableCell variant="head">
                                    Vehicle Brand
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_type || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_brand || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Vehicle Model
                                  </TableCell>
                                  <TableCell variant="head">Terrain</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.vehicle_model || "-"}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {Terrain(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Load Capacity
                                  </TableCell>
                                  <TableCell variant="head">
                                    No. of Tyres
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.load_capicity || "-"} Tonnes
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {getNoOftyre(index) || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Initial Reading
                                  </TableCell>
                                  <TableCell variant="head">
                                    Inspections
                                  </TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.initial_reading || "-"} Km
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    <Grid item container>
                                      <Grid item container>
                                        <Grid xs={6}>Month :</Grid>
                                        <Grid xs={6}>
                                          <Typography>
                                            {item?.inspection_monthly || "-"}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                      <Grid item container>
                                        <Grid xs={6}>Overall :</Grid>
                                        <Grid xs={6}>
                                          <Typography>
                                            {item?.inspection_overall || "-"}
                                          </Typography>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell variant="head">
                                    Issues Noted
                                  </TableCell>
                                  <TableCell variant="head">Added By</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {isEmpty(item?.issue_noted)
                                      ? "-"
                                      : Object.keys(item?.issue_noted)?.map(
                                          (key) => {
                                            return (
                                              <Typography>
                                                {key}:{item?.issue_noted[key]}
                                              </Typography>
                                            );
                                          }
                                        )}
                                  </TableCell>
                                  <TableCell
                                    variant="body"
                                    style={{ paddingBottom: 5 }}
                                  >
                                    {item?.inspected_by || "-"}
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                              <TableHead>
                                <TableRow>
                                  <TableCell
                                    rowSpan={2}
                                    style={{
                                      padding: "10px 0px 10px 20px",
                                      color: "red",
                                      display: "flex",
                                    }}
                                  >
                                    <Button
                                      style={{
                                        backgroundColor: "red",
                                      }}
                                      onClick={() => {
                                        setDeleteData(item);
                                        setConfirmDialog(true);
                                      }}
                                    >
                                      <Delete />
                                    </Button>
                                  </TableCell>
                                  <TableCell
                                    rowSpan={2}
                                    align="right"
                                    style={{ padding: "10px 20px 10px 0px" }}
                                  >
                                    <Button
                                      style={{ backgroundColor: color.primary }}
                                      onClick={() => {
                                        handleClick("vehicleInspection", item);
                                      }}
                                    >
                                      <Visibility />
                                    </Button>
                                  </TableCell>
                                </TableRow>
                              </TableHead>
                            </>
                          )}
                        </Table>
                        <div
                          style={{
                            zIindex: 1,
                            color: color.primary,
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            bottom: -18,
                            left: "45%",
                          }}
                          onClick={() => {
                            activeIndex === index
                              ? setActiveIndex(null)
                              : setActiveIndex(index);
                          }}
                        >
                          {activeIndex === index ? (
                            <div style={{ transform: "rotate(180deg)" }}>
                              <TableToggle />
                            </div>
                          ) : (
                            <TableToggle />
                          )}
                        </div>
                      </Grid>
                    </div>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    marginTop: 30,
                    marginBottom: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(
                      inspectionHistoryList?.pagination?.totalPage
                    )}
                    defaultPage={pageNo}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      handlePageNo(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      )}
      <Modal
        open={visible}
        onClose={() => resetModal()}
        closeAfterTransition
        disableAutoFocus
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={visible}>
          <Box sx={style}>
            <div className={styles.modalHeader}>
              <Typography
                style={{ fontSize: 16, color: color.white, fontWeight: "bold" }}
              >
                {rowData?.is_reading_pending === 0
                  ? "Edit Reading"
                  : "Add Reading"}
              </Typography>
              <Close
                style={{ color: color.white, cursor: "pointer" }}
                onClick={() => resetModal()}
              />
            </div>
            <div className={styles.modalMain}>
              <Grid
                container
                alignItems={"center"}
                style={{
                  marginBottom: errObj.kmErr ? 30 : 20,
                  marginTop: 10,
                }}
              >
                <Grid item xs={12} sm={12} md={3} lg={3}>
                  <CTypography required title={`Inspection Reading`} />
                </Grid>
                <Grid item xs={12} sm={12} md={9} lg={9}>
                  <TextField
                    disabled={
                      userdata?.role === "fleet_owner" ||
                      userdata?.role === "fleet_manager"
                    }
                    style={{
                      width: "100%",
                      margin: 0,
                    }}
                    placeholder={`Enter Inspection Reading`}
                    value={kmReading}
                    className={styles.input}
                    error={errObj.kmErr}
                    helperText={errObj.kmErr ? errObj.kmMsg : null}
                    onChange={(e) => {
                      if (
                        e?.nativeEvent?.data === "0" ||
                        e?.nativeEvent?.data === "1" ||
                        e?.nativeEvent?.data === "2" ||
                        e?.nativeEvent?.data === "3" ||
                        e?.nativeEvent?.data === "4" ||
                        e?.nativeEvent?.data === "5" ||
                        e?.nativeEvent?.data === "6" ||
                        e?.nativeEvent?.data === "7" ||
                        e?.nativeEvent?.data === "8" ||
                        e?.nativeEvent?.data === "9" ||
                        e?.nativeEvent?.data === "." ||
                        isNull(e?.nativeEvent?.data)
                      ) {
                        // converter(e.target.value, "fitment");
                        setKmReading(e.target.value);
                        setErrObj({ ...errObj, kmErr: false, kmMsg: "" });
                      }
                    }}
                    onKeyPress={(ev) => {
                      if (ev.key === "Enter") {
                        ev.preventDefault();
                        checkValidation();
                      }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">Km</InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Button
                  disabled={
                    (userdata?.role === "fleet_owner" ||
                      userdata?.role === "fleet_manager") &&
                    !isOnline
                  }
                  variant="contained"
                  onClick={checkValidation}
                >
                  {loader ? (
                    <CircularProgress style={{ color: "#fff" }} size={18} />
                  ) : rowData?.is_reading_pending === 0 ? (
                    "Save & Update"
                  ) : (
                    "Add Reading"
                  )}
                </Button>
              </div>
            </div>
          </Box>
        </Fade>
      </Modal>
      <ConfirmDialog
        title={`Are you sure you want to Delete this Inspection?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteInspection();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={removeBtnLoad}
      />
    </>
  );
}
