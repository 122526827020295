import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import { Breadcrumbs, Grid, Link, Typography } from "@mui/material";
import { isEmpty, isNull } from "lodash";
import FleetView from "../../../Components/Fleet/FleetView";
import AddFleetForm from "../../../Components/Fleet/AddFleetForm";
import FleetDetails from "../../../Components/Fleet/FleetDetails/index.js";
import AddVehicle from "../../../Components/Fleet/AddVehicle/index.js";
import VehicleDetail from "../../../Components/Fleet/VehicleDetail/index.js";
import { useLocation } from "react-router-dom";
import UnresolvedIssue from "../../../Components/Fleet/UnresolvedIssue/index.js";
import IssueList from "../../../Components/Fleet/IssueList/index.js";
import ResolveIssue from "../../../Components/InspectionManagement/ResolveIssue/index.js";
import { color } from "../../../Config/theme.js";

export default function FleetManagement() {
  const styles = useStyles();
  const [fleetData, setFleetData] = useState({});
  const [changeTab, setChangeTab] = useState("");
  const [fromVehicle, setFromVehicle] = useState({});
  const [issueData, setIssueData] = useState({});
  const [resolveData, setResolveData] = useState({});
  const [issueType, setIssueType] = useState("");
  const [issue, setIssue] = useState("");
  const location = useLocation();

  const [tabValue, setTabValue] = useState(0);

  const [pageNoUI, setPageNoUI] = useState(1);
  const [recordUI, setRecordUI] = useState(10);
  const [filterUI, setFilterUI] = useState("");

  const [pageNoIL, setPageNoIL] = useState(1);
  const [recordIL, setRecordIL] = useState(10);

  const [pageNoFL, setPageNoFL] = useState(1);
  const [recordFL, setRecordFL] = useState(10);
  const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);

  const [pageNoV, setPageNoV] = useState(1);
  const [recordV, setRecordV] = useState(10);
  const [filterV, setFilterV] = useState("");
  const [searchFilterV, setSearchFilterV] = useState("");
  const [datePickerV, setDatePickerV] = useState(null);
  const [datePickerV1, setDatePickerV1] = useState(null);

  const fromInspection =
    !isNull(location?.state) && location?.state?.set === "fleetDetails";
  const fromDeshboard =
    !isNull(location?.state) && location?.state?.set === "dashboardDetails";

  const dashboard = location?.state?.set === "vehicleList";

  useEffect(() => {
    if (fromInspection) {
      setChangeTab("fleetDetails");
      setFleetData(location?.state);
    }
    if (fromDeshboard) {
      setChangeTab("vehicleDetails");
      setFleetData(location?.state);
    }
    if (dashboard) {
      setChangeTab("");
    }
  }, []);

  useEffect(() => {
    setFromVehicle({});
    document.title = Setting.page_name.FleetManagement;
  }, []);

  useEffect(() => {
    if (changeTab === "editVehicle") {
      const section = document.querySelector("#jump_to_me");
      section.scrollIntoView({ behavior: "auto", block: "end" });
    }
  }, [changeTab]);

  return (
    <div className={styles.card}>
      {changeTab === "fleetDetails" ? (
        <FleetDetails
          fleetData={!isEmpty(fromVehicle) ? fromVehicle : fleetData}
          from={fromInspection ? "inspection" : ""}
        />
      ) : changeTab === "vehicleDetails" ? (
        <VehicleDetail
          vehicleDetails={fleetData}
          handleClick={(data) => {
            setFromVehicle(data);
            setTimeout(() => {
              setChangeTab("fleetDetails");
            }, 200);
          }}
        />
      ) : (
        <>
          <Grid container id="jump_to_me">
            <Grid item xs={12} className={styles.breadcrumb}>
              <Breadcrumbs separator="›">
                <Link
                  underline="hover"
                  color="inherit"
                  href="/"
                  className={styles.linkStyle}
                >
                  Dashboard
                </Link>
                {changeTab === "fleet" ||
                changeTab === "fleetDetails" ||
                changeTab === "editVehicle" ||
                changeTab === "UnresolvedIssue" ||
                changeTab === "UnresolvedIssueList" ? (
                  <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    className={styles.linkStyle}
                    onClick={() => setChangeTab("")}
                  >
                    Fleet Management
                  </Link>
                ) : (
                  <Typography className={styles.linkStyle}>
                    Fleet Management
                  </Typography>
                )}
                {changeTab === "editVehicle" && (
                  <Typography className={styles.linkStyle}>
                    {fleetData?.fleet_name}
                  </Typography>
                )}
                {changeTab === "editVehicle" && (
                  <Typography className={styles.linkStyle}>
                    Edit Vehicle
                  </Typography>
                )}
                {changeTab === "fleet" && (
                  <Typography className={styles.linkStyle}>
                    {!isEmpty(fleetData) ? "Edit Fleet" : "Add Fleet"}
                  </Typography>
                )}
                {changeTab === "UnresolvedIssue" && (
                  <Typography className={styles.linkStyle}>
                    Unresolved Issues
                  </Typography>
                )}
                {(changeTab === "UnresolvedIssueList" ||
                  changeTab === "ResolveIssue") && (
                  <Link
                    underline="hover"
                    color="inherit"
                    href="#"
                    className={styles.linkStyle}
                    onClick={() => {
                      setFilterUI("");
                      setChangeTab("UnresolvedIssue");
                    }}
                  >
                    Unresolved Issues
                  </Link>
                )}
                {changeTab === "ResolveIssue" && (
                  <Link
                    underline="hover"
                    color="inherit"
                    className={styles.linkStyle}
                    onClick={() => {
                      setChangeTab("UnresolvedIssueList");
                    }}
                    href="#"
                  >
                    Unresolved Tyre List
                  </Link>
                )}
                {changeTab === "UnresolvedIssueList" && (
                  <Typography className={styles.linkStyle}>
                    Unresolved Tyre List
                  </Typography>
                )}

                {changeTab === "ResolveIssue" && (
                  <Typography className={styles.linkStyle}>
                    Resolve Issue
                  </Typography>
                )}
              </Breadcrumbs>
            </Grid>
          </Grid>
          <div className={styles.container}>
            {changeTab === "fleet" ? (
              <div style={{ border: `1px solid ${color.bordercolor}` }}>
                <AddFleetForm
                  handleClick={(v) => {
                    if (v === "cancel") {
                      setChangeTab("");
                    }
                  }}
                  fleetData={fleetData}
                />
              </div>
            ) : changeTab === "editVehicle" ? (
              <AddVehicle
                handleClick={(type) => {
                  if (type === "cancel") {
                    setChangeTab("");
                    setFleetData({});
                  }
                }}
                editData={fleetData}
              />
            ) : changeTab === "UnresolvedIssue" ? (
              <IssueList
                fleetData={fleetData}
                pageNo={pageNoIL}
                record={recordIL}
                handlePageNo={(page) => {
                  setPageNoIL(page);
                }}
                handleRecord={(rec) => {
                  setRecordIL(rec);
                }}
                handleClick={(type, data) => {
                  if (type === "UnresolvedIssueList") {
                    setPageNoUI(1);
                    setRecordUI(10);
                    setChangeTab("UnresolvedIssueList");
                    setIssueData(data);
                  }
                }}
              />
            ) : changeTab === "UnresolvedIssueList" ? (
              <UnresolvedIssue
                vehicleData={issueData}
                pageNo={pageNoUI}
                record={recordUI}
                filter={filterUI}
                handlePageNo={(page) => {
                  setPageNoUI(page);
                }}
                handleRecord={(rec) => {
                  setRecordUI(rec);
                }}
                handleFilter={(flt) => {
                  setFilterUI(flt);
                }}
                handleClick={(type, data, issType, iss, rec, page) => {
                  if (type === "ResolveIssue") {
                    setChangeTab("ResolveIssue");
                    setResolveData(data);
                    setIssueType(issType);
                    setIssue(iss);
                  }
                }}
              />
            ) : changeTab === "ResolveIssue" ? (
              <ResolveIssue
                handleClick={(type) => {
                  if (type === "cancel") {
                    setChangeTab("UnresolvedIssueList");
                  }
                }}
                vehicleDetails={resolveData}
                from={"fleet"}
                issueType={issueType}
                issue={issue}
              />
            ) : (
              <FleetView
                pageNo={pageNoFL}
                pageNoV={pageNoV}
                record={recordFL}
                recordV={recordV}
                filter={filter}
                filterV={filterV}
                searchFilter={searchFilter}
                searchFilterV={searchFilterV}
                datePicker={datePicker}
                datePicker1={datePicker1}
                datePickerV={datePickerV}
                datePickerV1={datePickerV1}
                handlePageNoFL={(page) => {
                  setPageNoFL(page);
                }}
                handlePageNoV={(page) => {
                  setPageNoV(page);
                }}
                handleRecordFL={(rec) => {
                  setRecordFL(rec);
                }}
                handleRecordV={(rec) => {
                  setRecordV(rec);
                }}
                handleFilterFL={(flt) => {
                  setFilter(flt);
                }}
                handleFilterV={(flt) => {
                  setFilterV(flt);
                }}
                handleSearch={(s) => {
                  setSearchFilter(s);
                }}
                handleSearchV={(s) => {
                  setSearchFilterV(s);
                }}
                handleDatePicker={(date) => {
                  setDatePicker(date);
                }}
                handleDatePicker1={(date) => {
                  setDatePicker1(date);
                }}
                handleDatePickerV={(date) => {
                  setDatePickerV(date);
                }}
                handleDatePickerV1={(date) => {
                  setDatePickerV1(date);
                }}
                handleClick={(v, data) => {
                  if (v === "addFleet") {
                    setTabValue(0);
                    setChangeTab("fleet");
                  } else if (v === "fleetDetails") {
                    setTabValue(0);
                    setChangeTab("fleetDetails");
                  } else if (v === "editVehicle") {
                    setTabValue(1);
                    setChangeTab("editVehicle");
                  } else if (v === "vehicleDetails") {
                    setTabValue(1);
                    setChangeTab("vehicleDetails");
                  } else if (v === "UnresolvedIssue") {
                    setPageNoIL(1);
                    setRecordIL(10);
                    setTabValue(0);
                    setChangeTab("UnresolvedIssue");
                  }
                  setFleetData(data);
                }}
                from={dashboard ? "vehicleList" : ""}
                tab={tabValue}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
}
