import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  fleetMain: {
    backgroundColor: color.white,
    marginTop: 20,
    borderRadius: 4,
    padding: 20,
    overflow: "auto",
    border: `1px solid ${color.bordercolor}`,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 500,
  },
  iconOpen: {},
}));
export default useStyles;
