import { makeStyles } from "@mui/styles";
import { isTablet } from "react-device-detect";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    // overflow: "auto",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    marginBottom: isTablet ? 0 : "3vh",
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
  customtable: {
    "& tr td": {
      border: "none !important",
      padding: 0,
      color: "#666666CC",
      width: "20%",
    },
    "& thead th": {
      border: "none !important",
      padding: 0,
      width: "20%",
      background: "transparent !important",
    },
  },
}));
export default useStyles;
