import { makeStyles } from "@mui/styles";
import { isTablet } from "react-device-detect";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  card: {
    flex: 1,
    display: "flex",
    boxSizing: "border-box",
    padding: isTablet ? 20 : 10,
    overflow: "hidden !important",
    flexDirection: "column",
    "@media (max-device-width:870px)": {
      marginBottom: "8vw",
    },
    "@media (max-device-width:425px)": {
      marginBottom: "8vh",
    },
  },
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
    overflow: "auto",
    padding: isTablet ? 20 : 10,
  },
  breadcrumb: {
    padding: "15px 0px",
  },
  linkStyle: {
    color: color.primary,
    fontSize: "14px !important",
    fontWeight: "600 !important",
  },
  tabBtn: {
    padding: "12px 20px",
  },
}));
export default useStyles;
