import React, { useEffect, useState } from "react";
import "./styles.js";
import { Setting } from "../../../Utils/Setting.js";
import Box from "@mui/material/Box";
import {
  Button,
  CircularProgress,
  MenuItem,
  Select,
  useMediaQuery,
} from "@mui/material";
import useStyles from "./styles.js";
import { color } from "../../../Config/theme";
import { Grid, TextField, Typography } from "@mui/material";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTheme } from "@emotion/react";
import { isArray, isEmpty } from "lodash";
import { CTypography } from "../../Fleet/AddFleetForm/index.js";

const errorObj = {
  readingErr: false,
  readingMsg: "",
};

export default function SwitchTyre(props) {
  const { handleClick = () => null, tyreDetails, vehicleDetails } = props;
  const styles = useStyles();

  const { token, dropDownData } = useSelector((state) => state.auth);
  const [reading, setReading] = useState("");
  const [positionList, setPositionList] = useState([]);
  const [errObj, setErrObj] = useState(errorObj);
  const [loader, setLoader] = useState(false);

  const [rotationArr, setRotationArr] = useState(tyreDetails?.data);

  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.down("md"));

  useEffect(() => {
    getFinalPositionData();
  }, []);

  function validation() {
    const error = { ...errObj };
    let valid = true;

    if (isEmpty(reading.toString())) {
      valid = false;
      error.readingErr = true;
      error.readingMsg = "Please enter Reading in Kms";
    }

    setErrObj(error);
    if (valid) {
      submitData();
    }
  }

  async function getFinalPositionData() {
    try {
      const response = await getApiData(
        `${Setting.endpoints.tyreposition}?vehicle_id=${vehicleDetails.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );

      if (response.status) {
        setPositionList(response.data);
      } else {
        toast.error(response?.message);
      }
    } catch (error) {
      console.log("error ===>>>", error);
      toast.error(error.toString());
    }
  }

  const submitData = async () => {
    setLoader(true);
    let endPoints = Setting.endpoints.switchTyre;
    const params = {
      "SwitchTyre[vehicle_id]": vehicleDetails?.id,
      "SwitchTyre[odo_meter_reading]": reading,
    };
    if (isArray(rotationArr) && !isEmpty(rotationArr)) {
      rotationArr.map((item, index) => {
        params[`SwitchTyre[tyre_id][${index}]`] = item?.id;
        params[`SwitchTyre[new_position][${index}]`] =
          item?.finalPosition || item?.tyre_position;
        params[`SwitchTyre[axle_type_id][${index}]`] =
          item?.finalAxleType || item?.axle_type_id;
      });
    }
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
      if (resp?.status) {
        handleClick("cancel");
        toast.success(resp?.message);
      } else {
        toast.error(resp?.message);
      }
      setLoader(false);
    } catch (error) {
      console.log("ERRRRR", error);
      setLoader(false);
    }
  };

  function SelectAxleType(value, index, type) {
    const dummyArr = [...rotationArr];

    dummyArr.map((item, i) => {
      if (i === index) {
        if (type === "axle") {
          item.finalAxleType = value;
        } else {
          item.finalPosition = value;
        }
      }
    });
    setRotationArr(dummyArr);
  }

  return (
    <Box>
      <Box
        style={{
          backgroundColor: color.primary,
          color: color.white,
          padding: "18px 20px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography fontWeight={"bold"}>Switch Tyre (Tyre Rotation)</Typography>
      </Box>
      <Box className={styles.container}>
        <Typography fontWeight={"bold"} style={{ marginBottom: 20 }}>
          Tyre Rotation
        </Typography>
        <Grid container style={{ marginBottom: 20 }} alignItems={"center"}>
          <Grid item xs={12} sm={12} md={2.1} lg={2}>
            <CTypography required title={"Inspection Reading"} />
          </Grid>

          <Grid item xs={12} md={9.9} lg={10}>
            <TextField
              style={{ width: "100%", margin: 0 }}
              placeholder="Enter the Reading in Kms"
              value={reading}
              onChange={(e) => {
                setReading(e?.target?.value.replace(/[^0-9]/g, ""));
                setErrObj({ ...errObj, readingErr: false, readingMsg: "" });
              }}
              error={errObj.readingErr}
              helperText={errObj.readingErr ? errObj.readingMsg : null}
            />
          </Grid>
        </Grid>
        {rotationArr.map((item, index) => {
          return (
            <Grid container style={{ marginBottom: 20 }}>
              <Grid item xs={12} sm={12} md={2.1} lg={2}>
                <Typography style={{ paddingTop: !md ? 10 : 0 }}>
                  {item?.tyre_serial_no}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={9.9} lg={10}>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography>Initial Position: </Typography>
                      </Grid>

                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          style={{ width: "100%", margin: 0 }}
                          placeholder="Initial Position"
                          value={item?.tyre_position || ""}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography style={{ marginLeft: !md ? 20 : 0 }}>
                          Final Position:
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={8} lg={8}>
                        <Select
                          fullWidth
                          displayEmpty
                          value={item?.finalPosition}
                          onChange={(event) => {
                            SelectAxleType(event.target.value, index, "");
                          }}
                          size={"medium"}
                          style={
                            !item?.finalPosition ? { color: "#A2A2A2" } : {}
                          }
                        >
                          <MenuItem value={undefined} disabled hidden selected>
                            Select Final Position
                          </MenuItem>
                          {positionList.map((item, index) => (
                            <MenuItem key={index} value={item?.id}>
                              {item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid container style={{ marginTop: !md ? 10 : 0 }}>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography>Initial Axle Type :</Typography>
                      </Grid>

                      <Grid item xs={12} md={8} lg={8}>
                        <TextField
                          style={{ width: "100%", margin: 0 }}
                          placeholder="Initial Axle Type"
                          value={item?.axle_type_name || ""}
                          inputProps={{
                            readOnly: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={6}>
                    <Grid container alignItems={"center"}>
                      <Grid item xs={12} sm={12} md={4} lg={4}>
                        <Typography style={{ marginLeft: !md ? 20 : 0 }}>
                          Final Axle Type :
                        </Typography>
                      </Grid>

                      <Grid item xs={12} md={8} lg={8}>
                        <Select
                          fullWidth
                          displayEmpty
                          value={item?.finalAxleType}
                          onChange={(event) => {
                            SelectAxleType(event.target.value, index, "axle");
                          }}
                          size={"medium"}
                          style={
                            !item?.finalAxleType ? { color: "#A2A2A2" } : {}
                          }
                        >
                          <MenuItem value={undefined} disabled hidden selected>
                            Select Final Axle Type
                          </MenuItem>
                          {dropDownData.axle_type.map((item, index) => (
                            <MenuItem key={index} value={item?.id}>
                              {item?.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        })}
        <Grid
          container
          alignItems={"center"}
          justifyContent={"center"}
          style={{ marginTop: 20 }}
        >
          <Button
            onClick={validation}
            style={{
              backgroundColor: color.primary,
              marginRight: 10,
              width: 120,
            }}
            disabled={loader}
          >
            {loader ? (
              <CircularProgress style={{ color: "#fff" }} size={18} />
            ) : (
              `Update`
            )}
          </Button>
          <Button
            style={{
              backgroundColor: color.secondary,
              width: 120,
            }}
            onClick={() => {
              handleClick("cancel");
            }}
          >
            Cancel
          </Button>
        </Grid>
      </Box>
    </Box>
  );
}
