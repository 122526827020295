import { makeStyles } from "@mui/styles";
import { color } from "../../../Config/theme";

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: 4,
    boxSizing: "border-box",
    backgroundColor: color.white,
    display: "flex",
    flex: 1,
    overflow: "auto",
    flexDirection: "column",
    border: `1px solid ${color.bordercolor}`,
  },
  modalHeader: {
    backgroundColor: color.primary,
    padding: 20,
    display: "flex",
    justifyContent: "space-between",
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
  dataMain: {
    marginTop: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: 300,
  },
  inputField: {
    backgroundColor: "#ffffff",
  },
  datePicker: {
    "& .MuiButton-root": {
      color: color.primary,
    },
    "& .MuiSvgIcon-root": {
      color: color.primary,
    },
  },

  customeTableMobile: {
    "& thead th": {
      border: "none !important",
      padding: "5px 10px",
      width: "50%",
      background: "transparent !important",
    },

    "& thead": {
      "&:nth-child(even)": {
        backgroundColor: "#EEF8FF",
      },
    },
  },
}));
export default useStyles;
