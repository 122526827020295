import React, { useEffect, useState } from "react";
import useStyles from "./styles.js";
import { Setting } from "../../../Utils/Setting";
import Images from "../../../Config/Images";
import {
  Typography,
  TableRow,
  TableCell,
  Table,
  CircularProgress,
  Pagination,
  MenuItem,
  Select,
  Grid,
  Button,
  InputAdornment,
  TextField,
  ListItemButton,
  Tooltip,
  Popover,
  TableHead,
  TableBody,
} from "@mui/material";
import { color } from "../../../Config/theme.js";
import { getApiData, getAPIProgressData } from "../../../Utils/APIHelper.js";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Box } from "@mui/system";
import {
  ArrowBackOutlined,
  FilterAlt,
  MoreVert,
  Search,
  TuneOutlined,
  Edit,
  Delete,
} from "@mui/icons-material";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import InfoIcon from "@mui/icons-material/Info";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import * as XLSX from "xlsx/xlsx.mjs";
import { isTablet } from "react-device-detect";
import TableToggle, { ResetIcon } from "../../Icon/TableToggle/index.js";
import ConfirmDialog from "../../ConfirmDialog";
import useDetectKeyboardOpen from "use-detect-keyboard-open";

export default function TabInspectionIssueDetails(props) {
  const {
    handleClick = () => null,
    handlePTabValue = () => null,
    handleRTabValue = () => null,
    PtabValue = 0,
    tabValue = 0,

    handlePageNoPT = () => null,
    handleRecordPT = () => null,
    handlePageNoPM = () => null,
    handleRecordPM = () => null,
    pageNoPT = 1,
    recordPT = 10,
    pageNoPM = 1,
    recordPM = 10,

    handlePageNoRT = () => null,
    handleRecordRT = () => null,
    handlePageNoRM = () => null,
    handleRecordRM = () => null,
    pageNoRT = 1,
    recordRT = 10,
    pageNoRM = 1,
    recordRM = 10,
    inspectionData,
    scroll,
  } = props;
  const styles = useStyles();
  const { token, dropDownData, isOnline, userdata } = useSelector(
    (state) => state.auth
  );
  const isKeyboardOpen = useDetectKeyboardOpen();

  const [resolutionDetails, setResolutionDetails] = useState({});
  const [vehicleDetail, setVehicleDetail] = useState({});
  const [PresolutionDetails, setPResolutionDetails] = useState({});

  const [anchorEl, setAnchorEl] = useState(null);
  const [PanchorEl, setPAnchorEl] = useState(null);

  const pop = Boolean(anchorEl);
  const id = pop ? "simple-popover" : undefined;

  const Ppop = Boolean(PanchorEl);
  const Pid = Ppop ? "simple-popover" : undefined;

  const [pageLoad, setPageLoad] = useState(false);
  const [pageLoad1, setPageLoad1] = useState(false);
  const [PpageLoad1, setPPageLoad1] = useState(false);

  const [filter, setFilter] = useState("");
  const [searchFilter, setSearchFilter] = useState("");

  const [Pfilter, setPFilter] = useState("");
  const [PsearchFilter, setPSearchFilter] = useState("");

  const [typeList, settypeList] = useState(false);
  const [brandList, setbrandList] = useState(false);
  const [datePicker, setDatePicker] = useState(null);
  const [datePicker1, setDatePicker1] = useState(null);
  const [disable, setDisable] = useState(true);

  const [PtypeList, setPtypeList] = useState(false);
  const [PbrandList, setPbrandList] = useState(false);
  const [PdatePicker, setPDatePicker] = useState(null);
  const [PdatePicker1, setPDatePicker1] = useState(null);
  const [Pdisable, setPDisable] = useState(true);

  const [issueList, setIssueList] = useState(false);

  const [activeIndex, setActiveIndex] = useState(null);
  const [PactiveIndex, setPActiveIndex] = useState(null);

  const [confirmDialog, setConfirmDialog] = useState(false);
  const [loader, setLoader] = useState(false);
  const [issueData, setIssueData] = useState({});

  useEffect(() => {
    document.title = Setting.page_name.Inspection;
    setPageLoad(true);
    getVehicleDetails();
  }, []);

  useEffect(() => {
    setPageLoad1(true);
    getResolutionDetails(false);
  }, [pageNoRT, pageNoRM, recordRT, recordRM, filter, tabValue]);

  // useEffect(() => {
  //   setPageNo(1);
  // }, [record]);

  useEffect(() => {
    isNull(datePicker) && isEmpty(searchFilter) && getResolutionDetails(false);
  }, [datePicker, datePicker1, searchFilter]);

  useEffect(() => {
    setPPageLoad1(true);
    getPResolutionDetails(false);
  }, [pageNoPT, pageNoPM, recordPT, recordPM, Pfilter, PtabValue]);

  useEffect(() => {
    isNull(PdatePicker) &&
      isEmpty(PsearchFilter) &&
      getPResolutionDetails(false);
  }, [PdatePicker, PdatePicker1, PsearchFilter]);

  useEffect(() => {
    if (isOnline) {
      document.title = Setting.page_name.Inspection;
    }
  }, [isOnline]);

  useEffect(() => {
    setSearchFilter("");
    setFilter("");
  }, [tabValue]);

  useEffect(() => {
    setPSearchFilter("");
    setPFilter("");
  }, [PtabValue]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_meR");
    section.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [pageNoRT, pageNoRM]);

  useEffect(() => {
    const section = document.querySelector("#jump_to_mePR");
    section.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [pageNoPT, pageNoPM]);

  useEffect(() => {
    if (activeIndex !== null) {
      const section = document.querySelector(`#jump_to_me${activeIndex}`);
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [activeIndex]);
  useEffect(() => {
    if (PactiveIndex !== null) {
      const section = document.querySelector(`#jump_to_meP${PactiveIndex}`);
      section.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  }, [PactiveIndex]);

  useEffect(() => {
    setActiveIndex(null);
  }, [pageNoRT, pageNoRM, recordRT, recordRM]);
  useEffect(() => {
    setPActiveIndex(null);
  }, [pageNoPT, pageNoPM, recordPT, recordPM]);

  const auditSave = async (reportName) => {
    let endPoints = Setting.endpoints.auditsave;

    const message = `${userdata?.name} Downloaded ${reportName} From Inspection Management`;
    const params = {
      "AuditLog[message]": message,
    };
    try {
      const resp = await getAPIProgressData(endPoints, "POST", params, true);
    } catch (error) {
      toast.error(error.message.toString());
      console.log("ERRRRR", error);
    }
  };

  const getVehicleDetails = async () => {
    try {
      const response = await getApiData(
        `${Setting.endpoints.vehicledetails}?id=${inspectionData.vehicle_id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setVehicleDetail(response?.data);
      } else {
        toast.error(response?.message);
      }
      setPageLoad(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setPageLoad(false);
    }
  };

  function resetFilterData() {
    setFilter("");
    setSearchFilter("");
    setDatePicker(null);
    setDatePicker1(null);
  }

  function resetPFilterData() {
    setPFilter("");
    setPSearchFilter("");
    setPDatePicker(null);
    setPDatePicker1(null);
  }
  async function getResolutionDetails(bool, v) {
    setActiveIndex(null);
    !v && setPageLoad1(true);
    const fromDate = !isNull(datePicker)
      ? moment(datePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(datePicker1)
      ? moment(datePicker1).format("YYYY-MM-DD")
      : "";

    let endpoints = `${Setting.endpoints.resolutionDetail}?type=${
      tabValue === 1 ? "mechanical" : "tyre"
    }&page=${tabValue === 0 ? pageNoRT : pageNoRM}&per_page=${
      tabValue === 0 ? recordRT : recordRM
    }&search=${bool ? "" : searchFilter}&vehicle_id=${
      inspectionData?.vehicle_id
    }&status=1&from_date=${fromDate}&to_date=${toDate}&filter=${filter}&is_download=${
      v ? v : ""
    }`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            tabValue === 1
              ? downloadResolutionData("mech", response?.data?.rows)
              : downloadResolutionData("", response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setResolutionDetails(listObj);
          }
      } else {
        toast.error(response?.message);
      }
      v &&
        auditSave(
          tabValue === 1 ? "Mechanical Resolution List" : "Tyre Resolution List"
        );
      setPageLoad1(false);
    } catch (error) {
      setPageLoad1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  async function getPResolutionDetails(bool, v) {
    setPActiveIndex(null);
    !v && setPPageLoad1(true);
    const fromDate = !isNull(PdatePicker)
      ? moment(PdatePicker).format("YYYY-MM-DD")
      : "";
    const toDate = !isNull(PdatePicker1)
      ? moment(PdatePicker1).format("YYYY-MM-DD")
      : "";

    let endpoints = `${Setting.endpoints.resolutionDetail}?type=${
      PtabValue === 1 ? "mechanical" : "tyre"
    }&page=${PtabValue === 0 ? pageNoPT : pageNoPM}&per_page=${
      PtabValue === 0 ? recordPT : recordPM
    }&search=${bool ? "" : PsearchFilter}&vehicle_id=${
      inspectionData?.vehicle_id
    }&status=2&from_date=${fromDate}&to_date=${toDate}&filter=${Pfilter}&is_download=${
      v ? v : ""
    }`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        )
          if (v) {
            PtabValue === 1
              ? downloadResolutionData("mech", response?.data?.rows)
              : downloadResolutionData("", response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setPResolutionDetails(listObj);
          }
      } else {
        toast.error(response?.message);
      }
      v &&
        auditSave(
          PtabValue === 1
            ? "Partially Resolution Mechanical List"
            : "Partially Resolution Tyre List"
        );
      setPPageLoad1(false);
    } catch (error) {
      setPPageLoad1(false);
      toast.error(error.toString());
      console.log("error ===>>>", error);
    }
  }

  const getTerrain = () => {
    let array = vehicleDetail?.terrain_ids?.split(",");
    let res = dropDownData?.terrain.filter((item) => {
      let a = array?.includes(item?.id?.toString());
      return a;
    });
    return res.map((v, i) => (
      <Typography>
        {v?.label}
        {i < res.length - 1 ? ", " : ""}
      </Typography>
    ));
  };

  const getLastInspectionDate = () => {
    const timestamp = vehicleDetail?.last_inspected_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return displayDate;
  };

  const getIssuesIdentifiedOn = (index) => {
    const timestamp = resolutionDetails?.data[index]?.issue_identified_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };
  const getPIssuesIdentifiedOn = (index) => {
    const timestamp = PresolutionDetails?.data[index]?.issue_identified_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };

  const getResolvedOn = (index) => {
    const timestamp = resolutionDetails?.data[index]?.resolved_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };
  const getPResolvedOn = (index) => {
    const timestamp = PresolutionDetails?.data[index]?.resolved_at;
    const date = new Date(timestamp * 1000);
    const displayDate = moment(date).format("DD-MM-yyyy");
    return (
      <div
        style={{
          flex: 1,
          display: "flex",
        }}
      >
        {timestamp ? displayDate : ""}
      </div>
    );
  };
  const getIssued = (index) => {
    let array = resolutionDetails?.data[index]?.issue;
    return !isEmpty(array) ? (
      <div
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {array.map((items, index) => {
          const isTwo = index <= 1;
          return isTwo ? (
            <Typography>
              {items}
              {index !== array.length - 1 ? ", " : ""}
            </Typography>
          ) : null;
        })}
        {array.length > 2 && (
          <span style={{ color: color.placeholder }}>{`+ ${
            array.length - 2
          } more`}</span>
        )}
      </div>
    ) : (
      <Typography variant="tableHead">-</Typography>
    );
  };
  const getPIssued = (index) => {
    let array = PresolutionDetails?.data[index]?.issue;
    return !isEmpty(array) ? (
      <div
        style={{
          overflow: "hidden",
          wordWrap: "break-word",
          whiteSpace: "break-spaces",
        }}
      >
        {array.map((items, index) => {
          const isTwo = index <= 1;
          return isTwo ? (
            <Typography>
              {items}
              {index !== array.length - 1 ? ", " : ""}
            </Typography>
          ) : null;
        })}
        {array.length > 2 && (
          <span style={{ color: color.placeholder }}>{`+ ${
            array.length - 2
          } more`}</span>
        )}
      </div>
    ) : (
      <Typography variant="tableHead">-</Typography>
    );
  };

  const PAction = (index) => {
    return (
      <Tooltip title={"Edit"} placement="right" arrow>
        <Edit
          style={{
            color: isTablet && color.primary,
            cursor: "pointer",
          }}
          onClick={() => {
            handleClick(
              "resolveIssue",
              PresolutionDetails?.data[index],
              "mechanical",
              PresolutionDetails?.data[index]?.issue[0]
            );
          }}
        />
      </Tooltip>
    );
  };

  const getDelete = (index) => {
    return (
      <Tooltip title={"Delete"} placement="left" arrow>
        <Delete
          style={{
            cursor: "pointer",
          }}
          onClick={() => {
            setConfirmDialog(true);
            setIssueData(PresolutionDetails?.data[index]);
          }}
        />
      </Tooltip>
    );
  };

  const deleteIssue = async () => {
    setLoader(true);
    try {
      const response = await getApiData(
        `${Setting.endpoints.deleteIssue}?id=${issueData?.id}`,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        setConfirmDialog(false);
        getPResolutionDetails(false);
      }
      setLoader(false);
    } catch (error) {
      console.log("error ===>>>", error);
      setLoader(false);
    }
  };

  function downloadResolutionData(tab, data) {
    const newData = data.map((row, index) => {
      const timestamp = row?.issue_identified_at;
      const date = new Date(timestamp * 1000);
      const displayDate = moment(date).format("DD-MM-yyyy");

      const timestamp1 = row?.resolved_at;
      const date1 = new Date(timestamp1 * 1000);
      const displayDate1 = moment(date1).format("DD-MM-yyyy");
      const newObj = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Position": row?.tyre_position || "-",
        "Axle Type": row?.axle_type || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.tyre_pattern_size || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Tyre Issue": row?.issue.toString() || "-",
        "Identified On": timestamp ? displayDate : "",
        "Identified By": row?.issue_identified_by || "-",
        "Resolved On": timestamp1 ? displayDate1 : "-",
        "Resolved By": row?.resolved_by || "-",
        Remarks: row?.remark || "-",
      };

      const newObj1 = {
        "Sr. No.": index + 1,
        "Fleet Name": row?.fleet_name,
        "Vehicle No.": row?.vehicle_no,
        "Tyre Serial No.": row?.tyre_serial_no || "-",
        "Tyre Position": row?.tyre_position || "-",
        "Axle Type": row?.axle_type || "-",
        "Tyre Type": row?.tyre_type || "-",
        "Tyre Brand": row?.tyre_brand || "-",
        "Item Description": row?.tyre_pattern_size || "-",
        "Tyre Composition": row?.tyre_composition || "-",
        "Mechanical Issue": row?.issue.toString() || "-",
        "Identified On": timestamp ? displayDate : "-",
        "Identified By": row?.issue_identified_by || "-",
        "Resolved On": timestamp1 ? displayDate1 : "-",
        "Resolved By": row?.resolved_by || "-",
        Remarks: row?.remark || "-",
      };

      return tab === "mech" ? newObj1 : newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(
      workBook,
      workSheet,
      tab === "mech" ? "Mechanical Resolution List" : "Tyre Resolution List"
    );
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    XLSX.writeFile(
      workBook,
      tab === "mech"
        ? "Mechanical Resolution List.xlsx"
        : "Tyre Resolution List.xlsx"
    );
  }

  useEffect(() => {
    setTimeout(() => {
      if (scroll) {
        const section = document.querySelector(`#${scroll}`);
        section.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    }, 500);
  }, []);

  return (
    <>
      {isTablet ? (
        <>
          <Box>
            <Button
              style={{ backgroundColor: color.secondary, marginBottom: "10px" }}
              onClick={() => {
                handleClick("cancel");
              }}
            >
              Back
            </Button>
          </Box>
          <div className={styles.container}>
            <Box
              style={{
                backgroundColor: color.primary,
                color: color.white,
                padding: "18px 20px",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography fontWeight={"bold"}>
                {inspectionData?.fleet_name} / {inspectionData?.vehicle_no}
              </Typography>
            </Box>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : (
              <div style={{ padding: 20 }}>
                <Table>
                  <TableRow>
                    <TableCell variant="head">Vehicle No.</TableCell>
                    <TableCell>{vehicleDetail?.vehicle_no || "-"}</TableCell>
                    <TableCell variant="head">No. of Tyres</TableCell>
                    <TableCell>
                      {vehicleDetail?.added_tyre_count}
                      {" / "}
                      {vehicleDetail?.no_of_tyres || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Type</TableCell>
                    <TableCell>
                      {vehicleDetail?.vehicle_type_id || "-"}
                    </TableCell>
                    <TableCell variant="head">Initial Reading</TableCell>
                    <TableCell>
                      {vehicleDetail?.initial_reading || "-"}
                      {vehicleDetail?.initial_reading ? " Km" : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Brand</TableCell>
                    <TableCell>
                      {vehicleDetail?.vehicle_brand_id || "-"}
                    </TableCell>
                    <TableCell variant="head">Current Reading</TableCell>
                    <TableCell>
                      {vehicleDetail?.fitment_reading}
                      {vehicleDetail?.fitment_reading ? " Km" : ""}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Vehicle Model</TableCell>
                    <TableCell>{vehicleDetail?.vehicle_model || "-"}</TableCell>
                    <TableCell variant="head">Inspection Date</TableCell>
                    <TableCell>{getLastInspectionDate()}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Terrain</TableCell>
                    <TableCell>{getTerrain()}</TableCell>
                    <TableCell variant="head">Inspected By</TableCell>
                    <TableCell>{vehicleDetail?.inspected_by || "-"}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Route Origin</TableCell>
                    <TableCell>{vehicleDetail?.route_origin || "-"}</TableCell>
                    <TableCell variant="head">Inspections (Month)</TableCell>
                    <TableCell>
                      {vehicleDetail?.inspection_monthly || "-"}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell variant="head">Route Destination</TableCell>
                    <TableCell>
                      {vehicleDetail?.route_destination || "-"}
                    </TableCell>
                    <TableCell variant="head">Inspections (Overall)</TableCell>
                    <TableCell>
                      {vehicleDetail?.inspection_overall || "-"}
                    </TableCell>
                  </TableRow>
                </Table>
              </div>
            )}
          </div>
          <div
            className={styles.container}
            style={{ marginTop: 20, padding: 20, marginBottom: 20 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              id="resolve"
            >
              <Grid item>
                <Typography variant="tableTitle" id="jump_to_meR">
                  Resolved Issue List
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disable={isOnline ? false : true}
                  onClick={() => getResolutionDetails(false, 1)}
                >
                  <img src={Images.Mdownload} alt="download" />
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: color.white,
                    color: "#D8D8D8",
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    selected={tabValue === 0}
                    onClick={(event) => {
                      handlePageNoRT(1);
                      handleRecordRT(10);
                      handleRTabValue(0);
                      setAnchorEl(null);
                    }}
                  >
                    Tyre
                  </ListItemButton>
                  <ListItemButton
                    selected={tabValue === 1}
                    onClick={(event) => {
                      handlePageNoRM(1);
                      handleRecordRM(10);
                      handleRTabValue(1);
                      setAnchorEl(null);
                    }}
                  >
                    Mechanical
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: 10 }} gap={1} wrap="nowrap">
              <Grid item sm={3} lg={3}>
                <Select
                  fullWidth
                  placeholder="Filter"
                  value={filter}
                  onChange={(v) => {
                    tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                    setFilter(v.target.value);
                  }}
                  onOpen={() => {
                    if (isEmpty(filter.toString())) {
                      settypeList(false);
                      setbrandList(false);
                    }
                  }}
                  displayEmpty
                  IconComponent={FilterAlt}
                  style={filter === "" ? { color: "#A2A2A2" } : {}}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem
                    value={""}
                    hidden
                    selected
                    disabled
                    style={{ minWidth: 220 }}
                  >
                    Filter
                  </MenuItem>
                  <ListItemButton
                    onClick={() => {
                      settypeList(!typeList);
                      setbrandList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Brand</Typography>
                    {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.tyre_brand.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: typeList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setbrandList(!brandList);
                      settypeList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Axle Type</Typography>
                    {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.axle_type.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: brandList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
              <Grid item sm={7} lg={7}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getResolutionDetails(true);
                    setSearchFilter(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (
                        !isEmpty(searchFilter) ||
                        !isNull(datePicker) ||
                        !isNull(datePicker1)
                      ) {
                        tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                        getResolutionDetails(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Tyre Serial No. and Resolved By"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item sm={2} lg={2}>
                <Select
                  fullWidth
                  value={tabValue === 0 ? recordRT : recordRM}
                  onChange={(v) => {
                    tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                    tabValue === 0
                      ? handleRecordRT(v.target.value)
                      : handleRecordRM(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container style={{ marginTop: 10 }} wrap="nowrap">
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={datePicker}
                    onChange={(newValue) => {
                      setDatePicker(newValue);
                      setDisable(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                  to
                </Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(datePicker) ? true : disable}
                    minDate={datePicker}
                    views={["year", "month", "day"]}
                    disableFuture
                    value={datePicker1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      setDatePicker1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              disable || isNull(datePicker)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                  onClick={() => {
                    if (
                      !isEmpty(searchFilter) ||
                      (!isNull(datePicker) && !isNull(datePicker1))
                    ) {
                      tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                      getResolutionDetails(false);
                    }
                  }}
                >
                  <Search />
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  disabled={!searchFilter && !datePicker && !filter}
                  onClick={resetFilterData}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
            {pageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(resolutionDetails?.data) &&
              !isEmpty(resolutionDetails?.data) ? (
              <>
                {resolutionDetails?.data?.map((item, index) => {
                  return (
                    <div style={{ marginTop: 10 }}>
                      <Grid
                        container
                        style={{
                          border: `1px solid ${color.bordercolor}`,
                          boxShadow: color.shadow,
                        }}
                        overflow="auto"
                      >
                        <Table className={styles.customtable}>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">Fleet Name</TableCell>
                              <TableCell variant="head">Vehicle No.</TableCell>
                              <TableCell variant="head">
                                Tyre Serial No.
                              </TableCell>
                              <TableCell variant="head">
                                Tyre Position
                              </TableCell>
                              <TableCell variant="head">Axle Type</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>{item?.fleet_name || "-"}</TableCell>
                              <TableCell>{item?.vehicle_no || "-"}</TableCell>
                              <TableCell>
                                {item?.tyre_serial_no || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.tyre_position || "-"}
                              </TableCell>
                              <TableCell>{item?.axle_type || "-"}</TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">Tyre Type</TableCell>
                              <TableCell variant="head">Tyre Brand</TableCell>
                              <TableCell variant="head">
                                Item Description
                              </TableCell>
                              <TableCell variant="head">
                                Tyre Composition
                              </TableCell>
                              {tabValue === 1 ? (
                                <TableCell variant="head">
                                  Mechanical Issue
                                </TableCell>
                              ) : (
                                <TableCell variant="head">Tyre Issue</TableCell>
                              )}
                            </TableRow>
                            <TableRow>
                              <TableCell>{item?.tyre_type || "-"}</TableCell>
                              <TableCell>{item?.tyre_brand || "-"}</TableCell>
                              <TableCell>
                                {item?.tyre_pattern_size || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.tyre_composition || "-"}
                              </TableCell>
                              <TableCell> {getIssued(index)}</TableCell>
                            </TableRow>
                          </TableBody>
                          <TableBody>
                            <TableRow>
                              <TableCell variant="head">
                                Identified On
                              </TableCell>
                              <TableCell variant="head">
                                Identified By
                              </TableCell>
                              <TableCell variant="head">Resolved On</TableCell>
                              <TableCell variant="head">Resolved By</TableCell>
                              <TableCell variant="head">Remarks</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell>
                                {getIssuesIdentifiedOn(index) || "-"}
                              </TableCell>
                              <TableCell>
                                {item?.issue_identified_by || "-"}
                              </TableCell>
                              <TableCell>
                                {getResolvedOn(index) || "-"}
                              </TableCell>
                              <TableCell>{item?.resolved_by || "-"}</TableCell>
                              <TableCell>{item?.remark || "-"}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>
                    </div>
                  );
                })}

                <div
                  style={{
                    marginTop: 20,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(resolutionDetails?.pagination?.totalPage)}
                    defaultPage={tabValue === 0 ? pageNoRT : pageNoRM}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      tabValue === 0 ? handlePageNoRT(e) : handlePageNoRM(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>

          <div
            className={styles.container}
            style={{ padding: 20, marginBottom: 20 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              id="partiallyresolve"
            >
              <Grid item>
                <Typography variant="tableTitle" id="jump_to_mePR">
                  Partially Resolved Issue List
                </Typography>
              </Grid>
              <Grid item display="flex" wrap="nowrap" alignItems="center">
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disable={isOnline ? false : true}
                  onClick={() => getPResolutionDetails(false, 1)}
                >
                  <img src={Images.Mdownload} alt="download" />
                </Button>
                <Grid>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: 10,
                      backgroundColor: color.white,
                      color: "#D8D8D8",
                    }}
                    onClick={(event) => {
                      setPAnchorEl(event.currentTarget);
                    }}
                  >
                    <TuneOutlined style={{ transform: "rotate(-90deg)" }} />
                  </Button>
                  <Popover
                    onClose={() => setPAnchorEl(null)}
                    anchorEl={PanchorEl}
                    id={Pid}
                    open={Ppop}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ListItemButton
                      selected={PtabValue === 0}
                      onClick={(event) => {
                        handlePageNoPT(1);
                        handleRecordPT(10);
                        handlePTabValue(0);
                        setPAnchorEl(null);
                      }}
                    >
                      Tyre
                    </ListItemButton>
                    <ListItemButton
                      selected={PtabValue === 1}
                      onClick={(event) => {
                        handlePageNoPM(1);
                        handleRecordPM(10);
                        handlePTabValue(1);
                        setPAnchorEl(null);
                      }}
                    >
                      Mechanical
                    </ListItemButton>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              gap={1}
              wrap="nowrap"
              alignItems="center"
              style={{ marginTop: 10 }}
            >
              <Grid item sm={3} lg={2}>
                <Select
                  fullWidth
                  placeholder="Filter"
                  value={Pfilter}
                  onChange={(v) => {
                    PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                    setPFilter(v.target.value);
                  }}
                  onOpen={() => {
                    if (isEmpty(Pfilter.toString())) {
                      setPtypeList(false);
                      setPbrandList(false);
                    }
                  }}
                  displayEmpty
                  IconComponent={FilterAlt}
                  style={Pfilter === "" ? { color: "#A2A2A2" } : {}}
                  classes={{
                    iconOpen: styles.iconOpen,
                  }}
                >
                  <MenuItem
                    value={""}
                    hidden
                    selected
                    disabled
                    style={{ minWidth: 220 }}
                  >
                    Filter
                  </MenuItem>
                  <ListItemButton
                    onClick={() => {
                      setPtypeList(!PtypeList);
                      setPbrandList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Tyre Brand</Typography>
                    {PtypeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.tyre_brand.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: PtypeList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                  <ListItemButton
                    onClick={() => {
                      setPbrandList(!PbrandList);
                      setPtypeList(false);
                    }}
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography>Axle Type</Typography>
                    {PbrandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </ListItemButton>
                  {dropDownData.axle_type.map((item, index) => {
                    return (
                      <MenuItem
                        style={{
                          display: PbrandList ? "flex" : "none",
                        }}
                        key={index}
                        value={item?.id}
                      >
                        {item?.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid item sm={7} lg={2}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={PsearchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getPResolutionDetails(true);
                    setPSearchFilter(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (
                        !isEmpty(PsearchFilter) ||
                        !isNull(PdatePicker) ||
                        !isNull(PdatePicker1)
                      ) {
                        PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                        getPResolutionDetails(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Tyre Serial No. and Resolved By"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid
                item
                sm={2}
                lg={1.6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Select
                  fullWidth
                  value={PtabValue === 0 ? recordPT : recordPM}
                  onChange={(v) => {
                    PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                    PtabValue === 0
                      ? handleRecordPT(v.target.value)
                      : handleRecordPM(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>

            <Grid container style={{ marginTop: 10 }} wrap="nowrap">
              <Grid
                item
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={PdatePicker}
                    onChange={(newValue) => {
                      setPDatePicker(newValue);
                      setPDisable(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Typography style={{ margin: "0 10px" }}>to</Typography>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(PdatePicker) ? true : Pdisable}
                    minDate={PdatePicker}
                    views={["year", "month", "day"]}
                    disableFuture
                    value={PdatePicker1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      setPDatePicker1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              Pdisable || isNull(PdatePicker)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary, marginLeft: 20 }}
                  onClick={() => {
                    if (
                      !isEmpty(PsearchFilter) ||
                      (!isNull(PdatePicker) && !isNull(PdatePicker1))
                    ) {
                      PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                      getPResolutionDetails(false);
                    }
                  }}
                >
                  <Search />
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  disabled={!PsearchFilter && !PdatePicker && !Pfilter}
                  onClick={resetPFilterData}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>
            {PpageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(PresolutionDetails?.data) &&
              !isEmpty(PresolutionDetails?.data) ? (
              <div style={{ marginTop: 10 }}>
                {PresolutionDetails?.data?.map((item, index) => {
                  return (
                    <Grid
                      container
                      style={{
                        border: `1px solid ${color.bordercolor}`,
                        boxShadow: color.shadow,
                        marginBottom: 15,
                      }}
                      overflow="auto"
                    >
                      <Table className={styles.customtable}>
                        <TableBody>
                          <TableRow>
                            <TableCell variant="head">Fleet Name</TableCell>
                            <TableCell variant="head">Vehicle No.</TableCell>
                            <TableCell variant="head">
                              Tyre Serial No.
                            </TableCell>
                            <TableCell variant="head">Tyre Position</TableCell>
                            <TableCell variant="head">Axle Type</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>{item?.fleet_name || "-"}</TableCell>
                            <TableCell>{item?.vehicle_no || "-"}</TableCell>
                            <TableCell>{item?.tyre_serial_no || "-"}</TableCell>
                            <TableCell>{item?.tyre_position || "-"}</TableCell>
                            <TableCell>{item?.axle_type || "-"}</TableCell>
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          <TableRow>
                            <TableCell variant="head">Tyre Type</TableCell>
                            <TableCell variant="head">Tyre Brand</TableCell>
                            <TableCell variant="head">
                              Item Description
                            </TableCell>
                            <TableCell variant="head">
                              Tyre Composition
                            </TableCell>
                            {PtabValue === 1 ? (
                              <TableCell variant="head">
                                Mechanical Issue
                              </TableCell>
                            ) : (
                              <TableCell variant="head">Tyre Issue</TableCell>
                            )}
                          </TableRow>
                          <TableRow>
                            <TableCell>{item?.tyre_type || "-"}</TableCell>
                            <TableCell>{item?.tyre_brand || "-"}</TableCell>
                            <TableCell>
                              {item?.tyre_pattern_size || "-"}
                            </TableCell>
                            <TableCell>
                              {item?.tyre_composition || "-"}
                            </TableCell>
                            <TableCell> {getPIssued(index)}</TableCell>
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          <TableRow>
                            <TableCell variant="head">Identified On</TableCell>
                            <TableCell variant="head">Identified By</TableCell>
                            <TableCell variant="head">Resolved On</TableCell>
                            <TableCell variant="head">Resolved By</TableCell>
                            <TableCell variant="head">Remarks</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell>
                              {getPIssuesIdentifiedOn(index) || "-"}
                            </TableCell>
                            <TableCell>
                              {item?.issue_identified_by || "-"}
                            </TableCell>
                            <TableCell>
                              {getPResolvedOn(index) || "-"}
                            </TableCell>
                            <TableCell>{item?.resolved_by || "-"}</TableCell>
                            <TableCell>{item?.remark || "-"}</TableCell>
                          </TableRow>
                        </TableBody>
                        <TableBody>
                          <TableRow>
                            <TableCell>{PAction(index)}</TableCell>
                            <TableCell colSpan={4}>
                              <Button
                                style={{
                                  backgroundColor: "red",
                                }}
                              >
                                {getDelete(index)}
                              </Button>
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </Grid>
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    padding: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(PresolutionDetails?.pagination?.totalPage)}
                    defaultPage={PtabValue === 0 ? pageNoPT : pageNoPM}
                    boundaryCount={2}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      PtabValue === 0 ? handlePageNoPT(e) : handlePageNoPM(e);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      ) : (
        <>
          <Box>
            {isKeyboardOpen && !isTablet ? null : (
              <Button
                style={{
                  backgroundColor: color.secondary,
                  position: "fixed",
                  top: 10,
                  zIndex: 10,
                }}
                onClick={() => {
                  handleClick("cancel");
                }}
              >
                <ArrowBackOutlined />
              </Button>
            )}
          </Box>
          <Box
            style={{
              backgroundColor: color.primary,
              color: color.white,
              padding: 15,
            }}
          >
            <Typography fontWeight={"bold"}>
              {inspectionData?.fleet_name} / {inspectionData?.vehicle_no}
            </Typography>
          </Box>
          <div className={styles.container}>
            {pageLoad ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : (
              <div style={{ position: "relative" }}>
                <Grid
                  item
                  container
                  style={{
                    border: `1px solid ${color.bordercolor}`,
                    alignItems: "center",
                    boxShadow: color.shadow,
                  }}
                >
                  <Grid item container>
                    <Table className={styles.customtableMobile}>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Vehicle No.</TableCell>
                          <TableCell variant="head">No. of Tyres</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.vehicle_no || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.added_tyre_count}
                            {" / "}
                            {vehicleDetail?.no_of_tyres || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Vehicle Type</TableCell>
                          <TableCell variant="head">Initial Reading</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.vehicle_type_id || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.initial_reading || "-"}
                            {vehicleDetail?.initial_reading ? " Km" : ""}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Vehicle Brand</TableCell>
                          <TableCell variant="head">Current Reading</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.vehicle_brand_id || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.fitment_reading}
                            {vehicleDetail?.fitment_reading ? " Km" : ""}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Vehicle Model</TableCell>
                          <TableCell variant="head">Inspection Date</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.vehicle_model || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {getLastInspectionDate() || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Terrain</TableCell>
                          <TableCell variant="head">Inspected By</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {getTerrain() || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.inspected_by || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">Route Origin</TableCell>
                          <TableCell variant="head">Inspections</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.route_origin || "-"}
                          </TableCell>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            <Typography>
                              Month: {vehicleDetail?.inspection_overall || "-"}
                            </Typography>
                            Overall: {vehicleDetail?.inspection_overall || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableHead>
                        <TableRow>
                          <TableCell variant="head">
                            Route Destination
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            variant="body"
                            style={{ paddingBottom: 5 }}
                          >
                            {vehicleDetail?.route_destination || "-"}
                          </TableCell>
                        </TableRow>
                      </TableHead>
                    </Table>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
          <div
            className={styles.container}
            style={{ marginTop: 10, padding: 10, marginBottom: 10 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              id="resolve"
            >
              <Grid item>
                <Typography variant="tableTitle" id="jump_to_meR">
                  Resolved Issue List
                </Typography>
              </Grid>
              <Grid item display="flex" wrap="nowrap">
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disable={isOnline ? false : true}
                  onClick={() => getResolutionDetails(false, 1)}
                >
                  <img src={Images.Mdownload} alt="download" />
                </Button>
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: "#BBBBBB",
                    color: color.secondary,
                  }}
                  onClick={(event) => {
                    setAnchorEl(event.currentTarget);
                  }}
                >
                  <MoreVert />
                </Button>
                <Popover
                  onClose={() => setAnchorEl(null)}
                  anchorEl={anchorEl}
                  id={id}
                  open={pop}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <ListItemButton
                    selected={tabValue === 0}
                    onClick={(event) => {
                      handlePageNoRT(1);
                      handleRecordRT(10);
                      handleRTabValue(0);
                      setAnchorEl(null);
                    }}
                  >
                    Tyre
                  </ListItemButton>
                  <ListItemButton
                    selected={tabValue === 1}
                    onClick={(event) => {
                      handlePageNoRM(1);
                      handleRecordRM(10);
                      handleRTabValue(1);
                      setAnchorEl(null);
                    }}
                  >
                    Mechanical
                  </ListItemButton>
                </Popover>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 10 }}
              wrap="nowrap"
            >
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <Grid item>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={filter}
                    onChange={(v) => {
                      tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                      setFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(filter.toString())) {
                        settypeList(false);
                        setbrandList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={filter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem
                      value={""}
                      hidden
                      selected
                      style={{ minWidth: 220 }}
                    >
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        settypeList(!typeList);
                        setbrandList(false);
                        setIssueList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Brand</Typography>
                      {typeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {typeList &&
                      dropDownData.tyre_brand.map((item, index) => {
                        return (
                          <MenuItem
                            style={{ display: typeList ? "flex" : "none" }}
                            key={index}
                            value={item?.id}
                          >
                            {item?.label}
                          </MenuItem>
                        );
                      })}
                    <ListItemButton
                      onClick={() => {
                        setbrandList(!brandList);
                        settypeList(false);
                        setIssueList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Axle Type</Typography>
                      {brandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.axle_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: brandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setIssueList(!issueList);
                        settypeList(false);
                        setbrandList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Issue</Typography>
                      {issueList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_issue_inspection.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: issueList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <Grid item style={{ display: "flex", alignItems: "center" }}>
                <Select
                  fullWidth
                  value={tabValue === 0 ? recordRT : recordRM}
                  onChange={(v) => {
                    tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                    tabValue === 0
                      ? handleRecordRT(v.target.value)
                      : handleRecordRM(v.target.value);
                  }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              item
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: 10,
              }}
            >
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disableFuture
                  value={datePicker}
                  onChange={(newValue) => {
                    setDatePicker(newValue);
                    setDisable(false);
                  }}
                  inputFormat="dd-MM-yyyy"
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{ svg: { color: color.primary } }}
                    />
                  )}
                />
              </LocalizationProvider>
              <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                to
              </Typography>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  showToolbar={false}
                  disabled={isNull(datePicker) ? true : disable}
                  minDate={datePicker}
                  disableFuture
                  value={datePicker1}
                  inputFormat="dd-MM-yyyy"
                  onChange={(newValue) => {
                    setDatePicker1(newValue);
                  }}
                  DialogProps={{ className: styles.datePicker }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "DD-MM-YYYY",
                      }}
                      sx={{
                        svg: {
                          color:
                            disable || isNull(datePicker) ? "" : color.primary,
                        },
                      }}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid
              container
              style={{ marginTop: 10 }}
              alignItems={"center"}
              wrap="nowrap"
              columnGap={1}
            >
              <Grid item>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={searchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getResolutionDetails(true);
                    setSearchFilter(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (
                        !isEmpty(searchFilter) ||
                        !isNull(datePicker) ||
                        !isNull(datePicker1)
                      ) {
                        tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                        getResolutionDetails(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by TyreSerialNo. and Resolved By"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary }}
                  onClick={() => {
                    if (
                      !isEmpty(searchFilter) ||
                      (!isNull(datePicker) && !isNull(datePicker1))
                    ) {
                      tabValue === 0 ? handlePageNoRT(1) : handlePageNoRM(1);
                      getResolutionDetails(false);
                    }
                  }}
                >
                  <Search />
                </Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  disabled={!searchFilter && !datePicker && !filter}
                  onClick={resetFilterData}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>

            {pageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(resolutionDetails?.data) &&
              !isEmpty(resolutionDetails?.data) ? (
              <>
                {resolutionDetails?.data?.map((item, index) => {
                  return (
                    <div style={{ position: "relative" }}>
                      <Grid
                        item
                        container
                        id={`jump_to_me${index}`}
                        style={{
                          marginTop: 20,
                          border: `1px solid ${color.bordercolor}`,
                          alignItems: "center",
                          // height: activeIndex === index ? "auto" : 115,
                          boxShadow: color.shadow,
                          overflow: "hidden",
                        }}
                      >
                        <Grid item container>
                          <Table className={styles.customtableResolutionList}>
                            {activeIndex !== index ? (
                              <>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Serial No.
                                    </TableCell>
                                    <TableCell variant="head">
                                      Tyre Position
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_serial_no || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_position || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Brand
                                    </TableCell>
                                    <TableCell variant="head">
                                      Axle Type
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_brand || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.axle_type || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              </>
                            ) : (
                              <>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Serial No.
                                    </TableCell>
                                    <TableCell variant="head">
                                      Tyre Position
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_serial_no || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_position || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Brand
                                    </TableCell>
                                    <TableCell variant="head">
                                      Axle Type
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_brand || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.axle_type || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Fleet Name</TableCell>
                                    <TableCell>Vehicle No.</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.fleet_name || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.vehicle_no || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell>Tyre Type</TableCell>
                                    <TableCell>Item Description</TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_type || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_pattern_size || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Composition
                                    </TableCell>
                                    {tabValue === 1 ? (
                                      <TableCell variant="head">
                                        Mechanical Issue
                                      </TableCell>
                                    ) : (
                                      <TableCell variant="head">
                                        Tyre Issue
                                      </TableCell>
                                    )}
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_composition || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {getIssued(index)}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Identified On
                                    </TableCell>
                                    <TableCell variant="head">
                                      Identified By
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {getIssuesIdentifiedOn(index) || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.issue_identified_by || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Resolved On
                                    </TableCell>
                                    <TableCell variant="head">
                                      Resolved By
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {getResolvedOn(index) || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.resolved_by || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell colSpan={2} variant="head">
                                      Remarks
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      colSpan={2}
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.remark || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              </>
                            )}
                          </Table>
                        </Grid>
                        <div
                          style={{
                            zIindex: 10,
                            color: color.primary,
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            bottom: -18,
                            left: "45%",
                          }}
                          onClick={() => {
                            activeIndex === index
                              ? setActiveIndex(null)
                              : setActiveIndex(index);
                          }}
                        >
                          {activeIndex === index ? (
                            <div style={{ transform: "rotate(180deg)" }}>
                              <TableToggle />
                            </div>
                          ) : (
                            <TableToggle />
                          )}
                        </div>
                      </Grid>
                    </div>
                  );
                })}
                <div
                  style={{
                    display: "flex",
                    marginTop: 30,
                    marginBottom: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(resolutionDetails?.pagination?.totalPage)}
                    defaultPage={tabValue === 0 ? pageNoRT : pageNoRM}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      tabValue === 0 ? handlePageNoRT(e) : handlePageNoRM(e);
                    }}
                  />
                </div>
              </>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>

          <div
            className={styles.container}
            style={{ padding: 10, marginBottom: 10 }}
          >
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              id="partiallyresolve"
            >
              <Grid item>
                <Typography variant="tableTitle" id="jump_to_mePR">
                  Partially Resolved Issue List
                </Typography>
              </Grid>
              <Grid item display="flex" wrap="nowrap" alignItems="center">
                <Button
                  variant="contained"
                  style={{
                    marginLeft: 10,
                    backgroundColor: isOnline
                      ? color.secondary
                      : color.bordercolor,
                  }}
                  disable={isOnline ? false : true}
                  onClick={() => getPResolutionDetails(false, 1)}
                >
                  <img src={Images.Mdownload} alt="download" />
                </Button>
                <Grid>
                  <Button
                    variant="contained"
                    style={{
                      marginLeft: 10,
                      backgroundColor: "#BBBBBB",
                      color: color.secondary,
                    }}
                    onClick={(event) => {
                      setPAnchorEl(event.currentTarget);
                    }}
                  >
                    <MoreVert />
                  </Button>
                  <Popover
                    onClose={() => setPAnchorEl(null)}
                    anchorEl={PanchorEl}
                    id={Pid}
                    open={Ppop}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "center",
                    }}
                    transformOrigin={{
                      vertical: "top",
                      horizontal: "center",
                    }}
                  >
                    <ListItemButton
                      selected={PtabValue === 0}
                      onClick={(event) => {
                        handlePageNoPT(1);
                        handleRecordPT(10);
                        handlePTabValue(0);
                        setPAnchorEl(null);
                      }}
                    >
                      Tyre
                    </ListItemButton>
                    <ListItemButton
                      selected={PtabValue === 1}
                      onClick={(event) => {
                        handlePageNoPM(1);
                        handleRecordPM(10);
                        handlePTabValue(1);
                        setPAnchorEl(null);
                      }}
                    >
                      Mechanical
                    </ListItemButton>
                  </Popover>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              style={{ marginTop: 14 }}
            >
              <Grid
                item
                lg={10.4}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Grid item lg={2}>
                  <Select
                    fullWidth
                    placeholder="Filter"
                    value={Pfilter}
                    onChange={(v) => {
                      PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                      setPFilter(v.target.value);
                    }}
                    onOpen={() => {
                      if (isEmpty(Pfilter.toString())) {
                        setPtypeList(false);
                        setPbrandList(false);
                      }
                    }}
                    displayEmpty
                    IconComponent={FilterAlt}
                    style={Pfilter === "" ? { color: "#A2A2A2" } : {}}
                    classes={{
                      iconOpen: styles.iconOpen,
                    }}
                  >
                    <MenuItem
                      value={""}
                      hidden
                      selected
                      disabled
                      style={{ minWidth: 220 }}
                    >
                      Filter
                    </MenuItem>
                    <ListItemButton
                      onClick={() => {
                        setPtypeList(!PtypeList);
                        setPbrandList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Tyre Brand</Typography>
                      {PtypeList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.tyre_brand.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: PtypeList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                    <ListItemButton
                      onClick={() => {
                        setPbrandList(!PbrandList);
                        setPtypeList(false);
                      }}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography>Axle Type</Typography>
                      {PbrandList ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemButton>
                    {dropDownData.axle_type.map((item, index) => {
                      return (
                        <MenuItem
                          style={{
                            display: PbrandList ? "flex" : "none",
                          }}
                          key={index}
                          value={item?.id}
                        >
                          {item?.label}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
              </Grid>
              <Grid
                item
                lg={1.6}
                style={{ display: "flex", alignItems: "center" }}
              >
                <Select
                  fullWidth
                  value={PtabValue === 0 ? recordPT : recordPM}
                  onChange={(v) => {
                    PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                    PtabValue === 0
                      ? handleRecordPT(v.target.value)
                      : handleRecordPM(v.target.value);
                  }}
                  style={{ marginLeft: 10 }}
                >
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={25}>25</MenuItem>
                  <MenuItem value={50}>50</MenuItem>
                  <MenuItem value={100}>100</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid
              item
              container
              style={{ display: "flex", alignItems: "center", marginTop: 10 }}
              wrap="nowrap"
            >
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disableFuture
                    views={["year", "month", "day"]}
                    value={PdatePicker}
                    onChange={(newValue) => {
                      setPDatePicker(newValue);
                      setPDisable(false);
                    }}
                    inputFormat="dd-MM-yyyy"
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{ svg: { color: color.primary } }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Typography style={{ marginLeft: 10, marginRight: 10 }}>
                to
              </Typography>
              <Grid>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    showToolbar={false}
                    disabled={isNull(PdatePicker) ? true : Pdisable}
                    minDate={PdatePicker}
                    views={["year", "month", "day"]}
                    disableFuture
                    value={PdatePicker1}
                    inputFormat="dd-MM-yyyy"
                    onChange={(newValue) => {
                      setPDatePicker1(newValue);
                    }}
                    DialogProps={{ className: styles.datePicker }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "DD-MM-YYYY",
                        }}
                        sx={{
                          svg: {
                            color:
                              Pdisable || isNull(PdatePicker)
                                ? ""
                                : color.primary,
                          },
                        }}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
            <Grid
              container
              style={{ marginTop: 10 }}
              alignItems={"center"}
              wrap="nowrap"
            >
              <Grid item xs={10}>
                <TextField
                  fullWidth
                  placeholder="Search"
                  className={styles.inputFieldStyle}
                  value={PsearchFilter}
                  onChange={(v) => {
                    isEmpty(v.target.value) && getPResolutionDetails(true);
                    setPSearchFilter(v?.target?.value);
                  }}
                  onKeyPress={(ev) => {
                    if (ev.key === "Enter") {
                      if (
                        !isEmpty(PsearchFilter) ||
                        !isNull(PdatePicker) ||
                        !isNull(PdatePicker1)
                      ) {
                        PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                        getPResolutionDetails(false);
                      }
                    }
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <div style={{ lineHeight: 0 }}>
                          <Search style={{ fontSize: 20 }} />
                        </div>
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position="end">
                        <div style={{ lineHeight: 0, cursor: "pointer" }}>
                          <Tooltip
                            title="Search by Tyre Serial No. and Resolved By"
                            placement="bottom"
                            arrow
                          >
                            <InfoIcon style={{ fontSize: 20 }} />
                          </Tooltip>
                        </div>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  style={{ backgroundColor: color.secondary, marginLeft: 10 }}
                  onClick={() => {
                    if (
                      !isEmpty(PsearchFilter) ||
                      (!isNull(PdatePicker) && !isNull(PdatePicker1))
                    ) {
                      PtabValue === 0 ? handlePageNoPT(1) : handlePageNoPM(1);
                      getPResolutionDetails(false);
                    }
                  }}
                >
                  <Search />
                </Button>
                <Button
                  variant="contained"
                  style={{ marginLeft: 10 }}
                  disabled={!PsearchFilter && !PdatePicker && !Pfilter}
                  onClick={resetPFilterData}
                >
                  <ResetIcon />
                </Button>
              </Grid>
            </Grid>

            {PpageLoad1 ? (
              <div className={styles.dataMain}>
                <CircularProgress style={{ color: color.primary }} />
              </div>
            ) : isArray(PresolutionDetails?.data) &&
              !isEmpty(PresolutionDetails?.data) ? (
              <div>
                {PresolutionDetails?.data?.map((item, index) => {
                  return (
                    <div style={{ position: "relative" }}>
                      <Grid
                        item
                        container
                        id={`jump_to_meP${index}`}
                        style={{
                          border: `1px solid ${color.bordercolor}`,
                          alignItems: "center",
                          // height: PactiveIndex === index ? "auto" : 115,
                          boxShadow: color.shadow,
                          overflow: "hidden",
                          marginTop: 20,
                        }}
                      >
                        <Grid item container>
                          <Table className={styles.customtableResolutionList}>
                            {PactiveIndex !== index ? (
                              <>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Serial No.
                                    </TableCell>
                                    <TableCell variant="head">
                                      Tyre Position
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_serial_no || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_position || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Fleet Name
                                    </TableCell>
                                    <TableCell variant="head">
                                      Vehicle No.
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.fleet_name || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.vehicle_no || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                              </>
                            ) : (
                              <>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Serial No.
                                    </TableCell>
                                    <TableCell variant="head">
                                      Tyre Position
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_serial_no || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_position || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Fleet Name
                                    </TableCell>
                                    <TableCell variant="head">
                                      Vehicle No.
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.fleet_name || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.vehicle_no || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>

                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Axle Type
                                    </TableCell>
                                    <TableCell variant="head">
                                      Tyre Type
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.axle_type || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_type || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Brand
                                    </TableCell>
                                    <TableCell variant="head">
                                      Item Description
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_brand || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_pattern_size || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Tyre Composition
                                    </TableCell>
                                    {PtabValue === 1 ? (
                                      <TableCell variant="head">
                                        Mechanical Issue
                                      </TableCell>
                                    ) : (
                                      <TableCell variant="head">
                                        Tyre Issue
                                      </TableCell>
                                    )}
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.tyre_composition || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {getPIssued(index)}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Identified On
                                    </TableCell>
                                    <TableCell variant="head">
                                      Identified By
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {getPIssuesIdentifiedOn(index) || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.issue_identified_by || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head">
                                      Resolved On
                                    </TableCell>
                                    <TableCell variant="head">
                                      Resolved By
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {getPResolvedOn(index) || "-"}
                                    </TableCell>
                                    <TableCell
                                      variant="body"
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.resolved_by || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableHead>
                                  <TableRow>
                                    <TableCell variant="head" colSpan={2}>
                                      Remarks
                                    </TableCell>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell
                                      variant="body"
                                      colSpan={2}
                                      style={{ paddingBottom: 5 }}
                                    >
                                      {item?.remark || "-"}
                                    </TableCell>
                                  </TableRow>
                                </TableHead>
                                {(userdata?.role === "superadmin" ||
                                  userdata?.role === "admin") && (
                                  <TableHead>
                                    <TableRow>
                                      <TableCell
                                        rowSpan={2}
                                        style={{
                                          padding: "10px 0px 10px 20px",
                                        }}
                                      >
                                        <Button
                                          style={{
                                            backgroundColor: color.primary,
                                          }}
                                        >
                                          {PAction(index)}
                                        </Button>
                                      </TableCell>
                                      <TableCell
                                        rowSpan={2}
                                        style={{
                                          padding: "10px 20px 10px 0px",
                                        }}
                                        align="right"
                                      >
                                        <Button
                                          style={{
                                            backgroundColor: "red",
                                          }}
                                        >
                                          {getDelete(index)}
                                        </Button>
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                )}
                              </>
                            )}
                          </Table>
                        </Grid>
                        <div
                          style={{
                            zIindex: 1,
                            color: color.primary,
                            display: "flex",
                            justifyContent: "center",
                            position: "absolute",
                            bottom: -18,
                            left: "45%",
                          }}
                          onClick={() => {
                            PactiveIndex === index
                              ? setPActiveIndex(null)
                              : setPActiveIndex(index);
                          }}
                        >
                          {PactiveIndex === index ? (
                            <div style={{ transform: "rotate(180deg)" }}>
                              <TableToggle />
                            </div>
                          ) : (
                            <TableToggle />
                          )}
                        </div>
                      </Grid>
                    </div>
                  );
                })}

                <div
                  style={{
                    display: "flex",
                    paddingTop: 30,
                    paddingBottom: 20,
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Pagination
                    count={Math.ceil(PresolutionDetails?.pagination?.totalPage)}
                    defaultPage={PtabValue === 0 ? pageNoPT : pageNoPM}
                    boundaryCount={1}
                    siblingCount={0}
                    variant="outlined"
                    shape="rounded"
                    onChange={(v, e) => {
                      PtabValue === 0 ? handlePageNoPT(e) : handlePageNoPM(e);
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles.dataMain}>
                <Typography>No Data</Typography>
              </div>
            )}
          </div>
        </>
      )}

      <ConfirmDialog
        title={`Are you sure you want to Delete Issue?`}
        visible={confirmDialog}
        handleModal={(bool) => {
          if (bool) {
            deleteIssue();
          } else {
            setConfirmDialog(false);
          }
        }}
        btnLoad={loader}
      />
    </>
  );
}
