import { Search } from "@mui/icons-material";
import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  MenuItem,
  Pagination,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { useSelector } from "react-redux";
import InfoIcon from "@mui/icons-material/Info";
import { toast } from "react-toastify";
import { color } from "../../../Config/theme";
import { getApiData } from "../../../Utils/APIHelper";
import { Setting } from "../../../Utils/Setting";
import useStyles from "./styles";
import { useTheme } from "@mui/styles";
import { isArray, isEmpty, isNull, isObject } from "lodash";
import moment from "moment";
import * as XLSX from "xlsx/xlsx.mjs";
import authActions from "../../../Redux/reducers/auth/actions";

function AuditList(props, ref) {
  const {
    tsn = "",
    handleClick = () => null,
    handlePageNo = () => null,
    handleRecord = () => null,
    handleLoader = () => null,
    handleSearch = () => null,
    search = "",
    pageNo = 1,
    record = 10,
  } = props;
  const styles = useStyles();
  const { token, dropDownData } = useSelector((state) => state.auth);

  const theme = useTheme();
  const lg = useMediaQuery(theme.breakpoints.down("lg"));
  const [pageLoad, setPageLoad] = useState(false);

  // const [searchFilter, setSearchFilter] = useState(tsn);
  const [auditData, setInventoryData] = useState({});

  useEffect(() => {
    getAuditList();
  }, []);

  useState(() => {
    if (!isEmpty(tsn)) {
      handleSearch(tsn);
    }
  }, [tsn]);

  useEffect(() => {
    isEmpty(search) && getAuditList(false);
  }, [search]);

  useEffect(() => {
    setPageLoad(true);
    getAuditList(false);
  }, [pageNo, record]);

  function resetFilterData() {
    handleSearch("");
    getAuditList(true);
  }

  useImperativeHandle(ref, () => ({
    getAuditList: () => {
      getAuditList(false, 1);
    },
  }));

  const inventoryColumn = [
    {
      field: "id",
      headerName: "Sr. No.",
      width: 80,
      headerAlign: "center",
      align: "center",
      sortable: false,
      renderCell: (params) => {
        const index = params.api.getRowIndex(params.row.id) + 1;
        const mainIndex = pageNo === 1 ? index : (pageNo - 1) * record + index;
        return <Typography>{mainIndex}</Typography>;
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: lg ? 260 : "unset",
      flex: 1,
    },
    {
      field: "audit_date",
      headerName: "Audit Date",
      width: 300,
      renderCell: (params) => {
        const Date = params?.row?.audit_date;
        const displayDate = moment(Date).format("DD-MM-yyyy");
        return <Typography>{displayDate || "-"}</Typography>;
      },
    },
  ];

  async function getAuditList(bool, v) {
    !v && setPageLoad(true);
    v && handleLoader(true);
    let endpoints = `${
      Setting.endpoints.auditList
    }?page=${pageNo}&per_page=${record}&search=${
      bool ? "" : search
    }&is_download=${v ? v : ""}`;
    try {
      const response = await getApiData(
        endpoints,
        "GET",
        {},
        { Authorization: `Bearer ${token}` }
      );
      if (response.status) {
        if (
          isObject(response.data) &&
          !isEmpty(response.data) &&
          isArray(response?.data?.rows)
        ) {
          if (v) {
            isEmpty(response?.data?.rows)
              ? toast.error("No Data Found")
              : downloadInvetoryData(response?.data?.rows);
          } else {
            const listObj = {
              pagination: response?.data?.pagination,
              data: response?.data?.rows || [],
            };
            setInventoryData(listObj);
          }
        }
      } else {
        toast.error(response?.message, { toastId: 17 });
      }
      handleLoader(false);
      setPageLoad(false);
    } catch (error) {
      handleLoader(false);
      setPageLoad(false);
      toast.error(error.toString(), { toastId: 17 });
      console.log("error ===>>>", error);
    }
  }

  function downloadInvetoryData(data) {
    const newData = data.map((item, index) => {
      const newObj = {
        "Sr. No.": index + 1,
        Name: item?.name,
        "Audit Date": moment(item?.capture_date).format("DD-MM-yyyy") || "-",
      };
      return newObj;
    });
    const workSheet = XLSX.utils.json_to_sheet(newData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, "Audit List");
    //Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    //Binary string
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    //Download
    XLSX.writeFile(workBook, "Audit List.xlsx");
  }

  const dataGridRender = useMemo(() => {
    return (
      <DataGrid
        rows={auditData?.data}
        columns={inventoryColumn}
        disableColumnMenu
        hideFooter
        showCellRightBorder
        disableSelectionOnClick
        showColumnRightBorder
        autoHeight={true}
        getRowHeight={() => "auto"}
        onCellClick={(params) => {
          handleClick("auditDetails", params?.row);
        }}
      />
    );
  }, [auditData]);

  return (
    <div>
      <Grid
        container
        justifyContent="space-between"
        alignItems="center"
        style={{ marginTop: 14 }}
        wrap="nowrap"
      >
        <Grid style={{ display: "flex" }} alignItems={"center"}>
          <Grid item alignItems={"center"} wrap={"nowrap"}>
            <TextField
              fullWidth
              placeholder="Search"
              className={styles.inputFieldStyle}
              value={search}
              onChange={(v) => {
                isEmpty(v.target.value) && getAuditList(true);
                handleSearch(v?.target?.value);
              }}
              onKeyPress={(ev) => {
                if (ev.key === "Enter") {
                  if (!isEmpty(search)) {
                    handlePageNo(1);
                    getAuditList(false);
                  }
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <div style={{ lineHeight: 0 }}>
                      <Search style={{ fontSize: 20 }} />
                    </div>
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <div style={{ lineHeight: 0, cursor: "pointer" }}>
                      <Tooltip title="Search by Name" placement="bottom" arrow>
                        <InfoIcon style={{ fontSize: 20 }} />
                      </Tooltip>
                    </div>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <div style={{ marginLeft: 20, display: "flex", wrap: "nowrap" }}>
            <Button
              variant="contained"
              style={{ backgroundColor: color.secondary }}
              onClick={() => {
                if (!isEmpty(search)) {
                  handlePageNo(1);
                  getAuditList(false);
                }
              }}
            >
              Search
            </Button>
            <Button
              variant="contained"
              style={{ marginLeft: 10 }}
              onClick={resetFilterData}
              disabled={isEmpty(search) ? true : false}
            >
              Reset
            </Button>
          </div>
        </Grid>
        <Grid
          item
          lg={1.6}
          style={{
            marginLeft: 20,
            display: "flex",
            alignItems: "center",
            alignSelf: "flex-end",
          }}
        >
          <Typography style={{ fontSize: 14 }}>Records:</Typography>
          <Select
            fullWidth
            value={record}
            onChange={(v) => {
              handlePageNo(1);
              handleRecord(v.target.value);
            }}
            style={{ marginLeft: 10 }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
            <MenuItem value={100}>100</MenuItem>
          </Select>
        </Grid>
      </Grid>

      {pageLoad ? (
        <div className={styles.dataMain}>
          <CircularProgress style={{ color: color.primary }} />
        </div>
      ) : isArray(auditData?.data) && !isEmpty(auditData?.data) ? (
        <div style={{ marginTop: 20 }}>
          {dataGridRender}
          <div
            style={{
              display: "flex",
              padding: 20,
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Pagination
              count={Math.ceil(auditData?.pagination?.totalPage)}
              defaultPage={pageNo}
              boundaryCount={2}
              siblingCount={0}
              variant="outlined"
              shape="rounded"
              onChange={(v, e) => {
                handlePageNo(e);
              }}
            />
          </div>
        </div>
      ) : (
        <div className={styles.dataMain}>
          <Typography>No Data</Typography>
        </div>
      )}
    </div>
  );
}

export default forwardRef(AuditList);
